import {
  getLocalToken,
  getGateToken,
  setLocalToken,
  setGateToken,
} from './getToken'

export const get = (url, params, token = '') => {
  let config = {
    method: 'GET',
    url,
    params,
  }

  if (token) {
    config.headers = {
      Authorization: `Token token="${token}"`
    };
  } else {
    const gateToken = getGateToken();
    if (gateToken) {
      config.headers = {
        Authorization: `Token gate="${gateToken}"`
      };
    } else {
      const gateToken = Date.now() + Math.floor(Math.random() * 1000000);
      setGateToken(gateToken)
      config.headers = {
        Authorization: `Token gate="${gateToken}"`
      };
    }

  }

  return config
};

export const post = (url, data, params, token = '') => ({
  method: 'POST',
  url,
  data,
  params,
  headers: {
    Authorization: `Token token="${token}"`
  },
})

export const patch = (url, data, params, token = '') => ({
  method: 'PATCH',
  url,
  data,
  params,
  headers: {
    Authorization: `Token token="${token}"`
  },
})

export const remove = (url, data, params, token = '') => ({
  method: 'DELETE',
  url,
  data,
  params,
  headers: {
    Authorization: `Token token="${token}"`
  },
})