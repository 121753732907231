import createReducer from "../helpers/createReducers";
import isEmpty from "lodash/isEmpty";
import { fromJS, Map } from "immutable";
import isNil from "lodash/isNil";

const initialState = fromJS({
  isLogging: false,
  isLoggedIn: false,
  isAuthenticating: false,
  isLoginFailed: false,
  authError: false,
  details: {},
  token: null,
  activeTabbar: "Workouts",
  errorMessage: null,
  isTabbarDynamic: false,
  reactivateAccountData: null,
  paypalConfirmationLink: null,
  firstTime: false,
});

// TODO: Add a default user detail fetch on saga -d
export default createReducer(initialState, {
  APP_STARTUP_SUCCESS: (state, action) => {
    state = state.setIn(["token"], action.payload.token);
    state = state.setIn(
      ["activeTabbar"],
      action.payload.activeTabbar ? action.payload.activeTabbar : "Workouts"
    );
    return state;
  },
  REMOVE_TOKEN_IN_REDUX: (state, action) => {
    state = state.setIn(["token"], null);
    return state;
  },
  CHANGE_TABBAR_DYNAMIC_OPTION_SUCCESS: (state, action) => {
    state = state.setIn(["activeTabbar"], action.payload.activeTabbar);
    return state;
  },
  LOGIN_USER: (state, action) => {
    state = state.setIn(["isLogging"], true);
    state = state.setIn(["errorMessage"], null);
    if (action.payload.firstTime) {
      state = state.setIn(["firstTime"], action.payload.firstTime);
    }
    return state;
  },
  ACCEPT_FIRST_TIME: (state, action) => {
    state = state.setIn(["firstTime"], false);
    state = state.setIn(["secondTime"], true);
    return state;
  },
  ACCEPT_SECOND_TIME: (state, action) => {
    state = state.setIn(["firstTime"], false);
    state = state.setIn(["secondTime"], false);
    return state;
  },
  LOGIN_USER_SUCCESS: (state, action) => {
    state = state.setIn(["isLogging"], false);
    state = state.setIn(["isLoggedIn"], true);
    state = state.setIn(["errorMessage"], null);
    state = state.setIn(["details"], fromJS(action.payload.loginData.user));
    state = state.setIn(["authError"], false);
    state = state.setIn(
      ["token"],
      fromJS(action.payload.loginData.session_token)
    );

    return state;
  },
  LOGIN_USER_FAILURE: (state, action) => {
    state = state.setIn(["isLogging"], false);
    state = state.setIn(["isLoggedIn"], false);
    state = state.setIn(["isLoginFailed"], true);
    state = state.setIn(["details"], {});
    state = state.setIn(["token"], null);
    state = state.setIn(["errorMessage"], action.payload.error);
    return state;
  },
  USER_AUTHENTICATE: (state, action) => {
    state = state.setIn(["isAuthenticating"], true);
    state = state.setIn(["authError"], false);
    return state;
  },
  USER_AUTHENTICATE_SUCCESS: (state, action) => {
    state = state.setIn(["isLogging"], false);
    state = state.setIn(["isAuthenticating"], false);
    state = state.setIn(["isLoggedIn"], action.payload.userData.isLoggedIn);
    state = state.setIn(["details"], fromJS(action.payload.userData.details));
    state = state.setIn(["token"], action.payload.userData.token);
    state = state.setIn(["authError"], false);
    return state;
  },
  USER_AUTHENTICATE_FAILURE: (state, action) => {
    state = state.setIn(["isAuthenticating"], false);
    state = state.setIn(["isLogging"], false);
    state = state.setIn(["isLoggedIn"], false);
    // state = state.setIn(["isLoginFailed"], true);

    state = state.setIn(["authError"], true);
    return state;
  },
  // set state when user cliked logout in navbar
  LOGOUT_NAVBAR_SUCCESS: (state, action) => {
    state = state.setIn(["details"], Map());
    state = state.setIn(["isLogging"], false);
    state = state.setIn(["isLoggedIn"], false);
    state = state.setIn(["isLoginFailed"], false);
    state = state.setIn(["token"], undefined);

    return state;
  },
  TOGGLE_TABBAR_DYNAMIC_OPTION: (state, action) => {
    return state.setIn(["isTabbarDynamic"], action.payload.showTabbar);
  },
  CANCEL_SUBSCRIPTION_SUCCESS: (state, action) => {
    return state.setIn(["details", "membership", "membership_renewal"], false);
  },
  REVERSE_CANCEL_SUBSCRIPTION_SUCCESS: (state, action) => {
    return state.setIn(["details", "membership", "membership_renewal"], true);
  },
  CHANGE_PLAN_SUCCESS: (state, action) => {
    if (!isNil(action.payload.plan)) {
      state = state.setIn(
        ["details", "membership", "amount"],
        action.payload.newSubscriptionCost
      );
      state = state.setIn(
        ["details", "membership", "term"],
        action.payload.newBillingCycle
      );
      state = state.setIn(["details", "plan"], fromJS(action.payload.plan));
    } else {
      state = state.setIn(
        ["paypalConfirmationLink"],
        action.payload.paypalConfirmationLink
      );
    }
    return state;
  },
  EDIT_USER_PROFILE_SUCCESS: (state, action) => {
    if (!isEmpty(action.payload.avatar)) {
      state = state.setIn(["details", "avatar"], fromJS(action.payload.avatar));
    }

    return state;
  },
  CHANGE_PAYMENT_TYPE_SUCCESS: (state, action) => {
    state = state.setIn(
      ["details", "membership", "payment_method", "card_expiry"],
      action.payload.membership.card_expiry
    );
    state = state.setIn(
      ["details", "membership", "payment_method", "card_last4"],
      action.payload.membership.card_last4
    );
    state = state.setIn(
      ["details", "membership", "payment_method", "card_type"],
      action.payload.membership.card_type
    );

    return state;
  },

  SAVE_REACTIVATE_ACCOUNT_DATA: (state, action) => {
    state = state.setIn(["reactivateAccountData"], action.payload.user);
    return state;
  },
});
