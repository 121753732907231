import React from "react";
import '../styles/common.scss';

const Under45MinIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="dynamic-Icon">
            <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                <rect class="minIcon-1" width="48" height="48" />
                <path class="minIcon-2" d="M24,5A19,19,0,1,0,43,24,19,19,0,0,0,24,5Zm1,35.95V39a1,1,0,0,0-2,0v1.95A17,17,0,0,1,7.05,25H9a1,1,0,0,0,0-2H7.05A17,17,0,0,1,23,7.05V9a1,1,0,1,0,2,0V7.05A17,17,0,0,1,40.95,23H39a1,1,0,0,0,0,2h1.95A17,17,0,0,1,25,40.95Z" />
                <path class="minIcon-2" d="M20,30a1,1,0,0,0,.78-.38l4-5A1,1,0,0,0,25,24V17a1,1,0,0,0-2,0v6.65l-3.78,4.73A1,1,0,0,0,20,30Z" /><path class="minIcon-2" d="M24,12a1,1,0,0,0,0,2,10,10,0,1,1,0,20h-.12a10,10,0,0,1-10-10,1,1,0,0,0-2,0,12,12,0,0,0,12,12H24a12,12,0,1,0,0-24Z" />
            </g>
            </g>
        </svg>
    );
};
export default Under45MinIcon;

