import { createSelector } from "reselect";
import { List, Map, Iterable, update, fromJS, toJS } from "immutable";
import type {
  essentialFetchActionParamsType,
  recipeType,
  recipeVariationType,
  recipeGroupType,
  recipeLineType,
} from "../types";
import filter from "lodash/filter";
import join from "lodash/join";
import take from "lodash/take";
import { map, reduce, times, forEach } from "lodash";
import toString from "lodash/toString";

import { getFractionFromString } from "../helpers/converters";
import Fraction from "fraction.js";
import isNull from "lodash/isNull";
import capitalize from "lodash/capitalize";
import trim from "lodash/trim";
import toLower from "lodash/toLower";
import { includes } from "lodash";
import moment, { months } from "moment";
import getDatesForMonth from "../helpers/getDatesForMonth";

export const getEssentials = (state: any) => state.getIn(["essentials"]);

export const getObjectSpecificEssential = (state: any) => {
  return state.getIn(["objectSpecificEssential"]);
};
export const getUserSpecificEssential = (state: any) =>
  state.getIn(["userSpecificEssential"]);

export const getFeaturedItems = createSelector(
  [getEssentials],
  (essentials) => {
    let filterArray = [];
    essentials
      .getIn(["workout", "workoutTypes"])
      .forEach((filteredWorkoutType, index) => {
        if (filteredWorkoutType.getIn(["featured"])) {
          filterArray.push({
            type: "workoutTypes",
            title: "Workout Type",
            ...filteredWorkoutType.toJS(),
          });
        }
      });

    essentials
      .getIn(["workout", "equipments"])
      .forEach((filteredEquipments, index) => {
        if (filteredEquipments.getIn(["featured"])) {
          filterArray.push({
            type: "equipments",
            title: "Equipment",
            ...filteredEquipments.toJS(),
          });
        }
      });

    essentials
      .getIn(["workout", "bodyParts"])
      .forEach((filteredBodyParts, index) => {
        if (filteredBodyParts.getIn(["featured"])) {
          filterArray.push({
            type: "bodyParts",
            title: "Body Part",
            ...filteredBodyParts.toJS(),
          });
        }
      });

    return filterArray;
  }
);

// selector workout headers
export const getWorkoutFilterName = createSelector(
  [getEssentials, getUserSpecificEssential],
  (essentials, userEssentials) => {
    if (essentials.getIn(["workout", "searchApplied"])) {
      // Search
      return {
        type: "search",
        title: essentials.getIn(["workout", "filters", "search", 0]),
      };
    } else if (essentials.getIn(["workout", "favorite", "show"])) {
      // Favorites
      let favoriteBoardSlug = essentials.getIn([
        "workout",
        "favorite",
        "board",
      ]);
      if (favoriteBoardSlug == null) {
        return { type: "favorite", title: "All" };
      }

      let boards = userEssentials.getIn(["boards", "workoutBoards", "boards"]);
      const index = boards.findIndex(
        (board) => board.getIn(["slug"]) == favoriteBoardSlug
      );
      const favoriteHeader = boards.getIn([index, "title"]);
      const favoriteSlug = boards.getIn([index, "slug"]);

      return { type: "favorite", title: favoriteHeader, slug: favoriteSlug };
    } else if (
      essentials.getIn(["workout", "filters", "workoutTypes"]).size > 0 ||
      essentials.getIn(["workout", "filters", "equipments"]).size > 0 ||
      essentials.getIn(["workout", "filters", "bodyParts"]).size > 0 ||
      essentials.getIn(["workout", "filters", "collection"]).size > 0 ||
      essentials.getIn(["workout", "filters", "difficulty"]).size > 0 ||
      essentials.getIn(["workout", "filters", "time"]).size > 0 ||
      (essentials.getIn(["workout", "filters", "search"]) &&
        essentials.getIn(["workout", "filters", "search"]).size > 0 &&
        essentials.getIn(["workout", "filters", "searchBy"]).size > 0) ||
      (essentials.getIn(["workout", "filters", "order"]) &&
        essentials.getIn(["workout", "filters", "order"]).size > 0)
    ) {
      // Filters
      let filterArray = [];
      essentials
        .getIn(["workout", "filters", "workoutTypes"])
        .forEach((filteredWorkoutType, index) => {
          let selectedWorkoutType = essentials
            .getIn(["workout", "workoutTypes"])
            .find(
              (workoutType) =>
                workoutType.getIn(["slug"]) == filteredWorkoutType
            );
          if (selectedWorkoutType) {
            filterArray.push({
              type: "workoutTypes",
              ...selectedWorkoutType.toJS(),
            });
          }
        });

      essentials
        .getIn(["workout", "filters", "equipments"])
        .forEach((filteredEquipments, index) => {
          let selectedEquipment = essentials
            .getIn(["workout", "equipments"])
            .find(
              (equipment) => equipment.getIn(["slug"]) == filteredEquipments
            );
          if (selectedEquipment) {
            filterArray.push({
              type: "equipments",
              ...selectedEquipment.toJS(),
            });
          }
        });

      essentials
        .getIn(["workout", "filters", "bodyParts"])
        .forEach((filteredBodyParts, index) => {
          let selectedOtherFilter = essentials
            .getIn(["workout", "bodyParts"])
            .find(
              (otherFilter) => otherFilter.getIn(["slug"]) == filteredBodyParts
            );
          if (selectedOtherFilter) {
            filterArray.push({
              type: "bodyParts",
              ...selectedOtherFilter.toJS(),
            });
          }
        });

      essentials
        .getIn(["workout", "filters", "difficulty"])
        .forEach((filteredDifficulty, index) => {
          filterArray.push({
            type: "difficulty",
            name: capitalize(filteredDifficulty),
            slug: filteredDifficulty,
          });
        });

      essentials
        .getIn(["workout", "filters", "time"])
        .forEach((filteredTime, index) => {
          filterArray.push({
            type: "time",
            name: capitalize(filteredTime),
            slug: filteredTime,
          });
        });

      essentials
        .getIn(["workout", "filters", "collection"])
        .forEach((filteredCollection, index) => {
          let selectedCollection = essentials
            .getIn(["workout", "collection"])
            .find(
              (collection) => collection.getIn(["slug"]) == filteredCollection
            );
          if (selectedCollection) {
            filterArray.push({
              type: "collection",
              ...selectedCollection.toJS(),
            });
          }
        });
      if (
        essentials.getIn(["workout", "filters", "search", 0]) &&
        essentials.getIn(["workout", "filters", "search", 0]).length > 0
      ) {
        filterArray.push({
          type: "search",
          value: essentials.getIn(["workout", "filters", "search", 0]),
        });
      }

      if (
        essentials.getIn(["workout", "filters", "order", 0]) &&
        essentials.getIn(["workout", "filters", "order", 0]).length > 0
      ) {
        filterArray.push({
          type: "order",
          value: essentials.getIn(["workout", "filters", "order", 0]),
        });
      }

      return { type: "filter", filterArray };
    } else {
      return { type: "recent", title: "Recent Workouts" };
    }
  }
);

// for workouts
export const getUnFilteredList = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workout", "unFilteredList", "list"])
);
export const getFilteredList = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workout", "filteredList", "list"])
);

export const getWorkouts = createSelector(
  [getFilteredList, getUnFilteredList, getWorkoutFilterName],
  (filteredWorkouts, unfilteredWorkouts, workoutFilterName) => {
    return workoutFilterName.type !== "recent"
      ? filteredWorkouts
      : unfilteredWorkouts;
  }
);

// for workout search text
export const getWorkoutSearchText = createSelector(
  [getEssentials],
  (essentials) => {
    return essentials.getIn(["workout", "filters", "search", 0]);
  }
);

// for workout search text
export const getWorkoutSearchBy = createSelector(
  [getEssentials],
  (essentials) => {
    return essentials.getIn(["workout", "filters", "searchBy", 0]);
  }
);

export const getMoreWorkoutsAvailable = createSelector(
  [getWorkoutFilterName, getEssentials],
  (existingFilterType, essentials) => {
    return existingFilterType.type !== "recent"
      ? essentials.getIn(["workout", "filteredList", "moreWorkoutsAvailable"])
      : essentials.getIn([
          "workout",
          "unFilteredList",
          "moreWorkoutsAvailable",
        ]);
  }
);
export const getMoreFeaturedCollectionWorkoutAvailable = createSelector(
  [getWorkoutFilterName, getEssentials],
  (existingFilterType, essentials) => {
    let status = essentials.getIn([
      "workout",
      "featuredCollection",
      "moreWorkoutsAvailable",
    ]);
    return status;
  }
);
export const getFeaturedCollections = createSelector(
  [getEssentials],
  (essentials) => {
    const collections = essentials.getIn(["workout", "collection"]).toJS();
    const changedCollections = filter(
      collections,
      (collection) => collection.featured
    );
    const randomizedCollections = changedCollections;

    return randomizedCollections;
  }
);

export const getWorkoutLimit = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workout", "limit"])
);
export const getWorkoutOffset = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workout", "offset"])
);
export const getActiveWorkout = (state) => state.getIn(["workout"]);
export const getActiveWorkoutDetails = createSelector(
  [getActiveWorkout],
  (workout) => workout.getIn(["activeWorkout"])
);
export const getActiveRelatedWorkouts = createSelector(
  [getActiveWorkout],
  (workout) => {
    return workout.getIn(["relatedWorkouts"]);
  }
);
export const getWorkoutBoards = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => {
    // return userEssentials.getIn(['boards', 'boards','boards'])
    return userEssentials.getIn(["boards", "workoutBoards", "boards"]);
  }
);
export const getWorkoutTypes = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workout", "workoutTypes"])
);
export const getWorkoutBodyParts = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workout", "bodyParts"])
);
export const getWorkoutEquipments = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workout", "equipments"])
);

export const getWorkoutsMessage = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workout", "message"])
);
export const appliedWorkoutFilters = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workout", "filters"])
);

export const getWorkoutsSearchCount = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workout", "searchList", "count"])
);
export const getWorkoutsSearchList = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workout", "searchList", "data"])
);

// fetch status
export const getFetchStatus = (state) => {
  return fromJS({
    appEssentials: state.getIn(["fetchStatus", "appEssentials"]),
    preFetch: state.getIn(["fetchStatus", "objectPreFetch", "workout"]),
    workoutsSearch: state.getIn(["fetchStatus", "workoutsSearch"]),
    initialLoad: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "workout",
      "initialLoad",
    ]),
    pagination: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "workout",
      "pagination",
    ]),
    startup: state.getIn(["fetchStatus", "startup"]),
    userAuthenticate: state.getIn(["fetchStatus", "userAuthenticate"]),
    workoutTemporaryListFetch: state.getIn([
      "fetchStatus",
      "workoutTemporaryListFetch",
    ]),
    // Specific fetchStatus
    featuredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "featuredCollectionList",
      "workout",
    ]),
    favoritesList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "favoritesList",
      "workout",
    ]),
    filteredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "filteredList",
      "workout",
    ]),
    unfilteredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "unfilteredList",
      "workout",
    ]),
    globalError: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "globalError",
      "errorMessage",
    ]),
    pullToRefresh: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "workout",
      "pullToRefresh",
    ]),
    trendingFetch: state.getIn([
      "fetchStatus",
      "trendingWorkout",
      "isFetching",
    ]),
    unfilteredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "unfiltered",
      "initial",
    ]),
    filteredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "filtered",
      "initial",
    ]),
    favoritedListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "favorited",
      "initial",
    ]),
    featuredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "featured",
      "initial",
    ]),
    unfilteredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "unfiltered",
      "pagination",
    ]),
    filteredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "filtered",
      "pagination",
    ]),
    favoritedListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "favorited",
      "pagination",
    ]),
    featuredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "featured",
      "pagination",
    ]),
  });
};

export const getGlobalError = (state) =>
  state.getIn(["fetchStatus", "listItemsFetch", "globalError", "errorMessage"]);

// for workouts *new...........................................................................
export const getWorkoutStackItem = (state, stackIndex = 0) => {
  const recipeStack = state.getIn([
    "objectSpecificEssential",
    "workout",
    "objectStack",
  ]);
  if (!recipeStack || !recipeStack.getIn([stackIndex])) {
    return Map();
  }
  return recipeStack.getIn([stackIndex]);
};

// Recipe stack Item pointed by the component
export const getWorkoutDetails = createSelector(
  [getWorkoutStackItem],
  (workoutStackItem) => {
    if (!workoutStackItem.isEmpty()) {
      const workoutDetails = workoutStackItem.getIn(["data", "workout"]);
      return workoutDetails;
    }
    return Map();
  }
);

export const getRelatedWorkouts = createSelector(
  [getWorkoutStackItem],
  (workoutStackItem) => {
    if (workoutStackItem) {
      const relatedWorkouts = workoutStackItem.getIn([
        "data",
        "relatedWorkouts",
      ]);
      return relatedWorkouts;
    }
    return List();
  }
);

export const getTags = createSelector(
  [getWorkoutStackItem],
  (workoutStackItem) => {
    if (
      workoutStackItem &&
      Iterable.isIterable(workoutStackItem) && workoutStackItem.size > 0
    ) {
      const tags = workoutStackItem.getIn(["data", "tags"]).toJS();
      return take(tags, 5);
    }
    return List();
  }
);

// Get note for active object
export const getNoteStackItem = (state, objectType, stackIndex = 0) => {
  const noteStack = state.getIn([
    "objectSpecificEssential",
    objectType,
    "noteStack",
  ]);
  if (!noteStack || !noteStack.getIn([stackIndex])) {
    return Map();
  }
  return noteStack.getIn([stackIndex, "data"]);
};

// Get comments to the active object
export const getCommentsList = (state, objectType, stackIndex = 0) => {
  // Mofify comment according to front end requirments
  const commentStackItem = state.getIn([
    "objectSpecificEssential",
    objectType,
    "commentStack",
    stackIndex,
    "data",
  ]);

  if (commentStackItem) {
    const currentComments = commentStackItem
      .toSeq()
      .map((comment) => {
        const author = comment.getIn(["user", "name"]);
        const url = comment.getIn(["user", "url"]);

        return comment.setIn(["author"], author).setIn(["url"], url);
      })
      .toList();

    return currentComments;
  }
  return List();
};

export const getTemporaryFilterCount = (state) => {
  return state.getIn(["essentials", "workout", "temporaryFilteredCount"]);
};
export const getWorkoutTemporaryFilterCount = (state) => {
  return state.getIn(["essentials", "workout", "temporaryFilteredCount"]);
};
export const getFeaturedList = (state) => {
  return state.getIn(["essentials", "workout", "featuredCollection", "list"]);
};
export const getTrendingWorkouts = (state) => {
  return state.getIn(["essentials", "trending", "workouts", "data"]);
};

export const getTrendingType = (state) => {
  return state.getIn(["essentials", "trending", "workouts", "type"]);
};

export const getOrder = (state) => {
  return state.getIn(["essentials", "workout", "filters", "order", 0]);
};

export const getWorkoutFetchStatus = (state) => {
  return fromJS({
    appEssentials: state.getIn(["fetchStatus", "appEssentials"]),
    preFetch: state.getIn(["fetchStatus", "objectPreFetch", "workout"]),
    workoutsSearch: state.getIn(["fetchStatus", "workoutsSearch"]),
    initialLoad: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "workout",
      "initialLoad",
    ]),
    pagination: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "workout",
      "pagination",
    ]),
    startup: state.getIn(["fetchStatus", "startup"]),
    userAuthenticate: state.getIn(["fetchStatus", "userAuthenticate"]),
    workoutTemporaryListFetch: state.getIn([
      "fetchStatus",
      "workoutTemporaryListFetch",
    ]),
    // Specific fetchStatus
    featuredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "featuredCollectionList",
      "workout",
    ]),
    favoritesList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "favoritesList",
      "workout",
    ]),
    filteredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "filteredList",
      "workout",
    ]),
    unfilteredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "unfilteredList",
      "workout",
    ]),
    globalError: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "globalError",
      "errorMessage",
    ]),
    pullToRefresh: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "workout",
      "pullToRefresh",
    ]),
    trendingFetch: state.getIn([
      "fetchStatus",
      "trendingWorkout",
      "isFetching",
    ]),
    unfilteredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "unfiltered",
      "initial",
    ]),
    filteredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "filtered",
      "initial",
    ]),
    favoritedListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "favorited",
      "initial",
    ]),
    featuredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "featured",
      "initial",
    ]),
    unfilteredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "unfiltered",
      "pagination",
    ]),
    filteredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "filtered",
      "pagination",
    ]),
    favoritedListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "favorited",
      "pagination",
    ]),
    featuredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "workout",
      "featured",
      "pagination",
    ]),
  });
};

export const getMeteredDetails = (state) =>
  state.getIn(["meteredDetails", "workout"]);
