import React from "react";
import '../styles/common.scss';

const AddnoteIcon = () => {
  return (
   
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 38 38" xml space="preserve">
<g>
	<rect className="AddnoteFillIcon-0" width="38" height="38"/>
	<g>
		<path className="AddnoteFillIcon-1" d="M34.5,35.1H14c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1h20.5c0.6,0,1-0.4,1-1C35.5,35.6,35,35.1,34.5,35.1z"/>
		<path className="AddnoteFillIcon-1" d="M27.7,9.9c1.5-2.4,0.9-5.5-1.5-7.1c0,0,0,0,0,0l-1.7-1.1c-2.4-1.5-5.5-0.8-7.1,1.5c0,0,0,0,0,0l-0.6,0.9
			l10.3,6.7L27.7,9.9z"/>
		<path className="AddnoteFillIcon-1" d="M2.5,36.1c0,0.6,0.4,1,1,1c0.2,0,0.4,0,0.5-0.1l8.2-3.9l-9.6-6.2L2.5,36.1z"/>
		<polygon className="AddnoteFillIcon-1" points="26.1,12.5 15.8,5.8 3.4,25 13.7,31.7 		"/>
	</g>
</g>
</svg> 
  );
};
export default AddnoteIcon;