import React, { useEffect, useState } from "react";

import cx from "classnames";
import moment from "moment";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  AddressElement,
  CardElement
} from "@stripe/react-stripe-js";

import isNil from "lodash/isNil";

import Button from "../../../common/button";
import CustomInput from "../../../common/customInput";

import FrameIcon from "../../../images/frameIcon";

const _StripeForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitStatus, setSubmitStatus] = useState(false);

  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCVCComplete] = useState(false);
  const [isAddressComplete, setIsAddressComplete] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const [ipAddress, setIPAddress] = useState('')

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => setIPAddress(data.ip))
      .catch(error => console.log(error))
  }, []);

  useEffect(() => {
    if (!props.unifiedRegisterState.isRegistering) {
      setIsClickedOnce(false);
    }
  }, [props.unifiedRegisterState.isRegistering]);
  const handleChange = (type, complete) => {
    if (type === "number") {
      if (complete) {
        setNumberComplete(true);
      } else {
        setNumberComplete(false);
      }
    } else if (type === "expiry") {
      if (complete) {
        setExpiryComplete(true);
      } else {
        setExpiryComplete(false);
      }
    } else if (type === "cvc") {
      if (complete) {
        setCVCComplete(true);
      } else {
        setCVCComplete(false);
      }
    }
    else if (type === "address") {
      if (complete) {
        setAddressIsComplete(true);
      } else {
        setAddressIsComplete(false);
      }
    }
  };

  const isHeardFromValid = (props.heardFrom !== 'Other' && !!props.heardFrom) || (props.heardFrom === 'Other' && !!props.otherReasons);

  const isFormComplete =
    numberComplete &&
    expiryComplete &&
    cvcComplete &&
    !props.errors.email &&
    !props.errors.password &&
    !props.errors.name &&
    !props.errors.billingAddress &&
    props.name &&
    props.email &&
    props.password &&
    isHeardFromValid;

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsClickedOnce(true);

    if (stripe) {
      const cardElement = elements.getElement(CardNumberElement);

      stripe.createToken(cardElement).then((payload) => {
        props.completeRegistrationUnified(
          props.name,
          props.email,
          props.password,
          payload.token.id,
          props.unifiedRegisterState.duration,
          props.localPlan,
          null,
          null,
          props.shouldApplyDiscount,
          props.heardFrom,
          props.otherReasons,
          ipAddress
        );
      });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  let couponType = null;
  if (
    props.unifiedRegisterState.coupon.activationSuccess &&
    props.unifiedRegisterState.coupon.data.percent_off &&
    props.unifiedRegisterState.coupon.data.percent_off > 0
  ) {
    couponType = "discount";
  } else if (props.unifiedRegisterState.coupon.activationSuccess) {
    couponType = "freetrial";
  }
  // minor change


  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="paymet-option">Credit Card  |  Stripe</div> */}
      <div className="form-text">
        <label className="static-value">Card</label>
        <CardNumberElement
          // onBlur={handleBlur}
          onChange={(details) => handleChange("number", details.complete)}
        // onFocus={handleFocus}
        // onReady={handleReady}
        />
      </div>
      <div className="form-group">
        <div className="form-text">
          <label className="static-value">Exp</label>
          <CardExpiryElement
            // onBlur={handleBlur}
            onChange={(details) => handleChange("expiry", details.complete)}
          // onFocus={handleFocus}
          // onReady={handleReady}
          />
        </div>
        <div className="form-text">
          <label className="static-value">CVC</label>
          <CardCvcElement
            // onBlur={handleBlur}
            onChange={(details) => handleChange("cvc", details.complete)}
          // onFocus={handleFocus}
          // onReady={handleReady}
          />
        </div>

        {/* <Button>
          Submit
        </Button> */}
        {/* <PaymentRequestButtonElement
          paymentRequest={paymentRequest}
          className="PaymentRequestButton"
          style={{
            // For more details on how to style the Payment Request Button, see:
            // https://stripe.com/docs/elements/payment-request-button#styling-the-element
            paymentRequestButton: {
              theme: 'light',
              height: '64px',
            },
          }}
        /> */}
      </div>
      {/* <div className="form-text address-element-stripe"> */}
      {/* <AddressElement
          options={{
            mode: 'billing',
          }}
          // onBlur={handleBlur}
          onChange={(details) => handleChange("address", details.complete)}
        // onFocus={handleFocus}
        // onReady={handleReady}
        /> */}
      {/* </div> */}
      {/* <div className="due-wrapper-set">
        <div className="due-wrapper">
          <span>Total due today</span>
          <span>$0.00</span>
        </div>
        <div className="due-slide">
          <span>
            Total due on{" "}
            {moment()
              .add({ days: 7 })
              .format("MMMM Do")}
          </span>
          <span
            className={cx({
              cross: props.unifiedRegisterState.coupon.activationSuccess,
            })}
          >
            ${props.localDollars}
          </span>
        </div>
        {props.unifiedRegisterState.coupon.activationSuccess ? (
          <div className="due-wrapper coupon-wrapper">
            <span className={cx("applied-coupon")}>
              Coupon applied: {props.unifiedRegisterState.coupon.data.code}
            </span>
            <span className="price normal-highlight">
              {!isNil(props.discountedPrice)
                ? `$${parseFloat(props.discountedPrice)}`
                : null}
            </span>
          </div>
        ) : null}
      </div> */}
      {(couponType === "discount" && props.unifiedRegisterState.duration === 'annually') && (
        <div className="due-wrapper-set">

          <div className="due-wrapper">
            <span>Total due today</span>
            <span
              className={cx({
                cross:
                  props.unifiedRegisterState.coupon
                    .activationSuccess,
              })}
            >${props.localDollars}</span>
          </div>
          {props.unifiedRegisterState.coupon.activationSuccess && props.shouldApplyDiscount && !props.shouldNeverApplyDiscount ? (
            // <div className="due-wrapper coupon-wrapper">
            //   <span className={cx("applied-coupon")}>
            //     Coupon applied: {props.unifiedRegisterState.coupon.data.code}
            //   </span>
            //   <span className="price normal-highlight">
            //     {!isNil(props.discountedPrice)
            //       ? `$${parseFloat(props.discountedPrice)}`
            //       : null}
            //   </span>
            // </div>
            <>
              <div className="due-wrapper coupon-wrapper">
                <span className={cx("applied-coupon")}>
                  Coupon applied for the first cycle:{" "}
                  {props.unifiedRegisterState.coupon.data.code}
                </span>
                <span className="price normal-highlight">
                  {!isNil(props.discountedPrice)
                    ? `$${parseFloat(props.discountedPrice)}`
                    : null}
                </span>
              </div>
              <div className="due-wrapper secondary">
                <span>
                  From next cycle:{" "}
                </span>
                <span className="price normal-highlight">
                  {!isNil(props.localDollars)
                    ? `$${parseFloat(props.localDollars)}`
                    : null}
                </span>
              </div>
            </>
          ) : null}


        </div>
      )}
      {(couponType === "freetrial") && (
        <div className="due-wrapper-set">
          <div className="due-wrapper">
            <span>Total due today</span>
            <span>$0.00</span>
          </div>
          <div className="due-slide">
            <span
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span>
                Total due on{" "}
                {moment()
                  .add({
                    weeks: props.unifiedRegisterState.coupon.activationSuccess
                      ? parseInt(
                        props.unifiedRegisterState.coupon.data.trial_length
                      )
                      : 0,
                  })
                  .format("MMMM Do")}
              </span>
              {props.unifiedRegisterState.coupon.activationSuccess ? (
                <span style={{ fontSize: "0.9rem" }}>
                  (You have got{" "}
                  {props.unifiedRegisterState.coupon.data.trial_length} weeks of
                  free trial)
                </span>
              ) : null}
            </span>
            <span>${props.localDollars}</span>
          </div>
          {props.unifiedRegisterState.coupon.activationSuccess ? (
            <>
              <div className="due-wrapper coupon-wrapper">
                <span className={cx("applied-coupon")}>
                  Coupon applied: {props.unifiedRegisterState.coupon.data.code}
                </span>
              </div>
              <div className="due-wrapper coupon-wrapper">
                <span className={cx("applied-coupon")} />
              </div>
            </>
          ) : null}
        </div>
      )}

      {(couponType === null || (couponType === "discount" && props.unifiedRegisterState.duration === 'monthly')) && (
        <div className="due-wrapper-set">
          <div className="due-wrapper">
            <span>Total due today</span>
            <span>$0.00</span>
          </div>
          <div className="due-slide">
            <span>
              Total due on{" "}
              {moment()
                .add({
                  days: 3
                })
                .format("MMMM Do")}
            </span>
            <span>${props.localDollars}</span>
          </div>
        </div>
      )}

      <Button
        btnClass="full btn-primary btn-md"
        btnText="Signup"
        isLoading={isClickedOnce || props.unifiedRegisterState.isRegistering}
        disabled={
          isClickedOnce ||
          props.unifiedRegisterState.isRegistering ||
          !isFormComplete
        }
      />
      {props.unifiedRegisterState.errorMessage && (
        <div className="errorText globalErrorText">
          <FrameIcon />
          <p>{props.unifiedRegisterState.errorMessage}</p>
        </div>
      )}
    </form>
  );
};

export default (_StripeForm);
