import React from "react";
import '../styles/common.scss';

const StripeIcon = () => {
    return (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.3639 11.3478C12.44 10.5978 12.44 10.2174 12.44 9.81523C12.44 9.38045 12.8095 8.92393 13.8421 8.92393C14.5378 8.92393 15.2117 9.08697 15.8313 9.41306L16.4943 9.70654C16.6574 9.78263 16.8421 9.76089 16.9943 9.67393C17.1465 9.5761 17.2334 9.41306 17.2334 9.22828V6.40219C17.2334 6.1848 17.103 5.98915 16.9074 5.90219C15.9508 5.50002 14.9291 5.30437 13.8965 5.32611C10.7987 5.32611 8.53779 7.2935 8.53779 10C8.53779 13.2718 11.7008 14.3913 12.7226 14.75C14.5161 15.4348 14.516 15.9239 14.516 16.3478C14.516 17.3587 13.4726 17.5109 12.8421 17.5109C11.9726 17.5 11.1139 17.2609 10.3639 16.8152L9.08127 16.0761V20.2609C10.2117 20.8152 11.4617 21.0978 12.7226 21.0978C16.6574 21.0978 18.4291 18.6413 18.4291 16.2174C18.4291 13.2935 16.2552 12.087 14.3639 11.3478Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M0.918213 2.08696C0.918213 1.48913 1.40734 1 2.00517 1H24.8313C25.4291 1 25.9182 1.48913 25.9182 2.08696V24.913C25.9182 25.5109 25.4291 26 24.8313 26H2.00517C1.40734 26 0.918213 25.5109 0.918213 24.913V2.08696Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
export default StripeIcon;

