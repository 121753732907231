import React, { useState, useEffect } from "react";
import isNil from "lodash/isNil";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import axios from "axios";
import { membershipGift, auth } from '../../../helpers/api'

import { saveGiftCode, showToastMessage, addSentryError, setInitiateRegisterEmail, assignPlanUnified, setPlans } from "../../../actions";
import toJS from '../../../helpers/toJS';
import pushToSentry from '../../../helpers/pushToSentry';
import Loader from "../../images/loader";

const AvailGift = ({
  ...props
}) => {
  const [state, setState] = useState({
    isGiftCodeValid: false,
    isLoggedIn: false,
    isRegisteredUser: false,
    fetchStatus: {
      isFetched: false,
      isFetching: false,
      isError: null,
    }
  });

  const [isPlansFetching, setPlansFetching] = useState(false);

  useEffect(() => {

    setPlansFetching(true);
    axios({
      method: "GET",
      url: auth.getPlans(true),
    })
      .then((response) => {
        setPlansFetching(true);
        console.log('setting plans ', response.data)
        props.setPlansOnRedux(response.data);
      })
      .catch((e) => {
        setPlansFetching(false);
        props.showToastMessage(`Something went wrong`, "error");
      });

  }, []);

  useEffect(() => {
    if (!isNil(props.match.params.gift_code)) {
      axios({
        method: 'POST',
        url: membershipGift.getGiftInfo(),
        data: {
          gift_code: props.match.params.gift_code
        },
      }).then((response) => {
        setState({ ...state, fetchStatus: { isFetching: true, isFetched: false, isError: null } })
        if (response.data.success) {
          let token = localStorage.getItem("token");
          if (!isNil(token)) {
            setState({ ...state, isGiftCodeValid: true, isLoggedIn: true, fetchStatus: { isFetching: false, isFetched: true, isError: null } })
            props.history.push('/account/membership');
          } else {
            props.history.push("/login");
            props.showToastMessage('✅ Login in with your account and navigate to membership page to activate the gift.', 'success')
            setState({ ...state, isGiftCodeValid: true, isLoggedIn: false, fetchStatus: { isFetching: false, isFetched: true, isError: null } })
          }
        } else {
          if (response.data.message === "Invalid gift code") {
            setState({ ...state, isGiftCodeValid: false, isLoggedIn: false, fetchStatus: { isFetching: false, isFetched: true, isError: response.data.message } })
          } else {
            props.assignPlanUnified(1, 'annually');
            props.setInitiateRegisterEmail(response.data.to_email);
            props.saveGiftCode({
              giftCode: props.match.params.gift_code,
              toEmail: response.data.to_email,
              fromEmail: response.data.from_email,
              fromName: response.data.from_name,
              months: response.data.months,
              toName: response.data.to_name
            });


            setTimeout(() => {
              props.history.push('/register');
            }, 5000)

          }
        }
      }).catch((e) => {
        const eventId = pushToSentry(e)
        props.addSentryError({
          error: e.message,
          eventId
        })
      })
    }
  }, [props.match.params.gift_code]);

  const renderContent = () => {
    if (!state.fetchStatus.isFetching && state.fetchStatus.isFetched) {
      if (state.isGiftCodeValid) {
        return (
          <div className="container">
            <div className="membership-card-wrapper">
              <h2>avail gift by creating account or logging into the account.</h2>
            </div>
          </div>
        )
      } else {
        return (
          <div className="container">
            <div className="membership-card-wrapper">
              <h2>Gift code is Invalid</h2>
            </div>
          </div>
        )
      }
    }
    return (
      <div className="container">
        <Helmet>
          <title>Avail Gift | Dashing Dish </title>
          <meta name="description" content="Dashingdish Avail Gift page" />
        </Helmet>
        <div className="preLoader">
          <Loader></Loader>
        </div>
      </div>
    )
  }

  return (
    renderContent()
  )
}

const mapStateToProps = state => ({})
const dispatchActionToProps = dispatch => ({
  saveGiftCode: bindActionCreators(saveGiftCode, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
  setInitiateRegisterEmail: bindActionCreators(setInitiateRegisterEmail, dispatch),
  assignPlanUnified: bindActionCreators(assignPlanUnified, dispatch),
  setPlansOnRedux: bindActionCreators(setPlans, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(AvailGift));