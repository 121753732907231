import { useQuery } from 'react-query';
import axios from 'axios';
import { baseUrl } from './api';

const fetchRecipesByIngredients = async (ingredients) => {
  const response = await axios({
    method: 'GET',
    url: `${baseUrl}/api/recipes-instant-filter`,
    params: {
      search: ingredients.join(','),
      search_by: 'ingredients',
      timestamp: new Date().getTime()
    }
  });
  return response.data;
};

export const useRecipeSearchByIngredients = (ingredients, options = {}) => {
  return useQuery(
    ['recipes', 'ingredients', ingredients?.join(',')],
    () => fetchRecipesByIngredients(ingredients),
    {
      enabled: ingredients?.length > 0,
      staleTime: 10 * (60 * 1000), // 10 minutes
      cacheTime: 30 * 60 * 1000, // 30 minutes
      retry: 2,
      ...options
    }
  );
};
