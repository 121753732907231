import { MOVE_MEAL_IN_MEALPLAN } from './actionTypes';

export default (meal_id, toDate, order, fromDate) => ({
  type: MOVE_MEAL_IN_MEALPLAN,
  payload: {
    meal_id,
    toDate,
    fromDate,
    order
  }
})