import { COMPLETE_REGISTRATION } from './actionTypes';

export default (userId, stripeId, duration, planId, paypalId) => ({
  type: COMPLETE_REGISTRATION,
  payload: {
    userId,
    stripeId,
    duration,
    planId,
    paypalId
  },
});