import React from 'react'

import cx from 'classnames'

const TappableRow = ({
  disabled,
  additionalClass,
  onTap,
  children,
}) => (
  <div
    className={
      cx(
        'tappable-row',
        additionalClass
      )
    }
    onClick={onTap}
  >
    {children}
  </div>
)

export default TappableRow