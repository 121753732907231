import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import img from '../images/404-heroimg.png';
import { Helmet } from 'react-helmet';

import { appEssentialFetch } from "../../actions";

const Page404 = (props) => {
  useEffect(() => {
    props.appEssentialFetch();
  }, [])
  return (
    <main>
      <Helmet>
        <title>404 | Dashing Dish</title>
        <meta name="description" content="Dashingdish 404 page" />
      </Helmet>
      <section className="page404"
        style={{
          backgroundImage: `url(${img})`
        }}>
        <div className="container">
          <div className="Wrapper404">
            <h1>404</h1>
            <h2>Mama said there’d be pages like this.</h2>
            <p>Try using the menu at the top of the page to find what you’re looking for.  Or, go&nbsp;<a href={'/'} onClick={(e) => { e.preventDefault(); props.history.push("/") }}>home.</a></p>
          </div>
        </div>
      </section>
    </main>
  )
}

const mapStateToProps = () => ({})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(Page404);