import React from 'react';
import { map } from 'lodash';
import ReactMarkdown from 'react-markdown';

const IngredientFooter = (props) => {

  const {
    footnotes
  } = props;

  return (
    <div className="ingredientFooter">
      <h5>Footnotes</h5>
      <ol>
        {
          map(footnotes, (footnote, index) => {
            return (
              <div key={index}>
                <ReactMarkdown
                  source={footnote}
                  linkTarget="_blank"
                />
              </div>
            )
          })
        }
      </ol>
    </div>
  )
}

export default IngredientFooter;