import { UNIFIED_REGISTER } from "./actionTypes";

export default (
  name,
  email,
  password,
  stripeId,
  duration,
  planId,
  paypalId,
  giftCode,
  shouldApplyDiscount,
  heardFrom,
  otherReasons,
  ipAddress
) => ({
  type: UNIFIED_REGISTER,
  payload: {
    name,
    email,
    password,
    stripeId,
    duration,
    planId,
    paypalId,
    giftCode,
    shouldApplyDiscount,
    heardFrom,
    otherReasons,
    ipAddress
  },
});
