import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '../common/button'
import img from '../images/presskit-heroImg.png'
import DownlloadArrowIcon from '../images/DownlloadArrow';
import brands from '../images/brand-image.png';
import DirectContactIcon from '../images/DirectContactIcon';
import Slider from "react-slick";
import TestimonialIcon from '../images/TestimonialIcon';
import map from "lodash/map";
import slice from 'lodash/slice';
import shuffle from 'lodash/shuffle';
import filter from 'lodash/filter';
import axios from "axios";
import { baseUrl } from "../../helpers/api";
import parse from 'html-react-parser';

import { appEssentialFetch } from "../../actions";
import pushToSentry from '../../helpers/pushToSentry';
import { TransitionEaseInOne, TransitionTwo, TransitionThree, TransitionFour } from '../common/transitions';

const sliderRef = React.createRef();
const PressKitPage = (props) => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '330px',
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          centerPadding: '280px',
        }
      },
      {
        breakpoint: 1119,
        settings: {
          centerPadding: '240px',
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: '180px',
        }
      },
      {

        breakpoint: 767,
        settings: {
          centerPadding: '120px',
        }
      },
      {

        breakpoint: 575,
        settings: {
          centerPadding: '30px',
        }
      }
    ],
  };
  useEffect(() => {
    setState({
      ...state,
      isFetching: true
    });
    axios({
      method: 'GET',
      url: `${baseUrl}/api/v1/testimonials`,
    }).then((response) => {
      setState({
        ...state,
        isFetching: false,
        testimonials: slice(shuffle(filter(response.data.testimonial, (testimonialItem) => testimonialItem.featured)), 0, 8)
      });
    }).catch(e => {
      pushToSentry(e)
      setState({
        ...state,
        isFetching: false,
        error: true
      });
    })
    props.appEssentialFetch();
  }, []);
  const [state, setState] = useState({
    isFetching: false,
    testimonials: [],
    error: false
  })

  useEffect(() => {
    if (state.testimonials.length > 0) {
      // no way to handle this
      setTimeout(() => {
        if (sliderRef && sliderRef.current) {
          sliderRef.current.slickGoTo(0)
        }
      }, 500)

    }
  }, [state.testimonials.length])

  const renderDropdownArrow = () =>
    <span className="btn-download" onClick={() => { window.open("https://share.getcloudapp.com/rRu6Q0An", '_blank'); }}>
      Download Full Press Kit PDF
      <DownlloadArrowIcon />
    </span>
  return (
    <>
      <Helmet>
        <title>Press Kit | Dashing Dish</title>
        <meta name="description" content="Dashingdish Press Kit page" />
      </Helmet>
      <TransitionEaseInOne>
        <section
          className="press-kit-hero"
          style={{
            backgroundImage: `url(${img})`
          }}>
          <div className="container">
            <div className="press-kit-hero-wrapper">
              <h2>Dashing Dish Press Kit</h2>
              <p>Get to know the Dashing Dish story and what makes us different than the other healthy lifestyle brands out there!</p>
              <Button
                btnClass="btn btn-primary btn-md"
                btnText={renderDropdownArrow()}
                onClick={() => {
                }} />
            </div>
          </div>
          <div className="direct-wrapper">
            <a href={'/contact'} className="direct">
              <figure className="direct-inner">
                {/* <DirectIcon></DirectIcon> */}
                <DirectContactIcon></DirectContactIcon>
              </figure>
            </a>
          </div>
        </section>
      </TransitionEaseInOne>
      <main
        className="press-kit-page">
        <section className="dashing-dish-note-wrapper">
          <div className="container">
            <TransitionTwo>
              <div className="dashing-dish-note">
                <p><b>Dashing Dish</b> is a healthy lifestyle subscription service. It combines recipes, workouts, meal planning, and devotionals
              in an app dedicated to helping people make lasting healthy choices.</p>
              </div>
            </TransitionTwo>
            <TransitionThree>
              <div className="dashing-dish-card">
                <a href="https://share.getcloudapp.com/RBuXA1Kg" target="_blank" class="card">
                  <h5>Brand/ Logo <br></br>
                    Assets</h5>
                  <p>These assets include: Dashing Dish full type logo, circle D logo, and colors.</p>
                  <div className="download-wrapper">
                    <a href="https://share.getcloudapp.com/RBuXA1Kg" target="_blank">
                      <span>
                        Download
                  </span>
                      <div className="download-link">
                        <figure>
                          <DownlloadArrowIcon></DownlloadArrowIcon>
                        </figure>
                      </div>
                    </a>
                  </div>
                </a>
                <a href="https://share.getcloudapp.com/L1ukbxK6" target="_blank" class="card">
                  <h5>App<br></br>
                    Screenshots</h5>
                  <p>Includes screenshots for recipes, meal plan calendar, and more.</p>
                  <div className="download-wrapper">
                    <a href="https://share.getcloudapp.com/L1ukbxK6" target="_blank">
                      <span>
                        Download
                  </span>
                      <div className="download-link">
                        <figure>
                          <DownlloadArrowIcon></DownlloadArrowIcon>
                        </figure>
                      </div>
                    </a>
                  </div>
                </a>
                <a href="https://share.getcloudapp.com/Jru7EzAW" target="_blank" class="card">
                  <h5>Lifestyle<br></br>
                    Photos</h5>
                  <p>Includes lifestyle photos featuring Katie, Dashing Dish kitchen, and more.</p>
                  <div className="download-wrapper">
                    <a href="https://share.getcloudapp.com/Jru7EzAW" target="_blank">
                      <span>
                        Download
                  </span>
                      <div className="download-link">
                        <figure>
                          <DownlloadArrowIcon></DownlloadArrowIcon>
                        </figure>
                      </div>
                    </a>
                  </div>
                </a>
              </div>
            </TransitionThree>
            <TransitionFour>
              <div className="partnering-wrapper">
                <ul>
                  <li>
                    <h4>26.5m</h4>
                    <small>All time <br></br>visits</small>
                  </li>
                  <li>
                    <h4>200k</h4>
                    <small>Avg monthly <br></br>views</small>
                  </li>
                  <li>
                    <h4>33.6k</h4>
                    <small>Instagram<br></br> followers</small>
                  </li>
                  <li>
                    <h4>70k</h4>
                    <small>Facebook <br></br>likes</small>
                  </li>
                  <li>
                    <h4>31k</h4>
                    <small>Pinterest<br></br>
                      pinners</small>
                  </li>
                </ul>
              </div>
            </TransitionFour>
          </div>
        </section>
        <section className="press-kit-testimonials">
          <div className="testimonial-wrapper">
            <Slider
              {...carouselSettings}
              centerMode={true}
              ref={sliderRef}

            >
              {
                map(state.testimonials, (testimonial, index) => {
                  return (
                    <div
                      className="testimonials-content"
                      onClick={() => {
                        sliderRef.current.slickGoTo(index)
                      }}
                    >
                      <figure>
                        <TestimonialIcon />
                      </figure>
                      {parse(testimonial.contents)}
                      <small>
                        {testimonial.author}
                      </small>
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        </section>
      </main>
    </>
  )
}

const mapStateToProps = () => ({})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(PressKitPage);