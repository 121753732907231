import { put, call, select, all } from "redux-saga/effects";

import isEqual from "lodash/isEqual";

import { getExistingRecipes } from "./selectors";
import * as actions from "../actions/actionTypes";
import recipeTask from "./subtask/recipes";
import pushToSentry from "../helpers/pushToSentry";

import retrier from "./process/retrier";

const getState = (state) => state.toJS();
export default function* fetchRecipeDetails(action) {
  // yield takeEvery(actions.GET_SINGLE_RECIPE_DETAILS, task)
  let response;
  try {
    const {
      payload: { recipeSlug },
    } = action;

    // If this recipe is already present in the list
    let recipeData;
    // this has to be done to get the categories and related recipes.

    const recipeArray = yield call(
      retrier,
      [call(recipeTask.get, recipeSlug)],
      "single recipe"
    );

    const recipeResponse = recipeArray[0];

    if (!recipeResponse) {
      const eventId = pushToSentry(
        "Internet connection seems to be down - single recipe"
      );
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: "Internet connection seems to be down",
          eventId,
        },
      });
    }

    if (recipeResponse.status === 403) {
      return {
        limitReached: true,
        error: "Limit reached",
        title: recipeResponse.data.title,
        images: recipeResponse.data.images,
        story: recipeResponse.data.story,
        variations: recipeResponse.data.variations,
        favoriteCount: recipeResponse.data.favorite_count,
      };
    }

    recipeData = recipeResponse.data.recipe;
    relatedRecipes = recipeResponse.data.related_recipes;
    tags = recipeResponse.data.tags;
    meteredCount = recipeResponse.data.metered_recipes;

    return {
      objectType: "recipe",
      updatedData: recipeData, // Will have recipes, tags and related recipes
      relatedRecipes,
      tags,
      meteredCount,
    };
  } catch (e) {
    yield put({
      type: actions.GET_SINGLE_RECIPE_DETAILS_FAILURE,
      payload: {
        error: e,
      },
    });
    if (e.response && e.response.status == 403) {
      return {
        limitReached: true,
        error: "Limit reached",
        title: e.response.data.title,
        images: e.response.data.images,
        story: e.response.data.recipe.story,
        variations: e.response.data.recipe.variations,
        favoriteCount: e.response.data.recipe.favorite_count,
      };
    } else {
      const getState = (state) => state.toJS();
      const state = yield select(getState);
      const eventId = pushToSentry(
        e,
        state,
        "Fetch recipe error",
        JSON.stringify(state.objectSpecificEssential)
      );

      return {
        objectType: "recipe",
        updatedData: {}, // Will have recipes, tags and related recipes
        relatedRecipes: [],
        tags: [],
      };
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: e.message,
          eventId,
        },
      });
    }
  }
}
