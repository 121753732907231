import React, { useEffect, useState } from "react";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import ModalVideo from "./modalVideoLib";

import take from "lodash/take";
import capitalize from "lodash/capitalize";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import map from "lodash/map";
import concat from "lodash/concat";
import { Helmet } from "react-helmet";

import { fromJS } from "immutable";
// New imports
import { baseUrl } from "../../../helpers/api";
import pushToSentry from "../../../helpers/pushToSentry";

import {
  generateAppEssentialPayload,
  generateVideoQueryFromFilterForFrontend,
} from "../../../helpers/filterConversions";
import Loader from "../../images/loader";
import CloseableTag from "../../common/closeableTag";
import GridCard from "../../common/GridCard";
import { removeCharecterFromIndex } from "../../../helpers/converters";
import Button from "../../common/button";

import HorizontalListWithAction from "../../common/trendingChangeAction";

import filtersHOC from "./videofiltersHOC";
import VideoPlayIcon from "../../images/VideoPlayIcon";
import secondsToMinutes from "../../../helpers/secondsToMinutes";
import { isNewTagStatus } from "../../../helpers/getNewTagStatus";
import Modalwrapper from "../../common/modal";
import modalImg from "../../images/modal-katie-img.png";
import KatieSign from "../../images/KatieSign";
import { TransitionOne, TransitionTwo } from "../../common/transitions";

const Videos = ({
  appEssentialFetch,
  history,
  videos,
  deleteFavourite,
  isAuthenticated,
  loginRedirectHandler,
  toggleFavoriteModal,
  fetchVideos,
  moreVideoAvailable,
  fetchStatus,
  videoFilterName,
  appliedFilters,
  trendingVideos,
  addSentryError,
  changeFooterStatus,
  ...props
}) => {
  useEffect(() => {
    // Can be of few types
    // 1. /videos/favorites/:favoriteBoardSlug
    // 2. /videos/featured/:defaultDietary
    // 3. /videos/:defaultFodType
    // 4. /videos/?filters

    let favorite = {
      show: false,
      board: null,
    };

    const isFavorites =
      props.match.path === "/videos/favorites/:favoriteBoardSlug" ||
      props.match.path === "/videos/favorites";
    if (isFavorites) {
      const favoriteBoardSlug = props.match.params.favoriteBoardSlug
        ? props.match.params.favoriteBoardSlug
        : null;
      favorite.show = true;
      favorite.board = favoriteBoardSlug;
      const appEssentialPayload = generateAppEssentialPayload({
        objectType: "video",
        queryString: history.location.search,
        favorite,
        meta: {
          listType: "favorited",
          objectType: "video",
        },
      });
      appEssentialFetch(appEssentialPayload);
    } else {
      let listType = "unfiltered";

      if (isFavorites) {
        listType = "favorited";
      } else if (history.location.search.length !== 0) {
        listType = "filtered";
      }

      const appEssentialPayload = generateAppEssentialPayload({
        objectType: "video",
        queryString: history.location.search,
        favorite: {
          show: false,
          board: null,
        },
        meta: {
          objectType: "recipe",
          listType,
        },
      });
      appEssentialFetch(appEssentialPayload);
    }
  }, [history.location.search, props.match.path, props.match.url]);

  const [state, setState] = useState({
    showVideoModal: false,
    meteredBlock: false,
    youtubeId: "",
    vimeoId: "",
  });

  const playVideo = async (videoId, id, playerType) => {
    if (!isAuthenticated) {
      try {
        const response = await axios({
          url: `${baseUrl}/api/videos/gate-status?video_id=${id}`,
          headers: {
            Authorization: `Token gate="${Math.random()}"`,
          },
        });

        if (
          response.data.metered_videos <= 3 &&
          response.data.status !== "failed"
        ) {
          setState((prevState) => ({
            ...prevState,
            showVideoModal: true,
            vimeoId: playerType === "vimeo" ? videoId : "",
            youtubeId: playerType === "youtube" ? videoId : "",
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            showVideoModal: true,
            vimeoId,
            youtubeId,
            meteredBlock: true,
          }));
        }
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          showVideoModal: true,
          meteredBlock: true,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        showVideoModal: true,
        vimeoId: playerType === "vimeo" ? videoId : "",
        youtubeId: playerType === "youtube" ? videoId : "",
      }));
    }
  };

  const closeVideo = () => {
    setState((prevState) => ({
      ...prevState,
      showVideoModal: false,
      vimeoId: "",
      youtubeId: "",
    }));
  };

  const noVideosMessage = (message) => {
    return (
      <>
        <div className="pageMessage">
          {/* <h2>{message}</h2> */}
          <div className="empty-content">
            <h2>
              This category is as empty<br></br>
              as my plate after Thanksgiving.
            </h2>
            <p>
              Go to the{" "}
              <a
                href={"/videos"}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/videos");
                }}
              >
                {" "}
                Video index{" "}
              </a>{" "}
              to add some!
            </p>
          </div>
        </div>
      </>
    );
  };

  const renderVideoFilterTag = () => {
    const filters = filter(
      videoFilterName.filterArray,
      (filter) => filter.type !== "order"
    );
    return (
      <div className="filterTitle">
        {map(filters, (filter) => {
          return (
            <CloseableTag
              key={filter.slug}
              title={filter.name}
              onClose={() => {
                let updatedFilter;
                const updatedFilterGroup = appliedFilters[filter.type].filter(
                  (eachSlug) => eachSlug !== filter.slug
                );
                updatedFilter = {
                  ...appliedFilters,
                  [filter.type]: updatedFilterGroup,
                };
                history.push(
                  `/videos?${removeCharecterFromIndex(
                    generateVideoQueryFromFilterForFrontend(
                      fromJS(updatedFilter)
                    ),
                    0
                  )}`
                );
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderDynamicVideoHeader = () => {
    if (videoFilterName.type == "favorite") {
      return (
        <>
          <Helmet>
            <title>
              Videos{" "}
              {videoFilterName.title ? `in ${videoFilterName.title}` : ""} |
              Dashing Dish
            </title>
          </Helmet>
          <h3>{`${capitalize(videoFilterName.title)}`}</h3>
        </>
      );
    } else if (videoFilterName.type == "filter") {
      return renderVideoFilterTag();
    }
  };

  const renderMeta = (item) => {
    return (
      <>
        {item.duration ? (
          <span>{secondsToMinutes(parseInt(item.duration))}</span>
        ) : null}
      </>
    );
  };
  const noInitialFetchHappening =
    !fetchStatus.unfilteredListInitialTypeStatus.isFetching &&
    !fetchStatus.filteredListInitialTypeStatus.isFetching &&
    !fetchStatus.favoritedListInitialTypeStatus.isFetching &&
    !fetchStatus.featuredListInitialTypeStatus.isFetching;
  const noPaginationFetchHappening =
    !fetchStatus.unfilteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.filteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.favoritedListPaginationTypeStatus.isFetching &&
    !fetchStatus.featuredListPaginationTypeStatus.isFetching;

  const noFetchHappening =
    noInitialFetchHappening && noPaginationFetchHappening;

  let preloaderVideos = map(
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
    ],
    (item, index) => ({ isLoading: true, id: null })
  );

  const modifiedPreloadedVideos = noPaginationFetchHappening
    ? preloaderVideos
    : concat(videos, preloaderVideos);
  const actualVideos = !noFetchHappening ? modifiedPreloadedVideos : videos;

  return (
    <>
      <Helmet>
        <title>Videos | Dashing Dish</title>
        <meta name="description" content="Dashingdish Videos page" />
      </Helmet>
      <div className="container">
        <div className="filterName">{renderDynamicVideoHeader()}</div>
        {noFetchHappening && videos.length === 0 && !moreVideoAvailable
          ? noVideosMessage("No Videos")
          : null}
        <InfiniteScroll
          className="infiniteScroll"
          initialLoad={false}
          hasMore={moreVideoAvailable}
          loadMore={
            noFetchHappening
              ? () => {
                if (history.location.search) {
                  fetchVideos({ pagination: true, filterGroup: "filters" });
                } else if (
                  props.match.path ===
                  "/videos/favorites/:favoriteBoardSlug" ||
                  props.match.path === "/videos/favorites"
                ) {
                  fetchVideos({ pagination: true, filterGroup: "favorites" });
                } else {
                  fetchVideos({
                    pagination: true,
                    filterGroup: "unFiltered",
                  });
                }
              }
              : () => { }
          }
          loader={
            <div className="container">
              <div className="preLoader-minimal-height">
                <Loader></Loader>
              </div>
            </div>
          }
        >
          <TransitionOne>
            <ul className="videoList">
              <>
                {(actualVideos || []).map((item, index) => {
                  return (
                    <li key={index} className="video">
                      <GridCard
                        feature="video"
                        objectType="video"
                        history={history}
                        coverImage={
                          !isEmpty(item) && !isEmpty(item.images)
                            ? item.images.large
                            : ""
                        }
                        item={item}
                        deleteFavourite={deleteFavourite}
                        loginRedirect={loginRedirectHandler}
                        isAuthenticated={isAuthenticated}
                        toggleFavoriteModal={toggleFavoriteModal}
                        displayImage={
                          !isEmpty(item) && !isEmpty(item.images)
                            ? item.images.large
                            : ""
                        }
                        playVideo={playVideo}
                        closeVideo={closeVideo}
                        renderPreMeta={renderMeta(item)}
                        showTag={isNewTagStatus({ date: item.publish_date })}
                      />
                    </li>
                  );
                })}
              </>
            </ul>
          </TransitionOne>
          {!state.meteredBlock ? (
            <ModalVideo
              channel={state.vimeoId ? "vimeo" : "youtube"}
              isOpen={state.showVideoModal}
              videoId={state.vimeoId ? state.vimeoId : state.youtubeId}
              vimeo={{ autoplay: 1, autopause: 0 }}
              onClose={() => {
                document.body.classList.remove("ReactModal__Body--open");
                closeVideo();
              }}
            />
          ) : (
            <Modalwrapper
              minHeight="555px"
              maxWidth="500px"
              isOpen
              onRequestClose={() =>
                setState({
                  ...state,
                  meteredBlock: false,
                  showVideoModal: false,
                })
              }
            >
              <div className="modalWrapper paywall-modal">
                <div className="modal-image-wrapper">
                  <figure>
                    <img src={modalImg} className="img_fluid"></img>
                  </figure>
                </div>
                <div className="modal-body-wrapper">
                  <h3>Continue Viewing!</h3>
                  <p>
                    This video is available exclusively to Dashing Dish members.
                  </p>
                </div>
                <div className="modal-footer-wrapper">
                  <Button
                    btnClass="primary btn-full"
                    btnText="Signup"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("pricing");
                      props.onRequestClose();
                    }}
                  />
                  <p
                    onClick={() =>
                      setState({
                        ...state,
                        meteredBlock: false,
                        showVideoModal: false,
                      })
                    }
                  >
                    Cancel
                  </p>
                </div>
              </div>
            </Modalwrapper>
          )}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default filtersHOC(Videos);
