import createReducer from "../helpers/createReducers";
import { fromJS, List } from "immutable";
import findIndex from "lodash/findIndex";
import lowerCase from "lodash/lowerCase";
import shuffle from "lodash/shuffle";

import { globalFetchLimit } from "../helpers/filterConversions";

const initialState = fromJS({
  recipe: {
    foodTypes: [], // Food types are the older categories. Recipe filter - 1
    dietaries: [], // Dietaries are the older collections. Recipe filter - 2
    specialities: [], // Specialities are new. Recipe filter - 3
    collection: [], // Collections
    unFilteredList: {
      // Unfiltered list data.
      list: [], // Holds the list
      offset: 0, // Current offset in count
      moreRecipesAvailable: true, // Says when to refetch and when not
    },
    filteredList: {
      // This will be one of favorites / filters / search
      list: [],
      offset: 0,
      moreRecipesAvailable: true,
    },
    cachedList: {
      list: [],
    },
    featuredCollectionList: {
      list: [],
      offset: 0,
      moreRecipesAvailable: true,
    },
    featured: {
      // Seperate list to hold the featured
      show: false,
      taxonomy: null,
      slug: null,
    },
    filters: {
      // Describes the current filter state
      foodTypes: [],
      dietaries: [],
      specialities: [],
      collection: [],
      fp: ["0", "15"],
      sp: ["0", "15"],
      wwp: ["0", "15"],
      search: [],
      search_by: [],
      order: [],
    },
    filterSetUpdatedPrematurely: false, // For mobile, when filters are applied before fetching
    // Hold the key in terms of what to fetch
    // Comes through SET_NEW_FILTERS
    //search
    searchList: {
      // holds the temporary search list when we type
      count: null, // count needed to manage
      data: [],
    },
    searchApplied: false,
    dirtyGroup: null, // Represents what is dirty and what to fetch
    filterGroup: null, // Represents what is the last one
    favorite: {
      // Represents the favorites
      show: false,
      board: null, // Which favorite board should be fetched for (in filtered list)
    },

    forUser: null, // TODO: re-think needed (login/logout issue)
    fetchedOnce: false,
    temporaryFilteredCount: null, // For mobile count in filters
  },
  workout: {
    must: false,
    workoutTypes: [],
    equipments: [],
    bodyParts: [],
    difficulty: [],
    collection: [],
    time: [],
    cachedList: {
      list: [],
    },
    unFilteredList: {
      list: [],
      offset: 0, // Limit not required as it is a global config
      moreWorkoutsAvailable: true,
      // Offset for non fav apis are in terms of units (0, 25) whereas favorites is in terms of (0, 1)
    },
    filteredList: {
      list: [],
      offset: 0, // Limit not required as it is a global config
      moreWorkoutsAvailable: true,
      // Offset for non fav apis are in terms of units (0, 25) whereas favorites is in terms of (0, 1)
    },
    featuredCollectionList: {
      list: [],
      offset: 0, // Limit not required as it is a global config
      moreWorkoutsAvailable: true,
    },
    limit: globalFetchLimit,
    filters: {
      // Param based filter
      workoutTypes: [],
      equipments: [],
      bodyParts: [],
      difficulty: [],
      time: [],
      collection: [],
      search: [],
      search_by: [],
      order: [],
    },
    featured: {
      // Seperate list to hold the featured
      show: false,
      taxonomy: null,
      slug: null,
    },
    filterSetUpdatedPrematurely: false,
    //search
    searchList: {
      // holds the temporary search list when we type
      count: null, // count needed to manage
      data: [],
    },
    dirtyGroup: null,
    favorite: {
      show: false,
      board: null,
    },
    temporaryFilteredCount: null,
    forUser: null, // TODO: re-think needed (login/logout issue)
  },
  blog: {
    categories: [],
    archives: [],
    unFilteredList: {
      // Unfiltered list data.
      list: [], // Holds the list
      offset: 0, // Current offset in count
      moreBlogsAvailable: true, // Says when to refetch and when not
    },
    filteredList: {
      // This will be one of favorites / filters / search
      list: [],
      offset: 0,
      moreBlogsAvailable: true,
    },
    cachedList: {
      list: [],
    },
    featuredList: {
      list: [],
      offset: 0,
      moreBlogsAvailable: true,
    },
    filters: {
      // Describes the current filter state
      categories: [],
      archives: [],
      search: [],
      search_by: [],
      order: [],
    },
    filterSetUpdatedPrematurely: false, // For mobile, when filters are applied before fetching
    // Hold the key in terms of what to fetch
    // Comes through SET_NEW_FILTERS
    //search
    searchList: {
      // holds the temporary search list when we type
      count: null, // count needed to manage
      data: [],
    },
    searchApplied: false,
    dirtyGroup: null, // Represents what is dirty and what to fetch
    filterGroup: null, // Represents what is the last one
    favorite: {
      // Represents the favorites
      show: false,
      board: null, // Which favorite board should be fetched for (in filtered list)
    },
    forUser: null, // TODO: re-think needed (login/logout issue)
    fetchedOnce: false,
    temporaryFilteredCount: null, // For mobile count in filters
  },
  video: {
    categories: [],
    collections: [],
    unFilteredList: {
      // Unfiltered list data.
      list: [], // Holds the list
      offset: 0, // Current offset in count
      moreVideosAvailable: true, // Says when to refetch and when not
    },
    filteredList: {
      // This will be one of favorites / filters / search
      list: [],
      offset: 0,
      moreVideosAvailable: true,
    },
    cachedList: {
      list: [],
    },
    featuredList: {
      list: [],
      offset: 0,
      moreVideosAvailable: true,
    },
    filters: {
      // Describes the current filter state
      categories: [],
      collections: [],
      search: [],
      search_by: [],
      order: [],
    },
    filterSetUpdatedPrematurely: false, // For mobile, when filters are applied before fetching
    // Hold the key in terms of what to fetch
    // Comes through SET_NEW_FILTERS
    //search
    searchList: {
      // holds the temporary search list when we type
      count: null, // count needed to manage
      data: [],
    },
    searchApplied: false,
    dirtyGroup: null, // Represents what is dirty and what to fetch
    filterGroup: null, // Represents what is the last one
    favorite: {
      // Represents the favorites
      show: false,
      board: null, // Which favorite board should be fetched for (in filtered list)
    },
    forUser: null, // TODO: re-think needed (login/logout issue)
    fetchedOnce: false,
    temporaryFilteredCount: null, // For mobile count in filters
  },
  faqs: {
    list: [],
  },
  trending: {
    recipes: {
      data: [],
      type: "",
    },

    workouts: {
      data: [],
      type: "",
    },
    blogs: {
      data: [],
      type: "",
    },
    videos: {
      data: [],
      type: "",
    },
  },
  plans: [],
  dishlist: {
    categories: [],
    list: [],
  },
  mealPlanCategories: [],
});

export default createReducer(initialState, {
  APP_ESSENTIAL_FETCH: (state, action) => {
    return state;
  },
  LOGOUT_NAVBAR_SUCCESS: (state) => {
    // Bring the list data to initial state
    return initialState;
  },
  LOGIN_USER: (state) => {
    // On login too. This is for first time.
    return initialState;
  },
  // recipe collection and category
  RECIPE_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const {
      foodTypes,
      dietaries,
      specialities,
      collections,
    } = action.payload.recipePrefetchEssentials;
    state = state.setIn(["recipe", "foodTypes"], fromJS(foodTypes));
    state = state.setIn(["recipe", "dietaries"], fromJS(dietaries));
    state = state.setIn(["recipe", "specialities"], fromJS(specialities));
    state = state.setIn(["recipe", "collection"], fromJS(shuffle(collections)));
    return state;
  },

  RECIPES_LIST_FETCH_REQUEST: (state, action) => {
    // If not pagination, clear the recipes so that the preloader loads
    // This is only when the request fetches completely new
    if (!action.payload || (action.payload && !action.payload.pagination)) {
      // And also pagination
      const existingFilteredList = state.getIn([
        "recipe",
        "filteredList",
        "list",
      ]);

      state = state.setIn(
        ["recipe", "cachedList", "list"],
        List(existingFilteredList)
      );

      state = state.setIn(["recipe", "filteredList", "list"], List());
      state = state.setIn(["recipe", "filteredList", "offset"], 0);
      state = state.setIn(
        ["recipe", "filteredList", "moreRecipesAvailable"],
        true
      );

      state = state.setIn(["recipe", "featuredCollectionList", "list"], List());
      state = state.setIn(["recipe", "featuredCollectionList", "offset"], 0);
      state = state.setIn(
        ["recipe", "featuredCollectionList", "moreRecipesAvailable"],
        true
      );
    }
    return state;
  },
  TRENDING_ESSENTIALS_FETCH: (state, action) => {
    return state;
  },
  TRENDING_ESSENTIALS_FETCH_SUCCESS: (state, action) => {
    state = state.setIn(["trending", "recipes", "type"], "day");
    state = state.setIn(
      ["trending", "recipes", "data"],
      fromJS(action.payload.recipes)
    );

    state = state.setIn(["trending", "workouts", "type"], "day");
    state = state.setIn(
      ["trending", "workouts", "data"],
      fromJS(action.payload.workouts)
    );

    state = state.setIn(["trending", "videos", "type"], "day");
    state = state.setIn(
      ["trending", "videos", "data"],
      fromJS(action.payload.videos)
    );

    state = state.setIn(["trending", "blogs", "type"], "day");
    state = state.setIn(
      ["trending", "blogs", "data"],
      fromJS(action.payload.blogs)
    );

    return state;
  },
  TRENDING_TYPE_CHANGE_SUCCESS: (state, action) => {
    state = state.setIn(
      ["trending", `${action.payload.objectType}s`, "type"],
      action.payload.trendingType
    );
    state = state.setIn(
      ["trending", `${action.payload.objectType}s`, "data"],
      fromJS(action.payload.data)
    );

    return state;
  },
  // recipe list data
  RECIPES_LIST_FETCH_SUCCESS: (state, action) => {
    const { forUser, recipesData = {}, pagination } = action.payload;

    // reset dirtyGroup and filterGroup
    state = state.setIn(["recipe", "dirtyGroup"], null);

    // Type will be just for fetch status boundary
    if (!action.payload.type) {
      // Set filtered
      // concat when paginated
      if (
        recipesData &&
        recipesData.filteredRecipesData &&
        recipesData.filteredRecipesData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["recipe", "filteredList", "list"],
            state
              .getIn(["recipe", "filteredList", "list"])
              .concat(fromJS(recipesData.filteredRecipesData))
          );
        } else {
          state = state.setIn(
            ["recipe", "filteredList", "list"],
            fromJS(recipesData.filteredRecipesData)
          );
        }
        state = state.setIn(
          ["recipe", "filteredList", "offset"],
          state.getIn(["recipe", "filteredList", "offset"]) + 1
        );

        // Set more recipes available to false when it is less than global fetch limit
        if (recipesData.filteredRecipesData.length < globalFetchLimit) {
          state = state.setIn(
            ["recipe", "filteredList", "moreRecipesAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["recipe", "filteredList", "moreRecipesAvailable"],
            true
          );
        }
      } else if (
        recipesData &&
        recipesData.filteredRecipesData &&
        recipesData.filteredRecipesData.length === 0
      ) {
        state = state.setIn(
          ["recipe", "filteredList", "moreRecipesAvailable"],
          false
        );
      }

      // unfiltered list
      if (
        recipesData &&
        recipesData.unfilteredRecipesData &&
        recipesData.unfilteredRecipesData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["recipe", "unFilteredList", "list"],
            state
              .getIn(["recipe", "unFilteredList", "list"])
              .concat(fromJS(recipesData.unfilteredRecipesData))
          );
        } else {
          state = state.setIn(
            ["recipe", "unFilteredList", "list"],
            fromJS(recipesData.unfilteredRecipesData)
          );
        }
        state = state.setIn(
          ["recipe", "unFilteredList", "offset"],
          state.getIn(["recipe", "unFilteredList", "offset"]) + 1
        );

        if (
          recipesData &&
          recipesData.unfilteredRecipesData &&
          recipesData.unfilteredRecipesData.length < globalFetchLimit
        ) {
          state = state.setIn(
            ["recipe", "unFilteredList", "moreRecipesAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["recipe", "unFilteredList", "moreRecipesAvailable"],
            true
          );
        }
      } else if (
        recipesData &&
        recipesData.unfilteredRecipesData &&
        recipesData.unfilteredRecipesData.length === 0
      ) {
        state = state.setIn(
          ["recipe", "unFilteredList", "moreRecipesAvailable"],
          false
        );
      }

      // default Featured data
      if (
        recipesData &&
        recipesData.featuredCollectionList &&
        recipesData.featuredCollectionList.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["recipe", "featuredCollectionList", "list"],
            state
              .getIn(["recipe", "featuredCollectionList", "list"])
              .concat(fromJS(recipesData.featuredCollectionList))
          );
        } else {
          state = state.setIn(
            ["recipe", "featuredCollectionList", "list"],
            fromJS(recipesData.featuredCollectionList)
          );
        }
        state = state.setIn(
          ["recipe", "featuredCollectionList", "offset"],
          state.getIn(["recipe", "featuredCollectionList", "offset"]) + 1
        );

        if (
          recipesData &&
          recipesData.featuredCollectionList &&
          recipesData.featuredCollectionList.length < globalFetchLimit
        ) {
          state = state.setIn(
            ["recipe", "featuredCollectionList", "moreRecipesAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["recipe", "featuredCollectionList", "moreRecipesAvailable"],
            true
          );
        }
      } else if (
        recipesData &&
        recipesData.featuredCollectionList &&
        recipesData.featuredCollectionList.length === 0
      ) {
        state = state.setIn(
          ["recipe", "featuredCollectionList", "moreRecipesAvailable"],
          false
        );
      }

      state = state.setIn(["recipe", "forUser"], forUser);
      state = state.setIn(["recipe", "fetchedOnce"], true);
    }

    return state;
  },

  // Will be happening only for mobile
  SET_FILTERS_REQUEST: (state, action) => {
    const { recipe, workout, video, blog, objectType } = action.payload;

    if (objectType === "recipe") {
      // Set the filters immediately so that tags get updated
      state = state.setIn([objectType, "filters"], fromJS(recipe.filters));
    } else if (objectType === "workout") {
      state = state.setIn([objectType, "filters"], fromJS(workout.filters));
    } else if (objectType === "video") {
      state = state.setIn([objectType, "filters"], fromJS(video.filters));
    } else if (objectType === "blog") {
      state = state.setIn([objectType, "filters"], fromJS(blog.filters));
    }

    // Mark this since filter comparision will ignore same filters due to premature setting
    state = state.setIn([objectType, "filterSetUpdatedPrematurely"], true);
    return state;
  },
  // filter paramerters
  SET_NEW_FILTERS_SUCCESS: (state, action) => {
    const {
      favorite,
      filters,
      defaultDietary,
      featured,
      objectType,
      dirtyGroup,
      searchApplied,
    } = action.payload;

    // If filters are changed due to filter modal / search
    if (filters) {
      state = state.setIn([objectType, "filters"], fromJS(filters));
    }

    // If filters are due to t featured
    if (defaultDietary) {
      state = state.setIn(
        [objectType, "defaultDietary"],
        fromJS(defaultDietary)
      );
      state = state.setIn([objectType, "defaultDietaryList", "offset"], 0);
    }

    if (favorite) {
      state = state.setIn([objectType, "favorite", "show"], favorite.show);
      state = state.setIn([objectType, "favorite", "board"], favorite.board);
    }

    if (featured) {
      state = state.setIn([objectType, "featured", "show"], featured.show);
      state = state.setIn(
        [objectType, "featured", "taxonomy"],
        featured.taxonomy
      );
      state = state.setIn([objectType, "featured", "slug"], featured.slug);
      state = state.setIn([objectType, "featuredCollectionList", "offset"], 0);
    }

    // Triggered only for favorites.
    // Since when you go to the same board from board selection screen
    // Fitler comparision will not recognize without this
    if (action.payload.triggered) {
      state = state.setIn([objectType, "favorite", "show"], false);
      state = state.setIn([objectType, "favorite", "board"], null);
    }

    state = state.setIn([objectType, "dirtyGroup"], dirtyGroup);
    state = state.setIn([objectType, "filteredList", "offset"], 0);

    state = state.setIn([objectType, "searchApplied"], searchApplied);

    // Clean this otherwise filters wont work
    state = state.setIn([objectType, "filterSetUpdatedPrematurely"], false);

    return state;
  },

  // For fav icon reactivity in all recipies page
  CREATE_FAVOURITE_TO_OBJECT_SUCCESS: (state, action) => {
    let objectType = action.payload.objectType;

    if (objectType !== "meal_plan") {
      // Update in trending
      const updatedTrending = state
        .getIn(["trending", `${objectType}s`, "data"])
        .toSeq()
        .map((item) => {
          if (action.payload.favouriteObject.object_id === item.getIn(["id"])) {
            item = item.setIn(["favorite"], true);
            item = item.setIn(
              ["favorite_boards"],
              fromJS(action.payload.favouriteObject.boards)
            );
          }
          return item;
        })
        .toList();
      state = state.setIn(
        ["trending", `${objectType}s`, "data"],
        updatedTrending
      );

      // Update in unfiltered list
      const updatedUnfilteredList = state
        .getIn([objectType, "unFilteredList", "list"])
        .toSeq()
        .map((item) => {
          if (action.payload.favouriteObject.object_id === item.getIn(["id"])) {
            item = item.setIn(["favorite"], true);
            item = item.setIn(
              ["favorite_boards"],
              fromJS(action.payload.favouriteObject.boards)
            );
          }
          return item;
        })
        .toList();
      state = state.setIn(
        [objectType, "unFilteredList", "list"],
        updatedUnfilteredList
      );

      // Update in filtered list
      const updatedFilteredList = state
        .getIn([objectType, "filteredList", "list"])
        .toSeq()
        .map((item) => {
          if (action.payload.favouriteObject.object_id === item.getIn(["id"])) {
            item = item.setIn(["favorite"], true);
            item = item.setIn(
              ["favorite_boards"],
              fromJS(action.payload.favouriteObject.boards)
            );
          }
          return item;
        })
        .toList();
      state = state.setIn(
        [objectType, "filteredList", "list"],
        updatedFilteredList
      );

      // Default dietary is only for recipe
      if (objectType === "recipe") {
        // Update in default dietary list
        const updatedFeaturedCollection = state
          .getIn([objectType, "featuredCollectionList", "list"])
          .toSeq()
          .map((item) => {
            if (
              action.payload.favouriteObject.object_id === item.getIn(["id"])
            ) {
              item = item.setIn(["favorite"], true);
              item = item.setIn(
                ["favorite_boards"],
                fromJS(action.payload.favouriteObject.boards)
              );
            }
            return item;
          })
          .toList();
        state = state.setIn(
          [objectType, "featuredCollectionList", "list"],
          updatedFeaturedCollection
        );
      }

      if (objectType === "workout") {
        const updatedFeaturedList = state
          .getIn([objectType, "featuredCollectionList", "list"])
          .toSeq()
          .map((item) => {
            if (
              action.payload.favouriteObject.object_id === item.getIn(["id"])
            ) {
              item = item.setIn(["favorite"], true);
              item = item.setIn(
                ["favorite_boards"],
                fromJS(action.payload.favouriteObject.boards)
              );
            }
            return item;
          })
          .toList();
        state = state.setIn(
          [objectType, "featuredCollectionList", "list"],
          updatedFeaturedList
        );
      }

      if (objectType === "blog") {
        // Update in default dietary list
        const updatedFeaturedList = state
          .getIn([objectType, "featuredList", "list"])
          .toSeq()
          .map((item) => {
            if (
              action.payload.favouriteObject.object_id === item.getIn(["id"])
            ) {
              item = item.setIn(["favorite"], true);
              item = item.setIn(
                ["favorite_boards"],
                fromJS(action.payload.favouriteObject.boards)
              );
            }
            return item;
          })
          .toList();
        state = state.setIn(
          [objectType, "featuredList", "list"],
          updatedFeaturedList
        );
      }
    }
    return state;
  },

  // For fav icon reactivity in all recipies page
  DELETE_FAVOURITE_SUCCESS: (state, action) => {
    if (action.payload.objectType !== "meal_plan") {
      const updatedTrending = state
        .getIn(["trending", `${action.payload.objectType}s`, "data"])
        .toSeq()
        .map((object) => {
          if (action.payload.objectId === object.getIn(["id"])) {
            if (action.payload.allBoards) {
              object = object.setIn(["favorite"], false);
            } else {
            }
          }
          return object;
        })
        .toList();
      state = state.setIn(
        ["trending", `${action.payload.objectType}s`, "data"],
        updatedTrending
      );

      const updatedUnfilteredList = state
        .getIn([action.payload.objectType, "unFilteredList", "list"])
        .toSeq()
        .map((object) => {
          if (action.payload.objectId === object.getIn(["id"])) {
            if (action.payload.allBoards) {
              object = object.setIn(["favorite"], false);
            } else {
            }
          }
          return object;
        })
        .toList();
      state = state.setIn(
        [action.payload.objectType, "unFilteredList", "list"],
        updatedUnfilteredList
      );

      // if delete request is from favorite page remove from list
      if (action.payload.filterList) {
        const newObjectList = state
          .getIn([action.payload.objectType, "filteredList", "list"])
          .filter((item) => {
            if (item.getIn(["id"]) !== action.payload.objectId) {
              return item;
            }
          });
        state = state.setIn(
          [action.payload.objectType, "filteredList", "list"],
          newObjectList
        );
      }

      const updatedFilteredList = state
        .getIn([action.payload.objectType, "filteredList", "list"])
        .toSeq()
        .map((object) => {
          if (action.payload.objectId === object.getIn(["id"])) {
            object = object.setIn(["favorite"], false);
          }
          return object;
        })
        .toList();
      state = state.setIn(
        [action.payload.objectType, "filteredList", "list"],
        updatedFilteredList
      );

      if (action.payload.objectType === "recipe") {
        const updatedFeaturedCollection = state
          .getIn([action.payload.objectType, "featuredCollectionList", "list"])
          .toSeq()
          .map((object) => {
            if (action.payload.objectId === object.getIn(["id"])) {
              object = object.setIn(["favorite"], false);
            }
            return object;
          })
          .toList();
        state = state.setIn(
          [action.payload.objectType, "featuredCollectionList", "list"],
          updatedFeaturedCollection
        );
      }

      if (action.payload.objectType === "workout") {
        const updatedFeaturedList = state
          .getIn([action.payload.objectType, "featuredCollectionList", "list"])
          .toSeq()
          .map((object) => {
            if (action.payload.objectId === object.getIn(["id"])) {
              object = object.setIn(["favorite"], false);
            }
            return object;
          })
          .toList();
        state = state.setIn(
          [action.payload.objectType, "featuredCollectionList", "list"],
          updatedFeaturedList
        );
      }

      if (action.payload.objectType === "blog") {
        const updatedFeaturedList = state
          .getIn([action.payload.objectType, "featuredList", "list"])
          .toSeq()
          .map((object) => {
            if (action.payload.objectId === object.getIn(["id"])) {
              object = object.setIn(["favorite"], false);
            }
            return object;
          })
          .toList();
        state = state.setIn(
          [action.payload.objectType, "featuredList", "list"],
          updatedFeaturedList
        );
      }

      if (action.payload.objectType === "video") {
        const updatedFeaturedList = state
          .getIn([action.payload.objectType, "featuredList", "list"])
          .toSeq()
          .map((object) => {
            if (action.payload.objectId === object.getIn(["id"])) {
              object = object.setIn(["favorite"], false);
            }
            return object;
          })
          .toList();
        state = state.setIn(
          [action.payload.objectType, "featuredList", "list"],
          updatedFeaturedList
        );
      }
    }

    if (action.payload.objectType === "video") {
      const updatedFeaturedList = state
        .getIn([action.payload.objectType, "featuredList", "list"])
        .toSeq()
        .map((object) => {
          if (action.payload.objectId === object.getIn(["id"])) {
            object = object.setIn(["favorite"], false);
          }
          return object;
        })
        .toList();
      state = state.setIn(
        [action.payload.objectType, "featuredList", "list"],
        updatedFeaturedList
      );
    }

    return state;
  },

  // For fav icon reactivity in all recipies page
  CREATE_NOTE_TO_OBJECT_SUCCESS: (state, action) => {
    let objectType = action.payload.objectType;

    // Update in trending
    const updatedTrending = state
      .getIn(["trending", `${objectType}s`, "data"])
      .toSeq()
      .map((item) => {
        if (action.payload.objectId === item.getIn(["id"])) {
          if (action.payload.noteId && action.payload.content.length === 0) {
            item = item.setIn(["notes"], false);
          } else {
            item = item.setIn(["notes"], true);
          }
        }
        return item;
      })
      .toList();
    state = state.setIn(
      ["trending", `${objectType}s`, "data"],
      updatedTrending
    );

    // Update in unfiltered list
    const updatedUnfilteredList = state
      .getIn([objectType, "unFilteredList", "list"])
      .toSeq()
      .map((item) => {
        if (action.payload.objectId === item.getIn(["id"])) {
          if (action.payload.noteId && action.payload.content.length === 0) {
            item = item.setIn(["notes"], false);
          } else {
            item = item.setIn(["notes"], true);
          }
        }
        return item;
      })
      .toList();
    state = state.setIn(
      [objectType, "unFilteredList", "list"],
      updatedUnfilteredList
    );

    // Update in filtered list
    const updatedFilteredList = state
      .getIn([objectType, "filteredList", "list"])
      .toSeq()
      .map((item) => {
        if (action.payload.objectId === item.getIn(["id"])) {
          if (action.payload.noteId && action.payload.content.length === 0) {
            item = item.setIn(["notes"], false);
          } else {
            item = item.setIn(["notes"], true);
          }
        }
        return item;
      })
      .toList();
    state = state.setIn(
      [objectType, "filteredList", "list"],
      updatedFilteredList
    );

    // Default dietary is only for recipe
    if (objectType === "recipe") {
      // Update in default dietary list
      const updatedFeaturedCollection = state
        .getIn([objectType, "featuredCollectionList", "list"])
        .toSeq()
        .map((item) => {
          if (action.payload.objectId === item.getIn(["id"])) {
            if (action.payload.noteId && action.payload.content.length === 0) {
              item = item.setIn(["notes"], false);
            } else {
              item = item.setIn(["notes"], true);
            }
          }
          return item;
        })
        .toList();
      state = state.setIn(
        [objectType, "featuredCollectionList", "list"],
        updatedFeaturedCollection
      );
    }

    if (objectType === "workout") {
      const updatedFeaturedList = state
        .getIn([objectType, "featuredCollectionList", "list"])
        .toSeq()
        .map((item) => {
          if (action.payload.objectId === item.getIn(["id"])) {
            if (action.payload.noteId && action.payload.content.length === 0) {
              item = item.setIn(["notes"], false);
            } else {
              item = item.setIn(["notes"], true);
            }
          }
          return item;
        })
        .toList();
      state = state.setIn(
        [objectType, "featuredCollectionList", "list"],
        updatedFeaturedList
      );
    }
    return state;
  },

  RECIPE_FILTER_COUNT: (state, action) => {
    state = state.setIn(["recipe", "temporaryFilteredCount"], null);
    return state;
  },

  RECIPE_FILTER_COUNT_SUCCESS: (state, action) => {
    const {
      payload: { totalCount = 0 },
    } = action;

    state = state.setIn(["recipe", "temporaryFilteredCount"], totalCount);
    return state;
  },

  SEARCH_REQUEST_SUCCESS: (state, action) => {
    const { objectType, searchList, total_count } = action.payload;

    state = state.setIn([objectType, "searchList"], searchList);
    state = state.setIn([objectType, "searchTotalCount"], total_count);
    return state;
  },

  // workout collection and category
  WORKOUT_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const {
      workoutTypes,
      equipments,
      bodyParts,
      collections,
    } = action.payload.workoutPrefetchEssentials;
    state = state.setIn(["workout", "workoutTypes"], fromJS(workoutTypes));
    state = state.setIn(["workout", "equipments"], fromJS(equipments));
    state = state.setIn(["workout", "bodyParts"], fromJS(bodyParts));
    state = state.setIn(
      ["workout", "collection"],
      fromJS(shuffle(collections))
    );
    return state;
  },
  WORKOUTS_LIST_FETCH_REQUEST: (state, action) => {
    // If not pagination, clear the recipes so that the preloader loads
    // This is only when the request fetches completely new
    if (!action.payload || (action.payload && !action.payload.pagination)) {
      // And also pagination
      const existingFilteredList = state.getIn([
        "workout",
        "filteredList",
        "list",
      ]);

      state = state.setIn(["workout", "filteredList", "list"], List());
      state = state.setIn(["workout", "filteredList", "offset"], 0);
      state = state.setIn(
        ["workout", "filteredList", "moreWorkoutsAvailable"],
        true
      );

      state = state.setIn(
        ["workout", "featuredCollectionList", "list"],
        List()
      );
      state = state.setIn(["workout", "featuredCollectionList", "offset"], 0);
      state = state.setIn(
        ["workout", "featuredCollectionList", "moreWorkoutsAvailable"],
        true
      );

      state = state.setIn(
        ["workout", "cachedList", "list"],
        List(existingFilteredList)
      );
    }
    return state;
  },
  // workout list data
  WORKOUTS_LIST_FETCH_SUCCESS: (state, action) => {
    const { forUser, workoutsData = {}, pagination } = action.payload;

    // Type will be just for fetch status boundary
    if (!action.payload.type) {
      // Set filtered
      // concat when paginated
      if (
        workoutsData &&
        workoutsData.filteredWorkoutsData &&
        workoutsData.filteredWorkoutsData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["workout", "filteredList", "list"],
            state
              .getIn(["workout", "filteredList", "list"])
              .concat(fromJS(workoutsData.filteredWorkoutsData))
          );
        } else {
          state = state.setIn(
            ["workout", "filteredList", "list"],
            fromJS(workoutsData.filteredWorkoutsData)
          );
        }
        state = state.setIn(
          ["workout", "filteredList", "offset"],
          state.getIn(["workout", "filteredList", "offset"]) + 1
        );

        // Set more workouts available to false when it is less than global fetch limit
        if (workoutsData.filteredWorkoutsData.length < globalFetchLimit) {
          state = state.setIn(
            ["workout", "filteredList", "moreWorkoutsAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["workout", "filteredList", "moreWorkoutsAvailable"],
            true
          );
        }
      } else if (
        workoutsData &&
        workoutsData.filteredWorkoutsData &&
        workoutsData.filteredWorkoutsData.length === 0
      ) {
        state = state.setIn(
          ["workout", "filteredList", "moreWorkoutsAvailable"],
          false
        );
      }

      // unfiltered list
      if (
        workoutsData &&
        workoutsData.unfilteredWorkoutsData &&
        workoutsData.unfilteredWorkoutsData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["workout", "unFilteredList", "list"],
            state
              .getIn(["workout", "unFilteredList", "list"])
              .concat(fromJS(workoutsData.unfilteredWorkoutsData))
          );
        } else {
          state = state.setIn(
            ["workout", "unFilteredList", "list"],
            fromJS(workoutsData.unfilteredWorkoutsData)
          );
        }
        state = state.setIn(
          ["workout", "unFilteredList", "offset"],
          state.getIn(["workout", "unFilteredList", "offset"]) + 1
        );

        if (
          workoutsData &&
          workoutsData.unfilteredWorkoutsData &&
          workoutsData.unfilteredWorkoutsData.length < globalFetchLimit
        ) {
          state = state.setIn(
            ["workout", "unFilteredList", "moreWorkoutsAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["workout", "unFilteredList", "moreWorkoutsAvailable"],
            true
          );
        }
      } else if (
        workoutsData &&
        workoutsData.unfilteredWorkoutsData &&
        workoutsData.unfilteredWorkoutsData.length === 0
      ) {
        state = state.setIn(
          ["workout", "unFilteredList", "moreWorkoutsAvailable"],
          false
        );
      }

      // default Featured data
      if (
        workoutsData &&
        workoutsData.featuredWorkoutsData &&
        workoutsData.featuredWorkoutsData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["workout", "featuredCollection", "list"],
            state
              .getIn(["workout", "featuredCollection", "list"])
              .concat(fromJS(workoutsData.featuredWorkoutsData))
          );
        } else {
          state = state.setIn(
            ["workout", "featuredCollection", "list"],
            fromJS(workoutsData.featuredWorkoutsData)
          );
        }
        state = state.setIn(
          ["workout", "featuredCollection", "offset"],
          state.getIn(["workout", "featuredCollection", "offset"]) + 1
        );

        if (
          workoutsData &&
          workoutsData.featuredWorkoutsData &&
          workoutsData.featuredWorkoutsData.length < globalFetchLimit
        ) {
          state = state.setIn(
            ["workout", "featuredCollection", "moreWorkoutsAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["workout", "featuredCollection", "moreWorkoutsAvailable"],
            true
          );
        }
      } else if (
        workoutsData &&
        workoutsData.featuredWorkoutsData &&
        workoutsData.featuredWorkoutsData.length === 0
      ) {
        state = state.setIn(
          ["workout", "featuredCollection", "moreWorkoutsAvailable"],
          false
        );
      }

      state = state.setIn(["workout", "forUser"], forUser);
      state = state.setIn(["workout", "fetchedOnce"], true);
    }

    return state;
  },

  WORKOUT_FILTER_COUNT: (state, action) => {
    state = state.setIn(["workout", "temporaryFilteredCount"], null);
    return state;
  },

  WORKOUT_FILTER_COUNT_SUCCESS: (state, action) => {
    const {
      payload: { totalCount = 0 },
    } = action;

    state = state.setIn(["workout", "temporaryFilteredCount"], totalCount);
    return state;
  },

  // Blogs
  // blog collection and category
  BLOG_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const { categories } = action.payload.blogPrefetchEssentials;
    state = state.setIn(["blog", "categories"], fromJS(categories));
    return state;
  },
  BLOGS_LIST_FETCH_REQUEST: (state, action) => {
    // If not pagination, clear the recipes so that the preloader loads
    // This is only when the request fetches completely new
    if (!action.payload || (action.payload && !action.payload.pagination)) {
      // And also pagination
      const existingFilteredList = state.getIn([
        "blog",
        "filteredList",
        "list",
      ]);

      state = state.setIn(
        ["blog", "cachedList", "list"],
        List(existingFilteredList)
      );

      state = state.setIn(["blog", "filteredList", "list"], List());
      state = state.setIn(["blog", "filteredList", "offset"], 0);
      state = state.setIn(["blog", "filteredList", "moreBlogsAvailable"], true);
    }
    return state;
  },
  // recipe list data
  BLOGS_LIST_FETCH_SUCCESS: (state, action) => {
    const { forUser, blogsData = {}, pagination } = action.payload;

    // reset dirtyGroup and filterGroup
    state = state.setIn(["blog", "dirtyGroup"], null);

    // Type will be just for fetch status boundary
    if (!action.payload.type) {
      // Set filtered
      // concat when paginated
      if (
        blogsData &&
        blogsData.filteredBlogsData &&
        blogsData.filteredBlogsData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["blog", "filteredList", "list"],
            state
              .getIn(["blog", "filteredList", "list"])
              .concat(fromJS(blogsData.filteredBlogsData))
          );
        } else {
          state = state.setIn(
            ["blog", "filteredList", "list"],
            fromJS(blogsData.filteredBlogsData)
          );
        }
        state = state.setIn(
          ["blog", "filteredList", "offset"],
          state.getIn(["blog", "filteredList", "offset"]) + 1
        );

        // Set more blogs available to false when it is less than global fetch limit
        if (blogsData.filteredBlogsData.length < globalFetchLimit) {
          state = state.setIn(
            ["blog", "filteredList", "moreBlogsAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["blog", "filteredList", "moreBlogsAvailable"],
            true
          );
        }
      } else if (
        blogsData &&
        blogsData.filteredBlogsData &&
        blogsData.filteredBlogsData.length === 0
      ) {
        state = state.setIn(
          ["blog", "filteredList", "moreBlogsAvailable"],
          false
        );
      }

      // unfiltered list
      if (
        blogsData &&
        blogsData.unfilteredBlogsData &&
        blogsData.unfilteredBlogsData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["blog", "unFilteredList", "list"],
            state
              .getIn(["blog", "unFilteredList", "list"])
              .concat(fromJS(blogsData.unfilteredBlogsData))
          );
        } else {
          state = state.setIn(
            ["blog", "unFilteredList", "list"],
            fromJS(blogsData.unfilteredBlogsData)
          );
        }
        state = state.setIn(
          ["blog", "unFilteredList", "offset"],
          state.getIn(["blog", "unFilteredList", "offset"]) + 1
        );

        if (
          blogsData &&
          blogsData.unfilteredBlogsData &&
          blogsData.unfilteredBlogsData.length < globalFetchLimit
        ) {
          state = state.setIn(
            ["blog", "unFilteredList", "moreBlogsAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["blog", "unFilteredList", "moreBlogsAvailable"],
            true
          );
        }
      } else if (
        blogsData &&
        blogsData.unfilteredBlogsData &&
        blogsData.unfilteredBlogsData.length === 0
      ) {
        state = state.setIn(
          ["blog", "unFilteredList", "moreBlogsAvailable"],
          false
        );
      }

      if (
        blogsData &&
        blogsData.featuredBlogsData &&
        blogsData.featuredBlogsData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["blog", "featuredList", "list"],
            state
              .getIn(["blog", "featuredList", "list"])
              .concat(fromJS(blogsData.featuredBlogsData))
          );
        } else {
          state = state.setIn(
            ["blog", "featuredList", "list"],
            fromJS(blogsData.featuredBlogsData)
          );
        }
        state = state.setIn(
          ["blog", "featuredList", "offset"],
          state.getIn(["blog", "featuredList", "offset"]) + 1
        );

        // Set more blogs available to false when it is less than global fetch limit
        if (blogsData.featuredBlogsData.length < globalFetchLimit) {
          state = state.setIn(
            ["blog", "featuredList", "moreBlogsAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["blog", "featuredList", "moreBlogsAvailable"],
            true
          );
        }
      } else if (
        blogsData &&
        blogsData.featuredBlogsData &&
        blogsData.featuredBlogsData.length === 0
      ) {
        state = state.setIn(
          ["blog", "featuredList", "moreBlogsAvailable"],
          false
        );
      }

      state = state.setIn(["blog", "forUser"], forUser);
      state = state.setIn(["blog", "fetchedOnce"], true);
    }

    return state;
  },

  // Videos
  VIDEO_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const { categories, collections } = action.payload.videoPrefetchEssentials;
    state = state.setIn(["video", "categories"], fromJS(categories));
    state = state.setIn(["video", "collections"], fromJS(collections));
    return state;
  },
  VIDEOS_LIST_FETCH_REQUEST: (state, action) => {
    // If not pagination, clear the videos so that the preloader loads
    // This is only when the request fetches completely new
    if (!action.payload || (action.payload && !action.payload.pagination)) {
      // And also pagination
      const existingFilteredList = state.getIn([
        "video",
        "filteredList",
        "list",
      ]);

      state = state.setIn(
        ["video", "cachedList", "list"],
        List(existingFilteredList)
      );

      state = state.setIn(["video", "filteredList", "list"], List());
      state = state.setIn(["video", "filteredList", "offset"], 0);
      state = state.setIn(
        ["video", "filteredList", "moreVideosAvailable"],
        true
      );
    }
    return state;
  },
  // recipe list data
  VIDEOS_LIST_FETCH_SUCCESS: (state, action) => {
    const { forUser, videosData = {}, pagination } = action.payload;

    // reset dirtyGroup and filterGroup
    state = state.setIn(["video", "dirtyGroup"], null);

    // Type will be just for fetch status boundary
    if (!action.payload.type) {
      // Set filtered
      // concat when paginated
      if (
        videosData &&
        videosData.filteredVideosData &&
        videosData.filteredVideosData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["video", "filteredList", "list"],
            state
              .getIn(["video", "filteredList", "list"])
              .concat(fromJS(videosData.filteredVideosData))
          );
        } else {
          state = state.setIn(
            ["video", "filteredList", "list"],
            fromJS(videosData.filteredVideosData)
          );
        }
        state = state.setIn(
          ["video", "filteredList", "offset"],
          state.getIn(["video", "filteredList", "offset"]) + 1
        );

        // Set more blogs available to false when it is less than global fetch limit
        if (videosData.filteredVideosData.length < globalFetchLimit) {
          state = state.setIn(
            ["video", "filteredList", "moreVideosAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["video", "filteredList", "moreVideosAvailable"],
            true
          );
        }
      } else if (
        videosData &&
        videosData.filteredVideosData &&
        videosData.filteredVideosData.length === 0
      ) {
        state = state.setIn(
          ["video", "filteredList", "moreVideosAvailable"],
          false
        );
      }

      // unfiltered list
      if (
        videosData &&
        videosData.unfilteredVideosData &&
        videosData.unfilteredVideosData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["video", "unFilteredList", "list"],
            state
              .getIn(["video", "unFilteredList", "list"])
              .concat(fromJS(videosData.unfilteredVideosData))
          );
        } else {
          state = state.setIn(
            ["video", "unFilteredList", "list"],
            fromJS(videosData.unfilteredVideosData)
          );
        }
        state = state.setIn(
          ["video", "unFilteredList", "offset"],
          state.getIn(["video", "unFilteredList", "offset"]) + 1
        );

        if (
          videosData &&
          videosData.unfilteredVideosData &&
          videosData.unfilteredVideosData.length < globalFetchLimit
        ) {
          state = state.setIn(
            ["video", "unFilteredList", "moreVideosAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["video", "unFilteredList", "moreVideosAvailable"],
            true
          );
        }
      } else if (
        videosData &&
        videosData.unfilteredVideosData &&
        videosData.unfilteredVideosData.length === 0
      ) {
        state = state.setIn(
          ["video", "unFilteredList", "moreVideosAvailable"],
          false
        );
      }

      if (
        videosData &&
        videosData.featuredVideosData &&
        videosData.featuredVideosData.length > 0
      ) {
        if (pagination) {
          state = state.setIn(
            ["video", "featuredList", "list"],
            state
              .getIn(["video", "featuredList", "list"])
              .concat(fromJS(videosData.featuredVideosData))
          );
        } else {
          state = state.setIn(
            ["video", "featuredList", "list"],
            fromJS(videosData.featuredVideosData)
          );
        }
        state = state.setIn(
          ["video", "featuredList", "offset"],
          state.getIn(["video", "featuredList", "offset"]) + 1
        );

        // Set more blogs available to false when it is less than global fetch limit
        if (videosData.featuredVideosData.length < globalFetchLimit) {
          state = state.setIn(
            ["video", "featuredList", "moreVideosAvailable"],
            false
          );
        } else {
          state = state.setIn(
            ["video", "featuredList", "moreVideosAvailable"],
            true
          );
        }
      } else if (
        videosData &&
        videosData.featuredVideosData &&
        videosData.featuredVideosData.length === 0
      ) {
        state = state.setIn(
          ["video", "featuredList", "moreVideosAvailable"],
          false
        );
      }

      state = state.setIn(["video", "forUser"], forUser);
      state = state.setIn(["video", "fetchedOnce"], true);
    }

    return state;
  },

  FAQS_LIST_FETCH_REQUEST_SUCCESS: (state, action) => {
    const faqs = fromJS(action.payload.data);
    let allCategories = faqs.toSeq().reduce((acc, faq) => {
      faq.getIn(["categories"]).forEach((category) => {
        if (
          acc.findIndex(
            (presentCategory) =>
              presentCategory.getIn(["slug"]) === category.getIn(["slug"])
          ) === -1
        ) {
          acc = acc.push(category);
        }
      });
      return acc;
    }, List());

    const order = ["general", "recipes", "workouts", "grocerylist", "calendar"];

    allCategories = allCategories.toJS();

    allCategories.sort((a, b) => {
      return (
        findIndex(order, (element) => element === lowerCase(a.slug)) -
        findIndex(order, (element) => element === lowerCase(b.slug))
      );
    });

    allCategories = fromJS(allCategories);
    const categorizedFaqs = allCategories
      .toSeq()
      .reduce((acc, filterCategory) => {
        const faqsForCategory = faqs.filter((faq) => {
          const categoriesForFaq = faq.getIn(["categories"]);
          return (
            categoriesForFaq.findIndex(
              (category) =>
                category.getIn(["slug"]) === filterCategory.getIn(["slug"])
            ) !== -1
          );
        });

        acc = acc.push(
          fromJS({
            category: filterCategory,
            faqs: faqsForCategory.sortBy((a) => a.getIn(["order"])),
          })
        );

        return acc;
      }, List());

    return state.setIn(["faqs", "list"], categorizedFaqs);
  },
  PLANS_LIST_FETCH_SUCCESS: (state, action) => {
    return state.setIn(["plans"], fromJS(action.payload.data));
  },

  DISHLIST_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    state = state.setIn(
      ["dishlist", "categories"],
      fromJS(action.payload.categories)
    );
    return state;
  },

  MEAL_PLAN_CATEGORIES_FETCH_SUCCESS: (state, action) => {
    state = state.setIn(["mealPlanCategories"], action.payload.categories);
    return state;
  },
  SET_PLANS: (state, action) => {
    state = state.setIn(["plans"], action.payload.plans);
    return state;
  },
});
