import React from "react";

import { Row, Col } from "react-flexbox-grid";
import filter from "lodash/filter";
import cx from "classnames";
import VerticalListWithoutTitle from "./common/verticalListWithoutTitle";
import VerticalListWithTitle from "./common/verticalListWithTitle";
import NavbarImageCard from "./common/navbarImageCard";
import NavDropdownImage from "./common/NavDropdownImage";
import recipe1 from "../../images/recipe1.png";

const horizontalList = (filters) => {
  const getTaxonomy = (name) =>
    filter(filters, (taxonomy) => {
      return taxonomy && taxonomy.name == name;
    })[0];

  const getImage = (taxonomy) =>
    taxonomy && taxonomy.latest_recipe
      ? taxonomy.latest_recipe.images.full_sm
      : "";

  const getUrl = (taxonomy) => {
    if (taxonomy && taxonomy.taxonomy_type === "foodtype") {
      return `/recipes/?food-types=${taxonomy.slug}`;
    }

    if (taxonomy && taxonomy.taxonomy_type === "speciality") {
      return `/recipes/?specialities=${taxonomy.slug}`;
    }

    if (taxonomy && taxonomy.taxonomy_type === "dietary") {
      return `/recipes/?dietaries=${taxonomy.slug}`;
    }
  };

  const glutenFree = getTaxonomy("Gluten Free");
  const under30Minutes = getTaxonomy("30 Minutes or Less");
  const lowCarb = getTaxonomy("Keto");
  const crockPot = getTaxonomy("Crock/Insta");
  const proteinPacked = getTaxonomy("Protein Packed Breakfasts");

  return [
    {
      image: getImage(glutenFree), // Leave: Dynamic image
      title: "Gluten Free Recipes",
      type: "recipe",
      href: getUrl(glutenFree),
    },
    {
      image: getImage(under30Minutes), // Leave: Dynamic image
      title: "Under 30 Minutes",
      type: "recipe",
      href: getUrl(under30Minutes),
    },
    {
      image: getImage(lowCarb), // Leave: Dynamic image
      title: "Low carb Recipes",
      type: "recipe",
      href: getUrl(lowCarb),
    },
    {
      image: getImage(crockPot), // Leave: Dynamic image
      title: "Crockpot & Instapot",
      type: "recipe",
      href: getUrl(crockPot),
    },
    {
      image: getImage(proteinPacked), // Leave: Dynamic image
      title: "Protein Packed",
      type: "recipe",
      href: proteinPacked
        ? `/recipes/featured/${proteinPacked.slug}`
        : "/recipes",
    },
  ];
};

const verticalList = [
  {
    href: "/recipes?order=favorite",
    title: "Most Popular",
  },
  {
    href: "/recipes/featured/20-staff-favorites",
    title: "Staff Picks",
  },
  {
    href: "/recipes/featured/15-quick-and-easy-make-ahead-meals",
    title: "Quick & Easy Dinners",
  },
  {
    href: "/recipes/featured/healthy-freezer-meals",
    title: "Freezer Foods",
  },
  {
    href: "/recipes/featured/15-easy-seafood-recipes",
    title: "Seafood Selection",
  },
];

const RecipesNavDropdown = ({
  history,
  recipeFilters,
  activeHoveredEntry,
  requestCloseNavDropdown,
}) => (
  <div className="container">
    <Row className="recipes-dropdown navbar-dropdown-wrapper no-margin">
      <Col sm={3} lg={3} className="no-padding">
        <VerticalListWithoutTitle
          list={verticalList}
          history={history}
          onClose={requestCloseNavDropdown}
        />
      </Col>
      <Col sm={9} lg={9} className="no-padding">
        <NavbarImageCard
          list={horizontalList(recipeFilters)}
          history={history}
          onClose={requestCloseNavDropdown}
          render={(item) => (
            <NavDropdownImage type={item.type} src={item.image} />
          )}
        />
      </Col>
    </Row>
  </div>
);

export default RecipesNavDropdown;
