import axios from 'axios';
import { get, post, patch } from '../../helpers/requests';
import { notes } from '../../helpers/api';
import { call, select } from 'redux-saga/effects';
import { getToken } from '../selectors'
import isNull from 'lodash/isNull';

export default {
  get: function* getNote(objectType, objectId) {
    const token = yield select(getToken);
    let notesResponse = {
      data: {
        notes: []
      }
    };

    if (!isNull(token)) {
      notesResponse = yield call(axios, get(notes.get(objectType, objectId), '', token))
    }

    return notesResponse
  },
  post: function* postNote(noteObject, noteId) {
    const token = yield select(getToken);
    let noteResponse;
    if (noteId) {
      noteResponse = yield call(axios, patch(notes.patch(noteId), noteObject, '', token))
    } else {
      noteResponse = yield call(axios, post(notes.post(), noteObject, '', token))
    }
    return noteResponse
  }
} 