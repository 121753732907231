import React from 'react'
import { withRouter } from 'react-router-dom'
import { Facebook, Twitter, Pinterest } from 'react-sharingbuttons'

import { shareUrl } from '../../helpers/api'
import PinIcon from '../images/PinIcon';
import TwitIcon from '../images/TwitIcon';
import ShareIcon from '../images/ShareIcon';
import PrintIcon from '../images/PrintIcon';

import './socialStyles.scss';


// renders all the social icons
const renderAllIcons = ({feature, slug, showPrintIcon = true, history, img, title, description}) => {
  return (
    <div className="sharewrapper">
      <ul className='SocialIconLists'>
        <li>
          {/* <PinIcon></PinIcon> */}
          <Pinterest
            text={`Share on Pinterest`}
            url={`${shareUrl}/${feature}/${slug}`}
            shareText={`${title}, ${description}`}
            mediaSrc={img}
          />
        </li>
        <li>
          {/* <TwitIcon></TwitIcon> */}
          <Twitter
            text={`Share on Twitter`}
            url={`${shareUrl}/${feature}/${slug}`}
          />
        </li>
        <li>
          {/* <ShareIcon></ShareIcon> */}
          <Facebook
            text={`Share on Facebook`}
            url={`${shareUrl}/${feature}/${slug}`}
          />
        </li>
        {showPrintIcon &&
          <li>
            <a href={`/${feature}/${slug}/print`} onClick={e => {
              e.preventDefault();
              history.push(`/${feature}/${slug}/print`)
            }}>
              <PrintIcon />
              Print {feature}
            </a>
          </li>
        }
      </ul>
    </div >
  )
}

// used for all pages except blogs
const SocialIcon = (props) => {
  const {
    feature,
    slug,
    img,
    title,
    description,
    history
  } = props;
  return renderAllIcons({feature, slug, history, img, title, description})
}

// used for blog (using this because blog does not need withRouter)
export const BlogSocialIcon = (props) => {
  const {
    feature,
    slug,
    img,
    title,
    description,
    history
  } = props;

  return renderAllIcons({feature, slug, showPrintIcon: false, history, img, title, description})
}



export default withRouter(SocialIcon);