import { CREATE_NOTE_TO_OBJECT } from "./actionTypes";

export default ({noteId, note, objectType, objectId, objectSlug}) => ({
  type: CREATE_NOTE_TO_OBJECT,
  payload: {
    noteId,
    note,
    objectType,
    objectId,
    objectSlug,
  }
});