import React, { useState } from "react";
import isNil from "lodash/isNil";
import cx from 'classnames'
import replace from 'lodash/replace'
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull"
import ReactImageFallback from "react-image-fallback";
import RecipeFallbackImage from '../../images/workoutfallback.png';
import RecipeInnerImage from '../../images/favboardfallback.png';
import InputFallbackImage from '../../images/inputFallbackImg.png';
import Placeholder from '../../common/placeholder';
import CloseIcon from "../../images/closeIcon";


const FavoriteCard = ({
  onUpdateFavouriteBoard,
  onDeleteBoard,
  onCreateNewBoard,
  onBoardTitleChange,
  onCancelNewCategory,
  defaultCard = true,
  thumbnails,
  board,
  boardKey,
  index,
  onFavoriteBoardClick,
  thumbnail,
  editStatus,
  objectType: customizedObjectType, // Stripping off pluralize
  ...props
}) => {
  const [state, setState] = useState({
    title: "",
    edit: false
  })


  // Stripping off 's'
  const objectType = customizedObjectType !== 'blog' ? customizedObjectType.slice(0, -1) : customizedObjectType;

  const onEditKeyPress = (e) => {
    if (e.keyCode === 27) {
      setState({ ...state, title: "", edit: false });
    }
    if (e.keyCode === 13) {
      if (!isEqual(state.title, board.title)) {
        onUpdateFavouriteBoard(board, state.title);
        setState({ ...state, title: "", edit: false });
      }
    }
  }

  const renderTitle = (title) => {
    if (editStatus && state.edit) {
      return (
        <p className="favorite-title">
          <input
            type="text"
            value={state.title}
            onKeyDown={onEditKeyPress}
            onChange={(e) => setState({ ...state, title: e.target.value })}
            onBlur={(e) => setState({ ...state, title: "", edit: false })}
            ref={input => input && input.focus()}
          />
        </p>
      )
    }
    return (
      <p className="favorite-title"
        onClick={() => {
          if (editStatus) {
            setState({ ...state, title: title, edit: true })
          } else {
            onFavoriteBoardClick(board)
          }
        }}>
        {title}
      </p>
    )
  }

  const renderNormalCardContent = (board) => {
    if (board.isLoading) {
      return (
        <>
          <Placeholder styles={{ height: 180, width: 180 }} />
        </>
      )
    } else {
      const boardCount = (board && board.thumbnail && !isNull(board.thumbnail.length)) ? board.thumbnail.length : 0;
      return (
        <div
          className={cx('favorites', { fallback: !(thumbnail && thumbnail.length > 0) })}
          key={boardKey ? boardKey : 1} >
          {
            editStatus &&
            <span
              onClick={(e) => onDeleteBoard(board)}
              className="close-icon">
              <CloseIcon />
            </span>
          }
          <a
            href={`/${objectType}s/favorites/${board.slug}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onFavoriteBoardClick(board)
            }}
          >
            <div
              className="favorite-collection"
            >
              <figure className="listCardImg">
                <ReactImageFallback
                  src={thumbnail}
                  className="CardImg"
                  fallbackImage={RecipeFallbackImage}
                  alt="favorites"
                />
              </figure>
            </div>
            <div className="favorite-log">
              {
                renderTitle(board.title)
              }
              <small
                className="favorite-count">{board.thumbnail ? `${boardCount} ${objectType}${boardCount > 1 ? 's' : ''}` : `No ${objectType}`}</small>
            </div>
          </a>
        </div >
      )
    }
  }

  const renderGridImageCard = (board) => {
    if (board.isLoading) {
      return (
        <div className="favorite-collection-cards-Placeholder">
          <Placeholder styles={{ height: 85, width: 80, margin: 5 }} />
          <Placeholder styles={{ height: 85, width: 80, margin: 5 }} />
          <Placeholder styles={{ height: 85, width: 80, margin: 5 }} />
          <Placeholder styles={{ height: 85, width: 80, margin: 5 }} />
        </div>
      )
    } else {
      return (
        <div className="favorites">
          <div className="favorite-collection-cards"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onFavoriteBoardClick(board)
            }}>
            <a href={`/${objectType}s/favorites`}>
              <div className="favorite-inner-card-joned">
                <div className="favorite-inner-card br-tl">
                  <figure className="listCardImg">
                    <ReactImageFallback
                      src={thumbnails[0]}
                      className="CardImg"
                      fallbackImage={RecipeInnerImage}
                      alt="favorites"
                    />
                  </figure>
                </div>
                <div className="favorite-inner-card br-tr">
                  <figure className="listCardImg">
                    <ReactImageFallback
                      src={thumbnails[1]}
                      className="CardImg"
                      fallbackImage={RecipeInnerImage}
                      alt="favorites"
                    />
                  </figure>
                </div>
              </div>
              <div className="favorite-inner-card-joned">
                <div className="favorite-inner-card br-bl">
                  <figure className="listCardImg">
                    <ReactImageFallback
                      src={thumbnails[2]}
                      className="CardImg"
                      fallbackImage={RecipeInnerImage}
                      alt="favorites"
                    />
                  </figure>
                </div>
                <div className="favorite-inner-card br-br">
                  <figure className="listCardImg">
                    <ReactImageFallback
                      src={thumbnails[3]}
                      className="CardImg"
                      fallbackImage={RecipeInnerImage}
                      alt="favorites"
                    />
                  </figure>
                </div>
              </div>
              <p
                className="favorite-title"
              >{objectType === 'blog' ? replace(board.title, 'Favorites', 'Bookmarks') : board.title}</p>
            </a>
          </div>
        </div>
      )
    }
  }

  const onNewBoardInputKeyPress = (e) => {
    if (e.keyCode === 27) {
      onCancelNewCategory()
    }
    if (e.keyCode === 13) {
      onCreateNewBoard(board);
    }
  }

  const renderNewBordContent = (board) => {
    return (
      <div
        className="favorite-inputcard"
        key={boardKey ? boardKey : 1}
      >
        <figure className="listCardImg">
          <ReactImageFallback
            src={thumbnail}
            className="CardImg"
            fallbackImage={InputFallbackImage}
            alt="favorites"
          />
        </figure>
        {
          <input type="text"
            value={board.title}
            ref={input => input && input.focus()}
            onKeyDown={onNewBoardInputKeyPress}
            onChange={(e) => onBoardTitleChange(board, e.target.value)}
            onBlur={(e) => onCancelNewCategory()}
            autoFocus
          />
        }
      </div>
    )
  }

  const renderCardContent = (board, defaultCard) => {

    if (defaultCard) {

      return renderGridImageCard(board)
    }
    else {
      if (!isNil(board.newBoardCard)) {
        return renderNewBordContent(board)
      }
      return renderNormalCardContent(board)
    }
  }

  return (
    <>
      {
        renderCardContent(board, defaultCard)
      }
    </>
  )
}

export default FavoriteCard;