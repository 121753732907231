import React from "react";
import '../styles/common.scss';

const VideoPlayIcon = (props) => {
    return (
        <figure className="VideoPlayIcon">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M39.7289 30L24.4626 20.1312V39.8688L39.7289 30Z" fill="white" />
                <circle cx="30" cy="30" r="28" stroke="white" stroke-width="4" />
            </svg>
        </figure>
    );
};
export default VideoPlayIcon;
