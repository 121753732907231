import React from "react";
import cx from 'classnames'

import '../styles/common.scss';


const BookmarkFillIcon = ({
  onClick,
}) => {
  return (
    <>
      <figure className="action-image">
        <svg onClick={onClick} className="cardFavicon" width="23" height="34" viewBox="0 0 23 34" fill="white" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.0752 32.9247V4.43003C1.0752 2.58057 2.58057 1.0752 4.43003 1.0752H17.8386C19.6881 1.0752 21.1935 2.58057 21.1935 4.43003V32.9247L11.1397 26.215L1.0752 32.9247Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </figure>
    </>
  );
};
export default BookmarkFillIcon;
