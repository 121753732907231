import { CREATE_COMMENT_TO_OBJECT } from "./actionTypes";

// TODO : change name to commented -done
export default ({commentContent, rating, objectType, objectId, objectSlug}) => ({
  type: CREATE_COMMENT_TO_OBJECT,
  payload: {
    commentContent,
    rating,
    objectType,
    objectId,
    objectSlug
  },
});