import * as actions from '../actions/actionTypes'
import { call, select, put, takeEvery } from 'redux-saga/effects'
import usersTask from './subtask/users'
import pushToSentry from '../helpers/pushToSentry';

export default function* startUp(action) {

  try {
    userData = yield call(usersTask.get);
    if (userData.isLoggedIn) {
      yield put({
        type: actions.USER_AUTHENTICATE_SUCCESS,
        payload: {
          userData,
        }
      })
    }
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.USER_AUTHENTICATE_FAILURE,
    })
  }
}
