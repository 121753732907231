
import React, { useEffect, useState } from 'react';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Button from './button'
import StripeIcon from '../images/StripeIcon';
import PaypalIcon from '../images/PaypalIcon';

const stripePromise = loadStripe(process.env.STRIPE_KEY);


// const handleBlur = () => {
//   console.log('[blur]');
// };
// const handleChange = (change) => {
//   console.log('[change]', change);
// };
// const handleClick = () => {
//   console.log('[click]');
// };
// const handleFocus = () => {
//   console.log('[focus]');
// };
// const handleReady = () => {
//   console.log('[ready]');
// };

const _CardForm = (props) => {

  const stripe = useStripe();
  const elements = useElements();
  const [submitStatus, setSubmitStatus] = useState(false);

  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCVCComplete] = useState(false);

  const handleChange = (type, complete) => {
    if (type === 'number') {
      if (complete) {
        setNumberComplete(true)
      } else {
        setNumberComplete(false)
      }
    } else if (type === 'expiry') {
      if (complete) {
        setExpiryComplete(true)
      } else {
        setExpiryComplete(false)
      }
    } else if (type === 'cvc') {
      if (complete) {
        setCVCComplete(true)
      } else {
        setCVCComplete(false)
      }
    }
  }
  const isFormComplete = numberComplete && expiryComplete && cvcComplete;

  const handleSubmit = (ev) => {
    ev.preventDefault();
    console.log('submitted...')
    setSubmitStatus(true);

    const cardElement = elements.getElement(CardNumberElement);

    if (stripe) {
      stripe
        .createToken(cardElement)
        .then((payload) => {
          props.changePaymentType(payload.token.id)
        });
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="paymet-option">Credit Card  |  Stripe</div> */}
      <div className="form-text">
        <label className="static-value">
          Card
        </label>
        <CardNumberElement
          // onBlur={handleBlur}
          onChange={(details) => handleChange('number', details.complete)}
        // onFocus={handleFocus}
        // onReady={handleReady}
        />
      </div>
      <div className="form-group">
        <div className="form-text">
          <label className="static-value">
            Exp
          </label>
          <CardExpiryElement
            // onBlur={handleBlur}
            onChange={(details) => handleChange('expiry', details.complete)}
          // onFocus={handleFocus}
          // onReady={handleReady}
          />
        </div>
        <div className="form-text">
          <label className="static-value">
            CVC
          </label>
          <CardCvcElement
            // onBlur={handleBlur}
            onChange={(details) => handleChange('cvc', details.complete)}
          // onFocus={handleFocus}
          // onReady={handleReady}
          />
        </div>
      </div>
      <div className="modalFooter">
        <Button
          btnClass="primary btn-full"
          btnText="Update Card"
          isLoading={props.formSubmitStatus}
          disabled={props.formSubmitStatus || !isFormComplete}
        />
        <Button
          btnClass="secondary btn-full btn-bp-sm"
          btnText="Cancel"
          onClick={() => {
            props.onClose()
          }}
        />
      </div>
    </form >
  );
}


const CardForm = (_CardForm);

const Checkout = ({
  changePaymentType,
  onClose,
  formSubmitStatus
}) => {

  return (
    <div className="modalBodyPayment">
      <div className="modalBody">
        <div className="checkout">

          <CardForm
            formSubmitStatus={formSubmitStatus}
            onClose={onClose}
            fontSize="18px"
            changePaymentType={changePaymentType}
          />

        </div>
        <div className="login-cookies">
          <p>CHECKOUT SECURELY WITH STRIPE </p>
          <div className="paymentIcon">
            {/* <figure>
              <PaypalIcon />
            </figure> */}
            <figure>
              <StripeIcon />
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}

const App = ({
  changePaymentType,
  onClose,
  formSubmitStatus
}) => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout
        onClose={onClose}
        changePaymentType={changePaymentType}
        formSubmitStatus={formSubmitStatus}
      />
    </Elements>
  );
};

export default App
