
import { select, put, call } from 'redux-saga/effects'

import isEqual from 'lodash/isEqual'

import {
  getToken,
  getFeaturedRecipes,
  getAppliedRecipeFilters,
  getAppliedRecipeFavorites,
  // Workouts
  getWorkoutsWorkoutTypes,
  getWorkoutsOtherFilters,
  getAppliedWorkoutFilters,
  getAppliedWorkoutFavorites,
  getFeaturedWorkout,
  getAppliedBlogFilters,
  getAppliedBlogFavorites,
  getAppliedVideoFilters,
  getAppliedVideoFavorites,
} from '../selectors'

import * as actions from '../../actions/actionTypes'
import pushToSentry from '../../helpers/pushToSentry';
import { getFavoritesFilteredData } from '../../actions';
import { generateAppEssentialPayload } from '../../helpers/filterConversions';


const checkIfFiltersChanged = (currentFilters, newFilters, defaultFilters) => {

  return newFilters.order.length > 0 && newFilters.order[0] === 'rand' ||
    (
      !isEqual(currentFilters, newFilters) &&
      !isEqual(newFilters, defaultFilters)
    )
}

export default {
  recipe: function* compareRecipeFilters(recipe) {
    let currentFilters = yield select(getAppliedRecipeFilters);
    let currentFavorites = yield select(getAppliedRecipeFavorites);
    let currentFeatured = yield select(getFeaturedRecipes);

    currentFilters = currentFilters.toJS();
    currentFavorites = currentFavorites.toJS();
    currentFeatured = currentFeatured.toJS()


    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'recipe'
    });

    // For recipe
    const defaultFilters = payloadDefault.recipe.filters;

    // Get the current ones
    const newFilters = recipe.filters;
    const newFavorites = recipe.favorite;
    const newSearchApplied = recipe.searchApplied;
    const newFeatured = recipe.featured;

    // If favorites are changing take it as priority.
    // filters might change at this time. But since we moved to favorites
    if (!isEqual(currentFavorites, newFavorites) && newFavorites && newFavorites.show) {
      // Sets the dirty group to be favorite
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: recipe.favorite,
          filters: recipe.filters,
          objectType: 'recipe',
          dirtyGroup: 'favorites',
          searchApplied: recipe.searchApplied
        }
      });
    } else if (checkIfFiltersChanged(currentFilters, newFilters, defaultFilters)) {
      // If filters update and not same as default
      // Sets the dirty group to be recipe
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: recipe.favorite,
          filters: recipe.filters,
          objectType: 'recipe',
          dirtyGroup: 'filters',
          searchApplied: recipe.searchApplied
        }
      });
    } else if (
      !isEqual(currentFeatured, newFeatured) && newFeatured && newFeatured.show
    ) {
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          featured: newFeatured,
          objectType: 'recipe',
          dirtyGroup: 'featuredCollection',
        }
      });
    } else if (
      (isEqual(currentFilters, newFilters) && isEqual(currentFavorites, newFavorites)) ||
      isEqual(newFilters, defaultFilters)
    ) {
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: recipe.favorite,
          filters: recipe.filters,
          objectType: 'recipe',
          dirtyGroup: null,
          searchApplied: recipe.searchApplied
        }
      });
      // Do nothing since you don't need to fetch anything
      // Ensure that in the previous reducer you have set that filters are not changed
    }
  },
  workout: function* compareRecipeFilters(workout) {
    // Change these for workouts
    let currentFilters = yield select(getAppliedWorkoutFilters);
    let currentFavorites = yield select(getAppliedWorkoutFavorites);
    let currentFeaturedWorkout = yield select(getFeaturedWorkout);

    currentFilters = currentFilters.toJS();
    currentFavorites = currentFavorites.toJS();
    currentFeaturedWorkout = currentFeaturedWorkout.toJS()

    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'workout'
    });

    // For recipe
    const defaultFilters = payloadDefault.workout.filters;

    // Get the current ones
    const newFilters = workout.filters;
    const newFavorites = workout.favorite;
    const newSearchApplied = workout.searchApplied;
    const newFeaturedWorkout = workout.featured;

    // If favorites are changing take it as priority.
    // filters might change at this time. But since we moved to favorites
    if (!isEqual(currentFavorites, newFavorites) && newFavorites && newFavorites.show) {
      // Sets the dirty group to be favorite
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: workout.favorite,
          filters: workout.filters,
          objectType: 'workout',
          dirtyGroup: 'favorites',
          searchApplied: workout.searchApplied
        }
      });
    } else if (checkIfFiltersChanged(currentFilters, newFilters, defaultFilters)) {
      // If filters update and not same as default
      // Sets the dirty group to be recipe
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: workout.favorite,
          filters: workout.filters,
          objectType: 'workout',
          dirtyGroup: 'filters',
          searchApplied: workout.searchApplied
        }
      });
    } else if (
      newFeaturedWorkout && newFeaturedWorkout.show
    ) {
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          featured: newFeaturedWorkout,
          objectType: 'workout',
          dirtyGroup: 'featured',
        }
      });
    } else if (
      (isEqual(currentFilters, newFilters) && isEqual(currentFavorites, newFavorites)) ||
      isEqual(newFilters, defaultFilters)
    ) {
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: workout.favorite,
          filters: workout.filters,
          objectType: 'workout',
          dirtyGroup: null,
          searchApplied: workout.searchApplied
        }
      });
      // Do nothing since you don't need to fetch anything
      // Ensure that in the previous reducer you have set that filters are not changed
    }
  },
  blog: function* compareBlogFilters(blog) {
    let currentFilters = yield select(getAppliedBlogFilters);
    let currentFavorites = yield select(getAppliedBlogFavorites);

    currentFilters = currentFilters.toJS();
    currentFavorites = currentFavorites.toJS();

    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'blog'
    });

    // For recipe
    const defaultFilters = payloadDefault.blog.filters;

    // Get the current ones
    const newFilters = blog.filters;
    const newFavorites = blog.favorite;

    // If favorites are changing take it as priority.
    // filters might change at this time. But since we moved to favorites
    if (!isEqual(currentFavorites, newFavorites) && newFavorites && newFavorites.show) {
      // Sets the dirty group to be favorite
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: blog.favorite,
          filters: blog.filters,
          objectType: 'blog',
          dirtyGroup: 'favorites',
          searchApplied: blog.searchApplied
        }
      });
    } else if (checkIfFiltersChanged(currentFilters, newFilters, defaultFilters)) {
      // If filters update and not same as default
      // Sets the dirty group to be blog
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: blog.favorite,
          filters: blog.filters,
          objectType: 'blog',
          dirtyGroup: 'filters',
          searchApplied: blog.searchApplied
        }
      });
    } else if (
      (isEqual(currentFilters, newFilters) && isEqual(currentFavorites, newFavorites)) ||
      isEqual(newFilters, defaultFilters)
    ) {
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: blog.favorite,
          filters: blog.filters,
          objectType: 'blog',
          dirtyGroup: null,
          searchApplied: blog.searchApplied
        }
      });
      // Do nothing since you don't need to fetch anything
      // Ensure that in the previous reducer you have set that filters are not changed
    }
  },
  video: function* compareVideoFilters(video) {
    let currentFilters = yield select(getAppliedVideoFilters);
    let currentFavorites = yield select(getAppliedVideoFavorites);

    currentFilters = currentFilters.toJS();
    currentFavorites = currentFavorites.toJS();

    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'video'
    });

    // For recipe
    const defaultFilters = payloadDefault.video.filters;

    // Get the current ones
    const newFilters = video.filters;
    const newFavorites = video.favorite;
    const newSearchApplied = video.searchApplied;

    // If favorites are changing take it as priority.
    // filters might change at this time. But since we moved to favorites
    if (!isEqual(currentFavorites, newFavorites) && newFavorites && newFavorites.show) {
      // Sets the dirty group to be favorite
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: video.favorite,
          filters: video.filters,
          objectType: 'video',
          dirtyGroup: 'favorites',
          searchApplied: video.searchApplied
        }
      });
    } else if (!isEqual(currentFilters, newFilters) && !isEqual(newFilters, defaultFilters)) {
      // If filters update and not same as default
      // Sets the dirty group to be video
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: video.favorite,
          filters: video.filters,
          objectType: 'video',
          dirtyGroup: 'filters',
          searchApplied: video.searchApplied
        }
      });
    } else if (
      (isEqual(currentFilters, newFilters) && isEqual(currentFavorites, newFavorites)) ||
      isEqual(newFilters, defaultFilters)
    ) {
      yield put({
        type: actions.SET_NEW_FILTERS_SUCCESS,
        payload: {
          favorite: video.favorite,
          filters: video.filters,
          objectType: 'video',
          dirtyGroup: null,
          searchApplied: video.searchApplied
        }
      });
      // Do nothing since you don't need to fetch anything
      // Ensure that in the previous reducer you have set that filters are not changed
    }
  }
}