import React from "react";
import '../styles/common.scss';

const ThumbNoteIcon = (props) => {
  return (
    <>
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 12.5H2.5C1.4 12.5 0.5 11.6 0.5 10.5V2.5C0.5 1.4 1.4 0.5 2.5 0.5H9C10.1 0.5 11 1.4 11 2.5V10.5C11 11.6 10.11 12.5 9 12.5Z" stroke="#888888" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M3.5 4.25H8" stroke="#888888" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M3.5 7.25H5.75" stroke="#888888" stroke-miterlimit="10" stroke-linecap="round" />
      </svg>
    </>
  );
};
export default ThumbNoteIcon;
