import axios from 'axios'
import { map } from 'lodash';
import { get, post } from '../../helpers/requests'

import { workouts, favourites } from '../../helpers/api'

import { call, select, put, delay } from 'redux-saga/effects'

import {
  getToken,
  getAppliedWorkoutFilters,
  getAppliedWorkoutFavorites,
  getWorkoutFilteredAppliedOnce,
  getIsWorkoutFavoritedList,
  getWorkoutDirtyGroup,
  getWorkoutsWorkoutTypes,
  getWorkoutsBodyParts,
  getWorkoutCategories,
  getUnFilteredWorkoutList,
  getWorkoutBoards,
  getFeaturedWorkout,
  getWorkoutsEquipments,
  getWorkoutFiltersSetUpdatedPrematurelyStatus,
  getCachedWorkoutList,
  getWorkoutCollections,
} from '../selectors'
import * as actionTypes from '../../actions/actionTypes';
import { generateWorkoutQueryFromFilter } from '../../helpers/filterConversions';

const getUnfilteredWorkoutsData = function* getUnfilteredData(workout) {
  const token = yield select(getToken)
  // Don't fetch unfiltered workout list if already fetched
  const unfilteredWorkoutList = yield select(getUnFilteredWorkoutList);
  let unfilteredWorkoutsResponse;

  let unfilteredWorkoutsData;
  if (unfilteredWorkoutList.isEmpty() || workout.workoutRefreshGroup === 'unfiltered') {
    yield put({
      type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
      payload: {
        listType: 'unfiltered',
        objectType: 'workout',
        pullToRefresh: workout.workoutRefreshGroup === 'unfiltered'
      }
    });
    unfilteredWorkoutsResponse = yield call(axios, get(
      workouts.getAll(workout.limit, workout.offset, ''),
      '',
      token
    ));
    yield put({
      type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
      payload: {
        listType: 'unfiltered',
        objectType: 'workout',
        pullToRefresh: workout.workoutRefreshGroup === 'unfiltered'
      }
    });

    unfilteredWorkoutsData = unfilteredWorkoutsResponse ? unfilteredWorkoutsResponse.data.workouts : [];
  } else {
    unfilteredWorkoutsData = null; // In redux it will be in immutable format
  }

  return unfilteredWorkoutsData;
};

export default {
  // Get Single workout details
  get: function* getSingleWorkout(objectSlug) {
    const token = yield select(getToken);
    const workoutResponse = yield call(axios, get(workouts.get(objectSlug), '', token));
    return workoutResponse;
  },
  // Gets all workouts as per existing filters / search / favorites
  getAll: function* getAllWorkouts(workout) {
    // // // Get the token
    const appliedWorkoutFilters = yield select(getAppliedWorkoutFilters);
    const featuredWorkout = yield select(getFeaturedWorkout);

    const workoutTypes = yield select(getWorkoutsWorkoutTypes);
    const workoutEquipments = yield select(getWorkoutsEquipments);
    const workoutBodyParts = yield select(getWorkoutsBodyParts);
    const workoutCollections = yield select(getWorkoutCollections)

    const workoutFiltersSetUpdatedPrematurely = yield select(getWorkoutFiltersSetUpdatedPrematurelyStatus);

    // Get the token
    const token = yield select(getToken);
    const dirtyGroup = yield select(getWorkoutDirtyGroup);
    // These will be toggled in each cases
    let filteredWorkoutsResponse;
    let unfilteredWorkoutsResponse;

    if (dirtyGroup === 'filters' || workout.workoutRefreshGroup === 'filters' || workoutFiltersSetUpdatedPrematurely) {
      // Dispatching to Set Particular Fetch Status

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'workout'
        }
      });

      // If to be fetched is filters
      filteredWorkoutsResponse = yield call(axios, get(
        workouts.getAll(workout.limit, workout.offset, generateWorkoutQueryFromFilter(
          appliedWorkoutFilters,
          workoutTypes,
          workoutEquipments,
          workoutBodyParts,
          workoutCollections
        )), '', token)
      );

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'workout'
        }
      });
      // This will fetch new set of data for the newly applied filter
      return {
        filteredWorkoutsData: filteredWorkoutsResponse && filteredWorkoutsResponse.data.workouts ? filteredWorkoutsResponse.data.workouts : null,
        unfilteredWorkoutsData: yield call(getUnfilteredWorkoutsData, workout)
      }
    } else if (dirtyGroup === 'favorites' || workout.workoutRefreshGroup === 'favorites') {
      // yield delay(200000)
      // These will be toggled in each cases
      let filteredWorkoutsResponse;
      let unfilteredWorkoutsResponse;

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorites',
          objectType: 'workout'
        }
      });

      // Get the favorite board id
      let appliedWorkoutFavorites = yield select(getAppliedWorkoutFavorites);
      appliedWorkoutFavorites = appliedWorkoutFavorites.toJS();
      let favoriteBoardSlug = appliedWorkoutFavorites.board;
      let favoriteBoardId;
      if (favoriteBoardSlug) {
        const allBoard = yield select(getWorkoutBoards);
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }


      // Favorites API fetch
      filteredWorkoutsResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'workout',
          favoriteBoardId,
          workout.limit,
          workout.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorites',
          objectType: 'workout'
        }
      });
      const formattedWorkoutsData = filteredWorkoutsResponse.data.items.map(item => ({
        ...item,
        ...item.workout,
      }));
      return {
        filteredWorkoutsData: filteredWorkoutsResponse && filteredWorkoutsResponse.data.items ? formattedWorkoutsData : null,
        unfilteredWorkoutsData: yield call(getUnfilteredWorkoutsData, workout)
      }
    } else if (dirtyGroup === 'featured') {
      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'featured',
          objectType: 'workout'
        }
      });

      filteredWorkoutsResponse = yield call(axios, get(
        workouts.getAll(workout.limit, workout.offset, generateWorkoutQueryFromFilter(
          {
            ...appliedWorkoutFilters.toJS(),
            collection: [workout.featured.slug],
          },
          workoutTypes,
          workoutEquipments,
          workoutBodyParts,
          workoutCollections
        )), '', token)
      );

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'featured',
          objectType: 'workout'
        }
      });

      // This is just the filtered data aka featuredWorkout
      return {
        filteredWorkoutsData: [],
        unfilteredWorkoutsData: yield call(getUnfilteredWorkoutsData, workout),
        featuredWorkoutsData: filteredWorkoutsResponse && filteredWorkoutsResponse.data.workouts ? filteredWorkoutsResponse.data.workouts : null
      }
    } else {
      // Caching for clear list
      const filteredWorkoutsData = yield select(getCachedWorkoutList)
      return {
        filteredWorkoutsData: filteredWorkoutsData.toJS(),
        unfilteredWorkoutsData: yield call(getUnfilteredWorkoutsData, workout),
      }
    }
  },
  // Gets more workouts as per existing filters / search / favorites
  getMore: function* getMoreWorkouts(workout) {
    const token = yield select(getToken);
    let offset = workout.offset * workout.limit;

    // filters
    const appliedWorkoutFilters = yield select(getAppliedWorkoutFilters);

    // Favorite related
    let appliedWorkoutFavorites = yield select(getAppliedWorkoutFavorites);

    appliedWorkoutFavorites = appliedWorkoutFavorites.toJS();
    const favoriteBoardSlug = appliedWorkoutFavorites.board;

    let filteredWorkoutsResponse;
    let unfilteredWorkoutsResponse;

    const workoutTypes = yield select(getWorkoutsWorkoutTypes);
    const workoutEquipments = yield select(getWorkoutsEquipments);
    const workoutBodyParts = yield select(getWorkoutsBodyParts);
    const workoutCollections = yield select(getWorkoutCollections);

    let featured = yield select(getFeaturedWorkout);
    featured = featured.toJS();

    if (workout.filterGroup == 'filters') {

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'workout',
          pagination: true
        }
      });

      filteredWorkoutsResponse = yield call(axios, get(
        workouts.getAll(workout.limit, offset, generateWorkoutQueryFromFilter(
          appliedWorkoutFilters,
          workoutTypes,
          workoutEquipments,
          workoutBodyParts,
          workoutCollections
        )),
        '',
        token
      ));
      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'workout',
          pagination: true
        }
      });
      return {
        filteredWorkoutsData: filteredWorkoutsResponse && filteredWorkoutsResponse.data.workouts.length > 0 ? filteredWorkoutsResponse.data.workouts : null,
        filteredWorkoutsCount: filteredWorkoutsResponse && filteredWorkoutsResponse.data.workouts.length > 0 ? filteredWorkoutsResponse.data.count : 0,
        // Not sending unfiltered data in filters
      }
    } else if (workout.filterGroup == 'featured') {
      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'featured',
          objectType: 'workout',
          pagination: true
        }
      });


      filteredWorkoutsResponse = yield call(axios, get(
        workouts.getAll(workout.limit, offset, generateWorkoutQueryFromFilter(
          {
            ...appliedWorkoutFilters.toJS(),
            collection: [featured.slug],
          },
          workoutTypes,
          workoutEquipments,
          workoutBodyParts,
          workoutCollections
        )), '', token)
      );

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'featured',
          objectType: 'workout',
          pagination: true,
        }
      });

      // This is just the filtered data aka defaultDietary
      return {
        filteredWorkoutsData: [],
        unfilteredWorkoutsData: yield call(getUnfilteredWorkoutsData, workout),
        featuredWorkoutsData: filteredWorkoutsResponse && filteredWorkoutsResponse.data.workouts ? filteredWorkoutsResponse.data.workouts : null
      }

    } else if (workout.filterGroup == 'favorites') {

      // Slug to id conversions
      let appliedWorkoutFavorites = yield select(getAppliedWorkoutFavorites);
      appliedWorkoutFavorites = appliedWorkoutFavorites.toJS();
      let favoriteBoardSlug = appliedWorkoutFavorites.board;
      let favoriteBoardId;

      const allBoard = yield select(getWorkoutBoards);
      if (favoriteBoardSlug) {
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorited',
          objectType: 'workout',
          pagination: true
        }
      });
      // Favorites API fetch
      filteredWorkoutsResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'workout',
          favoriteBoardId, // TODO: Board should be converted from slug to id
          workout.limit,
          workout.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorited',
          objectType: 'workout',
          pagination: true
        }
      });
      // Apply both since recipe is nested
      const formattedWorkoutsData = filteredWorkoutsResponse.data.items.map(item => ({
        ...item,
        ...item.workout,
      }));

      return {
        filteredWorkoutsData: filteredWorkoutsResponse && filteredWorkoutsResponse.data.items ? formattedWorkoutsData : null,
        unfilteredWorkoutsData: null,
        filteredWorkoutsCount: filteredWorkoutsResponse && filteredWorkoutsResponse.data.Workouts ? filteredWorkoutsResponse.data.count : 0,
        unfilteredWorkoutsCount: 0
      }
    } else {
      // No filters or favorites
      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'unfiltered',
          objectType: 'workout',
          pagination: true
        }
      });

      unfilteredWorkoutsResponse = yield call(axios, get(
        workouts.getAll(workout.limit, offset),
        '',
        token,
      ));

      yield put({
        type: actionTypes.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'unfiltered',
          objectType: 'workout',
          pagination: true,
        }
      });
      return {
        filteredWorkoutsData: null,
        unfilteredWorkoutsData: unfilteredWorkoutsResponse && unfilteredWorkoutsResponse.data.workouts.length > 0 ? unfilteredWorkoutsResponse.data.workouts : null,
        filteredWorkoutsCount: 0,
        unfilteredWorkoutsCount: unfilteredWorkoutsResponse && unfilteredWorkoutsResponse.data.workouts.length > 0 ? unfilteredWorkoutsResponse.data.count : 0
      };
    }
  },
} 