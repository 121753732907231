import React from 'react'

import Button from '../../../common/button'

const AddReplaceConfirmation = ({
  chosenCalendarDate,
  chosenAddIngredients,
  chosenServingSize,
  handleSubmitChooseDate,
  progressStatus
}) => {
  return (
    <div className="modalWrapper  confirmation-wrapper">
      <div className="modalHeader confirmationHead">
        <div className="content">
          <h3>Some of the days in the duration has recipes already.</h3>
          <br></br>
          <h3>Choose Add / Replace.</h3>
        </div>
      </div>
      <div className="modalFooter confirmationHead">
        <Button
          btnClass="primary btn-full"
          btnText="Add to meal plan"
          onClick={() => handleSubmitChooseDate(chosenCalendarDate, false, chosenAddIngredients, chosenServingSize)}
          disabled={progressStatus.addCustomMealPlanToCalendar.isProgressing}
          isLoading={
            progressStatus.addCustomMealPlanToCalendar.status === 'replace' &&
            progressStatus.addCustomMealPlanToCalendar.isProgressing
          }
        />
        <Button
          btnClass="secondary btn-full"
          btnText="Replace to meal plan"
          onClick={() => handleSubmitChooseDate(chosenCalendarDate, true, chosenAddIngredients, chosenServingSize)}
          disabled={progressStatus.addCustomMealPlanToCalendar.isProgressing}
          isLoading={
            progressStatus.addCustomMealPlanToCalendar.status === 'remove' &&
            progressStatus.addCustomMealPlanToCalendar.isProgressing
          }
        />
      </div>
    </div>
  )
}

export default AddReplaceConfirmation;