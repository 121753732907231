import React from "react";
import { generateAppEssentialPayload } from '../../../helpers/filterConversions';
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import EasyIcon from '../../images/DeasyIcon'
import HighIcon from '../../images/DhighIcon'
import MidIcon from '../../images/DmidIcon'
import under15MinIcon from '../../images/U15minIcon'
import under30MinIcon from '../../images/U30minIcon'
import under45MinIcon from '../../images/U45minIcon'


// generates the selected filters
export const generateFilterFromQueryString = (objectType, history) => {
  const filterFromQueryString = generateAppEssentialPayload({
    objectType: 'workout',
    queryString: history.location.search,
  })
  return filterFromQueryString
}

// genarated the collections for reicpe filters nav
export const getWorkoutTypeOptions = (workoutTypes, history) => {
  const workoutTypesOptions = workoutTypes.map(workoutType => {

    let filterFromQueryString = generateFilterFromQueryString('workout', history)

    const isSelected = filterFromQueryString.workout.filters.workoutTypes.findIndex(slug => isEqual(slug, workoutType.slug))
    let obj = {
      label: workoutType.name,
      value: workoutType.id,
      isSelected: isSelected !== -1 ? true : false,
      slug: workoutType.slug,
      image: workoutType.icon,
      featuredImage: workoutType.image,
    }
    return obj
  })

  return workoutTypesOptions
}

// genarated the categories for reicpe filters nav
export const getWorkoutEquipmentOptions = (workoutEquipments, history) => {
  const workoutEquipmentsNavOptions = workoutEquipments.map(equipment => {

    let filterFromQueryString = generateFilterFromQueryString('workout', history)

    const isSelected = filterFromQueryString.workout.filters.equipments.findIndex(slug => isEqual(slug, equipment.slug))
    let obj = {
      label: equipment.name,
      value: equipment.id,
      isSelected: isSelected !== -1 ? true : false,
      slug: equipment.slug,
      image: equipment.icon,
    }
    return obj
  })

  return workoutEquipmentsNavOptions
}

export const getWorkoutBodyPartsOptions = (workoutBodyParts, history) => {
  const workoutBodyPartsNavOptions = workoutBodyParts.map(filter => {

    let filterFromQueryString = generateFilterFromQueryString('workout', history)

    const isSelected = filterFromQueryString.workout.filters.bodyParts.findIndex(slug => isEqual(slug, filter.slug))

    let obj = {
      label: filter.name,
      value: filter.id,
      isSelected: isSelected !== -1 ? true : false,
      slug: filter.slug,
      image: filter.icon,
    }
    return obj
  })

  return workoutBodyPartsNavOptions
}


export const getDifficultyOptions = (history) => {
  let filterFromQueryString = generateFilterFromQueryString('workout', history)

  const isSelected = parentSlug => filterFromQueryString.workout.filters.difficulty.findIndex(slug => isEqual(slug, parentSlug))

  return [
    {
      label: 'easy',
      value: 'easy',
      isSelected: isSelected('easy') !== -1,
      slug: 'easy',
      svg: EasyIcon
    },
    {
      label: 'medium',
      value: 'medium',
      isSelected: isSelected('medium') !== -1,
      slug: 'medium',
      svg: MidIcon
    },
    {
      label: 'hard',
      value: 'hard',
      isSelected: isSelected('hard') !== -1,
      slug: 'hard',
      svg: HighIcon
    }
  ]
}

export const getTimeOptions = (history) => {
  let filterFromQueryString = generateFilterFromQueryString('workout', history)

  const isSelected = parentSlug => filterFromQueryString.workout.filters.time.findIndex(slug => isEqual(slug, parentSlug))

  return [
    {
      label: 'Under 30m',
      value: 'short',
      isSelected: isSelected('short') !== -1,
      slug: 'short',
      svg: under15MinIcon
    },
    {
      label: '30-45m',
      value: 'medium',
      isSelected: isSelected('medium') !== -1,
      slug: 'medium',
      svg: under30MinIcon
    },
    {
      label: '45m+',
      value: 'long',
      isSelected: isSelected('long') !== -1,
      slug: 'long',
      svg: under45MinIcon
    },
  ]
}

// genarated the favs for reicpe filters nav
export const getFavouriteBoardsNavOptions = (boards, recipeFilterName) => {
  const favouriteBoardsNavOptions = boards.map(board => {
    const isSelected = (board.slug === recipeFilterName.slug || (board.slug === null && recipeFilterName.title === 'All')) ? true : false

    let obj = {
      name: board.title,
      value: board.id,
      isSelected: isSelected,
      totalCount: board.total_count,
      slug: board.slug
    }
    return obj
  })

  return favouriteBoardsNavOptions
}

// generates the filer options like categories , collections .....
export const getFilterOptions = (
  workoutTypes,
  workoutBodyParts,
  workoutEquipments,
  difficultyOptions,
  timeOptions,
  history
) => {
  let filterFromQueryString = generateFilterFromQueryString('workout', history)

  const filterOptions = [
    {
      title: 'Workout Type',
      options: workoutTypes,
      isOptionsList: true, // to differentiate between mappable list like collections , and non mappable like wightWatchers
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.workout.filters.workoutTypes.length === 0 ? false : true
    },
    {
      title: 'Equipment',
      options: workoutEquipments,
      isOptionsList: true,
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.workout.filters.equipments.length === 0 ? false : true
    },
    {
      title: 'Body Parts',
      options: workoutBodyParts,
      isOptionsList: true,
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.workout.filters.bodyParts.length === 0 ? false : true
    },
    {
      title: 'Difficulty',
      options: difficultyOptions,
      isOptionsList: true,
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.workout.filters.difficulty.length === 0 ? false : true
    },
    {
      title: 'Time',
      options: timeOptions,
      isOptionsList: true,
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.workout.filters.time.length === 0 ? false : true
    }
  ]

  return filterOptions
}

// generated the fav options
export const getFavoriteFilterOptions = (favouriteBoardsNavOptions, recipeFilterName) => {

  const favoriteFilterOptions = {
    title: 'Favorites',
    options: favouriteBoardsNavOptions,
    isSelected: (recipeFilterName.type === 'favorite') ? true : false
  }

  return favoriteFilterOptions
}

// generates the search -> search by list
export const getSelectorList = () => {
  const selectorList = [
    {
      title: 'By Title',
      value: 'title'
    },
  ]

  return selectorList
}





