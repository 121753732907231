export default {
  PUSH_OBJECT_TO_STACK: (state, action) => {
    const {
      payload: {
        objectType,
        data
      }
    } = action;
    // stack index calculation
    let stackIndex = state.getIn(['objectDetails', objectType]).size - 1;
    
    // If the data is only available clear the state
    // If not UPDATE action will take care of clearing it
    if (data.id) {
      state = state.setIn(['objectDetails', objectType, stackIndex, 'isFetching'], false);
      state = state.setIn(['objectDetails', objectType, stackIndex, 'isFetched'], true);
    }
    
    return state
  },
  UPDATE_OBJECT_TO_STACK: (state, action) => {
    const {
      payload: {
        objectType,
        data
      }
    } = action;
    // stack index calculation
    let stackIndex = state.getIn(['objectDetails', objectType]).size - 1;
    
    // Could have already been cleared by push
    state = state.setIn(['objectDetails', objectType, stackIndex, 'isFetching'], false);
    state = state.setIn(['objectDetails', objectType, stackIndex, 'isFetched'], true);
    
    return state
  },

  POP_OBJECT_FROM_STACK: (state, action) => {
    state = state.setIn(['objectDetails', action.payload.objectType], state.getIn(['objectDetails', action.payload.objectType]).pop());
    state = state.setIn(['objectEssentials', action.payload.objectType], state.getIn(['objectEssentials', action.payload.objectType]).pop());
    return state;
  },
  GET_OBJECT_SPECIFIC_ESSENTIAL: (state, action) => {
    let stackIndex = state.getIn(['objectDetails', action.payload.objectType]).size - 1;
    // for object details
    state = state.setIn(['objectDetails', action.payload.objectType, stackIndex, 'isFetching'], true);
    state = state.setIn(['objectDetails', action.payload.objectType, stackIndex, 'isFetched'], false);

    stackIndex = state.getIn(['objectEssentials', action.payload.objectType]).size - 1;
    // for comments and notes
    state = state.setIn(['objectEssentials', action.payload.objectType, stackIndex, 'isFetching'], true);
    state = state.setIn(['objectEssentials', action.payload.objectType, stackIndex, 'isFetched'], false);
    return state
  },

  GET_OBJECT_SPECIFIC_ESSENTIAL_SUCCESS: (state, action) => {
    const {
      payload: {
        objectType
      }
    } = action;

    let stackIndex = state.getIn(['objectEssentials', objectType]).size - 1;

    state = state.setIn(['objectEssentials', objectType, stackIndex, 'isFetching'], false);
    state = state.setIn(['objectEssentials', objectType, stackIndex, 'isFetched'], true);

    // let newlist = state.getIn(['objectEssentials', objectType]).push(Map({ isFetching: false, isFetched: false }));
    // state = state.setIn(['objectEssentials', objectType], newlist);
    return state;
  },
}