import React from "react";
import '../styles/common.scss';


const VimeoIcon = (props) => {
  return (
    <>
      <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M13.38 5.30001C13.32 5.48001 13.36 5.68001 13.49 5.81001C13.62 5.95001 13.82 6.00001 14 5.94001C16 5.31001 17 7.29001 15.8 9.51001C14.49 12 13.23 13.68 12.59 13.68C11.95 13.68 11.49 12.01 10.72 9.05001C9.95 6.09001 9.97001 0.530009 6.81001 1.15001C4.30001 1.64001 1.10999 5.05001 0.18 6.11001C0.0300064 6.29001 0.0300064 6.56001 0.18 6.74001L0.570015 7.26001C0.730019 7.47001 1.03001 7.52001 1.25001 7.37001C1.85001 6.97001 2.97001 6.37001 3.44001 6.88001C4.08001 7.64001 6.53001 16.83 7.35001 18.52C8.06002 20 10.03 21.97 12.2 20.52C14.37 19.07 21.5 12.99 22.81 5.76001C24.02 -1.02999 15.28 -0.119991 13.38 5.30001Z" fill="#6B6D71" />
      </svg>

    </>
  );
};
export default VimeoIcon;
