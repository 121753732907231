
import { put, takeEvery, call } from 'redux-saga/effects'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import filterTask from './subtask/filters';

// get any filtered data except favorites 
// this will change for mobile
function* task(action) {
  try {
    yield put({
      type: actions.GET_ALL_WORKOUTS,
      payload: {
        replace: true
      }
    })
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.FETCH_FILTERED_DATA_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* fetchRecipesByCategory() {
  yield takeEvery(actions.APPLIED_SINGLE_FILTER_WORKOUTS, task)
}