import React, { useReducer, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { PayPalButton } from "react-paypal-button-v2";
import { connect } from "react-redux";
import Spinner from "react-spinner-material";
import axios from "axios";
import { Helmet } from "react-helmet";
import Radiobox from "../../common/radiobox";
import { isNil } from "lodash";
import { shop, user } from "../../../helpers/api";
import toJS from "../../../helpers/toJS";
import moment from "moment";
import Selector from "../../common/selector";
import CustomInput from "../../common/customInput";

import ChooseDate from "../../mealPlan/components/premadeModal/chooseDate";
import Popover, { ArrowContainer } from "react-tiny-popover";
import {
  showToastMessage,
  appEssentialFetch,
  addSentryError,
} from "../../../actions";
import coverImage from "../../images/membership-heroImg.png";
import CalenderIcon from "../../images/CalenderIcon";
import pushToSentry from "../../../helpers/pushToSentry";

import { getUserDetails } from "../../../selectors";

import SuccessMessage from "./giftMembershipSuccess";

import { generateAppEssentialPayload } from "../../../helpers/filterConversions";
import { themeTextRed } from "../../../helpers/theme";

let debounce;

const initialState = {
  recipient: {
    name: "",
    email: "",
    nameError: null,
    emailError: null,
    nameFocus: false,
    emailFocus: false,
  },
  sender: {
    name: "",
    email: "",
    nameError: null,
    emailError: null,
    nameFocus: false,
    emailFocus: false,
  },
  giftDetails: "12",
  giftSendDate: moment().format("YYYY-MM-DD"),
  message: "",
  emailVerifying: false,
  emailVerified: false,
  emailVerifyError: null,

  recipientEmailError: null,

  isFormValid: false,
  formSubmitStatus: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_RECIPIENT":
      const finalState = {
        ...state,
        recipient: action.payload,
      };
      return finalState;
    case "UPDATE_SENDER":
      return {
        ...state,
        sender: action.payload,
      };
    case "UPDATE_GIFT_DETAILS":
      return {
        ...state,
        giftDetails: action.payload.giftDetails,
      };
    case "UPDATE_GIFT_SEND_DATE":
      return {
        ...state,
        giftSendDate: action.payload.giftSendDate,
      };
    case "UPDATE_MESSAGE":
      return {
        ...state,
        message: action.payload.message,
      };
    case "FORM_SUBMIT_UPDATE":
      return {
        ...state,
        formSubmitStatus: action.payload.formSubmitStatus,
      };
    case "VALIDATE_EMAIL":
      return {
        ...state,
        emailVerifying: action.payload.emailVerifying,
        emailVerified: action.payload.emailVerified,
        emailVerifyError: action.payload.emailVerifyError,
      };
    case "VALIDATE_FORM":
      let sender = {
        emailError: null,
        nameError: null,
      };
      let recipient = {
        emailError: null,
        nameError: null,
      };
      let isFormValid = true;
      if (
        !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
          state.sender.email
        )
      ) {
        sender.emailError = "Enter a valid email Id";
      }
      if (state.sender.name.length < 1) {
        sender.nameError = "Name is required !";
      }
      if (
        !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
          state.recipient.email
        ) &&
        !state.emailVerifyError
      ) {
        recipient.emailError = "Enter a valid email Id";
      }
      if (state.recipient.name.length < 1) {
        recipient.nameError = "Name is required !";
      }
      if (
        sender.emailError ||
        sender.nameError ||
        recipient.emailError ||
        recipient.nameError
      ) {
        isFormValid = false;
      }
      return {
        ...state,
        sender: {
          ...state.sender,
          emailError: sender.emailError,
          nameError: sender.nameError,
        },
        recipient: {
          ...state.recipient,
          emailError: recipient.emailError,
          nameError: recipient.nameError,
        },
        isFormValid,
      };
    case "ON_BLUR":
      return {
        ...state,
        sender: {
          ...state.sender,
          emailFocus:
            action.payload.sender &&
              action.payload.sender.emailFocus &&
              !state.sender.emailFocus
              ? true
              : state.sender.emailFocus,
          nameFocus:
            action.payload.sender &&
              action.payload.sender.nameFocus &&
              !state.sender.nameFocus
              ? true
              : state.sender.nameFocus,
        },
        recipient: {
          ...state.recipient,
          emailFocus:
            action.payload.recipient &&
              action.payload.recipient.emailFocus &&
              !state.recipient.emailFocus
              ? true
              : state.recipient.emailFocus,
          nameFocus:
            action.payload.recipient &&
              action.payload.recipient.nameFocus &&
              !state.recipient.nameFocus
              ? true
              : state.recipient.nameFocus,
        },
      };
    case "RECIPIENT_EMAIL_ERROR":
      return {
        ...state,
        recipientEmailError: action.payload.error,
      };
    case "RESET":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const GiftMembership = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [dateModal, setDateModal] = useState(false);
  const [paymentType, setPaymentType] = useState("paypal");
  const [isGiftSent, setIsGiftSent] = useState({
    isSent: false,
    giftData: null,
  });

  useEffect(() => {
    props.appEssentialFetch(generateAppEssentialPayload());
  }, []);

  useEffect(() => {
    if (props.userDetails.isLoggedIn) {
      dispatch({
        type: "UPDATE_SENDER",
        payload: {
          ...state.sender,
          name: props.userDetails.details.name,
          email: props.userDetails.details.email,
        },
      });
    }
  }, [props.userDetails.isLoggedIn]);

  async function onRecipientEmailChange(email) {
    dispatch({
      type: "UPDATE_RECIPIENT",
      payload: {
        ...state.recipient,
        email: email,
      },
    });
    if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)) {
      dispatch({
        type: "VALIDATE_EMAIL",
        payload: {
          emailVerifying: true,
          emailVerified: false,
          emailVerifyError: null,
        },
      });
      if (!debounce) {
        debounce = setTimeout(() => {
          validateEmail(email);
        }, 500);
      } else {
        clearTimeout(debounce);
        debounce = setTimeout(() => {
          validateEmail(email);
        }, 500);
      }
    } else {
      dispatch({
        type: "RECIPIENT_EMAIL_ERROR",
        payload: { error: "Enter a valid email Id" },
      });
    }
  }

  const validateEmail = (email) => {
    axios({
      method: "POST",
      url: user.getAccountOrigin(),
      data: {
        email: email,
      },
    })
      .then((response) => {
        if (
          (response &&
            response.data &&
            response.data.status === "account_exists" &&
            response.data.origin === "stripe") ||
          response.data.status === "account_not_exists"
        ) {
          let emailError = null;
          dispatch({
            type: "RECIPIENT_EMAIL_ERROR",
            payload: { error: emailError },
          });
          dispatch({
            type: "VALIDATE_EMAIL",
            payload: {
              emailVerifying: false,
              emailVerified: true,
              emailVerifyError: emailError,
            },
          });
        } else {
          let emailError = "Recipient can`t receive gift";
          dispatch({
            type: "RECIPIENT_EMAIL_ERROR",
            payload: { error: emailError },
          });
          dispatch({
            type: "VALIDATE_EMAIL",
            payload: {
              emailVerifying: false,
              emailVerified: true,
              emailVerifyError: emailError,
            },
          });
        }
      })
      .catch((e) => {
        const eventId = pushToSentry(e);
        let emailError = "Recipient can`t receive gift";
        dispatch({
          type: "RECIPIENT_EMAIL_ERROR",
          payload: { error: emailError },
        });
        dispatch({
          type: "VALIDATE_EMAIL",
          payload: {
            emailVerifying: false,
            emailVerified: true,
            emailVerifyError: emailError,
          },
        });
      });
  };

  const onClickSubmitButton = (token, type = "stripe") => {
    dispatch({
      type: "FORM_SUBMIT_UPDATE",
      payload: {
        formSubmitStatus: true,
      },
    });
    if (state.isFormValid) {
      if (type === "stripe") {
        const stripe_token = token;
        const deliveredAt =
          moment()
            .startOf("day")
            .format("YYYY/MM/DD") ===
            moment(state.giftSendDate).format("YYYY/MM/DD")
            ? "today"
            : moment(state.giftSendDate).format("MM/DD/YYYY");
        axios({
          method: "POST",
          url: shop.createGiftMembership(),
          data: {
            from_name: state.sender.name,
            from_email: state.sender.email,
            to_name: state.recipient.name,
            to_email: state.recipient.email,
            months: state.giftDetails,
            stripe_token,
            gift_message: state.message,
            delivered_at: deliveredAt,
          },
        })
          .then((response) => {
            dispatch({
              type: "FORM_SUBMIT_UPDATE",
              payload: {
                formSubmitStatus: false,
              },
            });
            setIsGiftSent({ isSent: true, giftData: state });
          })
          .catch((e) => {
            const eventId = pushToSentry(e);
            dispatch({
              type: "FORM_SUBMIT_UPDATE",
              payload: {
                formSubmitStatus: false,
              },
            });
            props.showToastMessage(
              `❗️Sending gift failed!. Please change payment method or check recipent.`,
              "error"
            );
          });
      } else if (type === "paypal") {
        paypal_token = token;
        const deliveredAt =
          moment()
            .startOf("day")
            .format("YYYY/MM/DD") ===
            moment(state.giftSendDate).format("YYYY/MM/DD")
            ? "today"
            : moment(state.giftSendDate).format("MM/DD/YYYY");
        axios({
          method: "POST",
          url: shop.createGiftMembership(),
          data: {
            from_name: state.sender.name,
            from_email: state.sender.email,
            to_name: state.recipient.name,
            to_email: state.recipient.email,
            months: state.giftDetails,
            paypal_token,
            gift_message: state.message,
            delivered_at: deliveredAt,
          },
        })
          .then((response) => {
            dispatch({
              type: "FORM_SUBMIT_UPDATE",
              payload: {
                formSubmitStatus: false,
              },
            });
            setIsGiftSent({ isSent: true, giftData: state });
          })
          .catch((e) => {
            const eventId = pushToSentry(e);

            dispatch({
              type: "FORM_SUBMIT_UPDATE",
              payload: {
                formSubmitStatus: false,
              },
            });
            props.showToastMessage(
              `❗️ Something went wrong!. Please change payment method or check recipent.`,
              "error"
            );
          });
      }
    }
  };

  const getDatePickerContent = () => {
    return (
      <div className="datePopover">
        <ChooseDate
          aheadDays={28}
          date={moment(state.giftSendDate).format("YYYY-MM-DD")}
          updateDate={(giftSendDate) => {
            setDateModal(false);
            dispatch({
              type: "UPDATE_GIFT_SEND_DATE",
              payload: {
                giftSendDate,
              },
            });
          }}
        />
      </div>
    );
  };
  let giftAmount = "42";

  return (
    <>
      <Helmet>
        <title>Gift of Healthy Living | Dashing Dish</title>
        <meta name="description" content="Dashingdish Gift Membership page" />
      </Helmet>
      {isGiftSent.isSent ? (
        <SuccessMessage isGiftSent={isGiftSent} />
      ) : (
        <main>
          <section
            className="gift-membership"
            style={{
              backgroundImage: `url(${coverImage})`,
            }}
          >
            <div className="container">
              <div className="gift-container">
                <h2 className="gift-header">Gift a membership</h2>
                <p>
                  Join thousands of people empowered to make lasting, healthy
                  changes with Dashing Dish.
                </p>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="input-row">
                    <h2 className="input-group-header">Recipients info:</h2>
                    <div className="input-group">
                      <div className="input-set">
                        <CustomInput
                          type="name"
                          label="Name"
                          placeholder="Type here"
                          value={state.recipient.name}
                          onChange={(e) => {
                            dispatch({
                              type: "UPDATE_RECIPIENT",
                              payload: {
                                ...state.recipient,
                                name: e.target.value,
                              },
                            });
                            dispatch({ type: "VALIDATE_FORM" });
                          }}
                          onBlur={() => {
                            dispatch({ type: "VALIDATE_FORM" });
                            dispatch({
                              type: "ON_BLUR",
                              payload: {
                                recipient: {
                                  nameFocus: true,
                                },
                              },
                            });
                          }}
                          errorMessage={
                            !isNil(state.recipient.nameError) &&
                              state.recipient.nameFocus
                              ? state.recipient.nameError
                              : null
                          }
                          isLastElementOfForm={true}
                          formLastElementAction={() => handleResetPassword()}
                        />
                      </div>
                      <div
                        className="input-set"
                        style={{ position: "relative" }}
                      >
                        <div
                          className="gift-email-spinner"
                          style={{ position: "absolute" }}
                        >
                          {state.emailVerifying && (
                            <Spinner
                              className="spinner"
                              size={15}
                              spinnerColor={themeTextRed}
                              spinnerWidth={2}
                              visible={true}
                            />
                          )}
                        </div>
                        <CustomInput
                          type="email"
                          label="Email"
                          placeholder="Type here"
                          value={state.recipient.email}
                          onChange={(e) =>
                            onRecipientEmailChange(e.target.value)
                          }
                          errorMessage={
                            !isNil(state.recipientEmailError)
                              ? state.recipientEmailError
                              : null
                          }
                          isLastElementOfForm={true}
                          formLastElementAction={() => handleResetPassword()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-row">
                    <h2 className="input-group-header">Your Info:</h2>
                    <div className="input-group">
                      <div className="input-set">
                        <CustomInput
                          type="name"
                          label="Name"
                          placeholder="Type here"
                          value={state.sender.name}
                          onChange={(e) => {
                            dispatch({
                              type: "UPDATE_SENDER",
                              payload: {
                                ...state.sender,
                                name: e.target.value,
                              },
                            });
                            dispatch({ type: "VALIDATE_FORM" });
                          }}
                          onBlur={() => {
                            dispatch({ type: "VALIDATE_FORM" });
                            dispatch({
                              type: "ON_BLUR",
                              payload: {
                                sender: {
                                  nameFocus: true,
                                },
                              },
                            });
                          }}
                          errorMessage={
                            !isNil(state.sender.nameError) &&
                              state.sender.nameFocus
                              ? state.sender.nameError
                              : null
                          }
                          isLastElementOfForm={true}
                          formLastElementAction={() => handleResetPassword()}
                        />
                      </div>
                      <div className="input-set">
                        <CustomInput
                          type="email"
                          label="Email"
                          placeholder="Type here"
                          value={state.sender.email}
                          onChange={(e) => {
                            dispatch({
                              type: "UPDATE_SENDER",
                              payload: {
                                ...state.sender,
                                email: e.target.value,
                              },
                            });
                            dispatch({ type: "VALIDATE_FORM" });
                          }}
                          onBlur={() => {
                            dispatch({ type: "VALIDATE_FORM" });
                            dispatch({
                              type: "ON_BLUR",
                              payload: {
                                sender: {
                                  emailFocus: true,
                                },
                              },
                            });
                          }}
                          errorMessage={
                            !isNil(state.sender.emailError) &&
                              state.sender.emailFocus
                              ? state.sender.emailError
                              : null
                          }
                          isLastElementOfForm={true}
                          formLastElementAction={() => handleResetPassword()}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-row">
                    <div className="input-group">
                      <div className="input-group-set">
                        <h2 className="input-group-header">Gift details:</h2>
                        <div className="input-group">
                          <div className="amount-picker">
                            <div className="amount">
                              <span className="static-value">Amount:</span>
                              <span>$ {giftAmount}</span>
                              <Selector
                                disabled
                                selectorList={[
                                  { title: "12 mo", value: "12" },
                                ]}
                                value={state.giftDetails}
                                onSelectValue={(value) => {
                                  dispatch({
                                    type: "UPDATE_GIFT_DETAILS",
                                    payload: {
                                      giftDetails: value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="input-group-set">
                        <div className="date-pick">
                          <div className="input-group">
                            <Popover
                              isOpen={dateModal}
                              position={["top", "bottom"]}
                              padding={10}
                              disableReposition={true}
                              onClickOutside={() => setDateModal(false)}
                              containerClassName={""}
                              popOverContent={getDatePickerContent()}
                              containerStyle={{ zIndex: "99" }}
                              content={({
                                position,
                                targetRect,
                                popoverRect,
                              }) => {
                                return (
                                  <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                                    position={position}
                                    targetRect={targetRect}
                                    popoverRect={popoverRect}
                                    arrowColor={"rgba(0, 0, 0, 0.1)"}
                                    arrowSize={10}
                                    arrowStyle={{ opacity: 0.7 }}
                                  >
                                    {getDatePickerContent()}
                                  </ArrowContainer>
                                );
                              }}
                              align={"center"}
                            >
                              {
                                <div
                                  className="gift-calendar"
                                  onClick={() => setDateModal(!dateModal)}
                                >
                                  <span className="static-value">Send on:</span>
                                  <span className="select-date">
                                    {state.giftSendDate}
                                  </span>
                                  <CalenderIcon className="static-icon"></CalenderIcon>
                                </div>
                              }
                            </Popover>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="message-container">
                    <label className="static-value">{"Message:"}</label>
                    <textarea
                      className="messageInput"
                      placeholder="Type message here."
                      value={state.message}
                      onChange={(event) =>
                        dispatch({
                          type: "UPDATE_MESSAGE",
                          payload: {
                            message: event.target.value,
                          },
                        })
                      }
                    ></textarea>
                  </div>
                  {
                    <>
                      <div className="payment-options">
                        <span>Payment info: &nbsp; &nbsp;</span>
                        <div className="billing-duration membership-billing">
                          <Radiobox
                            type="checkbox"
                            label="Paypal"
                            checked={paymentType === "paypal"}
                            onSelect={() => setPaymentType("paypal")}
                          />
                        </div>
                      </div>
                      {/* {paymentType === "paypal" ? (
                        <div className="paypal-btns">
                          <PayPalButton
                            amount={giftAmount}
                            onSuccess={(details, data) => {
                              if (details.status === "COMPLETED") {
                                onClickSubmitButton(details.id, "paypal");
                              } else {
                                props.showToastMessage(
                                  `Could not complete payment`,
                                  "error"
                                );
                              }
                              return null;
                            }}
                            options={{
                              clientId: process.env.PAYPAL_CLIENT_ID,
                            }}
                          />
                        </div>
                      ) : null} */}
                      <button
                        onClick={() => onClickSubmitButton('self', "paypal")}
                      >Click here</button>
                    </>
                  }
                </form>
              </div>
            </div>
          </section>
        </main>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

const dispatchActionToProps = (dispatch) => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(GiftMembership));
