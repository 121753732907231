import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet"
import toJS from '../../../helpers/toJS';
import { getGroceryListByType, getGroceryListViewType } from "../../../selectors";
import { appEssentialFetch } from "../../../actions";
import Radiobox from "../../common/radiobox";
import PrintIcon from "../../images/PrintIcon";
import Logo from "../../images/Logo";



const Print = (props) => {
  useEffect(() => {
    props.appEssentialFetch({
      refetchGroceryList: true,
    })
  }, [])
  return (
    <div className="container">
      <Helmet>
        <title>Print GroceryList | Dashing Dish</title>
        <meta name="description" content="Dashingdish Print GroceryList page" />
      </Helmet>
      <div className="print-page_back-link">
        <a href="/grocery-list" className="">‹ Back to the Grocery List</a>
      </div>
      <div className="print-page_header">
        <Logo />
        <figure className="print-button" onClick={(e) => window.print()}>
          <PrintIcon />
        </figure>
      </div>
      <div className="print-page_content">
        <div className="print-page_title">
          {
            props.groceryList.categories.map((category, index) => {
              return (
                <div className="print-grocery-list_category">
                  <h5 className="category-name">{category.name}</h5>
                  {
                    category.items.map((item, index) => {
                      return (
                        <div className="group-list" key={index}>
                          <Radiobox
                            type={'checkbox'}
                          />
                          <p className="item-name">{item.item}</p>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  groceryList: getGroceryListByType(state),
  activeGroceryType: getGroceryListViewType(state),
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(Print));
