// Produciton APIs
export const baseUrl = 'https://unified-dd-backend.herokuapp.com' || process.env.API_URL;
export const shareUrl = "https://dashingdish.com";

import axios from 'axios';
const getUrl = (url) => `${baseUrl}${url}`;

export const user = {
  get: () => getUrl("/api/users/"),
  createUser: () => getUrl("/api/users/create"),
  edit: (user_id) => getUrl(`/api/users/${user_id}`),
  delete: () => getUrl("/api/users/"),
  reverseCancelSubscription: (userId) =>
    getUrl(`/api/v1/users/${userId}/membership`),
  changePlan: (userId) => getUrl(`/api/v1/users/${userId}/membership`),
  getAccountDetails: () => getUrl("/api/"),
  getAccountOrigin: () => getUrl("/api/users/get-account-origin"),
  updateSubscription: (userId) => getUrl(`/api/users/${userId}/membership`),
  getPaypalSubscriptionDetails: () =>
    getUrl(`/api/users/paypal-subscription-details`),
  getIOSSubscriptionDetails: () =>
    getUrl(`/api/users/ios-subscription-details`),
  deleteSubscription: (userId) => getUrl(`/api/users/${userId}/membership`),
  verifyEmail: () => getUrl(`/api/users/check_email_vailidity`),
};

export const mobileUser = {
  createMembership: (user_id) => getUrl(`/api/users/${user_id}/membership`),
};

export const auth = {
  login: () => getUrl("/api/users/log-in/"),
  register: () => getUrl("/api/users/register/"),
  assignPlanToUser: () => getUrl("/api/users/plan-assign"),
  createSubscription: () => getUrl(`/api/users/create-subscription`),
  getAccountDetails: () => getUrl("/api/users/account"),
  getPlans: (newPlans) => newPlans ? getUrl("/api/plans?include_web_only=true") : getUrl("/api/plans"),
  getProrationCosts: () => getUrl("/api/users/prorate-plan-details"),
  resetPasswordInitiate: () => getUrl("/api/v1/password-reset/initiate"),
  checkPasswordResetExpiryStatus: () =>
    getUrl("/api/v1/password-reset/validate-expired-link"),
  resetPassword: () => getUrl("/api/v1/password-reset/password-reset-confirm"),
  changePaymentType: () => getUrl("/api/v1/users/payment-method"),
  fetchGifts: () => getUrl("/api/v1/gift-memberships"),
  applyGift: () => getUrl("/api/v1/users/apply-gift-code"),
  validatePromo: () => getUrl("/api/coupons/validate"),
  registerUnified: () => getUrl(`/api/users/register-unified`),
  getGrandfatheredPlans: () => getUrl(`/api/v1/grandfathered-subscription`),
  deletePaypalSubscription: () => getUrl(`/api/v1/cancel-paypal-subscription`)
};

export const referral = {
  validateReferral: () => getUrl("/api/referral-code-validity"),
};

export const membershipGift = {
  getGiftInfo: () => getUrl("/api/gift-user-status"),
};

export const shop = {
  createGiftMembership: () => getUrl("/api/gift-memberships/new"),
  renewSubscription: () => getUrl("/api/account/renew"),
};

export const taxonomies = {
  get: () => getUrl("/api/v1/taxonomies/"),
};
export const recipes = {
  getAll: (limit = 25, offset = 1, queryParams = "") =>
    getUrl(`/api/recipes/?limit=${limit}&offset=${offset}${queryParams}`),
  get: (slug) =>
    slug
      ? getUrl(`/api/recipes/${slug}?&timestamp=${new Date().getTime()}`)
      : getUrl("/api/recipes/"),
  getFoodTypes: () => getUrl(`/api/recipes/foodtypes/`),
  getDietaries: () => getUrl(`/api/recipes/dietaries/`),
  getSpecialities: () => getUrl(`/api/recipes/specialities/`),
  getCollections: () => getUrl(`/api/recipes/collections/`),
  // one route for all filter
};

export const workouts = {
  getAll: (limit = 10, offset = 0, queryParams = "") =>
    getUrl(`/api/workouts/?limit=${limit}&offset=${offset}${queryParams}`),
  get: (slug) =>
    slug
      ? getUrl(`/api/workouts/${slug}?&timestamp=${new Date().getTime()}`)
      : getUrl("/api/workouts/"),
  getWorkoutTypes: () => getUrl(`/api/workouts/workout-types/`),
  getEquipments: () => getUrl(`/api/workouts/equipments`),
  getBodyParts: () => getUrl(`/api/workouts/body-parts/`),
  getCollections: () => getUrl(`/api/workouts/collections/`),
  getWorkoutsInCollection: (limit, offset, collectionId) =>
    getUrl(
      `/api/workouts/?limit=${limit}&offset=${offset}&collection=${collectionId}`
    ),
  // one route for all filter
  getWorkoutsfilteredData: (limit = 10, offset = 1, queryParms) =>
    getUrl(`/api/workouts/?limit=${limit}&offset=${offset}&${queryParms}`),
};

export const grocery = {
  get: () => getUrl("/api/v2/grocery-list"),
  post: () => getUrl("/api/grocery-list"),
  update: () => getUrl("/api/v2/grocery-list/items/"),
  delete: () => getUrl("/api/v2/grocery-list/items/"),
  deleteItem: (groceryListId, itemId) =>
    getUrl(`/api/v2/grocery-list/${groceryListId}/items/${itemId}/`),
  deleteItems: (groceryListId, itemId) =>
    getUrl(`/api/v2/grocery-list/destroy-specific/`),
};

export const comments = {
  get: (id, objectType) =>
    id
      ? getUrl(
        `/api/v2/comments?object_type=${objectType}&object_id=${id}&limit=100`
      )
      : getUrl("/api/comments/"),
  post: () => getUrl("/api/comments/"),
};

export const notes = {
  get: (objectType, objectId) =>
    getUrl(`/api/notes?object_type=${objectType}&object_id=${objectId}/`),
  patch: (id) => getUrl(`/api/notes/${id}/`),
  post: () => getUrl(`/api/notes/`),
};

export const favourites = {
  get: () => getUrl("/api/v1/favorites/"),
  post: () => getUrl("/api/v1/favorites/"),
  delete: () => getUrl("/api/v1/favorites/"),
  // Page is still based on offset. Will always add + 1 to balance the API data
  getAllFavoritesInBoard: (objectType, boardId, limit = 25, page = 0) =>
    getUrl(
      `/api/v1/favorites/?object_type=${objectType}&board_id=${boardId ||
      ""}&per_page=${limit}&page=${page + 1}`
    ),
};

export const boards = {
  get: (boardType) => getUrl(`/api/favorites/boards?object_type=${boardType}`),
  post: () => getUrl("/api/favorites/boards/"),
  delete: (id) => getUrl(`/api/v1/favorites/boards/${id}/`),
  patch: (id) => getUrl(`/api/v1/favorites/boards/${id}`),
};

export const mealplan = {
  get: (anchorDate, endDate) =>
    getUrl(`/api/calendar?from_date=${anchorDate}&to_date=${endDate}`),
  post: () => getUrl(`/api/v1/calendar`),
  delete: (meal_id) => getUrl(`/api/v1/calendar/${meal_id}`),
  patch: (meal_id) => getUrl(`/api/v1/calendar/${meal_id}`),
  deleteV2: () => getUrl(`/api/v1/calendar-delete-v2`),
  getCategories: () => getUrl("/api/v1/meal-plan-categories"),
};

// search
export const searches = {
  searchRecipe: (search, search_by) =>
    getUrl(
      `/api/recipes-instant-filter?search=${search}&search_by=${search_by}&timestamp=${new Date().getTime()}`
    ),
  filterCount: (queryParams) =>
    getUrl(`/api/recipes-instant-filter?${queryParams}`),
  searchWorkouts: (queryParams) =>
    getUrl(`/api/workouts/workouts-instant-search?${queryParams}`),
  searchBlogs: (queryParams) =>
    getUrl(`/api/blogs-instant-search?${queryParams}`),
  searchVideos: (queryParams) =>
    getUrl(`/api/videos-instant-search?${queryParams}`),
};

export const blogs = {
  getCategories: () => getUrl(`/api/blogs/categories`),
  getAll: (limit = 25, offset = 0, queryParams = "") =>
    getUrl(`/api/blogs/?limit=${limit}&offset=${offset}${queryParams}`),
  get: (slug) =>
    getUrl(`/api/blogs/${slug}?&timestamp=${new Date().getTime()}`),
};

export const videos = {
  getCategories: () => getUrl(`/api/videos/categories`),
  get: (slug) =>
    getUrl(`/api/videos/${slug}?&timestamp=${new Date().getTime()}`),
  getCollections: () => getUrl(`/api/videos/collections`),
  getAll: (limit = 25, offset = 0, queryParams = "") =>
    getUrl(`/api/videos/?limit=${limit}&offset=${offset}${queryParams}`),
  getVideoGateStatus: (id) => getUrl(`videos/gate_status?video_id=${id}`),
};

export const mealPlan = {
  addToCalendar: () => getUrl("/api/calendar/assign-plan"),
  deleteCalendarDay: () => getUrl("/api/meal-plans/delete-day"),
  saveCalendarDay: () => getUrl("/api/saved-calendar-day"),
  getSavedCalendarDays: (limit, offset) =>
    getUrl(`/api/saved-calendar-day?limit=${limit}&offset=${offset}`),
  loadSavedCalendar: () => getUrl(`/api/load-saved-calendar`),
  addMealToMultipleDays: () => getUrl(`/api/v1/calendar-multiple-days-add`),
  captureEmail: () => getUrl('/api/v1/meal-plan-giveaway')
};

export const trending = {
  recipes: (type) => getUrl(`/api/trending/recipes/?type=${type}`),
  workouts: (type) => getUrl(`/api/trending/workouts/?type=${type}`),
  videos: (type) => getUrl(`/api/trending/videos/?type=${type}`),
  blogs: (type) => getUrl(`/api/trending/blogs/?type=${type}`),
};

export const faqs = {
  get: () => getUrl(`/api/faqs`),
  postQuestion: () => getUrl(`/api/faq/ask`),
};

export const personalizedBlogs = {
  get: (offset = 0, order = 'desc', additionalParam = '', status = true) => getUrl(`/api/v1/personalized_blogs?offset=${offset}&order=${order}${status ? '&status=published&' : ''}${additionalParam}`),
  show: (slug) => getUrl(`/api/v1/personalized_blogs/${slug}`),
}

export const personalizedVideos = {
  get: (offset = 0, filter, additionalParam = '', status = true) => getUrl(`/api/v1/personalized_videos?offset=${offset}${status ? '&status=published&' : ''}&video_type=${filter}${additionalParam}`),
  show: (slug) => getUrl(`/api/v1/personalized_videos/${slug}`),
}

export const meetings = {
  get: () => getUrl(`/api/v1/upcoming_meetings`),
  show: (slug) => getUrl(`/api/v1/personalized_videos/${slug}`),
}

export const nourishMonthlyChallenge = {
  get: () => getUrl(`/api/v1/personalized_documents`)
}

export const getUpcomingMeetings = () => axios.get(`${baseUrl}/api/v1/upcoming_meetings`);

