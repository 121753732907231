import axios from 'axios'
import { showToastMessage, showGroceryListLimitNotification } from '../actions';
import { put, takeEvery, call, select } from 'redux-saga/effects'
// import { Sentry } from 'react-native-sentry';
import toString from 'lodash/toString'
import isNil from "lodash/isNil"
import { post } from '../helpers/requests'
import { grocery } from '../helpers/api'
import { getToken, getCurrentRecipeId } from './selectors'
import * as actions from '../actions/actionTypes'
import groceryTask from './subtask/grocery'
import pushToSentry from '../helpers/pushToSentry';
import { getCategoryId } from '../helpers/categories';


function* task(action) {
  try {
    const limit = 500;
    // TODO: Iterate the group

    const { ingredientData } = action.payload

    // Filtering to get  only the ingredient item name

    const filteredIngredientData = (ingredientData || [])
      .filter(i => !i.ingredient.header)
      .map((i) => {
        const ingredient = i;
        const recipeId = i.recipeId;

        let itemName = i.ingredient?.ingredient?.display_singular_name ? i.ingredient?.ingredient?.display_singular_name : '';
        let amount = i.ingredient?.ingredient_unit?.display_singular_name ? i.ingredient?.ingredient_unit?.display_singular_name : '';
        let quantity = i.ingredient?.quantity ? i.ingredient?.quantity : '';

        let addIngredientName = `${quantity} ${amount} ${itemName}`;

        let itemObject = {
          item: addIngredientName,
          category_id: getCategoryId(i.ingredient?.category),
          recipe_id: recipeId,
          ingredient_name: itemName,
          amount: toString(quantity),
          ingredient_unit: toString(amount)
        }

        return itemObject
      }, [])


    let groceryResponse = yield call(groceryTask.addToGroceryList, {
      items: filteredIngredientData,
      limit: limit
    });


    if (!isNil(groceryResponse.isLimitExceeded) && groceryResponse.isLimitExceeded) {
      yield put(showGroceryListLimitNotification(groceryResponse.items));
    } else {
      yield put({
        type: actions.ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST_SUCCESS,
        payload: {
          success: true,
          addedIngredients: groceryResponse.data.new_items
        }
      })
      yield put(showToastMessage(`✅ ${groceryResponse.data.new_items.length} items added.`, 'success', 'addToGroceryList'));
    }
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in updating groceries`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addToGroceryList() {
  yield takeEvery(actions.ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST, task)
}