import { ASSIGN_PLAN_TO_USER } from './actionTypes'

export default (
  planId,
  duration,
  userId = null
) => ({
  type: ASSIGN_PLAN_TO_USER,
  payload: {
    planId,
    duration,
    userId
  }
})