// TODO: move this to fetch status
import createReducer from "../helpers/createReducers";

import { fromJS, List } from "immutable";

const initialState = fromJS({
  isRegistering: false,
  isRegistered: false,
  registeredUserData: {},
  errorMessage: null,
  assignedToPlan: false,
  assignedPlanId: null,
  planDuration: null,
  password: null,
  loggedIn: false,
  newsletter: false,
  plans: [],
  giftCode: null,
  referralCode: null,
  referredBy: null,
  isRegistrationComplete: false,
});

export default createReducer(initialState, {
  CREATE_NEW_USER: (state, action) => {
    state = state.setIn(["isRegistering"], true);
    state = state.setIn(["isRegistered"], false);
    state = state.setIn(["errorMessage"], null);
    state = state.setIn(["password"], action.payload.password);
    return state;
  },
  CREATE_NEW_USER_SUCCESS: (state, action) => {
    state = state.setIn(["isRegistering"], false);
    state = state.setIn(["isRegistered"], true);
    state = state.setIn(
      ["registeredUserData"],
      fromJS(action.payload.loginData)
    );
    state = state.setIn(["errorMessage"], null);
    state = state.setIn(["newsletter"], fromJS(action.payload.newsletter));
    state = state.setIn(["plans"], action.payload.plans);

    return state;
  },

  CREATE_NEW_USER_FAILURE: (state, action) => {
    state = state.setIn(["isRegistering"], false);
    state = state.setIn(["isRegistered"], false);
    state = state.setIn(["errorMessage"], action.payload.error);
    return state;
  },

  ASSIGN_PLAN_TO_USER_SUCCESS: (state, action) => {
    state = state.setIn(["isRegistering"], false);
    state = state.setIn(["isRegistered"], true);
    state = state.setIn(["planDuration"], action.payload.duration);
    state = state.setIn(["assignedToPlan"], true);
    state = state.setIn(["assignedPlanId"], action.payload.planId);
    return state;
  },

  ASSIGN_PLAN_IN_COMPLETE_REGISTRATION: (state, action) => {
    state = state.setIn(["isRegistering"], true);

    return state;
  },

  COMPLETE_REGISTRATION: (state, action) => {
    state = state.setIn(["isRegistering"], true);

    return state;
  },
  COMPLETE_REGISTRATION_SUCCESS: (state, action) => {
    state = state.setIn(["isRegistering"], false);
    state = state.setIn(["isRegistered"], true);
    state = state.setIn(["isRegistrationComplete"], true);
    state = state.setIn(["errorMessage"], null);
    return state;
  },
  LOGIN_USER: (state, action) => {
    state = state.setIn(["loggedIn"], false);
    return state;
  },
  LOGIN_USER_SUCCESS: (state, action) => {
    state = state.setIn(["loggedIn"], true);
    return state;
  },
  COMPLETE_REGISTRATION_FAILURE: (state, action) => {
    state = state.setIn(["isRegistering"], false);
    state = state.setIn(["isRegistered"], false);
    state = state.setIn(["isRegistrationComplete"], false);
    state = state.setIn(
      ["errorMessage"],
      "Register failed. Please try with different card / method"
    );
    state = state.setIn(["loggedIn"], false);
    return state;
  },
  SET_PLAN_ASSIGN_STATUS: (state, action) => {
    state = state.setIn(["assignedToPlan"], action.payload.status);

    return state;
  },
  RESET_REGISTER_STATE: (state, action) => {
    let giftCode = state.getIn(["giftCode"]);
    let referralCode = state.getIn(["referralCode"]);
    let toEmail = state.getIn(["toEmail"]);
    state = initialState;
    state = state.setIn(["giftCode"], giftCode);
    state = state.setIn(["referralCode"], referralCode);
    state = state.setIn(["toEmail"], toEmail);
    return state;
  },

  REMOVE_GIFT_CODE: (state, action) => {
    state = state.setIn(["giftCode"], null);
    return state;
  },

  SAVE_GIFT_CODE: (state, action) => {
    state = state.setIn(["giftCode"], action.payload);
    return state;
  },

  SAVE_REFERRAL_CODE: (state, action) => {
    state = state.setIn(["referralCode"], action.payload.referralCode);
    return state;
  },

  REMOVE_REFERRAL_CODE: (state, action) => {
    state = state.setIn(["referralCode"], null);
    return state;
  },
});
