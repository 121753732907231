import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getFilteredVideoOffset, getUnFilteredVideoOffset, getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry'
import videosTask from './subtask/videos';

import retrier from './process/retrier'

import { globalFetchLimit } from '../helpers/filterConversions';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  try {
    yield put({
      type: actions.VIDEOS_LIST_FETCH_REQUEST,
      payload: {
        pagination: true,
      }
    });

    let tokenByAuthenticatedUser = yield select(getToken) // get current authEssential (which is loginInUser in store)

    const video = {
      limit: globalFetchLimit,
      filterGroup: action.payload.filterGroup,
    }
    if (action.payload.filterGroup == 'unFiltered') {
      video.offset = yield select(getUnFilteredVideoOffset)
    } else {
      video.offset = yield select(getFilteredVideoOffset)
    }

    const videosArray = yield call(retrier, [call(videosTask.getMore, video)], 'videos paginated');

    const videosData = videosArray[0]

    if (!videosData) {
      const eventId = pushToSentry('Internet connection seems to be down - videos data')
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: 'Internet connection seems to be down',
          eventId
        }
      })
    }

    yield put({
      type: actions.VIDEOS_LIST_FETCH_SUCCESS,
      payload: {
        videosData,
        forUser: tokenByAuthenticatedUser,
        pagination: true
      }
    });
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.VIDEOS_LIST_FETCH_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* fetchVideoDetails() {
  yield takeEvery(actions.GET_ALL_VIDEOS, task)
}