import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getFilteredBlogOffset, getUnFilteredBlogOffset, getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry'
import blogsTask from './subtask/blogs';

import retrier from './process/retrier'

import { globalFetchLimit } from '../helpers/filterConversions';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  try {
    yield put({
      type: actions.BLOGS_LIST_FETCH_REQUEST,
      payload: {
        pagination: true,
      }
    });
    let tokenByAuthenticatedUser = yield select(getToken) // get current authEssential (which is loginInUser in store)

    const blog = {
      limit: globalFetchLimit,
      filterGroup: action.payload.filterGroup,
    }
    if (action.payload.filterGroup == 'unFiltered') {
      blog.offset = yield select(getUnFilteredBlogOffset)
    } else {
      blog.offset = yield select(getFilteredBlogOffset)
    }

    const blogsArray = yield call(retrier, [call(blogsTask.getMore, blog)], 'blogs paginated');

    const blogsData = blogsArray[0]

    if (!blogsData) {
      const eventId = pushToSentry('Internet connection seems to be down - blogs data')
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: 'Internet connection seems to be down',
          eventId
        }
      })
    }


    yield put({
      type: actions.BLOGS_LIST_FETCH_SUCCESS,
      payload: {
        blogsData,
        forUser: tokenByAuthenticatedUser,
        pagination: true
      }
    });
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.BLOGS_LIST_FETCH_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* fetchblogDetails() {
  yield takeEvery(actions.GET_ALL_BLOGS, task)
}