import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-flexbox-grid';
import Avatar from './avatar';
import Loader from "../images/loader";
import axios from "axios";

import isNil from "lodash/isNil";
import findIndex from "lodash/findIndex";
import reverse from "lodash/reverse";
import map from "lodash/map";
import moment from "moment";
import ReactSafeHtml from 'react-safe-html';
import { baseUrl } from "../../helpers/api";
import pushToSentry from "../../helpers/pushToSentry";
import LeftArrow from '../images/LeftArrow';
import Spinner from "react-spinner-material";
import { TransitionOne, TransitionTwo } from '../common/transitions';
import { themeTextRed } from "../../helpers/theme";

const DailyDish = ({
  state,
  userDetails,
  dispatchDishListAction,
  showToastMessage,
  ...props
}) => {
  const {
    details: {
      avatar = {},
      member_since: memberSince,
      name,
    },
    token
  } = userDetails;

  const [currentNotification, setCurrentNotification] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [markAllReadState, setMarkAllReadState] = useState(false);

  useEffect(() => {
    if (!isNil(currentNotification) && !currentNotification.isread) {
      axios({
        method: 'POST',
        url: `${baseUrl}/api/users/notifications/`,
        headers: {
          Authorization: `Token token="${token}"`
        },
        data: {
          notification_id: currentNotification.id
        }
      }).then(response => {
        dispatchDishListAction({
          type: 'MARK_AS_READ',
          payload: {
            id: currentNotification.id
          }
        })
      }).catch(e => {
        const eventId = pushToSentry(e)
        props.addSentryError({
          error: e.message,
          eventId
        })
        console.warn(e);
      })
    }
  }, [currentNotification]);

  useEffect(() => {
    if (!isNil(token)) {
      dispatchDishListAction({
        type: "SET_TOKEN",
        payload: {
          token: userDetails.token
        }
      })
    }
  }, [token])

  const isRead = (id) => findIndex(state.unread_notifications, (notification) => notification.id === id);

  useEffect(() => {
    const array = [...state.unread_notifications, ...state.read_notifications];
    const sortedArray = array.sort((a, b) => new moment(a.created_at).diff(new moment(b.created_at).format()))
    setNotifications(reverse(sortedArray))
  }, [state.read_notifications, state.unread_notifications]);

  const getDiscriptionContent = (content) => {
    const htmlString = content.replace(/<img[^>]*>/g, "");
    const text = htmlString.replace(/<\/{0,1}[a-z]+>/gi, "");
    return text.substring(0, 250);
  }

  const renderDailyDish = () => {
    if (state.isFetching) {
      return (
        <div className="container">
          <div className="preLoader">
            <Loader></Loader>
          </div>
        </div>
      )
    } else if (state.unread_notifications.length < 1 && state.read_notifications.length < 1) {
      return (
        <div className="no-notification"> <p>No notification!</p></div>
      )
    } else {
      return (
        <>
          {
            map(notifications, notification => {
              return (
                <>
                  <div className={`daily-dish-item ${(isRead(notification.id) > -1) ? "unread" : "read"}`}
                    key={notification.id}
                    onClick={() => setCurrentNotification({ ...notification, isread: false })}
                  >
                    <div className="daily-dish-item-header">
                      <h4 className={`title ${(isRead(notification.id) > -1) ? "unread" : "read"}`}>{notification.title}</h4>
                      <span className="date">{moment(notification.created_at).format("MM/DD/YY")}</span>
                    </div>
                    <div className="notification-description">
                      <p>
                        {`${getDiscriptionContent(notification.content)}`}
                        {
                          (getDiscriptionContent(notification.content).length > 249)
                          && <span className="read-more">&nbsp;read more...</span>
                        }
                      </p>
                    </div>
                  </div>
                </>
              )
            })
          }
        </>
      )
    }
  }

  const markAllAsRead = () => {
    if (state.unread_notifications.length > 0) {
      setMarkAllReadState(true);
      axios({
        method: 'POST',
        url: `${baseUrl}/api/users/read-all-notifications`,
        headers: {
          Authorization: `Token token="${token}"`
        },
      }).then(response => {
        if (response.data.unread === 0) {
          showToastMessage('✅ All notifications are marked as read')
          dispatchDishListAction({
            type: 'MARK_ALL_AS_READ',
          })
        }
        setMarkAllReadState(false);
      }).catch(e => {
        setMarkAllReadState(false);
        showToastMessage(`Something goes wrong!`, 'error', 'read all notification')
        const eventId = pushToSentry(e)
        props.addSentryError({
          error: e.message,
          eventId
        })
        console.warn(e);
      })
    }
  }

  const getMainContent = () => {
    return (

      <div className="account-content-wrapper">
        <div className="dailydish-wrapper">
          <Row className="no-margin">
            <TransitionOne>
              <Col xs={12} lg={3} className="account-content-left-wrapper">
                <Avatar
                  src={avatar.medium}
                  memberSince={memberSince}
                  name={name}
                />
              </Col>
            </TransitionOne>
            <TransitionTwo>
              <Col xs={12} lg={9} className="account-content-right-wrapper">
                <div className="daily-dish-heading">
                  <h3>Updates</h3>
                  <span className="mark-read">
                    <span className={`${(state.unread_notifications.length > 0) ? "" : "disabled"}`}
                      onClick={markAllAsRead}
                    >
                      Mark all as read
                    </span>
                    {
                      markAllReadState &&
                      <Spinner
                        size={15}
                        spinnerColor={themeTextRed}
                        spinnerWidth={2}
                        visible={true}
                      />
                    }
                  </span>
                </div>
                <div className="daily-dish-list">
                  {renderDailyDish()}
                  {/* <div className="view-notification">
                  <a href={''}>View previous notifications</a>
                </div> */}
                </div>
              </Col>
            </TransitionTwo>
          </Row>
        </div>
      </div >
    )
  }

  const getNotificationContent = () => {
    return (
      <>
        <TransitionOne>
          <div className="dailydish-wrapper">
            <div className="notification-header">
              <div
                onClick={() => {
                  setCurrentNotification(null)
                }}
                className="back-button">
                <LeftArrow />
                back
              </div>
              <h2 className="title">{currentNotification.title}</h2>
            </div>
            <div className="html-content">
              {<ReactSafeHtml html={currentNotification.content} />}
            </div>
          </div>
        </TransitionOne>
      </>
    )
  }

  return (
    <div>
      {
        (isNil(currentNotification))
          ? getMainContent()
          : getNotificationContent()
      }
    </div>
  )
}

export default DailyDish