import React from 'react'
import GhostCover from './ghostCover'
import DownArrow from '../images/DownArrowLarge'

const RightButton = (props) => (
  <GhostCover {...props}>
    <DownArrow></DownArrow>
  </GhostCover>
)

export default RightButton