import React from "react";
import '../styles/common.scss';

const PlayButtonIcon = (props) => {
    return (
        <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M79.4594 60L48.9269 40.2623V79.7377L79.4594 60Z" fill="white" />
            <circle cx="60" cy="60" r="58" stroke="white" stroke-width="4" />
        </svg>
    );
};
export default PlayButtonIcon;
