import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from '../../helpers/toJS';

import {
  createBoard,
  appEssentialFetch,
  updateFavouriteBoard,
  deleteFavouriteBoard,
} from "../../actions";

import {
  getRecipeBoards,
} from "../../selectors/recipes";
import {
  getBoards,
} from "../../selectors/blogs";
import {
  getWorkoutBoards,
} from "../../selectors/workout";
import { getUserEssentialFetchStatus } from "../../selectors/userEssential";

import Favorite from "./components";

const mapStateToProps = state => ({
  blogBoards: getBoards(state),
  recipeBoards: getRecipeBoards(state),
  workoutBoards: getWorkoutBoards(state),
  userEssentialFetchStatus: getUserEssentialFetchStatus(state),
});

const dispatchActionToProps = dispatch => ({
  createBoard: bindActionCreators(createBoard, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(Favorite));