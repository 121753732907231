import React, { useState } from "react";
import cx from "classnames";
import map from "lodash/map";
import Button from "../../common/button";
import ListTick from "../../images/ListTick";
import LeftArrow from "../../images/LeftArrow";
import { themeTextRed } from "../../../helpers/theme";

const PlanBenefitCard = ({
  showExclusive,
  onClickBtn,
  title,
  price,
  baseFeatures,
  exclusiveFeatures,
  billingDuration,
  percentOff,
  trialLength,
  planId,
  premium,
  isCouponed
}) => {
  const duration = billingDuration === "annually" ? "year" : "month";
  const tempDuration = billingDuration === "annually" ? "yr" : "mo";
  const isPromoApplied = percentOff !== null;
  const curvedClass = !premium ? 'btn-curved' : 'btn-inverted';
  return (
    <>
      <div className="popular">
        {
          premium ? (
            <div className="mostPopular">
              Most Popular
            </div>
          ) : null
        }
      </div>
      <div className="header-content">
        <div className="header-title">{title}</div>
        <div className="header-price-with-text">
          {trialLength ? (
            <>
              Free for &nbsp;
              <span style={{ color: themeTextRed }}>{trialLength} weeks</span>{" "}
              &nbsp;then
            </>
          ) : (
            isPromoApplied ? 'Starts Immediately' : `Signup`
          )}
        </div>
        <div className={cx("header-item", { "promo-applied-color": isPromoApplied })}>
          <span
            className={cx("header-price", { "promo-applied": isPromoApplied })}
          // style={{ textDecoration: isPromoApplied ? "line-through" : "none" }}
          >
            ${parseFloat(price)}
          </span>
          <div
            className="header-price-amount"
            style={{
              color: isPromoApplied ? "#2C2F34" : "#9d9d9d",
            }}
          >
            {" "}

          </div>
          <div className="duration-tag">per {duration}</div>
        </div>
        {isPromoApplied ? (
          <div className="discounted-price">
            <span className="PromoPrice promotion-highlight">
              {" "}
              $
              {(
                parseFloat(price) * parseFloat((100 - percentOff) / 100)
              ).toFixed(2)}
              /{tempDuration}{" "}
              {/* <span className="promotion-highlight">{" for the 1st cycle"}</span> */}

            </span>

            {/* <div className="PromoPrice">
              {" ("}
              $
              {(
                parseFloat(price)
              ).toFixed(2)}
              /{tempDuration}{" "}{" from next)"}

            </div> */}
          </div>
        ) : null}
      </div>

      {
        <div className="plan-collapse">
          <div className="showFeatures">
            {/* <h5>What’s included:</h5> */}
            <ul>
              {baseFeatures &&
                map(baseFeatures.split("\n"), (row) => (
                  <li key={row} className={cx({ "premium-row": row })}>
                    <LeftArrow />
                    <span className="benefit-item">{row}</span>
                  </li>
                ))}
              {showExclusive &&
                exclusiveFeatures &&
                map(exclusiveFeatures.split("\n"), (row) => (
                  <li key={row} className={cx({ "premium-row": row })}>
                    <ListTick />
                    <span className="benefit-item">{row}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      }

      <div className="btn-checkout">
        <Button
          onClick={(e) => {
            onClickBtn(planId);
          }}
          btnClass={cx('full', curvedClass)}
          btnText={isPromoApplied ? "Select Plan" : "Select Plan"}
        />
      </div>
      <div className="cancel-anytime">
        Cancel at any time, no questions asked
      </div>
    </>
  );
};

PlanBenefitCard.defaultProps = {
  title: "Head Chef",
  price: "7",
  data: [

  ],
};

export default PlanBenefitCard;
