import React from 'react';
import moment from 'moment';
import Cover from '../../images/cover.png';
import { upperCase, capitalize } from 'lodash';

const KatiesCornerCard = ({
    title,
    date,
    introduction,
    banner,
    navigateTo,
    slug,
    categories
}) => {
    let updatedBanner = (!banner || banner === 'some banner') ? Cover : banner;
    return (
        <li className='katies-corner-list-card'>
            <a href={`/nourish/katies-corner/${slug}`} onClick={(e) => {
                e.preventDefault();
                navigateTo(`/nourish/katies-corner/${slug}`);
            }}>
                <figure className='img-wrapper' style={{
                    backgroundImage: "url(" + updatedBanner + ")",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center'
                }}>
                    <p className='card-title hover-underline-animation'>
                        {capitalize(title)}<nobr />{(introduction.length > 0 ? ': ' : '') + capitalize(introduction)}
                    </p>
                </figure>
            </a>
        </li>
    )
}


export default KatiesCornerCard
