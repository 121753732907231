import React from 'react'
import GhostCover from './ghostCover'
import CalenderRightArrow from '../images/CalenderRightArrow'

const RightButton = (props) => (
  <GhostCover {...props}>
    <CalenderRightArrow></CalenderRightArrow>
  </GhostCover>
)

export default RightButton