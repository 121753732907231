import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getFilteredRecipeOffset, getUnFilteredRecipeOffset, getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry'
import recipesTask from './subtask/recipes';
import blogsTask from './subtask/blogs';
import workoutsTask from './subtask/workouts';
import videosTask from './subtask/videos';


import { globalFetchLimit } from '../helpers/filterConversions';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  let tokenByAuthenticatedUser = yield select(getToken)
  // This will work only for triggered fetches from applying filters
  if (action.payload.triggered) {
    const recipe = {
      filters: action.payload.filters,
      favorite: action.payload.favorite,
      limit: globalFetchLimit,
      offset: 0
    }

    if (action.payload.objectType === 'recipe') {
      const recipesData = yield call(recipesTask.getAll, recipe);
      yield put({
        type: actions.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          recipesData,
          forUser: tokenByAuthenticatedUser,
          pagination: false,
          triggered: true, // This will clear any favorites when coming from that screen.
        }
      });
    } else if (action.payload.objectType === 'workout') {
      const workoutsData = yield call(workoutsTask.getAll, recipe);
      yield put({
        type: actions.WORKOUTS_LIST_FETCH_SUCCESS,
        payload: {
          workoutsData,
          forUser: tokenByAuthenticatedUser,
          pagination: false,
          triggered: true, // This will clear any favorites when coming from that screen.
        }
      });
    } else if (action.payload.objectType === 'blog') {
      const blogsData = yield call(blogsTask.getAll, recipe);
      yield put({
        type: actions.BLOGS_LIST_FETCH_SUCCESS,
        payload: {
          blogsData,
          forUser: tokenByAuthenticatedUser,
          pagination: false,
          triggered: true, // This will clear any favorites when coming from that screen.
        }
      });
    } else if (action.payload.objectType === 'video') {
      const videosData = yield call(videosTask.getAll, recipe);
      yield put({
        type: actions.VIDEOS_LIST_FETCH_SUCCESS,
        payload: {
          videosData,
          forUser: tokenByAuthenticatedUser,
          pagination: false,
          triggered: true, // This will clear any favorites when coming from that screen.
        }
      });
    }

  }

}

export default function* setFilters() {
  yield takeEvery(actions.SET_NEW_FILTERS_SUCCESS, task)
}