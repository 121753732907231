import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from "../../helpers/toJS";

import {
  appEssentialFetch,
  createFavourite,
  deleteFavourite,
  createBoard,
  fetchRecipes,
  setObjectBoards,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  setFiltersAndFetch,
  search,
  trendingTypeChange,
} from "../../actions";

import { getUserDetails } from "../../selectors";

import {
  getUnFilteredList,
  getFilteredList,
  getRecipes,
  getRecipeBoards,
  getFetchStatus,
  getMoreRecipeAvailable,
  getRecipesCategories,
  getFeaturedCollections,
  getRecipeFilterName,
  getRecipesSearchList,
  getRecipesSearchCount,
  getRecipeSearchBy,
  getRecipeSearchText,
  getRecipeSearchFetchStatus,
  getFeaturedDietaries,
  getRecipesFoodTypes,
  getRecipesSpecialities,
  getAppliedFilters,
  getTrendingRecipes,
  getTrendingType,
  getOrder,
  getAllFitlers,
} from "../../selectors/recipes";

import RecipesComponent from "./components/recipes";

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
  filteredList: getFilteredList(state),
  unfilteredList: getUnFilteredList(state),
  recipes: getRecipes(state),
  boards: getRecipeBoards(state),
  fetchStatus: getFetchStatus(state),
  moreRecipeAvailable: getMoreRecipeAvailable(state),
  recipesDietaries: getFeaturedDietaries(state),
  recipesFoodTypes: getRecipesFoodTypes(state),
  recipesSpecialities: getRecipesSpecialities(state),
  recipeFilterName: getRecipeFilterName(state),
  searchList: getRecipesSearchList(state),
  recipesSearchCount: getRecipesSearchCount(state),
  searchBy: getRecipeSearchBy(state),
  searchTerm: getRecipeSearchText(state),
  appliedFilters: getAppliedFilters(state),
  trendingRecipes: getTrendingRecipes(state),
  trendingType: getTrendingType(state),
  order: getOrder(state),
  featuredCollections: getFeaturedCollections(state),
  allCollections: getAllFitlers(state),
});

const dispatchActionToProps = (dispatch) => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  fetchRecipes: bindActionCreators(fetchRecipes, dispatch),
  createBoard: bindActionCreators(createBoard, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  setFiltersAndFetch: bindActionCreators(setFiltersAndFetch, dispatch),
  searchRecipes: bindActionCreators(search, dispatch),
  trendingTypeChange: bindActionCreators(trendingTypeChange, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(RecipesComponent));
