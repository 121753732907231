import React from 'react';
import '../styles/common.scss';

const PinIcon = () => {
    return (
        <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.0200195 8.66977C0.0200195 4.44977 4.04001 0.0397656 10.61 0.00976562C16.5 0.00976562 20.03 4.05977 20.01 7.97977C20.01 13.4798 16.62 17.4698 11.95 17.4698C10.85 17.4698 9.78001 17.1198 8.92001 16.4898C8.84001 16.4298 8.74002 16.4098 8.64001 16.4398C8.54001 16.4598 8.47002 16.5398 8.44002 16.6298C8.18002 17.5798 7.82002 18.9098 7.71002 19.2798C7.13002 20.8998 6.24001 22.4198 5.09001 23.7498C4.99001 23.8798 4.83002 23.9598 4.66002 23.9698H4.53001C4.34001 23.9198 4.21001 23.7798 4.18001 23.5998C3.87001 21.8798 3.89001 20.1098 4.23001 18.3898L6.00002 11.3298C6.03001 11.2298 6.03001 11.1198 6.00002 11.0098C5.75002 10.3698 5.62001 9.68977 5.62001 9.00977C5.62001 6.90977 6.97002 5.30977 8.69002 5.30977C9.32002 5.29977 9.92003 5.52977 10.35 5.95977C10.78 6.38977 11 6.96977 10.94 7.54977C10.86 8.56977 10.62 9.57977 10.25 10.5398C10.05 11.1598 9.85002 11.7898 9.69002 12.3898C9.54002 12.9198 9.67001 13.4898 10.03 13.9298C10.45 14.3598 11.05 14.5798 11.66 14.5498C14.09 14.5498 15.92 11.5998 15.92 7.67977C15.92 4.66977 13.74 2.79977 10.23 2.79977C8.45001 2.73977 6.71001 3.35977 5.43001 4.51977C4.15001 5.67977 3.43002 7.27977 3.44002 8.94977C3.39001 9.83977 3.71001 10.7198 4.31001 11.4098C4.61002 11.6698 4.71002 12.0698 4.57002 12.4198L4.28001 13.5398C4.24002 13.7598 4.08002 13.9598 3.86002 14.0598C3.64001 14.1598 3.38002 14.1598 3.16002 14.0598C1.22002 13.3098 0.0200195 11.2998 0.0200195 8.66977Z" fill="#6B6D71" />
        </svg>
    )
}

export default PinIcon;
