import React, { useEffect, useState } from 'react';
import { useRouteMatch, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Loader from "../images/loader";
import {
    useQuery,
} from 'react-query';
import { personalizedBlogs, personalizedVideos, taxonomies, nourishMonthlyChallenge } from '../../helpers/api';

import {
    appEssentialFetch,
} from "../../actions";

import toJS from "../../helpers/toJS";
import KatiesCornerAsk from './KatiesCornerAsk';
import KatiesCornerBlog from "./KatiesCornerBlog";
import KatiesCornerList from "./KatiesCornerList";
import KatiesCornerLiveCookingList from "./KatiesCornerLiveCookingList";
import KatiesCornerLiveMeetingList from "./KatiesCornerLiveMeetingList";
import KatiesCornerLiveWorkoutsList from "./KatiesCornerLiveWorkoutList";
import KatiesCornerPastCooking from "./KatiesCornerPastCooking";
import KatiesCornerPastMeeting from "./KatiesCornerPastMeeting";
import KatiesCornerPastWorkout from "./KatiesCornerPastWorkout";
import KatiesConerNavigation from "./navigation";

import KatiesCornerFaq from "./KatiesCornerFaq";
import KatiesCornerHome from "./KatiesCornerHome";
import KatiesCornerInternalNavigation from './KatiesCornerInternalNavigation';
import KatiesCornerBlogListNavigation from './katiesCornerBlogListNavigation';
import KatiesCornerVideoNavigation from "./KatiesCornerVideoNavigation";
import Nourish from './Nourish';
import { getObjectListFetchStatus, getUserDetails } from '../../selectors';
import MonthlyChallenge from './MonthlyChallenge';

export const getTaxonomies = async () => {
    const response = await axios.get(taxonomies.get());
    return response.data.taxonomies;
}

export const getMonthlyChallenge = async () => {
    const response = await axios.get(nourishMonthlyChallenge.get());
    return response.data.personalized_documents;
}

const getPersonalizedBlog = async (params) => {

    const response = await axios.get(personalizedBlogs.get(0, 'desc', '&limit=1&favorites=true', false));
    return {
        featuredPersonalizedBlog: response.data.personalized_blogs.length > 0 ? response.data.personalized_blogs[0] : null
    }
}


const getFeaturedWorkout = async (params) => {

    const response = await axios.get(personalizedVideos.get(params.pageParam, 'live_workout', '&limit=1&favorites=true', false));
    return {
        featuredWorkout: response.data.personalized_videos.length > 0 ? response.data.personalized_videos[0] : null
    }
}

const getFeaturedCooking = async (params) => {

    const response = await axios.get(personalizedVideos.get(params.pageParam, 'live_cooking', '&limit=1&favorites=true', false));
    return {
        featuredCooking: response.data.personalized_videos.length > 0 ? response.data.personalized_videos[0] : null
    }
}

const getFeaturedMeeting = async (params) => {

    const response = await axios.get(personalizedVideos.get(params.pageParam, 'live_meeting', '&limit=1&favorites=true', false));
    return {
        featuredMeeting: response.data.personalized_videos.length > 0 ? response.data.personalized_videos[0] : null
    }
}

const KatiesCornerRoute = (props) => {
    let { path, url } = useRouteMatch();

    const [isFirstLoad, setIsFirstLoad] = useState(true);
    useEffect(() => {
        props.appEssentialFetch();
    }, []);

    useEffect(() => {
        if (props.userDetails.isAuthenticating) {
            setIsFirstLoad(false);
        }
    }, [props.userDetails.isAuthenticating]);

    const email = props.userDetails?.email;

    const authorEmails = [
        'courtney@dashingdish.com',
        'jessica@dashingdish.com',
        'katie@dashingdish.com',
        'sean@dashingdish.com',
        'sarah@dashingdish.com',
        'kaci09@hotmail.com',
        'hello@timeless.co',
        'jmginter2014@yahoo.com'
    ]

    const userPlanOrder = (authorEmails.findIndex(author => author === email) !== -1) ? 3 : props.userDetails?.details?.plan?.plan_order;
    const isLoginProgress = isFirstLoad || props.userDetails?.isAuthenticating;
    const isLoginCompleted = props.userDetails?.isLoggedIn;
    const authToken = localStorage.getItem('token');

    const {
        data: featuredPersonalizedData,
        isFetching: featuredBlogIsFetching
    } = useQuery(['featuredPersonalizedBlog'], getPersonalizedBlog, {
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    const {
        data: featuredWorkoutData,
        isFetching: featuredWorkoutIsFetching
    } = useQuery(['featuredWorkout'], getFeaturedWorkout, {
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    const {
        data: featuredCookingData,
        isFetching: featuredCookingIsFetching
    } = useQuery(['featuredCooking'], getFeaturedCooking, {
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    const {
        data: featuredMeetingData,
        isFetching: featuredMeetingIsFetching
    } = useQuery(['featuredMeeting'], getFeaturedMeeting, {
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });


    const { isLoading: taxonomiesIsLoading, data: taxonomiesData } = useQuery({
        queryKey: ['taxonomies'],
        queryFn: getTaxonomies
    })

    const { isLoading: monthlyChallengeIsLoading, data: monthlyChallengeData } = useQuery({
        queryKey: ['monthlyChallenge'],
        queryFn: getMonthlyChallenge
    });


    const gatePlanUsers = userPlanOrder !== 3;

    if (
        featuredBlogIsFetching ||
        featuredWorkoutIsFetching ||
        featuredCookingIsFetching ||
        featuredMeetingIsFetching ||
        monthlyChallengeIsLoading ||
        taxonomiesIsLoading
    )
        return <div className="container">
            <div className="preLoader-minimal-height preloader-max-height">
                <Loader></Loader>
            </div>
        </div>


    return (!isLoginProgress || !authToken || isLoginCompleted && !featuredBlogIsFetching && !featuredWorkoutIsFetching && !featuredCookingIsFetching && !featuredMeetingIsFetching && !taxonomiesIsLoading && !monthlyChallengeIsLoading) ? (
        <div className='nourish-wrapper'>

            <Switch>
                <Route path={path} exact >
                    <KatiesConerNavigation {...props} userPlanOrder={userPlanOrder} />
                    <KatiesCornerHome {...props} userPlanOrder={userPlanOrder} />
                </Route>
                <Route path={`${path}/about`}>
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : (
                            <>
                                <KatiesCornerInternalNavigation {...props} userPlanOrder={userPlanOrder} featuredPersonalizedBlog={featuredPersonalizedData?.featuredPersonalizedBlog} />
                                <Nourish {...props} monthlyChallengeData={monthlyChallengeData} />
                            </>
                        )
                    }
                </Route>
                <Route
                    path={`${path}/live-workouts`}
                    exact
                >
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : (
                            <>
                                <KatiesCornerVideoNavigation {...props} featuredItem={featuredWorkoutData?.featuredWorkout} objectType="workout" />
                                <KatiesCornerLiveWorkoutsList
                                    {...props}
                                    userPlan={userPlanOrder}
                                    taxonomiesData={taxonomiesData}
                                    taxonomiesIsLoading={taxonomiesIsLoading}
                                />
                            </>
                        )
                    }
                </Route>
                <Route
                    path={`${path}/live-cooking`}
                    exact
                >
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : (
                            <>
                                <KatiesCornerVideoNavigation {...props} featuredItem={featuredCookingData?.featuredCooking} objectType="cooking" />
                                <KatiesCornerLiveCookingList
                                    {...props}
                                    userPlan={userPlanOrder}
                                    taxonomiesData={taxonomiesData}
                                    taxonomiesIsLoading={taxonomiesIsLoading}
                                />
                            </>
                        )
                    }

                </Route>
                <Route path={`${path}/live-meetings`} exact>

                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : (
                            <>
                                <KatiesCornerVideoNavigation {...props} featuredItem={featuredMeetingData?.featuredMeeting} objectType="meeting" />
                                <KatiesCornerLiveMeetingList
                                    {...props}
                                    taxonomiesData={taxonomiesData}
                                    taxonomiesIsLoading={taxonomiesIsLoading}
                                    userPlan={userPlanOrder}
                                />
                            </>
                        )
                    }
                </Route>
                <Route path={`${path}/katies-corner/:slug`} exact>
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : <KatiesCornerBlog {...props} userPlan={userPlanOrder} />
                    }

                </Route>
                <Route path={`${path}/katies-corner`}>
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : (
                            <>
                                <KatiesCornerBlogListNavigation
                                    {...props}
                                    featuredPersonalizedBlog={featuredPersonalizedData?.featuredPersonalizedBlog}
                                />
                                <KatiesCornerList
                                    {...props}
                                    taxonomiesData={taxonomiesData}
                                    taxonomiesIsLoading={taxonomiesIsLoading}
                                />
                            </>
                        )
                    }

                </Route>
                <Route
                    path={`${path}/past-meeting/:slug`}
                    exact
                >
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : <KatiesCornerPastMeeting {...props} userPlan={userPlanOrder} />
                    }
                </Route>

                <Route
                    path={`${path}/past-workout/:slug`}
                    exact
                >
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : <KatiesCornerPastWorkout {...props} userPlan={userPlanOrder} />
                    }
                </Route>

                <Route
                    path={`${path}/past-cooking/:slug`}
                    exact
                >
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : <KatiesCornerPastCooking {...props} userPlan={userPlanOrder} />
                    }

                </Route>
                <Route
                    path={`${path}/monthly-challenge`}
                    exact
                >
                    {
                        gatePlanUsers ? <Redirect to={`${path}/faq`} /> : <MonthlyChallenge {...props} />
                    }

                </Route>
                {/* <Route
                    path={`${path}/upcoming-meetings`}
                    exact
                >

                    <KatiesCornerAsk {...props} />
                </Route> */}
                <Route path={`${path}/faq`}>
                    <KatiesCornerFaq
                        userPlanOrder={userPlanOrder}
                        isLoginProgress={props.userDetails?.isAuthenticating}
                        isLoginCompleted={isLoginCompleted}
                        history={props.history}
                    />
                </Route>
            </Switch >
        </div>
    ) : null
}

const mapStateToProps = (state) => ({
    userDetails: getUserDetails(state),
    appEssentialFetch: getObjectListFetchStatus(state)
});

const dispatchActionToProps = (dispatch) => ({
    appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch)
});

export default connect(mapStateToProps, dispatchActionToProps)(toJS(KatiesCornerRoute));