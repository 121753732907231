import moment from 'moment';
import { forEach, reduce, times } from 'lodash';


export default (calendarListData, anchorDate, datesCount) => {
  let count = 0;
  
  const prevMonthWeekStart = moment(anchorDate).subtract({ days: datesCount })

  let emptyCalendarData = times(2 * datesCount, ()=> {
    const currentDate = prevMonthWeekStart.clone().add(count, 'd');
    let item = {
      date: prevMonthWeekStart.clone().add(count, 'd').format('YYYY-MM-DD'),
      completed: currentDate.isBefore(moment().startOf('day').format('YYYY-MM-DD')),
      calories: '',
      smartPoints: '',
      protein: '',
      meals: [],
      workouts: []
    }
    count++;
    return item
  })

  let calendarData = reduce(emptyCalendarData, (accumulator ,calendarValue ,key) => {
    forEach(calendarListData, (monthValue, month) => {
      forEach((monthValue.days), (dayValue, day) => {
        if(dayValue.date == calendarValue.date){
          calendarValue.meals = dayValue.meals
        }
      })
    })
    accumulator.push(calendarValue);
    return accumulator;
  }, []);

  return calendarData;
}