import React from 'react';
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const PinOutlineIcon = () => {
    return (
        <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0638 1C6.04255 1 1 8.18085 1 14.1649C1 17.7872 2.37234 21.0266 5.30851 22.2234C5.51596 22.3351 5.75532 22.3351 5.97872 22.2394C6.18617 22.1277 6.32979 21.9362 6.37766 21.6968C6.4734 21.3298 6.71277 20.3883 6.80851 20.0053C6.98404 19.5904 6.85638 19.1117 6.50532 18.8245C5.54787 17.6596 5.05319 16.1915 5.11702 14.6915C5.06915 11.9628 6.17021 9.32979 8.13298 7.41489C10.0957 5.5 12.7447 4.47872 15.4894 4.59043C21.1543 4.59043 24.266 8.05319 24.266 12.6649C24.266 18.7447 21.5692 23.867 17.5798 23.867C16.5426 23.9149 15.5532 23.484 14.8989 22.6702C14.2447 21.8723 14.0053 20.8032 14.2606 19.7979C14.8989 17.117 16.1277 14.2447 16.1277 12.3138C16.2234 11.5 15.9681 10.6862 15.4255 10.0798C14.867 9.52128 14.0851 9.17021 13.2713 9.18617C11.0213 9.18617 9.20213 11.516 9.20213 14.6436C9.18617 15.7926 9.40957 16.9255 9.87234 17.9787L7.15957 29.4681C6.68085 32.117 6.66489 34.8298 7.09575 37.4787C7.1117 37.5904 7.19149 37.6862 7.30319 37.7021C7.41489 37.734 7.5266 37.6862 7.59043 37.6064C9.25 35.5479 10.5426 33.2181 11.4043 30.6968C11.6596 29.7553 12.8883 24.9043 12.8883 24.9043C14.0532 26.5957 15.984 27.5851 18.0426 27.5372C24.8085 27.5372 29.4202 21.3457 29.4202 13.0798C29.4043 6.82447 24.1064 1 16.0638 1Z" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default PinOutlineIcon;
