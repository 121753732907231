import React, { useState, useEffect } from 'react';
import moment from "moment";
import filter from 'lodash/filter';
import map from "lodash/map";
import find from "lodash/find";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";

import Button from './button';
import ScrollableDatepicker from "./scrollableDatePicker";
import Selector from "./selector";

import getServingCountFromActiveVariant from '../../helpers/getServingCountFromActiveVariant'

const AddToMealPlan = ({
  objectType,
  activeObject,
  calendarList,
  addMealToMultipleDays,
  mealPlanModalToggleHandler,
  ...props
}) => {
  const [state, setState] = useState({
    calenderDates: [],
    servingSize: map(new Array(60), (i, index) => {
      return { title: `${index + 1}`, value: `${index + 1}` }
    }),
    addIngredients: false,
    buttonStatus: true,
  });

  const [currentServingSize, setCurrentServingSize] = useState(4)

  const noOfDaysLimit = 7;
  const numberOfDates = 28;
  useEffect(() => {
    if (objectType === "recipe" && !isNil(activeObject.id)) {
      let defaultServingSize = getServingCountFromActiveVariant(activeObject.variations[0].nutrition)
      setCurrentServingSize(defaultServingSize);
    }
  }, [activeObject.id]);


  useEffect(() => {
    if (state.calenderDates.length <= 0) {
      let datesArray = map(new Array(numberOfDates), (i, index) => {
        let date = moment().add(index, 'd').format("YYYY-MM-DD");
        let calendarDate = find(calendarList, item => moment(item.date).isSame(date));
        let recipes = false;
        let workouts = false;

        map((calendarDate || []).meals, meal => {
          if (!isEmpty(meal.workout)) {
            workouts = true
          }
          if (!isEmpty(meal.recipe)) {
            recipes = true
          }
        })

        return {
          date: date,
          isSelected: false,
          recipes: recipes,
          workouts: workouts
        }
      });
      setState({ ...state, calenderDates: datesArray });
    }
  }, [calendarList]);

  const onAddToMealPlan = () => {
    const activeDates = reduce(state.calenderDates, (accumulator, dateObj) => {
      if (dateObj.isSelected) {
        return accumulator.concat(dateObj.date)
      }
      return accumulator
    }, []);
    addMealToMultipleDays(
      activeObject.id,
      activeDates,
      'breakfast',
      objectType,
      state.addIngredients,
      currentServingSize,
      activeObject,
    )
  }

  const onSelectionToggle = (index) => {
    let dateArray = map(state.calenderDates, (dateObj, dateIndex) => {
      if (dateIndex === index) {
        dateObj.isSelected = !dateObj.isSelected
        return dateObj
      } else {
        return dateObj
      }
    })
    let anyDateSelected = find(state.calenderDates, dateObj => dateObj.isSelected);
    setState({ ...state, calenderDates: dateArray, buttonStatus: isEmpty(anyDateSelected) });
  }

  const getCalederDates = () => {
    let nodes = map(state.calenderDates, (dateObj, index) => {
      return (
        <div
          className={`${dateObj.isSelected ? 'calender-card-active' : 'calender-card'}`}
          key={index} onClick={(e) => onSelectionToggle(index)}>

          <p className="calender-day">{moment(dateObj.date).format("dd").substring(0, 2)}</p>
          <p className="calender-date">{moment(dateObj.date).format('D')}</p>
          <div className="dot-group">
            {
              (dateObj.recipes)
                ? <span className="recipe-dot"></span>
                : null
            }
            {
              (dateObj.workouts)
                ? <span className="workout-dot"></span>
                : null
            }

          </div>
        </div>
      )
    })
    return nodes
  }
  return (
    <div className="modalWrapper add-meal-modal">
      <div className="modalHeader mealplan-index-header">
        <figure className="currentObject">
          {/* thumbnail when favorite needs update, display image when not needed */}
          <img src={(props.thumbnail || props.displayImage) ? (props.thumbnail || props.displayImage) : null} />
        </figure>
        <h3>Add ‘{activeObject && activeObject.title}‘ to calendar</h3>
      </div>
      <div className="modalBody add-meal-modalBody">
        {
          (objectType === "recipe") &&
          <div className="add-meal-wrapper">
            <div className="categoryRow">
              <p>{"Serving Size"}</p>
              <div className="add-meal-selector">
                <Selector
                  value={currentServingSize}
                  onSelectValue={(value) => setCurrentServingSize(value)}
                  selectorList={state.servingSize}
                />
              </div>
            </div>
            <div className="categoryRow">
              <p>{"Add ingredients to grocery list ?"}</p>
              <div className="switchField"
                onClick={
                  (e) => {
                    setState({
                      ...state,
                      addIngredients: !state.addIngredients
                    });
                  }}
              >
                <label class="switch" for="checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    checked={state.addIngredients}
                    onClick={e => {
                      e.stopPropagation()
                    }}
                  />
                  <div class="slider round"></div>
                </label>
              </div>
            </div>
          </div>
        }
        <div className="ScrollableCalender">
          <p>Select day(s)</p>
        </div>
        <ScrollableDatepicker
          nodesPerSlide={noOfDaysLimit}
          nodesArray={getCalederDates()}
          onSelectionToggle={onSelectionToggle}
        />
      </div>
      <div className="modalFooter">
        <Button
          btnClass="primary btn-full"
          btnText={`Add ${objectType} ${filter(state.calenderDates, eachDate => eachDate.isSelected).length ? `to (${filter(state.calenderDates, eachDate => eachDate.isSelected).length}) days` : ''}`}
          onClick={() => {
            onAddToMealPlan()
            mealPlanModalToggleHandler(false)
          }}
          disabled={state.buttonStatus}
        />
        <Button
          btnClass="secondary btn-full btn-bp-sm"
          btnText="Cancel"
          onClick={() => {
            mealPlanModalToggleHandler(false)
          }}
        />
      </div>
    </div>
  )
}

AddToMealPlan.defaultProps = {
  objectType: "recipe"
}

export default AddToMealPlan
