import React from 'react';
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const PlusIcon = () => {
    return (
        <>
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="plusIcon">
                <path d="M16.1423 9.07101L2.00022 9.07107M9.07129 16.1421L9.07135 2.00006" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </>

    )
}
export default PlusIcon;
