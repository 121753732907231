import React from "react";
import '../styles/common.scss';


const CleanIngredientsIcon = (props) => {
  return (
    < >
      <svg width="32" height="30" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.1908 15.6567L4.44819 24.875C4.67961 27.089 6.45385 28.7707 8.5675 28.7707H22.6765C24.7902 28.7707 26.5644 27.089 26.7958 24.875L28.0532 15.6567M1 12.6946H30.2286M13.1188 17.0762L13.4813 24.3891M18.1098 17.0762L17.7472 24.3891M8.30518 17.0762L9.03801 24.3891M22.9233 17.0762L22.1904 24.3891M6.84717 9.77089L11.9616 1M24.389 9.77089L19.2668 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default CleanIngredientsIcon;
