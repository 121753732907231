import React from "react";

import cx from "classnames";
import filter from "lodash/filter";
import { Row, Col } from "react-flexbox-grid";
import VerticalListWithoutTitle from "./common/verticalListWithoutTitle";
import VerticalListWithTitle from "./common/verticalListWithTitle";
import NavbarImageCard from "./common/navbarImageCard";
import NavDropdownImage from "./common/NavDropdownImage";
import workout1 from "../../images/workout-nav-image1.png";
import workout2 from "../../images/workout-nav-image2.png";
import workout3 from "../../images/workout-nav-image3.png";
import workout4 from "../../images/workout-nav-image4.png";

const horizontalList = (workoutBodyParts) => {
  const getTaxonomy = (name) =>
    filter(workoutBodyParts, (taxonomy) => taxonomy.name == name)[0];

  const getImage = (taxonomy) =>
    taxonomy && taxonomy.secondary_image ? taxonomy.secondary_image : null;

  return [
    {
      image: workout1,
      title: "Core Focused",
      description: "Build a strong foundation with core workouts",
      type: "workout",
      href: "/workouts?bodyParts=core",
    },
    {
      image: workout2,
      title: "Full Body",
      description: "Strengthen and tone head to toe",
      type: "workout",
      href: "/workouts?bodyParts=full-body",
    },
    {
      image: workout3,
      title: "Lower Body",
      description: "Lower body workouts to hit every muscle",
      type: "workout",
      href: "/workouts?bodyParts=lower-body",
    },
    {
      image: workout4,
      title: "Upper Body",
      description: "Upper body workouts to hit every muscle",
      type: "workout",
      href: "/workouts?bodyParts=upper-body",
    },
  ];
};

const verticalList = [
  {
    href: "/workouts?order=favorite",
    title: "Most Popular",
  },
  {
    href: "/workouts?workout-types=strength",
    title: "Strength Training",
  },
  {
    href: "/workouts?workout-types=hiit",
    title: "HIIT Workouts",
  },
  {
    href: "/workouts?workout-types=circuit",
    title: "Circuit Training",
  },
  {
    href: "/workouts?workout-types=cardio",
    title: "Cardio Training",
  },
];

const WorkoutsNavDropdown = ({
  workoutBodyParts,
  history,
  activeHoveredEntry,
  requestCloseNavDropdown,
}) => (
  <div className="container">
    <Row className="workouts-dropdown navbar-dropdown-wrapper no-margin">
      <Col sm={3} lg={3} className="no-padding">
        <VerticalListWithoutTitle
          list={verticalList}
          history={history}
          onClose={requestCloseNavDropdown}
        />
      </Col>
      <Col sm={9} lg={9} className="no-padding">
        <NavbarImageCard
          list={horizontalList(workoutBodyParts)}
          history={history}
          onClose={requestCloseNavDropdown}
          render={(item) => (
            <NavDropdownImage type={item.type} src={item.image} />
          )}
        />
      </Col>
    </Row>
  </div>
);

export default WorkoutsNavDropdown;
