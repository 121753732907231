
import { put, takeEvery, call, select } from 'redux-saga/effects';
import axios from 'axios'
import { get } from '../helpers/requests'
import pushToSentry from '../helpers/pushToSentry';
import * as actions from '../actions/actionTypes';
import { getToken } from './selectors'
import { searches } from '../helpers/api';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  try {
    const {
      objectType,
      searchText,
      searchBy,
    } = action.payload;

    const token = yield select(getToken)

    if (objectType == 'recipe') {
      const formattedSearchText = searchText.split(',').join(' ');
      let searchResponse = yield call(axios, get(
        searches.searchRecipe(formattedSearchText, searchBy),
        '',
        token
      ))
      yield put({
        type: actions.SEARCH_REQUEST_SUCCESS,
        payload: {
          searchList: searchResponse.data,
          objectType: objectType,
          total_count: searchResponse.count
        }
      });
    }
    if (objectType == 'workout') {
      let searchResponse = yield call(axios, get(
        searches.searchWorkouts(`search=${searchText}`),
        '',
        token
      ))
      yield put({
        type: actions.SEARCH_REQUEST_SUCCESS,
        payload: {
          searchList: searchResponse.data,
          objectType: objectType,
          total_count: searchResponse.count
        }
      });
    }
    if (objectType == 'blog') {
      let searchResponse = yield call(axios, get(
        searches.searchBlogs(`search=${searchText}`),
        '',
        token
      ))
      yield put({
        type: actions.SEARCH_REQUEST_SUCCESS,
        payload: {
          searchList: searchResponse.data,
          objectType: objectType,
          total_count: searchResponse.count
        }
      });
    }
    if (objectType == 'video') {
      let searchResponse = yield call(axios, get(
        searches.searchVideos(`search=${searchText}`),
        '',
        token
      ))
      yield put({
        type: actions.SEARCH_REQUEST_SUCCESS,
        payload: {
          searchList: searchResponse.data,
          objectType: objectType,
          total_count: searchResponse.count
        }
      });
    }
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.SEARCH_REQUEST_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* search() {
  yield takeEvery(actions.SEARCH_REQUEST, task)
}