import axios from "axios";
import { put, takeEvery, call } from "redux-saga/effects";

import { post } from '../helpers/requests'
import { auth } from "../helpers/api";

import * as actions from '../actions/actionTypes'
import pushToSentry from "../helpers/pushToSentry";

function* task(action) {
  try {
    if (typeof localStorage !== 'undefined' && localStorage != null) {
      localStorage.removeItem('token');
    } else {
      pushToSentry('localStorage not available ')
    }


    yield put({
      type: actions.LOGOUT_NAVBAR_SUCCESS,
    });

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.LOGOUT_NAVBAR_FAILURE,
    });

  }
}

export default function* logoutNavbar() {
  yield takeEvery("LOGOUT_NAVBAR", task);
}