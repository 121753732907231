import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import Button from "../../common/button";
import DesktopBackgroundImage from "../../images/heroImageTwo.png";
import MobBackgroundImage from "../../images/mobileHeroImagetwo.png";
import { TransitionEaseInOne } from "../../common/transitions";

const HeroSectionTwo = (props) => {
  const [showMobileImage, setShowMobileImage] = useState(true);
  const [windowWidth, setWindowWidth] = useState(undefined);

  const addMemberToMailchimp = () => {
    props.history.push(`/pricing`);
  };
  useEffect(() => {
    if (window.innerWidth < 575) {
      setShowMobileImage(false);
      setWindowWidth(window.innerWidth);
    } else if (window.innerWidth > 575) {
      setShowMobileImage(true);
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 575) {
        setShowMobileImage(false);
        setWindowWidth(window.innerWidth);
      } else if (window.innerWidth > 575) {
        setShowMobileImage(true);
        setWindowWidth(window.innerWidth);
      }
    });
  }, []);
  return (
    <>
      <TransitionEaseInOne>
        <section
          className="home-hero"
          style={{
            backgroundImage: showMobileImage
              ? `linear-gradient(to left, rgba(255, 255, 255, 0) 37%, rgba(255, 255, 255, 1) 63%),url(${DesktopBackgroundImage})`
              : `linear-gradient(to top, rgba(255, 255, 255, 0) 23%, rgba(255, 255, 255, 1) 46%),url(${DesktopBackgroundImage})`,
          }}
        >
          <div className="container">
            <Row className="home-hero-wrapper ome-hero-heading-lg no-margin-sm">
              <Col xs={12} md={12} lg={8} className="no-padding-sm">
                <div className="home-hero-text">
                  <h1>
                    Weekly Recipes &nbsp;<br></br> & Meal Plans
                  </h1>
                  <p>
                    There's no better time to start learning how to&nbsp;
                    <br></br> cook healthy & delicious meals at home!
                  </p>
                  <form className="hero-form">
                    <Button
                      btnClass="btn btn-primary btn-md"
                      btnText={
                        !props.isAuthenticated
                          ? "Try free for 3 days"
                          : "Get Started"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (props.isAuthenticated) {
                          props.history.push("/recipes");
                        } else {
                          props.history.push("pricing");
                          addMemberToMailchimp();
                        }
                      }}
                    />
                    {!props.isAuthenticated ? (
                      <Button
                        btnClass="btn btn-transparent-link"
                        btnText="See Plans & Pricing"
                        onClick={(e) => {
                          e.preventDefault();
                          if (props.isAuthenticated) {
                            props.history.push("/recipes");
                          } else {
                            props.history.push("pricing");
                            addMemberToMailchimp();
                          }
                        }}
                      />
                    ) : null}
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </TransitionEaseInOne>
    </>
  );
};

export default HeroSectionTwo;
