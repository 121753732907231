import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import Button from '../common/button'
import img from '../images/tvshow-heroImg.png'
import episode_2_img from "../images/episode_2.jpg";
import episode_3_img from "../images/episode_3.jpg";
import episode_4_img from "../images/episode_4.png";
import episode_5_img from "../images/episode_5.jpg";
import episode_6_img from "../images/episode_6.jpg";
import episode_7_img from "../images/episode_7.jpg";
import episode_8_img from "../images/episode_8.jpg";
import episode_9_img from "../images/episode_9.jpg";
import episode_10_img from "../images/episode_10.jpg";
import episode_11_img from "../images/episode_11.png";
import episode_12_img from "../images/episode_12.jpg";
import PlayButtonIcon from '../images/PlayButton';
import VideoPlayIcon from '../images/VideoPlayIcon.js';
import ModalVideo from '../videos/components/modalVideoLib';

import { appEssentialFetch } from "../../actions";
import { TransitionEaseInOne, TransitionTwo, TransitionThree } from '../common/transitions';

const TvshowPage = (props) => {

  const [videoState, setVideoState] = useState({
    videoId: null,
    openVideoModal: false
  })

  useEffect(() => {
    props.appEssentialFetch();
  }, [])

  return (
    <>
      <Helmet>
        <title>TV Show | Dashing Dish</title>
        <meta name="description" content="Dashingdish TV Show page" />
      </Helmet>
      <section
        className="show-hero"
        style={{
          backgroundImage: `url(${img})`
        }}>
        <div className="container">
          <TransitionEaseInOne>
            <Row className="tvshow-wrapper no-margin">
              <Col xs={12} md={12} lg={6} className="no-padding">
                <div className="show-hero-wrapper">
                  <small>THIS WEEK’S SHOW  |  EP 4</small>
                  <h1>“Healthy Takeout
              <br></br> Dishes”
          </h1>
                  <p>Can your make your favorite take out healthy? Katie’s friends are joining her in the kitchen for grain free chicken nuggets & sauce, gluten free pizza with all the toppings and sparkling slushies to drink. Healthy take out with friends is going to make this get together a blast and easy on the waist line!</p>
                  <Button
                    btnClass="btn btn-primary btn-lg"
                    btnText="Watch this episode"
                    onClick={() => setVideoState({ videoId: 'jBcNRnIUDfg', openVideoModal: true })}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} lg={6} className="tvshow-video no-padding">
                <figure className="thumb-video-overlay" onClick={() => setVideoState({ videoId: 'jBcNRnIUDfg', openVideoModal: true })}>
                  <img src={"http://i3.ytimg.com/vi/jBcNRnIUDfg/maxresdefault.jpg"} className="img_fluid"></img>
                  <figure>
                    <PlayButtonIcon></PlayButtonIcon>
                  </figure>
                </figure>
              </Col>
            </Row>
          </TransitionEaseInOne>
        </div>
      </section>
      <main
        className="tvshow-page">
        <section className="to-watch">
          <div className="container">
            <TransitionTwo>
              <Row className="no-margin">
                <Col xs={12} md={6} lg={5} className="no-padding">
                  <div className="when-to-watch">
                    <h3>When to watch</h3>
                    <div className="list-card">
                      <div className="list">
                        <h5>Tuesday October 9th</h5>
                        <ul>
                          <li>
                            <a href="http://dashingdish.ctvn.org/" target="_blank" >
                              Channel List
                            </a>
                          </li>
                          <li>
                            <a href="http://dashingdish.ctvn.org/" target="_blank">
                              Live Stream
                            </a>
                          </li>
                        </ul>
                        <span> 2 & 9pm EST</span>
                      </div>
                      <div className="list">
                        <h5>Tuesday October 9th</h5>
                        <ul>
                          <li>
                            <a href="http://dashingdish.ctvn.org/" target="_blank">
                              Channel List
                            </a>
                          </li>
                          <li>
                            <a href="http://dashingdish.ctvn.org/" target="_blank">
                              Live Stream
                            </a>
                          </li>
                        </ul>
                        <span> 2 & 9pm EST</span>
                      </div>
                      <div className="list">
                        <h5>Tuesday October 9th</h5>
                        <ul>
                          <li>
                            <a href="http://dashingdish.ctvn.org/" target="_blank">
                              Channel List
                            </a>
                          </li>
                          <li>
                            <a href="http://dashingdish.ctvn.org/" target="_blank">
                              Live Stream
                            </a>
                          </li>
                        </ul>
                        <span> 2 & 9pm EST</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={7} className="no-padding">
                  <div className="episode">
                    <h3>Recipes from this episode</h3>
                    <ul>
                      <li>
                        <a href={'/recipe/clean-eating-hamburger-helper'} onClick={(e) => { e.preventDefault(); props.history.push("/recipe/clean-eating-hamburger-helper") }}>
                          <figure>
                            <img src="https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/001/769/full_sm/DSCF9793_2.JPG?1553775913" className="img_fluid"></img>
                          </figure>
                          <h5>Clean Eating Hamburger Helper</h5>
                        </a>
                      </li>
                      <li>
                        <a href={'/recipe/sweet-potato-chicken-nuggets'} onClick={(e) => { e.preventDefault(); props.history.push("/recipe/sweet-potato-chicken-nuggets") }}>
                          <figure>
                            <img src="https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/000/789/full_sm/IMG_0334.jpg?1444250751" className="img_fluid"></img>
                          </figure>
                          <h5>Sweet Potato Chicken Nuggets</h5>
                        </a>
                      </li>
                      <li>
                        <a href={'/recipe/salted-caramel-mocha-frap'} onClick={(e) => { e.preventDefault(); props.history.push("/recipe/salted-caramel-mocha-frap") }}>
                          <figure>
                            <img src="https://s3.amazonaws.com/v3-media.dashingdish.com/recipe_photos/images/000/000/935/full_sm/IMG_0160.JPG?1461072460" className="img_fluid"></img>
                          </figure>
                          <h5>Salted Caramel Protein Mocha Frappuccino</h5>
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </TransitionTwo>
          </div>
        </section>
        <section className="more-episodes">
          <TransitionThree>
            <div className="container">
              <h3>More episodes from season 1</h3>
              <ul className="more-episodes-wrapper">
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'aAPsj2tFHKQ', openVideoModal: true })}>
                    <img src={episode_2_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Easy Entertaining”</h5>
                  <small>Episode 2</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'NHVRG6CLrnM', openVideoModal: true })}>
                    <img src={episode_3_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Meals on the Go”</h5>
                  <small>Episode 3</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'zk1D4UZn1WY', openVideoModal: true })}>
                    <img src={episode_4_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Tailgating Treats”</h5>
                  <small>Episode 4</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'TQfSecuRb6s', openVideoModal: true })}>
                    <img src={episode_5_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Budget Friendly Healthy Recipes”</h5>
                  <small>Episode 5</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'I2i97FCRUbw', openVideoModal: true })}>
                    <img src={episode_6_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Q&A with Katie ”</h5>
                  <small>Episode 6</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'jBcNRnIUDfg', openVideoModal: true })}>
                    <img src={episode_7_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“15 Minute Meals”</h5>
                  <small>Episode 7</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'f15qv-5KmE0', openVideoModal: true })}>
                    <img src={episode_8_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Meal Planning Basics”</h5>
                  <small>Episode 8</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'hdQHRLoe6ZA', openVideoModal: true })}>
                    <img src={episode_9_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Freezer Meals”</h5>
                  <small>Episode 9</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'LSwYh1P3mXc', openVideoModal: true })}>
                    <img src={episode_10_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Christmas Special”</h5>
                  <small>Episode 10</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'VxIA9g7PU2I', openVideoModal: true })}>
                    <img src={episode_11_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Sweet Treats Made Healthy”</h5>
                  <small>Episode 11</small>
                </li>
                <li>
                  <figure className="episodes-video" onClick={() => setVideoState({ videoId: 'LHT7WqVAl2c', openVideoModal: true })}>
                    <img src={episode_12_img} className="img_fluid"></img>
                    <VideoPlayIcon></VideoPlayIcon>
                  </figure>
                  <h5>“Feeding Picky Eaters”</h5>
                  <small>Episode 12</small>
                </li>
              </ul>
              {/* <div className="laod-more-episodes">
              <Button
              btnClass="btn btn-primary btn-md"
              btnText="Load more episodes"
              onClick={() => {
              }} />
            </div> */}
            </div>
          </TransitionThree>
        </section>
        {
          videoState.openVideoModal &&
          <ModalVideo
            channel={'youtube'}
            isOpen={videoState.openVideoModal}
            videoId={videoState.videoId}
            vimeo={{ autoplay: 1, autopause: 0 }}
            onClose={() => {
              document.body.classList.remove('ReactModal__Body--open');
              setVideoState({ videoId: null, openVideoModal: false })
            }} />
        }
      </main>
    </>
  )
}

const mapStateToProps = () => ({})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(TvshowPage);