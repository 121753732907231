import React, { useState, useEffect } from 'react';

import replace from 'lodash/replace'
import { fromJS } from 'immutable';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter';
import capitalize from 'lodash/capitalize';
import concat from 'lodash/concat';
import { Helmet } from 'react-helmet'

import ChangeSortOrder from '../../../common/changeSortOrder'
import FiltersHOC from '../../common/blogfiltersHOC'
import CloseableTag from '../../../common/closeableTag';
import getMarkdownImage from '../../../../helpers/getMarkdownImage';
import convertQuality from '../../../../helpers/convertQuality';
import GridCard from '../../../common/GridCard';

// import Carousel from '../../common/featuredCarousel'
import Loader from '../../../images/loader';

import { removeCharecterFromIndex } from '../../../../helpers/converters'

// New imports 
import {
  generateAppEssentialPayload,
  generateBlogQueryFromFilterForFrontend,
} from '../../../../helpers/filterConversions';
import { isNewTagStatus } from "../../../../helpers/getNewTagStatus";

import {
  TransitionOne
} from "../../../common/transitions";
import AdComponent from '../../../common/ad';

const BlogList = ({
  appEssentialFetch,
  blogs,
  isAuthenticated,
  history,
  andler,
  toggleFavoriteModal,
  boards,
  createBoard,
  setObjectBoards,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  moreBlogAvailable,
  fetchBlogs,
  appliedFilters,
  fetchStatus,
  blogFilterName,
  deleteFavourite,
  loginRedirectHandler,
  trendingType,
  order,
  searchTerm,
  setFiltersAndFetch,
  // searchBy,
  ...props
}) => {

  useEffect(() => {
    // Can be of few types
    // 1. /blog/favorites/:favoriteBoardSlug
    // 2. /blog/featured/:defaultDietary
    // 3. /blog/:defaultFodType
    // 4. /blog/?filters

    let favorite = {
      show: false,
      board: null
    };


    const isFavorites = (props.match.path === '/blog/favorites/:favoriteBoardSlug' || props.match.path === '/blog/favorites');
    if (isFavorites) {
      const favoriteBoardSlug = props.match.params.favoriteBoardSlug ? props.match.params.favoriteBoardSlug : null;
      favorite.show = true;
      favorite.board = favoriteBoardSlug;
      const appEssentialPayload = generateAppEssentialPayload({
        objectType: 'blog',
        queryString: history.location.search,
        favorite,
        meta: {
          objectType: 'blog',
          listType: 'favorited'
        }
      });
      appEssentialFetch(appEssentialPayload);
    } else if (props.match.path === '/blog/topic/:blogTopic') {
      const appEssentialPayload = generateAppEssentialPayload({
        objectType: 'blog',
        queryString: `?categories=${props.match.params.blogTopic}`,
        favorite: {
          show: false,
          board: null,
        },
        meta: {
          objectType: 'blog',
          listType: 'featured'
        }
      });
      appEssentialFetch(appEssentialPayload);
    } else {
      let listType = 'unfiltered'

      if (isFavorites) {
        listType = 'favorited'
      } else if (history.location.search.length !== 0) {
        listType = 'filtered'
      }

      const appEssentialPayload = generateAppEssentialPayload({
        objectType: 'blog',
        queryString: history.location.search,
        favorite: {
          show: false,
          board: null
        },
        meta: {
          objectType: 'blog',
          listType
        }
      });
      appEssentialFetch(appEssentialPayload);
    }


  }, [history.location.search, props.match.path, props.match.url]);



  const renderBlogFilterTag = () => {
    const filters = filter(blogFilterName.filterArray, filter => filter.type !== 'order')

    if (filters.length > 0) {
      return (
        <div className="filterName">
          <div className="filterTitle">
            {
              map(filters, (filter) => {
                return (
                  <CloseableTag
                    key={filter.slug}
                    title={filter.name}
                    onClose={() => {
                      let updatedFilter;
                      if (filter.type === 'archives') {
                        updatedFilter = {
                          ...appliedFilters,
                          [filter.type]: appliedFilters[filter.type].filter(eachSlug => eachSlug !== filter.slug),
                        };
                      } else {
                        const updatedFilterGroup = appliedFilters[filter.type].filter(eachSlug => eachSlug !== filter.slug);
                        updatedFilter = {
                          ...appliedFilters,
                          [filter.type]: updatedFilterGroup,
                        }
                      }
                      history.push(`/blog?${removeCharecterFromIndex(generateBlogQueryFromFilterForFrontend(fromJS(updatedFilter)), 0)}`)
                    }}
                  />
                )
              })
            }
          </div>
        </div>
      );
    } else {
      return (
        <h3 className="header-content">
          {'Recent Blogs'}
        </h3>
      )
    }
  }

  // dynamic header
  const renderDynamicBlogHeader = () => {
    if (blogFilterName.type === 'favorite') {
      return (
        <>
          <Helmet>
            <title>
              {`Blogs in ${(blogFilterName.title) ? capitalize(blogFilterName.title) : ''}`}
            </title>
          </Helmet>
          <h3 className="header-content">
            {`${capitalize(blogFilterName.title)}`}
          </h3>
        </>
      )
    } else if (blogFilterName.type === 'filter') {
      return renderBlogFilterTag();
    } else if (blogFilterName.type === 'search') {
      return (
        <>
          <span className="filtered-text">{'Results for'}&nbsp;</span>
          {`${searchTerm}`}
        </>
      )
    } else {
      return (
        <h3 className="header-content">
          {'Recent Blogs'}
        </h3>
      )
    }
  }

  const noBlogsMessage = (message) => {
    return (
      <>

        {renderDynamicBlogHeader()}
        <div className="pageMessage">
          {/* <h2>{message}</h2> */}
          <div className="empty-content">
            <h2>This category is as empty<br></br>
              as my plate after Thanksgiving.</h2>
            <p>Go to the <a
              href={'/blog'}
              onClick={(e) => {
                e.preventDefault()
                history.push('/blog')
              }}
            > Blog index </a> to add some!</p>
          </div>
        </div>
      </>
    )
  }

  // // checks if we are in favorites view or not
  // const isFavorites = (props.match.path === '/recipes/favorites/:favoriteBoardSlug' || props.match.path === '/recipes/favorites')

  const renderBlogMeta = (item) => {
    return (
      <div className="recipe-meta">
        {`${moment(item.updated_at).format('MMMM Do YYYY').replace(/(\d)(st|nd|rd|th)/g, '$1$2')}`}
      </div>
    )
  }

  const isFavorites = (props.match.path === '/blog/favorites/:favoriteBoardSlug' || props.match.path === '/blog/favorites');

  const noInitialFetchHappening = (
    !fetchStatus.unfilteredListInitialTypeStatus.isFetching &&
    !fetchStatus.filteredListInitialTypeStatus.isFetching &&
    !fetchStatus.favoritedListInitialTypeStatus.isFetching &&
    !fetchStatus.featuredListInitialTypeStatus.isFetching
  );
  const noPaginationFetchHappening = (
    !fetchStatus.unfilteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.filteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.favoritedListPaginationTypeStatus.isFetching &&
    !fetchStatus.featuredListPaginationTypeStatus.isFetching
  );

  const noFetchHappening = (
    noInitialFetchHappening && noPaginationFetchHappening
  )

  let preloaderBlogs = map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], (item, index) => ({ isLoading: true, id: null, image: '', content: '' }))

  const modifiedPreloadedBlogs = noPaginationFetchHappening ? preloaderBlogs : concat(blogs, preloaderBlogs)
  const actualBlogs = !noFetchHappening ? modifiedPreloadedBlogs : blogs;

  // MAIN RETURN
  return (
    <>
      <Helmet>
        <title>Blog List | Dashing Dish</title>
        <meta name="description" content="Dashingdish Blog List page" />
      </Helmet>
      <div className="container">
        <AdComponent />
        {
          (noFetchHappening && blogs.length === 0 && !moreBlogAvailable) ? noBlogsMessage('No Blogs') : (
            <TransitionOne>
              <ChangeSortOrder
                setFiltersAndFetch={setFiltersAndFetch}
                appliedFilters={appliedFilters}
                history={history}
                objectType="blog"
                title={renderDynamicBlogHeader()}
                order={order}
                showActions={!isFavorites}
              >
                <InfiniteScroll
                  className="infiniteScroll"
                  initialLoad={false}
                  // threshold={500}
                  hasMore={moreBlogAvailable}
                  loadMore={(
                    noFetchHappening
                  ) ?
                    () => {
                      if (history.location.search) {
                        fetchBlogs({ pagination: true, filterGroup: 'filters' })
                      } else if (props.match.path === '/blog/favorites/:favoriteBoardSlug' || props.match.path === '/blog/favorites') {
                        fetchBlogs({ pagination: true, filterGroup: 'favorites' })
                      } else {
                        fetchBlogs({ pagination: true, filterGroup: 'unFiltered' })
                      }
                    } : () => { }
                  }
                  loader={
                    <div className="container">
                      <div className="preLoader-minimal-height">
                        <Loader></Loader>
                      </div>
                    </div>
                  }
                >
                  <ul className="blogList">
                    <>
                      {
                        (actualBlogs || []).map((item, index) => {
                          // Local based most favorited might have wrong count
                          if (item) {
                            return (
                              <li key={index} className="blog">
                                <GridCard
                                  feature='blog'
                                  objectType="blog"
                                  history={history}
                                  coverImage={item.image ? convertQuality(item.image, 'medium') : (!isEmpty(item.content) ? getMarkdownImage(item.content, 'medium')[1] : '')}
                                  item={item}
                                  deleteFavourite={deleteFavourite}
                                  loginRedirect={loginRedirectHandler}
                                  isAuthenticated={isAuthenticated}
                                  toggleFavoriteModal={toggleFavoriteModal}
                                  renderMeta={renderBlogMeta(item)}
                                  thumbnail={item.image ? convertQuality(item.image, 'thumb') : (!isEmpty(item.content) ? getMarkdownImage(item.content, 'thumb')[1] : '')}
                                  showTag={isNewTagStatus({ date: item.updated_at, isISOFormat: true })}
                                />
                              </li>
                            )
                          } else return null
                        })
                      }
                    </>
                  </ul>
                </InfiniteScroll>
              </ChangeSortOrder>
            </TransitionOne>
          )
        }
      </div>
    </>
  )
}

export default FiltersHOC(BlogList);