import React from 'react';
import take from 'lodash/take'
import map from 'lodash/map';
import filter from 'lodash/filter'
import ReactImageFallback from "react-image-fallback";
import SliderSlick from './carouselSlick'


import { Link, withRouter } from 'react-router';
import Placeholder from '../common/placeholder'

const Carousel = ({
  isFetching,
  data,
  onClick,
  history
}) => {
  const filteredData = filter(data, featured => featured.featured);
  const list = filteredData.length > 0 ? filteredData : [{}, {}]
  // const list = filteredData;


  return (
    <SliderSlick
      isFetching={isFetching}>
      {
        map(list, (featured, index) => {
          return <div className="collectionCard" key={featured.slug}>
            <a
              // href={`recipes/featured/${featured.slug}`}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onClick(featured.slug, featured.title, 'collection')
              }}
              href={`recipes/featured/${featured.slug}`}
              // onClick={() => {
              //   onClick(featured.slug, featured.title, 'collection')
              // }}
              className="collection">
              <h2>{featured.slug}</h2>
              <figure>
                <ReactImageFallback
                  src={featured.image}
                  className="CardImg"
                  fallbackImage={
                    <Placeholder
                      styles={{
                        height: 260,
                      }}
                    />
                  }
                />
              </figure>
              <div className="feature-overlay">
                <h5>featured</h5>
                <p>{featured.name}</p>
              </div>
            </a>
          </div>
        })
      }
    </SliderSlick>
  )
}

export default withRouter(Carousel);
