import { fork } from "redux-saga/effects";

import createNewUser from "./createNewUser";
import appEssentialFetch from "./appEssentialFetch";
import fetchRecipeDetails from "./fetchRecipeDetails";
import fetchRecipes from "./fetchRecipes";
import fetchWorkouts from "./fetchWorkouts";
import fetchBlogs from "./fetchBlogs";
import fetchVideos from "./fetchVideos";
import assignPlanToUser from "./assignPlanToUser";
// import fetchWorkoutDetails from './fetchWorkoutDetails'

import addItemsToGroceryWaitlist from "./addItemsToGroceryWaitlist";
import addAllToGroceryList from "./addAllToGroceryList";
import addToGroceryList from "./addToGroceryList";

import sendComment from "./sendComment";
import sendNote from "./sendNote";
import createFavourite from "./createFavourite";
import deleteFavourite from "./deleteFavourite";
import sendReplyComment from "./sendReplyComment";

// favourite boards
import createBoard from "./createBoard";
import deleteFavouriteBoard from "./deleteFavouriteBoard";
import updateFavouriteBoard from "./updateFavouriteBoard";

import objectSpecificEssential from "./objectSpecificEssential";

import clearAllGroceryListItems from "./clearAllGroceryListItems";
import addIngredientsInGroceryListAisle from "./addIngredientsInGroceryListAisle";
import addIngredientsInGroceryListRecipe from "./addIngredientsInGroceryListRecipe";

import deleteIngredientInGroceryList from "./deleteIngredientsInGroceryList";

import moveIngredientsWithinAisleGroceryList from "./moveIngredientsWithinAisleGroceryList";
import userAuthenticate from "./userAuthenticate";

import addMealInMealPlan from "./addMealInMealPlan";
import deleteMealFromMealPlan from "./deleteMealFromMealPlan";
import duplicateMealPlan from "./duplicateMeal";
import moveMealInMealPlan from "./moveMealInMealPlan";
import popObjectFromNavigationStack from "./popObjectFromNavigationStack";

//filters
import getAllRecipesFromSelectedCollection from "./getAllRecipesFromSelectedCollection";
import getAllWorkoutsFromSelectedCollection from "./getAllWorkoutsFromSelectedCollection";
import filterRecipesByCategory from "./filterRecipesByCategory";

import getFavoritesFilteredData from "./getFavoritesFilteredData";
import clearAllFilters from "./clearAllFilters";

import setFiltersAndFetch from "./setFiltersAndFetch";
import filterTriggeredRecipeFetch from "./filterTriggeredRecipeFetch";

//search
import search from "./search";
import calculateRecipeFilterCount from "./calculateRecipeFilterCount";
import calculateWorkoutFilterCount from "./calculateWorkoutFilterCount";
import removeToaster from "./removeToaster";
import addIngredientsOfDayToGroceryList from "./addIngredientsOfDayToGroceryList";
import addIngredientsOfRangeToGroceryList from "./addIngredientsOfRangeToGroceryList";
import addMealPlanToCalendar from "./addMealPlanToCalendar";
import addCustomMealPlanToGroceryList from "./addCustomMealPlanToGroceryList";
import addCustomRecipeToMealPlan from "./addCustomRecipeToMealPlan";

import clearCalendarDay from "./clearCalendarDay";
import saveCalendarDay from "./saveCalendarDay";
import addSavedDay from "./addSavedDay";
import addMealToMultipleDates from "./addMealToMultipleDates";

import changeTrendingType from "./changeTrendingType";
import completeRegistration from "./completeRegistration";
import cancelSubscription from "./cancelSubscription";
import reverseCancelSubscription from "./reverseCancelSubscription";
import changePlanSubscription from "./changePlanSubscription";
import editUserProfileRequest from "./editUserProfileRequest";
import changePaymentType from "./changePaymentType";

import replaceIngredients from "./replaceIngredients";

import registrationUnified from "./registrationUnified";

export default function* rootCommonSaga() {
  yield fork(appEssentialFetch);
  yield fork(fetchRecipes);
  yield fork(fetchWorkouts);
  yield fork(fetchBlogs);
  yield fork(fetchVideos);
  // yield fork(fetchWorkoutDetails);

  yield fork(sendComment);
  yield fork(sendNote);
  yield fork(deleteFavourite);
  yield fork(addAllToGroceryList);
  yield fork(addToGroceryList);
  yield fork(addItemsToGroceryWaitlist);
  yield fork(createFavourite);
  yield fork(createBoard);
  yield fork(deleteFavouriteBoard);
  yield fork(updateFavouriteBoard);
  yield fork(sendReplyComment);
  yield fork(createNewUser);
  yield fork(clearAllGroceryListItems);
  yield fork(addIngredientsInGroceryListAisle);
  yield fork(addIngredientsInGroceryListRecipe);
  yield fork(deleteIngredientInGroceryList);
  yield fork(moveIngredientsWithinAisleGroceryList);
  yield fork(userAuthenticate);
  yield fork(addMealInMealPlan);
  yield fork(deleteMealFromMealPlan);
  yield fork(moveMealInMealPlan);
  yield fork(duplicateMealPlan);
  yield fork(getAllRecipesFromSelectedCollection);
  yield fork(getAllWorkoutsFromSelectedCollection);
  yield fork(filterRecipesByCategory);
  yield fork(getFavoritesFilteredData);
  yield fork(clearAllFilters);
  yield fork(search);
  yield fork(popObjectFromNavigationStack);
  yield fork(setFiltersAndFetch);
  yield fork(filterTriggeredRecipeFetch);
  yield fork(calculateRecipeFilterCount);
  yield fork(calculateWorkoutFilterCount);
  yield fork(removeToaster);

  yield fork(addIngredientsOfDayToGroceryList);
  yield fork(addIngredientsOfRangeToGroceryList);
  yield fork(addMealPlanToCalendar);
  yield fork(addCustomMealPlanToGroceryList);
  yield fork(addCustomRecipeToMealPlan);
  yield fork(clearCalendarDay);
  yield fork(saveCalendarDay);
  yield fork(addSavedDay);
  yield fork(addMealToMultipleDates);
  yield fork(changeTrendingType);
  yield fork(assignPlanToUser);
  yield fork(completeRegistration);
  yield fork(cancelSubscription);
  yield fork(reverseCancelSubscription);
  yield fork(changePlanSubscription);
  yield fork(editUserProfileRequest);
  yield fork(changePaymentType);

  yield fork(replaceIngredients);
  yield fork(registrationUnified);
}
