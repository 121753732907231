import React, { useState, useEffect } from 'react'

import { fromJS } from 'immutable'
import isEmpty from 'lodash/isEmpty'
import Filters from '../../common/filters'
import isNil from 'lodash/isNil'
import find from 'lodash/find'

import {
  generateBlogQueryFromFilterForFrontend
} from '../../../helpers/filterConversions'

import Modalwrapper from '../../common/modal';
import FavoriteModal from '../../common/favoriteModal';
import PopupModal from '../../common/popupModal';
import getMarkdownImage from '../../../helpers/getMarkdownImage'
import convertQuality from '../../../helpers/convertQuality';
import { removeCharecterFromIndex } from '../../../helpers/converters'

import {
  generateFilterFromQueryString,
  getBlogCategories,
  getBlogArchives,
  getFilterOptions,
  getSelectorList,
  getFavoriteFilterOptions,
  getFavouriteBoardsNavOptions,
  searchTerm,
} from './configBlog'

const FiltersHOC = (Component) => (props) => {
  const [state, setState] = useState({
    showLoginModal: false,
    activeUnAuthClickedRecipeName: '',
    showFilterModal: false,
    showFavoriteModal: false,
    activeFavoritingBlogId: null,
    favoriteView: false,
    showSearchDropdown: false,
  });

  const loginRedirectHandler = (title) => {
    setState(prevState => ({ ...prevState, activeUnAuthClickedRecipeName: title, showLoginModal: true }));
  }

  const loginModalCloseHandler = () => {
    setState(prevState => ({ ...prevState, showLoginModal: false }))
  }

  const toggleFavoriteModal = ({ itemId, modalState }) => {
    setState(prevState => ({ ...prevState, showFavoriteModal: modalState, activeFavoritingBlogId: itemId }));
  }

  useEffect(() => {
    if (!isNil(props.fetchStatus.globalError)) {
      props.history.push('/blog')
    }
  }, [props.fetchStatus.globalError]);

  // // for the fav modal rendering condition
  let activeFavoritingBlog = find(props.featuredList || [], blog => blog && blog.id === state.activeFavoritingBlogId);
  activeFavoritingBlog = isEmpty(activeFavoritingBlog) ? find(props.trendingBlogs, blog => blog && blog.id === state.activeFavoritingBlogId) : activeFavoritingBlog
  activeFavoritingBlog = isEmpty(activeFavoritingBlog) ? find(props.blogs || [], blog => blog && blog.id === state.activeFavoritingBlogId) : activeFavoritingBlog;

  // // ALL GENERATED PROPS FOR NAV FILTER
  // // the categories data 
  const blogCategoriesNavOptions = getBlogCategories(props.categories, props.history)

  const archiveNavOptions = getBlogArchives(props.history)

  // // the final nav options , ie collections ....
  const filterOptions = getFilterOptions(
    blogCategoriesNavOptions,
    archiveNavOptions,
    props.history
  )

  // // the fav boards data
  const favouriteBoardsNavOptions = getFavouriteBoardsNavOptions(props.boards, props.blogFilterName)

  // // the final fav options
  const favoriteFilterOptions = getFavoriteFilterOptions(favouriteBoardsNavOptions, props.blogFilterName)

  // // the search -> searchBy dropdown list 
  const selectorList = getSelectorList()

  // // ALL LOGIC FUNCTIONS
  // // what happens when an item in fav filter dropdown is selected (for query parma setting)
  const navFilterActionDispatch = (slug, filterName, defaultPage) => {
    let finalQueryParam = '';

    let filterFromQueryString = generateFilterFromQueryString('blog', props.history)
    if (defaultPage) {

    } else {
      if (filterName === 'Categories') {

        const categories = filterFromQueryString.blog.filters.categories;
        const index = categories.findIndex(Category => Category === slug);
        if (index === -1) {
          filterFromQueryString.blog.filters.categories = [...categories, slug];
          filterFromQueryString.blog.filters.search = [];
        } else {
          let splicedCategory = categories.splice(index, 1);
          filterFromQueryString.blog.filters.categories = categories
        }
      } else if (filterName === 'Archives') {
        filterFromQueryString.blog.filters.search = [];
        const archives = filterFromQueryString.blog.filters.archives;

        const index = archives.findIndex(archive => archive === slug);
        if (index === -1) {
          filterFromQueryString.blog.filters.archives = [...archives, slug];
        } else {
          let splicedArchive = archives.splice(index, 1);

          filterFromQueryString.blog.filters.archives = archives
        }
      }

      finalQueryParam = generateBlogQueryFromFilterForFrontend(fromJS(filterFromQueryString.blog.filters))

      // condition to remove if & present in index 0
      if (finalQueryParam.charAt(0) === '&') {
        finalQueryParam = removeCharecterFromIndex(finalQueryParam, 0)
      }
      props.history.push(`/blog?${finalQueryParam}`)
    }
  }

  const isFavorites = (props.match.path === '/blog/favorites/:favoriteBoardSlug' || props.match.path === '/blog/favorites')
  const displayImage = !isEmpty(activeFavoritingBlog) ? (convertQuality(activeFavoritingBlog.image, 'thumb') || (getMarkdownImage(activeFavoritingBlog.content), 'thumb')[1]) : null;
  const thumbnail = !isEmpty(activeFavoritingBlog) ? (convertQuality(activeFavoritingBlog.image, 'thumb') || (getMarkdownImage(activeFavoritingBlog.content), 'thumb')[1]) : null;
  const showFilters = (props.match.path !== '/blog/topic/:blogTopic');
  return (
    <section className="blogs">
      <Filters
        objectType='blog'
        showFilters={showFilters}
        actions={(name, slug, filterName) => navFilterActionDispatch(slug, filterName)}
        options={filterOptions}
        staticOption={
          {
            primaryOption: 'Clear Filters',
            secondaryOption: 'All Blogs',
            route: '/blog',
            secondaryRoute: '/blog/preview'
          }
        }
        history={props.history}
        isAuthenticated={props.isAuthenticated}
        dropDownReturnActionText={`See all ${props.searchCount} Blogs`}
        dropDownReturnAction={(searchText, searchBy) => {
          props.history.push(`/blog?search=${searchText}`)
        }}
        selectorList={getSelectorList()}
        placeholder='Search blog'
        searchText={props.searchTerm}
        onSearchChange={(searchText, searchBy) => props.searchBlogs({
          objectType: 'blog',
          searchText: searchText,
          searchBy: 'title'
        })}
        onClickSearchClear={() => {
          props.history.push(`/blog`)
        }}
        defaultSearchText={props.searchTerm}
        defaultSearchBy="title"
        searchList={props.searchList}
        searchStatus={props.fetchStatus.blogsSearch}
        searchCount={props.searchCount}
        showDefaultInput={!isNil(props.searchTerm)}

        isFavorites={isFavorites}
        favoriteOptions={favoriteFilterOptions}
        updateFavouriteBoard={props.updateFavouriteBoard}
        deleteFavouriteBoard={props.deleteFavouriteBoard}
        createBoard={props.createBoard}
      />
      <Component
        {...props}
        loginRedirectHandler={loginRedirectHandler}
        loginModalCloseHandler={loginModalCloseHandler}
        toggleFavoriteModal={toggleFavoriteModal}
      />
      <Modalwrapper
        isOpen={state.showFavoriteModal}
        minHeight='600px'
        maxWidth='500px'
        onRequestClose={() => { toggleFavoriteModal({ itemId: null, modalState: false }) }}
      >
        <FavoriteModal
          boards={props.boards}
          objectType="blog"
          activeObject={activeFavoritingBlog}
          createBoard={props.createBoard}
          clearBoardSelection={props.clearBoardSelection}
          createFavourite={props.createFavourite}
          setObjectBoards={props.setObjectBoards}
          toggleFavoriteModal={toggleFavoriteModal}
          deleteFavouriteBoard={props.deleteFavouriteBoard}
          updateFavouriteBoard={props.updateFavouriteBoard}
          displayImage={displayImage}
          thumbnail={thumbnail}
        />
      </Modalwrapper>
      <Modalwrapper
        isOpen={state.showLoginModal}
        onRequestClose={loginModalCloseHandler}>
        <PopupModal
          objectName={state.activeUnAuthClickedRecipeName}
          history={history}
          closeModal={loginModalCloseHandler}
          objectType="blog"
        />
      </Modalwrapper>
    </section>

  )
}

export default FiltersHOC;