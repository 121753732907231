import React from "react";
import '../styles/common.scss';


const Arrow = (props) => {
  return (
    < >
      <svg width="49" height="16" viewBox="0 0 49 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.7071 8.7071C49.0976 8.31658 49.0976 7.68341 48.7071 7.29289L42.3431 0.928928C41.9526 0.538404 41.3195 0.538404 40.9289 0.928929C40.5384 1.31945 40.5384 1.95262 40.9289 2.34314L46.5858 8L40.9289 13.6569C40.5384 14.0474 40.5384 14.6805 40.9289 15.0711C41.3195 15.4616 41.9526 15.4616 42.3431 15.0711L48.7071 8.7071ZM8.74228e-08 9L48 9L48 7L-8.74228e-08 7L8.74228e-08 9Z" fill="#C6C6C8" />
      </svg>
    </>
  );
};
export default Arrow;
