import axios from "axios";
import { put, takeEvery, call, select, take } from "redux-saga/effects";
import { get, post } from "../helpers/requests";
import { auth } from "../helpers/api";

import * as actions from "../actions/actionTypes";
import pushToSentry from "../helpers/pushToSentry";

import { getGiftCode } from "./selectors";
import isNil from "lodash/isNil";

function* task(action) {
  try {
    const gift = yield select(getGiftCode);
    const { name, email, password, newsletter } = action.payload;

    // Registers the user
    let response;
    if (!isNil(gift)) {
      response = yield call(
        axios,
        post(auth.register(), {
          email,
          password,
          name,
          gift_code: gift.giftCode,
          months: gift.months,
        })
      );
    } else {
      response = yield call(
        axios,
        post(auth.register(), { email, password, name })
      );
    }

    const plansResponse = yield call(axios, get(auth.getPlans()));

    if (isNil(gift)) {
      yield put({
        type: actions.CREATE_NEW_USER_SUCCESS,
        payload: {
          loginData: response.data.user,
          password,
          newsletter,
          plans: plansResponse.data,
        },
      });
    }

    if (response.data.created) {
      // Redirect to the user directly

      if (!isNil(gift)) {
        yield put({
          type: actions.COMPLETE_REGISTRATION,
          payload: {
            userId: response.data.user.id,
            duration: gift.months,
            giftCode: gift.giftCode,
            newsletter,
          },
        });

        yield take(actions.COMPLETE_REGISTRATION_SUCCESS);

        yield put({
          type: actions.CREATE_NEW_USER_SUCCESS,
          payload: {
            loginData: response.data.user,
            password,
            newsletter,
            plans: plansResponse.data,
          },
        });

        yield put({
          type: "LOGIN_USER",
          payload: {
            email,
            password,
          },
        });
        yield put({
          type: actions.REMOVE_GIFT_CODE,
        });
      }
    } else {
      yield put({
        type: actions.CREATE_NEW_USER_FAILURE,
        payload: {
          error: response.data.message, // This holds the error for repeated user
        },
      });
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      const eventId = pushToSentry(e);

      yield put({
        type: actions.CREATE_NEW_USER_FAILURE,
        payload: {
          error: e.response.data.message,
        },
      });
    } else {
      yield put({
        type: actions.CREATE_NEW_USER_FAILURE,
        payload: {
          error: "Some error occured.",
        },
      });
    }
  }
}

export default function* registerUser() {
  yield takeEvery("CREATE_NEW_USER", task);
}
