import queryString from "query-string";

import reduce from "lodash/reduce";
import mapValues from "lodash/mapValues";
import camelCase from "lodash/camelCase";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import { Iterable, fromJS } from "immutable";

import { getValueBetweenTwoNumbers } from "./converters";

export const globalFetchLimit = 25;

// Generates payload based on query params and builds along with favorites
export const generateAppEssentialPayload = ({
  objectType = "recipe",
  // for interior screen, send the slug of recipe to fetch and push to stack
  recipeSlug,
  workoutSlug,
  blogSlug,
  videoSlug,
  favorite = { show: false, board: null }, // state shape can be as inside based on query param,
  featured = { show: false, taxonomy: null, slug: null },
  // Can be used to force refresh the appropriate screens
  recipeRefreshGroup,
  workoutRefreshGroup,
  blogRefreshGroup,
  videoRefreshGroup,
  queryString = "",
  meta
} = {}) => {
  const defaultFilterState = {
    recipe: {
      must: false,
      recipeSlug,
      recipeRefreshGroup,
      limit: globalFetchLimit,
      offset: 0,
      filters: {
        // Param based filter
        foodTypes: [],
        dietaries: [],
        specialities: [],
        collection: [],
        fp: ["0", "15"],
        sp: ["0", "15"],
        wwp: ["0", "15"],
        search: [],
        searchBy: [],
        order: []
      },
      favorite: {
        show: false,
        board: null
      },
      featured: {
        show: false,
        slug: null
      },
      searchApplied: false
    },
    workout: {
      must: false,
      limit: globalFetchLimit,
      offset: 0,
      workoutSlug,
      filters: {
        // Param based filter
        workoutTypes: [],
        equipments: [],
        bodyParts: [],
        difficulty: [],
        collection: [],
        time: [],
        search: [],
        order: []
      },
      featured: {
        // Seperate list to hold the featured
        show: false,
        slug: null
      },
      favorite: {
        show: false,
        board: null
      },
      workoutRefreshGroup
    },
    blog: {
      must: false,
      limit: globalFetchLimit,
      offset: 0,
      blogSlug,
      filters: {
        // Param based filter
        categories: [],
        archives: [],
        time: [],
        search: [],
        order: []
      },
      featured: {
        // Seperate list to hold the featured
        show: false,
        taxonomy: null,
        slug: null
      },
      favorite: {
        show: false,
        board: null
      },
      blogRefreshGroup
    },
    video: {
      must: false,
      limit: globalFetchLimit,
      offset: 0,
      videoSlug,
      filters: {
        // Param based filter
        categories: [],
        collections: [],
        order: [],
        search: null
      },
      featured: {
        // Seperate list to hold the featured
        show: false,
        taxonomy: null,
        slug: null
      },
      favorite: {
        show: false,
        board: null
      },
      videoRefreshGroup
    },
    mealplan: {
      must: false,
      limit: globalFetchLimit,
      offset: 0,
      filters: {
        // Param based filter
        categories: []
      },
      favorite: {
        show: false,
        board: null
      },
      videoRefreshGroup
    }
  };

  const parsedQueryString = generateFilterFromQueryString(queryString);

  // Normalizing all single query param value to an array
  const normalizedQueryString = mapValues(
    parsedQueryString,
    eachQueryParamValue =>
      isArray(eachQueryParamValue) ? eachQueryParamValue : [eachQueryParamValue]
  );

  if (normalizedQueryString.search && normalizedQueryString.search.length > 0) {
    normalizedQueryString.search = [
      reduce(
        normalizedQueryString.search,
        (accumulator, search) => {
          accumulator += search;
          accumulator += " ";
          return accumulator;
        },
        ""
      )
    ];

    normalizedQueryString.search = [
      normalizedQueryString.search[0].substring(
        0,
        normalizedQueryString.search[0].length - 1
      )
    ];
  }

  // Camel case urls
  const camelCasedString = reduce(
    normalizedQueryString,
    (accumulator, value, key) => {
      accumulator[camelCase(key)] = value;
      return accumulator;
    },
    {}
  );

  return {
    ...defaultFilterState,
    [objectType]: {
      ...defaultFilterState[objectType],
      must: true,
      filters: {
        ...defaultFilterState[objectType].filters,
        ...camelCasedString
      },
      favorite,
      featured,
      searchApplied: camelCasedString.search ? true : false,
      meta
    }
  };
};

export const generateFilterFromQueryString = query => {
  return queryString.parse(query, { arrayFormat: "comma" });
};

export const slugToIdMapper = (items, slug) => {
  // Expect data to be immutable
  const presentItem = items.find(item => item.getIn(["slug"]) === slug);

  try {
    return presentItem.getIn(["id"]);
  } catch (e) {
    throw new Error("404");
  }
};

export const generateRecipeQueryFromFilter = (
  filters,
  foodTypeList,
  dietaryList,
  specialityList,
  collections,
  type = "id"
) => {
  if (!Iterable.isIterable(filters)) filters = fromJS(filters);
  // Categories
  let foodTypeQueryParam = "";
  if (!filters.getIn(["foodTypes"]).isEmpty()) {
    // For favorites, this won't come here
    foodTypeQueryParam = filters
      .getIn(["foodTypes"])
      .reduce((accumulator, item, index) => {
        accumulator += `${`${
          type === "id" ? slugToIdMapper(foodTypeList, item) : item
        }`}${index !== filters.getIn(["foodTypes"]).size - 1 ? "," : ""}`;
        return accumulator;
      }, "");
    foodTypeQueryParam =
      foodTypeQueryParam.length > 0 ? `&foodtype=${foodTypeQueryParam},` : "";
  }

  let dietaryQueryParam = "";
  if (!filters.getIn(["dietaries"]).isEmpty()) {
    // dietarys
    dietaryQueryParam = filters
      .getIn(["dietaries"])
      .reduce((accumulator, item, index) => {
        accumulator += `${`${
          type === "id" ? slugToIdMapper(dietaryList, item) : item
        }`}${index !== filters.getIn(["dietaries"]).size - 1 ? "," : ""}`;
        return accumulator;
      }, "");

    dietaryQueryParam =
      dietaryQueryParam.length > 0 ? `&dietary=${dietaryQueryParam},` : "";
  }

  let specialityQueryParam = "";
  if (filters && !filters.getIn(["specialities"]).isEmpty()) {
    // Collections
    specialityQueryParam = filters
      .getIn(["specialities"])
      .reduce((accumulator, item, index) => {
        accumulator += `${`${
          type === "id" ? slugToIdMapper(specialityList, item) : item
        }`}${index !== filters.getIn(["specialities"]).size - 1 ? "," : ""}`;
        return accumulator;
      }, "");

    specialityQueryParam =
      specialityQueryParam.length > 0
        ? `&speciality=${specialityQueryParam},`
        : "";
  }

  let collectionQueryParam = "";
  if (filters && !filters.getIn(["collection"]).isEmpty()) {
    // Collections
    collectionQueryParam = filters
      .getIn(["collection"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(collections, item)}${
          index !== filters.getIn(["collection"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    collectionQueryParam =
      collectionQueryParam.length > 0
        ? `&collection=${collectionQueryParam},`
        : "";
  }

  // Search

  const searchStringFormatted = filters.getIn(["search", 0])
    ? filters
        .getIn(["search", 0])
        .split(",")
        .join(" ")
    : "";

  let searchParam = isEmpty(searchStringFormatted)
    ? ""
    : `&search=${searchStringFormatted}&search_by=${filters.getIn([
        "searchBy",
        0
      ])}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;
  let fpQueryParam = "";
  let spQueryParam = "";
  let wwpQueryParam = "";

  // FP
  let fpRangeArray = filters.getIn(["fp"]);

  if (
    !filters.getIn(["fp"]).isEmpty() &&
    !(
      (fpRangeArray.getIn([0]) === "0" || fpRangeArray.getIn([0]) === 0) &&
      (fpRangeArray.getIn([fpRangeArray.size - 1]) === "15" ||
        fpRangeArray.getIn([fpRangeArray.size - 1]) === 15)
    )
  ) {
    let fpRange = getValueBetweenTwoNumbers(
      fpRangeArray.getIn([0]),
      fpRangeArray.getIn([fpRangeArray.size - 1])
    );
    fpQueryParam = fpRange.reduce((accumulator, item) => {
      if (isNull(item)) {
        accumulator = "";
      } else {
        accumulator += `${item},`;
      }
      return accumulator;
    }, "&fp=");
  }

  // SP
  let spRangeArray = filters.getIn(["sp"]);

  if (
    !filters.getIn(["sp"]).isEmpty() &&
    !(
      (spRangeArray.getIn([0]) === "0" || spRangeArray.getIn([0]) === 0) &&
      (spRangeArray.getIn([spRangeArray.size - 1]) === "15" ||
        spRangeArray.getIn([spRangeArray.size - 1]) === 15)
    )
  ) {
    // SP
    let spRangeArray = filters.getIn(["sp"]);

    let spRange = getValueBetweenTwoNumbers(
      spRangeArray.getIn([0]),
      spRangeArray.getIn([spRangeArray.size - 1])
    );

    spQueryParam = spRange.reduce((accumulator, item) => {
      if (isNull(item)) {
        accumulator = "";
      } else {
        accumulator += `${item},`;
      }
      return accumulator;
    }, "&sp=");
  }

  // WWP
  let wwpRangeArray = filters.getIn(["wwp"]);

  if (
    !filters.getIn(["wwp"]).isEmpty() &&
    !(
      (wwpRangeArray.getIn([0]) === "0" || wwpRangeArray.getIn([0]) === 0) &&
      (wwpRangeArray.getIn([wwpRangeArray.size - 1]) === "15" ||
        wwpRangeArray.getIn([wwpRangeArray.size - 1]) === 15)
    )
  ) {
    let wwpRange = getValueBetweenTwoNumbers(
      wwpRangeArray.getIn([0]),
      wwpRangeArray.getIn([wwpRangeArray.size - 1])
    );
    wwpQueryParam = wwpRange.reduce((accumulator, item) => {
      if (isNull(item)) {
        accumulator = "";
      } else {
        accumulator += `${item},`;
      }
      return accumulator;
    }, "&wwp=");
  }

  const queryParams = `${foodTypeQueryParam}${dietaryQueryParam}${specialityQueryParam}${collectionQueryParam}${fpQueryParam}${spQueryParam}${wwpQueryParam}${searchParam}${orderParam}&timestamp=${new Date().getTime()}`;
  return queryParams;
};

// For Front end routing
export const generateRecipeQueryFromFilterForFrontend = filters => {
  // Food Types
  let foodTypesQueryParam = filters
    .getIn(["foodTypes"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["foodTypes"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  foodTypesQueryParam =
    foodTypesQueryParam.length > 0 ? `&food-types=${foodTypesQueryParam}` : "";

  // Dietaries
  let dietariesQueryParam = filters
    .getIn(["dietaries"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["dietaries"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  dietariesQueryParam =
    dietariesQueryParam.length > 0 ? `&dietaries=${dietariesQueryParam}` : "";

  // Specialities
  let specialitiesQueryParam = filters
    .getIn(["specialities"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["specialities"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  specialitiesQueryParam =
    specialitiesQueryParam.length > 0
      ? `&specialities=${specialitiesQueryParam}`
      : "";

  // Search
  let searchParam = isEmpty(filters.getIn(["search", 0]))
    ? ""
    : `&search=${filters.getIn(["search", 0])}&search_by=${filters.getIn([
        "searchBy",
        0
      ])}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;
  // FP
  let fpRangeArray = filters.getIn(["fp"]);

  // SP
  let spRangeArray = filters.getIn(["sp"]);

  // WWP
  let wwpRangeArray = filters.getIn(["wwp"]);

  let fpRange = getValueBetweenTwoNumbers(
    fpRangeArray.getIn([0]),
    fpRangeArray.getIn([1])
  );
  let spRange = getValueBetweenTwoNumbers(
    spRangeArray.getIn([0]),
    spRangeArray.getIn([1])
  );
  let wwpRange = getValueBetweenTwoNumbers(
    wwpRangeArray.getIn([0]),
    wwpRangeArray.getIn([1])
  );

  let fpQueryParam = "";
  let spQueryParam = "";
  let wwpQueryParam = "";
  if (
    !(
      (fpRangeArray.getIn([0]) === "0" || fpRangeArray.getIn([0]) === 0) &&
      (fpRangeArray.getIn([1]) === "15" || fpRangeArray.getIn([1]) === 15)
    )
  ) {
    fpQueryParam = `&fp=${fpRangeArray.getIn([0])},${fpRangeArray.getIn([1])}`;
  }

  if (
    !(
      (spRangeArray.getIn([0]) === "0" || spRangeArray.getIn([0]) === 0) &&
      (spRangeArray.getIn([1]) === "15" || spRangeArray.getIn([1]) === 15)
    )
  ) {
    spQueryParam = `&sp=${spRangeArray.getIn([0])},${spRangeArray.getIn([1])}`;
  }

  if (
    !(
      (wwpRangeArray.getIn([0]) === "0" || wwpRangeArray.getIn([0]) === 0) &&
      (wwpRangeArray.getIn([1]) === "15" || wwpRangeArray.getIn([1]) === 15)
    )
  ) {
    wwpQueryParam = `&wwp=${wwpRangeArray.getIn([0])},${wwpRangeArray.getIn([
      1
    ])}`;
  }

  const queryParams = `${foodTypesQueryParam}${dietariesQueryParam}${specialitiesQueryParam}${fpQueryParam}${spQueryParam}${wwpQueryParam}${searchParam}${orderParam}`;

  return queryParams;
};

export const generateWorkoutQueryFromFilter = (
  filters,
  workoutType,
  equipment,
  bodyParts,
  collections
) => {
  if (!Iterable.isIterable(filters)) filters = fromJS(filters);
  // Categories
  let workoutTypeQueryParam = "";
  if (!filters.getIn(["workoutTypes"]).isEmpty()) {
    // For favorites, this won't come here
    workoutTypeQueryParam = filters
      .getIn(["workoutTypes"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(workoutType, item)}${
          index !== filters.getIn(["workoutTypes"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");
    workoutTypeQueryParam =
      workoutTypeQueryParam.length > 0
        ? `&workoutType=${workoutTypeQueryParam},`
        : "";
  }

  let equipmentQueryParam = "";
  if (!filters.getIn(["equipments"]).isEmpty()) {
    // equipments
    equipmentQueryParam = filters
      .getIn(["equipments"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(equipment, item)}${
          index !== filters.getIn(["equipments"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    equipmentQueryParam =
      equipmentQueryParam.length > 0
        ? `&equipment=${equipmentQueryParam},`
        : "";
  }

  let bodyPartQueryParam = "";
  if (!filters.getIn(["bodyParts"]).isEmpty()) {
    // Collections
    bodyPartQueryParam = filters
      .getIn(["bodyParts"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(bodyParts, item)}${
          index !== filters.getIn(["bodyParts"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    bodyPartQueryParam =
      bodyPartQueryParam.length > 0 ? `&bodyPart=${bodyPartQueryParam},` : "";
  }

  let difficultyQueryParam = "";
  if (!filters.getIn(["difficulty"]).isEmpty()) {
    // Collections
    difficultyQueryParam = filters
      .getIn(["difficulty"])
      .reduce((accumulator, item, index) => {
        accumulator += `${item}${
          index !== filters.getIn(["difficulty"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    difficultyQueryParam =
      difficultyQueryParam.length > 0
        ? `&difficulty=${difficultyQueryParam},`
        : "";
  }

  let collectionQueryParam = "";
  if (!filters.getIn(["collection"]).isEmpty()) {
    // Collections
    collectionQueryParam = filters
      .getIn(["collection"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(collections, item)}${
          index !== filters.getIn(["collection"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    collectionQueryParam =
      collectionQueryParam.length > 0
        ? `&collection=${collectionQueryParam},`
        : "";
  }

  let timeQueryParam = "";
  if (!filters.getIn(["time"]).isEmpty()) {
    // Collections
    timeQueryParam = filters
      .getIn(["time"])
      .reduce((accumulator, item, index) => {
        accumulator += `${item}${
          index !== filters.getIn(["time"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    timeQueryParam =
      timeQueryParam.length > 0 ? `&time=${timeQueryParam},` : "";
  }

  // let
  // Search
  let searchParam = isEmpty(filters.getIn(["search", 0]))
    ? ""
    : `&search=${filters.getIn(["search", 0])}&search_by=${filters.getIn([
        "searchBy",
        0
      ])}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;

  const queryParams = `${workoutTypeQueryParam}${equipmentQueryParam}${bodyPartQueryParam}${collectionQueryParam}${difficultyQueryParam}${timeQueryParam}${searchParam}${orderParam}`;

  return queryParams;
};

export const generateWorkoutQueryFromFilterForFrontend = filters => {
  // WorkoutTypes
  let workoutTypesQueryParam = filters
    .getIn(["workoutTypes"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["workoutTypes"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  workoutTypesQueryParam =
    workoutTypesQueryParam.length > 0
      ? `&workout-types=${workoutTypesQueryParam}`
      : "";

  // equipments
  let equipmentsQueryParam = filters
    .getIn(["equipments"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["equipments"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  equipmentsQueryParam =
    equipmentsQueryParam.length > 0
      ? `&equipments=${equipmentsQueryParam}`
      : "";

  // bodyParts
  let bodyPartsQueryParam = filters
    .getIn(["bodyParts"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["bodyParts"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  bodyPartsQueryParam =
    bodyPartsQueryParam.length > 0 ? `&body-parts=${bodyPartsQueryParam}` : "";

  let difficultyQueryParam = filters
    .getIn(["difficulty"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["difficulty"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  difficultyQueryParam =
    difficultyQueryParam.length > 0
      ? `&difficulty=${difficultyQueryParam}`
      : "";

  let timeQueryParam = filters
    .getIn(["time"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["time"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  timeQueryParam = timeQueryParam.length > 0 ? `&time=${timeQueryParam}` : "";

  // Search
  let searchParam = isEmpty(filters.getIn(["search", 0]))
    ? ""
    : `&search=${filters.getIn(["search", 0])}&search_by=${filters.getIn([
        "searchBy",
        0
      ])}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;

  const queryParams = `${workoutTypesQueryParam}${equipmentsQueryParam}${bodyPartsQueryParam}${difficultyQueryParam}${timeQueryParam}${searchParam}${orderParam}`;

  return queryParams;
};

export const generateBlogQueryFromFilter = (filters, categories, archives) => {
  if (!Iterable.isIterable(filters)) filters = fromJS(filters);
  // Categories
  let categoryQueryParam = "";
  if (!filters.getIn(["categories"]).isEmpty()) {
    // For favorites, this won't come here
    categoryQueryParam = filters
      .getIn(["categories"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(categories, item)}${
          index !== filters.getIn(["categories"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");
    categoryQueryParam =
      categoryQueryParam.length > 0 ? `&categories=${categoryQueryParam},` : "";
  }

  let archivesQueryParam = "";
  if (!filters.getIn(["archives"]).isEmpty()) {
    // Collections
    archivesQueryParam = filters
      .getIn(["archives"])
      .reduce((accumulator, item, index) => {
        accumulator += `${item}${
          index !== filters.getIn(["archives"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    archivesQueryParam =
      archivesQueryParam.length > 0 ? `&archives=${archivesQueryParam},` : "";
  }

  // let
  // Search
  let searchParam = isEmpty(filters.getIn(["search", 0]))
    ? ""
    : `&search=${filters.getIn(["search", 0])}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;
  const queryParams = `${categoryQueryParam}${archivesQueryParam}${searchParam}${orderParam}`;

  return queryParams;
};

export const generateBlogQueryFromFilterForFrontend = filters => {
  let categoriesQueryParam = filters
    .getIn(["categories"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["categories"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  categoriesQueryParam =
    categoriesQueryParam.length > 0
      ? `&categories=${categoriesQueryParam}`
      : "";

  let archivesQueryParam = filters
    .getIn(["archives"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["archives"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  archivesQueryParam =
    archivesQueryParam.length > 0 ? `&archives=${archivesQueryParam}` : "";

  // Search
  let searchParam = isEmpty(filters.getIn(["search", 0]))
    ? ""
    : `&search=${filters.getIn(["search", 0])}}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;

  const queryParams = `${categoriesQueryParam}${archivesQueryParam}${searchParam}${orderParam}`;

  return queryParams;
};

export const generateVideoQueryFromFilter = (
  filters,
  categories,
  collections
) => {
  if (!Iterable.isIterable(filters)) filters = fromJS(filters);
  // Categories
  let categoryQueryParam = "";
  if (!filters.getIn(["categories"]).isEmpty()) {
    // For favorites, this won't come here
    categoryQueryParam = filters
      .getIn(["categories"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(categories, item)}${
          index !== filters.getIn(["categories"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");
    categoryQueryParam =
      categoryQueryParam.length > 0 ? `&categories=${categoryQueryParam},` : "";
  }

  let collectionQueryParam = "";
  if (!filters.getIn(["collections"]).isEmpty()) {
    // Collections
    collectionQueryParam = filters
      .getIn(["collections"])
      .reduce((accumulator, item, index) => {
        accumulator += `${slugToIdMapper(collections, item)}${
          index !== filters.getIn(["collections"]).size - 1 ? "," : ""
        }`;
        return accumulator;
      }, "");

    collectionQueryParam =
      collectionQueryParam.length > 0
        ? `&collections=${collectionQueryParam},`
        : "";
  }

  // Search
  let searchParam = isEmpty(filters.getIn(["search", 0]))
    ? ""
    : `&search=${filters.getIn(["search", 0])}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;

  const queryParams = `${categoryQueryParam}${collectionQueryParam}${searchParam}${orderParam}`;

  return queryParams;
};

export const generateVideoQueryFromFilterForFrontend = filters => {
  let categoriesQueryParam = filters
    .getIn(["categories"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["categories"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  categoriesQueryParam =
    categoriesQueryParam.length > 0
      ? `&categories=${categoriesQueryParam}`
      : "";

  let collectionsQueryParam = filters
    .getIn(["collections"])
    .reduce((accumulator, item, index) => {
      accumulator += `${item}${
        index !== filters.getIn(["collections"]).size - 1 ? "," : ""
      }`;
      return accumulator;
    }, "");

  collectionsQueryParam =
    collectionsQueryParam.length > 0
      ? `&collections=${collectionsQueryParam}`
      : "";

  // Search
  const searchContent = filters.getIn(["search", 0]);
  let searchParam = isEmpty(filters.getIn(["search", 0]))
    ? ""
    : `&search=${searchContent}`;
  let orderParam = isEmpty(filters.getIn(["order", 0]))
    ? ""
    : `&order=${filters.getIn(["order", 0])}`;

  const queryParams = `${categoriesQueryParam}${collectionsQueryParam}${searchParam}${orderParam}`;
  return queryParams;
};
