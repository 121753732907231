import React from "react";

import ModalWrapper from "../../../common/modal";
import PremadeMealPlanList from "./premadeMealPlanList";
import PremadeMealPlanIndex from "./premadeMealPlanIndex";
import Recipes from "./recipes";
import ChooseDate from "./chooseDate";
import AddReplaceConfirmation from "./addReplaceConfirmation";

const PremadeModal = ({
  show,
  premadeModal,
  moveToIndex,
  moveToCalendar,
  onRequestClose,
  handleSubmitChooseDate,
  createFavourite,
  deleteFavourite,
  moveToRecipes,
  moveToList,
  daysContainingRecipes,
  moveToAddReplaceConfirmation,
  chosenCalendarDate,
  chosenAddIngredients,
  chosenServingSize,
  progressStatus,
  isAuthenticated,
  activeDate,
  updateActiveDate,
  listState,
  listDispatch,
  history
}) => {
  const renderModalContent = () => {
    switch (premadeModal.type) {
      case "list":
        return (
          <PremadeMealPlanList
            moveToIndex={moveToIndex}
            isAuthenticated={isAuthenticated}
            updateActiveDate={updateActiveDate}
            activeDate={activeDate}
            state={listState}
            dispatch={listDispatch}
            browserHistory={history}
          />
        );
      case "index":
        return (
          <PremadeMealPlanIndex
            item={premadeModal.index.item}
            slug={premadeModal.index.slug}
            moveToCalendar={moveToCalendar}
            createFavourite={createFavourite}
            deleteFavourite={deleteFavourite}
            moveToRecipes={moveToRecipes}
            moveToList={moveToList}
            handleSubmitChooseDate={handleSubmitChooseDate}
            daysContainingRecipes={daysContainingRecipes}
            moveToAddReplaceConfirmation={moveToAddReplaceConfirmation}
            chosenCalendarDate={chosenCalendarDate}
            progressStatus={progressStatus}
            moveToIndex={moveToIndex}
            onRequestClose={onRequestClose}
            history={history}
          />
        );
      case "confirmation":
        return (
          <AddReplaceConfirmation
            handleSubmitChooseDate={handleSubmitChooseDate}
            chosenCalendarDate={chosenCalendarDate}
            chosenAddIngredients={chosenAddIngredients}
            chosenServingSize={chosenServingSize}
            progressStatus={progressStatus}
          />
        );
      case "recipes":
        return (
          <Recipes
            days={premadeModal.index.recipes}
            moveToIndex={moveToIndex}
            item={premadeModal.index.item}
          />
        );
      default:
        return (
          <div className="header-content">
            <h3>Nothing to render</h3>
          </div>
        );
    }
  };

  return (
    <ModalWrapper
      isOpen={show}
      minHeight={premadeModal.type !== "confirmation" ? "85vh" : "300px"}
      maxHeight={premadeModal.type !== "confirmation" ? "85vh" : "180px"}
      maxWidth={premadeModal.type !== "confirmation" ? "1140px" : "500px"}
      height={premadeModal.type !== "confirmation" ? "85vh" : "50%"}
      overflowY="visible"
      overflow="visible"
      borderRadius="10px"
      onRequestClose={onRequestClose}
      additionalClass="modal-close-wrapper-mobile"
    >
      {renderModalContent()}
    </ModalWrapper>
  );
};

export default PremadeModal;
