import React from 'react';
import '../styles/common.scss';

const PinIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.27083 5.5C5.775 5.5 6.1875 5.0875 6.1875 4.58333V1.83333H14.2908L16.2708 3.81333V4.58333C16.2708 5.0875 16.6833 5.5 17.1875 5.5C17.6917 5.5 18.1042 5.0875 18.1042 4.58333V3.4375C18.1042 3.19 18.0033 2.96083 17.8383 2.78667L15.5833 0.540833C15.235 0.1925 14.7767 0 14.2908 0H6.1875C5.17917 0 4.35417 0.825 4.35417 1.83333V4.58333C4.35417 5.0875 4.76667 5.5 5.27083 5.5Z" fill="#928686" />
            <path d="M16.0417 19.7083C16.0417 19.965 15.84 20.1667 15.5833 20.1667H6.875C6.61833 20.1667 6.41667 19.965 6.41667 19.7083V11.4217C6.41667 11.165 6.61833 10.9633 6.875 10.9633H15.5833C15.84 10.9633 16.0417 11.165 16.0417 11.4217V19.7083ZM3.20833 10.5417C2.70417 10.5417 2.29167 10.1292 2.29167 9.625C2.29167 9.12083 2.70417 8.70833 3.20833 8.70833C3.7125 8.70833 4.125 9.12083 4.125 9.625C4.125 10.1292 3.7125 10.5417 3.20833 10.5417ZM19.7083 6.41667H2.29167C1.02667 6.41667 0 7.44333 0 8.70833V12.8333C0 14.0983 1.02667 15.125 2.29167 15.125H4.58333V20.8542C4.58333 21.4867 5.09667 22 5.72917 22H16.7292C17.3617 22 17.875 21.4867 17.875 20.8542V15.125H19.7083C20.3133 15.125 20.9 14.8867 21.3308 14.4558C21.7617 14.025 22 13.4383 22 12.8333V8.70833C22 8.10333 21.7617 7.51667 21.3308 7.08583C20.9 6.655 20.3133 6.41667 19.7083 6.41667Z" fill="#928686" />
            <path d="M13.75 13.5208H8.70833C8.3325 13.5208 8.02083 13.8325 8.02083 14.2083C8.02083 14.5842 8.3325 14.8958 8.70833 14.8958H13.75C14.1258 14.8958 14.4375 14.5842 14.4375 14.2083C14.4375 13.8325 14.1258 13.5208 13.75 13.5208Z" fill="#928686" />
            <path d="M12.1458 16.7292H8.70833C8.3325 16.7292 8.02083 17.0408 8.02083 17.4167C8.02083 17.7925 8.3325 18.1042 8.70833 18.1042H12.1458C12.5217 18.1042 12.8333 17.7925 12.8333 17.4167C12.8333 17.0317 12.5217 16.7292 12.1458 16.7292Z" fill="#928686" />
        </svg>
    )
}
export default PinIcon;