import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const ThumbFavIcon = (props) => {
  return (
    <>
      <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.99461 0.52771C8.64368 0.52771 7.44052 1.34037 6.92337 2.59631C6.41677 1.34037 5.1925 0.52771 3.85213 0.52771C2.01571 0.52771 0.527588 2.35357 0.527588 4.18998C0.527588 8.21109 6.06848 11.4723 6.92337 11.4723C7.77825 11.4723 13.3191 8.21109 13.3191 4.18998C13.3191 2.35357 11.831 0.52771 9.99461 0.52771Z" stroke={themeTextRed} stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default ThumbFavIcon;
