// @flow
import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import GroceryListActions from './groceryListActions'
import GroceryListDetails from './groceryListDetails'

import { generateAppEssentialPayload } from '../../../helpers/filterConversions'

import Loader from '../../images/loader';
import '../../styles/common.scss';

import Button from '../../common/button';
import ModalWrapper from "../../common/modal";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import getUserPlanType from "../../../helpers/getUserPlanType";
import ModalImg from '../../images/modalpic.png';
import { TransitionOne } from '../../common/transitions';
const ref = React.createRef();



const GroceryList = (props) => {
  useEffect(() => {
    const filterFromQueryString = generateAppEssentialPayload({
    })

    props.appEssentialFetch({
      ...filterFromQueryString,
      meta: {
        feature: 'grocery'
      }
    });
  }, []);

  const isClearSelectedDisabled = (props.groceryListSelectedIngredients.length === 0) ? 'hide' : 'show';

  let deleteIngredientIds = props.groceryListSelectedIngredients.reduce((accumulator, select) => {
    let ids = select.id
    if (accumulator.length === 0) {
      accumulator = ids
    } else {
      accumulator = accumulator.concat(ids)
    }
    return accumulator
  }, [])

  if (
    props.fetchStatus.isListFetching ||
    props.fetchStatus.startup.isFetching ||
    props.fetchStatus.userAuthenticate.isFetching ||
    props.fetchStatus.preFetch.isFetching
  ) {
    return (
      <div className="preLoader">
        <Loader></Loader>
      </div>
    )
  }

  const isPremiumPlan = () => (!isNil(props.userDetails) && !isNil(props.userDetails.details) && !isEmpty(props.userDetails.details) && (getUserPlanType(props.userDetails) === "premiumPlan" || getUserPlanType(props.userDetails) === "grandfather"));

  return (
    <section className="grocerySection">
      <Helmet >
        <title>Grocery List | Dashing Dish </title>
        <meta name="description" content="Dashingdish Grocery List page" />

      </Helmet>
      <TransitionOne>
        <div className="container">
          <GroceryListActions
            userDetails={props.userDetails}
            changeGroceryListViewType={props.changeGroceryListViewType}
            groceryList={props.groceryList}
            ref={ref}
            activeGroceryType={props.activeGroceryType}
            clearAllGroceryListItems={props.clearAllGroceryListItems}
            groceryListSelectedIngredients={props.groceryListSelectedIngredients}
            deleteIngredientInGroceryList={props.deleteIngredientInGroceryList}
            selectIngredientInGroceryList={props.selectIngredientInGroceryList}
            progressStatus={props.progressStatus}
            history={props.history}
            showToastMessage={props.showToastMessage}
          />
          <GroceryListDetails
            userDetails={props.userDetails}
            ref={ref}
            groceryList={props.groceryList}
            selectIngredientInGroceryList={props.selectIngredientInGroceryList}
            removeIngredientInGroceryList={props.removeIngredientInGroceryList}
            moveIngredientInGroceryList={props.moveIngredientInGroceryList}
            addIngredientInGroceryListAisle={props.addIngredientInGroceryListAisle}
            activeGroceryType={props.activeGroceryType}
            addIngredientInGroceryListRecipe={props.addIngredientInGroceryListRecipe}
            deleteIngredientInGroceryList={props.deleteIngredientInGroceryList}
            groceryListSelectedIngredients={props.groceryListSelectedIngredients}
          />
          <div className="floating-btn">
            <Button
              btnClass={`${isClearSelectedDisabled} btn-primary btn-md`}
              btnText="Clear selected"
              onClick={() => { props.deleteIngredientInGroceryList(deleteIngredientIds) }}
              isLoading={props.progressStatus.deleteSingleGroceryItem.isProgressing}
            />
          </div>
        </div>
      </TransitionOne>

      {/* modal for non premium user */}
      {
        (props.isAuthenticated && !isPremiumPlan()) &&
        <ModalWrapper
          isOpen={true}
          showClose={false}
          minHeight='670px'
          maxWidth='450px'
        >
          <div className="modalWrapper">
            <div className="modalBody modalBodyPlanning">
              <div className="not-premium-user-modal">
                <h2>Save time and money by meal planning <b>like a pro.</b></h2>
                <figure>
                  <img src={ModalImg} className="img_fluid"></img>
                </figure>
              </div>
            </div>
            <div className="modalFooter">
              <Button
                btnClass="primary btn-full"
                btnText="Get started today!"
                onClick={() => {
                  props.history.push('/account/membership')
                }}
              />
            </div>
          </div>
        </ModalWrapper>
      }
    </section>
  )
}

export default GroceryList