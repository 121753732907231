import axios from "axios";
import { call, put, takeEvery, select } from "redux-saga/effects";
import { getToken } from './selectors'

import isNull from 'lodash/isNull'
import * as actions from '../actions/actionTypes'
import pushToSentry from "../helpers/pushToSentry";


export default function* startUp(action) {
  let tokenInStorage

  if (typeof localStorage !== 'undefined' && localStorage != null) {
    tokenInStorage = localStorage.getItem('token');
  } else {
    pushToSentry('localStorage not available ')
  }

  // console.log('token in storage ', tokenInStorage)
  const tokenInRedux = yield select(getToken);

  // Do this only when token in storage not present in redux
  try {
    // token from local storage    
    if (tokenInStorage && !tokenInRedux) {
      yield put({ type: 'APP_STARTUP' });
      let macros
      let calendarView

      if (typeof localStorage !== 'undefined' && localStorage != null) {
        macros = localStorage.getItem('macros');
        calendarView = localStorage.getItem('calendarView');
      } else {
        pushToSentry('localStorage not available ')
      }

      yield put({
        type: actions.APP_STARTUP_SUCCESS,
        payload: {
          token: tokenInStorage || null,
          macros: (macros && Array.isArray(macros) && macros.length >= 0) ? JSON.parse(macros) : ['calories', 'smartPoints', 'protein'],
          calendarView: (calendarView) ? JSON.parse(calendarView) : 'biweekly'

        }
      });
    } else {
      // Do nothing
    }
  } catch (e) {
    console.log(e)
    const eventId = pushToSentry(e)

    yield put({
      type: actions.APP_STARTUP_FAILURE,
      payload: {
        error: e.message
      }
    });
  }
}
