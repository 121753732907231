import React, { useState } from 'react'
import PlusIcon from '../../images/PlusIcon'
import DottedIcon from '../../images/DottedIcon';
import cx from 'classnames';
import GroceryListGroup from './groceryListGroup';
import type { groceryList } from "../../../types";
import PlusButton from '../../common/plusButton'
import DottedButton from '../../common/dottedButton';
import getUserPlanType from "../../../helpers/getUserPlanType";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";


// don't know what it do
// function getStyle(style, snapshot) {
//   if (!snapshot.isDropAnimating) {
//     return style;
//   }
//   return {
//     ...style,
//     // cannot be 0, but make it super tiny
//     transitionDuration: `0.001s`,
//   };
// }
type Props = {
  groceryList: groceryList,
  selectIngredientInGroceryList: ({ id: number, categoryId: number }) => void,
  removeIngredientInGroceryList: ({ id: number, categoryId: number }) => void,
  deleteIngredientInGroceryList: (Array<number>) => void,
  groceryListSelectedIngredients: Array<any>, //TODO: need to define specific
  onClickPlus: ({
    id: ?number,
    recipeId: ?number,
    slug: ?string,
    name: string,
    items: Array<{
      categoryId: Array<number>,
      id: Array<number>,
      item: string, name: string,
      recipe: ?Array<string>,
      recipe_id: ?Array<string>
    }>
  }) => void,
  activeGroceryType: string,
  setModalData: (string, { name: string, categoryId: number, ids: Array<number>}) => void
}

const GroceryListCategorySection = (props: Props) => {
  const {
    groceryList,
    selectIngredientInGroceryList,
    removeIngredientInGroceryList,
    deleteIngredientInGroceryList,
    onClickPlus,
    activeGroceryType,
    setModalData,
    groceryListSelectedIngredients,
    moveIngredientsInGroceryList,
  } = props;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [activeRow, setActiveRow] = useState(null)
  const isPremiumPlan = () => (!isNil(props.userDetails) &&
    !isNil(props.userDetails.details) &&
    (getUserPlanType(props.userDetails) === "premiumPlan" || getUserPlanType(props.userDetails) === "grandfather"))

  const groceryItemAction = category => {
    const actions = [
      {
        label: 'Delete',
        action: () => deleteIngredientInGroceryList(category.id)
      },
    ];

    if (activeGroceryType === 'aisle') {
      actions.push(
        {
          label: 'Move',
          action: () => setModalData('moveIngredientModalData', {
            name: category.name,
            categoryId: category.categoryId[0],
            ids: category.id
          }),
        }
      );
    }

    return actions;
  }

  return (
    <DragDropContext
      onDragEnd={(dragProps) => {
        // only call action on drop of item in different category not the same one
        if (!isNull(dragProps.source.droppableId) && !isNull(dragProps.destination)) {
          if (dragProps.source.droppableId !== dragProps.destination.droppableId) {
            moveIngredientsInGroceryList(dragProps)
          }
        }
      }}
    >
      <>
        {
          (groceryList && groceryList.categories || []).map((category, index) => (
            <div
              className={`category`}
              key={index}
            >

              <Droppable
                droppableId={(category.id) ? `${category.id}` : `${Math.random()}`}
                isDropDisabled={activeGroceryType === 'recipe'}
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{
                      marginTop: 10 // needed for dnd to work on empty categoty
                    }}
                  // style={getListStyle(snapshot.isDraggingOver)}
                  >
                    <div
                      key={category.id}
                      className="group-header"
                    >
                      <h4>
                        {category.name}
                      </h4>

                      <div onClick={() => onClickPlus(category)}>
                        {!(activeGroceryType === 'recipe' && category.name === 'Uncategorized') ? <PlusButton /> : null}
                      </div>
                    </div>
                    {
                      category.items.map((item, index) => {
                        return (
                          <Draggable
                            isDragDisabled={activeGroceryType === 'recipe'}
                            key={!isEmpty(item.id) ? `${item.id[0]}` : `${Math.random()}`}
                            draggableId={!isEmpty(item.id) ? `${item.id[0]}` : `${Math.random()}`}
                            index={index}
                          // disableInteractiveElementBlocking
                          // showRespectForcePress={true}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                key={!isEmpty(item.id) ? `${item.id[0]}` : `${Math.random()}`}

                                onMouseEnter={() => { if (!isPopoverOpen) setActiveRow(!isEmpty(item.id) ? item.id[0] : Math.random()) }}
                                onMouseLeave={() => { if (!isPopoverOpen) setActiveRow(null) }}
                              >
                                <div
                                  className="group-list"
                                  key={index}
                                >
                                  <GroceryListGroup
                                    {...item}
                                    selectIngredientInGroceryList={selectIngredientInGroceryList}
                                    removeIngredientInGroceryList={removeIngredientInGroceryList}
                                    groceryListSelectedIngredients={groceryListSelectedIngredients}
                                  />

                                  <div className={cx('dotted-actions', { 'show-dotted-actions': item.id[0] == activeRow })}>
                                    <DottedButton
                                      options={groceryItemAction(item)}
                                      setIsPopoverOpen={setIsPopoverOpen}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>

                        )
                      })
                    }
                    {provided.placeholder}
                  </div>
                )}

              </Droppable>
            </div>
          ))
        }
      </>
    </DragDropContext>

  )
}

export default GroceryListCategorySection