import React from "react";
import '../styles/common.scss';


const GrandFatherIcon = (props) => {
  return (
    < >
      <svg width="63" height="55" viewBox="0 0 63 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path class="GrandFatherIcon" d="M27.176 50.1903C16.6951 49.1152 8.53369 40.3729 8.53369 29.735C8.53369 21.7001 13.2301 14.7119 20.0455 11.3451C22.5941 10.0154 26.1452 7.07299 24.6847 1.41455C19.6447 2.71599 18.0697 5.88472 17.6688 8.4593M20.962 48.5493C18.2701 47.5591 13.6597 46.8801 10.3951 51.7464C14.7479 54.5756 18.1842 53.6419 20.3892 52.2273M12.2566 41.448C10.7388 39.0432 7.53155 35.733 1.91882 37.572C3.60837 42.4382 6.90155 43.7963 9.5361 43.9943M8.56256 30.5272C8.53392 27.698 7.50301 23.1996 1.7471 21.9264C0.630285 26.9624 2.74938 29.7916 4.8971 31.3194M11.7411 18.786C13.1443 16.2963 14.4902 11.8827 10.1088 7.97839C6.64381 11.7978 7.07335 15.3061 8.16153 17.6826M35.8243 50.1903C46.3053 49.1152 54.4666 40.3729 54.4666 29.735C54.4666 21.7001 49.7703 14.7119 42.9548 11.3451C40.4062 10.0154 36.8552 7.07299 38.3443 1.41455C43.3843 2.71599 44.9593 5.88472 45.3602 8.4593M42.0383 48.5493C44.7302 47.5591 49.3406 46.8801 52.6052 51.7464C48.2524 54.5756 44.8161 53.6419 42.6111 52.2273M50.7438 41.448C52.2616 39.0432 55.4688 35.733 61.0816 37.572C59.392 42.4382 56.0988 43.7963 53.4643 43.9943M54.4378 30.5272C54.4664 27.698 55.4973 23.1996 61.2533 21.9264C62.3701 26.9624 60.251 29.7916 58.1033 31.3194M51.2593 18.786C49.8847 16.2963 48.5102 11.911 52.8915 7.97839C56.3852 11.7978 55.9556 15.3061 54.8674 17.6826" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default GrandFatherIcon;
