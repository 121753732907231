import React, { useState } from 'react';
import Button from './button';
import LinkButton from '../common/linkButton'

const AddNoteModal = (props) => {
  const [buttonType, setButtonType] = useState((props.noteInputText && props.noteInputText.length > 0) ? 'black' : 'secondary');
  const [isNotesUpdated, setNotesUpdated] = useState(false);
  return (
    <div className="modalWrapper">
      <div className="modalHeader AddnoteHead">
        <h3>Add {(props.noteInputText && props.noteInputText.length > 0) ? `${props.objectType}` : null} Note </h3>
        {/* <h3> {props.objectType == 'recipe' ? 'Add Recipe Note' : 'Add Workout Note'} </h3> */}
      </div>
      <div className="modalBody AddnoteTextarea">
        <textarea
          className='modalInput'
          placeholder='Type your note here'
          value={props.noteInputText}
          onChange={() => {
            setButtonType('primary')
            setNotesUpdated(true)
            props.noteInputTextHandler(event.target.value)
          }}
          ref={input => { textInput = input }}
        ></textarea>
      </div>
      <div className="modalFooter">
        <Button
          btnClass={`${buttonType} btn-full`}
          btnText={isNotesUpdated ? 'Save Note' : `${(props.noteInputText && props.noteInputText.length > 0) ? `Edit` : `Add Note`}`}
          // btnText={props.objectType == 'recipe' ? 'Add Recipe Note' : 'Add Workout Note'}
          onClick={() => {
            // TODO: Have a handler for this, dont write inside JSX
            props.sendNote({
              noteId: props.noteId,
              note: props.noteInputText,
              objectType: props.objectType,
              objectId: props.objectId,
              objectSlug: props.objectSlug,
            });
            props.toggleNoteModal(modalState = false)
          }}
        />
        <Button
          btnClass="secondary btn-full btn-bp-sm"
          btnText="Cancel"
          onClick={() => {
            props.toggleNoteModal(modalState = false)
          }}
        />
      </div>
    </div>
  )
}

export default AddNoteModal;
