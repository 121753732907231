import React from "react";
import "../styles/common.scss";
import cx from "classnames";

export const Radiobox = (props) => {
  const {
    type,
    label,
    checked,
    content,
    checkId,
    onSelect,
    totalCount,
    radiobox,
  } = props;
  return (
    <span
      className="checkboxField"
      onClick={() => {
        onSelect(!checked, content);
      }}
    >
      <input
        style={{ display: "none" }}
        id={checkId}
        className={cx({ radiobox }, "checkboxFieldInput", { checked })}
        type={type}
        label={label}
        checked={checked}
        onClick={(e) => e.stopPropagation()}
      />
      <label
        for={checkId}
        className={cx("input-label checkboxFieldLabel", checked)}
      >
        <span>
          <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.18628 11.5L5.45444 15.7682L16.1113 5.1113"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </span>
      </label>
      <label className={cx("ingredientAmount", { active: checked })}>
        {label}
      </label>
    </span>
  );
};

Radiobox.defaultProps = {
  defaultValue: false,
  onSelect: () => { },
  radiobox: false,
};
const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.checked === nextProps.checked &&
    prevProps.label === nextProps.label
  );
};

export default React.memo(Radiobox, areEqual);
