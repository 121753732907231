import React, { useState, useRef, useEffect } from 'react';
import PillSelector from './PillSelector';
import '../styles/leftover-recipes/autocomplete.scss';

/**
* Converts an exact count into a range format.
* 
* @param {number} count - The exact count to convert.
* @returns {string} - The formatted range string.
*/
const formatCountRange = (count) => {
  if (count < 2) {
    return count.toString(); // Return the exact count if it's less than 2
  }

  if (count >= 2 && count <= 5) {
    return '2+'; // Return the exact count if it's less than 2
  }

  if (count >= 5 && count <= 10) {
    return '5+'; // Return the exact count if it's less than 2
  }

  if (count >= 10 && count <= 25) {
    return '10+'; // Return the exact count if it's less than 2
  }

  if (count >= 25 && count <= 50) {
    return '25+'; // Return the exact count if it's less than 2
  }

  if (count >= 50 && count <= 100) {
    return '50+'; // Return the exact count if it's less than 2
  }

  if (count >= 100 && count <= 200) {
    return '100+'; // Return the exact count if it's less than 2
  }

  if (count >= 200 && count <= 500) {
    return '200+'; // Return the exact count if it's less than 2
  }
  return `${count}+`; // Append '+' for counts 2 and above
};

const Autocomplete = ({
  suggestions = [],
  onSelect,
  onRemove,
  selectedItems = [],
  placeholder = 'Search...',
  minChars = 3,
  labelKey = 'title',
  valueKey = 'value',
  disabled = false
}) => {
  const [inputValue, setInputValue] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const containerRef = useRef(null);

  const filteredSuggestions = suggestions
    .filter(suggestion =>
      !suggestion.disabled &&
      suggestion[labelKey].replace(/\s+/g, ' ').trim().toLowerCase().includes(inputValue.replace(/\s+/g, ' ').trim().toLowerCase())
    );

  const handleInputChange = (e) => {
    // Capitalize the first letter of the input value
    const newValue = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1).toLowerCase();
    setInputValue(newValue);
    setShowSuggestions(newValue.length >= minChars);
    setHighlightedIndex(-1);
  };

  const handleSelectSuggestion = (suggestion) => {
    onSelect?.(suggestion[valueKey]);
    setInputValue('');
    setShowSuggestions(false);
  };

  const handleKeyDown = (e) => {
    if (!showSuggestions) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        setHighlightedIndex(prev =>
          prev < filteredSuggestions.length - 1 ? prev + 1 : prev
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setHighlightedIndex(prev => prev > 0 ? prev - 1 : -1);
        break;
      case 'Enter':
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelectSuggestion(filteredSuggestions[highlightedIndex]);
        }
        break;
      case 'Escape':
        setShowSuggestions(false);
        setHighlightedIndex(-1);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);


  return (
    <div className="autocomplete-container" ref={containerRef}>
      <div className="selected-items-container">
        {selectedItems.map((item, index) => (
          <PillSelector
            key={`${item}-${index}`}
            label={item}
            onRemove={onRemove}
          />
        ))}
      </div>
      <input
        className="autocomplete-input"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onFocus={() => !disabled && setShowSuggestions(inputValue.length >= minChars)}
        placeholder={placeholder}
        disabled={disabled}
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ul className="suggestions-list">
          {filteredSuggestions.map((suggestion, index) => (
            <li
              className={`suggestion-item suggestion-tag ${index === highlightedIndex ? 'highlighted' : ''}`}
              key={suggestion[valueKey]}
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              <p>{suggestion[labelKey]}</p>
              <p className="found-in">Found in {formatCountRange(suggestion.recipeCount)} recipes</p>
            </li>
          ))}
        </ul>
      )}
      {showSuggestions && filteredSuggestions.length === 0 && (
        <ul className="suggestions-list">
          <li className="suggestion-item suggestion-tag">
            <p>No Ingredients Found!</p>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Autocomplete; 