import React, { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import Button from "../common/button";
import { Routes, Route, useParams } from 'react-router-dom';
import Selector from "../common/selector";
import CustomInput from "../common/customInput";
import FacebookOutlineIcon from "../images/FacebookOutlineIcon";
import VimeoOutlineIcon from "../images/VimeoOutlineIcon";
import InstaOutlineIcon from "../images/InstaOutlineIcon";
import TwitOutlineIcon from "../images/TwitOutlineIcon";
import PinOutlineIcon from "../images/PinOutlineIcon";
import FormSucessIcon from "../images/formSucess";

import GroceryStock from "../images/groceryStock.png";
import FrameIcon from "../images/frameIcon";
import { baseUrl, grocery } from "../../helpers/api";

import {
    appEssentialFetch,
    addSentryError,
    showToastMessage,
} from "../../actions";
import pushToSentry from "../../helpers/pushToSentry";
import { TransitionOne, TransitionTwo } from "../common/transitions";
import Loader from "../common/contentLoader";

const initialState = {
    subject: "other",
    name: "",
    yourEmail: "",
    nomineeEmail: "",
    message: "",
    isValidForm: false,
    nameError: null,
    yourEmailError: null,
    nomineeEmailError: null,
    messageError: null,
    yourEmailFocus: false,
    nomineeEmailFocus: false,
    nameFocus: false,
    messageFocus: false,
    formSubmitted: false,
    formsubmitting: false,
    formSubmitSuccess: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case "YOUR_EMAIL_CHANGE":
            return {
                ...state,
                yourEmail: action.payload.email,
            };
        case "NOMINEE_EMAIL_CHANGE":
            return {
                ...state,
                nomineeEmail: action.payload.email,
            };
        case "NAME_CHANGE":
            return {
                ...state,
                name: action.payload.name,
            };
        case "MESSAGE_CHANGE":
            return {
                ...state,
                message: action.payload.message,
            };
        case "SUBJECT_CHANGE":
            return {
                ...state,
                subject: action.payload.subject,
            };
        case "VALIDATE_FORM":
            let yourEmailError = null;
            let nomineeEmailError = null;

            let nameError = null;
            let messageError = null;
            let isValidForm = true;
            if (
                !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(state.yourEmail)
            ) {
                yourEmailError = "Enter a valid email Id";
            }

            if (yourEmailError) {
                isValidForm = false;
            }
            return {
                ...state,
                yourEmailError,
                nomineeEmailError,
                nameError,
                messageError,
                isValidForm,
            };
        case "ON_BLUR":
            return {
                ...state,
                yourEmailFocus:
                    action.payload.yourEmailFocus && !state.yourEmailFocus
                        ? true
                        : state.yourEmailFocus,
                nomineeEmailFocus:
                    action.payload.nomineeEmailFocus && !state.nomineeEmailFocus
                        ? true
                        : state.nomineeEmailFocus,
                nameFocus:
                    action.payload.nameFocus && !state.nameFocus ? true : state.nameFocus,
                messageFocus:
                    action.payload.messageFocus && !state.messageFocus
                        ? true
                        : state.messageFocus,
            };
        case "FORM_SUBMIT":
            return {
                ...state,
                formsubmitting: action.payload.formsubmitting,
                formSubmitted: action.payload.formSubmitted,
            };
        case "FORM_SUCCESS":
            return {
                ...initialState,
                formsubmitting: false,
                formSubmitted: true,
                formSubmitSuccess: true,
            };
        default:
            return state;
    }
};

const MealPlanGiveaway = (props) => {
    useEffect(() => {
        props.appEssentialFetch();
    }, []);

    let { slug } = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const [isFetching, setIsFetching] = useState(true);
    const [displayData, setIsdisplayData] = useState({});
    useEffect(() => {
        if (slug) {
            const response = axios({
                method: "POST",
                url: `${baseUrl}/api/email-collects/${slug}`,
            })
                .then((response) => {
                    setIsFetching(false);
                    setIsdisplayData(response.data.emailcollect)
                });


        } else {

        }
    }, [slug]);


    const submitForm = () => {
        dispatch({
            type: "FORM_SUBMIT",
            payload: { formsubmitting: true, formSubmitted: false },
        });
        if (state.isValidForm) {
            // axios call
            axios({
                method: "POST",
                url: `${baseUrl}/api/meal-plan-giveaway-contact-addition`,
                data: {
                    email: state.yourEmail,
                },
            })
                .then((response) => {
                    dispatch({
                        type: "FORM_SUBMIT",
                        payload: { formsubmitting: false, formSubmitted: true },
                    });
                    dispatch({ type: "FORM_SUCCESS" });
                    // props.showToastMessage(`👍🏼Nomination sent successfully!`, "success");
                })
                .catch((e) => {
                    pushToSentry(e);
                    props.showToastMessage(`❗️Nomination error!`, "error");
                    dispatch({
                        type: "FORM_SUBMIT",
                        payload: { formsubmitting: false, formSubmitted: true },
                    });
                    props.addSentryError({
                        error: e.message,
                        eventId,
                    });
                });
        }
    };

    return (
        <main className="card-give-away" style={{ minHeight: '700px' }}>
            <Helmet>
                <title>Claim your Exclusive Content | Dashing Dish</title>
                <meta
                    name="description"
                    content="Dashingdish Claim your Exclusive Content page"
                />
            </Helmet>

            {
                isFetching ? (
                    <Loader />
                ) : (
                    <section className="giveaway-page">
                        <div className="container">
                            <Row className="giveaway-wrapper no-margin">
                                <Col xs={12} lg={12} className="no-padding">
                                    <div className="giveaway-form">
                                        {!state.formSubmitSuccess && (
                                            <TransitionOne>
                                                <Row>
                                                    <Col xl={6} lg={12}>
                                                        <div className="giveaway-hero-wrapper">
                                                            <h3>
                                                                {`Claim Access to download "${displayData.title}" Mealplan`}
                                                            </h3>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} lg={12} className="form-wrapper">
                                                        <p className="nominate-title">
                                                            Enter your email address
                                                        </p>

                                                        <form>
                                                            <CustomInput
                                                                type="email"
                                                                placeholder="Type here"
                                                                label="Your email"
                                                                value={state.yourEmail}
                                                                additionalDivClass="inline-div-wrapper"
                                                                additionalLabelClass="inline-label-wrapper"
                                                                additionalInputClass="inline-input-wrapper"
                                                                onChange={(e) => {
                                                                    dispatch({
                                                                        type: "YOUR_EMAIL_CHANGE",
                                                                        payload: { email: e.target.value },
                                                                    });
                                                                    dispatch({ type: "VALIDATE_FORM" });
                                                                }}
                                                                errorMessage={
                                                                    state.yourEmailError && state.yourEmailFocus
                                                                        ? state.yourEmailError
                                                                        : null
                                                                }
                                                                onBlur={(e) =>
                                                                    dispatch({
                                                                        type: "ON_BLUR",
                                                                        payload: { yourEmailFocus: true },
                                                                    })
                                                                }
                                                            />

                                                            {state.messageError && state.messageFocus && (
                                                                <div className="errorText">
                                                                    <FrameIcon></FrameIcon>
                                                                    <p>{state.messageError}</p>
                                                                </div>
                                                            )}
                                                            <Button
                                                                disabled={!state.isValidForm}
                                                                type="button"
                                                                btnClass="primary btn-full"
                                                                btnText="Submit"
                                                                onClick={submitForm}
                                                                isLoading={state.formsubmitting}
                                                            />
                                                        </form>
                                                    </Col>
                                                </Row>
                                            </TransitionOne>
                                        )}
                                        {state.formSubmitSuccess && (
                                            <>
                                                <TransitionOne>
                                                    <div className="giveaway-form-report">
                                                        <figure>
                                                            <FormSucessIcon></FormSucessIcon>
                                                        </figure>
                                                        <h3>Download your content!</h3>
                                                        <p>
                                                            Download <b>{`${displayData.title}`}</b> from this <a href={displayData.resource_url}>Link</a>
                                                        </p>
                                                    </div>
                                                </TransitionOne>
                                            </>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                )
            }

        </main>
    );
};

const mapStateToProps = () => ({});

const dispatchActionToProps = (dispatch) => ({
    addSentryError: bindActionCreators(addSentryError, dispatch),
    showToastMessage: bindActionCreators(showToastMessage, dispatch),
    appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(MealPlanGiveaway);
``;
