import React, { useState, useEffect } from 'react'

import { Transition, CSSTransition } from 'react-transition-group';
import { propTypes } from 'prop-types';

const duration = 2000;

const TransitionOne = ({
  children
}) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true)

  }, [])

  return (
    <CSSTransition in={inProp} timeout={duration} classNames="transition-one">
      {children}
    </CSSTransition>
  )

}


const TransitionTwo = ({
  children
}) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <CSSTransition in={inProp} timeout={duration} classNames="transition-two">
      {children}
    </CSSTransition>
  )
}



const TransitionThree = ({
  children
}) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <CSSTransition in={inProp} timeout={duration} classNames="transition-three">
      {children}
    </CSSTransition>
  )

}

const TransitionFour = ({
  children
}) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <CSSTransition in={inProp} timeout={duration} classNames="transition-four">
      {children}
    </CSSTransition>
  )
}


const TransitionFive = ({
  children
}) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <CSSTransition in={inProp} timeout={duration} classNames="transition-five">
      {children}
    </CSSTransition>
  )

}

const TransitionEaseInOne = ({
  children
}) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <CSSTransition in={inProp} timeout={duration} classNames="transition-easein-one">
      {children}
    </CSSTransition>
  )

}

const TransitionEaseInTwo = ({
  children
}) => {
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true)
  }, [])

  return (
    <CSSTransition in={inProp} timeout={duration} classNames="transition-easein-two">
      {children}
    </CSSTransition>
  )

}


export {
  TransitionOne,
  TransitionTwo,
  TransitionThree,
  TransitionFour,
  TransitionFive,
  TransitionEaseInOne,
  TransitionEaseInTwo
}