import React from 'react';
import Modalwrapper from '../../common/modal'
const PaypalConfirmationModal = (props) => {
  const {
    paypalConfirmationModalState,
    paypalConfirmationLink,
    setPaypalConfirmationModalState
  } = props

  console.log('paypal confirmation link ', paypalConfirmationLink);
  return (
    <Modalwrapper
      isOpen={paypalConfirmationModalState}
      maxWidth='450px'
      minHeight='460px'
      onRequestClose={() => {
        setPaypalConfirmationModalState(!paypalConfirmationModalState);
      }}
    >
      <div className="modalWrapper switch-plan-modal">
        <div className="modalBody">
          <p>
            <span>You are almost done with changing plan. Please confirm through </span>
            <a className="paypal-confirmation-link" href={paypalConfirmationLink.link} target="_blank">Paypal </a>
            <span> to proceed.</span>
          </p>
        </div>
      </div>
    </Modalwrapper>
  )
}

export default PaypalConfirmationModal