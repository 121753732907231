import { createSelector } from "reselect";
import { List, Map, Iterable, update, fromJS, toJS } from "immutable";
import filter from 'lodash/filter'
import join from 'lodash/join'
import isNull from 'lodash/isNull'
import trim from 'lodash/trim'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import shuffle from 'lodash/shuffle'
import take from 'lodash/take'
import capitalize from 'lodash/capitalize'

import { generateAppEssentialPayload } from '../helpers/filterConversions';

// recipe list selectors .........................................................................................
export const getEssentials = (state) => state.getIn(['essentials']);
export const getUserSpecificEssential = (state) => state.getIn(['userSpecificEssential']);
export const getObjectSpecificEssential = (state: any) => {
  return state.getIn(['objectSpecificEssential']);
}
export const getFetchStatus = state => {
  return fromJS({
    blogsSearch: state.getIn(['fetchStatus', 'blogsSearch']),
    appEssentials: state.getIn(['fetchStatus', 'appEssentials']),
    startup: state.getIn(['fetchStatus', 'startup']),
    userAuthenticate: state.getIn(['fetchStatus', 'userAuthenticate']),
    preFetch: state.getIn(['fetchStatus', 'objectPreFetch', 'blog']),
    initialLoad: state.getIn(['fetchStatus', 'listItemsFetch', 'blog', 'initialLoad']),
    pagination: state.getIn(['fetchStatus', 'listItemsFetch', 'blog', 'pagination']),
    // Specific Fetch Status
    dietaryList: state.getIn(['fetchStatus', 'listItemsFetch', 'defaultDietaryList', 'blog']),
    favoritesList: state.getIn(['fetchStatus', 'listItemsFetch', 'favoritesList', 'blog']),
    filteredList: state.getIn(['fetchStatus', 'listItemsFetch', 'filteredList', 'blog']),
    unfilteredList: state.getIn(['fetchStatus', 'listItemsFetch', 'unfilteredList', 'blog']),
    completeFetch: state.getIn(['fetchStatus', 'listItemsFetch', 'blog', 'completeFetch']),
    globalError: state.getIn(['fetchStatus', 'listItemsFetch', 'globalError', 'errorMessage']),
    blogTemporaryListFetch: state.getIn(['fetchStatus', 'blogTemporaryListFetch']),
    objectDetailsFetchStatus: state.getIn(['fetchStatus', 'objectDetails', 'blog']),
    videoTemporaryListFetch: state.getIn(['fetchStatus', 'videoTemporaryListFetch']),
    pullToRefresh: state.getIn(['fetchStatus', 'listItemsFetch', 'recipe', 'pullToRefresh']),
    trendingFetch: state.getIn(['fetchStatus', 'trendingBlog', 'isFetching']),
    unfilteredListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'unfiltered', 'initial']),
    filteredListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'filtered', 'initial']),
    favoritedListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'favorited', 'initial']),
    featuredListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'featured', 'initial']),
    unfilteredListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'unfiltered', 'pagination']),
    filteredListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'filtered', 'pagination']),
    favoritedListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'favorited', 'pagination']),
    featuredListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'blog', 'featured', 'pagination']),
  })
}

const objectFetchStatus = (state, payload) => { return { objectFetchStatus: state.getIn(['fetchStatus', 'objectDetails']), payload } };

export const getObjectFetchStatus = createSelector([objectFetchStatus], ({ objectFetchStatus, payload: { objectType, stackIndex = 0 } }) => {
  let status = objectFetchStatus.getIn([objectType, stackIndex]);
  return status;
});

// Blog categories
export const getBlogCategories = createSelector([getEssentials], essentials => essentials.getIn(['blog', 'categories']));

// unfiltered blogList
export const getUnFilteredList = createSelector([getEssentials], essentials => essentials.getIn(['blog', 'unFilteredList', 'list']));

// filtered blogList
export const getFilteredList = createSelector([getEssentials], essentials => essentials.getIn(['blog', 'filteredList', 'list']));

// Get Featured list
export const getFeaturedList = createSelector([getEssentials], essentials => essentials.getIn(['blog', 'featuredList', 'list']));

// for blog search text
export const getBlogsSearchText = createSelector([getEssentials], essentials => {
  return essentials.getIn(['blog', 'filters', 'search', 0])
});

export const getBlogsSearchCount = createSelector([getEssentials], essentials => essentials.getIn(['blog', 'searchList', 'count']))
export const getBlogsSearchList = createSelector([getEssentials], essentials => essentials.getIn(['blog', 'searchList', 'data']))

// recipe boards
export const getBoards = createSelector([getUserSpecificEssential], (userEssentials) => userEssentials.getIn(['boards', 'blogBoards', 'boards']));

export const getBlogFilterName = createSelector([getEssentials, getUserSpecificEssential], (essentials, userEssentials) => {

  if (essentials.getIn(['blog', 'searchApplied'])) {
    // Search
    return { type: 'search', title: essentials.getIn(['blog', 'filters', 'search', 0]) };
  } else if (essentials.getIn(['blog', 'favorite', 'show'])) {
    // Favorites
    let favoriteBoardSlug = essentials.getIn(['blog', 'favorite', 'board']);
    if (favoriteBoardSlug == null) {
      return { type: 'favorite', title: 'All' }
    }

    let boards = userEssentials.getIn(['boards', 'blogBoards', 'boards']);
    const index = boards.findIndex(board => board.getIn(['slug']) == favoriteBoardSlug);
    const favoriteHeader = boards.getIn([index, 'title']);
    const favoriteSlug = boards.getIn([index, 'slug']);

    return { type: 'favorite', title: favoriteHeader, slug: favoriteSlug }
  } else if (
    essentials.getIn(['blog', 'filters', 'categories']).size > 0 ||
    essentials.getIn(['blog', 'filters', 'archives']).size > 0 ||
    (essentials.getIn(['blog', 'filters', 'search']) && essentials.getIn(['blog', 'filters', 'search']).size > 0 && essentials.getIn(['blog', 'filters', 'searchBy']).size > 0) ||
    (essentials.getIn(['blog', 'filters', 'order']) && essentials.getIn(['blog', 'filters', 'order']).size > 0)
  ) {
    // Filters
    let filterArray = [];
    essentials.getIn(['blog', 'filters', 'categories'])
      .forEach((filteredCategory, index) => {
        let selectedCategories = essentials.getIn(['blog', 'categories']).find(category => category.getIn(['slug']) == filteredCategory);
        if (selectedCategories) {
          filterArray.push({
            type: 'categories',
            ...selectedCategories.toJS()
          });
        }
      });

    essentials.getIn(['blog', 'filters', 'archives'])
      .forEach((filteredArchive, index) => {
        filterArray.push({
          type: 'archives',
          name: capitalize(filteredArchive),
          slug: filteredArchive
        })
      })

    if (essentials.getIn(['blog', 'filters', 'search', 0]) && essentials.getIn(['blog', 'filters', 'search', 0]).length > 0) {
      filterArray.push({
        type: 'search',
        value: essentials.getIn(['blog', 'filters', 'search', 0]),
      })
    }

    if (essentials.getIn(['blog', 'filters', 'order', 0]) && essentials.getIn(['blog', 'filters', 'order', 0]).length > 0) {
      filterArray.push({
        type: 'order',
        value: essentials.getIn(['blog', 'filters', 'order', 0]),
      })
    }

    return { type: 'filter', filterArray }
  } else {
    return { type: 'recent', title: 'Recent Blogs' }
  }
})

export const getBlogs = createSelector([getFilteredList, getUnFilteredList, getBlogFilterName], (filteredBlogs, unfilteredBlogs, existingFilterType) => {
  return existingFilterType.type !== 'recent' ? filteredBlogs : unfilteredBlogs;
});

export const getMoreBlogAvailable = createSelector([getBlogFilterName, getEssentials], (existingFilterType, essentials) => {
  return existingFilterType.type !== 'recent' ?
    essentials.getIn(['blog', 'filteredList', 'moreBlogsAvailable']) : essentials.getIn(['blog', 'unFilteredList', 'moreBlogsAvailable']);
});

export const getMoreUnfilteredBlogAvailable = createSelector([getEssentials, getBlogFilterName], (essentials, existingFilterType) => {
  let status = essentials.getIn(['blog', 'unFilteredList', 'moreBlogsAvailable'])
  return status
})

export const getMoreFilteredBlogAvailable = createSelector([getEssentials, getBlogFilterName], (essentials, existingFilterType) => {
  let status = essentials.getIn(['blog', 'filteredList', 'moreBlogsAvailable']);
  return status
})


// specifies the id of recipe for the current page //Guess, please check
export const getBlogStackItem = (state, stackIndex = 0) => {
  const blogStack = state.getIn(['objectSpecificEssential', 'blog', 'objectStack']);

  if (!blogStack || !blogStack.getIn([stackIndex])) {
    return Map();
  }
  return blogStack.getIn([stackIndex]);
}

// Recipe stack Item pointed by the component
export const getBlogDetails = createSelector([getBlogStackItem], (blogStackItem) => {
  if (blogStackItem && !blogStackItem.isEmpty()) {
    const blogDetails = blogStackItem.getIn(['data', 'blog']);
    return blogDetails
  }
  return Map();
})



export const getNoteStackItem = (state, objectType, stackIndex = 0) => {
  const noteStack = state.getIn(['objectSpecificEssential', objectType, 'noteStack']);
  // TODO: Preloader should avoid this. Stack doesn't have this index
  // This happens just before new stack data is posted
  if (!noteStack || !noteStack.getIn([stackIndex])) {
    return Map();
  }
  return noteStack.getIn([stackIndex, 'data']);
}

// Get comments to the active recipe
export const getCommentsList = (state, objectType, stackIndex = 0) => {
  // Mofify comment according to front end requirments
  const commentStackItem = state.getIn(['objectSpecificEssential', objectType, 'commentStack', stackIndex, 'data']);

  if (commentStackItem) {
    const currentComments = commentStackItem
      .toSeq()
      .map(comment => {
        const author = comment.getIn(['user', 'name']);
        const url = comment.getIn(['user', 'url']);

        return comment
          .setIn(['author'], author)
          .setIn(['url'], url)
      })
      .toList()

    return currentComments;
  }
  return List()
};

export const getRelatedBlogs = createSelector([getBlogStackItem], (blogStackItem) => {

  if (blogStackItem) {
    const relatedBlogs = blogStackItem.getIn(['data', 'relatedBlogs']);
    return relatedBlogs
  }
  return List()
})

export const getAppliedFilters = (state) => {
  return state.getIn(['essentials', 'blog', 'filters']);
}

export const getTemporaryFilterCount = (state) => {
  return state.getIn(['essentials', 'blog', 'temporaryFilteredCount']);
}

export const getUserDetails = state => state.getIn(['loggedInUser', 'details']);


export const getRecipeStackLength = state => state.getIn(['objectSpecificEssential', 'recipe', 'objectStack']).size;
export const getWorkoutStackLength = state => state.getIn(['objectSpecificEssential', 'workout', 'objectStack']).size;

export const getTrendingBlogs = state => {
  return state.getIn(['essentials', 'trending', 'blogs', 'data']);
}

export const getTrendingType = state => {
  return state.getIn(['essentials', 'trending', 'recipes', 'type']);
}

export const getOrder = state => {
  return state.getIn(['essentials', 'blog', 'filters', 'order', 0]);
}