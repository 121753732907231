import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const RightArrow = (props) => {
  return (
    <>
      <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.53553 1.46447L9.07107 5L5.53553 8.53553" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default RightArrow;
