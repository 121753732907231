import React, { useEffect, useState } from 'react'
import {
    useInfiniteQuery,
} from 'react-query';

import classNames from 'classnames';
import { Row, Col } from "react-flexbox-grid";

import take from 'lodash/take';
import capitalize from 'lodash/capitalize';
import axios from 'axios';
import KatiesCornerCard from './cards/KatiesCornerCard';
import VideoPlayIcon from '../images/VideoPlayIcon';
import PersonalizedVideoCard from './cards/PersonalizedVideoCard';
import { personalizedBlogs, personalizedVideos } from '../../helpers/api';
import Button from "../common/button";
import HorizontalActionableHeader from '../common/horizontalActionableHeader';
import KatiesCornerChallenge from '../images/KatiesCornerChallenge.png';
import "../styles/common.scss";

const getPersonalizedBlogs = async (params) => {
    const response = await axios.get(personalizedBlogs.get(params.pageParam));
    return response.data.personalized_blogs;
}

const getCookingVideos = async (params) => {
    const response = await axios.get(personalizedVideos.get(params.pageParam, 'live_cooking'));
    return response.data.personalized_videos;
}

const getWorkoutVideos = async (params) => {
    const response = await axios.get(personalizedVideos.get(params.pageParam, 'live_workout'));
    return response.data.personalized_videos;
}

const getMeetingVideos = async (params) => {
    const response = await axios.get(personalizedVideos.get(params.pageParam, 'live_meeting'));
    return response.data.personalized_videos;
}

const Nourish = (props) => {

    const location = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }

    const [activeMeetingDisplay, onChooseActiveMeetingDisplay] = useState({});

    const offset = 0;
    const {
        data: blogData,
        isFetching: blogIsFetching,
        isFetchingNextPage: blogIsFetchingNextPage,
        status
    } = useInfiniteQuery(['personalizedBlogsData'], getPersonalizedBlogs, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage.length >= 15 ? pages.length * 15 : undefined;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    let personalizedBlogsData = [];
    if (blogData && blogData.pages) {
        personalizedBlogsData = blogData.pages.reduce((acc, page) => {
            acc = acc.concat(page);
            return acc;
        }, [])
    }

    const {
        data: liveCookingData,
        isFetching: cookingIsFetching,
        isFetchingNextPage: cookingIsFetchingNextPage,
    } = useInfiniteQuery(['liveCooking'], getCookingVideos, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage.length >= 15 ? pages.length * 15 : undefined;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    let cookingData = [];
    if (liveCookingData && liveCookingData.pages) {
        cookingData = liveCookingData.pages.reduce((acc, page) => {
            acc = acc.concat(page);
            return acc;
        }, [])
    }

    const {
        data: liveWorkoutData,
        isFetching: workoutIsFetching,
        isFetchingNextPage: workoutIsFetchingNextPage,
    } = useInfiniteQuery(['liveWorkout'], getWorkoutVideos, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage.length >= 15 ? pages.length * 15 : undefined;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    let workoutData = [];
    if (liveWorkoutData && liveWorkoutData.pages) {
        workoutData = liveWorkoutData.pages.reduce((acc, page) => {
            acc = acc.concat(page);
            return acc;
        }, [])
    }

    const {
        data: liveMeetingData,
        isFetching: meetingIsFetching,
        isFetchingNextPage: meetingIsFetchingNextPage,
    } = useInfiniteQuery(['liveMeeting'], getMeetingVideos, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage.length >= 15 ? pages.length * 15 : undefined;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    let meetingData = [];
    if (liveMeetingData && liveMeetingData.pages) {
        meetingData = liveMeetingData.pages.reduce((acc, page) => {
            acc = acc.concat(page);
            return acc;
        }, [])
    }


    useEffect(() => {
        if (!meetingIsFetching) {
            onChooseActiveMeetingDisplay(meetingData.length > 0 ? meetingData[0] : {})
        }

    }, [meetingIsFetching])


    const renderChallenge = () => {
        return props.monthlyChallengeData && props.monthlyChallengeData.length > 0 ? (
            <Row className='katie-challenge'>
                <Col xs={12} md={12} lg={4} className='challenge-header'>
                    <img src={KatiesCornerChallenge} />
                    <h4>{props.monthlyChallengeData[0]?.title}</h4>
                </Col>
                <Col xs={12} md={12} lg={8} className='challenge-content'>
                    <p>
                        {props.monthlyChallengeData[0]?.about}
                    </p>
                    <div className='challenge-action-grp'>
                        <Button
                            btnClass="btn btn-primary btn-md"
                            btnText={"Read More"}
                            onClick={(e) => {
                                navigateTo('/nourish/monthly-challenge')
                            }}
                        />
                        <Button
                            btnClass="btn btn-secondary btn-md"
                            btnText="Facebook Community"
                            onClick={(e) => {
                                window.open('https://www.facebook.com/groups/1189772621661105', '_newtab');

                            }}
                        />
                    </div>
                </Col>
            </Row>
        ) : null
    }

    const renderKatiesCornerBlog = () => (
        <div className="hl-list-wrapper-scrollable about-katies-corner-blog" id="katies-blog">
            <HorizontalActionableHeader
                showActions={false}
                showLink={true}
                link={{
                    text: 'View All',
                    href: '/nourish/katies-corner',
                    onClick: (to) => navigateTo(to)
                }}
                title={
                    <h3 className="header-content">
                        More from Katie’s Corner
                    </h3>
                }
                isLoading={false}
            >
                {
                    personalizedBlogsData.length > 0 ? (
                        <ul className='katies-corner-list nourish-list'>
                            {
                                (!blogIsFetching && !blogIsFetchingNextPage && blogData && blogData.pages) ? take(personalizedBlogsData, 4).map((cornerItem) => (
                                    <KatiesCornerCard
                                        key={cornerItem.slug}
                                        title={cornerItem.title}
                                        date={cornerItem.publish_at}
                                        introduction={cornerItem.about}
                                        banner={cornerItem.banner}
                                        navigateTo={navigateTo}
                                        slug={cornerItem.slug}
                                        categories={cornerItem.taxonomies}
                                    />
                                )) : null
                            }
                        </ul>
                    ) : <p>No Blogs Available</p>
                }


            </HorizontalActionableHeader>
        </div>
    )

    const renderKatiesCornerInTheKitchen = () => (
        <div className="hl-list-wrapper-scrollable about-katies-corner-blog" id="katies-in-the-kitchen">
            <HorizontalActionableHeader
                showActions={false}
                showLink={true}
                link={{
                    text: 'View All',
                    href: '/nourish/live-cooking',
                    onClick: (to) => navigateTo(to)
                }}
                title={
                    <h3 className="header-content">
                        In the kitchen
                    </h3>
                }
                isLoading={false}
            >
                {cookingData.length > 0 ? (
                    <ul className='katies-corner-list nourish-list'>
                        {
                            (!cookingIsFetching && !cookingIsFetchingNextPage && liveCookingData && liveCookingData.pages) ? take(cookingData, 4).map((cornerItem) => (
                                <PersonalizedVideoCard
                                    navigationType="past-cooking"
                                    key={cornerItem.slug}
                                    title={cornerItem.title}
                                    date={cornerItem.publish_at}
                                    introduction={cornerItem.about}
                                    banner={cornerItem.banner || cornerItem.thumbnail}
                                    navigateTo={navigateTo}
                                    slug={cornerItem.slug}
                                    categories={cornerItem.taxonomies}
                                />
                            )) : null
                        }
                    </ul>
                ) : <p>No In The Kitchen Videos Available</p>
                }
            </HorizontalActionableHeader>
        </div>
    )

    const renderKatiesCornerWorkout = () => (
        <div className="hl-list-wrapper-scrollable about-katies-corner-blog" id="katies-workout">
            <HorizontalActionableHeader
                showActions={false}
                showLink={true}
                link={{
                    text: 'View All',
                    href: '/nourish/live-workouts',
                    onClick: (to) => navigateTo(to)
                }}
                title={
                    <h3 className="header-content">
                        Workouts
                    </h3>
                }
                isLoading={false}
            >
                {
                    workoutData.length > 0 ? (
                        <ul className='katies-corner-list nourish-list'>
                            {
                                (!workoutIsFetching && !workoutIsFetchingNextPage && liveWorkoutData && liveWorkoutData.pages) ? take(workoutData, 4).map((cornerItem) => (
                                    <PersonalizedVideoCard
                                        navigationType="past-workout"
                                        key={cornerItem.slug}
                                        title={cornerItem.title}
                                        date={cornerItem.publish_at}
                                        introduction={cornerItem.about}
                                        banner={cornerItem.banner || cornerItem.thumbnail}
                                        navigateTo={navigateTo}
                                        slug={cornerItem.slug}
                                        categories={cornerItem.taxonomies}
                                    />
                                )) : null
                            }
                        </ul>

                    ) : <p>No Workout Videos Available</p>
                }

            </HorizontalActionableHeader>
        </div>
    )

    const renderKatiesZoomMeetings = () => (
        <>
            <div className="hl-list-wrapper-scrollable desktop about-katies-corner-blog" id="katies-zoom-meeting">
                <HorizontalActionableHeader
                    showActions={false}
                    showLink={true}
                    link={{
                        text: 'View Past Zoom Meetings',
                        href: '/nourish/live-meetings',
                        onClick: (to) => navigateTo(to)
                    }}
                    title={
                        <h3 className="header-content">
                            Recent Zoom Meetings
                        </h3>
                    }
                    isLoading={false}
                >
                    {
                        meetingData.length > 0 ? (
                            <Row className='meetings-group'>
                                <Col xs={3}>
                                    <ul className='tabs katies-corner-tabs vertical-tabs'>
                                        {
                                            (!meetingIsFetching && !meetingIsFetchingNextPage && liveMeetingData && liveMeetingData.pages) ? take(meetingData, 4).map((cornerItem) => {

                                                return (
                                                    <a
                                                        href={`/nourish/past-meeting/${cornerItem.slug}`}
                                                        key={cornerItem.id}
                                                        onMouseEnter={() => {
                                                            onChooseActiveMeetingDisplay(cornerItem)
                                                        }}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            navigateTo(`/nourish/past-meeting/${cornerItem.slug}`)
                                                        }}
                                                        className={
                                                            classNames({
                                                                active: cornerItem.slug === activeMeetingDisplay?.slug
                                                            })
                                                        }
                                                    >
                                                        <li
                                                            className={classNames('tab-option', 'katies-corner-tab-option')}

                                                        >

                                                            {capitalize(cornerItem.title)}{capitalize(cornerItem.about) ? ': ' : ''}{capitalize(cornerItem.about)}

                                                        </li>
                                                    </a>

                                                )

                                            }) : null
                                        }
                                        {
                                            (!meetingIsFetching && !meetingIsFetchingNextPage && liveMeetingData && liveMeetingData.pages) ? (
                                                <a href="/nourish/live-meetings" onClick={e => {
                                                    e.preventDefault();
                                                    navigateTo('/nourish/live-meetings')
                                                }}>
                                                    <li
                                                        className={classNames('tab-option', 'katies-corner-tab-option')}
                                                        onClick={(e) => {

                                                        }}
                                                    >

                                                        View Past Zoom Meetings

                                                    </li>
                                                </a>
                                            ) : null
                                        }
                                    </ul>
                                </Col>
                                <Col xs={9}>
                                    {
                                        (!meetingIsFetching && !meetingIsFetchingNextPage && liveMeetingData && liveMeetingData.pages && meetingData.length > 0 && activeMeetingDisplay) ? (
                                            <figure className='img-wrapper selected-meeting-container' style={{
                                                backgroundImage: "url(" + activeMeetingDisplay.banner + ")",
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat'
                                            }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigateTo(`/nourish/past-meeting/${activeMeetingDisplay.slug}`)
                                                }}
                                            >
                                                <VideoPlayIcon />
                                            </figure>

                                        ) : null
                                    }

                                </Col>
                            </Row>
                        ) : <p>No Meeting Videos Available</p>
                    }

                </HorizontalActionableHeader>
            </div>

            <div className="hl-list-wrapper-scrollable mobile">
                <HorizontalActionableHeader
                    showActions={false}
                    showLink={true}
                    link={{
                        text: 'View All',
                        href: '/nourish/live-meetings',
                        onClick: (to) => navigateTo(to)
                    }}
                    title={
                        <h3 className="header-content">
                            Recent Zoom Meetings
                        </h3>
                    }
                    isLoading={false}
                >
                    {
                        meetingData.length > 0 ? (
                            <ul className='katies-corner-list nourish-list'>
                                {
                                    (!meetingIsFetching && !meetingIsFetchingNextPage && liveMeetingData && liveMeetingData.pages) ? take(meetingData, 4).map((cornerItem) => (
                                        <PersonalizedVideoCard
                                            navigationType="past-workout"
                                            key={cornerItem.slug}
                                            title={cornerItem.title}
                                            date={cornerItem.publish_at}
                                            introduction={cornerItem.about}
                                            banner={cornerItem.banner || cornerItem.thumbnail}
                                            navigateTo={navigateTo}
                                            slug={cornerItem.slug}
                                            categories={cornerItem.taxonomies}
                                        />
                                    )) : null
                                }
                            </ul>
                        ) : <p>No Meeting Videos Available</p>
                    }


                </HorizontalActionableHeader>
            </div>
        </>
    )

    return (
        <div className='katies-corner-nourish'>
            <div className='container'>
                {renderChallenge()}
                {renderKatiesCornerBlog()}
                {renderKatiesCornerInTheKitchen()}
                {renderKatiesZoomMeetings()}
                {renderKatiesCornerWorkout()}
            </div>
        </div>



    )
}

export default Nourish