import React from "react";
import '../styles/common.scss';


const UpperBody = (props) => {
  return (
    < >
      <svg width="56" height="60" viewBox="0 0 56 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M21.8919 0.810791C21.8919 5.67566 20.2703 8.9189 12.1621 10.5405C7.39457 11.4973 4.05403 15.4054 4.05403 20.2702C4.05403 26.7567 4.05403 38.1081 0.810791 46.2162" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M13.7837 59.1891C21.8919 47.8377 13.7837 38.108 13.7837 25.135C13.7837 39.7296 8.91888 49.4593 8.91888 49.4593" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M33.2432 0.810791C33.2432 5.67566 34.8648 8.9189 42.973 10.5405C47.7405 11.4973 51.0811 15.4054 51.0811 20.2702C51.0811 26.7567 51.0811 38.1081 54.3243 46.2162" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M41.3513 59.1891C33.2432 47.8377 41.3513 38.108 41.3513 25.135C41.3513 39.7296 46.2162 49.4593 46.2162 49.4593" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          {/* <clipPath id="clip0">
            <rect width="55.1351" height="60" fill="white" />
          </clipPath> */}
        </defs>
      </svg>
    </>
  );
};
export default UpperBody;
