import { createSelector } from "reselect";
import { List, Map, Iterable, update, fromJS, toJS } from "immutable";
import filter from 'lodash/filter'
import join from 'lodash/join'
import isNull from 'lodash/isNull'
import trim from 'lodash/trim'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import shuffle from 'lodash/shuffle'
import take from 'lodash/take'
import capitalize from 'lodash/capitalize'

import { generateAppEssentialPayload } from '../helpers/filterConversions';

// video list selectors .........................................................................................
export const getEssentials = (state) => state.getIn(['essentials']);
export const getUserSpecificEssential = (state) => state.getIn(['userSpecificEssential']);
export const getObjectSpecificEssential = (state: any) => {
  return state.getIn(['objectSpecificEssential']);
}
export const getFetchStatus = state => {
  return fromJS({
    videosSearch: state.getIn(['fetchStatus', 'videosSearch']),
    appEssentials: state.getIn(['fetchStatus', 'appEssentials']),
    startup: state.getIn(['fetchStatus', 'startup']),
    userAuthenticate: state.getIn(['fetchStatus', 'userAuthenticate']),
    preFetch: state.getIn(['fetchStatus', 'objectPreFetch', 'video']),
    initialLoad: state.getIn(['fetchStatus', 'listItemsFetch', 'video', 'initialLoad']),
    pagination: state.getIn(['fetchStatus', 'listItemsFetch', 'video', 'pagination']),
    // Specific Fetch Status
    dietaryList: state.getIn(['fetchStatus', 'listItemsFetch', 'defaultDietaryList', 'video']),
    favoritesList: state.getIn(['fetchStatus', 'listItemsFetch', 'favoritesList', 'video']),
    filteredList: state.getIn(['fetchStatus', 'listItemsFetch', 'filteredList', 'video']),
    unfilteredList: state.getIn(['fetchStatus', 'listItemsFetch', 'unfilteredList', 'video']),
    completeFetch: state.getIn(['fetchStatus', 'listItemsFetch', 'video', 'completeFetch']),
    globalError: state.getIn(['fetchStatus', 'listItemsFetch', 'globalError', 'errorMessage']),
    videoTemporaryListFetch: state.getIn(['fetchStatus', 'videoTemporaryListFetch']),
    pullToRefresh: state.getIn(['fetchStatus', 'listItemsFetch', 'video', 'pullToRefresh']),
    unfilteredListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'unfiltered', 'initial']),
    filteredListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'filtered', 'initial']),
    favoritedListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'favorited', 'initial']),
    featuredListInitialTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'featured', 'initial']),
    unfilteredListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'unfiltered', 'pagination']),
    filteredListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'filtered', 'pagination']),
    favoritedListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'favorited', 'pagination']),
    featuredListPaginationTypeStatus: state.getIn(['fetchStatus', 'objectList', 'video', 'featured', 'pagination']),
  })
}

const objectFetchStatus = (state, payload) => { return { objectFetchStatus: state.getIn(['fetchStatus', 'objectDetails']), payload } };

export const getObjectFetchStatus = createSelector([objectFetchStatus], ({ objectFetchStatus, payload: { objectType, stackIndex = 0 } }) => {
  let status = objectFetchStatus.getIn([objectType, stackIndex]);
  return status;
});

// Video categories
export const getVideoCategories = createSelector([getEssentials], essentials => essentials.getIn(['video', 'categories']));

// Video collections
export const getVideoCollections = createSelector([getEssentials], essentials => essentials.getIn(['video', 'collections']));

// unfiltered videoList
export const getUnFilteredList = createSelector([getEssentials], essentials => essentials.getIn(['video', 'unFilteredList', 'list']));

// filtered videoList
export const getFilteredList = createSelector([getEssentials], essentials => essentials.getIn(['video', 'filteredList', 'list']));

// for video search text
export const getVideosSearchText = createSelector([getEssentials], essentials => {
  return essentials.getIn(['video', 'filters', 'search', 0])
});

export const getVideosSearchCount = createSelector([getEssentials], essentials => essentials.getIn(['video', 'searchList', 'count']))
export const getVideosSearchList = createSelector([getEssentials], essentials => essentials.getIn(['video', 'searchList', 'data']))

// recipe boards
export const getBoards = createSelector([getUserSpecificEssential], (userEssentials) => userEssentials.getIn(['boards', 'videoBoards', 'boards']));

export const getVideoFilterName = createSelector([getEssentials, getUserSpecificEssential], (essentials, userEssentials) => {

  if (essentials.getIn(['video', 'searchApplied'])) {
    // Search
    return { type: 'search', title: essentials.getIn(['video', 'filters', 'search', 0]) };
  } else if (essentials.getIn(['video', 'favorite', 'show'])) {
    // Favorites
    let favoriteBoardSlug = essentials.getIn(['video', 'favorite', 'board']);
    if (favoriteBoardSlug == null) {
      return { type: 'favorite', title: 'All' }
    }

    let boards = userEssentials.getIn(['boards', 'videoBoards', 'boards']);
    const index = boards.findIndex(board => board.getIn(['slug']) == favoriteBoardSlug);
    const favoriteHeader = boards.getIn([index, 'title']);
    const favoriteSlug = boards.getIn([index, 'slug']);

    return { type: 'favorite', title: favoriteHeader, slug: favoriteSlug }
  } else if (
    essentials.getIn(['video', 'filters', 'categories']).size > 0 ||
    essentials.getIn(['video', 'filters', 'collections']).size > 0 ||
    (essentials.getIn(['video', 'filters', 'search']) && essentials.getIn(['video', 'filters', 'search']).size > 0 && essentials.getIn(['video', 'filters', 'searchBy']).size > 0) ||
    (essentials.getIn(['video', 'filters', 'order']) && essentials.getIn(['video', 'filters', 'order']).size > 0)
  ) {
    // Filters
    let filterArray = [];
    essentials.getIn(['video', 'filters', 'categories'])
      .forEach((filteredCategory, index) => {
        let selectedCategories = essentials.getIn(['video', 'categories']).find(category => category.getIn(['slug']) == filteredCategory);
        if (selectedCategories) {
          filterArray.push({
            type: 'categories',
            ...selectedCategories.toJS()
          });
        }
      });

    essentials.getIn(['video', 'filters', 'collections'])
      .forEach((filteredCollection, index) => {
        let selectedCollections = essentials.getIn(['video', 'collections']).find(collection => collection.getIn(['slug']) == filteredCollection);
        if (selectedCollections) {
          filterArray.push({
            type: 'collections',
            ...selectedCollections.toJS()
          });
        }
      });



    if (essentials.getIn(['video', 'filters', 'search', 0]) && essentials.getIn(['video', 'filters', 'search', 0]).length > 0) {
      filterArray.push({
        type: 'search',
        value: essentials.getIn(['video', 'filters', 'search', 0]),
      })
    }

    if (essentials.getIn(['video', 'filters', 'order', 0]) && essentials.getIn(['video', 'filters', 'order', 0]).length > 0) {
      filterArray.push({
        type: 'order',
        value: essentials.getIn(['video', 'filters', 'order', 0]),
      })
    }

    return { type: 'filter', filterArray }
  } else {
    return { type: 'recent', title: 'Recent Videos' }
  }
})

export const getVideos = createSelector([getFilteredList, getUnFilteredList, getVideoFilterName], (filteredVideos, unfilteredVideos, existingFilterType) => {
  return existingFilterType.type !== 'recent' ? filteredVideos : unfilteredVideos;
});

export const getMoreVideoAvailable = createSelector([getVideoFilterName, getEssentials], (existingFilterType, essentials) => {
  return existingFilterType.type !== 'recent' ?
    essentials.getIn(['video', 'filteredList', 'moreVideosAvailable']) : essentials.getIn(['video', 'unFilteredList', 'moreVideosAvailable']);
});

export const getMoreUnfilteredVideoAvailable = createSelector([getEssentials, getVideoFilterName], (essentials, existingFilterType) => {
  let status = essentials.getIn(['video', 'unFilteredList', 'moreVideosAvailable'])
  return status
})

export const getMoreFilteredVideoAvailable = createSelector([getEssentials, getVideoFilterName], (essentials, existingFilterType) => {
  let status = essentials.getIn(['video', 'filteredList', 'moreVideosAvailable']);
  return status
});


export const getAppliedFilters = (state) => {
  return state.getIn(['essentials', 'video', 'filters']);
}

export const getTemporaryFilterCount = (state) => {
  return state.getIn(['essentials', 'video', 'temporaryFilteredCount']);
}

export const getUserDetails = state => state.getIn(['loggedInUser', 'details']);

export const getTrendingVideos = state => {
  return state.getIn(['essentials', 'trending', 'videos', 'data']);
}