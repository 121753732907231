import { put, takeEvery, call, select } from 'redux-saga/effects'
import axios from 'axios'
import * as actions from '../actions/actionTypes';
import { showToastMessage } from '../actions'
import mealPlanTask from './subtask/mealplan';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    const objectData = {
      date: action.payload.date,
      meal: 'breakfast',
      custom_title: action.payload.customTitle,
      custom_url: action.payload.customUrl,
      custom_notes: action.payload.customNotes
    }
    const { data } = yield call(mealPlanTask.post, objectData);
    yield put(showToastMessage(`✅ Custom recipe added.`, 'success', 'customRecipe'));
    yield put({
      type: actions.ADD_CUSTOM_RECIPE_TO_MEAL_PLAN_SUCCESS,
      payload: {
        mealData: data
      }
    })

  } catch (e) {
    yield put(showToastMessage(`❗️ Adding new item failed`, 'error'));

    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_CUSTOM_RECIPE_TO_MEAL_PLAN_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addCustomRecipeToMealPlan() {
  yield takeEvery(actions.ADD_CUSTOM_RECIPE_TO_MEAL_PLAN, task)
}