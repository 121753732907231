import React, { useEffect, useState } from 'react'

import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroller';
import ReactImageFallback from "react-image-fallback";

import cx from 'classnames'
import { fromJS } from 'immutable'
import concat from 'lodash/concat'
import toUpper from 'lodash/toUpper'
import map from 'lodash/map'
import filter from 'lodash/filter'
import capitalize from 'lodash/capitalize'
import findIndex from 'lodash/findIndex'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import reduce from 'lodash/reduce'

import { baseUrl } from '../../../helpers/api'

import ModalWrapper from '../../common/modal'
import CheckBox from '../../common/checkbox'
import ModalSearch from '../../common/modalSearch'

import useDataChooser from '../../../helpers/useAddWorkoutToMealPlan'
import CloseIcon from '../../images/closeIcon';
import UpArrow from '../../images/UpArrow';
import DownArrow from '../../images/DownArrow';
import CustomMealPlan from './CustomMealPlan';
import getWorkoutImages from '../../../helpers/getWorkoutImages';
import GridCard from '../../common/GridCard'
import Filters from '../../common/filters'
import InputRange from '../../common/inputRange';
import CloseableTag from '../../common/closeableTag';
import Loader from '../../images/loader';
import ChangeSortOrder from '../../common/changeSortOrder'

import ThumbNoteIcon from '../../images/ThumbNoteIcon';
import ThumbFavIcon from '../../images/ThumbFavIcon';

import {
  generateFilterFromQueryString,
  getWorkoutTypeOptions,
  getWorkoutBodyPartsOptions,
  getWorkoutEquipmentOptions,
  getFavouriteBoardsNavOptions,
  getFilterOptions,
  getFavoriteFilterOptions,
  getSelectorList,
  getDifficultyOptions,
  getTimeOptions
} from '../../workouts/components/config'
import Favorite from '../../favorite'
import LinkButton from '../../common/linkButton';
import LeftArrow from '../../images/LeftArrow';
import RightArrow from '../../images/RightArrow';
import AddIcon from '../../images/AddIcon';
import AddedIcon from '../../images/AddedIcon';

let debouncer;


const AddWorkoutModal = ({
  show,
  workoutTypes,
  workoutEquipments,
  workoutBodyParts,
  difficulty,
  time,
  addMeal,
  onRequestClose,
  activeObjectType,
  isAuthenticated,
  searchRecipes,
  workoutSearchList: searchList,
  fetchStatus,
  addedIds,
  workoutBoards: boards,
  workoutsSearchCount,
  activeDate,
  updateActiveDate
}) => {

  const [state, dispatch] = useDataChooser({
    token: localStorage.getItem('token'),
    objectType: activeObjectType,
    workoutFilterGroup: {
      workoutTypes,
      workoutEquipments,
      workoutBodyParts,
      difficulty,
      time,
    },
  });


  const [history, setHistory] = useState({
    location: {
      match: {
        url: ''
      }, // Just to prevent error 
      path: '/workouts', // Used majorly
      search: state.queryString // for hightlighting filter
    },
  })
  const [search, setSearch] = useState('');

  useEffect(() => {
    setHistory({
      ...history,
      location: {
        search: state.queryString
      }
    })
  }, [state.queryString])

  const workoutAddtionalStyle = { maxHeight: 132, minHeight: 132 };

  const renderMeta = (item) => {
    return (
      <div className="workout-meta recipe-meta">
        <span className="workout-time">
          {
            (item && item.time && item.time.length >= 0 && item.time) ? item.time.split(' ')[0] : '-'
          }
          {
            (item && item.time && item.time.length >= 0 && item.time) ?
              (toUpper(item.time.split(' ')[1]) ? toUpper(item.time.split(' ')[1]).substring(0, 1) : 'M')
              : ''
          }
        </span>
        <span className="separation">|</span>
        <span className="workout-difficuflty">
          {(item.difficulty)}
        </span>
        <span className="card-icons">
          {
            item.favorite ? (
              <ThumbFavIcon />
            ) : null
          }
          {
            item.notes ? (
              <ThumbNoteIcon />
            ) : null
          }
        </span>
      </div>
    )
  }

  const renderHoverEffect = (isItemAdded) => !isItemAdded ? (
    <div className="adding-wrapper add-wrapper">
      <figure>
        <AddIcon></AddIcon>
      </figure>
    </div>
  ) : (
      <div className="adding-wrapper added-wrapper">
        <figure>
          <AddedIcon></AddedIcon>
        </figure>
        <span>Workout added!</span>
      </div>
    );

  const renderWorkoutFilterTag = () => {
    if (state.filterSlugs.length > 0) {
      return (
        <div className="filterName">
          <div className="filterTitle">
            {
              map(state.filterSlugs, (filter) => {
                return (
                  <CloseableTag
                    key={filter.slug}
                    title={filter.name}
                    onClose={() => {
                      const updatedFilters = state.filterSlugs.filter(eachFilter => eachFilter.slug !== filter.slug);
                      dispatch({
                        type: 'UPDATE_FILTER_COMPLETELY',
                        payload: {
                          updatedFilters
                        }
                      })
                    }}
                  />
                )
              })
            }
          </div>
        </div>
      );
    } else if (state.search) {
      return (
        <>
          <span className="filtered-text">{'Results for'}&nbsp;</span>
          {`${state.search}`}
        </>
      )
    } else if (state.dataType === 'favorite') {
      return (
        <h3 className="header-content">
          {`${find(boards, board => {
            return board.id === state.favoriteSlug
          }).title}`}
        </h3>
      )
    } else {
      return (
        <h3 className="header-content">
          {'Recent Workouts'}
        </h3>
      )
    }
  }

  const renderDynamicWorkoutHeader = () => {
    if (state.filterSlugs.length > 0 || state.search.length > 0 || state.dataType === 'favorite') {
      return renderWorkoutFilterTag();
    } else {
      return (
        <h3 className="header-content">
          Recent Workouts
        </h3>
      )
    }
  }


  // the fav boards data
  const favouriteBoardsNavOptions = getFavouriteBoardsNavOptions(boards, {}) //workoutFilterName will be replacing here

  // the final fav options
  const favoriteFilterOptions = getFavoriteFilterOptions(favouriteBoardsNavOptions, {})
  // the dietaries data 
  const workoutTypeOptions = getWorkoutTypeOptions(workoutTypes, history)

  // the food type data
  const workoutBodyPartsOptions = getWorkoutBodyPartsOptions(workoutBodyParts, history)

  // specialities data
  const workoutEquipmentsOptions = getWorkoutEquipmentOptions(workoutEquipments, history)

  // Difficulty options
  const difficultyOptions = getDifficultyOptions(history)

  // Time options
  const timeOptions = getTimeOptions(history)

  const isBoards = (history.location.path === '/workouts/fav-boards')

  const filterOptions = getFilterOptions(
    workoutTypeOptions,
    workoutBodyPartsOptions,
    workoutEquipmentsOptions,
    difficultyOptions,
    timeOptions,
    history
  );

  // let preloaderWorkouts = map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], (item, index) => ({ isLoading: true, id: null }))

  // const modifiedPreloadedWorkouts = state.offset > 0 ? preloaderWorkouts : concat(state.data, preloaderWorkouts)
  // const actualWorkouts = state.isFetching ? modifiedPreloadedWorkouts : state.data;
  const actualWorkouts = state.data;

  const showClearText = state.filterSlugs.length > 0 || state.search;

  const renderListItems = () => {

    return (
      <>
        {
          (!state.hasMore && !state.isFetching && state.data.length === 0) ? (
            <h3>
              No Workout
            </h3>
          ) : (
              <ul className="workoutList in-modal">
                <>
                  {
                    map(actualWorkouts, (item, index) => {
                      let coverImage = getWorkoutImages(item, 'wide_640')
                      let backupImage;

                      const isItemAdded = findIndex(addedIds, addedItem => item.id === addedItem) !== -1;
                      return (
                        <li
                          key={index}
                          className={cx('workout', { 'added': isItemAdded })}
                        >
                          <GridCard
                            additionalStyles={workoutAddtionalStyle}
                            key={item.id}
                            feature={state.objectType}
                            objectType={state.objectType}
                            item={{
                              ...item,
                              coverImage
                            }}
                            renderMeta={renderMeta(item)}
                            thumbnail={coverImage}
                            coverImage={coverImage}
                            isAuthenticated={isAuthenticated}
                            showAction={false}
                            history={{ // Faking history
                              push: () => addMeal({
                                ...item,
                                objectType: 'workout'
                              })
                            }}

                            hoverEffect={renderHoverEffect(isItemAdded)}

                          />
                        </li>
                      )
                    })
                  }
                </>
              </ul>
            )
        }
      </>
    )
  }

  const isFavorites = history.location && history.location.path && history.location.path.split('/')[2] === 'favorites';

  const isSearch = state.search.length > 0;
  const isSortOrderActive = !isFavorites && !isSearch;
  const startDate = moment().startOf('day').format('YYYY-MM-DD');
  const endDate = moment().startOf('day').add({ days: 28 }).format('YYYY-MM-DD');
  return (
    <ModalWrapper
      isOpen={show}
      minHeight="85vh"
      maxWidth="1140px"
      borderRadius="10px"
      onRequestClose={onRequestClose}
      additionalClass="modal-close-wrapper-mobile"

    >
      <div className="modalWrapper fullScreenModal">
        <div className="modalHeader fullScreenModalHeader">
          <div className="previous-day">
            <LeftArrow></LeftArrow>
            <LinkButton
              renderValue={() => <>{moment(activeDate).clone().subtract({ days: 1 }).format('dddd Do')}</>}
              onClick={(e) => updateActiveDate(moment(activeDate).clone().subtract({ days: 1 }).format('YYYY-MM-DD'))}
              disabled={!moment(activeDate).clone().subtract({ days: 1 }).isBetween(startDate, endDate, null, '[)')}
            />
          </div>
          <h3>{moment(activeDate).clone().format('dddd Do')}</h3>
          <div className="next-day">
            <LinkButton
              renderValue={() => <>{moment(activeDate).clone().add({ days: 1 }).format('dddd Do')}</>}
              onClick={(e) => updateActiveDate(moment(activeDate).clone().add({ days: 1 }).format('YYYY-MM-DD'))}
              disabled={!moment(activeDate).clone().add({ days: 1 }).isBetween(startDate, endDate, null, '[)')}
            />
            <RightArrow></RightArrow>
          </div>
        </div>
        <section className="workouts">
          <Filters
            withRoute={false}
            objectType='workout'
            actions={(name, slug, filterName) => {
              setHistory({
                ...history,
                location: {
                  path: '/workouts/filters',
                }
              })

              let formattedFilterName = filterName;
              if (filterName === 'Workout Type') {
                formattedFilterName = 'workoutTypes';
              }
              if (filterName === 'Equipment') {
                formattedFilterName = 'equipments';
              }
              if (filterName === 'Body Parts') {
                formattedFilterName = 'bodyParts';
              }
              if (filterName === 'Difficulty') {
                formattedFilterName = 'difficulty'
              }
              if (filterName === 'Time') {
                formattedFilterName = 'time'
              }
              dispatch({
                type: 'UPDATE_FILTER',
                payload: {
                  previouslySelected: findIndex(state.filterSlugs, filter => filter.slug === slug) !== -1,
                  appFilter: { slug, name },
                  filterType: formattedFilterName
                }
              })
            }}
            showFilters={state.dataType !== 'favorite'}
            isFavorites={state.dataType === 'favorite'}
            options={filterOptions}
            staticOption={
              {
                primaryOption: 'Clear Filters',
                secondaryOption: 'All Workouts',
                route: '/workouts'
              }
            }
            showClearText={showClearText}
            onClickClearFilter={() => {
              dispatch({
                type: 'RESET_TO_INITIAL_STATE'
              })
            }}
            isAuthenticated={isAuthenticated}
            dropDownReturnActionText={`See all ${workoutsSearchCount} Workouts`}
            dropDownReturnAction={(searchText, searchBy) => {
              dispatch({
                type: 'SET_SEARCH',
                payload: {
                  search: searchText,
                  searchBy: searchBy
                }
              })
            }}
            history={{
              ...history,
              push: (path) => {
                const splitUrl = path.split('/')
                if (splitUrl[1] === 'workout') {
                  window.open(path, '_blank')
                }
                setHistory({
                  ...history,
                  location: {
                    path
                  }
                })
              }
            }}

            placeholder='Search workouts'
            searchText={state.search}
            onSearchChange={(searchText, searchBy) => {
              searchRecipes({
                objectType: 'workout',
                searchText: searchText,
                searchBy: searchBy
              })
            }}
            onSearchByChange={(value, searchText) => {
              searchRecipes({
                objectType: 'workout',
                searchText: searchText,
                searchBy: value
              })
            }}
            onClickSearchClear={() => {
              dispatch({
                type: 'RESET_TO_INITIAL_STATE'
              })
            }}
            defaultSearchText={state.search}
            defaultSearchBy="title"
            searchList={searchList}
            searchStatus={fetchStatus.workoutsSearch}
            searchCount={workoutsSearchCount}
            showDefaultInput={!isNil('title') || !isNil(filter.search)}
            selectorList={getSelectorList()}
            favoriteOptions={favoriteFilterOptions}
          />
          {
            !isBoards ? (
              <div className="container">
                <div className="calendar-modal-container">

                  <div className="scrollable-modal-container">
                    <InfiniteScroll
                      className="infiniteScroll"
                      initialLoad={false}
                      useWindow={false}
                      loadMore={!state.isFetching ? () => {
                        dispatch({
                          type: 'UPDATE_OFFSET',
                        })
                        setSearch(null)
                      } : () => { }}
                      hasMore={state.hasMore}
                      loader={
                        <div className="container">
                          <div className="preLoader-minimal-height">
                            <Loader></Loader>
                          </div>
                        </div>
                      }
                    >

                      <ChangeSortOrder
                        active={isSortOrderActive}
                        history={history}
                        objectType="workout"
                        title={renderDynamicWorkoutHeader()}
                        order={state.order}
                        history={{
                          ...history,
                          push: (path) => {
                            const filters = generateFilterFromQueryString('workout', {
                              ...history,
                              location: {
                                search: path
                              }
                            })

                            setHistory({
                              ...history,
                              location: {
                                path,
                                search: path,
                              }
                            })

                            const order = filters.workout.filters.order[0];

                            // Ordering will happen here 
                            dispatch({
                              type: 'CHANGE_ORDER',
                              payload: {
                                order,
                                force: order === 'rand'
                              }
                            })
                          }
                        }}
                        setFiltersAndFetch={(filters) => {
                          const order = filters.workout.filters.order[0];
                          dispatch({
                            type: 'CHANGE_ORDER',
                            payload: {
                              order,
                              force: order === 'rand'
                            }
                          })
                        }}
                      >
                        {renderListItems()}
                      </ChangeSortOrder>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            ) : (
                <Favorite
                  modal={true}
                  history={{
                    ...history,
                    push: (path) => {
                      const isFavoritePage = path.split('/')[1] && path.split('/')[2] === 'favorites'
                      const favoriteSlug = path.split('/')[3]

                      const favoriteBoard = find(boards, board => board.slug === favoriteSlug);

                      setHistory({
                        ...history,
                        location: {
                          path
                        }
                      })
                      if (isFavoritePage) {
                        dispatch({
                          type: 'CHANGE_DATA_TYPE_TO_FAVORITE',
                          payload: {
                            favoriteSlug: favoriteBoard ? favoriteBoard.id : null,
                            force: true,
                          }
                        })
                      } else {
                        if (state.dataType !== 'all') {
                          // If coming from any previous favorites page
                          dispatch({
                            type: 'RESET_TO_INITIAL_STATE'
                          })
                        }
                      }
                    }
                  }}
                  match={{
                    url: '/workouts'
                  }}
                />
              )
          }

        </section>
      </div>
    </ModalWrapper >
  )
}

export default AddWorkoutModal;