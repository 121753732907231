import React, { useState, useEffect } from "react";
import Avatar from "./avatar";
import { Row, Col } from "react-flexbox-grid";
import Button from "../common/button";
import CustomInput from "../common/customInput";
import ImageUploader from "react-images-upload";
import { TransitionOne, TransitionTwo } from "../common/transitions";

const Settings = ({
  userDetails,
  editUserProfile,
  profileUpdateFetchStatus
}) => {
  const {
    details: {
      avatar = {},
      member_since: memberSince,
      membership = {},
      plan,
      name,
      email
    }
  } = userDetails;

  const [state, updateState] = useState({
    email: null,
    password: null,
    avatar: null
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const [withPreview, setWithPreview] = useState(false);

  return (
    <div className="account-content-wrapper">
      <Row className="no-margin">
        <TransitionOne>
          <Col xs={12} lg={3} className="account-content-left-wrapper">
            <Avatar src={avatar.medium} memberSince={memberSince} name={name} />
          </Col>
        </TransitionOne>
        <TransitionTwo>
          <Col xs={12} lg={9} className="account-content-right-wrapper">
            <h3>Account Settings</h3>
            <div className="form-text">
              <div className="static-value">Avatar</div>
              <div className="inputField">
                <ImageUploader
                  className="customUploader"
                  withIcon={false}
                  onChange={(file, picture) => {
                    setWithPreview(true);
                    updateState({
                      ...state,
                      avatar: picture[0]
                    });
                  }}
                  imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                  maxFileSize={5242880}
                  withPreview
                  singleImage={true}
                  label=""
                  buttonText=""
                  buttonStyles={{ display: "none" }}
                  withPreview={withPreview}
                />
              </div>
            </div>
            <div className="form-text">
              <div className="static-value">Name</div>
              <div className="inputField">{name}</div>
            </div>
            <div className="form-text">
              {showEditEmail ? (
                <div className="password-field">
                  <CustomInput
                    type={"text"}
                    onChange={event =>
                      updateState({ ...state, email: event.target.value })
                    }
                    value={state.email}
                    label="Email"
                    focusOnMount
                  />
                </div>
              ) : (
                <>
                  <div className="static-value">Email</div>
                  <div className="inputField">{email}</div>
                  <div
                    className="edit-password"
                    onClick={() => {
                      updateState({
                        ...state,
                        email
                      });
                      setShowEditEmail(!showEditEmail);
                    }}
                  >
                    <span>{"Edit"}</span>
                  </div>
                </>
              )}
            </div>
            <div className="form-text">
              {showEditPassword ? (
                <div className="password-field">
                  <CustomInput
                    type={passwordVisible ? "text" : "password"}
                    onChange={event =>
                      updateState({ ...state, password: event.target.value })
                    }
                    placeholder="Type new password here"
                    value={state.password}
                    label="Password"
                    focusOnMount
                  />
                  <div
                    className="show-password"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    <span>{passwordVisible ? "Hide" : "Show"}</span>
                  </div>
                </div>
              ) : (
                <>
                  <div className="static-value">Password</div>
                  <div className="inputField">*********</div>
                  <div
                    className="edit-password"
                    onClick={() => setShowEditPassword(!passwordVisible)}
                  >
                    <span>{"Edit"}</span>
                  </div>
                </>
              )}
            </div>
            <div className="form-text">
              <div className="static-value">Notifications: On</div>
              <div className="inputField" style={{ fontWeight: 500 }}></div>
              <div className="switchField">
                <label class="switch" for="checkbox">
                  <input type="checkbox" id="checkbox" />
                  <div class="slider round"></div>
                </label>
              </div>
            </div>
            <Button
              btnClass={`full btn-primary ${
                state.password ||
                state.avatar ||
                (state.email && state.email !== email) ||
                profileUpdateFetchStatus.isFetching
                  ? ""
                  : "hide-button"
              }`}
              btnText="Save"
              onClick={() => {
                editUserProfile(state);
                updateState({
                  email: null,
                  password: null,
                  avatar: null
                });
                // setWithPreview(false)
                setPasswordVisible(false);
                setShowEditPassword(false);
              }}
              isLoading={profileUpdateFetchStatus.isFetching}
              disabled={
                profileUpdateFetchStatus.isFetching ||
                (!state.password && !state.avatar && !state.email)
              }
            />
          </Col>
        </TransitionTwo>
      </Row>
    </div>
  );
};

export default Settings;
