import { put, takeEvery, call, select } from 'redux-saga/effects'
// import { Sentry } from 'react-native-sentry';
import * as actions from '../actions/actionTypes'
import uuidv1 from 'uuid/v1';
import boardTask from './subtask/boards'
import pushToSentry from '../helpers/pushToSentry';
import { showToastMessage } from '../actions';

function* task(action) {
  try {

    const { boardName, objectType } = action.payload
    let boardObject = {
      title: boardName,
      object_type: objectType
    }

    const data = yield call(boardTask.post, boardObject)

    // adding isSelected: true , in board object to be default selected
    data.data.isSelected = true;

    yield put({
      type: actions.CREATE_FAVORITE_BOARD_SUCCESS,
      payload: {
        success: true,
        boardObject: data,
        objectType: objectType
      }
    })
    yield put(showToastMessage(`✅ Board created.`, 'success', 'createBoard'));

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CREATE_FAVORITE_BOARD_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* createBoard() {
  yield takeEvery(actions.CREATE_FAVORITE_BOARD, task)
}