import includes from "lodash/includes";
import toLower from "lodash/toLower";
import filter from "lodash/filter";

export default (nutrition) => {
  if (nutrition && nutrition.groups) {
    const servingItem = filter(nutrition.groups[0].lines, (line) => {
      return (
        (includes(toLower(line.item), "servings") ||
          includes(toLower(line.item), "serving")) &&
        !includes(toLower(line.item), "calorie")
      );
    });

    if (servingItem.length > 0) {
      return servingItem[0].amount;
    } else {
      return 1;
    }
  } else {
    return 1;
  }
};
