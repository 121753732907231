import React from "react";

import cx from "classnames";

import RecipesNavDropdown from "./recipesNavDropdown";
import WorkoutsNavDropdown from "./workoutsNavDropdown";
import VideosNavDropdown from "./videosNavDropdown";
import MealPlansNavDropdown from "./mealPlansDropdown";
import BlogsNavDropdown from "./blogsNavDropdown";
import LoggedOutNavDropdown from "./loggedOutNavDropdown";
import ProfileNavDropdown from "./profileNavDropdown";
import KatiesCornerDropdownContent from "./katiesCornerDropdown";
import SignupDropdown from "./signupDropdown";

const NavbarDropdown = ({
  activeHoveredNavItem = "Recipes",
  activeParentNavItem,
  userDetails,
  history,
  activeRecipe,
  activeWorkout,
  activeBlog,
  recipeFilters,
  workoutBodyParts,
  videoCategories,
  blogFeaturedList,
  latestRecipe,
  mealPlanCategories,
  activeHoveredEntry,
  requestCloseNavDropdown,
  isAuthenticated
}) => {
  let isAdmin = false;

  if (
    userDetails &&
    userDetails.details &&
    (userDetails.details.role == "admin" ||
      userDetails.details.role == "author")
  ) {
    isAdmin = true;
  }

  let dropdownContent = null;

  if (activeHoveredNavItem) {
    switch (activeHoveredNavItem) {
      // case "Recipes":
      //   dropdownContent = (
      //     <RecipesNavDropdown
      //       history={history}
      //       recipeFilters={recipeFilters}
      //       activeHoveredEntry={activeHoveredEntry}
      //       requestCloseNavDropdown={requestCloseNavDropdown}
      //     />
      //   );
      //   break;
      // case "Workouts":
      //   dropdownContent = (
      //     <WorkoutsNavDropdown
      //       history={history}
      //       workoutBodyParts={workoutBodyParts}
      //       activeHoveredEntry={activeHoveredEntry}
      //       requestCloseNavDropdown={requestCloseNavDropdown}
      //     />
      //   );
      //   break;
      // case "Videos":
      //   dropdownContent = (
      //     <VideosNavDropdown
      //       history={history}
      //       videoCategories={videoCategories}
      //       activeHoveredEntry={activeHoveredEntry}
      //       requestCloseNavDropdown={requestCloseNavDropdown}
      //     />
      //   );
      //   break;
      // case "Blog":
      //   dropdownContent = (
      //     <BlogsNavDropdown
      //       history={history}
      //       blogFeaturedList={blogFeaturedList}
      //       activeHoveredEntry={activeHoveredEntry}
      //       requestCloseNavDropdown={requestCloseNavDropdown}
      //     />
      //   );
      //   break;
      // case "Meal Plans":
      //   dropdownContent = (
      //     <MealPlansNavDropdown
      //       history={history}
      //       mealPlanCategories={mealPlanCategories}
      //       activeHoveredEntry={activeHoveredEntry}
      //       requestCloseNavDropdown={requestCloseNavDropdown}
      //     />
      //   );
      //   break;
      case "Explore":
        dropdownContent = (
          <LoggedOutNavDropdown
            history={history}
            latestRecipe={latestRecipe}
            activeHoveredEntry={activeHoveredEntry}
            requestCloseNavDropdown={requestCloseNavDropdown}
          />
        );
        break;
      // case "DashingDish":
      //   dropdownContent = null;
      //   dropdownContent = (
      //     <LoggedOutNavDropdown
      //       history={history}
      //       latestRecipe={latestRecipe}
      //       activeHoveredEntry={activeHoveredEntry}
      //       requestCloseNavDropdown={requestCloseNavDropdown}
      //     />
      //   );
      //   break;
      // case "Nourish":
      //   dropdownContent = (
      //     <KatiesCornerDropdownContent
      //       history={history}
      //       latestRecipe={latestRecipe}
      //       activeHoveredEntry={activeHoveredEntry}
      //       requestCloseNavDropdown={requestCloseNavDropdown}
      //     />
      //   )
      //   break;
      case "Profile":
        dropdownContent = (
          <ProfileNavDropdown
            isAdmin={isAdmin}
            history={history}
            activeRecipe={activeRecipe}
            activeWorkout={activeWorkout}
            activeBlog={activeBlog}
            activeHoveredEntry={activeHoveredEntry}
            requestCloseNavDropdown={requestCloseNavDropdown}
          />
        );
        break;
      case "My Calendar":
        return null;
      case "Grocery List":
        return null;
      case "Signup":
        dropdownContent = (
          <SignupDropdown
            isAdmin={isAdmin}
            history={history}
            activeRecipe={activeRecipe}
            activeWorkout={activeWorkout}
            activeBlog={activeBlog}
            activeHoveredEntry={activeHoveredEntry}
            requestCloseNavDropdown={requestCloseNavDropdown}
          />
        );
        break;
      default:
        return null;
    }

    return (
      <div
        className={cx("navbar-dropdown", {
          entry: activeHoveredEntry,
          "profile-dropdown": activeHoveredNavItem === "Profile" || activeHoveredNavItem === "Signup",
        })}
      >
        {dropdownContent}
      </div>
    );
  } else {
    return null;
  }
};

export default NavbarDropdown;
