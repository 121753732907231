import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import ReactImageFallback from "react-image-fallback";

import filter from 'lodash/filter'
import capitalize from 'lodash/capitalize'
import take from 'lodash/take'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import concat from 'lodash/concat'

import { fromJS } from 'immutable'
import toUpper from 'lodash/toUpper'
import { Helmet } from 'react-helmet'

import GridCard from '../../common/GridCard'
import Modalwrapper from '../../common/modal'
import PopupModal from '../../common/popupModal'
import FavoriteModal from '../../common/favoriteModal'
import CloseableTag from '../../common/closeableTag';
import Carousel from '../../common/featuredCarousel'

import ThumbNoteIcon from '../../images/ThumbNoteIcon';
import ThumbFavIcon from '../../images/ThumbFavIcon';

import Loader from '../../images/loader'
import ChangeSortOrder from '../../common/changeSortOrder'

import Filters from '../../common/filters'
import {
  generateAppEssentialPayload,
  generateWorkoutQueryFromFilterForFrontend,
} from '../../../helpers/filterConversions';

import {
  TransitionOne,
  TransitionTwo,
  TransitionThree,
  TransitionFour,
} from '../../common/transitions';

import getWorkoutImages from '../../../helpers/getWorkoutImages'
import TrendingChangeAction from '../../common/trendingChangeAction';

import { removeCharecterFromIndex } from '../../../helpers/converters'
import convertQuality from '../../../helpers/convertQuality';
import { isNewTagStatus } from "../../../helpers/getNewTagStatus";
import Placeholder from '../../common/placeholder'

import {
  generateFilterFromQueryString,
  getWorkoutTypeOptions,
  getWorkoutBodyPartsOptions,
  getWorkoutEquipmentOptions,
  getFavouriteBoardsNavOptions,
  getFilterOptions,
  getFavoriteFilterOptions,
  getSelectorList,
  getDifficultyOptions,
  getTimeOptions
} from './config'
import AdComponent from '../../common/ad';

const Workouts = ({
  appEssentialFetch,
  workouts,
  isAuthenticated,
  deleteFavourite,
  createBoard,
  setObjectBoards,
  createFavourite,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  boards,
  fetchStatus,
  history,
  fetchWorkouts,
  workoutTypes,
  workoutBodyParts,
  workoutEquipments,
  moreWorkoutsAvailable,
  searchCount = 0,
  searchTerm,
  searchBy = 'title',
  searchList = [],
  workoutFilterName,
  appliedFilters,
  searchWorkouts,
  featuredItems,
  globalError,
  trendingWorkouts,
  trendingType,
  order,
  setFiltersAndFetch,
  userDetails,
  ...props
}) => {
  const objectType = 'workouts'

  const [state, setState] = useState({
    showLoginModal: false,
    activeWorkoutId: null,
    clickedWorkoutName: '',
    showFavoriteModal: false,
  })

  const favoriteModalToggleHandler = ({ itemId, modalState }) => {
    setState(prevState => ({ ...prevState, showFavoriteModal: modalState, activeWorkoutId: itemId }))
  }
  // handler for login modal close
  const loginModalCloseHandler = () => {
    setState(prevState => ({ ...prevState, showLoginModal: false }))
  }
  // handler for login redirect 
  const loginRedirectHandler = (title) => {
    setState(prevState => ({ ...prevState, clickedWorkoutName: title, showLoginModal: true }))
  }

  const isFavorites = (
    props.match.path === '/workouts/favorites/:favoriteBoardSlug' ||
    props.match.path === '/workouts/favorites'
  );

  const isFeatured = (
    props.match.path === '/workouts/featured/:featuredCollection'
  );

  useEffect(() => {

    if (!isNil(globalError)) {
      history.push('/workouts')
    }
  }, [globalError]);

  useEffect(() => {
    // Can be of few types
    // 1. /workouts/favorites/:favoriteBoardSlug
    // 2. /workouts/featured/:defaultDietary
    // 3. /workouts/:defaultFodType
    // 4. /workouts/?filters

    let favorite = {
      show: false,
      board: null
    };

    // Case 1.
    const isFavorites = (props.match.path === '/workouts/favorites/:favoriteBoardSlug' || props.match.path === '/workouts/favorites');
    if (isFavorites) {
      const favoriteBoardSlug = props.match.params.favoriteBoardSlug ? props.match.params.favoriteBoardSlug : null;
      favorite.show = true;
      favorite.board = favoriteBoardSlug;
    }

    // Case 2.
    if (props.match.path === '/workouts/featured/:featuredCollection') {

      const appEssentialPayload = generateAppEssentialPayload({
        objectType: 'workout',
        queryString: `?collection=${props.match.params.featuredCollection}`,
        meta: {
          objectType: 'workout',
          listType: 'featured',
        }
      });

      appEssentialFetch(appEssentialPayload);
    } else if (props.match.path === '/workouts/:workoutType/:workoutTaxonomy') {
      // Case 3.
      const appEssentialPayload = generateAppEssentialPayload({
        objectType: 'workout',
        queryString: `?${props.match.params.workoutType}=${props.match.params.workoutTaxonomy}`,
        meta: {
          objectType: 'workout',
          listType: 'featured'
        }
      });
      appEssentialFetch(appEssentialPayload);
    } else {
      // Case 4
      let listType = 'unfiltered'

      if (isFavorites) {
        listType = 'favorited'
      } else if (history.location.search.length !== 0) {
        listType = 'filtered'
      }

      const appEssentialPayload = generateAppEssentialPayload({
        objectType: 'workout',
        queryString: history.location.search,
        favorite,
        meta: {
          objectType: 'workout',
          listType
        }
      });
      appEssentialFetch(appEssentialPayload);
    }

  }, [history.location.search, props.match.path, props.match.url]);

  const showFilters = !isFeatured;

  // the fav boards data
  const favouriteBoardsNavOptions = getFavouriteBoardsNavOptions(boards, {}) //workoutFilterName will be replacing here

  // the final fav options
  const favoriteFilterOptions = getFavoriteFilterOptions(favouriteBoardsNavOptions, {})

  // the dietaries data 
  const workoutTypeOptions = getWorkoutTypeOptions(workoutTypes, history)

  // the food type data
  const workoutBodyPartsOptions = getWorkoutBodyPartsOptions(workoutBodyParts, history)

  // specialities data
  const workoutEquipmentsOptions = getWorkoutEquipmentOptions(workoutEquipments, history)

  // Difficulty options
  const difficultyOptions = getDifficultyOptions(history)

  // Time options
  const timeOptions = getTimeOptions(history)

  const filterOptions = getFilterOptions(
    workoutTypeOptions,
    workoutBodyPartsOptions,
    workoutEquipmentsOptions,
    difficultyOptions,
    timeOptions,
    history
  );

  const navFilterActionDispatch = (slug, filterName, featureTitle) => {
    let finalQueryParam = '';
    let filterFromQueryString = generateFilterFromQueryString('workout', history)
    if (featureTitle) {
      // direct to category page
      if (featureTitle === 'collection') {
        // direct to category page
        finalQueryParam = `workouts/featured/${slug}`;
      }
      history.push(`${finalQueryParam}`)
    } else {
      if (filterName === 'Workout Type') {
        const workoutTypes = filterFromQueryString.workout.filters.workoutTypes;
        const index = workoutTypes.findIndex(workoutType => workoutType === slug);
        if (index === -1) {
          filterFromQueryString.workout.filters.workoutTypes = [...workoutTypes, slug];
        } else {
          let splicedDietary = workoutTypes.splice(index, 1);
          filterFromQueryString.workout.filters.workoutTypes = workoutTypes
        }
      } else if (filterName === 'Equipment') {
        const equipments = filterFromQueryString.workout.filters.equipments;
        const index = equipments.findIndex(category => category === slug);
        if (index === -1) {
          filterFromQueryString.workout.filters.equipments = [...equipments, slug];
        } else {
          let splicedFoodType = equipments.splice(index, 1);
          filterFromQueryString.workout.filters.equipments = equipments
        }
      } else if (filterName === 'Body Parts') {
        const bodyParts = filterFromQueryString.workout.filters.bodyParts;
        const index = bodyParts.findIndex(category => category === slug);
        if (index === -1) {
          filterFromQueryString.workout.filters.bodyParts = [...bodyParts, slug];
        } else {
          let splicedFoodType = bodyParts.splice(index, 1);
          filterFromQueryString.workout.filters.BodyParts = bodyParts
        }
      } else if (filterName === 'Difficulty') {
        const difficulty = filterFromQueryString.workout.filters.difficulty;
        const index = difficulty.findIndex(category => category === slug);
        if (index === -1) {
          filterFromQueryString.workout.filters.difficulty = [...difficulty, slug];
        } else {
          let splicedFoodType = difficulty.splice(index, 1);
          filterFromQueryString.workout.filters.difficulty = difficulty
        }
      } else if (filterName === 'Time') {
        const time = filterFromQueryString.workout.filters.time;
        const index = time.findIndex(category => category === slug);
        if (index === -1) {
          filterFromQueryString.workout.filters.time = [...time, slug];
        } else {
          let splicedFoodType = time.splice(index, 1);
          filterFromQueryString.workout.filters.time = time
        }
      }

      finalQueryParam = generateWorkoutQueryFromFilterForFrontend(fromJS(filterFromQueryString.workout.filters))

      // condition to remove if & present in index 0
      if (finalQueryParam.charAt(0) === '&') {
        finalQueryParam = removeCharecterFromIndex(finalQueryParam, 0)
      }
      history.push(`?${finalQueryParam}`)
    }
  }

  const directFilterNotApplied = location.pathname === '/workouts' &&
    (
      isEmpty(location.search) ||
      location.search === '?order=asc' ||
      location.search === '?order=desc' ||
      location.search === '?order=rand' ||
      location.search === '?order=favorite'
    )


  const renderWorkoutFilterTag = () => {
    const filters = filter(workoutFilterName.filterArray, filter => filter.type !== 'order')

    if (filters.length > 0) {
      return (
        <div className="filterName">
          <div className="filterTitle">
            {
              map(filters, (filter) => {
                return (
                  <CloseableTag
                    key={filter.slug}
                    title={filter.name}
                    onClose={() => {
                      let updatedFilter;
                      if (filter.type === 'difficulty' || filter.type === 'time') {
                        const updatedFilterGroup = appliedFilters[filter.type].filter(eachSlug => eachSlug !== filter.slug);
                        updatedFilter = {
                          ...appliedFilters,
                          [filter.type]: updatedFilterGroup,
                        }
                      } else {
                        const updatedFilterGroup = appliedFilters[filter.type].filter(eachSlug => eachSlug !== filter.slug);
                        updatedFilter = {
                          ...appliedFilters,
                          [filter.type]: updatedFilterGroup,
                        }
                      }

                      history.push(`/workouts?${removeCharecterFromIndex(
                        generateWorkoutQueryFromFilterForFrontend(fromJS(updatedFilter)),
                        0
                      )}`)
                    }}
                  />
                )
              })
            }
          </div>
        </div>
      );
    } else {
      return (
        <h3 className="header-content">
          {'Recent Workouts'}
        </h3>
      )
    }
  }

  const renderFeatured = () => {
    const featuredCollection = featuredItems.find((collection) => {
      return (collection.slug === props.match.params.featuredCollection)
    });
    return (isFeatured && !isNil(featuredCollection)) ? (
      <div className="container">
        <div className="featuredCollection-wrapper">
          <figure
            className="featuredCollection-image"
            style={{
              backgroundImage: `url("${convertQuality(featuredCollection.image, 'quality')}")`,
              height: 250,
              backgroundSize: 'cover',
              backgroundRepeat: 'none',
              backgroundPosition: 'center'
            }}
          >
          </figure>
          <div className="feature-overlay">
            <h5>featured</h5>
            <p>{featuredCollection.name}</p>
          </div>
        </div>
      </div>
    ) : null
  }

  const noInitialFetchHappening = (
    !fetchStatus.unfilteredListInitialTypeStatus.isFetching &&
    !fetchStatus.filteredListInitialTypeStatus.isFetching &&
    !fetchStatus.favoritedListInitialTypeStatus.isFetching &&
    !fetchStatus.featuredListInitialTypeStatus.isFetching
  );
  const noPaginationFetchHappening = (
    !fetchStatus.unfilteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.filteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.favoritedListPaginationTypeStatus.isFetching &&
    !fetchStatus.featuredListPaginationTypeStatus.isFetching
  );

  const noFetchHappening = (
    noInitialFetchHappening && noPaginationFetchHappening
  )

  const renderDynamicWorkoutHeader = () => {
    if (workoutFilterName.type == 'favorite') {
      return (
        <>
          <Helmet>
            <title>Workouts {(workoutFilterName.title) ? `in ${workoutFilterName.title}` : ''} | Dashing Dish</title>
          </Helmet>
          <h3 className="header-content">
            {`${capitalize(workoutFilterName.title)} Favorite Workouts`}
          </h3>
        </>
      )
    } else if (workoutFilterName.type == 'filter' && !isFeatured) {
      return renderWorkoutFilterTag();
    } else if (workoutFilterName.type === 'search') {
      return (
        <>
          <span className="filtered-text">{'Results for'}&nbsp;</span>
          {`${searchTerm}`}
        </>
      )
    } else if (!isFeatured) {
      return (
        <h3 className="header-content">
          {'Recent Workouts'}
        </h3>
      )
    } else {
      return null;
    }
  }

  const noWorkoutsMessage = (message) => {
    return (
      <>
        {renderDynamicWorkoutHeader()}
        <div className="pageMessage">
          <div className="empty-content">
            <h2>This category is as empty<br></br>
              as my plate after Thanksgiving.</h2>
            <p>Go to the <a
              href={'/workouts'}
              onClick={(e) => {
                e.preventDefault()
                history.push('/workouts')
              }}
            > Workout index </a> to add some!</p>
          </div>
        </div>
      </>
    )
  }

  // renders carousel based on condition
  const renderCarousel = () => {
    if (directFilterNotApplied) {
      return (
        <TransitionOne>
          <Carousel
            isFetching={true}
            data={featuredItems}
            onClick={(slug, title, featureType) => navFilterActionDispatch(slug, 'featuredCollection', 'collection')} // featureType for Foodtype / Dietary page
          />
        </TransitionOne>
      )
    }
  }
  const renderWorkoutMeta = (item) => {
    return (
      <div className="workout-meta recipe-meta">
        <span className="workout-time">
          {
            (item && item.time && item.time.length >= 0 && item.time) ? item.time.split(' ')[0] : '-'
          }
          {
            (item && item.time && item.time.length >= 0 && item.time) ?
              (toUpper(item.time.split(' ')[1]) ? toUpper(item.time.split(' ')[1]).substring(0, 1) : 'M')
              : ''
          }
        </span>
        <span className="separation">|</span>
        <span className="workout-difficulty">
          {(item.difficulty)}
        </span>
        <span className="card-icons">
          {
            item.favorite ? (
              <ThumbFavIcon />
            ) : null
          }
          {
            item.notes ? (
              <ThumbNoteIcon />
            ) : null
          }
        </span>
      </div>
    )
  }

  const renderTargetArea = () => {
    if (location.pathname === '/workouts' && isEmpty(location.search) && noFetchHappening) {
      return (
        <TransitionTwo>
          <div className="target-wrapper">
            <h2>Target areas</h2>
            <div className="target-area-wrapper">
              {
                workoutBodyParts.map((bodyPart) => (
                  <a className="each-target-area"
                    href={bodyPart.slug}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      history.push(`/workouts?bodyParts=${bodyPart.slug}`)
                    }}
                  >
                    <figure className="targetFigure">
                      <img src={bodyPart.secondary_image} className="targetImg" />
                    </figure>
                    <div className="targetDetail">
                      <h5>{bodyPart.name}</h5>
                      <p>{bodyPart.count} Workouts</p>
                    </div>
                  </a>
                ))
              }
            </div>
          </div>
        </TransitionTwo>
      )
    }
    if (location.pathname === '/workouts' && isEmpty(location.search)) {
      return (
        <TransitionTwo>
          <div className="target-wrapper">
            <h2>Target areas</h2>
            <div className="target-area-wrapper">
              {
                [{ isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }].map(item => {
                  return (
                    <figure className="target-area-placeholder">
                      <Placeholder styles={{ height: 165 }} />
                    </figure>
                  )
                })
              }
            </div>
          </div>
        </TransitionTwo>
      )
    }

  }

  let activeWorkout = workouts.find(workout => (workout && workout.id) === state.activeWorkoutId) || {}

  if (isEmpty(activeWorkout)) {
    activeWorkout = (trendingWorkouts || []).find(workout => workout.id === state.activeWorkoutId) || {};
  }

  let preloaderWorkouts = map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], (item, index) => ({ isLoading: true, id: null }))

  const modifiedPreloadedWorkouts = noPaginationFetchHappening ? preloaderWorkouts : concat(workouts, preloaderWorkouts)
  const actualWorkouts = !noFetchHappening ? modifiedPreloadedWorkouts : workouts;
  return (
    <section className="workouts">
      <Helmet >
        <title>Workouts | Dashing Dish</title>
        <meta name="description" content="Dashingdish Workouts page" />
      </Helmet>
      {renderFeatured()}
      <Filters
        objectType='workout'
        showFilters={showFilters}
        actions={(name, slug, filterName) => navFilterActionDispatch(slug, filterName)}
        options={filterOptions}
        staticOption={
          {
            primaryOption: 'Clear Filters',
            secondaryOption: 'All workouts',
            route: '/workouts'
          }
        }
        history={history}
        isAuthenticated={isAuthenticated}
        dropDownReturnActionText={`See all ${searchCount} Workouts`}
        dropDownReturnAction={(searchText, searchBy) => {
          history.push(`/workouts?search=${searchText}&search_by=${searchBy}`)
        }}

        selectorList={getSelectorList()}
        placeholder='Search workouts'
        searchText={searchTerm}
        onSearchChange={(searchText, searchBy) => searchWorkouts({
          objectType: 'workout',
          searchText: searchText,
          searchBy: searchBy
        })}
        onSearchByChange={(value, searchText) => {
          searchWorkouts({
            objectType: 'workout',
            searchText: searchText,
            searchBy: value
          })
        }}
        onClickSearchClear={() => {
          history.push(`/workouts`)
        }}
        defaultSearchText={searchTerm}
        defaultSearchBy={searchBy}
        searchList={searchList}
        searchStatus={fetchStatus.workoutsSearch}
        searchCount={searchCount}
        showDefaultInput={!isNil(searchBy) || !isNil(searchTerm)}
        isFavorites={isFavorites}
        favoriteOptions={favoriteFilterOptions}
        updateFavouriteBoard={updateFavouriteBoard}
        deleteFavouriteBoard={deleteFavouriteBoard}
        createBoard={createBoard}
      />
      <div className="container">
        <AdComponent />
        {renderCarousel()}
        {renderTargetArea()}
        {
          directFilterNotApplied ? (
            <TransitionThree>
              <div className="hl-list-wrapper-scrollable">
                {
                  (
                    <TrendingChangeAction
                      userDetails={userDetails}
                      objectType="workout"
                      list={take(trendingWorkouts, 4)}
                      history={history}
                      deleteFavourite={deleteFavourite}
                      loginRedirect={loginRedirectHandler}
                      isAuthenticated={isAuthenticated}
                      toggleFavoriteModal={favoriteModalToggleHandler}
                      renderMeta={renderWorkoutMeta}
                      trendingTypeChange={props.trendingTypeChange}
                      trendingType={trendingType}
                      trendingFetch={fetchStatus.trendingFetch}
                    />
                  )
                }
              </div>
            </TransitionThree>
          ) : null
        }

        {
          (noFetchHappening && workouts.length === 0 && !moreWorkoutsAvailable) ? noWorkoutsMessage('No Workouts') : (
            <TransitionFour>
              <ChangeSortOrder
                appliedFilters={appliedFilters}
                setFiltersAndFetch={setFiltersAndFetch}
                history={history}
                objectType="workout"
                title={renderDynamicWorkoutHeader()}
                order={order}
                active={!isFavorites && !isFeatured}
              >

                <InfiniteScroll
                  className="infiniteScroll"
                  initialLoad={false}
                  // threshold={800}
                  loadMore={noFetchHappening ? () => {
                    if (history.location.search || props.match.path === '/workouts/featured/:featuredCollection' || props.match.path === '/workouts/:workoutType/:workoutTaxonomy') {
                      fetchWorkouts({ pagination: true, filterGroup: 'filters' })
                    } else if (props.match.path === '/workouts/favorites/:favoriteBoardSlug' || props.match.path === '/workouts/favorites') {
                      fetchWorkouts({ pagination: true, filterGroup: 'favorites' })
                    } else {
                      fetchWorkouts({ pagination: true, filterGroup: 'unFiltered' })
                    }
                  } : () => { }}
                  hasMore={moreWorkoutsAvailable} // Replace with more workouts available
                  loader={
                    <div className="container">
                      <div className="preLoader-minimal-height">
                        <Loader></Loader>
                      </div>
                    </div>
                  }
                >
                  <ul className='workoutList'>
                    {
                      actualWorkouts.map((item, index) => {
                        // Certain responses are null
                        if (item) {
                          return (
                            <li key={index} className='workout'>
                              <GridCard
                                item={item}
                                coverImage={getWorkoutImages(item, 'wide_640')}
                                thumbnail={getWorkoutImages(item, 'square_400')}
                                feature='workout'
                                history={history}
                                isAuthenticated={isAuthenticated}
                                objectType="workout"
                                userDetails={userDetails}
                                deleteFavourite={deleteFavourite}
                                loginRedirect={loginRedirectHandler}
                                toggleFavoriteModal={favoriteModalToggleHandler}
                                renderMeta={renderWorkoutMeta(item)}
                                showTag={isNewTagStatus({ date: item.publish_date })}
                              />
                            </li>
                          )
                          return null;
                        }
                      })}
                  </ul>

                  <Modalwrapper
                    isOpen={state.showFavoriteModal}
                    minHeight='600px'
                    maxWidth='500px'
                    onRequestClose={() => { favoriteModalToggleHandler({ itemId: null, modalState: false }) }}
                  >
                    <FavoriteModal
                      boards={boards}
                      objectType="workout"
                      activeObject={activeWorkout}
                      createBoard={createBoard}
                      setObjectBoards={setObjectBoards}
                      createFavourite={createFavourite}
                      deleteFavourite={deleteFavourite}
                      clearBoardSelection={clearBoardSelection}
                      deleteFavouriteBoard={deleteFavouriteBoard}
                      updateFavouriteBoard={updateFavouriteBoard}
                      toggleFavoriteModal={favoriteModalToggleHandler}
                      thumbnail={getWorkoutImages(activeWorkout, 'square_400')}
                    />
                  </Modalwrapper>
                  <Modalwrapper
                    isOpen={state.showLoginModal}
                    onRequestClose={loginModalCloseHandler}>
                    <PopupModal
                      objectName={state.clickedWorkoutName}
                      history={history}
                      closeModal={loginModalCloseHandler}
                      objectType="workout"
                    />
                  </Modalwrapper>
                </InfiniteScroll>
              </ChangeSortOrder>
            </TransitionFour>
          )
        }
      </div>
    </section>
  )
}

export default Workouts