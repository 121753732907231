
import isEmpty from 'lodash/isEmpty';

import filter from 'lodash/filter';
import { call, select } from 'redux-saga/effects'
import moment from 'moment';
import axios from 'axios';
import { get, post, patch } from '../../helpers/requests';
import { user } from '../../helpers/api';
import { getToken } from '../selectors';
import pushToSentry from '../../helpers/pushToSentry';

const PAYPAL_LOCK_GRACE_TIME = 7;
const IOS_LOCK_GRACE_TIME = 45;

export default function* lockMembership(userData, tokenAtLogin = '') {

  // Stripe would automatically lock
  if (userData && userData.details && userData.details.membership) {
    if (userData.details.membership.origin === 'paypal') {
      const expires_at = moment.unix(userData && userData.details && userData.details.membership.expires_at);
      const token = yield select(getToken) // For authenticate and login respectively

      try {
        if (moment().isAfter(expires_at.clone().format('YYYY-MM-DD'), 'minute')) {
          // get paypal subscription details
          const updatedSubscriptionDetails = yield call(axios, get(user.getPaypalSubscriptionDetails(), '', token || tokenAtLogin));

          newExpiresAt = moment.unix(updatedSubscriptionDetails.expires_at)
          if (moment().isAfter(newExpiresAt.add(PAYPAL_LOCK_GRACE_TIME, 'days'))) {
            pushToSentry(`Kicked this user on paypal web ${JSON.stringify(userData)}`)
            return true
          } else {
            return false;
          }
        }
        // 3 day limit to wait for payment
      } catch (e) {
        console.warn('Error', e);
        pushToSentry(`Error in lock membership ${JSON.stringify(e)}`)
        // Ideally shouldn't come here
        return true;
      }
    }

    if (userData.details.membership.origin === 'android' || userData.details.membership.origin === 'ios') {
      const expires_at = moment.unix(userData && userData.details && userData.details.membership.expires_at);
      try {
        if (moment().isAfter(expires_at.clone().format('YYYY-MM-DD'), 'minute')) {
          // get paypal subscription details
          if (moment().isAfter(expires_at.add(IOS_LOCK_GRACE_TIME, 'days'))) {
            pushToSentry(`Kicked this user on web for origin mobile ios/android ${JSON.stringify(userData)}`)
            return true
          } else {
            return false;
          }
        }
      } catch (e) {
        pushToSentry(`Error in lock membership for web ${JSON.stringify(e)}`)
        // Ideally shouldn't come here
        return false;
      }
    }

  } else {
    return false;
  }
  // Show toast messages for locked users
}