import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from '../../helpers/toJS';

import {
  createBoard,
  fetchWorkouts,
  setObjectBoards,
  createFavourite,
  clearAllFilters,
  deleteFavourite,
  clearObjectStack,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  appEssentialFetch,
  getFavoritesFilteredData,
  appliedSingleFilterWorkouts,
  search,
  trendingTypeChange,
  setFiltersAndFetch,
} from '../../actions';

import { getUserDetails } from "../../selectors";

import {
  getWorkouts,
  getWorkoutBoards,
  getWorkoutsMessage,
  getWorkoutTypes,
  getWorkoutBodyParts,
  getWorkoutEquipments,
  getMoreWorkoutsAvailable,
  getFetchStatus,
  getWorkoutFilterName,
  appliedWorkoutFilters,
  getWorkoutsSearchList,
  getWorkoutsSearchCount,
  getFeaturedCollections,
  getGlobalError,
  getTrendingWorkouts,
  getTrendingType,
  getOrder,
  getWorkoutSearchText,
} from '../../selectors/workout';

import WorkoutComponent from './components/workouts';

const mapStateToProps = state => ({
  userDetails: getUserDetails(state),
  boards: getWorkoutBoards(state),
  workouts: getWorkouts(state),
  getWorkoutsMessage: getWorkoutsMessage(state),
  workoutTypes: getWorkoutTypes(state),
  workoutBodyParts: getWorkoutBodyParts(state),
  workoutEquipments: getWorkoutEquipments(state),
  moreWorkoutsAvailable: getMoreWorkoutsAvailable(state),
  fetchStatus: getFetchStatus(state),
  workoutFilterName: getWorkoutFilterName(state),
  appliedFilters: appliedWorkoutFilters(state),
  searchList: getWorkoutsSearchList(state),
  searchCount: getWorkoutsSearchCount(state),
  featuredItems: getFeaturedCollections(state),
  globalError: getGlobalError(state),
  trendingWorkouts: getTrendingWorkouts(state),
  trendingType: getTrendingType(state),
  order: getOrder(state),
  searchTerm: getWorkoutSearchText(state),
});

const dispatchActionToProps = dispatch => ({
  createBoard: bindActionCreators(createBoard, dispatch),
  fetchWorkouts: bindActionCreators(fetchWorkouts, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  clearObjectStack: bindActionCreators(clearObjectStack, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  getFavoritesFilteredData: bindActionCreators(getFavoritesFilteredData, dispatch),
  appliedSingleFilterWorkouts: bindActionCreators(appliedSingleFilterWorkouts, dispatch),
  searchWorkouts: bindActionCreators(search, dispatch),
  trendingTypeChange: bindActionCreators(trendingTypeChange, dispatch),
  setFiltersAndFetch: bindActionCreators(setFiltersAndFetch, dispatch)
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(WorkoutComponent));