import React from "react";
import '../styles/common.scss';

const GroceriesListIcon = () => {
  return (
    <svg width="73" height="68" viewBox="0 0 73 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33594 37.0974L10.3094 57.5753C10.8435 62.4956 15.0455 66.2302 20.0486 66.2302H53.415C58.4181 66.2302 62.6201 62.4956 63.1542 57.5753L66.1276 37.0974" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M36.7313 42.2479C34.025 42.2479 31.835 40.071 31.835 37.3807V9.41609C31.835 6.72582 34.025 4.54883 36.7313 4.54883C39.4376 4.54883 41.6276 6.72582 41.6276 9.41609V37.363C41.6276 40.071 39.4376 42.2479 36.7313 42.2479Z" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4.07715 30.531H26.9386" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M46.5244 30.531H69.3859" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M5.71573 23.6101C4.93232 19.2915 8.11939 16.3889 12.2145 15.8402C16.3096 15.1499 20.2089 16.9729 20.7609 21.3092" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" />
      <path d="M5.71573 23.6105C4.93232 19.292 8.11939 16.3893 12.2145 15.8406C16.9684 15.0265 21.473 17.6282 20.743 23.6282" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M56.1387 8.9735C59.3079 3.80536 63.5277 0.885005 66.875 2.01775C71.9672 3.75226 72.8396 14.177 68.1214 23.9824" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M63.8486 10.7612C63.8486 10.7612 67.3384 12.7258 71.1486 13.2568" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M49.6934 17.5398H58.8095V24.9734" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M8.51074 2.58398C12.1785 8.56629 12.6949 14.9557 12.6949 14.9557C12.6949 14.9557 13.9234 9.06186 20.4578 5.30965" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M1.78027 8.354C1.78027 8.354 8.72418 9.46905 12.6947 14.9558" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );

};
export default GroceriesListIcon;