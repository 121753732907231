import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const PlayStoreIcon = (props) => {
  return (
    <>
      <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.613 7.89228L18.8117 7.0752C18.6384 7.00092 18.4264 7.02878 18.282 7.15877L15.556 9.6193C15.4597 9.70287 15.4019 9.83286 15.4019 9.95357C15.4019 10.0836 15.4597 10.2135 15.556 10.2971L18.3205 12.7855C18.465 12.9155 18.6672 12.9434 18.8503 12.8691L20.613 12.0891C21.4606 11.7084 22 10.8914 22 9.98142C22 9.08077 21.4606 8.26369 20.613 7.883V7.89228Z" fill={themeTextRed} />
        <path d="M1.93608 20L12.6471 10.3157C12.7434 10.2321 12.8012 10.1021 12.8012 9.97214C12.8012 9.85144 12.7434 9.72145 12.6471 9.63788L1.83012 0C1.57969 0.0557103 1.33888 0.148561 1.11734 0.278552C0.423818 0.705664 0 1.43918 0 2.2377V17.7437C0 18.5422 0.423818 19.2758 1.11734 19.7029C1.36778 19.8514 1.64711 19.9536 1.93608 20Z" fill={themeTextRed} />
        <path d="M16.5578 6.77811C16.6734 6.67597 16.7312 6.51812 16.7023 6.36956C16.6734 6.21172 16.5771 6.08173 16.4326 6.01673L4.81611 0.714967L13.7548 8.68154C13.9378 8.84867 14.2268 8.84867 14.4098 8.68154L16.5578 6.77811Z" fill={themeTextRed} />
        <path d="M14.4195 11.2163C14.2364 11.0492 13.9475 11.0492 13.7645 11.2163L5.00877 19.1365L16.5 13.9369C16.6445 13.8719 16.7505 13.7419 16.7697 13.584C16.7986 13.4355 16.7408 13.2776 16.6252 13.1755L14.4195 11.2163Z" fill={themeTextRed} />
      </svg>
    </>
  );
};
export default PlayStoreIcon;
