import React from 'react';
import Slider from "react-slick";

const ScrollableDatePicker = ({
  nodesArray,
  nodesPerSlide,
  onSelectionToggle,
  ...props
}) => {
  return (
    <>
      <Slider
        dots={true}
        infinite={true}
        initialSlide={0}
        slidesToShow={nodesPerSlide}
        slidesToScroll={nodesPerSlide}
        autoplay={false}
        pauseOnHover={true}
        arrows={false}
        rtl={false}
        draggable={true}
        lazyLoad="progressive"
        className="calenderCard"
      >
        {nodesArray}
      </Slider >
    </>
  )
}

ScrollableDatePicker.defaultProps = {
  nodesPerSlide: 1,
  nodesArray: [
    <p>{"Nothing to display"}</p>
  ]
}

export default ScrollableDatePicker;
