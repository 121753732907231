import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet"

import axios from 'axios'
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import { Row, Col } from 'react-flexbox-grid';
import TabHeader from './tabHeader'
import TabContent from './tabContent'
import Modalwrapper from '../../../common/modal'
import Button from '../../../common/button'
import KatiesCornerBanner from '../../../images/KatiesCornerBanner.png';
import KatieCornerBanner from '../../../images/KatieCornerBanner.png';
import { faqs as faqsApi } from '../../../../helpers/api';
import pushToSentry from '../../../../helpers/pushToSentry';

import {
  generateAppEssentialPayload,
} from '../../../../helpers/filterConversions';

import {
  TransitionOne, TransitionTwo
} from "../../../common/transitions";


const divingInImages = [
  {
    image: 'https://s3.amazonaws.com/v3-media.dashingdish.com/media/images/000/003/903/medium/2020-09-dashing-dish-0146_copy.jpg?1642168678',
    title: "Katies corner",
  },
  {
    image: 'https://s3.amazonaws.com/v3-media.dashingdish.com/media/images/000/003/553/medium/lightstock_160873_medium_sean.jpg?1621266704', // TODO: Replace image here,
    title: "Live Meeting",
  },
  {
    image: 'http://s3.amazonaws.com/v3-media.dashingdish.com/workout_photos/images/000/000/426/wide_640/0wmAU54g.jpeg?1620328669',
    title: "Live Workouts",
  },
  {
    image: 'https://s3.amazonaws.com/v3-media.dashingdish.com/media/images/000/003/830/medium/Screen_Shot_2021-11-30_at_4.40.16_PM.png?1638308431',
    title: "Live Cooking",
  },
];
const Faq = ({
  faqs,
  appEssentialFetch,
  showToastMessage,
  fetchStatus,
  userDetails,
  addSentryError,
  userPlanOrder,
  isLoginProgress,
  isLoginCompleted,
  history
}) => {
  const [activeCategorySlug, setActiveCategorySlug] = useState(null);
  const [activeOpenedFaqSlug, setActiveOpenedFaqSlug] = useState(null);
  const [showAskQuestion, setShowAskQuestion] = useState(false);

  const [modalContentState, setModalContentState] = useState({
    question: '',
    email: '',
    isPosting: false,
  });

  const katiesCornerFaqs = reverse(faqs.reduce((acc, faq) => {
    if (faq.category.name === 'Katies Corner') {
      acc = acc.concat(faq.faqs);

    }
    return acc;
  }, []));

  useEffect(() => {
    setModalContentState({
      ...modalContentState,
      email: userDetails.details.email
    })
  }, [userDetails.details.email])

  const hanldeOnAsk = () => {
    setModalContentState({
      ...modalContentState,
      isPosting: true
    })

    axios({
      method: 'POST',
      url: faqsApi.postQuestion(),
      data: {
        sender_name: userDetails.details.name,
        sender_email: userDetails.details.email,
        question: modalContentState.question
      },
    }).then(response => {
      setShowAskQuestion(false)
      setModalContentState({
        ...modalContentState,
        isPosting: false,
        question: ''
      })
      showToastMessage(`✅ Question sent successfully !`, 'success', 'askQuestion')
    }).catch(e => {
      const eventId = pushToSentry(e)
      addSentryError({
        error: e.message,
        eventId
      })
      setShowAskQuestion(false)
      setModalContentState({
        ...modalContentState,
        isPosting: false,
        question: ''
      })
      showToastMessage(`❗️ Error in sending question.`, 'error', 'askQuestion')
    });
  }

  useEffect(() => {
    if (katiesCornerFaqs.length > 0) {
      const slug = katiesCornerFaqs[0].category?.slug || katiesCornerFaqs[0].categories[0].slug;
      setActiveCategorySlug(slug)
    }
  }, [katiesCornerFaqs.length])

  const renderDivingIn = () => {

    return map(divingInImages, (element) => (
      <div className="card">
        <a href={element.url} target="_blank">
          <figure className="img-card">
            <img src={element.image} className="img_fluid"></img>
          </figure>
          <div className="title">
            <p>{element.title}</p>
          </div>
        </a>
      </div>
    ))
  };
  const isUnAuthenticatedUser = !isLoginProgress && !isLoginCompleted;
  return (
    <section className="faqs katies-corner-faqs">
      <Helmet>
        <title>Nourish FAQ's | Dashing Dish</title>
        <meta name="description" content="Nourish Faqs page" />
      </Helmet>
      <section className="home-hero katies-corner-hero" style={{
        backgroundImage: `url(${KatiesCornerBanner})`,
      }}>
        <div className="container">
          <Row className="home-hero-wrapper home-hero-heading no-margin-sm">
            <Col xs={12} md={12} lg={8} className="no-padding-sm">
              <div className="home-hero-text">
                <p className='hero-katies-corner-legend'>NOURISH FAQs</p>
                <h1>
                  Connect with &nbsp;<br></br> like minded community
                </h1>
                <p>
                  To provide with the food or other substances necessary for <br />growth, health, and good condition.
                </p>
                <form className="hero-form">
                  <Button
                    btnClass="btn btn-primary btn-md"
                    btnText="Contact Us"
                    onClick={(e) => {
                      history.push('/nourish/ask')
                    }}
                  />
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <div className="container">
        <div className="upgrade-to-wrapper">
          {
            isUnAuthenticatedUser ? (
              <p>
                Create a new account to access the&nbsp;<br></br>
                exclusive content
              </p>
            ) : (
              <p>
                Upgrade your account to access the&nbsp;<br></br>
                exclusive content
              </p>
            )
          }

          <Button
            btnClass="btn btn-primary btn-md"
            btnText={isUnAuthenticatedUser ? "Register Now" : "Upgrade to Nourish"}
            onClick={() => {
              isUnAuthenticatedUser ? history.push("/register") : history.push("/account/membership");
            }}
          />
        </div>
      </div>
      <div className="container diving-in">
        <div className="diving-in-content">{renderDivingIn()}</div>
      </div>
      <div className="container faqs-wrapper-container">
        <div className="faqs-wrapper">
          <h3>Have a question about Nourish?</h3>
          <TransitionOne>
            <div className="faqs-header">
              <TabHeader
                faqs={katiesCornerFaqs}
                activeCategorySlug={activeCategorySlug}
                setActiveCategorySlug={setActiveCategorySlug}
                setShowAskQuestion={setShowAskQuestion}
                fetchStatus={fetchStatus}
              />
            </div>
          </TransitionOne>
          <div className="faqs-body">
            <TabContent
              faqs={katiesCornerFaqs}
              activeCategorySlug={activeCategorySlug}
              activeOpenedFaqSlug={activeOpenedFaqSlug}
              setActiveOpenedFaqSlug={setActiveOpenedFaqSlug}
              fetchStatus={fetchStatus}
            />
          </div>
          {
            showAskQuestion ? (
              <Modalwrapper
                maxWidth='500px'
                minHeight='600px'
                isOpen={showAskQuestion}
                onRequestClose={() => setShowAskQuestion(false)}
              >
                <div className="modalWrapper">
                  <div className="modalHeader AddnoteHead">
                    <h3> Ask Question </h3>
                  </div>

                  <div className="modalBody AddnoteTextarea">
                    <textarea
                      className='modalInput'
                      placeholder='Type your Question here'
                      value={modalContentState.question}
                      onChange={(e) => {
                        setModalContentState({
                          ...modalContentState,
                          question: e.target.value
                        })
                      }}
                    />
                  </div>

                  <div className="modalFooter">
                    <Button
                      btnClass="primary btn-full"
                      btnText="Send"
                      onClick={hanldeOnAsk}
                      isLoading={modalContentState.isPosting}
                      disabled={modalContentState.question.length === 0}
                    />
                    {/* <Button
                      btnClass="secondary btn-full"
                      btnText={`Cancel`}
                      // additionalClass='btn btn-full'
                      onClick={() => {
                        setShowAskQuestion(false)
                      }}
                    // showGhost
                    /> */}
                    <Button
                      btnClass="secondary btn-full btn-bp-sm"
                      btnText="Cancel"
                      onClick={() => {
                        setShowAskQuestion(false)
                      }}
                    />
                  </div>
                </div>
              </Modalwrapper>
            ) : null
          }
        </div>
      </div>
    </section>
  )
}

export default Faq;