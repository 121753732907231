import Fraction from 'fraction.js';
import capitalize from 'lodash/capitalize';

export const getIngredientQuantityType = (ingredientQuantity) => {
    const regExp = /[a-zA-Z]/g;
    if (ingredientQuantity.includes('-')) {
        return 'range';
    } else if (ingredientQuantity.includes('/')) {
        return 'fraction'
    } else if (ingredientQuantity.includes('.')) {
        return 'decimal'
    } else if (regExp.test(ingredientQuantity)) {
        return 'text'
    } else {
        return 'number'
    }
}

export const getQuantityAdded = (firstQuantity, secondQuantity) => {

    const firstQuantityType = getIngredientQuantityType(firstQuantity);
    const secondQuantityType = getIngredientQuantityType(secondQuantity);


    if (firstQuantityType === 'range' && secondQuantityType === 'range') {
        const firstValue =
            new Fraction(firstQuantity.split("-")[0]) + new Fraction(secondQuantity.split("-")[0]);
        const secondValue =
            new Fraction(firstQuantity.split("-")[1]) + new Fraction(secondQuantity.split("-")[1]);

        return `${new Fraction(firstValue).toFraction(true)}-${new Fraction(secondValue).toFraction(true)}`
    } else if (firstQuantityType !== 'range' && secondQuantityType === 'range') {

        const firstValue =
            new Fraction(secondQuantity.split("-")[0]) + new Fraction(firstQuantity);
        const secondValue =
            new Fraction(secondQuantity.split("-")[1]) + new Fraction(firstQuantity);

        return `${new Fraction(firstValue).toFraction(true)}-${new Fraction(secondValue).toFraction(true)}`
    } else if (firstQuantityType === 'range' && secondQuantityType !== 'range') {
        const firstValue =
            new Fraction(firstQuantity.split("-")[0]) + new Fraction(secondQuantity);
        const secondValue =
            new Fraction(firstQuantity.split("-")[1]) + new Fraction(secondQuantity);

        return `${new Fraction(firstValue).toFraction(true)}-${new Fraction(secondValue).toFraction(true)}`
    } else {
        return (new Fraction(new Fraction(firstQuantity) + new Fraction(secondQuantity))).toFraction(true);
    }
}

export const getQuantityMultiplied = (quantity, factor) => {
    const quantityType = getIngredientQuantityType(quantity);

    if (quantityType === 'range') {
        const firstValue =
            new Fraction(quantity.split("-")[0]) / factor;
        const secondValue =
            new Fraction(quantity.split("-")[1]) / factor;

        return `${new Fraction(firstValue).toFraction(true)}-${new Fraction(secondValue).toFraction(true)}`
    } else if (quantityType === 'text') {
        return quantity;
    } else if (quantityType === 'number') {
        return quantity * factor;
    } else {
        return (new Fraction(new Fraction(quantity) * factor)).toFraction(true);
    }
}


// Checks if an unit is mixed decimal
export const isUnitMixedDecimal = ingredientQuantity => {
    const splitIngredient = ingredientQuantity.split(' ');
    // Check first character of 2nd item is equal to a number
    return (splitIngredient.length >= 2 && !isNaN(parseInt(splitIngredient[1][0])));
}

export const isIngredientQuantityPlural = (ingredientQuantity) => {
    try {
        const ingredientQuantityType = getIngredientQuantityType(ingredientQuantity);

        if (ingredientQuantityType === 'range') {
            return true;
        } else if (ingredientQuantityType === 'fraction' || ingredientQuantityType === 'number' || ingredientQuantityType === 'decimal') {
            const quantity = new Fraction(ingredientQuantity);
            return eval(quantity.toFraction(true)) > 1;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }

}


export const getIngredientDetail = ingredient => {
    try {

        let ingredientUnit;

        let ingredientQuantity = ingredient.quantity;
        const ingredientName = ingredient.ingredient?.display_singular_name;
        const ingredientSpecialUnit = ingredient.special_unit;
        const ingredientProcess = ingredient.process?.name;


        try {
            const fractionalQuantity = new Fraction(ingredientQuantity)
            ingredientQuantity = fractionalQuantity.toFraction(true);
        } catch (e) {
            console.log('not able to parse quantity', ingredientQuantity)
        }
        if (isIngredientQuantityPlural(ingredientQuantity)) {
            ingredientUnit = ingredient.ingredient_unit?.display_plural_name || '';
        } else {
            ingredientUnit = ingredient.ingredient_unit?.display_singular_name || '';
        }

        const unitTitle = `${ingredientSpecialUnit}${ingredientUnit}`.length > 0 ? ` ${ingredientSpecialUnit ? `${ingredientSpecialUnit} ` : ''}${ingredientUnit}` : `${ingredientSpecialUnit ? `${ingredientSpecialUnit} ` : ''}${ingredientUnit}`;


        const ingredientIndicator = ingredient.indicator ? ` ${ingredient.indicator}` : '';

        return {
            ingredientQuantity,
            ingredientName,
            ingredientUnit,
            ingredientProcess,
            ingredientQuantityWithUnit: `${ingredientQuantity}${unitTitle}`,
            ingredientNameWithIndicator: capitalize(`${ingredientProcess ? `${ingredientProcess} ` : ''}${ingredientName}${ingredientIndicator}`)
        }
    } catch (e) {
        console.log(e);
    }


}


export const normalizeAmounts = (amounts, conversionRule, ingredientName) => {
    // Loop through each amount
    // If there is a conversion map, then convert to the base unit
    // If there is no matching map, then leave it as it is
    let normalizedAmount = null;
    let unavailableUnits = [];
    let normalizedUnits = [];
    let normalizedUnmergebleGlistItems = [];
    let groupedGroceryListItem = {};

    if (conversionRule) {
        const baseUnit = conversionRule.base_unit_singular;
        const unitMaps = conversionRule.maps;

        const availableUnits = amounts.filter(amount => {
            if (unitMaps) {
                return unitMaps.findIndex(unitMap => unitMap.unit_singular === amount.unit || amount.unit === baseUnit) !== -1;
            } else {
                return false;
            }

        });

        unavailableUnits = amounts.filter(amount => {
            if (unitMaps) {
                return unitMaps.findIndex(unitMap => unitMap.unit_singular === amount.unit || amount.unit === baseUnit) === -1;
            } else {
                return true;
            }

        });

        const baseUnitConvertedAvailableUnits = availableUnits
            .map(availableUnit => {
                if (availableUnit.unit === baseUnit) {
                    return availableUnit.quantity;
                } else if (unitMaps.findIndex(unitMap => unitMap.unit_singular === availableUnit.unit) !== -1) {
                    const unitMap = unitMaps.find(unitMap => unitMap.unit_singular === availableUnit.unit);

                    return new Fraction(getQuantityMultiplied(availableUnit.quantity, 1 / parseFloat(unitMap.factor))).toFraction(true)
                }
            });

        normalizedAmount = getAmountsAdded(baseUnitConvertedAvailableUnits);

        if (availableUnits.length > 0) {
            normalizedUnits = availableUnits
                .reduce((accumulator, unit) => {
                    accumulator['categoryId'].push(unit.categoryId);
                    accumulator['id'].push(unit.id);
                    accumulator['recipe'].push(unit.recipe);

                    return accumulator;
                }, {
                    categoryId: [],
                    id: [],
                    recipe: []
                })


            let unit = conversionRule.base_unit_singular;
            if (normalizedAmount > 1) {
                unit = conversionRule.base_unit_plural
            }

            normalizedAmount = `${normalizedAmount} ${unit} ${ingredientName}`;

            groupedGroceryListItem = {
                name: normalizedAmount,
                item: normalizedAmount,
                ...normalizedUnits
            }
        }

        normalizedUnmergebleGlistItems = unavailableUnits
            .reduce((accumulator, glistItem) => {
                let unit = glistItem.unit;
                let unitPlural = glistItem.unitPlural;
                let quantity = glistItem.quantity;

                const name = `${quantity} ${quantity > 1 ? unitPlural : unit} ${ingredientName}`;

                accumulator.push({
                    name,
                    item: name,
                    categoryId: [glistItem.categoryId],
                    id: [glistItem.id],
                    recipe: [glistItem.recipe],
                })

                return accumulator;
            }, [])


    } else {
        normalizedUnmergebleGlistItems = amounts
            .reduce((accumulator, glistItem) => {
                let unit = glistItem.unit;
                let unitPlural = glistItem.unitPlural;
                let quantity = glistItem.quantity;

                const name = `${quantity} ${quantity > 1 ? unitPlural : unit} ${ingredientName}`;

                accumulator.push({
                    name,
                    item: name,
                    categoryId: [glistItem.categoryId],
                    id: [glistItem.id],
                    recipe: [glistItem.recipe],
                })

                return accumulator;
            }, [])
    }


    return {
        groupedGroceryListItem,
        normalizedUnmergebleGlistItems
    }
}


export const getAmountsAdded = (amounts) => {
    const resultAmount = amounts.reduce((accumulator, amount) => {
        accumulator = getQuantityAdded(accumulator, amount);
        return accumulator
    }, '0');

    return resultAmount
}