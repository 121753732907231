import { combineReducers } from "redux-immutable";

import loggedInUser from "./loggedInUserDetails";
import essentials from "./essentialData";
import recipe from "./recipeView";
import workout from "./workoutView";
import register from "./register";
import fetchStatus from "./fetchStatus";
// import groceryList from './groceryList';
import setCurrentServing from "./setCurrentServing";
import userSpecificEssential from "./userSpecificEssential";
import objectSpecificEssential from "./objectSpecificEssential";
import navigationStack from "./NavigationStack";
import toasts from "./toasts";
import meteredDetails from "./meteredDetails";
import sentryError from "./sentryError";
import settings from "./settings";
import unifiedRegister from "./unifiedRegister";

// TODO: Change essentials to appSpecificEssentials
// TODO: Add userSpeficic
// TODO: Change recipe to activeRecipe

export default combineReducers({
  // groceryList,
  loggedInUser,
  register,
  essentials,
  recipe,
  workout,
  fetchStatus,
  setCurrentServing,
  userSpecificEssential,
  objectSpecificEssential,
  navigationStack,
  toasts,
  meteredDetails,
  sentryError,
  settings,
  unifiedRegister,
});
