import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const UpArrow = (props) => {
  return (
    <>
      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.0006 5.53554L5.53613 2.00001L9.07167 5.53554" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default UpArrow;
