import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil';

import moment from 'moment'
import ReactSwipe from 'react-swipe'
import { Link, scroller } from 'react-scroll'

import LeftArrow from '../../../images/LeftArrow';
import RightArrow from '../../../images/RightArrow';
import getDatesForMonthAhead from '../../../../helpers/getDatesForMonthAhead';
import Button from '../../../common/button'

const ChooseDate = ({
  handleSubmitChooseDate,
  date,
  updateDate,
  aheadDays = 14
}) => {
  // console.log("date : ",date);
  const calendarDates = getDatesForMonthAhead(
    'current',
    moment().format('YYYY-MM-DD'),
    moment().format('YYYY-MM-DD'),
    aheadDays
  )

  const currentMonth = moment().startOf('day').format('MMM');
  const [activeDate, changeActiveDate] = useState(date);
  const activeMonth = moment(activeDate).format('MMMM');

  const [localMonth, updateLocalMonth] = useState(activeMonth);
  const [showCalendar, setshowCalendar] = useState(true);
  const [state, setState] = useState({
    showCalendar: true
  });

  const startSlide = localMonth === moment().startOf('day').format('MMMM') ? 0 : 1
  useEffect(() => {
    reactSwipeEl.slide(startSlide, 200)
  }, [])

  // On clicking a day
  const onClickDay = (date, forceSwipe, dayDisabled) => {
    if (!dayDisabled) {
      updateDate(date);
    } else if (dayDisabled && forceSwipe) {
      const currentMonth = moment().startOf('month').format('MMMM');
      const nextMonth = moment().add({ month: 1 }).startOf('month').format('MMMM');

      if (currentMonth === localMonth) {
        updateLocalMonth(nextMonth);
        changeActiveDate(date);
        reactSwipeEl.next();
      } else {
        updateLocalMonth(currentMonth);
        changeActiveDate(date);
        reactSwipeEl.prev();
      }

    }
  }

  const renderWeekTitle = () => (
    <div className="week-title">
      <span>Mo</span>
      <span>Tu</span>
      <span>We</span>
      <span>Th</span>
      <span>Fr</span>
      <span>Sa</span>
      <span>Su</span>
    </div>
  )

  const renderCalenderWeek = (week, renderMonthType) => {

    // check if the day is disabled
    const isDayDisabled = day => {
      return (!day.isInRange || renderMonthType !== day.monthType);
    }

    const isActiveDayInCurrentMonth = day => {
      return (localMonth === currentMonth && renderMonthType === 'current') || (localMonth === nextMonth && renderMonthType === 'next');
    }

    const currentMonth = moment().startOf('month').format('MMMM');
    const nextMonth = moment().add({ month: 1 }).startOf('month').format('MMMM');

    // Specifies whether a day is active or not
    const dayActiveClass = day => {
      return (activeDate === day.date && isActiveDayInCurrentMonth(day) && !isDayDisabled(day)) ? 'active' : null;
    }

    // Specifies whether a day is disabled or not
    const dayDisabledClass = day => isDayDisabled(day) ? 'day-disabled' : '';

    // spy date
    const spyDate = day => !isDayDisabled(day) ? day.date : null;

    const forceDaySwipe = day => day.isInRange;
    const forceDaySwipeClass = day => forceDaySwipe(day) ? 'can-swipe' : 'cannot-swipe';
    return map(week, day => (
      <div
        key={day.date}
        className={`day-title ${dayActiveClass(day)} ${dayDisabledClass(day)} ${forceDaySwipeClass(day)}`}
      >
        <a
          onClick={() => onClickDay(day.date, forceDaySwipe(day), isDayDisabled(day))}
        >
          <span className="day">{day.date.split('-')[2]}</span>
        </a>
      </div>
    ));
  }

  const renderCalendarDates = (calendarDates, renderMonthType) => {
    const chunkedDates = chunk(calendarDates, 7);
    return map(chunkedDates, week => (
      <div className="each-week">
        {renderCalenderWeek(week, renderMonthType)}
      </div>
    ));
  }

  const prevMonthLastDay = moment().endOf('month').format('YYYY-MM-DD');
  const nextMonthFirstDay = moment().add({ month: 1 }).startOf('month').format('YYYY-MM-DD');

  const nextMonth = moment().add({ month: 1 }).startOf('month').format('MMMM');

  return (
    <div className="modalWrapper">
      <div className="calendar">
        <div className="calendar-head">
          <div
            className="month-title"
            onClick={() => setState(prevState => ({ ...prevState, showCalendar: !state.showCalendar }))}
          >
            <h5>{localMonth}</h5>
          </div>
          <div className="month-pagination">
            <div className={`previous-icon ${localMonth === currentMonth ? 'disabled' : ''}`}
              onClick={() => {
                const toChangeMonth = (localMonth === currentMonth) ? nextMonth : currentMonth;
                updateLocalMonth(toChangeMonth)
                changeActiveDate(prevMonthLastDay)
                reactSwipeEl.prev()
              }}>
              <LeftArrow />
            </div>
            <div
              className={`next-icon ${localMonth === nextMonth ? 'disabled' : ''}`}
              onClick={() => {
                const toChangeMonth = (localMonth === currentMonth) ? nextMonth : currentMonth;
                updateLocalMonth(toChangeMonth)
                changeActiveDate(nextMonthFirstDay)
                reactSwipeEl.next()
              }}>
              <RightArrow />
            </div>
          </div>
        </div>
        {
          state.showCalendar &&
          <div className="calenderbody">
            {renderWeekTitle()}
            <ReactSwipe
              className="carousel"
              swipeOptions={{ continuous: false }}
              ref={el => (reactSwipeEl = el)}
              startSlide={startSlide}
            >
              <div>
                {renderCalendarDates(filter(calendarDates, date => date.inCurrentMonth), 'current')}
              </div>
              <div>
                {renderCalendarDates(filter(calendarDates, date => date.inNextMonth), 'next')}
              </div>
            </ReactSwipe>

          </div>
        }
      </div>
    </div>
  )
}

export default ChooseDate