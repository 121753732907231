import { all, delay } from "redux-saga/effects";

export default function* listRetrier(requests, title) {
  for (var i = 1; i <= 5; i++) {
    console.log("retrying list for: ", title, " : ", i);
    try {
      const response = yield all(requests);
      return response;
    } catch (e) {
      if (e.response && !e.response.status) {
        if (i < 5) {
          yield delay(2000);
        }
      } else {
        return [e.response];
      }
    }
  }
  return new Array(requests.length).fill(false);
}
