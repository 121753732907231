import React from "react";
import ReactMarkdown from "react-markdown";

import "../../styles/common.scss";

const RecipeStory = (props) => {
  return (
    <p className="story">
      <ReactMarkdown source={props.story} linkTarget="_blank" />
    </p>
  );
};

export default RecipeStory;
