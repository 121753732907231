import React, { useState } from "react";

import CustomInput from "../../../common/customInput";
const CreateAccount = ({
  email,
  password,
  name,
  billingAddress,
  setBillingAddress,
  setEmail,
  setPassword,
  setName,
  nameFocusedOnce,
  emailFocusedOnce,
  passwordFocusedOnce,
  setNameOnceFocusState,
  billingAddressFocusedOnce,
  setBillingAddressFocusedOnce,
  setEmailOnceFocusState,
  setPasswordOnceFocusState,
  checkName,
  checkEmail,
  checkPassword,
  errors,
  isGifted,
  serverValidateEmailRequest,
  initiatedEmail
}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  return (
    <div>
      <h1>{isGifted ? "Claim your gift" : "Create your account"}</h1>
      <CustomInput
        type="name"
        name="name"
        onChange={(event) => {
          setName(event.target.value);
          checkName();
        }}
        placeholder="Type here"
        value={name}
        className="error"
        errorMessage={nameFocusedOnce ? errors.name : null}
        onBlur={(e) => {
          setNameOnceFocusState(true);
          checkName();
        }}
        disabled={false}
        label="Name"
      />
      <CustomInput
        type="email"
        name="email"
        onChange={(event) => {
          setEmail(event.target.value);
          checkEmail();
        }}
        placeholder="Type here"
        value={email}
        // value={giftedRegistration.isGifted ? giftedRegistration.toEmail : email}
        className="error"
        errorMessage={emailFocusedOnce ? errors.email : null}
        onBlur={() => {
          setEmailOnceFocusState(true);
          serverValidateEmailRequest();
          checkEmail();
        }}
        label="Email"
        disabled={isGifted || initiatedEmail}
      />
      <div className="password-field">
        <CustomInput
          type={passwordVisible ? "text" : "password"}
          onChange={(event) => {
            setPassword(event.target.value);
            checkPassword();
          }}
          placeholder="Type here"
          value={password}
          name="password"
          errorMessage={passwordFocusedOnce ? errors.password : null}
          onBlur={() => {
            setPasswordOnceFocusState(true);
            checkPassword();
          }}
          isLastElementOfForm={true}
          label="Password"
        />
        <div
          className="show-password"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          <span>{passwordVisible ? "Hide" : "Show"}</span>
        </div>
        {/* <CustomInput
          type="Billing Address"
          name="Billing Address"
          onChange={(event) => {
            setBillingAddress(event.target.value);
            checkBillingAddress();
          }}
          placeholder="Type here"
          value={billingAddress}
          className="error"
          errorMessage={billingAddressFocusedOnce ? errors.name : null}
          onBlur={(e) => {
            setBillingAddressFocusedOnce(true);
            checkBillingAddress();
          }}
          disabled={false}
          label="Billing Address"
        /> */}
      </div>
    </div>
  );
};

export default CreateAccount;
