import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';


const SelectorIcon = (props) => {
  return (
    < >
      <svg width="11" height="17" viewBox="0 0 11 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.07118 11.5355L5.53564 15.0711L2.00011 11.5355" stroke={themeTextRed} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.00011 5.53551L5.53564 1.99998L9.07118 5.53551" stroke={themeTextRed} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </>
  );
};
export default SelectorIcon;
