import React, { useState, useEffect } from "react";

import map from "lodash/map";
import isNil from "lodash/isNil";
import dropRight from "lodash/dropRight";
import split from "lodash/split";
import isArray from "lodash/isArray";
import take from "lodash/take";
import concat from "lodash/concat";
import reduce from "lodash/reduce";
import filter from "lodash/filter";
import { Helmet } from "react-helmet"
import capitalize from 'lodash/capitalize';

import LinkButton from '../../common/linkButton'

import FavoriteCard from "./favoriteCard";
import {
  generateAppEssentialPayload,
} from '../../../helpers/filterConversions';
import CloseIcon from "../../images/closeIcon";
import LeftArrow from "../../images/LeftArrow";
import {
  TransitionTwo,
} from "../../common/transitions";

const Favorite = ({
  modal,
  match,
  history,
  createBoard,
  blogBoards,
  recipeBoards,
  workoutBoards,
  appEssentialFetch,
  updateFavouriteBoard,
  deleteFavouriteBoard,
  userEssentialFetchStatus,
  objectType,
  ...props
}) => {
  const [state, setState] = useState({
    favoriteBoards: [],
    editStatus: false,
    newBoard: false,
    // objectType: null
  });

  const getFavoriteBoards = () => {
    if (userEssentialFetchStatus.isFetching || !userEssentialFetchStatus.isFetched) {
      return new Array(10).fill({ isLoading: true })
    } else {
      return state.favoriteBoards
    }

    // return [{
    //   id: "new",
    //   thumbnail: null,
    //   title: "",
    //   total_count: 0,
    //   newBoardCard: true
    // },
    // {
    //   id: "new",
    //   thumbnail: null,
    //   title: "",
    //   total_count: 0,
    //   newBoardCard: true
    // }];

  }

  useEffect(() => {
    if (!modal) {
      const appEssentialPayload = generateAppEssentialPayload({
        objectType: objectType,
        queryString: history.location.search,
      });
      appEssentialFetch(appEssentialPayload);
    }

  }, [])


  useEffect(() => {
    let objectType = split(match.url, "/")[1];
    let callAppEssentialFetch = false;
    switch (objectType) {
      case "recipes":
        if (isArray(recipeBoards) && recipeBoards.length > 0) {
          setState({ ...state, objectType: 'recipe', favoriteBoards: recipeBoards });
        } else {
          callAppEssentialFetch = true
        }
        break;
      case "workouts":
        if (isArray(workoutBoards) && workoutBoards.length > 0) {
          setState({ ...state, objectType: 'workout', favoriteBoards: workoutBoards });
        } else {
          callAppEssentialFetch = true
        }
        break;
      case "blog":
        if (isArray(blogBoards) && blogBoards.length > 0) {
          setState({ ...state, objectType: 'blog', favoriteBoards: blogBoards });
        } else {
          callAppEssentialFetch = true
        }
        break;
    }
  }, [match.url, recipeBoards, blogBoards, workoutBoards]);



  const getThumbnails = () => {
    return take(concat(reduce(state.favoriteBoards, (accumulater, board) => {
      if ((!isNil(board.thumbnail) && isArray(board.thumbnail) && board.thumbnail.length > 0)) {
        return accumulater.concat(board.thumbnail[0]);
      }
      return accumulater
    }, []), [null, null, null, null]), 4);
  }

  const onFavoriteBoardClick = (item) => {
    if (!state.editStatus) {
      if (!isNil(item.slug)) {
        if (state.objectType === 'blog') {
          history.push(`/${state.objectType}/favorites/${item.slug}`);
        } else {
          history.push(`/${state.objectType}s/favorites/${item.slug}`);
        }

      } else {
        if (state.objectType === 'blog') {
          history.push(`/${state.objectType}/favorites`);
        } else {
          history.push(`/${state.objectType}s/favorites`);
        }
      }
    }
  }

  const onToggleEditState = () => {
    setState({ ...state, editStatus: !state.editStatus });
  }

  const onNewCategoryButtonClick = () => {
    if (!state.newBoard) {
      let boards = [
        state.favoriteBoards[0],
        {
          id: "new",
          thumbnail: null,
          title: "",
          total_count: 0,
          newBoardCard: true
        },
        ...filter(state.favoriteBoards, (board) => board.id !== null)
      ]
      setState({ ...state, newBoard: true, favoriteBoards: boards })
    }
  }

  const onCreateNewBoard = (item) => {
    createBoard(item.title, state.objectType);
    setState({ ...state, newBoard: false })
  }

  const onCancelNewCategory = () => {
    let boards = filter(state.favoriteBoards, board => board.id !== "new");
    setState({ ...state, newBoard: false, favoriteBoards: boards })
  }

  const onBoardTitleChange = (item, newValue) => {
    let boards = state.favoriteBoards.reduce((accumulater, board) => {
      if (board.id === item.id) {
        board.title = newValue;
        return accumulater.concat(board);
      }

      return accumulater.concat(board)
    }, []);
    setState({ ...state, favoriteBoards: boards })
  }

  const onDeleteBoard = (item) => {
    deleteFavouriteBoard({
      boardId: item.id,
      objectType: state.objectType
    })
  }

  const onUpdateFavouriteBoard = (item, newTitle) => {
    updateFavouriteBoard({ boardId: item.id, title: newTitle, objectType: state.objectType });
  }

  const onBackClick = () => {
    if (state.objectType === "blog") {
      history.push(`/blog/preview`);
    } else if (state.objectType === 'video') {
      history.push(`/videos`);
    } else {
      if (state.objectType === 'blog') {
        history.push(`/${state.objectType}`);
      } else {
        history.push(`/${state.objectType}s`);
      }
    }
  }


  return (
    <>
      <Helmet>
        <title>{`${state.objectType ? capitalize(state.objectType) : ''} Favorites | Dashing Dish`}</title>
        <meta name="description" content="Dashingdish Favorites page" />
      </Helmet>
      <section className="favorite-board">
        <div className="container">
          <div className="actions">
            <div className="left-group">
              <LinkButton
                renderValue={() => <>{state.objectType ? `Back to all ${state.objectType}s` : null}</>}
                onClick={(e) => onBackClick()}
                additionalClass="breadcrum-back"
              />
              <span className="breadcrum-icon">
                <LeftArrow></LeftArrow>
              </span>
              <span
                className="breadcrum-state">{state.objectType === 'blog' ? 'Bookmarks' : 'Favorites'}</span>
            </div>
            <div className="right-group">
              <LinkButton
                renderValue={() => <>{!state.editStatus ? 'Edit Categories' : 'Done Editing'}</>}
                onClick={(e) => onToggleEditState()}
              />
              <LinkButton
                renderValue={() => <>New Category</>}
                onClick={(e) => onNewCategoryButtonClick()}
              />
            </div>
          </div>
          <TransitionTwo>
          <div className="favoriteBoards">
            {
              map(getFavoriteBoards(), (board, index) => {
                if (index === 0) {
                  return (
                    <FavoriteCard
                      key={index}
                      onFavoriteBoardClick={onFavoriteBoardClick}
                      defaultCard={true}
                      board={board}
                      thumbnails={getThumbnails()}
                      objectType={split(match.url, "/")[1]}
                    />
                  )
                } else {
                  return (
                    <FavoriteCard
                      key={index}
                      defaultCard={false}
                      onUpdateFavouriteBoard={onUpdateFavouriteBoard}
                      onDeleteBoard={onDeleteBoard}
                      onCreateNewBoard={onCreateNewBoard}
                      onBoardTitleChange={onBoardTitleChange}
                      onCancelNewCategory={onCancelNewCategory}
                      newBoard={state.newBoard}
                      onFavoriteBoardClick={onFavoriteBoardClick}
                      board={board}
                      editStatus={state.editStatus}
                      boardKey={board.id ? board.id : 1}
                      thumbnail={board.thumbnail ? board.thumbnail[board.thumbnail.length - 1] : null}
                      objectType={split(match.url, "/")[1]}
                    />
                  )
                }
              })
            }
          </div>
          </TransitionTwo>
        </div>
      </section>
    </>
  )
}

export default Favorite;