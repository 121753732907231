import React from 'react'
import GhostCover from '../common/ghostCover'
import PlusIcon from '../images/PlusIcon'
import '../styles/common.scss';

const PlusButton = (props) => {
  return (
    <GhostCover {...props}>
      <PlusIcon></PlusIcon>
    </GhostCover>
  )
}

export default PlusButton;