import React from 'react'
import { Row, Col } from "react-flexbox-grid";

import axios from 'axios';
import { personalizedBlogs } from '../../helpers/api';

import KatiesCornerBlogCenter from '../images/KatiesCornerDescriptionBlogCenter.png';
import KatiesCornerCooking from '../images/katiesCornerDescriptionCooking.png';
import KatiesCornerCookingSecond from '../images/katiesCornerDescriptionCookingSecond.png';
import KatiesCornerWorkout from '../images/KatiesCornerDescriptionWorkout.png';
import KatiesCornerDescriptionMeeting from '../images/KatiesCornerDescriptionMeeting.png';

import "../styles/common.scss";
import Button from '../common/button';

const getPersonalizedBlogs = async (params) => {
    const response = await axios.get(personalizedBlogs.get(params.pageParam));
    return response.data.personalized_blogs;
}

const KatiesCorner = (props) => {
    const location = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }

    let actionText = null;
    let actionLink = null;

    if (!props.userPlanOrder) {
        actionText = 'Signup';
        actionLink = '/pricing'
    } else if (props.userPlanOrder !== 3) {
        actionText = 'Upgrade to Nourish';
        actionLink = '/account/membership'
    } else if (props.userPlanOrder === 3) {
        actionText = 'Read More';
        actionLink = '/nourish/about'
    }

    const renderMission = () => {
        return (
            <Row className='katie-mission'>
                <Col xs={12} md={12} lg={3} className='mission-header'>
                    <h4>The Mission</h4>
                </Col>
                <Col xs={12} md={12} lg={9} className='mission-content'>
                    <p>
                        God wants us to be healthy in our bodies, and free in our minds so we can fulfill our purpose!&nbsp; Our goal with Nourish is a side-by-side journey to equip you with support, knowledge, and the tools you need to be healthy and FREE!

                    </p>
                </Col>
            </Row>
        )
    }

    const renderDescription = () => {
        return (
            <div className='mission-description'>
                <Row className='mission-description-row'>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} lg={4}>
                                <h5>Katie’s Corner</h5>
                                <p>
                                    Get personal with Katie in sessions such as: “In the Word” which includes Q&A, devotionals and prayer, Katies favorite things,“Real Life Topics” such as marriage, parenting, and real life sit downs with guests, and fun topics such as “Cosmetic Corner”!
                                </p>
                            </Col>
                            <Col xs={12} xl={8} className='katies-corner-blog-img'>
                                <div className='group-container'>
                                    <figure className='first'>
                                        <img src={KatiesCornerBlogCenter} />
                                    </figure>
                                    <figure className='second'>
                                        <img />
                                    </figure>
                                    <figure className='third'>
                                        <img />
                                    </figure>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mission-description-row'>
                    <Col xs={12}>
                        <Row className='mission-description-row-group'>
                            <Col xs={12} lg={8} className='katies-corner-cooking-img'>
                                <div className='group-container'>
                                    <figure className='first'>
                                        <img src={KatiesCornerCooking} />
                                    </figure>
                                    <figure className='second'>
                                        <img src={KatiesCornerCookingSecond} />
                                    </figure>
                                    <figure className='third'>
                                        <img />
                                    </figure>
                                </div>

                            </Col>
                            <Col xs={12} lg={4} className='katies-corner-middle-description'>
                                <h5>Live Zoom Meetings & Cooking Classes</h5>
                                <p>Monthly zoom meetings and cooking classes will be held with Katie and our certified coaches and trainers. We will join together to discuss nutrition, hormone health, emotional eating, disordered eating, body image, the importance of mindset, meal prep ideas, and more!</p>
                            </Col>

                        </Row>
                    </Col>
                </Row>
                <Row className='mission-description-row'>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12} lg={4}>
                                <h5>
                                    Live Workouts
                                </h5>
                                <p>
                                    Weekly live workouts with our certified personal trainer are available to keep you accountable and on track! The workouts can be done live with the group, or replayed any time. Workouts include modifications and progressions for every fitness level at home with minimal equipment. There’s something for everyone to keep you moving!
                                </p>
                            </Col>
                            <Col xs={12} lg={8} className='katies-corner-workout-img'>
                                <img src={KatiesCornerWorkout} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className='mission-description-row'>
                    <Col xs={12}>
                        <Row className='mission-description-row-group'>
                            <Col xs={12} lg={8} className='katies-corner-cooking-img'>
                                <div className='group-container'>
                                    <figure className='first'>
                                        <img src={KatiesCornerDescriptionMeeting} />
                                    </figure>
                                </div>

                            </Col>
                            <Col xs={12} lg={4} className='katies-corner-middle-description exclude'>
                                <h5>Connect with Like Minded Community</h5>
                                <p>We all have well intended plans for reaching a goal, but without the support and accountability of others, we often stop short. In Nourish, we focus on coaching as well as community and accountability to establish healthy habits that produce results and lasting success!</p>
                            </Col>

                        </Row>
                    </Col>
                </Row>
            </div>

        )
    }
    return (
        <div className='katies-corner-group-section'>
            <div className='container'>
                {renderMission()}
                {renderDescription()}
                <div className='floating-center-btn-wrapper'>
                    <Button
                        btnClass="btn btn-primary btn-md"
                        btnText={actionText}
                        onClick={(e) => {
                            navigateTo(actionLink)
                        }}
                    />
                </div>

            </div>
        </div>



    )
}

export default KatiesCorner