import React, { useEffect, useState } from "react";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import CloseIcon from "../../images/closeIcon";
import moment from 'moment';

const TopBanner = ({ history, topBanner, setTopBanner, activeMeetings, isAuthenticated }) => {
  useEffect(() => {
    if (activeMeetings.length > 0) {
      adjustHeader();
    }
  }, [activeMeetings.length])
  const adjustHeader = () => {
    const appBanner = localStorage.getItem("appBanner") || topBanner;
    localStorage.removeItem("couponBanner");
    if (appBanner === "true") {
      header = document.querySelector("header");
      header.style.position = "sticky";
      header.style.top = "0px";
      const content = document.getElementById("content");
      content.style.paddingTop = "0px";
    } else {
      header = document.querySelector("header");
      header.style.position = "sticky";
      header.style.top = "0px";
      const content = document.getElementById("content");
      content.style.paddingTop = "0px";
    }
  };

  // Get the next upcoming meeting
  const upcomingMeeting = activeMeetings && activeMeetings.length > 0
    ? activeMeetings[0]
    : null;

  const formatMeetingTime = (startTime) => {
    const start = moment(startTime).format('h:mm A');
    return `${start}`;
  };

  return (
    <>
      {topBanner && !isAuthenticated && (
        <section className={cx("top-banner", { active: topBanner })}>
          <div className="container">
            <div className="banner-content">

              {
                !isAuthenticated && (
                  <>
                    <a
                      href={"/pricing"}
                      onClick={(e) => {
                        e.preventDefault();
                        setTopBanner(!topBanner);
                        adjustHeader();
                        history.push("/pricing");
                      }}
                    >
                      <p>
                        NEW YEAR SALE | 30% OFF ON YEARLY MEMBERSHIP
                      </p>
                    </a>
                    <span style={{
                      position: 'relative',
                      left: '20px',
                      top: '-1px'
                    }}>
                      <CloseIcon
                        onClick={() => {
                          adjustHeader();
                          setTopBanner(!topBanner);
                        }}
                      ></CloseIcon>
                    </span>
                  </>
                )}

            </div>
          </div>
        </section>
      )}

      {upcomingMeeting && isAuthenticated && (
        <section className={cx("top-banner", { active: topBanner })}>
          <div className="container">
            <div className="banner-content">

              {
                isAuthenticated && (
                  <>
                    <a
                      target="_blank"
                      href={upcomingMeeting.event_link}
                      onClick={(e) => {
                        setTopBanner(!topBanner);
                        adjustHeader();
                      }}
                    >

                      <span className="meeting-title">Live Meeting with Katie&nbsp;</span>
                      <span className="meeting-time">
                        <span className="meeting-date">

                          on {moment(upcomingMeeting.event_timing).format('MMM D, hh:mm a')}
                        </span>
                      </span>
                    </a>
                  </>
                )}

            </div>
          </div>
        </section>
      )}

    </>
  );
};

export default withRouter(TopBanner);
