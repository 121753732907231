import React, { useState, useEffect } from 'react'

import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import moment from 'moment'
import isNull from 'lodash/isNull'
import capitalize from 'lodash/capitalize'
import reduce from 'lodash/reduce'

import { useMealPlanInteriorHook } from '../../../../helpers/useMealPlanInteriorHook'
import Radiobox from "../../../common/radiobox";

import Favorite from '../../../common/favoriteHeart'
import Button from '../../../common/button'
import Selector from '../../../common/selector'
import CheckBox from '../../../common/checkbox'
import LeftArrow from '../../../images/LeftArrow'
import RightArrow from '../../../images/RightArrow'
import CalenderIcon from '../../../images/CalenderIcon'

import ChooseDate from './chooseDate'
import Spinner from 'react-spinner-material';
import DownArrow from '../../../images/DownArrow'
import MealPlanGridItem from '../mealPlanGridItem'

import { isNewTagStatus } from "../../../../helpers/getNewTagStatus";
import { themeTextRed } from '../../../../helpers/theme'

const ref = React.createRef()

const PremadeMealPlanIndex = ({
  slug,
  moveToCalendar,
  item,
  days,
  createFavourite,
  deleteFavourite,
  moveToRecipes,
  moveToList,
  handleSubmitChooseDate,
  daysContainingRecipes,
  moveToAddReplaceConfirmation,
  chosenCalendarDate,
  moveToIndex,
  progressStatus,
  onRequestClose,
  history
}) => {
  const [state, updateState] = useMealPlanInteriorHook({
    slug,
    token: localStorage.getItem('token'),
  });

  const [favorite, setFavorite] = useState(false);
  const [addIngredients, setAddIngredients] = useState(true);
  const [servingSize, setServingSize] = useState(4);

  const [showCalendar, toggleCalendar] = useState(false);
  const [enableActions, setEnableActions] = useState(false);
  const [calendarDate, setCalendarDate] = useState(chosenCalendarDate || moment().startOf('day').format('YYYY-MM-DD'));

  const handleOnAddMealplan = () => {
    const dateRangeStart = moment(calendarDate);
    const dateRangeEnd = moment(calendarDate).add({ days: 28 });

    const doesTheRangeHasPlans = findIndex(daysContainingRecipes, dateWithRecipe => moment(dateWithRecipe).isBetween(dateRangeStart, dateRangeEnd, null, '[]')) !== -1;

    if (doesTheRangeHasPlans) {
      moveToAddReplaceConfirmation(calendarDate, addIngredients, parseInt(servingSize) || 4);
    } else {
      handleSubmitChooseDate(calendarDate, false, addIngredients, parseInt(servingSize) || 4);
      // onRequestClose();
    }
  }
  const handleClick = (e) => {
    // if it is outside and also outside of calendar group
    if (!isNull(ref) && !isNull(ref.current) && !ref.current.contains(e.target)) {
      toggleCalendar(false);
    }
  }

  useEffect(() => document.addEventListener('mousedown', handleClick, false), []);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    }
  }, [])
  let actualFavorite = (state.mealPlan && state.mealPlan.favorite) ? (state.mealPlan.favorite) : false
  useEffect(() => {
    setFavorite(favorite)
  }, [actualFavorite])

  useEffect(() => {
    if (!state.isFetching) {
      setEnableActions(true)
    } else {
      setEnableActions(false)
    }
  }, [state.isFetching])

  useEffect(() => {
    if (state.isFetched) {
      moveToIndex(state.mealPlan)
    }
  }, [state.isFetched])

  const recipeCount = reduce(state.mealPlanDetails, (accumulator, day) => {
    accumulator += day.meals.length
    return accumulator;
  }, 0)

  if (state.isError) {
    return (
      <div className="modalWrapper index-wrapper">
        <div className="content-wrapper">
          <div className="pageMessage" style={{ margin: 'auto' }}>
            {/* <h2>{message}</h2> */}
            <div className="empty-content">
              <h2>This Meal plan is as empty<br></br>
                as my plate after Thanksgiving.</h2>
              <p>Go to the <a
                href={'/calendar'}
                onClick={(e) => {
                  e.preventDefault()
                  history.push('/calendar')
                }}
              > Calendar index </a> to add some!</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="modalWrapper index-wrapper">
      <div className="header">
        <div className="header-text">
          <div className="header-title" onClick={moveToList}>
            <span>
              Meal Plans
            </span>
          </div>
          <LeftArrow />
          <div className="current-dish">
            <span>
              {item.name || state.mealPlan.name}
            </span>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        {
          (state.isFetching)
            ? (
              <div style={{
                width: "100%",
                height: "25rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}>
                <Spinner
                  size={75}
                  spinnerColor={themeTextRed}
                  spinnerWidth={4}
                  visible={true}
                />
              </div>
            )
            : (
              <>
                <div className="left-wrapper">
                  <MealPlanGridItem
                    plan={state.mealPlan}
                    showTag={isNewTagStatus({ date: state.mealPlan.publish_date, isISOFormat: false })}
                  />
                </div>
                <div className="right-wrapper">
                  <div className="plan-header">
                    <div className="current-plan">
                      <h4>
                        {item.name || state.mealPlan.name}
                      </h4>
                    </div>
                    <div className="favoriteIcon">
                      <Favorite
                        favorite={favorite}
                        objectType="meal_plan"
                        onClick={(value) => {
                          if (value) {
                            setFavorite(false)
                            deleteFavourite(
                              item.id,
                              'meal_plan'
                            )
                          } else {
                            setFavorite(true)
                            createFavourite(
                              item.id,
                              'meal_plan'
                            )
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="created-date">
                    Created on {moment(item.created_at).format('YYYY-MM-DD') || capitalize(state.mealPlan.description)}
                  </div>
                  <div className="mealplan-index-content-wrapper">
                    <div className="description">
                      <div className="description-info">
                        {capitalize(item.description) || capitalize(state.mealPlan.description)}
                      </div>
                    </div>
                    <div className="row-data">
                      <div className="title">
                        Serves
                      </div>
                      <div className="value">
                        <Selector
                          selectorList={
                            map(new Array(30), (item, index) => {
                              return { title: index + 1, value: index + 1 }
                            })
                          }
                          onSelectValue={value => setServingSize(value)}
                          value={servingSize}
                        />
                      </div>
                    </div>

                    <div
                      className={`row-data recipes ${!enableActions ? 'disabled' : ''}`}
                      onClick={() => {
                        moveToRecipes(state.mealPlanDetails)
                      }}
                    >
                      <div className={`title`}>
                        Recipes included <span>({recipeCount > 0 ? recipeCount : null})</span>
                      </div>
                      <div className="value">
                        <DownArrow></DownArrow>
                      </div>
                    </div>

                    {/* <div className="row-data">
                      <div className="title">
                        Duration
                      </div>
                      <div className="value">
                        {item ? item.days : state.mealPlan.days}
                      </div>
                    </div> */}

                    {/* <div className="row-data">
                      <div className="title">
                        Ingredients
                  </div>
                      <div className="value">
                        {state.ingredientsCount || '-'}
                      </div>
                    </div> */}

                    {/* <div className="row-data">
                      <div className="title">
                        Estimated grocery bill
                  </div>
                      <div className="value">
                        $&nbsp;{state.mealPlan ? state.mealPlan.budget : ''}
                      </div>
                    </div> */}

                    <div className="row-data" ref={ref}>
                      <div className="title">
                        Start this meal plan on
                      </div>
                      <div className="value calendar-picker">
                        <div className="date-group" onClick={() => toggleCalendar(!showCalendar)}>
                          {calendarDate}
                          <CalenderIcon />
                        </div>
                        {
                          showCalendar ? (
                            <div className="calendar-popover">
                              <ChooseDate
                                handleSubmitChooseDate={handleSubmitChooseDate}
                                date={calendarDate}
                                updateDate={(date) => {
                                  setCalendarDate(date);
                                  toggleCalendar(false);
                                }}
                              />
                            </div>
                          ) : null
                        }
                      </div>
                    </div>
                    <div className="button-Footer">
                      <Button
                        btnClass="primary btn-full"
                        btnText="Add this meal plan to my calendar"
                        onClick={handleOnAddMealplan}
                      />
                    </div>
                  </div>
                </div>
              </>
            )
        }
      </div>
    </div >
  )
}

export default PremadeMealPlanIndex