import createReducer from '../helpers/createReducers';

import { fromJS } from 'immutable';

const initialState = fromJS({
  items: [],
  type: '',
  topIndex: -1
});

export default createReducer(initialState, {
  CLEAR_NAVIGATION_STACK: (state, action) => {
    if(state.getIn(['type']) == action.objectType){
      //TODO: just clear the stack
      state = state.setIn(['topIndex'], -1);
      let newItems = state.getIn(['items']).clear();
      state = state.setIn(['items'], newItems);
      state = state.setIn(['type'], '');
    }
    return state
  },

  PUSH_OBJECT_TO_NAVIGATION_STACK: (state, action) => {
    //check for previous object type and upcomming object type
    if(state.getIn(['type']) === action.payload.objectType){
      //if object type match just push the object in stack
      let index = state.getIn(['topIndex'])+1;
      state = state.setIn(['topIndex'], index);
      let newItems = state.getIn(['items']).push(action.payload.objectData);
      state = state.setIn(['items'], newItems);
    }else{
      //if not: clear the stack and push the new object with type
      state = state.setIn(['topIndex'], 0);
      let newItems = state.getIn(['items']).clear();
      state = state.setIn(['items'], newItems.push(action.payload.objectData));
      state = state.setIn(['type'], action.payload.objectType);
    }
    return state
  },

  POP_OBJECT_FROM_NAVIGATION_STACK_SUCCESS: (state, action) => {
    let index = state.getIn(['topIndex']);
    if(index > -1){
      state = state.setIn(['topIndex'], (index -1));
      let newItems = state.getIn(['items']).pop();
      state = state.setIn(['items'], newItems);
    }else {
      state = state.setIn(['topIndex'], -1);
      let newItems = state.getIn(['items']).clear();
      state = state.setIn(['items'], newItems);
      state = state.setIn(['type'], '');
    }
    return state
  },

  CREATE_FAVOURITE_TO_OBJECT_SUCCESS: (state, action) => {
    if(action.payload.objectType == 'recipe'){
      let topIndex = state.getIn(['topIndex']);
      state = state.setIn(['items', topIndex, 'activeRecipe', 'favorite'], true);
    }else if(action.payload.objectType == 'workout') {
      let topIndex = state.getIn(['topIndex']);
      state = state.setIn(['items', topIndex, 'activeWorkout', 'favorite'], true);
    }
    return state
  },

  DELETE_FAVOURITE_SUCCESS: (state, action) => {
    if(action.payload.objectType == 'recipe'){
      let topIndex = state.getIn(['topIndex']);
      state = state.setIn(['items', topIndex, 'activeRecipe', 'favorite'], false);
    }else if(action.payload.objectType == 'workout') {
      let topIndex = state.getIn(['topIndex']);
      state = state.setIn(['items', topIndex, 'activeWorkout', 'favorite'], false);
    }
    return state
  },

})