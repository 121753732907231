import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import chunk from 'lodash/chunk'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'

import cx from 'classnames'

import moment from 'moment'
import ReactSwipe from 'react-swipe'
import { Link, scroller } from 'react-scroll'

import getDatesForMonth from '../../../../helpers/getDatesForMonth';
import RightButton from '../../../common/rightButton'
import LeftButton from '../../../common/leftButton';
import GhostCover from '../../../common/ghostCover'
import LinkButton from '../../../common/linkButton'

const currentMonth = moment().startOf('month').format('MMMM');
const nextMonth = moment().add({ month: 1 }).startOf('month').format('MMMM');
const prevMonth = moment().subtract({ month: 1 }).startOf('month').format('MMMM');

let reactSwipeEl;
let debouncer;

const PlanCalendar = ({
  calendarSettings,
  calendarDates,
  changeActiveDay,
  daysContainingRecipes,
  daysContainingWorkouts,
  yOffset
}) => {
  const [localMonth, updateLocalMonth] = useState(currentMonth);
  const [spyActive, changeSpyActive] = useState(true);
  const [showCalendar, setshowCalendar] = useState(true);
  const [state, setState] = useState({
    showCalendar: true
  })
  const [recentSwipe, setRecentSwipe] = useState('none');

  const weekToSlide = findIndex(chunk(calendarDates, 14), week => findIndex(week, day => day.date === calendarSettings.activeDate) !== -1)

  useEffect(() => {
    setTimeout(() => {
      changeSpyActive(false);

      const weekToSlide = findIndex(chunk(calendarDates, 14), week => findIndex(week, day => day.date === calendarSettings.activeDate) !== -1)
      if (reactSwipeEl) {
        reactSwipeEl.slide(weekToSlide, 0)
      }
      setTimeout(() => {
        changeSpyActive(true);
      }, 500)

    }, 500)

  }, [])
  const renderWeekTitle = () => (
    <div className="week-title">
      <span>Mo</span>
      <span>Tu</span>
      <span>We</span>
      <span>Th</span>
      <span>Fr</span>
      <span>Sa</span>
      <span>Su</span>
    </div>
  )

  // On clicking a day
  const onClickDay = (date) => {
    changeActiveDay(date);
    // Set this to false to avoid continuos movement between dates
    changeSpyActive(false);
    // release spy after 500 ms
    setTimeout(() => {
      changeSpyActive(true)
    }, 500)
  }

  const handleChangeInSpyByScroll = (scrolledDay, renderMonthType) => {
    const weekToSlide = findIndex(chunk(calendarDates, 14), week => findIndex(week, day => day.date === scrolledDay.date) !== -1)
    if (reactSwipeEl) {
      reactSwipeEl.slide(weekToSlide, 0)
    }

    changeActiveDay(scrolledDay.date)
  }

  const renderCalenderWeek = (week, renderMonthType) => {

    // check if the day is disabled
    const isDayDisabled = day => {
      return (!day.isInRange);
    }

    const currentMonth = moment().startOf('month').format('MMMM');
    const nextMonth = moment().add({ month: 1 }).startOf('month').format('MMMM');
    const prevMonth = moment().subtract({ month: 1 }).startOf('month').format('MMMM');

    // Specifies whether a day is active or not
    const dayActiveClass = day => (calendarSettings.activeDate === day.date) ? 'active' : null;

    // Specifies whether a day is disabled or not
    const dayDisabledClass = day => isDayDisabled(day) ? 'disabled' : '';

    // spy date
    const spyDate = day => !isDayDisabled(day) ? day.date : null;

    // mentions whether a recipe is present on a day
    const isRecipePresent = day => findIndex(daysContainingRecipes, recipeDay => day.date === recipeDay) !== -1 ? 'recipe-tag' : 'no-recipe-tag';
    const isWorkoutPresent = day => findIndex(daysContainingWorkouts, workoutDay => day.date === workoutDay) !== -1 ? 'workout-tag' : 'no-workout-tag';

    const isCurrentDay = day => moment().startOf('day').format('YYYY-MM-DD') === day.date ? 'current-day' : null;
    return map(week, day => (
      <div
        key={day.date}
        className={`day-title ${dayActiveClass(day)} ${dayDisabledClass(day)} ${isRecipePresent(day)} ${isWorkoutPresent(day)} ${isCurrentDay(day)}`}
      >
        <Link
          to={spyDate(day)}
          spy={spyActive}
          smooth
          offset={yOffset}
          duration={500}
          onClick={() => onClickDay(day.date)}
          onSetActive={() => handleChangeInSpyByScroll(day, renderMonthType)}
        >
          <span className="day">{day.date.split('-')[2]}</span>
          <div className="dot-group">
            {
              isRecipePresent(day) === 'recipe-tag' &&
              <span className="recipe-dot"></span>
            }
            {
              isWorkoutPresent(day) === 'workout-tag' &&
              <span className="workout-dot"></span>
            }
          </div>
        </Link>
      </div>
    ));
  }

  const renderCalendarDates = (calendarDates, renderMonthType) => {
    const chunkedDates = chunk(calendarDates, 14);
    return map(chunkedDates, week => (
      <div className="each-week">
        {renderCalenderWeek(week, renderMonthType)}
      </div>
    ));
  }

  const isDayActive = checkDate => {
    const indexOfDay = findIndex(calendarDates, day => day.date === checkDate);
    return indexOfDay !== -1 && calendarDates[indexOfDay].isInRange;
  }


  const prevMonthLastDay = moment().subtract({ month: 1 }).endOf('month').format('YYYY-MM-DD');
  const currentMonthFirstDay = moment().startOf('month').format('YYYY-MM-DD');
  const currentMonthLastDay = moment().endOf('month').format('YYYY-MM-DD');
  const nextMonthFirstDay = moment().add({ month: 1 }).startOf('month').format('YYYY-MM-DD');

  const isPrevMonthDisabled = weekToSlide === 0 || !isDayActive(chunk(calendarDates, 14)[weekToSlide - 1][0].date);
  const isNextMonthDisabled = weekToSlide === chunk(calendarDates, 14).length - 1 || !isDayActive(chunk(calendarDates, 14)[weekToSlide + 1][0].date);

  const isActiveNotCurrentDay = (calendarSettings.activeDate) !== moment().startOf('day').format('YYYY-MM-DD');
  return (
    <div className="calendar">
      <div className="calendar-head">
        <div className="month-title">
          <h5
            className={
              cx({
                dirty: isActiveNotCurrentDay
              })
            }
            onClick={() => {
              const moveTo = moment().startOf('day').format('YYYY-MM-DD');
              scroller.scrollTo(moveTo, {
                duration: 500,
                offset: yOffset,
                smooth: true,
              })
            }}
          >
            {moment(calendarSettings.activeDate).format('MMMM')}
          </h5>
        </div>
        <div className="month-pagination">
          <div className={`previous-icon ${isPrevMonthDisabled ? 'disabled' : ''}`}
            onClick={() => {
              const moveTo = chunk(calendarDates, 14)[weekToSlide - 1][0].date;
              changeSpyActive(false);
              changeActiveDay(moveTo);
              scroller.scrollTo(moveTo, {
                duration: 100,
                offset: yOffset,
                smooth: true,
              })
              setTimeout(() => {
                changeSpyActive(true)
              }, 500);
              setRecentSwipe('none');

            }}>
            <LeftButton />
          </div>
          <div
            className={`next-icon ${isNextMonthDisabled ? 'disabled' : ''}`}
            onClick={() => {
              const moveTo = chunk(calendarDates, 14)[weekToSlide + 1][0].date;
              if (isDayActive(moveTo)) {

              }
              changeSpyActive(false);
              changeActiveDay(moveTo);
              scroller.scrollTo(moveTo, {
                duration: 100,
                offset: yOffset,
                smooth: true,
              })
              setTimeout(() => {
                changeSpyActive(true)
              }, 500);
              setRecentSwipe('none');

            }}>
            <RightButton />
          </div>
        </div>
      </div>
      {
        state.showCalendar &&
        <div className="calenderbody">
          {renderWeekTitle()}
          <ReactSwipe
            className="carousel"
            swipeOptions={{
              continuous: false,
              disableScroll: false,
              // stopPropagation: true,
              startSlide: weekToSlide,
              swiping: (value) => {
                setRecentSwipe(value > 0 ? 'positive' : 'negative');
              },
              transitionEnd: (weekToSlide) => {
                // Only if recently swiped transition effect  
                if (recentSwipe !== 'none') {

                  let dx;
                  if (recentSwipe === 'positive') {
                    dx = 1;
                  } else if (recentSwipe === 'negative') {
                    dx = -1;
                  }
                  const moveTo = chunk(calendarDates, 14)[weekToSlide + dx][0].date;
                  if (isDayActive(moveTo)) {
                    changeActiveDay(moveTo);
                    changeSpyActive(false);

                    scroller.scrollTo(moveTo, {
                      duration: 100,
                      offset: yOffset,
                      smooth: true,
                    })
                    setTimeout(() => {
                      changeSpyActive(true)
                    }, 500);
                  }

                  setRecentSwipe('none');
                }
              },
            }}
            ref={el => (reactSwipeEl = el)}
          >
            {
              map(chunk(calendarDates, 14), eachBatch => (
                <div>
                  {renderCalendarDates(chunk(eachBatch, 7)[0])}
                  {renderCalendarDates(chunk(eachBatch, 7)[1])}
                </div>
              ))
            }
          </ReactSwipe>
        </div>
      }
    </div>
  )
}


export default PlanCalendar