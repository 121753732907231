import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import img from '../../images/katieAvatar.png'
import { set } from 'immutable';
import {
  Tooltip,
} from 'react-tippy';

const RecipeKatieTip = (props) => {
  const [state, setState] = useState({
    isPopoverOpen: false,
  });

  if (props.katiesTip === '' || props.KatiesTip === null) {
    return (
      null
    )
  }
  return (
    <>
      <Tooltip
        className="katieAvartar"
        multiple={true}
        open={state.isPopoverOpen}
        arrow={true}
        trigger="click"
        theme="light"
        interactive={true}
        position="top-end"
        onRequestClose={() => setState({ isPopoverOpen: false })}
        html={(
          <div className="katieTip"
            onClick={() => setState({ isPopoverOpen: false })}
          >
            <ReactMarkdown
              source={props.katiesTip}
              linkTarget="_blank"
            />
          </div>
        )}
      >
        <div className="katieTips"
          onClick={() => setState({ isPopoverOpen: !state.isPopoverOpen })}
        >
          <img src={img} />
        </div>
      </Tooltip>
    </>
  )
}

export default RecipeKatieTip;