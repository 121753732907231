import React, { useState, useEffect } from "react";
import axios from "axios";
import isNil from "lodash/isNil";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { baseUrl, referral } from "../../../helpers/api";
import Loader from "../../images/loader";
import { saveReferralCode, addSentryError } from "../../../actions";
import toJS from "../../../helpers/toJS";
import pushToSentry from "../../../helpers/pushToSentry";

const Referral = ({ saveReferralCode, ...props }) => {
  // rCodeStatus = referralCodeStatus
  const [rCodeStatus, setRCodeStatus] = useState({
    isValid: false,
    isValidating: false,
    isValidated: false,
    isError: false,
  });
  useEffect(() => {
    setRCodeStatus({ ...rCodeStatus, isValidating: true });
    if (!isNil(props.match.params.referral_code)) {
      axios({
        method: "POST",
        url: referral.validateReferral(),
        data: {
          referral_code: props.match.params.referral_code,
        },
      })
        .then((response) => {
          if (response.data.valid) {
            setRCodeStatus({
              ...rCodeStatus,
              isValid: true,
              isValidating: false,
              isValidated: true,
            });
          } else {
            setRCodeStatus({
              ...rCodeStatus,
              isValid: false,
              isValidating: false,
              isError: "Something went wrong!",
              isValidated: true,
            });
          }
        })
        .catch((e) => {
          const eventId = pushToSentry(e);
          props.addSentryError({
            error: e.message,
            eventId,
          });
          setRCodeStatus({
            ...rCodeStatus,
            isValid: false,
            isValidating: false,
            isError: "Something went wrong!",
            isValidated: true,
          });
        });
    }
  }, [props.match.params.referral_code]);

  useEffect(() => {
    if (rCodeStatus.isValid && rCodeStatus.isValidated) {
      saveReferralCode(props.match.params.referral_code);
      props.history.push("pricing");
    }
  }, [rCodeStatus.isValid, rCodeStatus.isValidated]);

  return !rCodeStatus.isValid && rCodeStatus.isValidated ? (
    <div>This Referral Code is not valid!</div>
  ) : (
    <div className="container">
      <div className="preLoader">
        <Loader></Loader>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({});
const dispatchActionToProps = (dispatch) => ({
  saveReferralCode: bindActionCreators(saveReferralCode, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Referral));
