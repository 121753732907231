import React from 'react'

const HalfStarIcon = () => (
  <i style={{ fontStyle: 'normal' }}>
    <small className="halfStar">★</small>
    <small className="halfStarstroke">★</small>
  </i >
)

export default HalfStarIcon
