import React, { useState, useEffect } from 'react'
import isNull from 'lodash/isNull'
import '../styles/common.scss';
import DottedAction from '../groceryList/components/DottedAction';
import {
  Tooltip,
} from 'react-tippy';

const ref = React.createRef()
const DottedIcon = ({ popoverDisableReposition, ...props }) => {
  const [state, setState] = useState({
    handleClick: false
  })

  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    }
  }, [])

  const setDropdownActive = () => {
    // this will be done by button itself
  }

  const handleClick = (e) => {
    // if it is outside and also outside of 3 dots
    if (!isNull(ref) && !isNull(ref.current) && !ref.current.contains(e.target)) {
      // props.setDisplayDropdown(props.displayDropdown);
    }
  }
  const threeDots = (
    <div className="DottedIcon">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="4" viewBox="0 0 24 4">
        <path id="dotsIcon.svg" className="themeFill" d="M1138,344a2,2,0,1,1-2,2A2,2,0,0,1,1138,344Zm-10,0a2,2,0,1,1-2,2A2,2,0,0,1,1128,344Zm-10,0a2,2,0,1,1-2,2A2,2,0,0,1,1118,344Z" transform="translate(-1116 -344)" />
      </svg>
    </div>
  );

  const getActionContent = () => {
    return (
      <div className={"actionPopover"}>
        <ul>
          {
            props.options.map(option => (
              <li onClick={(e) => {
                option.action();
                props.setDisplayDropdown(!props.displayDropdown)
              }}>
                {option.label}
              </li>
            ))
          }
        </ul>
      </div>
    )
  }
  let content = (<div>options not loded</div>)
  useEffect(() => {
    content = (
      <DottedAction
        options={props.options}
        displayDropdown={props.displayDropdown}
        setDisplayDropdown={props.setDisplayDropdown}
        ref={ref}
      />
    )
  }, [props.options.length])

  return (
    <>
      {
        <Tooltip
          multiple={true}
          open={props.displayDropdown}
          arrow={true}
          trigger="click"
          theme="light"
          interactive={true}
          position="top-end"
          onRequestClose={() => props.setDisplayDropdown(false)}
          html={(
            <div className="actionPopover">
              <ul>
                {
                  props.options.map(option => (
                    <li onClick={(e) => {
                      option.action();
                      props.setDisplayDropdown(!props.displayDropdown)
                    }}>
                      {option.label}
                    </li>
                  ))
                }
              </ul>
            </div>
          )}>
          {
            props.displayIcon ? <props.displayIcon /> : threeDots
          }
        </Tooltip>
      }
    </>
  )
}

DottedIcon.defaultProps = {
  popover: false,
  popoverDisableReposition: true,
}

export default DottedIcon; 