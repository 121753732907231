
const categories = [
    { id: 11, category: 'Specialty' },
    { id: 12, category: 'Miscellaneous' },
    { id: 8, category: 'Frozen' },
    { id: 14, category: 'Nuts & Nut Butters' },
    { id: 3, category: 'Condiments, Dressings, & Oils' },
    { id: 10, category: 'Seasonings' },
    { id: 9, category: 'Baking & Cooking' },
    { id: 5, category: 'Boxed, Jarred, & Canned' },
    { id: 2, category: 'Bread & Baked Goods' },
    { id: 4, category: 'Meat & Seafood' },
    { id: 6, category: 'Grains & Pasta' },
    { id: 13, category: 'Exclude' },
    { id: 7, category: 'Dairy & eggs' },
    { id: 15, category: 'Uncategorized' },
    { id: 1, category: 'Produce' },
]

export const getCategoryId = category => {
    const cat = categories.find(c => c.category === category);

    if (cat) {
        return cat.id;
    }

    return null
}