import React from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import { Row, Col } from 'react-flexbox-grid';
import { withRouter } from "react-router-dom";
import Modal from '../common/modal';
import Button from '../common/button'
import CustomInput from '../common/customInput'
import { getUserDetails } from "../../selectors";
import toJS from '../../helpers/toJS';
import { mealPlan } from '../../helpers/api';
import FreezerMealPlan from '../images/freezerMealPlan.png'
import { shareUrl, user } from '../../helpers/api';
import { bindActionCreators } from 'redux';
import { setInitiateRegisterEmail } from '../../actions';

class AdComponent extends React.Component {
    constructor(props) {
        super(props);

        const completeEmailCapture = localStorage.getItem('completeEmailCapture')
        this.state = {
            showModal: false,
            email: '',
            isEmailServerValidated: false,
            errorMessage: null
        }

        // this.onSubmit = this.onSubmit.bind(this);
        // this.ads = [
        // '6135488652',
        // '8391839100'
        // ]

        // this.adNumber = Math.floor((Math.random() * 2));
    }
    componentWillReceiveProps(nextProps, prevProps) {

        // if ((!prevProps.userDetails || !prevProps.userDetails.authError) && nextProps.userDetails.authError) {
        //     setTimeout(() => {
        //         (window.adsbygoogle = window.adsbygoogle || []).push({});
        //     }, 500)

        // }

    }
    setEmailServerValidation(status) {
        this.setState({
            ...this.state,
            isEmailServerValidated: status
        })
    }

    setError(message) {
        this.setState({
            ...this.state,
            errorMessage: message
        })
    }
    componentDidMount() {
        // if (this.props.userDetails.authError) {

        //     setTimeout(() => {
        //         (window.adsbygoogle = window.adsbygoogle || []).push({});
        //     }, 500)

        // }
    }

    onSubmit() {


        axios({
            url: mealPlan.captureEmail(),
            method: 'POST',
            data: {
                contact: {
                    email: this.state.email,
                    name: this.state.email
                }

            }
        }).then(data => {
            console.log('captured...')
            localStorage.setItem('completeEmailCapture', true);
            this.setState({
                ...this.state,
                showModal: false
            })
        })
    }

    setErrors(error) {
        this.setState({
            ...this.state,
            errorMessage: error
        })
    }
    serverValidateEmailRequest() {
        axios({
            method: "POST",
            url: user.verifyEmail(),
            data: {
                email: this.state.email || "",
            },
        })
            .then((response) => {
                if (response.data.valid) {
                    // console.log('valid...');
                    this.setEmailServerValidation(true);
                    this.setErrors(null);
                    this.props.setInitiateRegisterEmail(this.state.email);
                    localStorage.setItem('completeEmailCapture', true);
                    this.props.history.push(`/pricing`)
                }
            })
            .catch((e) => {
                console.log(e);
                this.setEmailServerValidation(false);
                this.setErrors("This email is already taken");
            });
    };


    render() {
        if (!this.props.userDetails || (this.props.userDetails && !this.props.userDetails.authError)) {
            return null
        } else if (this.props.userDetails && this.props.userDetails.authError) {
            return (
                <>
                    {
                        this.state.showModal ? (
                            <Modal
                                isOpen={true}
                                onRequestClose={() => {
                                    localStorage.setItem('completeEmailCapture', true);
                                    this.setState({
                                        showModal: false
                                    })
                                }}
                                minHeight='450px'
                                minWidth='640px'
                                maxWidth="700px"
                                maxHeight='450px'
                                showClose="true"
                            >
                                <div className="modalWrapper">
                                    {/* <div class="ribbon">
                                        <span>-50%</span>
                                    </div> */}
                                    <div className="modalBody grocery-body">
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <img style={{ 'width': '100%' }} src={FreezerMealPlan} />
                                            </Col>
                                            <Col xs={12} md={8}>
                                                <p className="sale-heading" style={{ 'textAlign': 'left', fontSize: '1.5rem' }}><b>CLAIM YOUR MEALPLAN</b></p>
                                                <ul>
                                                    <li><b>Singup</b> using your email</li>
                                                    <li><b>Freezer Meal Plan</b> for free through email.</li>
                                                </ul>
                                                <div className="input-group" style={{ 'marginTop': '25px' }}>
                                                    <CustomInput
                                                        type="text"
                                                        onChange={event => {
                                                            this.setState({
                                                                ...this.state,
                                                                email: event.target.value,
                                                                errorMessage: null
                                                            })
                                                        }}
                                                        value={this.state.email}
                                                        placeholder="email@email.com"
                                                        label="Email"
                                                        errorMessage={this.state.errorMessage}
                                                    />
                                                    <Button
                                                        disabled={false}
                                                        btnClass="primary btn-full"
                                                        btnText="Signup"
                                                        onClick={() => {
                                                            this.serverValidateEmailRequest();
                                                            window.location = '/pricing'
                                                        }}
                                                    />
                                                    <div className='secondary-popup'>
                                                        <Button
                                                            disabled={false}
                                                            btnClass="secondary btn-full"
                                                            btnText="Cancel"
                                                            onClick={() => {
                                                                this.setState({
                                                                    showModal: false
                                                                })
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>


                                        </Row>

                                    </div>
                                </div>
                            </Modal>
                        ) : null
                    }
                </>
            );
        }

        return null

    }
}

const mapStateToProps = state => ({
    userDetails: getUserDetails(state),

});

const dispatchActionToProps = dispatch => ({
    setInitiateRegisterEmail: bindActionCreators(setInitiateRegisterEmail, dispatch)
});

export default connect(
    mapStateToProps,
    dispatchActionToProps
)(toJS(withRouter(AdComponent)));