import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {
    useInfiniteQuery,
} from 'react-query';
import InfiniteScroll from 'react-infinite-scroller';
import PersonalizedVideoCard from './cards/PersonalizedVideoCard';
import queryString from 'query-string';
import Loader from "../images/loader";
import UpComingSessions from './UpcomingSessions';
import { personalizedVideos } from '../../helpers/api';
import "../styles/common.scss";
import { meetings } from '../../helpers/api';
import HorizontalListWithAction from '../common/horizontalActionableHeader';

const getPersonalizedVideos = async (params) => {
    let additionalParams = '';
    if (params.queryKey[1] && params.queryKey[2]) {
        additionalParams = `&categories=${params.queryKey[1]}&order=${params.queryKey[2]}`
    }
    if (!params.queryKey[1] && params.queryKey[2]) {
        additionalParams = `&order=${params.queryKey[2]}`
    }
    if (params.queryKey[1] && !params.queryKey[2]) {
        additionalParams = `&categories=${params.queryKey[1]}`
    }

    const response = await axios.get(personalizedVideos.get(params.pageParam, 'live_meeting', additionalParams));
    return response.data.personalized_videos;
}


const KatiesCornerMeetingList = (props) => {
    const location = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }

    const [existingMeetings, setExistingMeetings] = useState([]);
    const [category, setCategory] = useState(null);
    const [sort, setSort] = useState('desc');

    useEffect(() => {
        const query = queryString.parse(props.history.location.search, { arrayFormat: 'comma' });

        if (query.categories) {
            setCategory(query.categories);
        } else {
            setCategory(null);
        }
        setSort(query.sort || 'desc');

    }, [props.history.location.search]);

    useEffect(() => {
        const query = queryString.parse(props.history.location.search, { arrayFormat: 'comma' });

        if (query.categories) {
            setCategory(query.categories);
        } else {
            setCategory(null);
        }

        setSort(query.sort || 'desc');

    }, [])

    const changeRouteForCategory = (category) => {
        if (sort) {
            navigateTo(`/nourish/live-meetings?categories=${category}&sort=${sort}`);
        } else {
            navigateTo(`/nourish/live-meetings?categories=${category}`);
        }

    }

    const changeRouteForSort = (sort) => {
        if (category) {
            navigateTo(`/nourish/live-meetings?categories=${category}&sort=${sort}`);
        } else {
            navigateTo(`/nourish/live-meetings?sort=${sort}`);
        }

    }

    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status
    } = useInfiniteQuery(['liveMeeting', category, sort], getPersonalizedVideos, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage.length >= 15 ? pages.length * 15 : undefined;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });


    useEffect(() => {
        axios.get(meetings.get())
            .then((response) => {
                setExistingMeetings(response.data.upcoming_meetings);
            })
    }, [])

    let personalizedVideos = [];
    if (data && data.pages) {
        personalizedVideos = data.pages.reduce((acc, page) => {
            acc = acc.concat(page);
            return acc;
        }, [])
    }

    let personalizedMeetingTaxonomy = props
        .taxonomiesData?.filter(taxonomy => taxonomy.object_type === 'personalized_video')
        .map((taxonomy) => ({
            title: taxonomy.name,
            value: taxonomy.id
        }));

    personalizedMeetingTaxonomy = [{ title: 'Choose', value: 'select' }].concat(personalizedMeetingTaxonomy || []);

    if (isFetching) {
        return <div className="container">
            <div className="preLoader-minimal-height preloader-max-height">
                <Loader></Loader>
            </div>
        </div>
    }
    return (
        <>
            {/* <KatiesConerNavigation url={location} navigateTo={navigateTo} /> */}
            <UpComingSessions existingMeetings={existingMeetings.filter(existingMeeting => existingMeeting.event_type === 'meeting')} title="Meeting Schedule" meetingType="the meeting" />
            <div className='container past-meetings-container'>
                <HorizontalListWithAction
                    title="Previous Meetings"
                    showActions={false}
                    showMultipleActions={true}
                    links={[
                        //     {
                        //     title: 'Categories',
                        //     value: category,
                        //     defaultValue: category,
                        //     onAction: (category) => {
                        //         changeRouteForCategory(category);
                        //     },
                        //     options: personalizedMeetingTaxonomy
                        // },
                        {
                            title: 'Sort',
                            value: sort,
                            defaultValue: sort,
                            onAction: (sortType) => {
                                changeRouteForSort(sortType)
                            },
                            options: [
                                { title: 'Newest', value: 'desc' },
                                { title: 'Oldest', value: 'asc' },
                            ]
                        }]}
                >
                    {
                        personalizedVideos.length === 0 ? <div className='no-item-header'>
                            No Sessions available
                        </div> : null
                    }
                    <InfiniteScroll
                        initialLoad={true}
                        loadMore={() => {
                            if (!isFetchingNextPage) {
                                fetchNextPage()
                            }
                        }}
                        hasMore={hasNextPage}
                        loader={
                            <div className="container">
                                <div className="preLoader-minimal-height">
                                    <Loader></Loader>
                                </div>
                            </div>
                        }
                    >
                        <ul className='katies-corner-list'>
                            {
                                (!isFetching && !isFetchingNextPage && data && data.pages) ? personalizedVideos.map((cornerItem) => (
                                    <PersonalizedVideoCard
                                        navigationType="past-meeting"
                                        key={cornerItem.slug}
                                        title={cornerItem.title}
                                        date={cornerItem.publish_at}
                                        introduction={cornerItem.about}
                                        banner={cornerItem.banner || cornerItem.thumbnail}
                                        navigateTo={navigateTo}
                                        slug={cornerItem.slug}
                                        categories={cornerItem.taxonomies}
                                    />
                                )) : null
                            }
                        </ul>
                    </InfiniteScroll>
                </HorizontalListWithAction>
            </div>
        </>
    )
}

export default KatiesCornerMeetingList