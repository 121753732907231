import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const FavoriteIconFill = () => {
  return (
    <svg width="34" height="31" viewBox="0 0 34 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.6767 1C21.3062 1.00806 18.2782 3.16694 17 6.46972C15.7218 3.16694 12.6862 1.00806 9.30813 1C4.7204 1 1 5.84139 1 10.715C1 21.3564 14.8621 30 17 30C19.1379 30 33 21.3483 33 10.715C33 5.84139 29.272 1 24.6767 1Z" fill={themeTextRed} stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
export default FavoriteIconFill;
