import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import '../../styles/common.scss';
import PropTypes from 'prop-types';

export const Input = (props) => {
  const {
    label,
    errorMessage,
    value,
    onChange,
    placeholder,
    type,
    name,
    onBlur,
    disabled,
    onKeyPress,
    isLastElementOfForm,
    formLastElementAction,
    additionalInputClass,
  } = props;
  return (
    <>
      <label>{label}</label>
      <input
        onKeyPress={onKeyPress}
        disabled={disabled}
        onBlur={onBlur}
        className={
          cx(
            'inputField',
            additionalInputClass,
            { 
              'errored': errorMessage, 
            }
          )
        }
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        name={name}
        required
        onKeyPress={(e) => {
          if (e.key === 'Enter' && isLastElementOfForm) {
            formLastElementAction()
          }
        }}
      />
      <div className="errorText">{errorMessage}</div>
    </>
  );
}

Input.propTypes = {
  onKeyPress: PropTypes.func
}

Input.defaultProps = {
  onKeyPress: () => { }
}

export default Input;

