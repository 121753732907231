import { put, takeEvery, call } from 'redux-saga/effects';
import * as actions from '../actions/actionTypes';
import { showToastMessage } from '../actions'
import mealPlanTask from './subtask/mealplan';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {

    const { meal_id, toDate, fromDate, order } = action.payload;
    const data = yield call(mealPlanTask.moveMeal, meal_id, toDate, order, fromDate);

    yield put({
      type: actions.MOVE_MEAL_IN_MEALPLAN_SUCCESS,
      payload: {
        success: true,
        data: data,
        toDate: toDate,
        fromDate: fromDate
      }
    })
    yield put(showToastMessage(`👍🏼 Calendar item move success`, 'success'));
  } catch (e) {
    yield put(showToastMessage(`❗️ Calendar item move failure`, 'success'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.MOVE_MEAL_IN_MEALPLAN_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* moveMealInMealPlan() {
  yield takeEvery(actions.MOVE_MEAL_IN_MEALPLAN, task)
}