import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import Button from '../common/button'
import CookBook from '../images/cookbook.png'
import Delicious from '../images/delicious.png'
import Devotion from '../images/devotion.png'
import img from '../images/book-heroImg.png'
import FullScreenLeft from '../images/fullscreen-left1.png'
import FullScreenRight from '../images/fullscreen-right1.png'
import Bookimg from '../images/book.png'
import FacebookOutlineIcon from '../images/FacebookOutlineIcon';
import VimeoOutlineIcon from '../images/VimeoOutlineIcon';
import InstaOutlineIcon from '../images/InstaOutlineIcon';
import TwitOutlineIcon from '../images/TwitOutlineIcon';
import PinOutlineIcon from '../images/PinOutlineIcon';
import AmazonIcon from '../images/AmazonIcon';
import BooklabelIcon from '../images/BooklabelIcon';

import { appEssentialFetch } from "../../actions";
import { TransitionEaseInOne, TransitionTwo, TransitionThree, TransitionFour } from '../common/transitions';

const BookPage = (props) => {

  useEffect(() => {
    props.appEssentialFetch();
  }, [])

  return (
    <>

      <Helmet>
        <title>Books | Dashing Dish</title>
        <meta name="description" content="Dashingdish Books page" />
      </Helmet>
      <section
        className="book-hero"
        style={{
          backgroundImage: `url(${img})`
        }}>
        <div className="container">
          <TransitionEaseInOne>
            <Row className="book-wrapper no-margin-sm">
              <Col xs={12} md={12} lg={8}>
                <div className="book-hero-wrapper">
                  <h1>Discover God’s Perfectly<br></br>
                    Balanced Plan for Your<br></br>
                    Body and Soul</h1>
                  <p>“Nourish” will provide you with encouragement and practical knowledge for living a healthy lifestyle, both physically and spiritually.</p>
                  <Button
                    btnClass="btn btn-primary btn-md"
                    btnText="Available now on Amazon.com"
                    onClick={() => {
                      window.open("https://www.amazon.com/Nourish-Discover-Gods-Perfectly-Balanced/dp/1478976063/ref=sr_1_1?ie=UTF8&qid=1509028523&sr=8-1&keywords=nourish+katie+farrell", '_blank');
                    }}
                  />
                </div>
              </Col>
              <Col xs={12} md={12} lg={4}>
                <div className="book-cover">
                  <figure>
                    <img src={Bookimg} className="img_fluid"></img>
                  </figure>
                </div>
              </Col>
            </Row>
          </TransitionEaseInOne>
        </div>
      </section>
      <main
        className="book-page">
        <section className="katies-book-wrapper">
          <TransitionTwo>
            <div className="container">
              <h3>Discover Katie’s other books</h3>
              <div className="book-lists">
                <ul className="list-unstyle">
                  <li>
                    <a href="http://www.amazon.com/Dashing-Dish-Simple-Delicious-Recipes-ebook/dp/B00KQ2G4IY/ref=tmm_kin_swatch_0?_encoding=UTF8&sr=&qid=" target="_blank">
                      <figure>
                        <img src={CookBook} className="img_fluid"></img>
                      </figure>
                      <p>Dashing Dish<br></br>
                        Cookbook</p>
                      <figure className="brand-icon">
                        <AmazonIcon></AmazonIcon>
                      </figure>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.amazon.com/Devotions-Healthier-You-Katie-Farrell-ebook/dp/B00KQ2G7HM" target="_blank">
                      <figure>
                        <img src={Devotion} className="img_fluid"></img>
                      </figure>
                      <p>Devotions for a<br></br>
                        Healthier You</p>
                      <figure className="brand-icon">
                        <AmazonIcon></AmazonIcon>
                      </figure>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.amazon.com/Delicious-Devotions-Feeding-Mind-Body/dp/B01I0DTR70/ref=sr_1_1?keywords=delicious+devotions+mind+body&qid=1581505113&s=digital-text&sr=8-1" target="_blank">
                      <figure>
                        <img src={Delicious} className="img_fluid"></img>
                      </figure>
                      <p>Delicious Devotions:<br></br>
                        Mind, Body, & Soul</p>
                      <figure className="brand-icon">
                        <AmazonIcon></AmazonIcon>
                      </figure>
                    </a>
                    <div className="book-label">
                      <figure>
                        <BooklabelIcon></BooklabelIcon>
                      </figure>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </TransitionTwo>
        </section>
        <section className="image-section">
          <TransitionThree>
            <div className="image-wrapper">
              <div className="FullScreen-left">
                <figure>
                  <img src={FullScreenLeft} className="img_fluid"></img>
                </figure>
              </div>
              <div className="FullScreen-right">
                <figure>
                  <img src={FullScreenRight} className="img_fluid"></img>
                </figure>
              </div>
            </div>
          </TransitionThree>
        </section>
        <section className="founder">
          <div className="container">
            <TransitionFour>
              <Row className="founder-wrapper no-margin-sm">
                <Col xs={12} sm={12} md={6} lg={5} className="no-padding-sm">
                  <div className="founder-intro">
                    <h3>Meet the founder, author,<br></br> and chef behind Dashing Dish</h3>
                    <div className="outline-social-icons">
                      <ul className="outline-icons-lists">
                        <li>
                          <a href="https://www.facebook.com/dashingdish/" target="_blank">
                            <FacebookOutlineIcon></FacebookOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://vimeo.com/dashingdish" target="_blank">
                            <VimeoOutlineIcon></VimeoOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.pinterest.com/dashingdish/" target="_blank">
                            <PinOutlineIcon></PinOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/dashingdish/?hl=en" target="_blank">
                            <InstaOutlineIcon></InstaOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/dashingdish?lang=en" target="_blank">
                            <TwitOutlineIcon></TwitOutlineIcon>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={6} lg={7} className="no-padding-sm">
                  <div className="founder-text">
                    <p>Hi, I’m Katie. I’m an author and registered nurse, and I’m on a mission to change the way the world thinks about healthy eating. Most people would say that eating healthy is dry, bland, and boring. But I’m here to show you a better way.
                  <a href="/about/my-story" onClick={(e) => { e.preventDefault(); props.history.push("/about/my-story") }}>&nbsp;Learn more about my story.</a></p>
                  </div>
                </Col>
              </Row>
            </TransitionFour>
          </div>
        </section>
      </main>
    </>
  )
}

const mapStateToProps = () => ({})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(BookPage);