import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";

import axios from "axios";

import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";

import filter from "lodash/filter";
import moment from "moment";
import Spinner from "react-spinner-material";

import { Elements } from "@stripe/react-stripe-js";

import { PayPalButton } from "react-paypal-button-v2";
import { loadStripe } from '@stripe/stripe-js';

import { Helmet } from "react-helmet";

import Radiobox from "../../../common/radiobox";

import Selector from "../../../common/selector";
import cx from "classnames";

import StripeForm from "./stripeForm";
import { TransitionEaseInOne } from "../../../common/transitions";

import CustomInput from "../../../common/customInput";
import Button from "../../../common/button";
import { auth, user } from "../../../../helpers/api";
import CreateAccountSection from "./createAccount";
import { themeTextRed } from "../../../../helpers/theme";
import Modalwrapper from "../../../common/modal";

const stripePromise = loadStripe(process.env.STRIPE_KEY);

const Checkout = ({
  completeRegistration,
  unifiedRegisterState,
  paymentType,
  changePaymentType,
  plans: allPlans,
  completeRegistrationUnified,
  defaultEmail,
  couponActivateInitiate,
  couponActivateSuccess,
  couponActivateFailure,
  couponRemove,
  showToastMessage,
  initiatedEmail,
  errorMessage,
  history,
  ...props
}) => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const plans = allPlans.filter(plan => plan.plan_order !== 3);
  const isGifted =
    unifiedRegisterState &&
    unifiedRegisterState.gift &&
    unifiedRegisterState.gift.data &&
    unifiedRegisterState.gift.data.giftCode;

  const [name, setName] = useState(
    isGifted ? unifiedRegisterState.gift.data.toName : null
  );
  const [email, setEmail] = useState(
    isGifted ? unifiedRegisterState.gift.data.toEmail : (!!initiatedEmail ? initiatedEmail : null)
  );

  const [billingAddress, setBillingAddress] = useState(null);

  const [isEmailServerValidated, setEmailServerValidation] = useState(false);
  const [password, setPassword] = useState(null);

  const [heardFrom, setHeardFrom] = useState('');
  const [otherReasons, setOtherReasons] = useState(null);

  const isBlackFriday = true;

  const [couponCode, setCouponCode] = useState(
    (unifiedRegisterState.coupon.activationSuccess && unifiedRegisterState.duration === 'monthly')
      ? unifiedRegisterState.coupon.data.code
      : null
  );

  // const [loginError, setLoginError] = useState(props.registerStatus.errorMessage);
  // const emailError = defaultEmail
  //   ? !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(defaultEmail)
  //   : false;

  const emailError = false;

  const [nameFocusedOnce, setNameOnceFocusState] = useState(false);
  const [emailFocusedOnce, setEmailOnceFocusState] = useState(
    defaultEmail ? true : false
  );
  const [isSixWeekLoading, setisWeekLoading] = useState(false);
  const [billingAddressFocusedOnce, setBillingAddressFocusedOnce] = useState(false);
  const [passwordFocusedOnce, setPasswordOnceFocusState] = useState(false);
  const [heardFromFocusedOnce, setHeardFromFocusedOnce] = useState(false);
  const [otherReasonsFocusedOnce, setOtherReasonsFocusedOnce] = useState(false);
  const [isAmazonPayModal, setAmazonPayModal] = useState(false);

  const [errors, setErrors] = useState({
    email: emailError ? "Please enter a valid email id" : null,
    password: null,
    name: null,
    otherReasons: null,
  });

  const [localPlan, setLocalPlan] = useState(`${unifiedRegisterState.planId}`);
  const [paypalPaymentStatus, setPaypalPaymentStatus] = useState({
    isPaying: false,
    isPayed: false,
  });

  const checkName = () => {
    if (name && (name.length < 3 || name.length > 35)) {
      setErrors({
        ...errors,
        name: "Name length should be between 3 - 35 letters",
      });
    } else {
      setErrors({ ...errors, name: null });
    }
  };

  const checkOtherReasons = (otherReasons) => {
    if (!otherReasons) {
      setErrors({
        ...errors,
        otherReasons: "Mention about the source that you heard us from",
      });
    } else {
      setErrors({
        ...errors,
        otherReasons: null,
      });
    }
  };
  const checkEmail = () => {
    setEmailServerValidation(false);

    if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email)) {
      setErrors({ ...errors, email: "Enter a valid email Id" });
    } else {
      setErrors({ ...errors, email: null });
    }
  };

  const checkBillingAddress = () => {
    if (!billingAddress || (billingAddress && billingAddress.length < 5)) {
      setErrors({
        ...errors,
        billingAddress: "Billing Address length should be more than 5 character",
      });
    } else {
      setErrors({ ...errors, password: null });
    }
  };


  const checkPassword = () => {
    if (!password || (password && password.length < 3)) {
      setErrors({
        ...errors,
        password: "Password length should be more than 3 character",
      });
    } else {
      setErrors({ ...errors, password: null });
    }
  };

  const serverValidateEmailRequest = () => {
    axios({
      method: "POST",
      url: user.verifyEmail(),
      data: {
        email: email || "",
      },
    })
      .then((response) => {
        if (response.data.valid) {
          setEmailServerValidation(true);
        }
      })
      .catch((e) => {
        setEmailServerValidation(false);
        setErrors({ ...errors, email: "This email is already taken" });
      });
  };

  const onActivateCoupon = (e) => {
    e.preventDefault();

    if (unifiedRegisterState.coupon.activationSuccess) {
      couponRemove();
    } else {
      couponActivateInitiate();
      axios({
        method: "POST",
        url: auth.validatePromo(),
        data: {
          code: couponCode,
        },
        headers: {
          Authorization: `Token token="${localStorage.getItem("token")}"`,
        },
      })
        .then((response) => {
          if (response.data.valid) {
            showToastMessage("✅ Coupon code applied.");
            couponActivateSuccess(response.data.coupon);
          }
        })
        .catch((e) => {
          showToastMessage("❗️Coupon code seems to be invalid");
          couponActivateFailure();
        });
    }
  };

  const handleGiftSubmit = (e) => {
    e.preventDefault();
    completeRegistrationUnified(
      unifiedRegisterState.gift.data.toName,
      unifiedRegisterState.gift.data.toEmail,
      password,
      null,
      unifiedRegisterState.gift.data.months,
      null,
      null,
      unifiedRegisterState.gift.data.giftCode,
      heardFrom,
      otherReasons
    );
  };
  if (plans.length === 0 && !unifiedRegisterState?.gift?.data?.giftCode) {
    return null;
  }
  const chosenPlan = filter(
    plans,
    (plan) => plan.id === unifiedRegisterState.planId
  )[0];

  const chosenPeriod = !isEmpty(chosenPlan)
    ? chosenPlan &&
    (unifiedRegisterState.duration === "monthly" ? `Monthly` : `Annually`)
    : "";
  const chosenPrice = !isEmpty(chosenPlan)
    ? chosenPlan &&
    (unifiedRegisterState.duration === "monthly"
      ? `$${parseInt(chosenPlan.monthly_price / 100)} Monthly`
      : `$${parseInt(chosenPlan.annual_price / 100)} Annually`)
    : "";

  const alternatePlans = filter(
    plans,
    (plan) => plan.id != unifiedRegisterState.planId
  )
  const alternatePeriodFirst = !isEmpty(alternatePlans[0])
    ? alternatePlans[0] &&
    (unifiedRegisterState.duration === "monthly" ? `Monthly` : `Annually`)
    : "";


  const alternatePeriodSecond = !isEmpty(alternatePlans[1])
    ? alternatePlans[1] &&
    (unifiedRegisterState.duration === "monthly" ? `Monthly` : `Annually`)
    : "";

  const alterOptions = [{
    title: `${alternatePlans[0] ? alternatePlans[0]?.name : ""
      } ${alternatePeriodFirst}`,
    value: alternatePlans[0]?.id,
  },
  ]


  const localPlanObject = filter(
    plans,
    (plan) => plan && plan.id === parseInt(localPlan)
  )[0];
  const localPrice = localPlanObject
    ? unifiedRegisterState.duration === "monthly"
      ? `$${parseInt(localPlanObject.monthly_price / 100)}`
      : `$${localPlanObject.annual_price / 100}`
    : "";

  const localDollars = localPlanObject
    ? unifiedRegisterState.duration === "monthly"
      ? parseInt(localPlanObject.monthly_price / 100)
      : localPlanObject.annual_price / 100
    : 0;

  const shouldApplyDiscount = true;
  const shouldNeverApplyDiscount = false;

  const earlyBird = false;
  const discountedPrice = (unifiedRegisterState.coupon.activationSuccess && isBlackFriday && shouldApplyDiscount && !shouldNeverApplyDiscount)
    ? (
      parseFloat(localDollars) *
      parseFloat((100 - unifiedRegisterState.coupon.data.percent_off) / 100)
    ).toFixed(2)
    : (shouldNeverApplyDiscount ? localDollars : (
      parseFloat(localDollars) *
      0.8
    ).toFixed(2));

  let couponType = null;

  if (true) {
    if (
      unifiedRegisterState.coupon.activationSuccess &&
      unifiedRegisterState.coupon.data.percent_off &&
      unifiedRegisterState.coupon.data.percent_off > 0
    ) {
      couponType = "discount";
    } else if (unifiedRegisterState.coupon.activationSuccess) {
      couponType = "freetrial";
    }
  }


  const handleCClessSubmit = (e) => {
    e.preventDefault();
    setisWeekLoading(true);
    completeRegistrationUnified(
      name,
      email,
      password,
      '',
      unifiedRegisterState.duration,
      localPlan,
      null,
      null,
      true,
      heardFrom,
      otherReasons,
      Math.random()
    );
  }

  return (
    <>
      <section className="login-section theme-bg-color">
        <Helmet>
          <title>Complete registration | Dashing Dish</title>
          <meta
            name="description"
            content="Dashingdish Complete registration page"
          />
        </Helmet>
        <Modalwrapper
          isOpen={isAmazonPayModal}
          minHeight='600px'
          maxWidth='500px'
          onRequestClose={() => setAmazonPayModal(false)}
        >
          <div className="modalWrapper">
            <div class="modalHeader warning-head">
              <h4>Attention</h4>
            </div>
            <div className="modalBody">
              <p>
                <ul>
                  <li style={{ textAlign: 'left' }}>While choosing Amazon Pay, the Username and password will be the email that is in your Amazon account.</li>
                  <li style={{ textAlign: 'left' }}>For example, if your Amazon account is registered with <b>ABC@gmail.com</b>, then both username and password after registration in Dashingdish would be <b>ABC@gmail.com</b>.</li>
                  <li style={{ textAlign: 'left' }}>Once logged in, you can change your password</li>
                </ul>

              </p>
            </div>
            <div className="modalFooter" style={{ display: 'flex' }}>

              <Button
                btnText="Proceed"
                btnClass="btn btn-primary"
                onClick={() => {

                  setAmazonPayModal(false);
                }}
              ></Button>
            </div>
          </div>
        </Modalwrapper>
        <Col xs={12} md={12} lg={12}>
          <TransitionEaseInOne>
            <form>
              <div className="login-wrapper">
                <div className="loginBlock">
                  <CreateAccountSection
                    name={name}
                    email={email}
                    password={password}
                    initiatedEmail={initiatedEmail}
                    setName={setName}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    nameFocusedOnce={nameFocusedOnce}
                    emailFocusedOnce={emailFocusedOnce}
                    passwordFocusedOnce={passwordFocusedOnce}
                    setNameOnceFocusState={setNameOnceFocusState}
                    setEmailOnceFocusState={setEmailOnceFocusState}
                    setPasswordOnceFocusState={setPasswordOnceFocusState}
                    checkEmail={checkEmail}
                    checkPassword={checkPassword}
                    checkName={checkName}
                    errors={errors}
                    isGifted={isGifted}
                    serverValidateEmailRequest={serverValidateEmailRequest}
                    billingAddress={billingAddress}
                    setBillingAddress={setBillingAddress}
                    checkBillingAddress={checkBillingAddress}
                    billingAddressFocusedOnce={billingAddressFocusedOnce}
                    setBillingAddressFocusedOnce={setBillingAddressFocusedOnce}
                  />
                  <div className="login-selector">
                    <div>
                      <b>Where did you hear us from?</b>

                    </div>
                    <div className="selector">
                      <span className="static-value">Answer</span>
                      <Selector
                        selectorList={[
                          {
                            title: '',
                            value: '',
                          },
                          {
                            title: 'Instagram',
                            value: 'Instagram',
                          },
                          {
                            title: 'Facebook',
                            value: 'Facebook',
                          },
                          {
                            title: 'Pinitrest',
                            value: 'Pinitrest',
                          },
                          {
                            title: 'Google Search',
                            value: 'Google Search',
                          },
                          {
                            title: 'Other Source',
                            value: 'Other',
                          },
                        ]}
                        onSelectValue={(value) => {
                          setHeardFromFocusedOnce(true);
                          setHeardFrom((value))
                        }}
                        disabled={!heardFromFocusedOnce}
                        value={(heardFrom)}
                      />
                    </div>
                    {
                      (heardFrom === 'Other') ? (
                        <div className="other-field">
                          <CustomInput
                            type="others"
                            name="others"
                            onChange={(event) => {
                              checkOtherReasons(event.target.value)
                              setOtherReasons(event.target.value || null);
                            }}
                            placeholder="Enter other source"
                            value={otherReasons}
                            className="error"
                            label="Source"
                            style={{ width: "100%" }}
                            errorMessage={otherReasonsFocusedOnce ? errors.otherReasons : null}
                            onBlur={(e) => {
                              setOtherReasonsFocusedOnce(true);
                            }}
                          />
                        </div>
                      ) : null
                    }

                  </div>
                  {unifiedRegisterState &&
                    unifiedRegisterState.gift &&
                    unifiedRegisterState.gift.data &&
                    unifiedRegisterState.gift.data.giftCode ? (
                    <Button
                      btnClass="full btn-primary btn-md"
                      btnText="Claim"
                      isLoading={
                        // isClickedOnce ||
                        unifiedRegisterState.isRegistering
                      }
                      onClick={handleGiftSubmit}
                      disabled={
                        // isClickedOnce ||
                        unifiedRegisterState.isRegistering || errors.password
                      }
                    />
                  ) : null}
                  {!(
                    unifiedRegisterState &&
                    unifiedRegisterState.gift &&
                    unifiedRegisterState.gift.data &&
                    unifiedRegisterState.gift.data.giftCode
                  ) ? (
                    <div className="checkout">
                      <h1>Payment Information</h1>
                      <p className="checkout-pre">
                        {(couponType !== "freetrial" && unifiedRegisterState.duration !== 'monthly')
                          ? null
                          : (couponType === 'freetrial' && unifiedRegisterState.duration !== 'monthly')
                            ? `Billing begins after free ${unifiedRegisterState.coupon.data.trial_length}-week trial unless
                        cancelled`
                            : (couponType === 'freetrial' ? "Billing begins after free 3-day trial unless cancelled" : null)
                        }
                      </p>
                      <div className="login-selector">
                        <div className="selector">
                          <span className="static-value">Plan</span>
                          <Selector
                            selectorList={[
                              {
                                title: `${chosenPlan ? chosenPlan.name : ""
                                  } ${chosenPeriod}`,
                                value: chosenPlan?.id,
                              },
                              ...alterOptions
                            ]}
                            onSelectValue={(value) =>
                              setLocalPlan(parseInt(value))
                            }
                            defaultValue={unifiedRegisterState.planId}
                            value={parseInt(localPlan)}
                          />
                        </div>
                      </div>
                      <div className="password-field">
                        <CustomInput
                          type="coupon"
                          name="coupon"
                          onChange={(event) => {
                            setCouponCode(event.target.value);
                          }}
                          placeholder="Enter Coupon code"
                          value={couponCode}
                          className="error"
                          disabled={
                            unifiedRegisterState.coupon.activationSuccess
                          }
                          label="Coupon Code"
                          style={{ width: "100%" }}
                        />
                        <div
                          className="show-password"
                          onClick={onActivateCoupon}
                          style={{ top: "26px" }}
                        >
                          <span>
                            {!unifiedRegisterState.coupon.activationSuccess
                              ? "Apply"
                              : "Remove"}
                          </span>
                        </div>
                      </div>
                      {
                        unifiedRegisterState.coupon.data.code === 'TRY6' ? (
                          <Button
                            btnClass="full btn-primary btn-md"
                            btnText="Try free for 6 weeks"
                            onClick={handleCClessSubmit}
                            disabled={isSixWeekLoading}
                            isLoading={isSixWeekLoading}
                            disabled={
                              errors.name || errors.password || errors.otherReasons
                            }
                          />
                        ) : (
                          <>
                            {
                              heardFrom ? (
                                <div
                                  className={cx("billing-duration billing-type", {
                                    "show-disable":
                                      errors.name ||
                                      errors.password,
                                  })}
                                >
                                  <Radiobox
                                    type="checkbox"
                                    label="Credit Card"
                                    checked={paymentType === "stripe"}
                                    radiobox
                                    onSelect={() => changePaymentType("stripe")}
                                    disabled
                                  />

                                  <Radiobox
                                    type="checkbox"
                                    label="Paypal"
                                    checked={paymentType === "paypal"}
                                    onSelect={() => changePaymentType("paypal")}
                                    radiobox
                                  />

                                  {
                                    !unifiedRegisterState.coupon.activationSuccess ? (
                                      <Radiobox
                                        type="checkbox"
                                        label="Amazon Pay"
                                        checked={paymentType === "amazonpay"}
                                        radiobox
                                        onSelect={() => {
                                          setAmazonPayModal(true);
                                          changePaymentType("amazonpay")
                                        }}
                                        disabled
                                      />
                                    ) : null
                                  }

                                </div>
                              ) : null
                            }

                            <div
                              style={{
                                display: paymentType === "stripe" ? "block" : "none",
                              }}
                            >
                              <Elements
                                fonts={[
                                  {
                                    cssSrc:
                                      "https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,500,600",

                                  },
                                ]}
                                stripe={stripePromise}
                              >
                                <StripeForm
                                  fontSize="18px"
                                  completeRegistration={completeRegistration}
                                  unifiedRegisterState={unifiedRegisterState}
                                  localPlan={localPlan}
                                  localPrice={localPrice}
                                  chosenPrice={chosenPrice}
                                  completeRegistrationUnified={
                                    completeRegistrationUnified
                                  }
                                  errors={errors}
                                  email={email}
                                  name={name}
                                  password={password}
                                  discountedPrice={discountedPrice}
                                  localDollars={localDollars}
                                  isEmailServerValidated={isEmailServerValidated}
                                  shouldApplyDiscount={shouldApplyDiscount}
                                  shouldNeverApplyDiscount={shouldNeverApplyDiscount}
                                  earlyBird={false}
                                  heardFrom={heardFrom}
                                  otherReasons={otherReasons}
                                  errorMessage={errorMessage}
                                  couponType={couponType}
                                />
                              </Elements>
                            </div>
                            <div
                              style={{
                                display: paymentType === "paypal" ? "block" : "none",
                              }}
                            >
                              {paypalPaymentStatus.isPaying ? (
                                <Spinner
                                  size={40}
                                  spinnerColor={themeTextRed}
                                  spinnerWidth={2}
                                  visible={true}
                                />
                              ) : null}
                              {couponType === "discount" && (
                                unifiedRegisterState.duration !== 'monthly' ? (
                                  <div className="due-wrapper-set">
                                    <div className="due-wrapper">
                                      <span>Total due today</span>
                                      <span
                                        className={cx({
                                          cross:
                                            unifiedRegisterState.coupon
                                              .activationSuccess && !shouldNeverApplyDiscount,
                                        })}
                                      >
                                        ${localDollars}
                                      </span>
                                    </div>
                                    {(unifiedRegisterState.coupon.activationSuccess && shouldApplyDiscount && !shouldNeverApplyDiscount) ? (
                                      <>
                                        <div className="due-wrapper coupon-wrapper">
                                          <span className={cx("applied-coupon")}>
                                            Coupon applied for the first cycle:{" "}
                                            {unifiedRegisterState.coupon.data.code}
                                          </span>
                                          <span className="price normal-highlight">
                                            {!isNil(discountedPrice)
                                              ? `$${parseFloat(discountedPrice)}`
                                              : null}
                                          </span>
                                        </div>
                                        <div className="due-wrapper secondary">
                                          <span>
                                            From next cycle:{" "}
                                          </span>
                                          <span className="price normal-highlight">
                                            {!isNil(localDollars)
                                              ? `$${parseFloat(localDollars)}`
                                              : null}
                                          </span>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                ) : null

                              )}
                              {couponType === "freetrial" && unifiedRegisterState.duration === 'annually' && (
                                <div className="due-wrapper-set">
                                  <div className="due-wrapper">
                                    <span>Total due today</span>
                                    <span>$0.00</span>
                                  </div>
                                  <div className="due-slide">
                                    <span
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <span>
                                        Total due on{" "}
                                        {moment()
                                          .add({
                                            weeks: unifiedRegisterState.coupon
                                              .activationSuccess
                                              ? parseInt(
                                                unifiedRegisterState.coupon.data
                                                  .trial_length
                                              )
                                              : 0,
                                          })
                                          .format("MMMM Do")}
                                      </span>
                                      {unifiedRegisterState.coupon
                                        .activationSuccess ? (
                                        <span style={{ fontSize: "0.9rem" }}>
                                          (You have got{" "}
                                          {
                                            unifiedRegisterState.coupon.data
                                              .trial_length
                                          }{" "}
                                          weeks of free trial)
                                        </span>
                                      ) : null}
                                    </span>
                                    <span>${localDollars}</span>
                                  </div>
                                </div>
                              )}
                              {(couponType === null || (couponType === "discount" && (
                                unifiedRegisterState.duration === 'monthly'))) && (
                                  <div className="due-wrapper-set">
                                    <div className="due-wrapper">
                                      <span>Total due today</span>
                                      <span>$0.00</span>
                                    </div>
                                    <div className="due-slide">
                                      <span>
                                        Total due on{" "}
                                        {moment()
                                          .add({
                                            days: 3
                                          })
                                          .format("MMMM Do")}
                                      </span>
                                      <span>${localDollars}</span>
                                    </div>
                                  </div>
                                )}


                              <PayPalButton
                                options={{
                                  vault: true,
                                  clientId: process.env.PAYPAL_CLIENT_ID,
                                }}
                                createSubscription={(data, actions) => {
                                  let plan = filter(
                                    plans,
                                    (plan) => plan.id === parseInt(localPlan)
                                  )[0];


                                  const isPromoApplied =
                                    unifiedRegisterState.coupon.activationSuccess && shouldApplyDiscount;


                                  const non_discounted_plan_id =
                                    unifiedRegisterState.duration === "monthly"
                                      ? plan.monthly_paypal_membership_id
                                      : plan.annual_paypal_membership_id;

                                  let discounted_plan_id = null;
                                  if (isPromoApplied) {
                                    if (plan.order === 1) {
                                      discounted_plan_id =
                                        unifiedRegisterState.duration === "monthly"
                                          ? unifiedRegisterState.coupon.data
                                            .monthly_premium_paypal_plan_id
                                          : unifiedRegisterState.coupon.data
                                            .yearly_premium_paypal_plan_id;
                                    } else if (plan.order === 2) {
                                      discounted_plan_id =
                                        unifiedRegisterState.duration === "monthly"
                                          ? unifiedRegisterState.coupon.data
                                            .monthly_base_paypal_plan_id
                                          : unifiedRegisterState.coupon.data
                                            .yearly_base_paypal_plan_id;
                                    }
                                  }

                                  console.log('plan ids ', discounted_plan_id, non_discounted_plan_id)
                                  const plan_id = (isPromoApplied && unifiedRegisterState.duration === "annually") ? discounted_plan_id : non_discounted_plan_id;

                                  return actions.subscription.create({
                                    plan_id,
                                  });
                                }}
                                onApprove={(data, actions) => {
                                  // Capture the funds from the transaction
                                  setPaypalPaymentStatus({
                                    ...paypalPaymentStatus,
                                    isPaying: true,
                                  });
                                  return actions.subscription
                                    .get()
                                    .then(function (details) {
                                      // Show a success message to your buyer
                                      if (details.status === "ACTIVE") {
                                        completeRegistrationUnified(
                                          name,
                                          email,
                                          password,
                                          "",
                                          unifiedRegisterState.duration,
                                          localPlan,
                                          data.subscriptionID,
                                          null,
                                          null,
                                          null,
                                          heardFrom,
                                          otherReasons
                                        );
                                      }
                                    });
                                }}
                              />
                            </div>



                            <div
                              style={{
                                display: paymentType === "amazonpay" ? "block" : "none",
                              }}
                            >
                              {(couponType === null || (couponType === "discount" && (
                                unifiedRegisterState.duration === 'monthly'))) && (
                                  <div className="due-wrapper-set">
                                    <div className="due-wrapper">
                                      <span>Total due today</span>
                                      <span>$0.00</span>
                                    </div>
                                    <div className="due-slide">
                                      <span>
                                        Total due on{" "}
                                        {moment()
                                          .add({
                                            days: 3
                                          })
                                          .format("MMMM Do")}
                                      </span>
                                      <span>${localDollars}</span>
                                    </div>
                                  </div>
                                )}
                              <Button onClick={async (e) => {

                                if (unifiedRegisterState.duration === 'annually') {
                                  window.open(chosenPlan.annual_payment_link, '_blank');
                                }
                                if (unifiedRegisterState.duration === 'monthly') {
                                  window.open(chosenPlan.monthly_payment_link, '_blank');
                                }
                                history.push('/login');
                              }
                              }
                                btnClass="btn btn-full btn-primary"
                                btnText="Complete Payment"
                              >
                              </Button>
                            </div>
                          </>
                        )
                      }

                    </div>
                  ) : null}

                  <div className="login-cookies-text">
                    <p>
                      By signing up you agree to our
                      <br />
                      <a href={"/privacy-policy"} target="_blank">
                        privacy policy
                      </a>
                      &nbsp; and &nbsp;
                      <a href={"/terms-conditions"} target="_blank">
                        &nbsp;terms and conditions.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          </TransitionEaseInOne>
        </Col>
      </section >
    </>
  );
};

export default Checkout;
