import React, { useReducer, useState, useEffect } from 'react'
import take from 'lodash/take'
import Spinner from 'react-spinner-material';

import { trim, join, filter, isNil, isEmpty, isNull } from 'lodash';
import map from 'lodash/map'
import find from 'lodash/find'
import axios from 'axios';
import Modalwrapper from '../../../common/modal'
import CustomInput from "../../../common/customInput";
import LeftArrow from "../../../images/LeftArrow"
// import RightArrow from "../../../images/RightArrow"
import RightArrow from '../../../images/CalenderRightArrow'
import LinkButton from '../../../common/linkButton'
import Button from '../../../common/button'
import pushToSentry from '../../../../helpers/pushToSentry';
import { themeTextRed } from '../../../../helpers/theme';

const EXPORT_MAX_LIMIT = 60;
const initialState = {
  screen: 'groceryList',
  pinCode: '',
  retailers: null,
  showPreloader: false,
  currentRetailerName: null
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_SCREEN':
      return {
        ...state,
        screen: action.payload.screen,
        checkoutUrl: action.payload.checkoutUrl,
        showPreloader: !isNil(action.payload.showPreloader) ? action.payload.showPreloader : false
      }
    case 'UPDATE_RETAILERS':
      return {
        ...state,
        retailers: action.payload.retailers,
        showPreloader: action.payload.showPreloader,
        // pinCode: action.payload.pinValue
      }
    case 'UPDATE_PIN_CODE':
      return {
        ...state,
        pinCode: action.payload.value
      }
    case 'SHOW_PRELOADER':
      return {
        ...state,
        showPreloader: action.payload
      }
    case 'CURRENT_RETAILER_NAME':
      return {
        ...state,
        currentRetailerName: action.payload.currentRetailerName
      }
    default:
      return state;
  }
}

const Export = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const [error, setError] = useState(null)

  // prefetches the retailers data of code is in localstore
  useEffect(() => {
    if (!isNil(localStorage.getItem("zipCode")) && state.pinCode === '') {
      let pinCode = localStorage.getItem("zipCode");
      dispatch({ type: 'UPDATE_PIN_CODE', payload: { value: pinCode } })
      handleStoreLocator(pinCode)
    }
  }, [])


  const handleExport = async () => {
    dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'storeSelect' } })
  }

  const handleStoreLocator = async (code) => {
    dispatch({ type: 'SHOW_PRELOADER', payload: true })
    localStorage.setItem('zipCode', code);

    axios({
      method: 'GET',
      url: `https://graph.whisk.com/v1/retailers?country=US&zipcode=${code}`,

      headers: {
        Authorization: `Token oIl1CPhwbudlA4FXnbZOYiGQSCxNzDaFzEHLFFEnA9tbWrp4xnwJ4OYO8WVQqfvc`
      }
    }).then((response) => {
      dispatch({
        type: 'UPDATE_RETAILERS',
        payload: {
          retailers: response.data.retailers,
          showPreloader: false,
        }
      })
    }).catch((e) => {
      pushToSentry(e)
    })
  }
  const handleExportToGroceryList = async (retailerData) => {
    const retailerId = retailerData.id;
    const retailerName = retailerData.name;
    const data = {
      items: take(props.mappedItemsData, EXPORT_MAX_LIMIT),
      "retailerId": retailerId,
      "zipcode": state.pinCode,
    }
    const retailer = find(state.retailers, (retailer) => {
      if (retailer.id === retailerId) {
        return true
      }
      return false
    })

    dispatch({ type: 'SHOW_PRELOADER', payload: true })
    dispatch({ type: 'CURRENT_RETAILER_NAME', payload: { currentRetailerName: retailer.name } })

    if (retailerName === "Walmart") {
      axios({
        method: 'POST',
        url: `https://graph.whisk.com/v1/carts`,

        headers: {
          Authorization: `Token oIl1CPhwbudlA4FXnbZOYiGQSCxNzDaFzEHLFFEnA9tbWrp4xnwJ4OYO8WVQqfvc`
        },
        data: data
      }).then((response) => {
        const cartId = response.data.id
        return axios({
          method: 'POST',
          url: `https://graph.whisk.com/v1beta/${cartId}/checkout`,
          headers: {
            Authorization: `Token oIl1CPhwbudlA4FXnbZOYiGQSCxNzDaFzEHLFFEnA9tbWrp4xnwJ4OYO8WVQqfvc`
          },
          data: {
            retailerId: retailerId,
            extra: { zipcode: state.pinCode }
          }
        })
      })
        .then(response => {
          dispatch({
            type: 'CHANGE_SCREEN',
            payload: {
              checkoutUrl: response.data.completeCheckoutUrl,
              screen: 'checkoutScreen',
              showPreloader: true

            }
          })
        }).catch((e) => {
          dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'storeSelect' } })
          props.showToastMessage('❗️Something goes wrong', 'error');
          pushToSentry(e)
        })
    } else {
      axios({
        method: 'POST',
        url: `https://graph.whisk.com/v1beta/carts/checkout`,

        headers: {
          Authorization: `Token oIl1CPhwbudlA4FXnbZOYiGQSCxNzDaFzEHLFFEnA9tbWrp4xnwJ4OYO8WVQqfvc`
        },
        data: data
      }).then((response) => {

        dispatch({
          type: 'CHANGE_SCREEN',
          payload: {
            checkoutUrl: response.data.completeCheckoutUrl,
            screen: 'checkoutScreen',
            showPreloader: true

          }
        })
      }).catch((e) => {
        dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'storeSelect' } })
        props.showToastMessage('❗️Something goes wrong', 'error');
        pushToSentry(e)
      })
    }
  }

  const checkPin = (value) => {
    if (!(/^\d*$/).test(value)) {
      setError("Please enter valid code")
    } else {
      setError(null)
    }
  }

  const renderRetailers = () => {

    if (isNull(state.retailers)) {
      // this happens before user types any code  
      if (isNull(error)) {
        return (
          <p>
            Please enter a zip code to proceed with
          </p>
        )
      }
    } else {
      // this happesns after user types code and hits the api 
      if (!isEmpty(state.retailers)) {
        return (
          <div
            className="retailer-wrapper"
          >
            {
              <div className="retailer-item">Showing results for : <span style={{ fontWeight: "600" }}>{(localStorage && localStorage.getItem('zipCode') ? localStorage.getItem('zipCode') : '')}</span></div>
            }
            {
              map(state.retailers, (retailer) => (
                <>
                  {
                    (
                      <div
                        onClick={() => handleExportToGroceryList(retailer)}
                        className={`retailer-item`}
                        key={retailer.id}
                      >
                        <div className="retailer-logo">
                          <figure>
                            <img src={retailer.logo.url} className="retailers-image" />
                          </figure>
                        </div>
                        <RightArrow></RightArrow>
                      </div>
                    )
                  }
                </>
              ))
            }
          </div>
        )
      } else {
        return (
          <p>
            No retailers available for this zip code.
          </p>
        )
      }
    }


  }
  return (
    <>
      <LinkButton
        renderValue={() => <>Export</>}
        additionalClass={'export-btn'}
        onClick={handleExport}
        disabled={isEmpty(props.mappedItemsData)}
      />
      <Modalwrapper
        isOpen={state.screen === 'storeSelect'}
        onRequestClose={() => dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'groceryList' } })}
        minHeight="38rem"
      >
        <div className="whisk-wrapper">
          <div className="whisk-input-wrapper">
            <h4>Export List</h4>
            <CustomInput
              type="text"
              onChange={event => {
                if ((/^\d{0,5}$/).test(event.target.value)) {
                  dispatch({ type: 'UPDATE_PIN_CODE', payload: { value: event.target.value } })
                  if (event.target.value.length >= 5) {
                    handleStoreLocator(event.target.value)
                  }
                }
                // if (event.target.value.length >= 5) {
                //   dispatch({ type: 'UPDATE_PIN_CODE', payload: { value: event.target.value } })
                //   handleStoreLocator(event.target.value)
                // } else {
                //   dispatch({ type: 'UPDATE_PIN_CODE', payload: { value: event.target.value } })
                //   checkPin(event.target.value)
                // }
              }}
              placeholder={!isEmpty(state.retailers) ? `Showing results for ${localStorage.getItem('zipCode')}` : 'Please enter pin code'}
              value={state.pinCode}
              className="error"
              label="Zip Code"
              onKeyUp={e => {
                if (e.keyCode === 13) {
                  if (!isEmpty(state.pinCode) && isNull(error)) {
                    handleStoreLocator(e.target.value)
                  }
                }
              }}
            />
            {!isNull(error) && <p>{error}</p>}
            {state.showPreloader ?
              <div className="spinner-wrapper">
                <Spinner
                  size={15}
                  spinnerColor={themeTextRed}
                  spinnerWidth={2}
                  visible={true}
                />
              </div>
              :
              renderRetailers()
            }
          </div>
        </div>
      </Modalwrapper>

      <Modalwrapper
        isOpen={state.screen === 'checkoutScreen'}
        onRequestClose={() => dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'groceryList' } })}
        minHeight="220px"
      >
        <div
          className="whisk-wrapper"
        >
          <div className="header">
            <div className="header-text">
              <span
                onClick={() => dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'storeSelect' } })}
                className="back-button"
              >
                <LeftArrow />
                Back
              </span>
              <h3>Proceed to checkout</h3>
            </div>
          </div>
          <div className="checkout-wrapper">
            {
              props.mappedItemsData.length > EXPORT_MAX_LIMIT &&
              <>
                <p><span style={{ fontWeight: "600", color: themeTextRed }}>{state.currentRetailerName}</span> {`has a maximum limit of ${EXPORT_MAX_LIMIT} items for each checkout.`}</p>
                <p> Would you like to proceed ? </p>
              </>
            }
            <Button
              btnClass="btn btn-primary btn-md chekout-button"
              target="_blank"
              href={state.checkoutUrl}
              btnText={`Proceed with ${state.currentRetailerName}`}
              onClick={() => {
                window.open(state.checkoutUrl, "_blank")
                dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'confirmationModal' } })
              }}
            />
          </div>
        </div>
      </Modalwrapper>

      <Modalwrapper
        isOpen={state.screen === 'confirmationModal'}
        onRequestClose={() => dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'groceryList' } })}
        minHeight="200px"
      >

        <div
          className="whisk-wrapper"
        >
          <div className="header">
            <div className="header-text">
              <span
                onClick={() => dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'storeSelect' } })}
                className="back-button"
              >
                <LeftArrow />
                Back
              </span>
            </div>
          </div>
          <h3>Would you like to clear the items that are checked out in the grocery list ? </h3>

          <div
            className="confirmation-btn"
          >
            <Button
              btnClass="btn btn-primary btn-md"
              btnText={"Yes"}
              onClick={() => {
                props.clearExportedItems()
                dispatch({ type: 'CHANGE_SCREEN', payload: { screen: 'groceryList' } })
              }}
            />
          </div>

        </div>
      </Modalwrapper>
    </>
  )
}

export default Export