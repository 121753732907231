export const getScrollPage = () => {
  let docScrollTop = 0;
  if (document.documentElement && document.documentElement !== null) {
    docScrollTop = document.documentElement.scrollTop;
  }
  return window.pageYOffset || docScrollTop;
};


export const getScrollElement = (elementId) => {
  let elemScrollTop = 0;
  const element = document.getElementById(elementId);
  if (element !== null) {
    elemScrollTop = element.scrollTop;
  }
  return elemScrollTop;
};


export const scrollTo = (scrollnumber) =>
  window.requestAnimationFrame(() => {
    window.scrollTo(0, scrollnumber);
  });

export const scrollToElement = (
  scrollnumber = 0,
  elementId
) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollTop = scrollnumber;
  }
};

export const isBrowser = () => typeof window !== "undefined";