import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'

import { remove } from '../helpers/requests'
import { boards } from '../helpers/api'
import { getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import boardTask from './subtask/boards'
import pushToSentry from '../helpers/pushToSentry';
import { showToastMessage } from '../actions';

function* task(action) {
  try {

    const { boardId, objectType } = action.payload

    yield call(boardTask.delete, boardId)
    yield put({
      type: actions.DELETE_FAVOURITE_BOARD_SUCCESS,
      payload: {
        boardId,
        objectType
      }
    })
    yield put(showToastMessage(`✅ Board deleted.`, 'success', 'deleteBoard'));

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put(showToastMessage(`❗️ Board deletion failed.`, 'error', 'deleteBoard'));
    yield put({
      type: actions.DELETE_FAVOURITE_BOARD_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* deleteFavouriteBoard() {
  yield takeEvery(actions.DELETE_FAVOURITE_BOARD, task)
}