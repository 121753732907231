import React from "react";
import cx from "classnames";
import ReactImageFallback from "react-image-fallback";

import fallbackImage from "../../../images/navrecipefallback.png";

const NavDropdownImage = ({ src, type, image1, image2, image3 }) => {
  let cardTypeClass;
  let maxWidth;
  // TODO: Based on the class style the images
  if (type === "recipe") {
    cardTypeClass = "navimage-recipe";
    maxWidth = "90px";
  } else if (type === "workout") {
    cardTypeClass = "navimage-workout";
    maxWidth = "150px";
  } else if (type === "video") {
    cardTypeClass = "navimage-video";
    maxWidth = "150px";
  } else if (type === "blog") {
    cardTypeClass = "navimage-blog";
    maxWidth = "260px";
  } else if (type === "mealplan") {
    cardTypeClass = "navimage-mealplan";
    maxWidth = "90px";
  }

  if (type === "mealplan") {
    return (
      <div className="meanplan-navbar-group">
        <img className="first-image" src={image1} />
        <img className="second-image" src={image2} />
        <img className="third-image" src={image3} />
      </div>
    );
  } else {

    return (
      <div className="navbar-figure">
        <figure className={cx("card-Img navbar-image", cardTypeClass)}>
          <ReactImageFallback
            src={src}
            initialImage={fallbackImage}
            className="img_fluid"
            fallbackImage={fallbackImage}
          />
        </figure>
      </div>
    );
  }
};

export default NavDropdownImage;
