import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const CommentIcon = () => {
  return (
    <svg width="42" height="38" viewBox="0 0 42 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.44 10.24C26.69 5.04 21.08 1 14.26 1C6.94 1 1 5.67 1 11.42C1 14.34 2.54 16.98 5 18.87L5.32 24.64L10.36 21.38C10.71 21.46 11.06 21.54 11.41 21.6M27.53 13.36C34.85 13.36 40.79 18.03 40.79 23.78C40.79 26.7 39.25 29.34 36.79 31.23L36.47 37L31.43 33.74C30.2 34.04 28.89 34.2 27.53 34.2C20.21 34.2 14.27 29.53 14.27 23.78C14.26 18.03 20.2 13.36 27.53 13.36Z" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
export default CommentIcon;