import axios from 'axios'
import { showToastMessage } from '../actions'

import { put, takeEvery, call, select } from 'redux-saga/effects'
// import { Sentry } from 'react-native-sentry';
import pick from 'lodash/pick'

import * as actions from '../actions/actionTypes'
import groceryTask from './subtask/grocery'
import { getToken, getCurrentRecipeId } from './selectors'
import { fromJS } from 'immutable';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {

    const { ingredientsData } = action.payload

    const filteredIngredientsData = (ingredientsData.groups || []).reduce((accumulator, group) => {
      let lineAccumulator = [];
      (group.lines || []).forEach(line => {
        let itemName = line.item
        let amount = line.amount
        let addIngredientName = `${amount} ${itemName}`
        // lineAccumulator.push(pick(line, ['item']))
        let itemObject = {
          item: addIngredientName,
          category_id: parseInt(line.category),
          recipe_id: group.recipeId,
        }
        lineAccumulator.push(itemObject)

      })
      return accumulator.concat(lineAccumulator)
    }, [])

    let groceryResponse = yield call(groceryTask.post, filteredIngredientsData);

    yield put({
      type: actions.ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST_SUCCESS,
      payload: {
        success: true,
        addedIngredients: groceryResponse.data.new_items
      }
    })

    // Do it differently for workouts / videos / blogs
    yield put(showToastMessage(`✅ ${groceryResponse.data.new_items.length} items added.`, 'success', 'addAllGroceryList'));
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in updating groceries.`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addAllToGroceryList() {
  yield takeEvery(actions.ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST, task)
}