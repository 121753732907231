import { put, call, select, all } from "redux-saga/effects";
import { getExistingWorkouts } from "./selectors";
import * as actions from "../actions/actionTypes";
import workoutTask from "./subtask/workouts";
import pushToSentry from "../helpers/pushToSentry";
import retrier from "./process/retrier";

export default function* fetchWorkoutDetails(action) {
  try {
    const {
      payload: { workoutSlug },
    } = action;

    // If this workout is already present in the list
    let workoutData;

    const workoutArray = yield call(
      retrier,
      [call(workoutTask.get, workoutSlug)],
      "single workout"
    );

    const workoutResponse = workoutArray[0];

    if (!workoutResponse) {
      const eventId = pushToSentry(
        "Internet connection seems to be down - single workout"
      );
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: "Internet connection seems to be down",
          eventId,
        },
      });
    }

    if (workoutResponse.status === 403) {
      return {
        limitReached: true,
        error: "Limit reached",
        title: workoutResponse.data.title,
        images: workoutResponse.data.images,
        calorie_burn: workoutResponse.data.calorie_burn,
        difficulty: workoutResponse.data.difficulty,
        favorite_count: workoutResponse.data.favorite_count,
        introduction: workoutResponse.data.introduction,
        message: workoutResponse.data.message,
        status: workoutResponse.data.status,
        time: workoutResponse.data.time,
      };
    }

    workoutData = workoutResponse.data.workouts;
    relatedWorkouts = workoutResponse.data.related_workout;
    tags = workoutResponse.data.tags;
    meteredCount = workoutResponse.data.metered_workouts;

    return {
      objectType: "workout",
      updatedData: workoutData, // Will have recipes, foodTypes and related recipes
      relatedWorkouts,
      tags,
      meteredCount,
    };
  } catch (e) {
    yield put({
      type: actions.GET_SINGLE_WORKOUT_DETAILS_FAILURE,
      payload: {
        error: e,
      },
    });
    yield put({
      type: actions.GET_SINGLE_WORKOUT_DETAILS_FAILURE,
      payload: {
        error: e,
      },
    });
    if (e.response && e.response.status == 403) {
      return {
        limitReached: true,
        error: "Limit reached",
        title: e.response.data.title,
        images: e.response.data.images,
        calorie_burn: e.response.data.calorie_burn,
        difficulty: e.response.data.difficulty,
        favorite_count: e.response.data.favorite_count,
        introduction: e.response.data.introduction,
        message: e.response.data.message,
        status: e.response.data.status,
        time: e.response.data.time,
      };
    } else {
      console.log("came in 2");
      const getState = (state) => state.toJS();
      const state = yield select(getState);
      const eventId = pushToSentry(
        e,
        state,
        "Fetch workout error",
        JSON.stringify(state.objectSpecificEssential)
      );

      return {
        objectType: "workout",
        updatedData: {}, // Will have workouts, tags and related recipes
        relatedWorkouts: [],
        tags: [],
      };
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: e.message,
          eventId,
        },
      });
    }
  }
}
