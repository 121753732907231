import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import isNull from 'lodash/isNull'
import isNil from 'lodash/isNil'
import Headeroom from "react-headroom";
import Spinner from 'react-spinner-material'
import isEmpty from 'lodash/isEmpty'

import SearchIcon from '../images/SearchIcon'
import Selector from './selector'
import CloseIcon from '../images/closeIcon'
import { debounce } from '../../helpers/converters'
import { themeTextRed } from '../../helpers/theme';


const SEARCH_TEXT_THRESHOLD_LENGTH = 3;

const ref = React.createRef();
const inputRef = React.createRef();
const dropdownRef = React.createRef();
const selectorRef = React.createRef();
const closeIconRef = React.createRef();

let debouncer;

const SearchInput = ({
  placeholder,
  onChange, // for debounce change
  history,
  dropDownReturnActionText,
  dropDownReturnAction,
  selectorList,
  defaultSearchBy,
  searchList,
  searchStatus,
  searchCount,
  onSearchByChange,
  initialSearchValue,
  updateInputValue, // to update the local state
  objectType,
  isResponsive,
  showInstantResults,
  onClickSearchClear,
  ...props
}) => {

  const [state, setState] = useState({
    showInput: false,
    showDropdown: false,
    forceDropdownClose: false,
    searchByValue: !isNil(defaultSearchBy) ? defaultSearchBy : 'title'
  })

  // ALL USE-EFFECTS
  // to handle outside click
  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick, false)
    return function cleanup() {
      document.removeEventListener('mousedown', () => { }, false);
    }
  }, [state.showDropdown, state.searchByValue])

  // ALL LOGIC FUNCTIONS  
  // to handle outside click
  const handleOutsideClick = (e) => {

    // Input is the target for outside click
    const clickedOutsideInput = (!isNull(inputRef) && !isNull(inputRef.current) && !inputRef.current.contains(e.target));
    const clickedOutsideDropdown = isNull(dropdownRef) || isNull(dropdownRef.current) || (!isNull(dropdownRef) && !isNull(dropdownRef.current) && !dropdownRef.current.contains(e.target));
    const clickedOutsideSelector = isNull(selectorRef) || isNull(selectorRef.current) || (!isNull(selectorRef) && !isNull(selectorRef.current) && !selectorRef.current.contains(e.target));
    const clickedOutsideClose = isNull(closeIconRef) || isNull(closeIconRef.current) || (!isNull(closeIconRef) && !isNull(closeIconRef.current) && !closeIconRef.current.contains(e.target));

    if (
      clickedOutsideInput && clickedOutsideDropdown && clickedOutsideSelector && clickedOutsideClose
    ) {
      if (state.showDropdown) {
        setState({ ...state, showInput: true, showDropdown: false })
      } else {
        setState({ ...state, showInput: false, showDropdown: false })
      }
    }
  }

  // the show dropdown logic to be used in debounce 
  const renderDropdownIfRequired = (value) => {
    if (showInstantResults && value.length >= SEARCH_TEXT_THRESHOLD_LENGTH) {
      // Happens only for blog
      dropDownReturnAction(value, 'title')
    } else {
      // render dropdown based on value length
      if (value.length >= SEARCH_TEXT_THRESHOLD_LENGTH) {
        onChange(value, state.searchByValue) //goes to parent
        setState({ ...state, showDropdown: true })
      }
    }
  }

  // what happens on search on text input change
  const onSearchTextChange = (value) => {
    setState({ ...state, showDropdown: false })
    if (!debouncer) {
      debouncer = setTimeout(() => {
        renderDropdownIfRequired(value)
      }, 600)
    } else {

      clearTimeout(debouncer);
      debouncer = setTimeout(() => {
        renderDropdownIfRequired(value)
      }, 600);
    }
  }

  // what happens when a key is pressed in input
  const onInputKeyPress = (e) => {

    // on enter
    if (e.keyCode === 13) {
      if (initialSearchValue && initialSearchValue.length > 0) {
        clearTimeout(debouncer);
        dropDownReturnAction(initialSearchValue, state.searchByValue)
        setState({ ...state, showDropdown: false })
      }
    }
  }

  // ALL SUB-RENDERS
  // render input condition
  const renderInput = () => {

    if (state.showInput || isResponsive) {
      return (
        <div className="container">
          <div className='filterInput'>
            <div className="inputWrapper">
              {
                selectorList.length > 1 ? (
                  <div className='filterSelector' ref={selectorRef}>
                    <Selector
                      class={"customSelect"}
                      defaultValue={defaultSearchBy}
                      value={state.searchByValue}
                      onSelectValue={(value) => {
                        if (debouncer) {
                          clearTimeout(debouncer)
                        }
                        // Perform updates only when the value is greater than or equal to 3
                        if (initialSearchValue.length >= SEARCH_TEXT_THRESHOLD_LENGTH) {
                          onSearchByChange(value, initialSearchValue)
                          setState({ ...state, searchByValue: value, showDropdown: true })
                        } else {
                          setState({ ...state, searchByValue: value, showDropdown: false })
                        }
                      }}
                      selectorList={selectorList}
                      onlyDownArrow
                    />
                  </div>
                ) : null
              }
              <input
                value={initialSearchValue}
                placeholder={placeholder}
                className='searchFilter'
                ref={inputRef}
                onChange={(e) => {
                  updateInputValue(e.target.value)
                  onSearchTextChange(e.target.value)
                }}
                onKeyUp={(e) => onInputKeyPress(e)}
                autoFocus={isResponsive ? false : true}
              />
              {renderInputIcon()}
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div
          className='searchInput'
          ref={inputRef}
          onClick={() => {

            setState(prevState => ({ ...prevState, showInput: true }))
          }}
        >
          {/* condition to render the text */}
          <p>{isEmpty(initialSearchValue) ? placeholder : initialSearchValue}</p>
          <SearchIcon />
        </div>
      )
    }
  }

  // renders the search dropdown
  const renderSearchDropdown = () => {
    if (state.showDropdown && searchStatus.isSearched && !isEmpty(initialSearchValue)) {
      // condition based on search count
      if (searchCount !== 0) {
        return (
          <div className='searchFilterWrapper' ref={dropdownRef}>
            <div className='container'>
              <ul>
                <span className='result'> TOP RESULTS </span>
                {/* mapping the search list */}
                {searchList.map((item, index) => (
                  <li key={item.slug}>
                    <a
                      href={`/${objectType}/${item.slug}`}
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/${objectType}/${item.slug}`)
                      }}
                    >
                      {item.title}
                    </a>
                  </li>
                ))}
                <span className='showResult'>
                  <a
                    href={objectType === 'recipe' ? `/recipes?search=${initialSearchValue}&search_by=${state.searchByValue}` : `/${objectType}${objectType !== 'blog' ? 's' : ''}?search=${initialSearchValue}`}
                    onClick={(e) => {
                      e.preventDefault()
                      setState({ ...state, showDropdown: false })
                      dropDownReturnAction(initialSearchValue, state.searchByValue)
                    }}
                  >
                    {dropDownReturnActionText}
                  </a>
                </span>
              </ul>
            </div>
          </div>
        )
      } else {
        return (
          <div className='searchFilterWrapper'>
            <div className='container'>
              <p className="noresults-text">No results found for <span>{initialSearchValue}</span></p>
            </div>
          </div>
        )
      }
    }
  }

  // renders the search input icons
  const renderInputIcon = () => {
    if (searchStatus.isSearching) {
      return (
        <Spinner
          className="spinner"
          size={15}
          spinnerColor={themeTextRed}
          spinnerWidth={2}
          visible={true}
        />
      )
    } else if (!searchStatus.isSearching && initialSearchValue.length > 0) {
      return (
        <span
          className="relativeIcon"
          ref={closeIconRef}
          onClick={() => {
            updateInputValue('')
            setState({ ...state, showInput: false })
            onClickSearchClear();
          }}
        >
          <CloseIcon />
        </span>
      )
    } else {
      return <SearchIcon />
    }
  }

  const renderProperInput = () => {
    if (isResponsive) {
      return (
        <Headeroom
          pinStart={60}
          wrapperStyle={{ position: 'relative', zIndex: '-1' }}
          style={{
          }}>
          {renderInput()}
        </Headeroom>
      )
    } else {
      return (
        <>
          {renderInput()}
        </>
      )
    }
  };

  const renderDropdown = () => {
    if (isResponsive) {
      return (
        <Headeroom
          pinStart={60}
          wrapperStyle={{ position: 'relative', zIndex: '-1' }}
          style={{
          }}>
          {renderSearchDropdown()}
        </Headeroom>
      )
    } else {
      return (
        <>
          {renderSearchDropdown()}
        </>
      )
    }
  };

  // MAIN RETURN
  return (
    <>
      <div className="container">
        <div className='searchInputWrapper' ref={ref}>
          <div className="fWrap">
            {/* the search input */}
            {props.children}
          </div>
          {/* {renderInputIcon()} */}
          {/* condition to render search dropdown */}
          {/* condition to render the nav filter */}
        </div>
        {renderProperInput()}
      </div>
      {state.showDropdown ? renderDropdown() : null}
    </>
  )
}

SearchInput.propTypes = {
  onChange: PropTypes.func,
  searchText: PropTypes.string,
  selectorList: PropTypes.array,
  defaultSearchBy: PropTypes.string,
  dropDownReturnActionText: PropTypes.string,
  dropDownReturnAction: PropTypes.func,
  placeholder: PropTypes.string,
  searchList: PropTypes.array,
  searchStatus: PropTypes.object,
  searchCount: PropTypes.number,
  onSearchByChange: PropTypes.func,
  initialSearchValue: PropTypes.string,
  objectType: PropTypes.string,
  isResponsive: PropTypes.bool,
}

SearchInput.defaultProps = {
  onChange: () => { },
  selectorList: [],
  defaultSearchBy: null,
  dropDownReturnActionText: '',
  dropDownReturnAction: () => { },
  placeholder: '',
  searchList: [],
  searchStatus: {},
  searchCount: 0,
  onSearchByChange: () => { },
  initialSearchValue: '',
  objectType: 'recipe',
  isResponsive: false,
  onClickSearchClear: () => { }
  // searchText: ''
}

export default SearchInput