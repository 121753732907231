import React from "react";

import replace from "lodash/replace";

import { Row, Col } from "react-flexbox-grid";
import VerticalListWithTitle from "./common/verticalListWithTitle";
import NavbarImageCard from "./common/navbarImageCard";
import NavDropdownImage from "./common/NavDropdownImage";
import blog1 from "../../images/blog1.png";

const VerticalListOne = [
  {
    title: "Getting Started",
    href: "/blog?categories=getting-started",
  },
  {
    title: "Healthy Entertaining",
    href: "/blog?categories=entertaining",
  },
  {
    title: "Friday Fives",
    href: "/blog?categories=friday-five",
  },
  {
    title: "Grocery Hauls",
    href: "/blog?categories=grocery-hauls",
  },
];

const VerticalListTwo = [
  {
    title: "Kids / Family",
    href: "/blog?categories=sarah-shaffer",
  },
  {
    title: "Motivation",
    href: "/blog?categories=inspiration",
  },
  {
    title: "Healthy Lifestyle tips",
    href: "/blog?categories=healthy-lifestyle-tips",
  },
  {
    title: "Meal Planning",
    href: "/blog?categories=meal-planning",
  },
];

const VerticalListThree = [
  {
    title: "Bible Studies & Prayer",
    href: "/blog?categories=bible-studies",
  },
  {
    title: "Beauty for Ashes",
    href: "/blog?categories=beauty-for-ashes",
  },
  {
    title: "Devotionals",
    href: "/blog?categories=dashing-devotionals",
  },
  {
    title: "Disordered Eating",
    href: "/blog?categories=disordered-eating",
  },
];

const listTwo = (blogFeaturedList) => {
  return [
    {
      image:
        blogFeaturedList && blogFeaturedList.length > 0
          ? blogFeaturedList[0].image
          : "",
      description:
        blogFeaturedList && blogFeaturedList.length > 0
          ? blogFeaturedList[0].title
          : "",
      href:
        blogFeaturedList && blogFeaturedList.length > 0
          ? `/blog/${blogFeaturedList[0].slug}`
          : "",
    },
  ];
};

const BlogNavDropdown = ({
  history,
  blogFeaturedList,
  requestCloseNavDropdown,
}) => (
  <div className="container">
    <div className="blog-dropdown navbar-dropdown-wrapper">
      <VerticalListWithTitle
        title="Fun & Food"
        list={VerticalListOne}
        history={history}
        onClose={requestCloseNavDropdown}
      />

      <VerticalListWithTitle
        title="Tips & Tricks"
        list={VerticalListTwo}
        history={history}
        onClose={requestCloseNavDropdown}
      />

      <VerticalListWithTitle
        title="Spiritual"
        list={VerticalListThree}
        history={history}
        onClose={requestCloseNavDropdown}
      />
      <div className="blog-NavbarImageCard no-padding">
        <NavbarImageCard
          list={listTwo(blogFeaturedList)}
          onClose={requestCloseNavDropdown}
          history={history}
          render={(item) => (
            <NavDropdownImage
              type="blog"
              src={replace(item.image, "large", "thumb")}
              href={item.href}
            />
          )}
        />
      </div>
    </div>
  </div>
);

export default BlogNavDropdown;
