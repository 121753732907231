import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const CalenderLeftArrow = (props) => {
  return (
    <>
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.65662 13.3136L1.99977 7.65674L7.65662 1.99988" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default CalenderLeftArrow;
