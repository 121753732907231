import axios from 'axios';
import { get, post, remove } from '../../helpers/requests';
import { favourites } from '../../helpers/api';
import { call, select, put } from 'redux-saga/effects';
import { getToken, getSelectedFilters, getSelectedFiltersWorkouts, getRecipeOffset } from '../selectors'

export default {
  get: function* getFavorites() {
    const token = yield select(getToken);
    const favoritesResponse = yield call(axios, get(favourites.get(), '', token))
    return favoritesResponse
  },
  post: function* addFavourite(favouriteObject) {
    const token = yield select(getToken);
    
    // To remove boards key if no boards are selected    
    const favObj = (favouriteObject) => {
 
      if(favouriteObject.boards.length === 0) {
        const {object_id, object_type} = favouriteObject;
        return {object_id, object_type};
      }
      else return favouriteObject;
    } 

    const favouritesResponse = yield call(axios, post(favourites.post(), favObj(favouriteObject), '' , token))
    return favouritesResponse
  },
  delete: function* deleteFavourite(delFavObject) {
    const token = yield select(getToken)
    const favouritesResponse = yield call(axios, remove(favourites.delete(), delFavObject, '' , token))
    return favouritesResponse
  },
  getAllFavoritesInBoard: function* getAllFavoritesInBoard(type, payload) {
    const token = yield select(getToken)
    let selectedFilters;
    let offset
    if (payload.objectType === 'recipe') {
      selectedFilters = yield select(getSelectedFilters);
      offset = yield select(getRecipeOffset)
    } else {
      selectedFilters = yield select(getSelectedFiltersWorkouts);
      
    }
    const favouritesResponse = yield call(axios, get(favourites.getAllFavoritesInBoard(payload.objectType, payload.boardId, selectedFilters.getIn(['search']), undefined, offset + 1), '' , token))
    let favoritesData = favouritesResponse.data.items.map(item => {
      let recipe = item[payload.objectType]
      item = recipe.boards = item.boards;
      return recipe
    })
    yield put({
      type,
      payload: {
        favoritesData: favoritesData,
        objectType: payload.objectType,
        replace: payload.replace
      }
    })
    return favouritesResponse
  },
  getAllFavzoritesInBoardWorkouts: function* getAllFavoritesInBoard(type, payload) {
    const token = yield select(getToken)
    const favouritesResponse = yield call(axios, get(favourites.getAllFavoritesInBoard(payload.objectType, payload.boardId), '' , token))
    const favoritesData = favouritesResponse.data.items.map(item => {
      item.workout.boards = item.boards;
      return item.workout
    })
    yield put({
      type,
      payload: {
        favoritesData: favoritesData,
        objectType: payload.objectType,
        replace: payload.replace
      }
    })
    return favouritesResponse
  }
} 
