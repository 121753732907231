import React from 'react'
import '../styles/common.scss';

const SearchIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.18463 10.6036L1 15.7943M15.7943 6.42577C15.7943 9.42234 13.3651 11.8515 10.3685 11.8515C7.37191 11.8515 4.94271 9.42234 4.94271 6.42577C4.94271 3.4292 7.37191 1 10.3685 1C13.3651 1 15.7943 3.4292 15.7943 6.42577Z" stroke="#B5B5B5" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default SearchIcon;