import trim from 'lodash/trim'
import join from 'lodash/join'
import filter from 'lodash/filter'
import replace from 'lodash/replace'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import isNaN from 'lodash/isNaN'
import groupBy from 'lodash/groupBy'
import capitalize from 'lodash/capitalize'
import findIndex from 'lodash/findIndex'

import pushToSentry from '../helpers/pushToSentry'

import Fraction from 'fraction.js';
import { List, fromJS, update } from 'immutable'

import extractNumbers from 'extract-numbers';

import { mergeableIngredients, mergeableQuantities } from './groceryMerger'
import { normalizeAmounts } from './ingredientHelpers'
import { isEmpty } from 'lodash'

// converts all the ingredient to unit name format. Partially like that in DB
const convertToV2Grocery = item => {
  const amount = item.getIn(['amount']);
  const itemName = item.getIn(['item']);

  if (!amount) {
    // If v2 grocery item
    // ItemName itself contains everything
    return toLower((itemName));
  } else {
    // if v1 grocery item
    // For Grouping, this step is important

    return `${((amount))} ${toLower((itemName))}`
  }
}

// Checks if an unit is mixed decimal
const isUnitMixedDecimal = ingredient => {
  const splitIngredient = ingredient.split(' ');
  // Check first character of 2nd item is equal to a number
  return (splitIngredient.length >= 2 && !isNaN(parseInt(splitIngredient[1][0])));
}

// Extracts the name from index 1
const extractIngredientName = (grocery) => {
  const isMixedDecimal = isUnitMixedDecimal(grocery)

  return (join(filter(grocery.split(' '), (grocery, index) => {
    if (index === 0 || (isMixedDecimal && index === 1)) {
      return false
    } else {
      return true;
    }
  }), ' '));
}

// Extracts just the amount
const extractIngredientAmount = (grocery) => {
  const isMixedDecimal = isUnitMixedDecimal(grocery)

  const result = (join(filter(grocery.split(' '), (item, index) => {
    return index === 0 || (isMixedDecimal && index == 1)
  }), ' '));

  return result
}

const sumTwoRange = (rangeFirst, rangeSecond) => {
  const rfFractionStart = new Fraction(rangeFirst.split('-')[0]);
  const rsFractionStart = new Fraction(rangeSecond.split('-')[0]);

  const rfFractionEnd = new Fraction(rangeFirst.split('-')[1]);
  const rsFractionEnd = new Fraction(rangeSecond.split('-')[1]);

  const startFraction = rfFractionStart.add(rsFractionStart);
  const endFraction = rfFractionEnd.add(rsFractionEnd);

  return `${startFraction.toFraction(true)}-${endFraction.toFraction(true)}`;
}

const getQuantityWithTypeFromIngredient = (ingredient) => {

  const amountString = extractIngredientAmount(ingredient);
  try {
    if (includes(amountString, '-')) {
      return {
        type: 'range',
        quantity: amountString,
      }
    }
    if (isUnitMixedDecimal(ingredient)) {
      return {
        type: 'mixedFraction',
        quantity: new Fraction(amountString).toFraction(true)
      }
    }

    const amount = eval(amountString)
    if (!isNaN(amount)) {
      return {
        type: 'number',
        quantity: eval(amountString),
      }
    } else if (isNaN(amountString)) {

      if (includes(amountString, '-')) {
        return {
          type: 'range',
          quantity: amountString,
        }
      } else if (includes(amountString, '/') && !isNaN(eval(amountString))) {
        // Fraction

        return {
          type: 'number',
          quantity: eval(amountString),
        }
      } else {
        return {
          // Normal string with no units
          type: 'string',
          quantity: amountString
        }
      }
    }
  } catch (e) {
    return {
      type: 'string',
      quantity: ingredient
    }
  }
}

const isMergableIngredient = (ingredientName) => {

  // Check if mergable index has the ingredient
  const mergeableIngredientIndex = findIndex(mergeableIngredients, (eachIngredient) => {
    const aliasIndex = findIndex(eachIngredient.alias, eachAlias => toLower(eachAlias) === toLower(trim(ingredientName)))
    return aliasIndex !== -1
  })

  // If alias found then send the title of the alias group
  return mergeableIngredientIndex !== -1
}

const isMergableQuantity = (quantity) => {

  // Check if mergable index has the ingredient
  const mergableQuantityIndex = findIndex(mergeableQuantities, (eachQuantity) => {
    const aliasIndex = findIndex(eachQuantity.alias, eachAlias => toLower(eachAlias) === toLower(trim(quantity)))
    return aliasIndex !== -1
  })

  // If alias found then send the title of the alias group
  return mergableQuantityIndex !== -1
}

const getUnitAndNameFromIngredient = ingredient => {
  const ingredientName = extractIngredientName(ingredient)

  const splitIngredientName = ingredientName.split(' ');

  // If there is only one item
  if (splitIngredientName.length === 1) {
    return {
      unit: splitIngredientName[0],
      name: ''
    }
  } else {
    let newIngredientName = ''
    if (splitIngredientName.length >= 3 && splitIngredientName[0][0] === '(' && splitIngredientName[1][splitIngredientName[1].length - 1] === ')') {
      // Handle (16 oz) case
      for (var i = 3; i < splitIngredientName.length; i++) {
        newIngredientName += splitIngredientName[i] + ((i !== splitIngredientName.length - 1) ? ' ' : '')
      }
      return {
        unit: splitIngredientName[2],
        unitMeta: splitIngredientName[0] + ' ' + splitIngredientName[1],
        name: newIngredientName
      }
    } else {
      let newIngredientName = ''
      let fullIngredientName = ''

      for (var i = 0; i < splitIngredientName.length; i++) {
        fullIngredientName += splitIngredientName[i] + ((i !== splitIngredientName.length - 1) ? ' ' : '')
      }

      // 2 chicken breast
      if (isMergableIngredient(fullIngredientName)) {
        return {
          unit: '',
          name: fullIngredientName
        }
      } else {
        // 2 head chicken breast
        for (var i = 1; i < splitIngredientName.length; i++) {
          newIngredientName += splitIngredientName[i] + ((i !== splitIngredientName.length - 1) ? ' ' : '')
        }
        return {
          unit: splitIngredientName[0],
          name: newIngredientName
        }
      }

    }
  }
}

const splitIngredient = (ingredient) => {
  const {
    unit,
    name,
    unitMeta = ''
  } = getUnitAndNameFromIngredient(ingredient)

  const {
    quantity,
    type
  } = getQuantityWithTypeFromIngredient(ingredient)

  return {
    quantity,
    unit,
    name,
    unitMeta,
    type,
  }
}

// Groups same ingredients data
// Used for aisle key value pair grouping
const groupSameIngredientData = (list, itemKey) => list
  .toSeq()
  .reduce((acc, val) => acc.push(val.getIn([itemKey])), List());

const reduceSameIngredientOfMultipleOccurance = value => {
  let parsedValueType = ''
  let unit = ''
  let name = ''
  let unitMeta = ''
  const reducedSet = value
    .toSeq()
    .reduce((acc, val) => {
      const grocery = convertToV2Grocery(val);

      const {
        quantity,
        unit: currentUnit,
        name: currentName,
        unitMeta: currentUnitMeta,
        type,
      } = splitIngredient(grocery);

      unit = currentUnit,
        name = currentName,
        unitMeta = currentUnitMeta;

      try {
        if (type === 'number') {
          acc = (new Fraction(acc).add(new Fraction(quantity))).toFraction(true);
        } else if (type === 'mixedFraction') {

          if (acc || isNaN(acc)) {
            // if this is already encountered
            acc = (new Fraction(acc).add(new Fraction(quantity))).toFraction(true);
          }
        } else if (type === 'range') {
          if (acc || isNaN(acc)) {
            // if this is already encountered
            acc = sumTwoRange(acc, quantity)
          } else {
            acc = quantity;
          }

        } else if (type === 'string') {
          parsedValueType = 'string'
          // If first time this value is encountered
          acc = quantity;
        }
      } catch (e) {
        parsedValueType = 'string'
        acc = grocery
        // Do nothing since the text is not parsable
      }
      return acc;
    }, '0');

  // if unit is present
  if (name) {
    return {
      quantity: reducedSet,
      type: parsedValueType,
      unit,
      name,
      unitMeta
    }
  } else {
    return {
      quantity: reducedSet,
      type: parsedValueType,
      unit,
      name: '',
      unitMeta
    }
  }

}

const getSingularizedIngredient = (ingredientName) => {
  const ingredientIndex = findIndex(mergeableIngredients, (eachIngredient) => {
    const aliasIndex = findIndex(eachIngredient.alias, eachAlias => toLower(eachAlias) === toLower(trim(ingredientName)))
    return aliasIndex !== -1
  })

  // If alias found then send the title of the alias group
  return ingredientIndex === -1 ? ingredientName : mergeableIngredients[ingredientIndex].singular
}

const getSingularizedUnit = (quantity) => {
  const quantityIndex = findIndex(mergeableQuantities, (eachQuantity) => {
    const aliasIndex = findIndex(eachQuantity.alias, eachAlias => toLower(eachAlias) === toLower(trim(quantity)))
    return aliasIndex !== -1
  })

  // If alias found then send the title of the alias group
  return quantityIndex === -1 ? quantity : mergeableQuantities[quantityIndex].singular
}

const getPluralizedIngredient = (ingredientName) => {
  const ingredientIndex = findIndex(mergeableIngredients, (eachIngredient) => {
    const aliasIndex = findIndex(eachIngredient.alias, eachAlias => toLower(eachAlias) === toLower(trim(ingredientName)))
    return aliasIndex !== -1
  })

  // If alias found then send the title of the alias group
  return ingredientIndex === -1 ? ingredientName : mergeableIngredients[ingredientIndex].plural
}

const getPluralizedUnit = (quantity) => {
  const quantityIndex = findIndex(mergeableQuantities, (eachQuantity) => {
    const aliasIndex = findIndex(eachQuantity.alias, eachAlias => toLower(eachAlias) === toLower(trim(quantity)))
    return aliasIndex !== -1
  })

  // If alias found then send the title of the alias group
  return quantityIndex === -1 ? quantity : mergeableQuantities[quantityIndex].plural
}

const mergeIngredientNames = (ingredientName) => {

  // Check if mergable index has the ingredient
  const mergeableIngredientIndex = findIndex(mergeableIngredients, (eachIngredient) => {
    const aliasIndex = findIndex(eachIngredient.alias, eachAlias => toLower(eachAlias) === toLower(trim(ingredientName)))
    return aliasIndex !== -1
  })

  // If alias found then send the title of the alias group
  return mergeableIngredientIndex === -1 ? ingredientName : mergeableIngredients[mergeableIngredientIndex].title
}

// Used for aisle grouping
const groupKnownIngredients = ({
  name,
  unit,
  unitMeta
}) => {
  const groupByName = `${unitMeta} ${getSingularizedUnit(unit)} ${getSingularizedIngredient(mergeIngredientNames(name))}`
  return groupByName;
}

const isIngredientStandardized = ingredient => ingredient.ingredient_name && ingredient.ingredient_unit;

export const groceryListMergingByAisle = (list) => {

  // If view type is aisle
  const updatedCategories = list
    .getIn(['categories'])
    .toSeq()
    .map(category => {

      // This loop would return an updated category with grouped elements
      let currentItemsForTheCategory = category.getIn(['items']);

      const groceryListItemsForCurrentCategories = currentItemsForTheCategory.toJS();

      const mergeableGroceryListItemsForCurrentCategories = groceryListItemsForCurrentCategories
        .filter(isIngredientStandardized)

      const unmergeableGroceryListItemsForCurrentCategories = groceryListItemsForCurrentCategories
        .filter(item => !isIngredientStandardized(item))


      const unmergableListItems = unmergeableGroceryListItemsForCurrentCategories
        .map((glistItem) => ({
          name: glistItem.item,
          item: glistItem.item,
          categoryId: [glistItem.category_id],
          id: [glistItem.id],
          recipe: [glistItem.recipe],
        }));

      const groupedGroceryList = groupBy(mergeableGroceryListItemsForCurrentCategories, (item) => {
        return item.ingredient_name;
      });

      const groupedIngredientNames = Object.keys(groupedGroceryList);

      const groupedIngredientsAfterMerging = groupedIngredientNames.reduce((accumulator, ingredientName) => {
        const glistItemForIngredient = groupedGroceryList[ingredientName];
        const conversionRule = glistItemForIngredient[0].conversion_rules;

        try {

          const amounts = glistItemForIngredient.map(glistItem => ({
            quantity: new Fraction(glistItem.amount || '0').toFraction(true),
            unit: glistItem.ingredient_unit,
            unitPlural: glistItem.ingredient_unit_plural,
            id: glistItem.id,
            recipe: glistItem.recipe,
            categoryId: glistItem.category_id
          }));

          // Normalize all ingredients to base unit by converting the quantities

          const {
            groupedGroceryListItem,
            normalizedUnmergebleGlistItems
          } = normalizeAmounts(amounts, conversionRule, ingredientName)

          if (!isEmpty(groupedGroceryListItem)) {
            accumulator.push(groupedGroceryListItem);
          }

          accumulator = accumulator.concat(normalizedUnmergebleGlistItems);

          return accumulator
        } catch (e) {
          console.log('error is ', e);
          return accumulator;
        }
      }, []);


      // console.log('grouped ingredients ', groupedIngredientsAfterMerging);
      return category.setIn(['items'], fromJS([...groupedIngredientsAfterMerging, ...unmergableListItems]));
    })

  return updatedCategories
}

export const groceryListMergingByRecipe = (list) => {
  const groceryList = list.toJS();

  let allIngredients = groceryList.categories.reduce((accumulator, category) => {

    const itemsInCategory = category.items;

    accumulator = accumulator.concat(itemsInCategory);

    return accumulator;
  }, []);

  allIngredients = groupBy(allIngredients, ingredient => ingredient.recipe_id);

  const recipeIds = Object.keys(allIngredients);

  const byRecipe = recipeIds.reduce((accumulator, recipeId) => {
    const ingredientsForRecipe = allIngredients[recipeId];
    const mergeableGroceryListItemsForCurrentCategories = allIngredients[recipeId]
      .filter(isIngredientStandardized)

    const unmergeableGroceryListItemsForCurrentCategories = allIngredients[recipeId]
      .filter(item => !isIngredientStandardized(item))

    const unmergableListItems = unmergeableGroceryListItemsForCurrentCategories
      .map((glistItem) => ({
        name: glistItem.item,
        item: glistItem.item,
        categoryId: [glistItem.category_id],
        id: [glistItem.id],
        recipe: [glistItem.recipe],
      }));

    const groupedGroceryListForRecipe = groupBy(mergeableGroceryListItemsForCurrentCategories, (item) => {
      return item.ingredient_name;
    });


    const groupedIngredientNamesForRecipe = Object.keys(groupedGroceryListForRecipe);

    const groupedIngredientsAfterMerging = groupedIngredientNamesForRecipe.reduce((accumulator, ingredientName) => {
      try {
        const glistItemForIngredient = groupedGroceryListForRecipe[ingredientName];
        const conversionRule = glistItemForIngredient[0].conversion_rules;

        const amounts = glistItemForIngredient.map(glistItem => ({
          quantity: new Fraction(glistItem.amount || '0').toFraction(true),
          unit: glistItem.ingredient_unit,
          unitPlural: glistItem.ingredient_unit_plural,
          id: glistItem.id,
          recipe: glistItem.recipe,
          categoryId: glistItem.category_id
        }));

        // Normalize all ingredients to base unit by converting the quantities

        const {
          groupedGroceryListItem,
          normalizedUnmergebleGlistItems
        } = normalizeAmounts(amounts, conversionRule, ingredientName)

        if (!isEmpty(groupedGroceryListItem)) {
          accumulator.push(groupedGroceryListItem);
        }

        accumulator = accumulator.concat(normalizedUnmergebleGlistItems);

        return accumulator
      } catch (e) {
        console.log('error is ', e);
        return accumulator;
      }
    }, []);

    accumulator.push({
      recipeId,
      name: [...groupedIngredientsAfterMerging, ...unmergableListItems][0]?.recipe[0]?.title,
      items: [...groupedIngredientsAfterMerging, ...unmergableListItems]
    })

    return accumulator;
  }, []);

  return fromJS(byRecipe)
}