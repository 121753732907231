import axios from 'axios'
import { map } from 'lodash';
import { get, post } from '../../helpers/requests'

import { recipes, favourites } from '../../helpers/api'

import { call, select, put } from 'redux-saga/effects'

import {
  getToken,
  getAppliedRecipeFilters,
  getAppliedRecipeFavorites,
  getRecipeFilteredAppliedOnce,
  getIsRecipeFavoritedList,
  getRecipeDirtyGroup,
  getRecipeDietaries,
  getRecipeFoodTypes,
  getUnFilteredRecipeList,
  getRecipeBoards,
  getRecipeSpecialities,
  getFeaturedRecipes,
  getCachedRecipeList,
  getRecipeFiltersSetUpdatedPrematurelyStatus,
  getRecipeCollections,
} from '../selectors'
import * as actionTypes from '../../actions/actionTypes';
import { generateRecipeQueryFromFilter } from '../../helpers/filterConversions';

const getUnfilteredRecipesData = function* getUnfilteredData(recipe) {
  const token = yield select(getToken)
  // Don't fetch unfiltered recipe list if already fetched
  const unfilteredRecipeList = yield select(getUnFilteredRecipeList);
  let unfilteredRecipesResponse;

  let unfilteredRecipesData;
  if (unfilteredRecipeList.isEmpty() || recipe.recipeRefreshGroup === 'unfiltered') {
    yield put({
      type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
      payload: {
        listType: 'unfiltered',
        objectType: 'recipe',
        pullToRefresh: recipe.recipeRefreshGroup === 'unfiltered'
      }
    });
    unfilteredRecipesResponse = yield call(axios, get(
      recipes.getAll(recipe.limit, recipe.offset, ''),
      '',
      token
    ));
    yield put({
      type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
      payload: {
        listType: 'unfiltered',
        objectType: 'recipe',
        pullToRefresh: recipe.recipeRefreshGroup === 'unfiltered'
      }
    });

    unfilteredRecipesData = unfilteredRecipesResponse ? unfilteredRecipesResponse.data.recipes : [];
  } else {
    unfilteredRecipesData = null; // In redux it will be in immutable format
  }

  return unfilteredRecipesData;
};

export default {
  // Get Single recipe details
  get: function* getSingleRecipe(objectSlug) {
    const token = yield select(getToken);
    const recipeResponse = yield call(axios, get(recipes.get(objectSlug), '', token));
    return recipeResponse;
  },
  // Gets all recipes as per existing filters / search / favorites
  getAll: function* getAllRecipes(recipe) {
    const appliedRecipeFilters = yield select(getAppliedRecipeFilters);

    const recipeFoodTypes = yield select(getRecipeFoodTypes);
    const recipeDietaries = yield select(getRecipeDietaries);
    const recipeSpecialities = yield select(getRecipeSpecialities)
    const collections = yield select(getRecipeCollections)

    const filtersSetUpdatedPrematurely = yield select(getRecipeFiltersSetUpdatedPrematurelyStatus);

    // Get the token
    const token = yield select(getToken);
    const dirtyGroup = yield select(getRecipeDirtyGroup);

    // These will be toggled in each cases
    let filteredRecipesResponse;
    let unfilteredRecipesResponse;
    if (dirtyGroup === 'filters' || recipe.recipeRefreshGroup === 'filters' || filtersSetUpdatedPrematurely) {
      // Dispatching to Set Particular Fetch Status

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'recipe'
        }
      });

      // If to be fetched is filters
      filteredRecipesResponse = yield call(axios, get(
        recipes.getAll(recipe.limit, recipe.offset, generateRecipeQueryFromFilter(
          appliedRecipeFilters,
          recipeFoodTypes,
          recipeDietaries,
          recipeSpecialities,
          collections
        )), '', token)
      );

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'recipe'
        }
      });
      // This will fetch new set of data for the newly applied filter
      return {
        filteredRecipesData: filteredRecipesResponse && filteredRecipesResponse.data.recipes ? filteredRecipesResponse.data.recipes : null,
        unfilteredRecipesData: yield call(getUnfilteredRecipesData, recipe)
      }
    } else if (dirtyGroup === 'favorites' || recipe.recipeRefreshGroup === 'favorites') {

      // These will be toggled in each cases
      let filteredRecipesResponse;
      let unfilteredRecipesResponse;

      // Get the favorite board id
      let appliedRecipeFavorites = yield select(getAppliedRecipeFavorites);
      appliedRecipeFavorites = appliedRecipeFavorites.toJS();
      let favoriteBoardSlug = appliedRecipeFavorites.board;
      let favoriteBoardId;
      if (favoriteBoardSlug) {
        const allBoard = yield select(getRecipeBoards);
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorited',
          objectType: 'recipe'
        }
      });
      // Favorites API fetch
      filteredRecipesResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'recipe',
          favoriteBoardId,
          recipe.limit,
          recipe.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorited',
          objectType: 'recipe'
        }
      });
      const formattedRecipesData = filteredRecipesResponse.data.items.map(item => ({
        ...item,
        ...item.recipe,
      }));
      return {
        filteredRecipesData: filteredRecipesResponse && filteredRecipesResponse.data.items ? formattedRecipesData : null,
        unfilteredRecipesData: yield call(getUnfilteredRecipesData, recipe)
      }
    } else if (dirtyGroup === 'featuredCollection') {
      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
        payload: {
          listType: 'featured',
          objectType: 'recipe'
        }
      });

      filteredRecipesResponse = yield call(axios, get(
        recipes.getAll(recipe.limit, recipe.offset, generateRecipeQueryFromFilter(
          {
            ...appliedRecipeFilters.toJS(),
            collection: [recipe.featured.slug],
          },
          recipeFoodTypes,
          recipeDietaries,
          recipeSpecialities,
          collections
        )), '', token)
      );

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'featured',
          objectType: 'recipe',
        }
      });


      // This is just the filtered data aka featuredCollection
      return {
        filteredRecipesData: [],
        unfilteredRecipesData: yield call(getUnfilteredRecipesData, recipe),
        featuredCollectionList: filteredRecipesResponse && filteredRecipesResponse.data.recipes ? filteredRecipesResponse.data.recipes : null
      }
    } else {

      const filteredRecipesData = yield select(getCachedRecipeList)
      // Caching for clear list
      return {
        filteredRecipesData: filteredRecipesData.toJS(),
        unfilteredRecipesData: yield call(getUnfilteredRecipesData, recipe),
      }
    }
  },
  // Gets more recipes as per existing filters / search / favorites
  getMore: function* getMoreRecipes(recipe) {
    const token = yield select(getToken);
    let offset = recipe.offset * recipe.limit;
    let featuredCollection = yield select(getFeaturedRecipes);
    featuredCollection = featuredCollection.toJS();

    // filters
    const appliedRecipeFilters = yield select(getAppliedRecipeFilters);

    // Favorite related
    let appliedRecipeFavorites = yield select(getAppliedRecipeFavorites);
    appliedRecipeFavorites = appliedRecipeFavorites.toJS();
    const favoriteBoardSlug = appliedRecipeFavorites.board;

    let filteredRecipesResponse;
    let unfilteredRecipesResponse;
    const recipeFoodTypes = yield select(getRecipeFoodTypes);
    const recipeDietaries = yield select(getRecipeDietaries);
    const recipeSpecialities = yield select(getRecipeSpecialities);
    const collections = yield select(getRecipeCollections);

    if (recipe.filterGroup == 'filters') {

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'recipe',
          pagination: true
        }
      });

      filteredRecipesResponse = yield call(axios, get(
        recipes.getAll(recipe.limit, offset, generateRecipeQueryFromFilter(
          appliedRecipeFilters,
          recipeFoodTypes,
          recipeDietaries,
          recipeSpecialities,
          collections
        )),
        '',
        token)
      );
      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'recipe',
          pagination: true
        }
      });
      return {
        filteredRecipesData: filteredRecipesResponse && filteredRecipesResponse.data.recipes.length > 0 ? filteredRecipesResponse.data.recipes : null,
        filteredRecipesCount: filteredRecipesResponse && filteredRecipesResponse.data.recipes.length > 0 ? filteredRecipesResponse.data.count : 0,
        // Not sending unfiltered data in filters
      }
    } else if (recipe.filterGroup == 'featuredCollection') {
      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
        payload: {
          listType: 'featured',
          objectType: 'recipe',
          pagination: true
        }
      });

      filteredRecipesResponse = yield call(axios, get(
        recipes.getAll(recipe.limit, offset, generateRecipeQueryFromFilter(
          {
            ...appliedRecipeFilters.toJS(),
            collection: [featuredCollection.slug],
          },
          recipeFoodTypes,
          recipeDietaries,
          recipeSpecialities,
          collections
        )), '', token)
      );

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'featured',
          objectType: 'recipe',
          pagination: true,
        }
      });

      // This is just the filtered data aka featuredCollection
      return {
        filteredRecipesData: [],
        unfilteredRecipesData: yield call(getUnfilteredRecipesData, recipe),
        featuredCollectionData: filteredRecipesResponse && filteredRecipesResponse.data.recipes ? filteredRecipesResponse.data.recipes : null
      }

    } else if (recipe.filterGroup == 'favorites') {

      // Slug to id conversions
      let appliedRecipeFavorites = yield select(getAppliedRecipeFavorites);
      appliedRecipeFavorites = appliedRecipeFavorites.toJS();
      let favoriteBoardSlug = appliedRecipeFavorites.board;
      let favoriteBoardId;

      const allBoard = yield select(getRecipeBoards);
      if (favoriteBoardSlug) {
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorited',
          objectType: 'recipe',
          pagination: true
        }
      });
      // Favorites API fetch
      filteredRecipesResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'recipe',
          favoriteBoardId, // TODO: Board should be converted from slug to id
          recipe.limit,
          recipe.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorited',
          objectType: 'recipe',
          pagination: true
        }
      });
      // Apply both since recipe is nested
      const formattedRecipesData = filteredRecipesResponse.data.items.map(item => ({
        ...item,
        ...item.recipe,
      }));

      return {
        filteredRecipesData: filteredRecipesResponse && filteredRecipesResponse.data.items ? formattedRecipesData : null,
        unfilteredRecipesData: null,
        filteredRecipesCount: filteredRecipesResponse && filteredRecipesResponse.data.recipes ? filteredRecipesResponse.data.count : 0,
        unfilteredRecipesCount: 0
      }
    } else {
      // No filters or favorites
      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_REQUEST,
        payload: {
          listType: 'unfiltered',
          objectType: 'recipe',
          pagination: true
        }
      });

      unfilteredRecipesResponse = yield call(axios, get(
        recipes.getAll(recipe.limit, offset),
        '',
        token,
      ));

      yield put({
        type: actionTypes.RECIPES_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'unfiltered',
          objectType: 'recipe',
          pagination: true
        }
      });
      return {
        filteredRecipesData: null,
        unfilteredRecipesData: unfilteredRecipesResponse && unfilteredRecipesResponse.data.recipes.length > 0 ? unfilteredRecipesResponse.data.recipes : null,
        filteredRecipesCount: 0,
        unfilteredRecipesCount: unfilteredRecipesResponse && unfilteredRecipesResponse.data.recipes.length > 0 ? unfilteredRecipesResponse.data.count : 0
      };
    }
  },
} 