import React from "react";
import '../styles/common.scss';

const UpArrowLarge = (props) => {
  return (
    <>
      <svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" className="arrowIconLarge">
        <path id="upArrowlarge.svg" class="arrow" d="M448.991,533.986a1,1,0,0,0-.707.294l-5.994,6.012a1,1,0,1,0,1.413,1.417l5.288-5.3,5.288,5.3a1,1,0,1,0,1.413-1.417l-6-6.012A0.994,0.994,0,0,0,448.991,533.986Z" transform="translate(-442 -534)" />
      </svg>

    </>
  );
};
export default UpArrowLarge;
