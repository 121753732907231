import React, { useState } from 'react'
import Spinner from 'react-spinner-material';
import Avatar from 'react-avatar';
import includes from 'lodash/includes'
import moment from 'moment';
import { map } from 'lodash';
import capitalize from 'lodash/capitalize'
import ReactImageFallback from "react-image-fallback";

import Button from './button';
import LinkButton from '../common/linkButton'
import StarRating from './rating';

import '../styles/common.scss';
import AvatarIcon from '../images/avartar';
import VerifiedIcon from '../images/VerifiedIcon';
import Linkify from 'react-linkify';

const isValidAvatar = avatar => includes(avatar, 'amazonaws')

const Comments = React.forwardRef((props, ref) => {
  const [state, setState] = useState({
    commentText: '',
    showReplyCommentModal: false,
    commentId: 0,
    replyCommentText: '',
    starRatingValue: 0
  })

  const {
    // selectors
    objectId,
    comments,
    isLoggedIn,
    objectType,
    userDetails,
    activeObject,
    commentPostStatus,
    // actions
    sendComment,
    sendReplyComment,
  } = props;

  const componentDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank">
      {text}
    </a>
  );

  function getCommentCount(comments) {
    let commentCount = comments.length;
    map(comments, (comment, index) => {
      commentCount = commentCount + comment.children.length;
    });
    return commentCount;
  }

  function postComment() {
    sendComment({
      commentContent: state.commentText,
      rating: state.starRatingValue,
      objectType: objectType,
      objectId: activeObject.id,
      objectSlug: activeObject.slug
    });
    setState(prevState => ({ ...prevState, commentText: '', starRatingValue: 0 }));
  }

  function replyComment(index) {
    setState(prevState => ({ ...prevState, showReplyCommentModal: true }))
    let parentId;
    if (comments[index].id === undefined) {
      parentId = index
    } else {
      parentId = comments[index].id
    }
    setState(prevState => ({ ...prevState, commentId: parentId }));
  }

  function sendReplyCommentHandler(index) {
    let parentId = comments[index].id;
    setState(prevState => ({ ...prevState, showReplyCommentModal: false }));
    sendReplyComment({
      content: state.replyCommentText,
      parentCommentId: parentId,
      objectType: objectType,
      objectId: activeObject.id
    })
  }

  function renderReplyComments(children) {
    if (children) {
      return children.map((reply, index) => {
        return (
          <div key={index} className="repliedComment">
            <div className="userAvartar">
              {
                (reply && reply.user.role === 'admin') ? <VerifiedIcon /> : renderUserFromComment(reply)
              }
            </div>
            <div className="inner-comment">
              <h4 className="userName">{reply.user.name}</h4>

              <p className="content">
                <Linkify componentDecorator={componentDecorator}>{reply.content}</Linkify>
              </p>
              <span className="postOn">POSTED ON {moment(reply.date).format('MMMM DD,YYYY - hh:mm A')}
              </span>
            </div>
          </div>
        )
      })
    }
  }


  function cancelReply() {
    setState(prevState => ({ ...prevState, showReplyCommentModal: false }));
  }


  // For dymanic comment button lable text
  let btnText
  if ((isLoggedIn) && (state.commentText !== '' && state.starRatingValue === 0)) {
    btnText = 'Submit your comment'
  } else if ((isLoggedIn) && (state.commentText === '' && state.starRatingValue !== 0)) {
    btnText = 'Submit your review'
  } else if ((isLoggedIn) && (state.commentText !== '' && state.starRatingValue !== 0)) {
    btnText = 'Submit'
  } else if (isLoggedIn === false) {
    btnText = 'Log in to post a comment'
  } else {
    btnText = 'Submit comment'
  }

  const renderUserFromUserDetails = (userDetails) => {
    return userDetails.avatar && userDetails.avatar.thumb && isValidAvatar(userDetails.avatar.thumb) ? (
      <ReactImageFallback
        src={userDetails.avatar.thumb}
        fallbackImage={<AvatarIcon />}
      />
    ) : <AvatarIcon />
  }

  const renderUserFromComment = (comment) => {
    return comment && comment.user && comment.user.avatar && isValidAvatar(comment.user.avatar) ? (
      <ReactImageFallback
        src={comment.user.avatar}
        fallbackImage={<AvatarIcon />}
      />
    ) : <AvatarIcon />;
  }

  return (
    <div className="comments">

      {
        comments.length !== 0 && (
          <div className="commentHead">
            <h2>{capitalize(props.objectType)} comments
            <span>
                ({getCommentCount(comments)})
            </span>
            </h2>
          </div>
        )
      }
      <div className="commentBoxWrapper">
        {
          map(comments, (comment, index) => {
            return (
              <div key={index} className="commentBox">
                <div className="userAvartar">
                  <figure className="comment-avatar">
                    {
                      (comment && comment.user.role === 'admin') ? <VerifiedIcon /> : renderUserFromComment(comment)
                    }
                  </figure>
                </div>
                <div className="comment">
                  <h4 className="userName">{comment.author}</h4>
                  <p className="content">
                    <Linkify componentDecorator={componentDecorator}>
                      {comment.content}
                    </Linkify>
                  </p>
                  <span className="postOn">POSTED ON {moment(comment.date).format('MMMM DD,YYYY - hh:mm A')} -
                  {
                      isLoggedIn ? (
                        <span className="reply-comment" onClick={() => replyComment(index)}>REPLY</span>
                      ) : null
                    }
                  </span>
                  {/* Viewing reply comments */}

                  {(state.showReplyCommentModal && comment.id === state.commentId) &&
                    <div className="addComment">
                      <textarea
                        onChange={() => setState(prevState => ({ ...prevState, replyCommentText: event.target.value }))}
                        className='commentInput'
                        placeholder="Replying to..."
                      />
                      <div className="btn-wrapper">
                        <Button
                          disabled={!state.replyCommentText}
                          btnClass="btn btn-primary btn-sm"
                          btnText="Submit"
                          onClick={() => {
                            sendReplyCommentHandler(index)
                            setState(prevState => ({ ...prevState, replyCommentText: '' }))
                          }} />
                        <Button
                          // renderValue={() => <>Cancel</>}
                          btnText="Cancel"
                          btnClass="btn btn-secondary btn-sm"
                          onClick={() => cancelReply()}
                          isLoading={false}
                        // showGhost
                        />
                      </div>
                    </div>
                  }
                  {(comment.children) && (comment.children.length === 0) ? <span></span> : renderReplyComments(comment.children)}

                  {/* For reply comment */}

                </div>
              </div>
            )
          })
        }
        {
          isLoggedIn ? (
            <div className="leavecomment">
              {/* <h2>Leave a comment</h2> */}
              <div className="userAvartar">

                <figure>
                  {
                    (userDetails && userDetails.role) === 'admin' ? <VerifiedIcon /> : renderUserFromUserDetails(userDetails)
                  }
                </figure>
                <p>
                  Posting as <strong>&nbsp;{userDetails.name}</strong>
                </p>
                {/* <h4 className="userName">{userDetails && userDetails.name}</h4> */}
                {/* <h4 className="userName">{comment.author}</h4> */}
              </div>
              <textarea
                value={state.commentText}
                ref={ref}
                className='commentInput'
                placeholder='Type your comment here'
                onChange={() => setState(prevState => ({ ...prevState, commentText: event.target.value }))}
              />
              {/* TODO : spinner should be inside the button and not rendered inside button because of style */}
              <div className="btn-spinner">
                <Button
                  btnClass="primary btn-full"
                  btnText={btnText}
                  onClick={postComment}
                  isLoading={state.commentText.isPosting}
                  disabled={!state.commentText}
                >
                </Button>
              </div>
            </div>
          ) : null
        }
      </div>
    </div>
  )
})

export default Comments;