import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import '../styles/common.scss';
import PropTypes from 'prop-types';
import FrameIcon from '../images/frameIcon'

const ref = React.createRef()

export const customInput = (props) => {
  const {
    label,
    errorMessage,
    value,
    onChange,
    placeholder,
    type,
    name,
    onBlur,
    disabled,
    onKeyPress,
    isLastElementOfForm,
    formLastElementAction,
    focusOnMount,
    onKeyUp,
    onFocus,
    additionalDivClass,
    additionalLabelClass,
    additionalInputClass
  } = props;
  useEffect(() => {
    if (focusOnMount) {
      ref.current.focus();
    }
  }, [])
  return (
    <>
      <div className={`form-text ${additionalDivClass}`}>
        <label className={`static-value ${additionalLabelClass}`}>{label}</label>
        <input
          onFocus={onFocus}
          onKeyPress={onKeyPress}
          onKeyUp={onKeyUp}
          disabled={disabled}
          onBlur={onBlur}
          className={cx("inputField", { "errored": errorMessage }, additionalInputClass)}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          type={type}
          name={name}
          required
          onKeyPress={(e) => {
            if (e.key === 'Enter' && isLastElementOfForm) {
              formLastElementAction(e)
            }
          }}
          ref={ref}
        />
      </div>
      {
        errorMessage &&
        <div className="errorText">
          <FrameIcon></FrameIcon>
          <p>
            {errorMessage}
          </p>
        </div>
      }
    </>
  );
}


customInput.propTypes = {
  onKeyPress: PropTypes.func,
  onKeyUp: PropTypes.func,
  onFocus: PropTypes.func
}

customInput.defaultProps = {
  onKeyPress: () => { },
  onKeyUp: () => { },
  onFocus: () => { },
  additionalDivClass: '',
  additionalLabelClass: '',
  additionalInputClass: ''
}

export default customInput;

