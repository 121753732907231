import React, { useEffect } from 'react'
import Spinner from 'react-spinner-material'

import Selector from './selector'
import concat from 'lodash/concat'
import { themeTextRed } from '../../helpers/theme'

const HorizontalListWithAction = ({
  onAction,
  title,
  options,
  children,
  defaultValue,
  showActions,
  isLoading,
  value,
  showLink,
  link,
  showMultipleActions,
  links
}) => {

  console.log(links)
  return (
    <div className="hl-list-wrapper">
      <div className="hl-list-header">
        <h3 className="header-content">
          {title}
          {
            isLoading && (
              <Spinner
                className="spinner"
                size={15}
                spinnerColor={themeTextRed}
                spinnerWidth={2}
                visible={true}
              />
            )
          }
        </h3>
        {showActions && (
          <div className="hl-list-action">
            <Selector
              changeOnClick
              selectorList={options}
              onSelectValue={onAction}
              value={value}
              defaultValue={defaultValue}
            />
          </div>
        )}

        {
          showLink && (
            <div className="hl-list-link">
              <a onClick={(e) => {
                e.preventDefault();
                link.onClick(link.href);
              }} href={link.href}>{link.text}</a>
            </div>
          )
        }
        {
          showMultipleActions ? (
            <div className="hl-list-action">
              {links.map(action => {
                return (
                  <div className='hl-action'>
                    <Selector
                      changeOnClick
                      label={action.title}
                      selectorList={action.options}
                      onSelectValue={action.onAction}
                      value={action.value}
                      defaultValue={action.defaultValue}
                    />
                  </div>
                )
              })}
            </div>
          ) : null
        }
      </div>
      {children}
    </div>
  )
}

HorizontalListWithAction.defaultProps = {
  showActions: true,
}

export default HorizontalListWithAction