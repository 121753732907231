import { put, takeEvery, call, select, all } from 'redux-saga/effects'
import { showToastMessage } from '../actions';
import { getAuthStatus, getObjectEssentials, getCurrentWorkout, getCurrentObjectId, getRecipeByIdFromAnyList } from './selectors'
import * as actions from '../actions/actionTypes';
import commentTask from './subtask/comments'
import recipeTask from './subtask/recipes';
import workoutTask from './subtask/workouts';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  //For Toast message
  const getState = state => state;
  const state = yield select(getState)

  try {
    // getting action payload credetials
    const {
      commentContent,
      rating,
      objectType,
      objectSlug,
      objectId
    } = action.payload;

    // if no rating is given set rating as null
    const ratingValue = (rating) => {
      if (rating === 0) {
        return null
      } else {
        return rating
      }
    }
    // getting object specific data from selector
    let objectEssentials = yield select(getObjectEssentials);

    // gettting the auth details
    let authDetails = yield select(getAuthStatus);

    // preparing data object to send to api
    const commentObject = {
      object_type: objectType === 'blog' ? 'blog_post' : objectType,
      object_id: objectId,
      object_slug: objectSlug,
      content: commentContent,
      author: authDetails.getIn(['details', 'name']),
      author_email: authDetails.getIn(['details', 'email']),
      rating: ratingValue(rating),
      children: []
    }

    // api call
    let commentResponse = yield call(commentTask.post, commentObject);

    // add empty children array for reply comment reactivity
    let commentResponseData = commentResponse.data.comment;
    let commentResponseStructure = { ...commentResponseData, children: [] }

    // calling action to store the updates
    yield put({
      type: actions.CREATE_COMMENT_TO_OBJECT_SUCCESS,
      payload: {
        commentObject: commentResponseStructure,
        updatedRating: 0,
        objectId: objectId,
        objectSlug: objectSlug,
        success: true,
        objectType: objectType,
        approved: commentResponse.data.approved
      }
    })
    if (commentResponse.data.approved) {
      yield put(showToastMessage(`👍🏼 Comment added.`, 'success', 'comment'));
    } else {
      yield put(showToastMessage(`😬 Comment pending approval`, 'success', 'comment'));
    }

  } catch (e) {
    yield put(showToastMessage(`❗️ Error in posting new comment`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CREATE_COMMENT_TO_OBJECT_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* sendComment() {
  yield takeEvery(actions.CREATE_COMMENT_TO_OBJECT, task)
}