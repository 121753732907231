import "babel-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

import ErrorBoundary from "./errorBoundary";
import AppBanner from "./AppBanner";
import Routes from "./routes";

import store from "../store";

const root = document.getElementById("content");


ReactGA.initialize(process.env.GOOGLE_ANALITICS_ID);

if (root !== null) {
  ReactDOM.render(
    <Provider store={store}>
      <ErrorBoundary>
        <AppBanner />
        <QueryClientProvider client={queryClient}>
          <Routes />
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>,
    root
  );
}
