import React from 'react'
import GridCard from './GridCard';

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import take from 'lodash/take'

import HorizontalActionableHeader from './horizontalActionableHeader'
import getWorkoutImages from '../../helpers/getWorkoutImages'
import getRecipeImage from '../../helpers/getRecipeImage';
import capitalize from 'lodash/capitalize';
import { isNewTagStatus } from "../../helpers/getNewTagStatus";

const options = [
  {
    title: 'Today',
    value: 'day'
  },
  {
    title: 'This Week',
    value: 'week'
  },
  {
    title: 'This Month',
    value: 'month'
  },
  {
    title: 'All Time',
    value: 'allTime'
  },
]
const HorizontalListWithAction = ({
  list,
  history,
  deleteFavourite,
  loginRedirect,
  isAuthenticated,
  renderMeta,
  toggleFavoriteModal,
  trendingTypeChange,
  objectType,
  trendingType,
  trendingFetch,
  userDetails,
  feature
}) => {

  let actualList = [];

  if (isEmpty(list)) {
    actualList = [{
      isLoading: true
    }, {
      isLoading: true
    }, {
      isLoading: true
    }, {
      isLoading: true
    }, {
      isLoading: true
    }]

    if (objectType !== 'recipe') {
      actualList = take(actualList, 4);
    }
  } else {
    actualList = take(list, 5)
  }
  const getImages = item => {
    let coverImage;
    let thumbnail;
    if (objectType === 'recipe') {
      coverImage = getRecipeImage(item, 'full_sm')
      thumbnail = getRecipeImage(item, 'square_200')
    }
    if (objectType === 'workout') {
      coverImage = getWorkoutImages(item, 'wide_640')
      thumbnail = getWorkoutImages(item, 'square_200')
    }

    if (objectType === 'video') {
      coverImage = (!isEmpty(item) && !isEmpty(item.images)) ? item.images.large : ''
      thumbnail = (!isEmpty(item) && !isEmpty(item.images)) ? item.images.large : ''
    }

    return {
      coverImage,
      thumbnail
    }
  }


  return (
    <HorizontalActionableHeader
      options={options}
      title={
        <h3 className="header-content">
          {`Most Popular ${capitalize(objectType)}s`}
        </h3>
      }
      onAction={value => trendingTypeChange(objectType, value)}
      defaultValue={trendingType}
      isLoading={trendingFetch}
    >
      <ul className={`${objectType}List scrollable-wrapper`}>
        <>
          {
            map(actualList, (item, index) => {
              return (
                <li key={index} className={objectType}>
                  <GridCard
                    userDetails={userDetails}
                    feature={objectType}
                    objectType={objectType}
                    item={item}
                    coverImage={getImages(item).coverImage}
                    thumbnail={getImages(item).thumbnail}
                    history={history}
                    deleteFavourite={deleteFavourite}
                    loginRedirect={loginRedirect}
                    isAuthenticated={isAuthenticated}
                    toggleFavoriteModal={toggleFavoriteModal}
                    renderMeta={renderMeta(item)}
                    isLoading={item.isLoading}
                    showTag={isNewTagStatus({ date: item.publish_date })}
                  />
                </li>
              )
            })
          }
        </>
      </ul>
    </HorizontalActionableHeader>
  )
}

export default HorizontalListWithAction