import React from "react";
import "../styles/common.scss";

const StarIcon = () => {
  return (
    <svg
      width="99"
      height="17"
      viewBox="0 0 99 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.5029 0L51.4113 5.87336L57.5869 5.87336L52.5907 9.50329L54.4991 15.3766L49.5029 11.7467L44.5068 15.3766L46.4151 9.50329L41.4189 5.87336L47.5946 5.87336L49.5029 0Z"
        fill="#F1C40F"
      />
      <path
        d="M29.1029 0L31.0113 5.87336L37.1869 5.87336L32.1907 9.50329L34.0991 15.3766L29.1029 11.7467L24.1067 15.3766L26.0151 9.50329L21.0189 5.87336L27.1945 5.87336L29.1029 0Z"
        fill="#F1C40F"
      />
      <path
        d="M8.70288 0L10.6112 5.87336L16.7869 5.87336L11.7907 9.50329L13.6991 15.3766L8.70288 11.7467L3.70671 15.3766L5.61508 9.50329L0.6189 5.87336L6.79451 5.87336L8.70288 0Z"
        fill="#F1C40F"
      />
      <path
        d="M69.903 0L71.8113 5.87336L77.9869 5.87336L72.9908 9.50329L74.8991 15.3766L69.903 11.7467L64.9068 15.3766L66.8151 9.50329L61.819 5.87336L67.9946 5.87336L69.903 0Z"
        fill="#F1C40F"
      />
      <path
        d="M90.303 0L92.2113 5.87336L98.387 5.87336L93.3908 9.50329L95.2992 15.3766L90.303 11.7467L85.3068 15.3766L87.2152 9.50329L82.219 5.87336L88.3946 5.87336L90.303 0Z"
        fill="#F1C40F"
      />
    </svg>
  );
};
export default StarIcon;
