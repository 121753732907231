import React from "react";
import '../styles/common.scss';

const ShareIcon = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.12 19.82V29.23C31.12 31.31 29.43 32.99 27.36 32.99H4.76C2.69 33 1 31.31 1 29.24V6.65001C1 4.57001 2.69 2.89001 4.76 2.89001H17.94M8.53003 23.59C8.53003 23.59 8.53003 8.53003 25.47 8.53003H33M33 8.53003L25.47 1M33 8.53003L25.47 16.06" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
export default ShareIcon;