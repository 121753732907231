import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import { fromJS } from "immutable";
import { generateRecipeQueryFromFilterForFrontend } from "../../../helpers/filterConversions";
import { removeCharecterFromIndex } from "../../../helpers/converters";
import map from "lodash/map";
import concat from "lodash/concat";
import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import toUpper from "lodash/toUpper";

import Modalwrapper from "../../common/modal";
import PopupModal from "../../common/popupModal";
import FavoriteModal from "../../common/favoriteModal";
import GridCard from "../../common/GridCard";
import InputRange from "../../common/inputRange";
import Carousel from "../../common/featuredCarousel";
import Loader from "../../images/loader";
import Filters from "../../common/filters";
import AdComponent from "../../common/ad";
import {
  generateFilterFromQueryString,
  getRecipesDietariesNavOptions,
  getRecipesFoodTypesNavOptions,
  getRecipesSpecialitiesNavOptions,
  getFavouriteBoardsNavOptions,
  getWeightWatchersInputRangeOptions,
  getFilterOptions,
  getFavoriteFilterOptions,
  getSelectorList,
} from "./config";

import TrendingChangeAction from "../../common/trendingChangeAction";
import ChangeSortOrder from "../../common/changeSortOrder";

// New imports
import { generateAppEssentialPayload } from "../../../helpers/filterConversions";
import CloseableTag from "../../common/closeableTag";
import getRecipeImage from "../../../helpers/getRecipeImage";
import ThumbNoteIcon from "../../images/ThumbNoteIcon";
import ThumbFavIcon from "../../images/ThumbFavIcon";
import convertQuality from "../../../helpers/convertQuality";
import { isNewTagStatus } from "../../../helpers/getNewTagStatus";
import LeftoverRecipes from '../../leftover-reciepe';

import {
  TransitionOne,
  TransitionTwo,
  TransitionThree,
} from "../../common/transitions";

const Recipes = ({
  userDetails,
  appEssentialFetch,
  isAuthenticated,
  recipes,
  history,
  boards,
  createBoard,
  setObjectBoards,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  fetchRecipes,
  fetchStatus,
  moreRecipeAvailable,
  setFiltersAndFetch,
  recipeFilterName,
  searchRecipes,
  searchList,
  recipesSearchCount,
  searchTerm,
  searchBy,
  trendingRecipes,
  trendingType,
  order,
  featuredCollections,
  allCollections,
  ...props
}) => {
  const [state, setState] = useState({
    showLoginModal: false,
    activeUnAuthClickedRecipeName: "",
    showFilterModal: false,
    showFavoriteModal: false,
    activeFavoritingRecipeId: null,
    favoriteView: false,
    showSearchDropdown: false,
  });

  const loginRedirectHandler = (title) => {
    setState((prevState) => ({
      ...prevState,
      activeUnAuthClickedRecipeName: title,
      showLoginModal: true,
    }));
  };

  const loginModalCloseHandler = () => {
    setState((prevState) => ({ ...prevState, showLoginModal: false }));
  };

  const favoriteModalToggleHandler = ({ itemId, modalState }) => {
    setState((prevState) => ({
      ...prevState,
      showFavoriteModal: modalState,
      activeFavoritingRecipeId: itemId,
    }));
  };

  useEffect(() => {
    if (!isNil(fetchStatus.globalError)) {
      history.push("/recipes");
    }
  }, [fetchStatus.globalError]);

  useEffect(() => {
    // Can be of few types
    // 1. /recipes/favorites/:favoriteBoardSlug
    // 2. /recipes/featured/:featuredCollection
    // 3. /recipes/:defaultFodType
    // 4. /recipes/?filters

    let favorite = {
      show: false,
      board: null,
    };

    // Case 1.
    const isFavorites =
      props.match.path === "/recipes/favorites/:favoriteBoardSlug" ||
      props.match.path === "/recipes/favorites";

    if (isFavorites) {
      const favoriteBoardSlug = props.match.params.favoriteBoardSlug
        ? props.match.params.favoriteBoardSlug
        : null;
      favorite.show = true;
      favorite.board = favoriteBoardSlug;
    }

    // Case 2.
    if (props.match.path === "/recipes/featured/:featuredCollection") {
      const featuredCollection = {
        show: true,
        slug: props.match.params.featuredCollection,
      };

      const appEssentialPayload = generateAppEssentialPayload({
        objectType: "recipe",
        queryString: `?collection=${props.match.params.featuredCollection}`,
        meta: {
          objectType: "recipe",
          listType: "featured",
        },
      });

      appEssentialFetch(appEssentialPayload);
    } else if (props.match.path === "/recipes/:defaultFoodType") {
      // Case 3.
      const appEssentialPayload = generateAppEssentialPayload({
        objectType: "recipe",
        queryString: `?foodTypes=${props.match.params.defaultFoodType}`,
        meta: {
          objectType: "recipe",
          listType: "featured",
        },
      });
      appEssentialFetch(appEssentialPayload);
    } else {
      // Case 4
      let listType = "unfiltered";

      if (isFavorites) {
        listType = "favorited";
      } else if (history.location.search.length !== 0) {
        listType = "filtered";
      }

      const appEssentialPayload = generateAppEssentialPayload({
        objectType: "recipe",
        queryString: history.location.search,
        favorite,
        meta: {
          objectType: "recipe",
          listType,
        },
      });
      appEssentialFetch(appEssentialPayload);
    }
  }, [history.location.search, props.match.path, props.match.url]);

  // for the fav modal rendering condition
  let activeFavoritingRecipe =
    (recipes || []).find(
      (recipe) => recipe.id === state.activeFavoritingRecipeId
    ) || {};

  if (isEmpty(activeFavoritingRecipe)) {
    activeFavoritingRecipe =
      (trendingRecipes || []).find(
        (recipe) => recipe.id === state.activeFavoritingRecipeId
      ) || {};
  }

  // ALL GENERATED PROPS FOR NAV FILTER
  // the dietaries data
  const recipesDietariesNavOptions = getRecipesDietariesNavOptions(
    props.recipesDietaries,
    history
  );

  // the food type data
  const recipesFoodTypesNavOptions = getRecipesFoodTypesNavOptions(
    props.recipesFoodTypes,
    history
  );

  // specialities data
  const recipesSpecialitiesNavOptions = getRecipesSpecialitiesNavOptions(
    props.recipesSpecialities,
    history
  );

  // the fav boards data
  const favouriteBoardsNavOptions = getFavouriteBoardsNavOptions(
    boards,
    recipeFilterName
  );

  // the dymanic range logic for weightwatchers (the props for the input range)
  const weightWatchersInputRangeOptions = getWeightWatchersInputRangeOptions(
    history
  );

  // the weightwatchers options data
  const weightWatchersOptions = weightWatchersInputRangeOptions.map(
    (option) => {
      return (
        <InputRange
          label={option.label}
          labelClass={option.labelClass}
          wrapperClass={option.wrapperClass}
          minValue={option.minValue}
          maxValue={option.maxValue}
          onChangeComplete={(value, label) => {
            navFilterActionDispatch(value, label);
          }}
          defaultMinValue={option.defaultMinValue}
          defaultMaxValue={option.defaultMaxValue}
        />
      );
    }
  );

  // the final fav options
  const favoriteFilterOptions = getFavoriteFilterOptions(
    favouriteBoardsNavOptions,
    recipeFilterName
  );

  // the final nav options , ie collections ....
  const filterOptions = getFilterOptions(
    recipesFoodTypesNavOptions,
    recipesDietariesNavOptions,
    recipesSpecialitiesNavOptions,
    weightWatchersOptions,
    history
  );

  // the search -> searchBy dropdown list
  const selectorList = getSelectorList();

  // ALL LOGIC FUNCTIONS
  // what happens when an item in fav filter dropdown is selected (for query parma setting)
  const navFilterActionDispatch = (slug, filterName, defaultPage) => {
    let finalQueryParam = "";

    let filterFromQueryString = generateFilterFromQueryString(
      "recipe",
      history
    );
    if (defaultPage) {
      if (defaultPage === "collection") {
        // direct to category page
        finalQueryParam = `recipes/featured/${slug}`;
      }
      history.push(`${finalQueryParam}`);
    } else {
      if (filterName === "Dietary") {
        const dietaries = filterFromQueryString.recipe.filters.dietaries;
        const index = dietaries.findIndex((dietary) => dietary === slug);
        if (index === -1) {
          filterFromQueryString.recipe.filters.dietaries = [...dietaries, slug];
        } else {
          let splicedDietary = dietaries.splice(index, 1);
          filterFromQueryString.recipe.filters.dietaries = dietaries;
        }
      } else if (filterName === "Food Type") {
        const foodTypes = filterFromQueryString.recipe.filters.foodTypes;
        const index = foodTypes.findIndex((category) => category === slug);
        if (index === -1) {
          filterFromQueryString.recipe.filters.foodTypes = [...foodTypes, slug];
        } else {
          let splicedFoodType = foodTypes.splice(index, 1);
          filterFromQueryString.recipe.filters.foodTypes = foodTypes;
        }
      } else if (filterName === "Speciality") {
        const specialities = filterFromQueryString.recipe.filters.specialities;
        const index = specialities.findIndex((category) => category === slug);
        if (index === -1) {
          filterFromQueryString.recipe.filters.specialities = [
            ...specialities,
            slug,
          ];
        } else {
          let splicedFoodType = specialities.splice(index, 1);
          filterFromQueryString.recipe.filters.specialities = specialities;
        }
      } else if (filterName === "FP") {
        filterFromQueryString.recipe.filters.fp = ["0", `${slug}`];
      } else if (filterName === "SP") {
        filterFromQueryString.recipe.filters.sp = ["0", `${slug}`];
      } else if (filterName === "WWP") {
        filterFromQueryString.recipe.filters.wwp = ["0", `${slug}`];
      }

      finalQueryParam = generateRecipeQueryFromFilterForFrontend(
        fromJS(filterFromQueryString.recipe.filters)
      );

      // condition to remove if & present in index 0
      if (finalQueryParam.charAt(0) === "&") {
        finalQueryParam = removeCharecterFromIndex(finalQueryParam, 0);
      }
      history.push(`?${finalQueryParam}`);
    }
  };
  const directFilterNotApplied =
    location.pathname === "/recipes" &&
    (isEmpty(location.search) ||
      location.search === "?order=asc" ||
      location.search === "?order=desc" ||
      location.search === "?order=rand" ||
      location.search === "?order=favorite");

  const renderRecipeFilterTag = () => {
    const filters = filter(
      recipeFilterName.filterArray,
      (filter) => filter.type !== "order"
    );

    if (filters.length > 0) {
      return (
        <div className="filterName">
          <div className="filterTitle">
            {map(filters, (filter) => {
              return (
                <CloseableTag
                  key={filter.slug}
                  title={
                    filter.type === "fp" ||
                      filter.type === "sp" ||
                      filter.type === "wwp"
                      ? `${filter.name} ${filter.value}`
                      : filter.name
                  }
                  onClose={() => {
                    let updatedFilter;
                    if (
                      filter.type === "fp" ||
                      filter.type === "sp" ||
                      filter.type === "wwp"
                    ) {
                      updatedFilter = {
                        ...props.appliedFilters,
                        [filter.type]: ["0", "15"],
                      };
                    } else {
                      const updatedFilterGroup = props.appliedFilters[
                        filter.type
                      ].filter((eachSlug) => eachSlug !== filter.slug);
                      updatedFilter = {
                        ...props.appliedFilters,
                        [filter.type]: updatedFilterGroup,
                      };
                    }
                    history.push(
                      `/recipes?${removeCharecterFromIndex(
                        generateRecipeQueryFromFilterForFrontend(
                          fromJS(updatedFilter)
                        ),
                        0
                      )}`
                    );
                  }}
                />
              );
            })}
          </div>
        </div>
      );
    } else {
      return <h3 className="header-content">{"Recent Recipes"}</h3>;
    }
  };

  // dynamic header
  const renderDynamicRecipeHeader = () => {
    if (recipeFilterName.type == "favorite") {
      return (
        <>
          <Helmet>
            <title>
              Recipes{" "}
              {recipeFilterName.title ? `in ${recipeFilterName.title}` : ""} |
              Dashing Dish
            </title>
          </Helmet>
          <h3 className="header-content">
            {`${recipeFilterName.title} Favorite Recipes`}
          </h3>
        </>
      );
    } else if (recipeFilterName.type == "filter" && !isFeatured) {
      return renderRecipeFilterTag();
    } else if (recipeFilterName.type == "search") {
      return (
        <>
          <span className="filtered-text">{"Results for"}&nbsp;</span>
          {`${searchTerm}`}
        </>
      );
    } else if (!isFeatured) {
      return <h3 className="header-content">{"Recent Recipes"}</h3>;
    } else {
      return null;
    }
  };

  const noRecipeMessage = (message) => {
    return (
      <>
        {renderDynamicRecipeHeader()}
        <div className="pageMessage">
          {/* <h2>{message}</h2> */}
          <div className="empty-content">
            <h2>
              This category is as empty<br></br>
              as my plate after Thanksgiving.
            </h2>
            <p>
              Go to the{" "}
              <a
                href={"/recipes"}
                onClick={(e) => {
                  e.preventDefault();
                  history.push("/recipes");
                }}
              >
                {" "}
                Recipe index{" "}
              </a>{" "}
              to add some!
            </p>
          </div>
        </div>
      </>
    );
  };

  // checks if we are in favorites view or not
  const isFavorites =
    props.match.path === "/recipes/favorites/:favoriteBoardSlug" ||
    props.match.path === "/recipes/favorites";
  const isFeatured =
    props.match.path === "/recipes/featured/:featuredCollection";

  // ALL SUB-RENDERS
  // renders carousel based on condition
  const renderCarousel = () => {
    if (directFilterNotApplied) {
      return (
        <TransitionOne>
          <Carousel
            isFetching={true}
            data={featuredCollections}
            onClick={(slug) =>
              navFilterActionDispatch(slug, "featuredCollection", "collection")
            } // defaultOption for Foodtype / Dietary page
          />
        </TransitionOne>
      );
    }
  };

  const renderRecipeMeta = (item) => {
    return (
      <div className="recipe-meta">
        <span className="cook-time">
          {item.variations &&
            item.variations.length > 0 &&
            item.variations[0].cook_time
            ? item.variations[0].cook_time.split(" ")[0]
            : "-"}
          {item.variations &&
            item.variations.length > 0 &&
            item.variations[0].cook_time
            ? toUpper(item.variations[0].cook_time.split(" ")[1])
              ? toUpper(item.variations[0].cook_time.split(" ")[1]).substring(
                0,
                1
              )
              : "M"
            : ""}
        </span>
        <span className="separation">|</span>
        <span className="calories">
          {item.variations &&
            item.variations.length > 0 &&
            item.variations[0].calories}{" "}
          Cals
        </span>
        <span className="card-icons">
          {item.favorite ? <ThumbFavIcon /> : null}
          {item.notes ? <ThumbNoteIcon /> : null}
        </span>
      </div>
    );
  };

  const renderFeatured = () => {
    const featuredCollection = allCollections.find((collection) => {
      return collection.slug === props.match.params.featuredCollection;
    });
    return isFeatured && !isNil(featuredCollection) ? (
      <TransitionOne>
        <div className="container">
          <div className="featuredCollection-wrapper">
            <figure
              className="featuredCollection-image"
              style={{
                backgroundImage: `url("${convertQuality(
                  featuredCollection.image,
                  "quality"
                )}")`,
                height: 250,
                backgroundSize: "cover",
                backgroundRepeat: "none",
                backgroundPosition: "center",
              }}
            ></figure>
            <div className="feature-overlay">
              <h5>featured</h5>
              <p>{featuredCollection.name}</p>
            </div>
          </div>
        </div>
      </TransitionOne>
    ) : null;
  };

  const showFilters = !isFeatured;
  const noInitialFetchHappening =
    !fetchStatus.unfilteredListInitialTypeStatus.isFetching &&
    !fetchStatus.filteredListInitialTypeStatus.isFetching &&
    !fetchStatus.favoritedListInitialTypeStatus.isFetching &&
    !fetchStatus.featuredListInitialTypeStatus.isFetching;
  const noPaginationFetchHappening =
    !fetchStatus.unfilteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.filteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.favoritedListPaginationTypeStatus.isFetching &&
    !fetchStatus.featuredListPaginationTypeStatus.isFetching;

  const noFetchHappening =
    noInitialFetchHappening && noPaginationFetchHappening;

  let preloaderRecipes = map(
    [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20,
      21,
      22,
      23,
      24,
      25,
    ],
    (item, index) => ({ isLoading: true, id: null })
  );

  const modifiedPreloadedRecipes = noPaginationFetchHappening
    ? preloaderRecipes
    : concat(recipes, preloaderRecipes);
  const actualRecipes = !noFetchHappening ? modifiedPreloadedRecipes : recipes;

  return (
    <section className="recipes">
      <Helmet>
        <title>Recipes | Dashing Dish</title>
        <meta name="description" content="Dashingdish Recipes page" />
      </Helmet>
      {renderFeatured()}
      <Filters
        objectType="recipe"
        actions={(name, slug, filterName) =>
          navFilterActionDispatch(slug, filterName)
        }
        showFilters={showFilters}
        options={filterOptions}
        staticOption={{
          primaryOption: "Clear Filters",
          secondaryOption: "All Recipes",
          route: "/recipes",
        }}
        isAuthenticated={isAuthenticated}
        dropDownReturnActionText={`See all ${recipesSearchCount} Recipes`}
        dropDownReturnAction={(searchText, searchBy) => {
          history.push(`/recipes?search=${searchText}&search_by=${searchBy}`);
        }}
        history={history}
        placeholder="Search recipes"
        searchText={searchTerm}
        onSearchChange={(searchText, searchBy) => {
          searchRecipes({
            objectType: "recipe",
            searchText: searchText,
            searchBy: searchBy,
          });
        }}
        onSearchByChange={(value, searchText) => {
          searchRecipes({
            objectType: "recipe",
            searchText: searchText,
            searchBy: value,
          });
        }}
        onClickSearchClear={() => {
          history.push(`/recipes`);
        }}
        defaultSearchText={searchTerm}
        defaultSearchBy={searchBy}
        searchList={searchList}
        searchStatus={fetchStatus.recipesSearch}
        searchCount={recipesSearchCount}
        showDefaultInput={!isNil(searchBy) || !isNil(searchTerm)}
        selectorList={selectorList}
        isFavorites={isFavorites}
        isFeatured={isFeatured}
        favoriteOptions={favoriteFilterOptions}
        updateFavouriteBoard={updateFavouriteBoard}
        deleteFavouriteBoard={deleteFavouriteBoard}
        createBoard={createBoard}
      />

      <div className="container">
        <AdComponent />
        {renderCarousel()}

        {/* {renderFeatured()} */}
        {directFilterNotApplied ? (
          <TransitionTwo>
            <div className="hl-list-wrapper-scrollable">
              <TrendingChangeAction
                userDetails={userDetails}
                objectType="recipe"
                list={trendingRecipes}
                history={history}
                deleteFavourite={props.deleteFavourite}
                loginRedirect={loginRedirectHandler}
                isAuthenticated={isAuthenticated}
                toggleFavoriteModal={favoriteModalToggleHandler}
                renderMeta={renderRecipeMeta}
                trendingTypeChange={props.trendingTypeChange}
                trendingType={trendingType}
                trendingFetch={fetchStatus.trendingFetch}
              />
            </div>
          </TransitionTwo>
        ) : null}

        {noFetchHappening && recipes.length === 0 && !moreRecipeAvailable ? (
          noRecipeMessage("No Recipes")
        ) : (
          <TransitionThree>
            <ChangeSortOrder
              appliedFilters={props.appliedFilters}
              setFiltersAndFetch={setFiltersAndFetch}
              history={history}
              objectType="recipe"
              title={renderDynamicRecipeHeader()}
              order={order}
              active={!isFavorites && !isFeatured}
            >
              <InfiniteScroll
                className="infiniteScroll"
                initialLoad={false}
                // threshold={500}
                loadMore={
                  noFetchHappening
                    ? () => {
                      if (
                        history.location.search ||
                        props.match.path ===
                        "/recipes/featured/:featuredCollection"
                      ) {
                        fetchRecipes({
                          pagination: true,
                          filterGroup: "filters",
                        });
                      } else if (
                        props.match.path ===
                        "/recipes/favorites/:favoriteBoardSlug" ||
                        props.match.path === "/recipes/favorites"
                      ) {
                        fetchRecipes({
                          pagination: true,
                          filterGroup: "favorites",
                        });
                      } else {
                        fetchRecipes({
                          pagination: true,
                          filterGroup: "unFiltered",
                        });
                      }
                    }
                    : () => { }
                }
                hasMore={moreRecipeAvailable}
                loader={
                  <div className="container">
                    <div className="preLoader-minimal-height">
                      <Loader></Loader>
                    </div>
                  </div>
                }
              >
                <ul className="recipeList">
                  <>
                    {actualRecipes.map((item, index) => {
                      return (
                        <li key={index} className="recipe">
                          <GridCard
                            userDetails={userDetails}
                            feature="recipe"
                            objectType="recipe"
                            history={history}
                            item={item}
                            deleteFavourite={props.deleteFavourite}
                            loginRedirect={loginRedirectHandler}
                            isAuthenticated={isAuthenticated}
                            toggleFavoriteModal={favoriteModalToggleHandler}
                            renderMeta={renderRecipeMeta(item)}
                            coverImage={getRecipeImage(item, "full_sm")}
                            thumbnail={getRecipeImage(item, "square_400")}
                            showTag={isNewTagStatus({
                              date: item.publish_date,
                            })}
                          />
                        </li>
                      );
                    })}
                  </>
                </ul>
              </InfiniteScroll>
            </ChangeSortOrder>
          </TransitionThree>
        )}
      </div>
      <Modalwrapper
        isOpen={state.showFavoriteModal}
        minHeight="600px"
        maxWidth="500px"
        onRequestClose={() => {
          favoriteModalToggleHandler({ itemId: null, modalState: false });
        }}
      >
        <FavoriteModal
          boards={boards}
          objectType="recipe"
          activeObject={activeFavoritingRecipe}
          createBoard={createBoard}
          clearBoardSelection={props.clearBoardSelection}
          createFavourite={props.createFavourite}
          setObjectBoards={setObjectBoards}
          toggleFavoriteModal={favoriteModalToggleHandler}
          deleteFavouriteBoard={deleteFavouriteBoard}
          updateFavouriteBoard={updateFavouriteBoard}
          thumbnail={getRecipeImage(activeFavoritingRecipe, "square_400")}
        />
      </Modalwrapper>
      <Modalwrapper
        isOpen={state.showLoginModal}
        onRequestClose={loginModalCloseHandler}
      >
        <PopupModal
          objectName={state.activeUnAuthClickedRecipeName}
          history={history}
          closeModal={loginModalCloseHandler}
          objectType="recipe"
        />
      </Modalwrapper>
      <LeftoverRecipes isAuthenticated={isAuthenticated} {...props} history={history} />
    </section>
  );
};

export default Recipes;
