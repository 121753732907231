import React from "react";
import '../styles/common.scss';

const VisaCard = (props) => {
  return (
    <>
      <svg width="62" height="41" viewBox="0 0 62 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.2983 36.7741C61.2983 39.0199 59.4747 40.8427 57.228 40.8427H4.31395C2.06724 40.8427 0.243652 39.0199 0.243652 36.7741V4.22578C0.243652 1.98005 2.06724 0.157227 4.31395 0.157227H57.228C59.4747 0.157227 61.2983 1.98005 61.2983 4.22578V36.7741Z" fill="#0054A4" />
        <path d="M24.7727 26.9562L26.8962 13.8083H30.2944L28.1682 26.9562H24.7727Z" fill="white" />
        <path d="M40.4887 14.1295C39.8166 13.8645 38.7615 13.5781 37.4467 13.5781C34.0887 13.5781 31.7242 15.3635 31.7054 17.917C31.684 19.8068 33.3925 20.8614 34.6805 21.4904C36.0033 22.1355 36.4479 22.545 36.4425 23.1232C36.4345 24.0038 35.3874 24.408 34.41 24.408C33.0497 24.408 32.3267 24.2099 31.21 23.7174L30.7735 23.5086L30.2996 26.4503C31.0922 26.817 32.5597 27.1328 34.0833 27.1516C37.6529 27.1516 39.9719 25.3876 39.9987 22.6601C40.0121 21.1612 39.107 20.0262 37.1468 19.0867C35.9605 18.4764 35.2321 18.0723 35.2402 17.4566C35.2402 16.9106 35.8561 16.3244 37.1869 16.3244C38.2982 16.3057 39.1016 16.5626 39.7282 16.8303L40.0335 16.9802L40.4887 14.1295Z" fill="white" />
        <path d="M49.2025 13.8191H46.5782C45.7642 13.8191 45.1563 14.052 44.7975 14.9085L39.7524 26.9589H43.3193C43.3193 26.9589 43.9004 25.3368 44.0343 24.9835C44.4226 24.9835 47.8904 24.9916 48.3858 24.9916C48.4875 25.4493 48.7981 26.9616 48.7981 26.9616H51.95L49.2025 13.8191ZM45.0117 22.2935C45.2929 21.5386 46.364 18.6184 46.364 18.6184C46.3453 18.6532 46.6452 17.8555 46.8139 17.363L47.0442 18.4979C47.0442 18.4979 47.6949 21.6377 47.8314 22.2935C47.3039 22.2935 45.6758 22.2935 45.0117 22.2935Z" fill="white" />
        <path d="M21.9233 13.8191L18.5975 22.7833L18.2413 20.9604C17.6227 18.8619 15.6947 16.5841 13.5364 15.4465L16.5784 26.9455L20.1747 26.9428L25.5224 13.8191H21.9233Z" fill="white" />
        <path d="M15.5127 13.8083H10.0338L9.98828 14.0814C14.2514 15.1708 17.0711 17.7993 18.2414 20.9604L17.0497 14.9192C16.8462 14.0841 16.2491 13.8378 15.5127 13.8083Z" fill="#F39C12" />
      </svg>
    </>
  );
};
export default VisaCard;
