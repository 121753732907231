import axios from "axios";
import { baseUrl } from "./api";
import { useState, useEffect } from "react";

export const useMealPlanInteriorHook = ({
  token,
  slug
}) => {
  const [state, updateState] = useState({
    ingredientsCount: 0,
    mealPlan: {},
    mealPlanDetails: [],
    isFetching: false,
    isFetched: false,
    isError: false,
  });
  useEffect(() => {
    updateState({
      ...state,
      isFetching: true,
      isFetched: false,
    })
    axios(`${baseUrl}/api/v1/meal-plans/${slug}`, {
      headers: {
        Authorization: `Token token="${token}"`
      },
    }).then((response) => {
      updateState({
        ingredientsCount: response.data.ingredients_count,
        mealPlan: response.data.meal_plan,
        mealPlanDetails: response.data.meal_plan_details,
        isFetching: false,
        isFetched: true,
      })
    }).catch((error) => {
      updateState({
        ...state,
        isError: true,
        isFetching: false,
        isFetched: true,
      })
    })
      ;
  }, [slug]);

  return [
    state,
    updateState
  ];
}
