import { put, takeEvery, call, select } from 'redux-saga/effects'
import axios from 'axios'
import map from 'lodash/map'
import moment from 'moment'
import { showToastMessage } from '../actions'
import { getCurrentObjectId, getNoteStack, getRecipeByIdFromAnyList, getToken } from './selectors'
import * as actions from '../actions/actionTypes';
import { mealPlan } from '../helpers/api'
import { post } from '../helpers/requests'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {

  try {

    const {
      savedCalendarId,
      date,
    } = action.payload;

    const token = yield select(getToken);

    const { data } = yield call(axios, post(
      mealPlan.loadSavedCalendar(),
      {
        saved_calendar_id: savedCalendarId,
        date,
      },
      '',
      token
    ));

    const calendarItems = map(data.calendar_items, calendarItem => ({
      ...calendarItem,
      date
    }));

    // calling action to store the updates
    yield put({
      type: actions.ADD_SAVED_DAY_SUCCESS,
      payload: {
        calendarItems: calendarItems
      }
    })
    yield put(showToastMessage(`✅ Calendar day added.`, 'success', 'note'));
  } catch (e) {
    yield put(showToastMessage(`❗️ Calendar day add failure`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_SAVED_DAY_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* loadSavedCalendar() {
  yield takeEvery(actions.ADD_SAVED_DAY, task)
}