import React, { useState, useEffect } from "react";
import map from "lodash/map";
import chunk from "lodash/chunk";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";
import times from "lodash/times";

import moment from "moment";
import ReactSwipe from "react-swipe";
import { Link, scroller } from "react-scroll";

import getDatesForMonth from "../../../../helpers/getDatesForMonth";
import RightArrow from "../../../images/RightArrow";

import RightButton from "../../../common/rightButton";
import LeftButton from "../../../common/leftButton";
import GhostCover from "../../../common/ghostCover";
import LinkButton from "../../../common/linkButton";

const currentMonth = moment()
  .startOf("month")
  .format("MMMM");
const nextMonth = moment()
  .add({ month: 1 })
  .startOf("month")
  .format("MMMM");
const prevMonth = moment()
  .subtract({ month: 1 })
  .startOf("month")
  .format("MMMM");

let reactSwipeEl;
const PlanCalendar = ({
  calendarSettings,
  calendarDates,
  changeActiveDay,
  daysContainingRecipes,
  daysContainingWorkouts,
  yOffset,
}) => {
  const [localMonth, updateLocalMonth] = useState(currentMonth);
  const [spyActive, changeSpyActive] = useState(true);
  const [showCalendar, setshowCalendar] = useState(true);
  const [state, setState] = useState({
    showCalendar: true,
  });

  useEffect(() => {
    reactSwipeEl.slide(1, 0);
  }, []);
  const renderWeekTitle = () => (
    <div className="week-title">
      <span>Mo</span>
      <span>Tu</span>
      <span>We</span>
      <span>Th</span>
      <span>Fr</span>
      <span>Sa</span>
      <span>Su</span>
    </div>
  );

  // On clicking a day
  const onClickDay = (date) => {
    changeActiveDay(date);
    // Set this to false to avoid continuos movement between dates
    changeSpyActive(false);
    // release spy after 500 ms
    setTimeout(() => {
      changeSpyActive(true);
    }, 500);
  };

  const handleChangeInSpyByScroll = (day, renderMonthType) => {
    // When the list is scrolled and when it reaches a target, this gets fired

    // Extract current month and next month
    const currentMonth = moment()
      .startOf("month")
      .format("MMMM");
    const nextMonth = moment()
      .add({ month: 1 })
      .startOf("month")
      .format("MMMM");
    const prevMonth = moment()
      .subtract({ month: 1 })
      .startOf("month")
      .format("MMMM");

    // Change calendar to current or next month
    const spyDateMonth = moment(day.date).format("MMMM");

    if (localMonth !== spyDateMonth && typeof reactSwipeEl !== "undefined") {
      let toChangeMonth;

      if (spyDateMonth === prevMonth) {
        toChangeMonth = prevMonth;
        reactSwipeEl.slide(0, 500);
      } else if (spyDateMonth === currentMonth) {
        reactSwipeEl.slide(1, 500);
        toChangeMonth = currentMonth;
      } else if (spyDateMonth === nextMonth) {
        reactSwipeEl.slide(2, 500);
        toChangeMonth = nextMonth;
      }

      updateLocalMonth(toChangeMonth);
    }

    // Change active date
    changeActiveDay(day.date);
  };

  const renderCalenderWeek = (week, renderMonthType) => {
    // check if the day is disabled
    const isDayDisabled = (day) => {
      return !day.isInRange || renderMonthType !== day.monthType;
    };

    const currentMonth = moment()
      .startOf("month")
      .format("MMMM");
    const nextMonth = moment()
      .add({ month: 1 })
      .startOf("month")
      .format("MMMM");
    const prevMonth = moment()
      .subtract({ month: 1 })
      .startOf("month")
      .format("MMMM");

    const isActiveDayInCurrentMonth = (day) => {
      return (
        (localMonth === currentMonth && renderMonthType === "current") ||
        (localMonth === nextMonth && renderMonthType === "next") ||
        (localMonth === prevMonth && renderMonthType === "previous")
      );
    };

    // Specifies whether a day is active or not
    const dayActiveClass = (day) =>
      calendarSettings.activeDate === day.date &&
      isActiveDayInCurrentMonth(day) &&
      !isDayDisabled(day)
        ? "active"
        : null;

    // Specifies whether a day is disabled or not
    const dayDisabledClass = (day) => (isDayDisabled(day) ? "disabled" : "");

    // spy date
    const spyDate = (day) => (!isDayDisabled(day) ? day.date : null);

    // mentions whether a recipe is present on a day
    const isRecipePresent = (day) =>
      findIndex(
        daysContainingRecipes,
        (recipeDay) => day.date === recipeDay
      ) !== -1
        ? "recipe-tag"
        : "no-recipe-tag";
    const isWorkoutPresent = (day) =>
      findIndex(
        daysContainingWorkouts,
        (workoutDay) => day.date === workoutDay
      ) !== -1
        ? "workout-tag"
        : "no-workout-tag";
    const isCurrentDay = (day) =>
      moment()
        .startOf("day")
        .format("YYYY-MM-DD") === day.date
        ? "current-day"
        : null;
    return map(week, (day) => (
      <div
        key={day.date}
        className={`day-title ${dayActiveClass(day)} ${dayDisabledClass(
          day
        )} ${isRecipePresent(day)} ${isWorkoutPresent(day)} ${isCurrentDay(
          day
        )}`}
      >
        <Link
          to={spyDate(day)}
          spy={spyActive}
          smooth
          offset={yOffset}
          duration={500}
          onClick={() => onClickDay(day.date, dayActiveClass)}
          onSetActive={() => handleChangeInSpyByScroll(day, renderMonthType)}
        >
          <span className="day">{day.date.split("-")[2]}</span>
          <div className="dot-group">
            {isRecipePresent(day) === "recipe-tag" ? (
              <span className="recipe-dot"></span>
            ) : null}
            {isWorkoutPresent(day) === "workout-tag" && (
              <span className="workout-dot"></span>
            )}
          </div>
        </Link>
      </div>
    ));
  };

  const renderCalendarDates = (calendarDates, renderMonthType) => {
    let chunkedDates = chunk(calendarDates, 7);
    if (chunkedDates.length === 5) {
      const lastDate = chunkedDates[4][6].date;
      let count = 0;
      const additionalDates = times(7, () => {
        count += 1;
        return {
          date: moment(lastDate)
            .clone()
            .add({ days: count })
            .format("YYYY-MM-DD"),
          isInPreviousMonth: true,
          isInCurrentMonth: true,
          isInNextMonth: false,
          monthType: "current",
        };
      });

      chunkedDates.push(additionalDates);
    }

    return map(chunkedDates, (week) => (
      <div className="each-week">
        {renderCalenderWeek(week, renderMonthType)}
      </div>
    ));
  };

  const prevMonthLastDay = moment()
    .subtract({ month: 1 })
    .endOf("month")
    .format("YYYY-MM-DD");
  const currentMonthFirstDay = moment()
    .startOf("month")
    .format("YYYY-MM-DD");
  const currentMonthLastDay = moment()
    .endOf("month")
    .format("YYYY-MM-DD");
  const nextMonthFirstDay = moment()
    .add({ month: 1 })
    .startOf("month")
    .format("YYYY-MM-DD");

  // Scrolls the date and the calendar
  const scrollToMonth = (monthType) => {
    setTimeout(() => {
      if (monthType === "next") {
        if (localMonth === prevMonth) {
          reactSwipeEl.slide(1, 500);
          changeActiveDay(currentMonthFirstDay);
          scroller.scrollTo(currentMonthFirstDay, {
            duration: 500,
            smooth: true,
            offset: yOffset,
          });
        } else if (localMonth === currentMonth) {
          reactSwipeEl.slide(2, 500);

          changeActiveDay(nextMonthFirstDay);
          scroller.scrollTo(nextMonthFirstDay, {
            duration: 500,
            smooth: true,
            offset: yOffset,
          });
        }
      } else if (monthType === "prev") {
        if (localMonth === currentMonth) {
          reactSwipeEl.slide(0, 500);
          changeActiveDay(prevMonthLastDay);
          scroller.scrollTo(prevMonthLastDay, {
            duration: 500,
            smooth: true,
            offset: yOffset,
          });
        } else if (localMonth === nextMonth) {
          reactSwipeEl.slide(1, 500);
          changeActiveDay(currentMonthLastDay);
          scroller.scrollTo(currentMonthLastDay, {
            duration: 500,
            smooth: true,
            offset: yOffset,
          });
        }
      }
    }, 300);
  };

  const isPrevMonthDisabled = localMonth === prevMonth;
  const isNextMonthDisabled = localMonth === nextMonth;

  return (
    <div className="calendar">
      <div className="calendar-head">
        <div className="month-title">
          <h5>{localMonth}</h5>
          {calendarSettings.activeDate !==
          moment()
            .startOf("day")
            .format("YYYY-MM-DD") ? (
            <div
              className="today-link"
              onClick={() => {
                const moveTo = moment()
                  .startOf("day")
                  .format("YYYY-MM-DD");

                scroller.scrollTo(moveTo, {
                  duration: 500,
                  offset: yOffset,
                  smooth: true,
                });
              }}
            />
          ) : null}
        </div>
        <div className="month-pagination">
          <div
            className={`previous-icon ${isPrevMonthDisabled ? "disabled" : ""}`}
            onClick={() => {
              let toChangeMonth;
              if (localMonth === currentMonth) {
                toChangeMonth = prevMonth;
              } else if (localMonth === nextMonth) {
                toChangeMonth = currentMonth;
              }
              scrollToMonth("prev");
              updateLocalMonth(toChangeMonth);
              // Set this to false to avoid continuos movement between dates
              changeSpyActive(false);
              // release spy after 500 ms
              setTimeout(() => {
                changeSpyActive(true);
              }, 1500);
            }}
          >
            <LeftButton />
          </div>
          <div
            className={`next-icon ${isNextMonthDisabled ? "disabled" : ""}`}
            onClick={() => {
              let toChangeMonth;

              if (localMonth === prevMonth) {
                toChangeMonth = currentMonth;
              } else if (localMonth === currentMonth) {
                toChangeMonth = nextMonth;
              }
              scrollToMonth("next");
              updateLocalMonth(toChangeMonth);
              // Set this to false to avoid continuos movement between dates
              changeSpyActive(false);
              // release spy after 500 ms
              setTimeout(() => {
                changeSpyActive(true);
              }, 1500);
            }}
          >
            <RightButton />
          </div>
        </div>
      </div>
      {state.showCalendar && (
        <div className="calenderbody">
          {renderWeekTitle()}
          <ReactSwipe
            className="carousel"
            swipeOptions={{ continuous: false }}
            ref={(el) => (reactSwipeEl = el)}
          >
            <div className="previous-month">
              {renderCalendarDates(
                filter(calendarDates, (date) => date.inPreviousMonth),
                "previous"
              )}
            </div>
            <div className="current-month">
              {renderCalendarDates(
                filter(calendarDates, (date) => date.inCurrentMonth),
                "current"
              )}
            </div>
            <div className="next-month">
              {renderCalendarDates(
                filter(calendarDates, (date) => date.inNextMonth),
                "next"
              )}
            </div>
          </ReactSwipe>
        </div>
      )}
    </div>
  );
};

export default PlanCalendar;
