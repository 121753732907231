
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getRecipeLimit, getRecipeOffset, getCollectionId } from './selectors'
import recipesTask from './subtask/recipes';
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import isNil from 'lodash/isNil'

function* task(action) {
  try {
    const recipeLimit = yield select(getRecipeLimit);
    const recipeOffset = yield select(getRecipeOffset);
    const stateCollectionId = yield select(getCollectionId)

    const collectionId = isNil(action.payload.collectionId) ? stateCollectionId : action.payload.collectionId


    yield call(recipesTask.getAllRecipesFromCollection, actions.GET_ALL_RECIPES_FROM_SELECTED_COLLECTION_SUCCESS, {
      recipeLimit,
      recipeOffset,
      replace: action.payload.collectionId ? true : false,
      collectionId: collectionId
    })
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.GET_ALL_RECIPES_FROM_SELECTED_COLLECTION_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* getAllRecipesFromSelectedCollection() {
  yield takeEvery(actions.GET_ALL_RECIPES_FROM_SELECTED_COLLECTION, task)
}