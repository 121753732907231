import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const FrameIcon = ({ onClick }) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.5 1C14.19 1 18 4.81 18 9.5C18 14.19 14.19 18 9.5 18C4.81 18 1 14.19 1 9.5C1 4.81 4.81 1 9.5 1ZM9.5 0C4.26 0 0 4.26 0 9.5C0 14.74 4.26 19 9.5 19C14.74 19 19 14.74 19 9.5C19 4.26 14.74 0 9.5 0Z" fill={themeTextRed} />
      <path d="M9.5 10V5" stroke={themeTextRed} stroke-miterlimit="10" stroke-linecap="round" />
      <path d="M9.5 13V12" stroke={themeTextRed} stroke-miterlimit="10" stroke-linecap="round" />
    </svg>
  );
};
export default FrameIcon;