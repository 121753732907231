import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from "../../helpers/toJS";
import {
  sendNote,
  sendComment,
  createBoard,
  createFavourite,
  deleteFavourite,
  setObjectBoards,
  addToGroceryList,
  sendReplyComment,
  setCurrentServing,
  addAllToGroceryList,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  changeRecipeVariation,
  appEssentialFetch,
  addItemsToGroceryWaitlist,
  clearItemsFromGroceryWaitlist,
  removeItemsFromGroceryWaitlist,
  addMealToMultipleDays,
  agreeToMeteredVisit,

} from "../../actions";

import {
  getFoodTypes,
  getFetchStatus, // remove
  getUserDetails,
  getCommentsList,
  getRecipeBoards,
  getRecipeDetails, //
  getNoteStackItem, //
  getRelatedRecipes, //
  getGroceryWaitlist,
  getObjectFetchStatus,
  getCaloriesForRecipe,
  getCommentPostStatus,
  getFavoriteWithBoardMap, //note in use
  getAvgRatingForActiveRecipe, // need to implemet on stack
  getVariationsForActiveRecipe,
  getIngredientsForActiveRecipe,
  getObjectEssentialFetchStatus,
  getGroceryListItemCount,
  getIngredientsV2ForActiveRecipe,
  getSelectedCountV2,
  getVariationTotalCount
} from "../../selectors";

import { calendarListData, getCalendarMacros } from "../../selectors/calendar";

import {
  getRandomizedRecipesFoodTypes,
  getTags,
  getMeteredDetails,
} from "../../selectors/recipes";

import SingleRecipe from "./components/singleRecipe";

const mapStateToProps = (state) => ({
  tags: getTags(state),
  boards: getRecipeBoards(state),
  categories: getRandomizedRecipesFoodTypes(state),
  fetchStatus: getFetchStatus(state),
  userDetails: getUserDetails(state),
  activeRecipe: getRecipeDetails(state),
  relatedRecipe: getRelatedRecipes(state),
  notes: getNoteStackItem(state, "recipe"),
  comments: getCommentsList(state, "recipe"),
  token: state.getIn(["loggedInUser", "token"]),
  getGroceryWaitlist: getGroceryWaitlist(state),
  commentPostStatus: getCommentPostStatus(state),
  caloriesForRecipe: getCaloriesForRecipe(state),
  activeVariant: getVariationsForActiveRecipe(state),
  favoriteWithBoardMap: getFavoriteWithBoardMap(state),
  ingredientDetails: getIngredientsForActiveRecipe(state),
  isLoggedIn: state.getIn(["loggedInUser", "isLoggedIn"]),
  ratingForActiveRecipe: getAvgRatingForActiveRecipe(state),
  objectFetchStatus: getObjectFetchStatus(state, { objectType: "recipe" }),
  objectEssentialFetchStatus: getObjectEssentialFetchStatus(state, {
    objectType: "recipe",
  }),
  groceryListCount: getGroceryListItemCount(state),
  calendarList: calendarListData(state),
  setMacros: getCalendarMacros(state),
  meteredDetails: getMeteredDetails(state),
  ingredientsV2: getIngredientsV2ForActiveRecipe(state),
  selectedCountV2: getSelectedCountV2(state),
  variationTotalCount: getVariationTotalCount(state)
});

const dispatchActionToProps = (dispatch) => ({
  sendNote: bindActionCreators(sendNote, dispatch),
  createBoard: bindActionCreators(createBoard, dispatch),
  sendComment: bindActionCreators(sendComment, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  sendReplyComment: bindActionCreators(sendReplyComment, dispatch),
  addToGroceryList: bindActionCreators(addToGroceryList, dispatch),
  setCurrentServing: bindActionCreators(setCurrentServing, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  addAllToGroceryList: bindActionCreators(addAllToGroceryList, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  changeRecipeVariation: bindActionCreators(changeRecipeVariation, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  addItemsToGroceryWaitlist: bindActionCreators(
    addItemsToGroceryWaitlist,
    dispatch
  ),
  clearItemsFromGroceryWaitlist: bindActionCreators(
    clearItemsFromGroceryWaitlist,
    dispatch
  ),
  removeItemsFromGroceryWaitlist: bindActionCreators(
    removeItemsFromGroceryWaitlist,
    dispatch
  ),
  addMealToMultipleDays: bindActionCreators(addMealToMultipleDays, dispatch),
  agreeToMeteredVisit: bindActionCreators(agreeToMeteredVisit, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(SingleRecipe));
