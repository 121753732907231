import React, { useState } from 'react';
import moment from 'moment-timezone';
import AnchorIcon from '../images/anchor.png';
import DownArrow from "../images/DownArrow";
import UpArrow from "../images/UpArrow";
import { TransitionOne, TransitionTwo } from '../common/transitions';
import { toUpper } from 'lodash';

const getCETtime = (time) => moment.utc(time).tz("America/New_York");
const isActive = (timing) => {
    const meetingStartTime = getCETtime(timing);
    const meetingEndTime = getCETtime(moment(timing).add({ hour: 1 }));

    return moment(getCETtime()).isBetween(meetingStartTime, meetingEndTime, null, '[]');
}

const isNotExpired = (timing) => {
    const meetingStartTime = getCETtime(timing);
    const meetingEndTime = getCETtime(moment(timing).add({ hour: 1 }));

    return moment(getCETtime()).isBefore(meetingEndTime);
}

const getTime = (time) => moment.utc(time).tz("America/New_York")

const UpComingSessions = ({
    title = 'Upcoming Meetings',
    existingMeetings,
    meetingType,
    userPlan
}) => {
    const [isOpen, setIsOpen] = useState(true);
    return existingMeetings
        .filter(meeting => isNotExpired(meeting.event_timing)).length > 0 ? (
        <section className='container upcoming-sessions'>
            <h2 onClick={() => setIsOpen(!isOpen)}><span className='live'>LIVE</span> {title}<span className='arrow'>{isOpen ? <DownArrow /> : <UpArrow />}</span></h2>
            {
                isOpen ? existingMeetings
                    .filter(meeting => isNotExpired(meeting.event_timing))
                    .map((existingMeeting) => (
                        <TransitionOne>
                            <div className='session'>
                                <div className='session-first-group'>
                                    <div className='session-title'>{existingMeeting.title}{isActive(existingMeeting.event_timing) ? <span>LIVE</span> : null}</div>
                                    <div className='session-date'>{toUpper(getTime(existingMeeting.event_timing).format('MM/DD/YYYY'))} @ {toUpper(getTime(existingMeeting.event_timing).format('h:mm a'))} EST</div>
                                </div>
                                {
                                    true ? (
                                        <a href={existingMeeting.event_link} target="_blank">Join {meetingType} &nbsp;<img src={AnchorIcon} /></a>
                                    ) : null
                                }
                            </div>
                        </TransitionOne>
                    )) : null
            }
        </section>
    ) : null;
}

export default UpComingSessions