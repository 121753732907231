import createReducer from "../helpers/createReducers";
import isEmpty from "lodash/isEmpty";
import { fromJS, Map } from "immutable";
import isNil from "lodash/isNil";

const initialState = fromJS({
  recipe: {
    count: 0,
    errorMessage: "",
    title: "",
    agreedToCount: false,
    limitReached: false,
    images: {},
    isFetching: false,
  },
  workout: {
    count: 0,
    errorMessage: "",
    title: "",
    agreedToCount: false,
    limitReached: false,
    isFetching: false,
  },
  video: {
    count: 0,
    errorMessage: "",
  },
});

// TODO: Add a default user detail fetch on saga -d
export default createReducer(initialState, {
  APP_ESSENTIAL_FETCH: (state, action) => {
    const token =
      typeof localStorage !== "undefined" && localStorage
        ? localStorage.getItem("token")
        : null;
    if (!token) {
      state = state.setIn(["recipe", "isFetching"], true);
      state = state.setIn(["workout", "isFetching"], true);

      state = state.setIn(["recipe", "agreedToCount"], false);
      state = state.setIn(["workout", "agreedToCount"], false);
    }

    return state;
  },
  LOGIN_USER_SUCCESS: (state, action) => {
    return initialState;
  },
  UPDATE_OBJECT_TO_STACK: (state, action) => {
    state = state.setIn(["recipe", "isFetching"], false);
    state = state.setIn(["workout", "isFetching"], false);
    state = state.setIn(
      [`${action.payload.objectType}`, "count"],
      action.payload.meteredCount
    );
    return state;
  },
  UPDATE_OBJECT_TO_STACK_FAILURE: (state, action) => {
    state = state.setIn([`${action.payload.objectType}`, "limitReached"], true);
    state = state.setIn(
      [`${action.payload.objectType}`, "title"],
      action.payload.title
    );
    state = state.setIn(
      [`${action.payload.objectType}`, "images"],
      fromJS(action.payload.images)
    );
    state = state.setIn(
      [`${action.payload.objectType}`, "errorMessage"],
      "limitExceeded"
    );

    if (action.payload.objectType === "recipe") {
      state = state.setIn(
        [`${action.payload.objectType}`, "story"],
        action.payload.story
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "variations"],
        action.payload.variations
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "favorite_count"],
        action.payload.favoriteCount
      );
    }
    if (action.payload.objectType === "workout") {
      state = state.setIn(
        [`${action.payload.objectType}`, "calorie_burn"],
        action.payload.calorie_burn
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "difficulty"],
        action.payload.difficulty
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "favorite_count"],
        action.payload.favorite_count
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "introduction"],
        action.payload.introduction
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "message"],
        action.payload.message
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "status"],
        action.payload.status
      );
      state = state.setIn(
        [`${action.payload.objectType}`, "time"],
        action.payload.time
      );

      return state;
    }
    return state;
  },
  AGREE_TO_METERED_VISIT: (state, action) => {
    state = state.setIn(
      [
        `${action.payload.objectType.substring(
          0,
          action.payload.objectType.length - 1
        )}`,
        "agreedToCount",
      ],
      true
    );

    return state;
  },
});
