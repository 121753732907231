import React from "react";
import { themeTextRed } from "../../helpers/theme";
import "../styles/common.scss";

const NavDownArrow = props => {
  return (
    <>
      <svg
        className="navDropdownArrow"
        width="9"
        height="10"
        viewBox="0 0 9 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.07106 5.05018L4.53552 8.58572L0.999989 5.05018"
          stroke={themeTextRed}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};
export default NavDownArrow;
