import React, { useState } from "react";
import cx from "classnames";
import { withRouter } from "react-router-dom";
import CloseIcon from "../../images/closeIcon";

const TopBanner = ({ history, topBanner, setTopBanner }) => {
  const adjustHeader = () => {
    const appBanner = localStorage.getItem("appBanner");
    localStorage.removeItem("couponBanner");
    if (appBanner === "true") {
      header = document.querySelector("header");
      header.style.position = "sticky";
      header.style.top = "0px";
      const content = document.getElementById("content");
      content.style.paddingTop = "0px";
    } else {
      header = document.querySelector("header");
      header.style.position = "sticky";
      header.style.top = "0px";
      const content = document.getElementById("content");
      content.style.paddingTop = "0px";
    }
  };
  return (
    <>
      {topBanner && (
        <section className={cx("top-banner", { active: topBanner })}>
          <div className="container">
            <div className="banner-content">
              <a
                href={"/pricing"}
                onClick={(e) => {
                  e.preventDefault();
                  setTopBanner(!topBanner);
                  adjustHeader();
                  history.push("/pricing");
                }}
              >
                <p>
                  July 4th Sale | 40% OFF ON YEARLY MEMBERSHIP
                </p>
              </a>
              <span style={{
                position: 'relative',
                left: '20px',
                top: '-1px'
              }}>
                <CloseIcon
                  onClick={() => {
                    adjustHeader();
                    setTopBanner(!topBanner);
                  }}
                ></CloseIcon>
              </span>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default withRouter(TopBanner);
