import React from 'react'
import GhostCover from '../../common/ghostCover';
// component not in use
const DottedAction = React.forwardRef((props, ref) => {
  return (
    <div
      className="DottedDiv">
      {
        props.displayDropdown ? (
          <div ref={ref} className={`DottedAction ${props.additionalActionClass}`}>
            <ul className="dropdownlist">
              {
                props.options.map(option => (
                  <li onClick={(e) => {
                    option.action();
                    props.setDisplayDropdown(!props.displayDropdown)
                  }}>
                    {option.label}
                  </li>
                ))
              }
            </ul>
          </div>
        ) : null
      }
    </div>
  )
}
)

DottedAction.defaultProps = {
  setDisplayDropdown: () => { }
}

export default DottedAction;