import React from "react";
import '../styles/common.scss';

const CloseIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      className="close"
      width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.5762 1.67773L1.67668 11.5772" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M11.5763 11.5772L1.67676 1.67773" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
};
export default CloseIcon;