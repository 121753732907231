import React from 'react'
import isEqual from 'lodash/isEqual';
import Radiobox from '../../common/radiobox';

const GroceryListGroup = (props) => {
  const isChecked = props.groceryListSelectedIngredients.findIndex(ingredients => isEqual(ingredients.id, props.id)) !== -1;

  return (
    <div key={name} className="grocery-ingredients">
      <Radiobox
        checkId={props.id === null ? index : props.id}
        label={props.item}
        onSelect={(selected) => {
          selected ? props.selectIngredientInGroceryList({ id: props.id, categoryId: props.categoryId }) :
            props.removeIngredientInGroceryList({ id: props.id, categoryId: props.categoryId })
        }}
        checked={isChecked}
        type="checkbox"
      />

    </div>
  )
}


export default GroceryListGroup