import React from 'react'
import replace from 'lodash/replace'
import drop from 'lodash/drop'
import map from 'lodash/map'

import getMarkdownImage from '../../../../helpers/getMarkdownImage'
import convertQuality from '../../../../helpers/convertQuality';
import { isNewTagStatus } from "../../../../helpers/getNewTagStatus";
import GridCard from '../../../common/GridCard';


const FeaturedSection = ({
  data: featuredList,
  isAuthenticated,
  renderBlogMeta,
  deleteFavourite,
  toggleFavoriteModal,
  loginRedirect,
  history,
}) => {
  const thumbnail = listItem.image ? convertQuality(listItem.image, 'thumb') : (!isEmpty(listItem.content) ? getMarkdownImage(listItem.content, 'thumb')[1] : '')
  return (
    <div className="featuredWrapper">
      <h2>Featured</h2>
      <ul className="featuredLists">
        {
          map(drop(featuredList), listItem => (
            <li className="featuredBlog">
              <GridCard
                key={listItem.title}
                item={listItem}
                coverImage={listItem.image ? convertQuality(listItem.image, 'medium') : (!isEmpty(listItem.content) ? getMarkdownImage(listItem.content, 'medium')[1] : '')}
                feature="blog"
                isAuthenticated={isAuthenticated}
                objectType="blog"
                history={history}
                deleteFavourite={deleteFavourite}
                loginRedirect={loginRedirect}
                toggleFavoriteModal={toggleFavoriteModal}
                renderMeta={renderBlogMeta(listItem)}
                thumbnail={thumbnail}
                showTag={isNewTagStatus({ date: item.updated_at, isISOFormat: true })}
              />
            </li>
          ))
        }
      </ul>
    </div>
  )
}

export default FeaturedSection