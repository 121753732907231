import { put, takeEvery, call, select } from 'redux-saga/effects'
import { showToastMessage } from '../actions'
import { getCurrentObjectId, getNoteStack, getRecipeByIdFromAnyList } from './selectors'
import * as actions from '../actions/actionTypes';
import noteTask from './subtask/notes';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  //For Toast message
  const getState = state => state;
  const state = yield select(getState)

  try {

    const {
      noteId,
      note,
      objectId,
      objectType,
      objectSlug
    } = action.payload;

    // preparing data object to send to api
    const noteObject = {
      object_type: objectType,
      object_id: objectId,
      content: note,
    }

    // if note id is 0 then it will be undefinied , this will result in POST of note
    // const noteId = state.toJS().recipe.noteId === 0 ? undefined : state.toJS().recipe.noteId
    // TODO : add note id selector
    // api call
    const { data } = yield call(noteTask.post, noteObject, noteId);

    // calling action to store the updates
    yield put({
      type: actions.CREATE_NOTE_TO_OBJECT_SUCCESS,
      payload: {
        noteObject: data,
        objectId,
        objectType,
        objectSlug
      }
    })
    yield put(showToastMessage(`👍🏼 Note updated.`, 'success', 'note'));
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in creating new note`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CREATE_NOTE_TO_OBJECT_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* sendNote() {
  yield takeEvery(actions.CREATE_NOTE_TO_OBJECT, task)
}