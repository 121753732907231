import React from 'react'
import '../styles/common.scss';

const InstaIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M17.52 0H6.52002C2.94006 0 0.0200195 2.91992 0.0200195 6.5V17.5C0.0200195 21.0801 2.94006 24 6.52002 24H17.52C21.1 24 24.02 21.0801 24.02 17.5V6.5C24.02 2.91992 21.11 0 17.52 0ZM18.5199 6.5C17.6899 6.5 17.0199 5.83008 17.0199 5C17.0199 4.16992 17.6899 3.5 18.5199 3.5C19.3499 3.5 20.0199 4.16992 20.0199 5C20.0199 5.83008 19.3499 6.5 18.5199 6.5ZM12.0199 17.5C8.97986 17.5 6.5199 15.04 6.5199 12C6.5199 8.95996 8.97986 6.5 12.0199 6.5C15.0599 6.5 17.5199 8.95996 17.5199 12C17.5199 15.04 15.0599 17.5 12.0199 17.5Z" fill="#6B6D71" />
    </svg>
  )
}

export default InstaIcon; 