import React, { useState, useEffect, useRef, useLayoutEffect } from "react";

import InfiniteScroll from "react-infinite-scroller";
import map from "lodash/map";
import moment from "moment";
import useStayScrolled from "react-stay-scrolled";
import concat from "lodash/concat";
import capitalize from "lodash/capitalize";
import isNil from "lodash/isNil";

import findIndex from "lodash/findIndex";
import cx from "classnames";
import Loader from "../../../images/loader";
import CloseIcon from "../../../images/closeIcon";

import ModalSearch from "../../../common/modalSearch";
import MealPlanGridItem from "../mealPlanGridItem";
import Selector from "../../../common/selector";

import InputRange from "../../../common/inputRange";
import Filters from "../../../common/filters";
import ChangeSortOrder from "../../../common/changeSortOrder";
import LinkButton from "../../../common/linkButton";
import LeftArrow from "../../../images/LeftArrow";
import RightArrow from "../../../images/RightArrow";
import { isNewTagStatus } from "../../../../helpers/getNewTagStatus";

let options = [
  {
    title: "Ascending",
    value: "asc"
  },
  {
    title: "Descending",
    value: "desc"
  },
  {
    title: "Randomized",
    value: "rand"
  },
  {
    title: "Most Popular",
    value: "favorite"
  }
];

const getMealPlanCategoriesOption = (
  availableCategories,
  appliedCategories
) => {
  return map(availableCategories, availableFilter => {
    return {
      label: availableFilter.name,
      value: availableFilter.id,
      slug: availableFilter.slug,
      image: availableFilter.icon,
      isSelected: availableFilter.slug === appliedCategories[0]
    };
  });
};

const otherFilterOptions = (state, dispatch) => [
  <InputRange
    label="Budget"
    labelClass="filterTitle"
    wrapperClass="FreestyleStyle"
    minValue={0}
    maxValue={300}
    conditionalFormatValue={300}
    conditionalFormatLabel="300+"
    onChangeComplete={(value, label) => {
      dispatch({
        type: "UPDATE_BUDGET",
        payload: {
          budget: value
        }
      });
    }}
    defaultMinValue={0}
    defaultMaxValue={state.budget}
  />,
  <InputRange
    label="Calories"
    labelClass="filterTitle"
    wrapperClass="FreestyleStyle"
    minValue={0}
    maxValue={2000}
    conditionalFormatValue={2000}
    conditionalFormatLabel="2000+"
    onChangeComplete={(value, label) => {
      dispatch({
        type: "UPDATE_CALORIES",
        payload: {
          calories: value
        }
      });
    }}
    defaultMinValue={0}
    defaultMaxValue={state.calories}
  />,
  <InputRange
    label="Days"
    labelClass="filterTitle"
    wrapperClass="FreestyleStyle"
    minValue={0}
    maxValue={14}
    onChangeComplete={(value, label) => {
      dispatch({
        type: "UPDATE_DAYS",
        payload: {
          days: value
        }
      });
    }}
    defaultMinValue={0}
    defaultMaxValue={state.days}
  />
];

// generates the filer options like categories , collections .....
export const getFilterOptions = (state, dispatch) => {
  const mealPlanCategoriesOption = getMealPlanCategoriesOption(
    state.categoriesAvailable,
    state.categoriesApplied
  );

  const filterOptions = [
    {
      title: "Categories",
      options: mealPlanCategoriesOption,
      isOptionsList: true, // to differentiate between mappable list like collections , and non mappable like wightWatchers
      additionalDropdownWrapperClass: "",
      singleSelectable: true,
      isSelected: state.categoriesApplied.length > 0
    },
    {
      title: "Filters",
      options: otherFilterOptions(state, dispatch),
      isOptionsList: false,
      additionalDropdownWrapperClass: "weightWatchersOption",
      singleSelectable: false,
      isSelected: state.budget !== 300 || state.calories !== 2000
    }
  ];
  return filterOptions;
};

const PremadeMealPlanList = ({
  isAuthenticated,
  activeDate,
  updateActiveDate,
  state,
  dispatch,
  ...props
}) => {
  const listRef = useRef();
  const { stayScrolled /*, scrollBottom*/ } = useStayScrolled(listRef);

  const [history, setHistory] = useState({
    location: {
      match: {
        url: ""
      }, // Just to prevent error
      path: "/recipes", // Used majorly
      search: state.queryString // for hightlighting filter
    }
  });

  const {
    data: list,
    categoriesAvailable: availableCategories,
    categoriesApplied: appliedCategories,
    budget,
    calories
  } = state;

  const isFilterTagApplied = item =>
    findIndex(availableCategories, slug => slug === item.slug) !== -1;

  const getListTitle = () => {
    if (state.dataType === "unfiltered" && appliedCategories.length === 0) {
      return "Recently Published";
    } else if (
      appliedCategories.length > 0 ||
      budget !== 300 ||
      calories !== 2000
    ) {
      const filterSlug = capitalize(appliedCategories[0]);
      return `${
        filterSlug
          ? `${filterSlug} ${budget !== 300 || calories !== 2000 ? "&" : ""}`
          : ""
      } ${budget !== 300 ? `Budget < ${budget}$` : ""} ${
        calories !== 2000 ? `Calories < ${calories} CAL` : ""
      }`;
    } else if (state.dataType === "favorite") {
      return `Favorited Meal Plans`;
    }
  };

  const filterOptions = getFilterOptions(state, dispatch);

  const cardAdditionStyles = { maxHeight: 220, minHeight: 220 };

  useLayoutEffect(() => {
    stayScrolled();
  }, []);

  const isBoards = history.location.path === "/mealplans/favorites";

  // let preloaderRecipes = map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], (item, index) => ({isLoading: true, id: null }))

  // const modifiedPreloadedRecipes = state.offset > 0 ? preloaderRecipes : concat(state.data, preloaderRecipes)
  // const actualRecipes = state.isFetching ? modifiedPreloadedRecipes : state.data;

  const showClearText =
    appliedCategories.length > 0 ||
    state.search ||
    state.budget !== 300 ||
    state.calories !== 2000;

  const startDate = moment()
    .startOf("day")
    .format("YYYY-MM-DD");
  const endDate = moment()
    .startOf("day")
    .add({ days: 14 })
    .format("YYYY-MM-DD");
  return (
    <>
      <div className="modalWrapper fullScreenModal">
        <div className="modalHeader fullScreenModalHeader">
          <div className="previous-day">
            <LeftArrow></LeftArrow>
            <LinkButton
              renderValue={() => (
                <>
                  {moment(activeDate)
                    .clone()
                    .subtract({ days: 1 })
                    .format("dddd Do")}
                </>
              )}
              onClick={e =>
                updateActiveDate(
                  moment(activeDate)
                    .clone()
                    .subtract({ days: 1 })
                    .format("YYYY-MM-DD")
                )
              }
              disabled={
                !moment(activeDate)
                  .clone()
                  .subtract({ days: 1 })
                  .isBetween(startDate, endDate, null, "[)")
              }
            />
          </div>
          <h3>
            {moment(activeDate)
              .clone()
              .format("dddd Do")}
          </h3>
          <div className="next-day">
            <LinkButton
              renderValue={() => (
                <>
                  {moment(activeDate)
                    .clone()
                    .add({ days: 1 })
                    .format("dddd Do")}
                </>
              )}
              onClick={e =>
                updateActiveDate(
                  moment(activeDate)
                    .clone()
                    .add({ days: 1 })
                    .format("YYYY-MM-DD")
                )
              }
              disabled={
                !moment(activeDate)
                  .clone()
                  .add({ days: 1 })
                  .isBetween(startDate, endDate, null, "[)")
              }
            />
            <RightArrow></RightArrow>
          </div>
        </div>
        <section className="recipes">
          <Filters
            withRoute={false}
            objectType="mealplan"
            actions={(name, slug, filterName) => {
              if (appliedCategories[0] !== slug) {
                props.browserHistory.push(
                  `/calendar?meal-plans=true&category=${slug}`
                );
              } else {
                props.browserHistory.push(`/calendar?meal-plans=true`);
              }

              setTimeout(() => {
                dispatch({
                  type: "UPDATE_CATEGORY",
                  payload: {
                    previouslySelected: appliedCategories[0] === slug,
                    appCategory: slug
                  }
                });
              }, [50]);
            }}
            showFilters={state.dataType !== "favorite"}
            isFavorites={state.dataType === "favorite"}
            options={filterOptions}
            showClearText={showClearText}
            staticOption={{
              primaryOption: "Clear Filters",
              secondaryOption: "All Meal Plans",
              route: "/mealplan"
            }}
            onClickSearchClear={() => {
              dispatch({
                type: "CANCEL_SEARCH"
              });
            }}
            onClickClearFilter={() => {
              dispatch({
                type: "RESET_TO_INITIAL_STATE"
              });
            }}
            isAuthenticated={isAuthenticated}
            dropDownReturnActionText={`See all Recipes`}
            showInstantResults
            dropDownReturnAction={(searchText, searchBy) => {
              dispatch({
                type: "SET_SEARCH",
                payload: {
                  search: searchText
                }
              });
            }}
            history={{
              ...history,
              push: path => {
                if (path === "/mealplans/fav-boards" && !state.isFetching) {
                  dispatch({
                    type: "CHANGE_DATA_TYPE_TO_FAVORITE",
                    payload: {
                      favoriteSlug: null
                    }
                  });
                }
              }
            }}
            placeholder="Search meal plans"
            searchText={state.search}
            onSearchChange={(searchText, searchBy) => {
              // Results are instant
            }}
            onSearchByChange={(value, searchText) => {
              // Search by changed
            }}
            defaultSearchText={state.search}
            defaultSearchBy="title"
            searchList={[]}
            searchStatus={state.isFetching}
            searchCount={0}
            showDefaultInput={!isNil("title") || !isNil(filter.search)}
            favoriteOptions={{
              title: "Favorites",
              isSelected: state.dataType === "favorite"
            }}
          />
          {!isBoards ? (
            <div className="container">
              <div className="calendar-modal-container">
                <div className="scrollable-modal-container" ref={listRef}>
                  <InfiniteScroll
                    className="infiniteScroll"
                    initialLoad={false}
                    useWindow={false}
                    loadMore={
                      !state.isFetching
                        ? () => {
                            dispatch({
                              type: "UPDATE_OFFSET"
                            });
                          }
                        : () => {}
                    }
                    hasMore={state.hasMore}
                    loader={
                      <div className="container">
                        <div className="preLoader-minimal-height">
                          <Loader></Loader>
                        </div>
                      </div>
                    }
                  >
                    <ChangeSortOrder
                      active={true}
                      history={history}
                      objectType="recipe"
                      title={getListTitle()}
                      active={state.dataType !== "favorite"}
                      order={state.order}
                      history={{
                        ...history,
                        push: path => {
                          setHistory({
                            ...history,
                            location: {
                              path,
                              search: path
                            }
                          });
                          const order = path.split("=")[1];
                          // Ordering will happen here
                          dispatch({
                            type: "CHANGE_ORDER",
                            payload: {
                              order,
                              force: order === "rand"
                            }
                          });
                        }
                      }}
                      setFiltersAndFetch={filters => {
                        const order = state.order;
                        dispatch({
                          type: "CHANGE_ORDER",
                          payload: {
                            order,
                            force: order === "rand"
                          }
                        });
                      }}
                    >
                      {!state.hasMore &&
                      !state.isFetching &&
                      list.length === 0 ? (
                        <div className="recipeList in-modal">No Meal plans</div>
                      ) : (
                        <ul className="recipeList in-modal recipeListCard">
                          <>
                            {map(list, (item, index) => {
                              return (
                                <li
                                  key={index}
                                  className="recipe"
                                  onClick={() => props.moveToIndex(item)}
                                >
                                  <MealPlanGridItem
                                    additionalStyles={cardAdditionStyles}
                                    plan={item}
                                    showTag={isNewTagStatus({
                                      date: item ? item.publish_date : "",
                                      isISOFormat: false
                                    })}
                                  />
                                </li>
                              );
                            })}
                          </>
                        </ul>
                      )}
                    </ChangeSortOrder>
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          ) : null}
        </section>
      </div>
    </>
  );
};

export default PremadeMealPlanList;
