import createReducer from "../helpers/createReducers";

import { fromJS, List, update } from "immutable";
const initialState = fromJS({
  recipe: {
    objectStack: [],
    noteStack: [],
    commentStack: [],
    stackTopObjectId: null,
    stackTopObjectType: null,
    stackLength: 0,
    stackTopObjectActiveVariationId: null,
    stackTopObjectActiveVariationIndex: '0',
  },
  workout: {
    objectStack: [],
    noteStack: [],
    commentStack: [],
    stackTopObjectId: null,
    stackTopObjectType: null,
    stackLength: 0,
  },
  blog: {
    objectStack: [],
    noteStack: [],
    commentStack: [],
    stackTopObjectId: null,
    stackTopObjectType: null,
    stackLength: 0,
  },
  video: {
    objectStack: [],
    noteStack: [],
    commentStack: [],
    stackTopObjectId: null,
    stackTopObjectType: null,
    stackLength: 0,
  },
  forUser: null
});

export default createReducer(initialState, {
  // When object specific details are getting fetched
  // Happens for when a new object is being fetched
  // For comments / notes
  GET_OBJECT_SPECIFIC_ESSENTIAL_SUCCESS: (state, action) => {
    const {
      objectType
    } = action.payload;
    // Get existing items in stack
    const noteStack = state.getIn([objectType, 'noteStack']);
    const commentStack = state.getIn([objectType, 'commentStack']);
    // Updates note stack
    const newNote = action.payload.notesData.length > 0 ? action.payload.notesData[0] : {
      object_id: action.payload.objectId,
      content: null,
      id: null
    };

    const updatedNoteStack = noteStack.setIn([0], fromJS({
      slug: action.payload.slug,
      data: newNote
    }));

    // Updates comment stack
    const updatedCommentStack = commentStack.setIn([0], fromJS({
      slug: action.payload.slug,
      objectId: action.payload.objectId,
      data: fromJS(action.payload.commentsData),
      commentsCount: action.payload.commentsCount
    }));

    // Set all the stacks to have default value
    state = state.setIn([objectType, 'noteStack'], updatedNoteStack);
    state = state.setIn([objectType, 'commentStack'], updatedCommentStack);

    // Updates the top most item on the stack for (id, type)
    state = state.setIn([objectType, 'stackTopObjectId'], fromJS(action.payload.objectId));
    state = state.setIn([objectType, 'stackTopObjectType'], fromJS(action.payload.objectType));

    // Other updates
    state = state.setIn([objectType, 'stackLength'], 0);

    state = state.setIn(['forUser'], fromJS(action.payload.forUser));
    return state;
  },
  // TODO: use these for controlling object stack
  // for web: (objectSpecificEssential.js) by default all object get pushed on 0th place in list 
  PUSH_OBJECT_TO_STACK: (state, action) => {
    const {
      objectType
    } = action.payload;
    const currentObjectStack = state.getIn([objectType, 'objectStack']);
    let newObject;
    if (objectType == 'recipe') {
      newObject = fromJS({
        slug: action.payload.data.slug,
        data: {
          recipe: action.payload.data,
          relatedRecipes: action.payload.relatedRecipes,
          tags: [],
        }
      })
    } else if (objectType == 'workout') {
      newObject = fromJS({
        slug: action.payload.data.slug,
        data: {
          workout: action.payload.data,
          relatedWorkouts: action.payload.relatedWorkouts,
          tags: [],
        }
      })
    } else if (objectType === 'blog') {
      newObject = fromJS({
        slug: action.payload.data.slug,
        data: {
          blog: action.payload.data,
          relatedWorkouts: action.payload.relatedBlogs,
          categories: action.payload.categories,
        }
      })
    }
    return state.setIn([objectType, 'objectStack', 0], newObject)
  },

  // update object in stack with updated and additional API data
  UPDATE_OBJECT_TO_STACK: (state, action) => {
    const {
      objectType
    } = action.payload;
    const currentObjectStack = state.getIn([objectType, 'objectStack']);
    let updatedObject;
    if (action.payload.data) {
      if (objectType == 'recipe') {
        updatedObject = fromJS({
          slug: action.payload.data.slug,
          data: {
            recipe: action.payload.data,
            relatedRecipes: action.payload.relatedRecipes,
            tags: action.payload.tags,
          }
        })
      } else if (objectType == 'workout') {
        updatedObject = fromJS({
          slug: action.payload.data.slug,
          data: {
            workout: action.payload.data,
            relatedWorkouts: action.payload.relatedWorkouts,
            tags: action.payload.tags,
          }
        })
      } else if (objectType == 'blog') {
        updatedObject = fromJS({
          slug: action.payload.data.slug,
          data: {
            blog: action.payload.data,
            relatedBlogs: action.payload.relatedBlogs,
            categories: action.payload.categories,
          }
        })
      }
      return state.setIn([objectType, 'objectStack', 0], updatedObject)
    }

    return state;

  },

  POP_OBJECT_FROM_STACK: (state, action) => {
    const {
      objectType
    } = action.payload;
    state = state.setIn([objectType, 'objectStack'], state.getIn([objectType, 'objectStack']).pop());
    state = state.setIn([objectType, 'noteStack'], state.getIn([objectType, 'noteStack']).pop());
    state = state.setIn([objectType, 'commentStack'], state.getIn([objectType, 'commentStack']).pop());
    state = state.setIn([objectType, 'stackLength'], state.getIn([objectType, 'stackLength']) - 1);
    return state
  },

  CLEAR_OBJECT_STACK: (state, action) => {

    if (!action.payload.objectType) {
      state = initialState;
    } else if (action.payload.objectType === 'recipe') {
      const recipeInitialState = fromJS({
        objectStack: [],
        noteStack: [],
        commentStack: [],
        stackTopObjectId: null,
        stackTopObjectType: null,
        stackLength: 0,
        stackTopObjectActiveVariationId: null,
      });

      state = state.setIn(['recipe'], recipeInitialState);
    } else if (action.payload.objectType === 'workout') {
      const workoutInitialState = fromJS({
        objectStack: [],
        noteStack: [],
        commentStack: [],
        stackTopObjectId: null,
        stackTopObjectType: null,
        stackLength: 0,
      })

      state = state.setIn(['workout'], recipeInitialState);
    };

    return initialState;
  },

  // For add note live reactiviy
  // Change every item in the stack with same object id
  // Assuming every item in the stack has same object type
  CREATE_NOTE_TO_OBJECT_SUCCESS: (state, action) => {
    const {
      objectType,
    } = action.payload;

    const noteStack = state.getIn([objectType, 'noteStack']);

    const updatedNoteStack = noteStack
      .toSeq()
      .reduce((accumulator, noteItem) => {
        if (noteItem.getIn(['data', 'object_id']) === action.payload.objectId) {
          noteItem = noteItem.setIn(['data', 'content'], fromJS(action.payload.noteObject.note.content));
          noteItem = noteItem.setIn(['data', 'id'], fromJS(action.payload.noteObject.note.id));
        }
        return accumulator.push(noteItem);
      }, List())


    return state.setIn([objectType, 'noteStack'], updatedNoteStack);
  },

  // For add comments live reactivity
  CREATE_COMMENT_TO_OBJECT_SUCCESS: (state, action) => {
    const {
      objectType,
      objectId,
      updatedRating,
      approved,

    } = action.payload;

    if (action.payload.commentObject.content.length > 0 && approved) {
      const commentStack = state.getIn([objectType, 'commentStack']);

      const updatedCommentStack = commentStack
        .toSeq()
        .reduce((accumulator, commentItem) => {
          let currentComments = commentItem.getIn(['data']);
          if (commentItem.getIn(['objectId']) === action.payload.objectId) {

            let newComment = fromJS(action.payload.commentObject)

            // need parent to be null when new comment is added (this is for mobile)
            if (newComment.getIn(['comment_parent']) === null) {
              newComment = newComment.setIn(['parent'], null)
              newComment = newComment.remove('comment_parent')

            }

            let newCommentUserDetails = {
              name: newComment.getIn(['author']),
              role: action.payload.commentObject.role,
            }

            newComment = newComment.setIn(['user'], newCommentUserDetails);

            // to add comment on top of the list
            currentComments = currentComments.unshift(newComment);
          }
          commentItem = commentItem.setIn(['data'], currentComments);
          accumulator = accumulator.push(commentItem);
          return accumulator;
        }, List());
      state = state.setIn([objectType, 'commentStack'], updatedCommentStack)
    }


    // to update the rating value
    let objectStack = state.getIn([objectType, 'objectStack']);
    let newObjectStack = objectStack
      .toSeq()
      .reduce((accumulator, objectItem) => {
        if (objectItem.getIn(['data', objectType, 'id']) == objectId) {
          objectItem = objectItem.setIn(['data', objectType, 'avg_rating'], updatedRating);
          objectItem = objectItem.setIn(['data', objectType, 'is_rated'], true);

        }
        return accumulator.push(objectItem);
      }, List());
    state = state.setIn([objectType, 'objectStack'], newObjectStack);

    return state
  },

  SEND_REPLY_COMMENT_TO_OBJECT_SUCCESS: (state, action) => {
    const {
      payload: {
        commentObject,
        objectType
      }
    } = action;
    // Build this to keep the format as it is
    let formattedReplyComment = {
      content: commentObject.comment.content,
      children: [],
      date: new Date().toISOString(),
      id: commentObject.comment.id, // TODO: see if id is correct
      parent: commentObject.comment.comment_parent,
      user: {
        name: commentObject.comment.author,
        id: commentObject.comment.user_id,
        role: commentObject.comment.role
      }
    }


    let commentStack = state.getIn([objectType, 'commentStack']);

    let newCommnetStack = commentStack
      .toSeq()
      .reduce((accumulator, commentItem) => {

        let commentItemData = commentItem.getIn(['data'])
          .toSeq()
          .reduce((internalAccumulator, comment) => {
            if (comment.getIn(['id']) === formattedReplyComment.parent) {
              let newChildrenList = comment.getIn(['children']).push(formattedReplyComment);
              comment = comment.setIn(['children'], newChildrenList);
            }
            return internalAccumulator.push(comment);
          }, List());
        commentItem = commentItem.setIn(['data'], commentItemData);
        return accumulator.push(commentItem)
      }, List());
    state = state.setIn([objectType, 'commentStack'], newCommnetStack);
    return state
  },

  // CREATE_FAVOURITE_TO_OBJECT_SUCCESS
  CREATE_FAVOURITE_TO_OBJECT_SUCCESS: (state, action) => {
    if (action.payload.objectType !== 'meal_plan') {
      let objectStack = state.getIn([action.payload.objectType, 'objectStack']);
      let newObjectStack = objectStack
        .toSeq()
        .reduce((accumulator, objectItem) => {
          if (objectItem.getIn(['data', action.payload.objectType, 'id']) == action.payload.favouriteObject.object_id) {
            objectItem = objectItem.setIn(['data', action.payload.objectType, 'favorite'], true);
            objectItem = objectItem.setIn(['data', action.payload.objectType, 'favorite_count'],
              objectItem.getIn(['data', action.payload.objectType, 'favorite_count']) + 1
            );
          }
          return accumulator.push(objectItem);
        }, List());
      state = state.setIn([action.payload.objectType, 'objectStack'], newObjectStack);
    }
    return state
  },

  DELETE_FAVOURITE_SUCCESS: (state, action) => {
    if (action.payload.objectType !== 'meal_plan') {
      let objectStack = state.getIn([action.payload.objectType, 'objectStack']);
      let newObjectStack = objectStack
        .toSeq()
        .reduce((accumulator, objectItem) => {
          if (objectItem.getIn(['data', action.payload.objectType, 'id']) == action.payload.objectId) {
            objectItem = objectItem.setIn(['data', action.payload.objectType, 'favorite'], false);
            objectItem = objectItem.setIn(['data', action.payload.objectType, 'favorite_count'],
              objectItem.getIn(['data', action.payload.objectType, 'favorite_count']) - 1
            );
          }
          return accumulator.push(objectItem);
        }, List());
      state = state.setIn([action.payload.objectType, 'objectStack'], newObjectStack);
    }

    return state
  },

  ALTER_VARIATION_FOR_ACTIVE_RECIPE: (state, action) => {
    state = state.setIn(['recipe', 'stackTopObjectActiveVariationId'], action.payload.activeVariationId);
    state = state.setIn(['recipe', 'stackTopObjectActiveVariationIndex'], action.payload.stackTopObjectActiveVariationIndex);
    return state;
  },

});