import { ADD_MEAL_PLAN_TO_CALENDAR } from './actionTypes';

export default (meal_plan_id, start_date, remove_old, mealPlanDuration, addToGroceryList, servingSize) => ({
  type: ADD_MEAL_PLAN_TO_CALENDAR,
  payload: {
    meal_plan_id,
    start_date,
    remove_old,
    mealPlanDuration,
    addToGroceryList,
    servingSize,
  },
})