import { put, takeEvery, call, select } from 'redux-saga/effects'
import axios from 'axios'
import isEmpty from 'lodash/isEmpty';

import * as actions from '../actions/actionTypes';
import { showToastMessage } from '../actions'
import mealPlanTask from './subtask/mealplan';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    const isForRecipe = !isEmpty(action.payload.meal.recipe);
    let objectData = {
      date: action.payload.date,
      meal: 'breakfast' // Hardcoded
    }

    if (isForRecipe) {
      objectData["recipe_id"] = action.payload.meal.recipe.id || action.payload.meal.recipe_id;
      if (action.payload.meal.recipe.title) {
        objectData["custom_title"] = action.payload.meal.recipe.title;
      }
      if (action.payload.meal.recipe.notes) {
        objectData["custom_notes"] = action.payload.meal.recipe.notes;
      }
      if (action.payload.meal.recipe.url) {
        objectData["custom_url"] = action.payload.meal.recipe.url;
      }
    } else {
      objectData["workout_id"] = action.payload.meal.workout.id || action.payload.meal.workout_id;
    }

    const { data } = yield call(mealPlanTask.post, objectData);

    yield put(showToastMessage(`✅ Calendar item has been duplicated`, 'success', 'addIngredient'));

    yield put({
      type: actions.DUPLICATE_MEAL_IN_MEALPLAN_SUCCESS,
      payload: {
        success: true,
        mealData: data
      }
    })

  } catch (e) {

    yield put(showToastMessage(`❗️ Duplicating calendar item failed`, 'error'));

    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.DUPLICATE_MEAL_IN_MEALPLAN_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addMealInMealPlan() {
  yield takeEvery(actions.DUPLICATE_MEAL_IN_MEALPLAN, task)
}