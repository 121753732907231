// @flow
import React, { useState } from 'react'
import isNull from 'lodash/isNull';
import Fraction from 'fraction.js';
import Modal from '../../common/modal';
import AddIngredientModal from './addIngredientModal';
import MoveIngredientModal from './moveIngredientModal';
import GroceryListCategorySection from './GroceryListCategorySection';
import type { groceryList } from "../../../types";

type Props = {
  groceryList: groceryList,
  activeGroceryType: string,
  addIngredientInGroceryListAisle: (newIngredientName: string, categoryId: number) => void,
  addIngredientInGroceryListRecipe: (newIngredientName: string, recipeId: number) => void,
  moveIngredientInGroceryList: (ids: Array<number>, categoryId: number) => void,
  selectIngredientInGroceryList: ({ id: number, categoryId: number }) => void,
  removeIngredientInGroceryList: ({ id: number, categoryId: number }) => void,
  deleteIngredientInGroceryList: (Array<number>) => void,
  groceryListSelectedIngredients: Array<any>, //TODO: need to define specific

}

const GroceryListDetails = React.forwardRef < Props, HTMLDivElement> ((props, ref) => {
  const {
    groceryList,
    activeGroceryType,
    addIngredientInGroceryListAisle,
    addIngredientInGroceryListRecipe,
    moveIngredientInGroceryList,
    selectIngredientInGroceryList,
    removeIngredientInGroceryList,
    deleteIngredientInGroceryList,
    groceryListSelectedIngredients
  } = props;

  const [state, setState] = useState({
    showModal: false,
    currentModal: '',
    addIngredientModalData: {
      name: '',
      quantity: '',
      unit: '',
      categoryId: 1,
      recipeId: '',
    },
    moveIngredientModalData: {
      name: '',
      ids: [],
      categoryId: '',
    }
  });


  const setCurrentModal = (currentModal, showModal) => setState({
    ...state,
    currentModal,
    showModal,
    addIngredientModalData: {
      name: null,
      quantity: null,
      unit: null,
      categoryId: 1,
      recipeId: null,
    },
    moveIngredientModalData: {
      name: null,
      ids: [],
      categoryId: null,
    }
  });

  const setModalData = (modalName, newData) => setState({
    ...state,
    showModal: true,
    currentModal: modalName,
    [modalName]: {
      ...state[modalName],
      ...newData
    }
  });


  // on clicking plus icon on a category
  const onClickPlus = (grocery) => {
    if (activeGroceryType === 'recipe') {
      setModalData('addIngredientModalData', {
        recipeId: grocery.recipeId,
      })
    } else {
      setModalData('addIngredientModalData', {
        categoryId: grocery.id,
      })
    }
  }

  // On adding ingredients
  const onAddIngredient = () => {
    setCurrentModal('', false)
    let newIngredientName = '';

    newIngredientName = `${new Fraction(state.addIngredientModalData.quantity).toFraction(true)} ${state.addIngredientModalData.unit} ${state.addIngredientModalData.name}`

    if (activeGroceryType === 'aisle') {
      addIngredientInGroceryListAisle(newIngredientName, parseInt(state.addIngredientModalData.categoryId), {
        name: state.addIngredientModalData?.name,
        unit: state.addIngredientModalData?.unit,
        quantity: new Fraction(state.addIngredientModalData?.quantity).toFraction(true),
      });
    } else {
      addIngredientInGroceryListRecipe(newIngredientName, parseInt(state.addIngredientModalData.recipeId), {
        name: state.addIngredientModalData?.name,
        unit: state.addIngredientModalData?.unit,
        quantity: new Fraction(state.addIngredientModalData?.quantity).toFraction(true),
      });
    }
  }

  // On Moving Ingredients
  const onMoveIngredient = (result) => {
    setCurrentModal('', false)

    if (!result) {
      moveIngredientInGroceryList(state.moveIngredientModalData.ids, parseInt(state.moveIngredientModalData.categoryId))
    }
    // null when dropped outside of droppable
    else if (!isNull(result.destination)) {
      const categoryId = parseInt(result.destination.droppableId);
      const moveIngredientIds = result.draggableId.split(',').map(id => parseInt(id));
      moveIngredientInGroceryList(moveIngredientIds, categoryId)
    }
  }

  // onClickingIngredientsOptions
  const ingredientOptionsIcon = (item) => {
    setModalData('moveIngredientModalData', {
      name: item.name,
      ids: item.id
    })
  }

  // Create an object to show catogiries in dropdown in add category modal
  const typeOptions = ((groceryList && groceryList.categories) || []).map(category => ({
    value: activeGroceryType === 'recipe' ? category.recipeId : category.id,
    label: category.name
  }));


  return (
    <>
      <div ref={ref} className="grocery-groups">
        {
          groceryList && groceryList.categories.length === 0 ? (
            <div className="pageMessage">
              <div className="empty-content">
                <h2>This category is as empty<br></br>
                  as my plate after Thanksgiving.</h2>
              </div>
            </div>
          ) : (
            <GroceryListCategorySection
              groceryList={groceryList}
              onClickPlus={onClickPlus}
              selectIngredientInGroceryList={selectIngredientInGroceryList}
              removeIngredientInGroceryList={removeIngredientInGroceryList}
              deleteIngredientInGroceryList={deleteIngredientInGroceryList}
              moveIngredientsInGroceryList={onMoveIngredient}
              activeGroceryType={activeGroceryType}
              setModalData={setModalData}
              groceryListSelectedIngredients={groceryListSelectedIngredients}
            />
          )
        }


        <Modal
          isOpen={state.showModal}
          onRequestClose={() => setCurrentModal('')}
          minHeight='400px'
          maxWidth='440px'
        >
          {
            state.currentModal === 'addIngredientModalData' && (
              <AddIngredientModal
                data={state.addIngredientModalData}
                activeGroceryType={activeGroceryType}
                setModalData={setModalData}
                onAddIngredient={onAddIngredient}
                options={typeOptions}
                setCurrentModal={setCurrentModal}
              />
            )
          }
          {
            state.currentModal === 'moveIngredientModalData' &&
            <MoveIngredientModal
              data={state.moveIngredientModalData}
              activeGroceryType={activeGroceryType}
              setModalData={setModalData}
              onMoveIngredient={onMoveIngredient}
              setCurrentModal={setCurrentModal}
              options={typeOptions}
            />
          }
        </Modal>
      </div>
    </>
  )
})


export default GroceryListDetails