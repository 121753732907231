import React, { useEffect, useState } from 'react'
import { Tooltip } from "react-tippy";
import axios from 'axios'

import take from 'lodash/take'
import map from 'lodash/map'
import pushToSentry from "../../../helpers/pushToSentry";
import MealPlanGridItem from './mealPlanGridItem'
import { baseUrl } from '../../../helpers/api'
import GhostCover from '../../common/ghostCover'

import { isNewTagStatus } from "../../../helpers/getNewTagStatus";

const featuredData = [
  {
    name: '1 Day Plan',
    description: 'A good one day plan',
    slug: '1-day-plan',
  },
  {
    name: '3 Day Plan',
    description: 'A good one day plan',
    slug: '3-day-plan',
  },
  {
    name: '5 Day Plan',
    description: 'A good one day plan',
    slug: '5-day-plan',
  },
]

const FeaturedPlan = ({
  setMealPlanModalState,
  moveToIndex,
  openPreferredPlanChoose
}) => {
  const [premadeMealPlan, setPremadeMealPlan] = useState([]);
  const [showTip, setShowTip] = useState(false);

  useEffect(() => {
    axios(`${baseUrl}/api/v1/featured-meal-plans`, {
      headers: {
        Authorization: `Token token="${localStorage.getItem('token')}"`
      },
    })
      .then((response) => {
        setPremadeMealPlan(response.data.meal_plans)
      })
      .catch((e) => {
        const eventId = pushToSentry(e)
        addSentryError({
          error: e.message,
          eventId
        })
      });
  }, [])

  return (
    <ul className="featured-wrapper">
      <h6>
        <span>
          Latest meal plans &nbsp;|&nbsp;
          <Tooltip

            open={showTip}
            arrow={true}
            trigger="manual"
            theme="light"
            position="bottom-end"
            onRequestClose={() => { setShowTip(false) }}
            html={(
              <div className="actionPopover" style={{ padding: '1rem' }}>
                Choose your favorite Mealplans and get it straight at your Inbox every Week!
              </div>
            )}>
          </Tooltip>
          <span className="see-all" onClick={openPreferredPlanChoose} onMouseEnter={() => { setShowTip(true) }}>
            Pick Your Plan
          </span>
        </span>

        <span className="see-all" onClick={() => setMealPlanModalState()}>
          See all
        </span>
      </h6 >
      <div className="content">
        {
          map(take(premadeMealPlan, 3), plan => (
            <li onClick={() => moveToIndex(plan)}>
              <MealPlanGridItem
                plan={plan}
                showTag={isNewTagStatus({ date: plan.publish_date, isISOFormat: false })}
                removeCreated
              />
            </li>
          ))
        }
      </div>
    </ul >
  )

}

export default FeaturedPlan