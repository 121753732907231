import React, { useEffect, useReducer } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import Button from "../common/button";
import Selector from "../common/selector";
import CustomInput from "../common/customInput";
import FacebookOutlineIcon from "../images/FacebookOutlineIcon";
import VimeoOutlineIcon from "../images/VimeoOutlineIcon";
import InstaOutlineIcon from "../images/InstaOutlineIcon";
import TwitOutlineIcon from "../images/TwitOutlineIcon";
import PinOutlineIcon from "../images/PinOutlineIcon";
import FormSucessIcon from "../images/formSucess";
import FrameIcon from "../images/frameIcon";
import { baseUrl } from "../../helpers/api";

import {
  appEssentialFetch,
  addSentryError,
  showToastMessage,
} from "../../actions";
import pushToSentry from "../../helpers/pushToSentry";
import { TransitionOne, TransitionTwo } from "../common/transitions";

const initialState = {
  subject: "other",
  name: "",
  email: "",
  message: "",
  isValidForm: false,
  nameError: null,
  emailError: null,
  messageError: null,
  emailFocus: false,
  nameFocus: false,
  messageFocus: false,
  formSubmitted: false,
  formsubmitting: false,
  formSubmitSuccess: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "EMAIL_CHANGE":
      return {
        ...state,
        email: action.payload.email,
      };
    case "NAME_CHANGE":
      return {
        ...state,
        name: action.payload.name,
      };
    case "MESSAGE_CHANGE":
      return {
        ...state,
        message: action.payload.message,
      };
    case "SUBJECT_CHANGE":
      return {
        ...state,
        subject: action.payload.subject,
      };
    case "VALIDATE_FORM":
      let emailError = null;
      let nameError = null;
      let messageError = null;
      let isValidForm = true;
      if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(state.email)) {
        emailError = "Enter a valid email Id";
      }
      if (state.name.length < 2) {
        nameError = "Name should be at least 2 char. long.";
      }
      if (state.message.length < 2) {
        messageError = "Message should be at least 2 char. long.";
      }
      if (emailError || nameError || messageError) {
        isValidForm = false;
      }
      return {
        ...state,
        emailError,
        nameError,
        messageError,
        isValidForm,
      };
    case "ON_BLUR":
      return {
        ...state,
        emailFocus:
          action.payload.emailFocus && !state.emailFocus
            ? true
            : state.emailFocus,
        nameFocus:
          action.payload.nameFocus && !state.nameFocus ? true : state.nameFocus,
        messageFocus:
          action.payload.messageFocus && !state.messageFocus
            ? true
            : state.messageFocus,
      };
    case "FORM_SUBMIT":
      return {
        ...state,
        formsubmitting: action.payload.formsubmitting,
        formSubmitted: action.payload.formSubmitted,
      };
    case "FORM_SUCCESS":
      return {
        ...initialState,
        formsubmitting: false,
        formSubmitted: true,
        formSubmitSuccess: true,
      };
    default:
      return state;
  }
};

const ContactPage = (props) => {
  useEffect(() => {
    props.appEssentialFetch();
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const submitForm = () => {
    dispatch({
      type: "FORM_SUBMIT",
      payload: { formsubmitting: true, formSubmitted: false },
    });
    if (state.isValidForm) {
      // axios call
      axios({
        method: "POST",
        url: `${baseUrl}/api/contact`,
        data: {
          contact: {
            reason: state.subject,
            name: state.name,
            email: state.email,
            message: state.message,
          },
        },
      })
        .then((response) => {
          dispatch({
            type: "FORM_SUBMIT",
            payload: { formsubmitting: false, formSubmitted: true },
          });
          dispatch({ type: "FORM_SUCCESS" });
          props.showToastMessage(`👍🏼Message sent!`, "success");
        })
        .catch((e) => {
          pushToSentry(e);
          props.showToastMessage(`❗️Something went wrong!`, "error");
          dispatch({
            type: "FORM_SUBMIT",
            payload: { formsubmitting: false, formSubmitted: true },
          });
          props.addSentryError({
            error: e.message,
            eventId,
          });
        });
    }
  };

  return (
    <main>
      <Helmet>
        <title>Contact Us | Dashing Dish</title>
        <meta name="description" content="Dashingdish Contact page" />
      </Helmet>
      <section className="contact-page">
        <div className="container">
          <Row className="contact-wrapper no-margin">
            <Col xs={12} lg={6} className="no-padding">
              <div className="contact-form">
                {!state.formSubmitSuccess && (
                  <TransitionOne>
                    <div>
                      <div className="contact-hero-wrapper">
                        <h3>Our kitchen is always cooking</h3>
                        <p>
                          Fill out the form below to get in touch about anything
                          from technical problems to almond flour.
                        </p>
                      </div>
                      <form>
                        <div className="amount">
                          <span className="static-value">Subject</span>
                          <Selector
                            onSelectValue={(value) =>
                              dispatch({
                                type: "SUBJECT_CHANGE",
                                payload: { subject: value },
                              })
                            }
                            selectorList={[
                              { title: "Other", value: "other" },
                              { title: "Cancellation", value: "cancellation" },
                              { title: "Membership", value: "membership" },
                              { title: "Services", value: "services" },
                              { title: "Personal", value: "personal" },
                              { title: "Billing", value: "billing" },
                              { title: "Bugs", value: "bugs" },
                            ]}
                          />
                        </div>
                        <CustomInput
                          type="name"
                          placeholder="Type here"
                          label="Name"
                          value={state.name}
                          onChange={(e) => {
                            dispatch({
                              type: "NAME_CHANGE",
                              payload: { name: e.target.value },
                            });
                            dispatch({ type: "VALIDATE_FORM" });
                          }}
                          errorMessage={
                            state.nameError && state.nameFocus
                              ? state.nameError
                              : null
                          }
                          onBlur={(e) =>
                            dispatch({
                              type: "ON_BLUR",
                              payload: { nameFocus: true },
                            })
                          }
                        />
                        <CustomInput
                          type="email"
                          placeholder="Type here"
                          label="Email"
                          value={state.email}
                          onChange={(e) => {
                            dispatch({
                              type: "EMAIL_CHANGE",
                              payload: { email: e.target.value },
                            });
                            dispatch({ type: "VALIDATE_FORM" });
                          }}
                          errorMessage={
                            state.emailError && state.emailFocus
                              ? state.emailError
                              : null
                          }
                          onBlur={(e) =>
                            dispatch({
                              type: "ON_BLUR",
                              payload: { emailFocus: true },
                            })
                          }
                        />
                        <div className="message-container">
                          <label className="static-value">{"Message:"}</label>
                          <textarea
                            value={state.message}
                            className="messageInput"
                            placeholder="Type message here."
                            onChange={(e) => {
                              dispatch({
                                type: "MESSAGE_CHANGE",
                                payload: { message: e.target.value },
                              });
                              dispatch({ type: "VALIDATE_FORM" });
                            }}
                            onBlur={(e) =>
                              dispatch({
                                type: "ON_BLUR",
                                payload: { messageFocus: true },
                              })
                            }
                          />
                        </div>
                        {state.messageError && state.messageFocus && (
                          <div className="errorText">
                            <FrameIcon></FrameIcon>
                            <p>{state.messageError}</p>
                          </div>
                        )}
                        <Button
                          disabled={!state.isValidForm}
                          type="button"
                          btnClass="primary btn-full"
                          btnText="Submit"
                          onClick={submitForm}
                          isLoading={state.formsubmitting}
                        />
                      </form>
                    </div>
                  </TransitionOne>
                )}
                {state.formSubmitSuccess && (
                  <>
                    <TransitionOne>
                      <div className="contact-form-report">
                        <figure>
                          <FormSucessIcon></FormSucessIcon>
                        </figure>
                        <h3>Success!</h3>
                        <p>
                          We received your email! Look for a <br></br>response
                          within the next 24-48 hours!
                        </p>
                        <p>
                          {" "}
                          If you need an answer quicker, you may<br></br>try one
                          of the options on the right!
                        </p>
                      </div>
                    </TransitionOne>
                  </>
                )}
              </div>
            </Col>
            <TransitionTwo>
              <Col xs={12} lg={6} className="no-padding">
                <div className="contact-details">
                  <div className="contact-directly">
                    <h3>Contact Directly</h3>
                    <p>
                      Need an imediate answer? Try one of the ways below to get
                      in touch faster.
                    </p>
                    <ul className="link-lists">
                      <li>
                        <a href="mailto:support@dashingdish.com">
                          support@dashingdish.com
                        </a>
                      </li>
                      <li>
                        <a
                          href="/faqs"
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/faqs");
                          }}
                        >
                          Frequently Asked Questions
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="social">
                    <h3>Social</h3>
                    <p>
                      Connect with us on the socials and stay up <br></br>to
                      date on all of our yummy goods.
                    </p>
                    <div className="outline-social-icons">
                      <ul className="outline-icons-lists">
                        <li>
                          <a
                            href="https://www.facebook.com/dashingdish/"
                            target="_blank"
                          >
                            <FacebookOutlineIcon></FacebookOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://vimeo.com/dashingdish"
                            target="_blank"
                          >
                            <VimeoOutlineIcon></VimeoOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.pinterest.com/dashingdish/"
                            target="_blank"
                          >
                            <PinOutlineIcon></PinOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/dashingdish/?hl=en"
                            target="_blank"
                          >
                            <InstaOutlineIcon></InstaOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/dashingdish?lang=en"
                            target="_blank"
                          >
                            <TwitOutlineIcon></TwitOutlineIcon>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </TransitionTwo>
          </Row>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = () => ({});

const dispatchActionToProps = (dispatch) => ({
  addSentryError: bindActionCreators(addSentryError, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(ContactPage);
``;
