import axios from "axios";
import { baseUrl } from "./api";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";
import map from "lodash/map";
import join from "lodash/join";
import { useState, useEffect, useReducer } from "react";

let debouncer;

const initialState = {
  limit: 25,
  offset: 0,
  dataType: "unfiltered", // filtered or favorite or unfiltered
  favorite: false,
  isFetching: false,
  hasMore: true,
  newBatch: true,
  data: [],
  search: "",
  order: "desc",
  force: false,
  categoriesAvailable: [],
  categoriesApplied: [],
  categoriesIsFetching: false,
  budget: 300,
  calories: 2000,
  days: 14,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_CATEGORIES":
      return {
        ...state,
        categoriesIsFetching: true,
      };
    case "FETCH_CATEGORIES_SUCCESS":
      return {
        ...state,
        categoriesIsFetching: false,
        categoriesAvailable: action.payload.data,
      };
    case "UPDATE_CATEGORY":
      const { previouslySelected, appCategory } = action.payload;

      if (previouslySelected) {
        return {
          ...state,
          dataType: "unfiltered",
          favorite: false,
          search: "",
          newBatch: true,
          offset: 0,
          hasMore: true,
          categoriesApplied: [],
        };
      } else {
        return {
          ...state,
          dataType: "filtered",
          favorite: false,
          search: "",
          newBatch: true,
          offset: 0,
          hasMore: true,
          categoriesApplied: [appCategory],
        };
      }
    case "UPDATE_CALORIES":
      return {
        ...state,
        newBatch: true,
        force: state.order === "rand",
        calories: action.payload.calories,
      };
    case "UPDATE_BUDGET":
      return {
        ...state,
        newBatch: true,
        force: state.order === "rand",
        budget: action.payload.budget,
        dataType: "filtered",
      };
    case "UPDATE_DAYS":
      return {
        ...state,
        newBatch: true,
        force: state.order === "rand",
        days: action.payload.days,
        dataType: "filtered",
      };
    case "CHANGE_ORDER":
      return {
        ...state,
        newBatch: true,
        force: state.order === "rand",
        order: action.payload.order,
        categoriesApplied: action.payload.clearCategories
          ? []
          : state.categoriesApplied,
      };
    case "FETCH_NEW_DATA":
      const { favorite = false } = action.payload;
      return {
        ...state,
        data: state.newBatch ? [] : state.data,
        offset: state.newBatch ? 0 : state.offset,
        isFetching: true,
        dataType: favorite ? "favorite" : state.dataType,
      };
    case "FETCH_NEW_DATA_SUCCESS":
      return {
        ...state,
        data:
          state.offset === 0
            ? action.payload.data
            : [...state.data, ...action.payload.data],
        isFetching: false,
        newBatch: false,
        hasMore: action.payload.data.length >= state.limit,
        force: false,
      };
    case "UPDATE_OFFSET":
      return {
        ...state,
        newBatch: false,
        offset: state.offset + state.limit,
      };
    case "SET_SEARCH":
      return {
        ...state,
        favorite: false,
        favoriteSlug: null,
        newBatch: true,
        offset: 0,
        filterSlugs: [],
        budget: 300,
        calories: 2000,
        order: "desc",
        search: action.payload.search,
        searchBy: action.payload.searchBy,
      };
    case "CHANGE_DATA_TYPE_TO_FAVORITE":
      return {
        ...state,
        dataType: "favorite",
        favoriteSlug: action.payload.favoriteSlug,
        newBatch: true,
        hasMore: true,
        categoriesApplied: [],
        budget: 300,
        calories: 2000,
        offset: 0,
      };
    case "RESET_TO_INITIAL_STATE":
      return {
        ...initialState,
        categoriesAvailable: state.categoriesAvailable,
        categoriesApplied: [],
      };
    case "CANCEL_SEARCH":
      return {
        ...initialState,
        categoriesAvailable: state.categoriesAvailable,
        categoriesApplied: state.categoriesApplied,
      };
    default:
      return state;
  }
};

export const useMealPlanHook = ({ token, selectedCategory, sortorder }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    categoriesApplied: selectedCategory ? [selectedCategory] : [],
    order: sortorder,
  });

  if (state.categoriesAvailable.length === 0 && !state.categoriesIsFetching) {
    dispatch({
      type: "FETCH_CATEGORIES",
    });

    axios(`${baseUrl}/api/meal-plans/categories`, {
      headers: {
        Authorization: `Token token="${token}"`,
      },
    }).then((response) => {
      dispatch({
        type: "FETCH_CATEGORIES_SUCCESS",
        payload: {
          data: response.data.categories,
        },
      });
    });
  }

  const {
    offset,
    dataType,
    favorite,
    search,
    limit,
    order,
    force,
    budget,
    calories,
    days,
  } = state;

  const fetchMealPlans = () => {
    let finalQueryParam = "";

    if (dataType === "unfiltered" || dataType === "filtered") {
      if (state.categoriesAvailable.length === 0) {
        // TODO: Rewrite this to fetch categories and list together
        axios(`${baseUrl}/api/meal-plans/categories`, {
          headers: {
            Authorization: `Token token="${token}"`,
          },
        }).then((response) => {
          dispatch({
            type: "FETCH_NEW_DATA",
            payload: {},
          });

          const appliedCategoryIds = filter(
            response.data.categories,
            (category) => {
              const categorySlug = category.slug;
              const isCategoryApplied =
                findIndex(
                  state.categoriesApplied,
                  (appliedCategory) => appliedCategory === categorySlug
                ) !== -1;
              return isCategoryApplied;
            }
          );

          const categoryQuery = join(
            map(appliedCategoryIds, (appliedCategory) => appliedCategory.id),
            ","
          );
          finalQueryParam = `${
            categoryQuery.length > 0 ? `&categories=${categoryQuery}` : ""
          }${search.length > 0 ? `&search=${search}` : ""}${
            order.length > 0 ? `&order=${order}` : ""
          }${budget !== 300 ? `&budget=${budget}` : ""}${
            calories !== 2000 ? `&calories=${calories}` : ""
          }${days !== 14 ? `&days=${days}` : ""}`;

          axios(
            `${baseUrl}/api/meal-plans?limit=${limit}&offset=${offset}${finalQueryParam}`,
            {
              headers: {
                Authorization: `Token token="${token}"`,
              },
            }
          ).then((response) => {
            dispatch({
              type: "FETCH_NEW_DATA_SUCCESS",
              payload: {
                ...state,
                isFetching: false,
                newBatch: false,
                data: response.data.meal_plans,
                hasMore: response.data.meal_plans.length >= 25,
              },
            });
          });
        });
      } else {
        dispatch({
          type: "FETCH_NEW_DATA",
          payload: {},
        });
        const appliedCategoryIds = filter(
          state.categoriesAvailable,
          (category) => {
            const categorySlug = category.slug;
            const isCategoryApplied =
              findIndex(
                state.categoriesApplied,
                (appliedCategory) => appliedCategory === categorySlug
              ) !== -1;
            return isCategoryApplied;
          }
        );
        const categoryQuery = join(
          map(appliedCategoryIds, (appliedCategory) => appliedCategory.id),
          ","
        );
        finalQueryParam = `${
          categoryQuery.length > 0 ? `&categories=${categoryQuery}` : ""
        }${search.length > 0 ? `&search=${search}` : ""}${
          order.length > 0 ? `&order=${order}` : ""
        }${budget !== 300 ? `&budget=${budget}` : ""}${
          calories !== 2000 ? `&calories=${calories}` : ""
        }${days !== 14 ? `&days=${days}` : ""}`;
        axios(
          `${baseUrl}/api/meal-plans?limit=${limit}&offset=${offset}${finalQueryParam}`,
          {
            headers: {
              Authorization: `Token token="${token}"`,
            },
          }
        ).then((response) => {
          dispatch({
            type: "FETCH_NEW_DATA_SUCCESS",
            payload: {
              ...state,
              isFetching: false,
              newBatch: false,
              data: response.data.meal_plans,
              hasMore: response.data.meal_plans.length >= 25,
            },
          });
        });
      }
    } else {
      dispatch({
        type: "FETCH_NEW_DATA",
        payload: {
          favorite: true,
        },
      });
      axios({
        url: `${baseUrl}/api/v1/favorites/?object_type=meal_plan&page=${Math.floor(
          offset / 25
        )}&per_page=25`,
        headers: {
          Authorization: `Token token="${token}"`,
        },
      }).then((response) => {
        const newMealPlans = map(
          filter(response.data.items, (item) => {
            return item.meal_plan;
          }),
          (item) => item.meal_plan
        );

        dispatch({
          type: "FETCH_NEW_DATA_SUCCESS",
          payload: {
            ...state,
            isFetching: false,
            newBatch: false,
            data: state.newBatch
              ? newMealPlans
              : [...state.data, ...newMealPlans],
            hasMore: response.data.items.length >= 25,
          },
        });
      });
    }
  };

  useEffect(() => {
    fetchMealPlans();
  }, [
    state.offset,
    state.dataType,
    state.categoriesApplied[0],
    state.favorite,
    state.search,
    state.order,
    state.budget,
    state.calories,
    state.days,
    state.force,
  ]);
  return [state, dispatch];
};
