import React from 'react'
import slice from 'lodash/slice';
import ReactImageFallback from "react-image-fallback";
import { map } from 'lodash';
import toUpper from 'lodash/toUpper';

import StarRating from '../../common/rating';

import '../../styles/common.scss';
import RecipeFallbackImage from '../../images/recipefallback.png';
import WorkoutFallbackImage from '../../images/workoutfallback.png';
import getWorkoutImages from '../../../helpers/getWorkoutImages';



const SideIdeas = (props) => {
  const ideas = props.feature === "workout" ? slice(props.sideIdeas, 0, 3) : slice(props.sideIdeas, 0, 3);
  let fallbackImage;
  if (props.feature === "workout") {
    fallbackImage = WorkoutFallbackImage
  } else {
    fallbackImage = RecipeFallbackImage
  }

  const {
    title,
    history,
    sideIdeas,
    objectType,
    wrapperClass
  } = props;

  return (
    <div className="similarCardWrapper">
      <h2>{title}</h2>
      <ul className={props.wrapperClass}>
        {
          map(ideas, (idea, index) => {
            return (
              <li key={index}
                className="similarCard"
              >
                <a href={`/${objectType}/${idea.slug}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push({
                      pathname: `/${objectType}/${idea.slug}`,
                      state: idea.images ? {
                        images: idea.images,
                        title: idea.title,
                        ratingValue: idea.avg_rating
                      }
                        : {}
                    });
                  }}>
                  <figure className="listCardImg">
                    <ReactImageFallback
                      src={props.feature === 'recipe' ? idea.images ? idea.images.full_sm : null : getWorkoutImages(idea, 'wide_640')}
                      fallbackImage={fallbackImage}
                      className="CardImg"
                    />
                  </figure>
                  <p className="cardTitle">{idea.title}</p>
                  {
                    props.feature === 'recipe' ?
                      <div className="recipe-meta">
                        <span className="cook-time">
                          {
                            (idea.variations && idea.variations.length >= 0 && idea.variations[0].cook_time) ? idea.variations[0].cook_time.split(' ')[0] : '-'
                          }
                          {
                            (idea.variations && idea.variations.length >= 0 && idea.variations[0].cook_time) ?
                              (toUpper(idea.variations[0].cook_time.split(' ')[1]) ? toUpper(idea.variations[0].cook_time.split(' ')[1]).substring(0, 1) : 'M')
                              : ''
                          }
                        </span>
                        <span className="separation">|</span>
                        <span className="calories">
                          {idea.variations && idea.variations.length >= 0 && idea.variations[0].calories} Cals
                    </span>
                      </div>
                      :
                      <div className="workout-meta recipe-meta">
                        <span className="workout-time">
                          {
                            (idea && idea.time.length >= 0 && idea.time) ? idea.time.split(' ')[0] : '-'
                          }
                          {
                            (idea && idea.time.length >= 0 && idea.time) ?
                              (toUpper(idea.time.split(' ')[1]) ? toUpper(idea.time.split(' ')[1]).substring(0, 1) : 'M')
                              : ''
                          }
                        </span>
                        <span className="separation">|</span>
                        <span className="workout-difficulty">
                          {toUpper(idea.difficulty)}
                        </span>
                      </div>
                  }
                </a>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default SideIdeas;