import createReducer from "../helpers/createReducers";

import { fromJS } from "immutable";

// TODO: all action payload.data should have a type
const initialState = fromJS({
  isError: false,
  error: null,
  eventId: null
});

export default createReducer(initialState, {
  ADD_SENTRY_ERROR: (state, action) => {
    state = state.setIn(['isError'], true);
    state = state.setIn(['error'], action.payload.error);
    state = state.setIn(['eventId'], action.payload.eventId);
    return state
  },
  REMOVE_SENTRY_ERROR: (state, action) => {
    state = state.setIn(['isError'], false);
    state = state.setIn(['error'], null);
    state = state.setIn(['eventId'], null);
    return state
  }
});