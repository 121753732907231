import React from 'react'
import Spinner from 'react-spinner-material';
import '../styles/common.scss';

const Button = (props) => {
  const btnClass = `btn btn-${props.btnClass}`
  return (
    <button
      disabled={props.disabled}
      className={btnClass}
      type={props.type}
      onClick={props.onClick}
    >

      {props.btnText}
      {
        props.isLoading ? (
          <Spinner
            className="spinner"
            size={15}
            spinnerColor={"#fff"}
            spinnerWidth={2}
            visible={true}
          />
        )
          : ""
      }
    </button>
  )
}

Button.defaultProps = {
  // disabled={disabled} 
  onClick: () => { }
}

export default Button;