// @flow
import { EDIT_USER_PROFILE_REQUEST } from "./actionTypes";

export default ({ email, password, avatar }) => ({
  type: EDIT_USER_PROFILE_REQUEST,
  payload: {
    email,
    password,
    avatar // filters, favorites, unFiltered
  }
});
