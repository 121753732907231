import React from 'react'
import GhostCover from './ghostCover'
import CalenderLeftArrow from '../images/CalenderLeftArrow'

const RightButton = (props) => (
  <GhostCover {...props}>
    <CalenderLeftArrow></CalenderLeftArrow>
  </GhostCover>
)

export default RightButton