import axios from 'axios';
import map from 'lodash/map';
import size from 'lodash/size';
import { get, post, remove, patch } from '../../helpers/requests';
import { grocery } from '../../helpers/api';
import { all, call, select } from 'redux-saga/effects';
import { getToken, getGroceryListId, getGroceryList } from '../selectors'

import isNull from 'lodash/isNull';
import getGroceryCountFromCategories from '../../helpers/getGroceryCountFromCategories';

export default {
  get: function* getGroceryList() {
    const token = yield select(getToken);
    if (!isNull(token)) {
      const groceryResponse = yield call(axios, get(grocery.get(), '', token))
      return groceryResponse
    } else {
      return {
        data: {
          categories: []
        }
      };
    }
  },
  post: function* addAllToGroceryList(items) {
    const token = yield select(getToken);
    const groceryResponse = yield call(axios, post(grocery.post(), { items }, '', token))
    return groceryResponse
  },
  delete: function* clearAllGroceryList() {
    const token = yield select(getToken);
    const groceryResponse = yield call(axios, remove(grocery.delete(), '', '', token))
    return groceryResponse
  },

  deleteItems: function* clearGroceryListItems(itemIds) {
    const token = yield select(getToken);

    const deleteItemsResponse = yield call(axios, remove(grocery.deleteItems(), { items: itemIds }, '', token));

    return deleteItemsResponse;
  },
  updateCategories: function* updateCategoriesForItems(items) {
    const token = yield select(getToken);
    const groceryResponse = yield call(axios, patch(grocery.update(), items, '', token))
    return groceryResponse
  },

  addToGroceryList: function* addToGroceryList({ items, limit = 1 }) {
    const groceryList = yield select(getGroceryList);
    let count = getGroceryCountFromCategories(groceryList)
    if (count >= limit) {
      const response = {
        isLimitExceeded: true,
        count: count,
        limit: limit,
        items: items
      }
      return response
    } else {
      const token = yield select(getToken);
      const groceryResponse = yield call(axios, post(grocery.post(), { items }, '', token))
      return groceryResponse
    }
  }
} 