import axios from 'axios'
import { showToastMessage } from '../actions'
import { put, takeEvery, call, select, delay } from 'redux-saga/effects'
import { post } from '../helpers/requests';
import { auth } from '../helpers/api';

import { getRegisteredUser, getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';


function* task(action) {
  try {

    const registeredUser = yield select(getRegisteredUser);
    const userId = registeredUser.getIn(['id']) || action.payload.userId;

    const response = yield call(axios, post(auth.assignPlanToUser(), {
      user_id: userId,
      plan_id: action.payload.planId,
      duration: action.payload.duration,
    }, ''));

    yield put({
      type: actions.ASSIGN_PLAN_TO_USER_SUCCESS,
      payload: {
        success: true,
        duration: action.payload.duration,
        planId: action.payload.planId
      }
    })

    yield delay(500);

    yield put({
      type: actions.SET_PLAN_ASSIGN_STATUS,
      payload: {
        status: false,
      }
    })
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in adding plan`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ASSIGN_PLAN_TO_USER_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addToGroceryList() {
  yield takeEvery(actions.ASSIGN_PLAN_TO_USER, task)
}