import pushToSentry from '../../helpers/pushToSentry'
import { select, put, take, call, delay } from 'redux-saga/effects'

export default function* removeToken() {
  if (typeof localStorage !== 'undefined' && localStorage != null) {
    localStorage.removeItem('token')
  } else {
    pushToSentry('localStorage not available ')
  }


  yield put({
    type: 'REMOVE_TOKEN_IN_REDUX'
  })

  yield delay(1000)
}
