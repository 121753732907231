import React from 'react'
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const FacebookOutlineIcon = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 29.75H2.25C1.5625 29.75 1 29.1875 1 28.5V2.25C1 1.5625 1.5625 1 2.25 1H28.5C29.1875 1 29.75 1.5625 29.75 2.25V28.5C29.75 29.1875 29.1875 29.75 28.5 29.75H21V18.5H24.2C24.5125 18.5 24.7875 18.2625 24.825 17.95L25.3 14.2C25.325 14.025 25.2625 13.8375 25.15 13.7125C25.025 13.575 24.8625 13.5 24.675 13.5H21V11.8625C21 10.7 21.95 9.7625 23.1125 9.75H25.375C25.725 9.75 26 9.475 26 9.125V5.375C26 5.025 25.725 4.75 25.375 4.75H23.1125C19.1875 4.75 16.0125 7.9375 16 11.8625V13.5H12.875C12.525 13.5 12.25 13.775 12.25 14.125V17.875C12.25 18.225 12.525 18.5 12.875 18.5H16V29.75Z" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default FacebookOutlineIcon; 