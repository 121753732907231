import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"
import { Row, Col } from 'react-flexbox-grid';
import DownlloadArrowIcon from '../images/DownlloadArrow';
import Resource1 from '../images/resource1.png';
import Resource2 from '../images/resource2.png';
import Resource3 from '../images/resource3.png';
import Resource4 from '../images/resource4.png';
import Resource5 from '../images/resource5.png';
import { TransitionOne, TransitionTwo } from "../common/transitions";

const Resource = () => {
  return (
    <section className="resource" >
      <Helmet>
        <title>Resource | Dashing Dish </title>
        <meta name="desrciption" content="Dashingdish Resource page" />
      </Helmet>
      <div className="container">
        <Row className="resource-wrapper no-margin-sm">
          <TransitionOne>
            <Col sm={12} md={3} lg={3} className="account-content-left-wrapper">
              <div className="category-container">
                <ul>
                  <li className="category selected-category">
                    All Resources
                </li>
                  <li className="category">
                    Challenges
                </li>
                  <li className="category">
                    Guides
                </li>
                  <li className="category">
                    Spiritual
                </li>
                  <li className="category">
                    Tips & Tricks
                </li>
                </ul>
              </div>
            </Col>
          </TransitionOne>
          <TransitionTwo>
            <Col sm={12} md={9} lg={9} className="account-content-right-wrapper">
              <h2>All Resources</h2>
              <div className="resource-data">
                <ul className="resources">
                  <li className="resources-card">
                    <a href={''}>
                      <figure className="resources-image">
                        <img src={Resource1} className="img_fluid"></img>
                      </figure>
                      <p>The Ultimate Clean Eating Freezer Meal Plan Guide</p>
                      <small>Download
                    <DownlloadArrowIcon />
                      </small>
                    </a>
                  </li>
                  <li className="resources-card">
                    <a href={''}>
                      <figure className="resources-image">
                        <img src={Resource2} className="img_fluid"></img>
                      </figure>
                      <p>THoliday Mason Jar Recipe Mixes with Printable Tags</p>
                      <small>Download
                    <DownlloadArrowIcon />
                      </small>
                    </a>
                  </li>
                  <li className="resources-card">
                    <a href={''}>
                      <figure className="resources-image">
                        <img src={Resource3} className="img_fluid"></img>
                      </figure>
                      <p>TMy Personal Favorite Scripture Based Prayers</p>
                      <small>Download
                    <DownlloadArrowIcon />
                      </small>
                    </a>
                  </li>
                  <li className="resources-card">
                    <a href={''}>
                      <figure className="resources-image">
                        <img src={Resource4} className="img_fluid"></img>
                      </figure>
                      <p>Healthy Eating Back-to-school guide</p>
                      <small>Download
                    <DownlloadArrowIcon />
                      </small>
                    </a>
                  </li>
                  <li className="resources-card">
                    <a href={''}>
                      <figure className="resources-image">
                        <img src={Resource5} className="img_fluid"></img>
                      </figure>
                      <p>Spring Shape Up Challenge</p>
                      <small>Download
                    <DownlloadArrowIcon />
                      </small>
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </TransitionTwo>
        </Row>
      </div>
    </section >
  )
}


export default Resource;