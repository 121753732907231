import React, { useState } from 'react'
import Modalwrapper from '../modal';
import cx from 'classnames'
import PlusIcon from '../../images/PlusIcon'
import isNull from 'lodash/isNull'
import DottedIcon from '../../images/DottedIcon'
import Button from '../../common/button';
import isEmpty from 'lodash/isEmpty'


const ref = React.createRef();

const MobileView = ({
  options,
  favoriteOptions,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  onCancelClick,
  onClearClick,
  actions,
  history,
  createBoard,
  objectType,
  isAuthenticated,
  ...props
}) => {
  const [state, setState] = useState({
    showModal: false,
    showFavorites: false,
    editText: null,
    createBoardText: ''
  })

  // ALL-LOGIC FUNCTIONS
  // when we click a dropdown item
  const onDropdownItemClick = (label, slug, dropdownOptions) => {
    const { title } = dropdownOptions

    // action for the parent
    actions(label, slug, title)

    //condition for close dropdown on select
    // if (singleSelectable) {
    setState({ ...state, showModal: false })
    // }
  }

  // what happens on edit fav board
  const onEditBoradInputChange = (e, value) => {

    // on enter
    if (e.keyCode === 13) {
      updateFavouriteBoard({ boardId: value, title: e.target.value, objectType: objectType })
      setState({ ...state, editBoardModal: null, editText: e.target.value })
    }
  }

  // what happen on create fav board
  const createBoardOnEnter = (e) => {
    // on enter
    if (!isEmpty(e.target.value) && e.keyCode === 13) {
      createBoard(e.target.value, objectType)
      setState({ ...state, showFavoritesDropdown: false, editText: null, createBoardText: '' })
    }
  }
  // what happens on fav board click
  const onFavoriteBoardClick = (slug) => {
    if (slug !== null) {
      history.push(`/${objectType}s/favorites/${slug}`)
    } else {
      history.push(`/${objectType}s/favorites`)
    }
    setState({ ...state, showFavorites: false, showModal: false })
  }

  // renders the options heading like collections , categories ...
  const renderFilterRow = () => {
    const filterItemsArray = options.map((option, index) => {
      return (
        <div
          key={option.title}
          className={`navList ${option.isSelected ? 'active' : ''}`}
          onClick={(e) => {
            setState({ ...state, showDropdown: index })
          }}>
          <h5>
            {option.title}
          </h5>
          {/* Only render the dropdown based on condition */}
          <div className="mobFilterOption">

            {renderOptionsDropdown(option)}

          </div>
        </div>
      )
    })

    return filterItemsArray
  }

  //renders  the dropdonw in the filter row
  const renderOptionsDropdown = (dropdownOptions) => {

    // only map is isOptionsList is true
    let filterItemsArray
    if (dropdownOptions.isOptionsList) {
      filterItemsArray = dropdownOptions.options.map((option, index) => {
        return (
          <li
            key={index}
            className={cx('filterList', { 'selected': option.isSelected })}
            onClick={(e) => {
              onDropdownItemClick(option.label, option.slug, dropdownOptions)
            }}
          >
            <figure className="icon-wrapper">
              {
                option.image ? (
                  <img src={option.image} className="taxonomy-icon" />

                ) : (
                    (option.svg ? <option.svg /> : null)
                  )
              }
            </figure>
            {option.label}
          </li>
        )
      })
    }

    return (
      <ul
        className={`filterOption ${dropdownOptions.additionalDropdownWrapperClass}`}
        ref={ref}
      >
        {/* non-mappable dropdown condition */}
        {dropdownOptions.isOptionsList ? filterItemsArray : dropdownOptions.options}
      </ul>
    )
  }

  // renders the fav heading
  // const renderFavorites = () => {
  //   return (
  //     <>
  //       {/* <div
  //         className={`navList ${favoriteOptions.isSelected ? 'active' : ''}`}
  //         onClick={() => { }}
  //       >
  //         <span>
  //           {favoriteOptions.title}
  //         </span>
  //       </div> */}

  //       {/* the condition to render the fav dropdown */}

  //       <div className='favoriteOption' ref={ref}>
  //         {/* the create fav input */}
  //         <div className="optionInputField">
  //           <input
  //             className="categoryInput"
  //             placeholder='Create new'
  //             onKeyUp={(e) => createBoardOnEnter(e)}
  //             onChange={(e) => setState({ ...state, createBoardText: e.target.value })}
  //             value={state.createBoardText}
  //           />
  //           <PlusIcon></PlusIcon>
  //         </div>
  //         {renderFavoritesDropdown()}
  //       </div>

  //     </>
  //   )
  // }

  // renders the fav heading
  const renderFavorites = () => {
    return (
      <>
        <li
          className={`navList ${favoriteOptions.isSelected || state.showFavoritesDropdown ? 'active' : ''}`}
        >
          <a
            href={
              (objectType === "video") ?
                `/${objectType}s/favorites`
                :
                objectType === 'blog' ? `/${objectType}/fav-boards` : `/${objectType}s/fav-boards`
            }
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (objectType === "video") {
                history.push(`/${objectType}s/favorites`)
              } else {
                objectType === 'blog' ? history.push(`/${objectType}/fav-boards`) : history.push(`/${objectType}s/fav-boards`);
              }
            }}
          >
            <span>
              {favoriteOptions.title}
            </span>
          </a>
        </li>

      </>
    )
  }

  // renders the fav dropdown
  const renderFavoritesDropdown = () => {
    let favoriteOptionsMap = favoriteOptions.options.map((option, index) => {
      return (
        <div className="mobFavRow" key={option.id}>
          {renderFavoriteListContent(index, option)}
        </div>
      )
    })

    return favoriteOptionsMap
  }

  // renders the fav list content in the fav dropdown and all its logic like edit , delete ...
  const renderFavoriteListContent = (index, option) => {
    return (
      <>
        {/* condition to render the board name */}
        {(state.editBoardModal !== option.value) || isNull(option.value) ?
          <li
            key={option.value}
            onClick={() => {
              onFavoriteBoardClick(option.slug)
            }}
            className={option.isSelected ? 'favoriteItem selected' : 'favoriteItem'}
          >
            {/* <img src={(board.thumbnail && board.thumbnail[boardCount])} /> */}

            {option.name}

          </li> : null}
        {/* condition to render the edit board input */}
        {(state.editBoardModal === option.value) && !isNull(option.value) ?
          <input
            placeholder={option.name}
            onKeyUp={(e) => {
              onEditBoradInputChange(e, option.value)
            }}
            autoFocus
            onChange={(e) => {
              setState({
                ...state,
                editText: e.target.value
              })
            }}
            value={state.editText}
          /> : null}
        {!isNull(option.value) ?
          <DottedIcon
            options={[
              {
                label: 'Edit',
                action: () => {
                  setState({ ...state, editBoardModal: option.value, editText: option.name })
                }
              },
              {
                label: 'Delete',
                action: () => deleteFavouriteBoard({ boardId: option.value, objectType: objectType })
              }
            ]}
          />
          : null}
      </>

    )
  }



  return (
    <div className="filterModal">
      <div className="filterModalList">
        <ul>
          {/* <li
            onClick={() => {
              if (objectType === "video") {
                history.push(`/${objectType}s/favorites`)
              } else {
                history.push(`/${objectType}s/fav-boards`)
              }
            }}
          >Favorites</li> */}
          {isAuthenticated ? renderFavorites() : null}
          <li
            onClick={() => {
              setState({ ...state, showModal: true, showFavorites: false })
            }}>Filters</li>
        </ul>
      </div>
      <Modalwrapper
        isOpen={state.showModal}
        onRequestClose={() => { }}
        showClose={false}
      >
        <div className="modalWrapper nav-mobFiltersList">
          <div className="modalHeader">
            <Button
              btnClass="secondary"
              btnText="Save & Close"
              onClick={() => {
                setState({ ...state, showModal: false })
                // onCancelClick()
              }}
            />
            <Button
              btnClass="secondary"
              btnText="Clear"
              onClick={() => {
                history.push(props.staticOption.route)
                // onCancelClick()
                // onClearClick()
              }}
            />
          </div>
          <div className="modalBody">
            {state.showFavorites ? renderFavorites() : renderFilterRow()}
          </div>
        </div>
      </Modalwrapper>
    </div>
  )
}

export default MobileView