import React from 'react'
import slice from 'lodash/slice';
import ReactImageFallback from "react-image-fallback";
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import toUpper from 'lodash/toUpper';

import '../../../styles/common.scss';
import RecipeFallbackImage from '../../../images/recipesideplaceholder.jpg';
import BolgFallbackImage from '../../../images/blogfallback.png';
import getMarkdownImage from '../../../../helpers/getMarkdownImage';
import convertQuality from '../../../../helpers/convertQuality';
import moment from 'moment';

const SideIdeas = (props) => {
  const ideas = props.feature === "blog" ? slice(props.sideIdeas, 0, 3) : slice(props.sideIdeas, 0, 3);
  let fallbackImage;
  if (props.feature === "blog") {
    fallbackImage = BolgFallbackImage
  }

  const {
    title,
    history,
    sideIdeas,
    objectType,
    wrapperClass
  } = props;

  const renderBlogMeta = (item) => (
    <div className="recipe-meta">
      {`${moment(item.updated_at).format('MMMM Do YYYY').replace(/(\d)(st|nd|rd|th)/g, '$1$2')}`}
    </div>
  )
  return (
    <div className="similarCardWrapper">
      <h2>{title}</h2>
      <ul className={props.wrapperClass}>
        {
          map(ideas, (idea, index) => {
            return (
              <li key={index}
                className="similarCard"
              >
                <a href={`/${objectType}/${idea.slug}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push({
                      pathname: `/${objectType}/${idea.slug}`,
                      state: idea.images ? {
                        images: idea.images,
                        title: idea.title,
                      }
                        : {}
                    });
                  }}>
                  <figure className="listCardImg">
                    <ReactImageFallback
                      src={idea.image ? convertQuality(idea.image, 'medium') : (!isEmpty(idea.content) ? getMarkdownImage(idea.content, 'medium')[1] : '')}
                      fallbackImage={fallbackImage}
                      className="CardImg"
                    />
                  </figure>
                  <p className="cardTitle">{idea.title}</p>
                  {renderBlogMeta(idea)}
                </a>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default SideIdeas;