import React, { useEffect } from "react";
import SmartBanner from "./smartbanner.js";
import "./smartbanner.scss";

const AppBanner = () => {
  useEffect(() => {
    window.addEventListener("load", function() {
      const smartbanner = new SmartBanner();
      smartbanner.apiEnabled && smartbanner.publish();
    });
  });
  return <></>;
};

export default AppBanner;
