import React, { useState } from "react";
import Spinner from "react-spinner-material";
import { isEmpty, map, isEqual } from "lodash";

import FavoriteModal from "./favoriteModal";
import GroceriesListModal from "./groceriesListModal";
import AddNoteModal from "./addnoteModal";
import AddToMealPlan from "./addToMealPlan";

import Modalwrapper from "./modal";

import MealplanIcon from "../images/MealplanIcon";
import GroceriesIcon from "../images/GroceriesIcon";
import AddnoteIcon from "../images/AddnoteIcon";
import NoteFillIcon from "../images/NoteFillIcon";
import AddednoteIcon from "../images/AddednoteIcon";
import FavoriteIcon from "../images/FavoriteIcon";
import FavoriteIconFill from "../images/FavoriteIconFill";
import CommentIcon from "../images/CommentIcon";
import ShareIcon from "../images/Share";
import BookmarkFillIcon from "../images/BookmarkFillIcon";
import BookmarkUnfillIcon from "../images/BookmarkUnfillIcon";
import { BlogSocialIcon } from "../common/socialIcon";

import { Link } from "react-scroll";
import Button from "./button";
import { Tooltip } from "react-tippy";
import StarIcon from "../images/AddnoteIcon copy";

const RecipeActions = React.forwardRef((props, ref) => {
  const [state, setState] = useState({
    noteInputText: "",
    activeObjectId: null,
    showNoteModal: false,
    showGroceryModal: false,
    showFavoriteModal: false,
    showMealPlanModal: false,
    showShareModal: false,
  });

  const {
    // selectors
    history,
    note,
    boards,
    objectType,
    actionList,
    objectSlug,
    isLoggedIn,
    isAuthenticated,
    activeObject,
    objectEssentialFetchStatus,
    setMacros,
    calendarList,
    // actions
    sendNote,
    createBoard,
    setObjectBoards,
    createFavourite,
    deleteFavourite,
    clearBoardSelection,
    addAllToGroceryList,
    updateFavouriteBoard,
    deleteFavouriteBoard,
    thumbnail,
    displayImage,
    addMealToMultipleDays,
    userType,
  } = props;

  const favoriteModalToggleHandler = ({ itemId, modalState }) => {
    setState((prevState) => ({
      ...prevState,
      showFavoriteModal: modalState,
      activeObjectId: itemId,
    }));
  };

  const noteModalToggleHandler = (modalState) => {
    setState((prevState) => ({ ...prevState, showNoteModal: modalState }));
  };

  const noteInputTextHandler = (noteText) => {
    setState((prevState) => ({ ...prevState, noteInputText: noteText }));
  };

  const groceryModalToggleHandler = (modalState) => {
    setState((prevState) => ({ ...prevState, showGroceryModal: modalState }));
  };

  const mealPlanModalToggleHandler = (modalState) => {
    setState((prevState) => ({ ...prevState, showMealPlanModal: modalState }));
  };

  const isNotesUpdated = () => {
    return !isEqual(state.noteInputText, note.content);
  };

  const defaultShareImage =
    "https://d11z36dj5c0s43.cloudfront.net/public-assets/images/icons/apple-touch-icon-precomposed-152x152.png";

  const getNoteContent = () => {
    if (isAuthenticated) {
      return (
        <div className="notePopover">
          <div className="modalWrapper">
            <h3>
              Add{" "}
              {state.noteInputText && state.noteInputText.length > 0
                ? `${objectType}`
                : null}{" "}
              Note{" "}
            </h3>
          </div>
          <textarea
            className=""
            placeholder="Type your note here"
            value={state.noteInputText}
            onChange={() => {
              noteInputTextHandler(event.target.value);
            }}
            ref={(input) => {
              textInput = input;
            }}
          ></textarea>
          <div className="modalFooter">
            <Button
              btnClass={`${state.noteInputText && state.noteInputText.length > 0
                ? isNotesUpdated()
                  ? "primary"
                  : "black"
                : "secondary"
                } btn-full`}
              btnText={
                isNotesUpdated()
                  ? "Save Note"
                  : `${state.noteInputText && state.noteInputText.length > 0
                    ? `Edit`
                    : `Add Note`
                  }`
              }
              onClick={() => {
                props.sendNote({
                  noteId: note.id,
                  note: state.noteInputText,
                  objectType: objectType,
                  objectId: note.object_id,
                  objectSlug: objectSlug,
                });
                noteModalToggleHandler((modalState = false));
              }}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="notePopover">
          <div className="modalWrapper">
            <h3>{"Register to access these features"}</h3>
          </div>
          <div className="modalFooter">
            <Button
              btnClass="primary btn-full"
              btnText={"See Plans & Pricing"}
              onClick={(e) => {
                e.preventDefault();
                history.push("/pricing?type=view");
              }}
            />
          </div>
        </div>
      );
    }
  };

  const actions = {
    mealPlan:
      !isAuthenticated ||
        (userType !== "premiumPlan" && userType !== "grandfather") ? (
        <Tooltip
          className="actionList"
          multiple={true}
          open={state.showMealPlanModal}
          arrow={true}
          trigger="click"
          theme="light"
          interactive={true}
          position="bottom-start"
          onRequestClose={() =>
            mealPlanModalToggleHandler((modalState = false))
          }
          html={
            <div className="Addgroceries feature-tooltip">
              <div className="groceriesHead">
                <StarIcon></StarIcon>

                <h3>
                  {isAuthenticated
                    ? "This feature is available for meal plan pro users."
                    : "This feature is available for members only."}
                </h3>
              </div>
              <div className="advantages">
                {isAuthenticated
                  ? "Upgrade your plan today to start saving time and money by meal planning like a pro!"
                  : "See why thousands of people trust us for weekly recipes, meal plans, workouts, and more."}
              </div>
              <div className="groceriesFooter">
                <Button
                  btnClass="primary btn-full"
                  btnText={
                    isAuthenticated
                      ? "Upgrade for $2 month"
                      : "See Plans & Pricing"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    if (isAuthenticated) {
                      history.push("/account/membership");
                    } else {
                      history.push("/pricing?type=view");
                    }
                  }}
                />
              </div>
            </div>
          }
        >
          <li
            className=""
            key="groceryList"
            data-tip
            data-for="grocery"
            onClick={() =>
              mealPlanModalToggleHandler(
                (modalState = !state.showMealPlanModal)
              )
            }
          >
            <MealplanIcon />
            <span>Plan It</span>
          </li>
        </Tooltip>
      ) : (
        <li
          className="actionList"
          key="mealPlan"
          data-tip
          data-for="mealPlan"
          onClick={() => {
            // TODO: Have a handler for this, dont write inside JSX
            if (isLoggedIn) {
              mealPlanModalToggleHandler((modalState = true));
            }
          }}
        >
          <MealplanIcon />
          <span>Plan it</span>
        </li>
      ),

    groceryList: (
      <Tooltip
        className="actionList"
        multiple={true}
        open={state.showGroceryModal}
        arrow={true}
        trigger="click"
        theme="light"
        interactive={true}
        position="bottom-start"
        onRequestClose={() => groceryModalToggleHandler(false)}
        html={
          <GroceriesListModal
            toggleGroceryModal={groceryModalToggleHandler}
            addAllToGroceryList={props.addAllToGroceryList}
            isAuthenticated={isAuthenticated}
            userType={userType}
            history={history}
          />
        }
      >
        {
          <li
            key=""
            data-tip
            data-for="grocery"
            onClick={() => {
              groceryModalToggleHandler(!state.showGroceryModal);
            }}
          >
            <GroceriesIcon />
            <span>Grocery List</span>
          </li>
        }
      </Tooltip>
    ),
    comment: (
      <li className="actionList">
        <Link
          to="Comment"
          spy={true}
          smooth={true}
          hashSpy={false}
          offset={200}
          duration={1000}
          onClick={() => {
            if (ref && ref.current) {
              setTimeout(() => {
                ref.current.focus();
              }, 1000);
            }
          }}
        >
          <CommentIcon />
          <span>comment</span>
        </Link>
      </li>
    ),
    share: (
      <Tooltip
        className="actionList"
        multiple={true}
        open={state.showShareModal}
        arrow={true}
        trigger="click"
        theme="light"
        interactive={true}
        position="bottom"
        onRequestClose={() => setState({ showShareModal: false })}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
        html={
          <div className="blog-social-icon">
            <BlogSocialIcon
              feature={objectType}
              slug={activeObject.slug}
              img={
                activeObject && activeObject.image
                  ? activeObject.image
                  : defaultShareImage
              }
              title={
                activeObject && activeObject.title ? activeObject.title : ""
              }
              description=""
            />
          </div>
        }
      >
        {
          <li className="">
            <Link
              to="Social"
              spy={true}
              smooth={true}
              hashSpy={false}
              offset={0}
              duration={1000}
              onClick={() => {
                //TODO: Have a handler for this, dont write inside JSX
                setState({ showShareModal: true });
                // if (isAuthenticated) setState({ showShareModal: true })
              }}
            >
              <ShareIcon />
              <span>share</span>
            </Link>
          </li>
        }
      </Tooltip>
    ),
    note: (
      <Tooltip
        className="actionList"
        multiple={true}
        open={state.showNoteModal}
        arrow={true}
        trigger="click"
        theme="light"
        interactive={true}
        position="right-start"
        onRequestClose={() => noteModalToggleHandler((modalState = false))}
        html={
          <>
            {isAuthenticated ? (
              <div className="notePopover">
                <div className="modalWrapper">
                  <h3>
                    Add{" "}
                    {state.noteInputText && state.noteInputText.length > 0
                      ? `${objectType}`
                      : null}{" "}
                    Note{" "}
                  </h3>
                </div>
                <textarea
                  className=""
                  placeholder="Type your note here"
                  value={state.noteInputText}
                  onChange={() => {
                    noteInputTextHandler(event.target.value);
                  }}
                  ref={(input) => {
                    textInput = input;
                  }}
                ></textarea>
                <div className="modalFooter">
                  <Button
                    btnClass={`${state.noteInputText && state.noteInputText.length > 0
                      ? isNotesUpdated()
                        ? "primary"
                        : "black"
                      : "secondary"
                      } btn-full`}
                    btnText={
                      isNotesUpdated()
                        ? "Save note"
                        : `${state.noteInputText &&
                          state.noteInputText.length > 0
                          ? `Edit`
                          : `Add note`
                        }`
                    }
                    onClick={() => {
                      props.sendNote({
                        noteId: note.id,
                        note: state.noteInputText,
                        objectType: objectType,
                        objectId: note.object_id,
                        objectSlug: objectSlug,
                      });
                      noteModalToggleHandler((modalState = false));
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="Addgroceries feature-tooltip">
                <StarIcon></StarIcon>
                <div className="featureHead">
                  <h3>{"This feature is available for members only."}</h3>
                </div>
                <div className="advantages">
                  See why thousands of people trust us for weekly recipes, meal
                  plans, workouts, and more.
                </div>
                <div className="groceriesFooter">
                  <Button
                    btnClass="primary btn-full"
                    btnText={"See Plans & Pricing"}
                    onClick={(e) => {
                      e.preventDefault();
                      history.push("pricing");
                    }}
                  />
                </div>
              </div>
            )}
          </>
        }
      >
        {
          <>
            {isEmpty(props.note.content) ? (
              <li
                key="note"
                data-tip
                data-for="addNote"
                onClick={() => {
                  // TODO: Have a handler for this, dont write inside JSX
                  noteModalToggleHandler((modalState = !state.showNoteModal));
                }}
              >
                {
                  <>
                    <span>
                      <AddnoteIcon />
                    </span>
                    Add Note
                  </>
                }
              </li>
            ) : (
              <li
                key="note"
                data-tip
                data-for="addNote"
                onClick={() => {
                  // TODO: Have a handler for this, dont write inside JSX
                  if (isLoggedIn) {
                    noteModalToggleHandler((modalState = true));
                    noteInputTextHandler(note.content);
                  }
                }}
              >
                {
                  <>
                    <NoteFillIcon />
                    <span>View Note</span>
                  </>
                }
              </li>
            )}
          </>
        }
      </Tooltip>
    ),
    favourite:
      activeObject && activeObject.favorite ? (
        <li
          className="actionList"
          key="favourite"
          data-tip
          data-for="favorite"
          onClick={() => {
            // TODO: Have a handler for this, dont write inside JSX
            if (isLoggedIn) {
              favoriteModalToggleHandler({
                itemId: activeObject.id,
                modalState: false,
              });
              deleteFavourite(activeObject.id, objectType, props.thumbnail);
            }
          }}
        >
          <a>
            {objectType === "blog" ? (
              <>
                <BookmarkFillIcon></BookmarkFillIcon>
                <span>Unbookmark</span>
              </>
            ) : (
              <>
                <FavoriteIconFill />
                <span>Unfavorite</span>
              </>
            )}
          </a>
        </li>
      ) : !isAuthenticated ? (
        <Tooltip
          className="actionList"
          multiple={true}
          open={state.showFavoriteModal}
          arrow={true}
          trigger="click"
          theme="light"
          interactive={true}
          position="bottom-start"
          onRequestClose={() =>
            favoriteModalToggleHandler({ modalState: false })
          }
          html={
            <div className="Addgroceries feature-tooltip">
              <StarIcon></StarIcon>
              <div className="featureHead">
                <h3>{"This feature is available for members only."}</h3>
              </div>
              <div className="advantages">
                See why thousands of people trust us for weekly recipes, meal
                plans, workouts, and more.
              </div>
              <div className="groceriesFooter">
                <Button
                  btnClass="primary btn-full"
                  btnText={"See Plans & Pricing"}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push("/pricing?type=view");
                  }}
                />
              </div>
            </div>
          }
        >
          <li
            className=""
            key="favourite"
            data-tip
            data-for="favorite"
            onClick={() => {
              favoriteModalToggleHandler({
                itemId: activeObject.id,
                modalState: true,
              });
            }}
          >
            <a>
              {objectType === "blog" ? (
                <>
                  <BookmarkUnfillIcon></BookmarkUnfillIcon>
                  <span>Bookmark</span>
                </>
              ) : (
                <>
                  <FavoriteIcon />
                  <span>Favorite</span>
                </>
              )}
            </a>
          </li>
        </Tooltip>
      ) : (
        <li
          className="actionList"
          key="favourite"
          data-tip
          data-for="favorite"
          onClick={() => {
            favoriteModalToggleHandler({
              itemId: activeObject.id,
              modalState: true,
            });
          }}
        >
          <a>
            {objectType === "blog" ? (
              <>
                <BookmarkUnfillIcon></BookmarkUnfillIcon>
                <span>Bookmark</span>
              </>
            ) : (
              <>
                <FavoriteIcon />
                <span>Favorite</span>
              </>
            )}
          </a>
        </li>
      ),
  };

  const renderAction = (action) => {
    return actions[action];
  };

  return (
    <>
      <ul className="actionmodalList">
        {map(actionList, (action, index) => {
          return renderAction(action);
        })}
      </ul>
      {isAuthenticated &&
        (userType === "premiumPlan" || userType === "grandfather") ? (
        <Modalwrapper
          maxWidth="510px"
          minHeight="600px"
          // overFlowY="hidden"
          isOpen={state.showMealPlanModal}
          onRequestClose={() =>
            mealPlanModalToggleHandler((modalState = false))
          }
        >
          <AddToMealPlan
            objectType={objectType}
            activeObject={activeObject}
            calendarList={calendarList}
            thumbnail={thumbnail}
            displayImage={displayImage}
            setMacros={setMacros}
            mealPlanModalToggleHandler={mealPlanModalToggleHandler}
            addMealToMultipleDays={addMealToMultipleDays}
          />
        </Modalwrapper>
      ) : null}
      {isAuthenticated ? (
        <Modalwrapper
          minHeight="600px"
          maxWidth="500px"
          isOpen={state.showFavoriteModal}
          onRequestClose={() => {
            favoriteModalToggleHandler((modalState = false));
          }}
        >
          <FavoriteModal
            setObjectBoards={setObjectBoards}
            activeObject={activeObject}
            createBoard={createBoard}
            objectType={objectType}
            boards={boards}
            createFavourite={createFavourite}
            deleteFavourite={deleteFavourite}
            clearBoardSelection={clearBoardSelection}
            deleteFavouriteBoard={deleteFavouriteBoard}
            updateFavouriteBoard={updateFavouriteBoard}
            toggleFavoriteModal={favoriteModalToggleHandler}
            thumbnail={thumbnail}
            displayImage={displayImage}
          />
        </Modalwrapper>
      ) : null}
    </>
  );
});

RecipeActions.defaultProps = {
  note: {
    content: null,
    id: null,
  },
};

export default RecipeActions;
