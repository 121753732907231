import React, { useState, useEffect } from "react";
import SelectorArrow from "../images/SelectorIcon";
import DownArrow from "../images/DownArrow";

/**
 *
 * @param {onSelectValue} function : what should happen when a value is selected
 * @param {defaultValue} string/number : defauld selector value
 * @param {selectorList} Array : all the selector options to be rendered
 */

const Selector = (props) => {
  const [localValue, setLocalValue] = useState(props.value);
  const [previousValue, setPreviousValue] = useState(props.value);

  const setLocalValueHandler = (value) => {
    if (props.value === "rand") {
      setLocalValue(value);
    }
  };

  useEffect(() => {
    setLocalValue(props.value);
    setPreviousValue(props.value);
  }, [props.value]);
  return (
    <>
      <label onClick={() => {}}>{props.label}</label>
      <select
        style={{ width: props.width }}
        className="customSelect"
        defaultValue={props.defaultValue}
        value={localValue}
        onMouseEnter={() => setLocalValueHandler("")}
        onMouseLeave={() => setLocalValueHandler(previousValue)}
        onChange={(e) => {
          props.onSelectValue(e.target.value);
        }}
      >
        {props.selectorList.map((item, index) => {
          return (
            <option disabled={item.disabled} key={index} value={item.value}>
              {item.title}
            </option>
          );
        })}
      </select>
      {props.selectorList.length > 1 ? (
        props.onlyDownArrow ? (
          <DownArrow />
        ) : (
          <SelectorArrow />
        )
      ) : null}
    </>
  );
};

Selector.defaultProps = {
  onSelectValue: () => {},
};
export default Selector;
