import React, { useState, useEffect, useReducer } from "react";
import cx from "classnames";
import includes from "lodash/includes";
import { withRouter } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import getUserPlanType from "../../../helpers/getUserPlanType";
import LoggedOutNavDropdown from "./loggedOutNavDropdown";
import KatiesCornerDropdownContent from './katiesCornerDropdown'

import "../../styles/common.scss";
import DownArrow from "../../images/NavDownArrow";
import GhostCover from "../../common/ghostCover";
import AvatarIcon from "../../images/avartar";
import { TransitionEaseInOne } from "../../common/transitions";

let hoverDebouncer = null;
const NavList = ({
  isAuthenticating,
  isAuthenticated,
  userDetails,
  groceryListItemCount,
  groceryListFetchStatus,
  navState,
  navDispatch,
  activeRecipe,
  activeWorkout,
  activeBlog,
  recipeFilters,
  workoutBodyParts,
  videoCategories,
  blogFeaturedList,
  latestRecipe,
  mealPlanCategories,
  requestCloseNavDropdown,
  ...props
}) => {
  const [isDdDropdownActive, setDdDropdownActive] = useState(false);
  const [isKatiesCornerDropdownActive, setKatiesCornerDropdownActive] = useState(false);

  const handleAuth = () => {
    props.history.push("/login");
  };

  const getActiveUrl = () => {
    if (includes(window.location.href, "/recipes")) {
      return "Recipes";
    }
    if (includes(window.location.href, "/workouts")) {
      return "Workouts";
    }
    // if (includes(window.location.href, "/blog") && !includes(window.location.href, "/nourish")) {
    //   return "Blog";
    // }
    if (includes(window.location.href, "/videos")) {
      return "Videos";
    }
    if (includes(window.location.href, "/grocery-list")) {
      return "Grocery List";
    }
    if (includes(window.location.href, "/calendar")) {
      return "Calendar";
    }
    // if (includes(window.location.href, "/about") && !includes(window.location.href, "nourish")) {
    //   return "About";
    // }
    if (includes(window.location.href, "/contact")) {
      return "Contact";
    }
    if (props.location.pathname === "/login") {
      return "Login";
    }
    if (props.location.pathname === "/app") {
      return "ALive pp";
    }



    // if (props.location.pathname === "/nourish") {
    //   return "Home";
    // }
    // if (props.location.pathname === "/nourish/about") {
    //   return "Nourish";
    // }
    // if (props.location.pathname === "/nourish/katies-corner") {
    //   return "Katies Corner";
    // }
    // if (props.location.pathname === "/nourish/live-workouts") {
    //   return "Live Workouts";
    // }
    // if (props.location.pathname === "/nourish/live-meetings") {
    //   return "Live Meetings";
    // }
    // if (props.location.pathname === "/nourish/live-cooking") {
    //   return "Live Cooking";
    // }
    // if (props.location.pathname === "/nourish/upcoming-meetings") {
    //   return "Upcoming Meetings";
    // }
    if (props.location.pathname === "/") {
      return "Home";
    }
  };

  const isKatiesCornerActive = () => false;
  // includes(window.location.href, "/nourish");

  let unAuthenticatedListItems = (isAuthenticating) => {
    let result = [
      {
        title: "Plans & Pricing",
        downArrow: false,
        href: "/pricing?type=view",
      },
      // {
      //   title: "Nourish",
      //   downArrow: false,
      //   href: '/nourish',
      //   newIcon: true,
      // },
      {
        title: "Explore",
        downArrow: true,
        href: null,
      },
      {
        title: "App",
        downArrow: false,
        href: "/app",
      },
    ];

    if (props.windowWidth > 767) {
      result.push({
        title: "Login",
        downArrow: false,
        href: "/login",
      });
    }
    result.push({
      title: "Signup",
      downArrow: false,
      href: "/pricing",
    });


    return result;
  };

  let authenticatedNonBasePlanListItems = [
    {
      title: "Recipes",
      downArrow: false,
      href: "/recipes",
    },
    {
      title: "Meal Plans",
      downArrow: false,
      href: "/calendar?meal-plans=true",
    },
    {
      title: "My Calendar",
      downArrow: false,
      href: "/calendar",
    },
    {
      title: "Grocery List",
      downArrow: false,
      href: "/grocery-list",
    },
    // {
    //   title: "Nourish",
    //   downArrow: false,
    //   newIcon: true,
    //   href: "/nourish/about",
    // },
    {
      title: "Workouts",
      downArrow: false,
      href: "/workouts",
    },
    {
      title: "Videos",
      downArrow: false,
      href: "/videos",
    },
    {
      title: "Blog",
      downArrow: false,
      href: "/blog/preview",
    },
  ];

  let authenticatedBasePlanListItems = [
    {
      title: "Recipes",
      downArrow: false,
      href: "/recipes",
    },
    {
      title: "Meal Plans",
      downArrow: false,
      href: "/calendar?meal-plans=true",
    },
    {
      title: "My Calendar",
      downArrow: false,
      href: "/calendar",
    },
    {
      title: "Grocery List",
      downArrow: false,
      href: "/grocery-list",
    },
    {
      title: "Workouts",
      downArrow: false,
      href: "/workouts",
    },
    {
      title: "Videos",
      downArrow: false,
      href: "/videos",
    },
    {
      title: "Blog",
      downArrow: false,
      href: "/blog/preview",
    },
    // {
    //   title: "Nourish",
    //   href: '/nourish/about',
    //   downArrow: true,
    //   newIcon: true,
    // },
  ];

  const authenticatedKatiesCornerNonBasePlanListItems = [
    {
      title: "Nourish",
      downArrow: false,
      href: "/nourish/about",
    },
    {
      title: "Katies Corner",
      downArrow: false,
      href: "/nourish/katies-corner",
    },
    {
      title: "Live Meetings",
      downArrow: false,
      href: "/nourish/live-meetings",
    },
    {
      title: "Live Workouts",
      downArrow: false,
      href: "/nourish/live-workouts",
    },
    {
      title: "Live Cooking",
      downArrow: false,
      href: "/nourish/live-cooking",
    },
    // {
    //   title: "Upcoming Meetings",
    //   downArrow: false,
    //   href: "/nourish/upcoming-meetings",
    // },
    {
      title: "DashingDish",
      downArrow: false,
      href: "/recipes"
    },
  ];

  // if (
  //   userDetails &&
  //   userDetails.details &&
  //   !isEmpty(userDetails.details) &&
  //   (getUserPlanType(userDetails) === "premiumPlan" ||
  //     getUserPlanType(userDetails) === "grandfather")
  // ) {
  // }

  let listItems = null;
  if (isAuthenticated) {
    // if (isKatiesCornerActive()) {
    //   listItems = authenticatedKatiesCornerNonBasePlanListItems;
    // } else 
    if (getUserPlanType(userDetails) === "basePlan") {
      listItems = authenticatedBasePlanListItems;
    } else {
      listItems = authenticatedNonBasePlanListItems;
    }
  } else {
    listItems = authenticatedNonBasePlanListItems;
  }

  // what happens when we click a nav item
  const onItemClick = (e, navListItem) => {
    if (props.windowWidth < 767 && (navListItem.title === "Explore" || navListItem.title === 'DashingDish')) {
      setDdDropdownActive(!isDdDropdownActive);
    } else if (props.windowWidth < 767 && navListItem.title === "Nourish" && isAuthenticated) {
      setKatiesCornerDropdownActive(!isKatiesCornerDropdownActive);
    } else {
      if (e.metaKey) {
        window.open(navListItem.href, "_blank");
        window.focus();
      } else {
        if (!includes(window.location.href, "reactivate-account")) {
          e.preventDefault();
          if (props.windowWidth < 767) {
            props.setShowNavList(false);
          }
          props.history.push(navListItem.href);
        } else {
          // refresh the page to have actual way
        }
      }
    }
  };

  const onMouseAction = (e, navListItem, type) => {
    if (type === "hover") {
      if (hoverDebouncer) {
        clearTimeout(hoverDebouncer);
      }
      hoverDebouncer = setTimeout(() => {
        if (navListItem.title === 'Nourish' && !isAuthenticated) {
          clearTimeout(hoverDebouncer);
        } else {
          // console.log('location ', window.location.href.split('/'));
          if (!(navListItem.title === 'Nourish' && window.location.href.split('/')[3] === 'nourish')) {
            navDispatch({
              type: "HOVER_ON_NAVITEM",
              payload: {
                hoveredOn: navListItem.title,
              },
            });
          }

        }

      }, 300);
    } else if (type === "clear") {
      if (hoverDebouncer) {
        clearTimeout(hoverDebouncer);
      }
    }
  };

  const renderNavItem = (navListItem) => {
    let renderTitle = null;

    switch (navListItem.title) {
      case "Signup":
        renderTitle = <span>{navListItem.title}</span>;
        break;
      default:
        renderTitle = navListItem.title;
    }

    return (
      <>
        {renderTitle}
        {isAuthenticated &&
          navListItem.title === "Grocery List" &&
          groceryListItemCount > 0 ? (
          <span className="count">&nbsp;({groceryListItemCount})</span>
        ) : null}
      </>
    );
  };


  return props.showStickyBanner && !isAuthenticated ? (
    <div className="banner">
      <p>
        There’s no better time to start learning how to cook healthy and
        delicious meals.
        <a
          href={"/pricing"}
          onClick={(e) => {
            e.preventDefault();
            props.history.push("pricing");
          }}
        >
          {" "}
          &nbsp; Signup
        </a>
      </p>
    </div>
  ) : (
    <ul
      id="main-menu1"
      className={cx("navbar-nav navList", {
        "navList-center": true,
      })}
    >
      {listItems.map((navListItem, index) => {
        return (
          <>
            <li
              key={navListItem.title}
              onMouseEnter={(e) => onMouseAction(e, navListItem, "hover")}
              onMouseLeave={(e) => onMouseAction(e, navListItem, "clear")} // Clear the debouncer
              className={cx({
                "active-navitem":
                  navState.activeHovered === navListItem.title &&
                  navListItem.downArrow,
                "join-dashingdish":
                  navListItem.title === "Signup",
              })}
            >
              {navListItem.newIcon ? <div className={isAuthenticated ? "new-icon" : "new-icon logged-out"}>NEW</div> : null}
              <a
                href={navListItem.href}
                onMouseEnter={(e) => e.preventDefault()}
                onClick={(e) => onItemClick(e, navListItem)}
                className={cx({
                  active: getActiveUrl() === navListItem.title,
                  newIcon: navListItem.newIcon
                })}
              >

                <div>
                  {renderNavItem(navListItem)}
                  {navListItem.downArrow ? <DownArrow /> : null}
                </div>
              </a>
              {props.windowWidth < 767 &&
                (navListItem.title === "Explore" || navListItem.title === 'DashingDish') &&
                isDdDropdownActive ? (
                <LoggedOutNavDropdown
                  history={props.history}
                  latestRecipe={latestRecipe}
                  requestCloseNavDropdown={() => {
                    setDdDropdownActive(false);
                    props.setShowNavList(false);
                    requestCloseNavDropdown();
                  }}
                />
              ) : null}
              {props.windowWidth < 767 &&
                navListItem.title === "Nourish" &&
                isKatiesCornerDropdownActive ? (
                <KatiesCornerDropdownContent
                  history={props.history}
                  latestRecipe={latestRecipe}
                  requestCloseNavDropdown={() => {
                    setKatiesCornerDropdownActive(false);
                    props.setShowNavList(false);
                    requestCloseNavDropdown();
                  }}
                />
              ) : null}
            </li>
          </>
        );
      })}
      {isAuthenticated ? (
        <li>
          <>
            <a
              href={"/account"}
              onMouseEnter={(e) =>
                onMouseAction(e, { title: "Profile" }, "hover")
              }
              onMouseLeave={(e) =>
                onMouseAction(e, { title: "Profile" }, "clear")
              } // Clear the debouncer
              onClick={(e) => {
                onItemClick(e, { href: "/account" });
              }}
            >
              <figure className="member-avatar">
                {userDetails &&
                  userDetails.details &&
                  userDetails.details.avatar ? (
                  <ReactImageFallback
                    src={userDetails.details.avatar.thumb}
                    fallbackImage={<AvatarIcon />}
                    className="img_fluid"
                  />
                ) : (
                  <AvatarIcon></AvatarIcon>
                )}
              </figure>
              {(isAuthenticated && userDetails && userDetails.details && userDetails.details.name && userDetails.details.name !== null)
                ? (userDetails.details.name || "").substring(0, 15)
                : null}
              <DownArrow />
            </a>
          </>
        </li>
      ) : <><li className="join-dashingdish">
        <a
          href="/pricing"
          onMouseEnter={(e) =>
            onMouseAction(e, { title: "Signup" }, "hover")
          }
          onMouseLeave={(e) =>
            onMouseAction(e, { title: "Signup" }, "clear")
          } // Clear the debouncer
          onClick={(e) => {
            onItemClick(e, { href: "/initiate-register" });
          }}
        >
          Signup
          <DownArrow />
        </a>
      </li>
      </>
      }
    </ul>
  );
};

export default withRouter(NavList);
