import { call, select } from 'redux-saga/effects'
import { getRecipeLimit, getRecipeOffset, getSelectedFilters } from '../selectors'
import recipesTask from '../subtask/recipes';
import * as actions from '../../actions/actionTypes'
import { getValueBetweenTwoNumbers } from '../../helpers/converters'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty';

export default {
  get: function* getFilteredData(payload, type) {
    const selectedFilters = yield select(getSelectedFilters)
    let categoryQueryParam = selectedFilters.getIn(['categories']).reduce((accumulator, item) => {
      accumulator += `${item},`
      return accumulator
    }, '&category=')

    // to remove the last comma in the string
    categoryQueryParam = categoryQueryParam.replace(/,\s*$/, "")

    if (categoryQueryParam === '&category=') {
      categoryQueryParam = ''
    }

    let collectionQueryParam = selectedFilters.getIn(['collections']).reduce((accumulator, item) => {
      accumulator += `${item},`
      return accumulator
    }, '&collection=')

    collectionQueryParam = collectionQueryParam.replace(/,\s*$/, "")

    if (collectionQueryParam === '&collection=') {
      collectionQueryParam = ''
    }

    let fpmin = selectedFilters.getIn(['freeStylePoints', 'min'])
    let fpmax = selectedFilters.getIn(['freeStylePoints', 'max'])
    let wwpmin = selectedFilters.getIn(['wwp', 'min'])
    let wwpmax = selectedFilters.getIn(['wwp', 'max'])
    let spmin = selectedFilters.getIn(['smartPoints', 'min'])
    let spmax = selectedFilters.getIn(['smartPoints', 'max'])

    let fpRange = getValueBetweenTwoNumbers(fpmin, fpmax)
    let wwpRange = getValueBetweenTwoNumbers(wwpmin, wwpmax)
    let spRange = getValueBetweenTwoNumbers(spmin, spmax)


    let fpQueryParam = fpRange.reduce((accumulator, item) => {
      if (isNull(item)) {
        accumulator = ''
      } else {
        accumulator += `${item},`
      }
      return accumulator
    }, '&fp=')

    fpQueryParam = fpQueryParam.replace(/,\s*$/, "")

    let wwpQueryParam = wwpRange.reduce((accumulator, item) => {
      if (isNull(item)) {
        accumulator = ''
      } else {
        accumulator += `${item},`
      }
      return accumulator
    }, '&wwp=')

    wwpQueryParam = wwpQueryParam.replace(/,\s*$/, "")

    let spQueryParam = spRange.reduce((accumulator, item) => {
      if (isNull(item)) {
        accumulator = ''
      } else {
        accumulator += `${item},`
      }
      return accumulator
    }, '&sp=')

    spQueryParam = spQueryParam.replace(/,\s*$/, "")

    let searchParam = `&search=${selectedFilters.getIn(['search'])}&search_by=${selectedFilters.getIn(['search_by'])}`

    if (isEmpty(selectedFilters.getIn(['search']))) {
      searchParam = ''
    }

    let queryParms
    if (!isEmpty(selectedFilters.getIn(['search']))) {
      queryParms = `${searchParam}`
    } else {
      queryParms = `${categoryQueryParam}${collectionQueryParam}${fpQueryParam}${wwpQueryParam}${spQueryParam}`
    }


    const recipeLimit = yield select(getRecipeLimit);
    const recipeOffset = yield select(getRecipeOffset);
    yield call(recipesTask.getAll, type, {
      recipeLimit,
      recipeOffset: recipeOffset * recipeLimit,
      queryParms,
      replace: payload.replace
    })
  }
} 