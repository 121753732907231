import { put, takeEvery, call, select } from 'redux-saga/effects'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CLEAR_ALL_FILTERS_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* createBoard() {
  yield takeEvery(actions.CLEAR_ALL_FILTERS, task)
}