import { ADD_MEAL_TO_MULTIPLE_DATES } from './actionTypes';

export default (objectId, dates, meal, objectType = 'recipe', addToGroceryList = false, recipeServingSize = 0, activeObject) => ({
  type: ADD_MEAL_TO_MULTIPLE_DATES,
  payload: {
    objectId,
    objectType,
    dates,
    meal,
    addToGroceryList,
    recipeServingSize,
    activeObject
  },
})