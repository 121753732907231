import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'

import isEqual from 'lodash/isEqual'

import {
  getFilteredRecipeOffset,
  getUnFilteredRecipeOffset,
  getToken,
  getRecipeDirtyGroup,
  getAppliedRecipeFilters,
  getRecipeFiltersSetUpdatedPrematurelyStatus,
  getWorkoutDirtyGroup,
  getAppliedWorkoutFilters,
  getWorkoutFiltersSetUpdatedPrematurelyStatus,
  getBlogDirtyGroup,
  getAppliedBlogFilters,
  getBlogFiltersSetUpdatedPrematurelyStatus,
  getVideoDirtyGroup,
  getAppliedVideoFilters,
  getVideoFiltersSetUpdatedPrematurelyStatus,
} from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry'

import { globalFetchLimit, generateAppEssentialPayload } from '../helpers/filterConversions';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  let dirtyGroup
  let toUpdateFilters
  let toUpdateFavorites
  let listType

  if (action.payload.objectType === 'recipe') {
    const {
      recipe: {
        favorite,
        filters: newFilters,
        recipeRefreshGroup,
      } = {}
    } = action.payload

    toUpdateFilters = newFilters;
    toUpdateFavorites = favorite;
    dirtyGroup = yield select(getRecipeDirtyGroup);
    let currentFilters = yield select(getAppliedRecipeFilters);
    currentFilters = currentFilters.toJS();

    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'recipe'
    });

    // For recipe
    const defaultFilters = payloadDefault.recipe.filters;

    // The job of comparing filters is done here
    // Compare old and new filters and see if it not same as default
    // Why default? since default refers to unfiltered data
    const isFiltersDifferent = !isEqual(currentFilters, newFilters) && !isEqual(defaultFilters, newFilters)
    const filtersSetUpdatedPrematurely = yield select(getRecipeFiltersSetUpdatedPrematurelyStatus);

    if (isFiltersDifferent || recipeRefreshGroup === 'filters' || filtersSetUpdatedPrematurely) {
      dirtyGroup = 'filters';
      listType = 'filtered';
    }
  } else if (action.payload.objectType === 'workout') {
    const {
      workout: {
        favorite,
        filters: newFilters,
        workoutRefreshGroup,
      } = {}
    } = action.payload

    toUpdateFilters = newFilters;
    toUpdateFavorites = favorite;

    dirtyGroup = yield select(getWorkoutDirtyGroup);
    let currentFilters = yield select(getAppliedWorkoutFilters);
    currentFilters = currentFilters.toJS();

    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'workout'
    });

    // For workiut
    const defaultFilters = payloadDefault.workout.filters;

    // The job of comparing filters is done here
    // Compare old and new filters and see if it not same as default
    // Why default? since default refers to unfiltered data
    const isFiltersDifferent = !isEqual(currentFilters, newFilters) && !isEqual(defaultFilters, newFilters)
    const filtersSetUpdatedPrematurely = yield select(getWorkoutFiltersSetUpdatedPrematurelyStatus);

    if (isFiltersDifferent || workoutRefreshGroup === 'filters' || filtersSetUpdatedPrematurely) {
      dirtyGroup = 'filters';
      listType = 'filtered';
    }
  } else if (action.payload.objectType === 'blog') {
    const {
      blog: {
        favorite,
        filters: newFilters,
        blogRefreshGroup,
      } = {}
    } = action.payload

    toUpdateFilters = newFilters;
    toUpdateFavorites = favorite;

    dirtyGroup = yield select(getBlogDirtyGroup);
    let currentFilters = yield select(getAppliedBlogFilters);
    currentFilters = currentFilters.toJS();

    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'blog'
    });

    // For workiut
    const defaultFilters = payloadDefault.blog.filters;

    // The job of comparing filters is done here
    // Compare old and new filters and see if it not same as default
    // Why default? since default refers to unfiltered data
    const isFiltersDifferent = !isEqual(currentFilters, newFilters) && !isEqual(defaultFilters, newFilters)
    const filtersSetUpdatedPrematurely = yield select(getBlogFiltersSetUpdatedPrematurelyStatus);

    if (isFiltersDifferent || blogRefreshGroup === 'filters' || filtersSetUpdatedPrematurely) {
      dirtyGroup = 'filters';
      listType = 'filtered';
    }
  } else if (action.payload.objectType === 'video') {
    const {
      video: {
        favorite,
        filters: newFilters,
        videoRefreshGroup,
      }
    } = action.payload

    toUpdateFilters = newFilters;
    toUpdateFavorites = favorite;

    dirtyGroup = yield select(getVideoDirtyGroup);
    let currentFilters = yield select(getAppliedVideoFilters);
    currentFilters = currentFilters.toJS();

    // Gets the default Fitlers
    const payloadDefault = generateAppEssentialPayload({
      objectType: 'video'
    });

    // For workiut
    const defaultFilters = payloadDefault.video.filters;

    // The job of comparing filters is done here
    // Compare old and new filters and see if it not same as default
    // Why default? since default refers to unfiltered data
    const isFiltersDifferent = !isEqual(currentFilters, newFilters) && !isEqual(defaultFilters, newFilters)
    const filtersSetUpdatedPrematurely = yield select(getVideoFiltersSetUpdatedPrematurelyStatus);

    if (isFiltersDifferent || videoRefreshGroup === 'filters' || filtersSetUpdatedPrematurely) {
      dirtyGroup = 'filters';
      listType = 'filtered';
    }
  }

  try {
    yield put({
      type: actions.SET_NEW_FILTERS_SUCCESS,
      payload: {
        favorite: toUpdateFavorites, // TODO: This is not being used now
        filters: toUpdateFilters,
        objectType: action.payload.objectType,
        listType: toUpdateFavorites.show ? 'favorited' : listType,
        dirtyGroup,
        triggered: true,
      }
    });
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.SET_NEW_FILTERS_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* setFilters() {
  yield takeEvery(actions.SET_FILTERS_REQUEST, task)
}