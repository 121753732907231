
import { ADD_ALL_TO_GROCERYLIST } from "./actionTypes";

// TODO: Add types + focus on what data is being is sent
export default (ingredientData, recipeId) => ({
  type: ADD_ALL_TO_GROCERYLIST,
  payload: {
    ingredientData,
    recipeId,
  },
});
