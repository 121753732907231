import { ADD_CUSTOM_RECIPE_TO_MEAL_PLAN } from './actionTypes';

export default (date, customTitle, customUrl, customNotes) => ({
  type: ADD_CUSTOM_RECIPE_TO_MEAL_PLAN,
  payload: {
    date,
    customTitle,
    customUrl,
    customNotes
  },
})