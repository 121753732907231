import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';


const VimeoOutlineIcon = (props) => {
  return (
    <>
      <svg width="31" height="28" viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7.83476L2.1625 9.35976C2.1625 9.35976 4.575 7.45976 5.375 8.40976C6.175 9.35976 9.25 20.8223 10.2625 22.9348C11.15 24.7848 13.6125 27.2348 16.325 25.4848C19.0375 23.7348 28 16.0723 29.6125 7.02226C31.225 -2.02774 18.8125 -0.127743 17.5 7.74726C20.7875 5.77226 22.5375 8.54726 20.8625 11.6848C19.1875 14.8223 17.65 16.8598 16.85 16.8598C16.05 16.8598 15.425 14.7598 14.5125 11.0973C13.5625 7.30976 13.5625 0.472258 9.625 1.24726C5.8875 1.99726 1 7.83476 1 7.83476Z" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default VimeoOutlineIcon;
