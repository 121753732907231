import React from "react";
import '../styles/common.scss';

const MemberAvartar = () => {
  return (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4">
        <path d="M50 0C22.3857 0 0 22.3846 0 50C0 77.6131 22.3857 100 50 100C77.6143 100 100 77.6131 100 50C100.001 22.3846 77.6154 0 50 0ZM74.6585 78.3995H62.2014C60.0832 78.3995 58.3661 76.6699 58.3661 74.5358V72.5857C55.841 76.0063 51.6819 79.7723 45.0874 79.7723C35.2053 79.7723 25.3415 71.788 25.3415 57.2274C25.3415 41.2464 36.5588 32.2962 47.0954 32.2962C52.6194 32.2962 56.2842 34.7906 58.3661 37.9531V24.9574C58.3661 22.8346 57.2661 21.6812 55.0388 21.6812V16.7799L67.2049 15.4765C69.4095 15.2401 71.3312 16.9811 71.3312 19.2152V70.2209C71.3312 72.3448 72.4312 73.4971 74.6585 73.4971V78.3995Z" fill="#888888" />
        <path d="M58.365 50.6193L58.3343 50.1125C58.3343 43.6146 54.8467 39.21 50.8296 39.21C45.7567 39.21 40.8032 45.1249 40.8032 57.4058C40.8032 65.5935 44.1078 70.9232 49.1045 70.9232C53.7455 70.9232 56.6354 67.8709 58.3672 64.9117L58.365 50.6193Z" fill="#888888" />
      </g>
    </svg>
  );
};
export default MemberAvartar;