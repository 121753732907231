import React, { useState, useEffect } from "react";
import ReactImageFallback from "react-image-fallback";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import toJS from "../../helpers/toJS";
import { Helmet } from "react-helmet"

import { getDishListCategories, dishListPreFetchStatus } from "../../selectors";
import { appEssentialFetch } from "../../actions";

import { useDishListHook } from "../../helpers/useDishListHook";
import { generateAppEssentialPayload } from '../../helpers/filterConversions';
import isNil from "lodash/isNil";
import map from "lodash/map";
import InfiniteScroll from 'react-infinite-scroller';
import { Row, Col } from 'react-flexbox-grid';
import Loader from "../images/loader";
import Placeholder from "../common/placeholder";
import RecipeFallbackImage from '../images/workoutfallback.png';
import {
  TransitionOne
} from "../common/transitions";

let scrollParentRef = React.createRef()

const DishList = ({
  appEssentialFetch,
  dishlistCategories,
  dishListPreFetchStatus,
  ...props
}) => {
  const defaultCategory = {
    id: '#1',
    slug: 'all',
    name: 'All Items'
  }

  const [categories, setCategories] = useState([
    defaultCategory,
    ...dishlistCategories
  ]);

  const [selectedItem, setSelectedItem] = useState(null)

  const [state, dispatchDishListAction] = useDishListHook({
    categories,
    shouldFetch: false
  });



  useEffect(() => {
    const splitUrl = props.history.location.pathname.split('/')
    if (splitUrl.length !== 0) {
      setSelectedItem(splitUrl[splitUrl.length - 1])
    }

  }, [])


  useEffect(() => {
    if (!dishListPreFetchStatus.isFetched) {
      appEssentialFetch(generateAppEssentialPayload());
    }
  }, [dishListPreFetchStatus.isFetched]);

  useEffect(() => {
    if (dishListPreFetchStatus.isFetched) {
      setCategories([
        defaultCategory,
        ...dishlistCategories
      ]);
      dispatchDishListAction({
        type: 'SET_CATEGORIES',
        payload: {
          categories:
            [
              defaultCategory,
              ...dishlistCategories
            ]
        }
      });
      if (!isNil(props.match.params.category)) {
        dispatchDishListAction({ type: 'SET_FILTER', payload: { selectedCategory: props.match.params.category } })
      } else {
        dispatchDishListAction({ type: 'SET_FILTER', payload: { selectedCategory: defaultCategory.slug } })
      }
    }
  }, [dishListPreFetchStatus.isFetched, props.match.params.category]);

  const isFetching = (state.isFetching || state.paginationFetching);

  const renderDishListItem = (item) => {
    return (
      <li className="dish-card">
        <a href={item.url} onClick={(e) => {
          e.preventDefault()
          window.open(item.url, '_blank')
        }
        } key={item.id}>
          <figure className="dish-image">
            <ReactImageFallback
              src={(item.image === "") ? RecipeFallbackImage : item.image}
              className="img_fluid"
              fallbackImage={RecipeFallbackImage}
              item={item}
            />
          </figure>
          <p>{item.name}</p>
        </a>
      </li>
    )
  }

  return (
    <section className="dishlist">
      <Helmet>
        <title>DishList | Dashingdish</title>
        <meta name="description" content="Dashingdish DishList page" />
      </Helmet>
      <div className="container">
        <Row className="dishlist-wrapper no-margin">
          <Col sm={3} md={3} lg={3} className="no-padding">
            <TransitionOne>
              <div className="account-content-left-wrapper">
                <div className="category-container">
                  <ul>
                    {
                      map(categories, (category) => {
                        return (
                          <li
                            key={category.slug}
                            onClick={(e) => {
                              props.history.push(`/dishlist/${category.slug}`)
                              setSelectedItem(category.name)
                            }}
                            className={`category ${(state.selectedCategory === category.slug) ? 'selected-category' : ''}`}>
                            {category.name}
                          </li>
                        )
                      })
                    }
                  </ul>
                  <div className="dishlist-text">
                    <p>
                      This is a curated list of my favorite products that you can find on Amazon.com.</p>
                    <p>We do not physically sell any products listed here.</p>
                  </div>
                </div>
              </div>
            </TransitionOne>
          </Col>
          <TransitionOne>
            <Col sm={9} md={9} lg={9} className="no-padding">
              <div className="account-content-right-wrapper">
                <h2>{selectedItem === 'dishlist' ? 'All Items' : selectedItem}</h2>
                <div className="dishlist-data">
                  <InfiniteScroll
                    className="infiniteScroll"
                    initialLoad={false}
                    loadMore={(
                      isFetching
                    ) ?
                      () => { }
                      : () => dispatchDishListAction({ type: 'FETCH_MORE_DISH_LIST' })
                    }
                    hasMore={state.hasMore}
                    loader={
                      <div className="container">
                        <div className="preLoader">
                          <Loader></Loader>
                        </div>
                      </div>
                    }
                  >
                    {
                      state.data.length > 0 ? (
                        <ul className="dish-card-lists">
                          {
                            map(state.data, item => {
                              return renderDishListItem(item)
                            })
                          }
                        </ul>
                      ) : (
                          (!isFetching && state.isFetched) ?
                            < div >
                              No Items for this category
                            </div>
                            : null
                        )
                    }

                  </InfiniteScroll>
                </div>
              </div>
            </Col>
          </TransitionOne>
        </Row>
      </div>
    </section >
  )
}

const mapStateToProps = state => ({
  dishlistCategories: getDishListCategories(state),
  dishListPreFetchStatus: dishListPreFetchStatus(state),
})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(DishList));