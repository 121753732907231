import createReducer from "../helpers/createReducers";

import { fromJS, Map } from "immutable";

import objectSpecificFetchStatus from './objectSpecificFetchStatus';

const initialState = fromJS({
  // For Recipe list page
  appEssentials: {
    isFetching: true,
    isFetched: false,
    isFetchFailed: false,
    errorMessage: null
  },
  startup: {
    isFetching: false,
    isFetched: false,
  },
  userAuthenticate: {
    isFetching: false,
    isFetched: false,
  },
  objectPreFetch: {
    recipe: {
      isFetching: false,
      isFetched: false
    },
    workout: {
      isFetching: false,
      isFetched: false
    },
    blog: {
      isFetching: false,
      isFetched: false
    },
    video: {
      isFetching: false,
      isFetched: false
    },
    dishlist: {
      isFetching: false,
      isFetched: false
    }
  },
  groceryList: {
    isFetching: false,
    isFetched: false,
  },
  listItemsFetch: {
    globalError: {
      errorMessage: null,
    },
    recipe: {
      completeFetch: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
      },
      initialLoad: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pagination: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pullToRefresh: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
      }
    },
    unfilteredList: {
      recipe: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
      },
      workout: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },
      blog: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },
      video: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      }
    },
    filteredList: {
      recipe: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
      },
      workout: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },
      blog: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },
      video: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      }
    },
    favoritesList: {
      recipe: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },
      workout: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },
      blog: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
      },
      video: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      }
    },
    defaultDietaryList: {
      recipe: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      }
    },
    featuredCollectionList: {
      recipe: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },
      workout: {
        initialLoad: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        },
        pagination: {
          isFetching: false,
          isFetched: false,
          isFetchFailed: false,
          errorMessage: null
        }
      },

    },
    workout: {
      initialLoad: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pagination: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pullToRefresh: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
      }
    },
    blog: {
      initialLoad: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pagination: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pullToRefresh: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
      }
    },
    video: {
      initialLoad: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pagination: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
        errorMessage: null
      },
      pullToRefresh: {
        isFetching: false,
        isFetched: false,
        isFetchFailed: false,
      }
    },
    isListItemsFetchFailed: false,
    errorMessage: null
  },

  // for object detials
  objectDetails: {
    recipe: [

    ],
    workout: [

    ],
    blog: [

    ],
  },

  userEssentials: {
    isFetching: false,
    isFetched: false,
  },
  // comments and notes
  objectEssentials: {
    recipe: [

    ],
    workout: [

    ],
    blog: [

    ],
  },
  recipeTemporaryListFetch: {
    isFetching: false,
    isFetched: false,
  },
  workoutTemporaryListFetch: {
    isFetching: false,
    isFetched: false,
  },
  // For posts
  commentPostStatus: {
    isPosting: false,
    isPosted: false,
  },
  boardPostStatus: {
    isPosting: false,
    isPosted: false,
  },
  notePostStatus: {
    isPosting: false,
    isPosted: false,
  },
  favouritePostStatus: {
    isPosting: false,
    isPosted: false,
  },
  //for mealPlan
  mealPlanStatus: {
    isAdding: false,
    isAdded: false
  },
  recipesSearch: {
    isSearching: false,
    isSearched: false
  },
  workoutsSearch: {
    isSearching: false,
    isSearched: false
  },
  blogsSearch: {
    isSearching: false,
    isSearched: false
  },
  videosSearch: {
    isSearching: false,
    isSearched: false
  },
  // Long activity preloader
  addAllCalendarItemsToGrocery: {
    isProgressing: false,
    isProgressed: false,
  },
  addCustomMealPlanToCalendar: {
    isProgressing: false,
    isProgressed: false,
    removeOld: 'complete'
  },
  addSingleMealInMealPlan: {
    isProgressing: false,
    isProgressed: false,
    date: null,
  },
  addSingleCalendarItemsToGrocery: {
    isProgressing: false,
    isProgressed: false,
    date: null,
  },
  addSavedDay: {
    isProgressing: false,
    isProgressed: false,
    date: null,
  },
  clearGroceryItems: {
    isProgressing: false,
    isProgressed: false,
  },
  trendingRecipe: {
    isFetching: false,
    isFetched: false,
  },
  trendingWorkout: {
    isFetching: false,
    isFetched: false,
  },
  faqList: {
    isFetching: false,
    isFetched: false
  },
  editUserDetails: {
    isUpdating: false,
    isUpdated: true,
  },
  changeCardType: {
    isUpdating: false,
    isUpdated: true
  },
  changePlan: {
    isUpdating: false,
    isUpdated: false
  },
  cancelSubscription: {
    isUpdating: false,
    isUpdated: false,
  },
  reverseCancelSubscription: {
    isUpdating: false,
    isUpdated: false
  },
  objectList: {
    recipe: {
      unfiltered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      filtered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      favorited: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      featured: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      }
    },
    workout: {
      unfiltered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      filtered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      favorited: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      featured: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      }
    },
    blog: {
      unfiltered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      filtered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      favorited: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      featured: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      }
    },
    video: {
      unfiltered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      filtered: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      favorited: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      },
      featured: {
        initial: {
          isFetching: false,
          isFetched: false,
        },
        pagination: {
          isFetching: false,
          isFetched: false,
        }
      }
    },
  }
});

export default createReducer(initialState, {
  APP_STARTUP: (state, action) => {
    state = state.setIn(['startup', 'isFetching'], true);
    state = state.setIn(['startup', 'isFetched'], false);
    return state;
  },
  APP_STARTUP_SUCCESS: (state, action) => {
    state = state.setIn(['startup', 'isFetching'], false);
    state = state.setIn(['startup', 'isFetched'], true);
    return state;
  },
  USER_AUTHENTICATE: (state, action) => {
    state = state.setIn(['userAuthenticate', 'isFetching'], true);
    state = state.setIn(['userAuthenticate', 'isFetched'], false);
    return state;
  },
  USER_AUTHENTICATE_SUCCESS: (state, action) => {
    state = state.setIn(['userAuthenticate', 'isFetching'], false);
    state = state.setIn(['userAuthenticate', 'isFetched'], true);
    return state;
  },
  USER_AUTHENTICATE_FAILURE: (state, action) => {
    state = state.setIn(['userAuthenticate', 'isFetching'], false);
    state = state.setIn(['userAuthenticate', 'isFetched'], true);
    return state;
  },
  RECIPE_PRE_FETCH_ESSENTIALS_REQUEST: (state, action) => {
    state = state.setIn(['objectPreFetch', 'recipe', 'isFetching'], true);
    return state;
  },
  WORKOUT_PRE_FETCH_ESSENTIALS_REQUEST: (state, action) => {
    state = state.setIn(['objectPreFetch', 'workout', 'isFetching'], true);
    return state;
  },
  BLOG_PRE_FETCH_ESSENTIALS_REQUEST: (state, action) => {
    state = state.setIn(['objectPreFetch', 'blog', 'isFetching'], true);
    return state;
  },
  DISHLIST_PRE_FETCH_ESSENTIALS_REQUEST: (state, action) => {
    state = state.setIn(['objectPreFetch', 'dishlist', 'isFetching'], true);
    return state;
  },
  RECIPE_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    state = state.setIn(['objectPreFetch', 'recipe', 'isFetching'], false);
    state = state.setIn(['objectPreFetch', 'recipe', 'isFetched'], true);
    return state;
  },
  WORKOUT_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    state = state.setIn(['objectPreFetch', 'workout', 'isFetching'], false);
    state = state.setIn(['objectPreFetch', 'workout', 'isFetched'], true);
    return state;
  },
  BLOG_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    state = state.setIn(['objectPreFetch', 'blog', 'isFetching'], false);
    state = state.setIn(['objectPreFetch', 'blog', 'isFetched'], true);
    return state;
  },
  DISHLIST_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    state = state.setIn(['objectPreFetch', 'dishlist', 'isFetching'], false);
    state = state.setIn(['objectPreFetch', 'dishlist', 'isFetched'], true);
    return state;
  },

  USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH: (state, action) => {
    state = state.setIn(['groceryList', 'isFetching'], true);
    state = state.setIn(['groceryList', 'isFetched'], false);
    return state;
  },
  USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH_SUCCESS: (state, action) => {
    state = state.setIn(['groceryList', 'isFetching'], false);
    state = state.setIn(['groceryList', 'isFetched'], true);
    return state;
  },
  APP_ESSENTIAL_FETCH: (state, action) => {
    state = state.setIn(['listItemsFetch', 'globalError', 'errorMessage'], null);
    state = state.setIn(['appEssentials', 'isFetching'], true);

    if (action.payload && action.payload.fromKey !== 'dietaryRecipesComponent'
      || action.payload && action.payload.fromKey !== 'singleRecipeScreen') {
      state = state.setIn(['listItemsFetch', 'recipe', 'completeFetch', 'isFetching'], true);
      state = state.setIn(['listItemsFetch', 'recipe', 'completeFetch', 'isFetched'], false);
    }

    if (action.payload.meta && action.payload.meta.feature === 'grocery') {
      state = state.setIn(['groceryList', 'isFetching'], true);
      state = state.setIn(['groceryList', 'isFetched'], false);
    }
    return state;
  },
  APP_ESSENTIAL_FETCH_SUCCESS: (state, action) => {
    state = state.setIn(['appEssentials', 'isFetching'], false);
    state = state.setIn(['appEssentials', 'isFetched'], true);

    state = state.setIn(['listItemsFetch', 'recipe', 'completeFetch', 'isFetching'], false);
    state = state.setIn(['listItemsFetch', 'recipe', 'completeFetch', 'isFetched'], true);
    return state;
  },
  APP_ESSENTIAL_FETCH_FAILURE: (state, action) => {
    state = state.setIn(['appEssentials', 'isFetching'], false);
    state = state.setIn(['appEssentials', 'isFetched'], false);
    state = state.setIn(['appEssentials', 'isFetchFailed'], true);
    return state;
  },

  ESSENTIALS_LIST_ITEMS_FETCH: (state, action) => {
    return state;
  },

  RECIPES_LIST_FETCH_REQUEST: (state, action) => {

    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], true);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], false);
    } else {
      if (action && action.payload && action.payload.type) {
        if (action.payload.pagination) {
          // TODO : Changing recipe to a specific [objectType] when adding workouts
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], false);
        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], false);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'isFetched'], false);
      } else {
        state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'isFetched'], false);
      }

    }


    return state;
  },
  WORKOUTS_LIST_FETCH_REQUEST: (state, action) => {
    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], true);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], false);
    } else {
      if (action && action.payload && action.payload.type) {
        if (action.payload.pagination) {
          // TODO : Changing recipe to a specific [objectType] when adding workouts
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], false);

        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], false);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'isFetched'], false);
      } else {
        state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'isFetched'], false);

      }
    }

    return state;
  },

  BLOGS_LIST_FETCH_REQUEST: (state, action) => {
    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], true);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], false);
    } else {
      if (action && action.payload && action.payload.type) {
        if (action.payload.pagination) {
          // TODO : Changing recipe to a specific [objectType] when adding workouts
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], false);

        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], false);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'isFetched'], false);
      } else {
        state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'isFetched'], false);

      }
    }

    return state;
  },

  VIDEOS_LIST_FETCH_REQUEST: (state, action) => {
    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], true);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], false);
    } else {
      if (action && action.payload && action.payload.type) {
        if (action.payload.pagination) {
          // TODO : Changing recipe to a specific [objectType] when adding workouts
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], false);

        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], true);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], false);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'video', 'pagination', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'video', 'pagination', 'isFetched'], false);
      } else {
        state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'isFetching'], true);
        state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'isFetched'], false);

      }
    }

    return state;
  },

  RECIPES_LIST_FETCH_SUCCESS: (state, action) => {

    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], true);
    } else {
      if (action && action.payload && action.payload.type) {
        // TODO : Changing recipe to a specific [objectType] when adding workouts
        if (action.payload.pagination) {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], true);
        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], true);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'isFetched'], true);
      } else {
        state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'isFetched'], true);
      }
    }
    return state;
  },

  WORKOUTS_LIST_FETCH_SUCCESS: (state, action) => {
    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], true);
    } else {
      if (action && action.payload && action.payload.type) {
        if (action.payload.pagination) {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], true);
        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], true);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'isFetched'], true);
      } else {
        state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'isFetched'], true);
      }
    }
    return state;
  },

  BLOGS_LIST_FETCH_SUCCESS: (state, action) => {
    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], true);
    } else {
      if (action && action.payload && action.payload.type) {
        // TODO : Changing recipe to a specific [objectType] when adding workouts
        if (action.payload.pagination) {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], true);
        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], true);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'isFetched'], true);
      } else {
        state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'isFetched'], true);
      }
    }
    return state;
  },
  VIDEOS_LIST_FETCH_SUCCESS: (state, action) => {
    if (action && action.payload && action.payload.pullToRefresh) {
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', action.payload.objectType, 'pullToRefresh', 'isFetched'], true);
    } else {
      if (action && action.payload && action.payload.type) {
        // TODO : Changing recipe to a specific [objectType] when adding workouts
        if (action.payload.pagination) {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'pagination', 'isFetched'], true);
        } else {
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetching'], false);
          state = state.setIn(['listItemsFetch', action.payload.type, action.payload.objectType, 'initialLoad', 'isFetched'], true);
        }
      }
      if (action && action.payload && action.payload.pagination) {
        state = state.setIn(['listItemsFetch', 'video', 'pagination', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'video', 'pagination', 'isFetched'], true);
      } else {
        state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'isFetching'], false);
        state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'isFetched'], true);
      }
    }
    return state;
  },

  RECIPES_LIST_FETCH_FAILURE: (state, action) => {
    if (action && action.payload && action.payload.pagination) {
      state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'recipe', 'pagination', 'errorMessage'], action.payload.errorMessage);
    } else {
      state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'recipe', 'initialLoad', 'errorMessage'], action.payload.errorMessage);
    }
    return state;
  },
  WORKOUTS_LIST_FETCH_FAILURE: (state, action) => {
    if (action && action.payload && action.payload.pagination) {
      state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'workout', 'pagination', 'errorMessage'], action.payload.errorMessage);
    } else {
      state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'workout', 'initialLoad', 'errorMessage'], action.payload.errorMessage);
    }
    return state;
  },
  BLOGS_LIST_FETCH_FAILURE: (state, action) => {
    if (action && action.payload && action.payload.pagination) {
      state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'blog', 'pagination', 'errorMessage'], action.payload.errorMessage);
    } else {
      state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'blog', 'initialLoad', 'errorMessage'], action.payload.errorMessage);
    }
    return state;
  },
  VIDEOS_LIST_FETCH_FAILURE: (state, action) => {
    if (action && action.payload && action.payload.pagination) {
      state = state.setIn(['listItemsFetch', 'video', 'pagination', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'video', 'pagination', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'video', 'pagination', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'video', 'pagination', 'errorMessage'], action.payload.errorMessage);
    } else {
      state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'isFetching'], false);
      state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'isFetched'], true);
      state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'isFetchFailed'], true);
      state = state.setIn(['listItemsFetch', 'video', 'initialLoad', 'errorMessage'], action.payload.errorMessage);
    }
    return state;
  },
  ESSENTIALS_LIST_ITEMS_FETCH_FAILURE: (state, action) => {
    return state.setIn(['listItemsFetch', 'globalError', 'errorMessage'], action.payload.message);
  },
  ESSENTIALS_LIST_ITEMS_FETCH_SUCCESS: (state, action) => {
    return state;
  },
  // for single pages(singleRecipe)


  SEARCH_REQUEST: (state, action) => {
    state = state.setIn([`${action.payload.objectType}sSearch`, 'isSearching'], true)
    state = state.setIn([`${action.payload.objectType}sSearch`, 'isSearched'], false)
    return state
  },

  SEARCH_REQUEST_SUCCESS: (state, action) => {
    state = state.setIn([`${action.payload.objectType}sSearch`, 'isSearching'], false)
    state = state.setIn([`${action.payload.objectType}sSearch`, 'isSearched'], true)
    return state
  },
  RECIPE_FILTER_COUNT: (state, action) => {
    state = state.setIn(['recipeTemporaryListFetch', 'isFetching'], true)
    state = state.setIn(['recipeTemporaryListFetch', 'isFetched'], false)
    return state;
  },

  RECIPE_FILTER_COUNT_SUCCESS: (state, action) => {
    state = state.setIn(['recipeTemporaryListFetch', 'isFetching'], false)
    state = state.setIn(['recipeTemporaryListFetch', 'isFetched'], true)
    return state;
  },
  WORKOUT_FILTER_COUNT: (state, action) => {
    state = state.setIn(['workoutTemporaryListFetch', 'isFetching'], true)
    state = state.setIn(['workoutTemporaryListFetch', 'isFetched'], false)
    return state;
  },

  WORKOUT_FILTER_COUNT_SUCCESS: (state, action) => {
    state = state.setIn(['workoutTemporaryListFetch', 'isFetching'], false)
    state = state.setIn(['workoutTemporaryListFetch', 'isFetched'], true)
    return state;
  },

  USER_ESSENTIALS_FETCH: (state, action) => {
    state = state.setIn(['userEssentials', 'isFetching'], true)
    state = state.setIn(['userEssentials', 'isFetched'], false)
    return state;
  },

  USER_ESSENTIALS_FETCH_SUCCESS: (state, action) => {
    state = state.setIn(['userEssentials', 'isFetching'], false)
    state = state.setIn(['userEssentials', 'isFetched'], true)

    state = state.setIn(['groceryList', 'isFetching'], false);
    state = state.setIn(['groceryList', 'isFetched'], true);

    state = state.setIn(['calendar', 'isFetching'], false);
    state = state.setIn(['calendar', 'isFetched'], true);

    return state;
  },

  USER_ESSENTIALS_FETCH_CACHED: (state, action) => {
    state = state.setIn(['userEssentials', 'isFetching'], false)
    state = state.setIn(['userEssentials', 'isFetched'], true)

    state = state.setIn(['groceryList', 'isFetching'], false);
    state = state.setIn(['groceryList', 'isFetched'], true);

    state = state.setIn(['calendar', 'isFetching'], false);
    state = state.setIn(['calendar', 'isFetched'], true);
    return state;
  },
  ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST: (state, action) => {
    state = state.setIn(['addAllCalendarItemsToGrocery', 'isProgressing'], true)
    state = state.setIn(['addAllCalendarItemsToGrocery', 'isProgressed'], false)
    return state;
  },

  ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_SUCCESS: (state, action) => {
    state = state.setIn(['addAllCalendarItemsToGrocery', 'isProgressing'], false)
    state = state.setIn(['addAllCalendarItemsToGrocery', 'isProgressed'], true)
    return state;
  },

  ADD_MEAL_PLAN_TO_CALENDAR: (state, action) => {
    state = state.setIn(['addCustomMealPlanToCalendar', 'isProgressing'], true)
    state = state.setIn(['addCustomMealPlanToCalendar', 'isProgressed'], false)
    state = state.setIn(['addCustomMealPlanToCalendar', 'status'], action.payload.remove_old ? 'remove' : 'replace')
    return state;
  },

  ADD_MEAL_PLAN_TO_CALENDAR_SUCCESS: (state, action) => {
    state = state.setIn(['addCustomMealPlanToCalendar', 'isProgressing'], false)
    state = state.setIn(['addCustomMealPlanToCalendar', 'isProgressed'], true)
    state = state.setIn(['addCustomMealPlanToCalendar', 'status'], null);
    return state;
  },
  ADD_MEAL_TO_MEAL_PLAN: (state, action) => {
    state = state.setIn(['addSingleMealInMealPlan', 'isProgressing'], true)
    state = state.setIn(['addSingleMealInMealPlan', 'isProgressed'], false)
    state = state.setIn(['addSingleMealInMealPlan', 'date'], action.payload.date);
    return state;
  },
  ADD_MEAL_TO_MEAL_PLAN_SUCCESS: (state, action) => {
    state = state.setIn(['addSingleMealInMealPlan', 'isProgressing'], false)
    state = state.setIn(['addSingleMealInMealPlan', 'isProgressed'], true)
    state = state.setIn(['addSingleMealInMealPlan', 'date'], null);
    return state;
  },

  ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST: (state, action) => {
    state = state.setIn(['addSingleCalendarItemsToGrocery', 'isProgressing'], true)
    state = state.setIn(['addSingleCalendarItemsToGrocery', 'isProgressed'], false)
    state = state.setIn(['addSingleCalendarItemsToGrocery', 'date'], action.payload.date);
    return state;
  },
  ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_SUCCESS: (state, action) => {
    state = state.setIn(['addSingleCalendarItemsToGrocery', 'isProgressing'], false)
    state = state.setIn(['addSingleCalendarItemsToGrocery', 'isProgressed'], true)
    state = state.setIn(['addSingleCalendarItemsToGrocery', 'date'], null);
    return state;
  },
  ADD_SAVED_DAY: (state, action) => {
    state = state.setIn(['addSavedDay', 'isProgressing'], true)
    state = state.setIn(['addSavedDay', 'isProgressed'], false)
    state = state.setIn(['addSavedDay', 'date'], action.payload.date);
    return state;
  },
  ADD_SAVED_DAY_SUCCESS: (state, action) => {
    state = state.setIn(['addSavedDay', 'isProgressing'], false)
    state = state.setIn(['addSavedDay', 'isProgressed'], true)
    state = state.setIn(['addSavedDay', 'date'], null);
    return state;
  },
  DELETE_INGREDIENT_IN_GROCERY_LIST: (state, action) => {
    state = state.setIn(['deleteSingleGroceryItem', 'isProgressing'], true)
    state = state.setIn(['deleteSingleGroceryItem', 'isProgressed'], false)
    return state;
  },
  DELETE_INGREDIENT_IN_GROCERY_LIST_SUCCESS: (state, action) => {
    state = state.setIn(['deleteSingleGroceryItem', 'isProgressing'], false)
    state = state.setIn(['deleteSingleGroceryItem', 'isProgressed'], true)
    return state;
  },
  CLEAR_ALL_GROCERY_LIST_ITEMS: (state, action) => {
    state = state.setIn(['clearGroceryItems', 'isProgressing'], true)
    state = state.setIn(['clearGroceryItems', 'isProgressed'], false)
    return state;
  },
  CLEAR_ALL_GROCERY_LIST_ITEMS_SUCCESS: (state, action) => {
    state = state.setIn(['clearGroceryItems', 'isProgressing'], false)
    state = state.setIn(['clearGroceryItems', 'isProgressed'], true)
    return state;
  },
  TRENDING_ESSENTIALS_FETCH: (state, action) => {
    state = state.setIn(['trendingRecipe', 'isFetching'], true)
    state = state.setIn(['trendingRecipe', 'isFetched'], false)

    return state;
  },
  TRENDING_ESSENTIALS_FETCH_SUCCESS: (state, action) => {
    state = state.setIn(['trendingRecipe', 'isFetching'], false)
    state = state.setIn(['trendingRecipe', 'isFetched'], true)

    return state;
  },
  TRENDING_TYPE_CHANGE: (state, action) => {
    if (action.payload.objectType === 'recipe') {
      state = state.setIn(['trendingRecipe', 'isFetching'], true)
      state = state.setIn(['trendingRecipe', 'isFetched'], false)
    }
    if (action.payload.objectType === 'workout') {
      state = state.setIn(['trendingWorkout', 'isFetching'], true)
      state = state.setIn(['trendingWorkout', 'isFetched'], false)
    }
    if (action.payload.objectType === 'blog') {
      state = state.setIn(['trendingBlog', 'isFetching'], true)
      state = state.setIn(['trendingBlog', 'isFetched'], false)
    }

    return state;
  },
  TRENDING_TYPE_CHANGE_SUCCESS: (state, action) => {
    if (action.payload.objectType === 'recipe') {
      state = state.setIn(['trendingRecipe', 'isFetching'], false)
      state = state.setIn(['trendingRecipe', 'isFetched'], true)
    }
    if (action.payload.objectType === 'workout') {
      state = state.setIn(['trendingWorkout', 'isFetching'], false)
      state = state.setIn(['trendingWorkout', 'isFetched'], true)
    }
    if (action.payload.objectType === 'blog') {
      state = state.setIn(['trendingBlog', 'isFetching'], false)
      state = state.setIn(['trendingBlog', 'isFetched'], true)
    }
    return state;
  },
  FAQS_LIST_FETCH_REQUEST: (state, action) => {
    state = state.setIn(['faqList', 'isFetching'], true)
    state = state.setIn(['faqList', 'isFetched'], false);
    return state;
  },
  FAQS_LIST_FETCH_REQUEST_SUCCESS: (state, action) => {
    state = state.setIn(['faqList', 'isFetching'], false)
    state = state.setIn(['faqList', 'isFetched'], true)
    return state;
  },

  EDIT_USER_PROFILE_REQUEST: (state, action) => {
    state = state.setIn(['editUserDetails', 'isFetching'], true)
    state = state.setIn(['editUserDetails', 'isFetched'], false);
    return state;
  },
  EDIT_USER_PROFILE_SUCCESS: (state, action) => {
    state = state.setIn(['editUserDetails', 'isFetching'], false)
    state = state.setIn(['editUserDetails', 'isFetched'], true)
    return state;
  },
  EDIT_USER_PROFILE_FAILURE: (state, action) => {
    state = state.setIn(['editUserDetails', 'isFetching'], false)
    state = state.setIn(['editUserDetails', 'isFetched'], false)
    return state;
  },
  CHANGE_PAYMENT_TYPE_REQUEST: (state, action) => {
    state = state.setIn(['changeCardType', 'isUpdating'], true);
    state = state.setIn(['changeCardType', 'isUpdated'], false);
    return state;
  },
  CHANGE_PAYMENT_TYPE_SUCCESS: (state, action) => {
    state = state.setIn(['changeCardType', 'isUpdating'], false);
    state = state.setIn(['changeCardType', 'isUpdated'], true);
    return state;
  },
  CHANGE_PAYMENT_TYPE_FAILURE: (state, action) => {
    state = state.setIn(['changeCardType', 'isUpdating'], false);
    state = state.setIn(['changeCardType', 'isUpdated'], false);
    return state;
  },
  CHANGE_PLAN: (state, action) => {
    state = state.setIn(['changePlan', 'isUpdating'], true);
    state = state.setIn(['changePlan', 'isUpdated'], false);
    return state;
  },
  CHANGE_PLAN_SUCCESS: (state, action) => {
    state = state.setIn(['changePlan', 'isUpdating'], false);
    state = state.setIn(['changePlan', 'isUpdated'], true);
    return state;
  },

  CANCEL_SUBSCRIPTION: (state, action) => {
    state = state.setIn(['cancelSubscription', 'isUpdating'], true);
    state = state.setIn(['cancelSubscription', 'isUpdated'], false);
    return state;
  },
  CANCEL_SUBSCRIPTION_SUCCESS: (state, action) => {
    state = state.setIn(['cancelSubscription', 'isUpdating'], false);
    state = state.setIn(['cancelSubscription', 'isUpdated'], true);
    return state;
  },
  REVERSE_CANCEL_SUBSCRIPTION: (state, action) => {
    state = state.setIn(['reverseCancelSubscription', 'isUpdating'], true);
    state = state.setIn(['reverseCancelSubscription', 'isUpdated'], false);
    return state;
  },
  REVERSE_CANCEL_SUBSCRIPTION_SUCCESS: (state, action) => {
    state = state.setIn(['reverseCancelSubscription', 'isUpdating'], false);
    state = state.setIn(['reverseCancelSubscription', 'isUpdated'], true);
    return state;
  },
  APP_ESSENTIAL_FETCH: (state, action) => {
    let objectType;
    let listType;
    if (action.payload && action.payload.recipe && action.payload.recipe.must && action.payload.recipe.meta) {
      objectType = 'recipe'
      listType = action.payload.recipe.meta.listType;
      listType = (action.payload.recipe && action.payload.recipe.meta) ? action.payload.recipe.meta.listType : null;
    } else if (action.payload && action.payload.workout && action.payload.workout.must && action.payload.workout.meta) {
      objectType = 'workout'
      listType = (action.payload.workout && action.payload.workout.meta) ? action.payload.workout.meta.listType : null;
    } else if (action.payload && action.payload.blog && action.payload.blog.must && action.payload.blog.meta) {
      objectType = 'blog'
      listType = (action.payload.blog && action.payload.blog.meta) ? action.payload.blog.meta.listType : null;
    } if (action.payload && action.payload.video && action.payload.video.must && action.payload.video.meta) {
      objectType = 'video'
      listType = (action.payload.video && action.payload.video.meta) ? action.payload.video.meta.listType : null;
    }

    if (listType === 'unfiltered') {
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetching'], true);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetched'], false);


      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetched'], false);


    } else if (listType === 'filtered') {
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetching'], true);
      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetched'], false);

    } else if (listType === 'favorited') {
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetching'], true);
      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetched'], false);

    } else if (listType === 'featured') {
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'filtered', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'favorited', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetching'], true);
      state = state.setIn(['objectList', objectType, 'featured', 'initial', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'unfiltered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'filtered', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'favorited', 'pagination', 'isFetched'], false);

      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetching'], false);
      state = state.setIn(['objectList', objectType, 'featured', 'pagination', 'isFetched'], false);
    }

    if (action.payload && action.payload.meta && action.payload.meta.feature === 'grocery') {
      state = state.setIn(['groceryList', 'isFetching'], true);
      state = state.setIn(['groceryList', 'isFetched'], false);
    }

    if (action.payload && action.payload.meta && action.payload.meta.feature === 'calendar') {
      state = state.setIn(['calendar', 'isFetching'], true);
      state = state.setIn(['calendar', 'isFetched'], false);
    }
    return state;
  },
  SET_NEW_FILTERS_SUCCESS: (state, action) => {
    if (action.payload.pagination) {
      const listType = action.payload.listType;
      state = state.setIn(['objectList', action.payload.objectType, listType, 'pagination', 'isFetching'], true);
      state = state.setIn(['objectList', action.payload.objectType, listType, 'pagination', 'isFetched'], false);
    } else {
      const listType = action.payload.listType;
      state = state.setIn(['objectList', action.payload.objectType, listType, 'initial', 'isFetching'], true);
      state = state.setIn(['objectList', action.payload.objectType, listType, 'initial', 'isFetched'], false);
    }
    return state;
  },
  RECIPES_LIST_FETCH_REQUEST: (state, action) => {
    if (action.payload.pagination) {
      const listType = action.payload.listType;
      state = state.setIn(['objectList', 'recipe', listType, 'pagination', 'isFetching'], true);
      state = state.setIn(['objectList', 'recipe', listType, 'pagination', 'isFetched'], false);
    }

    return state;
  },
  RECIPES_LIST_FETCH_SUCCESS: (state, action) => {
    const listType = action.payload.listType;
    const fetchType = action.payload.pagination ? 'pagination' : 'initial';
    if (listType === 'all') {
      // Might be repetitive but good to do with
      state = state.setIn(['objectList', 'recipe', 'unfiltered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'recipe', 'unfiltered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'recipe', 'filtered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'recipe', 'filtered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'recipe', 'favorited', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'recipe', 'favorited', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'recipe', 'featured', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'recipe', 'featured', fetchType, 'isFetched'], true);

    } else {
      state = state.setIn(['objectList', 'recipe', listType, fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'recipe', listType, fetchType, 'isFetched'], true);
    }

    return state;
  },

  WORKOUTS_LIST_FETCH_REQUEST: (state, action) => {
    if (action.payload.pagination) {
      const listType = action.payload.listType;
      state = state.setIn(['objectList', 'workout', listType, 'pagination', 'isFetching'], true);
      state = state.setIn(['objectList', 'workout', listType, 'pagination', 'isFetched'], false);
    }

    return state;
  },
  WORKOUTS_LIST_FETCH_SUCCESS: (state, action) => {
    const listType = action.payload.listType;
    const fetchType = action.payload.pagination ? 'pagination' : 'initial';
    if (listType === 'all') {
      // Might be repetitive but good to do with
      state = state.setIn(['objectList', 'workout', 'unfiltered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'workout', 'unfiltered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'workout', 'filtered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'workout', 'filtered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'workout', 'favorited', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'workout', 'favorited', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'workout', 'featured', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'workout', 'featured', fetchType, 'isFetched'], true);

    } else {
      state = state.setIn(['objectList', 'workout', listType, fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'workout', listType, fetchType, 'isFetched'], true);
    }

    return state;
  },
  BLOGS_LIST_FETCH_REQUEST: (state, action) => {
    if (action.payload.pagination) {
      const listType = action.payload.listType;
      state = state.setIn(['objectList', 'blog', listType, 'pagination', 'isFetching'], true);
      state = state.setIn(['objectList', 'blog', listType, 'pagination', 'isFetched'], false);
    }

    return state;
  },
  BLOGS_LIST_FETCH_SUCCESS: (state, action) => {
    const listType = action.payload.listType;
    const fetchType = action.payload.pagination ? 'pagination' : 'initial';
    if (listType === 'all') {
      // Might be repetitive but good to do with
      state = state.setIn(['objectList', 'blog', 'unfiltered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'blog', 'unfiltered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'blog', 'filtered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'blog', 'filtered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'blog', 'favorited', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'blog', 'favorited', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'blog', 'featured', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'blog', 'featured', fetchType, 'isFetched'], true);

    } else {
      state = state.setIn(['objectList', 'blog', listType, fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'blog', listType, fetchType, 'isFetched'], true);
    }

    return state;
  },
  VIDEOS_LIST_FETCH_REQUEST: (state, action) => {
    if (action.payload.pagination) {
      const listType = action.payload.listType;
      state = state.setIn(['objectList', 'video', listType, 'pagination', 'isFetching'], true);
      state = state.setIn(['objectList', 'video', listType, 'pagination', 'isFetched'], false);
    }

    return state;
  },
  VIDEOS_LIST_FETCH_SUCCESS: (state, action) => {
    const listType = action.payload.listType;
    const fetchType = action.payload.pagination ? 'pagination' : 'initial';
    if (listType === 'all') {
      // Might be repetitive but good to do with
      state = state.setIn(['objectList', 'video', 'unfiltered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'video', 'unfiltered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'video', 'filtered', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'video', 'filtered', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'video', 'favorited', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'video', 'favorited', fetchType, 'isFetched'], true);

      state = state.setIn(['objectList', 'video', 'featured', fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'video', 'featured', fetchType, 'isFetched'], true);

    } else {
      state = state.setIn(['objectList', 'video', listType, fetchType, 'isFetching'], false);
      state = state.setIn(['objectList', 'video', listType, fetchType, 'isFetched'], true);
    }

    return state;
  },
  ...objectSpecificFetchStatus

});


