import React from 'react';
import { map } from 'lodash';
import { withRouter } from 'react-router-dom'

import '../../styles/common.scss';

const RecipeTags = (props) => {
  const {
    tags = [],
    history,
  } = props;
  return (
    (tags && tags.length) > 0 && (
      <div className="tags">
        <h2>Recipe Tags</h2>
        <ul className="tagsLists">
          {
            map(tags, (tag, index) => {
              return (
                <li key={index} className="tagsList">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      if (tag.taxonomy_type === 'foodtype') {
                        history.push(`/recipes/?food-types=${tag.slug}`);
                      }

                      if (tag.taxonomy_type === 'speciality') {
                        history.push(`/recipes/?specialities=${tag.slug}`);
                      }

                      if (tag.taxonomy_type === 'dietary') {
                        history.push(`/recipes/?dietaries=${tag.slug}`);
                      }

                    }}
                    href={`/recipes/${tag.slug}`}>
                    {tag.name}
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  )
}

export default withRouter(RecipeTags);    