import axios from "axios";
import { put, takeEvery, call, select, take } from "redux-saga/effects";
import { get, post } from "../helpers/requests";
import { auth } from "../helpers/api";

import * as actions from "../actions/actionTypes";
import pushToSentry from "../helpers/pushToSentry";

import { getGiftCode, getCouponData } from "./selectors";
import isNil from "lodash/isNil";

function* task(action) {
  try {
    const gift = yield select(getGiftCode);
    const coupon = yield select(getCouponData);

    const {
      name,
      email,
      password,
      stripeId,
      duration,
      planId,
      paypalId,
      giftCode,
      shouldApplyDiscount = true,
      heardFrom,
      otherReasons,
      ipAddress
    } = action.payload;

    // Registers the user
    let response;

    let couponCode = coupon.getIn(["data", "code"]);

    let noCC = false;
    if (couponCode === 'TRY6') {
      noCC = true;
      couponCode = null;
    }
    response = yield call(
      axios,
      post(auth.registerUnified(), {
        name,
        email,
        password,
        stripe_token: stripeId,
        months: duration === "monthly" ? 1 : 12,
        plan_id: planId,
        paypal_id: paypalId,
        coupon: (shouldApplyDiscount && duration !== "monthly") ? couponCode : null,
        gift_code: giftCode,
        heard_from: heardFrom,
        other_reasons: otherReasons,
        ip_address: ipAddress,
        no_cc: noCC
      })
    );

    yield put({
      type: "LOGIN_USER",
      payload: {
        email,
        password,
        firstTime: true,
      },
    });

    yield put({
      type: actions.UNIFIED_REGISTER_SUCCESS,
      payload: {},
    });
  } catch (e) {
    console.log(e);
    if (e.response && e.response.status === 400) {
      const eventId = pushToSentry(e);

      yield put({
        type: actions.UNIFIED_REGISTER_FAILURE,
        payload: {
          error: e.response.data.message,
        },
      });
    } else {
      yield put({
        type: actions.CREATE_NEW_USER_FAILURE,
        payload: {
          error: "Some error occured.",
        },
      });
    }
  }
}

export default function* registerUser() {
  yield takeEvery("UNIFIED_REGISTER", task);
}
