import React, { useEffect } from 'react'

import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import Membership from './membership'
import Notifications from './notifications'
import Settings from './settings'
import Referral from './referral'
import AccountTab from './accountTab';
import Page404 from '../404';
import { useDailyDishHook } from "../../helpers/useDailyDishHook";
import {
  appEssentialFetch,
  cancelSubscription,
  reverseCancelSubscription,
  changePlan,
  editUserProfile,
  changePaymentType,
  showToastMessage,
  addSentryError,
} from '../../actions';

import toJS from '../../helpers/toJS'

import {
  getUserDetails,
  getPlans,
  getProfileUpdateFetchStatus,
  getChangeCardTypeFetchStatus,
  getPlanChangeFetchStatus,
  getCancelSubscriptionFetchStatus,
  getReverseCancelSubscriptionFetchStatus
} from '../../selectors'
import DailyDish from './DailyDish';

const Account = ({
  history,
  appEssentialFetch,
  userDetails,
  cancelSubscription,
  reverseCancelSubscription,
  changePlan,
  plans,
  editUserProfile,
  profileUpdateFetchStatus,
  changePaymentType,
  changeCardTypeFetchStatus,
  changePlanFetchStatus,
  cancelSubscriptionFetchStatus,
  reverseCancelSubscriptionFetchStatus,
  location,
  showToastMessage
}) => {
  const {
    token
  } = userDetails;

  const [state, dispatchDishListAction] = useDailyDishHook({ token });

  useEffect(() => {
    appEssentialFetch()
  }, [])

  return (
    <section>
      <div className="container">
        <Helmet>
          <title>Account | Dashing Dish</title>
          <meta name="description" content="Dashingdish Account page" />
        </Helmet>
        <AccountTab history={history} location={location} notificationState={state} />
        <Switch>
          <Route exact path="/account/membership"
            render={(props) => <Membership
              {...props}
              showToastMessage={showToastMessage}
              userDetails={userDetails}
              cancelSubscription={cancelSubscription}
              reverseCancelSubscription={reverseCancelSubscription}
              changePlan={changePlan}
              plans={plans}
              changePaymentType={changePaymentType}
              changeCardTypeFetchStatus={changeCardTypeFetchStatus}
              changePlanFetchStatus={changePlanFetchStatus}
              cancelSubscriptionFetchStatus={cancelSubscriptionFetchStatus}
              reverseCancelSubscriptionFetchStatus={reverseCancelSubscriptionFetchStatus}
            />
            }
          />
          <Route exact path="/account/referral"
            render={(props) =>
              <Referral
                {...props}
                userDetails={userDetails}
                showToastMessage={showToastMessage}
              />
            }
          />
          <Route exact path="/account/notifications"
            component={Notifications}
          />
          <Route exact path="/account/updates"
            render={(props) =>
              <DailyDish
                {...props}
                state={state}
                userDetails={userDetails}
                dispatchDishListAction={dispatchDishListAction}
                showToastMessage={showToastMessage}
              />
            }
          />
          <Route exact path="/account"
            render={(props) => <Settings
              {...props}
              userDetails={userDetails}
              editUserProfile={editUserProfile}
              profileUpdateFetchStatus={profileUpdateFetchStatus}
            />}
          />
          <Route
            component={Page404}
          />
        </Switch>
      </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
  plans: getPlans(state),
  profileUpdateFetchStatus: getProfileUpdateFetchStatus(state),
  changeCardTypeFetchStatus: getChangeCardTypeFetchStatus(state),
  changePlanFetchStatus: getPlanChangeFetchStatus(state),
  cancelSubscriptionFetchStatus: getCancelSubscriptionFetchStatus(state),
  reverseCancelSubscriptionFetchStatus: getReverseCancelSubscriptionFetchStatus(state)
})

const dispatchActionToProps = (dispatch) => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  cancelSubscription: bindActionCreators(cancelSubscription, dispatch),
  reverseCancelSubscription: bindActionCreators(reverseCancelSubscription, dispatch),
  changePlan: bindActionCreators(changePlan, dispatch),
  editUserProfile: bindActionCreators(editUserProfile, dispatch),
  changePaymentType: bindActionCreators(changePaymentType, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Account));