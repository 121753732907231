import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-flexbox-grid";
import toUpper from "lodash/toUpper";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import RecipeIngredients from "./ingredients";
import RecipeActions from "../../common/objectActions";
import RecipeStory from "./recipeStory";
import RecipeTags from "./recipeTags";
import RecipeMethods from "./methods";
import RecipeKatieTip from "./katieTip";
import SlugImage from "./slugImage";
import Comments from "../../common/comments";
import Button from "../../common/button";
import SocialIcon from "../../common/socialIcon";
import RecipeSideIdeas from "./sideIdeas";
import FeaturedMetaData from "../../common/featuredMetadata";
import Modalwrapper from "../../common/modal";
import PopupModal from "../../common/popupModal";
import getRecipeImage from "../../../helpers/getRecipeImage";
import { generateAppEssentialPayload } from "../../../helpers/filterConversions";
import "../../styles/common.scss";
import Loader from "../../images/loader";
import getUsePlanType from "../../../helpers/getUserPlanType";
import getServingCountFromActiveVariant from "../../../helpers/getServingCountFromActiveVariant";

import PaywallLimiter from "../../common/paywallLimiter";
import AdComponent from "../../common/ad";
import MFP from '../../images/mfp.png';

import {
  TransitionOne,
  TransitionTwo,
  TransitionThree,
} from "../../common/transitions";

import type {
  essentialFetchActionParamsType,
  recipeType,
  recipeVariationType,
  recipeGroupType,
  recipeLineType,
  relatedRecipeType,
} from "../../../types";

type Props = {
  appEssentialFetch: (args: essentialFetchActionParamsType) => void,
  match: {
    params: {
      recipeId: string,
    },
  },
  caloriesForRecipe: recipeType,
  activeRecipe: recipeType,
  activeVariant: recipeVariationType,
  ingredientDetails: {
    groups: Array<recipeGroupType>,
    selectedCount?: number,
  },
  relatedRecipe: Array<recipeType>,
  addToGroceryList: (args: Array<recipeLineType>) => void,
  addAllToGroceryList: (args: Array<recipeLineType>) => void,
  clearItemsFromGroceryWaitlist: () => void,
  removeItemsFromGroceryWaitlist: (args: string) => void,
  changeRecipeVariation: (args: string) => void,
};

const SingleRecipe = (props: Props) => {
  useEffect(() => {
    const filterFromQueryString = generateAppEssentialPayload({
      objectType: "recipe",
      recipeSlug: props.match.params.recipeSlug,
    });
    props.appEssentialFetch(filterFromQueryString);
  }, [props.match.params.recipeSlug]);

  const {
    //selectors
    tags,
    length,
    notes,
    token,
    boards,
    history, // navigation history
    comments,
    isLoggedIn,
    userDetails,
    activeRecipe, // refers to the recipe associated with the current page
    relatedRecipe,
    activeVariant, // active variant selected. Defaults to the variant at recipe.variants[0]
    ingredientDetails,
    commentPostStatus,
    fetchStatus,
    caloriesForRecipe,
    getGroceryWaitlist,
    isAuthenticated,
    isAuthenticationFailed,
    objectEssentialFetchStatus,
    objectFetchStatus,
    setMacros,
    calendarList,
    ingredientDetails: {
      groups, // Active recipe group. Trust this to render the recipe
      selectedCount, // total number of ingredients selected
    } = {},

    //actions
    sendNote,
    sendComment,
    createBoard,
    setObjectBoards,
    createFavourite,
    deleteFavourite,
    sendReplyComment,
    addToGroceryList,
    setCurrentServing,
    clearBoardSelection,
    addAllToGroceryList,
    deleteFavouriteBoard,
    updateFavouriteBoard,
    groceryListCount,
    addMealToMultipleDays,
    meteredDetails,
    agreeToMeteredVisit,
    ingredientsV2,
    selectedCountV2
  } = props;
  // condition for preloader


  if (
    fetchStatus.startup.isFetching ||
    fetchStatus.userAuthenticate.isFetching ||
    fetchStatus.objectPreFetch.recipe.isFetching ||
    meteredDetails.isFetching ||
    ((!activeVariant || !activeVariant.nutrition) &&
      meteredDetails.errorMessage.length === 0) // error message might be a reason for not fetching
  ) {
    return (
      <div className="preLoader">
        <Loader></Loader>
      </div>
    );
  } else {
    const defaultServing =
      activeVariant &&
        activeVariant.nutrition &&
        activeVariant.nutrition.groups &&
        activeVariant.nutrition.groups.length > 0
        ? getServingCountFromActiveVariant(activeVariant.nutrition)
        : 4;

    let cookTime =
      activeVariant && activeVariant.cook_time
        ? activeVariant.cook_time.split(" ")[0]
        : "-";
    let cookDuration =
      activeVariant && activeVariant.cook_time
        ? toUpper(activeVariant.cook_time.split(" ")[1])
          ? toUpper(activeVariant.cook_time.split(" ")[1]).substring(0, 1)
          : "M"
        : "";

    const primaryMetaData = [
      {
        title: "Cooking Time",
        value: `${cookTime} ${cookDuration}`,
      },
      {
        title: "Cals Per Serving",
        value: caloriesForRecipe.calories,
      },
    ];

    const secondaryMetaData = {
      title: "Full Nutrition",
      items:
        activeVariant &&
          activeVariant.nutrition &&
          activeVariant.nutrition.groups &&
          activeVariant.nutrition.groups.length > 0
          ? activeVariant.nutrition.groups[0].lines
          : [],
      additionalItems: [
        {
          item: "SmartPoints®",
          amount: caloriesForRecipe.sp,
        },
        {
          item: "WWP+",
          amount: activeVariant.wwp,
        },
      ],
    };

    const defaultShareImage =
      "https://d11z36dj5c0s43.cloudfront.net/public-assets/images/icons/apple-touch-icon-precomposed-152x152.png";

    let featuredImage =
      activeRecipe && activeRecipe.images ? activeRecipe.images.wide_640 : "";

    if (!featuredImage) {
      featuredImage =
        meteredDetails && meteredDetails.images
          ? meteredDetails.images.wide_640
          : "";
    }


    return (
      <section className="singleRecipe">
        <Helmet>
          <title>
            {activeRecipe.title
              ? `${activeRecipe.title} | `
              : meteredDetails.title
                ? meteredDetails.title
                : ""}{" "}
            Dashing Dish
          </title>
        </Helmet>
        <SlugImage
          className={"sm-display recipeFullWidth"}
          favorite={false}
          activeRecipe={activeRecipe}
          fetchStatus={fetchStatus}
          featuredImage={featuredImage}
        />

        <div className="container">
          <AdComponent />
          <Row className="singleWrapper no-margin">
            <TransitionOne>
              <Col sm={12} md={6} lg={6} className="mainboard no-padding">
                <div className="details">
                  <h1>{activeRecipe.title || meteredDetails.title}</h1>
                  {true ? (
                    <>
                      <FeaturedMetaData
                        rating={activeRecipe.avg_rating}
                        primaryMetaData={primaryMetaData}
                        secondaryMetaData={secondaryMetaData}
                        favoriteCount={activeRecipe.favorite_count}
                        activeVariant={activeVariant}
                        variation={activeRecipe.variations}
                        changeRecipeVariation={props.changeRecipeVariation}
                        variationTotalCount={props.variationTotalCount}
                      />
                      <RecipeActions
                        history={history}
                        note={notes}
                        token={token}
                        boards={boards}
                        thumbnail={getRecipeImage(activeRecipe, "square_400")}
                        objectType="recipe"
                        isLoggedIn={isLoggedIn}
                        activeObject={activeRecipe}
                        objectSlug={activeRecipe.slug}
                        objectEssentialFetchStatus={objectEssentialFetchStatus}
                        sendNote={sendNote}
                        createBoard={createBoard}
                        createFavourite={createFavourite}
                        deleteFavourite={deleteFavourite}
                        setObjectBoards={setObjectBoards}
                        clearBoardSelection={clearBoardSelection}
                        deleteFavouriteBoard={deleteFavouriteBoard}
                        updateFavouriteBoard={updateFavouriteBoard}
                        actionList={[
                          "mealPlan",
                          "groceryList",
                          "note",
                          "favourite",
                        ]}
                        addAllToGroceryList={() => {
                          addAllToGroceryList(
                            ingredientsV2,
                            activeRecipe.id
                          );
                        }}
                        calendarList={calendarList}
                        setMacros={setMacros}
                        addMealToMultipleDays={addMealToMultipleDays}
                        userType={getUsePlanType(userDetails)}
                        isAuthenticated={isAuthenticated}
                      />
                      <RecipeStory story={activeRecipe.story} />
                      <AdComponent />
                      {(!isAuthenticated && activeRecipe.premium) ? (
                        <PaywallLimiter
                          premium={activeRecipe.premium}
                          objectType="recipe"
                          isAuthenticated={isAuthenticated}
                          agreeToMeteredVisit={() => { }}
                        />
                      ) : (
                        <>
                          <RecipeIngredients
                            variation={activeRecipe.variations}
                            history={history}
                            groups={groups}
                            isLoggedIn={isLoggedIn}
                            selectedCount={selectedCountV2}
                            groceryWaitList={getGroceryWaitlist}
                            ingredients={activeVariant.ingredients}
                            recipeId={activeRecipe.id}
                            setCurrentServing={setCurrentServing}
                            addToGroceryList={() => {
                              addToGroceryList(getGroceryWaitlist)
                            }
                            }
                            fetchStatus={fetchStatus}
                            userType={getUsePlanType(userDetails)}
                            defaultServing={defaultServing}
                            isAuthenticated={isAuthenticated}
                            changeRecipeVariation={props.changeRecipeVariation}
                            ingredientsV2={ingredientsV2}
                            variationTotalCount={props.variationTotalCount}
                          />
                        </>
                      )}
                    </>
                  ) : null}
                </div>
                {
                  isAuthenticated || (!isAuthenticated && !activeRecipe.premium) ? (
                    <>
                      <RecipeMethods
                        cookTime={activeVariant.cook_time}
                        method={activeRecipe.method}
                        fetchStatus={fetchStatus}
                      />
                      {
                        activeRecipe.mfp ? (
                          <div className="mfp-wrapper">
                            <img src={MFP} className="mfp-img" alt="mfp-img" />
                            <span className="mfp-content"> {activeRecipe.mfp}</span>
                          </div>
                        ) : null
                      }

                    </>
                  ) : null
                }

                {
                  isAuthenticated || (!isAuthenticated && !activeRecipe.premium) ? (
                    <RecipeSideIdeas
                      feature="recipe"
                      sideIdeas={relatedRecipe}
                      wrapperClass="similarCardLists"
                      history={history}
                      objectType="recipe"
                      title="Recipes that you might like"
                    />
                  ) : null}
                {isAuthenticated || (!isAuthenticated && !activeRecipe.premium) ? (
                  <>
                    {objectEssentialFetchStatus.isFetching ? (
                      <div className="preLoader">
                        <Loader></Loader>
                      </div>
                    ) : (
                      <Comments
                        comments={comments}
                        objectType="recipe"
                        isLoggedIn={isLoggedIn}
                        userDetails={
                          !isEmpty(userDetails) ? userDetails.details : ""
                        }
                        activeObject={activeRecipe}
                        commentPostStatus={commentPostStatus}
                        sendComment={sendComment}
                        sendReplyComment={sendReplyComment}
                      />
                    )}
                  </>
                ) : null}
              </Col>
            </TransitionOne>
            <TransitionTwo>
              <Col
                sm={12}
                md={5}
                mdOffset={0}
                lg={5}
                lgOffset={0.75}
                className="sideboard no-padding"
              >
                <div className="assets">
                  <div className="SlugImage-wrapper">
                    <SlugImage
                      featuredImage={
                        activeRecipe && activeRecipe.images
                          ? activeRecipe.images.full_lg
                          : meteredDetails && meteredDetails.images
                            ? meteredDetails.images.full_lg
                            : ""
                      }
                    />
                    {(!isAuthenticated && !meteredDetails.errorMessage) ||
                      isAuthenticated ? (
                      <RecipeKatieTip
                        katiesTip={activeRecipe ? activeRecipe.katies_tip : ""}
                      />
                    ) : null}
                  </div>
                  <RecipeTags tags={tags.length === 0 ? [] : tags} />
                  <div className="insta-share-header">
                    <h3>Did you try to make this Recipe?</h3>
                    <p>Post a pic and mention <span>@dashingdish</span> or <span>#dashingdish!</span></p>
                  </div>
                  <h2>Share this recipe</h2>
                  <SocialIcon
                    slug={activeRecipe.slug}
                    feature="recipe"
                    img={
                      activeRecipe && activeRecipe.images
                        ? activeRecipe.images.full_lg
                          ? activeRecipe.images.full_lg
                          : defaultShareImage
                        : defaultShareImage
                    }
                    title={activeRecipe.title}
                    description={
                      !isNil(activeRecipe.story) ? activeRecipe.story : ""
                    }
                  />
                </div>
              </Col>
            </TransitionTwo>
          </Row>
        </div>
      </section>
    );
  }
};
export default SingleRecipe;
