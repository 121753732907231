import React, { useState, useEffect } from "react";
import { trim, join, filter, isNil, isEmpty } from "lodash";
import take from "lodash/take";
import type, { groceryList } from "../../../types";
import cx from "classnames";
import Button from "../../common/button";
import LinkButton from "../../common/linkButton";
import getUserPlanType from "../../../helpers/getUserPlanType";
import Export from "./export";

import { Tooltip } from "react-tippy";

// This component adds the exports

type Props = {
  groceryListSelectedIngredients: Array<Object>,
  activeGroceryType: string,
  changeGroceryListViewType: (string) => void,
  deleteIngredientInGroceryList: (Array<number>) => void,
  clearAllGroceryListItems: () => void,
  groceryList: groceryList,
};

const GroceryListActions = React.forwardRef((props, ref) => {
  const isPremiumPlan = () =>
    !isNil(props.userDetails) &&
    !isNil(props.userDetails.details) &&
    !isEmpty(props.userDetails.details) &&
    (getUserPlanType(props.userDetails) === "premiumPlan" ||
      getUserPlanType(props.userDetails) === "grandfather");
  const isClearSelectedDisabled =
    props.groceryListSelectedIngredients.length === 0 ? "hide" : "show";

  const [showConfirmPopover, setShowConfirmPopover] = useState(false);
  useEffect(() => {
    setShowConfirmPopover(false);
  }, [props.progressStatus.clearGroceryItems.isProgressed]);
  // returns all item in the caegory of grocery-list in an array
  const isAmountMixedDecimal = (grocery) => {
    const splitGrocery = grocery.split(" ");
    // Check first character of 2nd item is equal to a number
    return splitGrocery.length >= 2 && !isNaN(splitGrocery[1][0]);
  };
  const extractAmountStringFromCompleteName = (grocery) =>
    trim(grocery.split(" ")[0]);
  const extractItemStringFromCompleteName = (grocery) => {
    const isMixedDecimal = isAmountMixedDecimal(grocery);
    return trim(
      join(
        filter(grocery.split(" "), (grocery, index) => {
          if (index === 0 || (isMixedDecimal && index === 1)) {
            return false;
          } else {
            return true;
          }
        }),
        " "
      )
    );
  };
  const mappedItemsData = (
    (props.groceryList && props.groceryList.categories) ||
    []
  ).reduce((outerAccumulator, category) => {
    category.items.forEach((item) => {
      outerAccumulator.push({
        raw: {
          name: ` ${extractAmountStringFromCompleteName(
            item.item
          )} ${extractItemStringFromCompleteName(item.item)}`,
        },
      });
    });
    return outerAccumulator;
  }, []);

  const clearExportedItems = () => {
    const mappedItemIds = (
      (props.groceryList && props.groceryList.categories) ||
      []
    ).reduce((outerAccumulator, category) => {
      category.items.forEach((item) => {
        outerAccumulator = outerAccumulator.concat(item.id);
      });
      return outerAccumulator;
    }, []);
    props.deleteIngredientInGroceryList(take(mappedItemIds, 70));
  };

  return (
    <div className="actions">
      <div className="left-group">
        <LinkButton
          renderValue={() => <>Shop by Aisle</>}
          additionalClass={cx({
            "active-list": props.activeGroceryType === "aisle" && "active",
            "unactive-list": !(props.activeGroceryType === "aisle" && "active"),
          })}
          onClick={() => {
            props.changeGroceryListViewType("aisle");
          }}
        />
        <LinkButton
          renderValue={() => <>Shop by Recipe</>}
          additionalClass={cx({
            "active-list": props.activeGroceryType === "recipe" && "active",
            "unactive-list": !(
              props.activeGroceryType === "recipe" && "active"
            ),
          })}
          onClick={() => {
            props.changeGroceryListViewType("recipe");
          }}
          isLoading={false}
        />
      </div>
      <div className="right-group">
        {isPremiumPlan() && (
          <>
            <Tooltip
              multiple={true}
              open={showConfirmPopover}
              arrow={true}
              position="bottom-start"
              trigger="click"
              theme="light"
              interactive={true}
              onRequestClose={() => setShowConfirmPopover(false)}
              style={{ display: "flex" }}
              className=""
              html={
                <div className="Addgroceries">
                  <div className="groceriesHead">
                    <h3>Would you like to clear your grocery</h3>
                  </div>
                  <div className="groceriesFooter">
                    <Button
                      btnClass="primary btn-full"
                      btnText="Confirm"
                      onClick={() => {
                        props.clearAllGroceryListItems();
                      }}
                      isLoading={
                        props.progressStatus.clearGroceryItems.isProgressing
                      }
                    />
                  </div>
                </div>
              }
            >
              <LinkButton
                renderValue={() => <>Clear All</>}
                onClick={() => setShowConfirmPopover(true)}
              />
            </Tooltip>

            <LinkButton
              href={"/grocery-list/print"}
              renderValue={() => (
                <a
                  href={"/grocery-list/print"}
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/grocery-list/print");
                  }}
                >
                  Print
                </a>
              )}
            />
            {/* <Export
              groceryList={props.groceryList}
              clearAllGroceryListItems={props.clearAllGroceryListItems}
              mappedItemsData={mappedItemsData}
              clearExportedItems={clearExportedItems}
              showToastMessage={props.showToastMessage}
            /> */}
          </>
        )}
      </div>
    </div>
  );
});

export default GroceryListActions;
