import { fromJS, List } from "immutable";
import Fraction from "fraction.js";
import { getFractionFromString } from "./converters";
import isNaN from "lodash/isNaN";
import isEmpty from "lodash/isEmpty";
import getServingCountFromActiveVariant from "./getServingCountFromActiveVariant";

const calculateIngredients = ({
  recipe,
  variation = 0,
  servingSize: cServ,
}) => {
  if (recipe && recipe.variations) {
    const variations =
      typeof recipe.variations === "string"
        ? JSON.parse(recipe.variations)
        : recipe.variations;

    const actualServingSize = getServingCountFromActiveVariant(
      variations[0].nutrition
    );

    const groups = fromJS(variations[0].ingredients.groups);

    const newGroups = groups.toSeq().reduce((outerAccumulator, group) => {
      const newIngredients = group
        .getIn(["lines"])
        .toSeq()
        .reduce((innerAccumulator, ingredient, index) => {
          // for dynamic amount
          const modifiedAmount = getFractionFromString(
            ingredient.getIn(["amount"])
          );

          let defServing;
          // Recipes serving size
          // console.log('modified amount ', modifiedAmount, actualServingSize)

          if (actualServingSize === null) {
            defServing = 4;
          } else {
            defServing = parseInt(actualServingSize);
          }

          // If something changes out to be NaN change it to 1
          if (isNaN(parseInt(defServing))) {
            defServing = 1;
          }

          if (isNaN(parseInt(cServ))) {
            cServ = 1;
          }
          // Calculate the serving sizes as per the global field
          const servingValue = defServing / cServ;

          if (
            !isNaN(parseInt(modifiedAmount[0])) &&
            !modifiedAmount.includes("-")
          ) {
            try {
              const remodifedAmount = new Fraction(modifiedAmount);

              let dynamicAmount = eval(remodifedAmount) / servingValue;

              let fractionAmount = new Fraction(dynamicAmount);

              let amount = ingredient
                .getIn(["amount"])
                .replace(
                  getFractionFromString(ingredient.getIn(["amount"])),
                  fractionAmount.toFraction(true)
                );

              ingredient = ingredient.setIn(
                ["amount"],
                amount.replace("NaN/NaN", "")
              );

              // innerAccumulator = innerAccumulator.push(ingredient);
              outerAccumulator = outerAccumulator.push(
                fromJS({
                  category_id: ingredient.getIn(["category"]),
                  item: `${amount.length > 0 ? amount : ""} ${ingredient.getIn([
                    "item",
                  ])}`,
                  recipe_id: recipe.id,
                })
              );
            } catch (e) {
              let amount = ingredient.getIn(["amount"]);

              outerAccumulator = outerAccumulator.push(
                fromJS({
                  category_id: ingredient.getIn(["category"]),
                  item: `${amount.length > 0 ? amount : ""} ${ingredient.getIn([
                    "item",
                  ])}`,
                  recipe_id: recipe.id,
                })
              );
            }

            return innerAccumulator;
          } else if (modifiedAmount.includes("-")) {
            try {
              const firstValue =
                new Fraction(modifiedAmount.split("-")[0]) / servingValue;
              const secondValue =
                new Fraction(modifiedAmount.split("-")[1]) / servingValue;

              ingredient = ingredient.setIn(
                ["amount"],
                `${firstValue}-${secondValue}`
              );
              const amount = `${firstValue}-${secondValue}`;
              outerAccumulator = outerAccumulator.push(
                fromJS({
                  category_id: ingredient.getIn(["category"]),
                  item: `${amount.length > 0 ? amount : ""} ${ingredient.getIn([
                    "item",
                  ])}`,
                  recipe_id: recipe.id,
                })
              );
            } catch (e) {
              let amount = ingredient.getIn(["amount"]);

              outerAccumulator = outerAccumulator.push(
                fromJS({
                  category_id: ingredient.getIn(["category"]),
                  item: `${amount.length > 0 ? amount : ""} ${ingredient.getIn([
                    "item",
                  ])}`,
                  recipe_id: recipe.id,
                })
              );
            }

            return innerAccumulator;
          } else {
            const amount = ingredient.getIn(["amount"]);
            outerAccumulator = outerAccumulator.push(
              fromJS({
                category_id: ingredient.getIn(["category"]),
                recipe_id: recipe.id,
                item: `${amount.length > 0 ? amount : ""} ${ingredient.getIn([
                  "item",
                ])}`,
              })
            );
            return innerAccumulator;
          }
        }, List());

      // group = group.setIn(['lines'], newIngredients)
      // outerAccumulator = outerAccumulator.push(group);
      return outerAccumulator;
    }, List());

    return newGroups.toJS();
  } else {
    return [];
  }
};

export default calculateIngredients;
