import createReducer from "../helpers/createReducers";

import { fromJS } from "immutable";

import type
{
  recipeType,
  relatedRecipeType
} from '../types'

// Change activeRecipe.details 
// 
const initialState = fromJS({
  activeRecipe: {}, // Specifies the active recipe page and its associated data
  activeVariationId: null, // Specifies the active variation which is selected. Initially it is null, for first fetch the default value is variations[0]
  relatedRecipe: [],
  categories: [],
  fetchRecipeSlug: null,
  forUser: null
});

export default createReducer(initialState, {
  // to fix persistant single recipe bug when user logges out then logges in
  LOGOUT_NAVBAR_SUCCESS: (state, action) => {
    state = state.setIn(['fetchRecipeSlug'], null)
    return state
  },
  // to fix persistant single recipe bug when user logges out then logges in
  LOGIN_USER_SUCCESS: (state, action) => {
    state = state.setIn(['fetchRecipeSlug'], null)
    return state
  },

  // TODO : changed action name after rebase
  ALTER_VARIATION_FOR_ACTIVE_RECIPE: (state, action: {
    payload: {
      activeVariationId: string,
    }
  }) => {
    state = state.setIn(['activeVariationId'], action.payload.activeVariationId);
    state = state.setIn(['recipe', 'stackTopObjectActiveVariationIndex'], action.payload.stackTopObjectActiveVariationIndex);
    return state;
  },

  // For fav icon reactivity in single recipe page
  CREATE_FAVOURITE_TO_OBJECT_SUCCESS: (state, action) => {
    return state.setIn(['activeRecipe', 'favorite'], true)
  },

  // For fav icon reactivity in single recipe page
  DELETE_FAVOURITE_SUCCESS: (state, action) => {
    return state.setIn(['activeRecipe', 'favorite'], false)
  },

  CREATE_COMMENT_TO_OBJECT_SUCCESS: (state, action) => {
    if (action.payload.objectType == 'recipe') {
      // for removing rating input stars is user gives rating
      let newComment = fromJS(action.payload.commentObject)
      if (newComment.getIn(['rating']) !== null) {
        state = state.setIn(['activeRecipe', 'is_rated'], true)
      }
      // for updating recipe rating reactivily
      let updatedRating = fromJS(action.payload.updatedRating)
      if (updatedRating !== null) {
        state = state.setIn(['activeRecipe', 'avg_rating'], updatedRating)
      }
    }
    return state
  },

  POP_OBJECT_FROM_NAVIGATION_STACK_SUCCESS: (state, action) => {
    if (action.payload.objectType == 'recipe') {
      state = state.setIn(['activeRecipe'], fromJS(action.payload.activeObject.activeRecipe));
      state = state.setIn(['activeVariationId'], action.payload.activeObject.activeVariationId);
      state = state.setIn(['relatedRecipe'], fromJS(action.payload.activeObject.relatedRecipe));
      state = state.setIn(['categories'], action.payload.activeObject.categories);
      state = state.setIn(['fetchRecipeSlug'], action.payload.activeObject.fetchRecipeSlug);
      state = state.setIn(['forUser'], action.payload.activeObject.forUser);
    }
    return state
  }
});
