import React from 'react'

const Placeholder = ({
  styles
}) => {
  const defaultStyles = {
    backgroundColor: '#f7f7f7',
    borderRadius: 10,
  }

  return (
    <div style={{
      ...styles,
      backgroundColor: styles.backgroundColor || defaultStyles.backgroundColor,
      borderRadius: styles.borderRadius || defaultStyles.borderRadius,
    }}>
    </div>
  )
}


export default Placeholder