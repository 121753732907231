import React from 'react';
import cx from 'classnames';

import '../styles/common.scss';

export const Checkbox = (props) =>  {
  const {
    type,
    label,
    checked,
    additionalWrapperClass,
    additionalInputClass
  } = props
  return (
    <span 
      className={
        cx(
          'checkboxField',
          additionalWrapperClass,
        )
      }
      onClick={() => props.onClick(!checked)}
    >
      <input
        className={
          cx(
            'checkbox',
            additionalInputClass,
          )
        }
        type={type}
        label={label}
        checked={checked}
      />
      {label}
    </span>
  );
}

Checkbox.defaultProps = {
  type: 'checkbox',
}

export default Checkbox;

