import React from "react";
import Modal from "react-modal";
import cx from "classnames";

import CloseIcon from "../images/closeIcon";

const Modalwrapper = (props) => {
  const customStyles = {
    content: {
      position: "static",
      // position: 'fixed',
      top: "0",
      left: "0",
      maxHeight: props.maxHeight,
      minHeight: props.minHeight,
      maxWidth: props.maxWidth,
      margin: props.margin,
      padding: "0px",
      bottom: "0",
      right: "0",
      overflow: props.overflow,
      overflowY: props.overflowY,
      borderRadius: "8px",
      backgroundColor: "#ffffff",
      outline: "none",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.04)",
      border: "none",
      zIndex: "9999",
      WebkitOverflowScrolling: "touch",
      height: props.height,
    },
  };
  return (
    <Modal
      style={customStyles}
      isOpen={props.isOpen}
      ariaHideApp={false}
      onRequestClose={props.onRequestClose}
    >
      {props.showClose ? (
        <div
          onClick={() => {
            props.onRequestClose();
          }}
          className={cx("modal-close-wrapper", props.additionalClass)}
        >
          {props.showClose ? (
            <CloseIcon
              onClick={() => {
                props.onRequestClose();
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      {props.children}
    </Modal>
  );
};

Modalwrapper.defaultProps = {
  minHeight: "240px",
  maxWidth: "600px",
  maxHeight: "80vh",
  height: "auto",
  overflowY: "scroll visible",
  borderRadius: "8px",
  showClose: "false",
  overflow: "visible",
  additionalClass: "",
  onRequestClose: () => {},
  margin: null,
};

export default Modalwrapper;
