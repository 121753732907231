import React, { Component } from "react";
import Slider from "react-slick";

export const SliderSlick = (props) => {
  return (
    <Slider
      dots={true}
      infinite={true}
      initialSlide={0}

      slidesToShow={2}
      slidesToScroll={2}
      // variableWidth={true}
      // adaptiveHeight={true}
      autoplay={false}

      arrows={false}
      rtl={false}
      draggable={false}
      lazyLoad="anticipated"
      responsive={[
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,

          }

        },
        {

          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1

          }

        },
        {

          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }

        }]}

    >
      {props.children}
    </Slider >
  )
}
export default SliderSlick;