import axios from 'axios';
import { baseUrl } from './api';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import map from 'lodash/map';
import join from 'lodash/join';
import { useReducer, useEffect } from 'react';

import { fromJS } from 'immutable'

import { generateRecipeQueryFromFilter, generateRecipeQueryFromFilterForFrontend, generateWorkoutQueryFromFilter } from './filterConversions'
import { removeCharecterFromIndex } from './converters'

let debouncer;
const initialState = {
  dataType: 'all', // 'all' / 'favorite' / 'filter',
  search: '',
  searchBy: 'title',
  isFetching: false,
  data: [],
  limit: 25,
  offset: 0,
  hasMore: true,
  newBatch: true,
  queryString: '',
  favoriteSlug: null,
  order: 'desc',
  force: false,
  filterSlugs: [],
  fp: [0, 15],
  sp: [0, 15],
  wwp: [0, 15]
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FILTER':
      const {
        previouslySelected,
        appFilter,
        filterType
      } = action.payload;

      if (previouslySelected) {
        return {
          ...state,
          newBatch: true,
          force: state.order === 'rand',
          filterSlugs: filter(state.filterSlugs, filter => filter.slug !== appFilter.slug)
        }
      } else {
        return {
          ...state,
          newBatch: true,
          force: state.order === 'rand',
          filterSlugs: [
            ...state.filterSlugs,
            { type: filterType, slug: appFilter.slug, name: appFilter.name }
          ]
        }
      }
    case 'UPDATE_FILTER_COMPLETELY':
      return {
        ...state,
        newBatch: true,
        force: state.order === 'rand',
        filterSlugs: action.payload.updatedFilters
      }
    case 'SET_SEARCH':
      return {
        ...state,
        dataType: 'filter',
        favorite: false,
        favoriteSlug: null,
        newBatch: true,
        offset: 0,
        filterSlugs: [],
        fp: [0, 15],
        sp: [0, 15],
        wwp: [0, 15],
        order: 'desc',
        search: action.payload.search,
        searchBy: action.payload.searchBy
      }
    case 'CHANGE_ORDER':
      return {
        ...state,
        newBatch: true,
        force: state.order === 'rand',
        order: action.payload.order
      }
    case 'FETCH_NEW_DATA':
      const {
        queryString,
        favorite = false
      } = action.payload
      return {
        ...state,
        data: state.newBatch ? [] : state.data,
        offset: state.newBatch ? 0 : state.offset,
        isFetching: true,
        queryString,
        favorite,
        dataType: favorite ? 'favorite' : 'all'
      }
    case 'FETCH_NEW_DATA_SUCCESS':
      return {
        ...state,
        data: state.offset === 0 ? action.payload.recipes : [...state.data, ...action.payload.recipes],
        isFetching: false,
        newBatch: false,
        hasMore: action.payload.recipes.length >= state.limit,
        force: false
      }
    case 'UPDATE_WEIGHTWATCHER':

      return {
        ...state,
        newBatch: true,
        [action.payload.weightWatcherTitle]: [0, action.payload.weightWatcherValue]
      }
    case 'UPDATE_OFFSET':
      return {
        ...state,
        newBatch: false,
        offset: state.offset + state.limit
      }
    case 'CHANGE_DATA_TYPE_TO_FAVORITE':
      return {
        ...state,
        dataType: 'favorite',
        favoriteSlug: action.payload.favoriteSlug,
        force: action.payload.force,
        newBatch: true,
        isFetching: true,
        hasMore: true,
        data: []
      }
    case 'RESET_TO_INITIAL_STATE':
      return initialState
    default:
      return state;
  }
}

export default ({
  token,
  recipeFilterGroup,

  // filterSlugs
}) => {

  const [state, dispatch] = useReducer(reducer, initialState);


  const fetchRecipes = () => {
    if (state.dataType === 'filter' || state.dataType === 'all') {
      const foodTypeFilters = map(filter(state.filterSlugs, filter => filter.type === 'foodTypes'), filter => filter.slug);
      const dietaryFilters = map(filter(state.filterSlugs, filter => filter.type === 'dietaries'), filter => filter.slug);
      const specialityFilters = map(filter(state.filterSlugs, filter => filter.type === 'specialities'), filter => filter.slug);

      const filters = {
        foodTypes: foodTypeFilters,
        dietaries: dietaryFilters,
        specialities: specialityFilters,
        collection: [],
        fp: state.fp,
        sp: state.sp,
        wwp: state.wwp,
        search: []
      }

      const {
        foodTypes,
        dietaries,
        specialities
      } = recipeFilterGroup;

      let recipeQueryFilter = generateRecipeQueryFromFilter({
        ...filters,
        search: [state.search], // Append search to get the url
        search_by: [state.searchBy],
        searchBy: [state.searchBy]
      }, fromJS(foodTypes), fromJS(dietaries), fromJS(specialities), fromJS());

      let finalQueryParam = recipeQueryFilter;
      if (recipeQueryFilter.charAt(0) === '&') {
        finalQueryParam = removeCharecterFromIndex(finalQueryParam, 0)
      }
      const apiUrl = `${baseUrl}/api/recipes?limit=${state.limit}&offset=${state.offset}&order=${state.order}&${finalQueryParam}`;

      const queryString = generateRecipeQueryFromFilterForFrontend(fromJS({
        ...filters,
        search: [state.search], // Append search to get the url
        search_by: [state.searchBy],
        searchBy: [state.searchBy]
      }));

      dispatch({
        type: 'FETCH_NEW_DATA',
        payload: {
          queryString,
        }
      })

      axios(apiUrl)
        .then((response) => {
          dispatch({
            type: 'FETCH_NEW_DATA_SUCCESS',
            payload: {
              recipes: response.data.recipes
            }
          })
        });

    } else if (state.dataType === 'favorite') {
      dispatch({
        type: 'FETCH_NEW_DATA',
        payload: {
          favorite: true,
          dataType: 'favorite'
        }
      })
      axios({
        url: `${baseUrl}/api/v1/favorites/?object_type=recipe&${state.favoriteSlug ? `board_id=${state.favoriteSlug}` : ''}&per_page=25&page=${(state.offset / state.limit)}`,
        headers: {
          Authorization: `Token token="${token}"`
        },
      })
        .then((response) => {
          dispatch({
            type: 'FETCH_NEW_DATA_SUCCESS',
            payload: {
              recipes: map(response.data.items, item => item.recipe)
            }
          })
        });
    }
  }


  useEffect(() => {
    if (state.dataType === 'favorite' || state.order === 'rand') {
      if (state.force) {
        fetchRecipes()
      }
    } else {
      fetchRecipes()
    }

    // fetchRecipes()

  }, [
    state.dataType,
    state.filterSlugs.length,
    state.offset,
    state.search,
    state.searchBy,
    state.order,
    state.fp[1],
    state.sp[1],
    state.wwp[1],
    state.order,
    state.favoriteSlug,
    state.force
  ])

  return [
    state,
    dispatch
  ];
}