import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { showToastMessage, showGroceryListLimitNotification } from '../actions'
import { remove } from '../helpers/requests'
import moment from 'moment'
import { Iterable } from 'immutable'

import concat from 'lodash/concat'
import map from 'lodash/map'
import filter from 'lodash/filter'
import toString from 'lodash/toString'
import isNil from "lodash/isNil";
import groceryTask from './subtask/grocery'
import { getToken, getCalendarData } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import { getCategoryId } from '../helpers/categories'

function* task(action) {
  try {
    const limit = 500;
    const token = yield select(getToken);
    const calendarListData = yield select(getCalendarData);

    const items = calendarListData
      .toSeq()
      .reduce((accumulator, eachDay) => {
        const rangeDate = eachDay.getIn(['date']);

        if (moment(rangeDate).isBetween(
          moment().startOf('day').format('YYYY-MM-DD'),
          moment().startOf('day').add({ days: 14 }).format('YYYY-MM-DD'),
          null,
          '[)'
        )) {
          const mealsForDay = eachDay.getIn(['meals']);

          mealsForDay
            .toSeq()
            .forEach(meal => {

              let ingredients = (meal.getIn(['recipe', 'ingredients_v2']))
              if (ingredients && Iterable.isIterable(ingredients)) {
                ingredients = ingredients.toJS();


                const recipeId = meal.getIn(['recipe', 'id']);
                accumulator = concat(accumulator, map(filter(ingredients, ingredient => !ingredient.header), ingredient => {

                  let itemName = ingredient.ingredient?.display_singular_name ? ingredient.ingredient?.display_singular_name : '';
                  let amount = ingredient.ingredient_unit?.display_singular_name ? ingredient.ingredient_unit?.display_singular_name : '';
                  let quantity = ingredient.quantity ? ingredient.quantity : '';

                  let addIngredientName = `${quantity} ${amount} ${itemName}`;

                  return {
                    item: addIngredientName,
                    category_id: getCategoryId(ingredient?.ingredient?.category),
                    recipe_id: recipeId,
                    ingredient_name: itemName,
                    amount: toString(quantity),
                    ingredient_unit: toString(amount)
                  }
                }));
              }
            })
        }

        return accumulator
      }, [])

    let addResponse = yield call(groceryTask.addToGroceryList, { items: items, limit: limit });

    if (!isNil(addResponse.isLimitExceeded) && addResponse.isLimitExceeded) {
      yield put(showGroceryListLimitNotification(addResponse.items));
    } else {
      yield put({
        type: actions.ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_SUCCESS,
        payload: {
          addedIngredients: addResponse.data.new_items
        }
      })


      yield put(showToastMessage(`✅ All ingredients in the meal plan added to groceries.`, 'success', 'addIngredient'));
    }

  } catch (e) {
    console.log(e);
    yield put(showToastMessage('❗️Error occured while adding ingredient to groceries.', 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_FAILURE,
    })
  }
}

export default function* addIngredientsInGroceryList() {
  yield takeEvery(actions.ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST, task)
}