import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import toJS from '../../helpers/toJS'
import mealPlan from './components/mealPlan';

import {
  appEssentialFetch,
  changeCalendarActiveDate,
  changeCalendarActiveMonth,
  addMealInMealPlan,
  deleteMealFromMealPlan,
  moveMealInMealPlan,
  addIngredientsOfDayToGroceryList,
  addIngredientsOfRangeToGroceryList,
  duplicateMeal,
  addMealPlanToCalendar,
  createFavourite,
  deleteFavourite,
  addCustomRecipeToMealPlan,
  clearMealPlanDay,
  saveCalendarDay,
  addSavedDay,
  updateCalendarSettings,
  search,
  addSentryError,
} from '../../actions';

import { getUserDetails } from "../../selectors";

import {
  getRecipesSearchList,
  getRecipesSearchCount,
  getRecipeBoards,
  getRecipeFilterName
} from "../../selectors/recipes";

import {
  getCalendarSettings,
  getCalendarDates,
  calendarListData,
  getRecipesFoodTypes,
  getRecipesDietaries,
  getRecipesSpecialities,
  getStatisticsAverage,
  getFetchStatus,
  getWorkoutTypes,
  getWorkoutEquipments,
  getWorkoutBodyParts,
  getWorkoutDifficulty,
  getWorkoutTime,
  getCalendarMacros,
  getProgressStatus,
  getCalendarView,
} from '../../selectors/calendar'

import {
  getWorkoutBoards,
  getWorkoutFilterName,
  getWorkoutsSearchCount,
  getWorkoutsSearchList
} from '../../selectors/workout';

const mapStateToProps = state => ({
  userDetails: getUserDetails(state),
  calendarSettings: getCalendarSettings(state),
  calendarDates: getCalendarDates(state),
  calendarListData: calendarListData(state),
  foodTypes: getRecipesFoodTypes(state),
  dietaries: getRecipesDietaries(state),
  specialities: getRecipesSpecialities(state),
  workoutTypes: getWorkoutTypes(state),
  workoutEquipments: getWorkoutEquipments(state),
  workoutBodyParts: getWorkoutBodyParts(state),
  workoutDifficulty: getWorkoutDifficulty(state),
  workoutTime: getWorkoutTime(state),
  averageData: getStatisticsAverage(state),
  fetchStatus: getFetchStatus(state),
  workoutTypes: getWorkoutTypes(state),
  workoutEquipments: getWorkoutEquipments(state),
  workoutBodyParts: getWorkoutBodyParts(state),
  difficulty: getWorkoutDifficulty(state),
  time: getWorkoutTime(state),
  setMacros: getCalendarMacros(state),
  progressStatus: getProgressStatus(state),
  calendarView: getCalendarView(state),
  searchList: getRecipesSearchList(state),
  recipesSearchCount: getRecipesSearchCount(state),
  boards: getRecipeBoards(state),
  recipeFilterName: getRecipeFilterName(state),
  workoutBoards: getWorkoutBoards(state),
  workoutsSearchCount: getWorkoutsSearchCount(state),
  getWorkoutFilterName: getWorkoutFilterName(state),
  workoutSearchList: getWorkoutsSearchList(state),
})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  changeCalendarActiveDate: bindActionCreators(changeCalendarActiveDate, dispatch),
  changeCalendarActiveMonth: bindActionCreators(changeCalendarActiveMonth, dispatch),
  addMealInMealPlan: bindActionCreators(addMealInMealPlan, dispatch),
  deleteMealFromMealPlan: bindActionCreators(deleteMealFromMealPlan, dispatch),
  duplicateMeal: bindActionCreators(duplicateMeal, dispatch),
  moveMealInMealPlan: bindActionCreators(moveMealInMealPlan, dispatch),
  addIngredientsOfDayToGroceryList: bindActionCreators(addIngredientsOfDayToGroceryList, dispatch),
  addIngredientsOfRangeToGroceryList: bindActionCreators(addIngredientsOfRangeToGroceryList, dispatch),
  addMealPlanToCalendar: bindActionCreators(addMealPlanToCalendar, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  addCustomRecipeToMealPlan: bindActionCreators(addCustomRecipeToMealPlan, dispatch),
  clearMealPlanDay: bindActionCreators(clearMealPlanDay, dispatch),
  saveCalendarDay: bindActionCreators(saveCalendarDay, dispatch),
  addSavedDay: bindActionCreators(addSavedDay, dispatch),
  updateCalendarSettings: bindActionCreators(updateCalendarSettings, dispatch),
  searchRecipes: bindActionCreators(search, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(mealPlan));