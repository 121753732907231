
import { select, put, take, call, all } from 'redux-saga/effects'

import {
  showToastMessage,
  changeFooterStatus
} from '../../actions'

import {
  getAuthStatus,
  getToken
} from '../selectors'

import usersTask from '../subtask/users'

import removeToken from './removeToken'
import * as actions from '../../actions/actionTypes'
import lockMembership from './lockMembership'
import retrier from './retrier'

export default function* authenticate() {
  let loggedInStatus = yield select(getAuthStatus);
  let token = yield select(getToken);

  // console.log('token ', token)
  // Only for web.
  if (!token) {
    yield call(removeToken)
    yield put({
      type: actions.USER_AUTHENTICATE_FAILURE,
    })
  }

  // Attempt login For both web and mobile
  if (
    token && // Token is present already in the redux store
    !loggedInStatus.getIn(['isLoggedIn']) // If user is not already logged in
    && !loggedInStatus.getIn(['isLogginFailed'])) { // If user has no login related errors

    yield put({
      type: actions.USER_AUTHENTICATE,
    })
    try {
      const userDataArray = yield call(retrier, [call(usersTask.get)], 'authenticate');

      userData = userDataArray[0];
      if (!userData) {
        const eventId = pushToSentry('Internet connection seems to be down')
        yield put({
          type: actions.ADD_SENTRY_ERROR,
          payload: {
            error: 'Internet connection seems to be down',
            eventId
          }
        })
      }
      if (
        userData.isLoggedIn
      ) {

        // TODO: Add the locking case for IOS
        const isLocked = yield call(lockMembership, userData)
        const isUserValid = (
          userData.details.account_status === 'active' ||
          userData.details.account_status === 'trialing' ||
          userData.details.account_status === 'past_due'
        ) && !isLocked; // lock only for mobile and paypal

        if (isUserValid) {

          yield put({
            type: actions.USER_AUTHENTICATE_SUCCESS,
            payload: {
              userData,
            }
          })
        } else {
          yield call(removeToken)
          yield put(showToastMessage('❗️Your account might have expired. Please try login to proceed'));

          yield put({
            type: actions.USER_AUTHENTICATE_FAILURE,
          })
        }

      } else {
        yield call(removeToken)
        yield put({
          type: actions.USER_AUTHENTICATE_FAILURE,
        })
      }

    } catch (e) {
      yield call(removeToken)
      yield put({
        type: actions.USER_AUTHENTICATE_FAILURE,
      })
    }
  } else if (token && !loggedInStatus.getIn(['isLoggedIn'])) {
    yield call(removeToken)
    yield put({
      type: actions.USER_AUTHENTICATE_FAILURE,
    })
  }
}
