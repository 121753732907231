import React from 'react';
import styled from 'styled-components';

const StyledFloatingButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 160px;
  height: 30px;
  background-color:#FF3188;
  color: white;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  transition: transform 0.2s ease-in-out;
  font-weight: 600;

  &:hover {
    transform: scale(1.1);
    background-color: #FF3188;
  }

  &:active {
    transform: scale(0.95);
  }
`;

const FloatingButton = ({ onClick, children, ...props }) => {
  return (
    <StyledFloatingButton onClick={onClick} {...props}>
      {children}
    </StyledFloatingButton>
  );
};

export default FloatingButton; 