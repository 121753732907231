import { put, call, select, all } from 'redux-saga/effects'

import * as actions from '../actions/actionTypes'
import blogTask from './subtask/blogs'
import pushToSentry from '../helpers/pushToSentry';

import retrier from './process/retrier'

export default function* fetchBlogDetails(action) {

  // yield takeEvery(actions.GET_SINGLE_RECIPE_DETAILS, task)
  try {
    const {
      payload: {
        blogSlug,
      }
    } = action;

    // If this blog is already present in the list
    let blogData;

    const blogArray = yield call(retrier, [call(blogTask.get, blogSlug)], 'single blog');

    const blogResponse = blogArray[0]

    if (!blogResponse) {
      const eventId = pushToSentry('Internet connection seems to be down - single blog')
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: 'Internet connection seems to be down',
          eventId
        }
      })
    }

    if (blogResponse.status === 403) {
      return {
        limitReached: true,
        error: 'Limit reached',
        title: blogResponse.data.title,
        images: blogResponse.data.images
      }
    }


    blogData = blogResponse.data.blogs;
    relatedBlogs = blogResponse.data.related_blogs;
    categories = blogResponse.data.categories;

    return {
      objectType: 'blog',
      data: blogData, // Will have recipes, foodTypes and related recipes
      relatedBlogs,
      categories,
    };
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.GET_SINGLE_BLOG_DETAILS_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}
