import React from 'react';
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const WorkoutAboutIcon = () => {
    return (
        <svg width="68" height="59" viewBox="0 0 68 59" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path d="M30.9503 6.77936C27.7563 3.19391 23.12 1.03027 18.1539 1.03027C8.69573 1.03027 1.03027 10.4678 1.03027 19.926C1.03027 40.6351 29.6109 57.4703 34 57.4703C38.3891 57.4703 66.9697 40.6351 66.9697 19.926C66.9697 10.4472 59.3042 1.03027 49.8254 1.03027C42.8812 1.05088 36.6375 5.25452 34 11.663" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M51.3503 32.1248H46.2812C45.4775 32.1248 44.6739 31.486 44.4884 30.7236L43.726 27.5502C42.7369 23.4908 41.1503 23.4702 40.1612 27.5502L37.9357 36.7611C36.9672 40.7587 35.36 40.7587 34.3709 36.7817L30.6 21.6981C29.6315 17.8242 28.0242 17.8036 27.0351 21.6981L24.7272 30.7236C24.5418 31.486 23.7381 32.1042 22.9551 32.1042H16.6497" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                {/* <clipPath id="clip0">
                    <rect width="68" height="58.5006" fill="white" />
                </clipPath> */}
            </defs>
        </svg>

    )
}

export default WorkoutAboutIcon;