import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import toJS from "../../../helpers/toJS";
import { Row, Col } from "react-flexbox-grid";
import Logo from "../../images/Logo";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import TwitterIcon from "../../images/TwitIcon";
import FacebookIcon from "../../images/FacebookIcon";
import InstaIcon from "../../images/InstaIcon";
import PinIcon from "../../images/PinIcon";
import VimeoIcon from "../../images/VimeoIcon";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import axios from "axios";
import { baseUrl } from "../../../helpers/api";
import { showToastMessage } from "../../../actions";

const Footer = (props) => {
  if (props.location.pathname.includes("/print")) {
    return null;
  } else {
    const [state, setState] = useState({
      email: "",
      isSubmitting: false,
    });
    const addMemberToMailchimp = (e) => {
      e.preventDefault();
      if (/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(state.email)) {
        setState({ ...state, isSubmitting: true });
        axios({
          method: "POST",
          url: `${baseUrl}/api/v1/add-to-mailchimp`,
          data: {
            email: state.email,
            type: "newsletter",
          },
        })
          .then((response) => {
            props.showToastMessage(`Email Saved !`, "success");
            setState({ ...state, isSubmitting: false, email: "" });
          })
          .catch((e) => {
            setState({ ...state, isSubmitting: false, email: "" });
            props.showToastMessage(`Something went wrong !`, "error");
          });
      }
    };

    return (
      <footer style={{ position: props.noAbsolute ? "relative" : "absolute" }}>
        <div className="container">
          <Row className="no-margin">
            <Col xs={12} md={12} lg={2} className="logo-wrapper no-padding-sm">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  props.history.push("/");
                }}
                style={{ width: "36px", height: "36px" }}
              >
                <Logo />
              </a>
              <p>
                Dashing Dish, Inc<br></br>© 2010-2023<br></br>
                All Rights Reserved
              </p>
            </Col>
            <Col xs={4} md={4} lg={2} className="no-padding-sm">
              <div className="unstyled-lists">
                <h6>Site Map</h6>
                <ul>
                  <li>
                    <a
                      href={"/"}
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/");
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href={"/about"}
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/about");
                      }}
                    >
                      About Us
                    </a>
                  </li>
                  <li>
                    <a
                      href="/recipes"
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/recipes");
                      }}
                    >
                      Recipes
                    </a>
                  </li>
                  <li>
                    <a
                      href="/workouts"
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/workouts");
                      }}
                    >
                      Workouts
                    </a>
                  </li>
                  <li>
                    <a
                      href="/videos"
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/videos");
                      }}
                    >
                      Videos
                    </a>
                  </li>
                  <li>
                    <a
                      href="/blog/preview"
                      onClick={(e) => {
                        e.preventDefault();
                        props.history.push("/blog/preview");
                      }}
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={4} md={4} lg={2} className="no-padding-sm">
              <h6>Site Map</h6>
              <ul>
                <li>
                  <a
                    href={"/app"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/app");
                    }}
                  >
                    iPhone App
                  </a>
                </li>

                <li>
                  <a
                    href={"/about/my-story"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/about/my-story");
                    }}
                  >
                    Katie’s Story
                  </a>
                </li>
                {/* <li>
                  <a
                    href={"/shop/gift-memberships"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/shop/gift-memberships");
                    }}
                  >
                    Gift Cards
                  </a>
                </li> */}

                <li>
                  <a
                    href={"/shop/books"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/shop/books");
                    }}
                  >
                    Shop Books
                  </a>
                </li>
                <li>
                  <a
                    href={"/dishlist"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/dishlist");
                    }}
                  >
                    Dish List
                  </a>
                </li>
              </ul>
            </Col>
            <Col xs={4} md={4} lg={2} className="no-padding-sm">
              <h6>Support</h6>
              <ul>
                <li>
                  <a
                    href={"/contact"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/contact");
                    }}
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <a
                    href={"/faqs"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/faqs");
                    }}
                  >
                    FAQ’s
                  </a>
                </li>
                <li>
                  <a
                    href={"/get-started"}
                    onClick={(e) => {
                      e.preventDefault();
                      props.history.push("/get-started");
                    }}
                  >
                    Getting Started
                  </a>
                </li>
                <li>
                  <a target="_blank" href={"/privacy-policy"}>
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </Col>

            <Col xs={12} md={12} lg={4} className="form-wrapper no-padding-sm">
              <h6>Get the inside scoop</h6>
              <form>
                <CustomInput
                  value={state.email}
                  type="email"
                  placeholder="Your email"
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                  isLastElementOfForm={true}
                  formLastElementAction={addMemberToMailchimp}
                />
                <Button
                  type="button"
                  btnClass="primary btn btn-md"
                  btnText="Submit"
                  onClick={addMemberToMailchimp}
                  isLoading={state.isSubmitting}
                />
              </form>
              <ul className="social-icon">
                <li>
                  <a
                    href="https://twitter.com/dashingdish?lang=en"
                    target="_blank"
                  >
                    <figure>
                      <TwitterIcon></TwitterIcon>
                    </figure>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/dashingdish/"
                    target="_blank"
                  >
                    <figure>
                      <FacebookIcon></FacebookIcon>
                    </figure>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/dashingdish/?hl=en"
                    target="_blank"
                  >
                    <figure>
                      <InstaIcon></InstaIcon>
                    </figure>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.pinterest.com/dashingdish/"
                    target="_blank"
                  >
                    <figure>
                      <PinIcon></PinIcon>
                    </figure>
                  </a>
                </li>
                <li>
                  <a href="https://vimeo.com/dashingdish" target="_blank">
                    <figure>
                      <VimeoIcon></VimeoIcon>
                    </figure>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        {/* <Helmet>
          <script type="text/javascript">
            {`window.$crisp=[];window.CRISP_WEBSITE_ID="06701846-8837-4a96-a40d-89dd1537ff6c";(function(){ d=document;s=d.createElement("script"); s.src="https://client.crisp.chat/l.js"; s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`}
          </script>
        </Helmet> */}
      </footer>
    );
  }
};

const mapStateToProps = (state) => ({});

const dispatchActionToProps = (dispatch) => ({
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(withRouter(Footer)));
