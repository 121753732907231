import React from 'react';

import reduce from 'lodash/reduce';

import { Iterable } from 'immutable';

const ToJS = (WrappedComponent) => {
  return class ImmutableWrapper extends React.Component {

    constructor(props) {
      super(props);

      this.updateNewProps = this.updateNewProps.bind(this);
    }

    updateNewProps(currentProps) { // eslint-disable-line
      const objecEntries = Object.entries(currentProps);
      return reduce(objecEntries, (newProps, entry) => {
        newProps[entry[0]] = Iterable.isIterable(entry[1]) ? entry[1].toJS() : entry[1]; // eslint-disable-line
        return newProps;
      }, {});
    }


    render() {
      const newProps = this.updateNewProps(this.props);
      return (
        <WrappedComponent
          {...newProps}
        />
      );
    }
  };
};


export default ToJS;
