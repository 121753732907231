import React from 'react'
import trim from 'lodash/trim'
import Button from '../../common/button'
import CustomInput from '../../common/customInput'
import Dropdown from '../../common/dropdown'
import DownArrow from "../../images/DownArrow"
import LinkButton from '../../common/linkButton'
import SelectorIcon from '../../images/SelectorIcon'

const addIngredientModal = ({
  data,
  onAddIngredient,
  setModalData,
  activeGroceryType,
  options,
  setCurrentModal
}) => {
  validated = () => {
    return trim(data.name).length > 0 && trim(data.quantity).length > 0
  }
  return (
    <div className="modalWrapper">
      <div className="modalHeader">
        <h3>Add Ingredient to Grocery List</h3>
      </div>
      <div className="modalBody grocery-body">
        <div className="groceryInputList">
          <CustomInput
            type="text"
            onChange={event => { setModalData('addIngredientModalData', { name: event.target.value }) }}
            value={data.name}
            placeholder="E.g chicken"
            label="Item"
          />
        </div>
        <div className="groceryInputList">
          <CustomInput
            type="text"
            value={data.quantity}
            onChange={event => { setModalData('addIngredientModalData', { quantity: event.target.value }) }}
            placeholder="E.g 1, 1.5, 10 etc"
            label="Qty"
          />
        </div>
        <div className="groceryInputList">
          <CustomInput
            type="text"
            value={data.unit}
            onChange={event => { setModalData('addIngredientModalData', { unit: event.target.value }) }}
            placeholder="E.g cups, oz, etc"
            label="Unit"
          />
        </div>
        <div className="groceryInputList">
          <label className="static-value">Cat.</label>
          <Dropdown
            onChange={value => {
              setModalData('addIngredientModalData',
                activeGroceryType === 'aisle' ? { categoryId: value } : { recipeId: value }
              )
            }
            }
            options={options}
            defaultValue={activeGroceryType === 'aisle' ? data.categoryId : data.recipeId}
          />
          {/* TODO arrow inside component */}
          <SelectorIcon></SelectorIcon>
        </div>
      </div>
      <div className="modalFooter">
        <Button
          disabled={!validated()}
          btnClass="primary btn-full"
          btnText="Save"
          onClick={() => onAddIngredient()}
        />
        <Button
          btnClass="secondary btn-full btn-bp-sm"
          btnText={`Cancel`}
          // additionalClass='btn btn-full'
          onClick={() => setCurrentModal()}
        // isLoading={false}
        // showGhost
        />
      </div>
    </div>
  )
}

export default addIngredientModal