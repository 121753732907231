import React from 'react'
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const TwitOutlineIcon = () => {
  return (
    <svg width="31" height="26" viewBox="0 0 31 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.6957 4.92218L27.087 4.27L28.3913 1.66131L25.1826 2.57435C23.4739 0.983045 20.9826 0.55261 18.8565 1.49174C16.7304 2.43087 15.3348 4.55696 15.3478 6.8787V8.18304C10.7304 9.13522 6.7 6.61783 2.95652 2.31348C2.30435 5.79609 2.95652 8.40478 4.91304 10.1396L1 9.48739C1.52174 12.1874 2.77391 14.3135 6.21739 14.7048L2.95652 16.0091C4.26087 18.6178 6.3087 19.0222 9.47826 19.27C7.01304 21.0309 4.03913 21.9439 1 21.8787C17.6435 29.2744 27.087 18.4091 27.087 8.83522V7.11348L29.6957 4.92218Z" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default TwitOutlineIcon; 