import React, { useEffect } from 'react'
import parse, { domToReact } from "html-react-parser";
import axios from 'axios';
import {
    useQuery,
} from 'react-query';
import moment from 'moment';
import { Row, Col } from "react-flexbox-grid";
import upperCase from 'lodash/upperCase';
import { Facebook, Twitter } from "react-sharingbuttons";
import { personalizedBlogs } from '../../helpers/api';

import { TransitionEaseInOne } from "../common/transitions";
import LeftArrow from '../images/longArrowLeft.png';
import RightArrow from '../images/longArrowRight.png';
import FilesIcon from '../images/filesIcon.png';
import "../styles/common.scss";




const getPersonalizedBlog = async (params) => {
    const response = await axios.get(personalizedBlogs.show(params.queryKey[1]));

    return {
        data: response.data.personalized_blog,
        categories: response.data.taxonomies,
        relatedBlogs: response.data.related_blogs
    }
}

const renderMarkdownContent = (content) => {
    if (content) {
        return parse(content, {
            replace: (attribs, children) => {
                if (attribs.id === "a") {
                    return (
                        <a target="_blank">{domToReact(children, { depth: null })}</a>
                    );
                }
                if (attribs.name === "blockquote") {
                    return (
                        <div className="sharewrapper">
                            <ul className="SocialLink">
                                <li>
                                    <Twitter
                                        // text={`Share on Twitter`}
                                        url={replace(
                                            window.location.href,
                                            "http://localhost:8080",
                                            "https://dashingdish.com"
                                        )}
                                        shareText={
                                            attribs.children && attribs.children.length > 0
                                                ? attribs.children[0].data
                                                : ""
                                        }
                                    />
                                </li>
                                <li>
                                    <Facebook
                                        // text={`Share on Facebook`}
                                        url={replace(
                                            window.location.href,
                                            "http://localhost:8080",
                                            "https://dashingdish.com"
                                        )}
                                        shareText={
                                            attribs.children && attribs.children.length > 0
                                                ? attribs.children[0].data
                                                : ""
                                        }
                                    />
                                </li>
                            </ul>
                            <blockquote>
                                {domToReact(attribs.children, { depth: null })}
                            </blockquote>
                        </div>
                    );
                }
            },
        });
    }
};

const KatiesCornerBlog = (props) => {
    const slug = props.history.location.pathname.replace('/nourish/katies-corner/', '');
    const {
        data,
        error,
        isFetching
    } = useQuery(['personalizedBlog', slug], getPersonalizedBlog, {
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    return !isFetching ? (
        <div>
            <TransitionEaseInOne>
                <section className="home-hero katies-corner-hero blog-list-hero" style={{
                    backgroundImage: `url(${data?.data?.banner})`,
                }}>
                    <div className='container'>
                        <Row className="home-hero-wrapper home-hero-heading no-margin-sm">
                            <Col xs={12} className="hero-container-row no-padding-sm">
                                <div className="home-hero-text">
                                    <p className='hero-katies-corner-legend'>ARTICLE</p>
                                    <h1>
                                        {data?.data?.title}
                                    </h1>
                                </div>

                            </Col>
                        </Row>
                        <div className='categories'>
                            {data?.categories?.map((taxonomy, index) => (
                                <span>
                                    {upperCase(taxonomy?.name)} {
                                        (index !== data.categories.length - 1 ? <span className='separator'>|</span> : null)
                                    }
                                </span>
                            ))}
                        </div>
                    </div>
                </section>
            </TransitionEaseInOne>
            <section className='interiorBlog personalized-blog-interior'>
                <div className='container'>
                    <div className='interiorWrapper personalized-blog-interior-wrapper'>
                        <div className='interiorBlog-content'>
                            {renderMarkdownContent(data?.data?.content || '')}
                            {
                                data.data.attachments?.length > 0 ? (
                                    <div className='blog-attachment-group'>
                                        <h3 className="blog-attachment-header">
                                            Attachments
                                        </h3>
                                        <div className='blog-attachment-container'>
                                            {
                                                data.data.attachments.map((attachment) => (
                                                    <a href={attachment} target='_blank' className="blog-attachment-item">
                                                        <img src={FilesIcon} />
                                                        <span>{attachment.split('/')[attachment.split('/').length - 1].replaceAll('%20', ' ')}</span>

                                                    </a>
                                                ))
                                            }
                                        </div>
                                    </div>
                                ) : null
                            }
                        </div>
                        {
                            data.relatedBlogs.length > 0 ? (
                                <div className='related-blogs'>
                                    <a
                                        href={`/nourish/katies-corner/${data.relatedBlogs[0].slug}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.history.push(`/nourish/katies-corner/${data.relatedBlogs[0].slug}`)
                                        }}
                                    >
                                        <img src={LeftArrow} className='left-arrow' />
                                        {data.relatedBlogs[0].title}
                                    </a>
                                    <a
                                        href={`/nourish/katies-corner/${data.relatedBlogs[1].slug}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            props.history.push(`/nourish/katies-corner/${data.relatedBlogs[1].slug}`)
                                        }}
                                    >
                                        {data.relatedBlogs[1].title}
                                        <img src={RightArrow} className='right-arrow' />
                                    </a>
                                </div >
                            ) : null
                        }


                    </div>
                </div>
            </section>

        </div >
    ) : null
}

export default KatiesCornerBlog