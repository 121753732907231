import React, { useEffect } from "react";
import moment from "moment";
import parse, { domToReact } from "html-react-parser";
import { Row, Col } from "react-flexbox-grid";
import isEmpty from "lodash/isEmpty";
import replace from "lodash/replace";
import ReactMarkdown from "react-markdown";
import { Element } from "react-scroll";
import { Helmet } from "react-helmet";

import { shareUrl } from "../../../../helpers/api";
import { Facebook, Twitter } from "react-sharingbuttons";

import Comments from "../../../common/comments";
import getMarkdownImage from "../../../../helpers/getMarkdownImage";

import convertQuality from "../../../../helpers/convertQuality";
import BlogActions from "../../../common/objectActions";
import AdComponent from "../../../common/ad";
import BlogSideIdea from "./sideIdeas";
import Loader from "../../../images/loader";
import { TransitionOne, TransitionTwo } from "../../../common/transitions";

const commentTextBoxRef = React.createRef();

const SingleBlog = ({
  appEssentialFetch,
  activeBlog,
  sideIdeas,
  history,
  isAuthenticated,
  boards,
  setObjectBoards,
  createFavourite,
  createBoard,
  deleteFavourite,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  userDetails,
  comments,
  sendComment,
  sendReplyComment,
  objectFetchStatus,
  fetchStatus,
  ...props
}) => {
  useEffect(() => {
    appEssentialFetch({
      blog: {
        blogSlug: props.match.params.blogSlug,
      },
    });
  }, [props.location.pathname]);

  if (
    isEmpty(activeBlog) ||
    fetchStatus.startup.isFetching ||
    fetchStatus.userAuthenticate.isFetching ||
    fetchStatus.preFetch.isFetching ||
    objectFetchStatus.isFetching
  ) {
    return (
      <div className="preLoader">
        <Loader></Loader>
      </div>
    );
  }

  const renderBlogMeta = (item) => {
    return (
      <>
        {/* <div className="fav-meta">
          <HeartIcon></HeartIcon>
          {activeBlog.favorite_count ? activeBlog.favorite_count : '-'}
        </div> */}
        <div className="recipe-meta">
          {`${moment(item.updated_at)
            .format("MMMM Do YYYY")
            .replace(/(\d)(st|nd|rd|th)/g, "$1$2")}`}
        </div>
      </>
    );
  };

  const renderMarkdownContent = (content) => {
    if (activeBlog.content) {
      return parse(activeBlog.content, {
        replace: (attribs, children) => {
          if (attribs.id === "a") {
            return (
              <a target="_blank">{domToReact(children, { depth: null })}</a>
            );
          }
          if (attribs.name === "blockquote") {
            return (
              <div className="sharewrapper">
                <ul className="SocialLink">
                  <li>
                    <Twitter
                      // text={`Share on Twitter`}
                      url={replace(
                        window.location.href,
                        "http://localhost:8080",
                        "https://dashingdish.com"
                      )}
                      shareText={
                        attribs.children && attribs.children.length > 0
                          ? attribs.children[0].data
                          : ""
                      }
                    />
                  </li>
                  <li>
                    <Facebook
                      // text={`Share on Facebook`}
                      url={replace(
                        window.location.href,
                        "http://localhost:8080",
                        "https://dashingdish.com"
                      )}
                      shareText={
                        attribs.children && attribs.children.length > 0
                          ? attribs.children[0].data
                          : ""
                      }
                    />
                  </li>
                </ul>
                <blockquote>

                  {domToReact(attribs.children, { depth: null })}
                </blockquote>
              </div>
            );
          }
        },
      });
    }
  };

  const coverImage = activeBlog.image
    ? convertQuality(activeBlog.image, "large")
    : !isEmpty(activeBlog.content)
      ? getMarkdownImage(activeBlog.content, "large")[1]
      : "";
  return (
    <>
      <section
        className="fullWideSection divOverlay"
        style={{
          backgroundImage: `url(${coverImage})`,
        }}
      >
        <Helmet>
          <title>
            {activeBlog.title ? `${activeBlog.title} |` : ""} Dashing Dish
          </title>
        </Helmet>
        <TransitionOne>
          <div className="container">
            <div className="wideImgTitle">
              <h1>{activeBlog.title}</h1>
              {renderBlogMeta(activeBlog)}
            </div>
            <div className="blogActions">
              <BlogActions
                isAuthenticated={isAuthenticated}
                objectType="blog"
                boards={boards}
                isLoggedIn={isAuthenticated}
                createBoard={createBoard}
                activeObject={activeBlog}
                createFavourite={createFavourite}
                deleteFavourite={deleteFavourite}
                setObjectBoards={setObjectBoards}
                updateFavouriteBoard={updateFavouriteBoard}
                deleteFavouriteBoard={deleteFavouriteBoard}
                clearBoardSelection={clearBoardSelection}
                actionList={["share", "comment", "favourite"]}
                displayImage={
                  activeBlog.image
                    ? convertQuality(activeBlog.image, "thumb")
                    : !isEmpty(activeBlog.content)
                      ? getMarkdownImage(activeBlog.content, "thumb")[1]
                      : ""
                }
                thumbnail={
                  activeBlog.image
                    ? convertQuality(activeBlog.image, "thumb")
                    : !isEmpty(activeBlog.content)
                      ? getMarkdownImage(activeBlog.content, "thumb")[1]
                      : ""
                }
                ref={commentTextBoxRef}
                history={history}
              />
            </div>
          </div>
        </TransitionOne>
      </section>
      <TransitionTwo>
        <section className="interiorBlog">
          <div className="container">
            <div className="interiorWrapper">
              <div className="interiorBlog-content">
                <AdComponent />
                {renderMarkdownContent(activeBlog.content)}
              </div>
              <AdComponent />
              <BlogSideIdea
                feature="blog"
                history={history}
                objectType="blog"
                title={"Keep reading"}
                sideIdeas={sideIdeas}
                wrapperClass="similarCardLists blogsSideidea"
              />
              <Element name="Comment" className="element">
                <Comments
                  comments={comments}
                  objectType="blog"
                  isLoggedIn={isAuthenticated}
                  userDetails={userDetails}
                  activeObject={activeBlog}
                  sendComment={sendComment}
                  sendReplyComment={sendReplyComment}
                  ref={commentTextBoxRef}
                />
              </Element>
            </div>
          </div>
        </section>
      </TransitionTwo>
    </>
  );
};

export default SingleBlog;
