import React from "react";
import '../styles/common.scss';

const EasyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="dynamic-Icon">
            <g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1">
                <rect class="easyIcon-1" width="48" height="48" />
                <path class="easyIcon-2" d="M37.67,14.42l0,0-.07,0a19.88,19.88,0,0,0-27.17,0l-.06,0,0,0a19.93,19.93,0,0,0-3.93,24,1,1,0,0,0,1.36.4l6.59-3.57a1,1,0,0,0,.4-1.36,10.51,10.51,0,1,1,18.51,0,1,1,0,0,0,.4,1.36l6.59,3.57a1,1,0,0,0,1.36-.4,19.93,19.93,0,0,0-3.93-24ZM12.54,34,7.7,36.61A17.95,17.95,0,0,1,11.06,16.5l3.88,3.88A12.46,12.46,0,0,0,12.54,34Zm3.88-15-3.91-3.91a18,18,0,0,1,23,0L31.57,19a12.42,12.42,0,0,0-15.14,0ZM40.3,36.61,35.45,34a12.46,12.46,0,0,0-2.39-13.61l3.88-3.88A17.95,17.95,0,0,1,40.3,36.61Z" />
                <path class="easyIcon-2" d="M24,29a5,5,0,0,0-3.37,1.33l-4.15-3A1,1,0,1,0,15.31,29l4.14,3A5,5,0,0,0,19,34a5,5,0,1,0,5-5Zm0,8a3,3,0,1,1,3-3A3,3,0,0,1,24,37Z" />
            </g>
            </g>
        </svg>
    );
};
export default EasyIcon;

