import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import bottomimg from '../images/bottom-bg.png';
import img from '../images/story-heroImg.png';
import Innerimg from '../images/story-img.png';
import PlayButtonIcon from '../images/PlayButton';
import FullScreenLeft from '../images/story-fullscreen-right.jpg';
import FullScreenRight from '../images/story-fullscreen-left.jpg';
import ModalVideo from '../videos/components/modalVideoLib';

import {
  appEssentialFetch,
} from "../../actions";
import { TransitionOne, TransitionTwo } from '../common/transitions';

const StoryPage = (props) => {

  const [videoState, setVideoState] = useState({
    videoId: null,
    openVideoModal: false
  })

  useEffect(() => {
    props.appEssentialFetch();
  }, [])

  return (
    <>
      <Helmet>
        <title>My Story | Dashing Dish</title>
        <meta name="description" content="Dashingdish Story page" />
      </Helmet>
      <section
        className="story-hero"
        style={{
          backgroundImage: `url(${img})`
        }}>
        <TransitionOne>
          <div className="container">
            <div className="story-hero-wrapper">
              {/* TODO: replace videoId to actual one */}
              <div className="play-icon" onClick={() => setVideoState({ videoId: '389170526', openVideoModal: true })}>
                <figure className="play-button">
                  <PlayButtonIcon></PlayButtonIcon>
                </figure>
              </div>
              <h2>Learn more about my story</h2>
            </div>
          </div>
        </TransitionOne>
      </section>
      <main
        className="story-page">
        <section className="interior-page">
          <div className="container">
            <div className="inner-wrapper">
              <TransitionTwo>
                <div className="inner-wrapper-content">
                  <blockquote>Dashing Dish is a place where I hope to share what God has taught me, both spiritually and physically.</blockquote>
                  <p>
                    So much of my heart behind Dashing Dish stems from my personal struggle that I faced for so many years. From the age of fourteen into my early adult-hood I became trapped in a vicious cycle of dieting, disordered eating, and anxiety. It wasn’t until I was in my mid-twenties that I reached a healthy place in my body and mind.
                  </p>
                  <p>
                    <i>
                      My transformation began when I called out to God for help, knowing that He was the only one who could set me free from the mindsets and behaviors that were keeping me in bondage; and it was ultimately in Christ that I found my freedom.</i>
                  </p>
                  <p>
                    <i>
                      I started to rebuild a healthy relationship with food through cooking, and I realized that God has already given us all we need to truly thrive!
                    </i>
                  </p>
                  <p>
                    <i>
                      My transformation came full circle when I began nursing school. I became fascinated with how food impacts our health. My new found knowledge gave me such a deep appreciation for how wonderfully complex God created our bodies!
                    </i>
                  </p>
                  <p>
                    <i>
                      After graduating from nursing school, I started a new chapter in my life when I married my high school sweetheart, Sean; And I realized that I had my work cut out for me when it came to feeding my picky husband.
                    </i>
                  </p>
                  <p>
                    <i>Re-creating his favorites from childhood in a nutritious way became an exciting mission, and I started dreaming of ways I could share my recipes with others. After all, I knew I couldn’t be the only one learning to feed a picky eater!  </i>
                  </p>
                  <div className="image-wrapper">
                    <div className="FullScreen-left">
                      <figure>
                        <img src={FullScreenLeft} className="img_fluid"></img>
                      </figure>
                    </div>
                    <div className="FullScreen-right">
                      <figure>
                        <img src={FullScreenRight} className="img_fluid"></img>
                      </figure>
                    </div>
                  </div>
                  <p>
                    <i>Shortly after, Dashing Dish was born! I started sharing the recipes that I had created for my family, friends, and co-workers, as well as devotionals and stories from my personal journal entries. Initially I thought Dashing Dish would be a place where I would simply share a piece of my heart; But God had bigger plans! </i>
                  </p>
                  <p>
                    <i>
                      Over the years Dashing Dish has grown into an all encompassing healthy lifestyle service; as well as an incredible thriving community!  Thousands of women have joined Dashing Dish, and have since learned to abandon the diets and embrace a life of balance and freedom.
                    </i>
                  </p>
                  <p>
                    <i>Since the very beginning it has been my heart to create simple recipes that use real food ingredients, make meal planning effortless, and demonstrate how exercise can be fun and functional.</i>
                  </p>
                  <p>As&nbsp;
                    <i>
                      I look back and think about the lives that have been changed through Dashing Dish, I am beyond thankful. I am so grateful that God is able to look at the broken places in our lives and make something beautiful from our mess.
                    </i>
                  </p>
                  <p> Ultimately, we&nbsp;
                    <i>
                      desire that Dashing Dish is a dwelling place that brings nourishment for the body; And all who gather here experience God’s unfailing love, which satisfies the deepest longings of our souls.
                    </i>
                  </p>
                </div>
              </TransitionTwo>
            </div>
          </div>
        </section>
        <section className="interior-page-bg"
          style={{
            backgroundImage: `url(${bottomimg})`
          }}>
          <div className="container">
            <div className="inner-wrapper-lower">
              <div className="inner-wrapper-content">
                <blockquote>
                  “To all who mourn in Israel, he will give a crown of beauty for ashes, a joyous blessing instead of mourning, festive praise instead of despair.  In their righteousness, they will be like great oaks that the Lord has planted for his own glory.”
                </blockquote>
                <small>ISAIAH 61:3</small>
              </div>
            </div>
          </div>
        </section>
        {
          videoState.openVideoModal &&
          <ModalVideo
            channel={'vimeo'}
            isOpen={videoState.openVideoModal}
            videoId={videoState.videoId}
            vimeo={{ autoplay: 1, autopause: 0 }}
            onClose={() => {
              document.body.classList.remove('ReactModal__Body--open');
              setVideoState({ videoId: null, openVideoModal: false })
            }} />
        }
      </main>
    </>
  )
}

const mapStateToProps = () => ({

})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(StoryPage);