import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getFilteredRecipeOffset, getUnFilteredRecipeOffset, getToken, getDefaultDietary, getDefaultDietaryOffset } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry'
import recipesTask from './subtask/recipes';

import retrier from './process/retrier'

import { globalFetchLimit } from '../helpers/filterConversions';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  try {
    yield put({
      type: actions.RECIPES_LIST_FETCH_REQUEST,
      payload: {
        pagination: true,
      }
    });
    let tokenByAuthenticatedUser = yield select(getToken) // get current authEssential (which is loginInUser in store)

    const recipe = {
      limit: globalFetchLimit,
      filterGroup: action.payload.filterGroup,
    }
    if (action.payload.filterGroup == 'unFiltered') {
      recipe.offset = yield select(getUnFilteredRecipeOffset)
    } else if (action.payload.filterGroup === 'defaultDietary') {
      recipe.offset = yield select(getDefaultDietaryOffset)
    } else {
      recipe.offset = yield select(getFilteredRecipeOffset)
    }
    const recipesArray = yield call(retrier, [call(recipesTask.getMore, recipe)], 'recipes paginated');

    const recipesData = recipesArray[0]

    if (!recipesData) {
      const eventId = pushToSentry('Internet connection seems to be down - recipes data')
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: 'Internet connection seems to be down',
          eventId
        }
      })
    }

    yield put({
      type: actions.RECIPES_LIST_FETCH_SUCCESS,
      payload: {
        recipesData,
        forUser: tokenByAuthenticatedUser,
        pagination: true
      }
    });
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.RECIPES_LIST_FETCH_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* fetchRecipeDetails() {
  yield takeEvery(actions.GET_ALL_RECIPES, task)
}