import axios from 'axios'
import upperCase from 'lodash/upperCase'
import { post } from '../helpers/requests'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import * as actions from '../actions/actionTypes'
import filter from 'lodash/filter'
import favouriteTask from './subtask/favorites';
import { favourites } from '../helpers/api'
import pushToSentry from '../helpers/pushToSentry';
import { remove } from 'lodash';
import { Map, List } from 'immutable';
import { showToastMessage } from '../actions'
import {
  getToken,
  getEssentials,
  getAppliedRecipeFavorites,
  getAppliedWorkoutFavorites,
  getRecipeBoards,
  getRecipeByIdFromAnyList,
  getWorkoutBoards,
  getBlogBoards,
  getAppliedBlogFavorites,
  getVideoBoards,
  getAppliedVideoFavorites,
} from './selectors';

function* task(action) {
  //For Toast message
  const getState = state => state;
  const state = yield select(getState)

  try {
    const {
      objectId,
      objectType
    } = action.payload;

    let appliedFavorite;
    let allBoard

    if (objectType === 'recipe') {
      appliedFavorite = yield select(getAppliedRecipeFavorites);
      allBoard = yield select(getRecipeBoards);

    } else if (objectType === 'workout') {
      appliedFavorite = yield select(getAppliedWorkoutFavorites);
      allBoard = yield select(getWorkoutBoards);
    } else if (objectType === 'blog') {
      appliedFavorite = yield select(getAppliedBlogFavorites);
      allBoard = yield select(getBlogBoards);
    } else if (objectType === 'video') {
      appliedFavorite = yield select(getAppliedVideoFavorites);
      allBoard = yield select(getVideoBoards);
    } else if (objectType === 'meal_plan') {
      appliedFavorite = Map();
      allBoard = List();
    }

    const token = yield select(getToken);
    const essentials = yield select(getEssentials);

    // required to reactively remove items from the favorited items list
    const isFavoritePage = appliedFavorite.getIn(['show']);
    const favoriteBoardSlug = essentials.getIn([action.payload.objectType, 'favorite', 'board']);
    const isAllFavorites = favoriteBoardSlug === null;


    if (isFavoritePage && !isAllFavorites) {
      // Get the deleted board
      const toDeleteFavoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));

      const toDeleteFavoriteBoardId = toDeleteFavoriteBoard.getIn(['id']);


      const favouriteObject = {
        object_id: action.payload.objectId,
        object_type: action.payload.objectType,
        thumbnail: action.payload.thumbnail || `${objectType}_${objectId}`,
        boards: [toDeleteFavoriteBoardId]
      }

      yield call(axios, post(favourites.post(), favouriteObject, '', token));


      yield put({
        type: actions.DELETE_FAVOURITE_SUCCESS,
        payload: {
          filterList: true,
          objectId: action.payload.objectId,
          objectType: action.payload.objectType,
          thumbnail: action.payload.thumbnail || `${objectType}_${objectId}`,
          allBoards: isAllFavorites,
          affectedBoards: [toDeleteFavoriteBoardId] // This is the only affected board
        }
      })
      yield put(showToastMessage(`😕 ${upperCase(objectType)} removed from ${objectType === 'blog' ? 'bookmarks' : 'favorites'}.`, 'success', 'favorite'));

    } else {
      const delFavObject = {
        object_id: action.payload.objectId,
        object_type: action.payload.objectType,
        thumbnail: action.payload.thumbnail || `${objectType}_${objectId}`,
      }

      const response = yield call(favouriteTask.delete, delFavObject)

      yield put({
        type: actions.DELETE_FAVOURITE_SUCCESS,
        payload: {
          filterList: isFavoritePage,
          objectId: action.payload.objectId,
          objectType: action.payload.objectType,
          affectedBoards: response.data.affected_boards,
          thumbnail: action.payload.thumbnail || `${objectType}_${objectId}`,
          allBoards: true,
        }
      })
      yield put(showToastMessage(`😕 ${upperCase(objectType)} removed from ${objectType === 'blog' ? 'bookmarks' : 'favorites'}.`, 'success', 'favorite'));

    }

  } catch (e) {
    yield put(showToastMessage('❗️ Error Occured while unfavoriting', 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.DELETE_FAVOURITE_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* deleteFavourite() {
  yield takeEvery(actions.DELETE_FAVOURITE, task)
}