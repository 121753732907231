import React, { useState, useEffect, useReducer } from "react";
import axios from "axios";
import { baseUrl } from "../../helpers/api";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import toJS from "../../helpers/toJS";
import { Redirect } from "react-router-dom";
import {
  appEssentialFetch,
  acceptFirstTime,
  acceptSecondTime,
  showToastMessage,
} from "../../actions";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-flexbox-grid";
import Slider from "react-slick";
import slice from "lodash/slice";
import shuffle from "lodash/shuffle";
import map from "lodash/map";
import filter from "lodash/filter";
import parse from "html-react-parser";
import take from "lodash/take";
import pushToSentry from "../../helpers/pushToSentry";
import includes from "lodash/includes";

import ModalWrapper from "../common/modal";
import { getAuthStatus, getUserDetails } from "../../selectors";
import ModalVideo from "../videos/components/modalVideoLib";
import Button from "../common/button";
import CustomInput from "../common/customInput";
import BackgroundImageOne from "../images/hero-katieImage.png";
import BackgroundImageTwo from "../images/homerandow1.png";
import HeroImg from "../images/hero-image.png";
import recipe from "../images/homepage-recipe.png";
import innerImg from "../images/inner-img.png";
import brands from "../images/brand-image.png";
import workout from "../images/homepage-workout.png";
import LowerBody from "../images/LowerBody";
import FullBody from "../images/FullBody";
import UpperBody from "../images/UpperBody";
import CleanIngredientsIcon from "../images/CleanIngredients";
import MacroNutritionIcon from "../images/MacroNutrition";
import DietaryFiltersIcon from "../images/DietaryFilters";
import EditableServingIcon from "../images/EditableServing";
import TestimonialIcon from "../images/TestimonialIcon";
import SwapOutsIcon from "../images/SwapOuts";
import WeightWatcherIcon from "../images/WeightWatcher";
import LinkArrow from "../images/LinkArrow";
import IstoreIcon from "../images/Istore";
import PlayStoreIcon from "../images/PlayStore";
import PlayButtonIcon from "../images/PlayButton";
import Loader from "../images/loader";
import getUserPlanType from "../../helpers/getUserPlanType";
import { generateAppEssentialPayload } from "../../helpers/filterConversions";

import Logo from "../images/Logo";
import modalImg from "../images/modal-katie-img.png";
import KatieSign from "../images/KatieSign";
import Image1 from "./1.jpg";
import Image2 from "./2.jpg";
import Image3 from "./3.jpg";
import Image4 from "./4.jpg";
import Image5 from "./5.jpg";
import Image6 from "./6.jpg";
import Image7 from "./7.jpg";
import Image8 from "./8.jpg";
import Image9 from "./9.jpg";
import Image10 from "./10.jpg";
import Image11 from "./11.jpg";
import Image12 from "./12.jpg";
import Image13 from "./13.jpg";
import Image14 from "./14.jpg";
import Image15 from "./15.jpg";
import Image16 from "./16.jpg";
import Image17 from "./17.jpg";
import Image19 from "./19.jpg";

import Application from "../images/application.png";
import AppStore from "../images/AppStore";
import GooglePlay from "../images/GooglePlay";
import {
  TransitionEaseInOne,
  TransitionTwo,
  TransitionThree,
  TransitionFour,
} from "../common/transitions";
import HeroSectionOne from "./heroSectionOne";
import HeroSectionTwo from "./heroSectionTwo";
import HeroSectionThree from "./heroSectionThree";
import HeroSectionFour from "./heroSectionFour";
import HeroSectionFive from "./heroSectionFive";

import ThanksGivingFirst from "./blackFriday2020/1";
import ThanksGivingSecond from "./blackFriday2020/2";
import ThanksGivingThird from "./blackFriday2020/3";
import ThanksGivingFourth from "./blackFriday2020/4";

import HeroSectionMothersDay from "./heroMothersDay";
import AdComponent from "../common/ad";

const heroSections = shuffle([
  {
    index: 1,
    type: "what-is-for-your-dinner",
  },
  {
    index: 2,
    type: "weekly-recipes-and-mealplans",
  },
  {
    index: 3,
    type: "one-click-meal-planning",
  },
  {
    index: 4,
    type: "weekly-recipes-and-meal-plans-1",
  },
  {
    index: 5,
    type: "weekly-recipes-and-meal-plans-2",
  },
]);

// const heroSections = shuffle([
//   {
//     index: 1,
//     type: "50-percent-off",
//   },
//   {
//     index: 2,
//     type: "cyber-monday-1",
//   },
//   {
//     index: 3,
//     type: "cyber-monday-2",
//   },
//   {
//     index: 4,
//     type: "cyber-monday-3",
//   },
// ]);

const nonLoggedInHeroSection = [
  {
    index: 1,
    type: "mothers-day",
  },
];

const instagramImages = [
  {
    image: Image1,
    url: "https://www.instagram.com/p/BuFElE8nwED/?igshid=1xioylawusduq",
    title: "@well.and.whole",
  },
  {
    image: Image2,
    url: "https://www.instagram.com/p/B0J2tlXFiWK/?igshid=n6z2i3i44lsx",
    title: "@katieladyinchaarg",
  },
  {
    image: Image3,
    url: "https://www.instagram.com/p/B3qfwOWnARe/?igshid=eybey5ydsnye",
    title: "@classicannablog",
  },
  {
    image: Image4,
    url: "https://www.instagram.com/p/B43bzzflyN5/?igshid=3hfb25k19bfw",
    title: "@najatmarie",
  },
  {
    image: Image5,
    url: "https://www.instagram.com/p/BeOObssh4Qe/?igshid=wy8gwf8eszt0",
    title: "@tiu83clover",
  },
  {
    image: Image6,
    url: "https://www.instagram.com/p/Be_h1UIAi_K/?igshid=9ffoswlnesa5",
    title: "@demoeats",
  },
  {
    image: Image7,
    url: "https://www.instagram.com/p/BkQ17lXhA5b/?igshid=w59e4tb849tk",
    title: "@dietitians_of_dallas",
  },
  {
    image: Image8,
    url: "https://www.instagram.com/p/BvxTYUpnilQ/?igshid=6r3wkdzlzswk",
    title: "@mrsmcgehee23",
  },
  {
    image: Image9,
    url: "https://www.instagram.com/p/Bw-h2vhjrck/?igshid=1hz3wcttno4jg",
    title: "@rikkikreger",
  },
  {
    image: Image10,
    url: "https://www.instagram.com/p/BuZB_u2DbB7/?igshid=1bz0tq2jbh87r",
    title: "@craftychicgirl",
  },
  {
    image: Image11,
    url: "https://www.instagram.com/p/BxaYmzFnBR8/?igshid=vjts032fk3ru",
    title: "@lucieperciflette",
  },
  {
    image: Image12,
    url: "https://www.instagram.com/p/BjbR-ocjYhw/?igshid=1xoynjg7rm9s6",
    title: "@alexasparadise",
  },
  {
    image: Image13,
    url: "https://www.instagram.com/p/Bm8ylmiAXBu/?igshid=twt8thq08r0f",
    title: "@amcx4",
  },
  {
    image: Image14,
    url: "https://www.instagram.com/p/B2UWJQzBpzw/?igshid=duxjhvtzw40w",
    title: "@debbienicholsoncc",
  },
  {
    image: Image15,
    url: "https://www.instagram.com/p/B25XY1Bhg0O/?igshid=zgdhjlcs7iem",
    title: "@balanced_life_coach",
  },
  {
    image: Image16,
    url: "https://www.instagram.com/p/B3kpvclgnc5/?igshid=1bmxz3k16lls0",
    title: "@the_jadeoulla_feed",
  },
  {
    image: Image17,
    url: "https://www.instagram.com/p/B4X-mEYgxto/?igshid=1cuv0jg435mj8",
    title: "@chelseakuhn",
  },
  {
    image: Image19,
    url: "https://www.instagram.com/p/B5ut6gBFT1H/?igshid=9w9my5uwpsu9",
    title: "@katieladyinchaarg",
  },
];

var shuffledInstagramImages = take(shuffle(instagramImages), 5);
const shuffledBannerImage = shuffle([
  BackgroundImageOne,
  BackgroundImageTwo,
])[0];

let bannerVideo;
let MainWording;

if (shuffledBannerImage !== BackgroundImageOne) {
  bannerVideo = "389170352";
  MainWording = () => (
    <h1>
      Real. Simple.&nbsp;<br></br>
      Healthy Living.
    </h1>
  );
} else {
  bannerVideo = "389169506";
  MainWording = () => <h1>Start Your Journey to Health.</h1>;
}

const initialState = {
  mealPlansFetched: false,
  mealPlans: null,
  openVideoModal: false,
  videoId: null,
  videoType: null,
  mailChimpEmail: "",
  isMailchimpFormSubmitting: false,
};

let sliderRef = React.createRef();

const reducer = (rstate, action) => {
  switch (action.type) {
    case "SET_MEALPLANS":
      return {
        ...rstate,
        mealPlansFetched: true,
        mealPlans: take(action.payload.mealPlans, 8),
      };

    case "SET_FETCH_STATUS":
      return {
        ...rstate,
        mealPlansFetched: false,
      };

    case "PLAY_VIDEO":
      return {
        ...rstate,
        openVideoModal: true,
        videoId: action.payload.videoId,
        videoType: action.payload.videoType,
      };

    case "CLOSE_VIDEO":
      return {
        ...rstate,
        openVideoModal: false,
        videoId: null,
        videoType: null,
      };
    case "MAILCHIMP_FORM_EMAIL":
      return {
        ...rstate,
        mailChimpEmail: action.payload.email,
      };
    case "MAILCHIMP_FORM_SUBMIT":
      return {
        ...rstate,
        isMailchimpFormSubmitting: action.payload.submitting,
      };

    default:
      return rstate;
  }
};

const HomePage = (props) => {
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "330px",
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          centerPadding: "280px",
        },
      },
      {
        breakpoint: 1119,
        settings: {
          centerPadding: "240px",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerPadding: "180px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerPadding: "120px",
        },
      },
      {
        breakpoint: 575,
        settings: {
          centerPadding: "30px",
        },
      },
    ],
  };

  useEffect(() => {
    if (!props.isAuthenticating) {
      props.history.push(`/?type=${heroSections[0].type}`);
      // if (!props.isAuthenticated) {
      //   props.history.push(`/?type=${nonLoggedInHeroSection[0].type}`);
      // } else {
      //   props.history.push(`/?type=${heroSections[0].type}`);
      // }
    }
  }, [props.isAuthenticated, props.isAuthenticating]);
  const instagramImages = () => {
    return map(shuffledInstagramImages, (element) => (
      <div className="card">
        <a href={element.url} target="_blank">
          <figure className="insta-card">
            <img src={element.image} className="img_fluid"></img>
          </figure>
          <div className="member-name">
            <p>{element.title}</p>
          </div>
        </a>
      </div>
    ));
  };

  const [rstate, dispatch] = useReducer(reducer, initialState);
  const [loadedFirstTime, setloadedFirstTime] = useState(
    props.firstTimeAuthenticatedLoading
  );

  const [state, setState] = useState({
    isFetching: false,
    testimonials: [],
    error: false,
  });

  const [email, setEmail] = useState("");

  const addMemberToMailchimp = () => {
    props.history.push(`/pricing`);
  };

  useEffect(() => {
    setState({
      ...state,
      isFetching: true,
    });
    axios({
      method: "GET",
      url: `${baseUrl}/api/v1/testimonials`,
    })
      .then((response) => {
        setState({
          ...state,
          isFetching: false,
          testimonials: slice(
            shuffle(
              filter(
                response.data.testimonial,
                (testimonialItem) => testimonialItem.featured
              )
            ),
            0,
            8
          ),
        });
      })
      .catch((e) => {
        pushToSentry(e);
        setState({
          ...state,
          isFetching: false,
          error: true,
        });
      });
    // mela plans
    dispatch({ type: "SET_FETCH_STATUS", payload: {} });
    axios({
      method: "GET",
      url: `${baseUrl}/api/meal-plan-categories`,
    })
      .then((response) => {
        dispatch({
          type: "SET_MEALPLANS",
          payload: { mealPlans: response.data.data },
        });
      })
      .catch((e) => {
        pushToSentry(e);
      });
    props.appEssentialFetch(
      generateAppEssentialPayload({
        objectType: "recipe",
      })
    );
  }, []);
  useEffect(() => {
    if (props.firstTimeAuthenticatedLoading && !loadedFirstTime) {
      setloadedFirstTime(true);
      props.history.push("/recipes");
    }
  }, [props.firstTimeAuthenticatedLoading]);

  useEffect(() => {
    if (state.testimonials.length > 0) {
      // no way to handle this
      setTimeout(() => {
        if (sliderRef && sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }, 1000);
    }
  }, [state.testimonials.length]);

  const renderCustomMealPlan = (item, index) => {
    return map(rstate.mealPlans, (item, index) => {
      return (
        <div className="plans-card">
          <figure>
            <img
              src={
                item && item.images && item.images[0]
                  ? item.images[0].square_400
                  : ""
              }
              className="img_fluid"
            ></img>
          </figure>
          <div className="plans-card-name">
            <h6>{item.title}</h6>
            <small>{item.count}&nbsp;Meal Plans</small>
          </div>
          <div className="plans-card outline-1"></div>
          <div className="plans-card outline-2"></div>
        </div>
      );
    });
  };

  const isPremiumUser =
    props.userDetails &&
    props.userDetails.details &&
    (getUserPlanType(props.userDetails) === "premiumPlan" ||
      getUserPlanType(props.userDetails) === "grandfather");

  if (props.isAuthenticating) {
    return (
      <div className="container">
        <div className="preLoader">
          <Loader></Loader>
        </div>
      </div>
    );
  }
  return (
    <>
      <Helmet>
        <title>Dashing Dish | Real. Simple. Healthy Living.</title>
        <meta name="description" content="Dashingdish Home page" />
      </Helmet>

      {includes(props.history.location.search, "what-is-for-your-dinner") ? (
        <HeroSectionOne
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}
      {includes(
        props.history.location.search,
        "weekly-recipes-and-mealplans"
      ) ? (
        <HeroSectionTwo
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}
      {includes(props.history.location.search, "one-click-meal-planning") ? (
        <HeroSectionThree
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}
      {includes(
        props.history.location.search,
        "weekly-recipes-and-meal-plans-1"
      ) ? (
        <HeroSectionFour
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}
      {includes(
        props.history.location.search,
        "weekly-recipes-and-meal-plans-2"
      ) ? (
        <HeroSectionFive
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}

      {/* {includes(props.history.location.search, "50-percent-off") ? (
        <ThanksGivingFirst
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}

      {includes(props.history.location.search, "cyber-monday-1") ? (
        <ThanksGivingSecond
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}
      {includes(props.history.location.search, "cyber-monday-2") ? (
        <ThanksGivingThird
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null}
      {includes(props.history.location.search, "cyber-monday-3") ? (
        <ThanksGivingFourth
          isAuthenticated={props.isAuthenticated}
          history={props.history}
        />
      ) : null} */}

      <main className="home-page">
        <section className="recipe-about">
          <div className="home-about-hero">
            <div className="container">
              <AdComponent />
              <TransitionTwo>
                <p className="about-text">
                  Dashing Dish focuses on the most important things you need to
                  start and stick to a healthy lifestyle. When you become a
                  member,<br></br>
                  <b>you get unlimited access to:</b>
                </p>
              </TransitionTwo>
              <TransitionThree>
                <Row className="recipe-about-wrapper no-margin-sm">
                  <Col
                    xs={12}
                    lg={5}
                    className="BackgroundImageOne-wrapper no-padding-sm"
                  >
                    <figure>
                      <img src={recipe} className="heroRecipeImg img_fluid" />
                    </figure>
                  </Col>
                  <Col xs={12} lg={7} className="no-padding-sm">
                    <div className="feature-wrapper">
                      <h3>Simple & delicious clean eating recipes</h3>
                      <p>
                        We have over 1,000 mouth-watering recipes that are
                        simple to make and use only clean, wholesome ingredients
                        you can easily find at your local grocer. In each recipe
                        you’ll get:
                      </p>
                      <ul className="list-unstyle">
                        <li>
                          <figure className="list-figure">
                            <CleanIngredientsIcon></CleanIngredientsIcon>
                          </figure>
                          Clean Ingredients
                        </li>
                        <li>
                          <figure className="list-figure">
                            <MacroNutritionIcon></MacroNutritionIcon>
                          </figure>
                          Macro nutrition
                        </li>
                        <li>
                          <figure className="list-figure">
                            <DietaryFiltersIcon></DietaryFiltersIcon>
                          </figure>
                          Dietary filters
                        </li>
                        <li>
                          <figure className="list-figure">
                            <EditableServingIcon></EditableServingIcon>
                          </figure>
                          Editable serving sizes
                        </li>
                        <li>
                          <figure className="list-figure">
                            <SwapOutsIcon></SwapOutsIcon>
                          </figure>
                          Ingredient swap-outs
                        </li>
                        <li>
                          <figure className="list-figure">
                            <WeightWatcherIcon></WeightWatcherIcon>
                          </figure>
                          Weight Watcher Points
                        </li>
                      </ul>
                      <a
                        href="/recipes"
                        onClick={(e) => {
                          e.preventDefault();
                          props.history.push("/recipes");
                        }}
                        className="link-arrow"
                      >
                        Get a taste by checking out our full recipe collection{" "}
                        <LinkArrow></LinkArrow>
                      </a>
                    </div>
                  </Col>
                </Row>
              </TransitionThree>
            </div>
          </div>
        </section>
        <section>
          <TransitionFour>
            <div className="container">
              <div
                className="workout-about"
                style={{
                  backgroundImage: `url(${workout})`,
                }}
              >
                <div className="home-workout-hero">
                  <h3>Workouts for every level & muscle</h3>
                  <p>
                    Feel energized with over 200 simple video workouts you can
                    do at home or the gym. Better yet, join a Dashing Dish
                    challenge or build your own bootcamp!
                  </p>
                </div>
                <div className="floating-card">
                  <div
                    className="card"
                    onClick={(e) =>
                      props.history.push("/workouts?body-parts=lower-body")
                    }
                  >
                    <h5>
                      Lower
                      <br></br>Body
                    </h5>
                    <figure>
                      <LowerBody></LowerBody>
                    </figure>
                  </div>
                  <div
                    className="card"
                    onClick={(e) =>
                      props.history.push("/workouts?body-parts=upper-body")
                    }
                  >
                    <h5>
                      Upper
                      <br></br>Body
                    </h5>
                    <figure>
                      <UpperBody></UpperBody>
                    </figure>
                  </div>
                  <div
                    className="card"
                    onClick={(e) =>
                      props.history.push("/workouts?body-parts=full-body")
                    }
                  >
                    <h5>
                      Full <br></br>
                      Body
                    </h5>
                    <figure>
                      <FullBody></FullBody>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </TransitionFour>
        </section>
        <section className="plans">
          <div className="container">
            <div className="plans-wrapper">
              <div className="plans-hero">
                <h3>Save time & money with our meal plans</h3>
                <p>
                  Let us do the planning for you! Choose from over 100 pre-made
                  meal plans. Simply select the length of your plan and the
                  number of happy eaters, then send the grocery list to your
                  grocer for pickup or delivery!
                </p>
              </div>
              <div className="new-plans">
                <p className="new-plan">New meal plans added weekly!</p>
              </div>
            </div>
            <div className="plans-cards">
              {rstate.mealPlansFetched && <>{renderCustomMealPlan()}</>}
            </div>
          </div>
        </section>
        <section className="full-customize">
          <div className="container">
            <Row className="customize-sec no-margin-sm">
              <Col md={12} md={12} lg={7} className="no-padding-sm">
                <figure className="img-wrapper">
                  <img src={innerImg} className="img_fluid" />
                  {/* TODO: replace videoId to actual one */}
                  <figure
                    className="play-button"
                    onClick={() =>
                      dispatch({
                        type: "PLAY_VIDEO",
                        payload: { videoId: "390789064", videoType: "vimeo" },
                      })
                    }
                  >
                    <PlayButtonIcon></PlayButtonIcon>
                  </figure>
                </figure>
              </Col>

              <Col md={12} md={12} lg={5} className="no-padding-sm">
                <div className="customize">
                  <div className="customize-wrapper">
                    <h3>Fully customize your meal plans & workouts</h3>
                    <ul className="list-style">
                      <li>
                        Swap out recipes, edit serving sizes, and make a meal
                        plan yours!
                      </li>
                      <li>
                        Add workouts alongside meals to keep track of your
                        healthy habits.
                      </li>
                      <li>
                        Send grocery orders direct to your favorite stores for
                        pickup or delivery.
                      </li>
                      <li>Save your family’s favorites and add your own!</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="application">
          <div className="container">
            <Row className="customize-sec no-margin-sm">
              <Col md={12} md={7} lg={6} className="no-padding-sm">
                <div className="application-hero-wrapper">
                  <h3>
                    Everything you need to live a healthy &nbsp;<br></br>
                    lifestyle in the palm of your hand
                  </h3>
                  <p>
                    Take Dashing Dish with you on the go with our fully
                    integrated app for both iPhone
                  </p>
                  <div className="application-links">
                    <ul className="unstyle-links">
                      <li>
                        <a
                          href="https://apps.apple.com/us/app/dashing-dish/id977480745"
                          target="_blank"
                        >
                          <figure>
                            <IstoreIcon></IstoreIcon>
                          </figure>
                          <figure>
                            <AppStore></AppStore>
                          </figure>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col
                xs={12}
                md={5}
                lg={6}
                className="application-img no-padding-sm"
              >
                <figure className="">
                  <img src={Application} className="img_fluid"></img>
                </figure>
              </Col>
            </Row>
          </div>
        </section>
        <section className="home-testimonials">
          <div className="testimonial-wrapper">
            <Slider {...carouselSettings} centerMode={true} ref={sliderRef}>
              {map(state.testimonials, (testimonial, index) => {
                return (
                  <div
                    className="testimonials-content"
                    onClick={() => {
                      if (sliderRef && sliderRef.current) {
                        sliderRef.current.slickGoTo(index);
                      }
                    }}
                  >
                    <figure>
                      <TestimonialIcon />
                    </figure>
                    {parse(testimonial.contents)}
                    <small>{testimonial.author}</small>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
        <section className="members">
          <div className="container">
            <div className="member-wrappwer">
              <h5>
                Recipes from our <b>awesome</b> members!
              </h5>
              <div className="member-recipes">{instagramImages()}</div>
              <div className="try-wrapper">
                {!props.isAuthenticated && (
                  <>
                    <p>
                      Mom always said, “how do you know &nbsp;<br></br>
                      if you don’t try it?
                    </p>
                    <Button
                      btnClass="btn btn-primary btn-md"
                      btnText="Try free for 3 days"
                      onClick={() => {
                        props.history.push("pricing");
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        {rstate.openVideoModal && (
          <ModalVideo
            channel={rstate.videoType}
            isOpen={rstate.openVideoModal}
            videoId={rstate.videoId}
            vimeo={{ autoplay: 1, autopause: 0 }}
            onClose={() => {
              document.body.classList.remove("ReactModal__Body--open");
              dispatch({ type: "CLOSE_VIDEO" });
            }}
          />
        )}
        {props.isAuthenticated && props.loginStatus.firstTime ? (
          <ModalWrapper
            minHeight="680px"
            maxWidth="500px"
            isOpen
            onRequestClose={() => {
              props.acceptFirstTime();
            }}
          >
            <div className="modalWrapper paywall-modal">
              <div className="modal-image-wrapper">
                <figure>
                  <img src={modalImg} className="img_fluid"></img>
                </figure>
              </div>
              <div className="modal-body-wrapper">
                <h3>Welcome to Dashing Dish!</h3>
                <p>
                  {`I’m Katie Farrell, the founder of Dashing Dish!  My hope is that the recipes, workouts, videos, and ${isPremiumUser
                    ? "meal planning tools here on Dashing Dish"
                    : "more"
                    } will help you on your journey to healthy living!`}
                </p>
                <figure>
                  <KatieSign></KatieSign>
                </figure>
              </div>
              <div className="modal-footer-wrapper">
                <Button
                  btnClass="primary btn-md"
                  btnText="Get started with a few video tutorials"
                  onClick={() => {
                    props.history.push("/get-started");
                    props.acceptFirstTime();
                  }}
                />
                <p
                  onClick={() => {
                    props.acceptFirstTime();
                  }}
                >
                  Nah, I’ll check those out later
                </p>
              </div>
            </div>
          </ModalWrapper>
        ) : null}


        {/* {(props.isAuthenticated && props.loginStatus.secondTime) ? (
          <ModalWrapper
            minHeight="680px"
            maxWidth="500px"
            isOpen
            onRequestClose={() => {
              props.acceptSecondTime();
            }}
          >
            <div className="modalWrapper paywall-modal">
              <div className="modal-image-wrapper">
                <figure>
                  <img src={modalImg} className="img_fluid"></img>
                </figure>
              </div>
              <div className="modal-body-wrapper">
                <h3>Pick your Meal Plans!</h3>
                <p>
                  You can now pick your Favorite Mealplans and get the latest Mealplan every week directly on your email.
                </p>

              </div>
              <div className="modal-footer-wrapper">
                <Button
                  btnClass="primary btn-md"
                  btnText="Proceed to pick"
                  onClick={() => {
                    props.history.push("/calendar?pick-your-plan=true");
                    props.acceptSecondTime();
                  }}
                />
                <p
                  onClick={() => {
                    props.acceptSecondTime();
                  }}
                >
                  Nah, I’ll check those out later
                </p>
              </div>
            </div>
          </ModalWrapper>
        ) : null} */}
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({
  loginStatus: getAuthStatus(state),
  userDetails: getUserDetails(state),
  firstTimeAuthenticatedLoading: state.getIn([
    "settings",
    "firstTimeAuthenticatedLoading",
  ]),
});

const dispatchActionToProps = (dispatch) => ({
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  acceptSecondTime: bindActionCreators(acceptSecondTime, dispatch),
  acceptFirstTime: bindActionCreators(acceptFirstTime, dispatch),

});

export default connect(mapStateToProps, dispatchActionToProps)(toJS(HomePage));
