import { put, takeEvery, call, select } from 'redux-saga/effects'
import { showToastMessage } from '../actions'
import { getCurrentObjectId, getAuthStatus, getRecipeByIdFromAnyList } from './selectors'
import * as actions from '../actions/actionTypes'
import commentTask from './subtask/comments'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    //For Toast message
    const getState = state => state;
    const state = yield select(getState)

    // getting action payload credetials
    const { content, parentCommentId, objectType, objectId } = action.payload;

    // gettting the auth details
    let authDetails = yield select(getAuthStatus);

    // preparing data object to send to api
    const commentObject = {
      content: content,
      comment_parent: parentCommentId,
      comment_type: "comment",
      object_type: objectType === 'blog' ? 'blog_post' : objectType,
      object_id: objectId,
      author: authDetails.getIn(['details', 'name']),
      author_email: authDetails.getIn(['details', 'email']),
    }

    // api call
    const commentResponse = yield call(commentTask.post, commentObject)

    //action call to store updates
    yield put({
      type: actions.SEND_REPLY_COMMENT_TO_OBJECT_SUCCESS,
      payload: {
        success: 'true',
        commentObject: commentResponse.data,
        objectType,
        objectId,
        parentCommentId
      }
    })
    yield put(showToastMessage(`✅ Reply added.`, 'success', 'comment'));
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in posting reply comment`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.SEND_REPLY_COMMENT_TO_OBJECT_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* sendReplyComment() {
  yield takeEvery(actions.SEND_REPLY_COMMENT_TO_OBJECT, task)
}