import React from "react";
import Button from "./button";
import LinkButton from "../common/linkButton";
import StarIcon from "../images/AddnoteIcon copy";

const GroceriesListModal = (props) => {
  if (
    !props.isAuthenticated ||
    (props.userType !== "premiumPlan" && props.userType !== "grandfather")
  ) {
    return (
      <div className="Addgroceries feature-tooltip">
        <StarIcon></StarIcon>
        <div className="groceriesHead">
          <h3>
            {props.isAuthenticated
              ? "This feature is available for meal plan pro users."
              : "This feature is available for members only."}
          </h3>
        </div>
        <div className="advantages">
          {props.isAuthenticated
            ? "Upgrade your plan today to start saving time and money by meal planning like a pro!"
            : "See why thousands of people trust us for weekly recipes, meal plans, workouts, and more."}
        </div>
        <div className="groceriesFooter">
          <Button
            btnClass="primary btn-full"
            btnText={
              props.isAuthenticated
                ? "Upgrade for $2 month"
                : "See Plans and Pricing"
            }
            onClick={(e) => {
              e.preventDefault();
              if (props.isAuthenticated) {
                props.history.push("/account/membership");
              } else {
                props.history.push("/pricing");
              }
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="Addgroceries">
      <div className="groceriesHead">
        <h3>Add all ingredients to grocery list?</h3>
      </div>
      <div className="groceriesFooter">
        <Button
          btnClass="primary btn-full"
          btnText="Yes"
          onClick={() => {
            props.addAllToGroceryList();
            props.toggleGroceryModal((modalState = false));
          }}
        />
      </div>
    </div>
  );
};

export default GroceriesListModal;
