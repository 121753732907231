import React from "react";

import filter from "lodash/filter";
import cx from "classnames";
import { Row, Col } from "react-flexbox-grid";
import VerticalListWithoutTitle from "./common/verticalListWithoutTitle";
import VerticalListWithTitle from "./common/verticalListWithTitle";
import NavbarImageCard from "./common/navbarImageCard";

import mealplan1 from "../../images/mealPlan.png";

const list = (categories) => {
  const getCategory = (name) =>
    filter(categories, (category) => category.title == name)[0];

  const getImage = (category) =>
    category && category.images
      ? {
          image1: category.images[0].full_sm,
          image2: category.images[1].full_sm || category.images[0].full_sm,
          image3: category.images[2].full_sm || category.images[0].full_sm,
        }
      : "";

  const crockpot = getCategory("Crockpot Only");
  const quickAndEasy = getCategory("Quick & Easy");
  const lowCarb = getCategory("Low Carb");
  const mealPrep = getCategory("Meal Prep");

  const result = [
    {
      title: "Crockpot",
      href: `/calendar?meal-plans=true&category=${
        crockpot ? crockpot.slug : ""
      }`,
      description: "Cook 3 times and eat for the whole week",
      ...getImage(crockpot),
    },
    {
      href: `/calendar?meal-plans=true&category=${
        quickAndEasy ? quickAndEasy.slug : ""
      }`,
      title: "Quick & Easy",
      description: "Short on time? We got you covered",
      ...getImage(quickAndEasy),
    },
    {
      href: `/calendar?meal-plans=true&category=${lowCarb ? lowCarb.slug : ""}`,
      title: "Low Carb",
      description: "Easy and delecious low carb dinners",
      ...getImage(lowCarb),
    },
    {
      href: `/calendar?meal-plans=true&category=${
        mealPrep ? mealPrep.slug : ""
      }`,
      title: "Meal Prep",
      description: "Set it and forget it! Easy one pot dinners",
      ...getImage(mealPrep),
    },
  ];

  return result;
};

const verticalList = [
  {
    href: "/calendar?meal-plans=true&order=most-popular",
    title: "Most Popular",
  },
  {
    href: "/calendar?meal-plans=true&category=complete-meal-plan",
    title: "BLD Plans",
  },
  {
    href: "/calendar?meal-plans=true&category=gluten-free-meal-plan",
    title: "Gluten Free",
  },
  {
    href: "/calendar?meal-plans=true&category=6-ingredient",
    title: "6 Ingredient",
  },
  {
    href: "/calendar?meal-plans=true&category=kid-friendly",
    title: "Kid Friendly",
  },
];

const MealPlansDropdown = ({
  mealPlanCategories,
  history,
  activeHoveredEntry,
  requestCloseNavDropdown,
}) => (
  <div className="container">
    <Row className="mealplan-dropdown navbar-dropdown-wrapper no-margin">
      <Col sm={3} lg={3} className="no-padding">
        <VerticalListWithoutTitle
          list={verticalList}
          history={history}
          onClose={requestCloseNavDropdown}
        />
      </Col>
      <Col sm={9} lg={9} className="no-padding">
        <NavbarImageCard
          list={list(mealPlanCategories)}
          history={history}
          onClose={requestCloseNavDropdown}
          render={(item) => (
            <div className="meanplan-navbar-group">
              <img className="first-image" src={item.image1} />
              <img className="second-image" src={item.image2} />
              <img className="third-image" src={item.image3} />
            </div>
          )}
        />
      </Col>
    </Row>
  </div>
);

export default MealPlansDropdown;
