import pushToSentry from '../helpers/pushToSentry';

export const getLocalToken = () => {
  if (typeof localStorage !== 'undefined' && localStorage != null) {
    return localStorage.getItem('token')
  } else {
    pushToSentry('localStorage not available ')
  }

}

export const getGateToken = () => {
  if (typeof localStorage !== 'undefined' && localStorage != null) {
    console.log('gate token !!!', localStorage.getItem('gateToken'))
    return localStorage.getItem('gateToken')
  } else {
    pushToSentry('localStorage not available ')
  }

}

export const setLocalToken = (token) => {
  if (typeof localStorage !== 'undefined' && localStorage != null) {
    return localStorage.setItem('token', token)
  } else {
    pushToSentry('localStorage not available ')
  }

}

export const setGateToken = (token) => {
  if (typeof localStorage !== 'undefined' && localStorage != null) {
    return localStorage.setItem('gateToken', token)
  } else {
    pushToSentry('localStorage not available ')
  }

}