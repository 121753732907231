import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getFilteredWorkoutOffset, getUnFilteredWorkoutOffset, getToken, getFeaturedWorkoutOffset } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry'
import workoutsTask from './subtask/workouts';

import retrier from './process/retrier'

import { globalFetchLimit } from '../helpers/filterConversions';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  try {
    yield put({
      type: actions.WORKOUTS_LIST_FETCH_REQUEST,
      payload: {
        pagination: true,
      }
    });
    let tokenByAuthenticatedUser = yield select(getToken) // get current authEssential (which is loginInUser in store)

    const workout = {
      limit: globalFetchLimit,
      filterGroup: action.payload.filterGroup,
    }
    if (action.payload.filterGroup == 'unFiltered') {
      workout.offset = yield select(getUnFilteredWorkoutOffset)
    } else if (action.payload.filterGroup == 'featured') {
      workout.offset = yield select(getFeaturedWorkoutOffset)
    } else {
      workout.offset = yield select(getFilteredWorkoutOffset)
    }

    const workoutsArray = yield call(retrier, [call(workoutsTask.getMore, workout)], 'workouts paginated');

    const workoutsData = workoutsArray[0]

    if (!workoutsData) {
      const eventId = pushToSentry('Internet connection seems to be down - workouts data')
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: 'Internet connection seems to be down',
          eventId
        }
      })
    }

    yield put({
      type: actions.WORKOUTS_LIST_FETCH_SUCCESS,
      payload: {
        workoutsData,
        forUser: tokenByAuthenticatedUser,
        pagination: true
      }
    });
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.WORKOUTS_LIST_FETCH_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* fetchRecipeDetails() {
  yield takeEvery(actions.GET_ALL_WORKOUTS, task)
}