import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import isNull from 'lodash/isNull'
import PrintIcon from "../../images/PrintIcon";

import { withRouter } from 'react-router-dom'
import { Helmet } from "react-helmet"

import toJS from '../../../helpers/toJS';
import { getRecipeDetails, getCaloriesForRecipe, getVariationsForActiveRecipe } from "../../../selectors";

import { appEssentialFetch } from "../../../actions";

import Radiobox from "../../common/radiobox";
import Logo from "../../images/Logo";

import ReactMarkdown from 'react-markdown';
import { getIngredientDetail } from "../../../helpers/ingredientHelpers";



const Print = (props) => {
  useEffect(() => {
    props.appEssentialFetch({
      recipe: {
        recipeSlug: props.match.params.recipeSlug
      }
    })
  }, []);

  const primaryMetaData = [
    {
      title: 'CALORIES',
      value: props.caloriesRecipe.calories
    },
    {
      title: 'FP',
      value: props.caloriesRecipe.fp
    },
    {
      title: 'SP',
      value: props.caloriesRecipe.sp
    }
  ]

  const secondaryMetaData = {
    title: 'Full Nutrition',
    items: !isEmpty(props.variationsRecipe) && props.variationsRecipe.nutrition.groups[0].lines
  }

  console.log("recipe : ", props.activeRecipe.ingredients_v2)

  return (
    <div className="container">
      <Helmet>
        <title>Print Recipe | Dashing Dish</title>
        <meta name="description" content="Dashingdish Print Recipe page" />
      </Helmet>
      <div class="print-page_back-link">
        <a
          href={`/recipe/${props.match.params.recipeSlug}`}
          onClick={e => {
            e.preventDefault()
            props.history.push(`/recipe/${props.match.params.recipeSlug}`)
          }}
          className=""
        >
          ‹ Back to the Recipe
        </a>
      </div>
      <div className="print-page_header">
        <Logo />
        <figure className="print-button" onClick={(e) => window.print()}>
          <PrintIcon />
        </figure>
      </div>
      <div class="print-page_content">
        <div className="print_content-left">
          <small>{`https://dashingdish.com/recipe/${props.match.params.recipeSlug}`}</small>
          <h1>{props.activeRecipe.title}</h1>
          <ul className="featuredMetadata">
            {
              primaryMetaData && primaryMetaData.map((item, index) => {
                return (
                  <li key={index}>
                    {item.title}<span>{(isNull(item.value)) ? '-' : item.value}</span>
                  </li>
                )
              })
            }
          </ul>
          <h2>Story</h2>
          <p className="story">
            {props.activeRecipe.story}
          </p>
          <ol className="print-page_recipeMethod">
            <h2>Method</h2>
            {
              props.activeRecipe.method && props.activeRecipe.method.map((instruction, index) => {
                return (
                  <li key={index}>
                    {instruction.directions}
                  </li>
                )
              })
            }
          </ol>
          <div className="nutritions">
            <h6>{secondaryMetaData.title}</h6>
            {
              <div className="showFullNutrition">
                {secondaryMetaData.items && secondaryMetaData.items.map((item, index) => {
                  return (
                    <li className="nutritionsList" key={index}>
                      <p>{item.item}</p>
                      {
                        item.amount && <p className="nutritionAmount">{item.amount}</p>
                      }
                    </li>
                  )
                })}
              </div>
            }
          </div>
        </div>
        <div className="print_content-right">

          <ul className="print-page_recipeIngredients">
            <h2>Ingredients</h2>
            {
              props.activeRecipe.ingredients_v2 && props.activeRecipe.ingredients_v2.filter(ingredient => ingredient.variation_position === 0).map((ingredient, index) => {
                let quantity = null;
                let name = null;
                if (!ingredient.header) {
                  const {
                    ingredientQuantityWithUnit,
                    ingredientNameWithIndicator,
                  } = getIngredientDetail(ingredient);
                  quantity = ingredientQuantityWithUnit;
                  name = ingredientNameWithIndicator;

                }


                return (
                  <>

                    {
                      ingredient.header ? (
                        <h3>{ingredient.quantity}</h3>
                      ) : (
                        <li key={index} className="ingredientList">
                          <Radiobox
                            type={'checkbox'}
                          />
                          <span className="ingredientsAmount">{quantity}</span>
                          <span className="ingredientsItem">{name}</span>
                        </li>
                      )
                    }

                  </>
                )
              })
            }
          </ul>
          {/* footnote */}
          {
            (props.activeRecipe && props.activeRecipe.variations && props.activeRecipe.variations[0] && props.activeRecipe.variations[0].ingredients.footnotes) &&
            <div className="ingredientFooter">
              <h2>Footnotes</h2>
              <ul style={{ padding: '0px' }}>
                {
                  map(props.activeRecipe.variations[0].ingredients.footnotes, (footnote, index) => {
                    return (
                      <div key={index} className="ingredientList" style={{ display: 'flex', alignItems: 'center' }}>
                        <span>
                          [{index + 1}]
                        </span>
                        <div style={{ padding: '0px 20px' }}>
                          <ReactMarkdown
                            source={footnote}
                            linkTarget="_blank"
                          />
                        </div>

                      </div>
                    )
                  })
                }
              </ul>
            </div>
          }
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  activeRecipe: getRecipeDetails(state, 0),
  caloriesRecipe: getCaloriesForRecipe(state, 0),
  variationsRecipe: getVariationsForActiveRecipe(state, 0)
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(withRouter(Print)));
