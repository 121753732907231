import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'

import { remove } from '../helpers/requests'
import { mealPlan } from '../helpers/api'
import { getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import { showToastMessage } from '../actions';

function* task(action) {
  try {
    const token = yield select(getToken);
    const { day } = action.payload

    yield call(
      axios, remove(mealPlan.deleteCalendarDay(), { day }, '', token)
    );

    yield put({
      type: actions.CLEAR_MEAL_PLAN_DAY_SUCCESS,
      payload: {
        day
      }
    })
    yield put(showToastMessage(`✅ Calendar day cleared`, 'success', 'deleteBoard'));

  } catch (e) {
    yield put(showToastMessage(`❗️Calendar day clear failed.`, 'error', 'deleteBoard'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CLEAR_MEAL_PLAN_DAY_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* clearDay() {
  yield takeEvery(actions.CLEAR_MEAL_PLAN_DAY, task)
}