import React, { useState, useEffect, useReducer } from "react";
import cx from "classnames";
import isNil from "lodash/isNil";
import map from "lodash/map";
import reduce from "lodash/reduce";
import capitalize from "lodash/capitalize";
import findIndex from "lodash/findIndex";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";
import Avatar from "../avatar";
import ReactTooltip from "react-tooltip";

import { baseUrl, auth } from "../../../helpers/api";
import pushToSentry from "../../../helpers/pushToSentry";

import Modalwrapper from "../../common/modal";
import Button from "../../common/button";
import DownArrow from "../../images/DownArrow";
import UpArrow from "../../images/UpArrow";

import UpdatePayment from "../../common/updatePayment";
import Arrow from "../../images/Arrow";
import Selector from "../../common/selector";
import AmexCard from "../../images/AmexCard";
import AmericanExpress from "../../images/AmericanExpressCard";
import VisaCard from "../../images/VisaCard";
import MasterCard from "../../images/MasterCard";
import DiscoverCard from "../../images/DiscoverCard";
import GrandFatherIcon from "../../images/GrandFatherIcon";
import SwitchIcon from "../../images/SwitchIcon";
import LinkButton from "../../common/linkButton";
import Loader from "../../images/loader";

import { Tooltip } from "react-tippy";

import ProrateModal from "./prorateModal";
import PaypalConfirmationModal from "./paypalConfirmationModal";

import { TransitionOne, TransitionTwo } from "../../common/transitions";

const initialState = {
  gifts: [],
  giftFetchStatus: {
    fetched: false,
    error: null,
  },
  applyGift: {
    giftApplyStatus: false,
    giftApplyError: null,
  },
  trialNextBillingDate: null,
  paypalPlanDeleteStatus: {
    initiated: false,
    success: false
  }
};

function reducer(state, action) {
  switch (action.type) {
    case "GIFTS":
      return {
        ...state,
        gifts: action.payload.gifts,
        giftFetchStatus: action.payload.giftFetchStatus,
      };

    case "GIFTS-ERROR":
      return {
        ...state,
        giftFetchStatus: action.payload.giftFetchStatus,
      };

    case "GIFTS-APPLY":
      return {
        ...state,
        applyGift: {
          giftApplyStatus: true,
          giftApplyError: null,
        },
      };
    case "GIFTS-APPLY-SUCCESS":
      return {
        ...state,
        applyGift: {
          giftApplyStatus: false,
          giftApplyError: null,
        },
        gifts: action.payload.gifts,
        trialNextBillingDate: action.payload.trialNextBillingDate,
      };

    case "GIFTS-APPLY-ERROR":
      return {
        ...state,
        applyGift: {
          giftApplyStatus: false,
          giftApplyError: "Something went wrong!",
        },
      };
    case "DELETE-PAYPAL-SUBSCRIPTION":
      return {
        ...state,
        paypalPlanDeleteStatus: {
          initiated: true,
          success: false
        }
      }
    case "DELETE-PAYPAL-SUBSCRIPTION-SUCCESS":
      return {
        ...state,
        paypalPlanDeleteStatus: {
          initiated: false,
          success: true
        }
      }
  }
}

const Membership = ({
  history,
  userDetails,
  cancelSubscription,
  reverseCancelSubscription,
  changePlan,
  plans,
  changePaymentType,
  changeCardTypeFetchStatus,
  changePlanFetchStatus,
  cancelSubscriptionFetchStatus,
  reverseCancelSubscriptionFetchStatus,
  ...props
}) => {
  const {
    details: {
      avatar = {},
      member_since: memberSince,
      membership = {},
      plan,
      name,
    },
  } = userDetails;

  const [modalState, setModalState] = useState({
    show: false,
    id: null,
    planType: null,
    isFetchingProrate: false,
  });

  const [state, dispatch] = useReducer(reducer, initialState);

  const basePlan = find(plans, (plan) => plan.order === 2);
  const premiumPlan = find(plans, (plan) => plan.order === 1);
  const cornerPlan = find(plans, (plan) => plan.order === 3);

  const [showDropdown, setshowDropdown] = useState(false);

  // Used only to track active billing cycle
  const term = !isEmpty(membership) ? membership.term : null;
  const [activeBillingCycle, setActiveBillingCycle] = useState(term);

  // For cancel plan
  const [
    showCancelConfirmationModal,
    setShowCancelConfirmationModal,
  ] = useState(false);

  // To reverse cancellation
  const [
    reverseCancelSubscriptionModal,
    setReverseCancelConfirmationModal,
  ] = useState(false);

  const [
    subscriptionDurationModalState,
    setSubscriptionDurationModalState,
  ] = useState({
    show: false,
    term: null,
    prorateCost: null,
    isSubcriptionCycleChanging: false,
  });

  const [planSwichEligibility, setPlanSwitchEligibility] = useState({
    show: false,
    content: null
  })

  const [cancellationRules, setCancellationRules] = useState({
    show: false,
    content: null
  })

  const subscriptionCancelled =
    membership && membership.membership_renewal === false;

  const [paymentMethodState, updatePaymentMethodState] = useState({
    show: false,
  });

  const [billingInfoState, setBillingInfoState] = useState(false);
  const [
    paypalConfirmationModalState,
    setPaypalConfirmationModalState,
  ] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      axios({
        method: "GET",
        url: auth.fetchGifts(),
        headers: {
          Authorization: `Token token="${localStorage.getItem("token")}"`,
        },
        data: {},
      })
        .then((response) => {
          let gifts = response.data.gifts;
          dispatch({
            type: "GIFTS",
            payload: {
              gifts: gifts,
              giftFetchStatus: {
                fetched: true,
                error: null,
              },
            },
          });
        })
        .catch((e) => {
          dispatch({
            type: "GIFTS-ERROR",
            payload: {
              gifts: [],
              giftFetchStatus: {
                fetched: true,
                error: "Something went wrong.",
              },
            },
          });
          const eventId = pushToSentry(e);
          props.addSentryError({
            error: e.message,
            eventId,
          });
        });
    }
  }, []);

  useEffect(() => {
    if (!isNil(userDetails.paypalConfirmationLink)) {
      setPaypalConfirmationModalState(true);
    }
  }, [userDetails.paypalConfirmationLink]);

  // for getiing membership origin info
  const getSignUpOnInfo = (membership) => {
    if (membership.origin === "stripe") {
      return "Website";
    } else if (membership.origin === "ios") {
      return "iPhone";
    } else {
      return "Not available";
    }
  };

  const getOrigin = (membership) => {
    return membership.origin;
  };

  // for getting billing info
  const getBillingInfo = (membership) => {
    if (
      membership.origin === "stripe" ||
      !isNil(membership.payment_method.card_type)
    ) {
      let card = null;
      switch (membership.payment_method.card_type) {
        case "Visa":
          card = <VisaCard />;
          break;
        case "MasterCard":
          card = <MasterCard />;
          break;
        case "Discover":
          card = <DiscoverCard />;
          break;
      }
      return (
        <>
          {card}
          {!isNil(membership.payment_method.card_last4)
            ? `Card ending ${membership.payment_method.card_last4}`
            : "Card Details Not available"}
          <span
            className="Card-edit"
            onClick={() => {
              updatePaymentMethodState({
                ...paymentMethodState,
                show: true,
              });
            }}
          >
            Edit
          </span>
        </>
      );
    } else if (
      membership.origin === "paypal" ||
      membership.origin === "Paypal"
    ) {
      return "Paypal";
    } else if (membership.origin === "ios") {
      return "iTunes";
    } else if (membership.origin === "android") {
      return "Google Play";
    } else if (membership.payment_method.origin === "paypal") {
      return "Paypal";
    } else {
      return "Info not available !";
    }
  };

  const getBillingCycleInfo = (membership) => {
    const isStripe =
      !isGrandFather && membership.payment_method.origin === "stripe";
    if (isStripe) {
      if (activeBillingCycle === "1 month") {
        return (
          <Selector
            selectorList={[
              {
                title: "Monthly",
                value: "1 month",
              },
              {
                title: "Annually",
                value: "12 months",
              },
            ]}
            onSelectValue={handleChangeBillingCycle}
            value={activeBillingCycle}
          />
        );
      } else {
        return "Annual";
      }
    } else {
      return membership.term === "1 month" ? "Monthly" : "Annual";
    }
  };

  const getBeforeCancelAccountContent = (membership) => {
    if (membership.payment_method.origin === "ios") {
      return (
        showDropdown && (
          <>
            <div className="detail-text">
              Unfortunately Apple handles its own subscriptions. We are unable
              to allow users to cancel their accounts from inside this dashboard
              area. To cancel your subscription you will need to follow these
              instructions:
            </div>
            <br />
            <ol className="detail-lists">
              <li>Open up settings on your iPhone </li>
              <li>Tap on your name/avatar</li>
              <li>Tap on iTunes & App Store </li>
              <li>Tap on Apple ID: your email</li>
              <li>Tap View Apple ID</li>
              <li>Then login</li>
              <li>Tap on subscriptions</li>
              <li>Tap cancel subscription </li>
            </ol>
          </>
        )
      );
    } else {
      return (
        showDropdown && (
          <div className="detail detail-text">
            You can cancel your Dashing Dish Membership at any time for any
            reason, no questions asked. If you cancel your subscription, your
            favorites and other saved info will be placed on hold for one month
            unless you reactivate your subscription.
            <br></br>
            <br></br>
            If you cancel your subscription, your account will be closed and you
            won’t be able to access it again in the future.
            <span onClick={() => setShowCancelConfirmationModal(true)}>
              Cancel my account.
            </span>
          </div>
        )
      );
    }
  };

  useEffect(() => {
    setActiveBillingCycle(term);
  }, [term]);

  useEffect(() => {
    if (changeCardTypeFetchStatus.isUpdated) {
      updatePaymentMethodState({
        show: false,
      });
    }
  }, [changeCardTypeFetchStatus.isUpdated]);

  useEffect(() => {
    if (changePlanFetchStatus.isUpdated) {
      setModalState({
        ...modalState,
        show: false,
      });

      setSubscriptionDurationModalState({
        show: false,
        value: null,
      });
    }
  }, [changePlanFetchStatus.isUpdated]);

  useEffect(() => {
    if (cancelSubscriptionFetchStatus.isUpdated) {
      setShowCancelConfirmationModal(false);
    }
  }, [cancelSubscriptionFetchStatus.isUpdated]);

  useEffect(() => {
    if (reverseCancelSubscriptionFetchStatus.isUpdated) {
      setReverseCancelConfirmationModal(false);
    }
  }, [reverseCancelSubscriptionFetchStatus.isUpdated]);

  const isGrandFather =
    (userDetails && userDetails.details && !userDetails.details.plan) ||
    (userDetails.details.plan &&
      findIndex(plans, (plan) => plan.id === userDetails.details.plan.id) ===
      -1);
  const isWebsiteSigning =
    membership &&
    (membership.origin === "stripe" || membership.origin === "paypal");

  const currentPlan = (userDetails?.details?.plan &&
    find(plans, (plan) => plan.id === userDetails.details.plan.id));

  // If current plan is grandfather plan
  const moveFromPlan = isGrandFather
    ? {
      name: "grandfathered",
      image: GrandFatherIcon,
      isGrandFather: true,
    }
    : currentPlan;

  const getPlanTypeFromPlan = (plan) => {
    if (!plan || plan.order === 1 || plan.order === null) {
      return 'premium';
    }
    if (plan.order === 2) {
      return 'base';
    }
    if (plan.order === 3) {
      return 'corner';
    }
  }
  const getPlanByPlanType = (type) => {
    if (type === 'base') {
      return basePlan;
    }
    if (type === 'premium') {
      return premiumPlan;
    }
    if (type === 'corner') {
      return cornerPlan;
    }
  }
  const moveToPlan = getPlanByPlanType(modalState.planType);

  const moveToPlanPrice = moveToPlan ? (activeBillingCycle === '1 month' ? moveToPlan.monthly_price : moveToPlan.annual_price) : null;
  const moveToPlanDuration = moveToPlan ? (activeBillingCycle === '1 month' ? 'month' : 'year') : null;
  const moveToPremium = modalState.planType === "premium";

  const handlePlanChange = (planType) => {
    const origin = getOrigin(membership);
    if (origin === "stripe") {
      setModalState({
        ...modalState,
        show: true,
        isFetchingProrate: true,
        planType,
        planId: getPlanByPlanType(planType).id
      });

      axios({
        method: "POST",
        url: auth.getProrationCosts(),
        headers: {
          Authorization: `Token token="${localStorage.getItem("token")}"`,
        },
        data: {
          new_plan_id: getPlanByPlanType(planType).id,
          duration: activeBillingCycle === "1 month" ? 1 : 12,
        },
      })
        .then((response) => {
          if (planType === "base") {
            setModalState({
              ...modalState,
              show: true,
              isFetchingProrate: false,
              planId: basePlan.id,
              planType: "base",
              prorateCost: response.data.cost,
            });
          } else if (planType === 'premium') {
            setModalState({
              ...modalState,
              show: true,
              isFetchingProrate: false,
              planId: premiumPlan.id,
              planType: "premium",
              prorateCost: response.data.cost,
            });
          } else if (planType === 'corner') {
            setModalState({
              ...modalState,
              show: true,
              isFetchingProrate: false,
              planId: cornerPlan.id,
              planType: "corner",
              prorateCost: response.data.cost,
            });
          }
        })
        .catch((e) => {
          setModalState({
            ...modalState,
            show: true,
            error: true,
            isFetchingProrate: false,
          });
          const eventId = pushToSentry(e);
          props.addSentryError({
            error: e.message,
            eventId,
          });
        });
    } else if (origin === "Paypal" || origin === "paypal") {
      if (planType === "base") {
        setModalState({
          ...modalState,
          show: true,
          isFetchingProrate: false,
          planId: basePlan.id,
          planType: "base",
        });
      } else if (planType === 'premium') {
        setModalState({
          ...modalState,
          show: true,
          isFetchingProrate: false,
          planId: premiumPlan.id,
          planType: "premium",
        });
      } else if (planType === 'corner') {
        setModalState({
          ...modalState,
          show: true,
          isFetchingProrate: false,
          planId: cornerPlan.id,
          planType: "corner",
        });
      }
    }
  };

  const handleChangeBillingCycle = (value) => {
    setSubscriptionDurationModalState({
      ...subscriptionDurationModalState,
      show: true,
      term: value,
      isSubcriptionCycleChanging: true,
    });

    axios({
      method: "POST",
      url: auth.getProrationCosts(),
      headers: {
        Authorization: `Token token="${localStorage.getItem("token")}"`,
      },
      data: {
        new_plan_id: plan.id,
        duration: value === "1 month" ? 1 : 12,
      },
    })
      .then((response) => {
        setSubscriptionDurationModalState({
          ...subscriptionDurationModalState,
          show: true,
          term: value,
          prorateCost: response.data.cost,
          isSubcriptionCycleChanging: false,
        });
      })
      .catch((e) => {
        const eventId = pushToSentry(e);
        props.addSentryError({
          error: e.message,
          eventId,
        });
      });
  };

  const deletePaypalPlan = () => {
    dispatch({
      type: "DELETE-PAYPAL-SUBSCRIPTION",
    });
    axios({
      method: "POST",
      url: auth.deletePaypalSubscription(),
      headers: {
        Authorization: `Token token="${localStorage.getItem("token")}"`,
      },
      data: {
        reason: 'Subscription change'
      },
    })
      .then((response) => {
        console.log('status ', response)
        if (response.data.success) {
          dispatch({
            type: "DELETE-PAYPAL-SUBSCRIPTION-SUCCESS",
          });
          props.showToastMessage(
            "Your subscription has been deleted. Redirecting you to login page to reactivate",
            "success"
          );

          setTimeout(() => {
            window.location = '/login'
          }, 5000)
        } else {
          throw "Failed to delete subscription ! Contact support@dashingdish.com";
        }
      })
      .catch((e) => {
        dispatch({
          type: "DELETE-PAYPAL-SUBSCRIPTION-FAILURE",
        });
        props.showToastMessage(
          "Failed to delete subscription, Contact support@dashingdish.com",
          "error"
        );
      });
  }

  const applyGift = (gift) => {
    dispatch({
      type: "GIFTS-APPLY",
    });
    axios({
      method: "POST",
      url: auth.applyGift(),
      headers: {
        Authorization: `Token token="${localStorage.getItem("token")}"`,
      },
      data: {
        code: gift.activation_code,
      },
    })
      .then((response) => {
        if (response.data.status === "success") {
          let gifts = reduce(
            state.gifts,
            (accumulator, item) => {
              if (item.id === gift.id) {
                item.status = "activated";
              }
              return accumulator.concat(item);
            },
            []
          );
          dispatch({
            type: "GIFTS-APPLY-SUCCESS",
            payload: {
              gifts: gifts,
              trialNextBillingDate: response.data.new_period_end,
            },
          });
          props.showToastMessage(
            "You have successfully avail the gift!",
            "success"
          );
        } else {
          throw "something went wrong !";
        }
      })
      .catch((e) => {
        dispatch({
          type: "GIFTS-APPLY-ERROR",
          payload: {},
        });
        const eventId = pushToSentry(e);
        props.addSentryError({
          error: e.message,
          eventId,
        });
        props.showToastMessage(
          "Failed availing gift, Contact support@dashingdish.com",
          "error"
        );
      });
  };

  const testAccount = isEmpty(membership) || isEmpty(membership.payment_method);

  const getGiftContent = () => {
    return null
    if (state.gifts.length > 0 && state.giftFetchStatus.fetched) {
      return (
        <div className="gift-history">
          <ul>
            {map(state.gifts, (gift) => {
              return (
                <>
                  {(gift.status === "pending" ||
                    gift.status === "delivered") && (
                      <li style={{ display: "flex" }}>
                        <span>
                          {`You got a gift from ${gift.from_email}`}&nbsp;.
                        </span>
                        To avail, click
                        <span
                          onClick={() => {
                            applyGift(gift);
                          }}
                          className="activate-action"
                        >
                          <LinkButton
                            renderValue={() => <>here</>}
                            isLoading={state.applyGift.giftApplyStatus}
                          />
                        </span>
                      </li>
                    )}
                </>
              );
            })}
          </ul>
          <ul>
            {map(state.gifts, (gift) => {
              return (
                <>
                  {gift.status === "activated" && (
                    <li style={{ display: "flex" }}>
                      <span>{`You activated gift, thanks to a gift from ${capitalize(
                        gift.from_name
                      )}`}</span>
                    </li>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      );
    } else {
      return null;
    }
  };

  const prorateModal = () => {
    return modalState.show ? (
      <ProrateModal
        userDetails={userDetails}
        origin={getOrigin(membership)}
        modalState={modalState}
        moveFromPlan={moveFromPlan}
        moveToPlan={moveToPlan}
        moveToPremium={moveToPremium}
        moveToPlanType={getPlanTypeFromPlan(moveToPlan)}
        moveFromPlanType={getPlanTypeFromPlan(moveFromPlan)}
        membership={membership}
        changePlanFetchStatus={changePlanFetchStatus}
        setModalState={setModalState}
        changePlan={changePlan}
        activeBillingCycle={activeBillingCycle}
        deletePaypalPlan={deletePaypalPlan}
        deleteStatus={state.paypalPlanDeleteStatus}
        moveToPlanPrice={moveToPlanPrice}
        moveToPlanDuration={moveToPlanDuration}
      />
    ) : null;
  };

  const paypalConfirmationModal = () => {
    return paypalConfirmationModalState ? (
      <PaypalConfirmationModal
        paypalConfirmationLink={userDetails.paypalConfirmationLink}
        paypalConfirmationModalState={paypalConfirmationModalState}
        setPaypalConfirmationModalState={setPaypalConfirmationModalState}
      />
    ) : null;
  };

  const cancelConfirmationModal = () => {
    return showCancelConfirmationModal ? (
      <Modalwrapper
        isOpen={showCancelConfirmationModal}
        minHeight="300px"
        maxWidth="450px"
        onRequestClose={() => {
          setShowCancelConfirmationModal(false);
        }}
      >
        <div className="modalWrapper">
          <div className="modalBody confirmationModal">
            <h5>Cancel Account</h5>
            <p>
              If you cancel your subscription, your account will be closed and
              you won’t be able to access it again in the future. Are you sure ?
            </p>
          </div>
          <div className="modalFooter">
            <Button
              btnText="Confirm"
              btnClass="btn btn-full btn-primary"
              isLoading={cancelSubscriptionFetchStatus.isUpdating}
              disabled={cancelSubscriptionFetchStatus.isUpdating}
              onClick={() => {
                cancelSubscription();
              }}
            />
            <Button
              btnText="Cancel"
              btnClass="btn btn-full btn-secondary btn-bp-sm"
              onClick={() => {
                setShowCancelConfirmationModal(false);
              }}
            />
          </div>
        </div>
      </Modalwrapper>
    ) : null;
  };

  const subscriptionDurationModal = () => {
    return subscriptionDurationModalState.show ? (
      <Modalwrapper
        isOpen={subscriptionDurationModalState.show}
        minHeight="460px"
        maxWidth="450px"
        onRequestClose={() => {
          setSubscriptionDurationModalState({
            ...subscriptionDurationModalState,
            show: false,
          });
        }}
      >
        <div className="modalWrapper switch-plan-modal">
          <div className="modalBody">
            <div className="icons-wapper">
              <figure>
                <SwitchIcon />
              </figure>
            </div>
            <h5>
              Are you sure you’d like to switch from monthly billing to annual
              billing?
            </h5>
            <p>
              This will{" "}
              {subscriptionDurationModalState.prorateCost < 0
                ? "credit"
                : "debit"}{" "}
              your account ending in{" "}
              <span className="card_last">
                {membership.payment_method.card_last4} for the existing
                subscription
              </span>
              <br></br>
              <span className="bold">
                ${Math.abs(subscriptionDurationModalState.prorateCost) / 100}
              </span>
              .
            </p>
          </div>
          <div className="modalFooter">
            <Button
              btnText="Confirm"
              btnClass="btn btn-full btn-primary"
              isLoading={changePlanFetchStatus.isUpdating}
              disabled={changePlanFetchStatus.isUpdating}
              onClick={() => {
                changePlan(
                  plan.id,
                  "12 months", // this will be always 12 months
                  getOrigin(membership)
                );
              }}
            />
            <Button
              btnText="Cancel"
              btnClass="btn btn-full btn-secondary btn-bp-sm"
              onClick={() => {
                setSubscriptionDurationModalState({
                  ...subscriptionDurationModalState,
                  show: false,
                });
              }}
            />
          </div>
        </div>
      </Modalwrapper>
    ) : null;
  };

  const reverseCancellSubscriptionModal = () => {
    return reverseCancelSubscriptionModal ? (
      <Modalwrapper
        isOpen={reverseCancelSubscriptionModal}
        minHeight="300px"
        maxWidth="450px"
        onRequestClose={() => {
          setReverseCancelConfirmationModal(false);
        }}
      >
        <div className="modalWrapper">
          <div className="modalBody confirmationModal">
            <h5>Turn on Auto renewal</h5>
            <p>
              Turning on Auto renewal will undo your subscription cancellation
              and your subscription will continue. Are you sure ?
            </p>
          </div>
          <div className="modalFooter">
            <Button
              btnText="Confirm"
              btnClass="btn btn-full btn-primary"
              isLoading={reverseCancelSubscriptionFetchStatus.isUpdating}
              disabled={reverseCancelSubscriptionFetchStatus.isUpdating}
              onClick={() => {
                reverseCancelSubscription();
              }}
            />
            <Button
              btnText="Cancel"
              btnClass="btn btn-full btn-secondary btn-bp-sm"
              onClick={() => {
                // changePlan(modalState.planId)
                setModalState({
                  ...modalState,
                  show: false,
                });
              }}
            />
          </div>
        </div>
      </Modalwrapper>
    ) : null;
  };

  const paymentMethodModal = () => {
    return paymentMethodState.show ? (
      <Modalwrapper
        isOpen={paymentMethodState}
        minHeight="400px"
        maxWidth="450px"
        onRequestClose={() => {
          updatePaymentMethodState({
            ...paymentMethodState,
            show: false,
          });
        }}
      >
        <div className="modalWrapper">
          <div className="modalHeader" style={{ justifyContent: "center" }}>
            <h3>Update Payment method</h3>
          </div>
          <UpdatePayment
            isStripe={Stripe}
            onClose={() => {
              updatePaymentMethodState({
                ...paymentMethodState,
                show: false,
              });
            }}
            changePaymentType={changePaymentType}
            formSubmitStatus={changeCardTypeFetchStatus.isUpdating}
          />
        </div>
      </Modalwrapper>
    ) : null;
  };

  const renderPlanCard = (actualPlan, planType, userPlan, membership) => {
    const isPlanSwitchEligible =
      membership &&
      membership.origin === 'stripe' ||
      membership.origin == "paypal"

    return (
      <div
        className={cx(
          "plan",
          {
            active: userPlan && actualPlan && userPlan.id === actualPlan.id,
          },
          {
            disabled: !isPlanSwitchEligible
          }
        )}
        onClick={() => {
          if (isPlanSwitchEligible) {
            handlePlanChange(planType);
          }
        }}
        data-for={planType}
        data-tip
      >
        <figure>
          <img src={actualPlan && actualPlan.image} />
        </figure>
        <span>{actualPlan && actualPlan.name}</span>
      </div>
    );
  };

  const getBillingInfoContent = () => {
    return (
      <div className="billing-info-container">
        <p>
          If you want to use Paypal for subscription, you need to wait till your
          gift expiration date.{" "}
        </p>
        <p>After expire of gift you can renew your subscription with paypal</p>
      </div>
    );
  };

  const renderMembershipRow = (
    title,
    value,
    additionalWrapperClass,
    additionValueClass
  ) => {
    return (
      <div className={cx("detail", additionalWrapperClass)}>
        <div className="space-between">
          <div className="info-container" style={{ display: "flex" }}>
            <span>{title}</span>
            {title === "Billing Info" &&
              membership.payment_method.origin === "stripe" &&
              isNil(membership.payment_method.card_type)}
          </div>
          <span className={cx("bold-text", additionValueClass)}>{value}</span>
        </div>
      </div>
    );
  };

  const getSignedUpOn = () => {
    if (membership.origin === "ios") return "iPhone";
    if (membership.origin === "android") return "Android";
    if (membership.origin === "paypal") return "paypal";
    if (membership.origin === "stripe") return "stripe";
  };

  const stripeCancellationContent = () => {
    return (
      <div className="modalWrapper">
        <div className="modalHeader" style={{ justifyContent: "center" }}>
          <h3>Cancelling your account</h3>
        </div>
        <div className="modalBody">
          <p className="left-align">
            You can cancel your Dashing Dish Membership at any time for any
            reason, no questions asked.
            <br />
            <br />
            If you cancel in this dashboard, the corresponding
            stripe subscription will also get cancelled at the end of the time period.
            <br />
            <br />
            If you cancel your subscription, your favorites and other saved info
            will be placed on hold for one month unless you reactivate your subscription.
            <br />
            <br />
            If you cancel your subscription, your account will be closed and you
            won’t be able to access it again in the future.
            <br />
            <br />
            Your account will be still accessible until the expiry time (until what you have payed for),
            If you change your mind during that time, then you can turn on auto renew in the membership page.
          </p>
        </div>
      </div>
    );
  }

  const paypalCancellationContent = () => {
    return (
      <div className="modalWrapper">
        <div className="modalHeader" style={{ justifyContent: "center" }}>
          <h3>Cancelling your account</h3>
        </div>
        <div className="modalBody">
          <p className="left-align">
            You can cancel your Dashing Dish Membership at any time for any
            reason, no questions asked.
            <br />
            <br />
            If you cancel in this dashboard, the corresponding
            paypal subscription will also get cancelled at the end of the time period.
            <br />
            <br />
            If you cancel your subscription, your favorites and other saved info
            will be placed on hold for one month unless you reactivate your subscription.
            <br />
            <br />
            If you cancel your subscription, your account will be closed and you
            won’t be able to access it again in the future.
            <br />
            <br />
            Your account will be still accessible until the expiry time (until what you have payed for),
            If you change your mind during that time, then you can turn on auto renew in the membership page.
          </p>
        </div>
      </div>
    );
  }

  const iosCancellationContent = () => {
    return (
      <div className="modalWrapper">
        <div className="modalHeader" style={{ justifyContent: "center" }}>
          <h3>Cancelling your account</h3>
        </div>
        <div className="modalBody">
          <p className="detail-text left-align">
            Unfortunately Apple handles its own subscriptions. We are unable
            to allow users to switch their plans from inside this dashboard
            area. To switch the plans for your subscription you will need to follow these
            instructions:
          </p>
          <p className="left-align">
            <ol className="detail-lists">
              <li>Open up settings on your iPhone (with the same apple account that is used for registration)</li>
              <li>Tap on your name/avatar</li>
              <li>Tap on iTunes & App Store </li>
              <li>Tap on Apple ID: your email</li>
              <li>Tap View Apple ID</li>
              <li>Then login</li>
              <li>Tap on subscriptions</li>
              <li>Proceed to change the subscription plan</li>
            </ol>
          </p>
        </div>
      </div>
    );
  }

  const androidCancellationContent = () => {
    return (
      <div className="modalBody">
        <p className="detail-text left-align">
          Unfortunately Android handles its own subscriptions. We are unable
          to allow users to switch their plans from inside this dashboard
          area. To switch the plans for your subscription you will need to follow these
          instructions:
        </p>
        <p className="left-align">
          <ol className="detail-lists">
            <li>Open the Google Play app Google Play.</li>
            <li>At the top right, tap the profile icon.</li>
            <li>Tap Payments & subscriptions and then Subscriptions.</li>
            <li>Select the subscription you want to switch the plan.</li>
            <li>Tap Change plan to proceed</li>
          </ol>
        </p>
      </div>
    );
  }

  const renderMembershipCancellationRules = () => {
    let cancellationContent = null;

    const origin = getSignedUpOn();

    if (origin === 'stripe') {
      cancellationContent = stripeCancellationContent();
    } else if (origin === 'paypal') {
      cancellationContent = paypalCancellationContent();
    } else if (origin === 'iPhone') {
      cancellationContent = iosCancellationContent();
    } else if (origin === 'Android') {
      cancellationContent = androidCancellationContent();
    }

    return (
      <div>
        <p>
          <span
            className="text-highlight"
            onClick={() => setCancellationRules({
              ...cancellationRules,
              show: true,
              content: cancellationContent
            })}>Learn More</span> about cancelling your account
        </p>
        {
          cancellationRules.show ? (
            <Modalwrapper
              isOpen={cancellationRules.show}
              minHeight="500px"
              maxWidth="550px"
              onRequestClose={() => {
                setCancellationRules({
                  ...cancellationRules.show,
                  show: false,
                });
              }}
            >
              {cancellationRules.content}
            </Modalwrapper>
          ) : null
        }

      </div>
    )
  }

  const planSwichEligibilityModal = () => {
    return planSwichEligibility.show ? (
      <Modalwrapper
        isOpen={planSwichEligibility.show}
        minHeight="500px"
        maxWidth="550px"
        onRequestClose={() => {
          setPlanSwitchEligibility({
            ...planSwichEligibility,
            show: false,
          });
        }}
      >
        {planSwichEligibility.content}
      </Modalwrapper>
    ) : null;
  };

  const stripePlanSwitchEligibilityContent = () => {
    return (
      <div className="modalWrapper">
        <div className="modalHeader" style={{ justifyContent: "center" }}>
          <h3>When can you switch your plan?</h3>
        </div>

        <div className="modalBody">
          <p className="left-align">You are eligibile to switch your plans.</p>
          <p className="left-align">
            When you switch your plans, the prorated pricing will be adjusted on your account and you can access the features of the new plan immediately.
          </p>
          <p className="left-align">
            For example, if you downgrade your plan then difference in the price of the plan will be credited to your account.
          </p>
        </div>
      </div>
    )
  }

  const paypalPlanSwitchEligibilityContent = () => {
    return (
      <div className="modalWrapper">
        <div className="modalHeader" style={{ justifyContent: "center" }}>
          <h3>Plan switch on paypal</h3>
        </div>
        <div className="modalBody">
          {membership.status === 'trialing' ? (
            <p className="left-align">You are eligibile to switch your plans with your paypal account</p>
          ) : null}
          <p className="left-align">
            When you switch your plans, the plan change <b>won't get effected immediately</b>. This is due to the terms and conditions enforced by Paypal.
          </p>
          <p className="left-align">
            For example, if you upgrade your plan then the plan change will happen on the next billing cycle.
            You can access the features of the upgraded plan starting from next billing cycle.
          </p>
          <p className="left-align">
            For more details refer <a href="https://developer.paypal.com/docs/subscriptions/customize/revise-subscriptions/#link-billingchanges" target="_blank">Paypal Terms and conditions</a>
          </p>
        </div>
      </div>
    )
  }

  const iosPlanSwitchEligibilityContent = () => {
    return (
      <div className="modalWrapper">
        <div className="modalHeader" style={{ justifyContent: "center" }}>
          <h3>Plan switch on your iTunes Account</h3>
        </div>
        <div className="modalBody">
          <p className="detail-text left-align">
            Unfortunately Apple handles its own subscriptions. We are unable
            to allow users to switch their plans from inside this dashboard
            area. To switch the plans for your subscription you will need to follow these
            instructions:
          </p>
          <p className="left-align">
            <ol className="detail-lists">
              <li>Open up settings on your iPhone (with the same apple account that is used for registration)</li>
              <li>Tap on your name/avatar</li>
              <li>Tap on iTunes & App Store </li>
              <li>Tap on Apple ID: your email</li>
              <li>Tap View Apple ID</li>
              <li>Then login</li>
              <li>Tap on subscriptions</li>
              <li>Proceed to change the subscription plan</li>
            </ol>
          </p>
        </div>
      </div>
    )
  }

  const androidPlanSwitchEligibilityContent = () => {
    return (
      <div className="modalWrapper">
        <div className="modalHeader" style={{ justifyContent: "center" }}>
          <h3>Plan switch on your Android Account</h3>
        </div>
        <div className="modalBody">
          <p className="detail-text left-align">
            Unfortunately Android handles its own subscriptions. We are unable
            to allow users to switch their plans from inside this dashboard
            area. To switch the plans for your subscription you will need to follow these
            instructions:
          </p>
          <p className="left-align">
            <ol className="detail-lists">
              <li>Open the Google Play app Google Play.</li>
              <li>At the top right, tap the profile icon.</li>
              <li>Tap Payments & subscriptions and then Subscriptions.</li>
              <li>Select the subscription you want to switch the plan.</li>
              <li>Tap Change plan to proceed</li>
            </ol>
          </p>
        </div>
      </div>
    )
  }

  const renderPlanSwitchEligiblity = () => {
    const origin = getSignedUpOn();
    let planSwitchEligiblityContent = null;
    if (origin === "stripe") {
      // if (membership.status === 'trialing') {
      //   planSwitchEligiblityContent = (
      //     <div className="detail features">
      //       You are not eligible for plan switching.
      //       <br />
      //       <span
      //         className="text-highlight"
      //         onClick={() => setPlanSwitchEligibility({
      //           show: true,
      //           content: stripePlanSwitchEligibilityContent()
      //         })}
      //       >
      //         Learn More
      //       </span>
      //       &nbsp; to know about why.
      //     </div>
      //   )
      // } else {
      planSwitchEligiblityContent = (
        <div className="detail features">
          You are eligible for plan switching.
          <br />
          <span
            className="text-highlight"
            onClick={() => setPlanSwitchEligibility({
              show: true,
              content: stripePlanSwitchEligibilityContent()
            })}
          >
            Learn More
          </span>
          &nbsp; to know about switching plans.
        </div>
      )
      // }
    }

    if (origin === "paypal") {
      planSwitchEligiblityContent = (
        <div className="detail features">
          You are eligible for plan switching.
          <br />
          <span
            className="text-highlight"
            onClick={() => setPlanSwitchEligibility({
              show: true,
              content: paypalPlanSwitchEligibilityContent()
            })}
          >
            Learn More
          </span>
          &nbsp; about plan switching with your account on Paypal.
        </div>
      )
    }

    if (origin === "iPhone") {
      planSwitchEligiblityContent = (
        <div className="detail features">
          You are eligible for plan switching.
          <br />
          <span
            className="text-highlight"
            onClick={() => setPlanSwitchEligibility({
              show: true,
              content: iosPlanSwitchEligibilityContent()
            })}
          >
            Learn More
          </span>
          &nbsp; about how to switch plan on your iTunes device.
        </div>
      )
    }

    if (origin === "Android") {
      planSwitchEligiblityContent = (
        <div className="detail features">
          You are eligible for plan switching.
          <br />
          <span
            className="text-highlight"
            onClick={() => setPlanSwitchEligibility({
              show: true,
              content: androidPlanSwitchEligibilityContent()
            })}
          >
            Learn More
          </span>
          &nbsp; about how to switch plan on your Android device.
        </div>
      )
    }

    return planSwitchEligiblityContent;
  }

  const renderMembershipInformation = () => {
    return (
      <TransitionTwo>
        {
          plans.length === 0 ? (
            <div className="preLoader">
              <Loader />
            </div>
          ) : (
            <div>
              {getGiftContent()}
              <h3>Membership Info</h3>
              <div className="Plan-display">
                <span>Plan</span>
                <span className="bold-text">
                  {plan && !isGrandFather ? plan.name : "Grandfathered"}
                </span>
              </div>
              {testAccount ? (
                <div className="errorText">
                  <p>
                    Subscription details not available. Your account might be a test
                    account. Please contact admin.
                  </p>
                </div>
              ) : (
                <>
                  <div className="plan-chooser">
                    {
                      isGrandFather ? (
                        <div
                          className={cx("plan", {
                            disabled: !isWebsiteSigning,
                            active: isGrandFather
                          })}
                        >
                          <figure>
                            <GrandFatherIcon />
                          </figure>
                          <span>Grandfather</span>
                        </div>
                      ) : null
                    }

                    {renderPlanCard(basePlan, "base", plan, membership)}
                    {renderPlanCard(premiumPlan, "premium", plan, membership)}
                    {/* {renderPlanCard(cornerPlan, "corner", plan, membership)} */}
                  </div>
                  <div className="existing-membership-details">
                    <div className="detail features">
                      {plan && basePlan && plan.name === basePlan.name
                        ? `Your "${basePlan &&
                        basePlan.name}" plan gives you access to all of Dashing Dish’s recipes, workouts, videos, and community forum.`
                        :
                        plan && premiumPlan && plan.name === premiumPlan.name ? (
                          `Your "${premiumPlan &&
                          premiumPlan.name}" plan gives you access to all of Dashing Dish’s recipes, workouts, videos, community forum and enables you to build meal plan, weekly premade meal plans and grocery list orgainizer.`
                        ) : (
                          plan && premiumPlan && plan.name === cornerPlan.name ? (
                            null
                            // `Your "${cornerPlan &&
                            // cornerPlan.name}" plan gives you access to all of Dashing Dish’s recipes, workouts, videos, community forum and enables you to build meal plan, weekly premade meal plans and grocery list orgainizer and The nourish.`
                          ) : null
                        )
                      }
                    </div>
                    {renderPlanSwitchEligiblity()}
                    {renderMembershipRow(
                      "Billing Cycle",
                      getBillingCycleInfo(membership),
                      "billing-cycle"
                    )}
                    {renderMembershipRow(
                      "Billing Info",
                      getBillingInfo(membership),
                      "billing-info",
                      "card-preview"
                    )}
                    {renderMembershipRow(
                      "Subscription Cost",
                      `$${membership.amount / 100}${activeBillingCycle == "1 month" ? "/mo" : "/year"
                      }`,
                      "billing-info"
                    )}
                    {renderMembershipRow(
                      "Next Billing Date",
                      `${state.trialNextBillingDate
                        ? moment
                          .unix(state.trialNextBillingDate)
                          .format("MM/DD/YY")
                        : moment.unix(membership.expires_at).format("MM/DD/YY")
                      }`,
                      "detail device"
                    )}
                    {renderMembershipRow(
                      "Signed Up on",
                      `${isWebsiteSigning ? "Website" : getSignedUpOn()}`,
                      "detail device"
                    )}

                    {isWebsiteSigning ? (
                      !subscriptionCancelled ? (
                        <div className="detail account">
                          <p onClick={() => setshowDropdown(!showDropdown)}>
                            <span>Cancel account</span>
                            {showDropdown ? <UpArrow /> : <DownArrow />}
                          </p>
                          {getBeforeCancelAccountContent(membership)}
                        </div>
                      ) : (
                        <>
                          <p onClick={() => setshowDropdown(!showDropdown)}>
                            <span>Turn on autorenew</span>
                            {showDropdown ? <UpArrow /> : <DownArrow />}
                          </p>
                          {showDropdown && (
                            <div className="detail detail-text">
                              Your membership will expire from{" "}
                              {moment
                                .unix(membership.expires_at)
                                .format("MM/DD/YYYY")}
                              .<br></br>
                              <br></br>
                              If you'd like to keep your membership
                              <span
                                onClick={() =>
                                  setReverseCancelConfirmationModal(true)
                                }
                              >
                                Turn on Autorenew
                              </span>
                            </div>
                          )}
                        </>
                      )
                    ) : null}
                    {renderMembershipCancellationRules()}
                  </div>
                </>
              )}
            </div>
          )
        }

      </TransitionTwo>
    );
  };

  return (
    <div className="account-content-wrapper">
      <div className="membership-wrapper">
        {prorateModal()}
        {paypalConfirmationModal()}
        {reverseCancellSubscriptionModal()}
        {cancelConfirmationModal()}
        {subscriptionDurationModal()}
        {paymentMethodModal()}
        {planSwichEligibilityModal()}
        <Row className="no-margin">
          <Col xs={12} lg={3} className="account-content-left-wrapper">
            <TransitionOne>
              <Avatar
                src={avatar.medium}
                memberSince={memberSince}
                name={name}
              />
            </TransitionOne>
          </Col>

          <Col xs={12} lg={9} className="account-content-right-wrapper">
            {renderMembershipInformation()}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Membership;
