import { put, call, select, all } from 'redux-saga/effects'
import isNil from 'lodash/isNil';

import {
  getToken,
  getUserSpecificCachedForUser,
  shouldRefetchGroceryList,
  getUserDetails
} from '../selectors'

import * as actions from '../../actions/actionTypes'
import groceryTask from '../subtask/grocery'
import mealplan from '../subtask/mealplan'

import getUsePlanType from '../../helpers/getUserPlanType'
import pushToSentry from '../../helpers/pushToSentry';
import retrier from '../process/retrier'

const processGroceryListData = data => {

  const categories = data.categories;

  console.log('categories ', data);
  const updatedCategories = categories?.map(category => {
    const categoryItems = category.items;

    const updatedCategoryItems = categoryItems
      .map(item => {


        if (
          item.conversion_rules &&
          item.conversion_rules.base_unit_singular &&
          item.conversion_rules.base_unit_plural
        ) {
          let updatedMaps = [
            item.maps || [],
            {
              factor: "1",
              base_unit_singular: item.conversion_rules.base_unit_singular,
              base_unit_plural: item.conversion_rules.base_unit_plural,
              unit_id: item.base_unit_id
            }
          ]

          return {
            ...item,
            conversion_rules: {
              ...item.conversion_rules,
              maps: updatedMaps
            }
          }
        }

        return item;
      })

    return {
      ...category,
      items: updatedCategoryItems
    }
  })

  return {
    ...data,
    categories: updatedCategories
  }
}

export default function* fetchUserEssentials({ refetchGroceryList }) {
  try {
    yield put({
      type: actions.USER_ESSENTIALS_FETCH
    })

    const tokenByAuthenticatedUser = yield select(getToken) // get current authEssential (which is loginInUser in store)
    const cachedForUser = yield select(getUserSpecificCachedForUser);
    const forceRefetchGroceryList = yield select(shouldRefetchGroceryList);

    const userDetails = yield select(getUserDetails)
    const userType = getUsePlanType(userDetails.toJS())
    // only fetch gorcery list if it is true, else fetch boards
    if ((userType === "premiumPlan" || userType === 'grandfather')) {
      if (tokenByAuthenticatedUser && (forceRefetchGroceryList || refetchGroceryList)) {
        yield put({
          type: actions.USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH,
        })

        const groceryListArray = yield call(retrier, [call(groceryTask.get)], 'grocery list');

        const groceryListResponse = groceryListArray[0]

        if (!groceryListResponse) {
          const eventId = pushToSentry('Internet connection seems to be down grocery list')
          yield put({
            type: actions.ADD_SENTRY_ERROR,
            payload: {
              error: 'Internet connection seems to be down',
              eventId
            }
          })
        }

        const groceryListData = groceryListResponse.data
        yield put({
          type: actions.USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH_SUCCESS,
          payload: {
            groceryListData,
            forUser: tokenByAuthenticatedUser,
          }
        })
      } else if (!isNil(tokenByAuthenticatedUser) && (cachedForUser !== tokenByAuthenticatedUser)) {
        const [groceryListResponse, mealplanResponse] = yield call(retrier, [call(groceryTask.get), call(mealplan.get)], 'grocery list and meal plan');

        if (!groceryListResponse || !mealplanResponse) {
          const eventId = pushToSentry('Internet connection seems to be down - user essentials')
          yield put({
            type: actions.ADD_SENTRY_ERROR,
            payload: {
              error: 'Internet connection seems to be down',
              eventId
            }
          })
        }

        const mealPlanData = mealplanResponse?.data?.months
        const groceryListData = processGroceryListData(groceryListResponse?.data);

        yield put({
          type: actions.USER_ESSENTIALS_FETCH_SUCCESS,
          payload: {
            groceryListData,
            mealPlanData,
            forUser: tokenByAuthenticatedUser,
          }
        })
      }
    }


    yield put({
      type: actions.USER_ESSENTIALS_FETCH_CACHED,
    })
  } catch (e) {
    console.log(e)
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.USER_ESSENTIALS_FETCH_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}