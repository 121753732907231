import React from "react";
import reduce from "lodash/reduce";
import map from "lodash/map";
import { useTable } from 'react-table';

const Table = (props) => {
  // Use the state and functions returned from useTable to build your UI

  const columns = reduce(props.columns, (accumulator, header) => {

    return accumulator.concat({
      Header: header || 'N/A',
      accessor: header
    });
  }, []);

  const data = reduce(props.data, (accumulator, item, outIndex) => {
    if (outIndex > 0) {
      let obj = {};
      map(props.columns, (column, innIndex) => {
        obj[column] = item[innIndex]
      })
      return accumulator.concat(obj);
    }
    return accumulator
  }, []);

  const memoizedColumns = React.useMemo(() => columns, []);
  const memoizedData = React.useMemo(() => data, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns: memoizedColumns,
    data: memoizedData,
  })

  // Render the UI for your table
  return (
    <div className="workout-table">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(
            (row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            }
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Table;

