import React, { useEffect, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroller';
import ReactImageFallback from "react-image-fallback";
import moment from 'moment'
import cx from 'classnames'
import { fromJS } from 'immutable'
import concat from 'lodash/concat'
import toUpper from 'lodash/toUpper'
import map from 'lodash/map'
import filter from 'lodash/filter'
import capitalize from 'lodash/capitalize'
import findIndex from 'lodash/findIndex'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import reduce from 'lodash/reduce'

import { baseUrl } from '../../../helpers/api'

import ModalWrapper from '../../common/modal'
import CheckBox from '../../common/checkbox'
import ModalSearch from '../../common/modalSearch'

import useDataChooser from '../../../helpers/useAddRecipeToMealPlan'
import CloseIcon from '../../images/closeIcon';
import UpArrow from '../../images/UpArrow';
import DownArrow from '../../images/DownArrow';
import CustomMealPlan from './CustomMealPlan';
import getWorkoutImages from '../../../helpers/getWorkoutImages';
import GridCard from '../../common/GridCard'
import Filters from '../../common/filters'
import InputRange from '../../common/inputRange';
import CloseableTag from '../../common/closeableTag';
import Loader from '../../images/loader';
import ChangeSortOrder from '../../common/changeSortOrder'
import RecipeFallbackImage from '../../images/recipeplaceholder.jpg';
import WorkoutFallbackImage from '../../images/fallback-logo.png';
import ThumbNoteIcon from '../../images/ThumbNoteIcon';
import ThumbFavIcon from '../../images/ThumbFavIcon';

import {
  generateFilterFromQueryString,
  getRecipesDietariesNavOptions,
  getRecipesFoodTypesNavOptions,
  getRecipesSpecialitiesNavOptions,
  getFavouriteBoardsNavOptions,
  getWeightWatchersInputRangeOptions,
  getFilterOptions,
  getFavoriteFilterOptions,
  getSelectorList
} from '../../recipes/components/config'
import Favorite from '../../favorite'
import LinkButton from '../../common/linkButton';
import LeftArrow from '../../images/LeftArrow';
import RightArrow from '../../images/RightArrow';
import AddIcon from '../../images/AddIcon';
import AddedIcon from '../../images/AddedIcon';

let debouncer;


const AddModal = ({
  show,
  foodTypes,
  dietaries,
  specialities,
  addMeal,
  onRequestClose,
  boards,
  activeObjectType,
  isAuthenticated,
  searchRecipes,
  searchList,
  fetchStatus,
  recipesSearchCount,
  recipeFilterName,
  addedIds,
  activeDate,
  updateActiveDate,
}) => {

  const [state, dispatch] = useDataChooser({
    token: localStorage.getItem('token'),
    objectType: activeObjectType,
    recipeFilterGroup: {
      foodTypes,
      dietaries,
      specialities
    },
  });

  const [history, setHistory] = useState({
    location: {
      match: {
        url: ''
      }, // Just to prevent error 
      path: '/recipes', // Used majorly
      search: state.queryString // for hightlighting filter
    },
  })
  const [search, setSearch] = useState('');

  useEffect(() => {
    setHistory({
      ...history,
      location: {
        search: state.queryString
      }
    })
  }, [state.queryString])

  const recipeAddtionalStyle = { maxHeight: 220, minHeight: 220 };
  const workoutAddtionalStyle = { maxHeight: 130, minHeight: 130 };

  const renderMeta = (item) => {
    return (
      <div className="recipe-meta">
        <span className="cook-time">
          {
            (item.variations && item.variations.length > 0 && item.variations[0].cook_time) ? item.variations[0].cook_time.split(' ')[0] : '-'
          }
          {
            (item.variations && item.variations.length > 0 && item.variations[0].cook_time) ?
              (toUpper(item.variations[0].cook_time.split(' ')[1]) ? toUpper(item.variations[0].cook_time.split(' ')[1]).substring(0, 1) : 'M')
              : ''
          }
        </span>
        <span className="separation">|</span>
        <span className="calories">
          {item.variations && item.variations.length > 0 && item.variations[0].calories} cals
        </span>
        <span className="card-icons">
          {
            item.favorite ? (
              <ThumbFavIcon />
            ) : null
          }
          {
            item.notes ? (
              <ThumbNoteIcon />
            ) : null
          }
        </span>
      </div>
    )
  }

  const renderHoverEffect = (isItemAdded) => !isItemAdded ? (
    <div className="adding-wrapper add-wrapper">
      <figure>
        <AddIcon></AddIcon>
      </figure>
    </div>
  ) : (
      <div className="adding-wrapper added-wrapper">
        <figure>
          <AddedIcon></AddedIcon>
        </figure>
        <span>Recipe added!</span>
      </div>
    );

  const renderRecipeFilterTag = () => {
    if (state.filterSlugs.length > 0 || state.fp[1] !== 15 || state.sp[1] !== 15 || state.wwp[1] !== 15) {
      return (
        <div className="filterName">
          <div className="filterTitle">
            {
              map(state.filterSlugs, (filter) => {
                return (
                  <CloseableTag
                    key={filter.slug}
                    title={(filter.type === 'fp' || filter.type === 'sp' || filter.type === 'wwp') ? `${filter.name} ${filter.value}` : filter.name}
                    onClose={() => {
                      const updatedFilters = state.filterSlugs.filter(eachFilter => eachFilter.slug !== filter.slug);
                      dispatch({
                        type: 'UPDATE_FILTER_COMPLETELY',
                        payload: {
                          updatedFilters
                        }
                      })
                    }}
                  />
                )
              })
            }
            {
              state.fp[1] !== 15 && <CloseableTag
                key={filter.slug}
                title={`FP ${state.fp[1]}`}
                onClose={() => {
                  dispatch({
                    type: 'UPDATE_WEIGHTWATCHER',
                    payload: {
                      weightWatcherTitle: 'fp',
                      weightWatcherValue: 15
                    }
                  })
                }}
              />
            }
            {
              state.sp[1] !== 15 && <CloseableTag
                key={filter.slug}
                title={`SP ${state.sp[1]}`}
                onClose={() => {
                  dispatch({
                    type: 'UPDATE_WEIGHTWATCHER',
                    payload: {
                      weightWatcherTitle: 'sp',
                      weightWatcherValue: 15
                    }
                  })
                }}
              />
            }
            {
              state.wwp[1] !== 15 && <CloseableTag
                key={filter.slug}
                title={`WWP ${state.wwp[1]}`}
                onClose={() => {
                  dispatch({
                    type: 'UPDATE_WEIGHTWATCHER',
                    payload: {
                      weightWatcherTitle: 'wwp',
                      weightWatcherValue: 15
                    }
                  })
                }}
              />
            }
          </div>
        </div>
      );
    } else if (state.search) {
      return (
        <>
          <span className="filtered-text">{'Results for'}&nbsp;</span>
          {`${state.search}`}
        </>
      )
    } else if (state.dataType === 'favorite') {
      return (
        <h3 className="header-content">
          {`Recipes in ${find(boards, board => {
            return board.id === state.favoriteSlug
          }).title}`}
        </h3>
      )
    } else {
      return (
        <h3 className="header-content">
          {'Recent Recipes'}
        </h3>
      )
    }
  }

  const renderDynamicRecipeHeader = () => {
    if (state.filterSlugs.length > 0 || state.fp[1] !== 15 || state.sp[1] !== 15 || state.wwp[1] !== 15 || state.search.length > 0 || state.dataType === 'favorite') {
      return renderRecipeFilterTag();
    } else {
      return (
        <h3 className="header-content">
          Recent Recipes
        </h3>
      )
    }
  }

  // ALL GENERATED PROPS FOR NAV FILTER
  // the dietaries data 
  const recipesDietariesNavOptions = getRecipesDietariesNavOptions(dietaries, history)

  // the food type data
  const recipesFoodTypesNavOptions = getRecipesFoodTypesNavOptions(foodTypes, history)

  // specialities data
  const recipesSpecialitiesNavOptions = getRecipesSpecialitiesNavOptions(specialities, history)

  // the fav boards data
  const favouriteBoardsNavOptions = getFavouriteBoardsNavOptions(boards, recipeFilterName)

  const favoriteFilterOptions = getFavoriteFilterOptions(favouriteBoardsNavOptions, recipeFilterName)

  // the dymanic range logic for weightwatchers (the props for the input range)
  const weightWatchersInputRangeOptions = getWeightWatchersInputRangeOptions(history)


  // the weightwatchers options data
  const weightWatchersOptions = weightWatchersInputRangeOptions.map(option => {
    return (
      <InputRange
        label={option.label}
        labelClass={option.labelClass}
        wrapperClass={option.wrapperClass}
        minValue={option.minValue}
        maxValue={option.maxValue}
        onChangeComplete={(value, label) => {
          if (label === 'FP') {
            dispatch({
              type: 'UPDATE_WEIGHTWATCHER',
              payload: {
                weightWatcherTitle: 'fp',
                weightWatcherValue: value
              }
            })
          }
          if (label === 'SP') {
            dispatch({
              type: 'UPDATE_WEIGHTWATCHER',
              payload: {
                weightWatcherTitle: 'sp',
                weightWatcherValue: value
              }
            })
          }
          if (label === 'WWP') {
            dispatch({
              type: 'UPDATE_WEIGHTWATCHER',
              payload: {
                weightWatcherTitle: 'wwp',
                weightWatcherValue: value
              }
            })
          }
        }}
        defaultMinValue={option.defaultMinValue}
        defaultMaxValue={option.defaultMaxValue}
      />
    )
  })
  const isBoards = (history.location.path === '/recipes/fav-boards')
  const filterOptions = getFilterOptions(recipesFoodTypesNavOptions, recipesDietariesNavOptions, recipesSpecialitiesNavOptions, weightWatchersOptions, history)

  let preloaderRecipes = map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25], (item, index) => ({ isLoading: true, id: null }))

  // const modifiedPreloadedRecipes = state.offset > 0 ? preloaderRecipes : concat(state.data, preloaderRecipes)
  // const actualRecipes = state.isFetching ? modifiedPreloadedRecipes : state.data;

  const actualRecipes = state.data;
  const showClearText = state.filterSlugs.length > 0 || state.search || state.fp[1] !== 15 || state.sp[1] !== 15 || state.wwp[1] !== 15;

  const renderListItems = () => {

    return (
      <>
        {
          (!state.hasMore && !state.isFetching && state.data.length === 0) ? (
            <h3>
              No Recipe
            </h3>
          ) : (
              <ul className="recipeList in-modal">
                <>
                  {
                    map(actualRecipes, (item, index) => {
                      let coverImage;
                      let backupImage;

                      coverImage = item && item.images && item.images.full_sm;
                      backupImage = RecipeFallbackImage;

                      const isItemAdded = findIndex(addedIds, addedItem => item.id === addedItem) !== -1;
                      return (
                        <li
                          key={index}
                          className={cx('recipe', { 'added': isItemAdded })}
                        >
                          <GridCard
                            additionalStyles={recipeAddtionalStyle}
                            key={item.id}
                            feature={state.objectType}
                            objectType={state.objectType}
                            item={{
                              ...item,
                              coverImage
                            }}
                            renderMeta={renderMeta(item)}
                            thumbnail={coverImage}
                            coverImage={coverImage}
                            isAuthenticated={isAuthenticated}
                            showAction={false}
                            history={{ // Faking history
                              push: () => addMeal({
                                ...item,
                                objectType: state.objectType
                              })
                            }}

                            hoverEffect={renderHoverEffect(isItemAdded)}
                          />

                        </li>
                      )
                    })
                  }
                </>
              </ul>
            )
        }
      </>
    )
  }

  const isFavorites = history.location && history.location.path && history.location.path.split('/')[2] === 'favorites';
  const isFp = state.fp[1] !== 15;
  const isSp = state.sp[1] !== 15;
  const isWWP = state.wwp[1] !== 15;
  const isSearch = state.search.length > 0;
  const isSortOrderActive = !isFavorites && !isFp && !isSp && !isWWP && !isSearch;
  const startDate = moment().startOf('day').format('YYYY-MM-DD');
  const endDate = moment().startOf('day').add({ days: 28 }).format('YYYY-MM-DD');

  return (
    <ModalWrapper
      isOpen={show}
      minHeight="85vh"
      maxWidth="1140px"
      borderRadius="10px"
      onRequestClose={onRequestClose}
      additionalClass="modal-close-wrapper-mobile"
    >
      <div className="modalWrapper fullScreenModal">
        <div className="modalHeader fullScreenModalHeader">
          <div className="previous-day">
            <LeftArrow></LeftArrow>
            <LinkButton
              renderValue={() => <>{moment(activeDate).clone().subtract({ days: 1 }).format('dddd Do')}</>}
              onClick={(e) => updateActiveDate(moment(activeDate).clone().subtract({ days: 1 }).format('YYYY-MM-DD'))}
              disabled={!moment(activeDate).clone().subtract({ days: 1 }).isBetween(startDate, endDate, null, '[)')}
            />
          </div>
          <h3>{moment(activeDate).clone().format('dddd Do')}</h3>
          <div className="next-day">
            <LinkButton
              renderValue={() => <>{moment(activeDate).clone().add({ days: 1 }).format('dddd Do')}</>}
              onClick={(e) => updateActiveDate(moment(activeDate).clone().add({ days: 1 }).format('YYYY-MM-DD'))}
              disabled={!moment(activeDate).clone().add({ days: 1 }).isBetween(startDate, endDate, null, '[)')}
            />
            <RightArrow></RightArrow>
          </div>
        </div>
        <section className="recipes">
          <Filters
            withRoute={false}
            objectType='recipe'
            actions={(name, slug, filterName) => {

              setHistory({
                ...history,
                location: {
                  path: '/recipes/filters',
                }
              })
              let formattedFilterName;
              if (filterName === 'Food Type') {
                formattedFilterName = 'foodTypes';
              }
              if (filterName === 'Dietary') {
                formattedFilterName = 'dietaries';
              }
              if (filterName === 'Speciality') {
                formattedFilterName = 'specialities';
              }
              dispatch({
                type: 'UPDATE_FILTER',
                payload: {
                  previouslySelected: findIndex(state.filterSlugs, filter => filter.slug === slug) !== -1,
                  appFilter: { slug, name },
                  filterType: formattedFilterName
                }
              })
            }}
            showFilters={state.dataType !== 'favorite'}
            isFavorites={state.dataType === 'favorite'}
            options={filterOptions}
            staticOption={
              {
                primaryOption: 'Clear Filters',
                secondaryOption: 'All Recipes',
                route: '/recipes'
              }
            }
            onClickClearFilter={() => {
              dispatch({
                type: 'RESET_TO_INITIAL_STATE'
              })
            }}

            isAuthenticated={isAuthenticated}
            dropDownReturnActionText={`See all ${recipesSearchCount} Recipes`}
            dropDownReturnAction={(searchText, searchBy) => {
              dispatch({
                type: 'SET_SEARCH',
                payload: {
                  search: searchText,
                  searchBy: searchBy
                }
              })
            }}
            history={{
              ...history,
              push: (path) => {
                const splitUrl = path.split('/')
                if (splitUrl[1] === 'recipe') {
                  window.open(path, '_blank')
                }
                setHistory({
                  ...history,
                  location: {
                    path
                  }
                })
              }
            }}

            placeholder='Search recipes'
            searchText={state.search}
            onSearchChange={(searchText, searchBy) => {
              searchRecipes({
                objectType: 'recipe',
                searchText: searchText,
                searchBy: searchBy
              })
            }}
            onSearchByChange={(value, searchText) => {
              searchRecipes({
                objectType: 'recipe',
                searchText: searchText,
                searchBy: value
              })
            }}
            onClickSearchClear={() => {
              dispatch({
                type: 'RESET_TO_INITIAL_STATE'
              })
            }}
            defaultSearchText={state.search}
            defaultSearchBy="title"
            searchList={searchList}
            searchStatus={fetchStatus.recipesSearch}
            searchCount={recipesSearchCount}
            showDefaultInput={!isNil('title') || !isNil(filter.search)}
            selectorList={getSelectorList()}
            favoriteOptions={favoriteFilterOptions}
            showClearText={showClearText}
          />
          {
            !isBoards ? (
              <div className="container">
                <div className="calendar-modal-container">
                  <div className="scrollable-modal-container">
                    <InfiniteScroll
                      className="infiniteScroll"
                      initialLoad={false}
                      useWindow={false}
                      loadMore={!state.isFetching ? () => {
                        dispatch({
                          type: 'UPDATE_OFFSET',
                        })
                      } : () => { }}
                      hasMore={state.hasMore}
                      loader={
                        <div className="container">
                          <div className="preLoader-minimal-height">
                            <Loader></Loader>
                          </div>
                        </div>
                      }
                    >
                      <ChangeSortOrder
                        active={isSortOrderActive}
                        history={history}
                        objectType="recipe"
                        title={renderDynamicRecipeHeader()}
                        order={state.order}
                        history={{
                          ...history,
                          push: (path) => {
                            const filters = generateFilterFromQueryString('recipe', {
                              ...history,
                              location: {
                                search: path
                              }
                            })

                            setHistory({
                              ...history,
                              location: {
                                path,
                                search: path,
                              }
                            })

                            const order = filters.recipe.filters.order[0];

                            dispatch({
                              type: 'CHANGE_ORDER',
                              payload: {
                                order,
                                force: order === 'rand'
                              }
                            })
                          }
                        }}
                        setFiltersAndFetch={(filters) => {
                          const order = filters.recipe.filters.order[0];
                          dispatch({
                            type: 'CHANGE_ORDER',
                            payload: {
                              order,
                              force: order === 'rand'
                            }
                          })
                        }}
                      >
                        {renderListItems()}
                      </ChangeSortOrder>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            ) : (
                <Favorite
                  modal={true}
                  history={{
                    ...history,
                    push: (path) => {
                      const isFavoritePage = path.split('/')[1] && path.split('/')[2] === 'favorites'
                      const favoriteSlug = path.split('/')[3]

                      const favoriteBoard = find(boards, board => board.slug === favoriteSlug);

                      setHistory({
                        ...history,
                        location: {
                          path
                        }
                      })
                      if (isFavoritePage) {
                        // Coming to favorites page
                        dispatch({
                          type: 'CHANGE_DATA_TYPE_TO_FAVORITE',
                          payload: {
                            favoriteSlug: favoriteBoard ? favoriteBoard.id : null,
                            force: true,
                          }
                        })

                      } else {
                        if (state.dataType !== 'all') {
                          dispatch({
                            type: 'RESET_TO_INITIAL_STATE'
                          })
                        }
                      }
                    }
                  }}
                  match={{
                    url: '/recipes'
                  }}
                />
              )
          }

        </section>
      </div>
    </ModalWrapper>
  )
}

export default AddModal;