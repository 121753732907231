import React from "react";
import '../styles/common.scss';

const PaypalIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.0163 6.71741C22.6032 7.85872 22.7445 9.17393 22.4076 10.4239C21.7011 13.8805 18.6358 16.3478 15.1141 16.2935C14.7663 16.3044 11.538 16.2935 11.1902 16.2935L9.53802 22.8261H4.24454L4.61411 21.1848M12.7119 9.0871C12.8967 8.96754 13.0706 8.8371 13.2336 8.68493C13.6902 8.27189 14.0054 7.72841 14.1576 7.14145C14.4184 6.38058 14.1684 5.54362 13.5271 5.06536C13.1902 4.82623 12.788 4.6958 12.375 4.70667H9.77713L9.69017 5.06536L8.63582 9.57623H11.125C11.6902 9.56536 12.2337 9.40232 12.7119 9.0871ZM0.961914 19.5436L5.27713 1.07617H14.3097C17.0163 1.07617 20.0271 3.02182 19.125 7.14139C18.3967 10.6523 15.2554 13.1414 11.6684 13.0327H7.88583L6.26626 19.5436H0.961914Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
export default PaypalIcon;

