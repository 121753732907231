import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import { remove } from '../helpers/requests'
import { showToastMessage, showGroceryListLimitNotification } from '../actions';


import groceryTask from './subtask/grocery'
import { getToken, getGroceryList } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    // Should be item Ids
    const itemsAsPerApi = map(action.payload.fromGroupId,
      groupId => ({
        id: groupId,
        category_id: action.payload.toGroupId,
      })
    )
    yield call(groceryTask.updateCategories, {
      items: itemsAsPerApi
    })
    yield put({
      type: actions.MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST_SUCCESS,
      payload: action.payload
    })

    const groceryList = yield select(getGroceryList)

    const movedCategory = groceryList.getIn(['categories'])
      .filter(category => {
        return category.getIn(['id']) === action.payload.toGroupId
      })

    yield put(showToastMessage(`✅ Moved item to category ${!isEmpty(movedCategory) ? movedCategory.getIn([0 ,"name"]) : null }`, 'success'));

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST_FAILURE,
    })
  }
}

export default function* deleteIngredientsInGroceryList() {
  yield takeEvery(actions.MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST, task)
}