import React, { useEffect, useState } from "react";

import map from "lodash/map";

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { loadStripe } from '@stripe/stripe-js';

import Button from "../common/button";
import StripeIcon from "../images/StripeIcon";
import PaypalIcon from "../images/PaypalIcon";
const stripePromise = loadStripe(process.env.STRIPE_KEY);

// const handleBlur = () => {
//   console.log('[blur]');
// };
// const handleChange = (change) => {
//   console.log('[change]', change);
// };
// const handleClick = () => {
//   console.log('[click]');
// };
// const handleFocus = () => {
//   console.log('[focus]');
// };
// const handleReady = () => {
//   console.log('[ready]');
// };

const _CardForm = (props) => {
  const [numberComplete, setNumberComplete] = useState(false);
  const [expiryComplete, setExpiryComplete] = useState(false);
  const [cvcComplete, setCVCComplete] = useState(false);

  const [isClickedOnce, setIsClickedOnce] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (props.userDetails && !props.userDetails.isLoggedIn) {
      setIsClickedOnce(false);
    }
  }, [props.userDetails ? props.userDetails.isLoggedIn : null]);

  const handleChange = (type, complete) => {
    if (type === "number") {
      if (complete) {
        setNumberComplete(true);
      } else {
        setNumberComplete(false);
      }
    } else if (type === "expiry") {
      if (complete) {
        setExpiryComplete(true);
      } else {
        setExpiryComplete(false);
      }
    } else if (type === "cvc") {
      if (complete) {
        setCVCComplete(true);
      } else {
        setCVCComplete(false);
      }
    }
  };

  const isFormComplete =
    numberComplete && expiryComplete && cvcComplete && props.isFormValid;

  const handleSubmit = (ev) => {
    setIsClickedOnce(true);
    ev.preventDefault();
    const cardElement = elements.getElement(CardNumberElement);
    if (stripe) {
      stripe.createToken(cardElement).then((payload) => {
        props.changePaymentType(payload.token.id);
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <div className="paymet-option">Credit Card  |  Stripe</div> */}
      <div className="form-text">
        <label className="static-value">Card</label>
        <CardNumberElement
          // onBlur={handleBlur}
          onChange={(details) => handleChange("number", details.complete)}
        // onFocus={handleFocus}
        // onReady={handleReady}
        />
      </div>
      <div className="form-group">
        <div className="form-text">
          <label className="static-value">Exp</label>
          <CardExpiryElement
            // onBlur={handleBlur}
            onChange={(details) => handleChange("expiry", details.complete)}
          // onFocus={handleFocus}
          // onReady={handleReady}
          />
        </div>
        <div className="form-text">
          <label className="static-value">CVC</label>
          <CardCvcElement
            // onBlur={handleBlur}
            onChange={(details) => handleChange("cvc", details.complete)}
          // onFocus={handleFocus}
          // onReady={handleReady}
          />
        </div>
      </div>

      <Button
        btnClass="primary btn-full"
        btnText="Submit"
        isLoading={isClickedOnce || props.formSubmitStatus}
        disabled={isClickedOnce || props.formSubmitStatus || !isFormComplete}
      />
      {props.formError ? (
        <div className="errorText globalErrorText">
          <p>{props.formError}</p>
        </div>
      ) : null}
    </form>
  );
};

const CardForm = (_CardForm);

const Checkout = ({
  changePaymentType,
  onClose,
  formSubmitStatus,
  isFormValid,
  userDetails,
  formError,
}) => {
  return (
    <>
      <div className="checkout">

        <CardForm
          onClose={onClose}
          fontSize="18px"
          changePaymentType={changePaymentType}
          formSubmitStatus={formSubmitStatus}
          isFormValid={isFormValid}
          userDetails={userDetails}
          formError={formError}
        />

      </div>
      <div className="login-cookies">
        <p>CHECKOUT SECURELY WITH STRIPE | PAYPAL</p>
        <div className="paymentIcon">
          <figure>
            <StripeIcon />
          </figure>
          <figure>
            <PaypalIcon />
          </figure>
        </div>
      </div>
    </>
  );
};

const App = ({
  changePaymentType,
  onClose,
  formSubmitStatus,
  isFormValid,
  userDetails,
  formError,
}) => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout
        changePaymentType={changePaymentType}
        formSubmitStatus={formSubmitStatus}
        isFormValid={isFormValid}
        userDetails={userDetails}
        formError={formError}
      />
    </Elements>
  );
};

export default App;
