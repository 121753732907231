import React, { useState } from 'react'
import Radiobox from "../../common/radiobox";
import {
  addItemsToGroceryWaitlist,
  removeItemsFromGroceryWaitlist
} from "../../../actions";
import capitalize from 'lodash/capitalize';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TappableRow from '../../common/TappableRow';
import { getIngredientDetail, isIngredientQuantityPlural } from '../../../helpers/ingredientHelpers';

const IngredientRowV2 = (props) => {
  const {
    ingredient
  } = props;
  const [strikeThrough, setStrikeThrough] = useState(false);
  const onSelect = (selected, ingredient) => {
    if (selected) {

      props.addItemsToGroceryWaitlist([{ ...ingredient, recipeId: props.recipeId }])
    } else {
      props.removeItemsFromGroceryWaitlist(ingredient.ingredient.id)
    }
  }
  const listClass = strikeThrough ? `ingredientItem lineThrough` : `ingredientItem`;
  const {
    ingredientQuantityWithUnit,
    ingredientNameWithIndicator,
  } = getIngredientDetail(ingredient)

  return (
    <li
      className="groupList"
      key={props.key}
    >
      <Radiobox
        onSelect={(isChecked, content) => onSelect(isChecked, content)}
        type="checkbox"
        checkId={ingredient.id}
        checked={ingredient.selected}
        content={{ ingredient }}
        label={ingredientQuantityWithUnit}
        disabled={!props.isLoggedIn ? true : false}
      />
      <p className={listClass} onClick={() => {
        setStrikeThrough(!strikeThrough);
      }}>
        {ingredientNameWithIndicator}
      </p>
    </li>
  )
}


const dispatchActionToProps = dispatch => ({
  addItemsToGroceryWaitlist: bindActionCreators(addItemsToGroceryWaitlist, dispatch),
  removeItemsFromGroceryWaitlist: bindActionCreators(removeItemsFromGroceryWaitlist, dispatch),
});

//TODO: Remove connect (connect should only be at the root)
export default connect(null, dispatchActionToProps)(IngredientRowV2);