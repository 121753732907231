import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { showToastMessage, showGroceryListLimitNotification } from '../actions'
import { remove } from '../helpers/requests'
import { Iterable } from 'immutable'
import isNil from "lodash/isNil";
import toString from 'lodash/toString'
import map from 'lodash/map'
import filter from 'lodash/filter'

import groceryTask from './subtask/grocery'
import { getToken, getCalendarData } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import { getCategoryId } from '../helpers/categories'

function* task(action) {
  try {
    const limit = 500;
    const calendarListData = yield select(getCalendarData);

    const {
      payload: {
        date
      }
    } = action;

    const dateIndex = calendarListData
      .findIndex(eachDateData => eachDateData.getIn(['date']) === date);

    // Will be always true
    if (dateIndex !== -1) {
      const currentDateMeals = calendarListData.getIn([dateIndex, 'meals']);

      const items = currentDateMeals
        .toSeq()
        .reduce((accumulator, meal) => {

          const recipe = meal.getIn(['recipe']);
          if (recipe && !recipe.isEmpty()) {
            let ingredients = (meal.getIn(['recipe', 'ingredients_v2']));

            if (ingredients && Iterable.isIterable(ingredients)) {
              ingredients = ingredients.toJS();

              const recipeId = meal.getIn(['recipe', 'id']);
              return map(filter(ingredients, ingredient => !ingredient.header), ingredient => {
                let itemName = ingredient.ingredient?.display_singular_name ? ingredient.ingredient?.display_singular_name : '';
                let amount = ingredient.ingredient_unit?.display_singular_name ? ingredient.ingredient_unit?.display_singular_name : '';
                let quantity = ingredient.quantity ? ingredient.quantity : '';

                let addIngredientName = `${quantity} ${amount} ${itemName}`;

                return {
                  item: addIngredientName,
                  category_id: getCategoryId(ingredient?.ingredient?.category),
                  recipe_id: recipeId,
                  ingredient_name: itemName,
                  amount: toString(quantity),
                  ingredient_unit: toString(amount)
                }
              });
            } else {
              return accumulator
            }

          }
          else {
            return accumulator;
          }

        }, [])

      let addResponse = yield call(groceryTask.addToGroceryList, { items: items, limit: limit });

      if (!isNil(addResponse.isLimitExceeded) && addResponse.isLimitExceeded) {
        yield put(showGroceryListLimitNotification(addResponse.items));
      } else {
        yield put({
          type: actions.ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_SUCCESS,
          payload: {
            addedIngredients: addResponse.data.new_items
          }
        })
        yield put(showToastMessage(`✅ Ingredients for ${date} added to groceries.`, 'success', 'addIngredient'));
      }

    }

  } catch (e) {
    console.log(e);
    yield put(showToastMessage('❗️ Error occured while adding ingrident to groceries.', 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_FAILURE,
    })
  }
}

export default function* addIngredientsInGroceryList() {
  yield takeEvery(actions.ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST, task)
}