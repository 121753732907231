import React from 'react'

/**
 * 
 * @param {onChange} function : what should happen when a value is selected
 * @param {defaultValue} string/number : defauld selector value
 * @param {options} Array : all the selector options to be rendered  
 */

const Dropdown = (props) => {
  return(
    <select
      className={props.class}
      defaultValue={props.defaultValue}
      onChange={(e) => {
        props.onChange(e.target.value)
      }}
    >
    {props.options.map((item, index) => {
      return(
        <option value={item.value}>{item.label}</option>
      )
    })}
  </select>
  )
}

export default Dropdown;