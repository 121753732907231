import React, { useState, useEffect } from 'react';
import Button from './button'
import Radiobox from './radiobox';
import ReactImageFallback from "react-image-fallback";
import RecipeFallbackImage from '../images/workoutfallback.png';


const FavoriteModal = (props) => {
  const [state, setState] = useState({
    newFavoriteInput: '',
    editBoardModal: null,
    editFavouriteText: '',
    showEdit: false,
  })

  // to keep track how many selection done
  const onSelectBoard = (selected, board) => {
    props.setObjectBoards(board, props.objectType);
  }

  // function for create board on enter
  const createBoardInputForEnter = (e) => {
    if (e.key === 'Enter') {
      if (state.newFavoriteInput === '') {
      } else {
        props.createBoard(state.newFavoriteInput, props.objectType);
        setState(prevState => ({ ...prevState, newFavoriteInput: '' }))
      }
    }
  }

  // function for create board onBlur
  const createBoardInputForOnBlur = (e) => {
    if (e.target.value === '') {
    } else {
      props.createBoard(state.newFavoriteInput, props.objectType);
      setState(prevState => ({ ...prevState, newFavoriteInput: '' }))
    }
  }

  const favoriteCancelHandler = () => {
    setState(prevState => ({ ...prevState, newFavoriteInput: '' }))
    props.toggleFavoriteModal({
      itemId: null,
      modalState: false
    })
    props.clearBoardSelection(props.objectType);
  }

  const favoriteSaveHandler = () => {
    props.createFavourite(props.activeObject.id, props.objectType, props.thumbnail ? props.thumbnail : null)
    props.clearBoardSelection(props.objectType);
    setState(prevState => ({ ...prevState, newFavoriteInput: '' }))
    props.toggleFavoriteModal({
      itemId: null,
      modalState: false
    })
  }

  const renderEditSpan = () => {
    if (state.showEdit) {
      return (
        <>
          <span onClick={() => {
            setState(prevState => ({ ...prevState, editBoardModal: board.id }));
            setState(prevState => ({ ...prevState, editFavouriteText: board.title }));
          }}>Edit</span>
          <span onClick={() => props.deleteFavouriteBoard({ boardId: board.id, objectType: props.objectType, thumbnail: props.thumbnail ? props.thumbnail : null })}>
            Delete
        </span>
        </>
      )
    }
  }

  return (
    <div className="modalWrapper Addfavorite">
      <div className="modalHeader AddfavoriteHead">
        <figure className="currentRecipe">
          {/* thumbnail when favorite needs update, display image when not needed */}
          <img src={(props.thumbnail || props.displayImage) ? (props.thumbnail || props.displayImage) : null} />
        </figure>
        <h4>Add ’{props.activeObject && props.activeObject.title}’ to favorites</h4>
      </div>
      <div className="modalBody AddfavoriteBody">


        {/* input box for add category */}
        <div className="categoryRow">
          {/* <Radiobox
            type={'checkbox'}
          /> */}
          <input
            className="categoryInput"
            placeholder='Type here to add new category'
            value={state.newFavoriteInput}
            onChange={() => setState(prevState => ({ ...prevState, newFavoriteInput: event.target.value }))}
            onKeyPress={(e) => createBoardInputForEnter(e)}
            onBlur={(e) => createBoardInputForOnBlur(e)}
          />
        </div>

        {/* end of input box for add category */}
        {
          props.boards.map((board, index) => {
            const boardCount = (board.thumbnail && board.thumbnail.length - 1 >= 0) ? board.thumbnail.length - 1 : 0;
            return board.id !== null && (
              <div key={board.id} className="AddfavoriteRow">
                {state.editBoardModal !== board.id
                  ? <>
                    <Radiobox
                      checkId={board.id === null ? index : board.id}
                      label={`${board.title} ${board.thumbnail && board.thumbnail.length > 0 ? ` (${board.thumbnail.length})` : ''}`}
                      checked={board.isSelected}
                      content={board}
                      onSelect={onSelectBoard}
                      type={'checkbox'}
                      totalCount={board.total_count}
                    />
                    {
                      (props.objectType === 'recipe' || props.objectType === 'workout' || props.objectType === 'blog') && 
                        <figure>
                          {/* <img src={(board.thumbnail && board.thumbnail[boardCount])} /> */}
                          <ReactImageFallback
                            src={(board.thumbnail && board.thumbnail[boardCount])}
                            fallbackImage={RecipeFallbackImage}
                            className="img_fluid"
                          />
                        </figure>
                    }
                  </>
                  : null
                }
                {state.editBoardModal == board.id
                  ? <div className="editableInput">
                    <input autofocus type="text" value={state.editFavouriteText} onChange={() => setState(prevState => ({ ...prevState, editFavouriteText: event.target.value }))} />
                    <span onClick={() => {
                      // todo
                      props.updateFavouriteBoard({ boardId: board.id, title: state.editFavouriteText, objectType: props.objectType })
                      setState(prevState => ({ ...prevState, editFavouriteText: event.target.value, editBoardModal: null }));
                    }}>save</span>
                    <span onClick={() => {
                      setState(prevState => ({ ...prevState, editFavouriteText: event.target.value, editBoardModal: null }));
                    }}>cancel</span>
                  </div>
                  : null
                }
              </div>
            )
          })
        }
      </div>
      <div className="modalFooter">
        <Button
          btnClass="primary btn-full"
          btnText={props.objectType !== 'blog' ? `Add ${props.objectType} to categories` : 'Bookmark this blog post'}
          onClick={() => favoriteSaveHandler()}
        />
        <Button
          btnClass="secondary btn-full btn-bp-sm"
          btnText="Cancel"
          onClick={() => favoriteCancelHandler()}
        />
      </div>
    </div>
  )
}

export default FavoriteModal;