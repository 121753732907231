import React from 'react'
import CloseIcon from '../../web/images/closeIcon'


const CloseableTag = ({
  title,
  onClose
}) => (
    <div
      onClick={onClose}
      className="filterTagTitle">
      {title}
      <span>
        <CloseIcon></CloseIcon>
      </span>
    </div>
  )

export default CloseableTag;