import React from 'react'
import cx from 'classnames'
import Spinner from 'react-spinner-material';
import { themeTextRed } from '../../helpers/theme';


// component 
const LinkButton = ({
  renderValue,
  additionalClass,
  onClick,
  isLoading,
  showGhost,
  rightOffset,
  disabled
}) => {
  return (
    <button
      className={
        cx(
          'btn-link',
          additionalClass,
          {
            showGhost: showGhost,
            disabled
          }
        )
      }
      onClick={onClick}
    >
      {renderValue()}
      {
        isLoading ? (
          <div className="spinner" style={{
            position: 'absolute',
            right: rightOffset,
            top: 0
          }}>
            <Spinner

              size={15}
              spinnerColor={themeTextRed}
              spinnerWidth={2}
              visible={true}
            />
          </div>
        )
          : ""
      }


    </button>
  )
}

LinkButton.defaultProps = {
  showGhost: false,
  rightOffset: 0,
}

export default LinkButton;