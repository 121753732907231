import classNames from 'classnames';
import React from 'react';
import { Row, Col } from "react-flexbox-grid";
import Button from "../common/button";
import KatiesCornerBanner from '../images/KatiesCornerBanner.png';
import { TransitionEaseInOne } from "../common/transitions";

const KatiesConerBlogListNavigation = (props) => {
    const url = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }
    return (
        <>
            <TransitionEaseInOne>
                <section className="home-hero katies-corner-hero blog-list-hero" style={{
                    backgroundImage: `url(${props.featuredPersonalizedBlog?.banner || KatiesCornerBanner})`,
                }}>
                    <div className='container'>
                        <Row className="home-hero-wrapper home-hero-heading no-margin-sm">
                            <Col xs={12} className="hero-container-row no-padding-sm">
                                <div className="home-hero-text">
                                    <p className='hero-katies-corner-legend'>FEATURED ARTICLE</p>
                                    <h1>
                                        {props.featuredPersonalizedBlog?.title || (
                                            <>Connect with &nbsp;<br></br> like minded community</>
                                        )
                                        }
                                    </h1>
                                    <form className="hero-form internal-navigation">
                                        <Button
                                            btnClass="btn btn-primary btn-md read-more-btn"
                                            btnText={"Read More"}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigateTo(`/nourish/katies-corner/${props.featuredPersonalizedBlog?.slug}`)
                                            }}
                                        />
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>

            </TransitionEaseInOne>
        </>
    )
}

export default KatiesConerBlogListNavigation