import { put, takeEvery, call, select, all, take, putResolve } from 'redux-saga/effects'
import { showToastMessage, clearAllGroceryListItems, showGroceryListLimitNotification } from '../actions';
import { getGroceryListThresholdData } from './selectors'
import * as actions from '../actions/actionTypes';
import pushToSentry from '../helpers/pushToSentry';

import { isNil } from "lodash";

import groceryTask from './subtask/grocery';

function* task(action) {
  const limit = 500;

  try {
    // getting object specific data from selector
    let GroceryListData = yield select(getGroceryListThresholdData);
    if (GroceryListData.status) {
      yield putResolve({
        type: actions.CLEAR_ALL_GROCERY_LIST_ITEMS,
      });
      let addResponse = yield call(groceryTask.addToGroceryList, { items: GroceryListData.ingredients, limit: limit });
      if (!isNil(addResponse.isLimitExceeded) && addResponse.isLimitExceeded) {
        yield put(showGroceryListLimitNotification());
      } else {
        yield put({
          type: actions.REPLACE_GROCERY_LIST_SUCCESS,
          payload: {
            addedIngredients: addResponse.data.new_items
          }
        })
        yield put(showToastMessage(`✅ Ingredients Replaced`, 'success'));
      }
    }
  } catch (e) {
    yield put(showToastMessage(`❗️Error in replacing Ingredients in groceries.`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.REPLACE_GROCERY_LIST_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* replaceIngredients() {
  yield takeEvery(actions.REPLACE_GROCERY_LIST_REQUEST, task)
}