import { SET_FILTERS_REQUEST } from "./actionTypes";

export default (payload) => ({
  type: SET_FILTERS_REQUEST,
  payload: {
    objectType: payload.objectType,
    recipe: {
      favorite: (payload.recipe && payload.recipe.favorite) || {},
      filters: (payload.recipe && payload.recipe.filters) || {},
    },
    workout: {
      favorite: (payload.workout && payload.workout.favorite) || {},
      filters: (payload.workout && payload.workout.filters) || {},
    },
    blog: {
      favorite: (payload.blog && payload.blog.favorite) || {},
      filters: (payload.blog && payload.blog.filters) || {},
    },
    video: {
      favorite: (payload.video && payload.video.favorite) || {},
      filters: (payload.video && payload.video.filters) || {},
    }
  },
});