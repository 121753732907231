import React from "react";
import '../styles/common.scss';

const MasterCard = (props) => {
  return (
    <>
      <svg width="62" height="41" viewBox="0 0 62 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.558 36.7741C61.558 39.0199 59.7345 40.8427 57.4878 40.8427H4.57371C2.32701 40.8427 0.503418 39.0199 0.503418 36.7741V4.22578C0.503418 1.98005 2.32701 0.157227 4.57371 0.157227H57.4878C59.7345 0.157227 61.558 1.98005 61.558 4.22578V36.7741Z" fill="#34495E" />
        <path d="M23.241 32.3656C29.8267 32.3656 35.1654 27.0292 35.1654 20.4463C35.1654 13.8635 29.8267 8.5271 23.241 8.5271C16.6554 8.5271 11.3167 13.8635 11.3167 20.4463C11.3167 27.0292 16.6554 32.3656 23.241 32.3656Z" fill="#E74C3C" />
        <path d="M39.2623 32.3656C45.848 32.3656 51.1867 27.0292 51.1867 20.4463C51.1867 13.8635 45.848 8.5271 39.2623 8.5271C32.6766 8.5271 27.3379 13.8635 27.3379 20.4463C27.3379 27.0292 32.6766 32.3656 39.2623 32.3656Z" fill="#F1C40F" />
        <path d="M31.2531 29.2743C33.4139 29.2743 35.1655 25.322 35.1655 20.4466C35.1655 15.5712 33.4139 11.6189 31.2531 11.6189C29.0924 11.6189 27.3408 15.5712 27.3408 20.4466C27.3408 25.322 29.0924 29.2743 31.2531 29.2743Z" fill="#F39C12" />
        <path d="M26.2236 24.4747L26.4352 23.032C26.32 23.032 26.1486 23.0829 25.9987 23.0829C25.4069 23.0829 25.3426 22.7697 25.3801 22.5368L25.8568 19.5925H26.7565L26.9734 17.9945H26.1272L26.2986 17.0015H24.6009C24.5634 17.0389 23.5967 22.585 23.5967 23.2622C23.5967 24.2633 24.159 24.7076 24.9517 24.6996C25.5703 24.6915 26.0523 24.5202 26.2236 24.4747Z" fill="white" />
        <path d="M26.7622 21.7231C26.7622 24.1241 28.3475 24.6942 29.6971 24.6942C30.9423 24.6942 31.4912 24.4158 31.4912 24.4158L31.7912 22.7777C31.7912 22.7777 30.8432 23.1953 29.9863 23.1953C28.1627 23.1953 28.4814 21.8355 28.4814 21.8355H31.9331C31.9331 21.8355 32.1553 20.7354 32.1553 20.2857C32.1553 19.1669 31.5984 17.8044 29.7319 17.8044C28.0261 17.8044 26.7622 19.6433 26.7622 21.7231ZM29.7426 19.3248C30.7013 19.3248 30.5245 20.4008 30.5245 20.4892H28.6393C28.6367 20.3767 28.8161 19.3248 29.7426 19.3248Z" fill="white" />
        <path d="M40.4994 24.4748L40.8046 22.6226C40.8046 22.6226 39.9718 23.0401 39.3988 23.0401C38.1937 23.0401 37.709 22.1194 37.709 21.1317C37.709 19.1268 38.7454 18.0241 39.8995 18.0241C40.7645 18.0241 41.4607 18.5112 41.4607 18.5112L41.7392 16.7125C41.7392 16.7125 40.7082 16.2949 39.8245 16.2949C37.8644 16.2949 35.9551 17.9946 35.9551 21.1906C35.9551 23.3078 36.9861 24.7077 39.0132 24.7077C39.5862 24.7077 40.4994 24.4748 40.4994 24.4748Z" fill="white" />
        <path d="M16.8781 17.8313C15.7133 17.8313 14.8188 18.206 14.8188 18.206L14.5725 19.6702C14.5725 19.6702 15.3089 19.3704 16.4229 19.3704C17.0548 19.3704 17.5181 19.4427 17.5181 19.9566C17.5181 20.2698 17.4619 20.3849 17.4619 20.3849C17.4619 20.3849 16.9638 20.342 16.7308 20.342C15.2607 20.342 13.7183 20.9684 13.7183 22.8581C13.7183 24.3464 14.7305 24.689 15.3598 24.689C16.5594 24.689 17.0763 23.9128 17.1031 23.9101L17.0468 24.5578H18.5437L19.2105 19.8816C19.2132 17.8929 17.4806 17.8313 16.8781 17.8313ZM17.2423 21.6376C17.2744 21.924 17.0629 23.2623 16.04 23.2623C15.5124 23.2623 15.3759 22.8581 15.3759 22.6199C15.3759 22.1542 15.6276 21.5974 16.8728 21.5974C17.1646 21.6001 17.1941 21.6295 17.2423 21.6376Z" fill="white" />
        <path d="M20.8014 24.6515C21.1844 24.6515 23.3721 24.7478 23.3721 22.4914C23.3721 20.3822 21.3477 20.7998 21.3477 19.9512C21.3477 19.5283 21.6771 19.3972 22.2822 19.3972C22.5206 19.3972 23.4445 19.4721 23.4445 19.4721L23.6587 17.9732C23.6587 17.9732 23.0615 17.8394 22.0921 17.8394C20.8362 17.8394 19.5589 18.3399 19.5589 20.0556C19.5589 21.9989 21.6851 21.8035 21.6851 22.6226C21.6851 23.1686 21.0906 23.2141 20.6327 23.2141C19.8401 23.2141 19.1278 22.9411 19.1251 22.9545L18.8975 24.44C18.9376 24.4561 19.3768 24.6515 20.8014 24.6515Z" fill="white" />
        <path d="M54.5739 16.4851L54.207 18.7576C54.207 18.7576 53.567 17.8743 52.5655 17.8743C51.0097 17.8743 49.7109 19.7506 49.7109 21.9054C49.7109 23.2972 50.4018 24.6597 51.8184 24.6597C52.836 24.6597 53.401 23.9504 53.401 23.9504L53.326 24.5553H54.9782L56.277 16.4797L54.5739 16.4851ZM53.7839 20.9177C53.7839 21.8144 53.3394 23.0135 52.4182 23.0135C51.8077 23.0135 51.5212 22.4996 51.5212 21.6966C51.5212 20.3797 52.113 19.5124 52.8601 19.5124C53.4706 19.5071 53.7839 19.9273 53.7839 20.9177Z" fill="white" />
        <path d="M7.67985 24.566L8.7135 18.4685L8.86612 24.566H10.0363L12.2188 18.4685L11.2521 24.566H12.9927L14.3316 16.4797H11.643L9.96671 21.4423L9.87835 16.4797H7.39867L6.04102 24.566H7.67985Z" fill="white" />
        <path d="M33.8558 24.5763C34.3512 21.7658 34.4422 19.4826 35.6232 19.9001C35.8294 18.8134 36.0302 18.3905 36.2551 17.9301C36.2551 17.9301 36.1507 17.9087 35.9284 17.9087C35.1679 17.9087 34.6029 18.9472 34.6029 18.9472L34.7556 17.9917H33.173L32.1125 24.5736H33.8558V24.5763Z" fill="white" />
        <path d="M44.4213 17.8313C43.2565 17.8313 42.3621 18.206 42.3621 18.206L42.1157 19.6702C42.1157 19.6702 42.8521 19.3704 43.9661 19.3704C44.5981 19.3704 45.0613 19.4427 45.0613 19.9566C45.0613 20.2698 45.0051 20.3849 45.0051 20.3849C45.0051 20.3849 44.507 20.342 44.2741 20.342C42.8039 20.342 41.2615 20.9684 41.2615 22.8581C41.2615 24.3464 42.2737 24.689 42.9003 24.689C44.1 24.689 44.6168 23.9128 44.6436 23.9101L44.5874 24.5578C44.5874 24.5578 46.0816 24.5578 46.0843 24.5578L46.7511 19.8816C46.7537 17.8929 45.0212 17.8313 44.4213 17.8313ZM44.7855 21.6376C44.8177 21.924 44.6061 23.2623 43.5832 23.2623C43.0556 23.2623 42.9191 22.8581 42.9191 22.6199C42.9191 22.1542 43.1708 21.5974 44.416 21.5974C44.7052 21.6001 44.7346 21.6295 44.7855 21.6376Z" fill="white" />
        <path d="M48.1219 24.5763C48.6173 21.7658 48.7084 19.4826 49.8893 19.9001C50.0955 18.8134 50.2963 18.3905 50.5213 17.9301C50.5213 17.9301 50.4168 17.9087 50.1946 17.9087C49.4341 17.9087 48.869 18.9472 48.869 18.9472L49.0217 17.9917H47.4391L46.3787 24.5736H48.1219V24.5763Z" fill="white" />
      </svg>
    </>
  );
};
export default MasterCard;
