import React, { useEffect, useState } from "react";
import '../styles/common.scss';

const Logo = () => {
	return (
		<figure className="logo">
			<svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mob-brand-logo">
				<path className="brandLogo" d="M17.9998 0C8.05877 0 0 8.05846 0 18C0 27.9407 8.05877 36 17.9998 36C27.9408 36 35.9996 27.9407 35.9996 18C36 8.05846 27.9412 0 17.9998 0ZM26.8768 28.2238H22.3923C21.6297 28.2238 21.0116 27.6012 21.0116 26.8329V26.1309C20.1025 27.3623 18.6053 28.718 16.2313 28.718C12.6738 28.718 9.12283 25.8437 9.12283 20.6019C9.12283 14.8487 13.161 11.6266 16.9541 11.6266C18.9428 11.6266 20.2621 12.5246 21.0116 13.6631V8.98466C21.0116 8.22046 20.6156 7.80522 19.8137 7.80522V6.04077L24.1935 5.57154C24.9872 5.48644 25.6789 6.11318 25.6789 6.91748V12.3205H25.6793L25.6789 25.2795C25.6789 26.0441 26.0749 26.459 26.8768 26.459V28.2238Z" fill="#ED669A" />
				<path className="brandLogo" d="M21.0111 18.223L21.0001 18.0405C21.0001 15.7013 19.7446 14.1156 18.2984 14.1156C16.4722 14.1156 14.689 16.245 14.689 20.6661C14.689 23.6137 15.8786 25.5324 17.6774 25.5324C19.3482 25.5324 20.3885 24.4335 21.0119 23.3682V20.833H21.0115L21.0111 18.223Z" fill="#ED669A" />
			</svg>
		</figure>
	);
};
export default Logo;
