import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import Button from "../../common/button";
import DesktopBackgroundImage from "../../images/bfriday1.jpg";
import MobBackgroundImage from "../../images/bfriday1.jpg";
import { TransitionEaseInOne } from "../../common/transitions";

const HeroSectionMothersDay = (props) => {
  const [showMobileImage, setShowMobileImage] = useState(true);
  const [windowWidth, setWindowWidth] = useState(undefined);

  useEffect(() => {
    if (window.innerWidth < 575) {
      setShowMobileImage(false);
      setWindowWidth(window.innerWidth);
    } else if (window.innerWidth > 575) {
      setShowMobileImage(true);
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 575) {
        setShowMobileImage(false);
        setWindowWidth(window.innerWidth);
      } else if (window.innerWidth > 575) {
        setShowMobileImage(true);
        setWindowWidth(window.innerWidth);
      }
    });
  }, []);
  return (
    <>
      <TransitionEaseInOne>
        <section
          className="home-hero"
          style={{
            backgroundImage: showMobileImage
              ? `linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%), url(${DesktopBackgroundImage})`
              : `linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%), url(${MobBackgroundImage})`,
            backgroundSize: showMobileImage ? '850px' : '0px',
            backgroundPosition: showMobileImage ? 'right' : 'unset'
          }}
        >
          <div className="container">
            <Row className="home-hero-wrapper home-hero-heading no-margin-sm">
              <Col xs={12} md={12} lg={8} className="no-padding-sm">
                <div className="home-hero-text">
                  <h1 style={{ maxWidth: "450px" }}>
                    50% Off Healthy Delecious Recipes
                  </h1>
                  <p style={{ maxWidth: "400px" }}>
                    There is no better time to start learning how to cook healthy & delecious meals at home!
                  </p>
                  <form className="hero-form">
                    <Button
                      btnClass="btn btn-primary btn-md"
                      btnText={
                        !props.isAuthenticated
                          ? "Signup"
                          : "Get Started"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        if (props.isAuthenticated) {
                          props.history.push("/recipes");
                        } else {
                          props.history.push("pricing")
                        }
                      }}
                    />
                    {!props.isAuthenticated ? (
                      <Button
                        btnClass="btn btn-transparent-link"
                        btnText="See Plans & Pricing"
                        onClick={(e) => {
                          e.preventDefault();
                          if (props.isAuthenticated) {
                            props.history.push("/recipes");
                          } else {
                            props.history.push("pricing");
                          }
                        }}
                      />
                    ) : null}
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </TransitionEaseInOne>
    </>
  );
};

export default HeroSectionMothersDay;
