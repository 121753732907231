
import React from 'react'
import DesktopCalendar from './calendars/desktop'
import ResponsivePlanCalendar from './calendars/responsive'

const PlanCalendar = ({
  responsive,
  ...props
}) => {

  return !responsive ? (
    <DesktopCalendar
      {...props}
    />
  ) : (
      <ResponsivePlanCalendar
        {...props}
      />
    )
}

export default PlanCalendar