import React, { useState, useEffect } from 'react'
import InputRange from 'react-input-range';
import PropTypes from 'prop-types'

const InputRangeCommon = ({
  wrapperClass,
  label,
  labelClass,
  minValue,
  maxValue,
  onChangeComplete,
  defaultMinValue,
  defaultMaxValue,
  conditionalFormatValue,
  conditionalFormatLabel,
  ...props
}) => {

  const [value, setValue] = useState(15)

  useEffect(() => {
    setValue(parseInt(defaultMaxValue))
  }, [defaultMaxValue])

  const parseWeightWatchers = (label) => {

    if (label === 'FP') {
      return <>Freestyle Points</>
    } else if (label === 'SP') {
      return <>{`SmartPoints®`}</>
    } else if (label === 'WWP') {
      return <>WWP+</>
    } else {
      return label
    }
  }

  // MAIN RETURN
  return (
    <div className={wrapperClass}>
      <h4 className={labelClass}>{parseWeightWatchers(label)}</h4>
      <InputRange
        value={value}
        minValue={minValue}
        maxValue={maxValue}
        onChange={(value) => setValue(value)}
        formatLabel={(value) => value === conditionalFormatValue ? conditionalFormatLabel : value}
        onChangeComplete={(value) => {
          onChangeComplete(value, label)
        }}
      />
    </div>
  )
}

InputRange.propTypes = {
  wrapperClass: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  maxValue: PropTypes.number,
  minValue: PropTypes.number,
  onChangeComplete: PropTypes.func,
  defaultMinValue: PropTypes.number,
  defaultMaxValue: PropTypes.number
}

InputRange.defaultProps = {
  wrapperClass: '',
  label: '',
  labelClass: '',
  maxValue: 0,
  minValue: 15,
  onChangeComplete: () => { },
  defaultMinValue: 0,
  defaultMaxValue: 15,
  conditionalFormatValue: 15,
  conditionalFormatLabel: 'Any'
}

export default InputRangeCommon