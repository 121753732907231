import React from "react";
import { withRouter } from "react-router-dom";
import ReactImageFallback from "react-image-fallback";
import AvatarIcon from "../../images/avartar";

const UserAvatar = ({
  isAuthenticating,
  isAuthenticated,
  userDetails,
  groceryListItemCount,
  groceryListFetchStatus,
  ...props
}) => {
  const handleAuth = () => {
    props.history.push("/login");
  };

  // what happens when we click a nav item
  const onItemClick = (e, navListItem) => {
    if (e.metaKey) {
      window.open(navListItem.href, "_blank");
      window.focus();
    } else {
      e.preventDefault();

      if (props.windowWidth < 767) {
        props.setShowNavList(false);
      }
      props.history.push(navListItem.href);
    }
  };
  return (
    <div className="UserAvatar-mob">
      {isAuthenticated ? (
        <a
          href={"/account"}
          onClick={e => {
            onItemClick(e, { href: "/account" });
          }}
        >
          <figure className="member-avatar">
            {userDetails &&
            userDetails.details &&
            userDetails.details.avatar ? (
              <ReactImageFallback
                src={userDetails.details.avatar.thumb}
                fallbackImage={<AvatarIcon />}
                className="img_fluid"
              />
            ) : (
              <AvatarIcon></AvatarIcon>
            )}
          </figure>
          {/* {
              (isAuthenticated && (userDetails && userDetails.details)) ? userDetails.details.name
                : null
            } */}
        </a>
      ) : (
        <a
          href={"/login"}
          onClick={e => {
            e.preventDefault();
            props.logoutNavbar();
            handleAuth();
          }}
        >
          Login
        </a>
      )}
    </div>
  );
};
export default withRouter(UserAvatar);
