import React from "react";
import '../styles/common.scss';

const GooglePlay = (props) => {
  return (
    <>
      <svg width="135" height="39" viewBox="0 0 135 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.79998 9.16792C7.39598 9.16792 8.23598 8.41192 8.49998 8.05192L8.71598 8.99992H9.71198V4.36792H6.43598V5.61592H8.25998C8.18798 6.61192 7.55198 7.81192 5.87198 7.81192C4.20398 7.81192 3.06398 6.51592 3.06398 4.79992C3.06398 3.15592 4.19198 1.78792 5.84798 1.78792C7.07198 1.78792 7.70798 2.41192 8.03198 3.23992L9.41198 2.75992C8.91998 1.46392 7.80398 0.431922 5.77598 0.431922C3.32798 0.431922 1.59998 2.33992 1.59998 4.79992C1.59998 7.33192 3.30398 9.16792 5.79998 9.16792Z" fill="black" />
        <path d="M11.3096 8.99992H16.5896V7.67992H12.7736V5.41192H16.0856V4.12792H12.7736V1.91992H16.5896V0.599922H11.3096V8.99992Z" fill="black" />
        <path d="M19.861 8.99992H21.325V1.95592H23.749V0.599922H17.437V1.95592H19.861V8.99992Z" fill="black" />
        <path d="M28.3018 8.99992H29.7658V0.599922H28.3018V8.99992Z" fill="black" />
        <path d="M33.3727 8.99992H34.8367V1.95592H37.2607V0.599922H30.9487V1.95592H33.3727V8.99992Z" fill="black" />
        <path d="M45.8335 9.15592C48.4855 9.15592 50.1175 7.13992 50.1175 4.78792C50.1175 2.43592 48.4855 0.419922 45.8335 0.419922C43.1455 0.419922 41.5375 2.43592 41.5375 4.78792C41.5375 7.13992 43.1575 9.15592 45.8335 9.15592ZM45.8335 7.79992C44.0815 7.79992 43.0015 6.51592 43.0015 4.78792C43.0015 3.07192 44.0935 1.77592 45.8335 1.77592C47.5615 1.77592 48.6535 3.07192 48.6535 4.78792C48.6535 6.51592 47.5615 7.79992 45.8335 7.79992Z" fill="black" />
        <path d="M51.6226 8.99992H53.0866V2.98792L57.0946 8.99992H58.5946V0.599922H57.1306V6.61192L53.1346 0.599922H51.6226V8.99992Z" fill="black" />
        <path d="M8.81173 33.2847C12.0741 33.2847 13.7912 31.7393 14.3309 31.0034L14.7724 32.9412H16.8083V23.4729H10.1118V26.024H13.8403C13.6931 28.0599 12.393 30.5128 8.95891 30.5128C5.54932 30.5128 3.21903 27.8637 3.21903 24.356C3.21903 20.9954 5.52479 18.1991 8.90985 18.1991C11.4119 18.1991 12.7119 19.4746 13.3742 21.1671L16.1951 20.186C15.1894 17.5368 12.9081 15.4272 8.76268 15.4272C3.75868 15.4272 0.22644 19.3274 0.22644 24.356C0.22644 29.5317 3.70962 33.2847 8.81173 33.2847Z" fill="black" />
        <path d="M25.5686 33.2847C29.248 33.2847 31.9462 30.4392 31.9462 26.8089C31.9462 23.2031 29.248 20.3577 25.5686 20.3577C21.9137 20.3577 19.2155 23.2031 19.2155 26.8089C19.2155 30.4392 21.8646 33.2847 25.5686 33.2847ZM25.5686 30.5619C23.5817 30.5619 22.159 28.992 22.159 26.8089C22.159 24.6503 23.5817 23.0804 25.5686 23.0804C27.5309 23.0804 29.0027 24.6503 29.0027 26.8089C29.0027 28.992 27.5309 30.5619 25.5686 30.5619Z" fill="black" />
        <path d="M40.1569 33.2847C43.8363 33.2847 46.5345 30.4392 46.5345 26.8089C46.5345 23.2031 43.8363 20.3577 40.1569 20.3577C36.502 20.3577 33.8038 23.2031 33.8038 26.8089C33.8038 30.4392 36.4529 33.2847 40.1569 33.2847ZM40.1569 30.5619C38.17 30.5619 36.7473 28.992 36.7473 26.8089C36.7473 24.6503 38.17 23.0804 40.1569 23.0804C42.1192 23.0804 43.591 24.6503 43.591 26.8089C43.591 28.992 42.1192 30.5619 40.1569 30.5619Z" fill="black" />
        <path d="M54.598 38.1905C58.5718 38.1905 60.853 35.9093 60.853 32.4752V20.7011H58.4491L57.9095 22.1728C57.3943 21.3388 56.1679 20.3577 54.2055 20.3577C50.845 20.3577 48.392 23.0804 48.392 26.6127C48.392 30.1694 50.7959 32.8431 54.23 32.8431C56.0943 32.8431 57.3698 31.9355 57.9095 31.028V32.5242C57.9095 34.5847 56.6585 35.6885 54.598 35.6885C52.8809 35.6885 51.9733 34.8545 51.679 33.6035L48.9317 34.2168C49.5449 36.4735 51.311 38.1905 54.598 38.1905ZM54.647 30.194C52.7338 30.194 51.3356 28.7958 51.3356 26.6127C51.3356 24.405 52.7092 23.0068 54.6225 23.0068C56.5113 23.0068 57.9095 24.405 57.9095 26.6127C57.9095 28.7958 56.5358 30.194 54.647 30.194Z" fill="black" />
        <path d="M63.8074 32.9412H66.751V15.7707H63.8074V32.9412Z" fill="black" />
        <path d="M81.131 27.7901V26.6617C81.131 22.9332 78.8988 20.3577 75.342 20.3577C71.8588 20.3577 69.1606 23.105 69.1606 26.8089C69.1606 30.5864 71.8588 33.2847 75.3665 33.2847C78.3591 33.2847 80.1252 31.7148 80.8366 29.7524L78.3591 28.992C78.0648 29.826 77.0836 30.8562 75.3665 30.8562C73.4287 30.8562 72.1041 29.3354 71.9815 27.7901H81.131ZM78.5308 25.5334H72.006C72.2758 24.1352 73.3797 22.7861 75.3665 22.7861C77.4761 22.7861 78.4572 24.4295 78.5308 25.5334Z" fill="black" />
        <path d="M90.7398 32.9412H93.7324V26.7108H97.0193C99.9383 26.7108 102.735 24.9692 102.735 21.2162C102.735 17.3651 99.9874 15.7707 96.8721 15.7707H90.7398V32.9412ZM93.7324 24.0125V18.4689H96.6268C98.7364 18.4689 99.7421 19.6218 99.7421 21.2162C99.7421 22.8106 98.7854 24.0125 96.6514 24.0125H93.7324Z" fill="black" />
        <path d="M105.153 32.9412H108.096V15.7707H105.153V32.9412Z" fill="black" />
        <path d="M114.652 33.2847C116.614 33.2847 117.571 32.5242 118.061 31.8374L118.38 32.9412H120.857V24.8956C120.857 21.7068 118.478 20.3577 115.755 20.3577C112.836 20.3577 111.291 21.6332 110.629 24.0616L113.229 24.6994C113.425 23.4484 114.112 22.7125 115.657 22.7125C117.227 22.7125 117.914 23.5465 117.914 24.9447V25.0918L115.289 25.4598C111.389 25.9994 110.457 27.7165 110.457 29.5071C110.457 31.8374 112.346 33.2847 114.652 33.2847ZM115.436 30.9053C114.087 30.9053 113.278 30.2675 113.278 29.3354C113.278 28.3297 113.965 27.8146 115.486 27.5938L117.914 27.2259V28.6486C117.914 30.1204 116.761 30.9053 115.436 30.9053Z" fill="black" />
        <path d="M131.792 20.7011V26.9561C131.792 29.0165 130.59 30.0713 128.995 30.0713C127.524 30.0713 126.543 29.3845 126.543 27.1768V20.7011H123.599V27.6674C123.599 31.2487 125.39 32.8186 128.063 32.8186C129.928 32.8186 130.933 32.1318 131.767 31.1261L131.792 31.1997V32.4997C131.792 34.5602 130.688 35.664 128.897 35.664C127.352 35.664 126.543 34.9281 126.297 33.7262L123.55 34.2904C124.139 36.4735 125.807 38.1905 128.897 38.1905C132.601 38.1905 134.735 35.9093 134.735 32.4752V20.7011H131.792Z" fill="black" />
      </svg>
    </>
  );
};
export default GooglePlay;
