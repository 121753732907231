import { DELETE_FAVOURITE } from "./actionTypes";

export default (objectId, objectType, thumbnail, boards = []) => ({
  type: DELETE_FAVOURITE,
  payload: {
    objectId,
    objectType,
    thumbnail,
    boards,
  }
});