import React from 'react'
import Button from './button'
import CloseIcon from '../images/closeIcon';

const PopupModal = (props) => {
  return (
    <div className="messageWrapper">
      <div className="message">
        <h3>'{props.objectName}' is a premium {props.objectType}</h3>
        <p>
          When you join the Dashing Dish Membership,
          you get access to our full catalog of over 700 healthy,
          delicious recipes—plus a meal planner, a grocery list builder,
          and your own personal recipe box to save save all your favorites in.
          It's all the essential resources you need to maintain a healthy lifestyle.
        </p>
        <Button
          type={'button'}
          btnClass="primary btn-full"
          btnText="Login"
          onClick={() => {
            props.history.push('/login');
          }}
        />
        {/* <span className="close" onClick={() => {
          props.closeModal();
        }}>
          <CloseIcon></CloseIcon>
        </span> */}
      </div>
    </div>
  )
}

export default PopupModal;