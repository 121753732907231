import React from "react";
import '../styles/common.scss';

const DiscoverCard = (props) => {
  return (
    <>
      <svg width="62" height="42" viewBox="0 0 62 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M61.558 37.1956C61.558 39.4413 59.7345 41.2641 57.4878 41.2641H4.57371C2.32701 41.2641 0.503418 39.4413 0.503418 37.1956V4.64717C0.503418 2.40143 2.32701 0.578613 4.57371 0.578613H57.4878C59.7345 0.578613 61.558 2.40143 61.558 4.64717V37.1956Z" fill="#EBEDEE" />
        <path d="M57.611 41.2641C59.8577 41.2641 61.5582 39.6554 61.5582 37.407V25.394C61.5582 25.394 45.9303 36.446 17.0847 41.2641H57.611Z" fill="#E67E22" />
        <path opacity="0.1" d="M61.535 25C61.535 25 45.8456 36.1591 17 40.9771C51.4182 40.9798 61.535 25 61.535 25Z" fill="#231F20" />
        <path d="M7.01886 12.8296H4.57935V21.3495H7.00817C8.29889 21.3495 9.23077 21.0443 10.0502 20.3671C11.0222 19.5641 11.598 18.3516 11.598 17.0962C11.5953 14.5801 9.71544 12.8296 7.01886 12.8296ZM8.96297 19.2295C8.44079 19.7006 7.7633 19.9067 6.68681 19.9067H6.23963V14.2723H6.68681C7.76062 14.2723 8.41401 14.465 8.96297 14.9602C9.53871 15.4715 9.88417 16.2638 9.88417 17.0802C9.88417 17.8992 9.53603 18.7183 8.96297 19.2295Z" fill="#231F20" />
        <path d="M14.024 12.8296H12.3611V21.3495H14.024V12.8296Z" fill="#231F20" />
        <path d="M18.0892 16.0981C17.0904 15.7287 16.7985 15.4851 16.7985 15.0247C16.7985 14.4867 17.3207 14.0799 18.0383 14.0799C18.5364 14.0799 18.9461 14.2833 19.3799 14.7704L20.2502 13.6329C19.5353 13.0065 18.681 12.688 17.7465 12.688C16.2389 12.688 15.0874 13.7346 15.0874 15.1291C15.0874 16.3042 15.623 16.9037 17.1841 17.4658C17.8348 17.696 18.1669 17.8486 18.3329 17.9503C18.665 18.1671 18.831 18.475 18.831 18.8309C18.831 19.5215 18.282 20.0328 17.5403 20.0328C16.7476 20.0328 16.1076 19.6366 15.7247 18.8952L14.6509 19.9284C15.4167 21.0526 16.3379 21.5504 17.6019 21.5504C19.3291 21.5504 20.5421 20.4022 20.5421 18.7533C20.5394 17.4016 19.9798 16.7887 18.0892 16.0981Z" fill="#231F20" />
        <path d="M21.0642 17.0936C21.0642 19.599 23.0324 21.5396 25.563 21.5396C26.2779 21.5396 26.8912 21.3977 27.6463 21.0444V19.0877C26.9822 19.7516 26.3931 20.0219 25.638 20.0219C23.9643 20.0219 22.7754 18.8094 22.7754 17.0829C22.7754 15.4475 24.0018 14.1573 25.5603 14.1573C26.3529 14.1573 26.9528 14.441 27.6437 15.1156V13.1589C26.9126 12.7895 26.3128 12.6343 25.5978 12.6343C23.0833 12.637 21.0642 14.6177 21.0642 17.0936Z" fill="#231F20" />
        <path d="M40.8317 18.5523L38.5583 12.8296H36.74L40.3578 21.5663H41.2522L44.9342 12.8296H43.132L40.8317 18.5523Z" fill="#231F20" />
        <path d="M45.687 21.3495H50.4026V19.9067H47.3499V17.6075H50.2902V16.1621H47.3499V14.2723H50.4026V12.8296H45.687V21.3495Z" fill="#231F20" />
        <path d="M56.9845 15.3432C56.9845 13.7479 55.8866 12.8271 53.9692 12.8271H51.5029V21.347H53.1632V17.9235H53.3801L55.6804 21.347H57.7262L55.043 17.7576C56.2963 17.506 56.9845 16.6494 56.9845 15.3432ZM53.6506 16.7511H53.1659V14.1708H53.6773C54.7136 14.1708 55.276 14.6045 55.276 15.4342C55.2733 16.2908 54.711 16.7511 53.6506 16.7511Z" fill="#231F20" />
        <path d="M37.2595 17.1178C37.2595 19.6205 35.2297 21.6494 32.7259 21.6494C30.2222 21.6494 28.1924 19.6205 28.1924 17.1178C28.1924 14.6151 30.2222 12.5862 32.7259 12.5862C35.2297 12.5862 37.2595 14.6151 37.2595 17.1178Z" fill="#E67E22" />
      </svg>
    </>
  );
};
export default DiscoverCard;
