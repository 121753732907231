import React, { useState, useEffect } from "react";
import moment from "moment";
import { Helmet } from "react-helmet";
import isEmpty from "lodash/isEmpty";
import getMarkdownImage from "../../../../helpers/getMarkdownImage";
import convertQuality from "../../../../helpers/convertQuality";
import FeaturedSection from "./featuredSection";
import VerticalSplicedList from "./verticalSplicedScreen";

import Loader from "../../../images/loader";

// New imports
import { generateAppEssentialPayload } from "../../../../helpers/filterConversions";
import SlugImage from "../../../recipeIndex/components/slugImage";

import FiltersHOC from "../../common/blogfiltersHOC";
import BookmarkUnfillicon from "../../../images/BookmarkUnfillIcon";
import BookmarkFillIcon from "../../../images/BookmarkFillIcon";
import Button from "../../../common/button";
import {
  TransitionOne,
  TransitionTwo,
  TransitionThree,
} from "../../../common/transitions";
import AdComponent from "../../../common/ad";

const BlogPreview = ({
  appEssentialFetch,
  unfilteredList,
  featuredList,
  isAuthenticated,
  history,
  toggleFavoriteModal,
  deleteFavourite,
  fetchStatus,
  loginRedirectHandler,
  trendingBlogs,
  trendingTypeChange,
  additionalClass,
  ...props
}) => {
  useEffect(() => {
    const appEssentialPayload = generateAppEssentialPayload({
      objectType: "blog",
      meta: {
        objectType: "blog",
        listType: "unfiltered",
      },
    });

    appEssentialFetch(appEssentialPayload);
  }, []);

  const renderBlogMeta = (item) => (
    <div className="recipe-meta">
      {`${moment(item.updated_at)
        .format("MMMM Do YYYY")
        .replace(/(\d)(st|nd|rd|th)/g, "$1$2")}`}
    </div>
  );

  const noInitialFetchHappening =
    !fetchStatus.unfilteredListInitialTypeStatus.isFetching &&
    !fetchStatus.filteredListInitialTypeStatus.isFetching &&
    !fetchStatus.favoritedListInitialTypeStatus.isFetching &&
    !fetchStatus.featuredListInitialTypeStatus.isFetching;
  const noPaginationFetchHappening =
    !fetchStatus.unfilteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.filteredListPaginationTypeStatus.isFetching &&
    !fetchStatus.favoritedListPaginationTypeStatus.isFetching &&
    !fetchStatus.featuredListPaginationTypeStatus.isFetching;

  const noFetchHappening =
    noInitialFetchHappening && noPaginationFetchHappening;

  if (!noFetchHappening) {
    return (
      <div className="preLoader">
        <Loader></Loader>
      </div>
    );
  }
  return (
    <>
      {featuredList.length > 0 && (
        <TransitionOne>
          <section
            href={`/blog/${featuredList[0].slug}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`/blog/${featuredList[0].slug}`);
            }}
            className="fullWideSection fullWidthAnchor"
            style={{
              backgroundImage: `url("${featuredList[0].image
                  ? convertQuality(featuredList[0].image, "large")
                  : !isEmpty(featuredList[0].content)
                    ? getMarkdownImage(featuredList[0].content, "large")[1]
                    : ""
                }")`,
            }}
          >
            <div className="container">
              <div className="wideImgTitle">
                {isAuthenticated &&
                  (featuredList[0].favorite ? (
                    <BookmarkFillIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteFavourite(
                          featuredList[0].id,
                          "blog",
                          featuredList[0].image
                            ? convertQuality(featuredList[0].image, "thumb")
                            : !isEmpty(featuredList[0].content)
                              ? getMarkdownImage(
                                featuredList[0].content,
                                "thumb"
                              )[1]
                              : ""
                        );
                      }}
                    />
                  ) : (
                    <BookmarkUnfillicon
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavoriteModal({
                          itemId: featuredList[0].id,
                          modalState: true,
                        });
                      }}
                    />
                  ))}
                <h1>{featuredList[0].title}</h1>
                <div className="recipe-meta">
                  {featuredList[0].author.name
                    ? `BY ${featuredList[0].author.name} |`
                    : null}{" "}
                  {`${moment(featuredList[0].updated_at)
                    .format("MMMM Do YYYY")
                    .replace(/(\d)(st|nd|rd|th)/g, "$1$2")}`}
                </div>
              </div>
            </div>
          </section>
        </TransitionOne>
      )}
      <section className="featuredBlogSection">
        <Helmet>
          <title>Blog | Dashing Dish</title>
          <meta name="description" content="Dashingdish Blogs page" />
        </Helmet>

        <div className="container">
          <AdComponent />
          <div className="VerticalListWrapper">
            <TransitionTwo>
              <VerticalSplicedList
                title={`Most Popular Posts`}
                spliceCount={4}
                data={trendingBlogs}
                renderBlogMeta={renderBlogMeta}
                isAuthenticated={isAuthenticated}
                history={history}
                deleteFavourite={deleteFavourite}
                toggleFavoriteModal={toggleFavoriteModal}
                loginRedirect={loginRedirectHandler}
                showActions
                trendingTypeChange={trendingTypeChange}
                onAction={(value) => trendingTypeChange("blog", value)}
                isLoading={fetchStatus.trendingFetch}
                additionalClass
              />
            </TransitionTwo>
            <TransitionThree>
              <VerticalSplicedList
                title={"Recent Posts"}
                spliceCount={8}
                data={unfilteredList}
                renderBlogMeta={renderBlogMeta}
                isAuthenticated={isAuthenticated}
                history={history}
                deleteFavourite={deleteFavourite}
                toggleFavoriteModal={toggleFavoriteModal}
                loginRedirect={loginRedirectHandler}
                trendingTypeChange={trendingTypeChange}
                onAction={(value) => {
                  history.push(`/blog?order=${value}`);
                }}
                showActions={true}
              />
            </TransitionThree>
          </div>
          <div className="blogAnchor">
            <Button
              btnClass="primary btn"
              btnText="Load more blog posts"
              onClick={(e) => {
                history.push("/blog");
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default FiltersHOC(BlogPreview);
