import React from "react";
import replace from "lodash/replace";
import includes from "lodash/includes";

import HorizontalActionableHeader from "./horizontalActionableHeader";

const getOptions = (showFavorite, objectType) => {
  let result = [
    {
      index: "#1",
      value: "sort",
      title: "Choose option",
      disabled: true
    },
    {
      title: "Last to newest",
      value: "asc"
    },
    {
      title: "Newest to last",
      value: "desc"
    },
    {
      title: "Randomized",
      value: "rand"
    }
  ];

  if (showFavorite) {
    result.push({
      title: objectType !== "blog" ? "Most Favorited" : "Most Read",
      value: "favorite"
    });
  }

  return result;
};

const ChangeSortOrder = ({
  active,
  title,
  children,
  objectType,
  history,
  order,
  showActions,
  setFiltersAndFetch,
  appliedFilters
}) => {
  return (
    <HorizontalActionableHeader
      options={getOptions(
        !(
          includes(history && history.location && history.location.search, [
            "search"
          ]) ||
          includes(history && history.location && history.location.search, [
            "fp"
          ]) ||
          includes(history && history.location && history.location.search, [
            "sp"
          ]) ||
          includes(history && history.location && history.location.search, [
            "wwp"
          ])
        ),
        objectType
      )}
      showActions={active}
      title={title}
      onAction={value => {
        if (value === "rand") {
          const newFilters = {
            ...appliedFilters,
            order: ["rand"]
          };
          setFiltersAndFetch({
            objectType,
            [objectType]: {
              filters: newFilters
            }
          });
        }
        let actionUrl = history.location.search;

        actionUrl = replace(actionUrl, "?order=asc", "");
        actionUrl = replace(actionUrl, "?order=desc", "");
        actionUrl = replace(actionUrl, "?order=rand", "");
        actionUrl = replace(actionUrl, "?order=favorite", "");

        actionUrl = replace(actionUrl, "&order=asc", "");
        actionUrl = replace(actionUrl, "&order=desc", "");
        actionUrl = replace(actionUrl, "&order=rand", "");
        actionUrl = replace(actionUrl, "&order=favorite", "");

        let newUrl;
        if (actionUrl.length > 0) {
          newUrl = `${actionUrl}&order=${value}`;
        } else {
          newUrl = `?order=${value}`;
        }
        history.push(newUrl);
      }}
      value={order || "desc"}
    >
      {children}
    </HorizontalActionableHeader>
  );
};

ChangeSortOrder.defaultProps = {
  active: true
};
export default ChangeSortOrder;
