import React from "react";
import { generateAppEssentialPayload } from '../../../helpers/filterConversions';
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

// generates the selected filters
export const generateFilterFromQueryString = (objectType, history) => {

  const filterFromQueryString = generateAppEssentialPayload({
    objectType: 'video',
    queryString: history.location.search
  })

  return filterFromQueryString
}

export const getVideoCategories = (categories, history) => {

  const categoriesOptions = categories.map(category => {

    let filterFromQueryString = generateFilterFromQueryString('video', history)
    const isSelected = filterFromQueryString.video.filters.categories.findIndex(slug => isEqual(slug, category.slug))
    let obj = {
      label: category.name,
      value: category.id,
      isSelected: isSelected !== -1,
      slug: category.slug,
      image: category.icon,
      featuredImage: category.image,
    }
    return obj
  })


  return categoriesOptions
}

export const getVideoCollections = (collections, history) => {

  const collectionsOptions = collections.map(collection => {

    let filterFromQueryString = generateFilterFromQueryString('video', history)
    const isSelected = filterFromQueryString.video.filters.collections.findIndex(slug => isEqual(slug, collection.slug))
    let obj = {
      label: collection.name,
      value: collection.id,
      isSelected: isSelected !== -1,
      slug: collection.slug,
      image: collection.icon,
      featuredImage: collection.image,
    }
    return obj
  })


  return collectionsOptions
}


export const getVideoArchives = (history) => {
  let filterFromQueryString = generateFilterFromQueryString('video', history)

  const isSelected = parentSlug => filterFromQueryString.video.filters.archives.findIndex(slug => isEqual(slug, parentSlug))

  return [
    {
      label: '2014',
      value: '2014',
      isSelected: isSelected('2014') !== -1,
      slug: '2014',
    },
    {
      label: '2015',
      value: '2015',
      isSelected: isSelected('2015') !== -1,
      slug: '2015',
    },
    {
      label: '2016',
      value: '2016',
      isSelected: isSelected('2016') !== -1,
      slug: '2016',
    },
    {
      label: '2017',
      value: '2017',
      isSelected: isSelected('2017') !== -1,
      slug: '2017',
    },
    {
      label: '2018',
      value: '2018',
      isSelected: isSelected('2018') !== -1,
      slug: '2018',
    },
    {
      label: '2019',
      value: '2019',
      isSelected: isSelected('2019') !== -1,
      slug: '2019',
    },
  ]
}

// genarated the favs for reicpe filters nav
export const getFavouriteBoardsNavOptions = (boards, videoFilterName) => {
  const favouriteBoardsNavOptions = boards.map(board => {
    const isSelected = (board.slug === videoFilterName.slug || (board.slug === null && videoFilterName.title === 'All')) ? true : false

    let obj = {
      name: board.title,
      value: board.id,
      isSelected: isSelected,
      totalCount: board.total_count,
      slug: board.slug
    }
    return obj
  })

  return favouriteBoardsNavOptions
}

// generates the filer options like categories , collections .....
export const getFilterOptions = (
  categories,
  collections,
  history
) => {
  let filterFromQueryString = generateFilterFromQueryString('video', history)
  const filterOptions = [
    {
      title: 'Categories',
      options: categories,
      isOptionsList: true, // to differentiate between mappable list like collections , and non mappable like wightWatchers
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.video.filters.categories.length === 0 ? false : true
    },
  ]

  return filterOptions
}

// generated the fav options
export const getFavoriteFilterOptions = (favouriteBoardsNavOptions, videoFilterName) => {

  const favoriteFilterOptions = {
    title: 'Favorites',
    options: favouriteBoardsNavOptions,
    isSelected: (videoFilterName.type === 'favorite') ? true : false
  }

  return favoriteFilterOptions
}

// generates the search -> search by list
export const getSelectorList = () => {
  const selectorList = [
    {
      title: 'By Title',
      value: 'title'
    },
  ]

  return selectorList
}





