import { CREATE_NEW_USER } from "./actionTypes";

export default (name, email, password, newsletter) => ({
  type: CREATE_NEW_USER,
  payload: {
    name,
    email,
    password,
    newsletter
  },
});