import { put, takeEvery, call, select } from "redux-saga/effects";
import { getCalendarMacros } from './selectors';

import * as actions from '../actions/actionTypes'
import pushToSentry from "../helpers/pushToSentry";


function* task(action) {
  try {

    const {
      macros,
      calendarSettings: {
        calendarView,
      }
    } = action.payload;

    if (typeof localStorage !== 'undefined' && localStorage != null) {
      localStorage.setItem('macros', JSON.stringify(macros));

      localStorage.setItem('calendarView', JSON.stringify(calendarView));
    } else {
      pushToSentry('localStorage not available ')
    }




    // yield call(localStorage.setItem, 'showPreMadeMealPlans', JSON.stringify(showPreMadeMealPlans));

    // yield call(localStorage.setItem, 'showViewableMacros', JSON.stringify(showViewableMacros));

    yield put({
      type: actions.UPDATE_CALENDAR_SETTINGS_SUCCESS,
      payload: {
        macros,
        calendarView,
      }
    })
  } catch (e) {
    pushToSentry(e)
  }
}

export default function* startUp() {
  yield takeEvery(actions.UPDATE_CALENDAR_SETTINGS, task)
}