import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from "../../helpers/toJS";

import {
  sendNote,
  createBoard,
  sendComment,
  deleteFavourite,
  setObjectBoards,
  createFavourite,
  sendReplyComment,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  appEssentialFetch,
  addMealToMultipleDays,
  changeFooterStatus,
  agreeToMeteredVisit,
} from "../../actions";

import {
  getCategories,
  getFetchStatus,
  getUserDetails,
  getCommentsList,
  getWorkoutBoards,
  getNoteStackItem,
  getWorkoutDetails,
  getRelatedWorkouts,
  getObjectFetchStatus,
  getCommentPostStatus,
  getObjectEssentialFetchStatus,
  getWorkoutTypes,
} from "../../selectors";

import { getMeteredDetails, getTags } from "../../selectors/workout";

import { calendarListData, getCalendarMacros } from "../../selectors/calendar";

import SingleWorkout from "./components/singleWorkout";

const mapStateToProps = (state) => ({
  tags: getTags(state),
  notes: getNoteStackItem(state, "workout"),
  boards: getWorkoutBoards(state),
  comments: getCommentsList(state, "workout"),
  userDetails: getUserDetails(state),
  fetchStatus: getFetchStatus(state),
  activeWorkout: getWorkoutDetails(state),
  relatedWorkouts: getRelatedWorkouts(state),
  token: state.getIn(["loggedInUser", "token"]),
  commentPostStatus: getCommentPostStatus(state),
  isLoggedIn: state.getIn(["loggedInUser", "isLoggedIn"]),
  objectFetchStatus: getObjectFetchStatus(state, { objectType: "workout" }),
  objectEssentialFetchStatus: getObjectEssentialFetchStatus(state, {
    objectType: "workout",
  }),
  calendarList: calendarListData(state),
  setMacros: getCalendarMacros(state),
  workoutTypes: getWorkoutTypes(state),
  meteredDetails: getMeteredDetails(state),
});

const dispatchActionToProps = (dispatch) => ({
  sendNote: bindActionCreators(sendNote, dispatch),
  sendComment: bindActionCreators(sendComment, dispatch),
  createBoard: bindActionCreators(createBoard, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  sendReplyComment: bindActionCreators(sendReplyComment, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  addMealToMultipleDays: bindActionCreators(addMealToMultipleDays, dispatch),
  changeFooterStatus: bindActionCreators(changeFooterStatus, dispatch),
  agreeToMeteredVisit: bindActionCreators(agreeToMeteredVisit, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(SingleWorkout));
