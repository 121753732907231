import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const CalenderIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.65002 8.25H15.35M14.26 1V4.79M5.73999 1V4.79M5.24 2.89001H2.7C1.76 2.89001 1 3.74001 1 4.79001V16.16C1 17.21 1.76 18.05 2.7 18.05H17.11C18.16 18.05 19 17.29 19 16.35V4.79001C19 3.74001 18.24 2.90001 17.3 2.90001L14.22 2.89001H9.34" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};
export default CalenderIcon;