import React from 'react'
import moment from 'moment'

import isEmpty from 'lodash/isEmpty'

import ReactImageFallback from "react-image-fallback";
import Loader from '../../images/loader'
import capitalize from 'lodash/capitalize'
import RecipeFallbackImage from '../../images/recipefallback.png'

const MealPlanGridItem = ({
  plan,
  item,
  additionalStyles,
  showTag = true,
  removeCreated
}) => {
  if (isEmpty(plan)) {
    return null
  }
  const renderMeta = (plan) => {

    return (
      <div className="recipe-meta">
        <div className="plan-name"><p>{plan && plan.name}</p></div>
        <span className="plan-created">
          {/* {!removeCreated ? 'Created' : ""}  */}
          {moment.unix(plan.publish_date).format('MM/DD/YY')}</span>
      </div>
    )
  }
  let fallbackImage = RecipeFallbackImage;
  const imagesArray = [
    plan && plan.images && plan.images.length > 0 && plan.images[0] ? plan.images[0].tall_235 : '',
    plan && plan.images && plan.images.length > 0 && plan.images[1] ? plan.images[1].tall_235 : '',
    plan && plan.images && plan.images.length > 0 && plan.images[2] ? plan.images[2].tall_235 : '',
  ]
  return (
    <div className="position-wrapper">
      <div className="image-grid">
        <figure style={additionalStyles} className="image-grid-1">
          <ReactImageFallback
            src={imagesArray[0]}
            className="CardImg"
            fallbackImage={fallbackImage}
          />
        </figure>
        <figure style={additionalStyles} className="image-grid-2">
          <ReactImageFallback
            src={imagesArray[1]}
            className="CardImg"
            fallbackImage={fallbackImage}
          />
        </figure>
        <figure style={additionalStyles} className="image-grid-3">
          <ReactImageFallback
            src={imagesArray[2]}
            className="CardImg"
            fallbackImage={fallbackImage}
          />
        </figure>
      </div>
      <div className="featured-title">
        {/* <p>{capitalize(item.name) || capitalize(state.mealPlan.name)}</p> */}
        {/* <span>{moment(item.created_at).format('YYYY-MM-DD')}</span> */}
        {
          showTag &&
          <div className="flag">
            <span>New</span>
          </div>
        }
        {renderMeta(plan)}
      </div>
    </div>
  )
}


export default MealPlanGridItem