import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";

import rootReducer from "../reducers";
import rootSaga from "../sagas";

// saga middleware
const sagaMiddleware = createSagaMiddleware();
let middlewares = [sagaMiddleware];

// logger middleware
if (process.env.NODE_ENV === "development") {
  const logger = createLogger({
    collapsed: true,
    stateTransformer: (state) => state.toJS(),
  });
  middlewares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middlewares));

// Run the saga
sagaMiddleware.run(rootSaga);

export default store;
