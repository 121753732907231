import { put, takeEvery, call, select } from 'redux-saga/effects'
import favoritesTask from './subtask/favorites';
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    if (action.payload.objectType === 'recipe') {
      yield call(favoritesTask.getAllFavoritesInBoard, actions.GET_FAVORITES_FILTERED_DATA_SUCCESS, {
        objectType: action.payload.objectType,
        boardId: action.payload.boardId,
        replace: true
      })
    } else {
      yield call(favoritesTask.getAllFavzoritesInBoardWorkouts, actions.GET_FAVORITES_FILTERED_DATA_SUCCESS, {
        objectType: action.payload.objectType,
        boardId: action.payload.boardId,
        replace: true
      })
    }
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.GET_FAVORITES_FILTERED_DATA_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* fetchRecipesByCategory() {
  yield takeEvery(actions.GET_FAVORITES_FILTERED_DATA, task)
}