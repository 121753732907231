import React, { useState, useEffect } from 'react'

import { fromJS } from 'immutable'
import isEmpty from 'lodash/isEmpty'
import Filters from '../../common/filters'
import isNil from 'lodash/isNil'

import {
  generateVideoQueryFromFilterForFrontend
} from '../../../helpers/filterConversions'

import Modalwrapper from '../../common/modal';
import FavoriteModal from '../../common/favoriteModal';
import PopupModal from '../../common/popupModal';
import { removeCharecterFromIndex } from '../../../helpers/converters'

import {
  generateFilterFromQueryString,
  getVideoCategories,
  getVideoCollections,
  getFilterOptions,
  getSelectorList,
  getFavoriteFilterOptions,
  getFavouriteBoardsNavOptions,
  searchTerm,
} from './configVideo'

const FiltersHOC = (Component) => (props) => {
  const [state, setState] = useState({
    showLoginModal: false,
    activeUnAuthClickedRecipeName: '',
    showFilterModal: false,
    showFavoriteModal: false,
    activeFavoritingVideoId: null,
    favoriteView: false,
    showSearchDropdown: false,
  });

  const loginRedirectHandler = (title) => {
    setState(prevState => ({ ...prevState, activeUnAuthClickedRecipeName: title, showLoginModal: true }));
  }

  const loginModalCloseHandler = () => {
    setState(prevState => ({ ...prevState, showLoginModal: false }))
  }

  const toggleFavoriteModal = ({ itemId, modalState }) => {
    if (modalState) {
      props.createFavourite(itemId, 'video', '')
    }

    // Has to be instant
    // setState(prevState => ({ ...prevState, showFavoriteModal: modalState, activeFavoritingVideoId: itemId }));
  }

  useEffect(() => {
    if (!isNil(props.fetchStatus.globalError)) {
      props.history.push('/videos')
    }
  }, [props.fetchStatus.globalError]);

  // // for the fav modal rendering condition
  const activeFavoritingFeaturedVideo = (props.featuredList || []).find(video => video.id === state.activeFavoritingVideoId);
  const activeFavoritingVideo = isEmpty(activeFavoritingFeaturedVideo) ? (props.videos || []).find(video => video.id === state.activeFavoritingVideoId) : activeFavoritingFeaturedVideo;

  // // ALL GENERATED PROPS FOR NAV FILTER
  // // the categories data 
  const videoCategoriesNavOptions = getVideoCategories(props.categories, props.history)
  const videoCollectionsNavOptions = getVideoCollections(props.collections, props.history);

  // // the final nav options , ie collections ....
  const filterOptions = getFilterOptions(
    videoCategoriesNavOptions,
    videoCollectionsNavOptions,
    props.history
  )

  // // the fav boards data
  const favouriteBoardsNavOptions = getFavouriteBoardsNavOptions(props.boards, props.videoFilterName)

  // // the final fav options
  const favoriteFilterOptions = getFavoriteFilterOptions(favouriteBoardsNavOptions, props.videoFilterName)

  // // the search -> searchBy dropdown list 
  const selectorList = getSelectorList()

  // // ALL LOGIC FUNCTIONS
  // // what happens when an item in fav filter dropdown is selected (for query parma setting)
  const navFilterActionDispatch = (slug, filterName, defaultPage) => {
    let finalQueryParam = '';

    let filterFromQueryString = generateFilterFromQueryString('video', props.history)
    if (defaultPage) {

    } else {
      if (filterName === 'Categories') {
        const categories = filterFromQueryString.video.filters.categories;
        const index = categories.findIndex(category => category === slug);
        if (index === -1) {
          filterFromQueryString.video.filters.categories = [...categories, slug];
          filterFromQueryString.video.filters.search = [];
        } else {
          let splicedCategory = categories.splice(index, 1);
          filterFromQueryString.video.filters.categories = categories
        }
      } else if (filterName === 'Collections') {
        const collections = filterFromQueryString.video.filters.collections;
        const index = collections.findIndex(collection => collection === slug);
        if (index === -1) {
          filterFromQueryString.video.filters.collections = [...collections, slug];
          filterFromQueryString.video.filters.search = [];
        } else {
          let splicedCategory = collections.splice(index, 1);
          filterFromQueryString.video.filters.collections = collections
        }
      }

      finalQueryParam = generateVideoQueryFromFilterForFrontend(fromJS(filterFromQueryString.video.filters))

      // condition to remove if & present in index 0
      if (finalQueryParam.charAt(0) === '&') {
        finalQueryParam = removeCharecterFromIndex(finalQueryParam, 0)
      }
      props.history.push(`/videos?${finalQueryParam}`)
    }
  }

  const isFavorites = (props.match.path === '/videos/favorites/:favoriteBoardSlug' || props.match.path === '/videos/favorites')
  const displayImage = !isEmpty(activeFavoritingVideo) ? activeFavoritingVideo.images.square : null;

  const showFilters = (props.match.path !== '/videos/categories/:categoryTopic') || (props.match.path !== '/videos/categories/:collectionTopic');

  return (
    <section className="videos">
      <Filters
        objectType='video'
        showFilters={showFilters}
        actions={(name, slug, filterName) => navFilterActionDispatch(slug, filterName)}
        options={filterOptions}
        staticOption={
          {
            primaryOption: 'Clear Filters',
            secondaryOption: 'All Videos',
            route: '/videos',
            secondaryRoute: '/videos/preview'
          }
        }
        history={props.history}
        isAuthenticated={props.isAuthenticated}
        dropDownReturnActionText={`See all ${props.searchCount} Videos`}
        dropDownReturnAction={(searchText, searchBy) => {
          props.history.push(`/videos${searchText.length > 0 ? `?search=${searchText}` : ''}`)
        }}
        selectorList={getSelectorList()}
        placeholder='Search videos'
        searchText={props.searchTerm}
        onSearchChange={(searchText, searchBy) => props.searchVideos({
          objectType: 'video',
          searchText: searchText,
          searchBy: 'title'
        })}
        onClickSearchClear={() => {
          history.push(`/videos`)
        }}
        defaultSearchText={props.searchTerm}
        defaultSearchBy="title"
        searchList={props.searchList}
        searchStatus={props.fetchStatus.videosSearch}
        searchCount={props.searchCount}
        showDefaultInput={!isNil(props.searchTerm)}
        showInstantResults

        isFavorites={isFavorites}
        favoriteOptions={favoriteFilterOptions}
        updateFavouriteBoard={props.updateFavouriteBoard}
        deleteFavouriteBoard={props.deleteFavouriteBoard}
        createBoard={props.createBoard}
      />
      <Component
        {...props}
        loginRedirectHandler={loginRedirectHandler}
        loginModalCloseHandler={loginModalCloseHandler}
        toggleFavoriteModal={toggleFavoriteModal}
      />
      <Modalwrapper
        isOpen={state.showFavoriteModal}
        minHeight='650px'
        onRequestClose={() => { toggleFavoriteModal({ itemId: null, modalState: false }) }}
      >
        <FavoriteModal
          boards={props.boards}
          objectType="video"
          activeObject={activeFavoritingVideo}
          createBoard={props.createBoard}
          clearBoardSelection={props.clearBoardSelection}
          setObjectBoards={props.setObjectBoards}
          toggleFavoriteModal={toggleFavoriteModal}
          deleteFavouriteBoard={props.deleteFavouriteBoard}
          updateFavouriteBoard={props.updateFavouriteBoard}
          displayImage={displayImage}
        />
      </Modalwrapper>
      <Modalwrapper
        isOpen={state.showLoginModal}
        onRequestClose={loginModalCloseHandler}>
        <PopupModal
          objectName={state.activeUnAuthClickedRecipeName}
          history={history}
          closeModal={loginModalCloseHandler}
          objectType="video"
        />
      </Modalwrapper>
    </section>

  )
}

export default FiltersHOC;