import createReducer from "../helpers/createReducers";

import { fromJS, List, update } from "immutable";

// TODO: all action payload.data should have a type
const initialState = fromJS({
  show: false,
  message: null,
  type: null,
  iconType: null
});

export default createReducer(initialState, {
  SHOW_TOAST_MESSAGE: (state, action) => {
    state = state.setIn(['show'], true);
    state = state.setIn(['type'], action.payload.type);
    state = state.setIn(['message'], action.payload.message);
    state = state.setIn(['iconType'], action.payload.iconType)
    return state
  },
  REMOVE_TOAST_MESSAGE: (state, action) => {
    state = state.setIn(['show'], false);
    state = state.setIn(['message'], null);
    state = state.setIn(['iconType'], null)
    return state
  }
});