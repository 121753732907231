import React, { useState } from 'react';
import ReactImageFallback from "react-image-fallback";
import isEmpty from 'lodash/isEmpty'
import { Row, Col } from "react-flexbox-grid";
import RecipeFallbackImage from '../images/recipefallback.png';
import WorkoutFallbackImage from '../images/workoutfallback.png';

import GridCardAction from './gridCardAction'

import Favorite from '../images/Favorite.js';
import Placeholder from '../common/placeholder'
import VideoPlayIcon from '../images/VideoPlayIcon';
import BookmarkFillIcon from '../images/BookmarkFillIcon';
import BookmarkUnfillIcon from '../images/BookmarkUnfillIcon';
import ThumbFavIcon from '../images/ThumbFavIcon';
import ThumbNoteIcon from '../images/ThumbNoteIcon';
import Button from "../common/button";
import getUserPlanType from "../../helpers/getUserPlanType";
import isNil from 'lodash/isNil';
import { capitalize, toUpper } from 'lodash';
import Modalwrapper from './modal';


const GridCard = (props) => {
  const {
    item,
    objectType,
    isAuthenticated,
    loginRedirect,
    toggleFavoriteModal,
    renderMeta,
    renderPreMeta,
    additionalStyles,
    imageOnly,
    coverImage,
    thumbnail,
    isLoading,
    showTag,
    showAction,
    hoverEffect,
    userDetails,
    target,
    openInNew
  } = props;

  const title = item.title
  const displayImage = coverImage;
  const slug = item.slug;
  const isFavorite = item.favorite;
  const isNote = item.notes;

  const [cardActionState, setCardActionState] = useState({
    show: false,
  })
  const [isPremiumPopupActive, setIsPremiumPopupActive] = useState(false);

  const [showGridCardAction, setShowGridCardAction] = useState(false)

  let fallbackImage;
  let cardStyle
  if (props.feature === "workout" || props.feature === 'blog' || props.feature === 'video') {
    fallbackImage = WorkoutFallbackImage
    cardStyle = {
      height: 60,
    };
  } else {
    fallbackImage = RecipeFallbackImage
    cardStyle = {
      height: 270,
    };
  }

  const handleCardClick = (e) => {
    e.preventDefault()
    if (e.metaKey) {
      window.open(`/${objectType}/${slug}`, '_blank');
      window.focus()
    } else if (openInNew) {
      window.open(`/${objectType}/${slug}`, '_blank');
      window.focus();
    } else {
      e.stopPropagation();

      if (!isAuthenticated && item.premium) {
        setIsPremiumPopupActive(true);
      } else {
        if (!item.isLoading && item.slug) {
          props && props.history ? props.history.push(`/${objectType}/${slug}`) : null;
        }
      }


    }

  }

  const renderLoader = () => {
    if (objectType === 'recipe') {
      return (
        <>
          <Placeholder styles={{ height: 15, marginTop: 15 }} />
          <Placeholder styles={{ height: 15, marginTop: 15 }} />
          <Placeholder styles={{ height: 15, marginTop: 15 }} />
        </>
      )
    }
    if (objectType === 'video') {
      return (
        <>
        </>
      )
    } else if (objectType === 'workout' || objectType === 'blog') {
      return (
        <>
          <Placeholder styles={{ height: 15, marginTop: 15 }} />
          <Placeholder styles={{ height: 15, marginTop: 15 }} />
          <Placeholder styles={{ height: 15, marginTop: 15 }} />
        </>
      )
    }
  }
  const renderFavorite = () => {
    // isAuthenticated ? ((objectType === 'video' || objectType === 'blog') ? renderVideoOrBlogs() : null) : null
    if (isAuthenticated) {
      if (objectType === "blog" || objectType === "video") {
        return (
          <>
            {
              objectType === "blog"
                ? <Favorite
                  item={item}
                  favorite={isFavorite}
                  objectType={objectType}
                  onClickFavorite={toggleFavoriteModal}
                  deleteFavourite={props.deleteFavourite}
                  thumbnail={thumbnail}
                  displayFilledIcon={BookmarkFillIcon}
                  displayUnFilledIcon={BookmarkUnfillIcon}
                  show={showGridCardAction}
                />
                : <>
                  <Favorite
                    item={item}
                    favorite={isFavorite}
                    objectType={objectType}
                    onClickFavorite={toggleFavoriteModal}
                    deleteFavourite={props.deleteFavourite}
                    thumbnail={null}
                    show={showGridCardAction}
                  />
                  <VideoPlayIcon></VideoPlayIcon>
                </>
            }
          </>
        )
      } else {
        if (
          !isNil(userDetails) &&
          !isNil(userDetails.details) &&
          !isEmpty(userDetails.details)
        ) {
          const userPlan = getUserPlanType(userDetails);

          return (
            <>
              {
                (userPlan !== "premiumPlan" && userPlan !== 'grandfather') &&
                <>
                  <Favorite
                    item={item}
                    favorite={isFavorite}
                    objectType={objectType}
                    onClickFavorite={toggleFavoriteModal}
                    deleteFavourite={props.deleteFavourite}
                    thumbnail={null}
                    show={showGridCardAction}
                  />
                </>
              }
            </>
          )
        } else {
          return null
        }
      }
    } else {
      return null
    }
  }


  // const isAuthenticated = !isNil(userDetails) && !isNil(userDetails.details) && !isEmpty(userDetails.details);

  const renderMetaData = () => item.isLoading ? (
    renderLoader()
  ) : (
    <div className="cardWrap">
      {renderPreMeta}
      <p className="cardTitle">{title}</p>
      {renderMeta}
      {
        // (!isAuthenticated && item.premium && objectType !== 'video') ? (
        //   <div className='premium-recipe-indicator'>
        //     {`MEMBER ONLY ${toUpper(objectType)}`}
        //   </div>
        // ) : null
      }

    </div>
  )

  const renderGridCardAction = () => {
    if (!isNil(userDetails) && !isNil(userDetails.details) && !isEmpty(userDetails.details)) {
      const userPlan = getUserPlanType(userDetails);

      return (
        (isAuthenticated && (userPlan === "premiumPlan" || userPlan === "grandfather")) ? (
          (objectType === 'recipe' || objectType === 'workout') ? (
            <GridCardAction
              item={item}
              showAction={showGridCardAction}
              show={cardActionState.show}
              setCardActionState={setCardActionState}
              objectType={objectType}
              objectId={item.id}
              favorite={{
                isFavorite: isFavorite,
                thumbnail: (objectType === 'recipe' || objectType === 'workout') ? thumbnail : null
              }}
              toggleFavoriteModal={toggleFavoriteModal}
            />
          ) : null
        ) : null
      )
    } else {
      return null
    }
  }

  return (
    <>
      <a
        onMouseEnter={() => setShowGridCardAction(true)}
        onMouseLeave={() => setShowGridCardAction(false)}
        href={!isLoading ? `/${objectType}/${slug}` : null}
        onClick={handleCardClick}
        className="listCard"
        target={target}>
        <figure className="listCardImg iconOverlay" style={additionalStyles}>
          <ReactImageFallback
            src={item.isLoading ? () => <Placeholder styles={cardStyle} /> : displayImage}
            className="CardImg"
            fallbackImage={fallbackImage}
            item={item}
          />
          {
            renderFavorite()
          }

        </figure>
        {hoverEffect}
        {
          renderGridCardAction()
        }
        {
          !imageOnly && renderMetaData()
        }
      </a>
      {showTag &&
        <div className="flag">
          <span>New</span>
        </div>
      }
      {(!isAuthenticated && item.premium && !openInNew) &&
        <div className="premium-flag">
          <span>★</span>
        </div>
      }
      <Modalwrapper
        isOpen={isPremiumPopupActive}
        minHeight="350px"
        maxHeight="350px"
        maxWidth="650px"
        onRequestClose={() => {
          setIsPremiumPopupActive(false);
        }}
      >
        <div className='premium-modal-container'>
          <div className='premium-modal-content'>
            <h3>This {capitalize(objectType)} was specifically made for our members</h3>
            <p className='description'>Dashing Dish has been ad-free and member supported for over 10 years! Consider joining our wonderful community today</p>
            <Button btnText="See plans and pricing" onClick={(e) => props.history.push("/pricing")}> See plans and Pricing</Button>
            <p className='other-description' onClick={e => setIsPremiumPopupActive(false)}>

              No thanks, take me back to the hundreds of free {objectType}s
            </p>
          </div>
        </div>
      </Modalwrapper>
    </>
  )
}

GridCard.defaultProps = {
  loginRedirect: () => { },
  showAction: true,

}

export default GridCard;