import { put, takeEvery, call, select } from 'redux-saga/effects'
import axios from 'axios'
import { showToastMessage } from '../actions'
import { getCurrentObjectId, getNoteStack, getRecipeByIdFromAnyList, getToken } from './selectors'
import * as actions from '../actions/actionTypes';
import { mealPlan } from '../helpers/api'
import { post } from '../helpers/requests'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {

  try {

    const {
      date,
      title
    } = action.payload;

    const token = yield select(getToken);

    const { data } = yield call(axios, post(
      mealPlan.saveCalendarDay(),
      {
        date,
        title
      },
      '',
      token
    ));

    // calling action to store the updates
    yield put({
      type: actions.SAVE_CALENDAR_DAY_SUCCESS,
      payload: {
        data
      }
    })
    yield put(showToastMessage(`✅ Calendar day saved`, 'success', 'note'));
  } catch (e) {
    yield put(showToastMessage(`❗️ Calendar day save failure`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.SAVE_CALENDAR_DAY_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* saveCalendarDay() {
  yield takeEvery(actions.SAVE_CALENDAR_DAY, task)
}