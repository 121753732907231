import React, { useState } from 'react'
import Radiobox from "../../common/radiobox";
import {
  addItemsToGroceryWaitlist,
  removeItemsFromGroceryWaitlist
} from "../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TappableRow from '../../common/TappableRow';

const IngredientRow = (props) => {
  const [strikeThrough, setStrikeThrough] = useState(false);
  const onSelect = (selected, ingredient) => {
    if (selected) {
      props.addItemsToGroceryWaitlist([{ ...ingredient, recipeId: props.recipeId }])
    } else {

      props.removeItemsFromGroceryWaitlist(ingredient.id)
    }
  }
  const listClass = strikeThrough ? `ingredientItem lineThrough` : `ingredientItem`;
  return (
    <li
      className="groupList"
      key={props.key}
    >
      <Radiobox
        onSelect={(isChecked, content) => onSelect(isChecked, content)}
        type="checkbox"
        checkId={props.ingredient.id}
        checked={props.selected}
        content={props.ingredient}
        label={props.amount}
        disabled={!props.isLoggedIn ? true : false}
      />
      <p className={listClass} onClick={() => {
        setStrikeThrough(!strikeThrough);
      }}>
        {props.item}
      </p>
    </li>
  )
}


const dispatchActionToProps = dispatch => ({
  addItemsToGroceryWaitlist: bindActionCreators(addItemsToGroceryWaitlist, dispatch),
  removeItemsFromGroceryWaitlist: bindActionCreators(removeItemsFromGroceryWaitlist, dispatch),
});

//TODO: Remove connect (connect should only be at the root)
export default connect(null, dispatchActionToProps)(IngredientRow);