import React from 'react';
import '../styles/common.scss';

const Loader = ({
  customStyle
})=> {
    return(
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 75 75" xml space="preserve" className="loader">
                <path style={customStyle} id="Mweb-logo.svg" className="brandLogo" d="M37.5,0C16.8,0,0,16.8,0,37.5S16.8,75,37.5,75S75,58.2,75,37.5S58.2,0,37.5,0L37.5,0z
                    M56,58.8h-9.3c-1.6,0-2.9-1.3-2.9-2.9c0,0,0,0,0,0v-1.5c-1.9,2.6-5,5.4-10,5.4c-7.4,0-14.8-6-14.8-16.9c0-12,8.4-18.7,16.3-18.7
                c4.1,0,6.9,1.9,8.5,4.2v-9.7c0-1.6-0.8-2.5-2.5-2.5v-3.7l9.1-1c1.5-0.2,2.9,1,3.1,2.5c0,0.1,0,0.2,0,0.3v11.3l0,0v27
                c0,1.6,0.8,2.5,2.5,2.5L56,58.8L56,58.8z M43.8,38l0-0.4c0-4.9-2.6-8.2-5.6-8.2c-3.8,0-7.5,4.4-7.5,13.6c0,6.1,2.5,10.1,6.2,10.1
                c3.5,0,5.6-2.3,6.9-4.5v-5.3l0,0L43.8,38L43.8,38z"/>
        </svg>
    )
}

export default Loader;
