import isEmpty from 'lodash/isEmpty'
import reduceRight from 'lodash/reduceRight'

export default (workout, type = 'full_lg') => {
  // For workout meta
  if (workout && workout.thumbnail) {
    return workout.thumbnail;
  }

  if (workout && !isEmpty(workout.images)) {
    return workout.images[type];
  }
  // if there is a video based image 
  let instructions = workout && workout.instructions;

  const thumbnail = reduceRight(instructions, (accumulator, instruction) => {
    const exercises = instruction.exercises;

    (exercises || [])
      .forEach((exercise) => {
        if (!isEmpty(exercise.photos) && exercise.photos.length > 0) {
          const eachPhotoGroup = exercise.photos;
          eachPhotoGroup
            .forEach(photo => {
              if (photo && photo.thumbnail && photo.thumbnail.length > 0) {

                accumulator = photo.thumbnail
              }
            })
        }
      })
    return accumulator
  }, '')

  if (thumbnail) {
    return thumbnail
  }
  // If no video based image
  instructions = workout.instructions;
  return reduceRight(instructions, (accumulator, instruction) => {
    const exercises = instruction.exercises;

    (exercises || [])
      .forEach((exercise) => {

        if (!isEmpty(exercise.photos) && exercise.photos.length > 0) {
          const eachPhotoGroup = exercise.photos;

          eachPhotoGroup
            .forEach(photo => {
              if (photo.photos && photo.photos.length > 0) {
                accumulator = photo.photos[0].original
              }
            })
        }
      })
    return accumulator
  }, '')

}