import { fork } from "redux-saga/effects";

import startUp from "./startUp.js";
import loginUserSaga from "./loginUser.js";
import logoutNavbar from './logoutNavbar';

import rootCommonSaga from './common';
import fetchFilteredWorkoutsData from './fetchFilteredWorkoutsData'
import fetchFilteredData from './fetchFilteredData'
import setCalendarMacros from './setCalendarMacros'

export default function* rootSaga() {
  yield fork(loginUserSaga);
  yield fork(logoutNavbar);
  yield fork(rootCommonSaga);
  // the below two are only for web not mobile
  yield fork(fetchFilteredWorkoutsData);
  yield fork(fetchFilteredData);
  yield fork(setCalendarMacros);
}
