import React, { useEffect, useReducer } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { Row, Col } from "react-flexbox-grid";
import Button from "../common/button";
import Selector from "../common/selector";
import CustomInput from "../common/customInput";
import FacebookOutlineIcon from "../images/FacebookOutlineIcon";
import VimeoOutlineIcon from "../images/VimeoOutlineIcon";
import InstaOutlineIcon from "../images/InstaOutlineIcon";
import TwitOutlineIcon from "../images/TwitOutlineIcon";
import PinOutlineIcon from "../images/PinOutlineIcon";
import FormSucessIcon from "../images/formSucess";

import GroceryStock from "../images/groceryStock.png";
import FrameIcon from "../images/frameIcon";
import { baseUrl, grocery } from "../../helpers/api";

import {
  appEssentialFetch,
  addSentryError,
  showToastMessage,
} from "../../actions";
import pushToSentry from "../../helpers/pushToSentry";
import { TransitionOne, TransitionTwo } from "../common/transitions";

const initialState = {
  subject: "other",
  name: "",
  yourEmail: "",
  nomineeEmail: "",
  message: "",
  isValidForm: false,
  nameError: null,
  yourEmailError: null,
  nomineeEmailError: null,
  messageError: null,
  yourEmailFocus: false,
  nomineeEmailFocus: false,
  nameFocus: false,
  messageFocus: false,
  formSubmitted: false,
  formsubmitting: false,
  formSubmitSuccess: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "YOUR_EMAIL_CHANGE":
      return {
        ...state,
        yourEmail: action.payload.email,
      };
    case "NOMINEE_EMAIL_CHANGE":
      return {
        ...state,
        nomineeEmail: action.payload.email,
      };
    case "NAME_CHANGE":
      return {
        ...state,
        name: action.payload.name,
      };
    case "MESSAGE_CHANGE":
      return {
        ...state,
        message: action.payload.message,
      };
    case "SUBJECT_CHANGE":
      return {
        ...state,
        subject: action.payload.subject,
      };
    case "VALIDATE_FORM":
      let yourEmailError = null;
      let nomineeEmailError = null;

      let nameError = null;
      let messageError = null;
      let isValidForm = true;
      if (
        !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(state.yourEmail)
      ) {
        yourEmailError = "Enter a valid email Id";
      }
      if (
        !/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
          state.nomineeEmail
        )
      ) {
        nomineeEmailError = "Enter a valid email Id";
      }
      if (state.name.length < 2) {
        nameError = "Name should be at least 2 char. long.";
      }
      if (state.message.length < 2) {
        messageError = "Message should be at least 2 char. long.";
      }
      if (yourEmailError || nomineeEmailError || nameError || messageError) {
        isValidForm = false;
      }
      return {
        ...state,
        yourEmailError,
        nomineeEmailError,
        nameError,
        messageError,
        isValidForm,
      };
    case "ON_BLUR":
      return {
        ...state,
        yourEmailFocus:
          action.payload.yourEmailFocus && !state.yourEmailFocus
            ? true
            : state.yourEmailFocus,
        nomineeEmailFocus:
          action.payload.nomineeEmailFocus && !state.nomineeEmailFocus
            ? true
            : state.nomineeEmailFocus,
        nameFocus:
          action.payload.nameFocus && !state.nameFocus ? true : state.nameFocus,
        messageFocus:
          action.payload.messageFocus && !state.messageFocus
            ? true
            : state.messageFocus,
      };
    case "FORM_SUBMIT":
      return {
        ...state,
        formsubmitting: action.payload.formsubmitting,
        formSubmitted: action.payload.formSubmitted,
      };
    case "FORM_SUCCESS":
      return {
        ...initialState,
        formsubmitting: false,
        formSubmitted: true,
        formSubmitSuccess: true,
      };
    default:
      return state;
  }
};

const GiveawayPage = (props) => {
  useEffect(() => {
    props.appEssentialFetch();
  }, []);

  const [state, dispatch] = useReducer(reducer, initialState);

  const submitForm = () => {
    dispatch({
      type: "FORM_SUBMIT",
      payload: { formsubmitting: true, formSubmitted: false },
    });
    if (state.isValidForm) {
      // axios call
      axios({
        method: "POST",
        url: `${baseUrl}/api/grocery-card-giveaway`,
        data: {
          contact: {
            reason: state.subject,
            name: state.name,
            yourEmail: state.yourEmail,
            nomineeEmail: state.nomineeEmail,
            message: state.message,
          },
        },
      })
        .then((response) => {
          dispatch({
            type: "FORM_SUBMIT",
            payload: { formsubmitting: false, formSubmitted: true },
          });
          dispatch({ type: "FORM_SUCCESS" });
          props.showToastMessage(`👍🏼Nomination sent successfully!`, "success");
        })
        .catch((e) => {
          pushToSentry(e);
          props.showToastMessage(`❗️Nomination error!`, "error");
          dispatch({
            type: "FORM_SUBMIT",
            payload: { formsubmitting: false, formSubmitted: true },
          });
          props.addSentryError({
            error: e.message,
            eventId,
          });
        });
    }
  };

  return (
    <main className="card-give-away">
      <Helmet>
        <title>Grocery Card Giveaway | Dashing Dish</title>
        <meta
          name="description"
          content="Dashingdish Grocery Card Giveaway page"
        />
      </Helmet>
      <section className="giveaway-page">
        <div className="container">
          <Row className="giveaway-wrapper no-margin">
            <Col xs={12} lg={12} className="no-padding">
              <div className="giveaway-form">
                {!state.formSubmitSuccess && (
                  <TransitionOne>
                    <Row>
                      <Col xl={6} lg={12}>
                        <div className="giveaway-hero-wrapper">
                          <h3>Grocery Card Giveaway</h3>
                          <p>
                            Nominate a Family in Need of a Grocery Gift Card
                            this Holiday Season
                          </p>
                        </div>
                      </Col>
                      <Col xl={6} lg={12} className="form-wrapper">
                        <p className="nominate-title">
                          Nominate a family in need
                        </p>
                        <p className="nominate-additional-info">
                          (Note: All information will be kept private, and your
                          information will not be shared with nominee)
                        </p>
                        <form>
                          <CustomInput
                            type="name"
                            placeholder="Type here"
                            label="Your name"
                            value={state.name}
                            additionalDivClass="inline-div-wrapper"
                            additionalLabelClass="inline-label-wrapper"
                            additionalInputClass="inline-input-wrapper"
                            onChange={(e) => {
                              dispatch({
                                type: "NAME_CHANGE",
                                payload: { name: e.target.value },
                              });
                              dispatch({ type: "VALIDATE_FORM" });
                            }}
                            errorMessage={
                              state.nameError && state.nameFocus
                                ? state.nameError
                                : null
                            }
                            onBlur={(e) =>
                              dispatch({
                                type: "ON_BLUR",
                                payload: { nameFocus: true },
                              })
                            }
                          />
                          <CustomInput
                            type="email"
                            placeholder="Type here"
                            label="Your email"
                            value={state.yourEmail}
                            additionalDivClass="inline-div-wrapper"
                            additionalLabelClass="inline-label-wrapper"
                            additionalInputClass="inline-input-wrapper"
                            onChange={(e) => {
                              dispatch({
                                type: "YOUR_EMAIL_CHANGE",
                                payload: { email: e.target.value },
                              });
                              dispatch({ type: "VALIDATE_FORM" });
                            }}
                            errorMessage={
                              state.yourEmailError && state.yourEmailFocus
                                ? state.yourEmailError
                                : null
                            }
                            onBlur={(e) =>
                              dispatch({
                                type: "ON_BLUR",
                                payload: { yourEmailFocus: true },
                              })
                            }
                          />

                          <CustomInput
                            type="email"
                            placeholder="Type here"
                            label="Nomination email"
                            value={state.nomineeEmail}
                            additionalDivClass="inline-div-wrapper"
                            additionalLabelClass="inline-label-wrapper"
                            additionalInputClass="inline-input-wrapper"
                            onChange={(e) => {
                              dispatch({
                                type: "NOMINEE_EMAIL_CHANGE",
                                payload: { email: e.target.value },
                              });
                              dispatch({ type: "VALIDATE_FORM" });
                            }}
                            errorMessage={
                              state.nomineeEmailError && state.nomineeEmailFocus
                                ? state.nomineeEmailError
                                : null
                            }
                            onBlur={(e) =>
                              dispatch({
                                type: "ON_BLUR",
                                payload: { nomineeEmailFocus: true },
                              })
                            }
                          />
                          <div className="message-container inline-div-wrapper">
                            <label className="static-value inline-label-wrapper">
                              Reason for nomination
                            </label>
                            <textarea
                              value={state.message}
                              className="messageInput inline-textarea-wrapper"
                              placeholder="Type message here."
                              onChange={(e) => {
                                dispatch({
                                  type: "MESSAGE_CHANGE",
                                  payload: { message: e.target.value },
                                });
                                dispatch({ type: "VALIDATE_FORM" });
                              }}
                              onBlur={(e) =>
                                dispatch({
                                  type: "ON_BLUR",
                                  payload: { messageFocus: true },
                                })
                              }
                            />
                          </div>
                          {state.messageError && state.messageFocus && (
                            <div className="errorText">
                              <FrameIcon></FrameIcon>
                              <p>{state.messageError}</p>
                            </div>
                          )}
                          <Button
                            disabled={!state.isValidForm}
                            type="button"
                            btnClass="primary btn-full"
                            btnText="Submit"
                            onClick={submitForm}
                            isLoading={state.formsubmitting}
                          />
                        </form>
                      </Col>
                    </Row>
                  </TransitionOne>
                )}
                {state.formSubmitSuccess && (
                  <>
                    <TransitionOne>
                      <div className="giveaway-form-report">
                        <figure>
                          <FormSucessIcon></FormSucessIcon>
                        </figure>
                        <h3>Success!</h3>
                        <p>
                          We received your email! Look for a <br></br>response
                          within the next 24-48 hours!
                        </p>
                        <p>
                          {" "}
                          If you need an answer quicker, you may<br></br>try one
                          of the options on the right!
                        </p>
                      </div>
                    </TransitionOne>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </main>
  );
};

const mapStateToProps = () => ({});

const dispatchActionToProps = (dispatch) => ({
  addSentryError: bindActionCreators(addSentryError, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(GiveawayPage);
``;
