import React from 'react'
import cx from 'classnames'

import UpArrow from '../../../images/UpArrow';
import DownArrow from '../../../images/DownArrow';
import Placeholder from '../../../common/placeholder';

const FaqQuestion = ({
  question,
  answer,
  activeOpenedFaqSlug,
  setActiveOpenedFaqSlug,
  isActive,
  isLoading,
}) => (
  <div
    className={cx("question-wrapper", { active: isActive })}
    onClick={() => setActiveOpenedFaqSlug(!isActive ? question : null)}
  >
    <div className="question-content">
      <div className="question-text-wrapper">
        {isLoading ? <Placeholder styles={{ width: 300, height: 20 }} /> : question}
      </div>
      {isActive ? <UpArrow /> : <DownArrow />}
    </div>
    {
      isActive ? (
        <div className="answer-content">
          {answer}
        </div>
      ) : null
    }
  </div>
)

export default FaqQuestion;