import { generateAppEssentialPayload } from '../../../helpers/filterConversions';
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

// generates the selected filters
export const generateFilterFromQueryString = (objectType, history) => {
  const filterFromQueryString = generateAppEssentialPayload({
    objectType: objectType,
    queryString: history && history.location && history.location.search,
  })
  return filterFromQueryString
}

// genarated the collections for reicpe filters nav
export const getRecipesDietariesNavOptions = (recipesDietaries, history) => {
  const recipesDietariesNavOptions = recipesDietaries.map(dietary => {

    let filterFromQueryString = generateFilterFromQueryString('recipe', history)

    const isSelected = filterFromQueryString.recipe.filters.dietaries.findIndex(slug => isEqual(slug, dietary.slug))
    let obj = {
      label: dietary.name,
      value: dietary.id,
      isSelected: isSelected !== -1 ? true : false,
      slug: dietary.slug,
      image: dietary.icon,
      featuredImage: dietary.image,
    }
    return obj
  })

  return recipesDietariesNavOptions
}

// genarated the categories for reicpe filters nav
export const getRecipesFoodTypesNavOptions = (recipesFoodTypes, history) => {
  const recipesFoodTypesNavOptions = recipesFoodTypes.map(foodType => {

    let filterFromQueryString = generateFilterFromQueryString('recipe', history)
    const isSelected = filterFromQueryString.recipe.filters.foodTypes.findIndex(slug => isEqual(slug, foodType.slug))
    let obj = {
      label: foodType.name,
      value: foodType.id,
      isSelected: isSelected !== -1 ? true : false,
      slug: foodType.slug,
      image: foodType.icon,
    }
    return obj
  })

  return recipesFoodTypesNavOptions
}

export const getRecipesSpecialitiesNavOptions = (recipesSpecialities, history) => {
  const recipesSpecialitiesNavOptions = recipesSpecialities.map(speciality => {

    let filterFromQueryString = generateFilterFromQueryString('recipe', history)

    const isSelected = filterFromQueryString.recipe.filters.specialities.findIndex(slug => isEqual(slug, speciality.slug))

    let obj = {
      label: speciality.name,
      value: speciality.id,
      isSelected: isSelected !== -1 ? true : false,
      slug: speciality.slug,
      image: speciality.icon,
    }
    return obj
  })

  return recipesSpecialitiesNavOptions
}

// genarated the favs for reicpe filters nav
export const getFavouriteBoardsNavOptions = (boards, recipeFilterName) => {
  const favouriteBoardsNavOptions = boards.map(board => {
    const isSelected = (board.slug === recipeFilterName.slug || (board.slug === null && recipeFilterName.title === 'All')) ? true : false

    let obj = {
      name: board.title,
      value: board.id,
      isSelected: isSelected,
      totalCount: board.total_count,
      slug: board.slug
    }
    return obj
  })

  return favouriteBoardsNavOptions
}

// generates the props for weightwatchers input range
export const getWeightWatchersInputRangeOptions = (history) => {

  let filterFromQueryString = generateFilterFromQueryString('recipe', history)

  let fpLength = (filterFromQueryString.recipe.filters.fp.length - 1)
  let spLength = (filterFromQueryString.recipe.filters.sp.length - 1)
  let wwpLength = (filterFromQueryString.recipe.filters.wwp.length - 1)

  const weightWatchersInputRangeOptions = [
    {
      label: 'FP',
      labelClass: 'filterTitle',
      wrapperClass: 'FreestyleStyle',
      minValue: 0,
      maxValue: 15,
      defaultMinValue: filterFromQueryString.recipe.filters.fp[1],
      defaultMaxValue: filterFromQueryString.recipe.filters.fp[fpLength] // this is no longer requried as range has become a single range
    },
    {
      label: 'SP',
      labelClass: 'filterTitle',
      wrapperClass: 'SmartPoints',
      minValue: 0,
      maxValue: 15,
      defaultMinValue: filterFromQueryString.recipe.filters.sp[1],
      defaultMaxValue: filterFromQueryString.recipe.filters.sp[spLength]
    },
    {
      label: 'WWP',
      labelClass: 'filterTitle',
      wrapperClass: 'WWPstyle',
      minValue: 0,
      maxValue: 15,
      defaultMinValue: filterFromQueryString.recipe.filters.wwp[1],
      defaultMaxValue: filterFromQueryString.recipe.filters.wwp[wwpLength]
    },
  ]

  return weightWatchersInputRangeOptions
}

// to check is weightwatchers is selected
const isWeightwatchersSelected = (history) => {

  let filterFromQueryString = generateFilterFromQueryString('recipe', history)

  const recipesFilters = filterFromQueryString.recipe.filters

  if ((recipesFilters.fp[0] !== '0') ||
    (recipesFilters.fp[1] !== '15') ||
    (recipesFilters.sp[0] !== '0') ||
    (recipesFilters.sp[1] !== '15') ||
    (recipesFilters.wwp[0] !== '0') ||
    (recipesFilters.wwp[1] !== '15')
  ) {
    return true
  } else {
    return false
  }
}

// generates the filer options like categories , collections .....
export const getFilterOptions = (recipesFoodTypesNavOptions, recipesDietariesNavOptions, recipesSpecialitiesNavOptions, weightWatchersOptions, history) => {

  let filterFromQueryString = generateFilterFromQueryString('recipe', history)

  const filterOptions = [
    {
      title: 'Food Type',
      options: recipesFoodTypesNavOptions,
      isOptionsList: true, // to differentiate between mappable list like collections , and non mappable like wightWatchers
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.recipe.filters.foodTypes.length === 0 ? false : true
    },
    {
      title: 'Dietary',
      options: recipesDietariesNavOptions,
      isOptionsList: true,
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.recipe.filters.dietaries.length === 0 ? false : true
    },
    {
      title: 'Speciality',
      options: recipesSpecialitiesNavOptions,
      isOptionsList: true,
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.recipe.filters.specialities.length === 0 ? false : true
    },
    {
      title: 'Weight Watchers™',
      options: weightWatchersOptions,
      isOptionsList: false,
      additionalDropdownWrapperClass: 'weightWatchersOption',
      singleSelectable: false,
      isSelected: isWeightwatchersSelected(history)
    },

  ]

  return filterOptions
}

// generated the fav options 
export const getFavoriteFilterOptions = (favouriteBoardsNavOptions, recipeFilterName) => {

  const favoriteFilterOptions = {
    title: 'Favorites',
    options: favouriteBoardsNavOptions,
    isSelected: (recipeFilterName.type === 'favorite') ? true : false
  }

  return favoriteFilterOptions
}

// generates the search -> search by list
export const getSelectorList = () => {
  const selectorList = [
    {
      title: 'By Title',
      value: 'title'
    },
    {
      title: 'By Ingredients',
      value: 'ingredients'
    }
  ]

  return selectorList
}





