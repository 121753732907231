import React from "react";
import '../styles/common.scss';

const ShareIcon = ({
  width,
}) => {
  return (
    <svg
      version="1.1" 
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 44 44"
      width="40px"
      className="circled-plus"
    >
      <g>
        <path id="PlusIcon.svg" className="st0" d="M22,4.6C12.4,4.6,4.6,12.4,4.6,22c0,9.6,7.8,17.4,17.4,17.4c4.8,0,9.1-2,12.3-5.1
          c3.1-3.1,5.1-7.5,5.1-12.3C39.4,12.4,31.6,4.6,22,4.6z M22,37.3c-8.5,0-15.3-6.9-15.3-15.3S13.5,6.7,22,6.7
          c8.5,0,15.3,6.9,15.3,15.3C37.3,30.5,30.5,37.3,22,37.3z"/>
        <path className="st0" d="M31.2,22c0,0.6-0.5,1-1,1H23v7.2c0,0.6-0.5,1-1,1s-1-0.5-1-1V23h-7.2c-0.6,0-1-0.5-1-1s0.5-1,1-1H21v-7.2
          c0-0.6,0.5-1,1-1s1,0.5,1,1V21h7.2C30.7,21,31.2,21.4,31.2,22z"/>
      </g>
    </svg>
  )
};

export default ShareIcon;
