import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';


const DownArrow = (props) => {
  return (
    < >
      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.07118 5.53553L5.53564 9.07107L2.00011 5.53553" stroke={themeTextRed} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </>
  );
};
export default DownArrow;
