import axios from 'axios';
import { get, post, remove, patch } from '../../helpers/requests';
import { boards } from '../../helpers/api';
import { call, select } from 'redux-saga/effects';
import { getToken } from '../selectors'

import isNull from 'lodash/isNull'

export default {
  get: function* getBoards(boardType) {
    const token = yield select(getToken);
    if (!isNull(token) && boardType) {
      const boardsResponse = yield call(axios, get(boards.get(boardType), '', token))
      return boardsResponse
    } else {
      return {
        data: {
          boards: [] // TODO: replace this
        }
      }
    }
  },

  post: function* createBoard(boardObject) {
    const token = yield select(getToken);
    const boardResponse = yield call(axios, post(boards.post(), boardObject, '', token))
    return boardResponse
  },

  delete: function* deleteBoard(boardId) {
    const token = yield select(getToken);
    const boardResponse = yield call(axios, remove(boards.delete(boardId), {}, '', token))
    return boardResponse
  },

  patch: function* updateBoard({ boardId, title }) {
    const token = yield select(getToken);
    const boardResponse = yield call(axios, patch(boards.patch(boardId), { title: title }, '', token));
    return boardResponse
  }
}