
import { ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST } from "./actionTypes";

// TODO: Add types + focus on what data is being is sent
export default (date) => ({
  type: ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST,
  payload: {
    date
  },
});
