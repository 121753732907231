import React, { useEffect, useState } from 'react'

import cx from 'classnames';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';

import ModalWrapper from '../../common/modal'
import DownButton from '../../common/downButton';
import UpButton from '../../common/upButton';
import Button from '../../common/button';
import Radiobox from "../../common/radiobox";
import Selector from '../../common/selector';

const AverageData = ({
  averageData: {
    calories,
    protein,
    fat,
    carbs,
    smartPoints,
    sugar,
    saturatedFat,
    fiber,
    freestylePoints,
    wwp
  },
  allMacros,
  setMacros,
  updateCalendarSettings,
  macroState,
  setMacroState,
  calendarType,
  setCalendarType,
  defaultCalendarType
}) => {
  const [localMacroList, updateLocalMacroList] = useState(map(setMacros, macro => macro));
  const [localCalendarType, setLocalCalendarType] = useState(calendarType);
  return (
    <div className="dailyMacro-wrapper">
      {
        macroState.showChooseModal ? (
          <ModalWrapper
            isOpen={macroState.showChooseModal}
            minHeight="500px"
            maxWidth="450px"
            // overflowY="hidden"
            borderRadius="13px"
            onRequestClose={() => {
              setMacroState({
                ...macroState,
                showChooseModal: false,
              });
              setLocalCalendarType(defaultCalendarType);
            }}
          >

            <div className="modalWrapper choose-macro-modal">
              <div className="modalHeader">
                <h3>Calendar settings</h3>
              </div>
              <div className="modalBody">
                <div className="month-type">
                  {/* <h5 className="month-type-title">Calendar Type</h5> */}
                  <div className="month-type-view">
                    <div className="view-title">
                      View
                    </div>
                    <div className="Selector-flex">
                      <Selector
                        defaultValue={localCalendarType}
                        selectorList={[{ title: 'Monthly', value: 'monthly' }, { title: 'Bi-Weekly', value: 'biweekly' }]}
                        onSelectValue={() => setLocalCalendarType(localCalendarType === 'biweekly' ? 'Monthly' : 'biweekly')}
                      />
                    </div>
                  </div>

                </div>
                <div className="macro-list">
                  <div className="macro-list-head">
                    View macros - {localMacroList.length} / 3
                  </div>
                  <ul>
                    {
                      map(allMacros, macro => (
                        <li
                          onClick={() => {
                            if (findIndex(localMacroList, localMacro => macro.key === localMacro) === -1) {
                              updateLocalMacroList([...localMacroList, macro.key])
                            } else {
                              updateLocalMacroList(filter(localMacroList, localMacro => localMacro !== macro.key))
                            }

                          }}
                          className={cx({
                            disabled: (localMacroList.length >= 3 && findIndex(localMacroList, localMacro => macro.key === localMacro) === -1)
                          })}
                        >
                          <Radiobox
                            type="checkbox"
                            checked={findIndex(localMacroList, localMacro => macro.key === localMacro) !== -1}
                            content={macro.name}
                            label={macro.name}
                          />
                        </li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className="modalFooter">
                <Button
                  btnClass="primary btn-full"
                  btnText="Confirm"
                  onClick={() => {
                    setCalendarType(localCalendarType)
                    updateCalendarSettings(localMacroList, {
                      calendarView: localCalendarType,
                    })
                    setMacroState({
                      ...macroState,
                      showChooseModal: false,
                    })
                  }}
                />
                <Button
                  btnClass="secondary btn-full btn-bp-sm"
                  btnText={`Cancel`}
                  onClick={() => setMacroState({
                    ...macroState,
                    showChooseModal: false,
                  })}
                />
              </div>
            </div>
          </ModalWrapper>
        ) : null
      }
    </div>
  )
}
export default AverageData