import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const AddnoteIcon = () => {
  return (
    <svg width="35" height="38" viewBox="0 0 35 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.6616 17.9808L12.1989 25.4435C8.7546 28.8878 5.03784 29.7732 3.29622 28.0316M3.29622 28.0316C1.5546 26.29 2.43027 22.5732 5.88433 19.1289L13.347 11.6662M3.29622 28.0316L1 30.3376M14.8551 9.58405L22.2497 2.18946C23.8357 0.603513 26.4043 0.603513 27.9903 2.18946L29.1384 3.33757C30.7243 4.92351 30.7243 7.49216 29.1384 9.07811L21.7827 16.4338M14.8357 9.61324L21.6757 16.5505M29.7514 8.30946C31.4832 9.6327 31.7265 12.25 30.1114 13.8651L25.7038 18.2727M2.0896 33.7624C4.32744 34.6965 6.72095 35.3386 9.25068 35.6597C27.8734 37.9851 25.9372 16.8813 17.4042 30.1722C11.5372 39.3181 28.2918 36.5743 33.5166 30.0165" stroke={themeTextRed} strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};
export default AddnoteIcon;