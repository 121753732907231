import React from "react";
import NavBar from "./navBar";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import toJS from "../../helpers/toJS";
import {
  getUserDetails,
  getGroceryListItemCount,
  getRecipeDetails,
  getWorkoutDetails,
  getMealPlanCategories,
} from "../../selectors";
import {
  getAllFitlers as getRecipeFilters,
  getUnFilteredList,
} from "../../selectors/recipes";
import { getWorkoutBodyParts } from "../../selectors/workout";
import { getBlogDetails, getFeaturedList } from "../../selectors/blogs";
import { logoutNavbar, clearAllFilters } from "../../actions";
import { getVideoCategories } from "../../selectors/videos";

const Layout = (props) => <NavBar {...props} />;

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
  groceryListItemCount: getGroceryListItemCount(state),
  activeRecipe: getRecipeDetails(state),
  activeWorkout: getWorkoutDetails(state),
  activeBlog: getBlogDetails(state),
  recipeFilters: getRecipeFilters(state),
  workoutBodyParts: getWorkoutBodyParts(state),
  videoCategories: getVideoCategories(state),
  blogFeaturedList: getFeaturedList(state),
  latestRecipe: getUnFilteredList(state).getIn([0]),
  mealPlanCategories: getMealPlanCategories(state),
});

const dispatchActionToProps = (dispatch) => ({
  logoutNavbar: bindActionCreators(logoutNavbar, dispatch),
  clearAllFilters: bindActionCreators(clearAllFilters, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Layout));
