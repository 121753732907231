import React, { useState } from 'react'

import DesktopMonthly from './desktopMonthly'
import DesktopBiWeekly from './desktopBiWeekly'

const Desktop = (props) => {
  return props.calendarType === 'Monthly' ? (
    <DesktopMonthly {...props} setCalendarType={props.setCalendarType} />
  ) : (
      <DesktopBiWeekly {...props} setCalendarType={props.setCalendarType} />
    )
}

export default Desktop;