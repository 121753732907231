import axios from 'axios';
import { baseUrl } from './api';
import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import map from 'lodash/map';
import concat from 'lodash/concat';
import { useState, useEffect } from 'react';

import { fromJS } from 'immutable'

import { generateRecipeQueryFromFilter, generateWorkoutQueryFromFilter } from './filterConversions'
import { removeCharecterFromIndex } from './converters'
import { mealPlan } from '../helpers/api'

let debouncer;

export default ({
  token,
}) => {

  const [savedDayState, setSavedDayState] = useState({
    limit: 25,
    offset: 0,
    data: [],
    isFetching: false,
    hasMore: true,
    shouldFetch: true
  });

  useEffect(() => {
    if (savedDayState.shouldFetch) {
      setSavedDayState({
        ...savedDayState,
        isFetching: true,
      })
      axios({
        url: mealPlan.getSavedCalendarDays(savedDayState.limit, savedDayState.offset),
        headers: {
          Authorization: `Token token="${token}"`
        },
      }).then((response) => {
        setSavedDayState({
          ...savedDayState,
          data: concat(savedDayState.data, response.data.saved_days),
          hasMore: response.data.saved_days.length >= 25,
          shouldFetch: false
        })
      })
    }
  }, [savedDayState.offset, savedDayState.shouldFetch])


  return [savedDayState, setSavedDayState];
}