import React from 'react';
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const RecipeIcon = () => {
    return (
        <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M65.9172 35.4538C58.7917 42.5793 36.149 52.0882 28.0552 43.9696C19.9614 35.8758 29.4703 13.2082 36.571 6.10755C43.6965 -1.01797 56.0358 -0.223484 64.1296 7.89514C72.2234 15.9889 73.0179 28.3282 65.9172 35.4538Z" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M56.6069 26.1434C49.5063 33.2689 31.0097 46.9489 28.0552 43.9696C25.1007 41.0151 38.7807 22.5186 45.8814 15.4179C53.007 8.29237 61.1752 4.94065 64.1297 7.89513C67.0842 10.8496 63.7076 19.0179 56.6069 26.1434Z" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M26.2925 49.6304L10.1049 68.8966C8.2428 71.2055 4.79177 71.4042 2.70625 69.3186C0.595907 67.2083 0.794527 63.7573 3.12832 61.92L22.3945 45.7324" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default RecipeIcon;