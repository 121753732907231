import React from "react";
import '../styles/common.scss';


const LowerBody = (props) => {
  return (
    < >
      <svg width="40" height="67" viewBox="0 0 40 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M2.26667 65.7143C0.571437 59.5238 2.68572 52.9333 3.52382 47.8476C4.38096 42.6286 3.50477 31.0095 2.26667 25.8476C-2.05713 7.96191 5.71429 9.25715 5.71429 0.952393" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M37.7333 65.7143C39.4286 59.5238 37.3143 52.9333 36.4762 47.8476C35.619 42.6286 36.4952 31.0095 37.7333 25.8476C42.0571 7.96191 34.2857 9.25715 34.2857 0.952393" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M29.5238 65.7142C29.5238 53.1238 23.181 54.7047 24.7619 38.9714C24.7619 38.9714 20 17.5809 20 8.15234C20 17.5809 15.2381 38.9714 15.2381 38.9714C16.8191 54.7047 10.4762 53.1238 10.4762 65.7142" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          {/* <clipPath id="clip0">
            <rect width="40" height="66.6667" fill="white" />
          </clipPath> */}
        </defs>
      </svg>
    </>
  );
};
export default LowerBody;
