import { put, takeEvery, select, take, call, all, takeLatest } from 'redux-saga/effects'
import * as actions from '../actions/actionTypes'

import pushToSentry from '../helpers/pushToSentry';

import authenticateProcess from './process/authenticate'
import startUpProcess from './process/startup'
import objectPreFetchEssential from './process/objectPreFetchEssentials';
import listItemsFetchProcess from './process/listItemsFetch';
import objectItemsFetchProcess from './process/objectItemsFetch';
import fetchUserEssentials from './process/fetchUserEssentials';
import trendingEssentials from './process/trendingEssentials';

import { setFiltersProcess } from './process/setFiltersProcess';

function* task(action) {
  try {
    const { payload = {} } = action;

    const {
      refetchGroceryList,
    } = payload;

    // 1) see if token is present if not -> get token from APP_STARTUP
    // 2) see if you are authenticated with that token
    yield call(startUpProcess);

    batchedProcess = [];

    // at this point app startup is working depending on token 
    // user will not be authenticated and will not have userData when he logges out and then goes to any page, but user will have token. To be get authenticated and userData USER_AUTHENTICATE action need to take place  
    yield all([call(authenticateProcess)]);

    yield all([call(objectPreFetchEssential)]);

    // Fetch all list based items
    // Should happen during no auth also
    // Changes for every user
    // yield call(listItemsFetchProcess, payload);
    batchedProcess.push(call(listItemsFetchProcess, payload));
    // Fetch object based items
    // Should not happen during no auth
    batchedProcess.push(call(objectItemsFetchProcess, payload));

    // Should not happen during no auth
    batchedProcess.push(call(fetchUserEssentials, {
      refetchGroceryList,
    }));

    batchedProcess.push(call(trendingEssentials));

    yield all(batchedProcess);

    yield put({
      type: actions.APP_ESSENTIAL_FETCH_SUCCESS,
    })
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.APP_ESSENTIAL_FETCH_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}


export default function* appEssentialFetch() {
  yield takeLatest(actions.APP_ESSENTIAL_FETCH, task)
}