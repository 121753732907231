import { createSelector } from "reselect";
import { List, Map, Iterable, update, fromJS, toJS } from "immutable";
import isNull from "lodash/isNull";
import take from "lodash/take";
import shuffle from "lodash/shuffle";
import filter from "lodash/filter";
import { generateAppEssentialPayload } from "../helpers/filterConversions";

// recipe list selectors .........................................................................................
export const getEssentials = (state) => state.getIn(["essentials"]);
export const getUserSpecificEssential = (state) =>
  state.getIn(["userSpecificEssential"]);
export const getObjectSpecificEssential = (state: any) => {
  return state.getIn(["objectSpecificEssential"]);
};
export const getFetchStatus = (state) => {
  return fromJS({
    recipesSearch: state.getIn(["fetchStatus", "recipesSearch"]),
    appEssentials: state.getIn(["fetchStatus", "appEssentials"]),
    startup: state.getIn(["fetchStatus", "startup"]),
    userAuthenticate: state.getIn(["fetchStatus", "userAuthenticate"]),
    preFetch: state.getIn(["fetchStatus", "objectPreFetch", "recipe"]),
    initialLoad: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "recipe",
      "initialLoad",
    ]),
    pagination: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "recipe",
      "pagination",
    ]),
    // Specific Fetch Status
    featuredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "featuredCollectionList",
      "recipe",
    ]),
    favoritesList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "favoritesList",
      "recipe",
    ]),
    filteredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "filteredList",
      "recipe",
    ]),
    unfilteredList: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "unfilteredList",
      "recipe",
    ]),
    completeFetch: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "recipe",
      "completeFetch",
    ]),
    globalError: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "globalError",
      "errorMessage",
    ]),
    recipeTemporaryListFetch: state.getIn([
      "fetchStatus",
      "recipeTemporaryListFetch",
    ]),
    pullToRefresh: state.getIn([
      "fetchStatus",
      "listItemsFetch",
      "recipe",
      "pullToRefresh",
    ]),
    trendingFetch: state.getIn(["fetchStatus", "trendingRecipe", "isFetching"]),
    unfilteredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "unfiltered",
      "initial",
    ]),
    filteredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "filtered",
      "initial",
    ]),
    favoritedListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "favorited",
      "initial",
    ]),
    featuredListInitialTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "featured",
      "initial",
    ]),
    unfilteredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "unfiltered",
      "pagination",
    ]),
    filteredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "filtered",
      "pagination",
    ]),
    favoritedListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "favorited",
      "pagination",
    ]),
    featuredListPaginationTypeStatus: state.getIn([
      "fetchStatus",
      "objectList",
      "recipe",
      "featured",
      "pagination",
    ]),
  });
};
export const getGroceryWaitlist = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => userEssentials.getIn(["waitList"])
);
export const currentServing = (state: any) =>
  state.getIn(["setCurrentServing", "currentServing"]);

// unfiltered recipeList
export const getUnFilteredList = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "unFilteredList", "list"])
);
// unfiltered recipeList fetch status
export const getUnFilteredListStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => fetchStatus.getIn(["recipe", "unFilterdList"])
); // not available

export const getDietaryList = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "featuredCollection", "list"])
);

export const getDietaryListStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => fetchStatus.getIn(["recipe", "featuredCollection"])
); // not available

export const getFeaturedListStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => fetchStatus.getIn(["recipe", "featuredCollection"])
); // not available

export const getFeaturedCollections = createSelector(
  [getEssentials],
  (essentials) => {
    const collections = essentials.getIn(["recipe", "collection"]).toJS();
    const changedCollections = filter(
      collections,
      (collection) => collection.featured
    );
    const randomizedCollections = changedCollections;

    return randomizedCollections;
  }
);

// selector for featured recipe list
export const getFeaturedList = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "featuredCollectionList", "list"])
);

// recipe fetured collection
export const getFeaturedDietaries = createSelector(
  [getEssentials],
  (essentials) => {
    const featuredDietaries = essentials
      .getIn(["recipe", "dietaries"])
      .filter((collection) => {
        if (collection.getIn(["featured"])) {
          return collection;
        }
      });

    const nonFeaturedDietaries = essentials
      .getIn(["recipe", "dietaries"])
      .filter((collection) => {
        if (!collection.getIn(["featured"])) {
          return collection;
        }
      });

    const sortedDietaries = featuredDietaries.concat(nonFeaturedDietaries);
    return sortedDietaries;
  }
);
// category
export const getRecipesCategories = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "categories"])
);
// Selector for boards
export const getRecipeBoards = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => {
    return userEssentials.getIn(["boards", "recipeBoards", "boards"]);
  }
);

// filtered recipeList
export const getFilteredList = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "filteredList", "list"])
);
// fetch status
export const getFilteredListStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => fetchStatus.getIn(["recipe", "filterdList"])
);

// for recipe search text
export const getRecipeSearchText = createSelector(
  [getEssentials],
  (essentials) => {
    return essentials.getIn(["recipe", "filters", "search", 0]);
  }
);

// for recipe search text
export const getRecipeSearchBy = createSelector(
  [getEssentials],
  (essentials) => {
    return essentials.getIn(["recipe", "filters", "searchBy", 0]);
  }
);

export const getRecipesFoodTypes = createSelector(
  [getEssentials],
  (essentials) => {
    return essentials.getIn(["recipe", "foodTypes"]);
  }
);

export const getRandomizedRecipesFoodTypes = createSelector(
  [getEssentials],
  (essentials) => {
    const foodTypes = essentials.getIn(["recipe", "foodTypes"]).toJS();
    return take(shuffle(foodTypes), 5);
  }
);
export const getRecipesSpecialities = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "specialities"])
);

export const getRecipesMessage = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "message"])
);
export const getRecipesSearchCount = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "searchList", "count"])
);
export const getRecipesSearchList = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "searchList", "data"])
);

// recipe boards
export const getBoards = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => userEssentials.getIn(["boards", "recipeBoards", "boards"])
);

// selector recipe headers
export const getRecipeFilterName = createSelector(
  [getEssentials, getUserSpecificEssential],
  (essentials, userEssentials) => {
    if (essentials.getIn(["recipe", "searchApplied"])) {
      // Search
      return {
        type: "search",
        title: essentials.getIn(["recipe", "filters", "search", 0]),
      };
    } else if (essentials.getIn(["recipe", "favorite", "show"])) {
      // Favorites
      let favoriteBoardSlug = essentials.getIn(["recipe", "favorite", "board"]);
      if (favoriteBoardSlug == null) {
        return { type: "favorite", title: "All" };
      }

      let boards = userEssentials.getIn(["boards", "recipeBoards", "boards"]);
      const index = boards.findIndex(
        (board) => board.getIn(["slug"]) == favoriteBoardSlug
      );
      const favoriteHeader = boards.getIn([index, "title"]);
      const favoriteSlug = boards.getIn([index, "slug"]);

      return { type: "favorite", title: favoriteHeader, slug: favoriteSlug };
    } else if (
      essentials.getIn(["recipe", "filters", "foodTypes"]).size > 0 ||
      essentials.getIn(["recipe", "filters", "dietaries"]).size > 0 ||
      essentials.getIn(["recipe", "filters", "specialities"]).size > 0 ||
      essentials.getIn(["recipe", "filters", "collection"]).size > 0 ||
      (essentials.getIn(["recipe", "filters", "search"]) &&
        essentials.getIn(["recipe", "filters", "search"]).size > 0 &&
        essentials.getIn(["recipe", "filters", "searchBy"]).size > 0) ||
      essentials.getIn(["recipe", "filters", "sp", 1]) !== "15" ||
      essentials.getIn(["recipe", "filters", "fp", 1]) !== "15" ||
      essentials.getIn(["recipe", "filters", "wwp", 1]) !== "15" ||
      (essentials.getIn(["recipe", "filters", "order"]) &&
        essentials.getIn(["recipe", "filters", "order"]).size > 0)
    ) {
      // Filters
      let filterArray = [];
      essentials
        .getIn(["recipe", "filters", "foodTypes"])
        .forEach((filteredFoodType, index) => {
          let selectedFoodType = essentials
            .getIn(["recipe", "foodTypes"])
            .find((foodType) => foodType.getIn(["slug"]) == filteredFoodType);
          if (selectedFoodType) {
            filterArray.push({
              type: "foodTypes",
              ...selectedFoodType.toJS(),
            });
          }
        });

      essentials
        .getIn(["recipe", "filters", "dietaries"])
        .forEach((filteredDietary, index) => {
          let selectedDietary = essentials
            .getIn(["recipe", "dietaries"])
            .find((dietary) => dietary.getIn(["slug"]) == filteredDietary);
          if (selectedDietary) {
            filterArray.push({
              type: "dietaries",
              ...selectedDietary.toJS(),
            });
          }
        });

      essentials
        .getIn(["recipe", "filters", "specialities"])
        .forEach((filteredSpeciality, index) => {
          let selectedSpeciality = essentials
            .getIn(["recipe", "specialities"])
            .find(
              (speciality) => speciality.getIn(["slug"]) == filteredSpeciality
            );
          if (selectedSpeciality) {
            filterArray.push({
              type: "specialities",
              ...selectedSpeciality.toJS(),
            });
          }
        });

      essentials
        .getIn(["recipe", "filters", "collection"])
        .forEach((filteredSpeciality, index) => {
          let selectedSpeciality = essentials
            .getIn(["recipe", "collection"])
            .find(
              (speciality) => speciality.getIn(["slug"]) == filteredSpeciality
            );
          if (selectedSpeciality) {
            filterArray.push({
              type: "collection",
              ...selectedSpeciality.toJS(),
            });
          }
        });

      if (essentials.getIn(["recipe", "filters", "fp", 1]) !== "15") {
        filterArray.push({
          name: "FP",
          type: "fp",
          slug: "fp",
          value: essentials.getIn(["recipe", "filters", "fp", 1]),
        });
      }

      if (essentials.getIn(["recipe", "filters", "sp", 1]) !== "15") {
        filterArray.push({
          name: "SP",
          type: "sp",
          slug: "sp",
          value: essentials.getIn(["recipe", "filters", "sp", 1]),
        });
      }

      if (essentials.getIn(["recipe", "filters", "wwp", 1]) !== "15") {
        filterArray.push({
          name: "WWP",
          type: "wwp",
          slug: "wwp",
          value: essentials.getIn(["recipe", "filters", "wwp", 1]),
        });
      }

      if (
        essentials.getIn(["recipe", "filters", "search", 0]) &&
        essentials.getIn(["recipe", "filters", "search", 0]).length > 0
      ) {
        filterArray.push({
          type: "search",
          value: essentials.getIn(["recipe", "filters", "search", 0]),
        });
      }

      if (
        essentials.getIn(["recipe", "filters", "order", 0]) &&
        essentials.getIn(["recipe", "filters", "order", 0]).length > 0
      ) {
        filterArray.push({
          type: "order",
          value: essentials.getIn(["recipe", "filters", "order", 0]),
        });
      }

      return { type: "filter", filterArray };
    } else {
      return { type: "recent", title: "Recent Recipes" };
    }
  }
);

export const getRecipes = createSelector(
  [getFilteredList, getUnFilteredList, getRecipeFilterName],
  (filteredRecipes, unfilteredRecipes, existingFilterType) => {
    return existingFilterType.type !== "recent"
      ? filteredRecipes
      : unfilteredRecipes;
  }
);

export const getMoreRecipeAvailable = createSelector(
  [getRecipeFilterName, getEssentials],
  (existingFilterType, essentials) => {
    return existingFilterType.type !== "recent"
      ? essentials.getIn(["recipe", "filteredList", "moreRecipesAvailable"])
      : essentials.getIn(["recipe", "unFilteredList", "moreRecipesAvailable"]);
  }
);

export const getMoreUnfilteredRecipeAvailable = createSelector(
  [getEssentials, getRecipeFilterName],
  (essentials, existingFilterType) => {
    let status = essentials.getIn([
      "recipe",
      "unFilteredList",
      "moreRecipesAvailable",
    ]);
    return status;
  }
);

export const getMoreFilteredRecipeAvailable = createSelector(
  [getEssentials, getRecipeFilterName],
  (essentials, existingFilterType) => {
    let status = essentials.getIn([
      "recipe",
      "filteredList",
      "moreRecipesAvailable",
    ]);
    return status;
  }
);

export const getMoreFeaturedCollectionRecipeAvailable = createSelector(
  [getEssentials, getRecipeFilterName],
  (essentials, existingFilterType) => {
    let status = essentials.getIn([
      "recipe",
      "featuredCollectionList",
      "moreRecipesAvailable",
    ]);
    return status;
  }
);

// recipe Details selectors .............................................................................................
export const getActiveRecipe = (state) => state.getIn(["recipe"]);

// specifies the id of recipe for the current page //Guess, please check
export const getRecipeStackItem = (state, stackIndex = 0) => {
  const recipeStack = state.getIn([
    "objectSpecificEssential",
    "recipe",
    "objectStack",
  ]);
  // IF active object type is not recipe
  // TODO: Remove these checks by handling appropriate cases
  // These fails at re renders
  if (!recipeStack || !recipeStack.getIn([stackIndex])) {
    return Map();
  }
  return recipeStack.getIn([stackIndex]);
};

// Recipe stack Item pointed by the component
export const getRecipeDetails = createSelector(
  [getRecipeStackItem],
  (recipeStackItem) => {
    if (recipeStackItem && !recipeStackItem.isEmpty()) {
      const recipeDetails = recipeStackItem.getIn(["data", "recipe"]);
      return recipeDetails;
    }
    return Map();
  }
);

// Specifies the id of variant of the active recipe
export const getActiveVariantId = createSelector(
  [getRecipeDetails, getObjectSpecificEssential],
  (recipe, objectEssentials) => {
    // if stack if id null -> do default else get returm the stack id
    if (recipe) {
      const variations = recipe.getIn(["variations"]);
      const selectedActiveVariationId = objectEssentials.getIn([
        "recipe",
        "stackTopObjectActiveVariationId",
      ]);
      if (isNull(selectedActiveVariationId)) {
        if (variations && variations.size > 0) {
          return variations.getIn([0, "id"]);
        }
      } else {
        return selectedActiveVariationId;
      }
    }
    // Shouldnt happen
    return 0;
  }
);

export const getVariationsForActiveRecipe = createSelector(
  [getRecipeDetails, getActiveVariantId],
  (activeRecipe, activeVariationId) => {
    //
    if (activeVariationId && activeRecipe && !activeRecipe.isEmpty()) {
      let variations = activeRecipe.get("variations");
      // If there are multiple variations

      // Choose the active variation
      if (variations && !variations.isEmpty()) {
        const variation = variations.find(
          (variation) => variation.get("id") === activeVariationId
        );
        return variation;
      }
      return Map();
    } else {
      return Map();
    }
  }
);

export const getIngredientsForActiveRecipe = createSelector(
  [getVariationsForActiveRecipe, getGroceryWaitlist, currentServing],
  (activeVariation, groceryWaitlist, cServ) => {
    if (
      Iterable.isIterable(activeVariation) &&
      activeVariation &&
      !activeVariation.isEmpty()
    ) {
      const ingredientGroups = activeVariation.getIn(["ingredients", "groups"]);

      let selectedCount = 0;
      const newGroups = ingredientGroups
        .toSeq()
        .reduce((outerAccumulator, group) => {
          const newIngredients = group
            .getIn(["lines"])
            .toSeq()
            .reduce((innerAccumulator, ingredient, index) => {
              if (
                groceryWaitlist.findIndex(
                  (waitlist) => waitlist.get("item") === ingredient.get("item")
                ) !== -1
              ) {
                ingredient = ingredient.set("selected", true);
                selectedCount += 1;
              } else {
                ingredient = ingredient.set("selected", false);
              }
              // for dynamic amount
              const modifiedAmount = getFractionFromString(
                ingredient.getIn(["amount"])
              );

              let defServing;

              if (activeVariation.getIn(["servings"]) === null) {
                defServing = 4;
              } else {
                defServing = activeVariation.getIn(["servings"]);
              }

              const servingValue = defServing / cServ;
              let dynamicAmount = eval(modifiedAmount) / servingValue;
              let fractionAmount = new Fraction(dynamicAmount);

              let amount = ingredient
                .getIn(["amount"])
                .replace(
                  getFractionFromString(ingredient.getIn(["amount"])),
                  fractionAmount.toFraction(true)
                );

              ingredient = ingredient.setIn(
                ["amount"],
                amount.replace("NaN/NaN", "")
              );
              //

              innerAccumulator = innerAccumulator.push(ingredient);

              return innerAccumulator;
            }, List());

          group = group.setIn(["lines"], newIngredients);
          outerAccumulator = outerAccumulator.push(group);
          return outerAccumulator;
        }, List());
      return fromJS({
        groups: newGroups,
        selectedCount,
      });
    } else {
      return List();
    }
  }
);

export const getCaloriesForRecipe = createSelector(
  [getVariationsForActiveRecipe],
  (variation) => {
    if (Iterable.isIterable(variation) && variation && !variation.isEmpty()) {
      let data = {
        calories: variation.getIn(["calories"]),
        sp: variation.getIn(["smart_points"]),
        fp: variation.getIn(["freestyle_points"]),
      };
      return fromJS(data);
    } else {
      let data = {
        calories: null,
        sp: null,
        fp: null,
      };
      return fromJS(data);
    }
  }
);

export const getRelatedRecipes = createSelector(
  [getRecipeStackItem],
  (recipeStackItem) => {
    if (recipeStackItem) {
      const recipeDetails = recipeStackItem.getIn(["data", "relatedRecipes"]);
      return recipeDetails;
    }
    return List();
  }
);

export const getTags = createSelector(
  [getRecipeStackItem],
  (recipeStackItem) => {
    if (
      recipeStackItem &&
      Iterable.isIterable(recipeStackItem) &&
      recipeStackItem.size > 0
    ) {
      const tags = recipeStackItem.getIn(["data", "tags"]).toJS();
      return take(tags, 5);
    }
    return List();
  }
);

// Selector for recipe categories
export const getCategories = createSelector(
  [getRecipeStackItem],
  (recipeStackItem) => {
    if (recipeStackItem && !recipeStackItem.isEmpty()) {
      const recipeDetails = recipeStackItem.getIn(["data", "foodTypes"]);
      return recipeDetails;
    }
    return Map();
  }
);

export const getNoteStackItem = (state, objectType, stackIndex = 0) => {
  const noteStack = state.getIn([
    "objectSpecificEssential",
    objectType,
    "noteStack",
  ]);
  // TODO: Preloader should avoid this. Stack doesn't have this index
  // This happens just before new stack data is posted
  if (!noteStack || !noteStack.getIn([stackIndex])) {
    return Map();
  }
  return noteStack.getIn([stackIndex, "data"]);
};

// Get comments to the active recipe
export const getCommentsList = (state, objectType, stackIndex = 0) => {
  // Mofify comment according to front end requirments
  const commentStackItem = state.getIn([
    "objectSpecificEssential",
    objectType,
    "commentStack",
    stackIndex,
    "data",
  ]);

  if (commentStackItem) {
    const currentComments = commentStackItem
      .toSeq()
      .map((comment) => {
        const author = comment.getIn(["user", "name"]);
        const url = comment.getIn(["user", "url"]);

        return comment.setIn(["author"], author).setIn(["url"], url);
      })
      .toList();

    return currentComments;
  }
  return List();
};

// Get all boards with selected true (based on in the recipe is present in the board)
export const getFavoriteWithBoardMap = createSelector(
  [getUserSpecificEssential, getActiveRecipe],
  (userEssentials, recipe) => {
    if (!recipe.getIn(["activeRecipe"]).isEmpty()) {
      const allBoards = userEssentials.getIn([
        "boards",
        "recipeBoards",
        "boards",
      ]);
      const selectedBoards = recipe.getIn(["activeRecipe", "favorite_boards"]);
      let favoriteWithBoardMap = allBoards.toSeq().map((board) => {
        let boardId = board.getIn(["id"]);
        if (
          selectedBoards &&
          selectedBoards.findIndex((item) => item === boardId) !== -1
        ) {
          board = board.setIn(["selected"], true);
        } else {
          board = board.setIn(["selected"], false);
        }
        return board;
      });

      return favoriteWithBoardMap;
    } else {
      return List();
    }
  }
);

export const getAppliedFilters = (state) => {
  return state.getIn(["essentials", "recipe", "filters"]);
};

export const getTemporaryFilterCount = (state) => {
  return state.getIn(["essentials", "recipe", "temporaryFilteredCount"]);
};

export const getTrendingRecipes = (state) => {
  return state.getIn(["essentials", "trending", "recipes", "data"]);
};

export const getTrendingType = (state) => {
  return state.getIn(["essentials", "trending", "recipes", "type"]);
};

export const getOrder = (state) => {
  return state.getIn(["essentials", "recipe", "filters", "order", 0]);
};

export const getMeteredDetails = (state) =>
  state.getIn(["meteredDetails", "recipe"]);

export const getAllFitlers = (state) => {
  let result = List();
  result = result.concat(state.getIn(["essentials", "recipe", "foodTypes"]));
  result = result.concat(state.getIn(["essentials", "recipe", "dietaries"]));
  result = result.concat(state.getIn(["essentials", "recipe", "specialities"]));
  result = result.concat(state.getIn(["essentials", "recipe", "collection"]));
  return result;
};
