import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import toJS from '../../../helpers/toJS'
import Faq from './components/faqs';

import {
  appEssentialFetch,
  showToastMessage,
  addSentryError,
} from '../../../actions';

import {
  getFaqs,
  getFaqFetchStatus,
  getUserDetails
} from '../../../selectors/faqs'

const mapStateToProps = state => ({
  faqs: getFaqs(state),
  fetchStatus: getFaqFetchStatus(state),
  userDetails: getUserDetails(state)
})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Faq));