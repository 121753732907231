import { put, takeEvery, call, select } from 'redux-saga/effects';
import * as actions from '../actions/actionTypes'
import boardTask from './subtask/boards'
import pushToSentry from '../helpers/pushToSentry';
import { showToastMessage } from '../actions';

function* task(action) {
  try {
    const { boardId, title, objectType } = action.payload;

    yield call(boardTask.patch, action.payload);

    yield put({
      type: actions.UPDATE_FAVOURITE_BOARD_SUCCESS,
      payload: {
        boardId,
        title,
        objectType
      },
    })
    yield put(showToastMessage(`✅ Board updated.`, 'success', 'boardUpdated'));

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.UPDATE_FAVOURITE_BOARD_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* deleteFavouriteBoard() {
  yield takeEvery(actions.UPDATE_FAVOURITE_BOARD, task)
}