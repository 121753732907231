import axios from 'axios'
import { showToastMessage } from '../actions'
import { put, takeEvery, call, select, delay } from 'redux-saga/effects'
import { remove } from '../helpers/requests';
import { user } from '../helpers/api';

import { getRegisteredUser, getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';


function* task(action) {
  try {

    const token = yield select(getToken);

    yield call(axios, remove(user.delete(), {}, '', token));

    yield put({
      type: actions.CANCEL_SUBSCRIPTION_SUCCESS,
      payload: {
        success: true,
      }
    })
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in cancelling subscription`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CANCEL_SUBSCRIPTION_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addToGroceryList() {
  yield takeEvery(actions.CANCEL_SUBSCRIPTION, task)
}