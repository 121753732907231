import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import {
  showToastMessage
} from '../actions';
import { remove } from '../helpers/requests'

import groceryTask from './subtask/grocery'
import { getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    const token = yield select(getToken);

    // Should be item Ids
    yield call(groceryTask.deleteItems, action.payload.itemIds)

    yield put({
      type: actions.DELETE_INGREDIENT_IN_GROCERY_LIST_SUCCESS,
      payload: {
        itemIds: action.payload.itemIds
      }
    });
    yield put(showToastMessage(`😎 ${action.payload.itemIds.length} ingredient(s) removed.`, 'success', 'deleteGroceryList'));
  } catch (e) {
    yield put(showToastMessage('❗️ Error in removing groceries.', 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.DELETE_INGREDIENT_IN_GROCERY_LIST_FAILURE,
      payload: {
        itemIds: action.payload.itemIds
      }
    })
  }
}

export default function* deleteIngredientsInGroceryList() {
  yield takeEvery(actions.DELETE_INGREDIENT_IN_GROCERY_LIST, task)
}