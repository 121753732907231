import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ModalVideo from 'react-modal-video'
import { Helmet } from "react-helmet";
import RecipeIcon from '../images/RecipeIcon';
import WorkoutAboutIcon from '../images/WorkoutAboutIcon';
import FilterIcon from '../images/FilterIcon';
import PlanningIcon from '../images/PlanningIcon';
import GroceriesListIcon from '../images/GroceriesListIcon';

import LapIcon from '../images/lapIcon';
import { Tooltip, } from 'react-tippy';

import {
  appEssentialFetch,
} from "../../actions";

import '../styles/common.scss'
import PlayIconRed from '../images/PlayIconRed';
import { TransitionOne, TransitionTwo } from '../common/transitions';

const GetStart = (props) => {
  const [state, setState] = useState({
    isPopoverOpen: false,
    heading: null,
    deviceType: null,
  });

  const [showModal, setShowModal] = useState(false)
  const [videoId, setVideoId] = useState('');
  useEffect(() => {
    props.appEssentialFetch()
  }, [])
  // TODO replace videoId to actual one
  const staticData = [
    {
      img: RecipeIcon,
      heading: 'Recipes',
      content: 'Learn about our recipes that satisfy even the pickiest of palettes!',
      videoId: '390144055'
    },
    {
      img: FilterIcon,
      heading: 'Filters',
      content: 'Cook with specific ingredients that fit your dietary needs.',
      videoId: '390145034'
    },
    {
      img: WorkoutAboutIcon,
      heading: 'Workouts',
      content: 'Learn how our workouts are structured into easy-to-follow plans.',
      videoId: '390144656'
    },
    {
      img: PlanningIcon,
      heading: 'Calendar',
      content: 'Customize your calendar to fit your exact needs or select a pre-made plan.',
      videoId: '390145248'
    },
    {
      img: GroceriesListIcon,
      heading: 'Grocery List',
      content: 'Learn about utilizing the grocery list to its full potential.',
      videoId: '390144717'
    },
    // {
    //   img: MembershipIcon,
    //   heading: 'Membership',
    //   content: 'Get to know your account settings, including member updates and referrals.',
    //   videoId: '390144055'
    // },
  ]

  return (
    <main>
      <section className="get-start">
        <Helmet>
          <title>Getting Started Tutorials | Dashing Dish</title>
          <meta name="description" content="Dashingdish Onboard page" />
        </Helmet>
        <div className="container">
          <div className="get-startWrapper">
            <TransitionOne>
              <div className="get-startHead">
                <h1>Getting the most out of your<br></br>
                  Dashing Dish membership</h1>
                <p>We created a collection of videos  to help you learn all the in’s and outs of your membership!  If you still have a question, try our &nbsp;<a href='/faqs' onClick={(e) => { e.preventDefault(); props.history.push("/faqs") }}>FAQ’s.</a></p>
              </div>
            </TransitionOne>

            <ul className="get-startList">
              {staticData.map((item) => {
                return (
                  <TransitionTwo>
                    <li>
                      <div className="get-startCard"
                      >
                        <figure className="dark-svg">
                          <item.img />
                        </figure>
                        <h3>{item.heading}</h3>
                        <p>{item.content}</p>
                        <div className="watchtutorial">
                          <Tooltip
                            className="svg-container"
                            multiple={true}
                            open={state.isPopoverOpen && state.heading === item.heading}
                            arrow={true}
                            position="bottom"
                            trigger="click"
                            theme="light"
                            interactive={true}
                            onRequestClose={() => setState({
                              isPopoverOpen: !state.isPopoverOpen,
                              heading: null,
                              deviceType: null,
                            })}
                            html={(
                              <div
                                onClick={(e) => {
                                  e.preventDefault();
                                  setVideoId(item.videoId)
                                  document.body.classList.add('ReactModal__Body--open');
                                  setShowModal(true);
                                }}
                                className="video-pop">
                                <PlayIconRed></PlayIconRed>
                                <p>Desktop</p>
                              </div>
                            )}
                          >
                            <div className="device-type"
                              onClick={(e) => {
                                e.preventDefault();
                                setState({
                                  ...state,
                                  isPopoverOpen: !state.isPopoverOpen,
                                  heading: item.heading,
                                  deviceType: 'desktop'
                                })
                              }
                              }
                            >
                              <LapIcon />
                            </div>
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  </TransitionTwo>
                )
              })}
            </ul>
          </div>
          <ModalVideo
            channel='vimeo'
            isOpen={showModal}
            videoId={videoId}
            autoplay={1}
            onClose={() => {
              document.body.classList.remove('ReactModal__Body--open');
              setShowModal(false);
            }} />
        </div >
      </section>
    </main>
  )
}

const mapStateToProps = () => ({

})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(GetStart);



