import { put, takeEvery, call, select } from "redux-saga/effects";
import axios from "axios";
import * as actions from "../actions/actionTypes";
import { post } from "../helpers/requests";
import { auth } from "../helpers/api";
import {
  getToken,
  getPassword,
  getEmail,
  getNewsLetterSubscription,
  getReferralCode,
} from "./selectors";
import isNil from "lodash/isNil";

import pushToSentry from "../helpers/pushToSentry";
import { showToastMessage } from "../actions";

function* task(action) {
  try {
    const token = yield select(getToken);
    const newsletter = yield select(getNewsLetterSubscription);
    const referralCode = yield select(getReferralCode);

    yield put({
      type: "ASSIGN_PLAN_IN_COMPLETE_REGISTRATION",
    });

    // change plan at last moment
    if (action.payload.planId) {
      yield call(
        axios,
        post(
          auth.assignPlanToUser(),
          {
            user_id: action.payload.userId,
            plan_id: action.payload.planId,
            duration: action.payload.duration,
          },
          ""
        )
      );
    }

    // Create subscription
    if (!isNil(referralCode)) {
      const response = yield call(
        axios,
        post(
          auth.createSubscription(),
          {
            user_id: action.payload.userId,
            stripe_id: action.payload.stripeId,
            paypal_id: action.payload.paypalId,
            duration: action.payload.duration,
            newsletter,
            referral_code: referralCode,
          },
          "",
          token
        )
      );

      yield put({
        type: actions.REMOVE_REFERRAL_CODE,
        payload: {},
      });
    } else {
      const response = yield call(
        axios,
        post(
          auth.createSubscription(),
          {
            user_id: action.payload.userId,
            stripe_id: action.payload.stripeId,
            paypal_id: action.payload.paypalId,
            duration: action.payload.duration,
            gift_code: action.payload.giftCode,
            newsletter,
          },
          "",
          token
        )
      );
    }

    yield put({
      type: actions.COMPLETE_REGISTRATION_SUCCESS,
      payload: {
        success: true,
      },
    });

    const email = yield select(getEmail);
    const password = yield select(getPassword);

    yield put({
      type: "LOGIN_USER",
      payload: {
        email,
        password,
        remeberMe: true,
        firstTime: true,
      },
    });
  } catch (e) {
    const eventId = pushToSentry(e);
    showToastMessage("Some error occured");
    yield put({
      type: actions.COMPLETE_REGISTRATION_FAILURE,
      payload: {
        error: e.message,
      },
    });
  }
}

export default function* createBoard() {
  yield takeEvery(actions.COMPLETE_REGISTRATION, task);
}
