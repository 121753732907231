import React from "react";
import '../styles/common.scss';

const FormSucessIcon = (props) => {
  return (
    <>
      <svg width="44" height="53" viewBox="0 0 44 53" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.87857 48.1827C6.74204 47.4919 7.49757 46.8875 8.14516 46.3694C9.89368 44.9663 11.0378 43.1962 11.7717 41.2966C13.1317 37.778 17.2331 35.2307 22.0901 35.2307C26.9255 35.2307 31.027 37.778 32.4085 41.2966C33.1425 43.1962 34.265 44.9663 36.0135 46.3694C36.6611 46.8875 37.4166 47.4919 38.2801 48.1827M1 28.7763L10.6276 30.6327C14.3837 31.3667 18.2693 30.827 21.68 29.1001L24.6158 27.6106C25.8678 26.9846 26.4938 25.5383 26.1052 24.1783C25.6303 22.5809 23.925 21.6959 22.3492 22.2355L14.837 24.8259M1.04318 16.8605L4.62656 17.7239C6.59094 18.1988 8.66326 17.8534 10.3686 16.8173L14.7939 14.4859C16.8446 13.4066 19.2623 13.2987 21.3778 14.1837L40.0502 21.8901C41.5828 22.5161 42.3815 24.1999 41.9066 25.7757C41.4317 27.3515 39.8343 28.3014 38.2153 27.9991L29.3648 26.2938M41.4965 51.4207H2.64056M14.4207 4.39001L15.9007 5.87001M21.9407 1V3.09M27.9807 5.87001L29.4507 4.39001" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default FormSucessIcon;
