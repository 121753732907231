import pushToSentry from "../../helpers/pushToSentry";

import * as actions from '../../actions/actionTypes';
import { put } from 'redux-saga/effects'


export const setFiltersProcess = function* setFiltersProcess(payload) {
  try {
    yield put({
      type: actions.SET_FILTERS_PROCESS_SUCCESS,
      payload
    });
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.SET_FILTERS_PROCESS_FAILURE
    })
  }
}