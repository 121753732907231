import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const IstoreIcon = (props) => {
  return (
    <>
      <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.7082 14.4186C16.7308 12.5021 17.7341 10.7435 19.3574 9.74018C18.3203 8.27466 16.6631 7.3728 14.8594 7.30516C12.943 7.11352 11.128 8.43249 10.1585 8.43249C9.18897 8.43249 7.70091 7.32771 6.11138 7.36153C3.99201 7.4179 2.06429 8.59031 1.02715 10.4391C-1.13731 14.1931 0.474764 19.7734 2.58286 22.8171C3.60872 24.3052 4.84878 25.9849 6.46085 25.9173C8.01656 25.8609 8.60276 24.914 10.4854 24.914C12.368 24.914 12.8979 25.9173 14.5438 25.8834C16.2235 25.8496 17.2832 24.3616 18.2977 22.8622C19.0305 21.78 19.6054 20.6076 20 19.3675C18.0159 18.5333 16.7195 16.583 16.7082 14.4186Z" fill={themeTextRed} />
        <path d="M13.8223 4.13728C14.803 2.98741 15.2878 1.49934 15.1751 0C13.6983 0.146552 12.3342 0.845492 11.3534 1.96154C10.3614 3.05505 9.86536 4.50929 9.97809 5.97481C11.4662 5.99736 12.8866 5.32096 13.8223 4.13728Z" fill={themeTextRed} />
      </svg>
    </>
  );
};
export default IstoreIcon;
