import createReducer from "../helpers/createReducers";

import { fromJS, List } from "immutable";

const initialState = fromJS({
  isRegistering: false,
  isRegistered: false,
  planId: null,
  duration: null,
  errorMessage: null,
  coupon: {
    activationSuccess: false,
    activationFailure: false,
    isActivating: false,
    percentOff: null,
    data: {},
  },
  gift: {
    activationSuccess: false,
    activationFailure: false,
    isActivating: false,
    data: {},
  },
  initiatedEmail: null
});

export default createReducer(initialState, {
  UNIFIED_PLAN_CHOOSE: (state, action) => {
    state = state.setIn(["planId"], action.payload.planId);
    state = state.setIn(["duration"], action.payload.duration);
    return state;
  },
  UNIFIED_REGISTER: (state, action) => {
    state = state.setIn(["isRegistering"], true);
    state = state.setIn(["isRegistered"], false);
    state = state.setIn(["errorMessage"], null);
    return state;
  },
  UNIFIED_REGISTER_SUCCESS: (state, action) => {
    state = state.setIn(["isRegitering"], false);
    state = state.setIn(["isRegistered"], true);

    return state;
  },
  UNIFIED_REGISTER_FAILURE: (state, action) => {
    state = state.setIn(["isRegitering"], false);
    state = state.setIn(["isRegistered"], false);
    state = state.setIn(["errorMessage"], action.payload.error);
    return state;
  },
  UNIFIED_REGISTER_COUPON_ACTIVATE: (state) => {
    state = state.setIn(["coupon", "isActivating"], true);
    state = state.setIn(["coupon", "activationSuccess"], false);
    state = state.setIn(["coupon", "activationFailure"], false);

    return state;
  },
  UNIFIED_REGISTER_COUPON_ACTIVATE_SUCCESS: (state, action) => {
    state = state.setIn(["coupon", "isActivating"], false);
    state = state.setIn(["coupon", "activationSuccess"], true);
    state = state.setIn(["coupon", "activationFailure"], false);
    state = state.setIn(["coupon", "data"], fromJS(action.payload.coupon));

    return state;
  },
  UNIFIED_REGISTER_COUPON_ACTIVATE_FAILURE: (state) => {
    state = state.setIn(["coupon", "isActivating"], false);
    state = state.setIn(["coupon", "activationSuccess"], false);
    state = state.setIn(["coupon", "activationFailure"], true);

    return state;
  },

  UNIFIED_REGISTER_COUPON_REMOVE: (state) => {
    state = state.setIn(["coupon"], fromJS({}));

    return state;
  },
  SAVE_GIFT_CODE: (state, action) => {
    state = state.setIn(["gift", "data"], action.payload);
    return state;
  },
  SET_INITIATE_REGISTER_EMAIL: (state, action) => {
    state = state.setIn(["initiatedEmail"], action.payload.email);
    return state;
  }
});
