import classNames from 'classnames';
import React from 'react';
import { Row, Col } from "react-flexbox-grid";
import capitalize from 'lodash/capitalize';
import toUpper from 'lodash/toUpper';
import Button from "../common/button";
import KatiesCornerBanner from '../images/KatiesCornerBanner.png';
import KatiesCornerVideoBanner from '../images/KatiesCornerVideoBanner.png';
import { TransitionEaseInOne } from "../common/transitions";
import VideoPlayIcon from '../images/VideoPlayIcon';


const KatiesConerNavigation = (props) => {
    const url = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }


    let actionText = null;
    let actionLink = null;

    return (
        <>
            <TransitionEaseInOne>
                <section className="home-hero katies-corner-hero video-list-hero" style={{
                    backgroundImage: `linear-gradient(180deg, #FFFFFF 39.75%, rgba(255, 255, 255, 0) 100%), url(${KatiesCornerVideoBanner})`,
                }}>
                    <div className='container'>
                        <Row className="home-hero-wrapper home-hero-heading no-margin-sm">
                            <Col sm={6} className="no-padding-sm">
                                <div className="home-hero-text">
                                    <p className='hero-katies-corner-legend'>FEATURED {toUpper(props.objectType)}</p>
                                    <h1>
                                        {capitalize(props.featuredItem?.title) || (<h1>
                                            Connect with &nbsp;<br></br> like minded community
                                        </h1>)}
                                    </h1>
                                    <form className="hero-form internal-navigation">
                                        <Button
                                            btnClass="btn btn-primary btn-md read-more-btn"
                                            btnText={props.objectType === 'meeting' ? `Join ${capitalize(props.objectType)}` : `Start ${capitalize(props.objectType)}`}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                navigateTo(`/nourish/past-${props.objectType}/${props.featuredItem?.slug}`)
                                            }}
                                        />
                                    </form>
                                </div>
                            </Col>
                            <Col sm={6} className="video-banner no-padding-sm">
                                <figure className='img-wrapper' onClick={(e) => {
                                    e.preventDefault();
                                    navigateTo(`/nourish/past-${props.objectType}/${props.featuredItem?.slug}`)
                                }}>
                                    <img src={props.featuredItem?.banner} />
                                </figure>
                                <VideoPlayIcon />
                            </Col>
                        </Row>
                    </div>
                </section>

            </TransitionEaseInOne>
        </>
    )
}

export default KatiesConerNavigation