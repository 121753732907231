import { fromJS, Iterable } from 'immutable';

export default (list) => {
  list = Iterable.isIterable(list) ? list : fromJS(list)

  return list
    .getIn(['categories'])
    .toSeq()
    .reduce((count, category) => {
      count += category.getIn(['items']).size;
      return count
    }, 0)
}
