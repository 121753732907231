import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const DirectContactIcon = (props) => {
  return (
    <>
      <svg width="118" height="127" viewBox="0 0 118 127" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <circle cx="59" cy="53" r="45" fill="white" />
        </g>
        <circle opacity="0.2" cx="59" cy="53" r="40" fill={themeTextRed} />
        <g clip-path="url(#clip0)">
          <path d="M55.3808 60.3895L70.6882 69.1579C71.2619 69.4895 72.0023 69.1211 72.0764 68.4579L75.0564 37.9342C75.1304 37.1974 74.3345 36.6816 73.6867 37.0316L39.4071 55.7474C38.6482 56.1711 38.8333 57.3132 39.6847 57.4605L52.1231 59.6711" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M69.263 44.1237L55.3809 60.3895L56.9542 69.6C57.0467 70.1342 57.7501 70.2447 58.0092 69.7658L59.6936 66.5974" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <path d="M5.78995 93.1328C3.769 94.8685 3.64128 97.3351 5.22061 99.174C6.35429 100.494 7.94259 100.87 9.45955 100.168L8.75919 98.7449C7.83021 99.0999 6.93453 98.9042 6.30123 98.1668C5.3161 97.0198 5.6256 95.4094 6.85455 94.3539C8.0744 93.3063 9.71432 93.2266 10.7151 94.3918C11.4657 95.2657 11.3326 96.2342 10.9648 96.819L12.2271 97.902C13.155 96.4407 13.0076 94.7956 11.8348 93.4301C10.2555 91.5912 7.82911 91.3815 5.78995 93.1328Z" fill={themeTextRed} />
        <path d="M21.7769 108.188C23.3265 106.155 22.9721 103.592 21.0348 102.115C19.107 100.645 16.5411 100.983 14.9915 103.016C13.442 105.048 13.7964 107.612 15.7242 109.082C17.6615 110.558 20.2274 110.221 21.7769 108.188ZM20.479 107.199C19.4387 108.563 17.8119 108.817 16.619 107.908C15.4356 107.006 15.2491 105.37 16.2894 104.005C17.3297 102.64 18.9566 102.387 20.14 103.289C21.3329 104.198 21.5193 105.834 20.479 107.199Z" fill={themeTextRed} />
        <path d="M32.2064 118.445L35.7503 110.803L34.3133 110.136L31.7992 115.558L30.622 108.398L29.0326 107.661L25.4887 115.303L26.9257 115.97L29.4397 110.548L30.617 117.708L32.2064 118.445Z" fill={themeTextRed} />
        <path d="M47.3285 114.151L41.0331 112.666L40.6971 114.091L43.0798 114.653L41.4879 121.404L43.0296 121.768L44.6215 115.017L46.9925 115.576L47.3285 114.151Z" fill={themeTextRed} />
        <path d="M60.0449 123.671L57.2557 115.141L55.4928 115.077L52.0941 123.383L53.773 123.444L54.5166 121.478L57.7305 121.594L58.33 123.609L60.0449 123.671ZM56.2684 116.87L56.3164 116.872L57.3145 120.15L55.0599 120.068L56.2684 116.87Z" fill={themeTextRed} />
        <path d="M66.1114 119.137C66.5528 121.764 68.5984 123.148 70.9889 122.746C72.7048 122.458 73.8476 121.293 74.0308 119.631L72.4505 119.495C72.2742 120.474 71.6437 121.14 70.6852 121.301C69.1941 121.551 67.9754 120.454 67.707 118.856C67.4406 117.271 68.22 115.826 69.7347 115.571C70.8708 115.38 71.6311 115.995 71.9417 116.612L73.5212 116.091C72.7498 114.541 71.2653 113.817 69.4902 114.115C67.0997 114.517 65.6661 116.486 66.1114 119.137Z" fill={themeTextRed} />
        <path d="M83.6871 110.164L77.638 112.454L78.1562 113.823L80.4457 112.957L82.901 119.443L84.3825 118.883L81.9271 112.396L84.2054 111.534L83.6871 110.164Z" fill={themeTextRed} />
        <path d="M103.132 108.396C104.83 107.042 105.058 105.249 103.525 103.325L100.495 99.525L99.2658 100.505L102.221 104.211C103.178 105.412 103.142 106.484 102.204 107.232C101.285 107.965 100.232 107.761 99.2667 106.551L96.3195 102.854L95.0903 103.834L98.1273 107.643C99.6532 109.557 101.452 109.735 103.132 108.396Z" fill={themeTextRed} />
        <path d="M112.972 98.9241C114.151 97.5319 114.118 95.9168 112.9 94.8857C110.445 92.8081 108.732 96.8759 107.349 95.7053C106.863 95.2944 106.906 94.6863 107.371 94.1367C107.883 93.5322 108.555 93.4251 109.181 93.7818L109.963 92.431C108.78 91.729 107.371 91.9825 106.34 93.2008C105.254 94.4831 105.275 96.0567 106.411 97.018C108.874 99.1034 110.579 95.0278 111.999 96.2294C112.502 96.6558 112.492 97.3548 111.958 97.9868C111.446 98.5914 110.676 98.8513 110.033 98.4961L109.288 99.8778C110.495 100.569 111.972 100.106 112.972 98.9241Z" fill={themeTextRed} />
        <defs>
          <filter id="filter0_d" x="2" y="0" width="114" height="114" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="6" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
          <clipPath id="clip0">
            <rect x="38" y="36" width="38" height="35" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};
export default DirectContactIcon;
