import isNil from "lodash/isNil";
import { Map } from "immutable";

export const getToken = (state) => state.getIn(["loggedInUser", "token"]);
export const getUserDetails = (state) => state.getIn(["loggedInUser"]);
export const getUserId = (state) =>
  state.getIn(["loggedInUser", "details", "id"]);
export const getEmail = (state) =>
  state.getIn(["register", "registeredUserData", "email"]);
export const getPassword = (state) => state.getIn(["register", "password"]);
export const getNewsLetterSubscription = (state) =>
  state.getIn(["register", "newsletter"]);

export const getRegisteredUser = (state) =>
  state.getIn(["register", "registeredUserData"]);
export const getRecipeLimit = (state) =>
  state.getIn(["essentials", "recipes", "limit"]);
export const getRecipeOffset = (state) =>
  state.getIn(["essentials", "recipes", "offset"]);
export const getRecipeCollectionSlug = (state) =>
  state.getIn(["essentials", "recipes", "collectionSlug"]);
export const getEssentials = (state) => state.getIn(["essentials"]);

export const getWorkoutLimit = (state) =>
  state.getIn(["essentials", "workouts", "limit"]);
export const getWorkoutOffset = (state) =>
  state.getIn(["essentials", "workouts", "offset"]);

export const getCurrentRecipeId = (state) =>
  state.getIn(["recipe", "activeRecipe", "id"]);
export const getCurrentRecipeForUser = (state) =>
  state.getIn(["recipe", "forUser"]);
export const getCurrentRecipeFetchSlug = (state) =>
  state.getIn(["recipe", "fetchRecipeSlug"]);

export const getCurrentWorkoutId = (state) =>
  state.getIn(["workout", "activeWorkout", "id"]);
export const getCurrentWorkout = (state) =>
  state.getIn(["workout", "activeWorkout"]);
export const getCurrentObjectNote = (state) =>
  state.getIn(["objectSpecificEssential", "note"]);

// export const getRecipeCashed = state => state.getIn(['essentials', 'isCashed']);

export const getAuthStatus = (state) => state.getIn(["loggedInUser"]);

export const getGroceryListId = (state) =>
  state.getIn(["userSpecificEssential", "groceryList", "id"]);
export const getGroceryList = (state) =>
  state.getIn(["userSpecificEssential", "groceryList"]);
export const shouldRefetchGroceryList = (state) =>
  state.getIn(["userSpecificEssential", "shouldGroceryListRefetch"]);

export const getUserEssentials = (state) =>
  state.getIn(["userSpecificEssential"]);

export const getEssentialsFetchedOnce = (state) =>
  state.getIn(["essentials", "recipe", "fetchedOnce"]);
export const getEssentialsCachedForUser = (state) =>
  state.getIn(["essentials", "recipe", "forUser"]);

export const getCachedObjectType = (state) =>
  state.getIn(["objectSpecificEssential", "objectType"]);
export const getCachedObjectId = (state) =>
  state.getIn(["objectSpecificEssential", "objectId"]);
export const getObjectEssentialCachedForUser = (state) =>
  state.getIn(["objectSpecificEssential", "forUser"]);
export const getCachedObjectRecipeSlug = (state) =>
  state.getIn(["recipe", "activeRecipe", "slug"]);

export const getCachedObjectWorkoutSlug = (state) =>
  state.getIn(["workout", "activeWorkout", "slug"]);
export const getExistingWorkouts = (state) =>
  state.getIn(["essentials", "workouts", "list"]);

export const getUserSpecificCachedForUser = (state) =>
  state.getIn(["userSpecificEssential", "forUser"]);
export const getCalendarAnchorDate = (state) =>
  state.getIn(["userSpecificEssential", "calendarSettings", "anchorDate"]);
export const getCalendarDataForDays = (state) =>
  state.getIn(["userSpecificEssential", "calendarSettings", "dataForDays"]);

// filters
export const getCollectionId = (state) =>
  state.getIn(["essentials", "collectionId"]);
export const getSelectedFilters = (state) =>
  state.getIn(["essentials", "recipes", "selectedFilters"]);
export const getSelectedFiltersWorkouts = (state) =>
  state.getIn(["essentials", "workouts", "selectedFilters"]);

// Collections
export const getExistingRecipeCollections = (state) =>
  state.getIn(["essentials", "recipes", "collections"]);
export const getExistingRecipeCategories = (state) =>
  state.getIn(["essentials", "recipes", "categories"]);

export const getExistingWorkoutCollections = (state) =>
  state.getIn(["essentials", "workouts", "collections"]);
export const getExistingWorkoutCategories = (state) =>
  state.getIn(["essentials", "workouts", "categories"]);
// object stack selectors
export const getNavigationStack = (state) => state.getIn(["navigationStack"]);

// Navigation stack related
export const getCurrentObjectId = (state) =>
  state.getIn(["objectSpecificEssential", "stackTopObjectId"]);
export const getObjectEssentials = (state) =>
  state.getIn(["objectSpecificEssential"]); // for active recipe or workout
export const getObjectStack = (state) =>
  state.getIn(["objectSpecificEssential", "recipe", "objectStack"]);

// New Selectors Recipes
export const getAppliedRecipeFilters = (state) =>
  state.getIn(["essentials", "recipe", "filters"]);

export const getRecipeFiltersSetUpdatedPrematurelyStatus = (state) =>
  state.getIn(["essentials", "recipe", "filterSetUpdatedPrematurely"]);
export const getAppliedRecipeFavorites = (state) =>
  state.getIn(["essentials", "recipe", "favorite"]);
export const getDefaultDietary = (state) =>
  state.getIn(["essentials", "recipe", "defaultDietary"]);
export const getFeaturedRecipes = (state) =>
  state.getIn(["essentials", "recipe", "featured"]);
export const getRecipeDirtyGroup = (state) =>
  state.getIn(["essentials", "recipe", "dirtyGroup"]);

export const getRecipeFilteredAppliedOnce = (state) =>
  state.getIn(["essentials", "recipe", "isFilterAppliedOnce"]);
export const getIsRecipeFavoritedList = (state) =>
  state.getIn(["essentials", "recipe", "favorite"]);

export const getRecipeFoodTypes = (state) =>
  state.getIn(["essentials", "recipe", "foodTypes"]);
export const getRecipeDietaries = (state) =>
  state.getIn(["essentials", "recipe", "dietaries"]);
export const getRecipeSpecialities = (state) =>
  state.getIn(["essentials", "recipe", "specialities"]);
export const getRecipeCollections = (state) =>
  state.getIn(["essentials", "recipe", "collection"]);
export const getRecipeBoards = (state) =>
  state.getIn(["userSpecificEssential", "boards", "recipeBoards", "boards"]);

export const getUnFilteredRecipeOffset = (state) =>
  state.getIn(["essentials", "recipe", "unFilteredList", "offset"]);
export const getFilteredRecipeOffset = (state) =>
  state.getIn(["essentials", "recipe", "filteredList", "offset"]);
export const getDefaultDietaryOffset = (state) =>
  state.getIn(["essentials", "recipe", "defaultDietaryList", "offset"]);

export const getUnFilteredRecipeList = (state) =>
  state.getIn(["essentials", "recipe", "unFilteredList", "list"]);
export const getFilteredRecipeList = (state) =>
  state.getIn(["essentials", "recipe", "filteredList", "list"]);
export const getDefaultDietaryList = (state) =>
  state.getIn(["essentials", "recipe", "defaultDietary", "list"]);

export const getCachedRecipeList = (state) =>
  state.getIn(["essentials", "recipe", "cachedList", "list"]);
export const getCachedWorkoutList = (state) =>
  state.getIn(["essentials", "workout", "cachedList", "list"]);

export const getRecipeFromAnyListBySlug = (state, recipeSlug) => {
  const unfilteredRecipeList = getUnFilteredRecipeList(state);
  const filteredRecipeList = getFilteredRecipeList(state);
  const defaultDietaryRecipeList = getUnFilteredRecipeList(state);
  const objectStack = getObjectStack(state);

  let title = null;
  let foundRecipe = null;
  foundRecipe = unfilteredRecipeList.find((recipe) => {
    if (recipe) {
      return recipe.getIn(["slug"]) === recipeSlug;
    }
  });

  if (isNil(foundRecipe)) {
    foundRecipe = filteredRecipeList.find((recipe) => {
      if (recipe) {
        return recipe.getIn(["slug"]) === recipeSlug;
      }
      return recipe.getIn(["slug"]) === recipeSlug;
    });
  }

  if (isNil(foundRecipe)) {
    foundRecipe = defaultDietaryRecipeList.find((recipe) => {
      if (recipe) {
        return recipe.getIn(["slug"]) === recipeSlug;
      }
    });
  }

  if (isNil(foundRecipe)) {
    foundRecipeGroup = objectStack.find((recipeGroup) => {
      if (recipeGroup) {
        return recipeGroup.getIn(["data", "recipe", "slug"]) === recipeSlug;
      }
    });

    foundRecipe = !isNil(foundRecipeGroup)
      ? foundRecipeGroup.getIn(["data", "recipe"])
      : Map();
  }

  return foundRecipe;
};

export const getRecipeByIdFromAnyList = (state, recipeId) => {
  const unfilteredRecipeList = getUnFilteredRecipeList(state);
  const filteredRecipeList = getFilteredRecipeList(state);
  const defaultDietaryRecipeList = getUnFilteredRecipeList(state);

  let title = null;
  let foundRecipe = null;
  foundRecipe = unfilteredRecipeList.find((recipe) => {
    if (recipe) {
      return recipe.getIn(["id"]) === recipeId;
    }
  });

  if (isNil(foundRecipe)) {
    foundRecipe = filteredRecipeList.find((recipe) => {
      if (recipe) {
        return recipe.getIn(["id"]) === recipeId;
      }
      return recipe.getIn(["id"]) === recipeId;
    });
  }

  if (isNil(foundRecipe)) {
    foundRecipe = defaultDietaryRecipeList.find((recipe) => {
      if (recipe) {
        return recipe.getIn(["id"]) === recipeId;
      }
    });
  }

  if (isNil(foundRecipe)) {
    foundRecipeGroup = objectStack.find((recipeGroup) => {
      if (recipeGroup) {
        return recipeGroup.getIn(["data", "recipe", "id"]) === recipeId;
      }
    });

    foundRecipe = !isNil(foundRecipeGroup)
      ? foundRecipeGroup.getIn(["data", "recipe"])
      : Map();
  }

  return foundRecipe ? foundRecipe.getIn(["title"]) : "Recipe";
};

// New Selectors Workouts
export const getWorkoutFiltersSetUpdatedPrematurelyStatus = (state) =>
  state.getIn(["essentials", "workout", "filterSetUpdatedPrematurely"]);
export const getWorkoutsWorkoutTypes = (state) =>
  state.getIn(["essentials", "workout", "workoutTypes"]);
export const getWorkoutsEquipments = (state) =>
  state.getIn(["essentials", "workout", "equipments"]);
export const getWorkoutsBodyParts = (state) =>
  state.getIn(["essentials", "workout", "bodyParts"]);
export const getWorkoutsDifficulty = (state) =>
  state.getIn(["essentials", "workout", "difficulty"]);
export const getWorkoutsTime = (state) =>
  state.getIn(["essentials", "workout", "time"]);
export const getWorkoutCollections = (state) =>
  state.getIn(["essentials", "workout", "collection"]);

export const getAppliedWorkoutFilters = (state) =>
  state.getIn(["essentials", "workout", "filters"]);
export const getAppliedWorkoutFavorites = (state) =>
  state.getIn(["essentials", "workout", "favorite"]);
export const getWorkoutDirtyGroup = (state) =>
  state.getIn(["essentials", "workout", "dirtyGroup"]);

export const getFeaturedWorkout = (state) =>
  state.getIn(["essentials", "workout", "featured"]);

export const getWorkoutFilteredAppliedOnce = (state) =>
  state.getIn(["essentials", "workout", "isFilterAppliedOnce"]);
export const getIsWorkoutFavoritedList = (state) =>
  state.getIn(["essentials", "workout", "favorite"]);
export const getWorkoutBoards = (state) =>
  state.getIn(["userSpecificEssential", "boards", "workoutBoards", "boards"]);
export const getUnFilteredWorkoutList = (state) =>
  state.getIn(["essentials", "workout", "unFilteredList", "list"]);
export const getFilteredWorkoutList = (state) =>
  state.getIn(["essentials", "workout", "filteredList", "list"]);
export const getUnFilteredWorkoutOffset = (state) =>
  state.getIn(["essentials", "workout", "unFilteredList", "offset"]);
export const getFilteredWorkoutOffset = (state) =>
  state.getIn(["essentials", "workout", "filteredList", "offset"]);
export const getFeaturedWorkoutOffset = (state) =>
  state.getIn(["essentials", "workout", "featuredList", "offset"]);

// getUnFilteredRecipeList,
// getRecipeBoards

export const getWorkoutFromAnyListBySlug = (state, workoutSlug) => {
  const unfilteredWorkoutList = getUnFilteredWorkoutList(state);
  const filteredWorkoutList = getFilteredWorkoutList(state);
  const defaultDietaryWorkoutList = getUnFilteredWorkoutList(state);
  const objectStack = getObjectStack(state);

  let title = null;
  let foundWorkout = null;
  foundWorkout = unfilteredWorkoutList.find((workout) => {
    if (workout) {
      return workout.getIn(["slug"]) === workoutSlug;
    }
  });

  if (isNil(foundWorkout)) {
    foundWorkout = filteredWorkoutList.find((workout) => {
      if (workout) {
        return workout.getIn(["slug"]) === workoutSlug;
      }
      return workout.getIn(["slug"]) === workoutSlug;
    });
  }

  return foundWorkout;
};

// Blogposts
export const getExistingBlogPostCategories = (state) =>
  state.getIn(["essentials", "blog", "categories"]);
export const getAppliedBlogFilters = (state) =>
  state.getIn(["essentials", "blog", "filters"]);
export const getAppliedBlogFavorites = (state) =>
  state.getIn(["essentials", "blog", "favorite"]);
export const getBlogFiltersSetUpdatedPrematurelyStatus = (state) =>
  state.getIn(["essentials", "blog", "filterSetUpdatedPrematurely"]);
export const getBlogDirtyGroup = (state) =>
  state.getIn(["essentials", "blog", "dirtyGroup"]);
export const getBlogCategories = (state) =>
  state.getIn(["essentials", "blog", "categories"]);
export const getBlogBoards = (state) =>
  state.getIn(["userSpecificEssential", "boards", "blogBoards", "boards"]);
export const getUnFilteredBlogList = (state) =>
  state.getIn(["essentials", "blog", "unFilteredList", "list"]);
export const getFilteredBlogList = (state) =>
  state.getIn(["essentials", "blog", "filteredList", "list"]);
export const getCachedBlogList = (state) =>
  state.getIn(["essentials", "blog", "cachedList", "list"]);

export const getBlogFilteredAppliedOnce = (state) =>
  state.getIn(["essentials", "blog", "isFilterAppliedOnce"]);
export const getIsBlogFavoritedList = (state) =>
  state.getIn(["essentials", "blog", "favorite"]);
export const getUnFilteredBlogOffset = (state) =>
  state.getIn(["essentials", "blog", "unFilteredList", "offset"]);
export const getFilteredBlogOffset = (state) =>
  state.getIn(["essentials", "blog", "filteredList", "offset"]);

export const getBlogFromAnyListBySlug = (state, blogSlug) => {
  const unfilteredBlogList = getUnFilteredBlogList(state);
  const filteredBlogList = getFilteredBlogList(state);

  let title = null;
  let foundBlog = null;
  foundBlog = unfilteredBlogList.find((blog) => {
    if (blog) {
      return blog.getIn(["slug"]) === blogSlug;
    }
  });

  if (isNil(foundBlog)) {
    foundBlog = filteredBlogList.find((blog) => {
      if (blog) {
        return blog.getIn(["slug"]) === blogSlug;
      }
      return blog.getIn(["slug"]) === blogSlug;
    });
  }

  return foundBlog;
};

// videos
export const getExistingVideoCategories = (state) =>
  state.getIn(["essentials", "video", "categories"]);
export const getAppliedVideoFilters = (state) =>
  state.getIn(["essentials", "video", "filters"]);
export const getAppliedVideoFavorites = (state) =>
  state.getIn(["essentials", "video", "favorite"]);
export const getVideoFiltersSetUpdatedPrematurelyStatus = (state) =>
  state.getIn(["essentials", "video", "filterSetUpdatedPrematurely"]);
export const getVideoDirtyGroup = (state) =>
  state.getIn(["essentials", "video", "dirtyGroup"]);
export const getVideoCategories = (state) =>
  state.getIn(["essentials", "video", "categories"]);
export const getVideoCollections = (state) =>
  state.getIn(["essentials", "video", "collections"]);
export const getVideoBoards = (state) =>
  state.getIn(["userSpecificEssential", "boards", "videoBoards", "boards"]);
export const getUnFilteredVideoList = (state) =>
  state.getIn(["essentials", "video", "unFilteredList", "list"]);
export const getFilteredVideoList = (state) =>
  state.getIn(["essentials", "video", "filteredList", "list"]);
export const getCachedVideoList = (state) =>
  state.getIn(["essentials", "video", "cachedList", "list"]);

export const getVideoFilteredAppliedOnce = (state) =>
  state.getIn(["essentials", "video", "isFilterAppliedOnce"]);
export const getIsVideoFavoritedList = (state) =>
  state.getIn(["essentials", "video", "favorite"]);
export const getUnFilteredVideoOffset = (state) =>
  state.getIn(["essentials", "video", "unFilteredList", "offset"]);
export const getFilteredVideoOffset = (state) =>
  state.getIn(["essentials", "video", "filteredList", "offset"]);

export const getCalendarData = (state) =>
  state.getIn(["userSpecificEssential", "calendarListData"]);
export const getCalendarMacros = (state) =>
  state.getIn(["userSpecificEssential", "calendarSettings", "macros"]);

export const getTrending = (state) => state.getIn(["essentials", "trending"]);

export const getFaqs = (state) => state.getIn(["essentials", "faqs", "list"]);
export const getPlans = (state) => state.getIn(["essentials", "plans"]);

export const isTaxonomiesFetchedAlready = (state) => {
  const recipeFoodTypes = state.getIn(["essentials", "recipe", "foodTypes"]);
  const recipeDietaries = state.getIn(["essentials", "recipe", "dietaries"]);
  const recipeSpecialities = state.getIn([
    "essentials",
    "recipe",
    "specialities",
  ]);

  const videoCategories = state.getIn(["essentials", "video", "categories"]);
  const videoCollections = state.getIn(["essentials", "video", "collections"]);

  const workoutsWorkoutTypes = state.getIn([
    "essentials",
    "workout",
    "workoutTypes",
  ]);
  const workoutsEquipments = state.getIn([
    "essentials",
    "workout",
    "equipments",
  ]);
  const workoutsBodyParts = state.getIn(["essentials", "workout", "bodyParts"]);

  const blogCategories = state.getIn(["essentials", "blog", "categories"]);

  return (
    !recipeFoodTypes.isEmpty() &&
    !recipeDietaries.isEmpty() &&
    !recipeSpecialities.isEmpty() &&
    !videoCategories.isEmpty() &&
    !videoCollections.isEmpty() &&
    !workoutsWorkoutTypes.isEmpty() &&
    !workoutsEquipments.isEmpty() &&
    !workoutsBodyParts.isEmpty() &&
    !blogCategories.isEmpty()
  );
};

// dishlist
export const getExistingDishlistCategories = (state) =>
  state.getIn(["essentials", "dishlist", "categories"]);

// get grocerylist threshold status and temprory ingredients

export const getGroceryListThresholdData = (state) => {
  const status = state.getIn([
    "userSpecificEssential",
    "groceryListThresholdReached",
  ]);
  const ingredients = state.getIn([
    "userSpecificEssential",
    "temporaryGroceryList",
  ]);
  return {
    status,
    ingredients,
  };
};

// get gift code
export const getGiftCode = (state) => state.getIn(["register", "giftCode"]);
// get referral code
export const getReferralCode = (state) =>
  state.getIn(["register", "referralCode"]);
export const getCouponData = (state) =>
  state.getIn(["unifiedRegister", "coupon"]);
