
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getWorkoutLimit, getWorkoutOffset, getCollectionId } from './selectors'
import workoutsTask from './subtask/workouts';
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import isNil from 'lodash/isNil'

function* task(action) {
  try {
    const workoutLimit = yield select(getWorkoutLimit);
    const workoutOffset = yield select(getWorkoutOffset);
    const stateCollectionId = yield select(getCollectionId)

    const collectionId = isNil(action.payload.collectionId) ? stateCollectionId : action.payload.collectionId

    yield call(workoutsTask.getAllWorkoutsFromCollection, actions.GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION_SUCCESS, {
      workoutLimit,
      workoutOffset,
      replace: action.payload.collectionId ? true : false,
      collectionId: collectionId
    })
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* getAllWorkoutsFromSelectedCollection() {
  yield takeEvery(actions.GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION, task)
}