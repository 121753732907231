import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import ModalWrapper from '../../common/modal'
import Dropdown from '../../common/dropdown'
import Button from '../../common/button'
import CustomInput from '../../common/customInput'

const SaveDayModal = ({
  setSaveDayState,
  saveDayModal,
  onRequestClose,
  saveCalendarDay
}) => {
  const [customName, setCustomName] = useState('');
  const buttonType = (customName && customName.length > 0) ? 'primary' : 'secondary'

  return (
    <ModalWrapper
      isOpen={saveDayModal.show}
      // overflowY="hidden"
      onRequestClose={onRequestClose}
      // maxHeight="400px"
      maxWidth="440px"
      minHeight="250px"
    >
      <div className="modalWrapper">
        <h3 className="modalHeader">
          Save this day
        </h3>
        <div className="modalBody Save-meal-wrapper">
          <div className="custom-block">
            {/* What do you want to name it ? */}
            <CustomInput
              type='text'
              onChange={(e) => {
                setCustomName(e.target.value)
              }}
              placeholder={saveDayModal.activeDate}
              placeholder="Type here to save this day"
              value={customName}
              errorMessage=""
            />
          </div>
        </div>
        <div className="modalFooter">
          <Button
            btnClass={`${buttonType} btn-full`}
            // btnText="Save"
            btnText={'Save'}
            disabled={customName.length <= 0}
            onClick={() => {
              saveCalendarDay(customName);
              setCustomName('');
            }}
          />
          <Button
            btnClass="secondary btn-full btn-bp-sm"
            btnText="Cancel"
            onClick={() => {
              onRequestClose();
            }}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default SaveDayModal;