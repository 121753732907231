import axios from 'axios';
import { get, post } from '../../helpers/requests';
import { comments } from '../../helpers/api';
import { call, select } from 'redux-saga/effects';
import { getToken } from '../selectors'
import isNull from 'lodash/isNull'

export default {
  get: function* getComments(objectId, objectType) {
    let commentsResponse = {
      data: {
        comments: []
      }
    }
    const token = yield select(getToken)

    commentsResponse = yield call(axios, get(comments.get(objectId, objectType), '', token))

    return commentsResponse
  },
  post: function* postComment(commentObject) {
    const token = yield select(getToken);
    const commentsResponse = yield call(axios, post(comments.post(), commentObject, '', token))
    return commentsResponse
  }
} 
