import React from "react";
import '../styles/common.scss';


const SwapOutsIcon = (props) => {
  return (
    < >
      <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.6962 20.5634C20.4287 20.5634 24.2652 16.727 24.2652 11.9944C24.2652 7.26189 20.4287 3.42542 15.6962 3.42542C10.9636 3.42542 7.12717 7.26189 7.12717 11.9944C7.12717 16.727 10.9636 20.5634 15.6962 20.5634Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.30711 22.3835C-0.435704 16.6407 -0.435704 7.33686 5.30711 1.60535" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M26.0853 1.60535C31.8281 7.34816 31.8281 16.652 26.0853 22.3835" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M2.77484 24.0001H6.60716C6.77673 24.0001 6.92369 23.8644 6.92369 23.6835V19.8512C6.92369 19.5686 6.58455 19.4329 6.39237 19.6251L2.56005 23.4574C2.35657 23.6609 2.49223 24.0001 2.77484 24.0001Z" fill="white" />
        <path d="M24.3782 4.13754V0.316533C24.3782 0.146962 24.5139 0 24.6948 0H28.5271C28.8097 0 28.9454 0.339143 28.7532 0.531324L24.9209 4.36364C24.7174 4.55582 24.3782 4.42016 24.3782 4.13754Z" fill="white" />
      </svg>
    </>
  );
};
export default SwapOutsIcon;
