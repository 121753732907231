import React from 'react'

const IngredientOptionRow =( props ) => {
  return(
    <li className="groupList">
      <p className="ingredientOption">
        {props.amount}
      </p>
    </li>
  )
}

export default IngredientOptionRow;