import React from "react";

import { Row, Col } from "react-flexbox-grid";
import VerticalListWithTitle from "./common/verticalListWithTitle";
import NavbarImageCard from "./common/navbarImageCard";

import workout from "../../images/workoutnavimage.png";
import video from "../../images/videonavimage.png";
import recipe from "../../images/recipenavimage.png";
import NavDropdownImage from "./common/NavDropdownImage";
import mealplan1 from "../../images/mealPlannavimage1.png";
import mealplan2 from "../../images/mealPlannavimage2.png";
import mealplan3 from "../../images/mealPlannavimage3.png";
import blogCollage1 from "../../images/blogCollage1.png";
import blogCollage2 from "../../images/blogCollage2.png";
import blogCollage3 from "../../images/blogCollage3.png";
import blogCollage4 from "../../images/blogCollage4.png";

const completeNavListItems = (latestRecipe) => [
  {
    image:
      latestRecipe && latestRecipe.images
        ? latestRecipe.images.full_sm
        : recipe, // LEAVE: Dynamic image
    title: "Recipes",
    type: "recipe",
    href: "/recipes",
    description: "Simple, nutritious, no fuss & family friendly",
  },
  {
    image1: mealplan1,
    image2: mealplan2,
    image3: mealplan3,
    type: "mealplan",
    href: "/login?type=meal-plan-login",
    title: "Meal Plans",
    description: "Practical, budget friendly plans that work for you",
  },
  {
    image: workout, // TODO: Replace image here
    type: "workout",
    href: "/workouts",
    title: "Workout",
    description: "At home workouts for every level & muscle",
  },
  {
    image: video, // TODO: Replace image here
    type: "video",
    href: "/videos",
    title: "Videos",
    description: "Cooking shows & tips to succeed in the kitchen",
  },
  {
    type: "blog",
    href: "/blog/preview",
    title: "Blog",
    description: "Weekly devotionals & inspiring resources",
  },
];

const LoggedoutNavDropdown = ({
  latestRecipe,
  history,
  requestCloseNavDropdown,
}) => (
  <div className="container no-padding-md">
    <div className="navbar-dropdown-scroller">
      <Row className="loggedout-dropdown no-margin">
        <Col lg={12} className="no-padding">
          <NavbarImageCard
            list={completeNavListItems(latestRecipe)}
            history={history}
            onClose={requestCloseNavDropdown}
            render={(item) =>
              item.type !== "blog" ? (
                <NavDropdownImage
                  type={item.type}
                  src={item.image}
                  image1={item.image1}
                  image2={item.image2}
                  image3={item.image3}
                />
              ) : (
                <div className="navbar-image">
                  <div className="blog-static-collage">
                    <img
                      src={blogCollage1}
                      className="img_fluid dropdown-grid-image"
                    />
                    <img
                      src={blogCollage2}
                      className="img_fluid dropdown-grid-image"
                    />
                    <img
                      src={blogCollage3}
                      className="img_fluid dropdown-grid-image"
                    />
                    <img
                      src={blogCollage4}
                      className="img_fluid dropdown-grid-image"
                    />
                  </div>
                </div>
              )
            }
          />
        </Col>
      </Row>
    </div>
  </div>
);

export default LoggedoutNavDropdown;
