import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from "../../../helpers/toJS";

import {
  sendNote,
  createBoard,
  sendComment,
  deleteFavourite,
  setObjectBoards,
  createFavourite,
  sendReplyComment,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  appEssentialFetch,
} from "../../../actions";

import {
  getFetchStatus,
  getCommentsList,
  getNoteStackItem,
  getBlogDetails,
  getRelatedBlogs,
  getBoards,
  getUserDetails,
  getObjectFetchStatus
} from "../../../selectors/blogs";

import SingleBlog from './components';

const mapStateToProps = state => ({
  activeBlog: getBlogDetails(state),
  notes: getNoteStackItem(state, 'blog'),
  comments: getCommentsList(state, 'blog'),
  sideIdeas: getRelatedBlogs(state),
  boards: getBoards(state),
  fetchStatus: getFetchStatus(state),
  userDetails: getUserDetails(state),
  // commentPostStatus: getCommentPostStatus(state),
  // isLoggedIn: state.getIn(['loggedInUser', 'isLoggedIn']),
  objectFetchStatus: getObjectFetchStatus(state, { objectType: 'blog' }),
  // objectEssentialFetchStatus: getObjectEssentialFetchStatus(state, { objectType: 'workout' }),

})

const dispatchActionToProps = dispatch => ({
  sendNote: bindActionCreators(sendNote, dispatch),
  sendComment: bindActionCreators(sendComment, dispatch),
  createBoard: bindActionCreators(createBoard, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  sendReplyComment: bindActionCreators(sendReplyComment, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(SingleBlog))