import React, { useState } from 'react'
import axios from 'axios'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from "react-helmet"
import { Row, Col } from 'react-flexbox-grid';

import toJS from '../../../helpers/toJS';
import { auth } from '../../../helpers/api'
import Button from '../../common/button'
import CustomInput from "../../common/customInput";
import FrameIcon from '../../images/frameIcon';
import { addSentryError } from "../../../actions";;
import pushToSentry from "../../../helpers/pushToSentry";
import katie from '../../images/login-katie.png';
import { TransitionOne } from "../../common/transitions";

const Forgot = (props) => {
  const [email, setEmail] = useState(null);

  const checkEmail = (email) => {
    if (!(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email))) {
      return "Enter a valid email"
    } else {
      return null
    }
  }

  const [state, updateState] = useState({
    isResetting: false,
    resetSuccess: false,
    resetFailed: false,
    errorMessage: false
  })

  const [blurredOnce, setBlurredOnce] = useState(false)

  const handleResetPassword = () => {
    updateState({
      ...state,
      isResetting: true,
      resetSuccess: false,
      resetFailed: false,
      errorMessage: null,
    })

    axios({
      method: 'POST',
      url: auth.resetPasswordInitiate(),
      data: {
        email
      }
    }).then((response) => {
      if (response.data.success) {
        updateState({
          ...state,
          isResetting: false,
          resetSuccess: true
        })
      } else {
        updateState({
          ...state,
          isResetting: false,
          resetFailed: true
        })
      }
    }).catch((e) => {
      const eventId = pushToSentry(e)

      updateState({
        ...state,
        isResetting: false,
        resetFailed: true,
        errorMessage: 'Email not found. Please enter a valid email'
      })
    })
  }

  return (
    <>
      <section className="login-section">
        <Helmet>
          <title>Forgot Password | Dashing Dish </title>
          <meta name="description" content="Dashingdish forgot password page" />
        </Helmet>
        <Row className="no-margin">
          <Col xs={12} md={12} lg={6} className="no-padding-sm">
            <TransitionOne>
              <div className="login-wrapper">
                <div className="loginBlock">
                  <h1>Forgot Password</h1>
                  <p className="login-intro">We'll send you an email containing a secure link to reset your account password.</p>
                  <form onSubmit={e => e.preventDefault()}>
                    <CustomInput
                      type="email"
                      label="Email"
                      placeholder="Type here"
                      value={email}
                      onChange={e => {
                        if (state.errorMessage || state.isResetting || state.resetFailed || state.resetSuccess) {
                          updateState({
                            ...state,
                            isResetting: false,
                            resetSuccess: false,
                            resetFailed: false,
                            errorMessage: null,
                          })
                        }


                        setEmail(e.target.value)
                      }}
                      onBlur={() => setBlurredOnce(true)}
                      errorMessage={blurredOnce ? checkEmail(email) : null}
                      isLastElementOfForm={true}
                      formLastElementAction={() => handleResetPassword()}
                    />
                    <Button
                      type="button"
                      btnClass="primary btn-md"
                      btnText="Submit"
                      onClick={handleResetPassword}
                      disabled={checkEmail(email) || state.isResetting}
                      isLoading={state.isResetting}
                    />
                    {
                      state.resetSuccess && (
                        <div className="errorText">
                          <FrameIcon></FrameIcon>
                          <p>
                            Please check your email for the password reset instructions.
                  </p>
                        </div>
                      )
                    }
                    {
                      state.resetFailed && (
                        <div className="errorText">
                          <FrameIcon></FrameIcon>
                          <p>
                            {state.errorMessage}
                          </p>
                        </div>
                      )
                    }
                  </form>
                </div>
              </div>
            </TransitionOne>
          </Col>
          <TransitionOne>
            <Col xs={12} md={12} lg={6} className="image-bg-wrapper"
              style={{
                backgroundImage: `url(${katie})`
              }}
            >
            </Col>
          </TransitionOne>
        </Row>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
})

const dispatchActionToProps = dispatch => ({
  addSentryError: bindActionCreators(addSentryError, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Forgot));