export const mergeableIngredients = [
  {
    title: 'carrot',
    singular: 'carrot',
    plural: 'carrots',
    alias: ['carrot', 'carrots']
  },
  {
    title: 'onion',
    singular: 'onion',
    plural: 'onions',
    alias: ['onion', 'onions']
  },
  {
    title: 'egg white',
    singular: 'egg white',
    plural: 'egg whites',
    alias: ['egg white', 'egg whites']
  },
  {
    title: 'egg',
    singular: 'egg',
    plural: 'eggs',
    alias: ['egg', 'eggs']
  },
  {
    title: 'chicken breast',
    singular: 'chicken breast',
    plural: 'chicken breasts',
    alias: ['chicken breast', 'chicken breasts']
  },
  {
    title: 'banana',
    singular: 'banana',
    plural: 'bananas',
    alias: ['banana', 'bananas']
  },
  {
    title: 'apple',
    singular: 'apple',
    plural: 'apples',
    alias: ['apple', 'apples']
  },
  {
    title: 'sweet potato',
    singular: 'sweet potato',
    plural: 'sweet potatoes',
    alias: ['sweet potato', 'sweet potatoes']
  },
  {
    title: 'potato',
    singular: 'potato',
    plural: 'potatoes',
    alias: ['potato', 'potatoes']
  },
  {
    title: 'Jalapeño',
    singular: 'Jalapeño',
    plural: 'Jalapeños',
    alias: ['Jalapeño', 'Jalapeños']
  },
  {
    title: 'avacado',
    singular: 'avacado',
    plural: 'avacados',
    alias: ['avacado', 'avacados']
  },
  {
    title: 'tomato',
    singular: 'tomato',
    plural: 'tomatoes',
    alias: ['tomato', 'tomatoes']
  },
  {
    title: 'lemon',
    singular: 'lemon',
    plural: 'lemons',
    alias: ['lemon', 'lemons']
  },
  {
    title: 'lime',
    singular: 'lime',
    plural: 'limes',
    alias: ['lime', 'limes']
  },
  {
    title: 'green onion',
    singular: 'green onion',
    plural: 'green onions',
    alias: ['green onion', 'green onions']
  },
  {
    title: 'sweet onion',
    singular: 'sweet onion',
    plural: 'sweet onions',
    alias: ['sweet onion', 'sweet onions']
  },
  {
    title: 'red onion',
    singular: 'red onion',
    plural: 'red onions',
    alias: ['red onion', 'red onions']
  },
  {
    title: 'peach',
    singular: 'peach',
    plural: 'peaches',
    alias: ['peach', 'peaches']
  },
]

export const mergeableQuantities = [
  {
    title: 'lb',
    singular: 'lb',
    plural: 'lbs',
    alias: ['lb', 'lbs']
  },
  {
    title: 'cup',
    singular: 'cup',
    plural: 'cups',
    alias: ['cup', 'cups']
  },
  {
    title: 'stalk',
    singular: 'stalk',
    plural: 'stalks',
    alias: ['stalk', 'stalks']
  },
  {
    title: 'head',
    singular: 'head',
    plural: 'heads',
    alias: ['head', 'heads']
  },
  {
    title: 'jar',
    singular: 'jar',
    plural: 'jars',
    alias: ['jar', 'jars']
  },
  {
    title: 'box',
    singular: 'box',
    plural: 'boxes',
    alias: ['box', 'boxes']
  },
  {
    title: 'can',
    singular: 'can',
    plural: 'cans',
    alias: ['can', 'cans']
  },
  {
    title: 'bunch',
    singular: 'bunch',
    plural: 'bunches',
    alias: ['bunch', 'bunches']
  },
  {
    title: 'ear',
    singular: 'ear',
    plural: 'ears',
    alias: ['ear', 'ears']
  },
  {
    title: 'pkg',
    singular: 'pkg',
    plural: 'pkgs',
    alias: ['pkg', 'pkgs']
  },
  {
    title: 'slice',
    singular: 'slice',
    plural: 'slices',
    alias: ['slice', 'slices']
  },
]