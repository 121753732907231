import axios from 'axios'
import { put, takeEvery, call, select, all } from 'redux-saga/effects'
import * as actions from '../../actions/actionTypes'

import { get } from '../../helpers/requests';

import { trending } from '../../helpers/api'

import pushToSentry from '../../helpers/pushToSentry';
import { getToken, getTrending } from '../selectors';
import { getTrendingType } from '../../selectors/recipes';

export default function* trendingEssentials() {
  try {
    const trendingData = yield select(getTrending);

    if (
      trendingData.getIn(['recipes', 'type']) === '' &&
      trendingData.getIn(['workouts', 'type']) === '' &&
      trendingData.getIn(['videos', 'type']) === '' &&
      trendingData.getIn(['blogs', 'type']) === ''
    ) {
      const token = yield select(getToken)

      yield put({
        type: actions.TRENDING_ESSENTIALS_FETCH
      })

      const {
        trendingRecipes,
        trendingWorkouts,
        trendingBlogs,
      } = yield all({
        trendingRecipes: call(axios, get(trending.recipes('day'), '', token)),
        trendingWorkouts: call(axios, get(trending.workouts('day'), '', token)),
        trendingBlogs: call(axios, get(trending.blogs('day'), '', token)),
      });

      yield put({
        type: actions.TRENDING_ESSENTIALS_FETCH_SUCCESS,
        payload: {
          recipes: trendingRecipes.data.data,
          workouts: trendingWorkouts.data.data,
          blogs: trendingBlogs.data.data,
          videos: []
        }
      })
    }
  } catch (e) {
    const eventId = pushToSentry(e)

    yield put({
      type: actions.TRENDING_ESSENTIALS_FETCH_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}