import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'
import toJS from '../../../helpers/toJS';

import isNil from "lodash/isNil";

import coverImage from '../../images/membership-heroImg.png'
import { getRegisterStatus } from '../../../selectors'
import { showToastMessage, resetRegisterState, completeRegistration } from '../../../actions'
import Button from "../../common/button";

const ActivateGift = ({
  registerStatus,
  ...props
}) => {
  // gift data state
  const [giftedRegistration, setGiftedRegistration] = useState({
    isGifted: false,
    toEmail: null
  })

  const ActivateGift = () => {
    props.completeRegistration(
      registerStatus.registeredUserData.id,
      payload.token.id,
      "", // Duration has to come from API
      ""
    )
  }

  useEffect(() => {
    if (!isNil(registerStatus.giftCode) && !isNil(registerStatus.giftCode.giftCode) && !isNil(registerStatus.giftCode.toEmail)) {
      setGiftedRegistration({ isGifted: true, toEmail: registerStatus.giftCode.toEmail })
    }
  }, registerStatus.giftCode)
  return (
    <section className="gift-membership"
      style={{
        backgroundImage: `url(${coverImage})`,
      }}>
      <Helmet>
        <title>Activate Gift | Dashing Dish </title>
        <meta name="description" content="Dashingdish Activate Gift page" />
      </Helmet>
      <div className="container">
        <div className="gift-container">
          <h2 className="gift-header">Activate Gift</h2>
          <p>
            prasanna bought you a 3-month membership! We've already filled in your information below—just choose a password, then you're all set!
          </p>

          <Button
            btnText="Activate"
            btnClass="btn-primary"
            onClick={handleActivateGift}
          />
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  registerStatus: getRegisterStatus(state),
})

const dispatchActionToProps = dispatch => ({
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  resetRegisterState: bindActionCreators(resetRegisterState, dispatch),
  completeRegistration: bindActionCreators(completeRegistration, dispatch)
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(ActivateGift));