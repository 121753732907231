import trim from 'lodash/trim'
import join from 'lodash/join'
import filter from 'lodash/filter'

export const isAmountMixedDecimal = grocery => {
  try {
    if (grocery) {
      const splitGrocery = grocery?.split(' ') || [];
      return (splitGrocery.length >= 2 && !isNaN(parseInt(splitGrocery[1][0])));
    }
  } catch (e) {
    console.log(e);
  }

  // Check first character of 2nd item is equal to a number

}

// Same as inside selector function
export const getFractionFromString = (grocery) => {
  if (grocery) {
    const isMixedDecimal = isAmountMixedDecimal(grocery)

    return trim(join(filter(grocery.split(' '), (grocery, index) => {
      return (index === 0 || (isMixedDecimal && index === 1))
    }), ' '));
  } else {
    return '';
  }

}


export const getValueBetweenTwoNumbers = (num1, num2) => {
  let i
  let array = []
  for (i = num1 - '0'; i <= num2 - '0'; i++) {
    array = array.concat(`${i}`)
  }
  return array
}

// debouncer logic
let debouncer
export const debounce = (time, callback, value) => {
  if (!debouncer) {
    debouncer = setTimeout(() => {
      callback(value)
    }, time)
  } else {
    clearTimeout(debouncer);
    debouncer = setTimeout(() => {
      callback(value)
    }, time);
  }

}

// removed a charecter from a perticular index
export const removeCharecterFromIndex = (str, index) => {
  let one = str.substring(0, index)
  let two = str.substring(index + 1, str.length)
  return one + two
}

