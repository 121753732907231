import axios from "axios";
import { put, takeEvery, call, delay } from "redux-saga/effects";

import { post } from '../helpers/requests'
import { auth } from "../helpers/api";

import * as actions from '../actions/actionTypes'
import pushToSentry from "../helpers/pushToSentry";
import lockMembership from "./process/lockMembership";

function* task(action) {
  try {
    const { email, password } = action.payload;
    const response = yield call(axios, post(auth.login(), { email, password }));

    const token = response.data.session_token;

    if (typeof localStorage !== 'undefined' && localStorage != null) {

      localStorage.setItem('token', token)
    } else {
      pushToSentry('localStorage not available ')
    }

    // console.log('response ', response)
    const userData = {
      details: response.data.user
    }

    const isLocked = yield call(lockMembership, userData, token)

    // console.log('user data ', userData.details, isLocked);
    const isUserValid = (
      userData.details.account_status === 'active' ||
      userData.details.account_status === 'trialing' ||
      userData.details.account_status === 'past_due'
    ) && !isLocked; // lock only for mobile

    console.log('is user valid ', isUserValid);
    if (isUserValid) {
      yield put({
        type: actions.LOGIN_USER_SUCCESS,
        payload: {
          loginData: response.data
        }
      });
    } else {

      let originAccount = ''

      if (userData.details.membership.origin === 'ios') {
        originAccount = 'IOS'
      } else if (userData.details.membership.origin === 'android') {
        originAccount = 'Android'
      } else if (userData.details.membership.origin === 'paypal') {
        originAccount = 'Paypal'
      }
      const getOriginTitle = origin => {
        if (origin === 'ios') return 'iPhone'
        else if (origin === 'android') return 'Google Play'
        else if (origin === 'paypal') return 'Paypal'
      }
      yield put({
        type: actions.LOGIN_USER_FAILURE,
        payload: {
          error: `Hey, we see that you subscribed with (${userData && userData.details && userData.details.membership && getOriginTitle(userData.details.membership.origin)}), please log in once on your ${userData && userData.details && userData.details.membership && (originAccount)} device so we can recognise your subscription. You can come back and login here when you've done that.`
        }
      });
    }

  } catch (e) {

    if (e.response && e.response.status === 400) {
      const token = e.response.data.session_token;

      if (typeof localStorage !== 'undefined' && localStorage != null) {
        localStorage.setItem('token', token)
      } else {
        pushToSentry('localStorage not available ')
      }

      if (e.response.data.type === "inactive_account") {
        const data = e.response.data.user;
        yield put({
          type: actions.SAVE_REACTIVATE_ACCOUNT_DATA,
          payload: {
            user: {
              ...data,
              email: action.payload.email,
              password: action.payload.password
            }
          }
        });
      }
      yield put({
        type: actions.LOGIN_USER_FAILURE,
        payload: {
          error: e.response.data.message
        }
      });
    } else {
      const eventId = pushToSentry(e)
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: e.message,
          eventId
        }
      })
      yield put({
        type: actions.LOGIN_USER_FAILURE,
        payload: {
          error: 'Some error occured.'
        }
      });
    }
  }
}

export default function* loginUser() {
  yield takeEvery("LOGIN_USER", task);
}
