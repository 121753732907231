import { put, takeEvery, select } from 'redux-saga/effects';

import * as actions from '../actions/actionTypes';
import { getNavigationStack } from './selectors';
import pushToSentry from '../helpers/pushToSentry';

function* task(action){
  try {
    // get the stack data
    const navigationStack = yield select(getNavigationStack);

    let topIndex = navigationStack.getIn(['topIndex']);
    let activeObject = navigationStack.getIn(['items', topIndex-1]);

    //set the activeObject data to itended reducer
      yield put({
        type: actions.POP_OBJECT_FROM_NAVIGATION_STACK_SUCCESS,
        payload: {
          activeObject: activeObject,
          objectType: action.payload.objectType
        }
      })
    // call needed for object essential fetch
    
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.POP_OBJECT_FROM_NAVIGATION_STACK_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* popObjectFromStack(){
  yield takeEvery(actions.POP_OBJECT_FROM_NAVIGATION_STACK, task)
}
