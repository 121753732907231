import React from 'react'
import '../styles/common.scss';

const Loader = (props) => {
    return (
        <p className="loader" style={{ background: '#171717', marginBottom: '7.5px', height: props.height, animation: 'fading 1.5s infinite', opacity: '.1', }} />
    )
}

Loader.defaultProps = {
    height: '10px',
}

export default Loader;
