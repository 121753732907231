import axios from 'axios';
import findIndex from 'lodash/findIndex';
import { useEffect, useReducer } from 'react';
import { baseUrl } from './api';

const dishListReducer = (state, action) => {
  switch (action.type) {
    case 'DISH_LIST_REQUEST':
      if (action.payload.offset === 0)
        return {
          ...state,
          ...action.payload,
          shouldFetch: true,
          isFetching: true,
          isFetched: false,
        }
      else
        return {
          ...state,
          ...action.payload,
          paginationFetching: true,
          isFetched: false
        }
    case 'DISH_LIST_REQUEST_SUCCESS':
      if (action.payload.newBatch) {
        return {
          ...state,
          ...action.payload,
        }
      } else {
        return {
          ...state,
          ...action.payload,
          data: [...state.data, ...action.payload.data],
        }
      }
    case 'FETCH_MORE_DISH_LIST':
      return {
        ...state,
        offset: state.offset + 25,
        newBatch: false,
        shouldFetch: true,
        paginationFetching: true,
        isFetched:false
      }
    case 'SET_FILTER':
      return {
        ...state,
        selectedCategory: action.payload.selectedCategory,
        data: [],
        limit: 25,
        offset: 0,
        newBatch: true,
        hasMore: true,
        isFetching: true,
        shouldFetch: true,
        isFetched:false
      }
    
    case 'SET_CATEGORIES':
      return{
        ...state,
        categories: action.payload.categories
      }
    default: return state;
  }
}

export const useDishListHook = ({ categories, shouldFetch = true }) => {
  const [state, dispatchDishListAction] = useReducer(
    dishListReducer,
    {
      limit: 25,
      offset: 0,
      isFetching: false,
      paginationFetching: false,
      isFetched: false,
      hasMore: true,
      newBatch: true,
      data: [],
      shouldFetch: shouldFetch,
      selectedCategory: 'all',
      categories: categories,
    }
  );

  const selectedFilterIndex = findIndex(state.categories, category => category.slug === state.selectedCategory);
  const selectedFilter = selectedFilterIndex !== -1 ? state.categories[selectedFilterIndex] : [];
  const finalQueryParam = `categories=${selectedFilter.id}`;
  const fetchDishList = () => {
    dispatchDishListAction({ type: 'DISH_LIST_REQUEST', payload: { offset: state.offset } });
    axios(`${baseUrl}/api/dish-list?limit=${state.limit}&offset=${state.offset}&${finalQueryParam}`)
      .then((response) => {
        if (response.status === 200) {
          const payload = {
            data: response.data.dishlist,
            newBatch: false,
            isFetching: false,
            paginationFetching: false,
            hasMore: response.data.dishlist.length >= 25,
            shouldFetch: false,
            isFetched: true
          }
          dispatchDishListAction({ type: 'DISH_LIST_REQUEST_SUCCESS', payload });
        }
      })
      .catch(e => {
        console.warn(e)
      })
  }
  useEffect(() => {
    if (state.shouldFetch) {
      fetchDishList();
    }
  }, [state.shouldFetch]);


  return [state, dispatchDishListAction]
}
