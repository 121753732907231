import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from '../../helpers/toJS';

import {
  appEssentialFetch,
  selectIngredientInGroceryList,
  removeIngredientInGroceryList,
  moveIngredientInGroceryList,
  changeGroceryListViewType,
  clearAllGroceryListItems,
  addIngredientInGroceryListAisle,
  deleteIngredientInGroceryList,
  addIngredientInGroceryListRecipe,
  showToastMessage,
} from "../../actions";

import {
  getUserDetails,
  getGroceryListByType,
  getGroceryListViewType,
  getGrocerySelectedValues,
  getGroceryListFetchStatus,
  getProgressStatus,
  getGroceryListItemCount
} from '../../selectors'

import GroceryList from "./components/groceryList";

const mapStateToProps = state => ({
  userDetails: getUserDetails(state),
  groceryList: getGroceryListByType(state),
  activeGroceryType: getGroceryListViewType(state),
  groceryListSelectedIngredients: getGrocerySelectedValues(state),
  fetchStatus: getGroceryListFetchStatus(state),
  progressStatus: getProgressStatus(state),
  groceryListCount: getGroceryListItemCount(state),
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  selectIngredientInGroceryList: bindActionCreators(selectIngredientInGroceryList, dispatch),
  removeIngredientInGroceryList: bindActionCreators(removeIngredientInGroceryList, dispatch),
  moveIngredientInGroceryList: bindActionCreators(moveIngredientInGroceryList, dispatch),
  changeGroceryListViewType: bindActionCreators(changeGroceryListViewType, dispatch),
  clearAllGroceryListItems: bindActionCreators(clearAllGroceryListItems, dispatch),
  addIngredientInGroceryListAisle: bindActionCreators(addIngredientInGroceryListAisle, dispatch),
  deleteIngredientInGroceryList: bindActionCreators(deleteIngredientInGroceryList, dispatch),
  addIngredientInGroceryListRecipe: bindActionCreators(addIngredientInGroceryListRecipe, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(GroceryList));




