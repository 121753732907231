import { ADD_MEAL_TO_MEAL_PLAN } from './actionTypes';

export default ( objectId, date, meal, objectType = 'recipe') => ({
  type: ADD_MEAL_TO_MEAL_PLAN,
  payload:{
    objectId,
    date,
    meal,
    objectType
  },
})