import React from 'react'

// component 
const GhostCover = ({
  children,
  ...props
}) => {
  return (
    <figure className="ghostCover-figure" {...props}>
      {children}
    </figure>
  )
}

export default GhostCover;