import { put, takeEvery, call, select } from 'redux-saga/effects'
import axios from 'axios'
import capitalize from 'lodash/capitalize'
import * as actions from '../actions/actionTypes';
import { showToastMessage } from '../actions'
import mealPalnTask from './subtask/mealplan';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  let objectData;
  try {
    if (action.payload.objectType === 'recipe') {
      objectData = {
        recipe_id: action.payload.objectId,
        date: action.payload.date,
        meal: action.payload.meal
      }
    } else {
      objectData = {
        workout_id: action.payload.objectId,
        date: action.payload.date,
        meal: action.payload.meal
      }
    }

    const { data } = yield call(mealPalnTask.post, objectData);

    yield put(showToastMessage(`👍🏼 ${capitalize(action.payload.objectType)} has been added to meal plan.`, 'success', 'addMealPlan'));

    yield put({
      type: actions.ADD_MEAL_TO_MEAL_PLAN_SUCCESS,
      payload: {
        success: true,
        mealData: data
      }
    })

  } catch (e) {

    yield put(showToastMessage(`❗️Adding new item failed`, 'error'));

    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_MEAL_TO_MEAL_PLAN_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addMealInMealPlan() {
  yield takeEvery(actions.ADD_MEAL_TO_MEAL_PLAN, task)
}