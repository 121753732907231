import React from "react";
import '../styles/common.scss';

const AvartarIcon = () => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19" stroke="#EEEEEE" stroke-width="2" />
      <g opacity="0.4">
        <path d="M20 8C13.3726 8 8 13.3723 8 20C8 26.6272 13.3726 32 20 32C26.6274 32 32 26.6272 32 20C32.0003 13.3723 26.6277 8 20 8ZM25.918 26.8159H22.9283C22.42 26.8159 22.0079 26.4008 22.0079 25.8886V25.4206C21.4019 26.2415 20.4036 27.1453 18.821 27.1453C16.4493 27.1453 14.082 25.2291 14.082 21.7346C14.082 17.8991 16.7741 15.7511 19.3029 15.7511C20.6287 15.7511 21.5082 16.3497 22.0079 17.1088V13.9898C22.0079 13.4803 21.7439 13.2035 21.2093 13.2035V12.0272L24.1292 11.7144C24.6583 11.6576 25.1195 12.0755 25.1195 12.6117V24.853C25.1195 25.3628 25.3835 25.6393 25.918 25.6393V26.8159Z" fill="#888888" />
        <path d="M22.0076 20.1486L22.0002 20.027C22.0002 18.4675 21.1632 17.4104 20.1991 17.4104C18.9816 17.4104 17.7928 18.83 17.7928 21.7774C17.7928 23.7424 18.5859 25.0216 19.7851 25.0216C20.8989 25.0216 21.5925 24.289 22.0081 23.5788L22.0076 20.1486Z" fill="#888888" />
      </g>
    </svg>
  );
};
export default AvartarIcon;