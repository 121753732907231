import axios from 'axios'
import { map } from 'lodash';
import { get, post } from '../../helpers/requests'

import { videos, favourites } from '../../helpers/api'

import { call, select, put } from 'redux-saga/effects'

import {
  getToken,
  getAppliedVideoFilters,
  getAppliedVideoFavorites,
  getVideoDirtyGroup,
  getVideoCategories,
  getVideoBoards,
  getUnFilteredVideoList,
  getCachedVideoList,
  getVideoFiltersSetUpdatedPrematurelyStatus,
  getVideoCollections
} from '../selectors'
import * as actionTypes from '../../actions/actionTypes';
import { generateVideoQueryFromFilter } from '../../helpers/filterConversions';

const getUnfilteredVideosData = function* getUnfilteredData(video) {

  const token = yield select(getToken)
  // Don't fetch unfiltered video list if already fetched
  const unfilteredVideoList = yield select(getUnFilteredVideoList);
  let unfilteredVideosResponse;

  let unfilteredVideosData;

  if (unfilteredVideoList.isEmpty() || video.videoRefreshGroup === 'unfiltered') {
    yield put({
      type: actionTypes.VIDEOS_LIST_FETCH_REQUEST,
      payload: {
        listType: 'unfiltered',
        objectType: 'video',
        pullToRefresh: video.videoRefreshGroup === 'unfiltered'
      }
    });
    unfilteredVideosResponse = yield call(axios, get(
      videos.getAll(video.limit, video.offset, ''),
      '',
      token
    ));
    yield put({
      type: actionTypes.VIDEOS_LIST_FETCH_SUCCESS,
      payload: {
        listType: 'unfiltered',
        objectType: 'video',
        pullToRefresh: video.videoRefreshGroup === 'unfiltered'
      }
    });

    unfilteredVideosData = unfilteredVideosResponse ? unfilteredVideosResponse.data.videos : [];
  } else {
    unfilteredVideosData = null; // In redux it will be in immutable format
  }

  return {
    unfilteredVideosData,
  }
};

export default {
  // Gets all videos as per existing filters / search / favorites
  getAll: function* getAllVideos(video) {
    const appliedVideoFilters = yield select(getAppliedVideoFilters);
    const videoCategories = yield select(getVideoCategories);
    const videoCollections = yield select(getVideoCollections);

    const filtersSetUpdatedPrematurely = yield select(getVideoFiltersSetUpdatedPrematurelyStatus);

    // Get the token
    const token = yield select(getToken);
    const dirtyGroup = yield select(getVideoDirtyGroup);

    // These will be toggled in each cases
    let filteredVideosResponse;
    let unfilteredVideosResponse;
    if (dirtyGroup === 'filters' || video.videoRefreshGroup === 'filters' || filtersSetUpdatedPrematurely) {
      // Dispatching to Set Particular Fetch Status
      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'video'
        }
      });

      // If to be fetched is filters
      filteredVideosResponse = yield call(axios, get(
        videos.getAll(video.limit, video.offset, generateVideoQueryFromFilter(
          appliedVideoFilters,
          videoCategories,
          videoCollections,
        )), '', token)
      );

      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'video'
        }
      });

      const videosResponse = yield call(getUnfilteredVideosData, video);

      // This will fetch new set of data for the newly applied filter
      return {
        filteredVideosData: filteredVideosResponse && filteredVideosResponse.data.videos ? filteredVideosResponse.data.videos : null,
        unfilteredVideosData: videosResponse.unfilteredVideosData,
      }
    } else if (dirtyGroup === 'favorites' || video.videoRefreshGroup === 'favorites') {

      // These will be toggled in each cases
      let filteredVideosResponse;
      let unfilteredVideosResponse;

      // Get the favorite board id
      let appliedVideoFavorites = yield select(getAppliedVideoFavorites);
      appliedVideoFavorites = appliedVideoFavorites.toJS();
      let favoriteBoardSlug = appliedVideoFavorites.board;
      let favoriteBoardId;
      if (favoriteBoardSlug) {
        const allBoard = yield select(getVideoBoards);
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }

      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorited',
          objectType: 'video'
        }
      });
      // Favorites API fetch
      filteredVideosResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'video',
          favoriteBoardId,
          video.limit,
          video.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorited',
          objectType: 'video'
        }
      });
      const formattedVideosData = filteredVideosResponse.data.items.map(item => ({
        ...item,
        ...item.video,
      }));

      const videosResponse = yield call(getUnfilteredVideosData, video);

      return {
        filteredVideosData: filteredVideosResponse && filteredVideosResponse.data.items ? formattedVideosData : null,
        unfilteredVideosData: videosResponse.unfilteredVideosData,
      }
    } else {
      const filteredVideosData = yield select(getCachedVideoList)
      const videosResponse = yield call(getUnfilteredVideosData, video);
      // Caching for clear list
      return {
        filteredVideosData: filteredVideosData.toJS(),
        unfilteredVideosData: videosResponse.unfilteredVideosData,
      }
    }
  },
  // Gets more videos as per existing filters / search / favorites
  getMore: function* getMoreVideos(video) {


    const token = yield select(getToken);
    let offset = video.offset * video.limit;

    const appliedVideoFilters = yield select(getAppliedVideoFilters);

    const videoCategories = yield select(getVideoCategories);
    const videoCollections = yield select(getVideoCollections);

    let appliedVideoFavorites = yield select(getAppliedVideoFavorites);

    appliedVideoFavorites = appliedVideoFavorites.toJS();
    const favoriteBoardSlug = appliedVideoFavorites.board;

    let filteredVideosResponse;
    let unfilteredVideosResponse;

    if (video.filterGroup == 'filters') {

      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'video',
          pagination: true
        }
      });

      filteredVideosResponse = yield call(axios, get(
        videos.getAll(video.limit, offset, generateVideoQueryFromFilter(
          appliedVideoFilters,
          videoCategories,
          videoCollections,
        )), '', token)
      );
      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'video',
          pagination: true
        }
      });
      return {
        filteredVideosData: filteredVideosResponse && filteredVideosResponse.data.videos.length > 0 ? filteredVideosResponse.data.videos : [],
        filteredVideosCount: filteredVideosResponse && filteredVideosResponse.data.videos.length > 0 ? filteredVideosResponse.data.videos.count : 0,
      }
    } else if (video.filterGroup == 'favorites') {

      // Slug to id conversions
      let appliedVideoFavorites = yield select(getAppliedVideoFavorites);
      appliedVideoFavorites = appliedVideoFavorites.toJS();
      let favoriteBoardSlug = appliedVideoFavorites.board;
      let favoriteBoardId;

      const allBoard = yield select(getVideoBoards);
      if (favoriteBoardSlug) {
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }

      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorited',
          objectType: 'video',
          pagination: true
        }
      });
      // Favorites API fetch
      filteredVideosResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'video',
          favoriteBoardId, // TODO: Board should be converted from slug to id
          video.limit,
          video.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorited',
          objectType: 'video',
          pagination: true
        }
      });
      // Apply both since recipe is nested
      const formattedVideosData = filteredVideosResponse.data.items.map(item => ({
        ...item,
        ...item.video,
      }));

      return {
        filteredVideosData: filteredVideosResponse && filteredVideosResponse.data.items ? formattedVideosData : null,
        unfilteredVideosData: null,
        filteredVideosCount: filteredVideosResponse && filteredVideosResponse.data.items ? filteredVideosResponse.data.count : 0,
        unfilteredVideosCount: 0
      }
    } else {
      // No filters or favorites
      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'unfiltered',
          objectType: 'video',
          pagination: true
        }
      });

      unfilteredVideosResponse = yield call(axios, get(
        videos.getAll(video.limit, offset),
        '',
        token,
      ));

      yield put({
        type: actionTypes.VIDEOS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'unfiltered',
          objectType: 'video',
          pagination: true,
        }
      });
      return {
        filteredVideosData: null,
        unfilteredVideosData: unfilteredVideosResponse && unfilteredVideosResponse.data.videos ? unfilteredVideosResponse.data.videos : null,
        filteredVideosCount: 0,
        unfilteredVideosCount: unfilteredVideosResponse && unfilteredVideosResponse.data.videos.length > 0 ? unfilteredVideosResponse.data.count : 0
      };
    }
  },
}