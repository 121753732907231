export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const USER_AUTHENTICATE = "USER_AUTHENTICATE";
export const USER_AUTHENTICATE_SUCCESS = "USER_AUTHENTICATE_SUCCESS";
export const USER_AUTHENTICATE_FAILURE = "USER_AUTHENTICATE_FAILURE";

// TODO: Change this to APP_ESSENTIAL_FETCH
export const APP_ESSENTIAL_FETCH = "APP_ESSENTIAL_FETCH";
export const APP_ESSENTIAL_FETCH_SUCCESS = "APP_ESSENTIAL_FETCH_SUCCESS";
export const APP_ESSENTIAL_FETCH_FAILURE = "APP_ESSENTIAL_FETCH_FAILURE";

export const USER_ESSENTIALS_FETCH = "USER_ESSENTIALS_FETCH";
export const USER_ESSENTIALS_FETCH_SUCCESS = "USER_ESSENTIALS_FETCH_SUCCESS";
export const USER_ESSENTIALS_FETCH_CACHED = "USER_ESSENTIALS_FETCH_CACHED";
export const USER_ESSENTIALS_FETCH_FAILURE = "USER_ESSENTIALS_FETCH_FAILURE";
export const USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH =
  "USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH";
export const USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH_SUCCESS =
  "USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH_SUCCESS";

// TODO: Change this to GET_ALL_RECIPES
export const GET_ALL_RECIPES = "GET_ALL_RECIPES";
export const GET_ALL_RECIPES_SUCCESS = "GET_ALL_RECIPES_SUCCESS";
export const GET_ALL_RECIPES_FAILURE = "GET_ALL_RECIPES_FAILURE";
export const GET_ALL_RECIPES_CLEAN_CACHE = "GET_ALL_RECIPES_CLEAN_CACHE";

// get all recipes from a selected recipe collection
export const GET_ALL_RECIPES_FROM_SELECTED_COLLECTION =
  "GET_ALL_RECIPES_FROM_SELECTED_COLLECTION";
export const GET_ALL_RECIPES_FROM_SELECTED_COLLECTION_SUCCESS =
  "GET_ALL_RECIPES_FROM_SELECTED_COLLECTION_SUCCESS";
export const GET_ALL_RECIPES_FROM_SELECTED_COLLECTION_FAILURE =
  "GET_ALL_RECIPES_FROM_SELECTED_COLLECTION_FAILURE";

// filter the recipes based on selected category
export const FILTER_RECIPES_BY_CATEGORY = "FILTER_RECIPES_BY_CATEGORY";
export const FILTER_RECIPES_BY_CATEGORY_SUCCESS =
  "FILTER_RECIPES_BY_CATEGORY_SUCCESS";
export const FILTER_RECIPES_BY_CATEGORY_FAILURE =
  "FILTER_RECIPES_BY_CATEGORY_FAILURE";

// action types for GET_ALL_WORKOUTS
export const GET_ALL_WORKOUTS = "GET_ALL_WORKOUTS";
export const GET_ALL_WORKOUTS_SUCCESS = "GET_ALL_WORKOUTS_SUCCESS";
export const GET_ALL_WORKOUTS_FAILURE = "GET_ALL_WORKOUTS_FAILURE";

// get all workouts from a selected recipe collection
export const GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION =
  "GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION";
export const GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION_SUCCESS =
  "GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION_SUCCESS";
export const GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION_FAILURE =
  "GET_ALL_WORKOUTS_FROM_SELECTED_COLLECTION_FAILURE";

// TODO: Change this to GET_SINGLE_RECIPE_DETAILS
export const GET_SINGLE_RECIPE_DETAILS = "GET_SINGLE_RECIPE_DETAILS";
export const GET_SINGLE_RECIPE_META_SUCCESS = "GET_SINGLE_RECIPE_META_SUCCESS";
export const GET_SINGLE_RECIPE_DETAILS_SUCCESS =
  "GET_SINGLE_RECIPE_DETAILS_SUCCESS";
export const GET_SINGLE_RECIPE_DETAILS_FAILURE =
  "GET_SINGLE_RECIPE_DETAILS_FAILURE";

// GET_SINGLE_WORKOUT_DETAILS
export const GET_SINGLE_WORKOUT_DETAILS_SUCCESS =
  "GET_SINGLE_WORKOUT_DETAILS_SUCCESS";
export const GET_SINGLE_WORKOUT_META_SUCCESS =
  "GET_SINGLE_WORKOUT_META_SUCCESS";
export const GET_SINGLE_WORKOUT_DETAILS_FAILURE =
  "GET_SINGLE_WORKOUT_DETAILS_FAILURE";

// TODO: Change this to ALTER_VARIATION_FOR_ACTIVE_RECIPE
export const ALTER_VARIATION_FOR_ACTIVE_RECIPE =
  "ALTER_VARIATION_FOR_ACTIVE_RECIPE";
export const ALTER_VARIATION_FOR_ACTIVE_RECIPE_SUCCESS =
  "ALTER_VARIATION_FOR_ACTIVE_RECIPE_SUCCESS";
export const ALTER_VARIATION_FOR_ACTIVE_RECIPE_FAILURE =
  "ALTER_VARIATION_FOR_ACTIVE_RECIPE_FAILURE";

// TODO: Change this to ADD_INGREDIENTS_TO_GROCERY_WAITLIST
export const ADD_INGREDIENTS_TO_GROCERY_WAITLIST =
  "ADD_INGREDIENTS_TO_GROCERY_WAITLIST";
export const ADD_INGREDIENTS_TO_GROCERY_WAITLIST_SUCCESS =
  "ADD_INGREDIENTS_TO_GROCERY_WAITLIST_SUCCESS";
export const ADD_INGREDIENTS_TO_GROCERY_WAITLIST_FAILURE =
  "ADD_INGREDIENTS_TO_GROCERY_WAITLIST_FAILURE";

// TODO: Change this to REMOVE_INGREDIENTS_TO_GROCERY_WAITLIST
export const REMOVE_INGREDIENTS_TO_GROCERY_WAITLIST =
  "REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST";
export const REMOVE_INGREDIENTS_TO_GROCERY_WAITLIST_SUCCESS =
  "REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST_SUCCESS";
export const REMOVE_INGREDIENTS_TO_GROCERY_WAITLIST_FAILURE =
  "REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST_FAILURE";

// TODO: Change this to DELETE_INGREDIENTS_FROM_GROCERY_WAITLIST
export const DELETE_INGREDIENTS_FROM_GROCERY_WAITLIST =
  "DELETE_INGREDIENTS_FROM_GROCERY_WAITLIST"; // SAGA not required

// TODO: Change this to UPDATE_GROCERY_LIST_FROM_GROCERY_WAITLIST
export const UPDATE_GROCERY_LIST_FROM_GROCERY_WAITLIST =
  "UPDATE_GROCERY_LIST_FROM_GROCERY_WAITLIST";
export const UPDATE_GROCERY_LIST_FROM_GROCERY_WAITLIST_SUCCESS =
  "UPDATE_GROCERY_LIST_FROM_GROCERY_WAITLIST_SUCCESS";
export const UPDATE_GROCERY_LIST_FROM_GROCERY_WAITLIST_FAILURE =
  "UPDATE_GROCERY_LIST_FROM_GROCERY_WAITLIST_FAILURE";

// TODO: Change items to ingredients
export const REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST =
  "REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST";
export const REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST_SUCCESS =
  "REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST_SUCCESS";
export const REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST_FAILURE =
  "REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST_FAILURE";

// _APP_STARTUP
export const APP_STARTUP = "APP_STARTUP";
export const APP_STARTUP_SUCCESS = "APP_STARTUP_SUCCESS";
export const APP_STARTUP_FAILURE = "APP_STARTUP_FAILURE";

// ADD_ALL_INGREDIENTS_TO_GROCERYLIST
export const ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST =
  "ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST";
export const ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST_SUCCESS =
  "ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST_SUCCESS";
export const ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST_FAILURE =
  "ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST_FAILURE";

export const ADD_ALL_TO_GROCERYLIST = "ADD_ALL_TO_GROCERYLIST";
export const ADD_ALL_TO_GROCERYLIST_SUCCESS = "ADD_ALL_TO_GROCERYLIST_SUCCESS";
export const ADD_ALL_TO_GROCERYLIST_FAILURE = "ADD_ALL_TO_GROCERYLIST_FAILURE";

// CREATE_COMMENT_TO_OBJECT
export const CREATE_COMMENT_TO_OBJECT = "CREATE_COMMENT_TO_OBJECT";
export const CREATE_COMMENT_TO_OBJECT_SUCCESS =
  "CREATE_COMMENT_TO_OBJECT_SUCCESS";
export const CREATE_COMMENT_TO_OBJECT_FAILURE =
  "CREATE_COMMENT_TO_OBJECT_FAILURE";

// CREATE_NOTE_TO_OBJECT
export const CREATE_NOTE_TO_OBJECT = "CREATE_NOTE_TO_OBJECT";
export const CREATE_NOTE_TO_OBJECT_SUCCESS = "CREATE_NOTE_TO_OBJECT_SUCCESS";
export const CREATE_NOTE_TO_OBJECT_FAILURE = "CREATE_NOTE_TO_OBJECT_FAILURE";

// SET to UPDATE
export const UPDATE_CURRENT_SERVING = "UPDATE_CURRENT_SERVING";
export const UPDATE_CURRENT_SERVING_SUCCESS = "UPDATE_CURRENT_SERVING_SUCCESS";
export const UPDATE_CURRENT_SERVING_FAILURE = "UPDATE_CURRENT_SERVING_FAILURE";

// CREATE_FAVOURITE_TO_OBJECT_TO_OBJECT
export const CREATE_FAVOURITE_TO_OBJECT = "CREATE_FAVOURITE_TO_OBJECT";
export const CREATE_FAVOURITE_TO_OBJECT_SUCCESS =
  "CREATE_FAVOURITE_TO_OBJECT_SUCCESS";
export const CREATE_FAVOURITE_TO_OBJECT_FAILURE =
  "CREATE_FAVOURITE_TO_OBJECT_FAILURE";

// CREATE_FAVORITE_BOARD
export const SET_OBJECT_BOARDS = "SET_OBJECT_BOARDS";
export const CREATE_FAVORITE_BOARD = "CREATE_FAVORITE_BOARD";
export const CREATE_FAVORITE_BOARD_SUCCESS = "CREATE_FAVORITE_BOARD_SUCCESS";
export const CREATE_FAVORITE_BOARD_FAILURE = "CREATE_FAVORITE_BOARD_FAILURE";

// clear Board selection
export const CLEAR_BOARD_SELECTION = "CLEAR_BOARD_SELECTION";

// TODO: Newly addded from rebase
export const DELETE_FAVOURITE_BOARD = "DELETE_FAVOURITE_BOARD";
export const DELETE_FAVOURITE_BOARD_SUCCESS = "DELETE_FAVOURITE_BOARD_SUCCESS";
export const DELETE_FAVOURITE_BOARD_FAILURE = "DELETE_FAVOURITE_BOARD_FAILURE";
export const UPDATE_FAVOURITE_BOARD = "UPDATE_FAVOURITE_BOARD";
export const UPDATE_FAVOURITE_BOARD_SUCCESS = "UPDATE_FAVOURITE_BOARD_SUCCESS";
export const UPDATE_FAVOURITE_BOARD_FAILURE = "UPDATE_FAVOURITE_BOARD_FAILURE";

// TODO: All actions should include flow types

export const DELETE_FAVOURITE = "DELETE_FAVOURITE";
export const DELETE_FAVOURITE_SUCCESS = "DELETE_FAVOURITE_SUCCESS";
export const DELETE_FAVOURITE_FAILURE = "DELETE_FAVOURITE_FAILURE";

export const SEND_REPLY_COMMENT_TO_OBJECT = "SEND_REPLY_COMMENT_TO_OBJECT";
export const SEND_REPLY_COMMENT_TO_OBJECT_SUCCESS =
  "SEND_REPLY_COMMENT_TO_OBJECT_SUCCESS";
export const SEND_REPLY_COMMENT_TO_OBJECT_FAILURE =
  "SEND_REPLY_COMMENT_TO_OBJECT_FAILURE";

export const CREATE_NEW_USER = "CREATE_NEW_USER";
export const CREATE_NEW_USER_SUCCESS = "CREATE_NEW_USER_SUCCESS";
export const CREATE_NEW_USER_FAILURE = "CREATE_NEW_USER_FAILURE";
// TODO: All actions should include flow types
export const GET_USER_DETAILS = "GET_USER_DETAILS";

export const GET_OBJECT_SPECIFIC_ESSENTIAL = "GET_OBJECT_SPECIFIC_ESSENTIAL";
export const GET_OBJECT_SPECIFIC_ESSENTIAL_SUCCESS =
  "GET_OBJECT_SPECIFIC_ESSENTIAL_SUCCESS";
export const GET_OBJECT_SPECIFIC_ESSENTIAL_FAILURE =
  "GET_OBJECT_SPECIFIC_ESSENTIAL_FAILURE";

// Grocery list actions
export const SELECT_INGREDIENT_IN_GROCERY_LIST =
  "SELECT_INGREDIENT_IN_GROCERY_LIST";
export const UNSELECT_INGREDIENT_IN_GROCERY_LIST =
  "UNSELECT_INGREDIENT_IN_GROCERY_LIST";

// move ingredients between catogories in grocery list
export const MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST =
  "MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST";
export const MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST_SUCCESS =
  "MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST_SUCCESS";
export const MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST_FAILURE =
  "MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST_FAILURE";

export const CHANGE_GROCERY_LIST_VIEW_TYPE = "CHANGE_GROCERY_LIST_VIEW_TYPE";

export const CLEAR_ALL_GROCERY_LIST_ITEMS = "CLEAR_ALL_GROCERY_LIST_ITEMS";
export const CLEAR_ALL_GROCERY_LIST_ITEMS_SUCCESS =
  "CLEAR_ALL_GROCERY_LIST_ITEMS_SUCCESS";
export const CLEAR_ALL_GROCERY_LIST_ITEMS_FAILURE =
  "CLEAR_ALL_GROCERY_LIST_ITEMS_FAILURE";

export const ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE =
  "ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE";
export const ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE_SUCCESS =
  "ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE_SUCCESS";
export const ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE_FAILURE =
  "ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE_FAILURE";

export const DELETE_INGREDIENT_IN_GROCERY_LIST =
  "DELETE_INGREDIENT_IN_GROCERY_LIST";
export const DELETE_INGREDIENT_IN_GROCERY_LIST_SUCCESS =
  "DELETE_INGREDIENT_IN_GROCERY_LIST_SUCCESS";
export const DELETE_INGREDIENT_IN_GROCERY_LIST_FAILURE =
  "DELETE_INGREDIENT_IN_GROCERY_LIST_FAILURE";

// add for groceryList recipe
export const ADD_INGREDIENTS_IN_GROCERY_LIST_RECIPE =
  "ADD_INGREDIENTS_IN_GROCERY_LIST_RECIPE";
export const ADD_INGREDIENTS_IN_GROCERY_LIST_RECIPE_SUCCESS =
  "ADD_INGREDIENTS_IN_GROCERY_LIST_RECIPE_SUCCESS";
export const ADD_INGREDIENTS_IN_GROCERY_LIST_RECIPE_FAILURE =
  "ADD_INGREDIENTS_IN_GROCERY_LIST_RECIPE_FAILURE";

// for logout navbar click
export const LOGOUT_NAVBAR = "LOGOUT_NAVBAR";
export const LOGOUT_NAVBAR_SUCCESS = "LOGOUT_NAVBAR_SUCCESS";
export const LOGOUT_NAVBAR_FAILURE = "LOGOUT_NAVBAR_FAILURE";

// Calendar actions
export const CHANGE_CALENDAR_ACTIVE_MONTH = "CHANGE_CALENDAR_ACTIVE_MONTH";
export const CHANGE_CALENDAR_ACTIVE_DATE = "CHANGE_CALENDAR_ACTIVE_DATE";
export const ADD_MEAL_TO_MEAL_PLAN = "ADD_MEAL_TO_MEAL_PLAN";
export const ADD_MEAL_TO_MEAL_PLAN_SUCCESS = "ADD_MEAL_TO_MEAL_PLAN_SUCCESS";
export const ADD_MEAL_TO_MEAL_PLAN_FAILURE = "ADD_MEAL_TO_MEAL_PLAN_FAILURE";
export const DELETE_MEAL_FROM_MEALPLAN = "DELETE_MEAL_FROM_MEALPLAN";
export const DELETE_MEAL_FROM_MEALPLAN_SUCCESS =
  "DELETE_MEAL_FROM_MEALPLAN_SUCCESS";
export const DELETE_MEAL_FROM_MEALPLAN_FAILURE =
  "DELETE_MEAL_FROM_MEALPLAN_FAILURE";
export const MOVE_MEAL_IN_MEALPLAN = "MOVE_MEAL_IN_MEALPLAN";
export const MOVE_MEAL_IN_MEALPLAN_SUCCESS = "MOVE_MEAL_IN_MEALPLAN_SUCCESS";
export const MOVE_MEAL_IN_MEALPLAN_FAILURE = "MOVE_MEAL_IN_MEALPLAN_FAILURE";

// For filters
export const APPLIED_SINGLE_FILTER = "APPLIED_SINGLE_FILTER";
export const FETCH_FILTERED_DATA = "FETCH_FILTERED_DATA";
export const FETCH_FILTERED_DATA_WORKOUTS = "FETCH_FILTERED_DATA_WORKOUTS";

export const FETCH_FILTERED_DATA_SUCCESS = "FETCH_FILTERED_DATA_SUCCESS";
export const FETCH_FILTERED_DATA_FAILURE = "FETCH_FILTERED_DATA_FAILURE";

export const GET_FAVORITES_FILTERED_DATA = "GET_FAVORITES_FILTERED_DATA";
export const GET_FAVORITES_FILTERED_DATA_SUCCESS =
  "GET_FAVORITES_FILTERED_DATA_SUCCESS";
export const GET_FAVORITES_FILTERED_DATA_FAILURE =
  "GET_FAVORITES_FILTERED_DATA_FAILURE";

export const APPLIED_SINGLE_FILTER_WORKOUTS = "APPLIED_SINGLE_FILTER_WORKOUTS";

export const CLEAR_ALL_FILTERS = "CLEAR_ALL_FILTERS";

export const SEARCH_FILTER_OUTSIDE_CLICK = "SEARCH_FILTER_OUTSIDE_CLICK";
export const SEARCH_FILTER_OUTSIDE_CLICK_SUCCESS =
  "SEARCH_FILTER_OUTSIDE_CLICK_SUCCESS";
export const SEARCH_FILTER_OUTSIDE_CLICK_FAILURE =
  "SEARCH_FILTER_OUTSIDE_CLICK_FAILURE";

export const SEARCH_FILTER_SHOW_ALL_ITEMS_CLICK =
  "SEARCH_FILTER_SHOW_ALL_ITEMS_CLICK";
export const SEARCH_FILTER_SHOW_ALL_ITEMS_CLICK_SUCCESS =
  "SEARCH_FILTER_SHOW_ALL_ITEMS_CLICK_SUCCESS";
export const SEARCH_FILTER_SHOW_ALL_ITEMS_CLICK_FAILURE =
  "SEARCH_FILTER_SHOW_ALL_ITEMS_CLICK_FAILURE";

// for object stack
export const PUSH_OBJECT_TO_NAVIGATION_STACK =
  "PUSH_OBJECT_TO_NAVIGATION_STACK";
export const CLEAR_NAVIGATION_STACK = "CLEAR_NAVIGATION_STACK";
export const CLEAR_NAVIGATION_STACK_SUCCESS = "CLEAR_NAVIGATION_STACK_SUCCESS";
export const POP_OBJECT_FROM_NAVIGATION_STACK =
  "POP_OBJECT_FROM_NAVIGATION_STACK";
export const POP_OBJECT_FROM_NAVIGATION_STACK_SUCCESS =
  "POP_OBJECT_FROM_NAVIGATION_STACK_SUCCESS";
export const POP_OBJECT_FROM_NAVIGATION_STACK_FAILURE =
  "POP_OBJECT_FROM_NAVIGATION_STACK_FAILURE";

// New stack
export const PUSH_OBJECT_TO_STACK = "PUSH_OBJECT_TO_STACK";
export const UPDATE_OBJECT_TO_STACK = "UPDATE_OBJECT_TO_STACK";
export const UPDATE_OBJECT_TO_STACK_FAILURE = "UPDATE_OBJECT_TO_STACK_FAILURE";

export const POP_OBJECT_FROM_STACK = "POP_OBJECT_FROM_STACK";
export const CLEAR_OBJECT_STACK = "CLEAR_OBJECT_STACK";

//for search
export const CHANGE_SEARCH_BY = "CHANGE_SEARCH_BY";

export const CLEAR_SEARCHTEXT_AND_SEARCHLIST =
  "CLEAR_SEARCHTEXT_AND_SEARCHLIST";

// NETWORK LAYER RE_WRITE NEW ACTIONS

export const RECIPE_PRE_FETCH_ESSENTIALS_REQUEST =
  "RECIPE_PRE_FETCH_ESSENTIALS_REQUEST";
export const WORKOUT_PRE_FETCH_ESSENTIALS_REQUEST =
  "WORKOUT_PRE_FETCH_ESSENTIALS_REQUEST";

export const RECIPE_PRE_FETCH_ESSENTIALS_SUCCESS =
  "RECIPE_PRE_FETCH_ESSENTIALS_SUCCESS";
export const WORKOUT_PRE_FETCH_ESSENTIALS_SUCCESS =
  "WORKOUT_PRE_FETCH_ESSENTIALS_SUCCESS";

export const OBJECT_PRE_FETCH_ESSENTIALS_FAILURE =
  "OBJECT_PRE_FETCH_ESSENTIALS_FAILURE";

export const OBJECT_PRE_FETCH_ESSENTIALS_REQUEST =
  "OBJECT_PRE_FETCH_ESSENTIALS_REQUEST";
export const OBJECT_PRE_FETCH_ESSENTIALS_SUCCESS =
  "OBJECT_PRE_FETCH_ESSENTIALS_SUCCESS";

// ACTIONS OF LIST ITEMS FETCHS

export const RECIPES_LIST_FETCH_REQUEST = "RECIPES_LIST_FETCH_REQUEST";
export const RECIPES_LIST_FETCH_SUCCESS = "RECIPES_LIST_FETCH_SUCCESS";
export const RECIPES_LIST_FETCH_FAILURE = "RECIPES_LIST_FETCH_FAILURE";

export const WORKOUTS_LIST_FETCH_REQUEST = "WORKOUTS_LIST_FETCH_REQUEST";
export const WORKOUTS_LIST_FETCH_SUCCESS = "WORKOUTS_LIST_FETCH_SUCCESS";

export const GET_PAGINATION_RECIPE = "GET_PAGINATION_RECIPE";

// SET FILTERS PROCESS LEVEL

export const SET_FILTERS_PROCESS = "SET_FILTERS_PROCESS";
export const SET_FILTERS_PROCESS_SUCCESS = "SET_FILTERS_PROCESS_SUCCESS";
export const SET_FILTERS_PROCESS_FAILURE = "SET_FILTERS_PROCESS_FAILURE";
export const SET_NEW_FILTERS_SUCCESS = "SET_NEW_FILTERS_SUCCESS";
export const SET_NEW_FILTERS_FAILURE = "SET_NEW_FILTERS_FAILURE";

// SEPARATE ACTION TO HIT DIRECTLY FROM MOBILE
export const SET_FILTERS_REQUEST = "SET_FILTERS_REQUEST";
// SUCCESS AND FAILURE WILL BE BASED ON PROCESS LEVEL ACTIONS

// LIST ITEMS FETCH
export const ESSENTIALS_LIST_ITEMS_FETCH = "ESSENTIALS_LIST_ITEMS_FETCH";
export const ESSENTIALS_LIST_ITEMS_FETCH_SUCCESS =
  "ESSENTIALS_LIST_ITEMS_FETCH_SUCCESS";
export const ESSENTIALS_LIST_ITEMS_FETCH_FAILURE =
  "ESSENTIALS_LIST_ITEMS_FETCH_FAILURE";

// search
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SEARCH_REQUEST_SUCCESS = "SEARCH_REQUEST_SUCCESS";
export const SEARCH_REQUEST_FAILURE = "SEARCH_REQUEST_FAILURE";

// get count for recipe filters
export const RECIPE_FILTER_COUNT = "RECIPE_FILTER_COUNT";
export const RECIPE_FILTER_COUNT_SUCCESS = "RECIPE_FILTER_COUNT_SUCCESS";
export const RECIPE_FILTER_COUNT_FAILURE = "RECIPE_FILTER_COUNT_FAILURE";

// change tab bar
export const CHANGE_TABBAR_DYNAMIC_OPTION = "CHANGE_TABBAR_DYNAMIC_OPTION";
export const CHANGE_TABBAR_DYNAMIC_OPTION_SUCCESS =
  "CHANGE_TABBAR_DYNAMIC_OPTION_SUCCESS";
export const CHANGE_TABBAR_DYNAMIC_OPTION_FAILURE =
  "CHANGE_TABBAR_DYNAMIC_OPTION_FAILURE";

// Tab bar open close for outside click
export const TOGGLE_TABBAR_DYNAMIC_OPTION = "TOGGLE_TABBAR_DYNAMIC_OPTION";

// Toast message
export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";
export const REMOVE_TOAST_MESSAGE = "REMOVE_TOAST_MESSAGE";

// get count for workout filters
export const WORKOUT_FILTER_COUNT = "WORKOUT_FILTER_COUNT";
export const WORKOUT_FILTER_COUNT_SUCCESS = "WORKOUT_FILTER_COUNT_SUCCESS";
export const WORKOUT_FILTER_COUNT_FAILURE = "WORKOUT_FILTER_COUNT_FAILURE";

// Blog post
export const BLOG_PRE_FETCH_ESSENTIALS_REQUEST =
  "BLOG_PRE_FETCH_ESSENTIALS_REQUEST";
export const BLOG_PRE_FETCH_ESSENTIALS_SUCCESS =
  "BLOG_PRE_FETCH_ESSENTIALS_SUCCESS";

export const BLOGS_LIST_FETCH_REQUEST = "BLOGS_LIST_FETCH_REQUEST";
export const BLOGS_LIST_FETCH_SUCCESS = "BLOGS_LIST_FETCH_SUCCESS";
export const BLOGS_LIST_FETCH_FAILURE = "BLOGS_LIST_FETCH_FAILURE";

export const GET_SINGLE_BLOG_DETAILS_REQUEST =
  "GET_SINGLE_BLOG_DETAILS_REQUEST";
export const GET_SINGLE_BLOG_DETAILS_SUCCESS =
  "GET_SINGLE_BLOG_DETAILS_SUCCESS";
export const GET_SINGLE_BLOG_DETAILS_FAILURE =
  "GET_SINGLE_BLOG_DETAILS_FAILURE";

export const GET_ALL_BLOGS = "GET_ALL_BLOGS";

// Videos
export const VIDEO_PRE_FETCH_ESSENTIALS_REQUEST =
  "VIDEO_PRE_FETCH_ESSENTIALS_REQUEST";
export const VIDEO_PRE_FETCH_ESSENTIALS_SUCCESS =
  "VIDEO_PRE_FETCH_ESSENTIALS_SUCCESS";

export const VIDEOS_LIST_FETCH_REQUEST = "VIDEOS_LIST_FETCH_REQUEST";
export const VIDEOS_LIST_FETCH_SUCCESS = "VIDEOS_LIST_FETCH_SUCCESS";
export const VIDEOS_LIST_FETCH_FAILURE = "VIDEOS_LIST_FETCH_FAILURE";

export const GET_SINGLE_VIDEO_DETAILS_REQUEST =
  "GET_SINGLE_VIDEO_DETAILS_REQUEST";
export const GET_SINGLE_VIDEO_DETAILS_SUCCESS =
  "GET_SINGLE_VIDEO_DETAILS_SUCCESS";
export const GET_SINGLE_VIDEO_DETAILS_FAILURE =
  "GET_SINGLE_VIDEO_DETAILS_FAILURE";

export const GET_ALL_VIDEOS = "GET_ALL_VIDEOS";

export const ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST =
  "ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST";
export const ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_SUCCESS =
  "ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_SUCCESS";
export const ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_FAILURE =
  "ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_FAILURE";

export const ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST =
  "ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST";
export const ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_SUCCESS =
  "ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_SUCCESS";
export const ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_FAILURE =
  "ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_FAILURE";

export const DUPLICATE_MEAL_IN_MEALPLAN = "DUPLICATE_MEAL_IN_MEALPLAN";
export const DUPLICATE_MEAL_IN_MEALPLAN_SUCCESS =
  "DUPLICATE_MEAL_IN_MEALPLAN_SUCCESS";
export const DUPLICATE_MEAL_IN_MEALPLAN_FAILURE =
  "DUPLICATE_MEAL_IN_MEALPLAN_FAILURE";

export const UPDATE_CALENDAR_SETTINGS = "UPDATE_CALENDAR_SETTINGS";
export const UPDATE_CALENDAR_SETTINGS_SUCCESS =
  "UPDATE_CALENDAR_SETTINGS_SUCCESS";

export const SET_CALENDAR_VIEW = "SET_CALENDAR_VIEW";

export const ADD_MEAL_PLAN_TO_CALENDAR = "ADD_MEAL_PLAN_TO_CALENDAR";
export const ADD_MEAL_PLAN_TO_CALENDAR_SUCCESS =
  "ADD_MEAL_PLAN_TO_CALENDAR_SUCCESS";

export const ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST =
  "ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST";
export const ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST_SUCCESS =
  "ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST_SUCCESS";
export const ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST_FAILURE =
  "ADD_MEAL_PLAN_INGREDIENTS_TO_GROCERYLIST_FAILURE";

export const ADD_CUSTOM_RECIPE_TO_MEAL_PLAN = "ADD_CUSTOM_RECIPE_TO_MEAL_PLAN";
export const ADD_CUSTOM_RECIPE_TO_MEAL_PLAN_SUCCESS =
  "ADD_CUSTOM_RECIPE_TO_MEAL_PLAN_SUCCESS";
export const ADD_CUSTOM_RECIPE_TO_MEAL_PLAN_FAILURE =
  "ADD_CUSTOM_RECIPE_TO_MEAL_PLAN_FAILURE";

export const CLEAR_MEAL_PLAN_DAY = "CLEAR_MEAL_PLAN_DAY";
export const CLEAR_MEAL_PLAN_DAY_SUCCESS = "CLEAR_MEAL_PLAN_DAY_SUCCESS";
export const CLEAR_MEAL_PLAN_DAY_FAILURE = "CLEAR_MEAL_PLAN_DAY_FAILURE";

export const SAVE_CALENDAR_DAY = "SAVE_CALENDAR_DAY";
export const SAVE_CALENDAR_DAY_SUCCESS = "SAVE_CALENDAR_DAY_SUCCESS";
export const SAVE_CALENDAR_DAY_FAILURE = "SAVE_CALENDAR_DAY_FAILURE";

export const ADD_SAVED_DAY = "ADD_SAVED_DAY";
export const ADD_SAVED_DAY_SUCCESS = "ADD_SAVED_DAY_SUCCESS";
export const ADD_SAVED_DAY_FAILURE = "ADD_SAVED_DAY_FAILURE";

export const ADD_MEAL_TO_MULTIPLE_DATES = "ADD_MEAL_TO_MULTIPLE_DATES";
export const ADD_MEAL_TO_MULTIPLE_DATES_SUCCESS =
  "ADD_MEAL_TO_MULTIPLE_DATES_SUCCESS";
export const ADD_MEAL_TO_MULTIPLE_DATES_FAILURE =
  "ADD_MEAL_TO_MULTIPLE_DATES_FAILURE";
export const UPDATE_CALENDAR_MACROS = "UPDATE_CALENDAR_MACROS";
export const UPDATE_CALENDAR_MACROS_SUCCESS = "UPDATE_CALENDAR_MACROS_SUCCESS";

export const TRENDING_ESSENTIALS_FETCH = "TRENDING_ESSENTIALS_FETCH";
export const TRENDING_ESSENTIALS_FETCH_SUCCESS =
  "TRENDING_ESSENTIALS_FETCH_SUCCESS";
export const TRENDING_ESSENTIALS_FETCH_FAILURE =
  "TRENDING_ESSENTIALS_FETCH_FAILURE";

export const TRENDING_TYPE_CHANGE = "TRENDING_TYPE_CHANGE";
export const TRENDING_TYPE_CHANGE_SUCCESS = "TRENDING_TYPE_CHANGE_SUCCESS";
export const TRENDING_TYPE_CHANGE_FAILURE = "TRENDING_TYPE_CHANGE_FAILURE";

export const FAQS_LIST_FETCH_REQUEST = "FAQS_LIST_FETCH_REQUEST";
export const FAQS_LIST_FETCH_REQUEST_SUCCESS =
  "FAQS_LIST_FETCH_REQUEST_SUCCESS";
export const FAQS_LIST_FETCH_REQUEST_FAILURE =
  "FAQS_LIST_FETCH_REQUEST_FAILURE";

export const ASSIGN_PLAN_TO_USER = "ASSIGN_PLAN_TO_USER";
export const ASSIGN_PLAN_TO_USER_SUCCESS = "ASSIGN_PLAN_TO_USER_SUCCESS";
export const ASSIGN_PLAN_TO_USER_FAILURE = "ASSIGN_PLAN_TO_USER_FAILURE";

export const COMPLETE_REGISTRATION = "COMPLETE_REGISTRATION";
export const COMPLETE_REGISTRATION_SUCCESS = "COMPLETE_REGISTRATION_SUCCESS";
export const COMPLETE_REGISTRATION_FAILURE = "COMPLETE_REGISTRATION_FAILURE";

export const SET_PLAN_ASSIGN_STATUS = "SET_PLAN_ASSIGN_STATUS";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";

export const REVERSE_CANCEL_SUBSCRIPTION = "REVERSE_CANCEL_SUBSCRIPTION";
export const REVERSE_CANCEL_SUBSCRIPTION_SUCCESS =
  "REVERSE_CANCEL_SUBSCRIPTION_SUCCESS";
export const REVERSE_CANCEL_SUBSCRIPTION_FAILURE =
  "REVERSE_CANCEL_SUBSCRIPTION_FAILURE";

export const CHANGE_PLAN = "CHANGE_PLAN";
export const CHANGE_PLAN_SUCCESS = "CHANGE_PLAN_SUCCESS";
export const CHANGE_PLAN_FAILURE = "CHANGE_PLAN_FAILURE";

export const PLANS_LIST_FETCH_REQUEST = "PLANS_LIST_FETCH_REQUEST";
export const PLANS_LIST_FETCH_SUCCESS = "PLANS_LIST_FETCH_SUCCESS";
export const PLANS_LIST_FETCH_FAILURE = "PLANS_LIST_FETCH_FAILURE";

export const RESET_REGISTER_STATE = "RESET_REGISTER_STATE";

export const EDIT_USER_PROFILE_REQUEST = "EDIT_USER_PROFILE_REQUEST";
export const EDIT_USER_PROFILE_SUCCESS = "EDIT_USER_PROFILE_SUCCESS";
export const EDIT_USER_PROFILE_FAILURE = "EDIT_USER_PROFILE_FAILURE";

export const CHANGE_PAYMENT_TYPE_REQUEST = "CHANGE_PAYMENT_TYPE_REQUEST";
export const CHANGE_PAYMENT_TYPE_SUCCESS = "CHANGE_PAYMENT_TYPE_SUCCESS";
export const CHANGE_PAYMENT_TYPE_FAILURE = "CHANGE_PAYMENT_TYPE_FAILURE";

export const SHOW_GROCERYLIST_LIMIT_NOTIFICATION =
  "SHOW_GROCERYLIST_LIMIT_NOTIFICATION";
export const REMOVE_GROCERYLIST_LIMIT_NOTIFICATION =
  "REMOVE_GROCERYLIST_LIMIT_NOTIFICATION";

export const REPLACE_GROCERY_LIST_REQUEST = "REPLACE_GROCERY_LIST_REQUEST";
export const REPLACE_GROCERY_LIST_SUCCESS = "REPLACE_GROCERY_LIST_SUCCESS";
export const REPLACE_GROCERY_LIST_FAILURE = "REPLACE_GROCERY_LIST_FAILURE";

// gift code
export const SAVE_GIFT_CODE = "SAVE_GIFT_CODE";
export const REMOVE_GIFT_CODE = "REMOVE_GIFT_CODE";

// referral code
export const SAVE_REFERRAL_CODE = "SAVE_REFERRAL_CODE";
export const REMOVE_REFERRAL_CODE = "REMOVE_REFERRAL_CODE";
// essential fetch of dishlist categories

export const DISHLIST_PRE_FETCH_ESSENTIALS_REQUEST =
  "DISHLIST_PRE_FETCH_ESSENTIALS_REQUEST";
export const DISHLIST_PRE_FETCH_ESSENTIALS_SUCCESS =
  "DISHLIST_PRE_FETCH_ESSENTIALS_SUCCESS";

// reactivate account data
export const SAVE_REACTIVATE_ACCOUNT_DATA = "SAVE_REACTIVATE_ACCOUNT_DATA";
export const REMOVE_REACTIVATE_ACCOUNT_DATA = "REMOVE_REACTIVATE_ACCOUNT_DATA";

// sentry error
export const ADD_SENTRY_ERROR = "ADD_SENTRY_ERROR";
export const REMOVE_SENTRY_ERROR = "REMOVE_SENTRY_ERROR";

export const ACCEPT_FIRST_TIME = "ACCEPT_FIRST_TIME";
export const ACCEPT_SECOND_TIME = "ACCEPT_FIRST_TIME";

export const CHANGE_FOOTER_STATUS = "FOOTER_STATUS";

export const UNIFIED_PLAN_CHOOSE = "UNIFIED_PLAN_CHOOSE";

export const UNIFIED_REGISTER = "UNIFIED_REGISTER";
export const UNIFIED_REGISTER_SUCCESS = "UNIFIED_REGISTER_SUCCESS";
export const UNIFIED_REGISTER_FAILURE = "UNIFIED_REGISTER_FAILURE";

export const UNIFIED_REGISTER_COUPON_ACTIVATE =
  "UNIFIED_REGISTER_COUPON_ACTIVATE";
export const UNIFIED_REGISTER_COUPON_ACTIVATE_SUCCESS =
  "UNIFIED_REGISTER_COUPON_ACTIVATE_SUCCESS";
export const UNIFIED_REGISTER_COUPON_ACTIVATE_FAILURE =
  "UNIFIED_REGISTER_COUPON_ACTIVATE_FAILURE";
export const UNIFIED_REGISTER_COUPON_REMOVE = "UNIFIED_REGISTER_COUPON_REMOVE";

export const AGREE_TO_METERED_VISIT = "AGREE_TO_METERED_VISIT";
export const SET_PLANS = "SET_PLANS";
