import { createSelector } from "reselect";
import { List, Map, Iterable, fromJS, toJS } from "immutable";
import type {
  essentialFetchActionParamsType,
  recipeType,
  recipeVariationType,
  recipeGroupType,
  recipeLineType,
} from "../types";

import { getFractionFromString } from "../helpers/converters";
import join from "lodash/join";
import trim from "lodash/trim";
import isNaN from "lodash/isNaN";
import filter from "lodash/filter";
import isNull from "lodash/isNull";
import Fraction from "fraction.js";
import toLower from "lodash/toLower";
import includes from "lodash/includes";
import moment from "moment";
import getDatesForMonth from "../helpers/getDatesForMonth";
import getServingCountFromActiveVariant from "../helpers/getServingCountFromActiveVariant";
import getGroceryCountFromCategories from "../helpers/getGroceryCountFromCategories";
import {
  groceryListMergingByAisle,
  groceryListMergingByRecipe,
} from "../helpers/groceryListMerging";
import { getQuantityMultiplied } from "../helpers/ingredientHelpers";

export const getEssentials = (state: any) => state.getIn(["essentials"]);
// export const getComments = (state: any) => state.getIn(['comments']);
// export const getNoteReducer = (state) => state.getIn(['notes'])
export const getActiveRecipe = (state: any) => state.getIn(["recipe"]);
export const getActiveRecipeForMobile = (state: any) =>
  state.getIn(["navigationStack"]);
// export const getGroceryWaitlist = (state: any) => state.getIn(['groceryList', 'waitList'])
export const currentServing = (state: any) =>
  state.getIn(["setCurrentServing", "currentServing"]);
export const getObjectSpecificEssential = (state: any) => {
  return state.getIn(["objectSpecificEssential"]);
};
export const getUserSpecificEssential = (state: any) =>
  state.getIn(["userSpecificEssential"]);
export const getGlobalError = (state) =>
  state.getIn(["fetchStatus", "listItemsFetch", "globalError", "message"]);

//for recipe
export const getRecipes = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "list"])
);
export const getRecipeLimit = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "limit"])
);
export const getRecipeOffset = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "offset"])
);

export const getDefaultRecipeCollection = createSelector(
  [getEssentials],
  (essentials) => {
    return essentials.getIn(["recipe", "filters", "defaultCollection"]);
  }
);
export const getRecipesCategories = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "categories"])
);
export const getRecipesMessage = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "message"])
);
export const getRecipesCount = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["recipe", "total_count"])
);
export const getRecipesSearchList = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "filters", "searchRecipeList"])
);
export const getRecipesSearchText = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "filters", "search"])
);

export const getCollectionView = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["collectionView"])
);

export const getSelectedFilters = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["recipe", "filters"])
);
export const getSelectedFiltersWorkouts = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workouts", "filters"])
);
export const getRecipesCollections = createSelector(
  [getEssentials],
  (essentials) => {
    // get featured as first objects in the array
    const featuredCollections = essentials
      .getIn(["recipe", "collections"])
      .filter((collection) => {
        if (collection.getIn(["featured"])) {
          return collection;
        }
      });

    const nonFeaturedCollections = essentials
      .getIn(["recipe", "collections"])
      .filter((collection) => {
        if (!collection.getIn(["featured"])) {
          return collection;
        }
      });

    const sortedCollections = featuredCollections.concat(
      nonFeaturedCollections
    );
    return sortedCollections;
  }
);

// for workouts
export const getWorkouts = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workouts", "list"])
);
export const getWorkoutLimit = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workouts", "limit"])
);
export const getWorkoutOffset = createSelector([getEssentials], (essentials) =>
  essentials.getIn(["workouts", "offset"])
);
export const getActiveWorkout = (state) => state.getIn(["workout"]);
export const getActiveWorkoutDetails = createSelector(
  [getActiveWorkout],
  (workout) => workout.getIn(["activeWorkout"])
);
export const getActiveRelatedWorkouts = createSelector(
  [getActiveWorkout],
  (workout) => {
    return workout.getIn(["relatedWorkouts"]);
  }
);
export const getWorkoutBoards = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => {
    // return userEssentials.getIn(['boards', 'boards','boards'])
    return userEssentials.getIn(["boards", "workoutBoards", "boards"]);
  }
);
export const getWorkoutsCollections = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workouts", "collections"])
);
export const getWorkoutsCategories = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workouts", "workoutTypes"])
);
export const getWorkoutsMessage = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["workouts", "message"])
);
export const getMoreWorkoutAvailableState = createSelector(
  [getEssentials],
  (essential) => essential.getIn(["workouts", "moreWorkoutsAvailable"])
);

export const getGroceryWaitlist = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => {
    return userEssentials.getIn(["waitList"])
  }
);

// specifies the id of recipe for the current page //Guess, please check
export const getRecipeStackItem = (state, stackIndex = 0) => {
  const recipeStack = state.getIn([
    "objectSpecificEssential",
    "recipe",
    "objectStack",
  ]);
  // IF active object type is not recipe
  // TODO: Remove these checks by handling appropriate cases
  // These fails at re renders
  if (!recipeStack || !recipeStack.getIn([stackIndex])) {
    return Map();
  }
  return recipeStack.getIn([stackIndex]);
};

// Recipe stack Item pointed by the component
export const getRecipeDetails = createSelector(
  [getRecipeStackItem],
  (recipeStackItem) => {
    if (recipeStackItem && !recipeStackItem.isEmpty()) {
      const recipeDetails = recipeStackItem.getIn(["data", "recipe"]);
      return recipeDetails;
    }
    return Map();
  }
);

// Specifies the id of variant of the active recipe
export const getActiveVariantId = createSelector(
  [getRecipeDetails, getObjectSpecificEssential],
  (recipe, objectEssentials) => {
    // if stack if id null -> do default else get returm the stack id
    if (recipe) {
      const variations = recipe.getIn(["variations"]);
      const selectedActiveVariationId = objectEssentials.getIn([
        "recipe",
        "stackTopObjectActiveVariationId",
      ]);
      if (isNull(selectedActiveVariationId)) {
        if (variations && variations.size > 0) {
          return variations.getIn([0, "id"]);
        }
      } else {
        return selectedActiveVariationId;
      }
    }
    // Shouldnt happen
    return 0;
  }
);

export const getActiveVariantIndexId = createSelector(
  [getRecipeDetails, getObjectSpecificEssential],
  (recipe, objectEssentials) => {
    // if stack if id null -> do default else get returm the stack id
    if (recipe) {
      const variations = recipe.getIn(["variations"]);
      const selectedActiveVariationId = objectEssentials.getIn([
        "recipe",
        "stackTopObjectActiveVariationIndex",
      ]);

      return selectedActiveVariationId;
    }

    return '0';
  }
);

export const getVariationsForActiveRecipe = createSelector(
  [getRecipeDetails, getActiveVariantId],
  (activeRecipe, activeVariationId) => {
    //

    if (activeVariationId && activeRecipe && !activeRecipe.isEmpty()) {
      let variations = activeRecipe.get("variations");
      // If there are multiple variations

      // Choose the active variation
      if (variations && !variations.isEmpty()) {
        const variation = variations.find(
          (variation) => variation.get("id") === activeVariationId
        );
        return variation;
      }
      return Map();
    } else {
      return Map();
    }
  }
);

const convertToV2Grocery = (item) => {
  const amount = item.getIn(["amount"]);
  const itemName = item.getIn(["item"]);

  if (!amount) {
    // If v2 grocery item
    // ItemName itself contains everything
    return toLower(trim(itemName));
  } else {
    // if v1 grocery item
    // For Grouping, this step is important

    return `${trim(amount)} ${toLower(trim(itemName))}`;
  }
};

const sumTwoRange = (rangeFirst, rangeSecond) => {
  const rfFractionStart = new Fraction(rangeFirst.split("-")[0]);
  const rsFractionStart = new Fraction(rangeSecond.split("-")[0]);

  const rfFractionEnd = new Fraction(rangeFirst.split("-")[1]);
  const rsFractionEnd = new Fraction(rangeSecond.split("-")[1]);

  const startFraction = rfFractionStart.add(rsFractionStart);
  const endFraction = rfFractionEnd.add(rsFractionEnd);

  return `${startFraction.toFraction(true)}-${endFraction.toFraction(true)}`;
};

export const isAmountMixedDecimal = (grocery) => {
  const splitGrocery = grocery.split(" ");
  // Check first character of 2nd item is equal to a number
  return splitGrocery.length >= 2 && !isNaN(parseInt(splitGrocery[1][0]));
};

const extractItemStringFromCompleteName = (grocery) => {
  const isMixedDecimal = isAmountMixedDecimal(grocery);

  const result = trim(
    join(
      filter(grocery.split(" "), (grocery, index) => {
        if (index === 0 || (isMixedDecimal && index === 1)) {
          return false;
        } else {
          return true;
        }
      }),
      " "
    )
  );

  return result;
};

export const getIngredientsForActiveRecipe = createSelector(
  [getVariationsForActiveRecipe, getGroceryWaitlist, currentServing],
  (activeVariation, groceryWaitlist, cServ) => {

    if (
      Iterable.isIterable(activeVariation) &&
      activeVariation &&
      !activeVariation.isEmpty()
    ) {
      const ingredientGroups = activeVariation.getIn(["ingredients", "groups"]);



      if (!ingredientGroups) {
        return List();
      }
      let selectedCount = 0;
      const newGroups = ingredientGroups
        .toSeq()
        .reduce((outerAccumulator, group) => {
          const newIngredients = group
            .getIn(["lines"])
            .toSeq()
            .reduce((innerAccumulator, ingredient, index) => {
              const waitlistIndex = groceryWaitlist.findIndex(
                (waitlist) => waitlist.get("id") === ingredient.get("id")
              );
              if (waitlistIndex !== -1) {
                ingredient = ingredient.set("selected", true);
                ingredient = ingredient.set(
                  "recipeId",
                  groceryWaitlist.getIn([waitlistIndex, "recipeId"])
                );

                selectedCount += 1;
              } else {
                ingredient = ingredient.set("selected", false);
              }
              // for dynamic amount
              const modifiedAmount = getFractionFromString(
                ingredient.getIn(["amount"])
              );

              let defServing;
              // Recipes serving size
              const actualServingSize = getServingCountFromActiveVariant(
                activeVariation.getIn(["nutrition"]).toJS()
              );

              if (actualServingSize === null) {
                defServing = 4;
              } else {
                defServing = parseInt(actualServingSize);
              }

              // If something changes out to be NaN change it to 1
              if (isNaN(parseInt(defServing))) {
                defServing = 1;
              }

              let newCserv = cServ;
              if (isNaN(parseInt(newCserv))) {
                newCserv = newCserv.split(" ")[0];
                if (isNaN(parseInt(newCserv))) {
                  newCserv = 1;
                } else {
                  newCserv = parseInt(newCserv);
                }
              } else {
                newCserv = parseInt(newCserv);
              }
              let servingValue;

              try {
                // Calculate the serving sizes as per the global field
                servingValue = defServing / newCserv;
              } catch (e) {
                servingValue = 1;
              }

              if (
                !isNaN(parseInt(modifiedAmount[0])) &&
                !modifiedAmount.includes("-")
              ) {
                try {
                  const remodifedAmount = new Fraction(modifiedAmount);

                  let dynamicAmount = eval(remodifedAmount) / servingValue;

                  let fractionAmount = new Fraction(dynamicAmount);

                  let amount = ingredient
                    .getIn(["amount"])
                    .replace(
                      getFractionFromString(ingredient.getIn(["amount"])),
                      fractionAmount.toFraction(true)
                    );

                  ingredient = ingredient.setIn(
                    ["amount"],
                    amount.replace("NaN/NaN", "")
                  );

                  innerAccumulator = innerAccumulator.push(ingredient);

                  return innerAccumulator;
                } catch (e) {
                  innerAccumulator = innerAccumulator.push(ingredient);

                  return innerAccumulator;
                }
              } else if (modifiedAmount.includes("-")) {
                try {
                  const firstValue =
                    new Fraction(modifiedAmount.split("-")[0]) / servingValue;
                  const secondValue =
                    new Fraction(modifiedAmount.split("-")[1]) / servingValue;

                  ingredient = ingredient.setIn(
                    ["amount"],
                    `${firstValue.toFraction(true)}-${secondValue.toFraction(
                      true
                    )}`
                  );

                  innerAccumulator = innerAccumulator.push(ingredient);

                  return innerAccumulator;
                } catch (e) {
                  innerAccumulator = innerAccumulator.push(ingredient);

                  return innerAccumulator;
                }
              } else {
                innerAccumulator = innerAccumulator.push(ingredient);
                return innerAccumulator;
              }
            }, List());

          group = group.setIn(["lines"], newIngredients);
          outerAccumulator = outerAccumulator.push(group);
          return outerAccumulator;
        }, List());
      return fromJS({
        groups: newGroups,
        selectedCount,
      });
    } else {
      return List();
    }
  }
);


export const getSelectedCountV2 = createSelector(
  [getRecipeDetails, getVariationsForActiveRecipe, getActiveVariantIndexId, currentServing, getGroceryWaitlist],
  (recipe, variation, activeIndex, cServ, waitList) => {
    const selectedCount = waitList.toJS().length;

    return selectedCount
  })

export const getIngredientsV2ForActiveRecipe = createSelector(
  [getRecipeDetails, getVariationsForActiveRecipe, getActiveVariantIndexId, currentServing, getGroceryWaitlist],
  (recipe, variation, activeIndex, cServ, waitList) => {

    let defServing;
    let servingValue;
    if (variation && !variation.isEmpty()) {
      const actualServingSize = getServingCountFromActiveVariant(
        variation.getIn(["nutrition"]).toJS()
      );

      if (actualServingSize === null) {
        defServing = 4;
      } else {
        defServing = parseInt(actualServingSize);
      }

      // If something changes out to be NaN change it to 1
      if (isNaN(parseInt(defServing))) {
        defServing = 1;
      }


      // if default serving is not a number
      if (isNaN(parseInt(defServing))) {
        defServing = 1;
      }

      let newCserv = cServ;
      if (isNaN(parseInt(newCserv))) {
        newCserv = newCserv.split(" ")[0];
        if (isNaN(parseInt(newCserv))) {
          newCserv = 1;
        } else {
          newCserv = parseInt(newCserv);
        }
      } else {
        newCserv = parseInt(newCserv);
      }


      try {
        // Calculate the serving sizes as per the global field
        servingValue = defServing / newCserv;
      } catch (e) {
        servingValue = 1;
      }
    }

    if (!recipe.isEmpty() && recipe.getIn(['ingredients_v2'])) {
      const recipeIngredients = recipe.getIn(['ingredients_v2'])
        .toSeq()
        .filter((ingredient) => {

          return ingredient.getIn(['variation_position']) === parseInt(activeIndex);
        })
        .map((ingredient) => {

          const updatedQuantity = getQuantityMultiplied(ingredient.getIn(['quantity']), 1 / servingValue);
          const isSelected = waitList
            .findIndex(listItem => {
              const listItemId = listItem.getIn(['ingredient', 'id']);
              return (listItemId === ingredient.getIn(['id']))
            }) !== -1;
          ingredient = ingredient.setIn(['quantity'], updatedQuantity);
          ingredient = ingredient.setIn(['selected'], isSelected);

          return ingredient;
        });

      return fromJS(recipeIngredients);
    }

    // Recipes serving size


  });


export const getCaloriesForRecipe = createSelector(
  [getVariationsForActiveRecipe],
  (variation) => {
    if (Iterable.isIterable(variation) && variation && !variation.isEmpty()) {
      let data = {
        calories: variation.getIn(["calories"]),
        sp: variation.getIn(["smart_points"]),
        fp: variation.getIn(["freestyle_points"]),
      };
      return fromJS(data);
    } else {
      let data = {
        calories: null,
        sp: null,
        fp: null,
      };
      return fromJS(data);
    }
  }
);

export const getAuthStatus = (state) => {
  const isLoggingIn = state.getIn(["loggedInUser", "isLogging"]);
  const isAuthenticating = state.getIn(["loggedInUser", "isAuthenticating"]);
  const isLoggedIn = state.getIn(["loggedInUser", "isLoggedIn"]);
  const isLoginFailed = state.getIn(["loggedInUser", "isLoginFailed"]);
  const errorMessage = state.getIn(["loggedInUser", "errorMessage"]);
  const firstTime = state.getIn(["loggedInUser", "firstTime"]);
  const secondTime = state.getIn(["loggedInUser", "secondTime"]);
  const authError = state.getIn(["loggedInUser", "authError"]);
  return fromJS({
    isLoggingIn,
    isLoggedIn,
    isAuthenticating,
    isLoginFailed,
    errorMessage,
    firstTime,
    secondTime,
    authError,
  });
};

export const getRelatedRecipes = createSelector(
  [getRecipeStackItem],
  (recipeStackItem) => {
    if (recipeStackItem) {
      const recipeDetails = recipeStackItem.getIn(["data", "relatedRecipes"]);
      return recipeDetails;
    }
    return List();
  }
);

// ingredients for active recipe

// for workouts *new...........................................................................
export const getWorkoutStackItem = (state, stackIndex = 0) => {
  const recipeStack = state.getIn([
    "objectSpecificEssential",
    "workout",
    "objectStack",
  ]);
  if (!recipeStack || !recipeStack.getIn([stackIndex])) {
    return Map();
  }
  return recipeStack.getIn([stackIndex]);
};

// Recipe stack Item pointed by the component
export const getWorkoutDetails = createSelector(
  [getWorkoutStackItem],
  (workoutStackItem) => {
    if (!workoutStackItem.isEmpty()) {
      const workoutDetails = workoutStackItem.getIn(["data", "workout"]);
      return workoutDetails;
    }
    return Map();
  }
);

export const getRelatedWorkouts = createSelector(
  [getWorkoutStackItem],
  (workoutStackItem) => {
    if (workoutStackItem) {
      const relatedWorkouts = workoutStackItem.getIn([
        "data",
        "relatedWorkouts",
      ]);
      return relatedWorkouts;
    }
    return List();
  }
);

export const getWorkoutTypes = createSelector(
  [getWorkoutStackItem],
  (workoutStackItem) => {
    if (workoutStackItem && !workoutStackItem.isEmpty()) {
      const workoutDetails = workoutStackItem.getIn(["data", "workoutTypes"]);
      return workoutDetails;
    }
    return Map();
  }
);

// Selector for categories
export const getFoodTypes = createSelector(
  [getRecipeStackItem],
  (recipeStackItem) => {
    if (recipeStackItem && !recipeStackItem.isEmpty()) {
      const recipeDetails = recipeStackItem.getIn(["data", "foodtypes"]);
      return recipeDetails;
    }
    return Map();
  }
);

export const getNoteStackItem = (state, objectType, stackIndex = 0) => {
  const noteStack = state.getIn([
    "objectSpecificEssential",
    objectType,
    "noteStack",
  ]);
  // TODO: Preloader should avoid this. Stack doesn't have this index
  // This happens just before new stack data is posted
  if (!noteStack || !noteStack.getIn([stackIndex])) {
    return Map();
  }
  return noteStack.getIn([stackIndex, "data"]);
};

// Selector for boards
export const getRecipeBoards = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => {
    // return userEssentials.getIn(['boards', 'boards','boards'])
    return userEssentials.getIn(["boards", "recipeBoards", "boards"]);
  }
);

// Get comments to the active recipe
export const getCommentsList = (state, objectType, stackIndex = 0) => {
  // Mofify comment according to front end requirments
  const commentStackItem = state.getIn([
    "objectSpecificEssential",
    objectType,
    "commentStack",
    stackIndex,
    "data",
  ]);

  if (commentStackItem) {
    const currentComments = commentStackItem
      .toSeq()
      .map((comment) => {
        const author = comment.getIn(["user", "name"]);
        const url = comment.getIn(["user", "url"]);

        return comment.setIn(["author"], author).setIn(["url"], url);
      })
      .toList();

    return currentComments;
  }
  return List();
};

// Each item in the grocery wait list

export const getUserDetails = (state) => state.getIn(["loggedInUser"]);
export const getRegisterStatus = (state) => state.getIn(["register"]);

export const getMoreRecipeAvailableState = createSelector(
  [getEssentials],
  (essential) => essential.getIn(["recipe", "moreRecipesAvailable"])
);
// Get related recipies to the active recipe
export const getActiveRelatedRecipe = createSelector(
  [getActiveRecipe],
  (recipe) => {
    return recipe.getIn(["relatedRecipe"]);
  }
);

// Get avg rating for single recipe
export const getAvgRatingForActiveRecipe = createSelector(
  [getActiveRecipe],
  (recipe) => {
    return recipe.getIn(["activeRecipe", "avg_rating"]);
  }
);

// Get all boards with selected true (based on in the recipe is present in the board)
export const getFavoriteWithBoardMap = createSelector(
  [getUserSpecificEssential, getActiveRecipe],
  (userEssentials, recipe) => {
    if (!recipe.getIn(["activeRecipe"]).isEmpty()) {
      const allBoards = userEssentials.getIn([
        "boards",
        "recipeBoards",
        "boards",
      ]);
      const selectedBoards = recipe.getIn(["activeRecipe", "favorite_boards"]);
      let favoriteWithBoardMap = allBoards.toSeq().map((board) => {
        let boardId = board.getIn(["id"]);
        if (
          selectedBoards &&
          selectedBoards.findIndex((item) => item === boardId) !== -1
        ) {
          board = board.setIn(["selected"], true);
        } else {
          board = board.setIn(["selected"], false);
        }
        return board;
      });

      return favoriteWithBoardMap;
    } else {
      return List();
    }
  }
);

// For Grocery List
export const getGroceryList = (state) =>
  state.getIn(["userSpecificEssential", "groceryList"]);
// export const getGroceryListViewType = state => '
export const getGroceryListViewType = (state) =>
  state.getIn(["userSpecificEssential", "groceryListViewType"]);

const generateItemCompleteName = (amount, item) => `${item} ${amount}`;

const extractAmountStringFromCompleteName = (grocery) => {
  const isMixedDecimal = isAmountMixedDecimal(grocery);

  const result = trim(
    join(
      filter(grocery.split(" "), (item, index) => {
        return index === 0 || (isMixedDecimal && index == 1);
      }),
      " "
    )
  );

  return result;
};

// Always returns decimal
const extractAmountWithType = (grocery) => {
  const amountString = extractAmountStringFromCompleteName(grocery);
  try {
    if (includes(amountString, "-")) {
      return {
        type: "range",
        value: amountString,
      };
    }
    if (isAmountMixedDecimal(grocery)) {
      return {
        type: "mixedFraction",
        value: new Fraction(amountString).toFraction(true),
      };
    }

    const amount = eval(amountString);
    if (!isNaN(amount)) {
      return {
        type: "number",
        value: eval(amountString),
      };
    } else if (isNaN(amountString)) {
      if (includes(amountString, "-")) {
        return {
          type: "range",
          value: amountString,
        };
      } else if (includes(amountString, "/") && !isNaN(eval(amountString))) {
        // Fraction

        return {
          type: "number",
          value: eval(amountString),
        };
      } else {
        return {
          // Normal string with no units
          type: "string",
          value: amountString,
        };
      }
    }
  } catch (e) {
    return {
      type: "string",
      value: grocery,
    };
  }
};

/**
 * Merges ingredients with the same name under each category and combines their quantities
 * @param {Array} categoriesJson - The original JSON array of categories with ingredients
 * @returns {Array} - The processed JSON array with merged ingredients
 */
function mergeIngredients(categoriesJson) {
  // Include Fraction.js via CDN if not already included
  // <script src="https://cdnjs.cloudflare.com/ajax/libs/fraction.js/4.3.0/fraction.min.js"></script>

  const processedCategories = [];

  // Process each category
  for (const category of categoriesJson) {
    const mergedItems = {};

    // Process each item in the category
    for (const item of category.items) {
      const name = item.name;

      // Extract the quantity and item name with a more flexible regex
      // This handles any unit, not just specific ones like tbs, lb, etc.
      const match = name.match(/^([\d./\s]+)\s+([^\d]+)$/);

      if (match) {
        const [, quantityStr, itemNameWithUnit] = match;

        // Split the remaining part into the first word (potential unit) and the rest
        const parts = itemNameWithUnit.trim().split(/\s+/);
        const unit = parts[0];
        const itemName = parts.slice(1).join(' ');

        // Create a key that combines the unit and item name
        const key = `${unit} ${itemName}`;

        try {
          // Use Fraction.js to parse the quantity
          const quantity = new Fraction(quantityStr);

          // If this item already exists, add to its quantity, otherwise create a new entry
          if (mergedItems[key]) {
            mergedItems[key].quantity = mergedItems[key].quantity.add(quantity);
            mergedItems[key].recipes = [...new Set([...mergedItems[key].recipes, ...item.recipe.map(r => r ? r.id : null)])];
            // Combine IDs from the original ingredients
            mergedItems[key].ids = [...mergedItems[key].ids, ...item.id];
          } else {
            mergedItems[key] = {
              quantity,
              unit,
              itemName,
              recipes: item.recipe.map(r => r ? r.id : null),
              ids: [...item.id] // Store original IDs
            };
          }
        } catch (e) {
          // If parsing fails, treat as a non-quantified item
          if (mergedItems[name]) {
            mergedItems[name].count += 1;
            mergedItems[name].recipes = [...new Set([...mergedItems[name].recipes, ...item.recipe.map(r => r ? r.id : null)])];
            mergedItems[name].ids = [...mergedItems[name].ids, ...item.id];
          } else {
            mergedItems[name] = {
              count: 1,
              itemName: name,
              recipes: item.recipe.map(r => r ? r.id : null),
              ids: [...item.id]
            };
          }
        }
      } else {
        // Handle items that don't match the expected pattern
        if (mergedItems[name]) {
          mergedItems[name].count += 1;
          mergedItems[name].recipes = [...new Set([...mergedItems[name].recipes, ...item.recipe.map(r => r ? r.id : null)])];
          mergedItems[name].ids = [...mergedItems[name].ids, ...item.id];
        } else {
          mergedItems[name] = {
            count: 1,
            itemName: name,
            recipes: item.recipe.map(r => r ? r.id : null),
            ids: [...item.id]
          };
        }
      }
    }

    // Format the merged items back into the expected structure
    const processedItems = Object.entries(mergedItems).map(([key, data]) => {
      let formattedName;

      if (data.quantity) {
        // Use Fraction.js to format the quantity
        const formattedQuantity = data.quantity.toFraction(true);
        formattedName = `${formattedQuantity} ${data.unit} ${data.itemName}`;
      } else if (data.count > 1) {
        formattedName = `${data.count} ${data.itemName}`;
      } else {
        formattedName = data.itemName;
      }

      return {
        name: formattedName,
        item: formattedName,
        categoryId: [category.id],
        id: data.ids, // Use the combined array of original IDs
        recipe: data.recipes.map(id => {
          // Find the recipe details from the original items
          const originalItem = category.items.find(item =>
            item.recipe.some(r => r ? r.id === id : false)
          );

          return originalItem ? originalItem.recipe.find(r => r ? r.id === id : false) : null;
        }).filter(Boolean)
      };
    });

    // Create the processed category
    processedCategories.push({
      id: category.id,
      name: category.name,
      slug: category.slug,
      items: processedItems
    });
  }

  return processedCategories;
}

export const getGroceryListByType = createSelector(

  [getGroceryList, getGroceryListViewType],
  (list, viewType) => {
    try {
      // This function reduces and builds a list for any itemKey
      // for example [{ id: 1}, { id: 2 }, {id: 3 }] becomes [{ id: [1, 2, 3 ]}] for a ingreident
      const groupSameIngredientData = (list, itemKey) =>
        list
          .toSeq()
          .reduce((acc, val) => acc.push(val.getIn([itemKey])), List());

      // If view type is aisle
      if (viewType === "aisle") {
        const mergedAisleList = groceryListMergingByAisle(list);

        const mergedIngredients = mergeIngredients(mergedAisleList.toJS());
        console.log(mergedIngredients, 'mergedIngredients');
        return list.setIn(["categories"], mergedIngredients);
      } else if (viewType === "recipe") {
        const updatedCategories = groceryListMergingByRecipe(list);
        const mergedIngredients = mergeIngredients(updatedCategories.toJS());
        return list.setIn(["categories"], mergedIngredients);
      }
    } catch (e) {
      console.log(e);
    }
  }
);

// Specific selector for mobile gorcery list
export const getGroceryListByTypeForMobile = createSelector(
  [getGroceryListByType],
  (list) => {
    let newGroceryList = list
      .getIn(["categories"])
      .toSeq()
      .map((groceryItem) => {
        return groceryItem.setIn(["data"], groceryItem.getIn(["items"]));
      });
    let newList = list.setIn(["categories"], newGroceryList);

    return newList;
  }
);

// Get grocery list selected values
export const getGrocerySelectedValues = createSelector(
  [getUserSpecificEssential],
  (userEssentials) => userEssentials.getIn(["selectedIds"])
);

// fetchStatus

export const getFetchStatus = (state) => state.getIn(["fetchStatus"]);

// used
export const getObjectListFetchStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => {
    return {
      isListFetching: fetchStatus.getIn(["appEssentials", "isFetching"]),
    };
  }
);

export const dishListPreFetchStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => {
    return fetchStatus.getIn(["objectPreFetch", "dishlist"]);
  }
);

export const getGroceryListFetchStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => {
    return {
      isListFetching: fetchStatus.getIn(["groceryList", "isFetching"]),
      startup: fetchStatus.getIn(["startup"]).toJS(),
      userAuthenticate: fetchStatus.getIn(["userAuthenticate"]).toJS(),
      preFetch: fetchStatus.getIn(["objectPreFetch", "recipe"]).toJS(),
    };
  }
);

// get status data of single recipie details
export const getSingleRecipeFetchStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => {
    return {
      isFetching: fetchStatus.getIn(["recipeDetails", "isFetching"]),
      isCommentPosting: fetchStatus.getIn(["commentPostStatus", "isPosting"]), // TODO: Change this dynamically
    };
  }
);

// Calendar related selectors
export const getCalendarSettings = createSelector(
  [getUserSpecificEssential],
  (userSpecificEssential) => {
    return userSpecificEssential.getIn(["calendarSettings"]);
  }
);

// Gets the calendar dates for a particular month
export const getCalendarDates = createSelector(
  [getCalendarSettings],
  (calendarSettings) => {
    let result = getDatesForMonth(
      calendarSettings.getIn(["monthType"]),
      calendarSettings.getIn(["activeDate"]),
      calendarSettings.getIn(["anchorDate"]),
      14
    );

    const isEndLimitIsWithinThisMonth = moment(
      calendarSettings.getIn(["anchorDate"])
    )
      .clone()
      .add(calendarSettings.getIn(["dataForDays"]), "days")
      .isSame(new Date(), "month");
    if (isEndLimitIsWithinThisMonth) {
      result.isPrevMonthDisabled = true;
      result.isNextMonthDisabled = true;
    } else {
      if (calendarSettings.getIn(["monthType"]) === "current") {
        result.isPrevMonthDisabled = true;
        result.isNextMonthDisabled = false;
      } else if (calendarSettings.getIn(["monthType"]) === "next") {
        result.isPrevMonthDisabled = false;
        result.isNextMonthDisabled = true;
      }
    }

    return result;
  }
);

export const calendarListData = createSelector(
  [getUserSpecificEssential],
  (userSpecificEssential) => {
    return userSpecificEssential.getIn(["calendarListData"]);
  }
);
// selector recipe headers
export const getRecipeFilterName = createSelector(
  [getEssentials, getUserSpecificEssential],
  (essentials, userEssentials) => {
    if (essentials.getIn(["recipe", "filters", "searchApplied"])) {
      return {
        type: "search",
        title: essentials.getIn(["recipe", "filters", "search"]),
      };
    } else if (
      essentials.getIn(["recipe", "filters", "favoriteBoardId"]) > -1
    ) {
      let favoriteBoardId = essentials.getIn([
        "recipe",
        "filters",
        "favoriteBoardId",
      ]);
      if (favoriteBoardId == null) {
        return { type: "favorite", title: "All" };
      }
      let favoriteHeader;
      let boards = userEssentials.getIn(["boards", "recipeBoards", "boards"]);
      boards.map((board, index) => {
        if (board.getIn(["id"]) == favoriteBoardId) {
          favoriteHeader = board.getIn(["title"]);
        }
      });
      return { type: "favorite", title: favoriteHeader };
    } else if (
      essentials.getIn(["recipe", "filters", "categories"]).size > 0 ||
      essentials.getIn(["recipe", "filters", "collections"]).size > 0 ||
      essentials.getIn(["recipe", "filters", "freeStylePoints", "min"]) !==
      null ||
      essentials.getIn(["recipe", "filters", "smartPoints", "min"]) !== null ||
      essentials.getIn(["recipe", "filters", "wwp", "min"]) !== null
    ) {
      let filterArray = [];
      essentials
        .getIn(["recipe", "filters", "categories"])
        .map((category, index) => {
          let ctg = essentials
            .getIn(["recipe", "categories"])
            .find((ctg) => ctg.getIn(["id"]) == category);
          ctg = ctg.getIn(["name"]);
          filterArray.push(ctg);
        });
      essentials
        .getIn(["recipe", "filters", "collections"])
        .map((collection, index) => {
          let cln = essentials
            .getIn(["recipe", "collections"])
            .find((cln) => cln.getIn(["id"]) == collection);
          cln = cln.getIn(["name"]);
          filterArray.push(cln);
        });

      if (
        essentials.getIn(["recipe", "filters", "freeStylePoints", "min"]) !==
        null ||
        essentials.getIn(["recipe", "filters", "smartPoints", "min"]) !==
        null ||
        essentials.getIn(["recipe", "filters", "wwp", "min"]) !== null
      ) {
        if (filterArray.length > 0) {
          filterArray.push("with Weight Watchers™");
        } else {
          filterArray.push("Weight Watchers™");
        }
      }

      return { type: "filter", filterArray };
    } else {
      return { type: "recent", title: "Recent recipe" };
    }
  }
);

// selector for weightWathers
export const getWeightWathersSelection = createSelector(
  [getEssentials],
  (essentials) => {
    if (
      essentials.getIn(["recipe", "filters", "freeStylePoints", "min"]) !==
      null ||
      essentials.getIn(["recipe", "filters", "smartPoints", "min"]) !== null ||
      essentials.getIn(["recipe", "filters", "wwp", "min"]) !== null
    ) {
      return true;
    }
    return false;
  }
);

// fetch status *new...........
const objectFetchStatus = (state, payload) => {
  return {
    objectFetchStatus: state.getIn(["fetchStatus", "objectDetails"]),
    payload,
  };
};
/* for fetchRecipeDetails or fetchWorkoutDetails
  params*
    state
    payload: {
      objectType: 'recipe',
      stackIndex: 0
    }
*/
export const getObjectFetchStatus = createSelector(
  [objectFetchStatus],
  ({ objectFetchStatus, payload: { objectType, stackIndex = 0 } }) => {
    let status = objectFetchStatus.getIn([objectType, stackIndex]);
    return status;
  }
);

// fetch status for comments and notes
const ObjectEssentialFetchStatus = (state, payload) => {
  return {
    objectEssentialFetchStatus: state.getIn([
      "fetchStatus",
      "objectEssentials",
    ]),
    payload,
  };
};

export const getObjectEssentialFetchStatus = createSelector(
  [ObjectEssentialFetchStatus],
  ({ objectEssentialFetchStatus, payload: { objectType, stackIndex = 0 } }) => {
    let status = objectEssentialFetchStatus.getIn([objectType, stackIndex]);
    return status;
  }
);

export const getCommentPostStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => {
    return fetchStatus.getIn(["commentPostStatus", "isPosting"]); // TODO: Change this dynamically
  }
);

export const getBoardPostStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => {
    return fetchStatus.getIn(["boardPostStatus", "isPosting"]); // TODO: Change this dynamically
  }
);

export const getNotePostStatus = createSelector(
  [getFetchStatus],
  (fetchStatus) => {
    return fetchStatus.getIn(["notePostStatus", "isPosting"]); // TODO: Change this dynamically
  }
);

// selector for searchBy
export const getSearchBy = createSelector([getEssentials], (essentials) => {
  return essentials.getIn(["recipe", "filters", "searchBy"]);
});

// for recipe search text
export const getRecipeSearchText = createSelector(
  [getEssentials],
  (essentials) => {
    return essentials.getIn(["recipe", "filters", "search"]);
  }
);

// get count of number of filters selected
export const getSelectedFiltersCount = createSelector(
  [getSelectedFilters],
  (filters) => {
    let categoriesSize = filters.getIn(["categories"]).size;
    let collectionsSize = filters.getIn(["collections"]).size;
    let freeStylePointsSize = 0;
    let smartPointsSize = 0;
    let wwp = 0;
    if (
      !isNull(filters.getIn(["freeStylePoints", "max"])) ||
      !isNull(filters.getIn(["freeStylePoints", "min"]))
    ) {
      freeStylePointsSize = 1;
    }
    if (
      !isNull(filters.getIn(["smartPoints", "max"])) ||
      !isNull(filters.getIn(["smartPoints", "min"]))
    ) {
      smartPointsSize = 1;
    }
    if (
      !isNull(filters.getIn(["wwp", "max"])) ||
      !isNull(filters.getIn(["wwp", "min"]))
    ) {
      wwp = 1;
    }

    let totalCount =
      categoriesSize +
      collectionsSize +
      freeStylePointsSize +
      smartPointsSize +
      wwp;
    return totalCount;
  }
);

export const getIsRecipeFilterApplied = createSelector(
  [getSelectedFilters],
  (filters) => {
    return filters.getIn(["filterApplied"]);
  }
);

export const getActiveTabbar = (state) =>
  state.getIn(["loggedInUser", "activeTabbar"]);

export const getTabbarToggleStatus = (state) =>
  state.getIn(["loggedInUser", "isTabbarDynamic"]);

export const getToastMessage = (state) => state.getIn(["toasts"]);
export const getGroceryListThresholdNotification = (state) => {
  const status = state.getIn([
    "userSpecificEssential",
    "groceryListThresholdReached",
  ]);
  const ingredients = state.getIn([
    "userSpecificEssential",
    "temporaryGroceryList",
  ]);
  return {
    status: status,
    ingredients: ingredients,
  };
};

export const getGroceryThresholdReached = (state) => {
  return state.getIn(["userSpecificEssential", "groceryListThresholdReached"]);
};

export const getProgressStatus = (state) => ({
  deleteSingleGroceryItem: {
    isProgressing: state.getIn([
      "fetchStatus",
      "deleteSingleGroceryItem",
      "isProgressing",
    ]),
  },
  clearGroceryItems: {
    isProgressing: state.getIn([
      "fetchStatus",
      "clearGroceryItems",
      "isProgressing",
    ]),
    isProgressed: state.getIn([
      "fetchStatus",
      "clearGroceryItems",
      "isProgressed",
    ]),
  },
});

export const getGroceryListByAisle = createSelector(
  [getGroceryList, getGroceryListViewType],
  (list, viewType) => {
    try {
      // This function reduces and builds a list for any itemKey
      // for example [{ id: 1}, { id: 2 }, {id: 3 }] becomes [{ id: [1, 2, 3 ]}] for a ingreident
      // If view type is aisle
      const mergedAisleList = groceryListMergingByAisle(list);
      return list.setIn(["categories"], mergedAisleList);
    } catch (e) {
      console.log(e);
    }
  }
);

// Selector for recipe

export const getGroceryListByRecipe = createSelector(
  [getGroceryList, getGroceryListViewType],
  (list, viewType) => {
    try {
      // This function reduces and builds a list for any itemKey
      // for example [{ id: 1}, { id: 2 }, {id: 3 }] becomes [{ id: [1, 2, 3 ]}] for a ingreident
      // If view type is aisle
      const updatedCategories = groceryListMergingByRecipe(list);
      return list.setIn(["categories"], updatedCategories);
    } catch (e) {
      console.log(e);
    }
  }
);

export const getGroceryListByAisleForMobile = createSelector(
  [getGroceryListByAisle],
  (list) => {
    let newGroceryList = list
      .getIn(["categories"])
      .toSeq()
      .map((groceryItem) => {
        return groceryItem.setIn(["data"], groceryItem.getIn(["items"]));
      });
    let newList = list.setIn(["categories"], newGroceryList);

    return newList;
  }
);

export const getGroceryListByRecipeForMobile = createSelector(
  [getGroceryListByRecipe],
  (list) => {
    let newGroceryList = list
      .getIn(["categories"])
      .toSeq()
      .map((groceryItem) => {
        return groceryItem.setIn(["data"], groceryItem.getIn(["items"]));
      });
    let newList = list.setIn(["categories"], newGroceryList);

    return newList;
  }
);

export const getPlans = (state) => state.getIn(["essentials", "plans"]);

export const getProfileUpdateFetchStatus = (state) =>
  state.getIn(["fetchStatus", "editUserDetails"]);

export const getChangeCardTypeFetchStatus = (state) =>
  state.getIn(["fetchStatus", "changeCardType"]);

export const getPlanChangeFetchStatus = (state) =>
  state.getIn(["fetchStatus", "changePlan"]);

export const getCancelSubscriptionFetchStatus = (state) =>
  state.getIn(["fetchStatus", "cancelSubscription"]);

export const getReverseCancelSubscriptionFetchStatus = (state) =>
  state.getIn(["fetchStatus", "reverseCancelSubscription"]);

export const getDishListCategories = createSelector(
  [getEssentials],
  (essentials) => essentials.getIn(["dishlist", "categories"])
);

export const getSentryError = (state) => state.getIn(["sentryError"]);

export const getGroceryListItemCount = createSelector(
  [getGroceryListByAisle],
  (list) => {
    return getGroceryCountFromCategories(list);
  }
);

export const getMealPlanCategories = (state) =>
  state.getIn(["essentials", "mealPlanCategories"]);


export const getVariationTotalCount = createSelector(
  [getRecipeDetails, getVariationsForActiveRecipe, getActiveVariantIndexId, currentServing, getGroceryWaitlist],
  (recipe, variation, activeIndex, cServ, waitList) => {
    let totalCount = 0;
    if (!recipe.isEmpty() && recipe.getIn(['ingredients_v2'])) {
      const recipeIngredients = recipe.getIn(['ingredients_v2'])
        .toSeq()
        .forEach((ingredient) => {
          totalCount = Math.max(totalCount, ingredient.getIn(['variation_position']));
        });

    }

    // Recipes serving size
    return totalCount;

  });

export const getInitiatedEmail = state => state.getIn(['unifiedRegister', 'initiatedEmail']);