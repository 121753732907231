import React from 'react';
import replace from 'lodash/replace';
import take from 'lodash/take';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames'


import getMarkdownImage from '../../../../helpers/getMarkdownImage';
import convertQuality from '../../../../helpers/convertQuality';
import { isNewTagStatus } from "../../../../helpers/getNewTagStatus";
import GridCard from '../../../common/GridCard';
import Selector from '../../../common/selector';
import HorizontalListWithAction from '../../../common/horizontalActionableHeader';

const dayList = [
  {
    title: 'Today',
    value: 'day'
  },
  {
    title: 'Weekly',
    value: 'week'
  },
  {
    title: 'Monthly',
    value: 'month'
  },
  {
    title: 'All Time',
    value: 'allTime'
  },
]

const durationList = [
  {
    index: '#1',
    value: 'sort',
    title: 'Choose option',
    disabled: true
  },
  {
    title: 'Last to newest',
    value: 'asc'
  },
  {
    title: 'Newest to last',
    value: 'desc'
  },
  {
    title: 'Randomized',
    value: 'rand'
  },
]

const VerticalSplicedScreen = ({
  data,
  title,
  isAuthenticated,
  renderBlogMeta,
  toggleFavoriteModal,
  deleteFavourite,
  loginRedirect,
  history = { history },
  showActions,
  trendingTypeChange,
  isLoading,
  spliceCount,
  additionalClass,
  onAction
}) => {

  return (
    <div className="verticalList">

      <HorizontalListWithAction
        onAction={onAction}
        title={title}
        options={title !== 'Recent Posts' ? dayList : durationList}
        isLoading={isLoading}
        active={showActions}
        defaultValue={'desc'}
      >
        <ul
          className={cx('featuredLists', { 'scrollableWrapper': additionalClass })}>
          {
            map(take(data, spliceCount), listItem => (
              <li className="featuredBlog">
                <GridCard
                  key={listItem.title}
                  item={listItem}
                  coverImage={listItem.image ? convertQuality(listItem.image, 'medium') : (!isEmpty(listItem.content) ? getMarkdownImage(listItem.content, 'medium')[1] : '')}
                  feature="blog"
                  isAuthenticated={isAuthenticated}
                  objectType="blog"
                  history={history}
                  loginRedirect={loginRedirect}
                  deleteFavourite={deleteFavourite}
                  toggleFavoriteModal={toggleFavoriteModal}
                  renderMeta={renderBlogMeta(listItem)}
                  thumbnail={listItem.image ? convertQuality(listItem.image, 'thumb') : (!isEmpty(listItem.content) ? getMarkdownImage(listItem.content, 'thumb')[1] : '')}
                  showTag={isNewTagStatus({ date: listItem.updated_at, isISOFormat: true })}
                />
              </li>
            ))
          }
        </ul>
      </HorizontalListWithAction>
    </div>
  )
}

export default VerticalSplicedScreen