import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const LapIcon = (props) => {
  return (
    <>
      <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.49985 7C7.49985 5.62 8.61985 4.5 9.99985 4.5C11.3798 4.5 12.4998 5.62 12.4998 7C12.4998 8.38 11.3798 9.5 9.99985 9.5C9.33985 9.5 8.69985 9.24 8.22985 8.77C7.75985 8.3 7.49985 7.67 7.49985 7ZM0.499849 15H17.4998C17.7398 15 17.9398 14.83 17.9898 14.6L20.7298 1.81C20.8198 1.37 20.7099 0.9 20.4298 0.55C20.1398 0.2 19.7198 0 19.2598 0H4.30985C3.59985 0 2.98985 0.5 2.83985 1.19L0.00984928 14.4C-0.0201507 14.55 0.0198493 14.7 0.109849 14.82C0.209849 14.94 0.349849 15 0.499849 15Z" fill={themeTextRed} />
        <path d="M23.5 16.5H0.5C0.22 16.5 0 16.73 0 17V18C0 19.38 1.12 20.5 2.5 20.5H21.5C22.16 20.5 22.8 20.24 23.27 19.77C23.74 19.3 24 18.67 24 18V17C24 16.73 23.78 16.5 23.5 16.5Z" fill={themeTextRed} />
      </svg>
    </>
  )
}
export default LapIcon;
