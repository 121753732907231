import axios from 'axios'
import { showToastMessage } from '../actions'
import { put, takeEvery, call, select, delay } from 'redux-saga/effects'
import { patch } from '../helpers/requests';
import { auth } from '../helpers/api';

import { getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';


function* task(action) {
  try {

    const token = yield select(getToken);

    const response = yield call(axios, patch(auth.changePaymentType(), { stripe_token: action.payload.stripeId }, '', token));

    yield put({
      type: actions.CHANGE_PAYMENT_TYPE_SUCCESS,
      payload: {
        success: true,
        membership: response.data.membership
      }
    })
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in changing payment method. Please try with a different car`, 'error'));

    yield put({
      type: actions.CHANGE_PAYMENT_TYPE_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addToGroceryList() {
  yield takeEvery(actions.CHANGE_PAYMENT_TYPE_REQUEST, task)
}