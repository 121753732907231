
import React from 'react';
import { Row, Col } from "react-flexbox-grid";
import Button from "../common/button";
import KatiesCornerBanner from '../images/KatiesCornerBanner.png';
import { TransitionEaseInOne } from "../common/transitions";

const KatiesConerNavigation = (props) => {
    const url = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }

    let actionText = null;
    let actionLink = null;

    if (!props.userPlanOrder) {
        actionText = 'Signup';
        actionLink = '/pricing'
    } else if (props.userPlanOrder !== 3) {
        actionText = 'Upgrade to Nourish';
        actionLink = '/account/membership'
    } else if (props.userPlanOrder === 3) {
        actionText = 'Read More';
        actionLink = '/nourish/about'
    }

    return (
        <>
            <TransitionEaseInOne>
                <section
                    className="home-hero katies-corner-hero"
                    style={{
                        backgroundImage: `url(${KatiesCornerBanner})`
                    }}
                >
                    <div className="container">

                        <Row className="home-hero-wrapper home-hero-heading no-margin-sm">
                            <Col xs={12} md={12} lg={8} className="no-padding-sm">
                                <div className="home-hero-text">

                                    <h1>
                                        Nourish by Dashing Dish
                                    </h1>
                                    <p>
                                        Nourish Defined: To provide with the food or other <br />substances necessary for growth, health, <br />and good condition.

                                    </p>
                                    <form className="hero-form">
                                        <Button
                                            btnClass="btn btn-primary btn-md"
                                            btnText={actionText}
                                            onClick={(e) => {
                                                navigateTo(actionLink)
                                            }}
                                        />
                                    </form>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </section>

            </TransitionEaseInOne>
        </>
    )
}

export default KatiesConerNavigation