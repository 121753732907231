import React, { useState, useEffect } from 'react';
import Spinner from 'react-spinner-material'

import CloseIcon from '../images/closeIcon';
import SearchIcon from '../images/SearchIcon';

let debouncer;

const ModalSearchInput = ({
  placeholder,
  parentWrapperClass,
  inputClass,
  searchTextForLastUpdate,
  onWordTypeComplete,
  onClickSearchClear,
  isFetching,
  textClearer
}) => { 
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch('');
  }, [textClearer])

  // useEffect(() => {
  //   setSearch(searchTextForLastUpdate);
  // }, [searchTextForLastUpdate])
  return (
    <div className={parentWrapperClass}>
      <input
        placeholder={placeholder}
        className={inputClass}
        onChange={e => {
          e.persist();
          setSearch(e.target.value);
          if (!debouncer) {
            debouncer = setTimeout(() => {
              if (e.target.value.length >= 3) {
                onWordTypeComplete(e.target.value)
              }
            }, 600)
          } else {
            clearTimeout(debouncer);
            debouncer = setTimeout(() => {
              if (e.target.value.length >= 3) {
                onWordTypeComplete(e.target.value)
              }
            }, 600)
          }
        }}
        onBlur={(e) => {
          if (search.length < 3) {
            setSearch(searchTextForLastUpdate)
          }
        }}
        value={search}
      />
      <div
        className="end-icon"
        onClick={() => {
          onClickSearchClear();
          setSearch('')
      }}>
        {
          search.length >= 3 ? (
           <CloseIcon />
          ) : (
            <SearchIcon />
          )
        }
      </div>
    </div>
  )
}


ModalSearchInput.defaultProps = {
  placeholder: 'Search',
  parentWrapperClass: 'input-wrapper',
  inputClass: 'search'
}

export default ModalSearchInput