import createReducer from "../helpers/createReducers";

import { fromJS, List, update } from "immutable";
import getServingCountFromActiveVariant from "../helpers/getServingCountFromActiveVariant";

// TODO: all action payload.data should have a type
const initialState = fromJS({
  currentServing: '4',
});

export default createReducer(initialState, {

  UPDATE_CURRENT_SERVING: (state, action) => {
    state = state.setIn(['currentServing'], action.payload)
    return state
  },
  PUSH_OBJECT_TO_STACK: (state, action) => {
    if (action.payload.objectType === "recipe") {

      let serving = (
        action.payload &&
        action.payload.data &&
        action.payload.data.variations &&
        action.payload.data.variations.length > 0
      ) ? getServingCountFromActiveVariant(action.payload.data.variations[0].nutrition) : 4;
      state = state.setIn(["currentServing"], serving);
    }
    return state
  },
  UPDATE_OBJECT_TO_STACK: (state, action) => {
    if (action.payload.objectType === "recipe") {

      let serving = (
        action.payload &&
        action.payload.data &&
        action.payload.data.variations &&
        action.payload.data.variations.length > 0
      ) ? getServingCountFromActiveVariant(action.payload.data.variations[0].nutrition) : 4;
      state = state.setIn(["currentServing"], serving);
    }
    return state
  }

});