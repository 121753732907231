import React, { useEffect, useState, useReducer } from "react";
import { withRouter } from "react-router-dom";
import TopBanner from "../topBanner";
import shuffle from "lodash/shuffle";
import cx from "classnames";
import { StickyContainer, Sticky } from "react-sticky";
import includes from "lodash/includes";
import Logo from "../../images/Logo";
import NavList from "../navList";
import NavbarDropdown from "../navList/navbarDropdown";
import "../../styles/common.scss";
import HamburgerItem from "./HamburgerItem";
import UserAvatar from "./UserAvatarMobile";
import { getUpcomingMeetings } from "../../../helpers/api";

const listener = null;
const navInitialState = {
  activeHovered: null,
  entry: false,
};
const navbarReducer = (state, action) => {
  switch (action.type) {
    case "HOVER_ON_NAVITEM":
      return {
        ...state,
        activeHovered: action.payload.hoveredOn,
        entry: true,
      };
    case "BLUR_ON_NAVITEM":
      return {
        ...state,
        activeHovered: null,
        entry: false,
      };
    default:
      return state;
  }
};

const showCouponBanner = "false";

const heroSections = shuffle([
  // {
  //   index: 1,
  //   type: "40-percent-off",
  // },
  {
    index: 2,
    type: "weekly-recipes-and-mealplans",
  },
  {
    index: 3,
    type: "one-click-meal-planning",
  },
  {
    index: 4,
    type: "weekly-recipes-and-meal-plans-1",
  },
  {
    index: 5,
    type: "weekly-recipes-and-meal-plans-2",
  },
]);

const NavBar = (props) => {
  const [showNavList, setShowNavList] = useState(true);
  const [windowWidth, setWindowWidth] = useState(undefined);
  // setting initial value to false
  const [scroll, setScroll] = useState(false);
  const [navState, navDispatch] = useReducer(navbarReducer, navInitialState);

  const [topBanner, setTopBanner] = useState(true);
  const [hasMeeting, setHasMeeting] = useState(false);
  const [activeMeetings, setActiveMeetings] = useState([]);
  useEffect(() => {
    if (
      !props.isAuthenticating &&
      !props.isAuthenticated &&
      props.isAuthenticationFailed
    ) {
      setTimeout(() => {
        localStorage.setItem("couponBanner", showCouponBanner);
        let header = document.querySelector("header");
        let content = document.querySelector("#content");
        const showAppBanner = localStorage.getItem("appBanner") === "true";
        let headerTop = 0;
        let contentPaddingTop = 0;
        if (showCouponBanner === "true") {
          headerTop += 48;
          // contentPaddingTop += 48;
        }
        if (showAppBanner === "true") {
          headerTop += 60;
          contentPaddingTop += 60;
        }
        if (header && content) {
          header.style.position = "sticky";
          header.style.top = `${headerTop}px`;
          content.style.paddingTop = `${contentPaddingTop}px`;
        }

        setTopBanner(true);
      }, 10);



    } else {
      let header = document.querySelector("header");
      let content = document.querySelector("#content");
      const showAppBanner = localStorage.getItem("appBanner") === "true" || (props.isAuthenticated && hasMeeting);
      let headerTop = 0;
      let contentPaddingTop = 60;

      const navbarShouldFix = true;
      // !props.isAuthenticated ||
      // (!includes(window.location.href, "/recipes") &&
      //   !includes(window.location.href, "/workouts") &&
      //   !includes(window.location.href, "/blog/preview") &&
      //   !includes(window.location.href, "/videos"));

      if (header && content) {
        header.style.position = navbarShouldFix ? "fixed" : "absolute";

        header.style.top = `${headerTop}px`;
        content.style.paddingTop = `${contentPaddingTop}px`;
      }

      // Should not be there when there is an app banner
      // instead this should be padding top: 0Px when there is an app banner
      localStorage.setItem("couponBanner", "false");
      setTopBanner(false);
    }
  }, [
    props.isAuthenticated,
    props.isAuthenticating,
    props.isAuthenticationFailed,
  ]);
  useEffect(() => {
    const onScroll = () => {
      // Logic is false tell user reaches threshold, then true after.
      const scrollCheck = window.scrollY >= 100;
      if (scrollCheck !== scroll) {
        setScroll(scrollCheck);
      }
    };

    // setting the event handler from web API
    document.addEventListener("scroll", onScroll);

    // cleaning up from the web API
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [scroll, setScroll]);

  useEffect(() => {
    if (window.innerWidth < 767) {
      setShowNavList(false);
      setWindowWidth(window.innerWidth);
    } else if (window.innerWidth > 767) {
      setShowNavList(true);
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 767) {
        setShowNavList(false);
        setWindowWidth(window.innerWidth);
      } else if (window.innerWidth > 767) {
        setShowNavList(true);
        setWindowWidth(window.innerWidth);
      }
    });
  }, []);
  var showStickyBanner;
  {
    window.innerWidth > 767
      ? (showStickyBanner = scroll)
      : (showStickyBanner = false);
  }
  const navbarShouldFix =
    !props.isAuthenticated ||
    (!includes(window.location.href, "/recipes") &&
      !includes(window.location.href, "/workouts") &&
      !includes(window.location.href, "/blog/preview") &&
      !includes(window.location.href, "/videos"));

  // Add new useEffect for fetching meetings
  useEffect(() => {
    const checkForMeetings = async () => {
      try {
        const response = await getUpcomingMeetings();
        if (response.data.upcoming_meetings && response.data.upcoming_meetings.length > 0) {
          const activeMeetings = response.data.upcoming_meetings.filter(meeting => meeting.active);
          setHasMeeting(activeMeetings.length > 0);
          setActiveMeetings(activeMeetings);
        }
      } catch (error) {
        console.error('Error fetching upcoming meetings:', error);
      }
    };

    checkForMeetings();
  }, []);

  if (
    props.location.pathname.split("/")[
    props.location.pathname.split("/").length - 1
    ] !== "print"
  )
    return (
      <>
        {(showCouponBanner === "true" || (props.isAuthenticated && hasMeeting)) ? (
          <TopBanner topBanner={topBanner || (props.isAuthenticated && hasMeeting)} setTopBanner={setTopBanner} activeMeetings={activeMeetings} isAuthenticated={props.isAuthenticated} />
        ) : null}

        <header
          className={cx({ bannered: (topBanner || (props.isAuthenticated && hasMeeting)) })}
          style={{ position: navbarShouldFix ? "fixed" : "absolute" }}
          onMouseLeave={(e) => {
            navDispatch({
              type: "BLUR_ON_NAVITEM",
              payload: {},
            });
          }}
        >
          <div className="container">
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();

                // props.isAuthenticated
                //   ? props.history.push(`/?type=${heroSections[0].type}`)
                //   : props.history.push(`/?type=mothers-day`);
                props.history.push(`/?type=${heroSections[0].type}`);
              }}
            >
              <Logo />
            </a>
            <UserAvatar
              windowWidth={windowWidth}
              setShowNavList={setShowNavList}
              groceryListItemCount={props.groceryListItemCount}
              groceryListFetchStatus={props.groceryListFetchStatus}
              {...props}
            />
            <HamburgerItem
              showNavList={showNavList}
              onClick={() => setShowNavList(!showNavList)}
            ></HamburgerItem>
            <nav
              className={`collapse ${showNavList ? "show" : ""} ${showStickyBanner ? "center-align" : ""
                }`}
            >
              <NavList
                windowWidth={windowWidth}
                setShowNavList={setShowNavList}
                groceryListItemCount={props.groceryListItemCount}
                groceryListFetchStatus={props.groceryListFetchStatus}
                showStickyBanner={showStickyBanner}
                navState={navState}
                navDispatch={navDispatch}
                activeRecipe={props.activeRecipe}
                activeWorkout={props.activeWorkout}
                activeBlog={props.activeBlog}
                requestCloseNavDropdown={() => {
                  navDispatch({
                    type: "BLUR_ON_NAVITEM",
                    payload: {},
                  });
                }}
                {...props}
              />
              {window.innerWidth > 767 ? (
                <NavbarDropdown
                  activeHoveredNavItem={navState.activeHovered}
                  // activeParentNavItem={navListItem.title}
                  activeHoveredEntry={navState.entry}
                  userDetails={props.userDetails}
                  history={props.history}
                  activeRecipe={props.activeRecipe}
                  activeWorkout={props.activeWorkout}
                  activeBlog={props.activeBlog}
                  recipeFilters={props.recipeFilters}
                  workoutBodyParts={props.workoutBodyParts}
                  videoCategories={props.videoCategories}
                  blogFeaturedList={props.blogFeaturedList}
                  latestRecipe={props.latestRecipe}
                  mealPlanCategories={props.mealPlanCategories}
                  isAuthenticated={props.isAuthenticated}
                  requestCloseNavDropdown={() => {
                    navDispatch({
                      type: "BLUR_ON_NAVITEM",
                      payload: {},
                    });
                  }}
                />
              ) : null}
            </nav>
          </div>
        </header>
      </>
    );
  else return null;
};

export default withRouter(NavBar);
