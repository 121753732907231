import React from "react";

import map from "lodash/map";

const NavbarImageCard = ({ list, render, history, onClose }) => {
  return (
    <ul className="navbar-image-card">
      {map(list, item => (
        <li>
          <a
            href={item.href}
            onClick={e => {
              e.preventDefault();
              history.push(item.href);
              onClose();
            }}
          >
            {render(item)}
            <div className="card-content-wrapper">
              <div className="title">{item.title}</div>
              <div className="description">{item.description}</div>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
};

NavbarImageCard.defaultProps = {
  title: "Workout",
  list: [
    {
      href: "/recipes",
      title: "Most Popular"
    },
    {
      href: "/recipes",
      title: "BLD Plans"
    },
    {
      href: "/recipes",
      title: "Gluten Free"
    },
    {
      href: "/recipes",
      title: "6 Ingredient"
    }
  ]
};

export default NavbarImageCard;
