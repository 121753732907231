import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  appEssentialFetch,
} from "../../actions";

const CookiePolicyPage = (props) => {

  useEffect(() => {
    props.appEssentialFetch();
  }, [])

  return (
    <main>
      <Helmet>
        <title>Cookie Policy | Dashing Dish</title>
        <meta name="description" content="Dashingdish cookie policy page" />
        <script type="text/javascript">{`(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);`}</script>
      </Helmet>
      <div className="container">
        <a href="https://www.iubenda.com/privacy-policy/516411/cookie-policy" class="iubenda-white no-brand iubenda-embed iub-body-embed" title="Cookie Policy">Cookie Policy</a>
      </div>
    </main>
  )
}

const mapStateToProps = () => ({

})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(CookiePolicyPage);