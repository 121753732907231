// @flow
import React, { useState, useEffect } from "react";
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import isNull from "lodash/isNull";
import axios from 'axios';
import { Redirect } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { Row, Col } from 'react-flexbox-grid';
import isNil from "lodash/isNil";
import { getRegisterStatus, getAuthStatus } from '../../../selectors'
import { createUser, showToastMessage, resetRegisterState, setInitiateRegisterEmail } from '../../../actions'
import toJS from '../../../helpers/toJS';
import CustomInput from "../../common/customInput";
import Checkbox from "../../common/checkbox";
import Button from "../../common/button";
import Radiobox from '../../common/radiobox';
import FrameIcon from '../../images/frameIcon'
import katie from '../../images/register-katie.png'
import { shareUrl, user } from '../../../helpers/api';
import {
  TransitionEaseInOne,
} from "../../common/transitions"

type Props = {
  createUser: (name: string, email: string, password: string) => void,
  appToken: number,
  history: Object,
  registerStatus: { errorMessage: string, isRegistered: boolean, isRegistering: boolean }
};
const Register = (props: Props) => {
  const {
    createUser,
    registerStatus,
    unifiedRegisterState,
    showToastMessage
  } = props;
  const defaultEmail = props.location.search ? props.location.search.split('=')[1] : '';
  const { isLoggedIn, isLoginFailed } = props.loginStatus;
  const [email, setEmail] = useState(null);
  const [isEmailServerValidated, setEmailServerValidation] = useState(false);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loginError, setLoginError] = useState(props.registerStatus.errorMessage);
  const [nameFocusedOnce, setNameOnceFocusState] = useState(false);
  const [emailFocusedOnce, setEmailOnceFocusState] = useState(defaultEmail ? true : false);
  const [passwordFocusedOnce, setPasswordOnceFocusState] = useState(false);
  const emailError = false;
  const [errors, setErrors] = useState({
    email: emailError ? 'Please enter a valid email id' : null,
    password: null,
    name: null
  });
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  // gift data state
  const [giftedRegistration, setGiftedRegistration] = useState({
    isGifted: false,
    toEmail: null
  })
  useEffect(() => {
    if (!isNil(registerStatus.giftCode) && !isNil(registerStatus.giftCode.giftCode) && !isNil(registerStatus.giftCode.toEmail)) {
      setGiftedRegistration({ isGifted: true, toEmail: registerStatus.giftCode.toEmail })
      setEmail(registerStatus.giftCode.toEmail);
      setName(registerStatus.giftCode.toName)
    }
  }, registerStatus.giftCode)
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      localStorage.removeItem('token');
    }
  }, []);
  useEffect(() => {
    // props.resetRegisterState()
    const queryParam = props.location.search;
    if (!unifiedRegisterState.planId || !unifiedRegisterState.duration) {
      props.history.push(`/pricing${queryParam}`);
    }

  }, [])
  useEffect(() => {
    if (registerStatus.isRegistered) {
      if (giftedRegistration.isGifted) {
        props.history.push('/recipes');
      } else {
        props.history.push("pricing")
      }
    }
  }, [registerStatus.isRegistered])
  useEffect(() => {
    checkName();
    setLoginError(null);
  }, [name]);
  useEffect(() => {
    checkEmail();
    setLoginError(null);
  }, [email]);
  useEffect(() => {
    checkPassword();
    setLoginError(null);
  }, [password]);
  useEffect(() => {
    props.resetRegisterState()
  }, [])
  useEffect(() => {
    if (isLoggedIn) {
      props.history.push('/recipes')
    } else if (isLoginFailed) {
      setLoginError(registerStatus.errorMessage)
    }
  }, [isLoggedIn, isLoginFailed])
  useEffect(() => {
    if (props.registerStatus.errorMessage) {
      setLoginError(registerStatus.errorMessage)
    }
  }, [props.registerStatus.errorMessage])

  const serverValidateEmailRequest = () => {
    axios({
      method: "POST",
      url: user.verifyEmail(),
      data: {
        email: email || "",
      },
    })
      .then((response) => {
        if (response.data.valid) {
          // console.log('valid...');
          setEmailServerValidation(true);
          setErrors({ ...errors, email: null });
          props.setInitiateRegisterEmail(email);
          props.history.push(`/register`)
        }
      })
      .catch((e) => {
        // console.log('invalid...');
        setEmailServerValidation(false);
        setErrors({ ...errors, email: "This email is already taken" });
      });
  };

  const checkName = () => {
    if (name.length < 3 || name.length > 35) {
      setErrors({ ...errors, name: 'Name length should be between 3 - 35 letters' });
    } else {
      setErrors({ ...errors, name: null });
    }
  }
  const checkEmail = () => {
    if (!(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(email))) {
      setErrors({ ...errors, email: 'Enter a valid email Id' });
    } else {
      setErrors({ ...errors, email: null });
    }
  }
  const checkPassword = () => {
    if (password.length < 3) {
      setErrors({ ...errors, password: 'Password length should be more than 3 character' });
    } else {
      setErrors({ ...errors, password: null });
    }
  }
  const validated = () => {
    return isNull(errors.email);
  }


  return (
    <>
      <section
        className="login-section">
        <Helmet>
          <title>Register | Dashing Dish</title>
          <meta name="description" content="Dashingdish Register page" />
        </Helmet>
        <Row className="no-margin">
          <Col xs={12} md={12} lg={6} className="no-padding-sm">
            <TransitionEaseInOne>
              <div className="login-wrapper">
                <div className="loginBlock">
                  <h1>{giftedRegistration.isGifted ? "Claim Gift" : "Enter your email"}</h1>
                  <form onSubmit={e => {
                    e.preventDefault();
                  }}>
                    {/* <div className="email-register-title">Sign up by email</div> */}

                    <CustomInput
                      type="email"
                      name="email"
                      onChange={event => { setEmail(event.target.value); checkEmail(); }}
                      placeholder="Type here"
                      value={giftedRegistration.isGifted ? giftedRegistration.toEmail : email}
                      className="error"
                      errorMessage={emailFocusedOnce ? errors.email : null}
                      onBlur={() => {
                        setEmailOnceFocusState(true);
                        checkEmail();

                      }}
                      label="Email"
                      disabled={giftedRegistration.isGifted ? true : false}
                    />
                    <div className="globalErrorWrapper">
                      {
                        loginError &&
                        <div className="errorText globalErrorText">
                          <FrameIcon></FrameIcon>
                          <p>
                            {loginError}
                          </p>
                        </div>
                      }
                    </div>
                    <Button
                      type="button"
                      disabled={!validated() || registerStatus.isRegistering}
                      isLoading={registerStatus.isRegistering}
                      btnClass="primary btn-md"
                      btnText={giftedRegistration.isGifted ? "Claim Gift" : "Next: Complete Registration"}
                      onClick={(e) => {
                        e.preventDefault()
                        serverValidateEmailRequest();
                      }} />
                    {/* <p className="signing-text">By signing up you agree to our <a href={`${shareUrl}/terms-conditions`}> terms and conditions </a> and our <a href={`${shareUrl}/privacy-policy`}>privacy policy.</a></p> */}
                  </form>
                  <p className="login-anchor">Already have an account?  <a href={'/login'} onClick={(e) => { e.preventDefault(); props.history.push("/login") }}>Log in here</a></p>
                </div>
              </div>
            </TransitionEaseInOne>
          </Col>
          <TransitionEaseInOne>
            <Col xs={12} md={12} lg={6} className="image-bg-wrapper"
              style={{
                backgroundImage: `url(${katie})`
              }}
            >
            </Col>
          </TransitionEaseInOne>
        </Row>
      </section >
    </>
  );
};
const mapStateToProps = state => ({
  registerStatus: getRegisterStatus(state),
  unifiedRegisterState: state.getIn(["unifiedRegister"]),
  loginStatus: getAuthStatus(state),
})
const dispatchActionToProps = dispatch => ({
  createUser: bindActionCreators(createUser, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  resetRegisterState: bindActionCreators(resetRegisterState, dispatch),
  setInitiateRegisterEmail: bindActionCreators(setInitiateRegisterEmail, dispatch)
})
export default connect(mapStateToProps, dispatchActionToProps)(toJS(Register));