
import { put, takeEvery, call, select } from 'redux-saga/effects';
import axios from 'axios'
import { get } from '../helpers/requests'
import pushToSentry from '../helpers/pushToSentry';
import * as actions from '../actions/actionTypes';
import {
  getToken,
  getRecipeDietaries,
  getRecipeFoodTypes,
  getRecipeSpecialities,
} from './selectors'
import { generateRecipeQueryFromFilter } from '../helpers/filterConversions'
import { searches } from '../helpers/api';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_RECIPES_SUCCESS
function* task(action) {
  try {

    const recipeFoodTypes = yield select(getRecipeFoodTypes);

    const recipeDietaries = yield select(getRecipeDietaries);

    const recipeSpecialities = yield select(getRecipeSpecialities)

    const queryParam = generateRecipeQueryFromFilter(
      action.payload.filters,
      recipeFoodTypes,
      recipeDietaries,
      recipeSpecialities,
    );

    const token = yield select(getToken)

    let filterCountResponse = yield call(axios, get(
      searches.filterCount(queryParam.substring(1)), // Remove leading &
      '',
      token
    ))

    yield put({
      type: actions.RECIPE_FILTER_COUNT_SUCCESS,
      payload: { 
        totalCount: filterCountResponse.data.count
      }
    });

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.RECIPE_FILTER_COUNT_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* search() {
  yield takeEvery(actions.RECIPE_FILTER_COUNT, task)
}