import { put, takeEvery, call, select } from 'redux-saga/effects'
import {
  showToastMessage
} from '../actions';

// import { Sentry } from 'react-native-sentry';
import * as actions from '../actions/actionTypes'
import groceryTask from './subtask/grocery'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {

    yield call(groceryTask.delete)

    yield put({
      type: actions.CLEAR_ALL_GROCERY_LIST_ITEMS_SUCCESS,
      payload: {
        success: true,
      }
    })
    yield put(showToastMessage('😎 Groceries cleared.', 'success', 'clearGroceryList'));
  } catch (e) {
    yield put(showToastMessage('❗️ Error in clearing groceries', 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CLEAR_ALL_GROCERY_LIST_ITEMS_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* createBoard() {
  yield takeEvery(actions.CLEAR_ALL_GROCERY_LIST_ITEMS, task)
}