import React, { useState, useEffect } from 'react';

import ModalWrapper from './modal';
import Button from './button';

const GroceryListThresholdModal = ({
  removeGroceryListLimitNotification,
  groceryListThreshold,
  replaceIngredients
}) => {
  const [state, setState] = useState({
    showModal: false
  });
  useEffect(() => {
    setState({ ...state, showModal: groceryListThreshold.status });
  }, [groceryListThreshold.status]);
  const onRequestClose = () => {
    setState({ ...state, showModal: false });
    removeGroceryListLimitNotification()
  }
  return (
    <ModalWrapper
      isOpen={state.showModal}
      onRequestClose={onRequestClose}
      maxHeight="480px"
      maxWidth="500px"
    >
      <div className="modalWrapper">
        <h3 className="modalHeader add-save-modal-header" style={{ position: 'sticky', top: '0px' }}>
          You have exceeded the maximum limit for grocery list items. Do you want to replace them with the new ingredients ?
        </h3>
        <div className="modalFooter">
          <Button
            btnClass="primary btn-full"
            btnText="Replace"
            onClick={() => replaceIngredients()}
          />
          <Button
            btnClass="secondary btn-full btn-bp-sm"
            btnText="Cancel"
            onClick={() => onRequestClose()}
          />
        </div>
      </div>
    </ModalWrapper>
  )
}

export default GroceryListThresholdModal;