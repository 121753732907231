import React, { useEffect, useState } from 'react'
import isNull from 'lodash/isNull'

import cx from 'classnames'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CircledPlus from '../images/CircledPlus'
import DottedAction from '../groceryList/components/DottedAction';
import {
  createFavourite,
  deleteFavourite,
  addMealToMultipleDays,
  addAllToGroceryList,
} from "../../actions";

import {
  calendarListData,
  getCalendarMacros
} from '../../selectors/calendar'

import Modalwrapper from '../common/modal';
import AddToMealPlan from '../common/addToMealPlan';

import getRecipeImage from '../../helpers/getRecipeImage';

import toJS from '../../helpers/toJS';

const ref = React.createRef()

const GridCardAction = ({
  show,
  setCardActionState,
  objectId,
  objectType,
  favorite,
  deleteFavorite,
  toggleFavoriteModal,
  item,
  setMacros,
  calendarList,
  addMealToMultipleDays,
  addAllToGroceryList,
  showAction
}) => {


  const [mealPlanModalState, setMealPlanModalState] = useState({
    show: false
  })

  useEffect(() => document.addEventListener('mousedown', handleClick, false), []);

  useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', handleClick, false);
    }
  }, [])


  const handleClick = (e) => {
    // if it is outside and also outside of 3 dots
    if (!isNull(ref) && !isNull(ref.current) && !ref.current.contains(e.target)) {
      setCardActionState({
        show: false
      })
    }
  }

  const getActions = (objectId, objectType, isFavorite) => {
    const resultAction = ([
      {
        label: `${isFavorite ? 'Remove from' : 'Add to'} favorites`,
        action: isFavorite ? () => deleteFavorite(objectId, objectType, favorite.thumbnail) : () => {
          toggleFavoriteModal({
            itemId: objectId,
            modalState: true
          })
        }
      },
      {
        label: 'Add to calendar',
        action: () => setMealPlanModalState({ show: true })
      },
    ]);

    if (objectType === 'recipe') {
      resultAction.push({
        label: 'Add to grocery list',
        action: () => {
          addAllToGroceryList(item.ingredients_v2, objectId);
        }
      });
    }

    return resultAction;
  }

  return (
    <div
      className={cx("grid-card-action", { 'show': showAction })}
      show={show}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        setCardActionState({
          show: !show
        })
      }}
    >

      <figure className={cx("action-image", { rotate: show })}>
        <CircledPlus
        />
      </figure>

      {
        show ? (
          <DottedAction
            displayDropdown={show}
            additionalActionClass={`${objectType}-action`}
            options={getActions(objectId, objectType, favorite.isFavorite)}
            setDisplayDropdown={value => {
              setCardActionState({ show: value })
            }}
            ref={ref}
          />
        ) : null
      }
      <Modalwrapper
        maxWidth='540px'
        minHeight='600px'
        // overFlowY="hidden"
        isOpen={mealPlanModalState.show}
        onRequestClose={() => setMealPlanModalState({ show: false })}
      >
        <AddToMealPlan
          objectType={objectType}
          activeObject={item}
          calendarList={calendarList}
          displayImage={getRecipeImage(item, 'full_sm')}
          thumbnail={getRecipeImage(item, 'square_400')}
          setMacros={setMacros}
          mealPlanModalToggleHandler={value => setMealPlanModalState({ show: value })}
          addMealToMultipleDays={addMealToMultipleDays}
        />
      </Modalwrapper>
    </div>
  );
}

const mapStateToProps = state => ({
  calendarList: calendarListData(state),
  setMacros: getCalendarMacros(state),
});

const dispatchActionToProps = dispatch => ({
  createFavorite: bindActionCreators(createFavourite, dispatch),
  deleteFavorite: bindActionCreators(deleteFavourite, dispatch),
  addMealToMultipleDays: bindActionCreators(addMealToMultipleDays, dispatch),
  addAllToGroceryList: bindActionCreators(addAllToGroceryList, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(GridCardAction));