import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HeroImg from '../images/hero-image.png';
import IstoreIcon from '../images/Istore';
import AppStore from '../images/AppStore';
import GooglePlay from '../images/GooglePlay';
import PlayStoreIcon from '../images/PlayStore';

import { appEssentialFetch } from "../../actions";
import { TransitionOne } from '../common/transitions';

const ApplicationPage = (props) => {
  useEffect(() => {
    props.appEssentialFetch();
  }, [])

  return (
    <main>
      <Helmet>
        <title>App | Dashing Dish</title>
        <meta name="description" content="Dashingdish Application page" />
      </Helmet>
      <section
        className="application-page"
        style={{
          backgroundImage: `url(${HeroImg})`
        }}>
        <div className="container">
          <TransitionOne>
            <div className="application-hero-wrapper">
              <h3>Everything you need to live a healthy<br></br>
                lifestyle in the palm of your hand</h3>
              <p>Take Dashing Dish with you on the go with our fully integrated app for both iPhone</p>
              <div className="application-links">
                <ul className="unstyle-links">
                  <li>
                    <a href="https://apps.apple.com/us/app/dashing-dish/id977480745" target="_blank">
                      <figure>
                        <IstoreIcon></IstoreIcon>
                      </figure>
                      <figure>
                        <AppStore></AppStore>
                      </figure>
                    </a>
                  </li>

                </ul>
              </div>
            </div>
          </TransitionOne>
        </div>
      </section>
    </main>
  )
}

const mapStateToProps = () => ({})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(ApplicationPage);