import React, { useRef } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import Avatar from './avatar';
import map from "lodash/map";
import { shareUrl } from "../../helpers/api";
import { TransitionOne, TransitionTwo } from '../common/transitions';

const Referral = ({
  userDetails,
  history,
  showToastMessage
}) => {
  const referralCode = useRef(null);
  const {
    details: {
      avatar = {},
      member_since: memberSince,
      membership,
      plan,
      name,
      referral_code,
      referred_by_users
    }
  } = userDetails;

  const getReferedUserAccountStatus = (user) => {
    switch (user.account_status) {
      case 'active':
        return "Paid";

      default:
        return user.account_status

    }
  }

  return (
    <div className="account-content-wrapper">
      <div className="membership-wrapper">
        <Row className="no-margin">
          <TransitionOne>
            <Col xs={12} lg={3} className="account-content-left-wrapper">
              <Avatar
                src={avatar.medium}
                memberSince={memberSince}
                name={name}
              />
            </Col>
          </TransitionOne>
          <TransitionTwo>
            <Col xs={12} lg={9} className="account-content-right-wrapper">
              <h3>Referral Info</h3>
              <div className="referral-info">
                <h5>{`Share Dashing Dish with a friend`} {membership && membership.origin === 'stripe' ? `and get a month free!` : '.'}</h5>
                <p className="detail feature">Living healthy is better with friends.{membership && membership.origin === 'stripe' ? ` Each time someone signs up for a Dashing Dish membership using your special code, you get a free month credited to your account.` : '.'}</p>
              </div>
              <div className="referral-wrapper">
                <div className="referral-input">
                  <span className="code-text">{"Referral code"}</span>
                  <input
                    ref={referralCode}
                    value={`${shareUrl}/referral/${referral_code}`}
                  />
                </div>
                <span
                  className="copy-text"
                  onClick={(e) => {
                    referralCode.current.select();
                    document.execCommand("copy");
                    showToastMessage("✅ copied !", "success");
                  }}
                >
                  {"Copy"}
                </span>
              </div>
              <div className="referrals">
                <h3>Referrals</h3>
                <div className="referrals-lists">
                  {
                    referred_by_users && referred_by_users.length > 0 ?
                      map(referred_by_users, item => {
                        return (
                          <div
                            className="referrals-list">
                            <span>{item.email}</span>
                            <span>{getReferedUserAccountStatus(item)}</span>
                          </div>
                        )
                      }) : <p> No referrals </p>

                  }
                </div>
              </div>
            </Col>
          </TransitionTwo>
        </Row>
      </div >
    </div >
  )
}

export default Referral