import React from "react";
import { generateAppEssentialPayload } from '../../../helpers/filterConversions';
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'

// generates the selected filters
export const generateFilterFromQueryString = (objectType, history) => {

  const filterFromQueryString = generateAppEssentialPayload({
    objectType: 'blog',
    queryString: history.location.search
  })

  return filterFromQueryString
}

export const getBlogCategories = (categories, history) => {

  const categoriesOptions = categories.map(category => {

    let filterFromQueryString = generateFilterFromQueryString('blog', history)
    const isSelected = filterFromQueryString.blog.filters.categories.findIndex(slug => isEqual(slug, category.slug))
    let obj = {
      label: category.name,
      value: category.id,
      isSelected: isSelected !== -1,
      slug: category.slug,
      image: category.icon,
      featuredImage: category.image,
    }
    return obj
  })


  return categoriesOptions
}


export const getBlogArchives = (history) => {
  let filterFromQueryString = generateFilterFromQueryString('blog', history)

  const isSelected = parentSlug => filterFromQueryString.blog.filters.archives.findIndex(slug => isEqual(slug, parentSlug))

  return [
    {
      label: '2014',
      value: '2014',
      isSelected: isSelected('2014') !== -1,
      slug: '2014',
    },
    {
      label: '2015',
      value: '2015',
      isSelected: isSelected('2015') !== -1,
      slug: '2015',
    },
    {
      label: '2016',
      value: '2016',
      isSelected: isSelected('2016') !== -1,
      slug: '2016',
    },
    {
      label: '2017',
      value: '2017',
      isSelected: isSelected('2017') !== -1,
      slug: '2017',
    },
    {
      label: '2018',
      value: '2018',
      isSelected: isSelected('2018') !== -1,
      slug: '2018',
    },
    {
      label: '2019',
      value: '2019',
      isSelected: isSelected('2019') !== -1,
      slug: '2019',
    },
  ]
}

// genarated the favs for reicpe filters nav
export const getFavouriteBoardsNavOptions = (boards, blogFilterName) => {
  const favouriteBoardsNavOptions = boards.map(board => {
    const isSelected = (board.slug === blogFilterName.slug || (board.slug === null && blogFilterName.title === 'All')) ? true : false

    let obj = {
      name: board.title,
      value: board.id,
      isSelected: isSelected,
      totalCount: board.total_count,
      slug: board.slug
    }
    return obj
  })

  return favouriteBoardsNavOptions
}

// generates the filer options like categories , collections .....
export const getFilterOptions = (
  categories,
  archives,
  history
) => {
  let filterFromQueryString = generateFilterFromQueryString('blog', history)
  const filterOptions = [
    {
      title: 'Categories',
      options: categories,
      isOptionsList: true, // to differentiate between mappable list like collections , and non mappable like wightWatchers
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.blog.filters.categories.length === 0 ? false : true
    },
    {
      title: 'Archives',
      options: archives,
      isOptionsList: true,
      additionalDropdownWrapperClass: '',
      singleSelectable: true,
      isSelected: filterFromQueryString.blog.filters.archives.length === 0 ? false : true
    },
  ]

  return filterOptions
}

// generated the fav options
export const getFavoriteFilterOptions = (favouriteBoardsNavOptions, blogFilterName) => {

  const favoriteFilterOptions = {
    title: 'Bookmarks',
    options: favouriteBoardsNavOptions,
    isSelected: (blogFilterName.type === 'favorite') ? true : false
  }

  return favoriteFilterOptions
}

// generates the search -> search by list
export const getSelectorList = () => {
  const selectorList = [
    {
      title: 'By Title',
      value: 'title'
    },
  ]

  return selectorList
}





