import React, { useEffect, useState } from 'react'

import axios from 'axios'

import map from 'lodash/map'
import take from 'lodash/take'


import MealPlanGridItem from './mealPlanGridItem'
import { baseUrl } from '../../../helpers/api'
import GhostCover from '../../common/ghostCover'

import UpArrow from '../../images/UpArrow'
import DownArrow from '../../images/DownArrow'
import pushToSentry from '../../../helpers/pushToSentry';


const featuredData = [
  {
    name: '1 Day Plan',
    description: 'A good one day plan',
    slug: '1-day-plan',
  },
  {
    name: '3 Day Plan',
    description: 'A good one day plan',
    slug: '3-day-plan',
  },
  {
    name: '5 Day Plan',
    description: 'A good one day plan',
    slug: '5-day-plan',
  },
]

const FeaturedPlan = ({
  setMealPlanModalState,
  moveToIndex,
  addSentryError
}) => {
  const [premadeMealPlan, setPremadeMealPlan] = useState([]);
  const [show, setShow] = useState(false)
  useEffect(() => {
    axios(`${baseUrl}/api/v1/featured-meal-plans`, {
      headers: {
        Authorization: `Token token="${localStorage.getItem('token')}"`
      },
    })
      .then((response) => {
        setPremadeMealPlan(response.data.meal_plans)
      })
      .catch((e) => {
        const eventId = pushToSentry(e)
        addSentryError({
          error: e.message,
          eventId
        })
      });
  }, [])


  return (
    <ul className="responsive-featured-wrapper">
      <h6 onClick={() => { setShow(!show) }}>
        <span>
          View a premade meal plan
          {show ? <UpArrow /> : <DownArrow />}
        </span>
        <span className="see-all" onClick={() => setMealPlanModalState()}>
          See all
        </span>
      </h6>
      {
        show ? (
          <div className="collapse-content">
            <div className="content">
              {
                show ? map(take(premadeMealPlan, 3), plan => (
                  <li onClick={() => moveToIndex(plan)}>
                    <MealPlanGridItem
                      plan={plan}
                    />
                  </li>
                )) : null
              }
            </div>
          </div>
        ) : null
      }
    </ul>
  )

}

export default FeaturedPlan