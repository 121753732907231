import React from 'react';
import { map } from 'lodash';
import { withRouter } from 'react-router-dom'

import '../../styles/common.scss';

const WorkoutTags = (props) => {
  const {
    tags = [],
    history,
  } = props;
  return (
    (tags && tags.length) > 0 && (
      <div className="tags">
        <h2>Workout Tags</h2>
        <ul className="tagsLists">
          {
            map(tags, (tag, index) => {
              let tagType;
              if (tag.taxonomy_type === 'workout_type') {
                tagType = 'workoutTypes'
              } else if (tag.taxonomy_type === 'body_part') {
                tagType = 'bodyParts'
              } else if (tag.taxonomy_type === 'equipment') {
                tagType = 'equipments'
              }
              return (
                <li key={index} className="tagsList">
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (tagType === 'workoutTypes') {
                        history.push(`/workouts/?workout-types=${tag.slug}`);
                      } else if (tagType === 'equipments') {
                        history.push(`/workouts/?equipments=${tag.slug}`);
                      } else if (tagType === 'bodyParts') {
                        history.push(`/workouts/?bodyParts=${tag.slug}`);
                      }

                    }}
                    href={`/workouts/${tag.slug}`}>
                    {tag.name}
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  )
}

export default withRouter(WorkoutTags);    