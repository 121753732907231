import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from '../../../helpers/toJS';


import {
  appEssentialFetch,
  createFavourite,
  deleteFavourite,
  createBoard,
  setObjectBoards,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  setFiltersAndFetch,
  search,
  trendingTypeChange
} from "../../../actions";

import {
  getUnFilteredList,
  getFeaturedList,
  getBlogCategories,
  getBlogFilterName,
  getBoards,
  getBlogs,
  getBlogsSearchList,
  getBlogsSearchCount,
  getFetchStatus,
  // getMoreRecipeAvailable,
  getBlogsSearchText,
  getAppliedFilters,
  getTrendingBlogs
} from "../../../selectors/blogs";

import Preview from "./components";

const mapStateToProps = state => ({
  unfilteredList: getUnFilteredList(state),
  featuredList: getFeaturedList(state),
  categories: getBlogCategories(state),
  blogFilterName: getBlogFilterName(state),
  boards: getBoards(state),
  blogs: getBlogs(state),
  fetchStatus: getFetchStatus(state),
  searchList: getBlogsSearchList(state),
  searchCount: getBlogsSearchCount(state),
  searchBy: 'title',
  searchTerm: getBlogsSearchText(state),
  appliedFilters: getAppliedFilters(state),
  trendingBlogs: getTrendingBlogs(state),
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  createBoard: bindActionCreators(createBoard, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  deleteFavoriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  setFiltersAndFetch: bindActionCreators(setFiltersAndFetch, dispatch),
  searchBlogs: bindActionCreators(search, dispatch),
  trendingTypeChange: bindActionCreators(trendingTypeChange, dispatch)
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(Preview));




