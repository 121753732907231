import React from 'react'
import '../styles/common.scss';

const TwitIcon = () => {
  return (
    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.77 4.10021L24.63 4.33021C24.82 4.38021 24.98 4.54021 25.02 4.75021C25.07 4.95021 25 5.17021 24.84 5.30021L23.06 6.82021C22.93 6.92021 22.86 7.08021 22.86 7.24021V8.19021C22.88 12.8402 20.61 17.1902 16.84 19.7702C14.58 21.2602 11.95 22.0302 9.26002 21.9902C6.20002 21.9202 3.20002 21.1902 0.440018 19.8502C0.140018 19.7802 -0.0499826 19.4902 0.0100174 19.1802C0.0700174 18.8802 0.360019 18.6802 0.660019 18.7402C2.26002 18.7602 3.84002 18.4402 5.31002 17.8202C5.42002 17.7802 5.49002 17.6802 5.49002 17.5602C5.49002 17.4402 5.42002 17.3402 5.31002 17.3002C3.78002 17.0302 2.48002 16.0002 1.84002 14.5502C1.78002 14.4102 1.77002 14.2502 1.82002 14.1002C1.88002 13.9502 1.99002 13.8402 2.13002 13.7802L2.70002 13.5502C2.79002 13.5002 2.85002 13.4102 2.85002 13.3002C2.85002 13.1902 2.79002 13.1002 2.70002 13.0502C1.26002 12.1202 0.320016 10.5802 0.130016 8.85021C0.0900163 8.67021 0.150016 8.49021 0.270016 8.36021C0.400016 8.23021 0.580019 8.17021 0.750019 8.20021L1.96002 8.44021C2.06002 8.46021 2.16002 8.41021 2.22002 8.33021C2.28002 8.24021 2.29002 8.13021 2.24002 8.04021C1.48002 6.33021 1.31002 4.41021 1.74002 2.58021C1.76002 2.38021 1.88002 2.20021 2.06002 2.12021C2.26002 2.06021 2.47002 2.13022 2.60002 2.27022C5.74002 5.98022 8.66002 7.57021 11.7 7.20021C11.84 7.18021 11.94 7.06021 11.94 6.92021V6.52022C11.94 3.46022 14.37 0.970215 17.36 0.970215C18.53 0.970215 19.67 1.36021 20.61 2.08021C20.75 2.19021 20.93 2.22021 21.09 2.17021L23.26 1.55021C23.47 1.49021 23.68 1.56021 23.82 1.73021C23.95 1.90021 23.98 2.13021 23.89 2.33021L23.41 3.32022C23.34 3.47022 23.34 3.65021 23.41 3.80021C23.48 3.95021 23.61 4.06021 23.77 4.10021Z" fill="#6B6D71" />
    </svg>
  )
}

export default TwitIcon; 