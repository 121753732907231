import React from "react";
import '../styles/common.scss';


const MacroNutritionIcon = (props) => {
  return (
    < >
      <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.86395 18.016L6.37595 4.19199C6.86795 2.67999 8.27195 1.55199 9.86795 1.59999C10.012 1.59999 10.144 1.61199 10.288 1.63599C12.088 1.88799 13.372 3.51999 13.372 5.34399V19.768M30.136 18.016L25.624 4.192C25.132 2.668 23.728 1.552 22.132 1.6C21.988 1.6 21.856 1.612 21.712 1.636C19.912 1.888 18.628 3.52 18.628 5.344V19.768M13.384 6.08801H18.616M13.384 16.048H18.616M13.384 19.768C13.384 23.022 10.746 25.66 7.49197 25.66C4.23791 25.66 1.59998 23.022 1.59998 19.768C1.59998 16.5139 4.23791 13.876 7.49197 13.876C10.746 13.876 13.384 16.5139 13.384 19.768ZM30.4 19.768C30.4 23.022 27.762 25.66 24.508 25.66C21.2539 25.66 18.616 23.022 18.616 19.768C18.616 16.5139 21.2539 13.876 24.508 13.876C27.762 13.876 30.4 16.5139 30.4 19.768Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default MacroNutritionIcon;
