import React from 'react';
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const CommunityIcon = () => {
    return (
        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
                <path d="M42.5828 3.67569L45.4866 7.31461C46.6995 8.84001 48.4639 9.81407 50.3936 10.0162L55.0249 10.5308C59.0498 10.9719 61.9536 14.5924 61.4941 18.6173L60.9795 23.2487C60.759 25.1784 61.3287 27.1265 62.5417 28.6335L65.4455 32.2724C67.9817 35.4335 67.4671 40.0465 64.306 42.5643L60.6671 45.4681C59.1417 46.6811 58.1676 48.4454 57.9655 50.3751L57.4509 55.0065C57.0098 59.0314 53.3893 61.9351 49.3644 61.4757L44.7331 60.9611C42.8033 60.7406 40.8552 61.3103 39.3482 62.5233L35.7093 65.427C32.5482 67.9633 27.9352 67.4487 25.4174 64.2876L22.5136 60.6487C21.3006 59.1233 19.5363 58.1492 17.6066 57.947L12.9752 57.4324C8.95034 56.9914 6.04656 53.3708 6.50602 49.346L7.02061 44.7146C7.24115 42.7849 6.67143 40.8368 5.45845 39.3297L2.55467 35.6908C7.33906e-05 32.5481 0.514668 27.9535 3.67575 25.4173L7.31467 22.5135C8.84007 21.3006 9.81413 19.5362 10.0163 17.6065L10.5309 12.9751C10.972 8.95028 14.5925 6.0465 18.6174 6.50596L23.2487 7.02055C25.1785 7.24109 27.1266 6.67136 28.6336 5.45839L32.2725 2.55461C35.452 1.24156e-05 40.0466 0.514607 42.5828 3.67569Z" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M47.0302 27.8248C49.0519 36.5913 38.6313 46.5156 36.7384 46.9567C34.827 47.3978 21.1167 43.0421 19.0951 34.2756C18.1762 30.2691 20.4919 25.5091 24.4984 24.5902C27.5308 23.8918 30.5448 25.1415 32.2357 27.5307C32.7135 24.6453 34.8821 22.201 37.9146 21.5026C41.9394 20.5653 46.1113 23.8183 47.0302 27.8248Z" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                {/* <clipPath id="clip0">
                    <rect width="68" height="68" fill="white" />
                </clipPath> */}
            </defs>
        </svg>


    )
}

export default CommunityIcon;