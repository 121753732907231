// TODO: move this to fetch status
import createReducer from "../helpers/createReducers";

import { fromJS, List } from "immutable";

const initialState = fromJS({
  footerStatus: true,
  firstTimeAuthenticatedLoading: false,
  startUrl: window && window.location ? window.location.href : null,
  count: 0
});

export default createReducer(initialState, {
  APP_ESSENTIAL_FETCH: (state, action) => {
    return state.setIn(["count"], state.getIn(["count"]) + 1);
  },

  FOOTER_STATUS: (state, action) => {
    state = state.setIn(["footerStatus"], action.payload.status);

    return state;
  },
  USER_AUTHENTICATE_SUCCESS: (state, action) => {
    state = state.setIn(["firstTimeAuthenticatedLoading"], true);

    return state;
  }
});
