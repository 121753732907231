
import { put, takeEvery, call, select } from 'redux-saga/effects';
import axios from 'axios'
import { get } from '../helpers/requests'
import pushToSentry from '../helpers/pushToSentry';
import * as actions from '../actions/actionTypes';
import {
  getToken,
  getWorkoutsWorkoutTypes,
  getWorkoutsBodyParts,
  getWorkoutsEquipments,
} from './selectors'
import { generateWorkoutQueryFromFilter } from '../helpers/filterConversions'
import { searches } from '../helpers/api';

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_WORKOUTS_SUCCESS
function* task(action) {
  try {
    const workoutTypes = yield select(getWorkoutsWorkoutTypes);
    const bodyParts = yield select(getWorkoutsBodyParts);
    const equipments = yield select(getWorkoutsEquipments);

    const queryParam = generateWorkoutQueryFromFilter(
      action.payload.filters,
      workoutTypes,
      equipments,
      bodyParts,
    );

    if (queryParam.length > 0) {
      const token = yield select(getToken)

      let filterCountResponse = yield call(axios, get(
        searches.searchWorkouts(queryParam.substring(1)), // Remove leading &
        '',
        token
      ))

      yield put({
        type: actions.WORKOUT_FILTER_COUNT_SUCCESS,
        payload: { 
          totalCount: filterCountResponse.data.count
        }
      });
    } else {
      yield put({
        type: actions.WORKOUT_FILTER_COUNT_SUCCESS,
        payload: { 
          totalCount: 0
        }
      });
    }
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.WORKOUT_FILTER_COUNT_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* search() {
  yield takeEvery(actions.WORKOUT_FILTER_COUNT, task)
}