import React from 'react'
import Button from '../../common/button'
import CustomInput from '../../common/customInput'
import Dropdown from '../../common/dropdown'
import DownArrow from "../../images/DownArrow"
import LinkButton from '../../common/linkButton'
import SelectorIcon from '../../images/SelectorIcon'


const MoveIngredientModal = ({
  data,
  onMoveIngredient,
  setCurrentModal,
  setModalData,
  activeGroceryType,
  options
}) => (
    <div className="modalWrapper">
      <div className="modalHeader">
        <h3>Change Ingredient Category</h3>
      </div>
      <div className="modalBody grocery-body">
        <div className="groceryInputList">
          <CustomInput
            type="text"
            value={data.name}
            placeholder="Ingredient"
            label="Ingredient"
            disabled
          />
        </div>
        <div className="groceryInputList">
          <label className="static-value">{activeGroceryType === 'aisle' ? 'Category' : 'Recipe'}</label>
          <Dropdown
            onChange={value => { setModalData('moveIngredientModalData', { categoryId: value }) }}
            options={options}
            defaultValue={data.categoryId}
          />
          {/* TODO: Down arrow should be inside componenet */}
          <SelectorIcon></SelectorIcon>
        </div>
      </div>
      <div className="modalFooter">
        <Button
          btnClass="primary btn-full"
          btnText="Change"
          onClick={() => onMoveIngredient()}
        />
        <Button
          btnClass="secondary btn-full btn-bp-sm"
          btnText={`Cancel`}
          // additionalClass='btn btn-full'
          onClick={() => setCurrentModal()}
        // isLoading={false}
        // showGhost
        />
      </div>
    </div>
  )

export default MoveIngredientModal;