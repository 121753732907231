
import moment from 'moment';

moment.locale('en', {
  week: {
      dow: 1
  }
});
moment.locale('en');

// returns the complete days in the current month + offseted weekly view for previous and next month
// Month can be "current" or "next"
export default (month, activeDate, anchorDate, showForDays = 14) => {
  
  // Create two new dates for start and end of the anchor date's month
  // Create with the same date
  let startDate = moment(anchorDate).local();
  let endDate = moment(anchorDate).add({ month: 1 }).local();

  // Find the start and end week
  const startWeek = startDate.startOf('month').isoWeek();
  const endWeek = endDate.endOf('month').isoWeek();

  // Build the calendar
  let calendar = [];
  const startDay = startDate.clone().startOf('month').subtract({ month: 1 }).startOf('week');
  const endDay = endDate.clone().endOf('month').endOf('week');

  let date = startDay.clone().subtract(1, 'day');
  let count = 0;
  
  // Only for current month's cases
  const anchorMonthRangeStart = moment(anchorDate).startOf('month').format('YYYY-MM-DD');;
  const anchorMonthRangeEnd = moment(anchorDate).endOf('month').format('YYYY-MM-DD');

  // To check if current / next month's date is within showForDays
  const dataRangeStart = moment(anchorDate).clone().subtract(showForDays, 'days').format('YYYY-MM-DD');
  const dataRangeEnd = moment(anchorDate).clone().add(showForDays, 'days').format('YYYY-MM-DD');

  // If next month ignore the old month's date or if current month approve only dates after anchor date
  const prevMonthRangeStart = moment(anchorDate).subtract({ month: 1 }).startOf('month').format('YYYY-MM-DD');
  const prevMonthRangeEnd = moment(anchorDate).subtract({ month: 1 }).endOf('month').format('YYYY-MM-DD');
  
  // If next month ignore the old month's date or if current month approve only dates after anchor date
  const nextMonthRangeStart = moment(anchorDate).add({ month: 1 }).startOf('month').format('YYYY-MM-DD');
  const nextMonthRangeEnd = moment(anchorDate).add({ month: 1 }).endOf('month').format('YYYY-MM-DD');

  while (date.isBefore(endDay, 'day')) {
    const newMomentDate = date.add(1, 'day');
    const currentDate = newMomentDate.clone().format('YYYY-MM-DD');
    
    let monthType;

    if (newMomentDate.isBetween(anchorMonthRangeStart, anchorMonthRangeEnd, null, '[]')) {
      monthType = 'current';
    }

    if (newMomentDate.isBetween(prevMonthRangeStart, prevMonthRangeEnd, null, '[]')) {
      monthType = 'previous'
    }

    if (newMomentDate.isBetween(nextMonthRangeStart, nextMonthRangeEnd, null, '[]')) {
      monthType = 'next'
    }
    
    const isInCurrentMonthRange = newMomentDate.isBetween(anchorMonthRangeStart, anchorMonthRangeEnd, null, '[]');
    
    const isInDataRange = newMomentDate.isBetween(dataRangeStart, dataRangeEnd, null, '[)');

    // Logic for current month range and next month range
    const currentMonth = moment(anchorDate).startOf('month').format('MMM');
    const previousMonth = moment(anchorDate).subtract({ month: 1 }).startOf('month').format('MMM');
    const nextMonth = moment(anchorDate).add({ month: 1 }).startOf('month').format('MMM');
    const onePreviousMonth = moment(anchorDate).subtract({ month: 2 }).startOf('month').format('MMM');

    const monthAfterNextMonth = moment(anchorDate).add({ month: 2 }).startOf('month').format('MMM');

    const startOfWeeksMonth = moment(date).startOf('week').format('MMM');
    const endOfWeeksMonth = moment(date).endOf('week').format('MMM');

    const todaysMonth = moment(date).format('MMMM');

    const isDateCompleted = date => date.isBefore(moment().startOf('day').format('YYYY-MM-DD'), 'day');

    const days = {
      date: currentDate,
      inPreviousMonth: startOfWeeksMonth === previousMonth || startOfWeeksMonth === onePreviousMonth,
      inCurrentMonth: startOfWeeksMonth === currentMonth || endOfWeeksMonth === currentMonth,
      inNextMonth: endOfWeeksMonth === nextMonth || endOfWeeksMonth === monthAfterNextMonth,
      monthType,
      monthName: todaysMonth,
      isInRange: isInDataRange,
      completed: isDateCompleted(date)
    }

    calendar.push(days)
  }

  return calendar
}
