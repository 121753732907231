import { put, takeEvery, call, select, delay, take } from 'redux-saga/effects'

import * as actions from '../actions/actionTypes';
import noteTask from './subtask/notes';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {  
  
  try {


    yield delay(2000);
    
    yield put({
      type: actions.REMOVE_TOAST_MESSAGE
    })
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
  }
}

export default function* removeToast() {
  yield takeEvery(actions.SHOW_TOAST_MESSAGE, task)
}