import React from 'react'

import map from 'lodash/map'

import findIndex from 'lodash/findIndex'
import FaqQuestion from './question';
import { TransitionTwo } from '../../common/transitions';

const TabContent = ({
  faqs,
  activeCategorySlug,
  activeOpenedFaqSlug,
  setActiveOpenedFaqSlug,
  fetchStatus,
}) => {
  const isLoading = fetchStatus.faqList.isFetching;
  const isLoaded = fetchStatus.faqList.isFetched;

  const showPreloader = (isLoading || !isLoaded);

  const activeFaqIndex = findIndex(faqs, faq => faq.category.slug === activeCategorySlug);
  let activeFaq = null;

  if (activeFaqIndex !== -1) {
    activeFaq = faqs[activeFaqIndex];
  }

  const preloaderFaqs = map([1, 2, 3, 4], () => ({ isLoading: true }));

  const renderFaqs = (faqs) => {
    return (
       <div>
        {
          map(faqs, faq => (
            <FaqQuestion
              isLoading={faq.isLoading}
              question={faq.question}
              answer={faq.answer}
              isActive={activeOpenedFaqSlug == faq.question}
              activeOpenedFaqSlug={activeOpenedFaqSlug}
              setActiveOpenedFaqSlug={setActiveOpenedFaqSlug}
            />
          ))
        }
       </div>
    )
  }

  return (

    <div className="tab-content">
      {
        activeFaq ? renderFaqs(showPreloader ? preloaderFaqs : activeFaq.faqs, showPreloader) : renderFaqs(preloaderFaqs, showPreloader)
      }
    </div>
  )
}

export default TabContent