import React, { useEffect, useState } from "react";

import cx from "classnames";
import { Row, Col } from "react-flexbox-grid";
import map from "lodash/map";
import includes from "lodash/includes";
import concat from "lodash/concat";
import axios from "axios";

import { baseUrl, videos } from "../../../helpers/api";

const horizontalList = (isAdmin, activeRecipe, activeWorkout, activeBlog) => {
  const basicSet = [
    {
      title: "Account Settings",
      href: "/account",
    },
    {
      title: "Membership",
      href: "/account/membership",
    },
    {
      title: "Referrals",
      href: "/account/referral",
    },
    {
      title: "Updates",
      href: "/account/updates",
    },
  ];

  let additionalSet = [];

  if (isAdmin) {
    additionalSet = [
      {
        title: "Admin",
        href: `${baseUrl}/admin`,
        type: "direct",
      },
    ];

    if (includes(window.location.href, "/recipe/")) {
      additionalSet.push({
        title: "Edit this content",
        href: `${baseUrl}/admin/recipes/${activeRecipe.id}/edit`,
        type: "direct",
      });
    }
    if (includes(window.location.href, "/workout/")) {
      additionalSet.push({
        title: "Edit this content",
        href: `${baseUrl}/admin/workouts/${activeWorkout.id}/edit`,
        type: "direct",
      });
    }
    if (includes(window.location.href, "/video/")) {
      additionalSet.push({
        title: "Edit this content",
        href: null,
        type: "direct",
      });
    }
    if (includes(window.location.href, "/blog/")) {
      additionalSet.push({
        title: "Edit this content",
        href: "/recipes",
        href: `${baseUrl}/admin/blog/${activeBlog.id}/edit`,
        type: "direct",
      });
    }
  }

  const finalSet = [
    {
      title: "Logout",
      href: "/login",
      type: "direct",
      logout: true,
    },
  ];

  return concat(concat(basicSet, additionalSet), finalSet);
};

const ProfileDropdown = ({
  isAdmin,
  history,
  activeRecipe,
  activeWorkout,
  activeBlog,
  activeHoveredEntry,
}) => {
  const [videoAdminUrl, setVideoAdminUrl] = useState(null);

  useEffect(() => {
    if (includes(window.location.href, "/video/")) {
      axios({
        method: "GET",
        url: videos.get(window.location.href.split("video/")[1]),
        headers: {
          Authorization: `Token token="${localStorage.getItem("token")}"`,
        },
      }).then((response) => {
        if (response && response.data) {
          setVideoAdminUrl(
            `${baseUrl}/admin/videos/${response.data.videos.id}/edit`
          );
        }
      });
    }
  }, []);

  return (
    <ul className="profile-dropdown-list">
      {map(
        horizontalList(isAdmin, activeRecipe, activeWorkout, activeBlog),
        (item) => {
          return (
            <li>
              <a
                href={item.href}
                disabled={!item.href}
                onClick={(e) => {
                  e.preventDefault();
                  if (item.type === "direct") {
                    if (item.logout) {
                      localStorage.removeItem("token");
                      window.location = "/login";
                    } else {
                      window.open(item.href || videoAdminUrl, "_blank");
                    }
                  } else {
                    history.push(item.href);
                  }
                }}
              >
                {item.title}
              </a>
            </li>
          );
        }
      )}
    </ul>
  );
};

export default ProfileDropdown;
