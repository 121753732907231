
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { getRecipeLimit, getRecipeOffset, getCollectionId } from './selectors'
import recipesTask from './subtask/recipes';
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import isNull from 'lodash/isNull'

function* task(action) {
  try {
    const recipeLimit = yield select(getRecipeLimit);
    const recipeOffset = yield select(getRecipeOffset);
    const stateCollectionId = yield select(getCollectionId)

    const collectionId = isNull(stateCollectionId) ? '' : stateCollectionId

    yield call(recipesTask.filterRecipesByCategory, actions.FILTER_RECIPES_BY_CATEGORY_SUCCESS, {
      recipeLimit,
      recipeOffset,
      collectionId: collectionId,
      categoryId: action.payload.categoryId
    })
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.FILTER_RECIPES_BY_CATEGORY_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* fetchRecipesByCategory() {
  yield takeEvery(actions.FILTER_RECIPES_BY_CATEGORY, task)
}