import classNames from 'classnames';
import React from 'react';
import { Row, Col } from "react-flexbox-grid";
import Button from "../common/button";
import KatiesCornerBanner from '../images/KatiesCornerBanner.png';
import KatiesCornerDescriptionMeeting from '../images/KatiesCornerDescriptionMeeting.png';
import { TransitionEaseInOne } from "../common/transitions";


const tabs = (activeTab) => [
    {
        text: `Katie's Corner`,
        link: '#katies-blog',
    },
    {
        text: 'In The Kitchen',
        link: '#katies-in-the-kitchen',
    },
    {
        text: 'Workouts',
        link: '#katies-workout',
    },
    {
        text: 'Meetings',
        link: '#katies-zoom-meeting',
    }
]

const KatiesConerNavigation = (props) => {
    const url = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }


    let actionText = null;
    let actionLink = null;

    console.log('user plan order ', props.userPlanOrder)
    if (!props.userPlanOrder) {
        actionText = 'Signup';
        actionLink = '/pricing'
    } else if (props.userPlanOrder !== 3) {
        actionText = 'Upgrade';
        actionLink = '/account/membership'
    } else if (props.userPlanOrder === 3) {
        actionText = 'Read More';
        actionLink = `/nourish/katies-corner/${props.featuredPersonalizedBlog?.slug}`;
    }


    return (
        <>

            <div className='container'>
                <TransitionEaseInOne>
                    <section className="home-hero katies-corner-hero katies-corner-about-hero" style={{
                        backgroundImage: `url(${KatiesCornerDescriptionMeeting})`,
                    }}>
                        <Row className="home-hero-wrapper home-hero-heading no-margin-sm">
                            <Col xs={12} md={12} lg={8} className="no-padding-sm">
                                <div className="home-hero-text">
                                    <p className='hero-katies-corner-legend'>KATIE'S CORNER</p>
                                    <h1>
                                        {props.featuredPersonalizedBlog?.title || (<h1>
                                            Connect with &nbsp;<br></br> like minded community
                                        </h1>)}
                                    </h1>
                                    <form className="hero-form internal-navigation">
                                        <Button
                                            btnClass="btn btn-primary btn-md read-more-btn"
                                            btnText={actionText}
                                            onClick={(e) => {
                                                navigateTo(actionLink)
                                            }}
                                        />
                                    </form>
                                </div>
                            </Col>
                        </Row>
                    </section>

                </TransitionEaseInOne>
            </div>

            <section className='nourish-content-section'>
                <div className='container'>
                    <ul className='tabs katies-corner-tabs'>
                        {
                            tabs(url).map((tab) => (
                                <a href={tab.link} key={tab.text}>
                                    <li
                                        className={classNames('tab-option', 'katies-corner-tab-option', {
                                            active: tab.active
                                        })}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigateTo(tab.link)
                                        }}
                                    >

                                        {tab.text}

                                    </li>
                                </a>
                            ))
                        }
                    </ul>
                </div>
            </section>
        </>
    )
}

export default KatiesConerNavigation