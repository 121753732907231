import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet"
import toJS from '../../../helpers/toJS';
import { getWorkoutDetails } from "../../../selectors";
import { appEssentialFetch } from "../../../actions";
import isNull from 'lodash/isNull';
import { reduce } from 'lodash';
import PrintIcon from "../../images/PrintIcon";
import Logo from "../../images/Logo";


const WorkoutDetails = (activeWorkout, showPics) => {

  if (activeWorkout.instructions) {
    if (activeWorkout.instructions[0].group_type == 'list') {
      return (
        activeWorkout.instructions.map((instruction, index) => {
          return (
            <>
              <div className="instructionsList">
                <h5>{instruction.title}</h5>
                <p className="instructionsIntro">{instruction.intro}</p>
                <ol>
                  {
                    instruction.exercises.map((exercise, index) => {
                      return (
                        <li key={index}>
                          {exercise.description}
                        </li>
                      )
                    })
                  }
                </ol>
              </div>
            </>
          )
        })
      )
    } else if (activeWorkout.instructions[0].group_type == 'table') {
      return (
        <div className="instructions">
          <div className="instructionsTable">
            {activeWorkout.instructions[0].table.rows.map((row, index) => {
              return (
                <div className="tableHead" key={index}>
                  {
                    row.map((item, index) => {
                      return (
                        <div className="tableRow">
                          <p key={index}>{item}</p>
                        </div>
                      )
                    })
                  }
                </div>
              )
            })}
          </div>
        </div >
      )
    }
  } else {
    return null
  }
}
const getTime = (time) => {
  if (time) {
    return (time.split(' ')[0] + ' M');
  }
  return null
}

const getDifficulty = (difficulty) => {
  if (difficulty) {
    return difficulty == 'medium' ? 'MED' : difficulty.toUpperCase()
  }
  return null
}
const Print = (props) => {
  const { activeWorkout } = props;
  let equipments;
  if (activeWorkout.equipment) {
    equipments = activeWorkout.equipment.split('\r\n');
    equipments = reduce(equipments, (accumulater, equipment) => {
      let item = {
        item: equipment,
        amount: null
      }
      return accumulater.concat(item)
    }, [])
  }

  const secondaryMetaData = {
    title: 'Equipment Needed',
    items: equipments
  }

  const primaryMetaData = [
    {
      title: 'DIFFICULTY',
      value: getDifficulty(activeWorkout.difficulty)
    },
    {
      title: 'TIME',
      value: getTime(activeWorkout.time)
    },
    {
      title: 'CAL BURN',
      value: 0
    }
  ]
  useEffect(() => {
    props.appEssentialFetch({
      workout: {
        workoutSlug: props.match.params.workoutSlug
      }
    })
  }, [])
  return (
    <div className="container">
      <Helmet>
        <title>Print Workout | Dashing Dish </title>
        <meta name="description" content="Dashingdish Print Workout page" />
      </Helmet>
      <a
        href={`/workout/${props.match.params.workoutSlug}`}
        onClick={e => {
          e.preventDefault()
          props.history.push(`/workout/${props.match.params.workoutSlug}`)
        }}
        className=""
      >
        ‹ Back to the Workout
        </a>
      <div className="print-page_header">
        <Logo />
        <figure className="print-button" onClick={(e) => window.print()}>
          <PrintIcon />
        </figure>
      </div>
      <div class="print-page_content">
        <div className="print_content-left">
          <small>{`https://dashingdish.com/workout/${props.match.params.workoutSlug}`}</small>
          <h1>{props.activeWorkout.title}</h1>
          <ul className="featuredMetadata">
            {
              primaryMetaData && primaryMetaData.map((item, index) => {
                return (
                  <li key={index}>
                    {item.title}<span>{(isNull(item.value)) ? '-' : item.value}</span>
                  </li>
                )
              })
            }
          </ul>
          <h2>Introduction</h2>
          <p>{props.activeWorkout.introduction}</p>
          <div className="nutritions">
            <h6>{secondaryMetaData.title}</h6>
            {
              <div className="showFullNutrition">
                {secondaryMetaData.items && secondaryMetaData.items.map((item, index) => {
                  return (
                    <li className="nutritionsList" key={index}>
                      <p>{item.item}</p>
                      {
                        item.amount && <p className="nutritionAmount">{item.amount}</p>
                      }
                    </li>
                  )
                })}
              </div>
            }
          </div>

        </div>
        <div className="print_content-right">
          <h2>Instructions</h2>
          {WorkoutDetails(props.activeWorkout, true)}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  activeWorkout: getWorkoutDetails(state, 0),
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(Print));
