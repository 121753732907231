  import { put, takeEvery } from 'redux-saga/effects'
// import { Sentry } from 'react-native-sentry';
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';

// TODO: Remove this saga and corresponding actions
// TODO: Add DIRECT to non saga action
function* task(action) {
  try {
    // Todo: Throw failure when there is an error
    yield put({
      type: actions.ADD_INGREDIENTS_TO_GROCERY_WAITLIST_SUCCESS,
      payload: action.payload
    })

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_INGREDIENTS_TO_GROCERY_WAITLIST_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addItemsToGroceryWaitlist() {
  yield takeEvery(actions.ADD_INGREDIENTS_TO_GROCERY_WAITLIST, task)
}