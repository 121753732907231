import React, { useEffect, useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { reduce } from "lodash";
import ReactPlayer from "react-player";
import isEmpty from "lodash/isEmpty";
import drop from "lodash/drop";
import { Helmet } from "react-helmet";
import ReactMarkdown from "react-markdown";

import WorkoutActions from "../../common/objectActions";
import WorkoutSideIdeas from "../../recipeIndex/components/sideIdeas";
import Comments from "../../common/comments";
import FeaturedMetaData from "./metaData";
import SlugImage from "../../recipeIndex/components/slugImage";
import SocialIcon from "../../common/socialIcon";
import Button from "../../common/button";
import WorkoutTags from "./workoutTags";

import FallbackImage from "../../images/fallback-logo.png";
import isNull from "lodash/isNull";
import { Element } from "react-scroll";
import ReactImageFallback from "react-image-fallback";
import Loader from "../../images/loader";
import getWorkoutImages from "../../../helpers/getWorkoutImages";
import Table from "../../common/table";
import VideoPlayIcon from "../../images/VideoPlayIcon";
import getUsePlanType from "../../../helpers/getUserPlanType";

import PaywallLimiter from "../../common/paywallLimiter";
import AdComponent from "../../common/ad";
import {
  TransitionOne,
  TransitionTwo,
  TransitionThree,
} from "../../common/transitions";

const commentTextBoxRef = React.createRef();
const getTime = (time) => {
  if (time) {
    return time.split(" ")[0] + " M";
  }
  return null;
};

const getDifficulty = (difficulty) => {
  if (difficulty) {
    return difficulty == "medium" ? "MED" : difficulty.toUpperCase();
  }
  return null;
};

// temprory

const getImage = (item, sideImage) => {
  if (item) {
    if (item.image) {
      if (item.image.tall_470 && sideImage) {
        return item.image.tall_470;
      }
      return item.image.wide_640;
    }
  }
  return "";
};

const SingleWorkout = (props) => {
  const objectType = "workout";
  const [showPics, setShowPics] = useState(true);
  const [playing, setPlaying] = useState({});

  useEffect(() => {
    props.appEssentialFetch({
      workout: {
        workoutSlug: props.match.params.workoutSlug,
      },
    });
  }, [props.location.pathname]);

  const {
    tags,
    token,
    notes,
    boards,
    history,
    comments,
    sendNote,
    isLoggedIn,
    fetchStatus,
    sendComment,
    userDetails,
    createBoard,
    activeWorkout,
    setObjectBoards,
    deleteFavourite,
    relatedWorkouts,
    createFavourite,
    sendReplyComment,
    objectFetchStatus,
    commentPostStatus,
    clearBoardSelection,
    deleteFavouriteBoard,
    updateFavouriteBoard,
    objectEssentialFetchStatus,
    calendarList,
    setMacros,
    addMealToMultipleDays,
    workoutTypes,
    meteredDetails,
    isAuthenticated,
    agreeToMeteredVisit,
  } = props;

  let equipments;
  if (activeWorkout.equipment) {
    equipments = activeWorkout.equipment.split("\r\n");
    equipments = reduce(
      equipments,
      (accumulater, equipment) => {
        let item = {
          item: equipment,
          amount: null,
        };
        return accumulater.concat(item);
      },
      []
    );
  }

  const secondaryMetaData = {
    title: "Equipment Needed",
    items: equipments,
  };

  const primaryMetaData = [
    {
      title: "DIFF",
      value: getDifficulty(activeWorkout.difficulty),
    },
    {
      title: "TIME",
      value: getTime(activeWorkout.time),
    },
    {
      title: "EST CAL BURN",
      value: activeWorkout.calorie_burn,
    },
  ];

  const WorkoutDetails = (activeWorkout, showPics) => {
    if (activeWorkout.instructions) {
      return activeWorkout.instructions.reduce((acc, activeWorkoutInstruction) => {
        let content = null;
        if (activeWorkoutInstruction.group_type == "list") {
          content = activeWorkoutInstruction ? (
            <>
              <div className="instructionsList">
                <h5>{activeWorkoutInstruction.title}</h5>
                <p className="instructionsIntro">{activeWorkoutInstruction.intro}</p>
                <ol>
                  {activeWorkoutInstruction.exercises?.reduce(
                    (accumulator, exercise, index) => {
                      if (exercise && exercise.photos) {
                        exercise.photos.forEach((photo, index) => {
                          accumulator.push(
                            showPics && exercise && photo && photo.vimeo_id ? (
                              <>
                                {index === 0 ? (
                                  <li key={index}>{exercise.description}</li>
                                ) : null}
                                <div className="player-wrapper">
                                  <ReactPlayer
                                    ref={this.ref}
                                    className="react-player"
                                    width="100%"
                                    height="100%"
                                    url={`https://vimeo.com/${photo.vimeo_id}`}
                                    controls={true}
                                    playing={false}
                                    loop={true}
                                    wrapper="div"
                                  />
                                  <VideoPlayIcon></VideoPlayIcon>
                                </div>
                              </>
                            ) : (
                              <>
                                {index === 0 ? (
                                  <li key={index}>{exercise.description}</li>
                                ) : null}
                              </>
                            )
                          );
                        });
                      } else {
                        accumulator.push(
                          <>
                            <li key={index}>{exercise.description}</li>
                          </>
                        );
                      }

                      return accumulator;
                    },
                    []
                  )}
                </ol>
              </div>
            </>
          ) : null;

        } else if (activeWorkoutInstruction.group_type == "table") {

          content = (
            <Table
              columns={activeWorkoutInstruction.table?.rows[0]}
              data={activeWorkoutInstruction.table?.rows}
            />
          );
        }

        acc.push(content);
        return acc;
      }, []);

    } else {
      return null;
    }
  };

  if (isEmpty(activeWorkout) || objectFetchStatus.isFetching) {
    return (
      <div className="preLoader">
        <Loader></Loader>
      </div>
    );
  }

  const meteredImage =
    meteredDetails && meteredDetails.images && meteredDetails.images
      ? meteredDetails.images.wide_640
      : "";
  const defaultShareImage =
    "https://d11z36dj5c0s43.cloudfront.net/public-assets/images/icons/apple-touch-icon-precomposed-152x152.png";

  return (
    <>
      <Helmet>
        <title>
          {activeWorkout.title
            ? `${activeWorkout.title} |`
            : meteredDetails.title
              ? meteredDetails.title
              : ""}{" "}
          Dashing Dish
        </title>
      </Helmet>
      <TransitionOne>
        <section
          className="fullWideSection"
          style={{
            backgroundImage: `url(${getWorkoutImages(
              activeWorkout,
              "wide_640"
            ) || meteredImage})`,
          }}
        >
          <div className="container">
            <div className="wideImgTitle">
              <h1>{activeWorkout.title || meteredDetails.title}</h1>
            </div>
          </div>
        </section>
      </TransitionOne>
      <section className="singleWorkout">
        <div className="container">
          <AdComponent />
          <Row className="singleWrapper singleWrapper-workouts no-margin">

            <TransitionTwo>
              <Col sm={12} md={6} lg={6} className="mainboard no-padding">
                <div className="details">

                  {isAuthenticated ||
                    (!isAuthenticated && !activeWorkout.premium) ? (
                    <>
                      <h3>Workout Information</h3>
                      <FeaturedMetaData
                        primaryMetaData={primaryMetaData}
                        rating={activeWorkout.avg_rating}
                        secondaryMetaData={secondaryMetaData}
                        favoriteCount={activeWorkout.favorite_count}
                      />
                      <WorkoutActions
                        history={history}
                        note={notes}
                        token={token}
                        boards={boards}
                        sendNote={sendNote}
                        isLoggedIn={isLoggedIn}
                        objectType={objectType}
                        createBoard={createBoard}
                        sendComment={sendComment}
                        activeObject={activeWorkout}
                        thumbnail={getWorkoutImages(activeWorkout)}
                        createFavourite={createFavourite}
                        deleteFavourite={deleteFavourite}
                        setObjectBoards={setObjectBoards}
                        sendReplyComment={sendReplyComment}
                        clearBoardSelection={clearBoardSelection}
                        updateFavouriteBoard={updateFavouriteBoard}
                        deleteFavouriteBoard={deleteFavouriteBoard}
                        objectEssentialFetchStatus={objectEssentialFetchStatus}
                        actionList={[
                          "mealPlan",
                          "comment",
                          "note",
                          "favourite",
                        ]}
                        calendarList={calendarList}
                        setMacros={setMacros}
                        addMealToMultipleDays={addMealToMultipleDays}
                        userType={getUsePlanType(userDetails)}
                        isAuthenticated={isAuthenticated}
                        ref={commentTextBoxRef}
                      />
                      <div className="workout-story">
                        <ReactMarkdown
                          source={activeWorkout.introduction}
                          linkTarget="_blank"
                        />
                      </div>
                      {(meteredDetails.count > 0 &&
                        !meteredDetails.agreedToCount) ? (
                        <PaywallLimiter
                          limitReached={meteredDetails.limitReached}
                          readCount={meteredDetails.count}
                          objectType="workouts"
                          isAuthenticated={isAuthenticated}
                          agreeToMeteredVisit={agreeToMeteredVisit}
                        />
                      ) : (
                        <>
                          <div className="instruction">
                            <div className="instructionHead">
                              <h2>Instructions</h2>
                            </div>
                            <div className="instructionHead">
                              {activeWorkout.instructions &&
                                activeWorkout.instructions[0].group_type ==
                                "list" && (
                                  <div className="instructiontabs">
                                    <p>Video Instructions</p>
                                    <ul>
                                      <li
                                        className={showPics && "active"}
                                        onClick={() => {
                                          setShowPics(true);
                                        }}
                                      >
                                        On
                                      </li>
                                      <li
                                        className={!showPics && "active"}
                                        onClick={() => {
                                          setShowPics(false);
                                        }}
                                      >
                                        Off
                                      </li>
                                    </ul>
                                  </div>
                                )}
                            </div>
                            {WorkoutDetails(activeWorkout, showPics)}
                          </div>
                          <AdComponent />
                          <WorkoutSideIdeas
                            feature="workout"
                            history={history}
                            objectType={objectType}
                            title={"Similar Workouts"}
                            sideIdeas={relatedWorkouts}
                            wrapperClass="similarCardLists WorkoutsSideidea"
                          />
                          <Element name="Comment" className="element">
                            <Comments
                              comments={comments}
                              objectType={objectType}
                              isLoggedIn={isLoggedIn}
                              userDetails={userDetails.details}
                              activeObject={activeWorkout}
                              commentPostStatus={commentPostStatus}
                              ref={commentTextBoxRef}
                              sendComment={sendComment}
                              sendReplyComment={sendReplyComment}
                            />
                          </Element>
                        </>
                      )}
                    </>
                  ) : (
                    <>

                      <PaywallLimiter
                        premium={activeWorkout.premium}
                        objectType="workout"
                        isAuthenticated={isAuthenticated}
                        agreeToMeteredVisit={() => { }}
                      />
                    </>
                  )}
                </div>
              </Col>
            </TransitionTwo>
            <TransitionThree>
              <Col
                sm={12}
                md={5}
                mdOffset={0}
                lg={5}
                lgOffset={0.75}
                className="sideboard no-padding"
              >
                <div className="assets">
                  <div className="disclaimerWrapper">
                    <h2>Disclaimer</h2>
                    <p>
                      Although I've had plenty of experience with healthy eating
                      and exercise, I am not a medical doctor. The views I
                      express on this website are based on my own experiences,
                      and should not be taken as medical advice. The goal of
                      this website is to motivate and inspire others to live a
                      healthier life.
                      <br></br>
                      <br></br>
                      Dashing Dish, Inc. cannot be held liable for any result in
                      injury or health condition.
                    </p>
                  </div>
                  <WorkoutTags tags={tags} />
                  <h2>Share this Workout</h2>
                  <SocialIcon
                    feature="workout"
                    slug={activeWorkout.slug}
                    img={
                      activeWorkout && activeWorkout.images
                        ? activeWorkout.images.full_lg
                          ? activeWorkout.images.full_lg
                          : defaultShareImage
                        : defaultShareImage
                    }
                    title={activeWorkout.title}
                    description={
                      activeWorkout && activeWorkout.introduction
                        ? activeWorkout.introduction
                        : ""
                    }
                  />
                </div>
              </Col>
            </TransitionThree>
          </Row>
        </div>
      </section>
    </>
  );
};

SingleWorkout.defaultProps = {
  activeWorkout: {
    instructions: [{}],
  },
};

export default SingleWorkout;
