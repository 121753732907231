import moment from "moment";

export const isNewTagStatus = ({ isISOFormat = false, date }) => {
  if (!date) {
    return false
  }

  if (isISOFormat) {
    let now = moment().format("YYYY-MM-DD");
    let lastWeekDate = moment().subtract(7, 'days').format("YYYY-MM-DD");
    let checkingDate = moment(date).format('YYYY-MM-DD');

    return moment(checkingDate).isBetween(lastWeekDate, now, null, '[]');
  } else {
    let now = moment().add({ days: 1 }).format("YYYY-MM-DD");
    let lastWeekDate = moment(now).add({ days: 1 }).subtract(7, 'days').format("YYYY-MM-DD");
    let checkingDate = moment.unix(date).format("YYYY-MM-DD");
    return moment(checkingDate).isBetween(lastWeekDate, now, null, '[]');
  }
} 