import axios from 'axios'
import upperCase from 'lodash/upperCase'
import { put, takeEvery, call, select } from 'redux-saga/effects';
import { isNull } from 'lodash';
import { List } from 'immutable';
import { patch, post } from '../helpers/requests'
import { favourites } from '../helpers/api'
import { showToastMessage } from '../actions'
import { getToken, getCurrentRecipeId, getRecipeBoards, getWorkoutBoards, getBlogBoards, getVideoBoards, getRecipeByIdFromAnyList } from './selectors'
import * as actions from '../actions/actionTypes';
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  //For Toast message
  const getState = state => state;
  const state = yield select(getState)

  try {
    const token = yield select(getToken);
    const { objectId, objectType, thumbnail } = action.payload;

    let boardsArray;
    if (objectType == 'recipe') {
      boardsArray = yield select(getRecipeBoards);
    } else if (objectType == 'workout') {
      boardsArray = yield select(getWorkoutBoards);
    } else if (objectType === 'blog') {
      boardsArray = yield select(getBlogBoards);
    } else if (objectType === 'video') {
      boardsArray = yield select(getVideoBoards);
    } else if (objectType === 'meal_plan') {
      boardsArray = List()
    }

    let boards = boardsArray
      .reduce((accumulater, item) => {
        if (!isNull(item.getIn(['id'])) && item.getIn(['isSelected'])) {
          accumulater.push(item.getIn(['id']))
        }
        return accumulater;
      }, [])

    const favouriteObject = {
      object_id: objectId,
      object_type: objectType,
      thumbnail: thumbnail || `${objectType}_${objectId}`,
    }

    // TODO : if there any board get seleted only then do the api call

    // To remove boards key if no boards are selected
    if (boards.length !== 0) {
      favouriteObject.boards = boards
    }

    const boardsResponse = yield call(axios, post(favourites.post(), favouriteObject, '', token));

    yield put({
      type: actions.CREATE_FAVOURITE_TO_OBJECT_SUCCESS,
      payload: {
        success: true,
        favouriteObject: boardsResponse.data.favorite_item,
        objectType: objectType,
        thumbnail: thumbnail || `${objectType}_${objectId}`,
      }
    });

    const favoriteText = objectType !== 'blog' ? 'favorites' : 'bookmarks';
    // Do it differently for workouts / videos / blogs
    yield put(showToastMessage(`👍🏼 ${upperCase(objectType)} added to ${favoriteText}.`, 'success', 'favorite'));

  } catch (e) {
    yield put(showToastMessage('❗️Error occured while creating a new favorite.', 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CREATE_FAVOURITE_TO_OBJECT_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* createFavourite() {
  yield takeEvery(actions.CREATE_FAVOURITE_TO_OBJECT, task)
}