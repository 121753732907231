import React, { useState, useEffect, useReducer } from 'react';
import Modalwrapper from '../../common/modal'
import Button from '../../common/button'

import Arrow from '../../images/Arrow'
import GrandFatherIcon from '../../images/GrandFatherIcon'
import Loader from '../../images/loader';

const switcher = [
  {
    from: 'base',
    to: 'premium',
    message: 'You will now have the ability to use any of the meal planning features on Dashing Dish.'
  },
  {
    from: 'base',
    to: 'corner',
    message: 'You will now have the ability to use any of the meal planning features and Katies Corner on Dashing Dish.'
  },
  {
    from: 'premium',
    to: 'corner',
    message: 'You will now have the ability to use Katies Corner on Dashing Dish.'
  },
  {
    from: 'premium',
    to: 'base',
    message: 'You will lose the ability to use the meal planning features on Dashing Dish.'
  },
  {
    from: 'corner',
    to: 'base',
    message: 'You will lose the ability to use the meal planning features and Nourish on Dashing Dish.'
  },
  {
    from: 'corner',
    to: 'premium',
    message: 'You will lose the ability to use Nourish on Dashing Dish.'
  },
];

const ProrateModal = (props) => {
  const {
    origin,
    modalState,
    moveFromPlan,
    moveToPlan,
    membership,
    changePlanFetchStatus,
    setModalState,
    changePlan,
    activeBillingCycle,
    userDetails,
    moveToPlanType,
    moveFromPlanType,
    moveToPlanPrice,
    moveToPlanDuration,
    deletePaypalPlan,
    deleteStatus
  } = props

  console.log('move to plan ', moveToPlan);
  const renderStripeProrate = () => (
    <>
      {
        !modalState.error && (
          <>
            {
              modalState.isFetchingProrate ? (
                <Loader></Loader>
              ) : (
                <>
                  <h5>Are you sure you’d like to switch from the {moveFromPlan.name} plan to the {moveToPlan.name} plan?</h5>

                  <p>
                    This will {modalState.prorateCost < 0 ? 'credit' : 'debit'} your account ending in  <span className="card_last">{membership.payment_method.card_last4} for the remaining duration in the current cycle</span>
                    <br></br>
                    <span className="bold">${Math.abs(modalState.prorateCost) / 100}</span>.
                    <br></br>
                    <br></br>
                    <p>The actual plan would cost <b>${moveToPlanPrice / 100} per {moveToPlanDuration}</b> from the next Billing Cycle.</p>
                  </p>

                  <p>{switcherText ? switcherText.message : null}</p>
                </>
              )
            }
          </>
        )
      }
    </>
  );

  const renderPaypalProrate = () => {
    return (
      <>
        <h5>Warning</h5>
        <p className='left-align'>
          To switch plans in Paypal, you must delete current Paypal subscription and login with your credentials to reactivate with a new plan.
          <br />
          <br />
          All your data will be preserved until you reactivate.
          <br />
          <br />
          Once after deleting the subscription, if reactivation is not done within 15 days, all your data will be removed permanently.
        </p>
      </>
    )
  }


  const switcherText = switcher.find((eachSwitch) => {
    if (eachSwitch.from === moveFromPlanType && eachSwitch.to === moveToPlanType) {
      return true;
    }

    return false;
  });

  return (
    <Modalwrapper
      isOpen={modalState.show}
      maxWidth='450px'
      minHeight='550px'
      onRequestClose={() => {
        setModalState({ ...modalState, show: false, error: false })
      }}
    >
      <div className="modalWrapper switch-plan-modal">
        <div className="modalBody">
          {
            origin === 'stripe' ? (
              <div className="icons-wapper">
                <figure>
                  {
                    moveFromPlan.isGrandFather ? (
                      <GrandFatherIcon />
                    ) : (
                      <img src={moveFromPlan?.image} />
                    )
                  }

                </figure>
                <figure>
                  <Arrow></Arrow>
                </figure>
                <figure className="active-iconModal">
                  <img src={moveToPlan?.image} />
                </figure>
              </div>
            ) : null
          }          {
            origin === 'stripe' ? renderStripeProrate() : null
          }
          {
            origin === 'paypal' ? renderPaypalProrate() : null
          }
          {
            modalState.error ? (
              <p>
                There seems to be some issue with your membership. Please contact <a href="">support@dashingdish.com</a>
              </p>
            ) : null
          }

        </div>
        {
          !modalState.error ? (
            <div className="modalFooter">
              <Button
                btnText={origin === 'stripe' ? "Confirm" : "Proceed to delete current subscription"}
                btnClass="btn btn-full btn-primary"
                isLoading={changePlanFetchStatus.isUpdating || deleteStatus.initiated}
                disabled={changePlanFetchStatus.isUpdating}
                onClick={() => {
                  origin === 'paypal' ? deletePaypalPlan() :
                    changePlan(modalState.planId, activeBillingCycle, origin)
                }}
              />
              <Button
                btnText="Cancel"
                btnClass="btn btn-full btn-secondary btn-bp-sm"
                onClick={() => {
                  setModalState({
                    ...modalState,
                    show: false
                  })
                }}
              />
            </div>
          ) : null
        }

      </div>
    </Modalwrapper>
  )
}

export default ProrateModal