// @flow
import { CHANGE_PLAN } from "./actionTypes";

export default (planId, newBillingCycle, origin) => ({
  type: CHANGE_PLAN,
  payload: {
    planId,
    newBillingCycle,
    origin
  }
});