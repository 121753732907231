import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const VerifiedIcon = () => {
  return (
    <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9006 27.9927C10.9729 27.9927 10.0592 27.5646 9.43375 26.7529L8.32331 25.2917C7.91568 24.753 7.33235 24.4136 6.67874 24.3398L4.90766 24.1332C3.17875 23.9339 1.92775 22.2883 2.12454 20.4729L2.32133 18.6132C2.39161 17.9269 2.20888 17.2554 1.80125 16.7241L0.690809 15.2629C-0.398544 13.8386 -0.173645 11.7502 1.18278 10.6064L2.57434 9.44043C3.08739 9.01241 3.40365 8.39991 3.48096 7.71361L3.66369 5.85395C3.85345 4.03858 5.42071 2.7324 7.14962 2.93164L8.9207 3.13827C9.57431 3.21207 10.2139 3.0202 10.7199 2.59218L12.1114 1.42621C12.7721 0.872745 13.5944 0.621839 14.4307 0.717774C15.2671 0.813708 16.0191 1.2491 16.5462 1.94278L17.6566 3.40394C18.0642 3.94265 18.6476 4.28211 19.3012 4.3559L21.0723 4.54777C21.9086 4.6437 22.6606 5.0791 23.1877 5.77278C23.7148 6.46646 23.9538 7.32987 23.8624 8.20804L23.6656 10.0677C23.5953 10.754 23.7781 11.4255 24.1857 11.9569L25.2961 13.418C26.3855 14.8423 26.1606 16.9307 24.8042 18.0745L23.4126 19.2405C22.8996 19.6685 22.5833 20.281 22.506 20.9673L22.3162 22.827C22.2249 23.7051 21.8102 24.4947 21.1496 25.0482C20.4889 25.6017 19.6666 25.8452 18.8303 25.7567L17.0592 25.55C16.4126 25.4762 15.759 25.6755 15.26 26.0961L13.8685 27.2621C13.2851 27.7565 12.5894 27.9927 11.9006 27.9927Z" fill={themeTextRed} />
      <path d="M8.33374 13.7695L11.8338 17.4446L18.2505 10.707" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  );
};
export default VerifiedIcon;