import React from "react";
import '../styles/common.scss';


const DownArrowLarge = (props) => {
  return (
    < >
      <svg onClick={props.onClick} xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" className="arrowIconLarge">
        <path id="downArrowlarge.svg" className="arrow" d="M448.991,542.014a1,1,0,0,1-.707-0.294l-5.994-6.012a1,1,0,1,1,1.413-1.417l5.288,5.3,5.288-5.3a1,1,0,0,1,1.413,1.417l-6,6.012A0.994,0.994,0,0,1,448.991,542.014Z" transform="translate(-442 -534)" />
      </svg>

    </>
  );
};
export default DownArrowLarge;
