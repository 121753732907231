import { createSelector } from "reselect";
import moment from 'moment';
import { List, Map, Iterable, update, fromJS, toJS } from "immutable";

import isNaN from 'lodash/isNaN'
import isEmpty from 'lodash/isEmpty'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import find from 'lodash/find'
import split from 'lodash/split'

import getDatesForMonth from '../helpers/getDatesForMonth';

export const getUserSpecificEssential = (state: any) => state.getIn(['userSpecificEssential']);
export const getEssentials = (state) => state.getIn(['essentials']);

// Recipe related
export const getRecipesFoodTypes = createSelector([getEssentials], (essentials) => {
  return essentials.getIn(['recipe', 'foodTypes']);
});

export const getRecipesSpecialities = createSelector([getEssentials], (essentials) => {
  return essentials.getIn(['recipe', 'specialities']);
});

export const getRecipesDietaries = createSelector([getEssentials], (essentials) => {
  return essentials.getIn(['recipe', 'dietaries']);
});

export const getWorkoutTypes = createSelector([getEssentials], (essentials) => {
  return essentials.getIn(['workout', 'workoutTypes'])
});

export const getWorkoutEquipments = createSelector([getEssentials], (essentials) => {
  return essentials.getIn(['workout', 'equipments'])
});

export const getWorkoutBodyParts = createSelector([getEssentials], (essentials) => {
  return essentials.getIn(['workout', 'bodyParts'])
});

export const getWorkoutDifficulty = createSelector([getEssentials], (essentials) => {
  return [
    {
      name: 'easy',
      value: 'easy',
      slug: 'easy',
      type: 'difficulty'
    },
    {
      name: 'medium',
      value: 'medium',
      slug: 'medium',
      type: 'difficulty'
    },
    {
      name: 'hard',
      value: 'hard',
      slug: 'hard',
      type: 'difficulty'
    },
  ]
});

export const getWorkoutTime = createSelector([getEssentials], (essentials) => {
  return [
    {
      name: 'Under 30m',
      value: 'short',
      slug: 'short',
      type: 'time'
    },
    {
      name: '30-45m',
      value: 'medium',
      slug: 'medium',
      type: 'time'
    },
    {
      name: '45m+',
      value: 'long',
      slug: 'long',
      type: 'time'
    },
  ]
});

export const getCalendarSettings = createSelector([getUserSpecificEssential], (userSpecificEssential) => {
  return userSpecificEssential.getIn(['calendarSettings']);
})

export const getFetchStatus = (state) => {
  return {
    isCalendarFetching: state.getIn(['fetchStatus', 'calendar', 'isFetching']),
    isDataFetching: state.getIn(['fetchStatus', 'userEssentials', 'isFetching']),
    isDataFetched: state.getIn(['fetchStatus', 'userEssentials', 'isFetched']),
    startup: state.getIn(['fetchStatus', 'startup', 'isFetching']),
    recipesSearch: state.getIn(['fetchStatus', 'recipesSearch']).toJS(),
    workoutsSearch: state.getIn(['fetchStatus', 'workoutsSearch']).toJS(),
    userAuthenticate: state.getIn(['fetchStatus', 'userAuthenticate', 'isFetching']),
    preFetch: state.getIn(['fetchStatus', 'objectPreFetch', 'recipe', 'isFetching']) ||
      state.getIn(['fetchStatus', 'objectPreFetch', 'workout', 'isFetching']) ||
      state.getIn(['fetchStatus', 'objectPreFetch', 'blog', 'isFetching']) ||
      state.getIn(['fetchStatus', 'objectPreFetch', 'video', 'isFetching']),
  }
}
// Gets the calendar dates for a particular month
export const getCalendarDates = createSelector([getCalendarSettings], (calendarSettings) => {
  let result = getDatesForMonth(
    calendarSettings.getIn(['monthType']),
    calendarSettings.getIn(['activeDate']),
    calendarSettings.getIn(['anchorDate']),
    28
  );

  return result;
});

export const calendarListData = createSelector([getUserSpecificEssential], (userSpecificEssential) => {
  // Fn to calculate statistics
  const extractNutritionObject = (nutritionList, item) => {
    const nutritions = nutritionList.getIn(['groups', 0, 'lines']).toJS();
    const result = find(nutritions, nutrition => includes(toLower(nutrition.item), item));
    if (result) {
      return (result && result.amount) ? split(result.amount, ' ')[0] : null; // Extract '12 g'
    }
    return null; // Needed since it returns undefined
  }

  const calendarListData = userSpecificEssential
    .getIn(['calendarListData'])
    .toSeq()
    .reduce((accumulator, eachDayData) => {
      let mealsForDay = eachDayData.getIn(['meals']);
      // Calories, smart points, Protein, sugar, carbs, fat

      const statistics = mealsForDay
        .toSeq()
        .reduce((mealAccumulator, eachMeal) => {
          const recipeData = eachMeal.getIn(['recipe', 'variations', 0]);
          if (!isEmpty(recipeData)) {
            const recipeNutrition = recipeData.getIn(['nutrition']);


            let currentCalories = mealAccumulator.getIn(['calories']) || 0;
            let currentSmartPoints = mealAccumulator.getIn(['smartPoints']) || 0;
            let currentFreestylePoints = mealAccumulator.getIn(['freestylePoints']) || 0;
            let currentWWP = mealAccumulator.getIn(['wwp']) || 0;

            let currentProtein = mealAccumulator.getIn(['protein']) || 0;
            let currentSugar = mealAccumulator.getIn(['sugar']) || 0;
            let currentCarbs = mealAccumulator.getIn(['carbs']) || 0;
            let currentFat = mealAccumulator.getIn(['fat']) || 0;
            let currentSaturatedFat = mealAccumulator.getIn(['saturatedFat']) || 0;
            let currentFiber = mealAccumulator.getIn(['fiber']) || 0;

            const calories = parseInt(recipeData.getIn(['calories'])); // '213'
            const smartPoints = parseInt(recipeData.getIn(['smart_points'])); // '4'
            const freestylePoints = parseInt(recipeData.getIn(['freestyle_points']));
            const wwp = parseInt(recipeData.getIn(['wwp']));
            const protein = parseInt(extractNutritionObject(recipeNutrition, 'protein'));
            const sugar = parseInt(extractNutritionObject(recipeNutrition, 'sugar'));
            const carbs = !isNaN(parseInt(extractNutritionObject(recipeNutrition, 'carbohydrates'))) ? parseInt(extractNutritionObject(recipeNutrition, 'carbohydrates')) : parseInt(extractNutritionObject(recipeNutrition, 'carbohydrate'));
            const fat = parseInt(extractNutritionObject(recipeNutrition, 'fat'));

            const saturatedFat = parseInt(extractNutritionObject(recipeNutrition, 'saturated fat'));
            const fiber = parseInt(extractNutritionObject(recipeNutrition, 'fiber'));

            currentCalories = currentCalories + calories;
            currentSmartPoints = currentSmartPoints + smartPoints;
            currentFreestylePoints = currentFreestylePoints + freestylePoints;
            currentWWP = currentWWP + wwp;
            currentProtein = currentProtein + protein;
            currentSugar = currentSugar + sugar;
            currentCarbs = currentCarbs + carbs;
            currentFat = currentFat + fat;
            currentSaturatedFat = currentSaturatedFat + saturatedFat;
            currentFiber = currentFiber + fiber;

            mealAccumulator = mealAccumulator.setIn(['calories'], currentCalories)
            mealAccumulator = mealAccumulator.setIn(['smartPoints'], currentSmartPoints)
            mealAccumulator = mealAccumulator.setIn(['freestylePoints'], currentFreestylePoints);
            mealAccumulator = mealAccumulator.setIn(['wwp'], currentWWP);
            mealAccumulator = mealAccumulator.setIn(['protein'], currentProtein)
            mealAccumulator = mealAccumulator.setIn(['sugar'], currentSugar)
            mealAccumulator = mealAccumulator.setIn(['carbs'], currentCarbs)
            mealAccumulator = mealAccumulator.setIn(['fat'], currentFat)
            mealAccumulator = mealAccumulator.setIn(['saturatedFat'], saturatedFat);
            mealAccumulator = mealAccumulator.setIn(['fiber'], fiber);
          }

          return mealAccumulator
        }, Map())
      eachDayData = eachDayData.setIn(['statistics'], statistics);

      accumulator = accumulator.push(eachDayData);
      return accumulator;
    }, List());

  return calendarListData;
});


export const getStatisticsAverage = createSelector([calendarListData], (listData) => {
  let averageData = listData
    .toSeq()
    .reduce((accumulator, eachDayData) => {
      const statistics = eachDayData.getIn(['statistics']);

      const calories = statistics.getIn(['calories']);
      const smartPoints = statistics.getIn(['smartPoints']);
      const protein = statistics.getIn(['protein']);
      const sugar = statistics.getIn(['sugar']);
      const carbs = statistics.getIn(['carbs']);
      const fat = statistics.getIn(['fat']);

      const saturatedFat = statistics.getIn(['saturatedFat']);
      const fiber = statistics.getIn(['fiber']);
      const freestylePoints = statistics.getIn(['freestylePoints']);
      const wwp = statistics.getIn(['wwp']);

      if (calories) {
        accumulator.calories += calories;
        accumulator.caloriesCount += 1;
      }

      if (smartPoints) {
        accumulator.smartPoints += smartPoints;
        accumulator.smartPointsCount += 1;
      }

      if (protein) {

        accumulator.protein += protein;
        accumulator.proteinCount += 1;
      }

      if (sugar) {
        accumulator.sugar += sugar;
        accumulator.sugarCount += 1;
      }

      if (carbs) {
        accumulator.carbs += carbs;
        accumulator.carbsCount += 1;
      }

      if (fat) {
        accumulator.fat += fat;
        accumulator.fatCount += 1;
      }

      if (saturatedFat) {
        accumulator.saturatedFat += saturatedFat;
        accumulator.saturatedFatCount += 1;
      }

      if (fiber) {
        accumulator.fiber += fiber;
        accumulator.fiberCount += 1;
      }

      if (freestylePoints) {
        accumulator.freestylePoints += freestylePoints;
        accumulator.freestylePointsCount += 1;
      }

      if (wwp) {
        accumulator.wwp += wwp;
        accumulator.wwpCount += 1;
      }
      return accumulator;
    }, {
      calories: 0,
      caloriesCount: 0,
      smartPoints: 0,
      smartPointsCount: 0,
      protein: 0,
      proteinCount: 0,
      sugar: 0,
      sugarCount: 0,
      carbs: 0,
      carbsCount: 0,
      fat: 0,
      fatCount: 0,
      saturatedFat: 0,
      saturatedFatCount: 0,
      fiber: 0,
      fiberCount: 0,
      freestylePoints: 0,
      freestylePointsCount: 0,
      wwp: 0,
      wwpCount: 0,
    })

  const {
    calories,
    caloriesCount,
    smartPoints,
    smartPointsCount,
    protein,
    proteinCount,
    sugar,
    sugarCount,
    carbs,
    carbsCount,
    fat,
    fatCount,
    saturatedFat,
    saturatedFatCount,
    fiber,
    fiberCount,
    freestylePoints,
    freestylePointsCount,
    wwp,
    wwpCount,
  } = averageData;

  const dividor = (x, y) => y === 0 ? null : Math.floor(x / y);

  return {
    calories: dividor(calories, caloriesCount),
    smartPoints: dividor(smartPoints, smartPointsCount),
    protein: dividor(protein, proteinCount),
    sugar: dividor(sugar, sugarCount),
    carbs: dividor(carbs, carbsCount),
    fat: dividor(fat, fatCount),
    saturatedFat: dividor(saturatedFat, saturatedFatCount),
    fiber: dividor(fiber, fiberCount),
    freestylePoints: dividor(freestylePoints, freestylePointsCount),
    wwp: dividor(wwp, wwpCount)
  }
})


export const getCalendarView = createSelector([getUserSpecificEssential], (userSpecificEssential) => {
  return userSpecificEssential.getIn(['calendarSettings', 'calendarView']);
});

export const getCalendarMacros = createSelector([getUserSpecificEssential], (userSpecificEssential) => {
  return userSpecificEssential.getIn(['calendarSettings', 'macros']);
})

export const getShowPreMadePlan = createSelector([getUserSpecificEssential], (userSpecificEssential) => {
  return userSpecificEssential.getIn(['calendarSettings', 'showPreMadeMealPlans']);
})

export const getShowViewableMacros = createSelector([getUserSpecificEssential], (userSpecificEssential) => {
  return userSpecificEssential.getIn(['calendarSettings', 'showViewableMacros']);
})

export const getAutomateServingSize = createSelector([getUserSpecificEssential], (userSpecificEssential) => {
  return userSpecificEssential.getIn(['calendarSettings', 'automateServingSize']);
})

export const getProgressStatus = state => ({
  allToGroceryList: state.getIn(['fetchStatus', 'addAllCalendarItemsToGrocery', 'isProgressing']),
  addCustomMealPlanToCalendar: {
    isProgressing: state.getIn(['fetchStatus', 'addCustomMealPlanToCalendar', 'isProgressing']),
    status: state.getIn(['fetchStatus', 'addCustomMealPlanToCalendar', 'status']),
  },
  addSingleMeal: {
    isProgressing: state.getIn(['fetchStatus', 'addSingleMealInMealPlan', 'isProgressing']),
    date: state.getIn(['fetchStatus', 'addSingleMealInMealPlan', 'date']),
  },
  addSingleCalendarItemsToGrocery: {
    isProgressing: state.getIn(['fetchStatus', 'addSingleCalendarItemsToGrocery', 'isProgressing']),
    date: state.getIn(['fetchStatus', 'addSingleCalendarItemsToGrocery', 'date']),
  },
  addSavedDay: {
    isProgressing: state.getIn(['fetchStatus', 'addSavedDay', 'isProgressing']),
    date: state.getIn(['fetchStatus', 'addSavedDay', 'date']),
  }
})