import axios from 'axios';
import moment from 'moment';
import { get, post, remove, patch } from '../../helpers/requests';
import { mealplan } from '../../helpers/api';
import { call, select } from 'redux-saga/effects';
import { getToken, getCalendarAnchorDate, getCalendarDataForDays } from '../selectors';

export default {
  get: function* getMealplan(objectType, objectId) {
    const token = yield select(getToken);
    const anchorDate = yield select(getCalendarAnchorDate);
    const dataForDays = yield select(getCalendarDataForDays);
    const startDate = moment(anchorDate).subtract({ days: dataForDays }).format('YYYY-MM-DD');

    const endDate = moment(startDate, 'YYYY-MM-DD').add(2 * dataForDays, 'days').format('YYYY-MM-DD');

    const mealPlanResponse = yield call(axios, get(mealplan.get(startDate, endDate), '', token));
    return mealPlanResponse;
  },

  post: function* addMealToMealPlan(objectData) {
    const token = yield select(getToken);
    const mealPlan = yield call(axios, post(mealplan.post(), objectData, '', token));
    return mealPlan;
  },

  deleteMeal: function* deleteMealFromMealPlan(meal_id) {
    const token = yield select(getToken);
    const response = yield call(axios, post(mealplan.deleteV2(), ({ id: meal_id }), '', token));
    return response.data;
  },

  moveMeal: function* moveMealInMealPlan(meal_id, date, order, fromDate) {
    const token = yield select(getToken);
    const response = yield call(axios, patch(mealplan.patch(meal_id), { date, order, from_date: fromDate }, '', token))
    return response.data;
  }
} 