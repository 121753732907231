import React, { useState } from 'react';
import { map, isNull } from 'lodash';
import DownArrow from '../../images/DownArrow';
import UpArrow from '../../images/UpArrow';
import ThumbFavIcon from '../../images/ThumbFavIcon';


const FeaturedMetadata = (props) => {
  const [state, setState] = useState({
    showDropdown: false
  })



  const rederPrimaryMetaDeta = (primaryMetaData, rating) => {
    return (
      <ul className="featuredMetadata">
        {
          map(primaryMetaData, (item, index) => {
            return (
              <li key={index}>{item.title}<span>{(isNull(item.value)) ? '-' : item.value}</span></li>
            )
          })
        }

        {
          <li>
            <div className="recipe-favorite-count">
              <ThumbFavIcon></ThumbFavIcon>
              <span className="fav-text">Likes</span>
              <span>{props.favoriteCount}</span>
            </div>
          </li>
        }
      </ul>
    )
  }

  const rederSecondaryMetaData = (secondaryMetaData) => {
    return (
      <div className={`equipment ${(!secondaryMetaData.items || (secondaryMetaData.items && secondaryMetaData.items.length === 0)) && 'disabled'}`}>
        <h6
          onClick={() => setState(prevState => ({ ...prevState, showDropdown: !state.showDropdown }))}>
          {state.showDropdown ? 'Hide' : 'View'} {secondaryMetaData.title}
          {state.showDropdown ? <UpArrow /> : <DownArrow />}
        </h6>
        {
          state.showDropdown &&
          <div className="showFullEquipment">
            {map(secondaryMetaData.items, (item, index) => {
              return (
                <li className="equipmentList" key={index}>
                  <p>{item.item}</p>
                  {
                    item.amount && <p className="equipmenAmount">{item.amount}</p>
                  }
                </li>
              )
            })}
          </div>
        }
      </div>
    )
  }

  return (
    <>
      {
        rederPrimaryMetaDeta(props.primaryMetaData, props.rating)
      }
      {
        rederSecondaryMetaData(props.secondaryMetaData)
      }
    </>
  )
}
export default FeaturedMetadata;