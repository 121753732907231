import React from "react";
import '../styles/common.scss';


const WeightWatcherIcon = (props) => {
  return (
    < >
      <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M9.88 3.64L5.44 12.82L1 1" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.76 1L14.32 12.82L9.88 3.64" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M9.88 15.8201L5.44 25.0001L1 13.1801" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M18.76 13.1801L14.32 25.0001L9.88 15.8201" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          {/* <clipPath id="clip0">
            <rect width="19.76" height="26" fill="white" />
          </clipPath> */}
        </defs>
      </svg>
    </>
  );
};
export default WeightWatcherIcon;
