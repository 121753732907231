import React, { useState, useEffect } from 'react'
import GhostCover from './ghostCover'
import DottedIcon from '../images/DottedIcon'
import '../styles/common.scss';

const DottedButton = ({ popoverDisableReposition, ...props }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);

  const displayByBlur = (props.hideWhenBlurred ? (props.hideWhenBlurred && props.isFocused) : true)

  useEffect(() => {
    if (!displayByBlur) {
      setDisplayDropdown(false);
    }
  }, [displayByBlur])

  const onSetDisplayDropdown = (value) => {
    setDisplayDropdown(value);
    props.setIsPopoverOpen(value);
  }

  return (
    <GhostCover
      {...props}
      displayDropdown={displayDropdown}
      onClick={() => {
        onSetDisplayDropdown(!displayDropdown)
      }}
    >
      <DottedIcon
        options={props.options}
        displayDropdown={displayDropdown && displayByBlur}
        setDisplayDropdown={onSetDisplayDropdown}
        displayIcon={props.displayIcon}
        popover={true}
        popoverDisableReposition={popoverDisableReposition}
      ></DottedIcon>
    </GhostCover>
  )
}

DottedButton.defaultProps = {
  popoverDisableReposition: true,
  hideWhenBlurred: false,
  setIsPopoverOpen: () => {}
}

export default DottedButton;