import { createSelector } from "reselect";
import { fromJS } from "immutable";

export const getEssentials = (state: any) => state.getIn(['essentials']);

export const getFaqs = createSelector([getEssentials], essentials => essentials.getIn(['faqs', 'list']))

export const getFaqFetchStatus = (state: any) => {
  return fromJS({
    faqList: state.getIn(['fetchStatus', 'faqList'])
  })
}

export const getUserDetails = state => state.getIn(['loggedInUser']);