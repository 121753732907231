import React, { useState, useEffect } from 'react'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import concat from 'lodash/concat'
import InfiniteScroll from 'react-infinite-scroller';

import Loader from '../../images/loader';
import ModalWrapper from '../../common/modal'
import Dropdown from '../../common/dropdown'
import Button from '../../common/button'
import CustomInput from '../../common/customInput'

import PlusIcon from '../../images/PlusIcon'
import useAddSavedDayHook from '../../../helpers/useAddSavedDayHook'

const AddSaveDayModal = ({
  setAddSavedDayState,
  addSavedDayModalState,
  onRequestClose,
  onAddSaveDay,
}) => {
  const [customName, setCustomName] = useState('');
  const [savedDayState, setSavedDayState] = useAddSavedDayHook({
    token: localStorage.getItem('token'),
  })

  return (
    <ModalWrapper
      isOpen={addSavedDayModalState.show}
      // overflowY="hidden"
      onRequestClose={onRequestClose}
      minHeight="410px"
      maxWidth="500px"
    >
      <div className="modalWrapper">
        <div className="modalHeader">
          <h3>
            Add Saved Day
        </h3>
        </div>
        <div className="modalBody AddSaveBody">
          <div className="add-save-modal">
            <InfiniteScroll
              className="infiniteScroll"
              initialLoad={false}
              useWindow={false}
              loadMore={!savedDayState.isFetching ? () => {
                setSavedDayState({
                  ...savedDayState,
                  offset: savedDayState.offset + 25,
                })
              } : () => {

              }}
              hasMore={savedDayState.hasMore}
              loader={
                <div style={{ width: '100px', height: '100px', margin: 'auto' }}>
                  <Loader></Loader>
                </div>
              }
            >
              {
                (!savedDayState.hasMore && !savedDayState.isFetching && savedDayState.data.length === 0) ? (
                  <div className="list gallery-list">
                    No Saved days
              </div>
                ) : (
                    <ul className="list">
                      <>
                        {
                          map(savedDayState.data, (item, index) => {
                            return (
                              <li
                                key={index}
                                className="item"
                                onClick={() => onAddSaveDay(item.id)}
                              >
                                <div className="title">
                                  {capitalize(item.title)}
                                </div>
                                <div className="plus-icon">
                                  <PlusIcon />
                                </div>
                              </li>
                            )
                          })
                        }
                      </>
                    </ul>
                  )
              }
            </InfiniteScroll>
          </div>
          <div className="modalFooter">
            <Button
              btnClass="secondary btn-full btn-bp-sm"
              btnText="Cancel"
              onClick={() => onRequestClose()}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

export default AddSaveDayModal;