import { put, takeEvery, call, select } from 'redux-saga/effects'
import axios from 'axios'
import capitalize from 'lodash/capitalize'
import * as actions from '../actions/actionTypes';
import { showToastMessage, showGroceryListLimitNotification } from '../actions'
import { mealPlan } from '../helpers/api';
import pushToSentry from '../helpers/pushToSentry';
import calculateIngredients from '../helpers/calculateIngredients';
import { post } from '../helpers/requests';
import { getToken } from './selectors';
import groceryTask from './subtask/grocery';
import { isNil } from "lodash";

function* task(action) {
  const limit = 500;
  let objectData;
  const token = yield select(getToken);
  try {
    if (action.payload.objectType === 'recipe') {
      objectData = {
        recipe_id: action.payload.objectId,
        dates: action.payload.dates,
        meal: action.payload.meal
      }
    } else {
      objectData = {
        workout_id: action.payload.objectId,
        dates: action.payload.dates,
        meal: action.payload.meal
      }
    }
    const { data } = yield call(axios, post(mealPlan.addMealToMultipleDays(), objectData, '', token));

    yield put(showToastMessage(`👍🏼 ${capitalize(action.payload.objectType)} has been added to meal plan`, 'success', 'addIngredient'));

    yield put({
      type: actions.ADD_MEAL_TO_MULTIPLE_DATES_SUCCESS,
      payload: {
        success: true,
        mealData: data
      }
    })

    if (
      action.payload.objectType === "recipe" &&
      action.payload.addToGroceryList &&
      action.payload.recipeServingSize > 0
    ) {
      // calculate ingredients
      let ingredients = calculateIngredients({
        recipe: action.payload.activeObject,
        variation: 0,
        servingSize: `${(parseInt(action.payload.recipeServingSize) * action.payload.dates.length)}`
      });

      let groceryResponse = yield call(groceryTask.addToGroceryList, { items: ingredients, limit: limit });
      if (!isNil(groceryResponse.isLimitExceeded) && groceryResponse.isLimitExceeded) {
        yield put(showGroceryListLimitNotification(groceryResponse.items));
      } else {
        yield put(showToastMessage(`✅ Ingredients added to groceries`, 'success'));
        yield put({
          type: actions.ADD_ALL_TO_GROCERYLIST_SUCCESS,
          payload: {
            success: true,
            addedIngredients: groceryResponse.data.new_items
          }
        })
      }
    }

  } catch (e) {
    yield put(showToastMessage(`❗️Adding new item failed`, 'error'));

    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    // yield put({
    //   type: actions.ADD_MEAL_TO_MULTIPLE_DATES_FAILURE,
    //   payload: {
    //     error: e.message
    //   }
    // })
  }
}

export default function* addMealToMultipleDays() {
  yield takeEvery(actions.ADD_MEAL_TO_MULTIPLE_DATES, task)
}