import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'
import { showToastMessage, showGroceryListLimitNotification } from '../actions'
import { remove } from '../helpers/requests'

import isNil from "lodash/isNil";

import groceryTask from './subtask/grocery'
import { getToken } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    const limit = 500;

    // Should be item Ids
    const items = [{
      item: action.payload.ingredientName,
      category_id: action.payload.categoryId,
      ingredient_name: action.payload.name,
      ingredient_unit: action.payload.unit,
      amount: action.payload.quantity,
    }];

    let addResponse = yield call(groceryTask.addToGroceryList, { items: items, limit: limit });

    if (!isNil(addResponse.isLimitExceeded) && addResponse.isLimitExceeded) {
      yield put(showGroceryListLimitNotification(addResponse.items));
    } else {
      yield put({
        type: actions.ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE_SUCCESS,
        payload: {
          ingredientName: action.payload.ingredientName,
          categoryId: action.payload.categoryId,
          id: addResponse.data.new_item.id
        }
      })

      yield put(showToastMessage(`✅ ${action.payload.ingredientName} added.`, 'success', 'addIngredient'));
    }

  } catch (e) {
    yield put(showToastMessage('❗️ Error occured while adding ingrident to groceries.', 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE_FAILURE,
    })
  }
}

export default function* addIngredientsInGroceryList() {
  yield takeEvery(actions.ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE, task)
}