import React from 'react'
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const InstaOutlineIcon = () => {
  return (
    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 8.5C1 4.3625 4.3625 1 8.5 1H22.25C26.3875 1 29.75 4.3625 29.75 8.5V22.25C29.75 26.3875 26.3875 29.75 22.25 29.75H8.5C4.3625 29.75 1 26.3875 1 22.25V8.5Z" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.375 22.875C19.5125 22.875 22.875 19.5125 22.875 15.375C22.875 11.2375 19.5125 7.875 15.375 7.875C11.2375 7.875 7.875 11.2375 7.875 15.375C7.875 19.5125 11.2375 22.875 15.375 22.875Z" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M24.125 8.5C25.1625 8.5 26 7.6625 26 6.625C26 5.5875 25.1625 4.75 24.125 4.75C23.0875 4.75 22.25 5.5875 22.25 6.625C22.25 7.6625 23.0875 8.5 24.125 8.5Z" stroke={themeTextRed} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  )
}

export default InstaOutlineIcon; 