import React from 'react'
import GhostCover from './ghostCover'
import UpArrow from '../images/UpArrowLarge'

const RightButton = (props) => (
  <GhostCover {...props}>
    <UpArrow></UpArrow>
  </GhostCover>
)

export default RightButton