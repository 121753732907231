import React, { useState, useEffect } from 'react'

import SearchInput from '../searchInput';

import MobileView from './mobileView';
import DesktopView from './desktopView';

const Filters = ({
  placeholder,
  onSearchChange,
  searchText,
  // history,
  dropDownReturnActionText,
  dropDownReturnAction,
  selectorList,
  defaultSearchBy,
  searchList,
  searchStatus,
  searchCount,
  onSearchByChange,
  objectType,
  onCancelClick,
  showInstantResults,
  onClickSearchClear,
  ...props
}) => {
  const [showNavList, setShowNavList] = useState(true);
  const [windowWidth, setWindowWidth] = useState(undefined);

  const [state, setState] = useState({
    initialSearchValue: ''
  })

  useEffect(() => {
    if (state.initialSearchValue.length === 0 && searchText && searchText.length > 0) {
      setState({ ...state, initialSearchValue: searchText });
    }
  }, [searchText]);

  const clearSearchText = () => {
    setState({ ...state, initialSearchValue: "" });
  }

  // sets the showNavList
  useEffect(() => {
    if (window.innerWidth <= 767) {
      setShowNavList(false);
      setWindowWidth(window.innerWidth)
    } else if (window.innerWidth > 767) {
      setShowNavList(true);
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 767) {
        setShowNavList(false);
        setWindowWidth(window.innerWidth)
      } else if (window.innerWidth > 767) {
        setShowNavList(true);
        setWindowWidth(window.innerWidth)
      }
    });
  }, [])

  const renderMobileFilterWrapper = () => {
    return (

      <div className='filterSearch'>
        <SearchInput
          placeholder={placeholder}
          onChange={(value, searchBy) => { onSearchChange(value, searchBy) }} // for debounce on change
          updateInputValue={(value) => setState({ ...state, initialSearchValue: value })} // to update the local state
          searchText={searchText}
          history={props.history}
          dropDownReturnActionText={dropDownReturnActionText}
          dropDownReturnAction={(searchText, searchBy) => dropDownReturnAction(searchText, searchBy)}
          selectorList={selectorList}
          defaultSearchBy={defaultSearchBy}
          searchList={searchList}
          searchStatus={searchStatus}
          searchCount={searchCount}
          onSearchByChange={(value, searchText) => { onSearchByChange(value, searchText) }}
          initialSearchValue={state.initialSearchValue}
          objectType={objectType}
          isResponsive={!showNavList}
          showInstantResults={showInstantResults}
          onClickSearchClear={onClickSearchClear}
        >
          <MobileView {...props} objectType={objectType} />
        </SearchInput>
      </div>
    )
  }

  // renders the singel input compoent only
  const renderDesktopFilterWrapper = () => {
    return (
      <div className='filterSearch'>
        <SearchInput
          placeholder={placeholder}
          onChange={(value, searchBy) => { onSearchChange(value, searchBy) }} // for debounce on change
          updateInputValue={(value) => setState({ ...state, initialSearchValue: value })} // to update the local state
          searchText={searchText}
          history={props.history}
          dropDownReturnActionText={dropDownReturnActionText}
          dropDownReturnAction={(searchText, searchBy) => dropDownReturnAction(searchText, searchBy)}
          selectorList={selectorList}
          defaultSearchBy={defaultSearchBy}
          searchList={searchList}
          searchStatus={searchStatus}
          searchCount={searchCount}
          onSearchByChange={(value, searchText) => { onSearchByChange(value, searchText) }}
          initialSearchValue={state.initialSearchValue}
          objectType={objectType}
          isResponsive={!showNavList}
          showInstantResults={showInstantResults}
          onClickSearchClear={onClickSearchClear}
        >
          <DesktopView {...props} objectType={objectType} clearSearchText={clearSearchText} />
        </SearchInput>
      </div>
    )
  }

  // MAIN RETURN
  return (
    <div className='filtersWrapper'>
      {showNavList ? renderDesktopFilterWrapper() : renderMobileFilterWrapper()}
    </div>
  )
}

export default Filters