import React from "react";
import '../styles/common.scss';


const FullBody = (props) => {
  return (
    < >
      <svg width="40" height="66" viewBox="0 0 40 66" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
          <path d="M15.4209 1.07251C15.4209 4.0323 13.6193 6.69181 10.8954 7.78565L4.311 10.4452C2.31636 11.2602 1.60858 12.7401 1.28687 14.5846C0.922257 16.7079 1.05094 18.8741 1.45845 20.9974L2.85255 28.354" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M24.5791 1.07251C24.5791 4.0323 26.3807 6.69181 29.1045 7.78565L35.689 10.4452C37.7051 11.2602 38.4129 12.7401 38.7131 14.5631C39.0777 16.6865 38.949 18.8527 38.5415 20.976L37.1474 28.3326" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.80697 19.9465C9.63003 22.3058 11.7319 25.8876 11.8391 30.2843C11.9464 35.1101 9.65148 38.3272 7.24933 42.9385C3.96783 49.1798 5.19035 53.6623 9.54424 64.2146" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M32.0214 19.9465C30.1984 22.3058 28.0965 25.8876 27.9893 30.2843C27.882 35.1101 30.1769 38.3272 32.5791 42.9385C35.8606 49.1798 34.6381 53.6623 30.2842 64.2146" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
          {/* <clipPath id="clip0">
            <rect width="40" height="65.2654" fill="white" />
          </clipPath> */}
        </defs>
      </svg>
    </>
  );
};
export default FullBody;
