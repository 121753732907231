import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import toJS from '../../../helpers/toJS';
import queryString from 'query-string';
import { auth } from '../../../helpers/api'
import pushToSentry from '../../../helpers/pushToSentry';
import Button from '../../common/button'
import CustomInput from "../../common/customInput";
import FrameIcon from '../../images/frameIcon';
import Loader from '../../images/loader';
import katie from '../../images/login-katie.png'


import {
  addSentryError
} from '../../../actions';

const Forgot = (props) => {
  const [state, updateState] = useState({
    isResetting: false,
    isResetSuccess: false,
    isResetFailure: false,
    resetErrorMessage: null,
    isValidatingExpiry: true,
    isValidLink: true
  })

  const [password, setPassword] = useState(null);

  useEffect(() => {
    if (state.isResetSuccess) {
      setTimeout(() => {
        props.history.push('/recipes')
      }, 3000)
    }
  }, [state.isResetSuccess])

  useEffect(() => {
    const query = queryString.parse(props.location.search, { arrayFormat: 'comma' });
    updateState({
      ...state,
      isValidatingExpiry: true
    })
    axios({
      method: 'POST',
      url: auth.checkPasswordResetExpiryStatus(),
      data: {
        email: query.email
      }
    }).then((response) => {
      if (response.data.success) {
        updateState({
          ...state,
          isValidatingExpiry: false,
          isValidLink: true,
          isResetting: false
        })
      } else {
        updateState({
          ...state,
          isValidatingExpiry: false,
          isValidLink: false,
          isResetting: false
        })
      }
    }).catch((e) => {
      const eventId = pushToSentry(e)
      props.addSentryError({
        error: e.message,
        eventId
      })
      updateState({
        ...state,
        isValidatingExpiry: false,
        isValidLink: false,
        isResetting: false
      })
    })
  }, [])

  const handleResetPassword = () => {
    const query = queryString.parse(props.location.search, { arrayFormat: 'comma' });
    updateState({
      ...state,
      isResetting: true
    })
    axios({
      method: 'POST',
      url: auth.resetPassword(),
      data: {
        email: query.email,
        token: query.token,
        user: {
          password
        }
      }
    }).then((response) => {
      if (response.data.success) {
        updateState({
          ...state,
          isResetSuccess: true,
          isResetting: false
        })
      } else {
        updateState({
          ...state,
          isResetting: false,
          isResetFailure: true,
          resetErrorMessage: 'Some error occured',
        })
      }
    }).catch((e) => {
      pushToSentry(e)
      updateState({
        ...state,
        isResetting: false,
        isResetFailure: true,
        resetErrorMessage: 'Link is either expired / invalid.',
      })
    })
  }

  console.log('is validating ', state.isValidatingExpiry)
  if (state.isValidatingExpiry) {
    return (
      <div className="preLoader">
        <Loader></Loader>
      </div>
    )
  }

  if (!state.isValidatingExpiry && !state.isValidLink) {
    return (
      <>
        <p className="login-invalid-text">The link seems to be expired / invalid. <br></br>Please contact support@dashingdish.com</p>
      </>
    )

  }

  return (
    <>
      <section className="login-section">
        <Helmet>
          <title>Reset Password | Dashing Dish</title>
          <meta name="description" content="Dashingdish Reset Password page" />
        </Helmet>
        <Row className="no-margin">
          <Col xs={12} md={12} lg={6} className="no-padding-sm">
            <div className="login-wrapper">
              <div className="loginBlock">
                <h1>Enter new password</h1>
                <p className="login-intro"></p>
                <div>
                  <CustomInput
                    type="password"
                    label="Password"
                    placeholder="Type here"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    isLastElementOfForm={true}
                    formLastElementAction={() => handleResetPassword()}
                  />
                  <Button
                    type="button"
                    btnClass="primary btn-full"
                    btnText="Submit"
                    disabled={!(password && password.length > 3)}
                    isLoading={state.isResetting}
                    onClick={handleResetPassword}
                  />
                  {
                    state.isResetFailure && (
                      <div className="errorText">
                        <FrameIcon></FrameIcon>
                        <p>
                          {state.resetErrorMessage}
                        </p>
                      </div>
                    )
                  }
                  {
                    state.isResetSuccess && (
                      <div className="errorText">
                        <FrameIcon></FrameIcon>
                        <p>
                          Your password has been reset successfully. Redirecting you to the site.
                          You can login from there.
                        </p>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={12} lg={6} className="image-bg-wrapper"
            style={{
              backgroundImage: `url(${katie})`
            }}
          >
          </Col>
        </Row>
      </section>
    </>
  )
};

const mapStateToProps = state => ({
})

const dispatchActionToProps = dispatch => ({
  addSentryError: bindActionCreators(addSentryError, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Forgot));