import axios from 'axios'
import { showToastMessage, showGroceryListLimitNotification } from '../actions';

import { put, takeEvery, call, select } from 'redux-saga/effects';
import isNil from 'lodash/isNil'
import toString from 'lodash/toString'

import * as actions from '../actions/actionTypes'
import groceryTask from './subtask/grocery'
import { getToken, getCurrentRecipeId } from './selectors'
import { fromJS } from 'immutable';
import { getCategoryId } from '../helpers/categories'
import pushToSentry from '../helpers/pushToSentry';

function* task(action) {
  try {
    const limit = 500;

    const { ingredientData, recipeId } = action.payload


    const filteredIngredientData = (ingredientData || [])
      .filter(ingredient => !ingredient.header)
      .map((ingredient) => {

        let itemName = ingredient.ingredient?.display_singular_name ? ingredient.ingredient?.display_singular_name : '';
        let amount = ingredient.ingredient_unit?.display_singular_name ? ingredient.ingredient_unit?.display_singular_name : '';
        let quantity = ingredient.quantity ? ingredient.quantity : '';

        let addIngredientName = `${quantity} ${amount} ${itemName}`;

        let itemObject = {
          item: addIngredientName,
          category_id: getCategoryId(ingredient?.ingredient?.category),
          recipe_id: recipeId,
          ingredient_name: itemName,
          amount: toString(quantity),
          ingredient_unit: toString(amount)
        }

        return itemObject
      }, [])


    let groceryResponse = yield call(groceryTask.addToGroceryList, {
      items: filteredIngredientData,
      limit: limit
    });


    if (groceryResponse && groceryResponse.isLimitExceeded) {
      yield put(showGroceryListLimitNotification(groceryResponse.items));
    } else {
      yield put({
        type: actions.ADD_ALL_TO_GROCERYLIST_SUCCESS,
        payload: {
          success: true,
          addedIngredients: groceryResponse.data.new_items
        }
      })
      // Do it differently for workouts / videos / blogs
      yield put(showToastMessage(`✅ ${groceryResponse.data.new_items.length} items added to groceries.`, 'success', 'addAllGroceryList'));
    }
  } catch (e) {
    yield put(showToastMessage(`❗️ Error in updating groceries.`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.ADD_ALL_TO_GROCERYLIST_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* addAllToGroceryList() {
  yield takeEvery(actions.ADD_ALL_TO_GROCERYLIST, task)
}