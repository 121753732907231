import React, { useState, useEffect } from 'react';
import FloatingButton from './FloatingButton';
import ModalWrapper from "../common/modal";
import Button from '../common/button';
import PillSelector from '../common/PillSelector';
import Autocomplete from '../common/Autocomplete';
import { INGREDIENT_DATA } from '../common/ingredient_data';
import { useRecipeSearchByIngredients } from '../../helpers/useRecipeSearchByIngredients';
import img from '../images/katieAvatar.png'

function LeftoverReciepe(props) {
  const [openChooseIngredients, setOpenChooseIngredients] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [selectedIngredients, setSelectedIngredients] = useState([]);
  const [onCloseInfo, setOnCloseInfo] = useState(true);
  const { data: recipesCount, isLoading } = useRecipeSearchByIngredients(selectedIngredients);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  useEffect(() => {
    const selectIngredients = INGREDIENT_DATA.reduce((acc, curr) => {
      acc.push({
        title: curr.ingredient_name,
        value: curr.ingredient_name,
        disabled: false,
        recipeCount: curr.recipe_count
      })
      return acc
    }, [])
    setIngredients(selectIngredients)

    const timer = setTimeout(() => {
      setIsButtonVisible(true)
    }, 5000)

    return () => clearTimeout(timer)
  }, [])

  const onSelectOption = (option) => {
    setIngredients(data => data.map(value => {
      if (value.title === option) {
        return ({ ...value, ...{ disabled: true } })
      } else {
        return value
      }
    }))
    setSelectedIngredients((value) => [...value, option])
  }

  const onClose = () => {
    setOpenChooseIngredients(false)
    setSelectedIngredients([])
    // Reset values to normal
    setIngredients(data => data.map(value => ({ ...value, ...{ disabled: false } })))
  }

  const onClickSearchRecepies = (e) => {
    onClose()
    e.preventDefault()
    if (selectedIngredients.length) {
      props.history.push(`recipes?search=${selectedIngredients.join(',')}&search_by=ingredients`)
    } else {
      props.showToastMessage('Select an Ingredient!')
    }
  }

  const onRemoveSelection = (item) => {
    setSelectedIngredients(data => data.filter(value => value != item))
    setIngredients(data => data.map(value => {
      if (value.title === item) {
        return ({ ...value, ...{ disabled: false } })
      } else {
        return value
      }
    }))
  }

  const FLOATING_BUTTON = (
    <div
      className={`floating-button floating-ai-button ${isFirstLoad ? 'animation-floating-div' : ''}`}
      onClick={() => setOpenChooseIngredients(true)}
    >
      {onCloseInfo ? <p>
        Got leftover ingredients? Turn them into a healthy new recipe!
        <span className="close-icon" onClick={() => setOnCloseInfo(false)}>×</span>
      </p> : null}
      <img src={img} alt="Katie avatar" />
    </div>
  );

  return (
    <>
      {props.isAuthenticated ? (<div>
        {!openChooseIngredients && isButtonVisible ? FLOATING_BUTTON : null}

        <ModalWrapper
          isOpen={openChooseIngredients}
          showClose={true}
          minHeight='670px'
          maxWidth='650px'
          onRequestClose={onClose}
        >
          <div className="modalWrapper">
            <div className="modalHeader">
              <h3 style={{ textAlign: 'center', width: "100%" }}>Create recipe from leftovers</h3>
            </div>
            <div className="modal-body">
              <div className="ingredients-container">
                <section>
                  <p>Choose a few ingredients from your leftovers to get the recipe</p>
                  <Autocomplete suggestions={ingredients} onSelect={onSelectOption} />
                  <ul className="selected-ingredients-list">
                    {selectedIngredients.map((item, i) => (
                      <PillSelector
                        key={`${item}-${i}`}
                        label={item}
                        onRemove={onRemoveSelection}
                        className="pill-selector"
                      />
                    ))}
                  </ul>
                </section>
              </div>

            </div>
            <div className="modalFooter">
              <Button
                btnClass="primary btn-full"
                btnText={recipesCount?.count ? <>Search Recipes <p className='found-recipes-count'>(Found in {recipesCount?.count} recipes)</p></> : 'Search Recipes'}
                onClick={onClickSearchRecepies}
                isLoading={isLoading}
              />
              <Button
                btnClass="secondary btn-full btn-bp-sm"
                btnText={`Cancel`}
                onClick={onClose}
              />
            </div>
          </div>
        </ModalWrapper>
      </div>) : null
      }
    </>
  )
}
export default LeftoverReciepe;