import React from "react";
import '../styles/common.scss';

const TestimonialIcon = (props) => {
  return (
    <>
      <svg width="44" height="31" viewBox="0 0 44 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.9 12.6999C32.5 7.4999 36.6 5.3999 43.3 4.4999V0.399902C30.1 0.799903 23 7.2999 23 18.0999C23 25.3999 27.1 30.3999 33.6 30.3999C39 30.3999 43.3 26.9999 43.3 21.3999C43.3 16.2999 40.2 13.6999 36 12.6999H31.9ZM8.9 12.6999C9.5 7.4999 13.6 5.3999 20.3 4.4999V0.399902C7.1 0.799903 0 7.2999 0 18.0999C0 25.3999 4.1 30.3999 10.6 30.3999C16 30.3999 20.3 26.9999 20.3 21.3999C20.3 16.2999 17.2 13.6999 13 12.6999H8.9Z" fill="#2C2F34" />
      </svg>
    </>
  );
};
export default TestimonialIcon;
