import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import DownArrow from "../../web/images/DownArrow";
import Footer from "../../web/layout/footer";
import Button from "../common/button";
import Modalwrapper from "./modal";
import capitalize from "lodash/capitalize";

let footerRef = React.createRef();

const PaywallLimiter = ({
  children,
  readCount,
  isAuthenticated,
  premium,
  objectType,
  history,
  changeFooterStatus,
  agreeToMeteredVisit,
  closeVideoPaywall,
}) => {
  const [showLimit, setShowLimit] = useState(true);
  const [footerHeight, setFooterHeight] = useState(true);
  const [isResponsive, setResponsive] = useState(false);

  useEffect(() => {
    if (window.innerWidth <= 767) {
      setResponsive(true);
    } else if (window.innerWidth > 767) {
      setResponsive(false);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 767) {
        setResponsive(true);
      } else if (window.innerWidth > 767) {
        setResponsive(false);
      }
    });
  }, []);

  useEffect(() => {
    footer = document.getElementsByTagName("footer");
    setFooterHeight(footer && footer.length > 0 && footer[0].offsetHeight);
  }, []);
  useEffect(() => {
    if (isResponsive) {
      if (!isAuthenticated && showLimit) {
        window.scrollTo(0, 0);
        changeFooterStatus(false);
        document.body.style.overflow = "hidden";
      } else {
        changeFooterStatus(true);
        document.body.style.overflow = "auto";
      }
      return function cleanup() {
        document.body.style.overflow = "auto";
      };
    }
  }, [showLimit, isAuthenticated, isResponsive]);

  if (window.location.pathname.includes("/video/")) {
    return (
      <Modalwrapper
        additionalClass="paywall-limiter-modal"
        maxWidth="560px"
        minHeight="250px"
        margin={isResponsive ? null : "300px auto"}
        showClose={false}
        isOpen={true}
        onRequestClose={() => { }}
      >
        <div className="paywall-limiter">
          <div className="contact-point-container video-container">
            <h4>
              <>This is a premium {objectType}. Subscribe to view.</>

            </h4>
            <p>
              We are so excited you are excited about this {objectType}! <br />
              We work hard to produce one of a kind {objectType}s and content, and in order for us to keep providing <br />
              these wonderful {objectType}s, with no ads or gimmicks, we have a membership with meal plans, recipes, cooking shows and more!
            </p>
            <div className="btn-group" style={{ display: "flex" }}>
              <Button
                type="button"
                btnClass="primary"
                btnText={
                  premium
                    ? "Signup"
                    : isResponsive
                      ? "Plans & Pricing"
                      : "See Plans & Pricing"
                }
                onClick={
                  premium
                    ? () => history.push("pricing")
                    : () => history.push("/pricing?type=view")
                }
              />
              {premium ? (
                <Button
                  type="button"
                  btnClass="grey"
                  btnText={
                    isResponsive ? "Plans & Pricing" : "See Plans & Pricing"
                  }
                  onClick={() => history.push("/pricing?type=view")}
                />
              ) : (
                <a
                  href="/recipes"
                  onClick={(e) => {
                    e.preventDefault();
                    closeVideoPaywall();
                  }}
                  className="link-arrow continue-reading"
                >
                  {isResponsive
                    ? `Go to ${objectType.substring(0, objectType.length - 1)}`
                    : `Continue to ${capitalize(
                      objectType.substring(0, objectType.length - 1)
                    )}`}
                  <DownArrow></DownArrow>
                </a>
              )}
            </div>
          </div>
        </div>
      </Modalwrapper>
    );
  }

  const premiumContentOne = () => (
    <>
      <div className="contact-point-container">
        <h4>

          <>
            <>This is a premium {objectType}.</>
          </>

        </h4>
        <p>
          Don’t get FOMO. Unlock full access to Dashing Dish with Sign up
          and get access to all our recipes and meal plans
        </p>
        <div className="btn-group" style={{ display: "flex" }}>
          <Button
            type="button"
            btnClass="primary"
            btnText={isResponsive ? "Plans & Pricing" : "Signup"}
            onClick={() => history.push("/pricing?type=view")}
          />
          {/* <a
            href="/recipes"
            onClick={(e) => {
              e.preventDefault();
              agreeToMeteredVisit(objectType);
            }}
            className="link-arrow continue-reading"
          >
            {isResponsive
              ? `Go to ${capitalize(
                objectType.substring(0, objectType.length - 1)
              )}`
              : `Continue to ${capitalize(
                objectType.substring(0, objectType.length - 1)
              )}`}
            <DownArrow></DownArrow>
          </a> */}
        </div>
      </div>
    </>
  );
  const premiumContent = () => (
    <>
      <div className="contact-point-container">
        <h4>

          <>This is a premium {capitalize(objectType)}.</>

        </h4>
        <p>
          We are so excited you are excited about this {objectType}!
          We work hard to produce one of a kind {objectType}s and content, and in order for us to keep providing
          these wonderful {objectType}s, with no ads or gimmicks, <b><i>we have a membership with meal plans, recipes, cooking shows and more!</i></b>
        </p>
        <div className="btn-group" style={{ display: "flex" }}>
          <Button
            type="button"
            btnClass="primary"
            btnText={isResponsive ? "Signup" : "Signup"}
            onClick={() => history.push("pricing")}
          />
          <Button
            type="button"
            btnClass="grey"
            btnText={isResponsive ? "Plans & Pricing" : "See Plans & Pricing"}
            onClick={() => history.push("pricing")}
          />
        </div>
      </div>
    </>
  );
  return (
    <div className="paywall-limiter paywall-limiter-interior">
      <div id="paywall-content">{children}</div>
      {(!isAuthenticated && premium)
        ? premiumContent()
        : null}
    </div>
  );
};

PaywallLimiter.defaultProps = {
  changeFooterStatus: () => { },
};
export default withRouter(PaywallLimiter);
