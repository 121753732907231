import axios from 'axios'
import isEmpty from 'lodash/isEmpty'
import { showToastMessage } from '../actions'
import { put, takeEvery, call, select, delay } from 'redux-saga/effects'
import { patch } from '../helpers/requests';
import { user } from '../helpers/api';

import { getRegisteredUser, getToken, getUserId } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import isNil from 'lodash/isNil';


function* task(action) {
  try {

    const token = yield select(getToken);
    const userId = yield select(getUserId);

    const response = yield call(axios, patch(user.changePlan(userId), {
      user: {
        membership: {
          change_subscription_plan: true,
          new_plan_id: action.payload.planId,
          duration: action.payload.newBillingCycle,
          origin: action.payload.origin
        }
      }
    }, '', token));

    yield put(showToastMessage(`✅ Plan changed successfully`, 'success'));

    if (!isEmpty(response.data.plan)) {
      const newSubscriptionCost = action.payload.newBillingCycle === '1 month' ? parseFloat(response.data.plan.monthly_price) : parseFloat(response.data.plan.annual_price);

      yield put({
        type: actions.CHANGE_PLAN_SUCCESS,
        payload: {
          success: true,
          plan: response.data.plan,
          newSubscriptionCost,
          newBillingCycle: action.payload.newBillingCycle,
        }
      })
    } else if (!isNil(response.data.response)) {
      yield put({
        type: actions.CHANGE_PLAN_SUCCESS,
        payload: {
          success: true,
          paypalConfirmationLink: response.data.response
        }
      })
    } else {
      yield put({
        type: actions.CHANGE_PLAN_FAILURE,
        payload: {
          error: 'Response error'
        }
      })
    }

  } catch (e) {
    yield put(showToastMessage(`❗️ Error in changing plan`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.CHANGE_PLAN_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* changeSubscription() {
  yield takeEvery(actions.CHANGE_PLAN, task)
}