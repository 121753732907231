import { put, takeEvery, call, select, all, fork } from 'redux-saga/effects'

import * as actions from '../actions/actionTypes'
import commentTask from './subtask/comments'
import noteTask from './subtask/notes'
import pushToSentry from '../helpers/pushToSentry';
import retrier from './process/retrier'
const getState = state => state.toJS()

export default function* objectSpecificEssential(action) {
  try {
    const {
      payload: {
        objectType,
        objectId,
      }
    } = action;

    const [notesArray, commentsArray] = yield call(retrier, [
      call(noteTask.get, objectType, objectId),
      call(commentTask.get, objectId, objectType)
    ], 'comments and notes');


    const notesResponse = notesArray;
    const commentsResponse = commentsArray;

    if (!notesResponse || !commentsResponse) {
      const eventId = pushToSentry('Internet connection seems to be down - notes and comments')
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: 'Internet connection seems to be down',
          eventId
        }
      })
    }

    const notesData = notesResponse.data.notes;
    const commentsData = commentsResponse.data.comments;
    const commentsCount = commentsResponse.data.count;

    return {
      commentsData,
      notesData,
      commentsCount,
    }

  } catch (e) {
    const state = yield select(getState)
    const eventId = pushToSentry(e, state, "Fetch objectspecific error", JSON.stringify(state.objectSpecificEssential))

    yield put({
      type: actions.GET_OBJECT_SPECIFIC_ESSENTIAL_FAILURE,
      payload: {
        error: e.message
      }
    })

    return {
      commentsData: [],
      notesData: [],
      commentsCount: 0,
    }
  }
}