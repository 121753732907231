import React from 'react'
import AccountTab from './accountTab'

const Notifications = ({
  history
}) => {
  return (
    <div className="account-content-wrapper">
      Notifications
    </div>
  )
}

export default Notifications