import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const PlayIconRed = (props) => {
  return (
    <>
      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.9047 10.5L8.56152 7.04591V13.9541L13.9047 10.5Z" fill={themeTextRed} />
        <circle cx="10.5" cy="10.5" r="10" stroke={themeTextRed} />
      </svg>
    </>
  );
};
export default PlayIconRed;
