import React, { useState, useEffect } from 'react'
import axios from 'axios';
import {
    useInfiniteQuery,
} from 'react-query';
import InfiniteScroll from 'react-infinite-scroller';
import PersonalizedVideoCard from './cards/PersonalizedVideoCard';
import queryString from 'query-string';
import Loader from "../images/loader";
import UpComingSessions from './UpcomingSessions';
import { personalizedVideos } from '../../helpers/api';
import "../styles/common.scss";
import { meetings } from '../../helpers/api';
import HorizontalListWithAction from '../common/horizontalActionableHeader';



const KatiesCornerCookingList = (props) => {
    const location = props.history.location.pathname;
    const navigateTo = (link) => {
        props.history.push(link)
    }

    const [existingMeetings, setExistingMeetings] = useState([]);
    const [isFetching, setIsFetching] = useState(false);


    const changeRouteForCategory = (category) => {
        if (sort) {
            navigateTo(`/nourish/live-cooking?categories=${category}&sort=${sort}`);
        } else {
            navigateTo(`/nourish/live-cooking?categories=${category}`);
        }

    }

    const changeRouteForSort = (sort) => {
        if (category) {
            navigateTo(`/nourish/live-cooking?categories=${category}&sort=${sort}`);
        } else {
            navigateTo(`/nourish/live-cooking?sort=${sort}`);
        }

    }



    useEffect(() => {
        setIsFetching(true);
        axios.get(meetings.get())
            .then((response) => {
                setIsFetching(false);
                setExistingMeetings(response.data.upcoming_meetings);
            })
    }, [])

    let personalizedVideos = [];

    if (isFetching) {
        <div className='container' style={{ minHeight: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <p>Please wait while we load the meetings...</p>
        </div>
    }
    return (
        <>
            <div className='container'>
                {
                    existingMeetings.length > 0 ?
                        <UpComingSessions existingMeetings={existingMeetings} meetingType="Session" title="All Meetings" /> :
                        null
                }
            </div>

            <div className='container' style={{ minHeight: '600px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                    existingMeetings.length === 0 ?

                        <p>No scheduled meetings are available. Please wait till the next meeting is scheduled.</p> : null
                }
            </div>
        </>
    )
}

export default KatiesCornerCookingList