import React from "react";
import '../styles/common.scss';


const DietaryFiltersIcon = (props) => {
  return (
    < >
      <svg width="29" height="26" viewBox="0 0 29 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.81114 17.4222V25M3.81114 1V11.2111M14.3333 10.5221V24.9999M14.3333 1V4.42222M24.8555 20.8444V25M24.8555 1V14.6889M6.62221 14.2778C6.62221 15.8303 5.36364 17.0889 3.81111 17.0889C2.25858 17.0889 1 15.8303 1 14.2778C1 12.7253 2.25858 11.4667 3.81111 11.4667C5.36364 11.4667 6.62221 12.7253 6.62221 14.2778ZM17.2555 7.49993C17.2555 9.05246 15.997 10.311 14.4444 10.311C12.8919 10.311 11.6333 9.05246 11.6333 7.49993C11.6333 5.9474 12.8919 4.68882 14.4444 4.68882C15.997 4.68882 17.2555 5.9474 17.2555 7.49993ZM27.6666 17.7333C27.6666 19.2859 26.4081 20.5445 24.8555 20.5445C23.303 20.5445 22.0444 19.2859 22.0444 17.7333C22.0444 16.1808 23.303 14.9222 24.8555 14.9222C26.4081 14.9222 27.6666 16.1808 27.6666 17.7333Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default DietaryFiltersIcon;
