import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from '../../helpers/toJS';

import {
  appEssentialFetch,
  createFavourite,
  deleteFavourite,
  createBoard,
  fetchRecipes,
  setObjectBoards,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  setFiltersAndFetch,
  fetchVideos,
  search,
  trendingTypeChange,
  addSentryError,
  changeFooterStatus
} from "../../actions";

import {
  getUnFilteredList,
  getVideoCategories,
  getVideoCollections,
  getVideoFilterName,
  getVideos,
  getBoards,
  getMoreVideoAvailable,
  getVideosSearchList,
  getVideosSearchCount,
  getFetchStatus,
  getVideosSearchText,
  getAppliedFilters,
  getTrendingVideos
} from "../../selectors/videos";

import List from "./components";

const mapStateToProps = state => ({
  categories: getVideoCategories(state),
  collections: getVideoCollections(state),
  videoFilterName: getVideoFilterName(state),
  videos: getVideos(state),
  boards: getBoards(state),
  fetchStatus: getFetchStatus(state),
  moreVideoAvailable: getMoreVideoAvailable(state),
  fetchStatus: getFetchStatus(state),
  searchList: getVideosSearchList(state),
  searchCount: getVideosSearchCount(state),
  searchBy: 'title',
  searchTerm: getVideosSearchText(state),
  appliedFilters: getAppliedFilters(state),
  trendingVideos: getTrendingVideos(state),
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  createBoard: bindActionCreators(createBoard, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  setFiltersAndFetch: bindActionCreators(setFiltersAndFetch, dispatch),
  fetchVideos: bindActionCreators(fetchVideos, dispatch),
  searchVideos: bindActionCreators(search, dispatch),
  trendingTypeChange: bindActionCreators(trendingTypeChange, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
  changeFooterStatus: bindActionCreators(changeFooterStatus, dispatch)
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(List));




