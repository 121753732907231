import axios from 'axios'
import { put, takeEvery, call, select } from 'redux-saga/effects'

import mealPlanTask from './subtask/mealplan'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';
import { showToastMessage } from '../actions';

function* task(action) {
  try {
    let response = yield call(mealPlanTask.deleteMeal, action.payload.meal_id);

    yield put({
      type: actions.DELETE_MEAL_FROM_MEALPLAN_SUCCESS,
      payload: {
        mealData: response
      }
    })

    yield put(showToastMessage(`✅ Item removed from meal plan.`, 'success', 'addMealPlan'));

  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.DELETE_MEAL_FROM_MEALPLAN_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* deleteMealFromMealPlan() {
  yield takeEvery(actions.DELETE_MEAL_FROM_MEALPLAN, task)
}