import { createSelector } from "reselect";
import { List, Map, Iterable, update, fromJS, toJS } from "immutable";
import { filter, join, isNull, trim, toLower, includes } from 'lodash';

export const getAuthStatus = (state) => {
  const isLoggingIn = state.getIn(['loggedInUser', 'isLogging']);
  const isLoggedIn = state.getIn(['loggedInUser', 'isLoggedIn']);
  const isLoginFailed = state.getIn(['loggedInUser', 'isLoginFailed']);
  const errorMessage = state.getIn(['loggedInUser', 'errorMessage'])
  return Map({
    isLoggingIn,
    isLoggedIn,
    isLoginFailed,
    errorMessage
  })
}

export const getUserEssentialFetchStatus = state => state.getIn(["fetchStatus","userEssentials"]);
