import axios from 'axios';
import { useEffect, useReducer } from 'react';
import { baseUrl } from './api';
import isNil from 'lodash/isNil';
import concat from 'lodash/concat';
import find from "lodash/find";
import filter from "lodash/filter";

const dailyDishReducer = (state, action) => {
  switch (action.type) {
    case 'REQUEST_NOTIFICATIONS':
      return {
        ...state,
        isFetching: true,
        shouldFetch: true
      }
    case 'REQUEST_NOTIFICATIONS_SUCCESS':
      return {
        ...state,
        isFetching: false,
        unread_notifications: action.payload.unread_notifications,
        read_notifications: action.payload.read_notifications,
        shouldFetch: false
      }
    case 'MARK_AS_READ':
      let notification = find(state.unread_notifications, { id: action.payload.id });
      if (isNil(notification)) {
        return state
      } else {
        return {
          ...state,
          read_notifications: concat([notification], state.read_notifications),
          unread_notifications: filter(state.unread_notifications, (notification) => { return notification.id !== action.payload.id }),
        }
      }
    case 'MARK_ALL_AS_READ':
      return {
        ...state,
        read_notifications: [...state.read_notifications, ...state.unread_notifications],
        unread_notifications: []
      } 

    default: return state;
  }
}

export const useDailyDishHook = ({ token }) => {
  const [state, dispatchDishListAction] = useReducer(
    dailyDishReducer,
    {
      isFetching: false,
      shouldFetch: true,
      unread_notifications: [],
      read_notifications: [],
      token: null
    }
  );
  const fetchDailyDish = () => {
    dispatchDishListAction({
      type: 'REQUEST_NOTIFICATIONS'
    })
    axios({
      method: 'GET',
      url: `${baseUrl}/api/account/notifications/`,
      headers: {
        Authorization: `Token token="${token || state.token}"`
      },
    })
      .then((response) => {
        if (response.status === 200) {
          dispatchDishListAction({
            type: 'REQUEST_NOTIFICATIONS_SUCCESS', payload: {
              read_notifications: response.data.read_notifications,
              unread_notifications: response.data.unread_notifications
            }
          })
        }
      })
      .catch(e => {
      })
  }
  useEffect(() => {
    if (
      state.shouldFetch &&
      !state.isFetching &&
      (!isNil(token) || !isNil(state.token))
    ) {
      fetchDailyDish();
    }
  }, [
    state.shouldFetch,
    token,
    state.token,
    state.isFetching
  ]);


  return [state, dispatchDishListAction]
}
