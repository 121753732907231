import React from "react";
import '../styles/common.scss';

const Favorite = (props) => {
  if (props.favorite)
    return (
      <svg 
        className="favorite favorited"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();

          // This will be always props.item.boards and not favorite_boards
          // Because boards need to be sent only when we are in favorites page
          
          // On click to remove favorite
          props.onClick(true)
        }}
        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" 
        xmlns xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 24 24" xml space="preserve"
      >
      <g>
        <g id="Layer_1-2">
          <rect className="FavoriteFillWhite-0" width="24" height="24"/>
          <path className="FavoriteFillWhite-1" d="M12,22C10.4,22,1,15.7,1,8.9C1,5.2,3.9,2,7.2,2c1.9,0,3.7,0.8,4.8,2.3C13.2,2.8,14.9,2,16.8,2
            C20.1,2,23,5.2,23,8.9C23,15.9,13.6,22,12,22z"/>
        </g>
      </g>
    </svg>
  )
  else
    return (
      <svg 
        className="favorite unfavorited"
        onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            // On click add favorite
            props.onClick(false)
          }
        }
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect className="FavoriteWhite-1" width="24" height="24" />
          <path className="FavoriteWhite-2" d="M12,22C10.44,22,1,15.7,1,8.87,1,5.22,3.89,2,7.19,2A6,6,0,0,1,12,4.3,6.14,6.14,0,0,1,16.8,2C20.1,2,23,5.22,23,8.87,23,15.88,13.6,22,12,22Zm.13-2Zm-.25,0ZM7.19,4.05C4.79,4.05,3,6.6,3,8.87c0,5.46,7.62,10.48,9,11,1.56-.58,9-5.74,9-11,0-2.27-1.8-4.82-4.2-4.82A4.19,4.19,0,0,0,12.92,6.7a1,1,0,0,1-1,.63A1,1,0,0,1,11,6.61,3.92,3.92,0,0,0,7.19,4.05Z" />
        </g>
      </g>
    </svg>
  );
};

export default Favorite;