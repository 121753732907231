import React, { useState, useEffect } from 'react'

import cx from 'classnames'

import map from 'lodash/map'

import Selector from '../../../common/selector'
import Placeholder from '../../../common/placeholder';

let listener;

const TabHeader = ({
  faqs,
  setActiveCategorySlug,
  activeCategorySlug,
  setShowAskQuestion,
  fetchStatus
}) => {
  const [responsive, setResponsive] = useState(false);
  const isLoading = fetchStatus.faqList.isFetching;
  const isLoaded = fetchStatus.faqList.isFetched;

  // sets the showNavList
  useEffect(() => {
    if (window.innerWidth <= 991) {
      setResponsive(true)
    } else if (window.innerWidth > 991) {
      setResponsive(false)
    }
    if (listener) {
      window.removeEventListener("resize")
    }

    listener = window.addEventListener("resize", () => {
      if (window.innerWidth <= 991) {
        setResponsive(true)
      } else if (window.innerWidth > 991) {
        setResponsive(false);
      }
    });
  }, [])

  const renderDesktop = () => {

    return (
      <>
        <div className="tab-items">
          <div
            className={cx("each-tab-header", { active: true })}
          >
            Nourish
          </div>
        </div>
        <div
          className="tab-action"
          onClick={() => setShowAskQuestion(true)}
        >
          <span>
            Ask question
          </span>
        </div>
      </>
    )
  }

  const renderResponsive = () => {
    const responsiveSelectorList = [{
      title: 'Nourish',
      value: 'katies-corner'
    }]

    return (
      <>
        <div className="tab-responsive-items">
          <div className="topic">
            Topic
          </div>
          <div className="tab-responsive-selector">
            <Selector
              selectorList={responsiveSelectorList}
              onSelectValue={value => {
                setActiveCategorySlug(value)
              }}
            />
          </div>
        </div>
        <div className="tab-responsive-action">
          Ask Question
        </div>
      </>
    )
  }

  return (
    <div className="tab-header">
      {
        responsive ? renderResponsive() : renderDesktop()
      }
    </div>
  )
}


export default TabHeader