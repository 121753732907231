import { ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE } from "./actionTypes";

export default (ingredientName: String, categoryId: Number, options) => ({
  type: ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE,
  payload: {
    ingredientName,
    categoryId,
    ...options
  },
});
