import isEmpty from "lodash/isEmpty";
import axios from "axios";
import filter from "lodash/filter";
import {
  getToken,
  getRecipeFoodTypes,
  getRecipeDietaries,
  getRecipeSpecialities,
  getWorkoutsWorkoutTypes,
  getWorkoutsBodyParts,
  getWorkoutsEquipments,
  getExistingBlogPostCategories,
  getExistingVideoCategories,
  getExistingDishlistCategories,
  isTaxonomiesFetchedAlready
} from "../selectors";
import { call, select, all, put } from "redux-saga/effects";
import { recipes, workouts, blogs, videos, mealplan } from "../../helpers/api";
import { get } from "../../helpers/requests";

import { taxonomies } from "../../helpers/api";
import boardsFetch from "../subtask/boards";
import user from "../subtask/users";

import * as actions from "../../actions/actionTypes";
import pushToSentry from "../../helpers/pushToSentry";
import retrier from "./retrier";

function* getRecipePrefetch(taxonomies) {
  const token = yield select(getToken);
  const existingRecipeFoodTypes = yield select(getRecipeFoodTypes);
  const existingRecipeDietaries = yield select(getRecipeDietaries);
  const existingRecipeSpecialities = yield select(getRecipeSpecialities);

  if (
    (existingRecipeFoodTypes && existingRecipeFoodTypes.isEmpty()) ||
    (existingRecipeDietaries && existingRecipeDietaries.isEmpty()) ||
    (existingRecipeSpecialities && existingRecipeSpecialities.isEmpty())
  ) {
    // NEW BOUNDARY : RECIPE - Foodtype, dietaries and Boards
    yield put({
      type: actions.RECIPE_PRE_FETCH_ESSENTIALS_REQUEST
    });

    console.log("token is present ~~~~", token);
    if (token) {
      console.log("fetching with token ~~~~", token);
      const recipeBoardArray = yield call(
        retrier,
        [call(boardsFetch.get, "recipe", token)],
        "prefetch"
      );

      recipeBoardsResponse = recipeBoardArray[0];

      if (!recipeBoardsResponse) {
        const eventId = pushToSentry(
          "Internet connection seems to be down - recipe boards"
        );
        yield put({
          type: actions.ADD_SENTRY_ERROR,
          payload: {
            error: "Internet connection seems to be down",
            eventId
          }
        });
      }
    } else {
      recipeBoardsResponse = {
        data: {
          boards: []
        }
      };
    }

    const recipePrefetchEssentials = {
      foodTypes: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "foodtype"
      ),
      dietaries: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "dietary"
      ),
      specialities: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "speciality"
      ),
      collections: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "collection"
      ),
      boardsData: { boards: recipeBoardsResponse.data.boards }
    };
    yield put({
      type: actions.RECIPE_PRE_FETCH_ESSENTIALS_SUCCESS,
      payload: {
        recipePrefetchEssentials
      }
    });
  }
}

function* getWorkoutPrefetch(taxonomies) {
  const existingWorkoutsWorkoutTypes = yield select(getWorkoutsWorkoutTypes);
  const existingWorkoutBodyParts = yield select(getWorkoutsBodyParts);
  const existingWorkoutsEquipments = yield select(getWorkoutsEquipments);
  const token = yield select(getToken);
  if (
    (existingWorkoutsWorkoutTypes && existingWorkoutsWorkoutTypes.isEmpty()) ||
    (existingWorkoutBodyParts && existingWorkoutBodyParts.isEmpty()) ||
    (existingWorkoutsEquipments && existingWorkoutsEquipments.isEmpty())
  ) {
    if (token) {
      yield put({
        type: actions.WORKOUT_PRE_FETCH_ESSENTIALS_REQUEST
      });
      const workoutBoardArray = yield call(
        retrier,
        [call(boardsFetch.get, "workout", token)],
        "prefetch"
      );

      workoutBoardsResponse = workoutBoardArray[0];

      if (!workoutBoardsResponse) {
        const eventId = pushToSentry(
          "Internet connection seems to be down - workout boards"
        );
        yield put({
          type: actions.ADD_SENTRY_ERROR,
          payload: {
            error: "Internet connection seems to be down",
            eventId
          }
        });
      }
    } else {
      workoutBoardsResponse = {
        data: {
          boards: []
        }
      };
    }

    const workoutPrefetchEssentials = {
      workoutTypes: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "workout_type"
      ),
      equipments: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "equipment"
      ),
      bodyParts: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "body_part"
      ),
      collections: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "collection"
      ),
      boardsData: { boards: workoutBoardsResponse.data.boards }
    };

    yield put({
      type: actions.WORKOUT_PRE_FETCH_ESSENTIALS_SUCCESS,
      payload: {
        workoutPrefetchEssentials
      }
    });
  }
}

function* getBlogpostPrefetch(taxonomies) {
  const existingCategories = yield select(getExistingBlogPostCategories);

  const token = yield select(getToken);

  if (existingCategories && existingCategories.isEmpty()) {
    if (token) {
      const blogBoardArray = yield call(
        retrier,
        [call(boardsFetch.get, "blog", token)],
        "prefetch"
      );

      blogBoardsResponse = blogBoardArray[0];

      if (!blogBoardsResponse) {
        const eventId = pushToSentry(
          "Internet connection seems to be down - blogboards"
        );
        yield put({
          type: actions.ADD_SENTRY_ERROR,
          payload: {
            error: "Internet connection seems to be down",
            eventId
          }
        });
      }
    } else {
      blogBoardsResponse = {
        data: {
          boards: []
        }
      };
    }

    const blogPrefetchEssentials = {
      categories: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "category" && taxonomy.name
      ),
      collections: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "collection"
      ),
      boardsData: { boards: blogBoardsResponse.data.boards }
    };

    yield put({
      type: actions.BLOG_PRE_FETCH_ESSENTIALS_SUCCESS,
      payload: {
        blogPrefetchEssentials
      }
    });
  }
}

function* getVideoPrefetch(taxonomies) {
  const existingCategories = yield select(getExistingVideoCategories);

  const token = yield select(getToken);

  if (existingCategories && existingCategories.isEmpty()) {
    const videoPrefetchEssentials = {
      categories: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "category"
      ),
      collections: filter(
        taxonomies,
        taxonomy => taxonomy.taxonomy_type === "collection"
      ),
      boardsData: { boards: [] }
    };

    yield put({
      type: actions.VIDEO_PRE_FETCH_ESSENTIALS_SUCCESS,
      payload: {
        videoPrefetchEssentials
      }
    });
  }
}

function* getDishListPrefetch(taxonomies) {
  yield put({
    type: actions.DISHLIST_PRE_FETCH_ESSENTIALS_REQUEST
  });
  const existingCategories = yield select(getExistingDishlistCategories);

  if (existingCategories && existingCategories.isEmpty()) {
    const dishlistCategory = filter(
      taxonomies,
      taxonomy => taxonomy.taxonomy_type === "category"
    );

    yield put({
      type: actions.DISHLIST_PRE_FETCH_ESSENTIALS_SUCCESS,
      payload: {
        categories: dishlistCategory
      }
    });
  }
}

export default function* objectPreFetchEssential(payload) {
  const token = yield select(getToken);

  // Fetch Categories, collections and boards at first
  // These data are required to convert filter slug in appEssentialFetch
  // Recipe Pre Fetch Essentials
  const isTaxonomiesFetched = yield select(isTaxonomiesFetchedAlready);

  try {
    if (!isTaxonomiesFetched) {
      yield put({
        type: actions.OBJECT_PRE_FETCH_ESSENTIALS_REQUEST
      });

      const token = yield select(getToken);

      const taxonomyDataArray = yield call(
        retrier,
        [call(axios, get(taxonomies.get(), "", token))],
        "prefetch"
      );

      const mealPlanCategories = yield call(
        retrier,
        [call(axios, get(mealplan.getCategories(), "", ""))],
        "meal plan categories!!!!!"
      );

      console.log("meal plan categories ", mealPlanCategories);
      yield put({
        type: "MEAL_PLAN_CATEGORIES_FETCH_SUCCESS",
        payload: {
          categories: mealPlanCategories[0]?.data.data
        }
      });

      const taxonomiesResponse = taxonomyDataArray[0];

      if (taxonomiesResponse) {
        yield all([
          call(
            getRecipePrefetch,
            filter(
              taxonomiesResponse.data.taxonomies,
              taxonomy => taxonomy.object_type === "recipe"
            )
          ),
          call(
            getWorkoutPrefetch,
            filter(
              taxonomiesResponse.data.taxonomies,
              taxonomy => taxonomy.object_type === "workout"
            )
          ),
          call(
            getBlogpostPrefetch,
            filter(
              taxonomiesResponse.data.taxonomies,
              taxonomy => taxonomy.object_type === "post"
            )
          ),
          call(
            getVideoPrefetch,
            filter(
              taxonomiesResponse.data.taxonomies,
              taxonomy => taxonomy.object_type === "video"
            )
          ),
          call(
            getDishListPrefetch,
            filter(
              taxonomiesResponse.data.taxonomies,
              taxonomy => taxonomy.object_type === "dishlist"
            )
          )
        ]);
      } else {
        const eventId = pushToSentry(
          "Internet connection seems to be down - taxonomies"
        );
        yield put({
          type: actions.ADD_SENTRY_ERROR,
          payload: {
            error: "Internet connection seems to be down",
            eventId
          }
        });
        yield put({
          type: actions.OBJECT_PRE_FETCH_ESSENTIALS_FAILURE,
          payload: {
            error: "internet connection issue"
          }
        });
      }

      yield put({
        type: actions.OBJECT_PRE_FETCH_ESSENTIALS_SUCCESS
      });
    }
  } catch (e) {
    console.log(e);
    const eventId = pushToSentry(e);
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    });
    yield put({
      type: actions.OBJECT_PRE_FETCH_ESSENTIALS_FAILURE,
      payload: {
        error: e.message
      }
    });
  }
}
