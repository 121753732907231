import React from "react";
import { themeTextRed } from "../../helpers/theme";
import '../styles/common.scss';

const ListTick = (props) => {
  return (
    <>
      <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 6.48193L4.53553 10.0175L11.0173 3.53565" stroke={themeTextRed} />
      </svg>
    </>
  );
};
export default ListTick;
