
import { put, takeEvery, call, select } from 'redux-saga/effects';
import axios from 'axios'
import { get } from '../helpers/requests'
import pushToSentry from '../helpers/pushToSentry';
import * as actions from '../actions/actionTypes';
import {
  getToken,
} from './selectors'

import { trending } from '../helpers/api'

//if filter data is store is empty do the normal fetch or just yield call(filterTask.get) with GET_ALL_WORKOUTS_SUCCESS
function* task(action) {
  try {
    const {
      payload: {
        objectType,
        trendingType,
      }
    } = action

    const token = yield select(getToken);

    const response = yield call(axios, get(trending[`${objectType}s`](trendingType), '', token))

    yield put({
      type: actions.TRENDING_TYPE_CHANGE_SUCCESS,
      payload: {
        objectType,
        trendingType,
        data: response.data.data,
      }
    });
  } catch (e) {
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.TRENDING_TYPE_CHANGE_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* search() {
  yield takeEvery(actions.TRENDING_TYPE_CHANGE, task)
}