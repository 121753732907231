import React from 'react';
import ReactPlayer from "react-player";

import {
    useQuery,
} from 'react-query';
import moment from 'moment';
import axios from 'axios';
import { meetings } from '../../helpers/api';
import Loader from "../images/loader";

const getPastMeeting = async (params) => {
    const response = await axios.get(meetings.show(params.queryKey[1]));

    return {
        data: response.data.personalized_video
    }
}

const KatiesCornerPastMeeting = (props) => {
    const slug = props.history.location.pathname.replace('/nourish/past-meeting/', '');
    const {
        data,
        error,
        isFetching
    } = useQuery(['pastMeetings', slug], getPastMeeting, {
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });

    const videoUrl = id => `https://www.youtube.com/watch?v=${id}`;
    return !isFetching ? (
        <section className="video-index">
            <div className="container">
                <div className="video-page-wrapper">
                    <div className="video-page-body">
                        <div className="video-page-title">
                            <h3>{data.data.title}</h3>
                            <span>
                                Published at {moment(data?.data?.updated_at).format("MMMM DD")}
                            </span>

                        </div>
                        {!data.data.id ? (
                            <div className="container">
                                <div className="preLoader">
                                    <Loader></Loader>
                                </div>
                            </div>
                        ) : (
                            <div className="video-page-video-container">
                                <ReactPlayer
                                    ref={this.ref}
                                    className="react-player"
                                    // width='600px'
                                    url={videoUrl(data.data.video_url)}
                                    controls={true}
                                    playing={false}
                                    loop={true}
                                    wrapper="div"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    ) : null
}


export default KatiesCornerPastMeeting