import isEmpty from "lodash/isEmpty";

export default (userDetails) => {
  const {
    details: {
      plan
    }
  } = userDetails;
  const {
    details
  } = userDetails;
  let planOrder;
  if (!isEmpty(details.plan)) {
    planOrder = plan.plan_order;
  } else {
    planOrder = 'Grandfather';
  }
  if (planOrder === 1) return 'premiumPlan';
  if (planOrder === 2) return 'basePlan';
  return 'grandfather';
}