import React from 'react'
import Faq from './faqs'

const KatiesCornerFaq = ({
    userPlanOrder,
    isLoginProgress,
    isLoginCompleted,
    ...props
}) => (
    <Faq
        userPlanOrder={userPlanOrder}
        isLoginProgress={isLoginProgress}
        isLoginCompleted={isLoginCompleted}
        {...props}
    />
)

export default KatiesCornerFaq