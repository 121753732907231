import moment from 'moment';
import createReducer from "../helpers/createReducers";
import { find, reduce, filter } from 'lodash';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';


import { fromJS, List, update, toJS, Map } from "immutable";
import { UNSELECT_INGREDIENT_IN_GROCERY_LIST, ADD_INGREDIENTS_IN_GROCERY_LIST } from "../actions/actionTypes";
import restructureMealPlanData from '../helpers/restructureMealPlanData';
import { returnDefaultCalendarView } from './calendarSettings';

const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};


const initialState = fromJS({
  boards: {
    recipeBoards: {
      boards: []
    },
    workoutBoards: {
      boards: []
    },
    blogBoards: {
      boards: []
    },
    videoBoards: {
      boards: []
    }
  },
  //Recipe favorits boards
  recipeFavoriteBoards: [],
  // Grocery list related keys
  waitList: [], // Keeps in the waitlist for active recipe
  groceryList: {
    categories: [],
  },
  shouldGroceryListRefetch: false,
  selectedIds: [],
  groceryListViewType: 'aisle',
  groceryListThresholdReached: false,
  temporaryGroceryList: [],
  // calendar related keys
  calendarSettings: {
    calendarView: returnDefaultCalendarView(),
    showPreMadeMealPlans: true,
    showViewableMacros: true,
    automateServingSize: false,
    dataForDays: 28,
    anchorDate: moment().format('YYYY-MM-DD'), // default for current date
    activeDate: moment().format('YYYY-MM-DD'), // default for active date
    monthType: 'current',
    macros: ['calories', 'smartPoints', 'protein']
  },
  calendarListData: [],
  cached: false,
});

export default createReducer(initialState, {
  APP_ESSENTIAL_FETCH: (state, action) => {
    return state.setIn(['waitList'], List());
  },

  // for add selected ingredient to grocery-list reactvity
  ADD_WAITLIST_INGREDIENTS_TO_GROCERYLIST_SUCCESS: (state, action) => {
    let newIngredients = fromJS(action.payload.addedIngredients)
    let groceryListCategories = state
      .getIn(['groceryList', 'categories'])
      .toSeq()
      .map(category => {
        let updatedNewIngredientSet = newIngredients.filter(ingredient => ingredient.getIn(['category_id']) === category.getIn(['id']))

        // Convert everything to item, API gives in .item
        updatedNewIngredientSet = updatedNewIngredientSet
          .toSeq()
          .map(ingredient => ingredient.setIn(['item'], ingredient.getIn(['value'])));

        let updatedItems = category.getIn(['items']).concat(updatedNewIngredientSet);
        category = category.setIn(['items'], updatedItems)
        return category
      })
    state = state.setIn(['groceryList', 'categories'], groceryListCategories);
    state = state.setIn(['shouldGroceryListRefetch'], true);
    state = state.setIn(['waitList'], List());
    return state
  },
  ADD_ALL_TO_GROCERYLIST: (state, action) => {
    state = state.setIn(['shouldGroceryListRefetch'], true);
    return state;
  },
  // for add add ingredients to grocery-list reactvity
  ADD_ALL_TO_GROCERYLIST_SUCCESS: (state, action) => {
    let newIngredients = fromJS(action.payload.addedIngredients)
    let groceryListCategories = state
      .getIn(['groceryList', 'categories'])
      .toSeq()
      .map(category => {
        let updatedNewIngredientSet = newIngredients.filter(ingredient => parseInt(ingredient.getIn(['category_id'])) === category.getIn(['id']))

        // Convert everything to item, API gives in .item
        updatedNewIngredientSet = updatedNewIngredientSet
          .toSeq()
          .map(ingredient => ingredient.setIn(['item'], ingredient.getIn(['value'])));

        let updatedItems = category.getIn(['items']).concat(updatedNewIngredientSet);
        category = category.setIn(['items'], updatedItems)
        return category
      })

    state = state.setIn(['groceryList', 'categories'], groceryListCategories.toList())
    state = state.setIn(['shouldGroceryListRefetch'], true);
    return state
  },
  ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_SUCCESS: (state, action) => {
    let newIngredients = fromJS(action.payload.addedIngredients)
    let groceryListCategories = state
      .getIn(['groceryList', 'categories'])
      .toSeq()
      .map(category => {
        let updatedNewIngredientSet = newIngredients.filter(ingredient => ingredient.getIn(['category_id']) === category.getIn(['id']))

        // Convert everything to item, API gives in .item
        updatedNewIngredientSet = updatedNewIngredientSet
          .toSeq()
          .map(ingredient => ingredient.setIn(['item'], ingredient.getIn(['value'])));

        let updatedItems = category.getIn(['items']).concat(updatedNewIngredientSet);
        category = category.setIn(['items'], updatedItems)
        return category
      })
    state = state.setIn(['groceryList', 'categories'], groceryListCategories)
    state = state.setIn(['shouldGroceryListRefetch'], true);
    return state
  },
  ADD_INGREDIENTS_OF_RANGE_TO_GROCERY_LIST_SUCCESS: (state, action) => {
    let newIngredients = fromJS(action.payload.addedIngredients)
    let groceryListCategories = state
      .getIn(['groceryList', 'categories'])
      .toSeq()
      .map(category => {
        let updatedNewIngredientSet = newIngredients.filter(ingredient => ingredient.getIn(['category_id']) === category.getIn(['id']))

        // Convert everything to item, API gives in .item
        updatedNewIngredientSet = updatedNewIngredientSet
          .toSeq()
          .map(ingredient => ingredient.setIn(['item'], ingredient.getIn(['value'])));

        let updatedItems = category.getIn(['items']).concat(updatedNewIngredientSet);
        category = category.setIn(['items'], updatedItems)
        return category
      })
    state = state.setIn(['groceryList', 'categories'], groceryListCategories)
    state = state.setIn(['shouldGroceryListRefetch'], true);
    return state
  },
  // boards for recipes
  RECIPE_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const {
      boardsData
    } = action.payload.recipePrefetchEssentials
    //adding isSelected field in boards
    let recipeBoards = fromJS(boardsData.boards)
      .toSeq()
      .reduce((accumulater, board) => {
        let newBoard = Map({ isSelected: false });
        newBoard = newBoard.merge(board);
        return accumulater.push(newBoard);
      }, List([]));

    let recipeBoardsObject = fromJS({
      boards: recipeBoards,
      total_count: fromJS(boardsData.total_count),
      object_type: fromJS(boardsData.object_type)
    });
    state = state.setIn(['boards', 'recipeBoards'], recipeBoardsObject);
    return state
  },
  // boards for workouts
  WORKOUT_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const {
      boardsData
    } = action.payload.workoutPrefetchEssentials;
    let workoutBoards = fromJS(boardsData.boards)
      .toSeq()
      .reduce((accumulater, board) => {
        let newBoard = fromJS({ isSelected: false });
        newBoard = newBoard.merge(board);
        return accumulater.push(newBoard);
      }, List([]));

    let workoutBoardsObject = Map({
      boards: workoutBoards,
      total_count: fromJS(boardsData.total_count),
      object_type: fromJS(boardsData.object_type)
    });
    state = state.setIn(['boards', 'workoutBoards'], workoutBoardsObject);
    return state
  },
  BLOG_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const {
      boardsData
    } = action.payload.blogPrefetchEssentials;
    let blogBoards = fromJS(boardsData.boards)
      .toSeq()
      .reduce((accumulater, board) => {
        let newBoard = fromJS({ isSelected: false });
        newBoard = newBoard.merge(board);
        return accumulater.push(newBoard);
      }, List([]));

    let blogBoardsObject = Map({
      boards: blogBoards,
      total_count: fromJS(boardsData.total_count),
      object_type: fromJS(boardsData.object_type)
    });
    state = state.setIn(['boards', 'blogBoards'], blogBoardsObject);
    return state
  },
  VIDEO_PRE_FETCH_ESSENTIALS_SUCCESS: (state, action) => {
    const {
      boardsData
    } = action.payload.videoPrefetchEssentials;
    let videoBoards = fromJS(boardsData.boards)
      .toSeq()
      .reduce((accumulater, board) => {
        let newBoard = fromJS({ isSelected: false });
        newBoard = newBoard.merge(board);
        return accumulater.push(newBoard);
      }, List([]));

    let videoBoardsObject = Map({
      boards: videoBoards,
      total_count: fromJS(boardsData.total_count),
      object_type: fromJS(boardsData.object_type)
    });
    state = state.setIn(['boards', 'videoBoards'], videoBoardsObject);
    return state
  },

  // calendar and grocery data
  USER_ESSENTIALS_FETCH_SUCCESS: (state, action) => {

    state = state.setIn(['shouldGroceryListRefetch'], false);
    state = state.setIn(['groceryList'], fromJS(action.payload.groceryListData));
    state = state.setIn(['forUser'], fromJS(action.payload.forUser));
    //calling the helper function to re-structure the mealPlan data
    state = state.setIn(['calendarListData'], fromJS(
      restructureMealPlanData(
        action.payload.mealPlanData,
        state.getIn(['calendarSettings', 'anchorDate']),
        state.getIn(['calendarSettings', 'dataForDays'])
      )
    ))
    return state
  },

  USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH: (state, action) => {

    state = state.setIn(['shouldGroceryListRefetch'], false);

    return state
  },

  USER_ESSENTIALS_FETCH_GROCERYLIST_REFETCH_SUCCESS: (state, action) => {

    state = state.setIn(['groceryList'], fromJS(action.payload.groceryListData));
    state = state.setIn(['forUser'], fromJS(action.payload.forUser));

    return state
  },

  // For favourite boards reactivity
  CREATE_FAVORITE_BOARD_SUCCESS: (state, action) => {
    if (action.payload.objectType == 'recipe') {
      let currentBoards = state.getIn(['boards', 'recipeBoards', 'boards'])
      // to add new board in start of array
      currentBoards = currentBoards.splice(1, 0, fromJS(action.payload.boardObject.data))
      state = state.setIn(['boards', 'recipeBoards', 'boards'], currentBoards)
      let total_count = state.getIn(['boards', 'recipeBoards', 'total_count']) + 1;
      state = state.setIn(['boards', 'recipeBoards', 'total_count'], total_count);
    } else if (action.payload.objectType == 'workout') {
      let currentBoards = state.getIn(['boards', 'workoutBoards', 'boards']);

      currentBoards = currentBoards.splice(1, 0, fromJS(action.payload.boardObject.data));
      state = state.setIn(['boards', 'workoutBoards', 'boards'], currentBoards)
      let total_count = state.getIn(['boards', 'workoutBoards', 'total_count']) + 1;
      state = state.setIn(['boards', 'workoutBoards', 'total_count'], total_count);
    } else if (action.payload.objectType == 'blog') {
      let currentBoards = state.getIn(['boards', 'blogBoards', 'boards']);

      currentBoards = currentBoards.splice(1, 0, fromJS(action.payload.boardObject.data));
      state = state.setIn(['boards', 'blogBoards', 'boards'], currentBoards)
      let total_count = state.getIn(['boards', 'blogBoards', 'total_count']) + 1;
      state = state.setIn(['boards', 'blogBoards', 'total_count'], total_count);
    } else if (action.payload.objectType == 'video') {
      let currentBoards = state.getIn(['boards', 'videoBoards', 'boards']);

      currentBoards = currentBoards.splice(1, 0, fromJS(action.payload.boardObject.data));
      state = state.setIn(['boards', 'videoBoards', 'boards'], currentBoards)
      let total_count = state.getIn(['boards', 'videoBoards', 'total_count']) + 1;
      state = state.setIn(['boards', 'videoBoards', 'total_count'], total_count);
    }
    return state
  },
  // set object boards
  SET_OBJECT_BOARDS: (state, action) => {
    const {
      payload: {
        objectType
      } = {}
    } = action;

    let allBoards = state.getIn(['boards', `${objectType}Boards`, 'boards'])
      .toSeq()
      .reduce((accumulater, board) => {
        if (action.payload.board.id === board.getIn(['id'])) {
          board = board.setIn(['isSelected'], !action.payload.board.isSelected)
        }
        return accumulater.push(board)
      }, List([]))
    state = state.setIn(['boards', `${objectType}Boards`, 'boards'], allBoards);
    return state;
  },

  // For boards count reactivity
  CREATE_FAVOURITE_TO_OBJECT_SUCCESS: (state, action) => {
    if (action.payload.objectType !== 'meal_plan') {
      let selectedBoards = fromJS(action.payload.favouriteObject.boards);
      let objectType = action.payload.objectType;
      let currentBoards = state.getIn(['boards', `${objectType}Boards`, 'boards']);
      const allBoards = currentBoards
        .toSeq()
        .map(existingBoard => {
          const selectedBoardId = selectedBoards.findIndex(selectedBoard => selectedBoard === existingBoard.getIn(['id']));
          if (selectedBoardId !== -1) {
            const currentCount = existingBoard.getIn(['total_count']);
            existingBoard = existingBoard.setIn(['total_count'], currentCount + 1);
            existingBoard = existingBoard.setIn(['isSelected'], false);

            // Make thumbnail reactive
            const existingThumbnails = existingBoard.getIn(['thumbnail']) || List();
            existingBoard = existingBoard.setIn(['thumbnail'], existingThumbnails.push(action.payload.thumbnail));
          }
          return existingBoard;
        })
        .toList()
      state = state.setIn(['boards', `${objectType}Boards`, 'boards'], allBoards)
    }

    return state;
  },
  DELETE_FAVOURITE_SUCCESS: (state, action) => {
    let objectType = action.payload.objectType;
    if (objectType !== 'meal_plan') {
      let currentBoards = state.getIn(['boards', `${objectType}Boards`, 'boards']);

      const allBoards = currentBoards
        .toSeq()
        .map(existingBoard => {
          const isBoardAffected = action.payload.affectedBoards.findIndex(boardId => boardId === existingBoard.getIn(['id'])) !== -1;
          if (isBoardAffected && existingBoard.getIn(['id']) !== null) {
            // Make thumbnail reactive
            const existingThumbnails = existingBoard.getIn(['thumbnail']);
            const updatedThumbnails = existingThumbnails.filter(thumbnail => thumbnail !== action.payload.thumbnail);
            existingBoard = existingBoard.setIn(['thumbnail'], updatedThumbnails);
          }
          return existingBoard;
        })
      state = state.setIn(['boards', `${objectType}Boards`, 'boards'], allBoards)
    }

    return state;
  },
  // to unselect all selected boards
  CLEAR_BOARD_SELECTION: (state, action) => {
    let objectBoards = `${action.payload.objectType}Boards`;

    let currentBoards = state.getIn(['boards', objectBoards, 'boards']);
    const allBoards = currentBoards
      .toSeq()
      .map(existingBoard => {
        if (existingBoard.getIn(['isSelected'])) {
          existingBoard = existingBoard.setIn(['isSelected'], false);
        }
        return existingBoard;
      })
    state = state.setIn(['boards', objectBoards, 'boards'], allBoards)

    return state
  },

  // For grocery wait list
  ADD_INGREDIENTS_TO_GROCERY_WAITLIST_SUCCESS: (state, action) => {
    const currentWaitlist = state.getIn(['waitList'])
    state = state.setIn(['waitList'], currentWaitlist.concat(fromJS(action.payload)));
    return state;
  },
  DELETE_INGREDIENTS_FROM_GROCERY_WAITLIST: (state, action) => {
    state = state.setIn(['waitList'], List());
    return state;
  },
  REMOVE_INGREDIENTS_FROM_GROCERY_WAITLIST: (state, action) => {
    const currentWaitlist = state.getIn(['waitList']);
    const indexToDelete = currentWaitlist.findIndex(listItem => listItem.getIn(['ingredient', 'id']) === action.payload.id);

    if (indexToDelete !== -1) {
      const updatedWaitlist = currentWaitlist.delete(indexToDelete);
      state = state.setIn(['waitList'], updatedWaitlist)
    }

    return state;
  },


  // GROCERY LIST ACTIONS
  // To select an ingredient in grocery list
  SELECT_INGREDIENT_IN_GROCERY_LIST: (state, action) => {
    let selectedIds = state.getIn(['selectedIds'])
    let selectObject = fromJS(action.payload.selectObject)
    let concatObject = fromJS({
      id: selectObject.getIn(['id']),
      category: selectObject.getIn(['categoryId'])
    })
    selectedIds = selectedIds.push(concatObject)
    state = state.setIn(['selectedIds'], selectedIds)
    return state
  },

  UNSELECT_INGREDIENT_IN_GROCERY_LIST: (state, action) => {
    let currentSelected = state.getIn(['selectedIds'])
    let selectedIds = fromJS(action.payload.id).getIn(['id']) // all elements will be same

    let indexToDelete = currentSelected.findIndex(listItem => {
      return listItem.getIn(['id']).equals(selectedIds)
    });
    if (indexToDelete !== -1) {
      let updatedSelectedIds = currentSelected.delete(indexToDelete)
      state = state.setIn(['selectedIds'], updatedSelectedIds)
    }

    return state
  },

  CHANGE_GROCERY_LIST_VIEW_TYPE: (state, action) => {
    state = state.setIn(['groceryListViewType'], action.payload.viewTypeName)
    return state
  },

  MOVE_INGREDIENT_BETWEEN_AISLES_IN_GROCERY_LIST: (state, action) => {

    // 1) remove all the fromGroupIds from the existing categories
    // 2) insert it into the new list directly

    let fromIds = fromJS(action.payload.fromGroupId)
    let toId = fromJS(action.payload.toGroupId)
    let groceryListCategories = state.getIn(['groceryList', 'categories'])

    let removedListsForAllCategories = List();
    // get the selected ingredient (fromIds ingredient) to move
    let updatedCategories = groceryListCategories
      .toSeq()
      .reduce((outerAccumulator, category) => {

        const allItems = category.getIn(['items']);

        const updatedItemsForCategory = fromIds
          .reduce((accumulator, fromId) => {
            let matchedIndex = accumulator.findIndex(item => fromId === item.getIn(['id']));

            if (matchedIndex !== -1) {
              // Delete the item from existing category and update to removed list
              const ingredientToMove = accumulator.getIn([matchedIndex]);
              accumulator = accumulator.delete(matchedIndex);
              // Update removed list
              let removedLists = outerAccumulator.getIn(['removedList']);
              removedLists = removedLists.push(ingredientToMove);
              outerAccumulator = outerAccumulator.setIn(['removedList'], removedLists);

            }
            return accumulator;
          },
            allItems
          );

        let currentCategories = outerAccumulator.getIn(['categories']);

        let currentCategory = category;
        currentCategory = currentCategory.setIn(['items'], updatedItemsForCategory)
        currentCategories = currentCategories.push(currentCategory);

        return outerAccumulator.setIn(['categories'], currentCategories);
      }, fromJS({
        categories: [],
        removedList: []
      }))

    let resultCategories = updatedCategories.getIn(['categories']);

    // Moving the removed items
    updatedCategories
      .getIn(['removedList'])
      .toSeq()
      .forEach((removedIngredient) => {
        const toIndex = resultCategories.findIndex(category => category.getIn(['id']) === toId);

        let ingredients = resultCategories.getIn([toIndex, 'items']);
        ingredients = ingredients.push(removedIngredient);
        resultCategories = resultCategories.setIn([toIndex, 'items'], ingredients)
      })
    return state.setIn(['groceryList', 'categories'], resultCategories);
  },

  // For clear all items from grocery list reactivity
  CLEAR_ALL_GROCERY_LIST_ITEMS_SUCCESS: (state, action) => {
    let groceryList = state.getIn(['groceryList', 'categories'])
    let newCategories = groceryList
      .toSeq()
      .map(category => {
        let singleCategory = fromJS({
          id: category.getIn(['id']),
          name: category.getIn(['name']),
          items: [],
          slug: null
        })
        return singleCategory
      })

    state = state.setIn(['groceryList', 'categories'], newCategories)

    return state
  },

  ADD_INGREDIENTS_IN_GROCERY_LIST_AISLE_SUCCESS: (state, action) => {
    let groceryList = state.getIn(['groceryList', 'categories'])
    let newIngredientName = action.payload.ingredientName;
    let addCategoryId = action.payload.categoryId;
    let ingredientId = action.payload.id;

    let updatedCategories = groceryList
      .toSeq()
      .map(category => {
        let newItem = fromJS({
          amount: null,
          category_id: addCategoryId,
          display_order: null,
          id: ingredientId,
          item: newIngredientName,
          recipe: null,
          recipe_id: null,
        })

        let categoryId = category.getIn(['id'])
        if (categoryId === addCategoryId) {
          let updatedItems = category.getIn(['items']).push(newItem)
          category = category.setIn(['items'], updatedItems)
        }
        return category
      })

    state = state.setIn(['groceryList', 'categories'], updatedCategories)
    return state
  },

  DELETE_INGREDIENT_IN_GROCERY_LIST_SUCCESS: (state, action) => {

    let groceryList = state.getIn(['groceryList', 'categories'])
    let deleteIds = fromJS(action.payload.itemIds)

    let deletedList = groceryList
      .toSeq()
      .map(category => {

        const newItems = category
          .getIn(['items'])
          .filter(item => {
            let indexToDelete = deleteIds.findIndex(id => id === item.getIn(['id']))
            if (indexToDelete === -1) {
              return item
            }
          })
        category = category.setIn(['items'], newItems)
        return category
      })

    state = state.setIn(['groceryList', 'categories'], deletedList)

    state = state.setIn(['selectedIds'], List());

    return state
  },

  ADD_INGREDIENTS_IN_GROCERY_LIST_RECIPE_SUCCESS: (state, action) => {
    // get into the miscellaneous category in grocery list
    const miscellaneousIndex = state.getIn(['groceryList', 'categories'])
      .findIndex(category => category.getIn(['name']) === 'Miscellaneous')

    let miscellaneousCategories = state.getIn(['groceryList', 'categories', miscellaneousIndex])
    let newIngredientName = action.payload.ingredientName;
    let addRecipeId = action.payload.recipeId;
    let ingredientId = action.payload.id;

    let newItem = fromJS({
      amount: null,
      category_id: miscellaneousCategories.getIn(['id']),
      display_order: null,
      id: ingredientId,
      item: newIngredientName,
      recipe: null,
      recipe_id: addRecipeId,
    })

    let updatedCategories = miscellaneousCategories.getIn(['items']).push(newItem);
    state = state.setIn(['groceryList', 'categories', miscellaneousIndex, 'items'], updatedCategories)
    return state
  },
  CHANGE_CALENDAR_ACTIVE_MONTH: (state, action) => {
    const type = action.payload.type;

    const correction = type === 'previous' ? -1 : 1;
    const currentDate = state.getIn(['calendarSettings', 'activeDate']);
    let updatedCurrentDate;

    // Toggle for every change
    const updatedMonthType = state.getIn(['calendarSettings', 'monthType']) === 'current' ? 'next' : 'current';

    // Change the date for current and next month
    if (updatedMonthType === 'next') {
      updatedCurrentDate = moment(currentDate).add(1, 'months').date(1).format('YYYY-MM-DD');
    } else {
      updatedCurrentDate = state.getIn(['calendarSettings', 'anchorDate']);
    }

    state = state.setIn(['calendarSettings', 'monthType'], updatedMonthType);
    state = state.setIn(['calendarSettings', 'activeDate'], updatedCurrentDate);

    return state;
  },

  CHANGE_CALENDAR_ACTIVE_DATE: (state, action) => {
    const updatedActiveDate = action.payload.activeDate;
    const activeDate = state.getIn(['calendarSettings', 'activeDate']);
    state = state.setIn(['calendarSettings', 'activeDate'], updatedActiveDate);
    return state;
  },

  ADD_MEAL_TO_MEAL_PLAN_SUCCESS: (state, action) => {
    let date = action.payload.mealData.day.date;

    let calendarListData = state.getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulater, item) => {
        let newItem = Map({});
        if (item.getIn(['date']) == date) {
          newItem = item.setIn(['meals'], fromJS(action.payload.mealData.day.meals))
        } else {
          newItem = item
        }
        return accumulater.push(newItem)
      }, List([]))

    return state.setIn(['calendarListData'], calendarListData);
  },
  ADD_CUSTOM_RECIPE_TO_MEAL_PLAN_SUCCESS: (state, action) => {
    let date = action.payload.mealData.day.date;

    let calendarListData = state.getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulater, item) => {
        let newItem = Map({});
        if (item.getIn(['date']) == date) {
          newItem = item.setIn(['meals'], fromJS(action.payload.mealData.day.meals))
        } else {
          newItem = item
        }
        return accumulater.push(newItem)
      }, List([]))

    return state.setIn(['calendarListData'], calendarListData);
  },

  DUPLICATE_MEAL_IN_MEALPLAN_SUCCESS: (state, action) => {
    let date = action.payload.mealData.day.date;

    let calendarListData = state.getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulater, item) => {
        let newItem = Map({});
        if (item.getIn(['date']) == date) {
          newItem = item.setIn(['meals'], fromJS(action.payload.mealData.day.meals))
        } else {
          newItem = item
        }
        return accumulater.push(newItem)
      }, List([]))

    return state.setIn(['calendarListData'], calendarListData);
  },

  DELETE_MEAL_FROM_MEALPLAN_SUCCESS: (state, action) => {
    let date = action.payload.mealData.day.date;
    let calendarListData = state.getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulater, item) => {
        let newItem = Map({});
        if (item.getIn(['date']) == date) {
          newItem = item.setIn(['meals'], fromJS(action.payload.mealData.day.meals))
        } else {
          newItem = item
        }
        return accumulater.push(newItem)
      }, List([]));

    return state.setIn(['calendarListData'], calendarListData);
  },

  CLEAR_MEAL_PLAN_DAY: (state, action) => {
    let day = action.payload.day;
    let calendarListData = state.getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulater, item) => {
        let newItem = Map();
        if (item.getIn(['date']) == day) {
          newItem = item.setIn(['meals'], List())
        } else {
          newItem = item
        }
        return accumulater.push(newItem)
      }, List([]));

    return state.setIn(['calendarListData'], calendarListData);
  },

  MOVE_MEAL_IN_MEALPLAN: (state, action) => {
    const { meal_id, toDate, fromDate, order } = action.payload;

    let movingData = state.getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulator, item) => {
        if (item.getIn(['date']) == fromDate) {
          accumulator = item.getIn(['meals']).filter(meal => meal.getIn(['id']) === meal_id);
        }
        return accumulator;
      }, Map())

    let calendarListData;

    if (fromDate !== toDate) {
      calendarListData = state.getIn(['calendarListData'])
        .toSeq()
        .reduce((accumulater, item) => {
          let newItem = Map();
          if (item.getIn(['date']) == fromDate) {
            // Delete only when the date is different
            let newMeals = item.getIn(['meals']).filter(meal => meal.getIn(['id']) !== meal_id);
            newItem = item.setIn(['meals'], newMeals);
          } else if (item.getIn(['date']) == toDate) {
            const data = movingData.getIn([0]).toJS();
            let structuredData = fromJS({
              id: data.id,
              day: moment(toDate, 'YYYY-MM-DD').format('D'),
              meal: data.meal,
              recipe: data.recipe,
              workout: data.workout,
            })
            let newMeals = (item.getIn(['meals'])).insert(order, structuredData);
            newItem = item.setIn(['meals'], newMeals);
          } else {
            newItem = item
          }

          return accumulater.push(newItem)
        }, List([]))
    } else {
      calendarListData = state.getIn(['calendarListData'])
        .toSeq()
        .reduce((accumulater, item) => {
          let newItem = Map();
          if (item.getIn(['date']) == fromDate) {
            // Delete only when the date is different
            const indexToRemove = item.getIn(['meals']).findIndex(meal => meal.getIn(['id']) === meal_id);
            const indexToInsert = order;
            const meals = item.getIn(['meals']).toJS();
            const movedArray = arrayMove(meals, indexToRemove, indexToInsert);

            newItem = item.setIn(['meals'], fromJS(movedArray));
          } else {
            newItem = item
          }

          return accumulater.push(newItem)
        }, List([]))
    }

    return state.setIn(['calendarListData'], calendarListData);
  },

  DELETE_FAVOURITE_BOARD_SUCCESS: (state, action) => {
    const { boardId, objectType } = action.payload;
    let boards = state.getIn(['boards', `${objectType}Boards`, 'boards']);
    let updatedBoards = boards.filter(board => board.getIn(['id']) !== boardId);

    state = state.setIn(['boards', `${objectType}Boards`, 'boards'], updatedBoards);
    return state;
  },

  UPDATE_FAVOURITE_BOARD: (state, action) => {
    // Doing it optimistically. Should have been in success only
    const { boardId, objectType, title } = action.payload;
    let boards = state.getIn(['boards', `${objectType}Boards`, 'boards']);
    boards = boards
      .toSeq()
      .reduce((accumulater, board) => {
        if (board.getIn(['id']) === boardId) {
          board = board.setIn(['title'], title);
        }
        return accumulater.push(board);
      }, List());
    state = state.setIn(['boards', `${objectType}Boards`, 'boards'], boards);
    return state;
  },

  UPDATE_CALENDAR_SETTINGS_SUCCESS: (state, action) => {
    state = state.setIn(['calendarSettings', 'macros'], fromJS(action.payload.macros));
    state = state.setIn(['calendarSettings', 'calendarView'], fromJS(action.payload.calendarView || returnDefaultCalendarView()));
    state = state.setIn(['calendarSettings', 'showPreMadeMealPlans'], fromJS(action.payload.showPreMadeMealPlans));
    state = state.setIn(['calendarSettings', 'showViewableMacros'], fromJS(action.payload.showViewableMacros));
    state = state.setIn(['calendarSettings', 'automateServingSize'], fromJS(action.payload.automateServingSize));

    return state;
  },


  APP_STARTUP_SUCCESS: (state, action) => {
    state = state.setIn(['calendarSettings', 'macros'], fromJS(action.payload.macros || ['calories', 'smartPoints', 'protein']));
    state = state.setIn(['calendarSettings', 'calendarView'], fromJS(action.payload.calendarView || returnDefaultCalendarView()));
    state = state.setIn(['calendarSettings', 'showPreMadeMealPlans'], fromJS(action.payload.showPreMadeMealPlans));
    state = state.setIn(['calendarSettings', 'showViewableMacros'], fromJS(action.payload.showViewableMacros));
    state = state.setIn(['calendarSettings', 'automateServingSize'], fromJS(action.payload.automateServingSize));
    return state;
  },

  ADD_MEAL_PLAN_TO_CALENDAR_SUCCESS: (state, action) => {
    const dataGroupedByDate = groupBy(action.payload.calendarItems, calendarItem => calendarItem.date);

    const existingCalendarItems = state
      .getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulator, eachDate) => {
        const currentDate = eachDate.getIn(['date']);
        const currentDateMeals = dataGroupedByDate[currentDate];
        const formattedCurrentDateMeals = map(currentDateMeals, (eachMeal) => {
          const recipeMeta = eachMeal.recipe_meta;
          recipeMeta.variations = JSON.parse(recipeMeta.variations);
          return {
            ...eachMeal,
            recipe: recipeMeta
          }
        });
        let existingCurrentDateMeals = eachDate.getIn(['meals']);
        const isInRange = moment(currentDate).isBetween(
          action.payload.startDate,
          moment(action.payload.startDate).add({ days: action.payload.mealPlanDuration - 1 }).format('YYYY-MM-DD'),
          null,
          '[]'
        );
        if (action.payload.removeOld && isInRange) {
          existingCurrentDateMeals = List();
        }
        existingCurrentDateMeals = existingCurrentDateMeals.concat(fromJS(formattedCurrentDateMeals));
        eachDate = eachDate.setIn(['meals'], existingCurrentDateMeals);
        accumulator = accumulator.push(eachDate);
        return accumulator;
      }, List());

    state = state.setIn(['calendarListData'], existingCalendarItems);
    return state;
  },
  ADD_SAVED_DAY_SUCCESS: (state, action) => {
    const dataGroupedByDate = groupBy(action.payload.calendarItems, calendarItem => calendarItem.date);
    const existingCalendarItems = state
      .getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulator, eachDate) => {
        const currentDate = eachDate.getIn(['date']);
        const currentDateMeals = dataGroupedByDate[currentDate];
        const formattedCurrentDateMeals = map(currentDateMeals, (eachMeal) => {
          let recipeMeta;
          let workoutMeta;
          if (isEmpty(eachMeal.workout_meta)) {
            recipeMeta = eachMeal.recipe_meta;
            if (!isEmpty(recipeMeta.variations)) {
              recipeMeta.variations = JSON.parse(recipeMeta.variations);
            }
          } else {
            workoutMeta = eachMeal.workout_meta;
          }

          return {
            ...eachMeal,
            recipe: recipeMeta,
            workout: workoutMeta,
          }
        });
        let existingCurrentDateMeals = eachDate.getIn(['meals']);
        const isInRange = moment(currentDate).isBetween(
          action.payload.startDate,
          moment(action.payload.startDate).add({ days: action.payload.mealPlanDuration - 1 }).format('YYYY-MM-DD'),
          null,
          '[]'
        );
        if (action.payload.removeOld && isInRange) {
          existingCurrentDateMeals = List();
        }
        existingCurrentDateMeals = existingCurrentDateMeals.concat(fromJS(formattedCurrentDateMeals));
        eachDate = eachDate.setIn(['meals'], existingCurrentDateMeals);
        accumulator = accumulator.push(eachDate);
        return accumulator;
      }, List());
    state = state.setIn(['calendarListData'], existingCalendarItems);
    return state;
  },
  ADD_MEAL_TO_MULTIPLE_DATES_SUCCESS: (state, action) => {
    const dataGroupedByDate = groupBy(action.payload.mealData.calendar_items, calendarItem => moment(calendarItem.date).utc().format('YYYY-MM-DD'));
    const existingCalendarItems = state
      .getIn(['calendarListData'])
      .toSeq()
      .reduce((accumulator, eachDate) => {
        const currentDate = eachDate.getIn(['date']);
        const currentDateMeals = dataGroupedByDate[currentDate];
        const formattedCurrentDateMeals = map(currentDateMeals, (eachMeal) => {
          if (!isEmpty(eachMeal.recipe_meta)) {
            const recipeMeta = eachMeal.recipe_meta;
            recipeMeta.variations = JSON.parse(recipeMeta.variations);
            return {
              ...eachMeal,
              recipe: {
                ...recipeMeta,
                id: eachMeal.recipe_id
              }
            }
          } else {
            const workoutMeta = eachMeal.workout_meta;
            return {
              ...eachMeal,
              workout: {
                ...workoutMeta,
                id: eachMeal.workout_id
              }
            }
          }

        });
        let existingCurrentDateMeals = eachDate.getIn(['meals']);
        const isInRange = moment(currentDate).utc().isBetween(
          action.payload.startDate,
          moment(action.payload.startDate).utc().add({ days: action.payload.mealPlanDuration - 1 }).format('YYYY-MM-DD'),
          null,
          '[]'
        );

        if (action.payload.removeOld && isInRange) {
          existingCurrentDateMeals = List();
        }
        existingCurrentDateMeals = existingCurrentDateMeals.concat(fromJS(formattedCurrentDateMeals));
        eachDate = eachDate.setIn(['meals'], existingCurrentDateMeals);
        accumulator = accumulator.push(eachDate);
        return accumulator;
      }, List());
    state = state.setIn(['calendarListData'], existingCalendarItems);
    return state;

  },

  SHOW_GROCERYLIST_LIMIT_NOTIFICATION: (state, action) => {
    state = state.setIn(["groceryListThresholdReached"], true);
    state = state.setIn(["temporaryGroceryList"], fromJS(action.payload.ingredients));
    return state;
  },
  REMOVE_GROCERYLIST_LIMIT_NOTIFICATION: (state, action) => {
    state = state.setIn(["groceryListThresholdReached"], false);
    state = state.setIn(["temporaryGroceryList"], []);
    return state;
  },

  REPLACE_GROCERY_LIST_SUCCESS: (state, action) => {
    state = state.setIn(["groceryListThresholdReached"], false);
    state = state.setIn(["temporaryGroceryList"], []);
    return state;
  }
});