import axios from 'axios';
import isNull from 'lodash/isNull';
import { get, post } from '../../helpers/requests';
import { auth } from '../../helpers/api';
import { call, select, put } from 'redux-saga/effects';
import { getToken } from '../selectors'

export default {
  get: function* getUserDetails() {

    const token = yield select(getToken);
    let userData = {
      isLoggedIn: false,
      token,
      details: null
    };
    if (!isNull(token)) {
      userResponse = yield call(axios, get(auth.getAccountDetails(), '', token));
      userData = {
        isLoggedIn: true,
        token,
        details: userResponse.data.user
      };
    }

    return userData
  },
} 