import React from "react";
import '../styles/common.scss';


const EditableServingIcon = (props) => {
  return (
    < >
      <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.3936 23.5685L14.7982 28.1639L10.1968 23.5685M10.1968 18.889L14.7982 14.2936L19.3936 18.889M28.5965 21.2018C28.5965 28.8223 22.4188 35 14.7982 35C7.17768 35 1 28.8223 1 21.2018C1 13.5812 7.17768 7.40355 14.7982 7.40355C22.4188 7.40355 28.5965 13.5812 28.5965 21.2018ZM14.4198 7.40954C14.4198 3.87138 17.2911 1 20.8293 1C20.8293 4.53816 17.9579 7.40954 14.4198 7.40954Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </>
  );
};
export default EditableServingIcon;
