import React from 'react';
import { themeTextRed } from '../../helpers/theme';
import '../styles/common.scss';

const PlanningIcon = () => {
    return (
        <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.1283 45.2318L29.8028 52.1725L43.8717 34.9849" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6.91711 22.1351H65.6785" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.6248 8.08955H7.36271C3.93926 8.08955 1.17236 11.1847 1.17236 15.0068V59.9337C1.17236 63.7558 3.93926 66.8509 7.36271 66.8509H60.6372C64.0606 66.8509 66.8275 63.7558 66.8275 59.9337V14.9833C66.8275 11.1613 64.0606 8.0661 60.6372 8.0661H56.4634" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M49.5463 1.17242V14.9835" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.4537 1.17242V14.9835" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.371 8.08974L49.4055 8.04285" stroke={themeTextRed} stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default PlanningIcon;