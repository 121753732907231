import React from 'react';
import moment from "moment";

import coverImage from '../../images/membership-heroImg.png'

const GiftMembershipSuccess = (props) => {
  const giftData = props.isGiftSent.giftData;
  return (
    <section className="gift-membership"
      style={{
        backgroundImage: `url(${coverImage})`,
      }}>
      <div className="container">
        <div className="gift-container">
          <h2 className="gift-header">Gift Acknowledgement</h2>
          <p>{`Your gift purchase has been successful. It will be sent to ${giftData.recipient.name} on ${(moment(giftData.giftSendDate).format("DD-MM-YYYY"))}.`}</p>
        </div>
      </div>
    </section>
  )
}

export default GiftMembershipSuccess