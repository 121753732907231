import React from 'react'
import ReactImageFallback from "react-image-fallback";

import FallbackImage from '../../images/fallback-logo.png'


const SlugImage = (props) => {
  const {
    featuredImage,
    className
  } = props;
  return (
    <figure className="CardImg">
      <ReactImageFallback
        src={featuredImage}
        fallbackImage={FallbackImage}
        className={className}
      />
    </figure>
  )
}

export default SlugImage;