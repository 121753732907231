import axios from 'axios'
import { showToastMessage } from '../actions'
import { put, takeEvery, call, select, delay } from 'redux-saga/effects'
import { patch } from '../helpers/requests';
import { user } from '../helpers/api';

import { getRegisteredUser, getToken, getUserId } from './selectors'
import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry';


function* task(action) {
  try {

    const token = yield select(getToken);
    const userId = yield select(getUserId);

    yield call(axios, patch(user.reverseCancelSubscription(userId), {
      user: {
        membership: {
          undo_cancel: true
        }
      }
    }, '', token));

    yield put({
      type: actions.REVERSE_CANCEL_SUBSCRIPTION_SUCCESS,
      payload: {
        success: true,
      }
    })
  } catch (e) {
    yield put(showToastMessage(`❗️Error in reversing cancel subscription`, 'error'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
    yield put({
      type: actions.REVERSE_CANCEL_SUBSCRIPTION_FAILURE,
      payload: {
        error: e.message
      }
    })
  }
}

export default function* reverseCancelSubscription() {
  yield takeEvery(actions.REVERSE_CANCEL_SUBSCRIPTION, task)
}