import React from 'react'

import { baseUrl } from '../../../../helpers/api'
import map from 'lodash/map'

import isEmpty from 'lodash/isEmpty'

import LeftArrow from '../../../images/LeftArrow'
import GridCard from '../../../common/GridCard'

const Recipes = ({
  days,
  moveToIndex,
  item
}) => {
  const renderData = () => map(days, day => (
    <>
      <div className="each-date">
        <p className="details">Day {day.date}</p>
        {
          day.meals && day.meals.length > 0 ?
            map(day.meals, (meal) => (
              <div key={meal.id} className="calendarItem">
                <GridCard
                  target='_blank'
                  openInNew
                  imageOnly
                  coverImage={meal.recipe.images.square_200}
                  additionalStyles={{ maxHeight: 40, minHeight: 40 }}
                  key={!isEmpty(meal.recipe) ? meal.recipe.id : meal.workout.id}
                  objectType={!isEmpty(meal.recipe) ? 'recipe' : 'workout'}
                  item={
                    !isEmpty(meal) ? {
                      ...meal.recipe,
                      coverImage: meal.recipe.images.square_200
                    } : {
                      ...meal.workout,
                      coverImage: meal.recipe.images.square_200
                    }}
                />
                <div className="title">
                  <a
                    target="_blank"
                    href={`/recipe/${meal.recipe.slug}`}
                    className="no-highlight"
                  >
                    {meal.recipe.title}
                  </a>
                </div>
              </div>
            )) : (
              <h3 className="WrapperTitle">
                No Recipe for the particular day
              </h3>
            )
        }
      </div>
    </>
  ))

  return (
    <div className="modalWrapper index-wrapper display-only">
      <div className="header" onClick={() => moveToIndex(item)}>
        <div className="header-text" onClick={() => moveToIndex(item)}>
          <LeftArrow />
          Back
        </div>
      </div>
      <div className="recipes-list">
        <h3 className="WrapperTitle">Recipes</h3>
        <div className="list">
          {renderData()}
        </div>
      </div>
    </div>
  )
}

export default Recipes;