import axios from "axios";
import { put, takeEvery, call, select } from "redux-saga/effects";
// import { Sentry } from 'react-native-sentry';
import { patch } from "../helpers/requests";
import { user } from "../helpers/api";
import { showToastMessage } from "../actions";
import * as actions from "../actions/actionTypes";
import pushToSentry from "../helpers/pushToSentry";
import { getUserId, getToken } from "./selectors";

function* task(action) {
  try {
    const { email, password, avatar } = action.payload;
    const body = {};

    if (email) {
      body.user = {
        email
      };
    }
    if (password) {
      body.user = {
        password
      };
    }

    if (avatar) {
      // Need to do this since paperclip doesn't accept file names also
      const modifiedAvatar = avatar.split(";")[0] + ";" + avatar.split(";")[2];
      body.user = {
        base64_avatar: modifiedAvatar
      };
    }

    const userId = yield select(getUserId);
    const token = yield select(getToken);

    let response = {
      data: {
        user: {}
      }
    };

    if (email || avatar || password) {
      // Updates the password
      response = yield call(axios, patch(user.edit(userId), body, "", token));
    }

    yield put({
      type: actions.EDIT_USER_PROFILE_SUCCESS,
      payload: {
        avatar: response.data.user.avatar
      }
    });

    yield put(showToastMessage(`✅ Updated account settings`, "success"));
  } catch (e) {
    const eventId = pushToSentry(e);
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    });
    yield put(
      showToastMessage(`❗️ Failed updating account settings`, "success")
    );
    yield put({
      type: actions.EDIT_USER_PROFILE_FAILURE,
      payload: {
        error: e.message || "Some error occured."
      }
    });
  }
}

export default function* registerUser() {
  yield takeEvery(actions.EDIT_USER_PROFILE_REQUEST, task);
}
