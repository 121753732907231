// Harcoded list of ingredients data.
export const INGREDIENT_DATA =[
    {
        "ingredient_name": "Salt",
        "ingredient_slug": "salt",
        "recipe_count": 1419
    },
    {
        "ingredient_name": "Sweetener of choice",
        "ingredient_slug": "sweetener-of-choice",
        "recipe_count": 756
    },
    {
        "ingredient_name": "Garlic powder",
        "ingredient_slug": "garlic-powder",
        "recipe_count": 638
    },
    {
        "ingredient_name": "Pepper",
        "ingredient_slug": "pepper",
        "recipe_count": 505
    },
    {
        "ingredient_name": "Egg",
        "ingredient_slug": "egg",
        "recipe_count": 480
    },
    {
        "ingredient_name": "Greek yogurt",
        "ingredient_slug": "greek-yogurt",
        "recipe_count": 479
    },
    {
        "ingredient_name": "Chicken breast",
        "ingredient_slug": "chicken-breast",
        "recipe_count": 405
    },
    {
        "ingredient_name": "Almond milk",
        "ingredient_slug": "almond-milk",
        "recipe_count": 405
    },
    {
        "ingredient_name": "Parmesan cheese",
        "ingredient_slug": "parmesan-cheese",
        "recipe_count": 397
    },
    {
        "ingredient_name": "Oat flour",
        "ingredient_slug": "oat-flour",
        "recipe_count": 366
    },
    {
        "ingredient_name": "Baking powder",
        "ingredient_slug": "baking-powder",
        "recipe_count": 335
    },
    {
        "ingredient_name": "Garlic",
        "ingredient_slug": "garlic",
        "recipe_count": 292
    },
    {
        "ingredient_name": "Protein powder",
        "ingredient_slug": "protein-powder",
        "recipe_count": 278
    },
    {
        "ingredient_name": "Baking soda",
        "ingredient_slug": "baking-soda",
        "recipe_count": 256
    },
    {
        "ingredient_name": "Onion powder",
        "ingredient_slug": "onion-powder",
        "recipe_count": 249
    },
    {
        "ingredient_name": "Cinnamon",
        "ingredient_slug": "cinnamon",
        "recipe_count": 247
    },
    {
        "ingredient_name": "Onion",
        "ingredient_slug": "onion",
        "recipe_count": 243
    },
    {
        "ingredient_name": "Applesauce",
        "ingredient_slug": "applesauce",
        "recipe_count": 233
    },
    {
        "ingredient_name": "Egg white",
        "ingredient_slug": "egg-white",
        "recipe_count": 232
    },
    {
        "ingredient_name": "Maple syrup",
        "ingredient_slug": "maple-syrup",
        "recipe_count": 228
    },
    {
        "ingredient_name": "Honey",
        "ingredient_slug": "honey",
        "recipe_count": 225
    },
    {
        "ingredient_name": "Vanilla extract",
        "ingredient_slug": "vanilla-extract",
        "recipe_count": 196
    },
    {
        "ingredient_name": "Water",
        "ingredient_slug": "water",
        "recipe_count": 186
    },
    {
        "ingredient_name": "Chocolate chips",
        "ingredient_slug": "chocolate-chip",
        "recipe_count": 186
    },
    {
        "ingredient_name": "Lean ground meat",
        "ingredient_slug": "lean-ground-meat",
        "recipe_count": 184
    },
    {
        "ingredient_name": "Cottage cheese",
        "ingredient_slug": "cottage-cheese",
        "recipe_count": 180
    },
    {
        "ingredient_name": "Lemon juice",
        "ingredient_slug": "lemon-juice",
        "recipe_count": 177
    },
    {
        "ingredient_name": "Almond flour",
        "ingredient_slug": "almond-flour",
        "recipe_count": 171
    },
    {
        "ingredient_name": "Cheese",
        "ingredient_slug": "cheese",
        "recipe_count": 171
    },
    {
        "ingredient_name": "Old fashioned oat",
        "ingredient_slug": "old-fashioned-oat",
        "recipe_count": 168
    },
    {
        "ingredient_name": "Nut butter",
        "ingredient_slug": "nut-butter",
        "recipe_count": 165
    },
    {
        "ingredient_name": "Cocoa powder",
        "ingredient_slug": "cocoa-powder",
        "recipe_count": 156
    },
    {
        "ingredient_name": "Chicken broth",
        "ingredient_slug": "chicken-broth",
        "recipe_count": 147
    },
    {
        "ingredient_name": "Mozzarella",
        "ingredient_slug": "mozzarella",
        "recipe_count": 146
    },
    {
        "ingredient_name": "Paprika",
        "ingredient_slug": "paprika",
        "recipe_count": 141
    },
    {
        "ingredient_name": "Chili powder",
        "ingredient_slug": "chili-powder",
        "recipe_count": 140
    },
    {
        "ingredient_name": "Cumin",
        "ingredient_slug": "cumin",
        "recipe_count": 131
    },
    {
        "ingredient_name": "Peanut flour",
        "ingredient_slug": "peanut-flour",
        "recipe_count": 126
    },
    {
        "ingredient_name": "Zucchini",
        "ingredient_slug": "zucchini",
        "recipe_count": 118
    },
    {
        "ingredient_name": "Italian seasoning",
        "ingredient_slug": "italian-seasoning",
        "recipe_count": 115
    },
    {
        "ingredient_name": "Parsley",
        "ingredient_slug": "parsley",
        "recipe_count": 114
    },
    {
        "ingredient_name": "Cream cheese",
        "ingredient_slug": "cream-cheese",
        "recipe_count": 112
    },
    {
        "ingredient_name": "Oregano",
        "ingredient_slug": "oregano",
        "recipe_count": 108
    },
    {
        "ingredient_name": "Carrot",
        "ingredient_slug": "carrot",
        "recipe_count": 107
    },
    {
        "ingredient_name": "Soy sauce",
        "ingredient_slug": "soy-sauce",
        "recipe_count": 106
    },
    {
        "ingredient_name": "Avocado oil",
        "ingredient_slug": "avocado-oil",
        "recipe_count": 100
    },
    {
        "ingredient_name": "Bell pepper",
        "ingredient_slug": "bell-pepper",
        "recipe_count": 98
    },
    {
        "ingredient_name": "Red onion",
        "ingredient_slug": "red-onion",
        "recipe_count": 96
    },
    {
        "ingredient_name": "Corn",
        "ingredient_slug": "corn",
        "recipe_count": 95
    },
    {
        "ingredient_name": "Bacon",
        "ingredient_slug": "bacon",
        "recipe_count": 93
    },
    {
        "ingredient_name": "Cheddar",
        "ingredient_slug": "cheddar",
        "recipe_count": 88
    },
    {
        "ingredient_name": "Pumpkin pie spice",
        "ingredient_slug": "pumpkin-pie-spice",
        "recipe_count": 87
    },
    {
        "ingredient_name": "Tortilla",
        "ingredient_slug": "tortilla",
        "recipe_count": 87
    },
    {
        "ingredient_name": "Celery",
        "ingredient_slug": "celery",
        "recipe_count": 85
    },
    {
        "ingredient_name": "Salsa",
        "ingredient_slug": "salsa",
        "recipe_count": 84
    },
    {
        "ingredient_name": "Cilantro",
        "ingredient_slug": "cilantro",
        "recipe_count": 84
    },
    {
        "ingredient_name": "Diced tomato",
        "ingredient_slug": "diced-tomato",
        "recipe_count": 79
    },
    {
        "ingredient_name": "Pasta",
        "ingredient_slug": "pasta",
        "recipe_count": 79
    },
    {
        "ingredient_name": "Spinach",
        "ingredient_slug": "spinach",
        "recipe_count": 77
    },
    {
        "ingredient_name": "Tomato",
        "ingredient_slug": "tomato",
        "recipe_count": 76
    },
    {
        "ingredient_name": "Green onion",
        "ingredient_slug": "green-onion",
        "recipe_count": 76
    },
    {
        "ingredient_name": "Strawberries",
        "ingredient_slug": "strawberries",
        "recipe_count": 75
    },
    {
        "ingredient_name": "Lime juice",
        "ingredient_slug": "lime-juice",
        "recipe_count": 74
    },
    {
        "ingredient_name": "Black bean",
        "ingredient_slug": "black-bean",
        "recipe_count": 73
    },
    {
        "ingredient_name": "Feta cheese",
        "ingredient_slug": "feta-cheese",
        "recipe_count": 72
    },
    {
        "ingredient_name": "Sweetener of choice",
        "ingredient_slug": "sweetener-of-choice",
        "recipe_count": 72
    },
    {
        "ingredient_name": "Cornstarch",
        "ingredient_slug": "cornstarch",
        "recipe_count": 72
    },
    {
        "ingredient_name": "Tomato paste",
        "ingredient_slug": "tomato-paste",
        "recipe_count": 67
    },
    {
        "ingredient_name": "Tru whip",
        "ingredient_slug": "tru-whip",
        "recipe_count": 67
    },
    {
        "ingredient_name": "Basil",
        "ingredient_slug": "basil",
        "recipe_count": 67
    },
    {
        "ingredient_name": "Mustard",
        "ingredient_slug": "mustard",
        "recipe_count": 65
    },
    {
        "ingredient_name": "Marinara",
        "ingredient_slug": "marinara",
        "recipe_count": 62
    },
    {
        "ingredient_name": "Ginger",
        "ingredient_slug": "ginger",
        "recipe_count": 61
    },
    {
        "ingredient_name": "Red bell pepper",
        "ingredient_slug": "red-bell-pepper",
        "recipe_count": 60
    },
    {
        "ingredient_name": "Sweet potato",
        "ingredient_slug": "sweet-potato",
        "recipe_count": 59
    },
    {
        "ingredient_name": "Ketchup",
        "ingredient_slug": "ketchup",
        "recipe_count": 59
    },
    {
        "ingredient_name": "Banana",
        "ingredient_slug": "banana",
        "recipe_count": 59
    },
    {
        "ingredient_name": "Pumpkin puree",
        "ingredient_slug": "pumpkin-puree",
        "recipe_count": 58
    },
    {
        "ingredient_name": "Salt and pepper",
        "ingredient_slug": "salt-and-pepper",
        "recipe_count": 57
    },
    {
        "ingredient_name": "Oil of choice",
        "ingredient_slug": "oil-of-choice",
        "recipe_count": 56
    },
    {
        "ingredient_name": "Tomato sauce",
        "ingredient_slug": "tomato-sauce",
        "recipe_count": 53
    },
    {
        "ingredient_name": "Avocado",
        "ingredient_slug": "avocado",
        "recipe_count": 53
    },
    {
        "ingredient_name": "Cauliflower",
        "ingredient_slug": "cauliflower",
        "recipe_count": 52
    },
    {
        "ingredient_name": "Spaghetti squash",
        "ingredient_slug": "spaghetti-squash",
        "recipe_count": 50
    },
    {
        "ingredient_name": "Cherry tomato",
        "ingredient_slug": "cherry-tomato",
        "recipe_count": 49
    },
    {
        "ingredient_name": "Apple",
        "ingredient_slug": "apple",
        "recipe_count": 49
    },
    {
        "ingredient_name": "Roma tomato",
        "ingredient_slug": "roma-tomato",
        "recipe_count": 47
    },
    {
        "ingredient_name": "Peanut butter",
        "ingredient_slug": "peanut-butter",
        "recipe_count": 47
    },
    {
        "ingredient_name": "Broccoli",
        "ingredient_slug": "broccoli",
        "recipe_count": 46
    },
    {
        "ingredient_name": "Cauliflower rice",
        "ingredient_slug": "cauliflower-rice",
        "recipe_count": 46
    },
    {
        "ingredient_name": "Apple cider vinegar",
        "ingredient_slug": "apple-cider-vinegar",
        "recipe_count": 46
    },
    {
        "ingredient_name": "Almond extract",
        "ingredient_slug": "almond-extract",
        "recipe_count": 43
    },
    {
        "ingredient_name": "Balsamic vinegar",
        "ingredient_slug": "balsamic-vinegar",
        "recipe_count": 41
    },
    {
        "ingredient_name": "Sweetener of choice",
        "ingredient_slug": "sweetener-of-choice",
        "recipe_count": 40
    },
    {
        "ingredient_name": "Pumpkin",
        "ingredient_slug": "pumpkin",
        "recipe_count": 37
    },
    {
        "ingredient_name": "Ice cube",
        "ingredient_slug": "ice-cube",
        "recipe_count": 37
    },
    {
        "ingredient_name": "Worcestershire sauce",
        "ingredient_slug": "worcestershire-sauce",
        "recipe_count": 37
    },
    {
        "ingredient_name": "Sesame seed",
        "ingredient_slug": "sesame-seed",
        "recipe_count": 36
    },
    {
        "ingredient_name": "Coconut flour",
        "ingredient_slug": "coconut-flour",
        "recipe_count": 34
    },
    {
        "ingredient_name": "Blueberries",
        "ingredient_slug": "blueberries",
        "recipe_count": 33
    },
    {
        "ingredient_name": "Red wine vinegar",
        "ingredient_slug": "red-wine-vinegar",
        "recipe_count": 33
    },
    {
        "ingredient_name": "Taco seasoning",
        "ingredient_slug": "taco-seasoning",
        "recipe_count": 32
    },
    {
        "ingredient_name": "Nutmeg",
        "ingredient_slug": "nutmeg",
        "recipe_count": 32
    },
    {
        "ingredient_name": "Thyme",
        "ingredient_slug": "thyme",
        "recipe_count": 32
    },
    {
        "ingredient_name": "Quinoa",
        "ingredient_slug": "quinoa",
        "recipe_count": 32
    },
    {
        "ingredient_name": "Pepperoni",
        "ingredient_slug": "pepperoni",
        "recipe_count": 32
    },
    {
        "ingredient_name": "Sweet onion",
        "ingredient_slug": "sweet-onion",
        "recipe_count": 31
    },
    {
        "ingredient_name": "Broccoli floret",
        "ingredient_slug": "broccoli-floret",
        "recipe_count": 31
    },
    {
        "ingredient_name": "Pecan",
        "ingredient_slug": "pecan",
        "recipe_count": 31
    },
    {
        "ingredient_name": "Lettuce",
        "ingredient_slug": "lettuce",
        "recipe_count": 29
    },
    {
        "ingredient_name": "Coconut oil",
        "ingredient_slug": "coconut-oil",
        "recipe_count": 28
    },
    {
        "ingredient_name": "Sprinkles",
        "ingredient_slug": "sprinkle",
        "recipe_count": 28
    },
    {
        "ingredient_name": "Dill",
        "ingredient_slug": "dill",
        "recipe_count": 28
    },
    {
        "ingredient_name": "Chicken stock",
        "ingredient_slug": "chicken-stock",
        "recipe_count": 28
    },
    {
        "ingredient_name": "Ice",
        "ingredient_slug": "ice",
        "recipe_count": 27
    },
    {
        "ingredient_name": "Oats",
        "ingredient_slug": "oats",
        "recipe_count": 26
    },
    {
        "ingredient_name": "Rice vinegar",
        "ingredient_slug": "rice-vinegar",
        "recipe_count": 26
    },
    {
        "ingredient_name": "Cucumber",
        "ingredient_slug": "cucumber",
        "recipe_count": 26
    },
    {
        "ingredient_name": "Dijon mustard",
        "ingredient_slug": "dijon-mustard",
        "recipe_count": 25
    },
    {
        "ingredient_name": "Pizza sauce",
        "ingredient_slug": "pizza-sauce",
        "recipe_count": 25
    },
    {
        "ingredient_name": "Romaine lettuce",
        "ingredient_slug": "romaine-lettuce",
        "recipe_count": 25
    },
    {
        "ingredient_name": "Sweetener",
        "ingredient_slug": "sweetener",
        "recipe_count": 25
    },
    {
        "ingredient_name": "Pinto beans",
        "ingredient_slug": "pinto-bean",
        "recipe_count": 25
    },
    {
        "ingredient_name": "Chicken",
        "ingredient_slug": "chicken",
        "recipe_count": 25
    },
    {
        "ingredient_name": "Shredded cheese",
        "ingredient_slug": "shredded-cheese",
        "recipe_count": 25
    },
    {
        "ingredient_name": "White bean",
        "ingredient_slug": "white-bean",
        "recipe_count": 25
    },
    {
        "ingredient_name": "Poultry seasoning",
        "ingredient_slug": "poultry-seasoning",
        "recipe_count": 24
    },
    {
        "ingredient_name": "Marinara sauce",
        "ingredient_slug": "marinara-sauce",
        "recipe_count": 24
    },
    {
        "ingredient_name": "Diced green chile",
        "ingredient_slug": "diced-green-chile",
        "recipe_count": 24
    },
    {
        "ingredient_name": "Mozzarella cheese",
        "ingredient_slug": "mozzarella-cheese",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Garbanzo bean",
        "ingredient_slug": "garbanzo-bean",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Lean ground beef",
        "ingredient_slug": "lean-ground-beef",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Minced garlic",
        "ingredient_slug": "minced-garlic",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Parmesan",
        "ingredient_slug": "parmesan",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Grape tomato",
        "ingredient_slug": "grape-tomato",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Graham crackers",
        "ingredient_slug": "graham-crackers",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Potato",
        "ingredient_slug": "potato",
        "recipe_count": 23
    },
    {
        "ingredient_name": "Hummus",
        "ingredient_slug": "hummus",
        "recipe_count": 22
    },
    {
        "ingredient_name": "Butter extract",
        "ingredient_slug": "butter-extract",
        "recipe_count": 21
    },
    {
        "ingredient_name": "Peanuts",
        "ingredient_slug": "peanuts",
        "recipe_count": 21
    },
    {
        "ingredient_name": "Mushroom",
        "ingredient_slug": "mushroom",
        "recipe_count": 21
    },
    {
        "ingredient_name": "Peppermint extract",
        "ingredient_slug": "peppermint-extract",
        "recipe_count": 21
    },
    {
        "ingredient_name": "Refried bean",
        "ingredient_slug": "refried-bean",
        "recipe_count": 21
    },
    {
        "ingredient_name": "Maple extract",
        "ingredient_slug": "maple-extract",
        "recipe_count": 21
    },
    {
        "ingredient_name": "Enchilada sauce",
        "ingredient_slug": "enchilada-sauce",
        "recipe_count": 21
    },
    {
        "ingredient_name": "Italian seasoning",
        "ingredient_slug": "italian-seasoning",
        "recipe_count": 20
    },
    {
        "ingredient_name": "Yellow onion",
        "ingredient_slug": "yellow-onion",
        "recipe_count": 20
    },
    {
        "ingredient_name": "Green bell pepper",
        "ingredient_slug": "green-bell-pepper",
        "recipe_count": 20
    },
    {
        "ingredient_name": "Almonds",
        "ingredient_slug": "almonds",
        "recipe_count": 20
    },
    {
        "ingredient_name": "Almond milk",
        "ingredient_slug": "almond-milk",
        "recipe_count": 20
    },
    {
        "ingredient_name": "Chickpea",
        "ingredient_slug": "chickpea",
        "recipe_count": 19
    },
    {
        "ingredient_name": "Center cut bacon",
        "ingredient_slug": "center-cut-bacon",
        "recipe_count": 19
    },
    {
        "ingredient_name": "Lemon",
        "ingredient_slug": "lemon",
        "recipe_count": 19
    },
    {
        "ingredient_name": "Olive",
        "ingredient_slug": "olive",
        "recipe_count": 19
    },
    {
        "ingredient_name": "Black olive",
        "ingredient_slug": "black-olive",
        "recipe_count": 19
    },
    {
        "ingredient_name": "Cauliflower floret",
        "ingredient_slug": "cauliflower-floret",
        "recipe_count": 19
    },
    {
        "ingredient_name": "Buffalo sauce",
        "ingredient_slug": "buffalo-sauce",
        "recipe_count": 19
    },
    {
        "ingredient_name": "Minced garlic",
        "ingredient_slug": "minced-garlic",
        "recipe_count": 18
    },
    {
        "ingredient_name": "Lime",
        "ingredient_slug": "lime",
        "recipe_count": 18
    },
    {
        "ingredient_name": "White wine vinegar",
        "ingredient_slug": "white-wine-vinegar",
        "recipe_count": 18
    },
    {
        "ingredient_name": "Chicken  breast",
        "ingredient_slug": "chicken-breast",
        "recipe_count": 18
    },
    {
        "ingredient_name": "Olive oil",
        "ingredient_slug": "olive-oil",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Wonton wrapper",
        "ingredient_slug": "wonton-wrapper",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Rice wine vinegar",
        "ingredient_slug": "rice-wine-vinegar",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Sausage",
        "ingredient_slug": "sausage",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Half and half",
        "ingredient_slug": "half-and-half",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Dried oregano",
        "ingredient_slug": "dried-oregano",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Truwhip",
        "ingredient_slug": "truwhip",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Chicken breast",
        "ingredient_slug": "chicken-breast",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Beef broth",
        "ingredient_slug": "beef-broth",
        "recipe_count": 17
    },
    {
        "ingredient_name": "Cranberry",
        "ingredient_slug": "cranberry",
        "recipe_count": 16
    },
    {
        "ingredient_name": "Pineapple",
        "ingredient_slug": "pineapple",
        "recipe_count": 16
    },
    {
        "ingredient_name": "White vinegar",
        "ingredient_slug": "white-vinegar",
        "recipe_count": 16
    },
    {
        "ingredient_name": "Pea",
        "ingredient_slug": "pea",
        "recipe_count": 16
    },
    {
        "ingredient_name": "Bbq sauce",
        "ingredient_slug": "bbq-sauce",
        "recipe_count": 16
    },
    {
        "ingredient_name": "Mini chocolate chip",
        "ingredient_slug": "mini-chocolate-chip",
        "recipe_count": 16
    },
    {
        "ingredient_name": "Kidney bean",
        "ingredient_slug": "kidney-bean",
        "recipe_count": 16
    },
    {
        "ingredient_name": "Butternut squash",
        "ingredient_slug": "butternut-squash",
        "recipe_count": 16
    },
    {
        "ingredient_name": "Coconut extract",
        "ingredient_slug": "coconut-extract",
        "recipe_count": 15
    },
    {
        "ingredient_name": "Bread crumb",
        "ingredient_slug": "bread-crumb",
        "recipe_count": 15
    },
    {
        "ingredient_name": "Ground ginger",
        "ingredient_slug": "ground-ginger",
        "recipe_count": 15
    },
    {
        "ingredient_name": "Lemon zest",
        "ingredient_slug": "lemon-zest",
        "recipe_count": 15
    },
    {
        "ingredient_name": "Bread",
        "ingredient_slug": "bread",
        "recipe_count": 15
    },
    {
        "ingredient_name": "Red pepper flake",
        "ingredient_slug": "red-pepper-flake",
        "recipe_count": 14
    },
    {
        "ingredient_name": "Liquid egg white",
        "ingredient_slug": "liquid-egg-white",
        "recipe_count": 14
    },
    {
        "ingredient_name": "Milk of choice",
        "ingredient_slug": "milk-of-choice",
        "recipe_count": 14
    },
    {
        "ingredient_name": "Crushed tomato",
        "ingredient_slug": "crushed-tomato",
        "recipe_count": 14
    },
    {
        "ingredient_name": "Panko bread crumb",
        "ingredient_slug": "panko-bread-crumb",
        "recipe_count": 14
    },
    {
        "ingredient_name": "Artichoke heart",
        "ingredient_slug": "artichoke-heart",
        "recipe_count": 13
    },
    {
        "ingredient_name": "Green chile",
        "ingredient_slug": "green-chile",
        "recipe_count": 13
    },
    {
        "ingredient_name": "Taco sauce",
        "ingredient_slug": "taco-sauce",
        "recipe_count": 13
    },
    {
        "ingredient_name": "Shrimp",
        "ingredient_slug": "shrimp",
        "recipe_count": 13
    },
    {
        "ingredient_name": "Brown rice",
        "ingredient_slug": "brown-rice",
        "recipe_count": 13
    },
    {
        "ingredient_name": "White chocolate chip",
        "ingredient_slug": "white-chocolate-chip",
        "recipe_count": 13
    },
    {
        "ingredient_name": "Jalapeño",
        "ingredient_slug": "jalapeno",
        "recipe_count": 13
    },
    {
        "ingredient_name": "Lasagna noodle",
        "ingredient_slug": "lasagna-noodle",
        "recipe_count": 13
    },
    {
        "ingredient_name": "English cucumber",
        "ingredient_slug": "english-cucumber",
        "recipe_count": 13
    },
    {
        "ingredient_name": "Summer squash",
        "ingredient_slug": "summer-squash",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Turkey pepperoni",
        "ingredient_slug": "turkey-pepperoni",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Unsweetened applesauce",
        "ingredient_slug": "unsweetened-applesauce",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Vanilla",
        "ingredient_slug": "vanilla",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Protein powder",
        "ingredient_slug": "protein-power",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Arrowroot powder",
        "ingredient_slug": "arrowroot-powder",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Onion powder",
        "ingredient_slug": "onion-power",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Raspberry",
        "ingredient_slug": "raspberry",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Orange",
        "ingredient_slug": "orange",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Baby spinach",
        "ingredient_slug": "baby-spinach",
        "recipe_count": 12
    },
    {
        "ingredient_name": "Turkey breast",
        "ingredient_slug": "turkey-breast",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Coconut",
        "ingredient_slug": "coconut",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Pasta sauce",
        "ingredient_slug": "pasta-sauce",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Asparagus",
        "ingredient_slug": "asparagus",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Ground cumin",
        "ingredient_slug": "ground-cumin",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Pork tenderloin",
        "ingredient_slug": "pork-tenderloin",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Gallic powder",
        "ingredient_slug": "gallic-powder",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Cheddar cheese",
        "ingredient_slug": "cheddar-cheese",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Coleslaw mix",
        "ingredient_slug": "coleslaw-mix",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Applesauce",
        "ingredient_slug": "applesauce",
        "recipe_count": 11
    },
    {
        "ingredient_name": "Orange juice",
        "ingredient_slug": "orange-juice",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Taco seaoning",
        "ingredient_slug": "taco-seaoning",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Swiss cheese",
        "ingredient_slug": "swiss-cheese",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Brussels sprout",
        "ingredient_slug": "brussels-sprout",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Dark chocolate chip",
        "ingredient_slug": "dark-chocolate-chip",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Corn tortilla",
        "ingredient_slug": "corn-tortilla",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Greek  yogurt cream cheese",
        "ingredient_slug": "greek-yogurt-cream-cheese",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Greek yogurt cream cheese",
        "ingredient_slug": "greek-yogurt-cream-cheese",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Green chilies",
        "ingredient_slug": "green-chilies",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Smoked paprika",
        "ingredient_slug": "smoked-paprika",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Chicken tender",
        "ingredient_slug": "chicken-tender",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Ham",
        "ingredient_slug": "ham",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Shredded mozzarella",
        "ingredient_slug": "shredded-mozzarella",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Hot sauce",
        "ingredient_slug": "hot-sauce",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Dry pasta",
        "ingredient_slug": "dry-pasta",
        "recipe_count": 10
    },
    {
        "ingredient_name": "Yellow squash",
        "ingredient_slug": "yellow-squash",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Red food coloring",
        "ingredient_slug": "red-food-coloring",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Almond milk",
        "ingredient_slug": "almond-milk",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Lean ground chicken",
        "ingredient_slug": "lean-ground-chicken",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Diced jalapeño",
        "ingredient_slug": "diced-jalapeno",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Panko breadcrumb",
        "ingredient_slug": "panko-breadcrumb",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Sweetener of choice",
        "ingredient_slug": "sweetener-of-choice",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Dried thyme",
        "ingredient_slug": "dried-thyme",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Cabbage",
        "ingredient_slug": "cabbage",
        "recipe_count": 9
    },
    {
        "ingredient_name": "Feta",
        "ingredient_slug": "feta",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Dried parsely",
        "ingredient_slug": "dried-parsely",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Caramel extract",
        "ingredient_slug": "caramel-extract",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Strawberry jam",
        "ingredient_slug": "strawberry-jam",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Sriracha",
        "ingredient_slug": "sriracha",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Turkey",
        "ingredient_slug": "turkey",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Chicken sausage",
        "ingredient_slug": "chicken-sausage",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Iceberg lettuce",
        "ingredient_slug": "iceberg-lettuce",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Fresh basil",
        "ingredient_slug": "fresh-basil",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Egg roll wrapper",
        "ingredient_slug": "egg-roll-wrapper",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Everything bagel seasoning",
        "ingredient_slug": "everything-bagel-seasoning",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Mayonnaise",
        "ingredient_slug": "mayonnaise",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Diced green chilies",
        "ingredient_slug": "diced-green-chilies",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Dried basil",
        "ingredient_slug": "dried-basil",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Vinegar",
        "ingredient_slug": "vinegar",
        "recipe_count": 8
    },
    {
        "ingredient_name": "Instant coffee",
        "ingredient_slug": "instant-coffee",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Peppermint candy",
        "ingredient_slug": "peppermint-candy",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Pita bread",
        "ingredient_slug": "pita-bread",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Poppyseed",
        "ingredient_slug": "poppyseed",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Rice",
        "ingredient_slug": "rice",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Rum extract",
        "ingredient_slug": "rum-extract",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Broth",
        "ingredient_slug": "broth",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Salsa verde",
        "ingredient_slug": "salsa-verde",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Xanthan gum",
        "ingredient_slug": "xanthan-gum",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Yellow bell pepper",
        "ingredient_slug": "yellow-bell-pepper",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Baby potato",
        "ingredient_slug": "baby-potato",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Grated parmesan cheese",
        "ingredient_slug": "grated-parmesan-cheese",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Green bean",
        "ingredient_slug": "green-bean",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Clove",
        "ingredient_slug": "clove",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Nuts",
        "ingredient_slug": "nut",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Cold water",
        "ingredient_slug": "cold-water",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Berries",
        "ingredient_slug": "berries",
        "recipe_count": 7
    },
    {
        "ingredient_name": "Chocolate cookies",
        "ingredient_slug": "chocolate-cookie",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Chive",
        "ingredient_slug": "chive",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Pine nut",
        "ingredient_slug": "pine-nut",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Scallion",
        "ingredient_slug": "scallion",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Pineapple chunk",
        "ingredient_slug": "pineapple-chunk",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Mini marshmallow",
        "ingredient_slug": "mini-marshmallow",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Ground meat",
        "ingredient_slug": "ground-meat",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Teriyaki sauce",
        "ingredient_slug": "teriyaki-sauce",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Pita",
        "ingredient_slug": "pita",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Buttermilk",
        "ingredient_slug": "buttermilk",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Peach",
        "ingredient_slug": "peach",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Plain greek yogurt",
        "ingredient_slug": "plain-greek-yogurt",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Apple pie spice",
        "ingredient_slug": "apple-pie-spice",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Red pepper",
        "ingredient_slug": "red-pepper",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Salmon",
        "ingredient_slug": "salmon",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Salmon filet",
        "ingredient_slug": "salmon-filet",
        "recipe_count": 6
    },
    {
        "ingredient_name": "Berries of choice",
        "ingredient_slug": "berries-of-choice",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Dill pickle",
        "ingredient_slug": "dill-pickle",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Cooked chicken",
        "ingredient_slug": "cooked-chicken",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Fresh spinach",
        "ingredient_slug": "fresh-spinach",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Black pepper",
        "ingredient_slug": "black-pepper",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Egg yolk",
        "ingredient_slug": "egg-yolk",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Rainbow sprinkles",
        "ingredient_slug": "rainbow-sprinkle",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Walnut",
        "ingredient_slug": "walnut",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Chicken tenderloin",
        "ingredient_slug": "chicken-tenderloin",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Ground cinnamon",
        "ingredient_slug": "ground-cinnamon",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Parsnip",
        "ingredient_slug": "parsnip",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Beef roast",
        "ingredient_slug": "beef-roast",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Dry ginger",
        "ingredient_slug": "dry-ginger",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Guacamole",
        "ingredient_slug": "guacamole",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Stevia",
        "ingredient_slug": "stevia",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Coffee",
        "ingredient_slug": "coffee",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Molasses",
        "ingredient_slug": "molasses",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Roasted red bell pepper",
        "ingredient_slug": "roasted-red-bell-pepper",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Salt & pepper",
        "ingredient_slug": "salt-and-pepper",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Large egg",
        "ingredient_slug": "large-egg",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Powdered sugar",
        "ingredient_slug": "powdered-sugar",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Mozzarella cheese, shredded",
        "ingredient_slug": "mozzarella-cheese-shredded",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Ginger powder",
        "ingredient_slug": "ginger-powder",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Mustard powder",
        "ingredient_slug": "mustard-powder",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Gingersnap",
        "ingredient_slug": "gingersnap",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Unsweetened almond milk",
        "ingredient_slug": "unsweetened-almond-milk",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Cream of tartar",
        "ingredient_slug": "cream-of-tartar",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Oil",
        "ingredient_slug": "oil",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Pickle",
        "ingredient_slug": "pickle",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Organic bbq sauce",
        "ingredient_slug": "organic-bbq-sauce",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Lime juice",
        "ingredient_slug": "lime-juice",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Organic mayo",
        "ingredient_slug": "organic-mayo",
        "recipe_count": 5
    },
    {
        "ingredient_name": "Lentil",
        "ingredient_slug": "lentil",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Parmesan cheese",
        "ingredient_slug": "parmesan-cheese",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Instant coffee powder",
        "ingredient_slug": "instant-coffee-powder",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Roasted red pepper",
        "ingredient_slug": "roasted-red-pepper",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Sea salt",
        "ingredient_slug": "sea-salt",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Avocado mayonnaise",
        "ingredient_slug": "avocado-mayonnaise",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Edamame",
        "ingredient_slug": "edamame",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Sandwich thin",
        "ingredient_slug": "sandwich-thin",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Milk",
        "ingredient_slug": "milk",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Pecans, chopped",
        "ingredient_slug": "pecans-chopped",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Frozen spinach",
        "ingredient_slug": "frozen-spinach",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Fresh dill",
        "ingredient_slug": "fresh-dill",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Toppings of choice",
        "ingredient_slug": "toppings-of-choice",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Cooked chicken breast",
        "ingredient_slug": "cooked-chicken-breast",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Butterscotch chip",
        "ingredient_slug": "butterscotch-chip",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Broccoli slaw",
        "ingredient_slug": "broccoli-slaw",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Sweetener or choice",
        "ingredient_slug": "sweetener-or-choice",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Corn starch",
        "ingredient_slug": "corn-starch",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Blue cheese",
        "ingredient_slug": "blue-cheese",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Frozen chopped spinach",
        "ingredient_slug": "frozen-chopped-spinach",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Relish",
        "ingredient_slug": "relish",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Cheese of choice",
        "ingredient_slug": "cheese-of-choice",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Petite diced tomato",
        "ingredient_slug": "petite-diced-tomato",
        "recipe_count": 4
    },
    {
        "ingredient_name": "M&m'",
        "ingredient_slug": "mandm'",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Pico de gallo",
        "ingredient_slug": "pico-de-gallo",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Ground chicken",
        "ingredient_slug": "ground-chicken",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Orange bell pepper",
        "ingredient_slug": "orange-bell-pepper",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Coconut flake",
        "ingredient_slug": "coconut-flake",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Ground clove",
        "ingredient_slug": "ground-clove",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Mexican Cheese",
        "ingredient_slug": "mexican-cheese",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Pork chop",
        "ingredient_slug": "pork-chop",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Celery stalk",
        "ingredient_slug": "celery-stalk",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Mandarin orange",
        "ingredient_slug": "mandarin-orange",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Cornmeal",
        "ingredient_slug": "cornmeal",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Frozen peas and carrot",
        "ingredient_slug": "frozen-peas-and-carrot",
        "recipe_count": 4
    },
    {
        "ingredient_name": "White onion",
        "ingredient_slug": "white-onion",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Lean ground turkey",
        "ingredient_slug": "lean-ground-turkey",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Diced pineapple",
        "ingredient_slug": "diced-pineapple",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Shredded mozzarella cheese",
        "ingredient_slug": "shredded-mozzarella-cheese",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Mini bell pepper",
        "ingredient_slug": "mini-bell-pepper",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Pretzel",
        "ingredient_slug": "pretzel",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Sourdough starter",
        "ingredient_slug": "sourdough-starter",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Beef stock",
        "ingredient_slug": "beef-stock",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Water chestnut",
        "ingredient_slug": "water-chestnut",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Mini m&m'",
        "ingredient_slug": "mini-mandm'",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Turmeric",
        "ingredient_slug": "turmeric",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Dijon mustard",
        "ingredient_slug": "dijon-mustard",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Crumbled feta",
        "ingredient_slug": "crumbled-feta",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Watermelon",
        "ingredient_slug": "watermelon",
        "recipe_count": 4
    },
    {
        "ingredient_name": "Chocolate syrup",
        "ingredient_slug": "chocolate-syrup",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Tomato puree",
        "ingredient_slug": "tomato-puree",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Salad dressing",
        "ingredient_slug": "salad-dressing",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Warm water",
        "ingredient_slug": "warm-water",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Romaine",
        "ingredient_slug": "romaine",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Dried ginger",
        "ingredient_slug": "dried-ginger",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Riced cauliflower",
        "ingredient_slug": "riced-cauliflower",
        "recipe_count": 3
    },
    {
        "ingredient_name": "1\/3 less-fat cream cheese",
        "ingredient_slug": "13-less-fat-cream-cheese",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Lime zest",
        "ingredient_slug": "lime-zest",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Dried dill",
        "ingredient_slug": "dried-dill",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Geek yogurt",
        "ingredient_slug": "geek-yogurt",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Apple cider",
        "ingredient_slug": "apple-cider",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Sunflower seed",
        "ingredient_slug": "sunflower-seed",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Red enchilada sauce",
        "ingredient_slug": "red-enchilada-sauce",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Bacon bit",
        "ingredient_slug": "bacon-bit",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Fresh parsely",
        "ingredient_slug": "fresh-parsely",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Breadcrumb",
        "ingredient_slug": "breadcrumb",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Strong brewed coffee",
        "ingredient_slug": "strong-brewed-coffee",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Raspberry jam",
        "ingredient_slug": "raspberry-jam",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Meatballs",
        "ingredient_slug": "meatballs",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Ranch salad dressing & seasoning mix",
        "ingredient_slug": "ranch-salad-dressing-and-seasoning-mix",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Minced fresh ginger",
        "ingredient_slug": "minced-fresh-ginger",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Minced ginger",
        "ingredient_slug": "minced-ginger",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Coconut milk",
        "ingredient_slug": "coconut-milk",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Purple onion",
        "ingredient_slug": "purple-onion",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Crumbled feta cheese",
        "ingredient_slug": "crumbled-feta-cheese",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Fish filet",
        "ingredient_slug": "fish-filet",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Mixed vegetable",
        "ingredient_slug": "mixed-vegetable",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Burger bun",
        "ingredient_slug": "burger-bun",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Cold brew coffee",
        "ingredient_slug": "cold-brew-coffee",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Dill pickle juice",
        "ingredient_slug": "dill-pickle-juice",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Creamy peanut butter",
        "ingredient_slug": "creamy-peanut-butter",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Navy bean",
        "ingredient_slug": "navy-bean",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Creamed corn",
        "ingredient_slug": "creamed-corn",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Grated parmesan",
        "ingredient_slug": "grated-parmesan",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Flank steak",
        "ingredient_slug": "flank-steak",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Pizza  sauce",
        "ingredient_slug": "pizza-sauce",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Boneless skinless chicken breast",
        "ingredient_slug": "boneless-skinless-chicken-breast",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Flatbread",
        "ingredient_slug": "flatbread",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Everything seasoning",
        "ingredient_slug": "everything-seasoning",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Greek dressing",
        "ingredient_slug": "greek-dressing",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Peanut butter chip",
        "ingredient_slug": "peanut-butter-chip",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Cayenne pepper",
        "ingredient_slug": "cayenne-pepper",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Pecan half",
        "ingredient_slug": "pecan-half",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Cookies of choice",
        "ingredient_slug": "cookies-of-choice",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Corn meal",
        "ingredient_slug": "corn-meal",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Candy cane",
        "ingredient_slug": "candy-cane",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Sparkling water",
        "ingredient_slug": "sparkling-water",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Green food coloring",
        "ingredient_slug": "green-food-coloring",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Avocado",
        "ingredient_slug": "avocado",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Diced tomatoes and green chiles",
        "ingredient_slug": "diced-tomatoes-and-green-chiles",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Ground turkey",
        "ingredient_slug": "ground-turkey",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Baby carrots",
        "ingredient_slug": "baby-carrot",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Brown rice cereal",
        "ingredient_slug": "brown-rice-cereal",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Shredded chicken",
        "ingredient_slug": "shredded-chicken",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Fresh blueberries",
        "ingredient_slug": "fresh-blueberries",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Shredded cheddar cheese",
        "ingredient_slug": "shredded-cheddar-cheese",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Hot water",
        "ingredient_slug": "hot-water",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Yellow mustard",
        "ingredient_slug": "yellow-mustard",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Ice cream",
        "ingredient_slug": "ice-cream",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Turkey sausage",
        "ingredient_slug": "turkey-sausage",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Sesame oil",
        "ingredient_slug": "sesame-oil",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Organic flour",
        "ingredient_slug": "organic-flour",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Chipotles in adobo sauce",
        "ingredient_slug": "chipotles-in-adobo-sauce",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Chocolate",
        "ingredient_slug": "chocolate",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Fajita seasoning",
        "ingredient_slug": "fajita-seasoning",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Cajun seasoning",
        "ingredient_slug": "cajun-seasoning",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Coconut sugar",
        "ingredient_slug": "coconut-sugar",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Bean sprout",
        "ingredient_slug": "bean-sprout",
        "recipe_count": 3
    },
    {
        "ingredient_name": "Shredded carrot",
        "ingredient_slug": "shredded-carrot",
        "recipe_count": 2
    },
    {
        "ingredient_name": "White cheddar cheese",
        "ingredient_slug": "white-cheddar-cheese",
        "recipe_count": 2
    },
    {
        "ingredient_name": "White beans, drained and rinsed",
        "ingredient_slug": "white-beans-drained-and-rinsed",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Whipped topping",
        "ingredient_slug": "whipped-topping",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Cajun",
        "ingredient_slug": "cajun",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Zuchinni",
        "ingredient_slug": "zuchinni",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Pasta noodle",
        "ingredient_slug": "pasta-noodle",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Spaghetti noodles",
        "ingredient_slug": "sphagetti-noodles",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Baby red potato",
        "ingredient_slug": "baby-red-potato",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Chili flakes",
        "ingredient_slug": "chili flakes",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Vegetable broth",
        "ingredient_slug": "vegetable-broth",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Fish sauce",
        "ingredient_slug": "fish-sauce",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Butterscotch baking chip",
        "ingredient_slug": "butterscotch-baking-chip",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Chocolate candy",
        "ingredient_slug": "chocolate candy",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Corn on the cob",
        "ingredient_slug": "corn-on-the-cob",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Fresh cilantro",
        "ingredient_slug": "fresh-cilantro",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Course sea salt",
        "ingredient_slug": "course-sea-salt",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Fresh cranberry",
        "ingredient_slug": "fresh-cranberry",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Fresh green bean",
        "ingredient_slug": "fresh-green-bean",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Fresh mozzarella",
        "ingredient_slug": "fresh-mozzarella",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Fresh parsley",
        "ingredient_slug": "fresh-parsley",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Unsweetened shredded coconut",
        "ingredient_slug": "unsweetened-shredded-coconut",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Unsweetened coconut flake",
        "ingredient_slug": "unsweetened-coconut-flake",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Boneless skinless chicken thighs",
        "ingredient_slug": "boneless-skinless-chicken-thighs",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Unsweetened coconut",
        "ingredient_slug": "unsweetened-coconut",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Frozen broccoli floret",
        "ingredient_slug": "frozen-broccoli-floret",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Unflavored gelatin",
        "ingredient_slug": "unflavored-gelatin",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Baking chocolate",
        "ingredient_slug": "baking-chocolate",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Uncooked wild rice",
        "ingredient_slug": "uncooked-wild-rice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Frozen diced onion",
        "ingredient_slug": "frozen-diced-onion",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Bun",
        "ingredient_slug": "bun",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Banana peppers",
        "ingredient_slug": "banana-peppers",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Organic bread flour",
        "ingredient_slug": "organic-bread-flour",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Provolone cheese",
        "ingredient_slug": "provolone-cheese",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Tuna",
        "ingredient_slug": "tuna",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Tortilla chip",
        "ingredient_slug": "tortilla-chip",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Butter",
        "ingredient_slug": "butter",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Buffalo wing sauce",
        "ingredient_slug": "buffalo-wing-sauce",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Garam masala spice",
        "ingredient_slug": "garam-masala-spice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Thyme",
        "ingredient_slug": "thmye",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Sweetener of choice, to taste",
        "ingredient_slug": "sweetener-of-choice-to-taste",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Dried cranberry",
        "ingredient_slug": "dried-cranberry",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Dressing of choice",
        "ingredient_slug": "dressing-of-choice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Golden sweet potato",
        "ingredient_slug": "golden-sweet-potato",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Strip steak",
        "ingredient_slug": "strip-steak",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Brussel sprout",
        "ingredient_slug": "brussel-sprout",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Graham cracker pie crust",
        "ingredient_slug": "graham-cracker-pie-crust",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Strawberry jelly",
        "ingredient_slug": "strawberry-jelly",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Granola",
        "ingredient_slug": "granola",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Chicken italian sausage",
        "ingredient_slug": "chicken-italian-sausage",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Grape",
        "ingredient_slug": "grape",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Dill pickle chip",
        "ingredient_slug": "dill-pickle-chip",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Pasta of choice",
        "ingredient_slug": "pasta-of-choice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Great northern bean",
        "ingredient_slug": "great-northern-bean",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Steak",
        "ingredient_slug": "steak",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Sriracha sauce",
        "ingredient_slug": "sriracha-sauce",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Squash",
        "ingredient_slug": "squash",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Snow pea",
        "ingredient_slug": "snow-pea",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Sliced black olive",
        "ingredient_slug": "sliced-black-olive",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Diced tomatoes with green chiles",
        "ingredient_slug": "diced-tomatoes-with-green-chiles",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Sirloin steak",
        "ingredient_slug": "sirloin-steak",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Chile powder",
        "ingredient_slug": "chile-powder",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Bay leaf",
        "ingredient_slug": "bay-leaf",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Bean",
        "ingredient_slug": "bean",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Shredded cheese of choice",
        "ingredient_slug": "shredded-cheese-of-choice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Shredded cheddar",
        "ingredient_slug": "shredded-cheddar",
        "recipe_count": 2
    },
    {
        "ingredient_name": "English muffin",
        "ingredient_slug": "english-muffin",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Shaved parmesan cheese",
        "ingredient_slug": "shaved-parmesan-cheese",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Diced green chili",
        "ingredient_slug": "diced-green-chili",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Jelly",
        "ingredient_slug": "jelly",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Jumbo pasta shells",
        "ingredient_slug": "jumbo-pasta-shells",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Asian vegetable blend",
        "ingredient_slug": "asian-vegetable-blend",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Kale",
        "ingredient_slug": "kale",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Salmon fillet",
        "ingredient_slug": "salmon-fillet",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Chocolate cream cookies",
        "ingredient_slug": "chocolate-cream-cookies",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Lean chicken sausage",
        "ingredient_slug": "lean-chicken-sausage",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Sage",
        "ingredient_slug": "sage",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Lean pork tenderloin",
        "ingredient_slug": "lean-pork-tenderloin",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Romaine leaf",
        "ingredient_slug": "romaine-leaf",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Roasted bell pepper",
        "ingredient_slug": "roasted-bell-pepper",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Cinnmaon",
        "ingredient_slug": "cinnmaon",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Light coconut milk",
        "ingredient_slug": "light-coconut-milk",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Rice chex cereal",
        "ingredient_slug": "rice-chex-cereal",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Rice cereal",
        "ingredient_slug": "rice-cereal",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Allspice",
        "ingredient_slug": "allspice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Peanut butter cup",
        "ingredient_slug": "peanut-butter-cup",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Low carb wrap",
        "ingredient_slug": "low-carb-wrap",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Asiago cheese",
        "ingredient_slug": "asiago-cheese",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Low fat cottage cheese",
        "ingredient_slug": "low-fat-cottage-cheese",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Red pepper hummus",
        "ingredient_slug": "red-pepper-hummus",
        "recipe_count": 2
    },
    {
        "ingredient_name": "M&m",
        "ingredient_slug": "mandm",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Coconut amino",
        "ingredient_slug": "coconut-amino",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Curry powder",
        "ingredient_slug": "curry-powder",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Red cabbage",
        "ingredient_slug": "red-cabbage",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Marshmallow",
        "ingredient_slug": "marshmallow",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Matchstick carrot",
        "ingredient_slug": "matchstick-carrot",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Medium pasta shell",
        "ingredient_slug": "medium-pasta-shell",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Cumin powder",
        "ingredient_slug": "cumin-powder",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Quinoa, rinsed well",
        "ingredient_slug": "quinoa-rinsed-well",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Mini cucumber",
        "ingredient_slug": "mini-cucumber",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Miniature marshmallow",
        "ingredient_slug": "miniature-marshmallow",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Mixed salad green",
        "ingredient_slug": "mixed-salad-green",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Bran cereal",
        "ingredient_slug": "bran-cereal",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Cojita cheese",
        "ingredient_slug": "cojita-cheese",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Crumbled bacon",
        "ingredient_slug": "crumbled-bacon",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Pasta shells",
        "ingredient_slug": "pasta-shells",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Popcorn kernel",
        "ingredient_slug": "popcorn-kernel",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Plain low fat greek yogurt",
        "ingredient_slug": "plain-low-fat-greek-yogurt",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Artichoke",
        "ingredient_slug": "artichoke",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Pickle juice",
        "ingredient_slug": "pickle-juice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Orange zest",
        "ingredient_slug": "orange-zest",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Cotija cheese",
        "ingredient_slug": "cotija-cheese",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Organic pepperoni",
        "ingredient_slug": "organic-pepperoni",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Arrowroot flour",
        "ingredient_slug": "arrowroot-flour",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Whole chicken",
        "ingredient_slug": "whole-chicken",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Whole garlic clove",
        "ingredient_slug": "whole-garlic-clove",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Candy",
        "ingredient_slug": "candy",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Cajun spice",
        "ingredient_slug": "cajun-spice",
        "recipe_count": 2
    },
    {
        "ingredient_name": "Wing sauce",
        "ingredient_slug": "wing-sauce",
        "recipe_count": 2
    }
]