import React from 'react'

import moment from 'moment'
import ReactImageFallback from "react-image-fallback";
import AvatarIcon from '../../web/images/MemberAvartar';


const Avatar = ({
  src,
  name,
  memberSince
}) => {
  return (
    <div className="membership-Avatar">
      <figure>
        <ReactImageFallback
          src={src}
          fallbackImage={<AvatarIcon />}
          className="img_fluid"
        />
      </figure>
      <h3>{name}</h3>
      <span>
        Member since {moment(`${memberSince}`).format('YYYY')}
      </span>
    </div>
  )
}

export default Avatar