import {
  UNIFIED_REGISTER_COUPON_ACTIVATE,
  UNIFIED_REGISTER_COUPON_ACTIVATE_SUCCESS,
  UNIFIED_REGISTER_COUPON_ACTIVATE_FAILURE,
  UNIFIED_REGISTER_COUPON_REMOVE,
} from "./actionTypes";

const initiate = () => ({
  type: UNIFIED_REGISTER_COUPON_ACTIVATE,
  payload: {},
});

const success = (coupon) => ({
  type: UNIFIED_REGISTER_COUPON_ACTIVATE_SUCCESS,
  payload: {
    coupon,
  },
});

const failure = () => ({
  type: UNIFIED_REGISTER_COUPON_ACTIVATE_FAILURE,
  payload: {},
});

const remove = () => ({
  type: UNIFIED_REGISTER_COUPON_REMOVE,
  payload: {},
});

export default {
  initiate,
  success,
  failure,
  remove,
};
