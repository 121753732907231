import React from "react";

import map from "lodash/map";

const VerticalListWithTitle = ({ title, list, history, onClose }) => {
  return (
    <ul className="vertical-navdropdown-with-title">
      <li className="title">{title}</li>
      {map(list, item => (
        <li>
          <a
            href={item.href}
            onClick={e => {
              e.preventDefault();
              history.push(item.href);
              onClose();
            }}
          >
            {item.title}
          </a>
        </li>
      ))}
    </ul>
  );
};

VerticalListWithTitle.defaultProps = {
  title: "Workout",
  list: [
    {
      href: "/recipes",
      title: "Most Popular"
    },
    {
      href: "/recipes",
      title: "BLD Plans"
    },
    {
      href: "/recipes",
      title: "Gluten Free"
    },
    {
      href: "/recipes",
      title: "6 Ingredient"
    }
  ]
};

export default VerticalListWithTitle;
