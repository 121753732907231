import React from 'react';
import moment from 'moment';
import Cover from '../../images/videoCover.png';
import VideoPlayIcon from '../../images/VideoPlayIcon';
import { upperCase, capitalize } from 'lodash';

const KatiesCornerCard = ({
    title,
    date,
    introduction,
    banner,
    navigateTo,
    slug,
    categories,
    navigationType
}) => {
    let updatedBanner = (!banner || banner === 'some banner') ? Cover : banner;
    return (
        <li className='katies-corner-list-card list-video-card'>
            <a href={`/nourish/${navigationType}/${slug}`} onClick={(e) => {
                e.preventDefault();
                navigateTo(`/nourish/${navigationType}/${slug}`);
            }}>
                <figure className='img-wrapper' style={{
                    backgroundImage: "url(" + updatedBanner + ")",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <VideoPlayIcon />
                </figure>
                <div className='card-details'>
                    <p className='card-title hover-underline-animation'>
                        {capitalize(title)}<nobr />{(introduction.length > 0 ? ': ' : '') + capitalize(introduction)}
                    </p>
                </div>
            </a>
        </li>
    )
}


export default KatiesCornerCard
