import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from '../../helpers/toJS';

import {
  appEssentialFetch,
  createFavourite,
  deleteFavourite,
  addSentryError,
  changeFooterStatus
} from "../../actions";

import {
  getBoards,
  getFetchStatus,
} from "../../selectors/videos";

import Index from "./components";

const mapStateToProps = state => ({
  boards: getBoards(state),
  fetchStatus: getFetchStatus(state),
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
  changeFooterStatus: bindActionCreators(changeFooterStatus, dispatch)
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(Index));




