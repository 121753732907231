import React, { useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import cx from "classnames";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import findIndex from "lodash/findIndex";
import toJS from "../../../helpers/toJS";
import Spinner from "react-spinner-material";
import { getUserDetails } from "../../../selectors";
import {
  showToastMessage,
  loginUser,
  removeRenewSubscription,
  addSentryError,
} from "../../../actions";

import isNil from "lodash/isNil";
import filter from "lodash/filter";
import axios from "axios";

import { PayPalButton } from "react-paypal-button-v2";
import CardElements from "../../common/paymentInput";
import Radiobox from "../../common/radiobox";

import { auth, shop } from "../../../helpers/api";
import pushToSentry from "../../../helpers/pushToSentry";
import Selector from "../../common/selector";
import CustomInput from "../../common/customInput";
import { themeTextRed } from "../../../helpers/theme";

const initialState = {
  paymentType: "stripe",
  renewAmount: "0",
  plans: [],
  grandfatheredPlan: {},
  isGrandfatheredPlanChosen: false,
  planId: null,
  billingPlan: "premium",
  billingDuration: 12,
  status: {
    isSubscribing: false,
    isSubscribed: false,
  },
  coupon: {
    isActivating: false,
    isActivationSuccess: false,
    isActivationFailure: false,
    data: {},
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_PAYMENT_TYPE":
      return {
        ...state,
        paymentType: action.payload.paymentType,
      };
    case "SET_PLAN_ID":
      return {
        ...state,
        planId: action.payload.planId,
      };
    case "SELECT_BILLING_PLAN":
      console.log('action ', action.payload.billingPlan)
      if (action.payload.billingPlan === 'grandfathered') {
        return {
          ...state,
          billingPlan: action.payload.billingPlan,
          planId: state.grandfatheredPlan.id
        }
      } else {
        const chosenPlan = filter(state.plans, (plan) => {
          return (
            (action.payload.billingPlan === "premium" &&
              (plan.order === 1 || plan.plan_order === 1)) ||
            (action.payload.billingPlan === "base" &&
              (plan.order === 2 || plan.plan_order === 2)) ||
            (action.payload.billingPlan === "corner" &&
              (plan.order === 3 || plan.plan_order === 3))
          );
        });

        return {
          ...state,
          billingPlan: action.payload.billingPlan,
          planId: chosenPlan[0].id,
        };
      }


    case "SELECT_BILLING_DURATION":
      return {
        ...state,
        billingDuration: action.payload.billingDuration,
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.payload.status,
      };
    case "SET_RENEW_AMOUNT":
      return {
        ...state,
        renewAmount: action.payload.renewAmount,
      };
    case "SET_PLANS":
      return {
        ...state,
        plans: action.payload.plans,
        planId: action.payload.planId || action.payload.plans[0].id, // If user had planid before, if not first plan id
      };
    case "ACTIVATE_COUPON_INITIATE":
      return {
        ...state,
        coupon: {
          isActivating: true,
          isActivationSuccess: false,
          isActivationFailure: false,
        },
      };
    case "ACTIVATE_COUPON_SUCCESS":
      return {
        ...state,
        coupon: {
          isActivating: false,
          isActivationSuccess: true,
          isActivationFailure: false,
          data: action.payload.couponData,
        },
      };
    case "ACTIVATE_COUPON_FAILURE":
      return {
        ...state,
        coupon: {
          isActivating: false,
          isActivationSuccess: false,
          isActivationFailure: true,
        },
      };
    case "REMOVE_COUPON_INITIATE":
      return {
        ...state,
        coupon: {
          isActivating: false,
          isActivationSuccess: false,
          isActivationFailure: false,
        },
      };
    case "ADD_GF_PLAN_TO_LIST":
      return {
        ...state,
        grandfatheredPlan: action.payload.grandfatheredPlan,
        isGrandfatheredPlanChosen: true,
        billingPlan: 'grandfathered',
        planId: action.payload.grandfatheredPlan.id
      }
    default:
      return state;
  }
};

const ReactivateAccount = ({ userDetails, ...props }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [couponCode, setCouponCode] = useState(null);
  console.log('state ', state.grandfatheredPlan)
  useEffect(() => {

    if (isNil(userDetails.reactivateAccountData)) {
      props.history.push("/login");
    } else {
      const userPlan = userDetails.reactivateAccountData.plan;

      axios({
        method: "GET",
        url: auth.getPlans(true),
      })
        .then((response) => {
          const allPlans = response.data;
          const premiumPlanIndex = findIndex(
            allPlans,
            (plan) => plan.order === 1
          );
          dispatch({
            type: "SET_PLANS",
            payload: {
              plans: allPlans,
              planId: allPlans[premiumPlanIndex].id,
            },
          });

          axios({
            method: "GET",
            url: auth.getGrandfatheredPlans(),

            headers: {
              Authorization: `Token token="${localStorage.getItem("token")}"`,
            },
          })
            .then((response) => {
              let pastPlan = null;
              if (response.data?.data?.length > 0) {
                pastPlan = response.data.data[0].plan;
              }

              console.log('response ', response);
              const isPastPlanGrandfathered = allPlans.findIndex((plan) => {
                return plan.monthly_stripe_membership_id === pastPlan.id || plan.annual_stripe_membership_id === pastPlan.id
              }) === -1


              if (isPastPlanGrandfathered) {

                props.showToastMessage(
                  `✅ You are eligible to reactivate with Grandfathered plan`,
                  "success"
                );

                dispatch({
                  type: "ADD_GF_PLAN_TO_LIST",
                  payload: {
                    grandfatheredPlan: {
                      id: pastPlan.id,
                      amount: pastPlan.amount,
                      name: pastPlan.name,
                      duration: `${pastPlan.interval_count} ${pastPlan.interval}`
                    }
                  }
                });
              }
            })
            .catch((e) => {

            });
        })
        .catch((e) => {
          props.showToastMessage(
            `Something went wrong in getting plans!`,
            "error"
          );
          const eventId = pushToSentry(e);
        });
    }
  }, []);

  useEffect(() => {
    if (userDetails.isLoggedIn) {
      props.history.push("/");
      props.removeRenewSubscriptionData();
    }
  }, [userDetails.isLoggedIn]);

  const onActivateCoupon = (e) => {
    e.preventDefault();
    if (state.coupon.isActivationSuccess) {
      dispatch({
        type: "REMOVE_COUPON_INITIATE",
      });
    } else {
      dispatch({
        type: "ACTIVATE_COUPON_INITIATE",
      });
      axios({
        method: "POST",
        url: auth.validatePromo(),
        data: {
          code: couponCode,
        },
        headers: {
          Authorization: `Token token="${localStorage.getItem("token")}"`,
        },
      })
        .then((response) => {
          if (response.data.valid) {
            const percentOff = parseInt(response.data.coupon.percent_off);
            const trialLength = parseInt(response.data.coupon.trialLength);

            if (percentOff > 0 && (!trialLength || trialLength === 0)) {
              props.showToastMessage("✅ Coupon code applied.");
              dispatch({
                type: "ACTIVATE_COUPON_SUCCESS",
                payload: {
                  couponData: response.data.coupon,
                },
              });
            } else {
              props.showToastMessage("❗️Coupon code seems to be invalid");
              dispatch({
                type: "ACTIVATE_COUPON_FAILURE",
              });
            }
          }
        })
        .catch((e) => {
          console.log(e);
          props.showToastMessage("❗️Coupon code seems to be invalid");
          dispatch({
            type: "ACTIVATE_COUPON_FAILURE",
          });
        });
    }
  };

  const onClickSubmitButton = (token, type = "stripe") => {
    if (type === "stripe") {
      dispatch({
        type: "SET_STATUS",
        payload: {
          status: {
            isSubscribing: true,
            isSubscribed: false,
          },
        },
      });

      const stripe_token = token;
      axios({
        method: "PATCH",
        url: shop.renewSubscription(),
        data: {
          user_id: userDetails.reactivateAccountData.id,
          duration: state.billingDuration,
          stripe_token: stripe_token,
          plan_id: state.planId,
          coupon: state.coupon.isActivationSuccess
            ? state.coupon.data.stripe_coupon_code
            : null,
        },
        headers: {
          Authorization: `Token token="${localStorage.getItem("token")}"`,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            props.loginUser(
              userDetails.reactivateAccountData.email,
              userDetails.reactivateAccountData.password
            );
          } else {
            dispatch({
              type: "SET_STATUS",
              payload: {
                status: {
                  isSubscribing: false,
                  isSubscribed: false,
                },
              },
            });
            props.showToastMessage(`Something went wrong!`, "error");
          }
        })
        .catch((e) => {
          props.showToastMessage(`Something went wrong!`, "error");

          dispatch({
            type: "SET_STATUS",
            payload: {
              status: {
                isSubscribing: false,
                isSubscribed: false,
                formError: e.response.data.message,
              },
            },
          });
        });
    } else if (type === "paypal") {
      dispatch({
        type: "SET_STATUS",
        payload: {
          status: {
            isSubscribing: true,
            isSubscribed: false,
          },
        },
      });
      paypal_token = token;

      axios({
        method: "PATCH",
        url: shop.renewSubscription(),
        data: {
          user_id: userDetails.reactivateAccountData.id,
          duration: state.billingDuration,
          paypal_id: paypal_token,
          plan_id: state.planId,
          coupon: state.coupon.isActivationSuccess
            ? state.coupon.data.code
            : null,
        },
      })
        .then((response) => {
          if (response.data.status === "success") {
            dispatch({
              type: "SET_STATUS",
              payload: {
                status: {
                  isSubscribing: false,
                  isSubscribed: true,
                },
              },
            });

            props.loginUser(
              userDetails.reactivateAccountData.email,
              userDetails.reactivateAccountData.password
            );
          } else {
            props.showToastMessage(`Something went wrong!`, "error");
          }
        })
        .catch((e) => {
          pushToSentry(e);
          props.showToastMessage(`Something went wrong!`, "error");
          dispatch({
            type: "SET_STATUS",
            payload: {
              status: {
                isSubscribing: false,
                isSubscribed: false,
                formError: e.message,
              },
            },
          });
        });
    }
  };

  let activePlan;
  let activePrice;
  const basePlanIndex = findIndex(state.plans, (plan) => plan.order === 2);
  const premiumPlanIndex = findIndex(state.plans, (plan) => plan.order === 1);
  const cornerPlanIndex = findIndex(state.plans, (plan) => plan.order === 3);


  const selectedPlan = filter(state.plans, (plan) => {
    return state.planId === plan.id;
  });

  let selectedPlanType;

  let shouldApplyDiscount = true;
  let shouldNeverApplyDiscount = false;
  let earlyBird = false;
  if (state.billingPlan !== 'grandfathered') {
    if (selectedPlan.length > 0) {
      selectedPlanType = null;

      if (selectedPlan[0].order === 1 || selectedPlan[0].order === 1) selectedPlanType = "premium";
      if (selectedPlan[0].order === 2 || selectedPlan[0].order === 2) selectedPlanType = "base";

      activePrice =
        state.billingDuration === 1
          ? selectedPlan[0].monthly_price
          : selectedPlan[0].annual_price;

      // shouldApplyDiscount = !(selectedPlan[0].order === 3 && state.billingDuration === 12);
      // shouldNeverApplyDiscount = (selectedPlan[0].order === 3 && state.billingDuration === 1);
      earlyBird = false;
    }
  } else {
    selectedPlanType = 'grandfathered';
    activePrice = state.grandfatheredPlan.amount
  }

  let discountedPrice = (state.coupon.isActivationSuccess && shouldApplyDiscount && !shouldNeverApplyDiscount)
    ? (activePrice * ((100 - state.coupon.data.percent_off) / 100)).toFixed(2)
    : (shouldNeverApplyDiscount ? activePrice : 0.8 * activePrice);

  let planSelector = [
    {
      title:
        state.plans &&
        state.plans.length > 0 &&
        basePlanIndex >= 0 &&
        state.plans[basePlanIndex].name,
      value: "base",
    },
    {
      title:
        state.plans &&
        state.plans.length > 0 &&
        premiumPlanIndex >= 0 &&
        state.plans[premiumPlanIndex].name,
      value: "premium",
    }
  ]


  if (Object.keys(state.grandfatheredPlan).length > 0) {
    planSelector.push({
      title: state.grandfatheredPlan.name,
      value: 'grandfathered'
    })
  }

  let paymentSelector = [
    {
      title: "Stripe",
      value: "stripe",
    }
  ];


  if (state.billingPlan !== 'grandfathered') {
    paymentSelector.push({
      title: "Paypal",
      value: "paypal",
    })
  }


  return (
    <div className="reactivate">
      <Helmet>
        <title>Reactivate Account | Dashing Dish </title>
        <meta
          name="description"
          content="Dashingdish Reactivate Account page"
        />
      </Helmet>
      <h2>Reactivate your account</h2>
      <div className="reactivate-account">
        <div className="selector">
          <span className="static-value">Plan</span>
          <Selector
            selectorList={planSelector}
            onSelectValue={(value) => {
              dispatch({
                type: "SELECT_BILLING_PLAN",
                payload: {
                  billingPlan: value,
                },
              });
            }}
            value={selectedPlanType}
          />
        </div>
        {
          (selectedPlanType === 'grandfathered') ? (
            <div className="selector">
              <span className="static-value">Duration</span>
              <Selector
                selectorList={[
                  {
                    title: state.grandfatheredPlan.duration,
                    value: state.grandfatheredPlan.duration,
                  }
                ]}
                onSelectValue={(value) => { }}
              />
            </div>
          ) : (
            <div className="selector">
              <span className="static-value">Duration</span>
              <Selector
                selectorList={[
                  {
                    title: "Annually",
                    value: 12,
                  },
                  {
                    title: "Monthly",
                    value: 1,
                  },
                ]}
                onSelectValue={(value) => {
                  dispatch({
                    type: "SELECT_BILLING_DURATION",
                    payload: {
                      billingDuration: parseInt(value),
                    },
                  });
                }}
              />
            </div>
          )
        }
        <div className="selector">
          <span className="static-value">Payment</span>
          <Selector
            selectorList={paymentSelector}
            onSelectValue={(value) => {
              dispatch({
                type: "SET_PAYMENT_TYPE",
                payload: {
                  paymentType: value,
                },
              });
            }}
          />
        </div>
        <div
          className="password-field"
          style={{ width: "100%", display: "flex", alignItems: "center" }}
        >
          <CustomInput
            type="coupon"
            name="coupon"
            onChange={(event) => {
              setCouponCode(event.target.value);
            }}
            placeholder="Enter Coupon code"
            value={couponCode}
            className="error"
            disabled={state.coupon.isActivationSuccess}
            label="Coupon"
            style={{ width: "100%" }}
          />
          <div
            className="show-password"
            onClick={onActivateCoupon}
            style={{ top: "40px" }}
          >
            <span>
              {!state.coupon.isActivationSuccess ? "Apply" : "Remove"}
            </span>
          </div>
        </div>

        <div className="due-wrapper">
          <span>Total due today</span>
          <span className={cx({ cross: state.coupon.isActivationSuccess })}>
            {!isNil(activePrice) ? `$${parseFloat(activePrice) / 100}` : null}
          </span>
        </div>
        {(state.coupon.isActivationSuccess) ? (
          <div className="due-wrapper coupon-wrapper">
            <span className="applied-coupon">
              Coupon applied for the first billing cycle
            </span>
            <span>
              {!isNil(discountedPrice)
                ? `$${parseFloat(discountedPrice) / 100}`
                : null}
            </span>
          </div>
        ) : null}

        {state.paymentType === "paypal" ? (
          <div className="paypal-btns" style={{ width: "100%" }}>
            <div className="container">
              <div className="preLoader-minimal-height">
                {state.status.isSubscribing ? (
                  <Spinner
                    size={40}
                    spinnerColor={themeTextRed}
                    spinnerWidth={2}
                    visible={true}
                  />
                ) : null}
              </div>
            </div>
            <PayPalButton
              amount={state.renewAmount}
              options={{
                vault: true,
                clientId: process.env.PAYPAL_CLIENT_ID,
              }}
              createSubscription={(data, actions) => {
                const isPromoApplied = state.coupon.isActivationSuccess && shouldApplyDiscount && !shouldNeverApplyDiscount;

                const non_discounted_plan_id =
                  state.billingDuration === 1
                    ? selectedPlan[0].monthly_paypal_membership_id
                    : selectedPlan[0].annual_paypal_membership_id;

                let discounted_plan_id = null;
                // consider removing promotions on paypal
                if (isPromoApplied) {
                  if (selectedPlan[0].order === 1) {
                    discounted_plan_id =
                      state.billingDuration === 1
                        ? state.coupon.data.monthly_premium_paypal_plan_id
                        : state.coupon.data.yearly_premium_paypal_plan_id;
                  } else {
                    discounted_plan_id =
                      state.billingDuration === 1
                        ? state.coupon.data.monthly_base_paypal_plan_id
                        : state.coupon.data.yearly_base_paypal_plan_id;
                  }
                }
                const plan_id = discounted_plan_id || non_discounted_plan_id;

                return actions.subscription.create({
                  plan_id,
                });
              }}
              onApprove={(data, actions) => {
                // Capture the funds from the transaction
                return actions.subscription.get().then(function (details) {
                  if (details.status === "ACTIVE") {
                    onClickSubmitButton(details.id, "paypal");
                  } else {
                    props.showToastMessage(
                      `Could not complete payment`,
                      "error"
                    );
                  }
                });
              }}
            />
          </div>
        ) : (
          <div className="card-detail">
            <CardElements
              changePaymentType={onClickSubmitButton}
              formSubmitStatus={state.status.isSubscribing}
              isFormValid={true}
              userDetails={userDetails}
              formError={state.status.formError}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: getUserDetails(state),
});

const dispatchActionToProps = (dispatch) => ({
  loginUser: bindActionCreators(loginUser, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
  addSentryError: bindActionCreators(addSentryError, dispatch),
  removeRenewSubscriptionData: bindActionCreators(
    removeRenewSubscription,
    dispatch
  ),
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(ReactivateAccount));
