import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import toJS from '../../../helpers/toJS';

import {
  appEssentialFetch,
  createFavourite,
  deleteFavourite,
  createBoard,
  fetchRecipes,
  setObjectBoards,
  clearBoardSelection,
  deleteFavouriteBoard,
  updateFavouriteBoard,
  setFiltersAndFetch,
  search,
  fetchBlogs
} from "../../../actions";

import {
  getUnFilteredList,
  getFeaturedList,
  getBlogCategories,
  getBlogFilterName,
  getBlogs,
  getBoards,
  getMoreBlogAvailable,
  getBlogsSearchList,
  getBlogsSearchCount,
  getFetchStatus,
  // getMoreRecipeAvailable,
  getBlogsSearchText,
  getAppliedFilters,
  getTrendingBlogs,
  getTrendingType,
  getOrder,
} from "../../../selectors/blogs";

import List from "./components";

const mapStateToProps = state => ({

  // filteredList: getFilteredList(state),
  unFilteredList: getUnFilteredList(state),
  featuredList: getFeaturedList(state),
  categories: getBlogCategories(state),
  blogFilterName: getBlogFilterName(state),
  blogs: getBlogs(state),
  boards: getBoards(state),
  // fetchStatus: getFetchStatus(state),
  moreBlogAvailable: getMoreBlogAvailable(state),
  fetchStatus: getFetchStatus(state),
  searchList: getBlogsSearchList(state),
  searchCount: getBlogsSearchCount(state),
  searchBy: 'title',
  searchTerm: getBlogsSearchText(state),
  appliedFilters: getAppliedFilters(state),
  trendingBlogs: getTrendingBlogs(state),
  trendingType: getTrendingType(state),
  order: getOrder(state)
});

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  createBoard: bindActionCreators(createBoard, dispatch),
  createFavourite: bindActionCreators(createFavourite, dispatch),
  deleteFavourite: bindActionCreators(deleteFavourite, dispatch),
  setObjectBoards: bindActionCreators(setObjectBoards, dispatch),
  clearBoardSelection: bindActionCreators(clearBoardSelection, dispatch),
  deleteFavouriteBoard: bindActionCreators(deleteFavouriteBoard, dispatch),
  updateFavouriteBoard: bindActionCreators(updateFavouriteBoard, dispatch),
  setFiltersAndFetch: bindActionCreators(setFiltersAndFetch, dispatch),
  searchBlogs: bindActionCreators(search, dispatch),
  fetchBlogs: bindActionCreators(fetchBlogs, dispatch)
});

export default connect(
  mapStateToProps,
  dispatchActionToProps
)(toJS(List));




