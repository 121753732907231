import React from 'react';
import '../styles/common/pill-selector.scss';

const PillSelector = ({ label, onRemove, style = {}, className = '' }) => {
  return (
    <div className={`pill-container ${className}`} style={style}>
      <p className="pill-text">{label}</p>
      <button 
        className="close-button"
        onClick={() => onRemove(label)} 
        aria-label={`Remove ${label}`}
      >
        ×
      </button>
    </div>
  );
};

export default PillSelector; 