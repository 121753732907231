import * as actions from '../actions/actionTypes'
import pushToSentry from '../helpers/pushToSentry'
import { post } from '../helpers/requests';

import Fraction from 'fraction.js';
import moment from 'moment';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import isNil from 'lodash/isNil';
import toString from 'lodash/toString'

import { fromJS } from 'immutable';
import { mealPlan } from '../helpers/api';
import { call, select, takeEvery, put } from 'redux-saga/effects';
import { getToken } from './selectors';
import axios from 'axios';
import { showToastMessage, showGroceryListLimitNotification } from '../actions';

import { getFractionFromString } from '../helpers/converters';
import groceryTask from './subtask/grocery';
import calculateIngredients from '../helpers/calculateIngredients';
import { getCategoryId } from '../helpers/categories';

function* task(action) {

  try {
    const token = yield select(getToken);
    const body = action.payload;
    const response = yield call(axios, post(mealPlan.addToCalendar(), body, '', token))

    const calendarItems = map(response.data.calendar_items, calendarItem => ({
      ...calendarItem,
      date: calendarItem.date.split('T')[0],
      // date: moment(calendarItem.date).format('YYYY-MM-DD') // Since date will be in iso format
    }));

    yield put({
      type: actions.ADD_MEAL_PLAN_TO_CALENDAR_SUCCESS,
      payload: {
        calendarItems: calendarItems,
        removeOld: action.payload.remove_old,
        startDate: action.payload.start_date,
        mealPlanDuration: action.payload.mealPlanDuration
      }
    });
    yield put(showToastMessage(`👍🏼 Pre made meal plan added.`, 'success', 'addMealPlan'));

    if (action.payload.addToGroceryList) {
      // Accumulate all grocery list
      const ingredients = reduce(calendarItems, (accumulator, item) => {

        if (item.recipe_meta && item.recipe_meta?.ingredients_v2) {
          const ingredients = item.recipe_meta?.ingredients_v2;

          const recipeId = item.recipe_id;
          accumulator = concat(accumulator, map(filter(ingredients, ingredient => !ingredient.header), ingredient => {

            let itemName = ingredient.ingredient?.display_singular_name ? ingredient.ingredient?.display_singular_name : '';
            let amount = ingredient.ingredient_unit?.display_singular_name ? ingredient.ingredient_unit?.display_singular_name : '';
            let quantity = ingredient.quantity ? ingredient.quantity : '';

            let addIngredientName = `${quantity} ${amount} ${itemName}`;

            return {
              item: addIngredientName,
              category_id: getCategoryId(ingredient?.ingredient?.category),
              recipe_id: recipeId,
              ingredient_name: itemName,
              amount: toString(quantity),
              ingredient_unit: toString(amount)
            }
          }));
        }


        return accumulator
      }, [])

      let addResponse = yield call(groceryTask.addToGroceryList, { items: ingredients, limit: 500 });
      if (!isNil(addResponse.isLimitExceeded) && addResponse.isLimitExceeded) {
        yield put(showGroceryListLimitNotification(addResponse.items));
      } else {
        yield put({
          type: actions.ADD_INGREDIENTS_OF_DAY_TO_GROCERY_LIST_SUCCESS,
          payload: {
            addedIngredients: addResponse.data.new_items
          }
        })

        yield put({
          type: actions.ADD_ALL_TO_GROCERYLIST_SUCCESS,
          payload: {
            addedIngredients: addResponse.data.new_items
          }
        })
        yield put(showToastMessage(`✅ Ingredients added to groceries.`, 'success', 'addIngredient'));
      }
    }
  } catch (e) {
    console.log(e)
    yield put(showToastMessage(`❗️Adding meal plan failed.`, 'error', 'addMealPlan'));
    const eventId = pushToSentry(e)
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId
      }
    })
  }
}

export default function* addMealPlanToCalendar() {
  yield takeEvery(actions.ADD_MEAL_PLAN_TO_CALENDAR, task)
}