import React, { useState, useEffect } from "react";
import {
  map,
  filter,
  findIndex,
  isEmpty,
  concat,
  replace,
  isNil,
} from "lodash";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Element } from "react-scroll";
import Spinner from "react-spinner-material";
import cx from "classnames";

import DottedButton from "../../common/dottedButton";

import PlusIcon from "../../images/PlusIcon";
import Mealcard from "../../images/mealcardfallback.png";
import getWorkoutImages from "../../../helpers/getWorkoutImages";
import LinkButton from "../../common/linkButton";
import Button from "../../common/button";
import ModalWrapper from "../../common/modal";
import CustomInput from "../../common/customInput";
import getUserPlanType from "../../../helpers/getUserPlanType";
import TextArea from "../../common/formElements/textarea";

import { Tooltip } from "react-tippy";
import { themeTextRed } from "../../../helpers/theme";
import ScrollableDatePicker from "../../common/scrollableDatePicker";

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  opacity: isDragging ? 0.7 : 1,

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  opacity: isDraggingOver ? 0.7 : 1,
});

const MealPlanList = (props) => {
  const { feature, slug, userDetails } = props;

  const {
    calendarListData,
    setMacros,
    progressStatus,
    macroState,
    setMacroState,
  } = props;

  const [addToGroceryPopover, setAddToGroceryPopover] = useState(false);
  const [activeRow, setActiveRow] = useState(null);

  const [emptyCalendarState, setEmptyCalendarState] = useState({
    state: false,
    disabled: false,
  });

  const [isPopoverOpened, setIsPopoverOpen] = useState(false);

  const [clearMealPlanDay, setClearMelaPlanDay] = useState(null);

  const [customMealState, setCustomMealState] = useState({
    isOpen: false,
    objectType: null,
    title: null,
    url: null,
    notes: null,
    id: null,
  });

  const [showPrintFilterModal, setShowPrintFilterModal] = useState(false);

  const togglePrintFilterModal = () => {
    if (showPrintFilterModal) {
      // Reset start and end dates to defaults when closing the modal
      setStartDate(currentDate);
      setEndDate(addDaysToDate(currentDate, 7));
    }
    setShowPrintFilterModal(!showPrintFilterModal);
  };
  const isAddToGroceryListButtonVisible = () => {
    // returns only recipes in the meal plan
    let disableData = filter(calendarListData, (item) => {
      if (!isNil(item)) {
        let filteredRecipe = filter(item.meals, (meal) => meal.recipe);

        if (!isEmpty(filteredRecipe)) {
          return filteredRecipe;
        }
      }
    });

    // returns the recipes from disableData that are within 14 days
    const withinDateData = filter(disableData, (item) => {
      const isInPremadeRange = moment(item.date).isBetween(
        moment()
          .startOf("day")
          .format("YYYY-MM-DD"),
        moment()
          .startOf("day")
          .add({ days: 14 })
          .format("YYYY-MM-DD"),
        null,
        "[)"
      );

      if (isInPremadeRange) {
        return isInPremadeRange;
      }
    });

    if (isEmpty(withinDateData)) {
      // this meanes that there is no recipes in our meal-plan
      return true;
    } else {
      if (calendarListData.length > 0) {
        let index = findIndex(calendarListData, (item) => {
          if (item.meals.length > 0 && moment().isSameOrBefore(item.date)) {
            return true;
          }
        });
        if (index > -1) {
          return false;
        }
      }
      return true;
    }
  };

  useEffect(() => {
    if (calendarListData && calendarListData.length > 0) {
      let index = findIndex(calendarListData, (item) => {
        if (
          item.meals.length > 0 &&
          moment().isSameOrBefore(item.date, "day")
        ) {
          return true;
        }
      });
      if (index === -1 && !emptyCalendarState.disabled) {
        setEmptyCalendarState({ state: true, disabled: false });
      }
    }
  }, [calendarListData]);

  const onClearMealPlanDay = (item) => {
    setClearMelaPlanDay(item);
  };

  const calendarDateAction = (item) => {
    const isPastDate = moment(item.date).isBefore(
      moment()
        .startOf("day")
        .format("YYYY-MM-DD"),
      "day"
    );
    const isInPremadeRange = moment(item.date).isBetween(
      moment()
        .startOf("day")
        .format("YYYY-MM-DD"),
      moment()
        .startOf("day")
        .add({ days: 14 })
        .format("YYYY-MM-DD"),
      null,
      "[)"
    );
    const addItem = isPastDate
      ? []
      : [
          {
            label: "Add recipe",
            action: () => {
              props.updateRecipeModal({
                show: true,
                activeDate: item.date,
                activeObjectType: "recipe",
              });
            },
          },
          {
            label: "Add workout",
            action: () => {
              props.updateWorkoutModal({
                show: true,
                activeDate: item.date,
                activeObjectType: "workout",
              });
            },
          },
          {
            label: "Add custom",
            action: () => {
              props.updateCustomModal({
                show: true,
                activeDate: item.date,
                activeObjectType: "recipe",
              });
            },
          },
        ];

    const otherFutureItems = isPastDate
      ? []
      : [
          {
            label: "Add saved day",
            action: () => {
              props.setAddSavedDayState({
                show: true,
                activeDate: item.date,
              });
            },
          },
        ];

    const actions = [];

    if (isInPremadeRange) {
      actions.push({
        label: "Add pre-made meal plan",
        action: () => {
          props.moveToPremadeMealPlanList(item.date);
        },
      });
    }
    if (item.meals.length > 0) {
      // condition to only add this option if recipe is present in meal-plan
      if (!isEmpty(item.meals)) {
        if (!isEmpty(filter(item.meals, (meal) => meal.recipe))) {
          actions.push({
            label: "Add ingredients to List",
            action: () => {
              props.addIngredientsOfDayToGroceryList(item.date);
            },
          });
        }
      }

      actions.push({
        label: "Save this day",
        action: () => {
          props.setSaveDayState({
            show: true,
            activeDate: item.date,
          });
        },
      });

      if (!isPastDate) {
        actions.push({
          label: "Clear day",
          action: () => {
            // props.clearMealPlanDay(item.date)
            onClearMealPlanDay(item);
          },
        });
      }
    }
    return concat(addItem, actions, otherFutureItems);
  };

  const isPremiumPlan = () =>
    !isNil(userDetails) &&
    !isNil(userDetails.details) &&
    !isEmpty(userDetails.details) &&
    (getUserPlanType(userDetails) === "premiumPlan" ||
      getUserPlanType(props.userDetails) === "grandfather");

  const calendarAction = (meal, date) => {
    const actions = [
      {
        label: "Delete",
        action: () => props.deleteMeal(meal.id),
      },
    ];

    // No condition for duplicate
    actions.push({
      label: "Duplicate",
      action: () => props.duplicateMeal(meal, date),
    });

    return actions;
  };
  // Render date of the calendar

  const renderEachDateHeader = (item) => {
    const isRenderAPastDate = moment()
      .startOf("day")
      .isAfter(item.date);
    let commaCount = 0;
    const isProgressingInDate =
      (progressStatus.addSingleMeal.date === item.date &&
        progressStatus.addSingleMeal.isProgressing) ||
      (progressStatus.addSingleCalendarItemsToGrocery.date === item.date &&
        progressStatus.addSingleCalendarItemsToGrocery.isProgressing) ||
      (progressStatus.addSavedDay.date === item.date &&
        progressStatus.addSavedDay.isProgressing);

    const showMacros = item.meals.length > 0;

    return (
      // <div className="date-header">
      <div className={cx("date-header", { pre: showMacros })}>
        <div className="details">
          <div className={cx("title", { "past-date": isRenderAPastDate })}>
            {`${moment(item.date, "YYYY-MM-DD").format("dddd")} ${moment(
              item.date,
              "YYYY-MM-DD"
            ).format("MMMM Do")}`}
          </div>
          {showMacros ? (
            <div className={cx("stats", { "past-date": isRenderAPastDate })}>
              {findIndex(setMacros, (macro) => macro === "calories") !== -1 ? (
                <span>
                  Cal{" "}
                  {item.statistics.calories ? item.statistics.calories : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "carbs") !== -1 ? (
                <span>
                  Carbs{" "}
                  {item.statistics.carbs ? `${item.statistics.carbs} g` : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "fat") !== -1 ? (
                <span>
                  Fat {item.statistics.fat ? `${item.statistics.fat} g` : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "sugar") !== -1 ? (
                <span>
                  Sug{" "}
                  {item.statistics.sugar ? `${item.statistics.sugar} g` : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "fiber") !== -1 ? (
                <span>
                  F{" "}
                  {item.statistics.fiber ? `${item.statistics.fiber} g` : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "protein") !== -1 ? (
                <span>
                  Pro{" "}
                  {item.statistics.protein
                    ? `${item.statistics.protein} g`
                    : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "saturatedFat") !==
              -1 ? (
                <span>
                  Sat Fat{" "}
                  {item.statistics.saturatedFat
                    ? `${item.statistics.saturatedFat} g`
                    : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "smartPoints") !==
              -1 ? (
                <span>
                  SmartPoints®{" "}
                  {item.statistics.smartPoints
                    ? `${item.statistics.smartPoints}`
                    : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "freestylePoints") !==
                -1 && item.statistics.freestylePoints >= 0 ? (
                <span>
                  Freestyle Points™{" "}
                  {item.statistics.freestylePoints
                    ? `${item.statistics.freestylePoints}`
                    : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
              {findIndex(setMacros, (macro) => macro === "wwp") !== -1 &&
              item.statistics.wwp >= 0 ? (
                <span>
                  WWP+{" "}
                  {item.statistics.wwp ? `${item.statistics.wwp} g` : "0 g"}
                  {++commaCount < setMacros.length ? " | " : ""}&nbsp;
                </span>
              ) : null}
            </div>
          ) : null}
        </div>
        {calendarDateAction(item).length > 0 && isPremiumPlan() && (
          <div className="dotted-actions">
            {!isProgressingInDate ? (
              <DottedButton
                popoverDisableReposition={false}
                options={calendarDateAction(item)}
                displayIcon={item.meals.length === 0 ? PlusIcon : null}
              />
            ) : (
              <Spinner
                className="spinner"
                size={15}
                spinnerColor={themeTextRed}
                spinnerWidth={2}
                visible={true}
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const addToGroceryListPopoverContent = () => {
    return (
      <div className={"Addgroceries"}>
        <div className="groceriesHead">
          <h3>Add all ingredients to grocery list?</h3>
        </div>
        <div className="groceriesFooter">
          <Button
            btnClass="primary btn-full"
            btnText="Yes"
            onClick={() => {
              props.addIngredientsOfRangeToGroceryList();
              setAddToGroceryPopover(false);
            }}
          />
          <Button
            btnClass="secondary btn-full"
            btnText="Cancel"
            onClick={() => {
              setAddToGroceryPopover(false);
            }}
          />
        </div>
      </div>
    );
  };

  // Render Each item in the calendar
  const renderCalendarItem = (meals, date, completed, groupIndex) => {
    if (meals.length) {
      return map(meals, (meal, index) => {
        let mealDetails;
        let coverImage;
        let objectType;
        if (!isEmpty(meal.recipe)) {
          mealDetails = meal.recipe;
          coverImage = mealDetails.thumbnail;
          objectType = "recipe";
        } else if (!isEmpty(meal.workout)) {
          mealDetails = meal.workout;
          coverImage = getWorkoutImages(mealDetails);
          objectType = "workout";
        }
        const isPastDay = moment()
          .startOf("day")
          .isAfter(date);

        const renderDaysList = () => {
          const renderItem = (noLink = "") => (
            <div key={index} className={`calendarItem ${noLink}`}>
              <div className="itemList">
                <figure
                  className="CardImg"
                  style={{
                    backgroundImage: `url(${coverImage || Mealcard})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                />
                <div className="title">
                  <p>{mealDetails.title}</p>
                </div>
              </div>
            </div>
          );

          const renderDefaultItem = (url = mealDetails.url, custom = false) => {
            return (
              <a
                target="_blank"
                href={!custom ? url : null}
                className="no-highlight"
                onClick={(e) => {
                  if (custom) {
                    setCustomMealState({
                      ...customMealState,
                      isOpen: true,
                      title: mealDetails.title,
                      url: mealDetails.url,
                      notes: mealDetails.notes,
                    });
                  } else {
                    e.preventDefault();
                    window.open(url, "_blank");
                  }
                }}
              >
                {renderItem()}
              </a>
            );
          };
          const renderCustomItem = () => {
            let url = mealDetails.url;

            const httpPattern = /^((http|https):\/\/)/g;
            if (mealDetails.url) {
              url = httpPattern.test(url) ? url : "http://" + url;
              return (
                <Tooltip
                  multiple={true}
                  open={
                    customMealState.isOpen &&
                    mealDetails.url === customMealState.url &&
                    mealDetails.title === customMealState.title &&
                    mealDetails.notes === customMealState.notes
                  }
                  arrow={true}
                  position="bottom"
                  theme="light"
                  interactive={true}
                  onRequestClose={() => {
                    setCustomMealState({
                      isOpen: false,
                      url: false,
                      title: false,
                      notes: false,
                    });
                  }}
                  html={
                    <div className="custom-popover">
                      <div className="modalWrapper">
                        <h3>{customMealState.title}</h3>
                        <a
                          href={customMealState.url}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(customMealState.url, "_blank");
                          }}
                        >
                          {customMealState.url}
                        </a>
                        <p>{customMealState.notes}</p>
                      </div>
                    </div>
                  }
                >
                  {renderDefaultItem(url, true)}
                </Tooltip>
              );
            } else {
              return renderItem("noLink");
            }
          };
          return (
            <div
              className="calendarLists"
              onMouseEnter={() => !isPopoverOpened && setActiveRow(meal.id)}
              onMouseLeave={() => !isPopoverOpened && setActiveRow(null)}
            >
              {mealDetails.id ? renderDefaultItem() : renderCustomItem()}
              {!isPastDay && isPremiumPlan() ? (
                <div
                  className={cx("dotted-actions", {
                    "show-dotted-actions": meal.id == activeRow,
                  })}
                >
                  <DottedButton
                    options={calendarAction(meal, date)}
                    popoverDisableReposition={false}
                    hideWhenBlurred
                    isFocused={meal.id === activeRow}
                    isPopoverOpen={isPopoverOpened}
                    setIsPopoverOpen={setIsPopoverOpen}
                  />
                </div>
              ) : null}
            </div>
          );
        };

        return (
          <Draggable
            key={index}
            draggableId={`${meal.id}`}
            index={index}
            disableInteractiveElementBlocking
            showRespectForcePress={true}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                style={getItemStyle(
                  snapshot.isDragging,
                  provided.draggableProps.style
                )}
              >
                {renderDaysList()}
              </div>
            )}
          </Draggable>
        );
      });
    } else {
      return (
        <div className="calendarLists empty">
          <div className="emptyItem"></div>
        </div>
      );
    }
  };
  // util function
  const addDaysToDate = (date, day) => {
    return moment(date, "YYYY-MM-DD")
      .add(day, "days")
      .format("YYYY-MM-DD");
  };

  const currentDate = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState(addDaysToDate(currentDate, 7));
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleStartDate = (dateStr, index) => {
    setStartDate(dateStr);
    // Always choose the 7th day
    setEndDate(addDaysToDate(dateStr, 7));
    setSelectedIndex(index);
    props.showToastMessage(`${moment(startDate).format('MM/DD/YYYY')} to ${moment(endDate).format('MM/DD/YYYY')} is selected`, "success");
  };

  const generateStartDateList = () => {
    let nodes = map(calendarListData, (dateObj, index) => {
      // do not show older dates
      if (!(dateObj.date >= currentDate)) {
        return;
      }
      if (startDate === dateObj.date && index != selectedIndex) {
        // to set the initial index of valid data
        setSelectedIndex(index);
      }
      return (
        <div
          className={`${
            startDate === dateObj.date ||
            (selectedIndex <= index && index <= selectedIndex + 6)
              ? "calender-card-active"
              : "calender-card"
          }`}
          key={index}
          onClick={(e) => handleStartDate(dateObj.date, index)}
        >
          <p className="calender-day">
            {moment(dateObj.date)
              .format("dd")
              .substring(0, 2)}
          </p>
          <p className="calender-date">{moment(dateObj.date).format("D")}</p>
          <div className="dot-group"></div>
        </div>
      );
    });
    return nodes;
  };

  const handlePrintClick = (e) => {
    e.preventDefault();
    try {
      props.history.push({
        pathname: `/calendar/print`,
        state: { startDate, endDate },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const printFilterModal = (
    <ModalWrapper
      isOpen={showPrintFilterModal}
      minHeight="450px"
      maxWidth="550px"
      borderRadius="13px"
      onRequestClose={togglePrintFilterModal}
    >
      <div className="modalWrapper">
        <div
          className="modalHeader mealplan-index-header"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <h5>Choose Start Date</h5>
        </div>

        <div className="modalBody">
          <ScrollableDatePicker
            nodesPerSlide={7}
            nodesArray={generateStartDateList()}
            onSelectionToggle={setStartDate}
          />
        </div>
        <div
          className="modalFooter"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Button
            btnText="Proceed to Print"
            btnClass="btn btn-md btn-primary floating btn-full"
            onClick={(e) => handlePrintClick(e)}
          />
          <Button
            btnText="Close"
            btnClass="secondary btn-full btn-bp-sm"
            onClick={togglePrintFilterModal}
          />
        </div>
         
      </div>
    </ModalWrapper>
  );

  return (
    <>
      <div className="list">
        <div className="calendar-header">
          {showPrintFilterModal ? printFilterModal : null}
          <h5>My Calendar</h5>
          <ul>
            {isPremiumPlan() && (
              <>
                <li>
                  <span
                    onClick={(e) => {
                      setMacroState({
                        ...macroState,
                        showChooseModal: true,
                      });
                    }}
                  >
                    Settings
                  </span>
                </li>
                <span className="separation">|</span>
                <li>
                  <LinkButton
                    renderValue={() => (
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          togglePrintFilterModal();
                        }}
                      >
                        Print
                      </a>
                    )}
                    isLoading={false}
                  />
                </li>
                <span className="separation">|</span>
                <li>
                  {
                    <Tooltip
                      multiple={true}
                      open={addToGroceryPopover}
                      arrow={true}
                      position="bottom-start"
                      trigger="click"
                      theme="light"
                      interactive={true}
                      onRequestClose={() => setAddToGroceryPopover(false)}
                      html={
                        <div className={"Addgroceries"}>
                          <div className="groceriesHead">
                            <h3>Add all ingredients to grocery list?</h3>
                          </div>
                          <div className="groceriesFooter">
                            <Button
                              btnClass="primary btn-full"
                              btnText="Yes"
                              onClick={() => {
                                props.addIngredientsOfRangeToGroceryList();
                                setAddToGroceryPopover(false);
                              }}
                            />
                            {/* <Button
                              btnClass="secondary btn-full"
                              btnText="Cancel"
                              onClick={() => {
                                setAddToGroceryPopover(false)
                              }}
                            /> */}
                          </div>
                        </div>
                      }
                    >
                      <LinkButton
                        disabled={isAddToGroceryListButtonVisible()}
                        isLoading={progressStatus.allToGroceryList}
                        renderValue={() => (
                          <a
                            onClick={(e) => {
                              setAddToGroceryPopover(!addToGroceryPopover);
                            }}
                          >
                            Add all to Grocery List
                          </a>
                        )}
                        rightOffset={-13}
                      />
                    </Tooltip>
                  }
                </li>
              </>
            )}
          </ul>
        </div>
        <DragDropContext
          onDragEnd={(dragProps) => {
            if (dragProps && dragProps.destination) {
              const droppableDay = dragProps.destination.droppableId.split(
                "_"
              )[1];
              const draggableDay = dragProps.source.droppableId.split("_")[1];
              if (
                !(
                  dragProps.source.index === dragProps.destination.index &&
                  draggableDay === droppableDay
                )
              ) {
                props.moveMealInMealPlan(
                  parseInt(dragProps.draggableId), // draggable id
                  droppableDay,
                  dragProps.destination.index,
                  draggableDay
                );
              }
            }
          }}
        >
          {map(calendarListData, (item, index) => {
            const lastClass =
              index === calendarListData.length - 1 ? "last-item" : "";
            const startOfWeek =
              moment(item.date).weekday() == 0 ? "day-start-of-week" : "";
            const isDropDisabled = moment()
              .startOf("day")
              .isAfter(item.date);
            return (
              <div
                className={`each-date ${lastClass} ${startOfWeek}`}
                key={item.date}
              >
                <Element name={item.date}>
                  <Droppable
                    droppableId={`droppable_${item.date}`}
                    date={item.date}
                    isDropDisabled={isDropDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {renderEachDateHeader(item)}
                        {renderCalendarItem(
                          item.meals,
                          item.date,
                          item.completed,
                          index
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </Element>
              </div>
            );
          })}
        </DragDropContext>
        <div className="scroll-height"></div>
      </div>

      {!isNil(clearMealPlanDay) && (
        <ModalWrapper
          isOpen={!isNil(clearMealPlanDay)}
          minHeight="200px"
          maxWidth="450px"
          // overflowY="hidden"
          borderRadius="13px"
          onRequestClose={() => setClearMelaPlanDay(null)}
        >
          <div className="modalWrapper">
            <div className="modalHeader" style={{ textAlign: "center" }}>
              <h3>{`Confirm clear calendar item for the ${moment(
                clearMealPlanDay.date,
                "YYYY-MM-DD"
              ).format("dddd")} ${moment(
                clearMealPlanDay.date,
                "YYYY-MM-DD"
              ).format("MMMM Do")}`}</h3>
            </div>
            <div className="modalBody"></div>
            <div className="modalFooter">
              <Button
                btnClass="primary btn-full"
                btnText="Confirm"
                onClick={() => {
                  props.clearMealPlanDay(clearMealPlanDay.date);
                  setClearMelaPlanDay(null);
                }}
              />
              <Button
                btnClass="secondary btn-full btn-bp-sm"
                btnText={`Cancel`}
                onClick={() => setClearMelaPlanDay(null)}
              />
            </div>
          </div>
        </ModalWrapper>
      )}
    </>
  );
};

export default MealPlanList;
