import React from 'react';
import '../styles/common.scss';

const Filter = () => {
    return (
        <svg width="78" height="72" viewBox="0 0 78 72" fill="none" xmlns="http://www.w3.org/2000/svg" className="FilterIcon">
            <path d="M10.5908 48.1423V68.9491" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.5908 3.05078V31.0881" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M39 29.1968V68.9493" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M39 3.05078V12.4474" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M67.4092 57.5391V68.9492" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M67.4092 3.05078V40.6372" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.59 47.2268C14.7818 47.2268 18.18 43.7711 18.18 39.5082C18.18 35.2453 14.7818 31.7896 10.59 31.7896C6.39816 31.7896 3 35.2453 3 39.5082C3 43.7711 6.39816 47.2268 10.59 47.2268Z" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M39.3009 28.617C43.4928 28.617 46.8909 25.1612 46.8909 20.8983C46.8909 16.6354 43.4928 13.1797 39.3009 13.1797C35.1091 13.1797 31.7109 16.6354 31.7109 20.8983C31.7109 25.1612 35.1091 28.617 39.3009 28.617Z" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M67.4103 56.7154C71.6022 56.7154 75.0003 53.2596 75.0003 48.9967C75.0003 44.7338 71.6022 41.2781 67.4103 41.2781C63.2185 41.2781 59.8203 44.7338 59.8203 48.9967C59.8203 53.2596 63.2185 56.7154 67.4103 56.7154Z" stroke="#2C2F34" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default Filter;