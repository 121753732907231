import React from "react";

import { Row, Col } from "react-flexbox-grid";
import VerticalListWithTitle from "./common/verticalListWithTitle";
import NavbarImageCard from "./common/navbarImageCard";

import workout from "../../images/workoutnavimage.png";
import video from "../../images/videonavimage.png";
import recipe from "../../images/recipenavimage.png";
import NavDropdownImage from "./common/NavDropdownImage";
import mealplan1 from "../../images/mealPlannavimage1.png";
import mealplan2 from "../../images/mealPlannavimage2.png";
import mealplan3 from "../../images/mealPlannavimage3.png";
import KatiesCornerBlogCenter from '../../images/KatiesCornerDescriptionBlogCenter.png';
import KatiesCornerCooking from '../../images/katiesCornerDescriptionCooking.png';
import KatiesCornerCookingSecond from '../../images/katiesCornerDescriptionCookingSecond.png';
import KatiesCornerWorkout from '../../images/KatiesCornerDescriptionWorkout.png';
import KatiesCornerDescriptionMeeting from '../../images/KatiesCornerDescriptionMeeting.png';


const completeNavListItems = (latestRecipe) => [
    {
        image: KatiesCornerBlogCenter,
        title: "Katies Corner",
        type: "blog",
        href: "/nourish/katies-corner",
        description: "Simple, nutritious, no fuss & family friendly",
    },
    {
        image: KatiesCornerDescriptionMeeting,
        type: "video",
        href: "/nourish/live-meetings",
        title: "Meetings",
        description: "Look into our live meetings",
    },
    {
        image: KatiesCornerCooking,
        type: "video",
        href: "/nourish/live-cooking",
        title: "Live Cooking",
        description: "Cooking shows & tips to succeed in the kitchen",
    },
    {
        image: KatiesCornerWorkout,
        type: "video",
        href: "/nourish/live-workouts",
        title: "Live Workout",
        description: "Look into our live workouts",
    },
];

const KatiesCornerDropdownContent = ({
    latestRecipe,
    history,
    requestCloseNavDropdown,
}) => (
    <div className="container no-padding-md katies-corner-nav-dropdown">
        <div className="navbar-dropdown-scroller">
            <Row className="loggedout-dropdown no-margin">
                <Col lg={12} className="no-padding">
                    <NavbarImageCard
                        list={completeNavListItems(latestRecipe)}
                        history={history}
                        onClose={requestCloseNavDropdown}
                        render={(item) =>
                        (
                            <NavDropdownImage
                                type={item.type}
                                src={item.image}
                                image1={item.image1}
                                image2={item.image2}
                                image3={item.image3}
                            />
                        )
                        }
                    />
                </Col>
            </Row>
        </div>
    </div>
);

export default KatiesCornerDropdownContent;
