import React from 'react'
import StarRatingComponent from 'react-star-rating-component'

import HalfStarIcon from './halfStarIcon'

const StarRating = (props) => {
  return (
    <StarRatingComponent
      name="rate1"
      starCount={5}
      value={props.value}
      emptyStarColor={props.value === 0 ? '#e7e7e7' : '#999999'}
      starColor={'#ffb848'}
      editing={(props.setEditable) ? true : false}
      onStarClick={props.onStarClick}
      renderStarIconHalf={() => <HalfStarIcon />}
    />
  )
}

export default StarRating;