import axios from 'axios'
import { map } from 'lodash';
import { get, post } from '../../helpers/requests'

import { blogs, favourites } from '../../helpers/api'

import { call, select, put } from 'redux-saga/effects'

import {
  getToken,
  getAppliedBlogFilters,
  getAppliedBlogFavorites,
  getBlogDirtyGroup,
  getBlogCategories,
  getBlogBoards,
  getUnFilteredBlogList,
  getCachedBlogList,
  getBlogFiltersSetUpdatedPrematurelyStatus
} from '../selectors'
import * as actionTypes from '../../actions/actionTypes';
import { generateBlogQueryFromFilter } from '../../helpers/filterConversions';

const getUnfilteredBlogsData = function* getUnfilteredData(blog) {
  const token = yield select(getToken)
  // Don't fetch unfiltered blog list if already fetched
  const unfilteredBlogList = yield select(getUnFilteredBlogList);
  let unfilteredBlogsResponse;

  let unfilteredBlogsData;
  let featuredBlogsData;

  if (unfilteredBlogList.isEmpty() || blog.blogRefreshGroup === 'unfiltered') {
    yield put({
      type: actionTypes.BLOGS_LIST_FETCH_REQUEST,
      payload: {
        listType: 'unfiltered',
        objectType: 'blog',
        pullToRefresh: blog.blogRefreshGroup === 'unfiltered'
      }
    });
    unfilteredBlogsResponse = yield call(axios, get(
      blogs.getAll(blog.limit, blog.offset, ''),
      '',
      token
    ));
    yield put({
      type: actionTypes.BLOGS_LIST_FETCH_SUCCESS,
      payload: {
        listType: 'unfiltered',
        objectType: 'blog',
        pullToRefresh: blog.blogRefreshGroup === 'unfiltered'
      }
    });

    unfilteredBlogsData = unfilteredBlogsResponse ? unfilteredBlogsResponse.data.latest_blogs : [];
    featuredBlogsData = unfilteredBlogsResponse ? unfilteredBlogsResponse.data.featured_blogs : [];
  } else {
    unfilteredBlogsData = null; // In redux it will be in immutable format
    featuredBlogsData = null
  }

  return {
    unfilteredBlogsData,
    featuredBlogsData,
  }
};

export default {
  // Get Single blog details
  get: function* getSingleBlog(objectSlug) {
    const token = yield select(getToken);
    const blogResponse = yield call(axios, get(blogs.get(objectSlug), '', token));
    return blogResponse;
  },
  // Gets all blogs as per existing filters / search / favorites
  getAll: function* getAllBlogs(blog) {
    const appliedBlogFilters = yield select(getAppliedBlogFilters);
    const blogCategories = yield select(getBlogCategories)

    const filtersSetUpdatedPrematurely = yield select(getBlogFiltersSetUpdatedPrematurelyStatus);

    // Get the token
    const token = yield select(getToken);
    const dirtyGroup = yield select(getBlogDirtyGroup);

    // These will be toggled in each cases
    let filteredBlogsResponse;
    let unfilteredBlogsResponse;
    if (dirtyGroup === 'filters' || blog.blogRefreshGroup === 'filters' || filtersSetUpdatedPrematurely) {
      // Dispatching to Set Particular Fetch Status
      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'blog'
        }
      });

      // If to be fetched is filters
      filteredBlogsResponse = yield call(axios, get(
        blogs.getAll(blog.limit, blog.offset, generateBlogQueryFromFilter(
          appliedBlogFilters,
          blogCategories,
        )), '', token)
      );

      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'blog'
        }
      });
      const blogsResponse = yield call(getUnfilteredBlogsData, blog);

      // This will fetch new set of data for the newly applied filter
      return {
        filteredBlogsData: filteredBlogsResponse && filteredBlogsResponse.data.latest_blogs ? filteredBlogsResponse.data.latest_blogs : null,
        unfilteredBlogsData: blogsResponse.unfilteredBlogsData,
        featuredBlogsData: blogsResponse.featuredBlogsData,
      }
    } else if (dirtyGroup === 'favorites' || blog.blogRefreshGroup === 'favorites') {

      // These will be toggled in each cases
      let filteredBlogsResponse;
      let unfilteredBlogsResponse;

      // Get the favorite board id
      let appliedBlogFavorites = yield select(getAppliedBlogFavorites);
      appliedBlogFavorites = appliedBlogFavorites.toJS();
      let favoriteBoardSlug = appliedBlogFavorites.board;
      let favoriteBoardId;
      if (favoriteBoardSlug) {
        const allBoard = yield select(getBlogBoards);
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }

      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorited',
          objectType: 'blog'
        }
      });
      // Favorites API fetch
      filteredBlogsResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'blog',
          favoriteBoardId,
          blog.limit,
          blog.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorited',
          objectType: 'blog'
        }
      });
      const formattedBlogsData = filteredBlogsResponse.data.items.map(item => ({
        ...item,
        ...item.blog,
      }));

      const blogsResponse = yield call(getUnfilteredBlogsData, blog);

      return {
        filteredBlogsData: filteredBlogsResponse && filteredBlogsResponse.data.items ? formattedBlogsData : null,
        unfilteredBlogsData: blogsResponse.unfilteredBlogsData,
        featuredBlogsData: blogsResponse.featuredBlogsData,
      }
    } else {
      // No featured for blogs
      const filteredBlogsData = yield select(getCachedBlogList)
      const blogsResponse = yield call(getUnfilteredBlogsData, blog);
      // Caching for clear list
      return {
        filteredBlogsData: filteredBlogsData.toJS(),
        unfilteredBlogsData: blogsResponse.unfilteredBlogsData,
        featuredBlogsData: blogsResponse.featuredBlogsData,
      }
    }
  },
  // Gets more blogs as per existing filters / search / favorites
  getMore: function* getMoreBlogs(blog) {
    const token = yield select(getToken);
    let offset = blog.offset * blog.limit;

    const appliedBlogFilters = yield select(getAppliedBlogFilters);
    const blogCategories = yield select(getBlogCategories)
    let appliedBlogFavorites = yield select(getAppliedBlogFavorites);

    appliedBlogFavorites = appliedBlogFavorites.toJS();
    const favoriteBoardSlug = appliedBlogFavorites.board;

    let filteredBlogsResponse;
    let unfilteredBlogsResponse;

    if (blog.filterGroup == 'filters') {

      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'filtered',
          objectType: 'blog',
          pagination: true
        }
      });

      filteredBlogsResponse = yield call(axios, get(
        blogs.getAll(blog.limit, offset, generateBlogQueryFromFilter(
          appliedBlogFilters,
          blogCategories,
        )), '', token)
      );
      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'filtered',
          objectType: 'blog',
          pagination: true
        }
      });
      return {
        filteredBlogsData: filteredBlogsResponse && filteredBlogsResponse.data.latest_blogs.length > 0 ? filteredBlogsResponse.data.latest_blogs : [],
        filteredBlogsCount: filteredBlogsResponse && filteredBlogsResponse.data.latest_blogs.length > 0 ? filteredBlogsResponse.data.latest_blogs.count : 0,
        // Not sending unfiltered data in filters
      }
    } else if (blog.filterGroup == 'favorites') {

      // Slug to id conversions
      let appliedBlogFavorites = yield select(getAppliedBlogFavorites);
      appliedBlogFavorites = appliedBlogFavorites.toJS();
      let favoriteBoardSlug = appliedBlogFavorites.board;
      let favoriteBoardId;

      const allBoard = yield select(getBlogBoards);
      if (favoriteBoardSlug) {
        const favoriteBoard = allBoard.find(board => (board.getIn(['slug']) === favoriteBoardSlug));
        favoriteBoardId = favoriteBoard.getIn(['id']);
      } else {
        favoriteBoardId = null
      }

      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'favorited',
          objectType: 'blog',
          pagination: true
        }
      });
      // Favorites API fetch
      filteredBlogsResponse = yield call(axios, get(
        favourites.getAllFavoritesInBoard(
          'blog',
          favoriteBoardId, // TODO: Board should be converted from slug to id
          blog.limit,
          blog.offset),
        '',
        token
      ));

      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'favorited',
          objectType: 'blog',
          pagination: true
        }
      });
      // Apply both since recipe is nested
      const formattedBlogsData = filteredBlogsResponse.data.items.map(item => ({
        ...item,
        ...item.blog,
      }));

      return {
        filteredBlogsData: filteredBlogsResponse && filteredBlogsResponse.data.items ? formattedBlogsData : null,
        unfilteredBlogsData: null,
        filteredBlogsCount: filteredBlogsResponse && filteredBlogsResponse.data.items ? filteredBlogsResponse.data.count : 0,
        unfilteredBlogsCount: 0
      }
    } else {
      // No filters or favorites
      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_REQUEST,
        payload: {
          listType: 'unfiltered',
          objectType: 'blog',
          pagination: true
        }
      });

      unfilteredBlogsResponse = yield call(axios, get(
        blogs.getAll(blog.limit, offset),
        '',
        token,
      ));

      yield put({
        type: actionTypes.BLOGS_LIST_FETCH_SUCCESS,
        payload: {
          listType: 'unfiltered',
          objectType: 'blog',
          pagination: true,
        }
      });
      return {
        filteredBlogsData: null,
        unfilteredBlogsData: unfilteredBlogsResponse && unfilteredBlogsResponse.data.latest_blogs ? unfilteredBlogsResponse.data.latest_blogs : null,
        filteredBlogsCount: 0,
        unfilteredBlogsCount: unfilteredBlogsResponse && unfilteredBlogsResponse.data.latest_blogs.length > 0 ? unfilteredBlogsResponse.data.count : 0
      };
    }
  },
}