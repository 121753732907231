import React, { useEffect, useState } from 'react'
import {
    useQuery,
    useInfiniteQuery,
} from 'react-query';
import queryString from 'query-string';
import KatiesCornerCard from './cards/KatiesCornerCard';
import axios from 'axios';
import { personalizedBlogs } from '../../helpers/api';
import InfiniteScroll from 'react-infinite-scroller';
import Loader from "../images/loader";
import "../styles/common.scss";
import HorizontalListWithAction from '../common/horizontalActionableHeader';

const getPersonalizedBlogs = async (params) => {

    let additionalParams = '';
    if (params.queryKey[1]) {
        additionalParams = `&categories=${params.queryKey[1]}`
    }
    const response = await axios.get(personalizedBlogs.get(params.pageParam, params.queryKey[2], additionalParams));
    return response.data.personalized_blogs;
}

const KatiesCorner = (props) => {
    const location = props.history.location.pathname;

    const navigateTo = (link) => {
        props.history.push(link)
    }
    const [category, setCategory] = useState(null);
    const [sort, setSort] = useState('desc');

    useEffect(() => {
        const query = queryString.parse(props.history.location.search, { arrayFormat: 'comma' });

        if (query.categories) {
            setCategory(query.categories);
        } else {
            setCategory(null);
        }
        setSort(query.sort || 'desc');

    }, [props.history.location.search]);

    useEffect(() => {
        const query = queryString.parse(props.history.location.search, { arrayFormat: 'comma' });

        if (query.categories) {
            setCategory(query.categories);
        } else {
            setCategory(null);
        }

        setSort(query.sort || 'desc');

    }, [])
    const changeRouteForCategory = (category) => {

        if (sort) {
            if (category !== 'select') {
                navigateTo(`/nourish/katies-corner?categories=${category}&sort=${sort}`);
            } else {
                navigateTo(`/nourish/katies-corner?sort=${sort}`);
            }

        } else {
            if (category !== 'select') {
                navigateTo(`/nourish/katies-corner?categories=${category}`);
            } else {
                navigateTo(`/nourish/katies-corner`);
            }

        }
    }

    const changeRouteForSort = (sort) => {
        if (category) {
            navigateTo(`/nourish/katies-corner?categories=${category}&sort=${sort}`);
        } else {
            navigateTo(`/nourish/katies-corner?sort=${sort}`);
        }

    }



    const offset = 0;
    const {
        data,
        error,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
        status
    } = useInfiniteQuery(['personalizedBlogs', category, sort], getPersonalizedBlogs, {
        getNextPageParam: (lastPage, pages) => {
            return lastPage.length >= 15 ? pages.length * 15 : undefined;
        },
        refetchOnWindowFocus: false,
        refetchOnMount: false
    });


    let personalizedBlogs = [];
    if (data && data.pages) {
        personalizedBlogs = data.pages.reduce((acc, page) => {
            acc = acc.concat(page);
            return acc;
        }, [])
    }


    if (props.taxonomiesIsLoading || !props.taxonomiesData) {
        return null;
    }
    let personalizedBlogTaxonomy = props
        .taxonomiesData?.filter(taxonomy => taxonomy.object_type === 'personalized_blog')
        .map((taxonomy) => ({
            title: taxonomy.name,
            value: taxonomy.id
        }));

    personalizedBlogTaxonomy = [{ title: 'Choose', value: 'select' }].concat(personalizedBlogTaxonomy);
    if (isFetching) {
        return <div className="container">
            <div className="preLoader-minimal-height preloader-max-height">
                <Loader></Loader>
            </div>
        </div>
    }
    return (
        <div className='katies-corner-group-section about-katies-corner-blog'>
            <div className='container'>
                <HorizontalListWithAction
                    title="Katies Corner"
                    showActions={false}
                    showMultipleActions={true}
                    links={[{
                        title: 'Categories',
                        value: category,
                        defaultValue: category,
                        onAction: (category) => {
                            changeRouteForCategory(category);
                        },
                        options: personalizedBlogTaxonomy
                    },
                    {
                        title: 'Sort',
                        value: sort,
                        defaultValue: sort,
                        onAction: (sortType) => {
                            changeRouteForSort(sortType)
                        },
                        options: [
                            { title: 'Descending', value: 'desc' },
                            { title: 'Ascending', value: 'asc' },
                        ]
                    }]}
                >
                    {
                        personalizedBlogs.length === 0 ? <div className='no-item-header'>
                            No Blogs available
                        </div> : null
                    }
                    <InfiniteScroll
                        initialLoad={true}
                        loadMore={() => {
                            if (!isFetchingNextPage) {
                                fetchNextPage()
                            }
                        }}
                        hasMore={hasNextPage}
                        loader={
                            <div className="container">
                                <div className="preLoader-minimal-height">
                                    <Loader></Loader>
                                </div>
                            </div>
                        }
                    >
                        <ul className='katies-corner-list'>
                            {
                                (!isFetching && !isFetchingNextPage && data && data.pages) ? personalizedBlogs.map((cornerItem) => (
                                    <KatiesCornerCard
                                        key={cornerItem.slug}
                                        title={cornerItem.title}
                                        date={cornerItem.publish_at}
                                        introduction={cornerItem.about}
                                        banner={cornerItem.banner}
                                        navigateTo={navigateTo}
                                        slug={cornerItem.slug}
                                        categories={cornerItem.taxonomies}
                                    />
                                )) : null
                            }
                        </ul>
                    </InfiniteScroll>
                </HorizontalListWithAction>
            </div>

        </div >



    )
}

export default KatiesCorner