import { SEND_REPLY_COMMENT_TO_OBJECT } from './actionTypes';

export default ({content, parentCommentId, objectType, objectId}) => ({
  type: SEND_REPLY_COMMENT_TO_OBJECT,
  payload : {
    content,
    parentCommentId,
    objectType,
    objectId
  },
});
