// @flow
import React, { useState, useEffect, useReducer } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Row, Col } from "react-flexbox-grid";

import isNil from "lodash/isNil";
import includes from "lodash/includes";

import { getAuthStatus, getUserDetails } from "../../../selectors";
import { loginUser, appEssentialFetch } from "../../../actions";
import CustomInput from "../../common/customInput";
import Button from "../../common/button";
import toJS from "../../../helpers/toJS";
import FrameIcon from "../../images/frameIcon";
import Loader from "../../images/loader";
import katie from "../../images/login-katie.png";
import queryString from "query-string";
import { TransitionEaseInOne } from "../../common/transitions";

import { generateAppEssentialPayload } from "../../../helpers/filterConversions";
import { themeTextRed } from "../../../helpers/theme";

type Props = {
  loginUser: (email: string, password: string, remember: boolean) => void,
  loginStatus: { isLoggedIn: boolean, isLoginFailed: boolean },
  history: Object,
};

const initialState = {
  email: "",
  password: "",
  rememberMe: true,
  emailFocusedOnce: false,
  passwordFocusedOnce: false,
  emailError: null,
  passwordError: null,
  isFormValid: false,
  isFormSubmitting: false,
  isFormSubmitted: false,
  formError: null,
  showPreLoder: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_EMAIL":
      return {
        ...state,
        email: action.payload.email,
      };
    case "UPDATE_PASSWOORD":
      return {
        ...state,
        password: action.payload.password,
      };
    case "UPDATE_REMEMBER_ME":
      return {
        ...state,
        rememberMe: action.payload.rememberMe,
      };
    case "BLUR":
      return {
        ...state,
        emailFocusedOnce: !isNil(action.payload.emailFocusedOnce)
          ? action.payload.emailFocusedOnce
          : state.emailFocusedOnce,
        passwordFocusedOnce: !isNil(action.payload.passwordFocusedOnce)
          ? action.payload.passwordFocusedOnce
          : state.passwordFocusedOnce,
      };
    case "VALIDATE_FORM":
      let emailError = null;
      let passwordError = null;
      let isFormValid = true;
      if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(state.email)) {
        emailError = "Enter a valid email Id";
      }
      if (!state.password.length > 0) {
        passwordError = "Password should not be empty!";
      }
      if (emailError || passwordError) {
        isFormValid = false;
      }
      return {
        ...state,
        emailError,
        passwordError,
        isFormValid,
        formError: null,
      };
    case "FORM_SUBMIT":
      return {
        ...state,
        isFormSubmitting: action.payload.isFormSubmitting,
        isFormSubmitted: action.payload.isFormSubmitted,
      };
    case "FORM_ERROR":
      return {
        ...state,
        formError: action.payload.formError,
      };
    case "UPDATE_PRELOADER":
      return {
        ...state,
        showPreLoder: action.payload.showPreLoder,
      };
    default:
      return state;
  }
};

const Login = (props: Props) => {
  const { isLoggedIn, isLoginFailed, isAuthenticating } = props.loginStatus;

  const { loginUser, loginStatus } = props;

  useEffect(() => {
    // if (!isNil(localStorage.getItem("token"))) {
    dispatch({ type: "UPDATE_PRELOADER", payload: { showPreLoder: true } });
    props.appEssentialFetch(
      generateAppEssentialPayload({
        objectType: "recipe",
      })
    );
    // }
  }, []);

  useEffect(() => {
    if (
      (!isAuthenticating && isLoggedIn) ||
      isLoginFailed ||
      isNil(localStorage.getItem("token"))
    ) {
      dispatch({ type: "UPDATE_PRELOADER", payload: { showPreLoder: false } });
    }
  }, [isAuthenticating, isLoggedIn, isLoginFailed]);

  const [state, dispatch] = useReducer(reducer, initialState);
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      if (queryParamObject.type == "meal-plan-login") {
        props.history.push("/calendar?meal-plans=true");
      } else if (
        (includes(props.startUrl, "login") ||
          includes(props.startUrl, "register") ||
          includes(props.startUrl, "pricing")) &&
        props.pageCount <= 1
      ) {
        props.history.push("/recipes");
      } else {
        console.log("some other url");
        props.history.go(-1);
      }
    } else if (isLoginFailed) {
      dispatch({
        type: "FORM_ERROR",
        payload: {
          formError: "You have entered an invalid username and password",
        },
      });
    }
  }, [isLoggedIn, isLoginFailed]);

  useEffect(() => {
    dispatch({
      type: "FORM_ERROR",
      payload: { formError: props.loginStatus.errorMessage },
    });
    if (!isNil(props.userDetails.reactivateAccountData)) {
      props.history.push("/reactivate-account");
    }
  }, [props.loginStatus.errorMessage]);

  const loginUserAction = () => {
    if (state.isFormValid) {
      loginUser(state.email, state.password, state.rememberMe);
    }
  };
  const queryParam = props.location.search;
  let queryParamObject = {};
  if (queryParam) {
    queryParamObject = queryString.parse(queryParam, { arrayFormat: "comma" });
  }

  return (
    <>
      <section className="login-section">
        <Helmet>
          <title>Login | Dashing Dish </title>
          <meta name="description" content="Dashingdish login page" />
        </Helmet>
        {state.showPreLoder ? (
          <div className="preLoader">
            <Loader></Loader>
          </div>
        ) : (
          <Row className="no-margin">
            <TransitionEaseInOne>
              <Col xs={12} md={12} lg={6} className="no-padding-sm">
                <div className="login-wrapper">
                  <div className="loginBlock">
                    <h1>Welcome back</h1>
                    {queryParamObject.type == "meal-plan-login" ? (
                      <div className="errorText">
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.5 1C14.19 1 18 4.81 18 9.5C18 14.19 14.19 18 9.5 18C4.81 18 1 14.19 1 9.5C1 4.81 4.81 1 9.5 1ZM9.5 0C4.26 0 0 4.26 0 9.5C0 14.74 4.26 19 9.5 19C14.74 19 19 14.74 19 9.5C19 4.26 14.74 0 9.5 0Z"
                            fill={themeTextRed}
                          ></path>
                          <path
                            d="M9.5 10V5"
                            stroke={themeTextRed}
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                          ></path>
                          <path
                            d="M9.5 13V12"
                            stroke={themeTextRed}
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                          ></path>
                        </svg>
                        <p>Please login to view Meal Plans</p>
                      </div>
                    ) : (
                      <></>
                    )}
                    <form>
                      <CustomInput
                        type="email"
                        onChange={(e) => {
                          dispatch({
                            type: "UPDATE_EMAIL",
                            payload: { email: e.target.value },
                          });
                          dispatch({ type: "VALIDATE_FORM" });
                        }}
                        placeholder="Type here"
                        value={state.email}
                        className="error"
                        label="Email"
                        name="email"
                        errorMessage={
                          state.emailFocusedOnce && state.emailError
                            ? state.emailError
                            : null
                        }
                        onBlur={() => {
                          dispatch({
                            type: "BLUR",
                            payload: { emailFocusedOnce: true },
                          });
                        }}
                      />
                      <div className="password-field">
                        <CustomInput
                          // type="password"
                          type={passwordVisible ? "text" : "password"}
                          label="Password"
                          placeholder="Type here"
                          onChange={(e) => {
                            dispatch({
                              type: "UPDATE_PASSWOORD",
                              payload: { password: e.target.value },
                            });
                            dispatch({ type: "VALIDATE_FORM" });
                          }}
                          name="password"
                          value={state.password}
                          isLastElementOfForm={true}
                          formLastElementAction={loginUserAction}
                          errorMessage={
                            state.passwordFocusedOnce && state.passwordError
                              ? state.passwordError
                              : null
                          }
                          onBlur={() => {
                            dispatch({
                              type: "BLUR",
                              payload: { passwordFocusedOnce: true },
                            });
                          }}
                        />
                        <div
                          className="show-password"
                          onClick={() => setPasswordVisible(!passwordVisible)}
                        >
                          <span style={{ top: "0px" }}>
                            {passwordVisible ? "Hide" : "Show"}
                          </span>
                        </div>
                      </div>
                      <div className="globalErrorWrapper">
                        {state.formError && (
                          <div className="errorText globalErrorText">
                            <FrameIcon></FrameIcon>
                            <p>{state.formError}</p>
                          </div>
                        )}
                      </div>
                      <Button
                        type="button"
                        isLoading={loginStatus.isLoggingIn}
                        disabled={!state.isFormValid || loginStatus.isLoggingIn}
                        btnClass="primary btn-md"
                        btnText="Login"
                        onClick={loginUserAction}
                      />
                      <div className="forgotPassword">
                        <a href={"/forget-password"}>Forgot your password?</a>
                      </div>
                    </form>
                  </div>
                </div>
              </Col>
            </TransitionEaseInOne>
            <TransitionEaseInOne>
              <Col
                xs={12}
                md={12}
                lg={6}
                className="image-bg-wrapper"
                style={{
                  backgroundImage: `url(${katie})`,
                }}
              ></Col>
            </TransitionEaseInOne>
          </Row>
        )}
      </section>
    </>
  );
};

Login.defaultProps = {};

const mapStateToProps = (state) => ({
  loginStatus: getAuthStatus(state),
  userDetails: getUserDetails(state),
  startUrl: state.getIn(["settings", "startUrl"]),
  pageCount: state.getIn(["settings", "count"]),
});

const dispatchActionToProps = (dispatch) => ({
  loginUser: bindActionCreators(loginUser, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Login));
