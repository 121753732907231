import React from "react";
import '../styles/common.scss';

const AppStore = (props) => {
  return (
    <>
      <svg width="104" height="38" viewBox="0 0 104 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3822 28.9893H4.86119L3.28953 33.5845H0.532227L6.70858 16.5986H9.57618L15.7525 33.5845H12.9401L11.3822 28.9893ZM5.53673 26.8695H10.7067L8.15617 19.4159H8.08724L5.53673 26.8695Z" fill="#000001" />
        <path d="M29.1117 27.3891C29.1117 31.2321 27.0299 33.7075 23.9004 33.7075C22.1219 33.7075 20.7157 32.9143 19.9712 31.5467H19.9161V37.6736H17.3518V21.1938H19.8334V23.2589H19.8747C20.5916 21.9323 22.1082 21.0707 23.8453 21.0707C27.0162 21.0707 29.1117 23.5461 29.1117 27.3891ZM26.4785 27.3891C26.4785 24.8863 25.1688 23.2315 23.1835 23.2315C21.2258 23.2315 19.9023 24.9137 19.9023 27.3891C19.9023 29.8782 21.2258 31.5603 23.1835 31.5603C25.1688 31.5603 26.4785 29.9192 26.4785 27.3891Z" fill="#000001" />
        <path d="M42.843 27.3891C42.843 31.2321 40.7613 33.7075 37.6317 33.7075C35.8533 33.7075 34.4471 32.9143 33.7026 31.5467H33.6474V37.6736H31.0969V21.1938H33.5785V23.2589H33.6199C34.3368 21.9323 35.8533 21.0707 37.5904 21.0707C40.7613 21.0707 42.843 23.5461 42.843 27.3891ZM40.2098 27.3891C40.2098 24.8863 38.9001 23.2315 36.9148 23.2315C34.9571 23.2315 33.6336 24.9137 33.6336 27.3891C33.6336 29.8782 34.9571 31.5603 36.9148 31.5603C38.9139 31.5603 40.2098 29.9192 40.2098 27.3891Z" fill="#000001" />
        <path d="M51.9283 28.8526C52.1213 30.5348 53.7619 31.6426 56.0229 31.6426C58.1874 31.6426 59.7315 30.5348 59.7315 29.0167C59.7315 27.7038 58.794 26.9106 56.5744 26.3635L54.3548 25.8302C51.2114 25.078 49.7501 23.6146 49.7501 21.2486C49.7501 18.3219 52.3281 16.3115 55.9816 16.3115C59.5936 16.3115 62.0752 18.3219 62.1579 21.2486H59.5661C59.4144 19.5528 57.9944 18.5271 55.9402 18.5271C53.886 18.5271 52.466 19.5665 52.466 21.0708C52.466 22.2744 53.3621 22.9718 55.568 23.5189L57.4567 23.9839C60.9723 24.8045 62.4337 26.2131 62.4337 28.6885C62.4337 31.8614 59.8832 33.8581 55.8299 33.8581C52.0386 33.8581 49.4743 31.9161 49.3089 28.8389H51.9283V28.8526Z" fill="#000001" />
        <path d="M67.9758 18.2673V21.194H70.3471V23.2045H67.9758V30.0289C67.9758 31.0956 68.4446 31.588 69.4924 31.588C69.7543 31.588 70.1679 31.5469 70.3333 31.5333V33.53C70.0438 33.5984 69.4786 33.6531 68.9133 33.6531C66.3904 33.6531 65.3978 32.7094 65.3978 30.3161V23.2181H63.5779V21.2077H65.3978V18.2673H67.9758Z" fill="#000001" />
        <path d="M71.712 27.3893C71.712 23.4916 74.0281 21.0436 77.6264 21.0436C81.2385 21.0436 83.5408 23.4916 83.5408 27.3893C83.5408 31.3007 81.2522 33.7351 77.6264 33.7351C74.0143 33.7351 71.712 31.3007 71.712 27.3893ZM80.9489 27.3893C80.9489 24.7225 79.7082 23.136 77.6402 23.136C75.5722 23.136 74.3314 24.7225 74.3314 27.3893C74.3314 30.0699 75.5722 31.6426 77.6402 31.6426C79.7082 31.6426 80.9489 30.0699 80.9489 27.3893Z" fill="#000001" />
        <path d="M85.6777 21.1941H88.1179V23.3003H88.1731C88.5591 21.9053 89.7034 21.0574 91.1786 21.0574C91.5508 21.0574 91.8541 21.1121 92.0609 21.1531V23.5328C91.8541 23.4507 91.4129 23.3823 90.9166 23.3823C89.2622 23.3823 88.242 24.4901 88.242 26.227V33.5711H85.6777V21.1941V21.1941Z" fill="#000001" />
        <path d="M103.862 29.9468C103.517 32.1897 101.312 33.7351 98.4854 33.7351C94.8596 33.7351 92.5986 31.3281 92.5986 27.444C92.5986 23.56 94.8596 21.0436 98.3751 21.0436C101.822 21.0436 104 23.3959 104 27.1568V28.0321H95.1904V28.1826C95.1904 30.3024 96.5277 31.6837 98.5543 31.6837C99.9744 31.6837 101.091 31.0135 101.436 29.9468H103.862V29.9468ZM95.2042 26.2405H101.45C101.394 24.3395 100.167 23.095 98.3889 23.095C96.6105 23.1087 95.3421 24.3806 95.2042 26.2405Z" fill="#000001" />
        <path d="M5.21965 0.811035C7.65986 0.811035 9.09366 2.30174 9.09366 4.8592C9.09366 7.47135 7.67365 8.96206 5.21965 8.96206H2.24176V0.811035H5.21965ZM3.52391 7.81326H5.068C6.79131 7.81326 7.78394 6.74651 7.78394 4.87287C7.78394 3.02658 6.77753 1.95984 5.068 1.95984H3.52391V7.81326Z" fill="#000001" />
        <path d="M10.5275 5.88529C10.5275 3.90224 11.6442 2.68506 13.4503 2.68506C15.2563 2.68506 16.373 3.88856 16.373 5.88529C16.373 7.88202 15.2563 9.08553 13.4503 9.08553C11.6442 9.08553 10.5275 7.88202 10.5275 5.88529ZM15.1184 5.88529C15.1184 4.54502 14.5118 3.76548 13.4503 3.76548C12.3887 3.76548 11.7821 4.54502 11.7821 5.88529C11.7821 7.22556 12.3749 8.00511 13.4503 8.00511C14.5256 8.00511 15.1184 7.22556 15.1184 5.88529Z" fill="#000001" />
        <path d="M24.1624 8.97583H22.894L21.6119 4.43533H21.5154L20.2332 8.97583H18.9786L17.2691 2.82153H18.5099L19.6266 7.52615H19.7231L21.0052 2.82153H22.1771L23.4593 7.52615H23.5558L24.6587 2.82153H25.8857L24.1624 8.97583Z" fill="#000001" />
        <path d="M27.3057 2.80777H28.4913V3.79246H28.5878C28.8911 3.10865 29.5115 2.69836 30.4352 2.69836C31.8139 2.69836 32.5859 3.51894 32.5859 4.98229V8.97575H31.3589V5.29685C31.3589 4.31216 30.9315 3.81981 30.0216 3.81981C29.1117 3.81981 28.5327 4.42157 28.5327 5.3789V8.97575H27.3057V2.80777V2.80777Z" fill="#000001" />
        <path d="M34.5298 0.414551H35.7568V8.97587H34.5298V0.414551Z" fill="#000001" />
        <path d="M37.4663 5.88529C37.4663 3.90224 38.583 2.68506 40.389 2.68506C42.1951 2.68506 43.3118 3.88856 43.3118 5.88529C43.3118 7.88202 42.1951 9.08553 40.389 9.08553C38.5692 9.08553 37.4663 7.88202 37.4663 5.88529ZM42.0572 5.88529C42.0572 4.54502 41.4506 3.76548 40.389 3.76548C39.3275 3.76548 38.7209 4.54502 38.7209 5.88529C38.7209 7.22556 39.3137 8.00511 40.389 8.00511C41.4506 8.00511 42.0572 7.22556 42.0572 5.88529Z" fill="#000001" />
        <path d="M44.6078 7.22519C44.6078 6.11742 45.435 5.47464 46.9101 5.39258L48.5921 5.29685V4.76347C48.5921 4.10702 48.1647 3.75143 47.3237 3.75143C46.6344 3.75143 46.1657 3.99761 46.0278 4.43524H44.8422C44.9662 3.38218 45.9727 2.69836 47.3789 2.69836C48.9368 2.69836 49.8191 3.46423 49.8191 4.76347V8.97575H48.6334V8.11415H48.5369C48.1647 8.72958 47.4892 9.08516 46.6758 9.08516C45.4763 9.07148 44.6078 8.36032 44.6078 7.22519ZM48.6059 6.7055V6.1858L47.0894 6.28153C46.2346 6.33624 45.8486 6.62344 45.8486 7.17049C45.8486 7.73121 46.3311 8.04577 46.9928 8.04577C47.9165 8.04577 48.6059 7.47136 48.6059 6.7055Z" fill="#000001" />
        <path d="M51.4321 5.88504C51.4321 3.94302 52.4385 2.71216 54.0101 2.71216C54.8649 2.71216 55.5818 3.10877 55.9127 3.79258H56.0092V0.414551H57.2362V8.97586H56.0505V8.00485H55.954C55.5818 8.67499 54.8649 9.08527 54.0101 9.08527C52.4247 9.0716 51.4321 7.84074 51.4321 5.88504ZM52.7004 5.88504C52.7004 7.19796 53.3208 7.9775 54.3548 7.9775C55.3888 7.9775 56.023 7.18428 56.023 5.88504C56.023 4.59947 55.375 3.79258 54.3548 3.79258C53.3208 3.80625 52.7004 4.59947 52.7004 5.88504Z" fill="#000001" />
        <path d="M62.2958 5.88529C62.2958 3.90224 63.4125 2.68506 65.2185 2.68506C67.0245 2.68506 68.1413 3.88856 68.1413 5.88529C68.1413 7.88202 67.0245 9.08553 65.2185 9.08553C63.4125 9.08553 62.2958 7.88202 62.2958 5.88529ZM66.8867 5.88529C66.8867 4.54502 66.2801 3.76548 65.2185 3.76548C64.157 3.76548 63.5503 4.54502 63.5503 5.88529C63.5503 7.22556 64.1432 8.00511 65.2185 8.00511C66.2801 8.00511 66.8867 7.22556 66.8867 5.88529Z" fill="#000001" />
        <path d="M69.7957 2.80777H70.9813V3.79246H71.0778C71.3811 3.10865 72.0015 2.69836 72.9252 2.69836C74.3039 2.69836 75.0759 3.51894 75.0759 4.98229V8.97575H73.8489V5.29685C73.8489 4.31216 73.4215 3.81981 72.5116 3.81981C71.6017 3.81981 71.0227 4.42157 71.0227 5.3789V8.97575H69.7957V2.80777Z" fill="#000001" />
        <path d="M81.9967 1.276V2.83509H83.3478V3.86081H81.9967V7.03369C81.9967 7.67647 82.2587 7.96367 82.8791 7.96367C83.0721 7.96367 83.1686 7.95 83.3478 7.93632V8.94836C83.1548 8.97571 82.9204 9.01674 82.6861 9.01674C81.3212 9.01674 80.7835 8.53807 80.7835 7.34824V3.87448H79.8047V2.84877H80.7835V1.276H81.9967Z" fill="#000001" />
        <path d="M85.0022 0.414551H86.2154V3.80625H86.3119C86.6152 3.12244 87.2632 2.69848 88.2007 2.69848C89.5242 2.69848 90.3376 3.53273 90.3376 4.99609V8.97587H89.1106V5.29696C89.1106 4.31227 88.6556 3.81993 87.7871 3.81993C86.7807 3.81993 86.2292 4.44904 86.2292 5.37902V8.97587H85.0022V0.414551Z" fill="#000001" />
        <path d="M97.4928 7.30725C97.2171 8.41502 96.2244 9.08516 94.8044 9.08516C93.026 9.08516 91.9368 7.88165 91.9368 5.91228C91.9368 3.9429 93.0535 2.69836 94.8044 2.69836C96.5277 2.69836 97.5755 3.87452 97.5755 5.80287V6.22683H93.1914V6.29521C93.2328 7.37563 93.8669 8.05944 94.8458 8.05944C95.5902 8.05944 96.1004 7.79959 96.3209 7.30725H97.4928ZM93.1914 5.3242H96.3209C96.2934 4.35319 95.7005 3.72408 94.7906 3.72408C93.8807 3.72408 93.2603 4.36686 93.1914 5.3242Z" fill="#000001" />
      </svg>

    </>
  );
};
export default AppStore;
