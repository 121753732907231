import createReducer from "../helpers/createReducers";
import { fromJS } from "immutable";

const initialState = fromJS({
  activeWorkout: {},
  relatedWorkouts: [],
  categories: [],
  fetchWorkoutSlug: null,
  forUser: null
})

export default createReducer(initialState, {
  // check needed
  GET_SINGLE_WORKOUT_DETAILS_SUCCESS: (state, action) => {
    // First time fetch, set active recipe as the one which is fetched
    state = state.setIn(['activeWorkout'], fromJS(action.payload.workoutData));

    return state;
  },

  GET_SINGLE_WORKOUT_META_SUCCESS: (state, action) => {
    state = state.setIn(['categories'], fromJS(action.payload.categoryData));

    state = state.setIn(['relatedWorkouts'], fromJS(action.payload.relatedWorkoutData));

    state = state.setIn(['forUser'], action.payload.forUser)

    return state
  },
  // For fav icon reactivity in single recipe page
  CREATE_FAVOURITE_TO_OBJECT_SUCCESS: (state, action) => {
    return state.setIn(['activeWorkout', 'favorite'], true)
  },
  // For fav icon reactivity in single recipe page
  DELETE_FAVOURITE_SUCCESS: (state, action) => {
    return state.setIn(['activeWorkout', 'favorite'], false)
  },

  CREATE_COMMENT_TO_OBJECT_SUCCESS: (state, action) => {
    if(action.payload.objectType == 'workout'){
      // for removing rating input stars is user gives rating
      let newComment = fromJS(action.payload.commentObject)
      if (newComment.getIn(['rating']) !== null) {
        state = state.setIn(['activeWorkout', 'is_rated'], true)
      }
      // for updating recipe rating reactivily
      let updatedRating = fromJS(action.payload.updatedRating)
      if (updatedRating !== null) {
        state = state.setIn(['activeWorkout', 'avg_rating'], updatedRating)
      }
    }
    return state
  },

  POP_OBJECT_FROM_NAVIGATION_STACK_SUCCESS: (state, action) => {
    if(action.payload.objectType == 'workout'){
      state =  state.setIn(['activeWorkout'], fromJS(action.payload.activeObject.activeWorkout));
      state =  state.setIn(['relatedWorkouts'], fromJS(action.payload.activeObject.relatedWorkouts));
      state =  state.setIn(['categories'], action.payload.activeObject.categories);
      state =  state.setIn(['fetchWorkoutSlug'], action.payload.activeObject.fetchWorkoutSlug);
      state =  state.setIn(['forUser'], action.payload.activeObject.forUser);
    }
    return state
  }
})