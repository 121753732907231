import React from "react";
import map from "lodash/map";
import ReactMarkdown from "react-markdown";
import Loader from "../../images/loader";
import ReactImageFallback from "react-image-fallback";

const RecipeMethods = (props) => {
  const { method, cookTime, fetchStatus } = props;
  if (fetchStatus.objectDetails.recipe[0].isFetching) {
    return <Loader height="600px"></Loader>;
  } else {
    return (
      <div className="recipeMethod">
        <h2>
          Method
          <small>
            The estimated total time to make this recipe is{" "}
            {cookTime ? cookTime : "-"}
          </small>
        </h2>
        <ol>
          {map(method, (instruction, index) => {
            return (
              <div key={index}>
                <ReactMarkdown
                  source={instruction.directions}
                  linkTarget="_blank"
                />
                <figure
                  key={
                    instruction && instruction.images
                      ? instruction.images.large
                      : ""
                  }
                >
                  <img
                    src={
                      instruction && instruction.images
                        ? instruction.images.large
                        : ""
                    }
                  />
                </figure>
              </div>
            );
          })}
        </ol>
      </div>
    );
  }
};

export default RecipeMethods;
