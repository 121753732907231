import React from 'react'
import '../styles/common.scss';

const FacebookIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.02002 0H23.02C23.58 0 24.02 0.45 24.02 1V23C24.02 23.55 23.57 24 23.02 24H17.02C16.74 24 16.52 23.78 16.52 23.5V15.5C16.52 15.22 16.74 15 17.02 15H19.07C19.32 15 19.54 14.81 19.57 14.56L19.95 11.56C19.97 11.42 19.93 11.28 19.83 11.17C19.73 11.06 19.59 11 19.45 11H17.02C16.74 11 16.52 10.78 16.52 10.5V9.19C16.52 8.26 17.28 7.5 18.21 7.5H19.52C19.8 7.5 20.02 7.28 20.02 7V4C20.02 3.87 19.96 3.74 19.87 3.65C19.78 3.55 19.65 3.5 19.52 3.5H18.21C15.07 3.5 12.52 6.05 12.52 9.19V10.5C12.52 10.78 12.3 11 12.02 11H10.02C9.74002 11 9.52002 11.22 9.52002 11.5V14.5C9.52002 14.78 9.74002 15 10.02 15H12.02C12.3 15 12.52 15.22 12.52 15.5V23.5C12.52 23.78 12.3 24 12.02 24H1.02002C0.47002 24 0.0200195 23.55 0.0200195 23V1C0.0200195 0.45 0.47002 0 1.02002 0Z" fill="#6B6D71" />
    </svg>
  )
}

export default FacebookIcon; 