import { select, put, call, all, delay } from "redux-saga/effects";
import axios from "axios";

import countBy from "lodash/countBy";
import isEmpty from "lodash/isEmpty";
import {
  getEssentialsCachedForUser,
  getToken,
  getEssentialsFetchedOnce,
  getFaqs,
  getPlans,
} from "../selectors";

import { showToastMessage } from "../../actions";

import { faqs, auth } from "../../helpers/api";

import { get } from "../../helpers/requests";

import recipesTask from "../subtask/recipes";
import workoutsTask from "../subtask/workouts";
import blogsTask from "../subtask/blogs";
import videosTask from "../subtask/videos";

import * as actions from "../../actions/actionTypes";
import pushToSentry from "../../helpers/pushToSentry";
import compareFilters from "./compareFiltersProcess";

import retrier from "./retrier";

function* fetchRecipesList(payload) {
  // TODO: In redux, keep caching logic separate for every single vertical.
  // TODO: Since we have different boundaries for each vertical it is better to keep that way.
  let tokenByAuthenticatedUser = yield select(getToken); // get current authEssential (which is loginInUser in store)
  let essentialsForUser = yield select(getEssentialsCachedForUser); //get forUser in essentials
  let essentialsFetchedOnce = yield select(getEssentialsFetchedOnce); // Essentials fetched once

  if (
    payload &&
    payload.recipe &&
    !payload.recipe.recipeSlug &&
    payload.workout &&
    !payload.workout.workoutSlug &&
    payload.blog &&
    !payload.blog.blogSlug &&
    payload.video &&
    !payload.video.videoSlug
  ) {
    yield put({
      type: actions.RECIPES_LIST_FETCH_REQUEST,
      payload: {
        listType: "all",
      },
    });

    yield call(compareFilters.recipe, payload.recipe);

    if (
      !essentialsFetchedOnce || // This happens when atleast the data is fetched once
      essentialsForUser !== tokenByAuthenticatedUser
    ) {
      payload.recipe.recipeRefreshGroup = "unfiltered";
    }

    const recipesData = yield call(recipesTask.getAll, payload.recipe);

    yield put({
      type: actions.RECIPES_LIST_FETCH_SUCCESS,
      payload: {
        recipesData,
        forUser: tokenByAuthenticatedUser,
        pagination: false,
        listType: "all",
      },
    });

    return true;
  }
  // If caching changes
}

function* fetchWorkoutsList(payload) {
  // // TODO: Repeat the same for workouts
  let tokenByAuthenticatedUser = yield select(getToken); // get current authEssential (which is loginInUser in store)
  let essentialsForUser = yield select(getEssentialsCachedForUser); //get forUser in essentials
  let essentialsFetchedOnce = yield select(getEssentialsFetchedOnce); // Essentials fetched once

  if (
    payload &&
    payload.recipe &&
    !payload.recipe.recipeSlug &&
    payload.workout &&
    !payload.workout.workoutSlug &&
    payload.blog &&
    !payload.blog.blogSlug &&
    payload.video &&
    !payload.video.videoSlug
  ) {
    yield put({
      type: actions.WORKOUTS_LIST_FETCH_REQUEST,
      payload: {
        listType: "all",
      },
    });

    // Set filters
    yield call(compareFilters.workout, payload.workout);

    if (
      !essentialsFetchedOnce || // This happens when atleast the data is fetched once
      essentialsForUser !== tokenByAuthenticatedUser
    ) {
      payload.blog.workoutRefreshGroup = "unfiltered";
    }

    // fetch workouts data
    const workoutsData = yield call(workoutsTask.getAll, payload.workout);

    yield put({
      type: actions.WORKOUTS_LIST_FETCH_SUCCESS,
      payload: {
        workoutsData,
        forUser: tokenByAuthenticatedUser,
        listType: "all",
      },
    });

    return true;
  }
}

function* fetchBlogsList(payload) {
  let tokenByAuthenticatedUser = yield select(getToken); // get current authEssential (which is loginInUser in store)
  let essentialsForUser = yield select(getEssentialsCachedForUser); //get forUser in essentials
  let essentialsFetchedOnce = yield select(getEssentialsFetchedOnce); // Essentials fetched once

  if (payload) {
    yield put({
      type: actions.BLOGS_LIST_FETCH_REQUEST,
      payload: {
        listType: "all",
      },
    });

    yield call(compareFilters.blog, payload.blog);

    if (
      !essentialsFetchedOnce || // This happens when atleast the data is fetched once
      essentialsForUser !== tokenByAuthenticatedUser
    ) {
      payload.blog.blogRefreshGroup = "unfiltered";
    }

    const blogsData = yield call(blogsTask.getAll, payload.blog);

    yield put({
      type: actions.BLOGS_LIST_FETCH_SUCCESS,
      payload: {
        blogsData,
        forUser: tokenByAuthenticatedUser,
        pagination: false,
        listType: "all",
      },
    });

    return true;
  }
  // If caching changes
}

function* fetchVideosList(payload) {
  let tokenByAuthenticatedUser = yield select(getToken); // get current authEssential (which is loginInUser in store)
  let essentialsForUser = yield select(getEssentialsCachedForUser); //get forUser in essentials
  let essentialsFetchedOnce = yield select(getEssentialsFetchedOnce); // Essentials fetched once

  if (
    payload &&
    payload.recipe &&
    !payload.recipe.recipeSlug &&
    payload.workout &&
    !payload.workout.workoutSlug &&
    payload.blog &&
    !payload.blog.blogSlug &&
    payload.video &&
    !payload.video.videoSlug
  ) {
    yield put({
      type: actions.VIDEOS_LIST_FETCH_REQUEST,
      payload: {
        listType: "all",
      },
    });

    yield call(compareFilters.video, payload.video);

    if (
      !essentialsFetchedOnce || // This happens when atleast the data is fetched once
      essentialsForUser !== tokenByAuthenticatedUser
    ) {
      payload.video.videoRefreshGroup = "unfiltered";
    }

    const videosData = yield call(videosTask.getAll, payload.video);

    yield put({
      type: actions.VIDEOS_LIST_FETCH_SUCCESS,
      payload: {
        videosData,
        forUser: tokenByAuthenticatedUser,
        pagination: false,
        listType: "all",
      },
    });

    return true;
  }
}

function* fetchFaqsList(payload) {
  let tokenByAuthenticatedUser = yield select(getToken); // get current authEssential (which is loginInUser in store)
  const existingFaqs = yield select(getFaqs);
  if (existingFaqs && existingFaqs.size === 0) {
    yield put({
      type: actions.FAQS_LIST_FETCH_REQUEST,
    });

    const faqsResponse = yield call(
      axios,
      get(faqs.get(), "", tokenByAuthenticatedUser)
    );

    yield put({
      type: actions.FAQS_LIST_FETCH_REQUEST_SUCCESS,
      payload: {
        data: faqsResponse.data,
      },
    });

    return true;
  }
}

function* fetchPlansList(payload) {
  let tokenByAuthenticatedUser = yield select(getToken); // get current authEssential (which is loginInUser in store)

  const existingPlans = yield select(getPlans);

  if (!existingPlans || existingPlans.isEmpty()) {
    yield put({
      type: actions.PLANS_LIST_FETCH_REQUEST,
    });

    const plansResponse = yield call(axios, get(auth.getPlans(true)));

    yield put({
      type: actions.PLANS_LIST_FETCH_SUCCESS,
      payload: {
        data: plansResponse.data,
      },
    });

    return true;
  }
}

export default function* listItemsFetch(payload) {
  // Boundary for all essentials
  // Not needed apart from debugging
  try {
    yield put({
      type: actions.ESSENTIALS_LIST_ITEMS_FETCH,
    });

    let listResponse = [];
    let otherListResponse = [];

    try {
      let listResponse = yield call(
        retrier,
        [
          call(fetchRecipesList, payload),
          call(fetchWorkoutsList, payload),
          call(fetchBlogsList, payload),
          call(fetchVideosList, payload),
        ],
        "object list"
      );

      let otherListResponse = yield call(
        retrier,
        [call(fetchFaqsList, payload), call(fetchPlansList, payload)],
        "additional list"
      );

      if (!listResponse || !otherListResponse) {
        const eventId = pushToSentry("Internet connection seems to be down");
        yield put({
          type: actions.ADD_SENTRY_ERROR,
          payload: {
            error: "Internet connection seems to be down",
            eventId,
          },
        });
      }
    } catch (e) {
      const eventId = pushToSentry(e);
      yield put({
        type: actions.ADD_SENTRY_ERROR,
        payload: {
          error: e.message,
          eventId,
        },
      });
    }

    yield put({
      type: actions.ESSENTIALS_LIST_ITEMS_FETCH_SUCCESS,
    });
  } catch (e) {
    const eventId = pushToSentry(e);
    yield put({
      type: actions.ADD_SENTRY_ERROR,
      payload: {
        error: e.message,
        eventId,
      },
    });
    yield put(
      showToastMessage(
        `❗️ Couldn't find a page with specified path. Redirecting to the Home`,
        "error"
      )
    );
    yield put({
      type: actions.ESSENTIALS_LIST_ITEMS_FETCH_FAILURE,
      payload: {
        message: e.message,
      },
    });
  }
}
