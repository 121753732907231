import React from "react";
import cx from "classnames";
import filter from "lodash/filter";
import { Row, Col } from "react-flexbox-grid";
import VerticalListWithTitle from "./common/verticalListWithTitle";
import NavbarImageCard from "./common/navbarImageCard";
import NavDropdownImage from "./common/NavDropdownImage";
import video1 from "../../images/video-nav-image1.png";
import video2 from "../../images/video-nav-image2.png";
import video3 from "../../images/video-nav-image3.png";
import video4 from "../../images/video-nav-image4.png";
import video5 from "../../images/video-nav-image5.png";

const listOne = (videoCategories) => {
  const getTaxonomy = (name) =>
    filter(videoCategories, (taxonomy) => taxonomy.name == name)[0];

  const getImage = (taxonomy) =>
    taxonomy && taxonomy.latest_video
      ? taxonomy.latest_video.images.medium
      : "";

  const getSlug = (taxonomy) => (taxonomy ? taxonomy.slug : "");
  return [
    {
      image: video1,
      title: "Cooking Shows",
      description: "Full 30 minutes episode of katie in kitchen",
      href: `/videos?categories=cooking-shows`,
    },
    {
      image: video2,
      title: "Healthy Eating",
      description: "Get started with the basics of healthy eating",
      href: `/videos?categories=${getSlug(getTaxonomy("Healthy Eating"))}`,
    },
    {
      image: video3,
      title: "Meal Ideas",
      description: "Be inspired by meal ideas of all the occasions",
      href: `/videos?categories=${getSlug(getTaxonomy("Meal Ideas"))}`,
    },
    {
      image: video4,
      title: "Tips & Tricks",
      description: "Learn the ins and outs of kitchen skills",
      href: `/videos?categories=${getSlug(getTaxonomy("Tips"))}`,
    },
    {
      image: video5,
      title: "Devotionals",
      description: "Bite size devotionals to strengthen your faith",
      href: `/videos?categories=${getSlug(getTaxonomy("Devotionals"))}`,
    },
  ];
};

const VideosNavDropdown = ({
  videoCategories,
  history,
  activeHoveredEntry,
  requestCloseNavDropdown,
}) => (
  <div className="container">
    <div className="navbar-dropdown-scroller">
      <Row className="videos-dropdown navbar-dropdown-wrapper no-margin">
        <Col sm={12} lg={12} className="no-padding">
          <NavbarImageCard
            history={history}
            list={listOne(videoCategories)}
            render={(item) => (
              <NavDropdownImage type="video" src={item.image} />
            )}
            onClose={requestCloseNavDropdown}
          />
        </Col>
      </Row>
    </div>
  </div>
);

export default VideosNavDropdown;
