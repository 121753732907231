import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";
import moment from "moment";

import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import toJS from '../../../helpers/toJS';
import { calendarListData } from "../../../selectors/calendar";
import { getCalendarMacros } from "../../../selectors/calendar";
import { appEssentialFetch } from "../../../actions";
import PrintIcon from "../../images/PrintIcon";
import Logo from "../../images/Logo";

/**
 * Placeholder file
 * @param {*} props 
 * @returns 
 */

const Print = (props) => {
  useEffect(() => {
    props.appEssentialFetch();
  }, []);

  const renderData = () => map(props.calendarListData, day => {
    const startDate = props.location.state.startDate;
    const endDate = props.location.state.endDate 
    if (day.date >= startDate && day.date <= endDate && day.meals && day.meals.length > 0) {
      let commaCount = 0;
      return (
        <div className="print-calender">
          <div className="each-date">
            <div className="date-header pre">
              <div className="details">
                <div className="title" style={{ fontWeight: 'bold', borderBottom: "1px solid black", display: "inline-block" }}>
                  {`${moment(day.date, 'YYYY-MM-DD').format('dddd')} ${moment(day.date, 'YYYY-MM-DD').format('MMMM Do YYYY')}`}
                </div>
                <div className={'stats'}>
                  {
                    (findIndex(props.setMacros, macro => macro === 'calories') !== -1) ? (
                      <span>Cal {day.statistics.calories ? day.statistics.calories : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'carbs') !== -1) ? (
                      <span>Carbs {day.statistics.carbs ? `${day.statistics.carbs} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'fat') !== -1) ? (
                      <span>Fat {day.statistics.fat ? `${day.statistics.fat} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'sugar') !== -1) ? (
                      <span>Sug {day.statistics.sugar ? `${day.statistics.sugar} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'fiber') !== -1) ? (
                      <span>F {day.statistics.fiber ? `${day.statistics.fiber} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'protein') !== -1) ? (
                      <span>Pro {day.statistics.protein ? `${day.statistics.protein} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'saturatedFat') !== -1) ? (
                      <span>Sat Fat {day.statistics.saturatedFat ? `${day.statistics.saturatedFat} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'smartPoints') !== -1) ? (
                      <span>SmartPoints® {day.statistics.smartPoints ? `${day.statistics.smartPoints} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'freestylePoints') !== -1 && day.statistics.freestylePoints >= 0) ? (
                      <span>Freestyle Points {day.statistics.freestylePoints ? `${day.statistics.freestylePoints} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                  {
                    (findIndex(props.setMacros, macro => macro === 'wwp') !== -1 && day.statistics.wwp >= 0) ? (
                      <span>WWP+ {day.statistics.wwp ? `${day.statistics.wwp} g` : '0 g'}{++commaCount < props.setMacros.length ? ' | ' : ''}&nbsp;</span>
                    ) : null
                  }
                </div>
              </div>
            </div>
            {
              map(day.meals, (meal) => (
                <div
                  className="calendarLists"
                  style={{ margin: "10px 0px" }}
                >
                  <div key={meal.id} className="calendarItem">
                    <div className="itemList">
                      <div className="title">
                        {
                          !isEmpty(meal.recipe) ? (
                            <div
                              className="no-highlight"
                            >
                              {meal.recipe.title}
                            </div>
                          ) : (
                              <div
                                className="no-highlight"
                              >
                                {meal.workout.title}
                              </div>
                            )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )
    }
  })

  return (
    <div className="container">
      <Helmet>
        <title>Print Calendar | Dashing Dish</title>
        <meta name="description" content="Dashingdish Print Calender page" />
      </Helmet>
      <div class="print-page_back-link">
        <a
          onClick={(e) => {
            e.preventDefault();
            props.history.push(`/calendar`)
          }}
          href="/calendar"
          className="">
          ‹ Back to the Mealplan
        </a>
      </div>
      <div className="print-page_header">
        <Logo />
        <figure className="print-button" onClick={(e) => window.print()}>
          <PrintIcon />
        </figure>
      </div>
      <div class="print-page_content">
        <div className="print_content-left">
          {renderData()}
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  setMacros: getCalendarMacros(state),
  calendarListData: calendarListData(state),
})

const dispatchActionToProps = dispatch => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Print));
