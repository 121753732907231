import React, { useState, useEffect } from 'react'
import { StickyContainer, Sticky } from 'react-sticky';
import PropTypes from 'prop-types'
import DownArrow from '../../images/DownArrow'
import SearchInput from '../searchInput'
import isNull from 'lodash/isNull'
import svgInject from '@iconfu/svg-inject'

import cx from 'classnames'
import DottedIcon from '../../images/DottedIcon'
import PlusIcon from '../../images/PlusIcon'
import isEmpty from 'lodash/isEmpty'
import { generateAppEssentialPayload } from '../../../helpers/filterConversions'
import CloseIcon from '../../images/closeIcon';

const ref = React.createRef();

const DesktopView = ({
  options,
  actions,
  placeholder,
  onSearchChange,
  searchText,
  favoriteOptions,
  updateFavouriteBoard,
  deleteFavouriteBoard,
  createBoard,
  history,
  staticOption,
  isFavorites,
  seeAlltext,
  onSeeAllClick,
  defaultSearchBy,
  recipesSearchCount,
  onSearchByChange,
  objectType,
  showDefaultInput,
  isAuthenticated,
  showFilters,
  clearSearchText,
  withRoute,
  showClearText,
  isFeatured,
  ...props
}) => {

  // generated the selected filters form query param
  const filterFromQueryString = generateAppEssentialPayload({
    objectType: objectType,
    queryString: (history && history.location) ? history.location.search : '',
  })

  const [state, setState] = useState({
    showDropdown: null,
    editBoardModal: null,
    showFavoritesDropdown: false,
    initialSearchValue: isEmpty(filterFromQueryString.recipe.filters.search) ? '' : filterFromQueryString.recipe.filters.search[0],
    editText: null,
  })

  useEffect(() => {
    setState({
      ...state,
      initialSearchValue: isEmpty(filterFromQueryString.recipe.filters.search) ? '' : filterFromQueryString.recipe.filters.search[0]
    })
  }, [filterFromQueryString.recipe.filters.search[0]]);
  //ALL USE-EFFECTS
  // to handle outside click
  useEffect(() => {
    document.addEventListener('mousedown', handleClick, false)
  }, [])

  // ALL LOGIC FUNCTIONS  
  // to handle outside click
  const handleClick = (e) => {
    if (!isNull(ref) && !isNull(ref.current) && !ref.current.contains(e.target)) {
      setState({ ...state, showDropdown: null, showFavoritesDropdown: false })

    }
  }

  // when we click a dropdown item
  const onDropdownItemClick = (label, slug, dropdownOptions) => {
    const { title, singleSelectable } = dropdownOptions

    // action for the parent
    actions(label, slug, title)

    //condition for close dropdown on select
    // if (singleSelectable) {
    // setState({ ...state, showDropdown: null })
    // }
  }

  // what happens on edit fav board
  const onEditBoradInputChange = (e, value) => {

    // on enter
    if (e.keyCode === 13) {
      updateFavouriteBoard({ boardId: value, title: e.target.value, objectType: objectType })
      setState({ ...state, editBoardModal: null, editText: e.target.value })
    }
  }

  // what happen on create fav board
  const createBoardOnEnter = (e) => {
    // on enter
    if (!isEmpty(e.target.value) && e.keyCode === 13) {
      createBoard(e.target.value, objectType)
      setState({ ...state, showFavoritesDropdown: false, editText: null })
    }
  }

  // what happens on fav board click
  const onFavoriteBoardClick = (slug) => {
    if (slug !== null) {
      if (withRoute) {
        history.push(`/${objectType}s/favorites/${slug}`)
      } else {
        props.onClickFavoriteBoard(slug);
      }
    } else {
      if (withRoute) {
        history.push(`/${objectType}s/favorites`)
      } else {
        props.onClickFavoriteBoard(null);
      }
    }
    setState({ ...state, showFavoritesDropdown: false })
  }

  const onClickStaticText = () => {
    clearSearchText()
    if (withRoute) {
      history.push(staticOption.route)
    } else {
      props.onClickClearFilter()
    }

  }

  // ALL SUBRENDERS
  // renders the optios heading dropdown
  const renderOptionsDropdown = (dropdownOptions) => {

    // only map is isOptionsList is true
    let filterItemsArray
    if (dropdownOptions.isOptionsList) {
      filterItemsArray = dropdownOptions.options.map((option, index) => {
        return (
          <li
            key={index}
            className={cx('filterList', { 'selected': option.isSelected })}
            onClick={(e) => {
              onDropdownItemClick(option.label, option.slug, dropdownOptions)
            }}
          >
            <div className="filterWrap">
              {
                option.image ? (
                  <figure className="icon-wrapper">
                    <img src={option.image} className="taxonomy-icon" />
                  </figure>
                ) : (
                    option.svg ? <option.svg /> : null
                  )
              }
              <p>
                {option.label}
              </p>
              <span className="filter-remover">
                <CloseIcon></CloseIcon>
              </span>
            </div>
          </li >
        )
      })
    }

    return (
      <ul
        className={`filterOption ${dropdownOptions.additionalDropdownWrapperClass}`}
        ref={ref}
      >
        {/* non-mappable dropdown condition */}
        {dropdownOptions.isOptionsList ? filterItemsArray : dropdownOptions.options}
      </ul>
    )
  }


  // renders the options heading like collections , categories ...
  const renderFilterRow = () => {
    const filterItemsArray = options.map((option, index) => {
      return (
        <>
          <li
            key={option.title}
            className={`navList ${option.isSelected || (state.showDropdown === index) ? 'active' : ''}`}
            onClick={(e) => {
              setState({ ...state, showDropdown: index })
            }}>
            <span>
              {option.title}
              <DownArrow />
            </span>
            {/* Only render the dropdown based on condition */}
          </li>
          {
            state.showDropdown === index ?
              renderOptionsDropdown(option)
              : null
          }
        </>
      )
    })

    return filterItemsArray
  }

  // renders the fav heading
  const renderFavorites = () => {
    return (
      <>
        <li
          className={`navList ${favoriteOptions.isSelected || state.showFavoritesDropdown ? 'active' : ''}`}
        >
          <a
            href={
              (objectType === "video") ?
                `/${objectType}s/favorites`
                :
                objectType === 'blog' ? `/${objectType}/fav-boards` : `/${objectType}s/fav-boards`
            }
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              if (objectType === "video") {
                history.push(`/${objectType}s/favorites`)
              } else {
                objectType === 'blog' ? history.push(`/${objectType}/fav-boards`) : history.push(`/${objectType}s/fav-boards`);
              }
            }}
          >
            <span>
              {favoriteOptions.title}
            </span>
          </a>
        </li>

      </>
    )
  }

  // renders the fav dropdown
  const renderFavoritesDropdown = () => {
    let favoriteOptionsMap = favoriteOptions.options.map((option, index) => {
      return (
        <div key={option.id}>
          {renderFavoriteListContent(index, option)}
        </div>
      )
    })

    return favoriteOptionsMap
  }

  // renders the fav list content in the fav dropdown and all its logic like edit , delete ...
  const renderFavoriteListContent = (index, option) => {

    return (
      <div className="optionList">
        {/* condition to render the board name */}
        {(state.editBoardModal !== option.value) || isNull(option.value) ?
          <li
            key={option.value}
            onClick={() => {
              onFavoriteBoardClick(option.slug)
            }}
            className={option.isSelected ? 'favoriteItem selected' : 'favoriteItem'}
          >

            {option.name}

          </li> : null}
        {/* condition to render the edit board input */}
        {(state.editBoardModal === option.value) && !isNull(option.value) ?
          <input
            style={{ width: "100%" }}
            placeholder={option.name}
            onKeyUp={(e) => {
              onEditBoradInputChange(e, option.value)
            }}
            onChange={(e) => {
              setState({
                ...state,
                editText: e.target.value
              })
            }}
            autoFocus
            value={state.editText}
          /> : null}
        {!isNull(option.value) ?
          <DottedIcon
            options={[
              {
                label: 'Edit',
                action: () => {
                  setState({ ...state, editBoardModal: option.value, editText: option.name })
                }
              },
              {
                label: 'Delete',
                action: () => deleteFavouriteBoard({ boardId: option.value, objectType: objectType })
              }
            ]}
          />
          : null}
      </div>

    )
  }

  // renders static option
  const renderStaticText = () => {
    return (
      <li
        className='navList clearFilters'
        onClick={() => onClickStaticText()}
      >
        {(isFavorites || isFeatured) ? staticOption.secondaryOption : staticOption.primaryOption}
      </li>
    )
  }

  // MAIN RETURN
  return (
    <>
      <ul className='nav-filtersList'>
        {isAuthenticated ? renderFavorites() : null}
        {/* condition to render filter row , categories .... */}
        {(!isFavorites && showFilters) ? renderFilterRow() : renderStaticText()}
        {/* condition to render the static option */}
        {(((location.pathname === staticOption.route || location.pathname === staticOption.secondaryRoute) && isEmpty(location.search)) || !showFilters || !showClearText) ? null : (!isFavorites ? renderStaticText() : null)}
      </ul>
    </>

  )
}

DesktopView.propTypes = {
  options: PropTypes.array,
  action: PropTypes.func,
  favoriteOptions: PropTypes.object,
  updateFavouriteBoard: PropTypes.func,
  deleteFavouriteBoard: PropTypes.func,
  createBoard: PropTypes.func,
  staticOption: PropTypes.object,
  isFavorites: PropTypes.bool,
  onSearchByChange: PropTypes.func,
  objectType: PropTypes.string,
  withRoute: PropTypes.bool,
  onClickFavoriteBoard: PropTypes.func
}

DesktopView.defaultProps = {
  options: [],
  action: () => { },
  favoriteOptions: {},
  updateFavouriteBoard: () => { },
  deleteFavouriteBoard: () => { },
  createBoard: () => { },
  staticOption: {},
  isFavorites: false,
  onSearchByChange: () => { },
  withRoute: true,
  onClickFavoriteBoard: () => { },
  onClickClearFilter: () => { },
  showClearText: true
}

export default DesktopView