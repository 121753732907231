import React from "react";
import '../styles/common.scss';

const HighIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" className="dynamic-Icon">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <rect class="highIcon-1" width="48" height="48" />
                    <path class="highIcon-2" d="M40.72,39a1,1,0,0,1-.47-.12l-6.59-3.57a1,1,0,0,1-.4-1.36,10.51,10.51,0,1,0-18.51,0,1,1,0,0,1-.4,1.36L7.75,38.86a1,1,0,0,1-1.36-.4,20,20,0,1,1,35.21,0A1,1,0,0,1,40.72,39Zm-5.27-5,4.84,2.62a18,18,0,1,0-32.59,0L12.54,34a12.51,12.51,0,1,1,22.91,0Z" />
                    <path class="highIcon-2" d="M16.36,21.39a1,1,0,0,1-.71-.29l-5.3-5.3a1,1,0,0,1,1.41-1.41l5.3,5.3a1,1,0,0,1-.71,1.71Z" />
                    <path class="highIcon-2" d="M31.64,21.39a1,1,0,0,1-.71-1.71l5.3-5.3a1,1,0,0,1,1.41,1.41l-5.3,5.3A1,1,0,0,1,31.64,21.39Z" />
                    <path class="highIcon-2" d="M24,39a5,5,0,1,1,5-5A5,5,0,0,1,24,39Zm0-8a3,3,0,1,0,3,3A3,3,0,0,0,24,31Z" />
                    <path class="highIcon-2" d="M27.24,32.68a1,1,0,0,1-.59-1.81l4.87-3.51A1,1,0,0,1,32.69,29L27.83,32.5A1,1,0,0,1,27.24,32.68Z" />
                </g>
            </g>
        </svg>
    );
};
export default HighIcon;

