
import * as Sentry from '@sentry/browser';
import isEmpty from 'lodash/isEmpty'

export default (error, state, customMessage = "N/A", stackData = "N/A") => {
  let eventId = null;
  Sentry.withScope((scope) => {
    
    if (!isEmpty(state)) {
      if (isEmpty(state.loggedInUser.details)) {
        scope.setUser({ id: 'no user', email: 'no email', customMessage, stackData });  
      }
    }
    eventId = Sentry.captureException(error);
  });
  return eventId
}