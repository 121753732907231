// @flow
import { LOGIN_USER } from "./actionTypes";

export default (email: string, password: string, rememberMe: Boolean, firstTime: Boolean) => ({
  type: LOGIN_USER,
  payload: {
    email,
    password,
    rememberMe,
    firstTime
  }
});