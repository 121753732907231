import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import Button from "../common/button";
import toJS from '../../helpers/toJS';
import {
  getSentryError
} from '../../selectors'
import * as Sentry from '@sentry/browser';

Sentry.init({ dsn: 'https://e5b5b465c84e42a2abda589cea3d11e7@sentry.io/1479390', maxBreadcrumbs: 60 });

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      eventId: null,
      error: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.sentryError.isError !== this.props.sentryError.isError) {
      if (nextProps.sentryError.error === '404') {
        window.location = '/404'
      }
      this.setState({
        isError: nextProps.sentryError.isError,
        eventId: nextProps.sentryError.eventId,
        error: nextProps.sentryError.error
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ isError: true, eventId: eventId, error: error.message });
    });
  }


  render() {
    if (this.state.isError) {
      return (
        <div className="sentry-container">
          <div className="sentry">
            <div className="sentry-header">
              {(this.state.error && this.state.error == "Internet connection seems to be down" ? "Your internet connection is down" : "Something went wrong! This has been reported automatically to the admin.")}
            </div>
            {/* <p className="Sentry-eventId">{`Sentry eventId : ${this.state.eventId}`}</p> */}
            {/* <p className="Sentry-eventId">{`Error : ${this.state.error}`}</p> */}
            <Button
              btnClass="primary btn-sm floating"
              btnText="Refresh"
              onClick={() => {
                window.location.reload();
              }}
            />
          </div>
        </div>
      )
    } else {
      return this.props.children
    }
  }
}

const mapStateToProps = state => ({
  sentryError: getSentryError(state),
})

const dispatchActionToProps = dispatch => ({})

export default connect(mapStateToProps, dispatchActionToProps)(toJS(ErrorBoundary));