import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Helmet } from "react-helmet";
import moment from "moment";
import axios from "axios";
import isNil from "lodash/isNil";

import {
  getLocalToken,
  getGateToken,
  setGateToken,
} from "../../../helpers/getToken";

import { TransitionOne, TransitionTwo } from "../../common/transitions";

import { videos } from "../../../helpers/api";
import PaywallLimiter from "../../common/paywallLimiter";
import Loader from "../../images/loader";
import Favorite from "../../images/Favorite.js";

import pushToSentry from "../../../helpers/pushToSentry";

const VideoIndex = (props) => {
  const videoSlug =
    props.match && props.match && props.match.params
      ? props.match.params.videoSlug
      : "";

  const [showPaywall, setShowPaywall] = useState(false);

  const [state, updateState] = useState({
    isFavorite: false,
    isFetching: false,
    videoType: "youtube",
    videoId: "",
    meteredCount: 0,
    disableVideo: true,
  });

  if (!videoSlug) {
    props.history.push("/404");
  }

  useEffect(() => {
    props.changeFooterStatus(localStorage.getItem("token") ? true : false);
    props.appEssentialFetch();

    return function cleanup() {
      props.changeFooterStatus(true);
    };
  }, []);

  useEffect(() => {
    updateState({
      ...state,
      isFetching: true,
      videoType: "",
      videoId: "",
      title: "",
      date: "",
      limitReached: false,
    });

    const authenticatedToken = getLocalToken();
    let authToken;
    if (authenticatedToken) {
      authToken = `Token token="${authenticatedToken}"`;
    } else {
      let gateToken = localStorage.getItem('gateToken');
      if (!gateToken) {
        gateToken = Date.now() + Math.floor(Math.random() * 1000000);
      }

      authToken = `Token gate="${gateToken}"`;
      setGateToken(gateToken);
    }
    axios({
      method: "GET",
      url: videos.get(videoSlug),
      headers: {
        Authorization: authToken,
      },
    })
      .then((response) => {
        if (response && response.data) {
          updateState({
            ...state,
            isFavorite: response.data.videos.favorite,
            id: response.data.videos.id,
            isFetching: false,
            videoType: response.data.videos.youtube_id ? "youtube" : "vimeo",
            videoId: response.data.videos.youtube_id
              ? response.data.videos.youtube_id
              : response.data.videos.vimeo_id,
            title: response.data.videos.title,
            date: response.data.videos.publish_date,
            meteredCount: response.data.metered_videos,
          });
        }

        if (response.data.metered_videos > 0) {
          setShowPaywall(true);
        }
      })
      .catch((e) => {
        const eventId = pushToSentry(e);
        console.log(e);
        const response = e.response;
        setShowPaywall(true);
        updateState({
          isFetching: false,
          limitReached: true,
          videoType: response.data.videos.youtube_id ? "youtube" : "vimeo",
          videoId: response.data.videos.youtube_id
            ? response.data.videos.youtube_id
            : response.data.videos.vimeo_id,
          title: response.data.videos.title,
          date: response.data.videos.publish_date,
          meteredCount: response.data.metered_videos,
        });
      });
  }, []);

  const onClickFavorite = (item) => {
    if (item.itemId) {
      //favorite
      props.createFavourite(state.id, "video", "");
      updateState({
        ...state,
        isFavorite: true,
      });
    }
  };

  const deleteFavourite = (item) => {
    props.deleteFavourite(state.id, "video", null, []);
    updateState({
      ...state,
      isFavorite: false,
    });
  };

  const videoUrl =
    state.videoType === "youtube"
      ? `https://www.youtube.com/watch?v=${state.videoId}`
      : `https://vimeo.com/${state.videoId}`;

  return (
    <section className="video-index">
      <Helmet>
        <title>{`${!isNil(state.title) ? `${state.title} |` : ""
          } Dashing Dish`}</title>
      </Helmet>
      <div className="container">
        <div className="video-page-wrapper">
          <div className="video-page-body">
            <TransitionOne>
              <div className="video-page-title">
                <h3>{state.title}</h3>
                <span>
                  Published at {moment.unix(state.date).format("MMMM DD")}
                </span>
                {/* favorite */}
                <Favorite
                  item={{ id: state.id }}
                  favorite={true}
                  objectType={"video"}
                  onClickFavorite={(item) => onClickFavorite(item)}
                  deleteFavourite={(item) => deleteFavourite(item)}
                  thumbnail={null}
                  show={true}
                />
              </div>
            </TransitionOne>

            {showPaywall ? (
              <PaywallLimiter
                limitReached={state.limitReached}
                readCount={state.meteredCount}
                objectType="videos"
                isAuthenticated={props.isAuthenticated}
                closeVideoPaywall={() => setShowPaywall(false)}
              />
            ) : null}
            {state.isFetching ? (
              <TransitionTwo>
                <div className="container">
                  <div className="preLoader">
                    <Loader></Loader>
                  </div>
                </div>
              </TransitionTwo>
            ) : (
              <TransitionTwo>
                <div className="video-page-video-container">
                  <ReactPlayer
                    ref={this.ref}
                    className="react-player"
                    // width='600px'
                    url={videoUrl}
                    controls={true}
                    playing={false}
                    loop={true}
                    wrapper="div"
                  />
                </div>
              </TransitionTwo>
            )}

            {/* favorite */}
            {props.isAuthenticated && (
              <Favorite
                item={{ id: state.id }}
                favorite={state.isFavorite}
                objectType={"video"}
                onClickFavorite={(item) => onClickFavorite(item)}
                deleteFavourite={(item) => deleteFavourite(item)}
                thumbnail={null}
                show={true}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoIndex;
